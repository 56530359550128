type Subheading = Record<string, Record<string, string>>;

export const subheadKeyValue = {
  "#": {
    "#": "#"
  },
  Artist: {
    artistId: "ID",
    name: "이름",
    email: "이메일",
    typeArtistClass: "타입",
    validCheck: "유효성"
  },
  Track: {
    count: "개수"
  },
  Memo: {
    memo: "메모"
  },
  ArtistTitle: {
    description: "추가설명"
  },
  ArtistUrl: {
    thumbnail: "썸네일",
    typeUrl: "타입",
    url: "주소"
  },
  ArtistGenre: {
    name: "이름",
    type: "관리"
  },
  History: {
    birthDate: "태어난 날",
    birthPlace: "태어난 곳",
    deathDate: "사망한 날",
    deathPlace: "사망한 곳"
  },
  Role: {
    id: "ID",
    role: "이름",
    type: "타입",
    count: "count"
  }
} as Subheading;
