import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_X_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { usePlaylistsStore } from "App/Routes/Playlists/Store";
import { GetPlaylistCsvData } from "GraphQL/Queries/Playlist";
import { Toast } from "lib/toast";
import { handleDownloadCsv } from "./handleDownloadCsv";
import { MetadataPlaylistRelation, PlaylistCsvData } from "GraphQL/Queries/Playlist/GetPlaylistCsvData";
import { GRAY_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { Loading } from "App/Atomics/Loading";

type Props = Readonly<{
  toClose: () => void;
}>;

const CsvTypeOptionList = [
  { id: "default", name: "기본" },
  { id: "audioClip", name: "오디오클립" },
  { id: "kaist", name: "카이스트" },
  { id: "youtube", name: "유튜브 라이센스 체크" }
];

export const SelectCsvTypeModal = ({ toClose }: Props) => {
  const { userRole, userEmail, userName } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    userEmail: store.UserToken.email,
    userName: store.UserToken.name
  }));
  const [{ idList }] = usePlaylistsStore(store => ({
    idList: Array.from(store.Playlist.checkList)
      .filter(v => v[1])
      .map(k => k[0])
  }));

  const [csvType, setCsvType] = useState<string>(CsvTypeOptionList[0].id);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCsvData = useCallback(
    async (id: string, count: number, skip: number, list: MetadataPlaylistRelation[]): Promise<PlaylistCsvData[]> => {
      const playlist = await GetPlaylistCsvData({ idList: [id], count, skip, csvType });
      if (playlist[0]?.metadataPlaylistRelation.length === 100) {
        return fetchCsvData(id, count, skip + count, list.concat(playlist[0]?.metadataPlaylistRelation ?? []));
      }
      if (playlist.length) {
        playlist[0].metadataPlaylistRelation = list.concat(playlist[0]?.metadataPlaylistRelation);
      }
      return playlist;
    },
    [csvType]
  );

  const onDonwloadCsv = useCallback(async () => {
    try {
      const csvData: PlaylistCsvData[] = [];
      const count = 100;
      const skip = 0;
      setLoading(true);
      for (const id of idList) {
        const recurData = await fetchCsvData(id, count, skip, []);
        csvData.push(...recurData);
      }
      if (csvData.length) {
        handleDownloadCsv(csvData, csvType, userEmail, userName);
      } else {
        Toast.warning("다운로드할 음원이 없습니다.");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      Toast.error("정보를 가져올 수 없습니다.");
      setLoading(false);
    }
  }, [csvType, fetchCsvData, idList, userEmail, userName]);

  return (
    <Layout>
      <Header>
        <h3>{`CSV 다운로드 [${idList.length}개]`}</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <section>
        <Title>
          <h4>다운로드 할 CSV의 타입을 선택해주세요.</h4>
        </Title>
        <CsvSelect
          optionList={userRole === UserRole.Partner ? [CsvTypeOptionList[0]] : CsvTypeOptionList}
          defaultValue={CsvTypeOptionList.find(({ id }) => id === csvType)}
          onChange={info => {
            if (info) {
              setCsvType(info.id);
            }
          }}
        />
        <ButtonGroup>
          <Input.Button color="success" disabled={loading} onClick={onDonwloadCsv}>
            다운로드
          </Input.Button>
          <Input.Button color="danger" onClick={toClose}>
            닫기
          </Input.Button>
        </ButtonGroup>
      </section>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  width: ${pixelize(UNIT * 28)};
  height: ${pixelize(UNIT * 16)};

  section {
    padding: 1.5rem 2rem 0.5rem;
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background: mediumseagreen;

  color: #fff;
  padding: 1.3rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${MARGING_X_LARGE_PX};
  color: #191919;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

const CsvSelect = styled(Input.TextSelect)`
  width: 80%;
  margin-bottom: 3rem;
`;

const ButtonGroup = styled(Input.Group)`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }
`;
