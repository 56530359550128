/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field } from "graphql-jsx";
import { clients } from "utils/clients";

export const GetCountArtist = async () => {
  const { query, variables } = render(
    <Query operationName="GET_COUNT_ARTIST">
      <SelectionSet
        name="getCount"
        alias="artist"
        argument={{
          node: "artist"
        }}
      >
        <Field name="count" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    artist: {
      count: number;
    };
  }>;
  const { data, errors } = await clients.artist.request<Data>(query, variables);
  if (errors || !data) {
    return { artist: 0 };
  }
  const { artist } = data;
  return { artist: artist.count };
};
