/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, EnumScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

export const GetGenreList = async () => {
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="GET_GENRE_INFO">
      <SelectionSet
        name="genre"
        argument={{
          first: 999,
          group: true,
          orderBy: <EnumScalar type="GenreOrderByInput" value="genre_id__ASC" items={["genre_id__ASC"]} />,
          where: {
            type_kind: "genre",
            valid_check__not: $remove
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <Field name="name" />
        <Field name="type_kind" alias="typeKind" />
        <Field name="weight" />
        <SelectionSet name="genre_self_relation_child">
          <SelectionSet name="genre_self_relation_parent" alias="parent">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = {
    genre: {
      readonly id: string;
      readonly name: string;
      readonly typeKind: string;
      readonly weight: number;
      readonly genre_self_relation_child: {
        parent: {
          id: string;
          name: string;
        }[];
      }[];
    }[];
  };
  return await clients.genre.request<Data>(query, variables);
};
