import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

type Id = string;

export type Track = {
  id: Id;
  value: string;
  extra?: {
    index: number;
    structureId: string;
    order: number;
  };
};

export type Ordered = {
  metadataId: string;
  title: string;
  structureId: string;
  order: number;
  checked: boolean;
};

type Sort = "ASC" | "DESC";

type LoadingState = "None" | "Loading" | "Error";

const createInitialState = () => ({
  state: "None" as LoadingState,
  selectedTrackList: [] as readonly Track[],
  searchedTrackList: [] as readonly Track[],
  orderedTrackList: [] as Ordered[]
});

export const TrackActions = createDuck({
  namespace: "AlbumDetailTrack",
  createInitialState,
  reducers: {
    clear(state) {
      state.selectedTrackList = [];
      state.orderedTrackList = [];
    },
    set(state, { kind, tracks }: { kind: "searched" | "selected"; tracks: Track[] }) {
      const key = kind === "searched" ? "searchedTrackList" : "selectedTrackList";
      state[key] = tracks;
    },
    updateLoadingState(state, loadingState: LoadingState) {
      state.state = loadingState;
    },
    setOrderedTrackList(state, items: Ordered[]) {
      state.orderedTrackList = items;
    },
    swapOrderedList(state, { index, sort }: { index: number; sort: Sort }) {
      const target = state.orderedTrackList[index];
      state.orderedTrackList[index] = state.orderedTrackList[sort === "ASC" ? index - 1 : index + 1];
      state.orderedTrackList[sort === "ASC" ? index - 1 : index + 1] = target;
    },
    setOrderedCheck(state, { index, checked }: { index: number; checked: boolean }) {
      state.orderedTrackList[index].checked = checked;
    }
  }
});
