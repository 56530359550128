export enum ValidType {
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  VC = "VC",
  DONE = "DONE",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
  ETC = "ETC"
}
