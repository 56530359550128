/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { RoleTitleOrderByInput } from "../../constants/RoleTitleOrderByInput";

type Props = Readonly<{
  value?: RoleTitleOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  RoleTitleOrderByInput.id_ASC,
  RoleTitleOrderByInput.id_DESC,
  RoleTitleOrderByInput.exposure_order__ASC,
  RoleTitleOrderByInput.exposure_order__DESC,
  RoleTitleOrderByInput.type_role_title__ASC,
  RoleTitleOrderByInput.type_role_title__DESC,
  RoleTitleOrderByInput.value__ASC,
  RoleTitleOrderByInput.value__DESC,
  RoleTitleOrderByInput.valid_check_ASC,
  RoleTitleOrderByInput.valid_check_DESC,
  RoleTitleOrderByInput.createdAt_ASC,
  RoleTitleOrderByInput.createdAt_DESC,
  RoleTitleOrderByInput.updatedAt_ASC,
  RoleTitleOrderByInput.updatedAt_DESC
];

export const RoleTitleOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="Role_titleOrderByInput" value={value} isNullable={isNullable} items={items} />
);
