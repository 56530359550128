/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
}>;

export const DeletePlaylist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_PLAYLIST">
      <SelectionSet
        name="deletePlaylist"
        argument={{
          where: { playlist_id: $id }
        }}
      >
        <Field name="playlist_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    deletePlaylist: {
      id: string;
    }[];
  };
  return await clients.playlist.request<Data>(query, variables);
};
