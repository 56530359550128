import Time from "dayjs";
import itiriri from "itiriri";
import React, { useRef, ChangeEvent } from "react";
import styled, { keyframes } from "styled-components";
import uuid from "uuid";

import { InputTextRef } from "App/Atomics/Input/Text";
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { GRAY_4, BLACK, GRAY_8 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR, DANGER_COLOR } from "constants/color";
import {
  PADDING_XX_LARGE_PX,
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_LARGE_PX,
  MARGING_X_LARGE_PX,
  UNIT_PX,
  BORDER_RADIUS_PX,
  widthViewportize,
  MARGING_LARGE_PX,
  MARGING_SMALL_PX
} from "constants/size";
import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { useAlbumStore } from "../../Store";
import { AlbumActions, FileMode } from "../../Store/Album";
import { GuideTooltip } from "App/Molecules/AnimatedTooltips";
import { ArtistTagAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { ARTISTS_CREATE, RIGHTS_CREATE } from "constants/route";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { useAsyncEffect } from "lib/use-async-effect";
import { ReactComponent as CDIcon } from "assets/icons/cd.svg";
import { ReactComponent as MusicIcon } from "assets/icons/music.svg";
import { ReactComponent as EffectIcon } from "assets/icons/audio.svg";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { AlbumOverlapCheck } from "GraphQL/Queries";
import { Toast } from "lib/toast";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  padding: ${PADDING_XX_LARGE_PX} ${widthViewportize(0.4 * UNIT)};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};

  h2 {
    text-align: left;
    padding: ${PADDING_X_LARGE_PX} 0px;
  }

  .nextButton {
    margin-top: ${MARGING_X_LARGE_PX};
    margin-left: auto;
  }
`;

const LabelGroup = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    span {
      font-weight: 400;
      margin-left: ${MARGING_SMALL_PX};
      font-size: 0.9rem;
      color: ${DANGER_COLOR};
    }

    & svg {
      position: relative;
      top: 4px;
      width: ${pixelize(1.25 * UNIT)};
      height: ${pixelize(1.25 * UNIT)};
    }
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 5)};
  grid-template-columns: 80% 20%;
  grid-gap: ${UNIT_PX};
  align-items: center;

  input {
    width: 100%;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid ${GRAY_8};
    }
  }

  button {
    width: calc(100% - ${UNIT_PX});
  }
`;

const IsSingleGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${MARGING_X_LARGE_PX};

  label {
    padding: ${PADDING_LARGE_PX} 0px;
    margin-right: ${MARGING_LARGE_PX};
    text-align: left;
    font-weight: bold;
  }

  svg {
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
    margin-right: ${MARGING_SMALL_PX};
    margin-top: 3px;
    transition: fill 0.2s;
  }
  .active-cd {
    animation: ${rotate} 2s infinite linear;
  }
`;

const PageSpan = styled.a`
  width: fit-content;
  font-size: 0.9rem;
  color: ${PRIMARY_COLOR};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const CustomMargin = styled.div`
  width: 100%;
  height: ${MARGING_LARGE_PX};
  color: transparent;
`;

/**
 *  TODO:: ALBUM 생성 시 Required
 *  - metadata table (album 기본 정보)
 *    + metadata_id
 *    + type_metadata_class
 *    + type_metadata_subclass
 *    + no
 *    + title
 *    + valid_check
 *
 *  - metadata_artist_relation table (artist 기본 정보)
 *    + metadata_id
 *    + artist_id
 *    + role_id (고정?)
 *
 *  - metadata_title table (앨범 설명 정보)
 *    + metadata_id
 *    + type_metadata_title (트랙 = basic / 앨범 = album_desc)
 *    + language_code (언어팩 - 일단은 XX?)
 *    + value
 *
 *  - metadata_subdata_unique table (앨범 커버 url)
 *    (차후 metadata_url table로)
 *    + metadata_id
 *    + type_subdata_category
 *    + type_subdata_field
 *    + value
 *
 *  - metadata_company_relation table (제작사 / 유통사)
 *    + metadata_id
 *    + company_id
 *
 *  - metadata_structure table (앨범 구조 정보)
 *    + metadata_id
 *
 */

const serialValidateQuery = gql`
  query METADATA_SERIAL_VALIDATE($no: String) {
    metadata(where: { AND: [{ no: $no }, { type_metadata_class: "record", type_metadata_subclass: "album" }] }) {
      metadata_id
    }
  }
`;

export const AlbumForm = () => {
  const [{ album, trackList, isSingle, fileMode, isEffect }, dispatch] = useAlbumStore(store => ({
    album: store.AlbumCreate.album,
    trackList: store.AlbumCreate.trackList,
    isSingle: store.AlbumCreate.isSingle,
    fileMode: store.AlbumCreate.fileMode,
    isEffect: store.AlbumCreate.isEffect
  }));
  const userRole = useAppSelector(store => store.UserToken.role);
  const serialRef = useRef<InputTextRef>(null);
  const today = Time().format("YYYY-MM-DD");

  const onNextForm = () => {
    dispatch(AlbumActions.setAlbumClass());
    if (!trackList.length) {
      dispatch(
        AlbumActions.setNewTrack({
          no: "01",
          subClass: !isEffect ? "track" : "effect",
          license: {
            notice: `{"text": "License : ${album.albumCompany.license}"}`,
            country_code: "ZZ",
            type_track: album.albumCompany.type === "Cover" ? "Cover" : "VL",
            publish_date: album.releaseDate,
            is_service: 0
          }
        })
      );
    }

    if (isSingle) {
      dispatch(AlbumActions.setTrackTitle({ no: "01", title: album.title }));
    }

    dispatch(AlbumActions.setPageState("TRACK"));
  };

  const onAlbumOverlapCheck = async () => {
    try {
      const artistList = album.albumArtist.map(({ artist_id }) => artist_id);
      const { data, errors } = await AlbumOverlapCheck({ title: album.title, artistList });

      if (errors || !data) {
        throw errors;
      }

      if (!data.metadata.length) {
        Toast.primary("중복되는 음악 정보가 없습니다.", undefined, "bottom-right");
        return true;
      } else {
        if (
          window.confirm(
            `중복되는 음악 정보가 있습니다. 계속 진행하시겠습니까?\n트랙명: ${
              data.metadata[0].title
            } / 아티스트: ${data.metadata[0].artistRelation.map(({ artist }) => artist[0].name).join(", ")}`
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      alert("중복체크 중 에러가 발생했습니다.");
      return false;
    }
  };

  const albumSerialValidate = async (value: string) => {
    const { data } = await clients.metadata.query(serialValidateQuery, { no: value });
    if (!data.metadata.length) {
      dispatch(AlbumActions.setAlbumNo(value));
    } else {
      alert("앨범 시리얼 넘버가 중복됩니다. 다시 입력하세요.");
      serialRef.current?.focus();
      serialRef.current!.value = "";
    }
  };

  const albumAutoSerialValidate = async () => {
    while (true) {
      const albumNo = uuid();
      const { data } = await clients.metadata.query(serialValidateQuery, { no: albumNo });
      if (!data.metadata || !data.metadata.length) {
        dispatch(AlbumActions.setAlbumNo(albumNo));
        break;
      }
    }
  };

  const setAlbumCover = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        AlbumActions.setAlbumUrl({
          file: file.files![0],
          value: event.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          name: file.files![0].name,
          valueType: file.files![0].type
        })
      );
    };
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted() && !album.no) {
        albumAutoSerialValidate();
      }
      if (!album.releaseDate) {
        dispatch(AlbumActions.setAlbumRelease(today));
      }
    },
    [album.no]
  );

  return (
    <Layout
      onSubmit={event => {
        event.preventDefault();

        const current = event.currentTarget;
        const inputList = current.getElementsByTagName("input");

        const inputTextCheck = itiriri(inputList)
          .filter(input => input.type !== "file" && !input.id.includes("react-select"))
          .every(input => !!input.value.length);

        if (!inputTextCheck || !album.albumCompany) {
          alert("앨범정보가 모두 작성되지 않았습니다.");
          return;
        }

        if (fileMode === FileMode.URL && !isSingle) {
          window.alert("음원 URL 등록 시, 반드시 싱글앨범이어야 합니다.");
          return;
        }

        if (isSingle && !isEffect) {
          onAlbumOverlapCheck().then(async allow => {
            if (allow) {
              onNextForm();
            }
          });
        } else {
          onNextForm();
        }
      }}
    >
      <h2>앨범정보 입력</h2>
      <Divider />
      <IsSingleGroup>
        <CDIcon className={isSingle ? "active-cd" : "cd"} fill={isSingle ? "#4d64ec" : BLACK} />
        <label>싱글앨범 여부</label>
        <div style={{ display: "flex" }}>
          <Input.CheckBox id="single-check" isChecked={isSingle} onToggle={value => dispatch(AlbumActions.setAlbumIsSingle(value))} />
        </div>
        <MusicIcon style={{ marginLeft: "10px" }} fill={fileMode === FileMode.FILE ? "#4d64ec" : BLACK} />
        <label>음원 직접등록</label>
        <div style={{ display: "flex" }}>
          <Input.CheckBox
            id="mp3-check"
            isChecked={fileMode === FileMode.FILE}
            onToggle={() => dispatch(AlbumActions.setTrackFileMode(fileMode === FileMode.FILE ? FileMode.URL : FileMode.FILE))}
          />
        </div>
        <EffectIcon style={{ marginLeft: "10px" }} fill={fileMode === FileMode.FILE ? "#4d64ec" : BLACK} />
        <label>효과음 등록</label>
        <div style={{ display: "flex" }}>
          <Input.CheckBox id="effect-check" isChecked={isEffect} onToggle={isChecked => dispatch(AlbumActions.setIsEffect(isChecked))} />
        </div>
      </IsSingleGroup>

      <LabelGroup>
        <label>
          시리얼 넘버*
          <GuideTooltip text="앨범의 고유 등록 번호입니다. 고유 번호를 직접 입력하시거나, 자동생성 버튼을 눌러주세요." direction="right" />
        </label>
        <RowGroup>
          <Input.Text
            key={album.no}
            ref={serialRef}
            id="albumSerialInput"
            placeholder="시리얼 넘버를 입력하세요."
            defaultValue={album.no ? album.no : ""}
            onBlur={value => value && albumSerialValidate(value)}
            isRequired
          />
          <Input.Button color="default" isWide onClick={albumAutoSerialValidate}>
            자동생성
          </Input.Button>
        </RowGroup>
      </LabelGroup>

      {!isEffect && (
        <LabelGroup>
          <label>메인 아티스트</label>
          <RowGroup>
            <ArtistTagAutoComplete
              className="autocomplete"
              defaultValue={
                album.albumArtist &&
                album.albumArtist.map(({ artist_id, artist_name }) => ({
                  id: artist_id,
                  name: artist_name
                }))
              }
              onBlur={info => {
                if (info) {
                  const artistInfo = info.map(({ id, name }, index) => ({
                    artist_id: id,
                    artist_name: name,
                    role_id: "343",
                    role_name: "perform main",
                    order: index
                  }));
                  dispatch(AlbumActions.setAlbumArtist(artistInfo));
                }
              }}
            />
            <PageSpan href={ARTISTS_CREATE} target="_blank">
              아티스트 등록하기
            </PageSpan>
          </RowGroup>
        </LabelGroup>
      )}

      <LabelGroup>
        <label>타이틀*</label>
        <RowGroup>
          <Input.Text
            placeholder={`앨범 타이틀을 입력하세요. (특수문자 "를 사용할 수 없습니다.)`}
            defaultValue={album.title ? album.title : ""}
            onChange={str => {
              if (str.includes(`"`)) {
                window.alert(`특수문자 "를 사용할 수 없습니다.`);
                return;
              }
            }}
            onBlur={str => {
              dispatch(AlbumActions.setAlbumDescription(str));
            }}
            isRequired
          />
        </RowGroup>
      </LabelGroup>
      <LabelGroup>
        <label>
          {fileMode === FileMode.URL ? "음원 URL*" : "커버 이미지"}
          {fileMode === FileMode.URL ? <span> 재생시간이 10분이 넘는 파일은 업로드할 수 없습니다.</span> : ""}
        </label>
        <RowGroup>
          {fileMode === FileMode.URL ? (
            <Input.Text
              isRequired
              placeholder="예시) https://www.youtube.com/watch?v=XXXXXX"
              defaultValue={album.ytv_Url ? album.ytv_Url : undefined}
              onBlur={url => dispatch(AlbumActions.setAlbumYtvUrl(url))}
            />
          ) : (
            <div>
              <input type="file" accept="image/jpeg,image/png" onChange={setAlbumCover} />
              {album.albumUrl && album.albumUrl.url && (
                <div style={{ textAlign: "left" }}>
                  <b>현재 파일: </b> {album.albumUrl.url}
                </div>
              )}
            </div>
          )}
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>권리사*</label>
        <RowGroup>
          <CompanyTextAutoComplete
            className="autocomplete"
            defaultValue={album.albumCompany && { id: album.albumCompany.company_id, name: album.albumCompany.name }}
            onBlur={info => {
              if (info) {
                dispatch(
                  AlbumActions.setAlbumCompany({
                    company_id: info.id,
                    name: info.name,
                    license: info.license ? info.license : "",
                    type: info.type ? info.type : "",
                    path: info.path ? info.path : ""
                  })
                );
              }
            }}
          />
          {userRole === UserRole.Master && (
            <PageSpan href={RIGHTS_CREATE} target="_blank">
              권리사 등록하기
            </PageSpan>
          )}
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>
          제작사<span>(production)</span>
        </label>
        <RowGroup>
          <CompanyTagAutoComplete
            className="autocomplete"
            defaultValue={album.productions?.map(({ company_id, name }) => ({ id: company_id, name }))}
            onBlur={info => {
              if (info) {
                const productions = info.map(({ id, name }) => ({ company_id: id, name }));
                dispatch(AlbumActions.setAlbumProductions(productions));
              }
            }}
          />
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>
          배급사<span>(publication)</span>
        </label>
        <RowGroup>
          <CompanyTagAutoComplete
            className="autocomplete"
            defaultValue={album.publications?.map(({ company_id, name }) => ({ id: company_id, name }))}
            onBlur={info => {
              if (info) {
                const publications = info.map(({ id, name }) => ({ company_id: id, name }));
                dispatch(AlbumActions.setAlbumPublications(publications));
              }
            }}
          />
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>발매일자</label>
        <RowGroup>
          <input
            type="date"
            defaultValue={!album.releaseDate ? today : album.releaseDate}
            required
            min="1900-01-01"
            max="2099-12-31"
            onChange={event => dispatch(AlbumActions.setAlbumRelease(event.currentTarget.value))}
          />
        </RowGroup>
      </LabelGroup>

      <Input.Button className="nextButton" type="submit" color="danger">
        다음 (트랙정보 입력)
      </Input.Button>
      <CustomMargin>{"-"}</CustomMargin>
    </Layout>
  );
};
