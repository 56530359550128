import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsSubTitleSelect } from "./GoodsSubtitleSelect";

type Props = {
  readonly subtitle: string;
};

export const GoodsSubTitle = ({ subtitle }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>서브제목 (SubTitle) *</Style.Caption>
    <Style.SubCaption>각 굿즈 및 상품 제목 위에 표시됩니다.</Style.SubCaption>
    <GoodsSubTitleSelect subtitle={subtitle} />
  </Style.Row>
);
