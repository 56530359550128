/** @jsx jsx */

import { jsx, Scalar } from "graphql-jsx";

import { UserRole as TierType } from "constants/UserRole";

type Props = Readonly<{
  value?: TierType;
  isNullable?: boolean;
}>;

export const TierTypeScalar = ({ value, isNullable }: Props) => <Scalar type="TierType" value={value} isNullable={isNullable} />;
