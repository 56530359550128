import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { GRAY_4, GRAY_6 } from "constants/baseColor";
import {
  ColumnFilter,
  GenreExcludeFilter,
  GenreIncludeFilter,
  IdRangeFilter,
  IdIncludeFilter,
  MoodExcludeFilter,
  MoodIncludeFilter,
  LicenseOptionFilter,
  ValidTypeFilter,
  CompanyIncludeFilter,
  CountFilter,
  SkipFilter
} from "./Filter";
import { FilterBox, FilterWrapper } from "./formStyle";
import { useAuthGrantSelector } from "../Store";
import { IdType } from "../Store/IdList";
import { Toast } from "lib/toast";
import {
  findArtistSearchList,
  findGenreSearchList,
  findPlaylistSearchList,
  findTrackSearchList,
  findCompanySearchList
} from "GraphQL/Queries/AuthGrant";
import { Loading } from "App/Atomics/Loading";
import { Modal } from "lib/modal";
import { SearchResultModal } from "../Modals";
import { useToggle } from "lib/use-toggle";
import { GrantKey } from "../Store/Grant";

export const SearchForm = () => {
  const { mode, searchList, idList } = useAuthGrantSelector(store => store.IdList);
  const [searchIds, setSearchIds] = useState<IdType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const searchResultModal = useToggle();

  const onSearch = async (mode: GrantKey) => {
    setLoading(true);
    try {
      switch (mode) {
        case GrantKey.TRACK:
          const trackIds = await onSearchTrack();
          setSearchIds(trackIds);
          break;
        case GrantKey.ARTIST:
          const artistsIds = await onSearchArtist();
          setSearchIds(artistsIds);
          break;
        case GrantKey.GENRE:
          const genreIds = await onSearchGenre();
          setSearchIds(genreIds);
          break;
        case GrantKey.PLAYLIST:
          const playlistIds = await onSearchPlaylist();
          setSearchIds(playlistIds);
          break;
        case GrantKey.COMPANY:
          const companyIds = await onSearchCompany();
          setSearchIds(companyIds);
          break;
        default:
          return;
      }
      searchResultModal.on();
    } catch (err) {
      console.log(err);
      Toast.error("검색에 실패하였습니다.", undefined, "top-center");
      return;
    } finally {
      setLoading(false);
    }
  };

  const onSearchTrack = async () => {
    const {
      count,
      skip,
      idLte,
      idGte,
      idIncludeList,
      idExcludeList,
      validTypeList,
      includeGenreList,
      includeMoodList,
      includeCompanyList,
      excludeGenreList,
      excludeMoodList,
      licenses
    } = searchList.Track;
    const response = await findTrackSearchList({
      count,
      skip,
      idLte,
      idGte,
      idIncludeList: Array.from(idIncludeList),
      idExcludeList: Array.from(idExcludeList),
      licenseList: Array.from(licenses),
      validTypeList,
      includeGenreList: includeGenreList.map(({ id }) => id),
      includeMoodList: includeMoodList.map(({ id }) => id),
      excludeGenreList: excludeGenreList.map(({ id }) => id),
      excludeMoodList: excludeMoodList.map(({ id }) => id),
      includeCompanyList: includeCompanyList.map(({ id }) => id)
    });

    return response;
  };

  const onSearchPlaylist = async () => {
    const { count, skip, idLte, idGte, idIncludeList, idExcludeList, validTypeList } = searchList.Playlist;
    const response = await findPlaylistSearchList({
      count,
      skip,
      idLte,
      idGte,
      idIncludeList: Array.from(idIncludeList),
      idExcludeList: Array.from(idExcludeList),
      validTypeList
    });
    return response;
  };

  const onSearchArtist = async () => {
    const { count, skip, idLte, idGte, idIncludeList, idExcludeList, validTypeList } = searchList.Artist;
    const response = await findArtistSearchList({
      count,
      skip,
      idLte,
      idGte,
      idIncludeList: Array.from(idIncludeList),
      idExcludeList: Array.from(idExcludeList),
      validTypeList
    });
    return response;
  };

  const onSearchGenre = async () => {
    const { count, skip, idLte, idGte, idIncludeList, idExcludeList, validTypeList } = searchList.Genre;
    const response = await findGenreSearchList({
      count,
      skip,
      idLte,
      idGte,
      idIncludeList: Array.from(idIncludeList),
      idExcludeList: Array.from(idExcludeList),
      validTypeList
    });

    return response;
  };

  const onSearchCompany = async () => {
    const { count, skip, idLte, idGte, idIncludeList, idExcludeList, validTypeList } = searchList.Company;
    const response = await findCompanySearchList({
      count,
      skip,
      idLte,
      idGte,
      idIncludeList: Array.from(idIncludeList),
      idExcludeList: Array.from(idExcludeList),
      validTypeList
    });
    return response;
  };

  const onCloseSearchResultModal = () => {
    searchResultModal.off();
    setSearchIds([]);
  };
  return (
    <Layout>
      <FilterInfo>
        <li>
          <span>{`트랙 (${idList.Track.length ?? 0})`}</span>
        </li>
        <li>
          <span>{`아티스트 (${idList.Artist.length ?? 0})`}</span>
        </li>
        <li>
          <span>{`장르 / 무드 (${idList.Genre.length ?? 0})`}</span>
        </li>
        <li>
          <span>{`플레이리스트 (${idList.Playlist.length ?? 0})`}</span>
        </li>
        <li>
          <span>{`권리사 (${idList.Company.length ?? 0})`}</span>
        </li>
      </FilterInfo>
      <FilterBox>
        <FilterWrapper>
          <ColumnFilter />
          <CountFilter />
          <SkipFilter />
          <IdRangeFilter />
          <IdIncludeFilter />
          {/* <IdExcludeFilter /> */}
          <ValidTypeFilter />
          {mode === GrantKey.TRACK && <GenreIncludeFilter />}
          {mode === GrantKey.TRACK && <GenreExcludeFilter />}
          {mode === GrantKey.TRACK && <MoodIncludeFilter />}
          {mode === GrantKey.TRACK && <MoodExcludeFilter />}
          {mode === GrantKey.TRACK && <LicenseOptionFilter />}
          {mode === GrantKey.TRACK && <CompanyIncludeFilter />}
        </FilterWrapper>
      </FilterBox>
      <ButtonGroup>
        <Input.Button isWide className="search-btn" onClick={() => onSearch(mode)}>
          검색
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
      <Modal isOpen={searchResultModal.isToggled} onClose={onCloseSearchResultModal}>
        <SearchResultModal idType={mode} idList={searchIds} setSearchIds={setSearchIds} toClose={onCloseSearchResultModal} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  font-size: 13px;
`;

const ButtonGroup = styled.div`
  padding: 8px 0;
  .search-btn {
    margin: 8px 0;
    border: 1px solid ${GRAY_4};
    padding: 8px 16px;
  }
`;

const FilterInfo = styled.ul`
  width: 100%;
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 4px;
  list-style: none;

  span {
    font-size: 1.2rem;
    font-weight: 300;
    padding-right: 4px;
    border-bottom: 1px solid ${GRAY_6};
  }
`;
