import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { ReactComponent as Upload } from "assets/icons/upload.svg";
import { WHITE, GRAY_2, GRAY_3, BLUE_3 } from "constants/baseColor";
import { pixelize, UNIT, MARGING_LARGE_PX } from "constants/size";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAlbumDispatch } from "App/Routes/AlbumRenewal/Store";
import { SingleTrackActions } from "App/Routes/AlbumRenewal/Store/SingleTrack";

type Props = {
  accept: string;
  type: string;
  fileType: string;
  isUploaded: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Layout = styled.div<{ isUploaded: boolean }>`
  width: ${pixelize(UNIT * 9.375)};
  height: ${pixelize(UNIT * 9.375)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 6px solid ${props => (props.isUploaded ? BLUE_3 : GRAY_2)};
  border-radius: 50%;
  background-color: ${WHITE};
  outline: none;
  cursor: pointer;
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => (props.isUploaded ? BLUE_3 : GRAY_3)};
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    cursor: pointer;

    span {
      margin-top: ${MARGING_LARGE_PX};
    }
  }
`;

export const Dropzone = ({ accept, type, fileType, isUploaded, onChange }: Props) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({ accept });
  const dispatch = useAlbumDispatch();
  useAsyncEffect(
    async _ => {
      if (acceptedFiles.length) {
        dispatch(SingleTrackActions.setSingleTrackData({ file: acceptedFiles[0], fileType, name: acceptedFiles[0].name }));
      }
    },
    [acceptedFiles, fileType]
  );
  return (
    <Layout isUploaded={isUploaded} {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} onChange={onChange} />
      <label>
        <Upload fill={isUploaded ? BLUE_3 : GRAY_2} />
        <span>{type.toUpperCase()}</span>
      </label>
    </Layout>
  );
};
