import { BLACK, GRAY_1, GRAY_4, PINK_1, WHITE } from "constants/baseColor";
import { BORDER_RADIUS_PX, PADDING_LARGE_PX, pixelize, UNIT } from "constants/size";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Layout = styled.div`
  overflow-y: scroll;

  border-radius: ${BORDER_RADIUS_PX};
  border: 1px solid ${GRAY_4};
`;

const Guard = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.count}, 1fr);
  grid-gap: ${pixelize(UNIT / 4)};
`;

type Props = Styleable & {
  count: number;
  children: ReactNode;
};

export const ScrollableTagList = ({ count, children, ...props }: Props) => (
  <Layout {...props}>
    <Guard count={count}>{children}</Guard>
  </Layout>
);

const ItemLayout = styled.button`
  cursor: pointer;
  user-select: none;
  display: inline-block;

  padding: ${PADDING_LARGE_PX} ${PADDING_LARGE_PX};

  font-size: 0.875rem;
  color: ${BLACK};
  border-radius: ${BORDER_RADIUS_PX};
  background-color: ${WHITE};

  &:hover {
    background-color: ${GRAY_1};
  }

  &:disabled {
    background-image: linear-gradient(225deg, ${PINK_1}, ${PINK_1});
  }
`;

type ItemProps = {
  isActive?: boolean;
  children: ReactNode;
  onClick?: () => void;
};

ScrollableTagList.Item = ({ isActive = false, children, ...props }: ItemProps) => (
  <ItemLayout disabled={isActive} {...props}>
    {children}
  </ItemLayout>
);
