import React, { ReactNode } from "react";
import styled from "styled-components";
import { GRAY_5 } from "constants/baseColor";

type Props = Styleable & Readonly<{ children: ReactNode }>;

const TableLayout = styled.table`
  position: relative;
  width: 100%;
  border-top: 2px solid ${GRAY_5};
  border-bottom: 2px solid ${GRAY_5};
  border-collapse: collapse;
  border-spacing: 0;
`;

export const Layout = ({ style, className, children }: Props) => (
  <TableLayout style={style} className={className}>
    {children}
  </TableLayout>
);
