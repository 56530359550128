import immer from "immer";
import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import styled from "styled-components";

import { Input } from "App/Atomics/Input";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as Refresh } from "assets/icons/refresh.svg";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR, DANGER_COLOR, transparentizeRGB } from "constants/color";
import { pixelize, UNIT, MARGING_SMALL_PX, MARGING_LARGE_PX, PADDING_LARGE_PX, BORDER_RADIUS_PX, PADDING_X_LARGE_PX } from "constants/size";
import { GRAY_5, WHITE } from "constants/baseColor";
import { AnimatedCheckbox } from "../AnimatedCheckbox";

export type DualListboxItem<T> = Readonly<{
  id: string;
  value: string;
  extra?: any;
}>;

export type DualListboxItemProps<T = undefined> = DualListboxItem<T> & Readonly<{ index: number }>;

type Props<T> = Styleable &
  Readonly<{
    left: {
      label?: string;
      items: readonly DualListboxItem<T>[];
    };
    right: {
      label?: string;
      items: readonly DualListboxItem<T>[];
    };
    isLoading?: boolean;
    Item: FC<DualListboxItemProps<T>>;
    onChange: (itemList: readonly DualListboxItem<T>[]) => void;
  }>;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content 1fr;

  height: inherit;

  & > .left,
  & > .right {
    display: grid;
    grid-template-rows: min-content 1fr;
    height: inherit;
  }

  .icon {
    align-self: center;

    & > button {
      width: ${pixelize(2 * UNIT)};
      height: ${pixelize(2 * UNIT)};

      margin: ${MARGING_SMALL_PX};
      padding: 0 ${MARGING_LARGE_PX};
      border: 1px solid ${DEFAULT_BORDER_COLOR};
      background-color: ${WHITE};

      &:hover {
        background-color: #ededf8;
      }
    }
  }
`;

const Heading = styled.label`
  display: grid;
  grid-template-columns: 1fr min-content;
  height: min-content;
  margin-bottom: ${MARGING_LARGE_PX};
  font-weight: bold;
`;

const List = styled.div`
  overflow-y: auto;
  height: 100%;
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-radius: ${BORDER_RADIUS_PX};
  background-color: ${WHITE};

  li {
    padding: ${PADDING_LARGE_PX};
    border-bottom: 1px solid ${GRAY_5};
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #ededf8;
    }

    &[data-selected="true"] {
      background-color: ${transparentizeRGB(PRIMARY_COLOR, 15)};
    }
  }

  &.left li {
    display: grid;
    grid-template-columns: 95% min-content;
  }

  &.right li {
    display: flex;
    flex-direction: right;
    align-items: center;

    & > .removeBtn {
      padding: ${PADDING_LARGE_PX};
    }

    &[data-selected="true"] {
      background-color: ${transparentizeRGB(DANGER_COLOR, 15)};
    }
  }
`;

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${PADDING_X_LARGE_PX};
  background-color: #cfcfff;
  border-bottom: 1.5px solid ${DEFAULT_BORDER_COLOR};

  & > label {
    color: ${PRIMARY_COLOR};
    font-size: 1.1rem;
    align-self: left;
  }

  & > div {
    margin-left: auto;
    margin-right: 5px;
  }

  & > button {
    margin-left: auto;
    padding: 0;

    & > svg {
      width: ${pixelize(1.25 * UNIT)};
      height: ${pixelize(1.25 * UNIT)};
    }
  }
`;

export const DualListbox = function<T = undefined>({ style, left, right, isLoading = false, Item, onChange }: Props<T>) {
  const [selectedItems, setSelectedItems] = useState<Map<string, DualListboxItem<T>>>(() => new Map());
  const [waitingToRemoveItemIndex, setWaitingToRemoveItemIndex] = useState<null | number>(null);

  const initializeSelectedItems = () => {
    setSelectedItems(new Map());
    setWaitingToRemoveItemIndex(null);
  };
  useEffect(initializeSelectedItems, [right]);

  const createCachedItems = () => new Map(right.items.map(item => [item.id, item]));
  const cachedItems = useMemo(createCachedItems, [right.items]);

  const onToggleCheck = (checked: boolean) => {
    if (checked) {
      const items = new Map(selectedItems);
      for (const item of left.items) {
        items.set(item.id, item);
      }
      setSelectedItems(items);
    } else {
      setSelectedItems(() => new Map());
    }
  };

  const unselectAll = () => {
    if (window.confirm("정말로 전부 취소하겠습니까?")) {
      onChange([]);
      setSelectedItems(new Map());
      setWaitingToRemoveItemIndex(null);
    }
  };

  const toggleItem = (item: DualListboxItem<T>) => () => {
    setSelectedItems(selectedItems =>
      immer(selectedItems, draft => {
        if (selectedItems.has(item.id)) {
          draft.delete(item.id);
        } else {
          draft.set(item.id, item);
        }
      })
    );
  };

  const updateRightItemList = () => {
    const nextItemList = Array.from(
      new Map(right.items.map(item => [item.id, item] as const).concat(Array.from(selectedItems.entries()))).values()
    );
    onChange(nextItemList);
  };

  const removeItemByIndex = (index: number) => () => {
    if (waitingToRemoveItemIndex !== index) {
      setWaitingToRemoveItemIndex(index);
      return;
    }
    const nextItemList = right.items.slice();
    nextItemList.splice(index, 1);
    onChange(nextItemList);
  };

  const checkAllSelected = useCallback(() => (!left.items.length ? false : left.items.length === selectedItems.size), [
    left.items,
    selectedItems
  ]);

  return (
    <Layout style={style}>
      <div className="left">
        <Heading>{left.label && left.label}</Heading>
        <List className="left">
          <ListHeader>
            <label>전체선택</label>
            <AnimatedCheckbox id="dualSelectAll" isChecked={checkAllSelected()} onToggle={onToggleCheck} />
          </ListHeader>
          <ul>
            {isLoading && "불러오는 중입니다..."}
            {!isLoading &&
              left.items.map((item, index) => {
                if (cachedItems.has(item.id)) {
                  return null;
                }
                return (
                  <li key={item.id} data-selected={selectedItems.has(item.id)}>
                    <Item id={item.id} value={item.value} extra={item.extra} index={index} />
                    <AnimatedCheckbox id={"select" + item.id} isChecked={selectedItems.has(item.id)} onToggle={toggleItem(item)} />
                  </li>
                );
              })}
          </ul>
        </List>
      </div>

      <div className="icon">
        <Input.Button onClick={updateRightItemList}>
          <ArrowRight />
        </Input.Button>
      </div>

      <div className="right">
        <Heading>{right.label && right.label}</Heading>
        <List className="right">
          <ListHeader>
            <label>전부 지우기</label>
            <Input.Button onClick={unselectAll}>
              <Refresh />
            </Input.Button>
          </ListHeader>
          <ul>
            {right.items.map(({ id, value, extra }, index) => (
              <li data-selected={waitingToRemoveItemIndex === index} key={id}>
                <Item id={id} value={value} extra={extra} index={index} />
                <Input.Button className="removeBtn" onClick={removeItemByIndex(index)}>
                  X
                </Input.Button>
              </li>
            ))}
          </ul>
        </List>
      </div>
    </Layout>
  );
};
