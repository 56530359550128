import { INDIGO_4, INDIGO_5, INDIGO_6, INDIGO_7, INDIGO_8 } from "constants/baseColor";
import React, { ReactNode } from "react";
import styled from "styled-components";

const Layout = styled.div<{ count: number }>`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  .multi-floating-button {
    display: none;
  }
  .dot-wrapper {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border-bottom-right-radius: 6px;
    background: ${INDIGO_7};
    box-shadow: 0px 5px 20px ${INDIGO_5};
    transition: all 0.3s ease;
    z-index: 1;
    border: 1px solid ${INDIGO_8};
    cursor: pointer;
  }

  .dot-wrapper:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .multi-floating-button:checked ~ .dot-wrapper:before {
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background-color: rgba(255, 255, 255, 0.2);
  }
  .dot-wrapper:hover {
    background: ${INDIGO_6};
    box-shadow: 0px 5px 20px 5px ${INDIGO_4};
  }

  .dot {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    transform: translateX(0%) translateY(-50%) rotate(0deg);
    opacity: 1;
    animation: blink 3s ease infinite;
    transition: all 0.3s ease;
  }

  .dot-1 {
    left: 15px;
    animation-delay: 0s;
  }
  .dot-2 {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation-delay: 0.4s;
  }
  .dot-3 {
    right: 15px;
    animation-delay: 0.8s;
  }

  .multi-floating-button:checked ~ .dot-wrapper .dot {
    height: 6px;
  }

  .dot-wrapper .dot-2 {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  .multi-floating-button:checked ~ .dot-wrapper .dot-1 {
    width: 32px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  .multi-floating-button:checked ~ .dot-wrapper .dot-3 {
    width: 32px;
    border-radius: 10px;
    right: 50%;
    transform: translateX(50%) translateY(-50%) rotate(-45deg);
  }

  @keyframes blink {
    50% {
      opacity: 0.25;
    }
  }

  .multi-floating-button:checked ~ .dot-wrapper .dot {
    animation: none;
  }

  .floating-button-item-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10rem;
    height: 10rem;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    transform: scale(0);
  }

  .multi-floating-button:checked ~ .floating-button-item-wrapper {
    transform: scale(1);
  }

  .multi-floating-button:checked ~ .floating-button-item-wrapper .floating-button-item {
    opacity: 1;
  }

  .floating-button-item-wrapper button:nth-child(1) {
    right: ${props => (props.count === 4 ? "-1rem" : "-.9rem")};
    top: ${props => (props.count === 4 ? "0" : "1rem")};
  }

  .floating-button-item-wrapper button:nth-child(2) {
    right: ${props => (props.count === 4 ? "3.4rem" : "4rem")};
    top: ${props => (props.count === 4 ? "0.5rem" : "2.6rem")};
  }
  .floating-button-item-wrapper button:nth-child(3) {
    left: ${props => (props.count === 4 ? ".5rem" : "1.2rem")};
    bottom: ${props => (props.count === 4 ? "3.4rem" : "-.5rem")};
  }
  .floating-button-item-wrapper button:nth-child(4) {
    left: 0;
    bottom: -1rem;
  }
`;

type Props = Styleable & {
  children: ReactNode;
};

export const MultiFloatingButton = ({ children }: Props) => {
  const count = React.Children.toArray(children).length;
  return (
    <Layout count={count}>
      <input id="floating-button" type="checkbox" className="multi-floating-button" />
      <label className="dot-wrapper" htmlFor="floating-button">
        <span className="dot dot-1" />
        <span className="dot dot-2" />
        <span className="dot dot-3" />
      </label>
      <div className="floating-button-item-wrapper">{children}</div>
    </Layout>
  );
};

type ItemProps = {
  title?: string;
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ItemLayout = styled.button`
  position: absolute;
  background: ${INDIGO_6};
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  box-shadow: 0 0.1rem 1rem ${INDIGO_4};
  transition: all 0.2s ease;
  opacity: 0;

  &:hover {
    background-color: ${INDIGO_5};
  }
`;

MultiFloatingButton.Item = ({ title, children, onClick }: ItemProps) => (
  <ItemLayout title={title} className="floating-button-item" onClick={onClick}>
    {children}
  </ItemLayout>
);
