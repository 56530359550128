import { Permission } from "models/Permission";
import { AppStore } from "App/Store";

export const allowRoleCreate = (store: AppStore) => {
  const { allowTables } = store.UserToken;
  for (const allowTable of allowTables) {
    const permission = new Permission(allowTable.allow.split(""));
    switch (allowTable.table) {
      case "role":
        if (
          !permission.isUserReadable() ||
          !permission.isUserEditable() ||
          !permission.isGroupReadable() ||
          !permission.isGroupEditable() ||
          !permission.isOtherWritable()
        ) {
          return false;
        }
        break;
      case "role_title":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      default:
        break;
    }
  }
  return true;
};
