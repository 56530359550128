/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { MetadataRightType } from "constants/MetadataRightType";

type Props = Readonly<{
  value?: MetadataRightType;
  isNullable?: boolean;
}>;

const items = [MetadataRightType.production, MetadataRightType.publication, MetadataRightType.rightCompany];

export const MetadataRightScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="MetadataRightType" value={value} isNullable={isNullable} items={items} />
);
