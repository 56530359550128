import { createReducer, createHandler, createActions } from "lib/store";

type State = ReturnType<typeof createInitialState>;

const SET_WORK_SEARCH_COLUMN = Symbol("Work::SearchColumn::Set");

export const AppWorkActions = createActions({
  setWorkSearchColumn(column: string) {
    return { type: SET_WORK_SEARCH_COLUMN, column };
  }
});

const handler = createHandler<State>({
  [SET_WORK_SEARCH_COLUMN](state, payload: { column: string }) {
    state.column = payload.column;
  }
});

const createInitialState = () => ({
  column: "타이틀" as string
});

export const reducer = createReducer(handler, createInitialState);
