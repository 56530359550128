import { ValidType } from "constants/ValidType";
import dayjs from "dayjs";
import { Metadata } from "GraphQL/Queries/Playlist/GetPlaylistCsvData";
import { configs } from "configs";
import { DATA_VOUCHER_KIND_LIST, ORIGINAL_CSV_KIND_LIST } from "constants/PlaylistCompanyList";

const toLicenseFormat = (license: any) => {
  try {
    for (const track of license) {
      return `${JSON.parse(track.notice).text}${track.licenseDate ? `\n(updated: ${track.licenseDate})` : ""}`;
    }
  } catch {
    return "";
  }
};
type GenreRelation = {
  genre: any[];
  mood: any[];
};
type Props = {
  metadata: Metadata;
  uuid: string;
  playlistId: string;
  playlistName: string;
  playlistKind: string;
  csvIndex: number;
  csvType: string;
};
export const handlePlaylistFormat = ({ metadata, uuid, playlistId, playlistName, playlistKind, csvIndex, csvType }: Props) => {
  const {
    metadataId,
    no,
    title,
    genres,
    artists,
    structures,
    license,
    metadataUrl,
    titleRelation,
    subdataUnique,
    licenseExtra,
    metadata_self_relations_list_metadata,
    track_information
  } = metadata;
  let workSummary = "";
  let trackSummary = "";
  // GENRE
  const genreRelation: GenreRelation = {
    genre: [],
    mood: []
  };
  for (const {
    genre: [{ typeKind, name }]
  } of genres) {
    genreRelation[typeKind as keyof GenreRelation].push(name);
  }
  // ARTIST
  const { composer, performer, instrument } = artists.reduce(
    (result: any, { role: [role], artist: [artist] }: any) => {
      if (!role) {
        return result;
      } else {
        const { typeRole, name } = role;
        switch (typeRole) {
          case "job": {
            if (name === "Composer") {
              result.composer.push(artist.name);
            } else if (name === "perform main") {
              result.performer.push(artist.name);
            }
            break;
          }
          case "instrument": {
            result.instrument.push(name);
            break;
          }
        }
        return result;
      }
    },
    { composer: [], performer: [], instrument: [] }
  );
  // STRUCTURES
  for (const { parent } of structures) {
    for (const { parentMetadata } of parent) {
      if (parentMetadata[0].typeSubclass !== "album") {
        workSummary = parentMetadata[0].title;
        trackSummary = title;
      } else {
        workSummary = title;
      }
    }
  }

  if (DATA_VOUCHER_KIND_LIST.includes(playlistKind)) {
    const formattedLicense = toLicenseFormat(license);
    const result = {
      "#": csvIndex,
      playlist_id: playlistId,
      name: playlistName,
      id: metadataId,
      title: title,
      work_summary: workSummary,
      track_summary: trackSummary,
      composer: composer.join(", "),
      performer: performer.length ? performer[0] : composer[0],
      license: formattedLicense
    };
    return result;
  } else if (ORIGINAL_CSV_KIND_LIST.includes(playlistKind)) {
    const originalArtist = artists.filter(({ role }) => role[0]?.name === "Original")[0]?.artist[0]?.name ?? "-";
    const extraData = track_information[0]?.extraData ?? "-";
    const tempo = track_information[0]?.tempo;
    const bpm = !tempo ? "-" : `${tempo} BPM`;
    const genreRelation: GenreRelation = {
      genre: [],
      mood: []
    };
    for (const {
      genre: [{ typeKind, name }]
    } of genres) {
      genreRelation[typeKind as keyof GenreRelation].push(name);
    }
    const result = {
      순서: csvIndex,
      제목: title,
      오리지널: originalArtist,
      가수: performer.join(", "),
      장르: genreRelation.genre.join(", "),
      BPM: bpm,
      기타정보: extraData
    };
    return result;
  } else if (csvType === "default") {
    // LICENSE
    const formattedLicense = toLicenseFormat(license);
    const result = {
      "#": csvIndex,
      playlist_id: playlistId,
      name: playlistName,
      id: metadataId,
      title: title,
      work_summary: workSummary,
      track_summary: trackSummary,
      genre: genreRelation.genre.join(", "),
      mood: genreRelation.mood.join(", "),
      instrument: instrument.join(", "),
      composer: composer.join(", "),
      performer: performer.length ? performer[0] : composer[0],
      license: formattedLicense
    };
    return result;
  } else if (csvType === "audioClip") {
    const url = !metadataUrl.length ? undefined : metadataUrl.filter(({ typeUrl }) => typeUrl === "mp3high");
    const mp3highPath = !url || !url.length ? "" : `${configs.urls.audio}/${url[0].url}`;
    const { korName } = artists.reduce(
      (result: any, { artist }) => {
        if (!artist[0].artist_history || !artist[0].artist_history.length) {
          return result;
        } else {
          const { artist_title } = artist[0];
          result.korName.push(...artist_title.map(({ value }) => value));
          return result;
        }
      },
      { korName: [] }
    );
    const trackTextKor = !titleRelation || !titleRelation.length ? "" : titleRelation.map(({ name }) => name).join(", ");
    const roles = !artists.length
      ? ""
      : artists
          .filter(({ role }) => role.length)
          .map(({ role }) => role[0].name)
          .join(", ");
    const albumLabel =
      !structures.length || !structures[0].parent.length
        ? ""
        : structures[0].list.filter(({ metadata }) => metadata[0].typeSubclass === "album")[0].metadata[0].title;
    const playTime =
      !subdataUnique || !subdataUnique.length || !JSON.parse(subdataUnique[0].value ?? "[]").duration
        ? ""
        : dayjs(+`${JSON.parse(subdataUnique[0].value).duration}000`).format("mm:ss");
    const birth = !artists[0].artist[0].artist_history.length ? "" : artists[0].artist[0].artist_history[0].birth;
    const death = !artists[0].artist[0].artist_history.length ? "" : artists[0].artist[0].artist_history[0].death;
    const { composer, performs, performMain } = artists.reduce(
      (result: any, { role: [role], artist: [artist] }: any) => {
        if (!role) {
          return result;
        } else {
          const { typeRole, name } = role;
          result.performs.push(artist.name);
          switch (typeRole) {
            case "job": {
              if (name === "Composer") {
                result.composer.push(artist.name);
              } else if (name === "perform main") {
                result.performMain.push(artist.name);
              }
              break;
            }
          }
          return result;
        }
      },
      { composer: [], performs: [], performMain: [] }
    );
    const result = {
      id: uuid,
      mp3high_path: mp3highPath,
      track_id: metadataId,
      play_time: playTime,
      author: composer.join(", "),
      kor_name: korName.join(", "),
      birth,
      death,
      perform_main: performMain.join(", "),
      performs: performs.join(", "),
      roles: roles,
      work_summary: workSummary,
      track_summary: trackSummary,
      track_text_korean: trackTextKor,
      album_label: albumLabel,
      rec_year: !licenseExtra.length ? "" : licenseExtra[0].recordYear,
      publish_year: !licenseExtra.length ? "" : licenseExtra[0].firstEdition // ? 요청사항으로 publish_year -> firstEdition으로 수정함.
    };
    return result;
  } else if (csvType === "kaist") {
    //  CsvType: "Kaist"
    const result = {
      Album: !structures.length || !structures[0].parent.length ? "" : structures[0].parent[0].parentMetadata[0].title,
      Composer: composer.join(", "),
      Performer: performer.join(", "),
      Instrument: instrument.join(", "),
      ID: metadataId,
      Title: title,
      No: no,
      Work: !metadata_self_relations_list_metadata.length
        ? ""
        : metadata_self_relations_list_metadata[0].metadata_self_relations_element_metadata[0].title,
      Genre: genres
        .filter(({ genre }) => genre[0].typeKind === "genre")
        .map(genre => genre.genre[0].name)
        .join(", "),
      Mood: genres
        .filter(({ genre }) => genre[0].typeKind === "mood")
        .map(genre => genre.genre[0].name)
        .join(", "),
      RecordYear: !licenseExtra.length ? "" : licenseExtra[0].recordYear,
      PublishYear: !licenseExtra.length ? "" : licenseExtra[0].publishYear,
      FirstEdition: !licenseExtra.length ? "" : licenseExtra[0].firstEdition,
      Place: !licenseExtra.length ? "" : licenseExtra[0].place
    };
    return result;
  } else {
    const ytv_license =
      !license.length || license[0].validCheck === ValidType.V3
        ? "미확인"
        : license[0].validCheck === ValidType.DONE
        ? "사용 가능"
        : "만료";
    const formattedLicense = toLicenseFormat(license);
    const result = {
      playlist_name: playlistName,
      playlist_id: playlistId,
      artist: performer.length ? performer[0] : "",
      track_id: metadataId,
      title: title,
      license: formattedLicense,
      ytv_license
    };
    return result;
  }
};
