import React from "react";
import styled from "styled-components";
import { CardItem } from "./CardItem";
import { useGoodsSelector } from "../../Store";

const Layout = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 2em;
  padding-bottom: 0;
`;

export const CardList = () => {
  const goods = useGoodsSelector(store => store.Goods.goods);
  return (
    <Layout>
      {goods.map(data => (
        <CardItem key={data.id.key} data={data} />
      ))}
    </Layout>
  );
};
