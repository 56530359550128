/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  prevId: string;
  nextId: string;
}>;

export const UpdateMetadataArtistRelation = async (option: Option) => {
  const $prevId = <Variable name="prev_id" scalar={<IdScalar />} value={option.prevId} />;
  const $nextId = <Variable name="next_id" scalar={<IdScalar />} value={option.nextId} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST">
      <SelectionSet
        name="updateMetadata_artist_relations"
        alias="updateArtist"
        argument={{
          where: {
            artist_id: $prevId
          },
          data: {
            artist: {
              connect: {
                artist_id: $nextId
              }
            }
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateArtist: {
      id: string;
    }[];
  }>;

  return await clients.artist.request<Data>(query, variables);
};
