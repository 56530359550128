/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  field: string;
  category: string;
  value: string;
}>;

export const UpdateGoodsSubdata = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar isNullable={false} />} value={option.uuid} />;
  const $field = <Variable name="field" scalar={<StringScalar isNullable />} value={option.field} />;
  const $category = <Variable name="category" scalar={<StringScalar isNullable />} value={option.category} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable />} value={option.value} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS_SUBDATA">
      <SelectionSet
        name="updateGoods_subdata_unique"
        alias="updateGoodsSubdata"
        argument={{
          where: { id: $id },
          data: {
            type_subdata_field: $field,
            type_subdata_category: $category,
            value: $value
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoodsSubdata: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
