import axios from "axios";
import { createFormData } from "lib/createFormData";
import { UploadFile } from "GraphQL/Queries";

export const handleUploadFile = async (filename: string, companyId: string, file: Blob, contentType: string) => {
  try {
    const { data: uploadData, errors: uploadErr } = await UploadFile({ filename, companyId });
    if (uploadErr || !uploadData) {
      throw uploadErr;
    }
    await axios({
      method: "post",
      url: uploadData.uploadFile.url,
      data: createFormData(uploadData.uploadFile, file),
      headers: {
        "Content-Type": contentType
      }
    });
    return uploadData.uploadFile;
  } catch (err) {
    throw err;
  }
};
