import React, { ReactNode, Reducer, useEffect, useLayoutEffect, useState } from "react";
import { Action, ActionList } from "./combineReducers";
import { Unsubscriber } from "./createInitialStore";
import { createInitialStore } from "./createInitialStore";
import { fastDeepEqual } from "./fast-deep-equal";

/**
 * React Context에 코드 레벨의 컨벤션 요소를 추가하기 위해 작성함
 * react-redux의 영향을 받았으며, 순수한 react-redux와는 몇몇 다른 부분이 아래와 같이 있음
 * - 여러 store 생성이 가능함
 * - dispatch 함수를 직접 받을 수 있음
 * - Custom Hooks로만 사용할 수 있도록 작성을 했기 때문에 React Class Component에서는 사용할 수 없음
 *
 * const { useSelector, initialize, dispatch } = createStore(reducer);
 */
export const createStore = <Store, Actions extends Action<any> | ActionList<any>>(reducer: Reducer<Store, Actions>) => {
  const store = createInitialStore(reducer);

  type Select<State> = (store: Store) => State;
  type Equal<State> = (previousState: State, nextState: State) => boolean;

  /**
   * 값을 가져올 때에 사용하기 위한 유틸리티성 Custom Hooks
   *
   * const userName = useSelector(store => store.User.name);
   */
  function useSelector<State>(select: Select<State>, equal: Equal<State> = fastDeepEqual): State {
    const [current, setCurrent] = useState(() => select(store.getState()));
    const reRenderIfChanges = (): Unsubscriber =>
      store.subscribe(state => {
        const next = select(state);
        if (!equal(current, next)) {
          setCurrent(next);
        }
      });
    useLayoutEffect(reRenderIfChanges, [current, store]);
    return current;
  }

  type Props = {
    readonly children: ReactNode;
  };

  const Provider = ({ children }: Props) => {
    useEffect(() => {
      return () => {
        store.clear();
      };
    }, []);
    return <>{children}</>;
  };

  return { useSelector, Provider, ...store };
};
