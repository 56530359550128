import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_X_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { usePlaylistDetailStore } from "App/Routes/PlaylistDetail/Store";
import { PlaylistDetailActions, PlaylistRelation } from "App/Routes/PlaylistDetail/Store/PlaylistDetail";
import { MetadataPlaylistRelation } from "GraphQL/Queries/Playlist";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { GRAY_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { Loading } from "App/Atomics/Loading";
import { ValidType } from "constants/ValidType";
import { ValidTypeTagSelect } from "App/Molecules/Selects/ValidType";

type Props = Readonly<{
  playlistId: string;
  downloadLicenseData: (relations: PlaylistRelation[]) => void;
  toClose: () => void;
}>;

export const SelectCsvTypeModal = ({ playlistId, downloadLicenseData, toClose }: Props) => {
  const [{ csvType, order }, dispatch] = usePlaylistDetailStore(store => ({
    csvType: store.PlaylistDetail.csvType,
    order: store.PlaylistDetail.order
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [validCheck, setValidCheck] = useState<ValidType[] | undefined>(undefined);
  const userRole = useAppSelector(store => store.UserToken.role);
  const handleDownload = async () => {
    if (csvType === "undefined") {
      window.alert("타입을 선택해주세요.");
      return;
    }
    try {
      const count = 30;
      const csvPlaylistRelation: PlaylistRelation[] = [];
      setLoading(true);
      const { data } = await MetadataPlaylistRelation({
        playlistId,
        count,
        order,
        page: 1,
        csvType,
        validCheck: validCheck?.length ? validCheck : undefined
      });
      if (data) {
        csvPlaylistRelation.push(...data.metadataPlaylistRelation);
        if (data.edge.hasNextPage) {
          for (let currentPage = 2; currentPage <= data.edge.pageCount; currentPage += 1) {
            const { data: nextData } = await MetadataPlaylistRelation({
              playlistId,
              count,
              order,
              page: currentPage,
              csvType,
              validCheck: validCheck?.length ? validCheck : undefined
            });
            if (nextData) {
              csvPlaylistRelation.push(...nextData.metadataPlaylistRelation);
            }
          }
        }
        downloadLicenseData(csvPlaylistRelation);
      }
    } catch (err) {
      console.log(err);
      window.alert("CSV 다운로드에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };

  const CsvTitleOptionList = [
    { id: "default", name: "기본" },
    { id: "audioClip", name: "오디오클립" },
    { id: "audioClip-cn", name: "오디오클립 (중국어)" },
    { id: "kaist", name: "카이스트" },
    { id: "youtube", name: "유튜브 라이센스 체크" },
    { id: "taskManage", name: "작업관리" }
  ];

  return (
    <Layout>
      <Header>
        <h3>CSV 다운로드 </h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <section>
        <Title>
          <h4>다운로드 할 CSV의 타입을 선택해주세요.</h4>
        </Title>
        <CsvSelect
          optionList={userRole === UserRole.Partner ? [CsvTitleOptionList[0]] : CsvTitleOptionList}
          defaultValue={CsvTitleOptionList.find(({ id }) => id === csvType)}
          onChange={info => {
            if (info) {
              dispatch(PlaylistDetailActions.setPlaylistDetailCsvType(info.id));
            }
          }}
        />
        <div className="vc-select">
          <Title>
            <h4>유효성 필터</h4>
          </Title>
          <ValidTypeTagSelect
            isDisabled={userRole !== UserRole.Master && userRole !== UserRole.Manager}
            placeholder="기본 값은 전체입니다."
            onChange={vc => {
              if (vc) {
                setValidCheck(vc);
              }
            }}
          />
        </div>
        <ButtonGroup>
          <Input.Button color="success" onClick={handleDownload}>
            다운로드
          </Input.Button>
          <Input.Button color="danger" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </section>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  width: ${pixelize(UNIT * 32)};
  height: ${pixelize(UNIT * 22)};
  section {
    padding: 1.5rem 2rem 0.5rem;
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
  .vc-select {
    margin-bottom: 4rem;
    span {
      margin-bottom: 0.1rem;
    }
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background: mediumseagreen;

  color: #fff;
  padding: 1.3rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};
  color: #191919;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

const CsvSelect = styled(Input.TextSelect)`
  width: 80%;
  margin-bottom: 1rem;
`;

const ButtonGroup = styled(Input.Group)`
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }
`;
