import { Parser } from "json2csv";
import fileSaver from "file-saver";
import { handlePlaylistFormat } from "./handlePlaylistFormat";
import { PlaylistCsvData } from "GraphQL/Queries/Playlist/GetPlaylistCsvData";
import dayjs from "dayjs";
import { DATA_VOUCHER_KIND_LIST, ORIGINAL_CSV_KIND_LIST } from "constants/PlaylistCompanyList";

export const handleDownloadCsv = async (playlistCsvData: PlaylistCsvData[], csvType: string, userEmail?: string, userName?: string) => {
  try {
    const data = [];
    const playlistKind = playlistCsvData[0]?.kind;
    let parser;
    let csvIndex = 0;
    for (const { id, kind, name, metadataPlaylistRelation } of playlistCsvData) {
      for (const { uuid, metadata } of metadataPlaylistRelation) {
        csvIndex += 1;
        const result = handlePlaylistFormat({
          metadata: metadata[0],
          uuid,
          playlistId: id,
          playlistName: name,
          playlistKind: kind,
          csvIndex,
          csvType
        });
        data.push(result);
      }
    }
    if (!data.length) {
      return;
    }
    if (DATA_VOUCHER_KIND_LIST.includes(playlistKind)) {
      parser = new Parser({
        fields: ["#", "playlist_id", "name", "id", "title", "work_summary", "track_summary", "composer", "performer", "license"]
      });
    } else if (ORIGINAL_CSV_KIND_LIST.includes(playlistKind)) {
      parser = new Parser({
        fields: ["순서", "제목", "오리지널", "가수", "장르", "BPM", "기타정보"]
      });
    } else if (csvType === "default") {
      parser = new Parser({
        fields: [
          "#",
          "playlist_id",
          "name",
          "id",
          "title",
          "work_summary",
          "track_summary",
          "genre",
          "mood",
          "instrument",
          "composer",
          "performer",
          "license"
        ]
      });
    } else if (csvType === "audioClip") {
      parser = new Parser({
        fields: [
          "mp3high_path",
          "track_id",
          "play_time",
          "author",
          "kor_name",
          "birth",
          "death",
          "perform_main",
          "performs",
          "roles",
          "work_summary",
          "track_summary",
          "track_text_korean",
          "album_label",
          "rec_year",
          "publish_year"
        ]
      });
    } else if (csvType === "kaist") {
      parser = new Parser({
        fields: [
          "Album",
          "Composer",
          "Performer",
          "Instrument",
          "ID",
          "Title",
          "No",
          "Work",
          "Genre",
          "Mood",
          "RecordYear",
          "PublishYear",
          "FirstEdition",
          "Place"
        ]
      });
    } else {
      parser = new Parser({
        fields: ["playlist_name", "playlist_id", "name", "artist", "track_id", "title", "license", "ytv_license"]
      });
    }
    const file = new Blob(["\ufeff", parser.parse(data)], { type: "text/csv;charset=utf-8;" });
    const extraFileName = csvType === "default" ? "" : csvType === "audioClip" ? "_audioclip" : csvType === "kaist" ? "_kaist" : "_youtube";
    const date = dayjs(new Date()).format("YYYY_MM_DD");
    const fileName =
      userEmail === "secreticon@artistscard.com" ? `${userName}_최종_메타데이터_${date}.csv` : `playlist_${date}${extraFileName}.csv`;
    fileSaver.saveAs(file, fileName);
    return;
  } catch (e) {
    console.log(e);
    return;
  }
};
