import React, { createContext, ReactNode, useContext, useLayoutEffect, useMemo, useState, useEffect } from "react";
import { createPortal } from "react-dom";

import "./style.css";

import { KeyInfo } from "lib/key-info";
import { Layout } from "./Layout";

type Config = Readonly<{
  isPersist?: boolean;
}>;

type Handler = Readonly<{
  open: (children: ReactNode, config?: Config) => void;
  close: () => void;
  closeAll: () => void;
}>;

type Context = Handler;

type Props = Readonly<{ children: ReactNode }>;

const modalRootElement = window.document.createElement("div");
modalRootElement.id = "Modal";
window.document.body.appendChild(modalRootElement);

const Context = createContext<Context>((null as unknown) as Context);

export const useModal = () => useContext(Context);

const useModalEscapeable = (isPersist: undefined | boolean, close: () => void) => {
  const switchToEscapeable = () => {
    if (isPersist === undefined || isPersist) {
      return;
    }
    const closeOnEscape = (event: KeyboardEvent) => {
      const keyinfo = KeyInfo.from(event);
      if (keyinfo.isEscape) {
        close();
      }
    };
    const DISABLE_SCROLL = "disable-scroll";
    window.document.documentElement.classList.add(DISABLE_SCROLL);
    window.addEventListener("keydown", closeOnEscape);
    return () => {
      window.document.documentElement.classList.remove(DISABLE_SCROLL);
      window.removeEventListener("keydown", closeOnEscape);
    };
  };
  useLayoutEffect(switchToEscapeable, [isPersist]);
};

export const ModalProvider = ({ children }: Props) => {
  const [modalList, setModalList] = useState<readonly ReactNode[]>([]);
  const [configList, setConfigList] = useState<readonly Config[]>([]);
  const [config = {}] = configList;

  const createHandler = (): Context => {
    const open: Handler["open"] = (children, config = {}) => {
      setModalList(modalList => {
        const key = modalList.length - 1;
        const closeModal = () => {
          if (!config.isPersist) {
            close();
          }
        };
        return modalList.concat(
          <Layout key={key} isOpen={true} close={closeModal}>
            {children}
          </Layout>
        );
      });
      setConfigList(configList => configList.concat(config));
    };

    const close = () => {
      setModalList(modalList => modalList.slice(0, -1));
      setConfigList(configList => configList.slice(0, -1));
    };

    const closeAll = () => {
      setModalList([]);
      setConfigList([]);
    };

    return { open, close, closeAll };
  };
  const handler = useMemo(createHandler, [configList]);

  useModalEscapeable(config.isPersist, handler.close);

  const context = handler;
  return (
    <>
      <Context.Provider value={context}>{children}</Context.Provider>
      {createPortal(modalList, modalRootElement)}
    </>
  );
};

type ModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
};

let count = 0;

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const appendModal = () => {
    if (!isOpen) {
      return;
    }
    count += 1;
    const DISABLE_SCROLL = "disable-scroll";
    if (!window.document.documentElement.classList.contains(DISABLE_SCROLL)) {
      // window.document.body.style.top = `-${window.scrollY}px`;
      window.document.documentElement.classList.add(DISABLE_SCROLL);
    }
    return () => {
      count += -1;
      if (!count) {
        window.document.documentElement.classList.remove(DISABLE_SCROLL);
        const scrollY = document.body.style.top;
        window.document.body.style.position = "";
        window.document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    };
  };
  useEffect(appendModal, [isOpen]);
  return createPortal(
    <Layout isOpen={isOpen} close={onClose}>
      {children}
    </Layout>,
    modalRootElement
  );
};
