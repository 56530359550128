import { Edge } from "models/Edge";
import { Genre } from "GraphQL/Queries/Genre/GetGenre";
import { createDuck } from "lib/store/v2";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
import { Title } from "GraphQL/Queries/Genre/GetGenreTitleRelation";
import { ValidType } from "constants/ValidType";

type EditInfo = {
  uuid: string;
  index: number;
};
const createInitialState = () => ({
  genres: [] as Genre[],
  origin: [] as Genre[],
  edge: null as null | Edge,
  accessIds: new Map<string, string | undefined>(),
  editMode: new Map<string, boolean>(),
  titleRelation: [] as Title[],
  editInfo: {} as EditInfo
});

export const GenreActions = createDuck({
  namespace: "Genre",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setGenre(state, genres: Genre[]) {
      state.genres = genres;
      state.origin = genres;
    },
    setGenreSave(state) {
      state.origin = state.genres;
    },
    setGenreClear(state) {
      state.genres = state.origin;
    },
    setGenreAccessId(state, { genreId, accessId }: { genreId: string; accessId: string | undefined }) {
      state.accessIds.set(genreId, accessId);
    },
    setGenreEditMode(state, { genreId, mode }: { genreId: string; mode: boolean }) {
      if (mode) {
        state.editMode.set(genreId, mode);
      } else {
        state.editMode.delete(genreId);
      }
    },
    updateGenreName(state, { index, name }: { index: number; name: string }) {
      state.genres[index].name = name;
    },
    updateGenreDetail(state, { index, detail }: { index: number; detail: string }) {
      state.genres[index].detail = detail;
    },
    updateGenreValidCheck(state, { index, validCheck }: { index: number; validCheck: ValidType }) {
      state.genres[index].validCheck = validCheck;
    },
    createGenreImage(
      state,
      { index, uuid, typeUrl, url, order }: { index: number; uuid: string; typeUrl: string; url: string; order: number }
    ) {
      state.genres[index].genreUrl.push({ uuid, typeUrl, url, validCheck: "V3", order });
    },
    updateGenreImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.genres[index].genreUrl.findIndex(image => image.uuid === uuid);
      state.genres[index].genreUrl[urlIndex].url = url;
      state.genres[index].genreUrl[urlIndex].typeUrl = typeUrl;
    },
    deleteGenreImage(state, { index, uuid }: { index: number; uuid: string }) {
      const imageIndex = state.genres[index].genreUrl.findIndex(image => image.uuid === uuid);
      state.genres[index].genreUrl.splice(imageIndex, 1);
    },
    setGenreTitleRelation(state, titleRelation: Title[]) {
      state.titleRelation = titleRelation;
    },
    setGenreTitleRelationEditInfo(state, { uuid, index }: { uuid: string; index: number }) {
      state.editInfo.uuid = uuid;
      state.editInfo.index = index;
    },
    createGenreTitleRelation(
      state,
      { id, typeTitle, value, languageCode, order }: { id: string; typeTitle: string; value: string; languageCode: string; order: number }
    ) {
      const name = LanguageCodeInfo.find(({ id }) => id === languageCode)?.name ?? "-";
      state.titleRelation.unshift({ id, typeTitle, value, language: [{ languageCode, name }], order });
    },
    updateGenreTitleRelation(
      state,
      { index, id, typeTitle, value, languageCode }: { index: number; id: string; typeTitle: string; value: string; languageCode: string }
    ) {
      state.titleRelation[index].typeTitle = typeTitle;
      state.titleRelation[index].value = value;
      state.titleRelation[index].language[0].languageCode = languageCode;
    },
    deleteGenreTitleRelation(state, index: number) {
      state.titleRelation.splice(index, 1);
    },
    deleteGenre(state, index: number) {
      state.genres.splice(index, 1);
    }
  }
});
