import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as LogoIcon } from "assets/icons/logo.svg";
import { pixelize, UNIT, HEADER_HEIGHT_PX } from "constants/size";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { mediaQuery } from "constants/media";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetCountMetadata } from "./services/GetCountMetadata";
import { GetCountArtist } from "./services/GetCountArtist";
import { PRIMARY_COLOR } from "constants/color";
import { useAppSelector } from "App/Store";

type CountState = {
  goods?: number;
  metadata?: number;
  work?: number;
  podcast?: number;
  artist?: number;
};

export const Home = () => {
  const isLogin = useAppSelector(store => !!store.UserToken.email);
  const [count, setCount] = useState<CountState>({ metadata: undefined, work: undefined, podcast: undefined, artist: undefined });
  const { goods, metadata, work, podcast, artist } = count;

  useAsyncEffect(async () => {
    if (isLogin) {
      const { goods, metadata, work, podcast } = await GetCountMetadata();
      const { artist } = await GetCountArtist();
      setCount(() => ({ goods, metadata, work, podcast, artist }));
    }
  }, []);

  return (
    <ResponsiveToSideNavigatorTemplate>
      <Layout>
        <LogoIcon />
        <h2>DMS</h2>
        <h4>Data Management System</h4>
        {isLogin && (
          <CountBox>
            <CountItem>
              <span className="title">굿즈</span>
              <span className="count">{goods ? new Intl.NumberFormat().format(goods) : "계산 중..."}</span>
            </CountItem>
            <CountItem>
              <span className="title">앨범(트랙)</span>
              <span className="count">{metadata ? new Intl.NumberFormat().format(metadata) : "계산 중..."}</span>
            </CountItem>
            <CountItem>
              <span className="title">작품</span>
              <span className="count">{work ? new Intl.NumberFormat().format(work) : "계산 중..."}</span>
            </CountItem>
            <CountItem>
              <span className="title">리플레이</span>
              <span className="count">{podcast ? new Intl.NumberFormat().format(podcast) : "계산 중..."}</span>
            </CountItem>
            <CountItem>
              <span className="title">아티스트</span>
              <span className="count">{artist ? new Intl.NumberFormat().format(artist) : "계산 중 .."}</span>
            </CountItem>
          </CountBox>
        )}
      </Layout>
    </ResponsiveToSideNavigatorTemplate>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
  svg {
    width: 10em;
    height: 10em;
    object-fit: contain;
    margin-bottom: ${pixelize(3 * UNIT)};

    ${mediaQuery(768)} {
      width: 8em;
      height: 8em;
    }

    ${mediaQuery(375)} {
      width: 6em;
      height: 6em;
    }
  }
  h2 {
    font-family: Rubik;
    margin-bottom: ${pixelize(2 * UNIT)};
    text-align: center;
    font-size: 3.5rem;
    line-height: 0;
    color: #4c52bc;
    font-weight: bold;

    ${mediaQuery(768)} {
      font-size: 3rem;
    }

    ${mediaQuery(375)} {
      font-size: 2.5rem;
    }
  }

  h4 {
    margin-bottom: ${pixelize(4 * UNIT)};
    font-family: Rubik;
    font-size: 2.2rem;
    font-weight: 300;

    ${mediaQuery(768)} {
      font-size: 1.8rem;
    }

    ${mediaQuery(375)} {
      font-size: 1.5rem;
    }
  }
`;

const CountBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  border-left: 1px solid ${PRIMARY_COLOR};
  border-bottom: 1px solid ${PRIMARY_COLOR};
`;

const CountItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${PRIMARY_COLOR};
  .title {
    width: 100%;
    padding: 0.8rem 1.6rem;
    background-color: ${PRIMARY_COLOR};
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    ${mediaQuery(480)} {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }
  }
  .count {
    padding: 0.8rem;
    font-weight: bold;
    ${mediaQuery(480)} {
      font-size: 0.7rem;
      padding: 0.5rem;
    }
  }
`;
