import { AfterDispatch } from "lib/store/combineReducers";
import { Middleware } from "./createAfterDispatch";
import { identity } from "utils/identity";

type MiddlewareList<S> = readonly Middleware<S>[];

export const combineAfterDispatches = <S>(middlewareList: MiddlewareList<S>): AfterDispatch<S> => (
  previousStore,
  nextStore,
  action,
  isChanged
) => {
  const [middleware, ...nextMiddlewareList] = middlewareList;
  if (middleware) {
    const next = nextMiddlewareList.length ? () => combineAfterDispatches(nextMiddlewareList) : identity;
    middleware(previousStore, nextStore, action, isChanged, next);
  }
};
