export const HOME = "/";
export const ERROR = "/error";
export const SIGN = "/sign";
export const SIGN_UP = "/signup";
export const FORGET_PASSWORD = "/forget-password";
export const PROFILE = "/profile";
export const CHANGE_PASSWORD = "/change-password";

// ArtistCard Path
export const SINGLE_ALBUM_CREATE = "/album/create";

// Admin Path
export const SETTLEMENT = "/admin/settle";
export const SETTLEMENT_MONTHLY = "/admin/settle/monthly";
export const SETTLEMENT_DETAIL = "/admin/settle/detail";
export const ARTISTS = "/admin/artists";
export const ARTISTS_RENEWAL = "/admin/artists-renewal";
export const ARTISTS_EDIT = "/admin/artists/edit";
export const ARTISTS_CREATE = "/admin/artists/create";
export const TRACKS = "/admin/tracks";
export const TRACKS_EDIT = "/admin/tracks/edit";
export const ALBUMS = "/admin/albums";
export const ALBUM_DETAIL = "/admin/album/detail";
export const ALBUM_CREATE = "/admin/album/create";
export const ALBUM_RENEWAL_CREATE = "/admin/album-renewal/create";
export const ALBUM_EDIT = "/admin/album/edit";
export const GOODS = "/admin/goods";
export const GOODS_CREATE = `${GOODS}/create`;
export const GOODS_DETAIL = `${GOODS}/:id`;
export const PRODUCT = "/admin/product";
export const PRODUCT_CREATE = `${PRODUCT}/create`;
export const PRODUCT_DETAIL = `${PRODUCT}/:id`;
export const ROLES = "/admin/roles";
export const ROLES_EDIT = "/admin/roles/edit";
export const ROLES_CREATE = "/admin/roles/create";
export const TASK_GRANT = "/admin/task/grant";
export const GENRES = "/admin/genres";
export const GENRES_EDIT = "/admin/genres/edit";
export const GENRES_CREATE = "/admin/genres/create";
export const MOODS = "/admin/moods";
export const MOODS_EDIT = "/admin/moods/edit";
export const WORKS = "/admin/works";
export const WORKS_EDIT = "/admin/works/edit";
export const WORKS_TRACK = "/admin/works/track";
export const WORKS_TRACK_EDIT = "/admin/works/track/edit";
export const WORKS_CREATE = "/admin/works/create";
export const WORKS_RENEWAL = "/admin/works-renewal";
export const WORKS_DETAIL = "/admin/works-renewal/detail";
export const PLAYLIST = "/admin/playlist";
export const PLAYLIST_DETAIL = "/admin/playlist/detail";
export const PLAYLIST_CREATE = "/admin/playlist/create";
export const RIGHTS = "/admin/rights";
export const RIGHTS_CREATE = "/admin/rights/create";
export const EFFECTS = "/admin/effects";
export const EFFECTS_CREATE = "/admin/effects/create";
export const EFFECTS_EDIT = "/admin/effects/edit";
export const VIDEO_CREATE = "/admin/video/create";
export const AUTH_GRANT = "/admin/auth/grant";
export const SEARCH = "/admin/search";
export const ADMIN_SIGUNUP = "/admin/signup";
export const ADMIN_RESET_PASSWORD = "/admin/reset-password";
export const CSV_UPLOAD = "/admin/csv-upload";
export const LIVE_REPLAY = "/admin/live-replay";
export const LANGUAGE = "/admin/language";
export const CREATE_AUTO_PLAYLIST = "/admin/playlist/auto-create";
