/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, StringScalar, Mutation } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  name: string;
  nickname: string;
  email: string;
  password: string;
  channel?: string;
}>;

export const CreateUser = async (option: Option) => {
  const name = <Variable name="name" scalar={<StringScalar />} value={option.name} />;
  const nickname = <Variable name="nickname" scalar={<StringScalar />} value={option.nickname} />;
  const email = <Variable name="email" scalar={<StringScalar isNullable={false} />} value={option.email} />;
  const password = <Variable name="password" scalar={<StringScalar isNullable={false} />} value={option.password} />;
  const channel = <Variable name="channel" scalar={<StringScalar />} value={option.channel} />;
  const { query, variables } = render(
    <Mutation operationName="ACCESS_CREATE_USER">
      <SelectionSet name="createUser" alias="createUser" argument={{ data: { name, email, password, nickname, channel } }}>
        <Field name="users_id" alias="id" />
        <Field name="name" />
        <Field name="email" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createUser: Readonly<{
      id: string;
      name: string;
      email: string;
    }>;
  }>;
  return await clients.access.request<Data>(query, variables);
};
