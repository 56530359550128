import React from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Language } from "GraphQL/Queries/Language/GetLanguage";
import { Input } from "App/Atomics/Input";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { Modal } from "lib/modal";
import { LocaleModal } from "../../Modals";
import { useToggle } from "lib/use-toggle";
import { useLanguageDispatch } from "../../Store";
import { LanguageActions } from "../../Store/Language";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteLanguage } from "GraphQL/Queries/Language";
import { DeleteAccessRecord } from "GraphQL/Queries";

export const LanguageItem = ({ index, data }: ColumnProps<Language>) => {
  const dispatch = useLanguageDispatch();
  const userRole = useAppSelector(store => store.UserToken.role);
  const localeModal = useToggle();
  const { languageCode, name, validCheck } = data;

  const onDeleteLanguage = async (index: number, targetCode: string) => {
    if (window.confirm("삭제하시겠습니까?")) {
      const accessId = await requestAccessRecord({ targetId: targetCode, targetTable: TargetTableInput.Language });
      if (accessId) {
        try {
          await DeleteLanguage({ languageCode: targetCode });
          dispatch(LanguageActions.deleteLanguage(index));
          Toast.success("삭제되었습니다.");
        } catch (err) {
          console.log(err);
          Toast.error("삭제에 실패하였습니다.");
        }
        await DeleteAccessRecord({ id: accessId });
      }
    }
  };
  return (
    <Table.Row>
      <TableData width="10%">{languageCode}</TableData>
      <Table.Data>{name}</Table.Data>
      <TableData width="5%">{validCheck}</TableData>
      <TableData width="5%">
        <Input.Button color="primary" isWide disabled={userRole !== UserRole.Master} onClick={localeModal.on}>
          VIEW
        </Input.Button>
      </TableData>
      <TableData width="5%">
        <Input.Button color="danger" isWide disabled={userRole !== UserRole.Master} onClick={() => onDeleteLanguage(index, languageCode)}>
          삭제
        </Input.Button>
      </TableData>
      <Modal isOpen={localeModal.isToggled} onClose={localeModal.off}>
        <LocaleModal index={index} code={languageCode} name={name} onClose={localeModal.off} />
      </Modal>
    </Table.Row>
  );
};

const TableData = styled(Table.Data)<{ width?: string }>`
  width: ${props => (!props.width ? "initial" : props.width)};
  text-align: center;
`;
