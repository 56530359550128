import React from "react";

import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findWorkAlbumList } from "./findWorkAlbumList";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  | "style"
  | "className"
  | "classNamePrefix"
  | "isDisabled"
  | "defaultValue"
  | "onChange"
  | "onBlur"
  | "autoFocus"
  | "filterOption"
  | "placeholder"
>;

export const WorkAlbumTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  autoFocus,
  filterOption,
  placeholder = "대작품의 제목을 입력해주세요."
}: Props) => (
  <TextAutoComplete
    placeholder={placeholder}
    isDisabled={isDisabled}
    defaultValue={defaultValue}
    delay={500}
    style={style}
    className={className}
    classNamePrefix={classNamePrefix}
    filterOption={filterOption}
    onChange={onChange}
    onBlur={onBlur}
    autoFocus={autoFocus}
    loadInfoList={findWorkAlbumList}
  />
);
