import React from "react";
import { FilterItem, inputCustomStyle } from "../../formStyle";
import { MoodTagAutoComplete } from "App/Molecules/AutoCompletes/Mood";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";

export const MoodIncludeFilter = () => {
  const [{ mode, searchList }, dispatch] = useAuthGrantStore(store => ({
    mode: store.IdList.mode,
    searchList: store.IdList.searchList
  }));
  const includeMoodList = searchList.Track.excludeMoodList;
  const onSetMoodList = (info: Info[]) => {
    dispatch(IdActions.setIncludeMoodList({ idType: mode, moodList: info }));
  };
  return (
    <FilterItem>
      <div className="item-label">
        <span>무드 포함 리스트</span>
      </div>
      <div className="item-option-wrapper">
        <MoodTagAutoComplete
          style={inputCustomStyle}
          defaultValue={includeMoodList}
          onBlur={info => info && onSetMoodList(info as Info[])}
        />
      </div>
    </FilterItem>
  );
};
