/** @jsx jsx */

import { GoodsOrderByInput } from "constants/GoodsOrderByInput";
import { jsx, EnumScalar } from "graphql-jsx";

type Props = Readonly<{
  value?: GoodsOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  GoodsOrderByInput.goods_id__ASC,
  GoodsOrderByInput.goods_id__DESC,
  GoodsOrderByInput.name__ASC,
  GoodsOrderByInput.name__DESC,
  GoodsOrderByInput.type_kind__ASC,
  GoodsOrderByInput.type_kind__DESC,
  GoodsOrderByInput.weight__ASC,
  GoodsOrderByInput.weight__DESC,
  GoodsOrderByInput.createdAt__ASC,
  GoodsOrderByInput.createdAt__DESC
];

export const GoodsOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="GoodsOrderByInput" value={value} isNullable={isNullable} items={items} />
);
