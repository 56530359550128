import { Artist } from "GraphQL/Queries/Artist/GetArtist";
import { Metadata } from "GraphQL/Queries/Metadata/GetMetadata";
import { createDuck } from "lib/store";

const createInitialState = () => ({
  album: [] as Metadata[],
  work: [] as Metadata[],
  artist: [] as Artist[]
});

export const SearchActions = createDuck({
  namespace: "Search",
  createInitialState,
  reducers: {
    setSearchAlbum(state, album: Metadata[]) {
      state.album = album;
    },
    setSearchWork(state, work: Metadata[]) {
      state.work = work;
    },
    setSearchArtist(state, artist: Artist[]) {
      state.artist = artist;
    }
  }
});
