import React from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, UNIT } from "constants/size";
import { PRIMARY_COLOR_LIGHT } from "constants/color";
import { GRAY_6 } from "constants/baseColor";

export enum LOADING {
  UNLOAD,
  ONLOAD,
  NULL
}

type Props = {
  loading: LOADING;
  searchString?: string;
};

const opacity = keyframes`
  0%  {opacity: 0};
  20% {opacity: 0};
  50% {opacity: 1};
  100%{opacity: 0};
`;

const spin = keyframes`
  0%  {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: ${pixelize(UNIT * 6)};
  margin-top: 8%;
  justify-content: center;
  align-items: center;
  span {
    color: ${PRIMARY_COLOR_LIGHT};
  }

  .loading-text {
    animation: ${opacity} 1s linear infinite normal;
    font-family: "Helvetica Neue", Helvetica, arial;
    font-size: 0.6rem;
    font-weight: bold;
    color: ${GRAY_6};
    transform: translateX(-53px);
  }
`;

const Loading = styled.div`
  width: ${pixelize(UNIT * 4)};
  height: ${pixelize(UNIT * 4)};
  border: 2px solid transparent;
  border-radius: 50%;
  border-color: ${GRAY_6} transparent transparent;
  animation: ${spin} 0.8s linear infinite normal;
`;

export const LoadingTemplate = ({ loading, searchString }: Props) =>
  loading === LOADING.UNLOAD ? (
    <Layout>
      <Loading />
      <div className="loading-text">loading...</div>
    </Layout>
  ) : loading === LOADING.NULL ? (
    <Layout>
      {!searchString ? (
        "데이터가 없습니다"
      ) : (
        <>
          <span>{searchString}</span>에 대한 검색결과가 없습니다.
        </>
      )}
    </Layout>
  ) : null;
