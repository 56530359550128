import React from "react";
import styled from "styled-components";

import { ArtistActions } from "./Store/Artist";
import { gql } from "lib/gql-tag";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { clients } from "utils/clients";
import { LeftSideBar } from "./LeftSidebar";
import { RightArtistTable } from "./RightArtistTable";
import { ArtistsStoreProvider, useArtistsDispatch, useArtistsSelector } from "./Store";
import { useAppStore } from "App/Store";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import itiriri from "itiriri";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

const subheadDataEvery = (subheadData: Record<string, boolean>) => Object.values(subheadData).every(item => !item);

const ChildLayout = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userRole, dispatchApp] = useAppStore(store => store.UserToken.role);
  const dispatch = useArtistsDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchString = queryParams.get("q", { default: "" });
  const searchType = queryParams.get("type", { default: undefined });
  const hasUrl = queryParams.get("url", { default: undefined });
  const { head, subhead, validCheck, roleCheck, typeCheck } = useArtistsSelector(store => store.ArtistInfo);
  const validList = itiriri(Object.entries(validCheck))
    .filter(([key, isToggledKey]) => isToggledKey)
    .toArray(([key]) => key);
  const roleList =
    roleCheck && roleCheck.length
      ? itiriri(roleCheck)
          .map(value => value.id)
          .toArray(id => id)
      : [];
  const typeList = itiriri(Object.entries(typeCheck))
    .filter(([key, isToggledKey]) => isToggledKey)
    .toArray(([key]) => key);

  useAsyncEffect(
    async isMounted => {
      const artistQuery = `
        ${subhead.Artist.artistId ? `artistId: artist_id` : ""}
        ${subhead.Artist.name ? `name` : ""}
        ${subhead.Artist.typeArtistClass ? `typeArtistClass: type_artist_class` : ""}
        ${subhead.Artist.validCheck ? `validCheck: valid_check` : ""}
        ${subhead.Memo.memo ? `memo` : ""}
      `;

      const artistUrlQuery =
        !head.ArtistUrl || subheadDataEvery(subhead.ArtistUrl)
          ? ""
          : `
        artistUrls: artist_url(
          orderBy: exposure_order__ASC
        ) {
          uuid: id
          validCheck: valid_check
          ${subhead.ArtistUrl.typeUrl ? `typeUrl: type_url` : ""}
          ${subhead.ArtistUrl.url ? `url` : ""}
          order: exposure_order
        }
      `;

      const artistGenreQuery =
        !head.ArtistGenre || subheadDataEvery(subhead.ArtistGenre)
          ? ""
          : `
        genreRelation: artist_genre_relation(
          orderBy: exposure_order__ASC
          where: {
            genre__some: {
              type_kind: "genre"
            }
          }
        ) {
          id
          validCheck: valid_check 
          genre {
            id: genre_id
            name
            type: type_kind
          }
        }
      `;

      const artistHistoryQuery =
        !head.History || subheadDataEvery(subhead.History)
          ? ""
          : `
        history: artist_history {
          ${subhead.History.birthDate ? `birthDate: birth_date` : ""}
          ${subhead.History.birthPlace ? `birthPlace: birth_place` : ""}
          ${subhead.History.deathDate ? `deathDate: death_date` : ""}
          ${subhead.History.deathPlace ? `deathPlace: death_place` : ""}
        }
      `;

      const artistRoleQuery =
        !head.Role || subheadDataEvery(subhead.Role)
          ? ""
          : `
        roleRelation: artist_role_relation {
          exposureOrder: exposure_order
          role {
            ${subhead.Role.id ? `roleId: role_id` : ""}
            ${subhead.Role.role ? `name` : ""}
            ${subhead.Role.type ? `typeRoleClass: type_role_class` : ""}
            ${subhead.Role.count ? `roleCount: role_count` : ""}
          }
        }
      `;

      const artistTrackQuery =
        !head.Track || subheadDataEvery(subhead.Track)
          ? ""
          : `
          metadata_artist_relation(
            first: 99999,
            where:{
              metadata__some:{
                type_metadata_class: "record"
                type_metadata_subclass: "track"
              }
            }
          ) {
            id
          }
        `;
      const artistCompanyQuery = `artist_company_relation {
        id
        company {
          company_users_relation {
            id
            users {
              name
              email
            }
          }
        }
      }`;

      const QUERY = gql` 
        query ARTIST_GET_LIST($page: Int, $validList: [ValidType!]) {
          artists: artist(
            page: $page,
            group: true,
            orderBy: artist_id__DESC,
            first: 20,
            where: {
                valid_check__in: $validList
              ${
                !searchString
                  ? ""
                  : searchType === "이름"
                  ? `artist_title__some: { tsvec_words__search: "${searchString}", type_artist_title__starts_with: "name" }`
                  : `artist_id: "${searchString}"`
              }
              ${
                !roleList.length
                  ? ""
                  : `
                artist_role_relation__some: {
                  role__some: {
                    role_id__in: [${roleList}]
                  }
                }
                `
              }
              ${
                !typeList.length
                  ? ""
                  : `
                  type_artist_class__in: [${typeList.map(type => `"${type}"`)}]
                `
              }
              ${
                !hasUrl
                  ? ""
                  : `artist_url__some: {
                      id__exist: false,
                    }`
              }
            }
          ) {
            ${artistQuery}
            ${artistUrlQuery}
            ${artistGenreQuery}
            ${artistHistoryQuery}
            ${artistRoleQuery}
            ${artistTrackQuery}
            ${artistCompanyQuery}
          }

          edge(
            node: "artist",
            page: $page,
            first: 20,
            where: {
              artist: {
                valid_check__in: $validList
                ${
                  !searchString
                    ? ""
                    : searchType === "이름"
                    ? `artist_title__some: { tsvec_words__search: "${searchString}", type_artist_title__starts_with: "name" }`
                    : `artist_id: "${searchString}"`
                }
                ${
                  !roleList.length
                    ? ""
                    : `
                  artist_role_relation__some: {
                    role__some: {
                      role_id__in: [${roleList}]
                    }
                  }
                  `
                }
                ${
                  !typeList.length
                    ? ""
                    : `
                  type_artist_class__in: [${typeList.map(type => `"${type}"`)}]
                `
                }
              
                ${
                  !hasUrl
                    ? ""
                    : `artist_url__some: {
                      id__exist: false,
                    }`
                }
              }
            }
            ) {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            currentPage
            pageCount
          }
        }
      `;
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      const { data } = await clients.artist.query(QUERY, { page, validList });
      if (!data) {
        return;
      }

      if (isMounted()) {
        dispatch([ArtistActions.setArtist(data.artists), ArtistActions.setEdge(data.edge)]);
        dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
      }
    },
    [page, searchString, head, subhead, validCheck, roleCheck, typeCheck, searchType, hasUrl]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightArtistTable />
    </Layout>
  );
};

export const Artists = () => (
  <ArtistsStoreProvider>
    <Helmet title="아티스트" />
    <ChildLayout />
  </ArtistsStoreProvider>
);
