import { Button } from "./Button";
import { CheckBox } from "./CheckBox";
import { Group } from "./Group";
import { Multiline } from "./Multiline";
import { Number } from "./Number";
// import { Radio } from "./Radio";
import { Range } from "./Range";
// import { Switch } from "./Switch";
import { Text } from "./Text";
import { Password } from "./Password";
import { TextSelect, TagSelect, CreatableTextSelect, CreatableTagSelect } from "./Select";
import { Toggle } from "./Toggle";

export const Input = {
  Button,
  CheckBox,
  Group,
  Multiline,
  Number,
  // Radio,
  Range,
  // Switch,
  Text,
  Password,
  TextSelect,
  TagSelect,
  CreatableTextSelect,
  CreatableTagSelect,
  Toggle
};
