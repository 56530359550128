import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Pagination } from "App/Molecules/Pagination";
import { TableTemplate } from "App/Templates/TableTemplate";
import { ROLES } from "constants/route";
import { HEADER_HEIGHT_PX } from "constants/size";
import { useAppStore } from "App/Store";
import { useRolesStore } from "../Store";
import { useQueryParams } from "lib/use-query-params";
import { DataTemplate } from "./RoleItem";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { UserRole } from "constants/UserRole";
import { RoleActions } from "../Store/Role";
import { FloatingButton } from "App/Atomics/FloatingButton";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { Role } from "GraphQL/Queries/Role/GetRole";
import { SearchForm } from "App/Organisms/SearchForm";
import { Toast } from "lib/toast";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { DeleteRole } from "GraphQL/Queries/Role";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { useToggle } from "lib/use-toggle";
import { Loading } from "App/Atomics/Loading";
import { KeyInfo } from "lib/key-info";
import { Input } from "App/Atomics/Input";
import { FilterModalTemplate } from "App/Templates/FilterModalTemplate";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;

  table {
    margin-top: ${HEADER_HEIGHT_PX};
  }
`;

const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "name", name: "이름" }
];

export const RightRoleTable = () => {
  const router = useHistory();
  const [{ userRole, loading }] = useAppStore(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading
  }));
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const queryParams = useQueryParams();
  const [{ edge, roles, checkList }, dispatch] = useRolesStore(store => ({
    edge: store.Role.edge,
    roles: store.Role.roles,
    checkList: store.Role.checkList
  }));
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const filter = queryParams.get("filter", { default: undefined });
  const filterModal = useToggle();
  const confirmModal = useToggle();

  const onToggleAllCheck = (checked: boolean) => {
    roles.forEach((role: Role) => {
      dispatch(RoleActions.setRoleCheckList(role.id, checked));
    });
  };

  const isChecked = (list: Map<string, boolean>) => {
    const filteredList = Array.from(list).filter(item => roles.map(({ id }) => id).includes(item[0]));
    return list.size === 0 || !filteredList.length ? false : filteredList.every(item => item[1]);
  };

  const ROLE_SUB_HEAD =
    userRole !== UserRole.Master
      ? ["ID", "이름", "클래스", "유효성", "역할 개수", "우선 순위"]
      : [
          <AnimatedCheckbox
            id="allCheck"
            isChecked={isChecked(checkList)}
            onToggle={() => onToggleAllCheck(checkList.size === 0 ? true : Array.from(checkList).some(item => !item[1]))}
          />,
          "ID",
          "이름",
          "클래스",
          "유효성",
          "역할 개수",
          "우선 순위"
        ];

  const ROLE_TITLE_SUB_HEAD = ["역할 타이틀"];
  const ROLE_SELF_SUB_HEAD = ["ID", "이름", "타입"];

  const headList = [
    { name: "Role", colSpan: ROLE_SUB_HEAD.length },
    { name: "Role Title", colSpan: ROLE_TITLE_SUB_HEAD.length },
    { name: "Role Self", colSpan: ROLE_SELF_SUB_HEAD.length }
  ];

  const SUB_HEAD_LIST = ROLE_SUB_HEAD.concat(ROLE_TITLE_SUB_HEAD, ROLE_SELF_SUB_HEAD);

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);

  const onSearch = (e: React.FormEvent<HTMLFormElement>, searchStr: string) => {
    e.preventDefault();

    if (searchStr === getSearchString(searchType)) {
      return;
    }

    if (!searchStr.length) {
      router.push(`?page=1`);
      return;
    }
    if (searchType === SearchColumnList[0].id && !searchStr.match(/^[0-9]+$/)) {
      Toast.error("아이디는 숫자만 입력가능합니다.", undefined, "top-center");
      return;
    }
    router.push(`?page=1&${searchType}=${searchStr}${!filter ? "" : `&filter=${filter}`}`);
  };

  const onDelete = async () => {
    const url = Array.from(checkList)
      .filter(([_, isChecked]) => isChecked)
      .map(([id, _]) => id);
    setEditLoading(true);
    for (const id of url) {
      try {
        const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Role });
        if (accessId) {
          const { errors } = await DeleteRole({ id });
          if (errors) {
            throw new Error(errors[0].message);
          }
          await DeleteAccessRecord({ id: accessId });
          dispatch(RoleActions.deleteRole(id));
        }
      } catch (err) {
        console.log(err);
        setEditLoading(false);
        Toast.error(`${id}번 삭제에 실패하였습니다.`, undefined, "top-center");
        return;
      }
    }
    setEditLoading(false);
    confirmModal.off();
    Toast.primary("삭제되었습니다", undefined, "top-center");
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        dispatch(RoleActions.clearRoleCheckList());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <Input.Button onClick={filterModal.on}>필터</Input.Button>
        <SearchForm onSearch={onSearch} onChangeSearchTypeInfo={info => setSearchType(info!.id)} optionColumnList={SearchColumnList} />
      </TopbarTemplate>
      <TableTemplate
        headList={headList}
        subHeadList={SUB_HEAD_LIST}
        keyBy={data => data.id}
        dataList={loading === LOADING.UNLOAD ? [] : roles}
        Column={DataTemplate}
      />
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      <Loading loading={editLoading} />
      {loading !== LOADING.UNLOAD && (
        <Pagination
          edge={edge}
          goTo={index =>
            `${ROLES}?page=${index}${!getSearchString(searchType) ? "" : `&${searchType}=${getSearchString(searchType)}`}${
              !filter ? "" : `&filter=${filter}`
            }`
          }
        />
      )}
      {!Array.from(checkList).some(item => item[1]) ? null : (
        <FloatingButton color="danger" onClick={confirmModal.on}>
          DELETE
        </FloatingButton>
      )}
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm
          title="알림"
          context={`선택한 ${Array.from(checkList).filter(([, isChecked]) => isChecked).length}개의 역할을 삭제하시겠습니까?`}
          toSave={onDelete}
          toClose={confirmModal.off}
        />
      </Modal>
      <Modal isOpen={filterModal.isToggled} onClose={filterModal.off}>
        <FilterModalTemplate route={ROLES} toClose={filterModal.off} />
      </Modal>
    </Layout>
  );
};
