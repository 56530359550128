import axios from "axios";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreateGoodsUrl, DeleteAccessRecord, UploadFile } from "GraphQL/Queries";
import { createFormData } from "lib/createFormData";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { useGoodsCreateSelector } from "../Store";
import { Url } from "../Store/GoodsCreate";

export const useCreateImage = () => {
  const { company, coverUrls, detailUrls, videoUrls } = useGoodsCreateSelector(store => store.GoodsCreate.goods);
  const createImage = async (id: string) => {
    const companyId = company.id;
    const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Goods });
    if (!accessId) {
      Toast.error("이미지 등록에 실패하였습니다.");
      return;
    }
    const coverSet = await uploadImage(id, companyId, coverUrls);
    const detailSet = await uploadImage(id, companyId, detailUrls);
    const videoSet = await uploadImage(id, companyId, videoUrls);
    await CreateGoodsUrl({ id, coverUrls: Array.from(coverSet), detailUrls: Array.from(detailSet), videoUrls: Array.from(videoSet) });
    await DeleteAccessRecord({ id: accessId });
  };
  return { createImage };
};

const uploadImage = async (goodsId: string, companyId: string, urls: Map<string, Url>) => {
  const result = new Set<string>();
  try {
    for (const [, { id, typeUrl, file, ext }] of urls) {
      const type = typeUrl === "video/mp4" ? "video" : "cover";
      const { data } = await UploadFile({ filename: `goods/${type}/${goodsId}_${id}.${ext}`, companyId });

      if (!data) {
        throw new Error();
      }
      const bucket = data.uploadFile;
      await axios({
        method: "post",
        url: bucket.url,
        data: createFormData(bucket, file),
        headers: {
          "Content-Type": typeUrl
        }
      });
      result.add(bucket.key);
    }
    return result;
  } catch (err) {
    console.log(err);
    Toast.error("이미지 업로드에 실패하였습니다.");
    return result;
  }
};
