/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar, UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
import { MetadataRightType } from "constants/MetadataRightType";

type Option = Readonly<{
  uuid?: string;
  companyId?: string;
  typeKind?: MetadataRightType;
  order?: number;
  validCheck?: ValidType;
}>;

export const UpdateMetadataCompany = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $companyId = <Variable name="company_id" scalar={<IdScalar isNullable />} value={option.companyId} />;
  const $typeKind = <Variable name="type_kind" scalar={<MetadataRightScalar isNullable />} value={option.typeKind} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const $order = <Variable name="order" scalar={<IntScalar isNullable />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_COMPANY">
      <SelectionSet
        name="updateMetadata_company_relation"
        alias="updateMetadataCompany"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            company: {
              connect: {
                company_id: $companyId
              }
            },
            type_kind: $typeKind,
            exposure_order: $order,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataCompany: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
