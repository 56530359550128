/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum FileType {
  FILE = "FILE",
  FOLDER = "FOLDER"
}

type Props = Readonly<{
  value?: FileType;
  isNullable?: boolean;
}>;

const items = [FileType.FILE, FileType.FOLDER];

export const FileTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="FileType" value={value} isNullable={isNullable} items={items} />
);
