import React, { useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetMetadata } from "GraphQL/Queries/Metadata/index";
import { AlbumActions } from "./Store/Album";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { RightAlbumTable } from "./RightAlbumTable";
import { useQueryParams } from "lib/use-query-params";
import { AlbumStoreProvider, useAlbumDispatch } from "./Store";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Filters } from "App/Templates/FilterModalTemplate";

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;

const ChildLayout = () => {
  const dispatch = useAlbumDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchIdList = queryParams.get("ids", { default: undefined });
  const searchId = queryParams.get("id", { default: undefined });
  const searchNo = queryParams.get("no", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchArtist = queryParams.get("artist", { default: undefined });
  const filter = useMemo(() => {
    const { lte, gte, count, artist, rights, url, validCheck, order, artistType, licenseType } = JSON.parse(
      queryParams.get("filter", { default: undefined }) || "{}"
    ) as Filters;
    return { lte, gte, count, artist, rights, url, validCheck, order, artistType, licenseType };
  }, [queryParams]);
  const { lte, gte, count, artist, rights, url, validCheck, order, artistType, licenseType } = filter;
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          dispatch(AlbumActions.setLoading(LOADING.UNLOAD));
          const { data: albumData, errors: albumError } = await GetMetadata({
            first: count ? parseInt(count, 10) : 20,
            page,
            metadataId: searchId,
            metadataIdIn: !searchIdList?.length ? undefined : (JSON.parse(searchIdList) as number[]),
            no: searchNo,
            title: searchTitle,
            artistName: searchArtist,
            typeClassIn: ["record", "theater"],
            typeSubClass: "album",
            validCheck: validCheckIn,
            gte: gte,
            lte: lte,
            artistIdIn: artist?.map(({ id }) => id),
            rightsIdIn: rights?.map(({ id }) => id),
            url: url,
            order,
            typeArtistIn: artistType?.map(({ id }) => id) ?? [],
            licenseTypeIn: licenseType?.map(({ id }) => id) ?? []
          });
          if (albumError && albumError.length) {
            console.log(albumError[0].message);
            return;
          }
          if (albumData) {
            dispatch([AlbumActions.setAlbum(albumData.metadata), AlbumActions.setEdge(albumData.edge)]);
            if (!albumData.metadata.length) {
              dispatch(AlbumActions.setLoading(LOADING.NULL));
            } else {
              dispatch(AlbumActions.setLoading(LOADING.ONLOAD));
            }
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
    },
    [page, searchId, searchTitle, searchIdList, searchArtist, filter]
  );
  return (
    <Layout>
      <LeftSideBar />
      <RightAlbumTable count={!count ? 20 : parseInt(count, 10)} />
    </Layout>
  );
};

export const AlbumRenewal = () => (
  <AlbumStoreProvider>
    <Helmet title="대작품" />
    <ChildLayout />
  </AlbumStoreProvider>
);
