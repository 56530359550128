import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { ArtistEditEvents } from "./Store/Artist";
import itiriri from "itiriri";
import { createHashGenerator } from "lib/createHashGenerator";

export const commitToEditingLog = async (artists: any[], events: ArtistEditEvents) => {
  const hash = createHashGenerator();
  let artistTotalQuery = "";
  if (artists.length) {
    artists.forEach(artist => {
      const artistsEventLog = events.artists.get(artist.artistId);
      const urlEventLog = events.urlRelation.get(artist.artistId);
      const genreEventLog = events.genreRelation.get(artist.artistId);
      const historyEventLog = events.historyRelation.get(artist.artistId);
      const roleEventLog = events.roleRelation.get(artist.artistId);

      if (artistsEventLog) {
        let artistsDataQuery = `
        artist_${hash()}: updateArtist(
            data: {
              weight: 0`;

        const createGenreQuery = itiriri(Object.entries(artistsEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "genre")
          .toArray(([key]) => {
            const genre = events.genreRelation.get(artist.artistId)![key];
            if (genre) {
              return `{
              genre: {
                connect: {
                  genre_id: "${genre.to}"
                }
              },
              exposure_order: ${genre.order}
            }`;
            }
          });

        const createRoleQuery = itiriri(Object.entries(artistsEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "role")
          .toArray(([key]) => {
            const role = events.roleRelation.get(artist.artistId)![key];
            if (role) {
              return `{
                role: {
                  connect: {
                    role_id: "${role.to}"
                  }
                }
              }`;
            }
          });

        if (createGenreQuery.length) {
          artistsDataQuery += `
          artist_genre_relation: {
            create: [ ${createGenreQuery.join(" ")} ]
          }
          `;
        }

        if (createRoleQuery.length) {
          artistsDataQuery += `
          artist_role_relation: {
            create: [ ${createRoleQuery.join(" ")} ]
          }`;
        }

        const artistDataQuery = itiriri(Object.entries(artistsEventLog))
          .filter(([key, event]) => event.type === "Update")
          .toArray(([key]) => {
            return key === "valid_check" ? `${key}: ${artistsEventLog[key].to}` : `${key}: "${artistsEventLog[key].to}"`;
          })
          .join(" ");

        artistsDataQuery += `${artistDataQuery}}
        where: { artist_id: "${artist.artistId}"}
        ) {
          artist_id
        }`;

        artistTotalQuery += artistsDataQuery;
      }

      if (urlEventLog) {
        const urlQuery = itiriri(Object.entries(urlEventLog))
          .filter(([key, event]) => event.type === "Update")
          .toArray(([key]) => {
            return `
              urlRelation_${hash()}: updateArtist_url(
                data: {
                 url: "${urlEventLog[key].to}"
                },
                where: { id: "${key}" }
              ) {
               id  
              }
            `;
          })
          .join(" ");

        artistTotalQuery += urlQuery;
      }

      if (historyEventLog) {
        const createHistoryQuery = itiriri(Object.entries(historyEventLog))
          .filter(([key, event]) => event.entries().next().value[1].type === "Create")
          .toArray(([key, value], index) => {
            return `
              createHistory_${hash()}: updateArtist(
                where: { artist_id: "${artist.artistId}" }
                data: {
                  _onlySearch: true
                  artist_history: {
                    create: {
                      ${itiriri(value).map(([_, value]) => `${value.column}: "${value.to}"`)}
                    }
                  }
                }
              ) {
                artist_id
                artist_history {
                  id
                }
              }
            `;
          })
          .join(" ");
        artistTotalQuery += createHistoryQuery;

        const updateHistoryQuery = itiriri(Object.entries(historyEventLog))
          .filter(([key, event]) => event.entries().next().value[1].type === "Update")
          .toArray(([key, value]) => {
            return `
              updateHistory_${hash()}: updateArtist_history(
                where: { id: "${key}" }
                data: {
                  ${itiriri(value).map(([key, value]) => `${key}: "${value.to}"`)}
                }
              ) {
                id
              }
            `;
          })
          .join(" ");
        artistTotalQuery += updateHistoryQuery;
      }

      if (genreEventLog) {
        const genreRemoveQuery = itiriri(Object.entries(genreEventLog))
          .filter(([key, event]) => event.type === "Remove")
          .toArray(([key]) => {
            return `
            removeGenreRelation_${hash()}: deleteArtist_genre_relation(
              where: { id: "${key}" }
            ) {
              id
            }
          `;
          })
          .join(" ");
        artistTotalQuery += genreRemoveQuery;

        const genreUpdateQuery = itiriri(Object.entries(genreEventLog))
          .filter(([key, event]) => event.type === "Remove")
          .toArray(([key]) => {
            return `
            removeGenreRelation_${hash()}: updateArtist_genre_relation(
              where: { id: "${key}" }
              data: {
                genre: {
                  connect: {
                    genre_id: "${genreEventLog![key].to}"
                  }
                }
              }
            ) {
              id
            }
          `;
          })
          .join(" ");
        artistTotalQuery += genreUpdateQuery;
      }

      if (roleEventLog) {
        const roleRemovequery = itiriri(Object.entries(roleEventLog))
          .filter(([key, event]) => event.type === "Remove")
          .toArray(([key]) => {
            return `
            removeRoleRelation_${hash()}: deleteArtist_role_relation(
              where: { id: "${key}" }
            ) {
             id
            }
          `;
          })
          .join(" ");
        artistTotalQuery += roleRemovequery;

        const roleUpdatequery = itiriri(Object.entries(roleEventLog))
          .filter(
            ([key, event]) =>
              event.type === "Update" &&
              (artistsEventLog === undefined || itiriri(Object.entries(artistsEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key]) => {
            return `
            roleRelation_${hash()}: updateArtist_role_relation(
              where: { id: "${key}" }
              data: {
                role: {
                  connect: {
                    role_id: "${roleEventLog![key].to}"
                  }
                }
              }
            ) {
              id
            }
          `;
          })
          .join(" ");
        artistTotalQuery += roleUpdatequery;
      }
    });
  }

  if (!artistTotalQuery.length) {
    alert("변경할 데이터가 없습니다.");
    return false;
  }

  if (window.confirm("데이터를 변경하시겠습니까?")) {
    try {
      const query = gql`
        mutation UPDATE_ARTIST {
          ${artistTotalQuery}
        }
      `;
      const { data } = await clients.artist.mutation(query);
      if (data) {
        window.alert("저장되었습니다.");
        return;
      }
    } catch (e) {
      throw e;
    }
  } else {
    return false;
  }
};
