import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { AlbumDetailActions as AlbumDetail } from "./AlbumDetail";
import { StructureActions as Structure } from "./Structure";
import { TrackActions as TrackList } from "./TrackList";

const reducers = { AlbumDetail, Structure, TrackList };

const reducer = combineReducers(reducers, createStoreLogger("AlbumDetail"));

export const {
  StoreProvider: AlbumDetailStoreProvider,
  useStore: useAlbumDetailStore,
  useSelector: useAlbumDetailSelector,
  useDispatch: useAlbumDetailDispatch
} = createStore(reducer);
