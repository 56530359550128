import classNames from "classnames";
import React, { useState } from "react";
import ReactSelect from "react-select/creatable";
import styled from "styled-components";

import { identity } from "utils/identity";

type Value = string;
type Label = string;

export type Info = Readonly<{
  id: Value;
  name: Label;
}>;

type Props = Readonly<{
  style?: import("react-select/src/styles").Styles;
  name: string;
  className?: string;
  isDisabled?: boolean;
  defaultValue?: null | Info;
  placeholder?: string;
  optionList: readonly Info[];
  align?: "left" | "center";
  onChange?: (info: null | Info) => void;
  onCreateOption?: (info: null | Info) => void;
  onBlur?: (info: null | Info) => void;
}>;

const Layout = styled(ReactSelect)`
  & > * {
    text-transform: none;
  }
  &.left > div > div {
    justify-content: left;
  }
  &.center > div > div {
    justify-content: center;
  }
`;

export const CreatableTextSelect = ({
  style,
  name,
  className,
  isDisabled = false,
  defaultValue = null,
  placeholder,
  optionList,
  align = "left",
  onChange = identity,
  onBlur = identity
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const change: ReactSelect<Info>["props"]["onChange"] = nextInfo => {
    setInfo(nextInfo as Info);
    onChange(nextInfo as Info);
  };
  const inputChange: ReactSelect<Info>["props"]["onInputChange"] = newValue => {
    setInfo({ id: newValue, name: newValue } as Info);
    onChange({ id: newValue, name: newValue } as Info);
  };
  const getNewOption: ReactSelect<Info>["props"]["getNewOptionData"] = (newValue, optionLabel) => {
    return { id: newValue, name: newValue } as Info;
  };
  const blur = () => onBlur(info);
  return (
    <Layout
      isClearable
      name={name}
      style={style}
      menuPosition={"fixed"}
      className={classNames(className, { [align]: align })}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      isMulti={false}
      getOptionValue={(info: Info) => info.id}
      getOptionLabel={(info: Info) => info.name}
      getNewOptionData={getNewOption}
      placeholder={placeholder}
      options={optionList}
      onChange={change}
      onInputChange={inputChange}
      onBlur={blur}
    />
  );
};
