import { LoadGoodsSubdatas } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { ProductDetailStore } from "../Store";
import { ProductDetailActions } from "../Store/ProductDetail";

export const useLoadGoodsSubadatas = (goodsId?: string) => {
  useAsyncEffect(async () => {
    if (goodsId) {
      const { data, errors } = await LoadGoodsSubdatas({ id: goodsId });
      if (errors || !data) {
        Toast.error("굿즈 타입을 불러올 수 없습니다.");
        return;
      }
      ProductDetailStore.dispatch(ProductDetailActions.setGoodsSubadatas(data.goodsSubdatas));
    }
  }, [goodsId]);
  return;
};
