import React from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { LanguageStoreProvider, useLanguageDispatch } from "./Store";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { RightLanguageTable } from "./RightLanguageTable";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetLanguage } from "GraphQL/Queries/Language";
import { Toast } from "lib/toast";
import { LanguageActions } from "./Store/Language";
import { useQueryParams } from "lib/use-query-params";
import { useAppDispatch } from "App/Store";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";

const ChildLayout = () => {
  const dispatch = useLanguageDispatch();
  const dispatchApp = useAppDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: v => +v });
  const searchName = queryParams.get("name", { default: undefined });
  const searchCode = queryParams.get("code", { default: undefined });
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          const { language, edge } = await GetLanguage({ first: 30, page, name: searchName, languageCode: searchCode });
          dispatch([LanguageActions.setLanguage(language), LanguageActions.setEdge(edge)]);
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
          window.scrollTo({ top: 0, behavior: "smooth" });
        } catch (err) {
          console.log(err);
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
          Toast.error("데이터를 불러올 수 없습니다.");
          return;
        }
      }
    },
    [page, searchName, searchCode]
  );
  return (
    <Layout>
      <LeftSideBar />
      <RightLanguageTable />
    </Layout>
  );
};

export const LanguageManage = () => {
  return (
    <LanguageStoreProvider>
      <Helmet title="아티스트" />
      <ChildLayout />
    </LanguageStoreProvider>
  );
};

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;
