import React, { useState } from "react";
import styled, { css } from "styled-components";
import { mediaQuery } from "constants/media";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { configs } from "configs";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { useToggle } from "lib/use-toggle";
import { useDeleteGoods } from "App/Routes/Goods/Hooks/useDeleteGoods";
import { Toast } from "lib/toast";
import { Loading } from "App/Atomics/Loading";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as ProductIcon } from "assets/icons/product.svg";
import { AC_PURPLE, AC_GRAY } from "constants/color";
import { GoodsModel } from "models/GoodsModel";
import { useHistory } from "lib/use-router";
import { GOODS } from "constants/route";
import { GRAY_5 } from "constants/baseColor";
import NoImage from "assets/images/noImage.png";

type Props = {
  readonly data: GoodsModel;
};

export const CardItem = ({ data: goods }: Props) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  const naviage = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { deleteGoods } = useDeleteGoods();
  const confirmModal = useToggle();
  const image = goods.urls.find(({ typeUrl }) => typeUrl === "cover")?.url;
  const onGoToDetail = (id: string) => naviage.push(`${GOODS}/${id}`);
  const onGoToRelatedProduct = () => window.open(`${configs.urls.goodsWeb}/goods/${goods.id.raw}`);
  const onGoToRelatedParent = () => naviage.push(`${GOODS}?parentId=${goods.structureId}`);
  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteGoods(goods.id.key);
      Toast.primary("삭제되었습니다.");
    } catch (err) {
      console.log(err);
      Toast.error("삭제에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };
  return (
    <Layout>
      <ImageContainter onClick={() => onGoToDetail(goods.id.raw)}>
        <Link className="link">
          <Image src={image ? `${configs.urls.image}/${image}` : NoImage} alt={`cover`} />
        </Link>
        <ActiveGroup className="active-group" onClick={e => e.stopPropagation()}>
          <ProductWrap>
            <ProductIcon onClick={onGoToRelatedParent} />
          </ProductWrap>
          {userRole === UserRole.Master && <DeleteIcon onClick={confirmModal.on} />}
        </ActiveGroup>
        <Subtitle>{goods.subtitle}</Subtitle>
      </ImageContainter>
      <Info>
        <ClipBoard text={goods.id.raw}>
          <Id>{goods.id.raw}</Id>
        </ClipBoard>
        <Category>{goods.goodsType.displayType}</Category>
        <ClipBoard text={goods.name}>
          <Title>{goods.name}</Title>
        </ClipBoard>
        {goods.parentGoodsId && goods.parentName && <Parent onClick={() => onGoToDetail(goods.parentGoodsId!)}>{goods.parentName}</Parent>}
        <Product onClick={onGoToRelatedProduct}>웹에서 보기</Product>
      </Info>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm title={"알림"} context={"해당 굿즈를 삭제하시겠습니까?"} toSave={onDelete} toClose={confirmModal.off} />
      </Modal>
      {loading && <Loading loading={loading} />}
    </Layout>
  );
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;
  transition: width 0.2s ease-in-out;
  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  cursor: pointer;
  &:hover {
    .active-group {
      opacity: 1;
    }
    .link::after {
      opacity: 1;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 9em;
  max-height: 8em;

  span {
    white-space: pre-wrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .id {
    font-size: 0.8em;
    color: #bbb;
  }
  .title {
    font-weight: bold;
    flex-wrap: wrap;
  }
`;

const Link = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::after {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    z-index: 1;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent, rgba(0, 0, 0, 0.3));
    transition: opacity 0.2s ease-in;
  }
`;

const Image = styled.img`
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #eee;
`;
const Id = styled.span`
  font-size: 0.8em;
  color: #bbb;
`;

const Title = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const Product = styled.span`
  flex-wrap: wrap;
  font-size: 11px;
  color: ${GRAY_5};
`;
const Category = styled.span`
  font-weight: bold;
  flex-wrap: wrap;
  text-decoration: underline;
`;

const ActiveGroup = styled.div`
  position: absolute;
  width: 100%;
  bottom: 3%;
  padding: 0 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.2s;
  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
    cursor: pointer;
    transition: fill 0.2s;
    margin-left: 4px;
    &:hover {
      fill: ${AC_PURPLE};
    }
  }
`;

const ProductWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 22px;
    height: 22px;
    fill: #fff;
    cursor: pointer;
    transition: fill 0.2s;
    margin-left: 4px;
    &:hover {
      fill: ${AC_PURPLE};
    }
  }
`;

const BadgeStyle = css`
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  padding: 2px 4px;
`;

const Subtitle = styled.div`
  ${BadgeStyle};
  top: 5px;
  left: 5px;
  color: ${AC_PURPLE};
  border: 1px solid black;
`;

const Parent = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;
  color: ${AC_GRAY};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
