import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { ProductCreateStore } from "App/Routes/ProductCreate/Store";
import { ProductCreateActions } from "App/Routes/ProductCreate/Store/ProductCreate";

type Props = {
  readonly price: number;
};

export const ProductPrice = ({ price }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>가격 *</Style.Caption>
    <Style.SubCaption>ex. 7700원</Style.SubCaption>
    <Style.Inputs
      key={price}
      type="number"
      min={0}
      defaultValue={price}
      onBlur={event => ProductCreateStore.dispatch(ProductCreateActions.setPrice(+event.target.value))}
    />
  </Style.Row>
);
