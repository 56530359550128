import React, { useState } from "react";
import styled from "styled-components";
import { MARGING_SMALL_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useAsyncEffect } from "lib/use-async-effect";
import { CreateComment, DeleteAccessRecord, DeleteComment, GetComment } from "GraphQL/Queries";
import { Comment } from "GraphQL/Queries/Access/GetComment";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { Loading } from "App/Atomics/Loading";
import { CommentOrderByInput } from "constants/CommentOrderByInput";
import { ReactComponent as NoDataIcon } from "assets/icons/no-data.svg";
import { ReactComponent as NewIcon } from "assets/icons/new.svg";
import { PRIMARY_COLOR } from "constants/color";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { useToggle } from "lib/use-toggle";
import { BLUE_5, GRAY_3, GRAY_4, GRAY_6 } from "constants/baseColor";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { useAppSelector } from "App/Store";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { EditComment, InitComment, ParsedComment, SubPage } from "../..";
import { UserRole } from "constants/UserRole";

type Props = {
  id: string;
  setPage: React.Dispatch<React.SetStateAction<SubPage>>;
  setInitialComment: React.Dispatch<React.SetStateAction<InitComment>>;
  setEditComment: React.Dispatch<React.SetStateAction<EditComment>>;
};

type RemoveInfo = {
  uuid?: string;
  parentUuid?: string;
};

export const CommentListForm = ({ id, setPage, setInitialComment, setEditComment }: Props) => {
  const [comment, setComment] = useState<Comment[]>([] as Comment[]);
  const [orderBy, setOrderBy] = useState<CommentOrderByInput>(CommentOrderByInput.timestamp_DESC);
  const [loading, setLoading] = useState<boolean>(false);
  const [removeInfo, setRemoveInfo] = useState<RemoveInfo>({ uuid: undefined, parentUuid: undefined } as RemoveInfo);
  const [commentReply, setCommentReplay] = useState<Map<string, { isExtend: boolean; isOpen: boolean; content: string; extra: Comment[] }>>(
    () => new Map()
  );
  const confirmModal = useToggle();
  const { email: userEmail, role: userRole } = useAppSelector(store => store.UserToken);
  const textAreaRefs = React.useMemo(() => Array.from({ length: comment.length }, () => React.createRef<HTMLTextAreaElement>()), [
    comment.length
  ]);

  const onConfirm = (uuid: string, parentUuid?: string) => {
    setRemoveInfo({ uuid, parentUuid });
    confirmModal.on();
  };

  const onDeleteComment = async ({ uuid, parentUuid }: RemoveInfo) => {
    const accessId = await requestAccessRecord({ targetTable: TargetTableInput.Users });
    if (accessId) {
      try {
        setLoading(true);
        const { errors } = await DeleteComment({ uuid: uuid! });
        if (errors) {
          throw new Error(errors[0].message);
        }
        if (!parentUuid) {
          const newComment = comment.filter(item => item.uuid !== uuid);
          setComment(newComment);
        } else {
          const { isExtend, isOpen, content, extra } = commentReply.get(parentUuid)!;
          const filteredExtra = extra.filter(item => item.uuid !== uuid);
          const newReply = new Map(commentReply.set(parentUuid, { isExtend, isOpen, content, extra: filteredExtra }));
          setCommentReplay(newReply);
        }

        Toast.primary("삭제되었습니다.");
        setRemoveInfo({ uuid: undefined, parentUuid: undefined });
        setLoading(false);
        return;
      } catch (err) {
        console.log(err);
        Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
        setRemoveInfo({ uuid: undefined, parentUuid: undefined });
        setLoading(false);
        return;
      } finally {
        await DeleteAccessRecord({ id: accessId });
      }
    }
  };

  const onToggleCommentReply = async (uuid: string) => {
    const reply = commentReply.get(uuid) ?? { isExtend: false, isOpen: false, content: "", extra: [] };
    const { isExtend, isOpen, content } = reply;
    if (!isOpen) {
      try {
        const commentList = await GetComment({
          targetId: id,
          targetTable: TargetTableInput.Playlist,
          depth: 2,
          parentId: uuid,
          order: CommentOrderByInput.timestamp_ASC
        });
        setCommentReplay(new Map(commentReply.set(uuid, { isExtend, isOpen: !isOpen, content, extra: commentList })));
      } catch (err) {
        console.log(err);
        Toast.error("답글 목록을 불러오는데 실패하였습니다");
        return;
      }
    } else {
      setCommentReplay(new Map(commentReply.set(uuid, { isExtend, isOpen: !isOpen, content, extra: [] })));
    }
  };

  const onEditComment = (uuid: string, value: ParsedComment) => {
    setEditComment(prev => ({ uuid, value }));
    setPage(SubPage.THIRD);
  };

  const onWriteMasterComment = (uuid: string) => {
    setInitialComment({ uuid, value: { data: [], text: undefined } as ParsedComment, isReply: true });
    setPage(SubPage.SECOND);
  };

  const onCreateReply = async (uuid: string) => {
    const reply = commentReply.get(uuid)!;
    const { isExtend, isOpen, extra } = reply;
    try {
      const { data, errors } = await CreateComment({
        targetId: id,
        targetTable: TargetTableInput.Playlist,
        value: JSON.stringify({ data: [], text: reply.content }),
        parentId: uuid
      });
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (data) {
        const commentData = { ...data.createComment, value: { data: [], text: reply.content }, users: [{ email: userEmail }] } as Comment;
        setCommentReplay(new Map(commentReply.set(uuid, { isExtend, isOpen, content: "", extra: [...extra, commentData] })));
        Toast.primary("등록되었습니다.", undefined, "top-center");
      }
    } catch (err) {
      console.log(err);
      Toast.error("오류가 발생하였습니다", undefined, "top-center");
      return;
    }
  };

  const editReplyContent = (uuid: string, text: string) => {
    const reply = commentReply.get(uuid) ?? { isExtend: false, isOpen: true, content: "", extra: [] };
    setCommentReplay(new Map(commentReply.set(uuid, { ...reply, content: text })));
  };

  useAsyncEffect(
    async isMounted => {
      setLoading(true);
      if (isMounted()) {
        try {
          const commentList = await GetComment({ targetId: id, targetTable: TargetTableInput.Playlist, order: orderBy, depth: 1 });
          setComment(commentList);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          return;
        }
      }
    },
    [orderBy]
  );
  return (
    <Layout>
      <OrderMenu>
        <li
          className={orderBy === CommentOrderByInput.timestamp_DESC ? "active" : ""}
          onClick={() => setOrderBy(CommentOrderByInput.timestamp_DESC)}
        >
          최신순
        </li>
        <li
          className={orderBy === CommentOrderByInput.timestamp_ASC ? "active" : ""}
          onClick={() => setOrderBy(CommentOrderByInput.timestamp_ASC)}
        >
          오래된 순
        </li>
      </OrderMenu>
      <Divider />
      {!comment.length ? (
        <NoData>
          <NoDataIcon />
          댓글이 없습니다.
        </NoData>
      ) : (
        comment.map(({ uuid, value, timestamp, users }, i) => {
          dayjs.locale("ko");
          dayjs.extend(relativeTime);
          const fromNow = dayjs(timestamp).fromNow();
          const { email } = users![0];
          const isNew = (Date.now() - new Date(timestamp).getTime()) / 1000 / 60 / 60 < 22;
          const scrollHeight = () => {
            if (textAreaRefs[i].current) {
              return textAreaRefs[i].current!.scrollHeight;
            }
            return 0;
          };
          return (
            <DescCard key={uuid} isExtend={true}>
              <div className="titleContainer">
                <div className="titleInfo">
                  <div className="title">
                    <span>{`${email}`}</span>
                  </div>
                  <span className="date">{fromNow}</span>
                  {isNew && <NewIcon />}
                </div>
                <div className="edit-wrapper">
                  {userEmail !== email && userRole === UserRole.Master && (
                    <Input.Button className="btn" onClick={() => onWriteMasterComment(uuid)}>
                      관리자 답변
                    </Input.Button>
                  )}
                  {userEmail === email && (
                    <>
                      <Input.Button className="btn" onClick={() => onEditComment(uuid, value)}>
                        수정
                      </Input.Button>
                      <Input.Button className="btn" onClick={() => onConfirm(uuid)}>
                        삭제
                      </Input.Button>
                    </>
                  )}
                </div>
              </div>
              {/* 접었을 때 기가 사라지므로 -1px로 값 보정 */}
              <ResizeTextArea ref={textAreaRefs[i]} isExtend={true} scrollHeight={`${scrollHeight()}px`} disabled value={value.text} />
              <div className="btn-wrapper">
                <Input.Button className="comment-btn" onClick={() => onToggleCommentReply(uuid)}>
                  {!commentReply.get(uuid)?.isOpen ? "답글" : "접기"}
                </Input.Button>
              </div>
              {!commentReply.get(uuid)?.isOpen ? null : (
                <>
                  <ReplyWrap>
                    <div className="write-box">
                      <span className="line">└</span>
                      <Multiline
                        className="reply-area"
                        value={commentReply.get(uuid)!.content}
                        placeholder="답글을 작성하세요"
                        onChange={e => editReplyContent(uuid, e.target.value)}
                      />
                      <Input.Button onClick={() => onCreateReply(uuid)}>등록</Input.Button>
                    </div>
                  </ReplyWrap>
                  {!commentReply.get(uuid)!.extra.length
                    ? null
                    : commentReply.get(uuid)!.extra.map(reply => {
                        const replyFromNow = dayjs(reply.timestamp).fromNow();
                        const isNew = (Date.now() - new Date(reply.timestamp).getTime()) / 1000 / 60 / 60 < 22;
                        return (
                          <ReplyWrap key={reply.uuid} style={{ marginTop: "0", borderBottom: "none" }}>
                            <div className="view-box">
                              <span className="line">└</span>
                              <div className="user-info">
                                <span className="top">
                                  <span className="email">{!reply.users ? "unknown" : reply.users[0].email}</span>
                                  <span className="date">{replyFromNow}</span>
                                  {isNew && <NewIcon />}
                                </span>
                                <pre>{reply.value.text}</pre>
                              </div>
                              {reply.users && reply.users[0].email === userEmail && (
                                <div className="edit-wrapper">
                                  <Input.Button className="btn" onClick={() => onConfirm(reply.uuid, uuid)}>
                                    삭제
                                  </Input.Button>
                                </div>
                              )}
                            </div>
                          </ReplyWrap>
                        );
                      })}
                </>
              )}
            </DescCard>
          );
        })
      )}
      <Loading loading={loading} />
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm title="알림" context="정말로 삭제하시겠습니까?" toSave={() => onDeleteComment(removeInfo)} toClose={confirmModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
  font-size: 0.9rem;
  user-select: text;

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DescCard = styled.form<{ isExtend: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.5rem 0.5rem;
  background-color: #fff;
  border-bottom: 1.5px solid ${GRAY_3};

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    margin-bottom: ${MARGING_SMALL_PX};
    border-radius: 0;
    .titleInfo {
      display: flex;
      align-items: center;
      .title {
        font-size: 0.9rem;
        font-weight: 600;
        color: ${PRIMARY_COLOR};
        margin-right: 0.5rem;
        margin-bottom: 2px;
      }
      .date {
        font-size: 0.75rem;
        color: ${GRAY_6};
        margin-right: 0.3rem;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .edit-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      font-size: 0.8rem;
      padding: 0.2rem;
      margin-left: 0.5rem;
      color: #afafaf;
    }
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .comment-btn {
      padding: 0.5rem 0;
      color: #555;
      font-size: 0.75rem;
    }
  }
`;

const ResizeTextArea = styled.textarea<{ isExtend: boolean; scrollHeight: string }>`
  width: 100%;
  margin: 0.5rem 0 0.3rem;
  min-height: 6.3rem;
  height: ${props => (!props.isExtend ? "6.3rem" : props.scrollHeight)};
  /* transition: height 1s; */
  border: none;
  padding: 0;
  color: #212121;
  font-size: 14px;
  line-height: 1.43;
  background-color: #fff;
  overflow: hidden;
`;

const OrderMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 10rem;
  padding: 1rem 1rem 0;
  li {
    cursor: pointer;
    &.active {
      font-weight: 600;
    }
  }
`;

const NoData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 35rem;
  svg {
    width: 7rem;
    height: 7rem;
    margin-bottom: 1rem;
  }
`;

const Divider = styled.div`
  margin: 0.8rem 1rem;
  min-height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ReplyWrap = styled.div`
  width: 100%;
  padding: 22px 20px 14px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #d4d4d4;
  margin: 14px -20px 0;
  background-color: #f9f9f9;
  position: relative;
  z-index: 1;

  .write-box {
    display: grid;
    grid-template-columns: 1.5rem auto 2.5rem;
    .line {
      color: #c4c4c4;
    }
    .reply-area {
      background-color: #fff;
      height: 4rem;
      padding: 0.5rem;
      font-size: 0.8rem;
    }
  }

  .view-box {
    display: grid;
    grid-template-columns: 1.5rem auto 5rem;
    font-size: 0.8rem;
    .line {
      color: #c4c4c4;
    }
    .user-info {
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;
        .email {
          font-weight: 600;
          color: ${PRIMARY_COLOR};
          margin-right: 0.3rem;
          margin-bottom: 1px;
        }
        .date {
          font-size: 0.7rem;
          color: ${GRAY_6};
          margin-right: 0.5rem;
        }
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
      pre {
        word-break: break-all;
        white-space: pre-wrap;
      }
    }
  }
  .edit-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      font-size: 0.8rem;
      padding: 0.2rem;
      margin-left: 0.5rem;
      color: #afafaf;
    }
  }
`;

const Multiline = styled.textarea`
  display: block;
  overflow: auto;
  width: 100%;
  min-height: 10rem;
  padding: 1rem 1rem 0 0;
  border: 1px solid ${GRAY_4};
  border-radius: 8px;
  background: transparent;
  outline: none;
  line-height: 22px;
  color: #000;
  box-sizing: border-box;
  resize: none;
  transition: border-color 0.2s;
  &:focus {
    border-width: 1.5px;
    border-color: ${BLUE_5};
  }
`;
