import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";
import { GoodsTitle as TypeGoodsTitle } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";

type Props = {
  readonly titles: TypeGoodsTitle[];
};

type UpdateType = "language" | "typeTitle" | "value";

export const GoodsTitle = ({ titles = [] }: Props): ReactElement => {
  const onAddTitle = () => {
    const tempId = uuidv4();
    GoodsDetailStore.dispatch(GoodsDetailActions.addTitle(tempId));
  };
  const onUpdateTitle = (index: number, type: UpdateType, value: string) => {
    switch (type) {
      case "language":
        GoodsDetailStore.dispatch(GoodsDetailActions.updateTitleLanguageCode({ index, language: value }));
        break;
      case "typeTitle":
        GoodsDetailStore.dispatch(GoodsDetailActions.updateTitleType({ index, type: value }));
        break;
      case "value":
        GoodsDetailStore.dispatch(GoodsDetailActions.updateTitleValue({ index, value }));
        break;
    }
  };
  const onRemoveTitle = (index: number) => GoodsDetailStore.dispatch(GoodsDetailActions.removeTitle(index));
  return (
    <Style.Row>
      <Style.Caption>언어팩</Style.Caption>
      <Style.ColContainer>
        {titles.map((title, index) => (
          <GridGroup key={title.id}>
            <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveTitle(index)}>
              -
            </Style.RoundButton>
            <Style.Select
              optionList={TitleTypeOptionInfo.slice(0, 4)}
              defaultValue={!title.type ? undefined : { id: title.type, name: title.type }}
              onBlur={info => info && onUpdateTitle(index, "typeTitle", info.id)}
            />
            <Style.Select
              optionList={LanguageCodeInfo}
              defaultValue={!title.languageCode ? undefined : { id: title.languageCode, name: title.languageCode }}
              onBlur={info => info && onUpdateTitle(index, "language", info.id)}
            />
            <Style.InputTextArea
              style={{ marginTop: 0 }}
              defaultValue={title.value}
              onBlur={event => onUpdateTitle(index, "value", event.target.value)}
            />
          </GridGroup>
        ))}
        <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddTitle}>
          +
        </Style.RoundButton>
      </Style.ColContainer>
    </Style.Row>
  );
};

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2.6rem 8rem 8rem auto;
  grid-gap: 4px;

  & + & {
    margin-top: 4px;
  }
`;
