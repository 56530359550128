/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { MetadataUrlOrderByInput } from "../../constants/MetadataUrlOrderByInput";

type Props = Readonly<{
  value?: MetadataUrlOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  MetadataUrlOrderByInput.id_ASC,
  MetadataUrlOrderByInput.id_DESC,
  MetadataUrlOrderByInput.type_url_ASC,
  MetadataUrlOrderByInput.type_url_DESC,
  MetadataUrlOrderByInput.url_ASC,
  MetadataUrlOrderByInput.url_DESC,
  MetadataUrlOrderByInput.valid_check_ASC,
  MetadataUrlOrderByInput.valid_check_DESC,
  MetadataUrlOrderByInput.exposure_order_ASC,
  MetadataUrlOrderByInput.exposure_order_DESC,
  MetadataUrlOrderByInput.createdAt_ASC,
  MetadataUrlOrderByInput.createdAt_DESC,
  MetadataUrlOrderByInput.updatedAt_ASC,
  MetadataUrlOrderByInput.updatedAt_DESC
];

export const MetadataUrlOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="Metadata_urlOrderByInput" value={value} isNullable={isNullable} items={items} />
);
