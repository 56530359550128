import { createActions, createHandler, createReducer } from "lib/store";
import { MetadataOrderByInput } from "constants/MetadataOrderByInput";

type State = ReturnType<typeof createInitialState>;

const SET_ALBUM_ORDER = Symbol("Album::Order::Set");

export const AlbumsActions = createActions({
  setAlbumOrder(prevOrder: MetadataOrderByInput, nextOrder: MetadataOrderByInput) {
    return { type: SET_ALBUM_ORDER, prevOrder, nextOrder };
  }
});

const handler = createHandler<State>({
  [SET_ALBUM_ORDER](state, payload: { prevOrder: MetadataOrderByInput; nextOrder: MetadataOrderByInput }) {
    let nextState = state.order.filter(order => order !== payload.prevOrder);
    nextState.unshift(payload.nextOrder);
    state.order = nextState;
  }
});

const createInitialState = () => ({
  order: ["metadata_id__DESC", "no__ASC", "title__ASC"] as MetadataOrderByInput[]
});

export const reducer = createReducer(handler, createInitialState);
