import { CreateGoods } from "GraphQL/Queries/Goods";
import { Time } from "lib/time";
import { formatUtcDateForGql } from "utils/format";
import { useGoodsCreateSelector } from "../Store";

const oneSizeOption = [{ id: "1", field: "option", value: "ONE SIZE", category: "option" }];

export const useCreateGoods = () => {
  const {
    name,
    globalName,
    subtitle,
    details,
    tags,
    typeInfo,
    weight,
    artists,
    company,
    work,
    parent,
    subdatas,
    createdAt,
    officialUrl,
    officialBarcode,
    askPrice,
    officialPrice
  } = useGoodsCreateSelector(store => store.GoodsCreate.goods);
  const createGoods = async () => {
    const customSubdatas = !subdatas.length ? oneSizeOption : subdatas;
    const { data, errors } = await CreateGoods({
      name,
      globalName,
      subtitle,
      details,
      tags,
      typeId: +typeInfo.id,
      weight,
      officialPrice,
      askPrice,
      companyId: company.id,
      artistIds: artists.map(({ id }) => id),
      workIds: work?.id ? [work.id] : [],
      parentIds: !parent.length ? [0] : parent.map(({ parentId }) => +parentId),
      createdAt: formatUtcDateForGql(Time(createdAt)),
      officialUrl,
      officialBarcode,
      subdatas: customSubdatas
    });
    return { data, errors };
  };
  return { createGoods };
};
