import React, { useMemo, FC, ReactNode } from "react";

import { Table } from "App/Atomics/Table";

type Head = Readonly<{
  name: ReactNode;
  colSpan?: number;
}>;

export type ColumnProps<D> = Readonly<{ index: number; data: D }>;

type Props = Styleable &
  Readonly<{
    keyBy: (data: any, index: number) => number | string;
    headList: readonly Head[];
    subHeadList?: readonly ReactNode[];
    dataList: readonly any[];
    footerList?: ReactNode;
    Column: FC<ColumnProps<any>>;
  }>;

export const TableTemplate = ({ style, className, headList, subHeadList, dataList, keyBy, footerList, Column }: Props) => {
  const createHeading = () => (
    <>
      <Table.Row>
        {headList.map((head, index) =>
          head.colSpan === 0 ? (
            undefined
          ) : (
            <Table.Head colSpan={head.colSpan} key={index}>
              {head.name}
            </Table.Head>
          )
        )}
      </Table.Row>

      {subHeadList && (
        <Table.Row>
          {subHeadList.map((subHead, index) => (
            <Table.Head key={index}>{subHead}</Table.Head>
          ))}
        </Table.Row>
      )}
    </>
  );
  const heading = useMemo(createHeading, [headList, subHeadList]);

  const createColumnList = () =>
    !dataList ? [] : dataList.map((data, index) => <Column index={index} data={data} key={keyBy(data, index)} />);
  const columnList = useMemo(createColumnList, [dataList]);

  return (
    <Table.Layout style={style} className={className}>
      <Table.Header>{heading}</Table.Header>
      <Table.Body>{columnList}</Table.Body>
      <Table.Footer>{footerList}</Table.Footer>
    </Table.Layout>
  );
};
