/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id?: string;
}>;

export const GetCompanyId = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_COMPANY_ID">
      <SelectionSet
        name="metadata_company_relation"
        alias="metadataCompanyRelation"
        argument={{
          first: 1,
          where: {
            metadata__some: {
              metadata_id: $id
            }
          }
        }}
      >
        <SelectionSet name="company">
          <Field name="company_id" alias="id" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataCompanyRelation: {
      company: {
        id: string;
      }[];
    }[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return !data.metadataCompanyRelation.length ? undefined : data.metadataCompanyRelation[0].company[0].id;
};
