/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
}>;

export const DeleteMetadataCompany = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_METADATA_COMPANY_RELATION">
      <SelectionSet
        name="deleteMetadata_company_relation"
        alias="deleteCompany"
        argument={{
          where: { id: $uuid }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteCompany: Readonly<{
      id: string[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
