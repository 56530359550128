/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { Progress } from "App/Atomics/Progress";
import { MARGING_LARGE_PX, MARGING_SMALL_PX, PADDING_XX_LARGE_PX, pixelize, UNIT } from "constants/size";
import itiriri from "itiriri";
import { gql } from "lib/gql-tag";
import React, { useState } from "react";
import styled from "styled-components";
import { clients } from "utils/clients";
import { SingleAlbumStoreProvider, useSingleAlbumDispatch } from "../../Store";
import axios from "axios";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAppDispatch, useAppSelector } from "App/Store";
import { UserTokenActions } from "App/Store/UserToken";
import { SingleAlbum, Track } from "../../Store/SingleAlbum";
import { s3SingleUpload } from "../../Query/s3SingleUpload";

type Props = Readonly<{
  album: SingleAlbum;
  trackList: Track;
  onClick: () => void;
}>;

type LoadedData = {
  type: string;
  name: string;
  loaded: number | undefined;
  total: number | undefined;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(40 * UNIT)};
  padding: ${PADDING_XX_LARGE_PX};

  h3 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const ProgressField = styled.div`
  display: grid;
  grid-template-columns: ${pixelize(10 * UNIT)} ${pixelize(10 * UNIT)} auto;
  width: 100%;
  align-items: baseline;

  & + div {
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const reduceCreateAccessQuery = (result: string, trackId: any) =>
  result +
  `
    reqAccess${trackId}: createAccess_record(data: { target_id: "${trackId}", target_table: metadata }) {
      id
      target_id
      target_table
    }
  `;

const reduceRemoveAccessQuery = (result: string, record: any) =>
  result +
  `
    deleteReqAccess${record.target_id}:
      deleteAccess_record(where: { id: "${record.id}" })
  `;

const createAccessQuery = (table: string = "metadata") => gql`
  mutation CREATE_ACCESS_QUERY($id: String) {
    createAccess_record(data: { target_id: $id, target_table: ${table} }) {
      id
      target_id
      target_table
    }
  }
`;

const removeMetadataQuery = gql`
  mutation REMOVE_METADATA_QUERY($id: ID) {
    deleteMetadata(where: { metadata_id: $id }) {
      metadata_id
    }
  }
`;

const getStructureQuery = gql`
  query GET_STRUCTURE_QUERY($rootId: Int) {
    structureList: metadata_structure(first: 200, orderBy: structure_id__DESC, where: { structure_root_id: $rootId }) {
      metadata_id
      structure_id
    }
  }
`;

const serialValidateQuery = gql`
  query METADATA_SERIAL_VALIDATE($no: String) {
    metadata(where: { AND: [{ no: $no }, { type_metadata_class: "record", type_metadata_subclass: "album" }] }) {
      metadata_id
    }
  }
`;

const removeStructure = async (album: any, structureId: string) => {
  try {
    if (structureId) {
      const { data } = await clients.metadata.query(getStructureQuery, { rootId: parseInt(structureId) });
      data.structureList.forEach((metadata: any) => {
        clients.access
          .mutation(createAccessQuery(), { id: metadata.metadata_id })
          .then(() => clients.metadata.mutation(removeMetadataQuery, { id: metadata.metadata_id }));
      });
    } else {
      clients.access
        .mutation(createAccessQuery(), { id: album.metadata_id })
        .then(() => clients.metadata.mutation(removeMetadataQuery, { id: album.metadata_id }));
    }
  } catch {}
};

const ProgressItem = (props: { value: LoadedData }) => {
  const percent = props.value.loaded && props.value.total ? (props.value.loaded / props.value.total) * 100 : 0;
  return (
    <>
      <span>{props.value.type}</span>
      <span>{props.value.name}</span>
      <Progress style={{ width: "100%" }} percent={percent} />
    </>
  );
};

const ProgressLayout = (props: { loadedData: LoadedData[] }) => {
  return (
    <>
      {props.loadedData.length &&
        props.loadedData.map((value, index) => (
          <ProgressField key={index}>
            <ProgressItem value={value as LoadedData} />
          </ProgressField>
        ))}
    </>
  );
};

const albumSerialValidate = async (value: string) => {
  const { data } = await clients.metadata.query(serialValidateQuery, { no: value });

  if (!data.metadata.length) {
    return false;
  }

  return true;
};

const createAlbumLog = async (album: SingleAlbum, singleUpload: any) => {
  let createAlbumQuery = `
  createMetadata(
    data: {
      no: "${album.no}"
      title: "${album.title}"
      type_metadata_class: "${album.type_metadata_class}"
      type_metadata_subclass: "${album.type_metadata_subclass}"
  `;
  createAlbumQuery += `
      ${
        singleUpload
          ? `metadata_url: {
              create: [{
                type_url: "cover"
                url: "${singleUpload.key}"
              }]
            }`
          : ""
      }
      metadata_structure: {
        create: [{ parent_id: 0 }]
      }
    }
      ) {
      metadata_id
      metadata_structure {
        structure_id
      }
    }
  `;

  try {
    const query = gql`
      mutation CREATE_ALBUM {
        ${createAlbumQuery}
      }
    `;

    const { data, errors } = await clients.metadata.mutation(query);
    if (errors) {
      throw errors;
    }

    return { value: data.createMetadata, singleUpload };
  } catch (e) {
    console.log("서버 오류로 앨범 생성에 실패했습니다.");
    return;
  }
};

const createTracksLog = async (albumId: string, track: Track, parentId: number) => {
  const { trackTitle, trackGenre, trackMood, trackLicense, trackLicenseExtra, work } = track;
  const GenreMood = trackGenre.concat(trackMood);
  let createTrackDataQuery = `
    no: "${track.no}",
    title: "${track.title}",
    type_metadata_class: "${track.type_metadata_class}",
    type_metadata_subclass: "${track.type_metadata_subclass}"
  `;

  if (trackTitle) {
    createTrackDataQuery += `
      metadata_title: {
        create: [{
          language: {
            connect: {
              language_code: "XX"
            }
          }
          type_metadata_title: "description"
          value: "${trackTitle.value}"
        }]
      }
    `;
  }
  if (work) {
    createTrackDataQuery += `
      metadata_self_relations_list_metadata: {
        create: [{
          type_function: "works"
          metadata_self_relations_element_metadata: {
            connect: {
              metadata_id: "${work.id}"
            }
          }
        }]
      }
    `;
  }

  if (GenreMood.length) {
    createTrackDataQuery += `
      metadata_genre_relation: {
        create: [
          ${GenreMood.map(genre => `{ genre: { connect: { genre_id: "${genre.id}" } } }`).join(" ")}
        ]
      }
    `;
  }
  if (trackLicense) {
    createTrackDataQuery += `
      track_license: {
        create: [{
          copyright: true
          neighboring: true
          ${itiriri(Object.entries(trackLicense)).toArray(([key, value]) => `${key}: ${JSON.stringify(value)}`)}
        }]
      }
    `;
  }

  if (trackLicenseExtra) {
    createTrackDataQuery += `
      track_license_extra: {
        create: [{
          ${itiriri(Object.entries(trackLicenseExtra)).toArray(([key, value]) => `${key}: ${value}`)}
        }]
      }
    `;
  }

  const createTrackQuery = `
    createMetadata(
      data: {
        ${createTrackDataQuery}
        metadata_structure: {
          create: [{ parent_id: ${parentId} }] 
        }
      }) {
        metadata_id
    }
  `;
  try {
    const query = gql`
      mutation CREATE_TRACKS {
        ${createTrackQuery}
      }
    `;
    return await clients.metadata.mutation(query);
  } catch (e) {
    console.log("서버 오류로 트랙 생성에 실패했습니다.");
    removeStructure(albumId, "");
    return;
  }
};

const updateMetadataSource = async (trackId: string, no: string, typeUrl: string, url: string) => {
  if (url) {
    const updateMetadataQuery = `
      updateMetadata (
        where: {
          metadata_id: "${trackId}"
        }
        data: {
          _onlySearch: true
          metadata_url: {
            create: [{
              type_url: "${typeUrl}"
              url: "${url}"
            }]
          }
        }
      ) {
        metadata_id
      }
    `;

    const query = gql`
      mutation UPDATE_TRACK_SOURCE {
        ${updateMetadataQuery}
      }
    `;

    await clients.metadata.mutation(query);
  }
};

const albumFormData = (singleUpload: any, file: File) => {
  const formData = new FormData();
  formData.append("key", `${singleUpload.key}`);
  formData.append("Policy", singleUpload.Policy);
  formData.append("X-Amz-Credential", singleUpload.X_Amz_Credential);
  formData.append("X-Amz-Date", singleUpload.X_Amz_Date);
  formData.append("X-Amz-Signature", singleUpload.X_Amz_Signature);
  formData.append("X-Amz-Algorithm", singleUpload.X_Amz_Algorithm);
  formData.append("file", file);

  return formData;
};

const FileUploadModalForm = ({ album, trackList, onClick }: Props) => {
  const dispatchApp = useAppDispatch();
  const dispatch = useSingleAlbumDispatch();
  const accessRecord = useAppSelector(store => store.UserToken.accessRecord);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeStamp, setTimeStamp] = useState(0);
  const [loadedData, setLoadedData] = useState(
    [{ type: "Album Cover", name: album.title, loaded: undefined, total: undefined }].concat({
      type: "Track",
      name: trackList.title,
      loaded: undefined,
      total: undefined
    })
  );

  const [albumId, setAlbumId] = useState("");
  const [structureId, setStructureId] = useState("");
  const [failed, setFailed] = useState(false);

  useAsyncEffect(async () => {
    try {
      if (album && trackList) {
        if (!albumSerialValidate(album.no)) {
          alert("앨범 시리얼이 중복됩니다.");
          return;
        }
        try {
          let coverData;
          if (album.albumUrl && album.albumUrl.file) {
            const { data, errors } = await s3SingleUpload({ filename: album.albumUrl.url, action: "INSERT" });
            if (!data || errors) {
              throw errors;
            }

            coverData = data.singleUpload;
          }
          createAlbumLog(album, coverData)
            .then(async ({ value, singleUpload }: any) => {
              const parentId = value.metadata_structure[0].structure_id;
              setAlbumId(value.metadata_id);
              setStructureId(parentId);

              if (singleUpload) {
                axios({
                  method: "post",
                  url: singleUpload.url,
                  data: albumFormData(singleUpload, album.albumUrl.file),
                  onUploadProgress: progressEvent => {
                    loadedData[0].loaded = progressEvent.loaded;
                    loadedData[0].total = progressEvent.total;
                    setTimeStamp(progressEvent.timeStamp);
                    setLoadedData(loadedData);
                  },
                  headers: {
                    "Content-Type": album.albumUrl.typeUrl
                  }
                });
              }

              createTracksLog(value.metadata_id, trackList, parentId)
                .then(async ({ data }: any) => {
                  const trackIds = itiriri(Object.entries(data))
                    .map(([key, track]: [string, any]) => track.metadata_id)
                    .toArray();
                  const createAccessRecord = gql`
                      mutation REQUEST_ACCESS_RECORD {
                        ${trackIds.reduce(reduceCreateAccessQuery, "")}
                      }
                    `;

                  const { data: albumAccessRecord } = await clients.access.mutation(createAccessRecord);
                  dispatchApp(UserTokenActions.setAccessRecord(albumAccessRecord));

                  Promise.all(
                    Object.entries(trackList.trackUrl).map(async ([key, url]) => {
                      if (url.url) {
                        try {
                          const { data, errors } = await s3SingleUpload({
                            filename: url.url,
                            action: "INSERT"
                          });
                          if (!data || errors) {
                            throw errors;
                          }

                          axios({
                            method: "post",
                            url: data.singleUpload.url,
                            data: albumFormData(data.singleUpload, url.file),
                            onUploadProgress: progressEvent => {
                              loadedData[1].loaded = progressEvent.loaded;
                              loadedData[1].total = progressEvent.total;
                              setTimeStamp(progressEvent.timeStamp);
                              setLoadedData(loadedData);
                            },
                            headers: {
                              "Content-Type": "multipart/form-data"
                            }
                          })
                            .then(() => updateMetadataSource(trackIds[0], trackList.no, url.typeUrl, data.singleUpload.key))
                            .catch(e => {
                              alert("파일 업로드에 실패했습니다.");
                              removeStructure(value.metadata_id, parentId);
                              setFailed(true);
                              throw e;
                            });
                        } catch (e) {
                          throw e;
                        }
                      }
                    })
                  ).catch(e => {
                    throw e;
                  });
                })
                .catch(e => {
                  throw e;
                });
            })
            .catch(e => {
              throw e;
            });
        } catch (e) {
          removeStructure(albumId, structureId);
          setFailed(true);
          alert("서버에서 에러가 발생했습니다.");
          return;
        }
      }
    } catch (e) {
      removeStructure(albumId, structureId);
      setFailed(true);
      alert("서버에서 에러가 발생했습니다.");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTimeStamp]);

  const closeModal = () => {
    const deleteAccessQuery = gql`
      mutation DELETE_ACCESS_RECORD {
        ${Object.values(accessRecord).reduce(reduceRemoveAccessQuery, "")}
      }
    `;

    clients.access.mutation(deleteAccessQuery);
    onClick();
  };

  return (
    <Layout>
      <h3>음원을 업로드 중입니다.</h3>
      <Divider />
      <ProgressLayout loadedData={loadedData} />
      <Divider />
      <Input.Button
        color="danger"
        onClick={closeModal}
        disabled={!failed && !(loadedData.length > 0 && loadedData.every(data => data.loaded === data.total))}
      >
        닫기
      </Input.Button>
    </Layout>
  );
};

export const FileUploadModal = ({ album, trackList, onClick }: Props) => (
  <SingleAlbumStoreProvider>
    <FileUploadModalForm album={album} trackList={trackList} onClick={onClick} />
  </SingleAlbumStoreProvider>
);
