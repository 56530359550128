import { Helmet } from "App/Atomics/Helmet";
import { useAppDispatch } from "App/Store";
import { AudioPlayerActions } from "App/Store/AudioPlayer";
import { LoadingAction } from "App/Store/Loading";
import { Filters } from "App/Templates/FilterModalTemplate";
import { LOADING } from "App/Templates/LoadingTemplate";
import { SIGN } from "constants/route";
import { MetadataPlaylistRelation } from "GraphQL/Queries/Playlist";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { Edge } from "models/Edge";
import React, { useMemo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { LeftSideBar } from "./LeftSidebar";
import { PlaylistDetailTable } from "./PlaylistDetailTable";
import { PlaylistDetailStoreProvider, usePlaylistDetailDispatch } from "./Store";
import { PlaylistDetailActions } from "./Store/PlaylistDetail";

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

const ChildLayout = () => {
  const router = useHistory();
  const dispatchApp = useAppDispatch();
  const queryParams = useQueryParams();
  const searchId = queryParams.get("id", { default: undefined });
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const count = queryParams.get("count", { default: 30, cast: v => +v });
  const dispatch = usePlaylistDetailDispatch();

  const filter = useMemo(() => {
    const { artist, validCheck, order, genre, mood } = JSON.parse(queryParams.get("filter", { default: undefined }) || "{}") as Filters;
    return { artist, validCheck, order, genre, mood };
  }, [queryParams]);
  const { artist, validCheck, order, genre, mood } = filter;
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  const genreMoodList = [...(genre ?? []), ...(mood ?? [])];
  useAsyncEffect(
    async isMounted => {
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      try {
        const { data, errors } = await MetadataPlaylistRelation({
          playlistId: searchId,
          count,
          page,
          order,
          validCheck: validCheckIn,
          artistIdIn: artist?.map(({ id }) => id),
          genreIdIn: !genreMoodList.length ? undefined : genreMoodList.map(item => item.id)
        });
        if (!isMounted()) {
          return;
        }
        if (errors) {
          dispatchApp(AudioPlayerActions.toggleVisible(false));
          router.push(SIGN);
        }
        if (data) {
          dispatch([
            PlaylistDetailActions.setPlaylistDetail(data.metadataPlaylistRelation),
            PlaylistDetailActions.setEdge(data.edge as Edge)
          ]);
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      } catch {
        alert("서버에서 에러가 발생했습니다.");
      }
    },
    [page, searchId, count, filter]
  );
  return (
    <Layout>
      <LeftSideBar />
      <PlaylistDetailTable />
    </Layout>
  );
};

export const PlaylistDetail = () => (
  <PlaylistDetailStoreProvider>
    <Helmet title="플레이리스트 상세" />
    <ChildLayout />
  </PlaylistDetailStoreProvider>
);
