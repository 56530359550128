import { Album } from "../../Store/Album";
import { SingleUpload } from "App/Routes/SingleAlbumCreate/Query/s3SingleUpload";
import { clients } from "utils/clients";
import { gql } from "lib/gql-tag";

export const createAlbumLog = async (album: Album, singleUpload?: SingleUpload, isEffect?: boolean) => {
  const { albumArtist, albumCompany, productions, publications } = album;
  const titleNoSpace = album.title === album.title.replace(/\s/gi, "") ? undefined : album.title.replace(/\s/gi, "");
  let createAlbumQuery = `
  createMetadata(
    data: {
      no: "${album.no}"
      title: "${album.title}"
      type_metadata_class: "${album.type_metadata_class}"
      type_metadata_subclass: "${album.type_metadata_subclass}"
      valid_check: REMOVE
  `;

  createAlbumQuery += `
      ${
        isEffect
          ? ""
          : `metadata_artist_relation: {
        create: [
          ${albumArtist
            .map(
              ({ artist_id, role_id, order }) => `{
            artist: { connect: { artist_id: "${artist_id}" } },
            role: { connect: { role_id: "${role_id}" } },
            exposure_order: ${order}
          }`
            )
            .join(", ")}
        ]
      }`
      }

      ${
        singleUpload
          ? `metadata_url: {
        create: [{
          type_url: "cover"
          url: "${singleUpload.key}"
        }]
      }`
          : ""
      }

      metadata_company_relation: {
        create: [
          { 
            company: {
              connect: { company_id: "${albumCompany.company_id}" } 
            }
            type_kind: rightCompany,
            exposure_order: 0
          }
          ${
            productions && productions.length
              ? productions
                  .map(({ company_id }, order) => {
                    return `{ 
                company: {
                  connect: { company_id: "${company_id}" }
                } 
                type_kind: production,
                exposure_order: ${order}
              }`;
                  })
                  .join(" ")
              : ""
          }
            ${
              publications && publications.length
                ? publications
                    .map(({ company_id }, order) => {
                      return `{ 
                  company: {
                    connect: { company_id: "${company_id}" }
                  }
                  type_kind: publication,
                  exposure_order: ${order}
                }`;
                    })
                    .join(" ")
                : ""
            }
        ]
      }

      metadata_title: {
        create: [{
          language: {
            connect: {
              language_code: "OO"
            }
          },
          type_metadata_title: "name",
          value: "${album.title}",
          exposure_order: 0
        }${
          !titleNoSpace
            ? ""
            : `,{
          language: {
            connect: {
              language_code: "OO"
            }
          },
          type_metadata_title: "name",
          value: "${titleNoSpace}",
          exposure_order: 1
        }`
        } ]
      },
      metadata_structure: {
        create: [{ parent_id: 0 }]
      }
    }
      ) {
      metadata_id
      metadata_structure {
        structure_id
      }
    }
  `;

  try {
    const query = gql`
      mutation CREATE_ALBUM {
        ${createAlbumQuery}
      }
    `;

    const { data, errors } = await clients.metadata.mutation(query);

    if (errors) {
      throw errors;
    }

    return { value: data.createMetadata, singleUpload };
  } catch (e) {
    console.log("서버 오류로 앨범 생성에 실패했습니다.");
    return;
  }
};
