import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { TableInfoActions as TableInfo } from "./TableInfo";
import { TrackActions as Track } from "./Track";

const reducers = { TableInfo, Track };

const reducer = combineReducers(reducers, createStoreLogger("WorkTrackEdit"));

export const {
  StoreProvider: TracksStoreProvider,
  useStore: useTracksStore,
  useSelector: useTracksSelector,
  useDispatch: useTracksDispatch
} = createStore(reducer);
