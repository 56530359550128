/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  name: string;
  type: string;
  companyId?: string;
  ytvCode?: string;
}>;

export const CreateArtist = async (option: Option) => {
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={option.name} />;
  const $name_no_space = (
    <Variable name="name_no_space" scalar={<StringScalar isNullable={false} />} value={option.name.replace(/\s/gi, "")} />
  );
  const $ytvCode = <Variable name="ytvCode" scalar={<StringScalar isNullable={false} />} value={option.ytvCode} />;
  const $type = <Variable name="type_artist_class" scalar={<StringScalar isNullable={false} />} value={option.type} />;
  const $companyId = <Variable name="company_id" scalar={<IdScalar isNullable={true} />} value={option.companyId} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ARTIST">
      <SelectionSet
        name="createArtist"
        argument={{
          data: {
            name: $name,
            type_artist_class: $type,
            artist_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_artist_title: "name",
                  value: $name,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_artist_title: "name",
                  value: $name_no_space,
                  exposure_order: 1
                },
                !option.ytvCode?.length
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_artist_title: "name_youtube",
                      value: $ytvCode,
                      exposure_order: 0
                    }
              ]
            },
            artist_company_relation: {
              create: [{ company: { connect: { company_id: $companyId } } }]
            }
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createArtist: {
      artist_id: string;
    };
  }>;

  const { errors, data } = await clients.artist.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }

  return data!.createArtist.artist_id;
};
