/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  languageCode: string;
  typeTitle: string;
  value: string;
  order: number;
  displayCode: string;
}>;

export const CreateLanguageLocale = async (option: Option) => {
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $typeTitle = <Variable name="type_title" scalar={<StringScalar isNullable />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $displayCode = <Variable name="displayCode" scalar={<IdScalar isNullable={false} />} value={option.displayCode} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_LANGUAGE_LOCALE">
      <SelectionSet
        name="updateLanguage"
        alias="createLanguageLocale"
        argument={{
          where: {
            language_code: $languageCode
          },
          data: {
            _onlySearch: true,
            language_title: {
              create: [
                {
                  type_title: $typeTitle,
                  value: $value,
                  display_code: {
                    connect: {
                      language_code: $displayCode
                    }
                  },
                  exposure_order: $order
                }
              ]
            }
          }
        }}
      >
        <Field name="language_code" alias="languageCode" />
        <SelectionSet name="language_title" alias="languageTitle">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createLanguageLocale: {
      languageCode: string;
      languageTitle: {
        uuid: string;
      }[];
    };
  }>;

  const { data, errors } = await clients.language.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.createLanguageLocale.languageTitle[0].uuid;
};
