import React, { ComponentType } from "react";
import styled from "styled-components";
import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findArtistRenewal } from "./findArtistRenewal";
import { components, OptionProps } from "react-select";
import { GRAY_5 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  | "style"
  | "className"
  | "classNamePrefix"
  | "isDisabled"
  | "defaultValue"
  | "defaultOptions"
  | "onChange"
  | "onBlur"
  | "autoFocus"
  | "placeholder"
> & { typeArtistClass?: string };

export const ArtistTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  autoFocus,
  placeholder = "아티스트를 입력해주세요.",
  typeArtistClass
}: Props) => {
  return (
    <TextAutoComplete
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      autoFocus={autoFocus}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findArtistRenewal(keyword, typeArtistClass)}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="id">{props.data.id}</span>
        <span className="name">{props.data.name}</span>
        <span className="extra">{props.data.type}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .id {
    font-size: 9px;
    color: ${GRAY_5};
  }
  .name {
    font-size: 13px;
    font-weight: 500;
  }
  .extra {
    font-size: 10px;
    font-weight: 400;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;
