import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX, PADDING_XX_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE } from "constants/size";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_4, GRAY_0 } from "constants/baseColor";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { useRolesStore } from "App/Routes/Roles/Store";
import { CreateRoleTitle } from "GraphQL/Queries/Role";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { RoleActions } from "App/Routes/Roles/Store/Role";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Loading } from "App/Atomics/Loading";

type Props = {
  roleId: string;
  typeClass: string;
  order: number;
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 27)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
`;

const Multiline = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 8)};
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
  }

  .typeSelect {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  font-size: 1rem;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

const TypeInfo = [{ id: "name", name: "name" }];

export const CreateRoleTitleModal = ({ roleId, typeClass, order, toClose }: Props) => {
  const [type, setType] = useState<string>("");
  const [languageCode, setLanguageCode] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [{ editLoading }, dispatch] = useRolesStore(store => store.Role);

  const createRoleTitle = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(RoleActions.setRoleEditLoading(true));
    try {
      const accessId = await requestAccessRecord({ targetId: roleId, targetTable: TargetTableInput.Role });
      if (accessId) {
        const { data: roleData } = await CreateRoleTitle({
          roleId,
          typeClass,
          type,
          languageCode,
          value,
          order
        });
        if (roleData) {
          dispatch(RoleActions.createRoleTitle(roleId, type, value, languageCode));
        }
        await DeleteAccessRecord({ id: accessId });
        dispatch(RoleActions.setRoleEditLoading(false));
        Toast.primary("추가되었습니다.", undefined, "bottom-right");
        toClose();
      }
    } catch (e) {
      console.log(e);
      dispatch(RoleActions.setRoleEditLoading(false));
      Toast.error("생성에 실패하였습니다.", undefined, "top-center");
      return;
    }
  };

  return (
    <Layout>
      <RowGroup>
        <h2>추가</h2>
      </RowGroup>
      <RowGroup>
        <h4>타입 선택</h4>
        <Input.TextSelect
          className="typeSelect"
          optionList={TypeInfo}
          onChange={info => {
            if (info) {
              setType(info.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>언어 선택</h4>
        <LanguageTextSelect
          className="typeSelect"
          onChange={info => {
            if (info) {
              setLanguageCode(info.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>설명</h4>
        <Multiline placeholder="설명을 입력하세요" defaultValue="" onChange={e => setValue(e.target.value)} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="cancelButton" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button disabled={!type.length || !languageCode.length || !value.length} onClick={e => createRoleTitle(e)}>
          추가
        </Input.Button>
      </ButtonGroup>
      <Loading loading={editLoading} />
    </Layout>
  );
};
