import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions, Section } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { Style } from "../../../Style";
import { ReactComponent as LeftIcon } from "assets/icons/angle-left.svg";
import { useScrollToTop } from "App/Routes/GoodsCreate/Hooks/useScrollToTop";
import { useCreateGoods } from "App/Routes/GoodsCreate/Hooks/useCreateGoods";
import { Toast } from "lib/toast";
import { Loading } from "App/Atomics/Loading";
import { useCreateImage } from "App/Routes/GoodsCreate/Hooks/useCreateImage";
import { ClipBoard } from "App/Molecules/ClipBoard";

export const Third = (): ReactElement => {
  const goPrev = () => GoodsCreateStore.dispatch(GoodsCreateActions.setSection(Section.Second));
  const goFirst = () => {
    GoodsCreateStore.dispatch(GoodsCreateActions.setSection(Section.First));
    GoodsCreateStore.clear();
  };
  const [id, setId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { createGoods } = useCreateGoods();
  const { createImage } = useCreateImage();
  useScrollToTop();

  const tryToCreate = async () => {
    setLoading(true);
    try {
      const { data, errors } = await createGoods();
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (data) {
        const { id } = data.createGoods;
        setId(id);
        await createImage(id);
      }
      Toast.primary("성공적으로 생성되었습니다.");

      setSuccess(true);
    } catch (err) {
      console.log(err);
      Toast.error("생성에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container>
        {!success ? <Style.Title>등록하기</Style.Title> : <Style.Title>성공적으로 등록되었습니다.</Style.Title>}
        {id && success ? (
          <Content>
            <Title>{`굿즈 아이디`}</Title>
            <ClipBoard text={id}>
              <Text>{id}</Text>
            </ClipBoard>
          </Content>
        ) : null}
        <Style.Row>
          <Style.ButtonGroup>
            {!success ? (
              <Style.ButtonGroup>
                <Style.IconButton onClick={goPrev}>
                  <LeftIcon />
                </Style.IconButton>
                <Style.IconButton onClick={tryToCreate}>등록</Style.IconButton>
              </Style.ButtonGroup>
            ) : (
              <Style.ButtonGroup>
                <Style.IconButton style={{ width: "100px" }} onClick={goFirst}>
                  초기화
                </Style.IconButton>
              </Style.ButtonGroup>
            )}
          </Style.ButtonGroup>
        </Style.Row>
      </Container>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div``;

const Container = styled.div``;
const Content = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 16px;
`;
const Text = styled.span`
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
