/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  id?: string;
  typeTitle?: string | null;
  valueIn?: string[];
  languageCode?: string | null;
}>;

export type Title = {
  uuid: string;
  typeTitle: string;
  value: string;
  validCheck: ValidType;
  order: number;
  language: {
    languageCode: string;
  }[];
};

export const GetMetadataTitleRelation = async (option: Option) => {
  const $id = <Variable name="metadata_id" scalar={<IdScalar />} value={option.id} />;
  const $typeTitle = <Variable name="type_title" scalar={<StringScalar isNullable />} value={option.typeTitle} />;
  const $valueIn = <ListScalar scalar={<StringScalar isNullable={false} />} value={option.valueIn} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar isNullable />} value={option.languageCode} />;
  const { query, variables } = render(
    <Query operationName="GET_METADATA_TITLE_RELATION">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          where: {
            metadata_id: $id,
            metadata_title__some: {
              value__in: !option.valueIn?.length ? undefined : $valueIn,
              language__some: {
                language_code: $languageCode
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet
          name="metadata_title"
          alias="titleRelation"
          argument={{ first: 99, where: { type_metadata_title__not_starts_with: "search", type_metadata_title: $typeTitle } }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <Field name="type_metadata_title" alias="typeTitle" />
          <Field name="value" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="language">
            <Field name="language_code" alias="languageCode" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: Readonly<{
      id: string;
      titleRelation: Title[];
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
