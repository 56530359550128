import React from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, PADDING_LARGE_PX, MARGING_LARGE_PX } from "constants/size";
import { Divider } from "App/Atomics/Divider";
import { WHITE } from "constants/baseColor";
import { DualListboxItem, DualListbox, DualListboxItemProps } from "App/Molecules/DualListbox";
import { TrackActions, Track } from "../../Store/TrackList";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAlbumDetailStore } from "../../Store";
import { StructureActions } from "../../Store/Structure";
import { Input } from "App/Atomics/Input";
import { toEditStructureLog } from "./toEditStructureLog";

type Structure = {
  structureId: string;
  structureRootId: number;
};

type Metadata = {
  metadataId: string;
  title: string;
  typeClass: string;
  typeSubClass: string;
  validCheck: string;
  artistReltaion: any[];
  companyRelation: any[];
  titleRelation: any[];
  structures: Structure[];
};

type Props = Readonly<{
  index: number;
  metadataStructure: any;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 70)};
  height: ${pixelize(UNIT * 40)};
  padding: ${PADDING_XX_LARGE_PX};
`;

const ItemLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: ${PADDING_LARGE_PX};

  & > button {
    width: ${pixelize(UNIT * 3)};
    height: ${pixelize(UNIT * 3)};
    margin-left: auto;
    padding: ${PADDING_LARGE_PX};

    & > svg {
      fill: ${WHITE};
      width: ${pixelize(UNIT * 2)};
      height: ${pixelize(UNIT * 2)};
    }
  }
`;

const DataItem = styled.div`
  & > div {
    word-break: break-all;
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

const TrackName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 25rem;
`;

const Item = (item: DualListboxItemProps<any>) => {
  return (
    <ItemLayout>
      <DataItem>
        <div>[ {item.id} ]</div>
        <TrackName>{item.value}</TrackName>
      </DataItem>
    </ItemLayout>
  );
};

export const EditTextModal = ({ index, metadataStructure, toClose }: Props) => {
  const [{ structures, searchedTrackList, selectedTrackList, events }, albumDetailDispatch] = useAlbumDetailStore(store => ({
    structures: store.Structure.structures,
    searchedTrackList: store.TrackList.searchedTrackList.map(({ id, value, extra }) => ({ id, value, extra })),
    selectedTrackList: store.TrackList.selectedTrackList.map(({ id, value, extra }) => ({ id, value, extra })),
    events: store.Structure.events
  }));

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
      }
    },
    [selectedTrackList]
  );

  const setSelectedTrackList = (itemList: readonly DualListboxItem<any>[]) => {
    albumDetailDispatch([
      TrackActions.set({
        kind: "selected",
        tracks: itemList.map(({ id, value, extra }) => ({ id, value, extra }))
      }),
      StructureActions.movedAlbumTrack({ index, preTracks: selectedTrackList, tracks: itemList as Track[] })
    ]);
  };

  return (
    <Layout>
      <h3>앨범 Text 추가/수정</h3>
      <Divider />
      <DualListbox
        style={{ height: pixelize(UNIT * 30), marginBottom: MARGING_LARGE_PX }}
        left={{ label: "앨범의 전체 트랙 리스트", items: searchedTrackList }}
        right={{ label: "그룹에 담을 트랙 리스트", items: selectedTrackList }}
        Item={Item}
        isLoading={false}
        onChange={setSelectedTrackList}
      />
      <ButtonGroup>
        <Input.Button
          onClick={() => {
            toEditStructureLog(structures[index], metadataStructure[0], events).then(() =>
              albumDetailDispatch([StructureActions.clearEventLog(), StructureActions.toggleMountedFlag()])
            );
            toClose();
          }}
        >
          저장
        </Input.Button>
        <Input.Button onClick={toClose}>닫기</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
