/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IdScalar, IntScalar, FloatScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar } from "GraphQL/Scalars";
import { GoodsSubdata } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Option = Readonly<{
  name: string;
  globalName: string;
  subtitle: string;
  details?: string;
  tags?: string;
  typeId: number;
  weight?: number;
  createdAt: string;
  artistIds: string[];
  workIds: string[];
  companyId: string;
  officialUrl?: string;
  askPrice: number;
  officialPrice: number;
  officialBarcode?: string;
  subdatas: GoodsSubdata[];
  parentIds: number[];
}>;

export const CreateGoods = async (option: Option) => {
  const replacedName = option.name.replace(/\s/gi, "");
  const nameNoSapce = option.name === replacedName ? null : replacedName;
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={option.name} />;
  const $name_no_space = <Variable name="name_no_space" scalar={<StringScalar isNullable={false} />} value={nameNoSapce} />;
  const $globalName = <Variable name="globalName" scalar={<StringScalar isNullable={false} />} value={option.globalName} />;
  const $subtitle = <Variable name="subtitle" scalar={<StringScalar isNullable={false} />} value={option.subtitle} />;
  const $details = <Variable name="details" scalar={<StringScalar isNullable={false} />} value={option.details} />;
  const $tags = <Variable name="tags" scalar={<StringScalar isNullable={false} />} value={option.tags?.trim()} />;
  const $typeId = <Variable name="typeId" scalar={<IntScalar isNullable={false} />} value={option.typeId} />;
  const $weight = <Variable name="weight" scalar={<IntScalar isNullable />} value={option.weight} />;
  const $officialPrice = <Variable name="officialPrice" scalar={<FloatScalar />} value={option.officialPrice} />;
  const $officialBarcode = <Variable name="officialBarcode" scalar={<StringScalar isNullable />} value={option.officialBarcode} />;
  const $askPrice = <Variable name="askPrice" scalar={<FloatScalar />} value={option.askPrice} />;
  const $createdAt = <Variable name="createdAt" scalar={<DateTimeScalar isNullable={false} />} value={option.createdAt} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar isNullable={false} />} value={option.companyId} />;
  // const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable={false} />} value={option.parentId} />;

  const { query, variables } = render(
    <Mutation operationName="CREATE_GOODS">
      <SelectionSet
        name="createGoods"
        argument={{
          data: {
            name: $name,
            type_id: $typeId,
            weight: $weight,
            official_price: $officialPrice,
            official_barcode: $officialBarcode,
            ask_price: $askPrice,
            created_at: $createdAt,
            goods_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_title: "name",
                  value: $name,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "EN"
                    }
                  },
                  type_title: "name",
                  value: $globalName,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_title: "subtitle",
                  value: $subtitle,
                  exposure_order: 0
                },
                !nameNoSapce
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_title: "name",
                      value: $name_no_space,
                      exposure_order: 1
                    },
                !option.details?.length
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_title: "description",
                      value: $details,
                      exposure_order: 0
                    },
                !option.tags?.length
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_title: "tag",
                      value: $tags,
                      exposure_order: 0
                    }
              ]
            },
            goods_artist_relation: {
              create: [
                ...option.artistIds.map((artist_id, index) => ({
                  artist: {
                    connect: {
                      artist_id
                    }
                  },
                  exposure_order: index
                }))
              ]
            },
            metadata_goods_relation: {
              create: [
                ...option.workIds.map((metadata_id, index) => ({
                  metadata: {
                    connect: {
                      metadata_id
                    }
                  },
                  exposure_order: index
                }))
              ]
            },
            goods_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  exposure_order: 0
                }
              ]
            },
            goods_url: {
              create: [...(!option.officialUrl ? [] : [{ type_url: "official", url: option.officialUrl, exposure_order: 0 }])]
            },
            goods_subdata_unique: {
              create: [
                ...option.subdatas.map(({ field, category, value }) => ({
                  type_subdata_category: category,
                  type_subdata_field: field,
                  value
                }))
              ]
            },
            goods_structure: {
              create: [...option.parentIds.map((id, index) => ({ parent_id: id, booking_order: index + 1 }))]
            }
          }
        }}
      >
        <Field name="goods_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createGoods: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
