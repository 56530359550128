import { Permission } from "models/Permission";
import { AppStore } from "App/Store";

export const allowTaskStatus = (store: AppStore) => {
  const { allowTables } = store.UserToken;
  for (const allowTable of allowTables) {
    const permission = new Permission(allowTable.allow.split(""));
    switch (allowTable.table) {
      case "arbeit_status":
        if (permission.isOtherReadable()) {
          return true;
        }
        break;
      default:
        break;
    }
  }
  return false;
};
