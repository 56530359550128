import "cropperjs/dist/cropper.css";
import React, { useRef } from "react";
import Cropper from "react-cropper";

type Props = Styleable & {
  src: string;
  aspectRatio: number;
  preview?: string;
  minWidth?: number;
  minHeight?: number;

  onCropping?: () => void;
  onCropEnd?: (blob: null | Blob) => void;
};

export const ImageCrop = ({
  style,
  className,
  src,
  preview,
  minWidth,
  minHeight,
  aspectRatio,
  onCropping = () => {},
  onCropEnd = () => {}
}: Props) => {
  const cropper = useRef<Cropper>(null);

  const handleCrop = () => {
    onCropping();
    cropper.current?.getCroppedCanvas({ minWidth, minHeight, fillColor: "#000" }).toBlob(onCropEnd);
  };

  return (
    <Cropper
      ref={cropper}
      src={src}
      className={className}
      style={style}
      preview={preview}
      ready={handleCrop}
      minCropBoxWidth={minWidth}
      minCropBoxHeight={minHeight}
      aspectRatio={aspectRatio}
      guides={true}
      cropend={handleCrop}
    />
  );
};
