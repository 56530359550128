import { DeleteAccessRecord } from "GraphQL/Queries";
import { CreateMetadataUrl } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { requestAccessRecord } from "lib/requestAccessRecord";

export const handleCreateMetadataUrl = async (
  targetId: string,
  targetTable: TargetTableInput,
  typeUrl: string,
  url: string,
  order: number
) => {
  try {
    const accessId = await requestAccessRecord({ targetId, targetTable });
    const { data: urlData, errors: urlErrors } = await CreateMetadataUrl({
      metadataId: targetId,
      typeUrl,
      url,
      order
    });
    if (urlErrors || !urlData) {
      throw urlErrors;
    }
    await DeleteAccessRecord({ id: accessId! });
    return urlData.createMetadataUrl.metadata_url[0].uuid;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
