import React from "react";
import styled from "styled-components";
import { ReactComponent as ErrorIcon } from "assets/icons/no-data.svg";
import { Input } from "App/Atomics/Input";
import { useHistory } from "react-router";
import { HOME } from "constants/route";

export const ErrorPage = () => {
  const router = useHistory();
  const goHome = () => router.push(HOME);
  return (
    <Layout>
      <ErrorIcon />
      <span>페이지를 찾을 수 없습니다.</span>
      <HomeButton color="danger" onClick={goHome}>
        홈으로
      </HomeButton>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 15vw;
    height: 15vw;
    min-width: 10rem;
    min-height: 10rem;
  }
  span {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
`;

const HomeButton = styled(Input.Button)`
  border-radius: 0.8rem;
  font-size: 1.25rem;
  padding: 1.1rem 1.3rem;
`;
