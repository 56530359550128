import { ValidType } from "constants/ValidType";
import { Title } from "GraphQL/Queries/Artist/GetArtistTitleRelation";
import { createDuck } from "lib/store/v2";
import { Edge } from "models/Edge";

const createInitialState = () => ({
  edge: null as null | Edge,
  artists: [] as any[],
  inputDescription: new Map<number, string>(),
  modalId: "" as string,
  checkList: new Map<string, boolean>(),
  autoCompleteSearchMode: "name" as string,
  titleRelation: [] as Title[],
  loading: false as boolean
});

export const ArtistActions = createDuck({
  namespace: "Artist",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setArtist(state, artists: any[]) {
      state.artists = artists;
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setArtistInputDesc(state, { order, desc }: { order: number; desc: string }) {
      state.inputDescription.set(order, desc);
    },
    deleteArtistInputDesc(state, order: number) {
      state.inputDescription.delete(order);
    },
    setArtistTitleDesc(state, titles: Title[]) {
      state.titleRelation = titles;
    },
    createArtistTitleDesc(
      state,
      { id, typeTitle, value, languageCode, order }: { id: string; typeTitle: string; value: string; languageCode: string; order: number }
    ) {
      state.titleRelation.unshift({ uuid: id, typeArtistTitle: typeTitle, value, language: [{ languageCode }], order });
    },
    updateArtistTitleDesc(
      state,
      { index, id, typeTitle, value, languageCode }: { index: number; id: string; typeTitle: string; value: string; languageCode: string }
    ) {
      state.titleRelation[index].typeArtistTitle = typeTitle;
      state.titleRelation[index].value = value;
      state.titleRelation[index].language[0].languageCode = languageCode;
    },
    deleteArtistTitleDesc(state, id: string) {
      const idx = state.titleRelation.findIndex(item => item.uuid === id);
      state.titleRelation.splice(idx, 1);
    },
    setArtistModalId(state, id: string) {
      state.modalId = id;
    },
    setArtistMemo(state, { index, memo }: { index: number; memo: string | null }) {
      state.artists[index].memo = memo;
      state.artists[index].validCheck = ValidType.DONE;
    },
    setArtistVC(state, { index, vc }: { index: number; vc: ValidType }) {
      state.artists[index].validCheck = vc;
    },
    setArtistCheckListInit(state, ids: string[]) {
      ids.forEach(id => {
        state.checkList.get(id) === undefined && state.checkList.set(id, false);
      });
    },
    setArtistCheckList(state, { id, checked }: { id: string; checked: boolean }) {
      state.checkList.set(id, checked);
    },
    clearArtistCheckList(state) {
      state.checkList.clear();
    },
    setArtistAutoCompleteSearchMode(state, mode: string) {
      state.autoCompleteSearchMode = mode;
    },
    createArtistUrl(
      state,
      { index, uuid, typeUrl, url, order }: { index: number; uuid: string; typeUrl: string; url: string; order: number }
    ) {
      state.artists[index].artistUrls.push({
        uuid,
        typeUrl,
        url,
        order
      });
    },
    updateArtistUrl(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.artists[index].artistUrls.findIndex((image: any) => image.uuid === uuid);
      state.artists[index].artistUrls[urlIndex].url = url;
      state.artists[index].artistUrls[urlIndex].typeUrl = typeUrl;
    },
    deleteArtistUrl(state, { index, uuid }: { index: number; uuid: string }) {
      const imageIndex = state.artists[index].artistUrls.findIndex((url: any) => url.uuid === uuid);
      state.artists[index].artistUrls.splice(imageIndex, 1);
    },
    addArtistGenre(
      state,
      { index, uuid, genreId, genreName, order }: { index: number; uuid: string; genreId: string; genreName: string; order: number }
    ) {
      const newGenreRelation = {
        id: uuid,
        validCheck: ValidType.V3,
        order,
        genre: [
          {
            id: genreId,
            name: genreName,
            type: "genre"
          }
        ]
      };
      state.artists[index].genreRelation.push(newGenreRelation);
    },
    removeArtistGenre(state, { index, relationIndex }: { index: number; relationIndex: number }) {
      state.artists[index].genreRelation.splice(relationIndex, 1);
    },
    deleteArtist(state, id: string) {
      state.artists = state.artists.filter(({ artistId }: { artistId: string }) => artistId !== id);
    }
  }
});
