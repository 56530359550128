/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  id: string;
  typeTitle: string;
  value: string;
  languageCode?: string;
  validCheck?: ValidType;
}>;

export const UpdateMetadataTitleRelation = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar />} value={option.id} />;
  const $typeTitle = <Variable name="typeTitle" scalar={<StringScalar />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar isNullable />} value={option.languageCode} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_TITLE_RELATION">
      <SelectionSet
        name="updateMetadata_title"
        alias="updateMetadataTitleRelation"
        argument={{
          where: {
            id: $id
          },
          data: {
            type_metadata_title: $typeTitle,
            value: $value,
            valid_check: $validCheck,
            language: {
              connect: {
                language_code: $languageCode
              }
            }
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateMetadataTitleRelation: {
      id: string;
    };
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
