/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  id: string;
  urls?: { url: string; order: number }[];
}>;

export const CreateCommodityUrlWithOrder = async (option: Option) => {
  const $id = <Variable name="commodity_id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_COMMODITY_URL">
      <SelectionSet
        name="updateCommodity"
        alias="createCommodityUrlByOrder"
        argument={{
          where: {
            commodity_id: $id
          },
          data: {
            _onlySearch: true,
            commodity_url: {
              create: [...(option.urls?.map(({ url, order }) => ({ type_url: "cover", url, exposure_order: order })) ?? [])]
            }
          }
        }}
      >
        <Field name="commodity_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createCommodityUrlByOrder: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
