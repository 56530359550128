import { ServiceType } from "constants/ServiceType";
import { createDuck } from "lib/store/v2";

type Id = string;

export type Track = {
  id: Id;
  title: string;
  duplicated?: boolean;
  audio: {
    type: string;
    url: string;
  };
};

type LoadingState = "None" | "Loading" | "Error";

const createInitialState = () => ({
  state: "None" as LoadingState,
  serviceType: ServiceType.ClassicManager as ServiceType | null,
  kind: "" as string,
  selectedTrackList: [] as readonly Track[],
  searchedTrackList: [] as readonly Track[],
  idSet: new Set<string>()
});

export const PlaylistCreateActions = createDuck({
  namespace: "PlaylistCreate",
  createInitialState,
  reducers: {
    clear(state) {
      state.selectedTrackList = [];
    },
    setSercieType(state, serviceType: ServiceType | null) {
      state.serviceType = serviceType;
    },
    setKind(state, kind: string) {
      state.kind = kind;
    },
    set(state, { kind, tracks }: { kind: "searched" | "selected"; tracks: Track[] }) {
      const key = kind === "searched" ? "searchedTrackList" : "selectedTrackList";
      state[key] = tracks;
    },
    setIdSet(state, idSet: Set<string>) {
      state.idSet = new Set(idSet);
    },
    updateLoadingState(state, loadingState: LoadingState) {
      state.state = loadingState;
    }
  }
});
