import { initializeKeyValue } from "lib/initialize-key-value";

export type Head = ReturnType<typeof sanitizeHeads>["head"];
export type Subhead = ReturnType<typeof sanitizeHeads>["subhead"];

export const sanitizeHeads = (option: Record<string, boolean>) => {
  const { Head: head, Subhead: subhead } = initializeKeyValue(option, {
    Head: {
      "#": true,
      Artist: true,
      Metadata: true,
      Title: true,
      Url: true,
      Company: true,
      License: true,
      LicenseExtra: true,
      Work: true,
      Genre: true,
      Info: true
    },
    Subhead: {
      "#": {
        "#": true
      },
      Artist: {
        artist: true,
        role: true,
        character: true
      },
      Metadata: {
        validCheck: true,
        metadataId: true,
        title: true,
        no: true,
        typeMetadataSubclass: true
      },
      Title: {
        language: true,
        typeMetadataTitle: true,
        value: true
      },
      Url: {
        typeUrl: true,
        url: true,
        fileValidCheck: true
      },
      Company: {
        company: true
      },
      License: {
        license: true
      },
      LicenseExtra: {
        recordYear: true,
        publishYear: true,
        firstEdition: true,
        place: true,
        data: true
      },
      Work: {
        work: true,
        workArtist: true
      },
      Genre: {
        genre: true,
        mood: true
      },
      Info: {
        grade: true,
        channel: true,
        duration: true,
        bitrate: true,
        tempo: true,
        tonality: true,
        timeSignature: true,
        voiceGender: true,
        extraData: true
      }
    }
  });
  return { head, subhead };
};
