/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  typeTitle: string;
  value: string;
  displayCode: string;
}>;

export const UpdateLanguageLocale = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.uuid} />;
  const $typeTitle = <Variable name="typeTitle" scalar={<StringScalar isNullable />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable />} value={option.value} />;
  const $displayCode = <Variable name="language_code" scalar={<IdScalar isNullable />} value={option.displayCode} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_LANGUAGE_LOCALE">
      <SelectionSet
        name="updateLanguage_title"
        alias="updateLanguageTitle"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            type_title: $typeTitle,
            value: $value,
            display_code: {
              connect: {
                language_code: $displayCode
              }
            }
          }
        }}
      >
        <Field name="id" alias="uuid" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateLanguageTitle: {
      uuid: string;
    };
  }>;

  const { errors, data } = await clients.language.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data;
};
