import { createDuck } from "lib/store/v2";

type AlbumInputProps = {
  title?: string;
  companyId?: string;
  artist?: {
    id: string;
    name: string;
  }[];
  name?: string;
};

type Title = {
  id: string;
  type: string;
  order: number;
};

const createInitialState = () => ({
  albumDetail: [] as any[],
  checkList: new Map<string, boolean>(),
  count: 0 as number,
  currentDesc: "" as string,
  modalId: "" as string,
  albumItemList: [
    { key: "metadataId", value: "아이디", isEditMode: false },
    { key: "no", value: "No.", isEditMode: false },
    { key: "title", value: "제목", isEditMode: false },
    { key: "typeSubClass", value: "구분", isEditMode: false },
    { key: "rightCompany", value: "권리사", isEditMode: false },
    { key: "productions", value: "제작사", isEditMode: false },
    { key: "publications", value: "배급사", isEditMode: false },
    { key: "artistRelation", value: "아티스트", isEditMode: false },
    { key: "structures", value: "곡 수", isEditMode: false },
    { key: "titleRelation", value: "설명", isEditMode: false }
  ]
});

export const AlbumDetailActions = createDuck({
  namespace: "AlbumDetail",
  createInitialState,
  reducers: {
    setAlbumDetail(state, albumDetail: any[]) {
      state.albumDetail = albumDetail;
    },
    setAlbumCheckListInit(state, idList: string[]) {
      idList.forEach(id => {
        state.checkList.set(id, false);
      });
    },
    setAlbumCheckList(state, { id, checked }: { id: string; checked: boolean }) {
      state.checkList.set(id, checked);
    },
    editAlbumInputText(state, albumInputs: AlbumInputProps) {
      if (albumInputs.title) {
        state.albumDetail[0].album[0].title = albumInputs.title;
      } else if (albumInputs.companyId) {
        state.albumDetail[0].album[0].companyRelation[0].company[0].id = albumInputs.companyId;
        state.albumDetail[0].album[0].companyRelation[0].company[0].name = albumInputs.name;
      } else if (albumInputs.artist) {
        state.albumDetail[0].album[0].artistRelation = albumInputs.artist.map(({ id, name }) => ({}));
      }
    },
    createAlbumCompany(
      state,
      { typeKind, uuid, id, name, order }: { typeKind: string; uuid: string; id: string; name: string; order: number }
    ) {
      if (typeKind === "production") {
        state.albumDetail[0].album[0].productions.push({
          uuid: uuid,
          typeKind: typeKind,
          order: order,
          production: [
            {
              id: id,
              name: name,
              diplayName: null
            }
          ]
        });
      } else {
        state.albumDetail[0].album[0].publications.push({
          uuid: uuid,
          typeKind: typeKind,
          order: order,
          publication: [
            {
              id: id,
              name: name,
              diplayName: null
            }
          ]
        });
      }
    },
    deleteAlbumCompany(state, { companyType, uuid }: { companyType: string; uuid: string }) {
      if (companyType === "production") {
        state.albumDetail[0].album[0].productions = state.albumDetail[0].album[0].productions.filter((item: any) => item.uuid !== uuid);
      } else {
        state.albumDetail[0].album[0].publications = state.albumDetail[0].album[0].publications.filter((item: any) => item.uuid !== uuid);
      }
    },
    createAlbumArtist(state, { uuid, id, name, order }: { uuid: string; id: string; name: string; order: number }) {
      if (!state.albumDetail[0].album[0].artistRelation.length) {
        state.albumDetail[0].album[0].artistRelation = [{ uuid, order, artist: [{ id, name }] }];
      } else {
        state.albumDetail[0].album[0].artistRelation.push({ uuid, order, artist: [{ id, name }] });
      }
    },
    deleteAlbumArtist(state, uuid: string) {
      state.albumDetail[0].album[0].artistRelation = state.albumDetail[0].album[0].artistRelation.filter((item: any) => item.uuid !== uuid);
    },
    createAlbumCover(state, { uuid, typeUrl, url, validCheck }: { uuid: string; typeUrl: string; url: string; validCheck: string }) {
      const album = state.albumDetail[0];
      album[0].metadataUrl = [
        {
          uuid,
          typeUrl,
          url,
          validCheck
        }
      ];
    },
    setAlbumTrackCount(state, count: number) {
      state.count = count;
    },
    setAlbumTitleDesc(
      state,
      { id, typeTitle, value, languageCode, order }: { id: string; typeTitle: string; value: string; languageCode: string; order: number }
    ) {
      state.albumDetail[0].album[0].titleRelation.push({
        id: id,
        type: typeTitle,
        value: value,
        language: [
          {
            languageCode: languageCode
          }
        ],
        order: order
      });
    },
    updateAlbumTitleDesc(
      state,
      { id, typeTitle, value, languageCode }: { id: string; typeTitle: string; value: string; languageCode: string }
    ) {
      const idx = state.albumDetail[0].album[0].titleRelation.findIndex((item: Title) => item.id === id);
      state.albumDetail[0].album[0].titleRelation[idx].type = typeTitle;
      state.albumDetail[0].album[0].titleRelation[idx].value = value;
      state.albumDetail[0].album[0].titleRelation[idx].language[0].languageCode = languageCode;
    },
    deleteAlbumTitleDesc(state, id: string) {
      state.albumDetail[0].album[0].titleRelation = state.albumDetail[0].album[0].titleRelation.filter((item: Title) => item.id !== id);
    },
    setCurrentAlbumDesc(state, text: string) {
      state.currentDesc = text;
    },
    setAlbumDetailModalId(state, id: string) {
      state.modalId = id;
    },
    setAlbumDetailItemListMode(state, { key, mode }: { key: string; mode: boolean }) {
      const index = state.albumItemList.findIndex(item => item.key === key);
      state.albumItemList[index].isEditMode = mode;
    },
    createAlbumDetailImage(state, { uuid, typeUrl, url }: { uuid: string; typeUrl: string; url: string }) {
      state.albumDetail[0].album[0].metadataUrl.push({ uuid, typeUrl, url });
    },
    updateAlbumDetailImage(state, { uuid, typeUrl, url }: { uuid: string; typeUrl: string; url: string }) {
      const index = state.albumDetail[0].album[0].metadataUrl.findIndex((item: any) => item.uuid === uuid);
      state.albumDetail[0].album[0].metadataUrl[index].url = url;
      state.albumDetail[0].album[0].metadataUrl[index].typeUrl = typeUrl;
    },
    deleteAlbumDetailImage(state, { index, uuid }: { index: number; uuid: string }) {
      const imageIndex = state.albumDetail[index].metadataUrl.findIndex((url: any) => url.uuid === uuid);
      state.albumDetail[index].metadataUrl.splice(imageIndex, 1);
    }
  }
});
