import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { AlbumActions as AlbumCreate } from "./Album";

const reducers = { AlbumCreate };

const reducer = combineReducers(reducers, createStoreLogger("AlbumCreate"));

export const {
  StoreProvider: AlbumStoreProvider,
  useStore: useAlbumStore,
  useSelector: useAlbumSelector,
  useDispatch: useAlbumDispatch
} = createStore(reducer);
