import React, { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  MARGING_LARGE,
  PADDING_LARGE_PX,
  PADDING_X_LARGE_PX,
  BORDER_RADIUS_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { GRAY_0 } from "constants/baseColor";
import { DANGER_COLOR, DEFAULT_BORDER_COLOR } from "constants/color";
import { gql } from "lib/gql-tag";
import { Progress } from "App/Atomics/Progress";
import { clients } from "utils/clients";
import axios from "axios";
import { CreateInvalidation, DeleteAccessRecord, UpdateFile, UploadFile } from "GraphQL/Queries";
import { MediaType } from "GraphQL/Scalars/MediaType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { createFormData } from "lib/createFormData";
import { CreateMetadataUrl, DeleteMetadataUrl, GetTrackNoWithAlbumSerial, UpdateMetadataUrl } from "GraphQL/Queries/Metadata";
import Time from "dayjs";

type Props = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  metadataId: string;
  toClose: () => void;
}>;

type FileInfo = Readonly<{
  ext: string;
  accept: string;
}>;

type TrackData = Readonly<{
  name: string;
  file: File;
  fileType: string;
}>;

type LoadedData = {
  loaded: number | undefined;
  total: number | undefined;
};

const SEARCH_COMPANY_ID = gql`
  query SEARCH_COMPANY_ID($id: ID) {
    metadata_company_relation(where: { metadata__some: { metadata_id: $id } }) {
      company {
        companyId: company_id
      }
    }
  }
`;

const getFileInfo = (typeUrl: string) => {
  switch (typeUrl) {
    case "mp3high":
      return { ext: "mp3", accept: "audio/mpeg" } as FileInfo;
    case "aac":
      return { ext: "m4a", accept: "audio/x-m4a" } as FileInfo;
    case "flac":
      return { ext: "flac", accept: "audio/flac" } as FileInfo;
    case "wav":
      return { ext: "wav", accept: "audio/wav" } as FileInfo;
    case "zip":
      return { ext: "zip", accept: ".zip" } as FileInfo;
    case "txt":
      return { ext: "txt", accept: "text/plain" } as FileInfo;
    default:
      return { ext: "", accept: "" };
  }
};

export const EditFileModal = ({ id, typeUrl, url, metadataId, toClose }: Props) => {
  const [trackData, setTrackData] = useState({} as TrackData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeStamp, setTimeStamp] = useState(0);
  const [loadedData, setLoadedData] = useState({
    loaded: undefined,
    total: undefined
  } as LoadedData);
  const [failed, setFailed] = useState(false);
  const fileInfo: FileInfo = getFileInfo(typeUrl);

  useEffect(() => {}, [trackData, setTimeStamp]);

  const setTrackFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      setTrackData({
        name: file.files![0].name,
        file: file.files![0],
        fileType
      } as TrackData);
    };
  };

  const onUploadFile = async () => {
    const accessId = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
    try {
      const { data: companyData, errors: getCompanyError } = await clients.metadata.query(SEARCH_COMPANY_ID, { id: metadataId });
      if (getCompanyError) {
        throw getCompanyError;
      }
      const { albumNo, trackNo } = await GetTrackNoWithAlbumSerial({ metadataId });
      const companyId = companyData.metadata_company_relation[0].company[0].companyId;
      const filename = `${albumNo}/${typeUrl}/${trackNo}_${Time(new Date()).format("YYMMDD_HHmmss")}.${trackData.name.split(".")[1]}`;
      if (!url) {
        // TODO: UPLOAD
        const { data: urlData, errors: urlErr } = await CreateMetadataUrl({ metadataId, typeUrl, url: filename });
        if (urlErr || !urlData) {
          throw urlErr;
        }
        const uuid = urlData.createMetadataUrl.metadata_url[0].uuid;

        const { data, errors: uploadErr } = await UploadFile({ companyId, filename });
        if (uploadErr || !data) {
          await DeleteMetadataUrl({ uuid });
          throw uploadErr;
        }
        const uploadData = data.uploadFile;
        if (uploadData) {
          await axios({
            method: "post",
            url: uploadData.url,
            data: createFormData(uploadData, trackData.file),
            onUploadProgress: progressEvent => {
              loadedData.loaded = progressEvent.loaded;
              loadedData.total = progressEvent.total;
              setTimeStamp(progressEvent.timeStamp);
              setLoadedData(loadedData);
            },
            headers: {
              "Content-Type": fileInfo.accept
            }
          });
          await UpdateMetadataUrl({ uuid, url: uploadData.key });
        }
      } else {
        // TODO: UPDATE
        const { data, errors: updateErr } = await UpdateFile({ companyId, filename });
        if (updateErr || !data) {
          throw updateErr;
        }
        const uploadData = data.updateFile;
        if (uploadData) {
          await axios({
            method: "post",
            url: uploadData.url,
            data: createFormData(uploadData, trackData.file),
            onUploadProgress: progressEvent => {
              loadedData.loaded = progressEvent.loaded;
              loadedData.total = progressEvent.total;
              setTimeStamp(progressEvent.timeStamp);
              setLoadedData(loadedData);
            },
            headers: {
              "Content-Type": fileInfo.accept
            }
          });
          await CreateInvalidation({
            token: uploadData.invalidateToken,
            mediaType: MediaType.track
          });
          if (uploadData.key !== url) {
            await UpdateMetadataUrl({ uuid: id, url: uploadData.key });
          }
        }
      }
      await DeleteAccessRecord({ id: accessId! });
      Toast.primary("2초 후 화면이 새로고침됩니다.", undefined, "top-center");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      toClose();
    } catch (e) {
      await DeleteAccessRecord({ id: accessId! });
      setFailed(true);
      Toast.error("업로드에 실패하였습니다.", undefined, "top-center");
    }
  };
  return (
    <Layout>
      <RowGroup>
        <h2>파일 업로드</h2>
        <span className="alert-span">파일 업로드 이후, 페이지가 새로고침됩니다. 저장되지 않은 데이터의 손실에 주의해주세요!</span>
      </RowGroup>
      <RowGroup>
        <input
          type="file"
          accept={fileInfo.ext !== "m4a" ? fileInfo.accept : `audio/aac, ${fileInfo.accept}`}
          onChange={event => setTrackFile(event, fileInfo.ext)}
        />
      </RowGroup>
      <RowGroup>
        <div style={{ textAlign: "left", whiteSpace: "normal" }}>
          <b>현재 파일: </b> {trackData && trackData.name ? trackData.name : url}
        </div>
      </RowGroup>
      <RowGroup>
        <Progress
          style={{ width: "100%" }}
          percent={loadedData.loaded && loadedData.total ? (loadedData.loaded / loadedData.total) * 100 : 0}
        />
      </RowGroup>
      <ButtonGroup>
        <Input.Button
          color="danger"
          className="cancelButton"
          disabled={!failed && loadedData.loaded !== undefined && loadedData.total !== undefined && loadedData.loaded === loadedData.total}
          onClick={toClose}
        >
          닫기
        </Input.Button>
        <Input.Button color="primary" disabled={!(trackData && trackData.name) || loadedData.loaded !== undefined} onClick={onUploadFile}>
          업로드
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 20)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RowGroup = styled.div`
  width: 100%;
  padding: ${PADDING_LARGE_PX} 0px;

  & > h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }

  & > input {
    width: 100%;
    background-color: transparent;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }

  .alert-span {
    color: ${DANGER_COLOR};
    font-size: 12px;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;

  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;
