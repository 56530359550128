/* eslint-disable @typescript-eslint/no-unused-vars */
import { HEADER_HEIGHT_PX } from "constants/size";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { useGoodsDetailSelector } from "../Store";
import { Style } from "../Style";
import {
  GoodsArtist,
  GoodsCompany,
  GoodsDate,
  GoodsIsCollection,
  GoodsName,
  GoodsOfficialBarcode,
  GoodsOfficialUrls,
  GoodsParent,
  GoodsPrice,
  GoodsSubdata,
  GoodsTitle,
  GoodsType,
  GoodsUrls,
  GoodsWork,
  GoodsYoutubeUrls
} from "./Form";
import { GoodsQuantity } from "./Form/GoodsQuantity";

export const GoodsDetailForm = (): ReactElement => {
  const { goods, goodsTypes } = useGoodsDetailSelector(store => ({
    goods: store.GoodsDetail.goods,
    goodsTypes: store.GoodsDetail.goodsTypes
  }));
  const {
    name,
    titles,
    type,
    artists,
    company,
    works,
    coverUrls,
    detailUrls,
    videoUrls,
    createdAt,
    officialUrls,
    youtubeUrls,
    officialBarcode,
    subdatas,
    askQuantity,
    askPrice,
    officialPrice,
    parents,
    isCollection
  } = goods;
  return (
    <Layout>
      <Style.Title>굿즈 관리 및 편집</Style.Title>
      <GoodsName key={name} name={name} />
      <GoodsType key={`type-${type?.id ?? "-"}`} goodsTypes={goodsTypes} typeInfo={type} />
      <GoodsParent parents={parents} />
      <GoodsTitle titles={titles} />
      <GoodsArtist artists={artists} />
      <GoodsCompany key={company?.uuid} company={company} />
      <GoodsWork works={works} />
      <GoodsOfficialUrls officialUrls={officialUrls} />
      <GoodsYoutubeUrls youtubeUrls={youtubeUrls} />
      <GoodsOfficialBarcode key={officialBarcode} barcode={officialBarcode} />
      <GoodsPrice askPrice={askPrice} officialPrice={officialPrice} />
      <GoodsQuantity askQuantity={askQuantity} />
      <GoodsDate createdAt={createdAt} />
      <GoodsUrls type="cover" urls={coverUrls} />
      <GoodsUrls type="detail" urls={detailUrls} />
      <GoodsUrls type="video" urls={videoUrls} />
      <GoodsSubdata subdatas={subdatas} />
      <GoodsIsCollection isCollection={isCollection} />
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  width: 100%;
  max-width: 760px;
  margin: ${HEADER_HEIGHT_PX} auto 0;
  padding: 60px 26px 140px;
`;
