import immer from "immer";
import React, { Fragment, useState } from "react";
import styled from "styled-components";

import { Input } from "App/Atomics/Input";
import { MARGING_LARGE_PX, PADDING_XX_LARGE_PX, pixelize, UNIT, MARGING_X_LARGE_PX, PADDING_X_LARGE_PX } from "constants/size";
import { ARTIST_VC_FILTER } from "constants/storageKey";
import { ValidCheck } from "App/Routes/Tracks/Store/TableInfo/sanitizeVCs";
import { UserRole } from "constants/UserRole";
import { Divider } from "App/Atomics/Divider";
import { ReactComponent as SwapIcon } from "assets/icons/swap.svg";
import { BLUE_6 } from "constants/baseColor";

type Props = Readonly<{
  userRole: string | null;
  validCheck: ValidCheck;
  onClick: (validCheck: ValidCheck) => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow: overlay;

  width: ${pixelize(UNIT * 32.5)};
  height: ${pixelize(UNIT * 37)};
  padding: ${PADDING_XX_LARGE_PX};

  & > button {
    width: ${pixelize(UNIT * 5)};
    margin-top: auto;
    margin-left: auto;
  }
`;

const FilterGroup = styled.section`
  padding: ${PADDING_X_LARGE_PX};
  margin-bottom: ${MARGING_LARGE_PX};

  div {
    grid-template-columns: 30% auto;
    margin-bottom: ${MARGING_LARGE_PX};
  }

  label {
    font-weight: bold;
  }

  hr {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const Title = styled.div`
  display: flex;
  column-gap: ${MARGING_LARGE_PX};
  align-items: center;
  margin-left: ${MARGING_LARGE_PX};

  svg {
    width: ${pixelize(UNIT * 1.5)};
    height: ${pixelize(UNIT * 1.5)};
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      fill: ${BLUE_6};
    }
  }
`;

const filterDataEvery = (data: Record<string, boolean>) => {
  return Object.values(data).every(item => {
    return !item;
  });
};

export const VCFilterModal = ({ userRole, validCheck: initialVC, onClick }: Props) => {
  const [validCheck, setValidCheck] = useState(initialVC);

  const toSave = () => {
    if (filterDataEvery(validCheck)) {
      alert("하나 이상은 ON 상태여야 합니다.");
      return;
    }
    window.sessionStorage.setItem(ARTIST_VC_FILTER, JSON.stringify({ validCheck: validCheck }));
    onClick(validCheck);
  };

  const toggleVC = (key: keyof typeof validCheck) => {
    setValidCheck(validCheck =>
      immer(validCheck, draft => {
        draft[key] = !validCheck[key];
      })
    );
  };

  const toggleAllVC = () => {
    Object.keys(validCheck).forEach(key => {
      toggleVC(key as keyof typeof validCheck);
    });
  };

  return (
    <Layout>
      <Title>
        <h3>VC 필터</h3>
        <SwapIcon onClick={toggleAllVC} />
      </Title>
      <Divider />
      <FilterGroup>
        {Object.entries(validCheck).map(([key, value], index) => (
          <Fragment key={index}>
            <Input.Group isAlign="baseline">
              {(key !== "REMOVE" || userRole === UserRole.Master || userRole === UserRole.Arbeit) && (
                <label>{key !== "V1" ? key : "V1 (NAXOS)"}</label>
              )}
              {(key !== "REMOVE" || userRole === UserRole.Master || userRole === UserRole.Arbeit) && (
                <Input.Toggle color="primary" isActive={value} onClick={() => toggleVC(key as keyof typeof validCheck)} />
              )}
            </Input.Group>
          </Fragment>
        ))}
      </FilterGroup>
      <Input.Button isWide onClick={toSave}>
        저장
      </Input.Button>
    </Layout>
  );
};
