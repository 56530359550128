import React from "react";
import styled from "styled-components";
import { Info } from "App/Atomics/Input/Select/TagSelect";
import { usePlaylistCreateStore } from "App/Routes/PlaylistCreate/Store";
import { PlaylistCreateActions } from "App/Routes/PlaylistCreate/Store/TrackList";
import { Input } from "App/Atomics/Input";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { BORDER_RADIUS_PX, MARGING_LARGE_PX, pixelize, UNIT } from "constants/size";
import { CheckDuplicatedTrackInPlaylist } from "GraphQL/Queries/Playlist";
import { Toast } from "lib/toast";
import { PlaylistTextAutoComplete } from "App/Molecules/AutoCompletes/Playlist";
import { getTrackList } from "../SimpleSearchForm/getTrackList";

const Layout = styled.div`
  display: flex;
  align-items: center;
  .autocomplete {
    width: 95%;
    margin-right: 0.5rem;
  }
  .simpleSearch__control {
    border: none;

    &--is-focused {
      box-shadow: none;

      & > .simpleSearch__value-container {
        box-shadow: 0px 0px 1px 1px #4c52bc;
      }
    }

    & > .simpleSearch__value-container {
      margin-right: ${MARGING_LARGE_PX};
      border: 1px solid ${DEFAULT_BORDER_COLOR};
      border-radius: ${BORDER_RADIUS_PX};
    }

    & > .simpleSearch__indicators {
      & > div.simpleSearch__clear-indicator {
        position: absolute;
        right: ${pixelize(UNIT * 6)};
      }
    }
  }

  .simpleSearch__menu {
    & .simpleSearch__option.simpleSearch__option--is-focused {
      background-color: #ededf8;
    }
  }
`;

export const CopyPlaylistForm = () => {
  const [{ idSet, serviceType, kind }, dispatch] = usePlaylistCreateStore(store => ({
    idSet: store.TrackList.idSet,
    serviceType: store.TrackList.serviceType,
    kind: store.TrackList.kind
  }));

  const addId = (infoList: readonly Info[]) => {
    if (infoList.length) {
      const infoSet = new Set(infoList.map(({ id }) => id));
      dispatch(PlaylistCreateActions.setIdSet(infoSet));
    } else {
      dispatch(PlaylistCreateActions.setIdSet(new Set()));
    }
  };

  const loadTrackList = async () => {
    dispatch(PlaylistCreateActions.updateLoadingState("Loading"));
    const trackList = await getTrackList(Array.from(idSet));
    if (trackList) {
      if (serviceType && kind?.length) {
        const metadataIdIn = trackList.map(({ id }) => id);
        const { data, errors } = await CheckDuplicatedTrackInPlaylist({ serviceType, kind, metadataIdIn });
        if (errors || !data) {
          Toast.error("중복 검사에 실패하였습니다", undefined, "top-center");
          return;
        }
        if (data) {
          const duplicateList = data.metadataPlaylistRelation.map(({ metadata }) => metadata[0].id);
          const newTrackList = trackList.map(list => {
            const isDuplicated = duplicateList.includes(list.id);
            return { ...list, duplicated: isDuplicated };
          });
          dispatch([
            PlaylistCreateActions.updateLoadingState("None"),
            PlaylistCreateActions.set({ kind: "searched", tracks: newTrackList })
          ]);
          dispatch(PlaylistCreateActions.setIdSet(new Set()));
        }
      } else {
        dispatch([PlaylistCreateActions.updateLoadingState("None"), PlaylistCreateActions.set({ kind: "searched", tracks: trackList })]);
        dispatch(PlaylistCreateActions.setIdSet(new Set()));
      }
    }
  };

  return (
    <Layout>
      <PlaylistTextAutoComplete
        className="autocomplete"
        onChange={info => {
          if (info) {
            const trackInfoList = info.extra.map(({ metadata }: any) => {
              return { id: metadata[0].id, name: metadata[0].title };
            }) as Info[];
            addId(trackInfoList);
          }
        }}
      />
      <Input.Button color="primary" onClick={loadTrackList}>
        검색하기
      </Input.Button>
    </Layout>
  );
};
