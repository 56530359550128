/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { PlaylistOrderByInputScalar } from "GraphQL/Scalars";
import { PlaylistOrderByInput } from "constants/PlaylistOrderByInput";
import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";

type Option = Readonly<{
  id?: string;
  email?: string;
  trackOrder?: MetadataPlaylistRelationOrderByInput;
  trackCount?: number;
  trackSkip?: number;
}>;

export type PlaylistInfo = {
  id: string;
  name: string;
  kind: string;
  count?: number;
};

export const GetPlaylistInfoByEmail = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const $email = <Variable name="email" scalar={<IdScalar />} value={option.email} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_INFO_BY_EMAIL">
      <SelectionSet
        name="playlist"
        argument={{
          group: true,
          first: 999,
          where: {
            playlist_id: $playlistId,
            playlist_users_relation__some: {
              users__some: {
                email: $email
              }
            }
          },
          orderBy: <PlaylistOrderByInputScalar value={PlaylistOrderByInput.playlist_id_DESC} />
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="name" />
        <Field name="kind" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    playlist: PlaylistInfo[];
  }>;
  const { errors, data } = await clients.playlist.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }

  return data!.playlist;
};
