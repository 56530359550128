import React from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { RightsStoreProvider } from "./Store";
import { RightsForm } from "./RightsForm";
import { HEADER_HEIGHT_PX } from "constants/size";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

const RightsCreateLayout = () => (
  <ResponsiveToSideNavigatorTemplate>
    <Layout>
      <RightsForm />
    </Layout>
  </ResponsiveToSideNavigatorTemplate>
);

export const RightsCreate = () => (
  <RightsStoreProvider>
    <Helmet title="권리사 등록" />
    <RightsCreateLayout />
  </RightsStoreProvider>
);
