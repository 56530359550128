/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { MetadataPlaylistRelationOrderByInput } from "../../constants/MetadataPlaylistRelationOrderByInput";

type Props = Readonly<{
  value?: MetadataPlaylistRelationOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  MetadataPlaylistRelationOrderByInput.id_ASC,
  MetadataPlaylistRelationOrderByInput.id_DESC,
  MetadataPlaylistRelationOrderByInput.metadata_id_ASC,
  MetadataPlaylistRelationOrderByInput.metadata_id_DESC,
  MetadataPlaylistRelationOrderByInput.playlist_id_ASC,
  MetadataPlaylistRelationOrderByInput.playlist_id_DESC,
  MetadataPlaylistRelationOrderByInput.exposure_order_ASC,
  MetadataPlaylistRelationOrderByInput.exposure_order_DESC
];

export const MetadataPlaylistRelationOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="Metadata_playlist_relationOrderByInput" value={value} isNullable={isNullable} items={items} />
);
