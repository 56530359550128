import React from "react";
import styled from "styled-components";

import { TrackList } from "./TrackList";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, PADDING_XX_LARGE } from "constants/size";

const Layout = styled.div`
  min-width: ${pixelize(32 * UNIT)};
  height: calc(100vh - ${pixelize(2 * PADDING_XX_LARGE)});

  padding: ${PADDING_XX_LARGE_PX};
`;

export const Right = () => (
  <Layout>
    <TrackList />
  </Layout>
);
