import React from "react";
import styled from "styled-components";

import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { PlaylistCreateStoreProvider } from "./Store";
import { Left } from "./Left";
import { Right } from "./Right";
import { HEADER_HEIGHT_PX } from "constants/size";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  overflow-x: auto;
  display: grid;
  grid-template-columns: min-content 1fr;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

export const PlaylistCreate = () => (
  <PlaylistCreateStoreProvider>
    <Helmet title="플레이리스트 등록" />
    <ResponsiveToSideNavigatorTemplate>
      <Layout>
        <Left />
        <Right />
      </Layout>
    </ResponsiveToSideNavigatorTemplate>
  </PlaylistCreateStoreProvider>
);
