/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  order?: number;
  validCheck?: ValidType;
}>;

export const CreatePlaylistUrl = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable={false} />} value={option.typeUrl} />;
  const $url = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.url} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_PLAYLIST_URL">
      <SelectionSet
        name="updatePlaylist"
        alias="createPlaylistUrl"
        argument={{
          where: {
            playlist_id: $id
          },
          data: {
            _onlySearch: true,
            playlist_url: {
              create: [{ type_url: $typeUrl, url: $url, exposure_order: $order, valid_check: $validCheck }]
            }
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <SelectionSet name="playlist_url">
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createPlaylistUrl: {
      id: string;
      playlist_url: {
        uuid: string;
        typeUrl: string;
        url: string;
      }[];
    };
  }>;

  return await clients.playlist.request<Data>(query, variables);
};
