/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  genreId: string;
}>;

export const DeleteGenre = async (option: Option) => {
  const $genreId = <Variable name="genre_id" scalar={<IdScalar />} value={option.genreId} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_GENRE">
      <SelectionSet
        name="deleteGenre"
        argument={{
          where: {
            genre_id: $genreId
          }
        }}
      >
        <Field name="genre_id" alias="genreId" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteGenre: {
      genreId: string;
    };
  }>;

  const { errors, data } = await clients.genre.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  return data!;
};
