import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { PlaylistDetailActions as PlaylistDetail } from "./PlaylistDetail";
import { PlaylistTrackActions as PlaylistTrack } from "./PlaylistTrack";

const reducers = { PlaylistDetail, PlaylistTrack };

const reducer = combineReducers(reducers, createStoreLogger("PlaylistDetail"));

export const {
  StoreProvider: PlaylistDetailStoreProvider,
  useStore: usePlaylistDetailStore,
  useSelector: usePlaylistDetailSelector,
  useDispatch: usePlaylistDetailDispatch
} = createStore(reducer);
