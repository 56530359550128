/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";

type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  order?: number;
};

type Option = Readonly<{
  no: string;
  title: string;
  class: string;
  subClass: string;
  albumArtist: Artist[];
  companyId: string | null;
  url?: string;
  typeKind?: MetadataRightType;
  productions?: {
    companyId: string;
  }[];
  publications?: {
    companyId: string;
  }[];
}>;

export const CreateAlbum = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $title_no_space =
    option.title === option.title.replace(/\s/gi, "") ? (
      undefined
    ) : (
      <Variable name="title_no_space" scalar={<StringScalar isNullable={false} />} value={option.title.replace(/\s/gi, "")} />
    );
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.companyId} />;
  const $url = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.url} />;
  const $typeKind = <MetadataRightScalar isNullable={true} value={option.typeKind} />;
  const $production = <MetadataRightScalar isNullable={true} value={MetadataRightType.production} />;
  const $publication = <MetadataRightScalar isNullable={true} value={MetadataRightType.publication} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ALBUM_LOG">
      <SelectionSet
        name="createMetadata"
        alias="createAlbum"
        argument={{
          data: {
            no: $no,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                !$title_no_space
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_metadata_title: "name",
                      value: $title_no_space,
                      exposure_order: 1
                    }
              ]
            },
            metadata_artist_relation: {
              create:
                [
                  ...option.albumArtist?.map(({ artist_id, role_id, order }) => ({
                    artist: {
                      connect: {
                        artist_id: artist_id
                      }
                    },
                    role: {
                      connect: {
                        role_id: role_id
                      }
                    },
                    exposure_order: order
                  }))
                ] ?? {}
            },
            metadata_url: {
              create: [
                {
                  type_url: "cover",
                  url: $url
                }
              ]
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $typeKind,
                  exposure_order: 0
                },
                ...(option.productions?.map(({ companyId }, order) => ({
                  company: {
                    connect: {
                      company_id: companyId
                    }
                  },
                  type_kind: $production,
                  exposure_order: order
                })) ?? []),
                ...(option.publications?.map(({ companyId }, order) => ({
                  company: {
                    connect: {
                      company_id: companyId
                    }
                  },
                  type_kind: $publication,
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_structure: {
              create: [{ parent_id: 0 }]
            }
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata_structure">
          <Field name="structure_id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createAlbum: Readonly<{
      metadata_id: string;
      metadata_structure: Readonly<{
        structure_id: number;
      }>[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
