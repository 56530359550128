/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
  trackInfo: string[];
}>;

export const AddTracksToPlaylist = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_PLAYLIST">
      <SelectionSet
        name="updatePlaylist"
        argument={{
          where: {
            playlist_id: $playlistId
          },
          data: {
            _onlySearch: true,
            metadata_playlist_relation: {
              create: option.trackInfo.map(trackId => ({
                metadata: {
                  connect: {
                    metadata_id: trackId
                  }
                },
                booking_order: 0
              }))
            }
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updatePlaylist: {
      id: string;
    }[];
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
