import React, { useState } from "react";
import styled from "styled-components";
import { MARGING_SMALL_PX, pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { usePlaylistDetailStore } from "App/Routes/PlaylistDetail/Store";
import { PlaylistDetailActions } from "App/Routes/PlaylistDetail/Store/PlaylistDetail";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { GRAY_6 } from "constants/baseColor";
import { Column } from "constants/Column";

type Props = Readonly<{
  visibleField: string[];
  openAllDownloadModal: (compress: boolean) => void;
  openSelectedMusicDownloadModal: any;
  toClose: () => void;
}>;

export const SelectMusicTitleModal = ({ visibleField, openAllDownloadModal, openSelectedMusicDownloadModal, toClose }: Props) => {
  const [{ checkList }, dispatch] = usePlaylistDetailStore(store => ({
    checkList: store.PlaylistDetail.checkList
  }));
  const userRole = useAppSelector(store => store.UserToken.role);
  const handleButton = (callback: any) => {
    callback();
    toClose();
  };
  const [isCompress] = useState(true);
  const Mp3TitleOptionList = [
    { id: "id", name: "아이디" },
    { id: "id_mixed", name: "아이디+트랙 아이디" },
    { id: "track_id", name: "트랙 아이디" },
    { id: "title", name: "제목" },
    { id: "mixed", name: "대제목+소제목" }
  ];

  const isDisabledTypeUrlByPartner = (userRole: UserRole | null, typeUrl: string) => {
    if (userRole !== UserRole.Partner) return false;
    switch (typeUrl) {
      case "mp3high":
        return !visibleField.includes(Column.mp3);
      case "aac":
        return !visibleField.includes(Column.aac);
      case "flac":
        return !visibleField.includes(Column.flac);
      case "wav":
        return !visibleField.includes(Column.wav);
      case "zip":
      case "txt":
        return !visibleField.includes(Column.file);
      default:
        return false;
    }
  };

  const typeUrlOptionList = [
    { id: "mp3high", name: "MP3", isDisabled: isDisabledTypeUrlByPartner(userRole, "mp3high") },
    { id: "wav", name: "WAV", isDisabled: isDisabledTypeUrlByPartner(userRole, "wav") },
    { id: "aac", name: "AAC", isDisabled: isDisabledTypeUrlByPartner(userRole, "aac") },
    { id: "flac", name: "FLAC", isDisabled: isDisabledTypeUrlByPartner(userRole, "flac") },
    { id: "zip", name: "ZIP", isDisabled: isDisabledTypeUrlByPartner(userRole, "zip") }
    // { id: "txt", name: "TXT", isDisabled: userRole === UserRole.Master }
  ];

  return (
    <Layout>
      <Header>
        <h3>다운로드 할 파일의 제목 및 파일 타입을 선택해주세요.</h3>
      </Header>
      <Section>
        <div className="row-group">
          <h5>제목</h5>
          <Input.TextSelect
            optionList={Mp3TitleOptionList}
            defaultValue={Mp3TitleOptionList[0]}
            onChange={info => {
              if (info) {
                dispatch(PlaylistDetailActions.setPlaylistDetailMp3TitleType(info.id));
              }
            }}
          />
        </div>
        <div className="row-group">
          <h5>타입</h5>
          <Input.TextSelect
            optionList={typeUrlOptionList}
            placeholder="타입을 선택하세요."
            onChange={info => {
              if (info) {
                dispatch(PlaylistDetailActions.setPlaylistDetailTypeUrl(info.id));
              }
            }}
          />
        </div>
        <ButtonGroup>
          <Input.Button color="primary" onClick={() => openAllDownloadModal(isCompress)}>
            전체 파일 다운로드
          </Input.Button>
          <Input.Button
            disabled={!Array.from(checkList).filter(item => item[1]).length}
            color="warning"
            onClick={() => handleButton(openSelectedMusicDownloadModal)}
          >
            선택 파일 다운로드
          </Input.Button>
          <Input.Button color="danger" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Section>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 25)};
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background: rgb(106, 95, 221);

  color: #fff;
  padding: 1.3rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1.5rem 1.8rem;

  .row-group {
    margin-bottom: 1.6rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
`;

const ButtonGroup = styled(Input.Group)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 6.5rem;
  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }
`;
