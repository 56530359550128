import React, { useCallback } from "react";
import defaultImage from "assets/images/default.png";
import { configs } from "configs";
import { Artist } from "GraphQL/Queries/Artist/GetArtist";
import { Layout, RowGroup, RowTitle } from "../formStyle";
import { ARTISTS } from "constants/route";
import { useHistory } from "react-router";

type Props = {
  artist: Artist[];
  searchQuery: string;
};

export const ArtistForm = ({ artist, searchQuery }: Props) => {
  const router = useHistory();
  const onMovePage = useCallback(() => router.push(`${ARTISTS}?page=1${!searchQuery ? "" : `&q=${searchQuery}&type=이름`}`), [
    router,
    searchQuery
  ]);
  const onMoveById = useCallback((id: string) => router.push(`${ARTISTS}?page=1&q=${id}&type=아이디`), [router]);
  return (
    <Layout>
      <RowTitle>
        <span>아티스트</span>
        <button onClick={onMovePage}>{"＞"}</button>
      </RowTitle>
      {!artist.length ? (
        <div className="no-data">해당 아티스트가 없습니다.</div>
      ) : (
        artist.map(({ id, name, companyRelation, artistUrl }) => {
          const cover = artistUrl?.filter(({ typeUrl }) => typeUrl === "cover" || typeUrl === "thumbnail" || typeUrl === "head")[0]?.url;
          return (
            <RowGroup key={id} onClick={() => onMoveById(id)}>
              <div className="thumb">
                <img src={cover ? `${configs.urls.image}/${cover}?mode=s` : defaultImage} alt={name} />
              </div>
              <div className="info_area">
                <div className="title">
                  <span>{name}</span>
                </div>
                <div className="company">
                  <span>{companyRelation[0]?.company[0].name ?? "-"}</span>
                </div>
              </div>
            </RowGroup>
          );
        })
      )}
    </Layout>
  );
};
