/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet } from "graphql-jsx";
import { clients } from "utils/clients";
import { TargetTableInput } from "constants/TargetTableInput";
import { TargetTableInputScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  targetId?: string;
  targetTable: TargetTableInput;
}>;

export const CreateAccessRecord = async (option: Option) => {
  const $targetId = <Variable name="target_id" scalar={<StringScalar />} value={option.targetId} />;
  const $targetTable = <Variable name="target_table" scalar={<TargetTableInputScalar isNullable={false} />} value={option.targetTable} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ACCESS_RECORD">
      <SelectionSet
        name="createAccess_record"
        alias="createAccess"
        argument={{
          data: { target_id: $targetId, target_table: $targetTable }
        }}
      >
        <Field name="id" />
        <Field name="target_id" alias="targetId" />
        <Field name="target_table" alias="targetTable" />
        <Field name="uid" />
        <Field name="createdAt" />
        <Field name="updatedAt" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createAccess: Readonly<{
      id: string;
      targetId: string;
      targetTable: string;
      uid: string;
      createdAt: string;
      updatedAt: string;
    }>;
  }>;
  return await clients.access.request<Data>(query, variables);
};
