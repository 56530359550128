import React, { useCallback, useState } from "react";
import styled, { keyframes } from "styled-components";
import uuidv4 from "uuid/v4";
import { ReactComponent as CirclePlayIcon } from "assets/icons/circle-play.svg";
import { ReactComponent as ThumbDownIcon } from "assets/icons/thumb-down.svg";
import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";
import { BLUE_4, GRAY_0, GRAY_6, GRAY_7 } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { EditComment, ParsedComment, SubPage } from "../..";
import { PRIMARY_COLOR } from "constants/color";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetPlaylistWithTrack } from "GraphQL/Queries/Playlist";
import { Playlist } from "GraphQL/Queries/Playlist/GetPlaylistWithTrack";
import { Loading } from "App/Atomics/Loading";
import { Toast } from "lib/toast";
import { DeleteAccessRecord, UpdateComment } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import { useAppDispatch } from "App/Store";

type Props = {
  id: string;
  editComment: EditComment;
  setPage: React.Dispatch<React.SetStateAction<SubPage>>;
};

const flowText = keyframes`
  to {
    transform: translateX(-200%);
  }
`;

export const CommentEditForm = ({ id, editComment, setPage }: Props) => {
  const [playlistInfo, setPlaylistInfo] = useState<Playlist>({} as Playlist);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<ParsedComment>(!editComment.value ? { data: [], text: undefined } : editComment.value);
  const audioDispatch = useAppDispatch();

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const content = e.target.value;
    setComment(prev => ({ data: prev.data, text: content }));
  };

  const playAudio = (audio: TypeAudio) => {
    audioDispatch([AudioPlayerActions.toggleVisible(true), AudioPlayerActions.setAudioData(audio)]);
  };

  const onThumbDown = useCallback(
    (id: string, title: string) => {
      if (!comment.text) {
        setComment(prev => ({ data: prev.data, text: `[${id}] ${title} 곡이 마음에 들지 않습니다.\n` }));
      } else {
        setComment(prev => ({ data: prev.data, text: `${prev.text!}[${id}] ${title} 곡이 마음에 들지 않습니다.\n` }));
      }
    },
    [comment.text]
  );

  const onSaveComment = async () => {
    if (!comment) {
      Toast.error("댓글 내용을 작성해주세요.", undefined, "top-center");
      return;
    }
    setLoading(true);
    try {
      const accessId = await requestAccessRecord({ targetTable: TargetTableInput.Users });
      if (accessId) {
        const { data, errors } = await UpdateComment({ uuid: editComment.uuid, value: JSON.stringify(comment) });
        if (errors) {
          await DeleteAccessRecord({ id: accessId });
          throw new Error(errors[0].message);
        }
        if (data) {
          await DeleteAccessRecord({ id: accessId });
          Toast.primary("저장되었습니다.", undefined, "top-center");
          setPage(SubPage.FIRST);
        }
      }
    } catch (err) {
      console.log(err);
      Toast.error("오류가 발생하였습니다.", undefined, "top-center");
      return;
    } finally {
      setLoading(false);
    }
  };

  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const { data: playlistData } = await GetPlaylistWithTrack({ id, first: 30 });
        if (playlistData) {
          setPlaylistInfo(playlistData.playlist[0]);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        return;
      }
    }
  }, []);
  return (
    <Layout>
      <CommentSection>
        <Header>
          <CommentIcon className="comment-icon" />
          <h2>댓글 작성</h2>
        </Header>
        <div className="playlist-info">
          <div className="playlist-info-box">
            <strong>{playlistInfo.kind}</strong>
            <span>{playlistInfo.name}</span>
          </div>
        </div>
        <form className="comment-form">
          <textarea placeholder="댓글을 작성해주세요" value={comment.text} onChange={onChangeComment} />
        </form>
        <div className="comment-info">
          <p>
            <span>댓글 작성 팁</span>
            우측 트랙 목록의 재생버튼으로 음원을 들을 수 있습니다.
            <br />
            해당 음악이 마음에 들지 않을 경우, 댓글에 적거나 '싫어요' 아이콘을 눌러주시면 문구가 작성됩니다.
          </p>
        </div>
        <ButtonGroup>
          <Input.Button className="back-btn" onClick={() => setPage(SubPage.FIRST)}>
            돌아가기
          </Input.Button>
          <Input.Button color="primary" onClick={onSaveComment}>
            작성완료
          </Input.Button>
        </ButtonGroup>
      </CommentSection>
      <PlaylistSection>
        <div className="track-header">플레이리스트 트랙 목록</div>
        <div className="list-wrap">
          <ul>
            {!playlistInfo.metadataPlaylistRelation?.length ? (
              <div className="no-track">트랙이 없습니다.</div>
            ) : (
              playlistInfo.metadataPlaylistRelation.map(({ uuid, metadata }) => {
                const track = metadata[0];
                const audioId = uuidv4();
                const artistName = metadata[0].artistRelation[0]?.artist[0]?.name ?? "-";
                const { typeUrl, url } = metadata[0].metadataUrl.filter(({ typeUrl }) => typeUrl === "mp3high")[0];
                return (
                  <ListItem key={uuid}>
                    <div className="thumb-play" onClick={() => playAudio({ title: track.title, url, type: typeUrl, uuid: audioId })}>
                      <CirclePlayIcon />
                    </div>
                    <div className="info-area">
                      <span className="title">{track.title}</span>
                      <span className="artist">{artistName}</span>
                    </div>
                    <div className="thumb-down">
                      <ThumbDownIcon onClick={() => onThumbDown(track.id, track.title)} />
                    </div>
                  </ListItem>
                );
              })
            )}
          </ul>
        </div>
      </PlaylistSection>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.section`
  width: 100%;
`;

const Header = styled.header`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
  .comment-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.6rem;
    fill: ${PRIMARY_COLOR};
    transform: translateY(2px);
  }
`;

const CommentSection = styled.div`
  width: calc(100% - 350px);
  padding: 0 1rem 1rem;
  .playlist-info {
    padding: 1.25rem 0;
    border-bottom: 1px solid #d4d4d4;
    .playlist-info-box {
      position: relative;
      min-height: 2.8rem;
      strong {
        color: ${GRAY_7};
        display: block;
        font-weight: 600;
        margin-bottom: 0.1rem;
      }
      span {
        display: --webkit-box;
        overflow: hidden;
        max-height: 32px;
        text-overflow: ellipsis;
        font-size: 1.05rem;
        --webkit-line-break: 2;
        --webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .comment-form {
    position: relative;
    padding: 0;
    height: 520px;
    border-bottom: 1px solid #d4d4d4;
    textarea {
      display: block;
      overflow: auto;
      width: 100%;
      height: 100%;
      padding: 1rem 1rem 0 0;
      border: transparent;
      background: transparent;
      outline: none;
      line-height: 22px;
      color: #000;
      box-sizing: border-box;
      resize: none;
    }
  }
  .comment-info {
    padding: 20px 0;
    font-size: 0.85rem;
    line-height: 18px;
    color: #a0a0a0;
    span {
      display: block;
      margin-bottom: 4px;
      color: #ff4800;
    }
  }
`;
const PlaylistSection = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  background-color: #eee;
  .track-header {
    display: flex;
    align-items: center;
    height: 4rem;
    color: #fff;
    padding: 1.1rem;
    font-size: 1.03rem;
    font-weight: 600;
    border-bottom: 1px solid #d4d4d4;
    background-color: #000;
  }
  .list-wrap {
    position: absolute;
    top: 4rem;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .no-track {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
`;

const shake = keyframes`
  10%, 90% {
    transform: translate3d(0,-2px,0);
  };
  20%, 80% {
    transform: translate3d(0,4px,0);
  };
  30%, 50%, 70% {
    transform: translate3d(0,-8px,0);
  };
  40%, 60% {
    transform: translate3d(0,8px,0);
  };
`;

const ListItem = styled.li`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  user-select: none;
  transition: background-color 0.1s;
  &:hover {
    background-color: ${GRAY_0};
  }
  .thumb-play {
    display: table-cell;
    width: 72px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 36px;
      height: 36px;
      fill: #141414;
      vertical-align: middle;
      transition: fill 0.15s;
      &:hover {
        fill: ${BLUE_4};
      }
    }
  }

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;

    span {
      overflow: hidden;
      --webkit-line-break: 1;
      --webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      display: -webkit-box;
    }
    .title {
      height: 22px;
      line-height: 22px;
      color: #191919;
      &:hover {
        animation: ${flowText} 15s infinite linear;
      }
    }
    .artist {
      height: 17px;
      line-height: 17px;
      font-size: 13px;
      color: ${GRAY_6};
    }
  }

  .thumb-down {
    display: table-cell;
    width: 68px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
    }
    &:active {
      animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
  .back-btn {
    margin-right: 0.2rem;
  }
`;
