/** @jsx jsx */

import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  languageCode: string;
}>;

export const DeleteLanguage = async (option: Option) => {
  const $languageCode = <Variable name="languageCode" scalar={<IdScalar />} value={option.languageCode} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_LANGUAGE">
      <SelectionSet
        name="deleteLanguage"
        argument={{
          where: {
            language_code: $languageCode
          }
        }}
      >
        <Field name="language_code" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteLanguage: {
      language_code: string;
    };
  }>;

  const { errors, data } = await clients.artist.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }

  return data;
};
