import React from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, UNIT } from "constants/size";
import { WHITE } from "constants/baseColor";
import { ReactComponent as CheckCircleIcon } from "assets/icons/checkmark-circle.svg";
import { SUCCESS_COLOR_LIGHT } from "constants/color";

const scale = keyframes`
  0%, 100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
  }
`;

const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const fill = keyframes`
  100% {
    box-shadow: inset 0px 0px 0px 30px ${SUCCESS_COLOR_LIGHT};
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 1.8)};
  & .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: ${SUCCESS_COLOR_LIGHT};
    fill: none;
    -webkit-animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  & .checkmark {
    width: ${pixelize(UNIT * 1.8)};
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: ${WHITE};
    stroke-miterlimit: 10;
    margin: 0 auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    -webkit-animation: ${fill} 0.4s ease-in-out 0.4s forwards, ${scale} 0.3s ease-in-out 0.9s both;
    animation: ${fill} 0.4s ease-in-out 0.4s forwards, ${scale} 0.3s ease-in-out 0.9s both;
  }
  & .checkmark__check {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
`;

export const ProgressLoading = () => {
  return (
    <Layout>
      <CheckCircleIcon />
    </Layout>
  );
};
