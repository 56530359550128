import immer from "immer";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { Input } from "App/Atomics/Input";
import { pixelize, UNIT, PADDING_LARGE_PX, MARGING_LARGE_PX } from "constants/size";
import { TRACK_FILTER } from "constants/storageKey";
import { Head, Subhead } from "../../../Store/TableInfo/sanitizeHeads";
import { UserRole } from "constants/UserRole";
import { GRAY_6, GRAY_4 } from "constants/baseColor";
import { KeyInfo } from "lib/key-info";

type Props = Readonly<{
  head: Head;
  subhead: Subhead;
  userRole: UserRole | null;
  onClick: (head: Head, subhead: Subhead) => void;
  toClose?: () => void;
}>;

export const TableFilterModal = ({ head: initialHead, subhead: initialSubhead, userRole, onClick, toClose }: Props) => {
  const [head] = useState(initialHead);
  const [subhead, setSubhead] = useState(initialSubhead);
  const toSave = useCallback(() => {
    window.localStorage.setItem(
      TRACK_FILTER,
      JSON.stringify({
        Head: head,
        Subhead: {
          Metadata: subhead.Metadata,
          Artist: subhead.Artist,
          Company: subhead.Company,
          Genre: subhead.Genre
        }
      })
    );
    onClick(head, subhead);
  }, [head, onClick, subhead]);

  const toggleSubhead = (key: keyof typeof subhead, subKey: string) => {
    setSubhead(
      subhead =>
        immer(subhead as Record<string, Record<string, boolean>>, draft => {
          draft[key][subKey] = !(subhead as Record<string, Record<string, boolean>>)[key][subKey];
        }) as typeof subhead
    );
  };

  const toggleColumn = (key: string) => {
    Object.entries(subhead[key as keyof typeof subhead]).forEach(([subKey]) => {
      if (subKey !== "metadataId") {
        toggleSubhead(key as keyof typeof subhead, subKey);
      }
    });
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        toSave();
      }
    },
    [toSave]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>테이블 컬럼 필터</h3>
          <CancelIcon className="cancelIcon" onClick={toSave} />
        </div>
      </Header>
      <FilterGroup>
        {Object.entries(head)
          .filter(([key, value]) => {
            if (
              userRole === UserRole.Arbeit &&
              (key === "Company" || key === "Title" || key === "Url" || key === "License" || key === "LicenseExtra")
            ) {
              return false;
            } else {
              return key;
            }
          })
          .map(([key], index) => (
            <div key={index} className="filter-item">
              <label onClick={() => toggleColumn(key)}>{key}</label>
              <ButtonGroup>
                {Object.entries(subhead[key as keyof typeof subhead]).map(
                  ([subKey, subValue], index) =>
                    subKey !== "metadataId" && (
                      <Input.Toggle
                        className="toggle-btn"
                        color="primary"
                        toggleValue={subKey}
                        isActive={subValue}
                        onClick={() => toggleSubhead(key as keyof typeof subhead, subKey)}
                        key={index}
                      />
                    )
                )}
              </ButtonGroup>
            </div>
          ))}
      </FilterGroup>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 50)};
  height: ${pixelize(UNIT * 45)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  font-size: 0.9rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: #6a5fdd;
  color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  top: 0;
  width: 100%;
  display: flex;
  margin-bottom: 0.6rem;
  h3 {
    font-size: 1.1rem;
    svg {
      fill: #fff;
      width: ${pixelize(UNIT * 1.1)};
      height: ${pixelize(UNIT * 1.1)};
      margin-right: ${MARGING_LARGE_PX};
    }
  }
  .title {
    width: 100%;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: #fff;
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const FilterGroup = styled.section`
  .filter-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* min-height: 6rem; */
    border-bottom: 1px solid #ddd;
    padding: 1rem 1.5rem;

    label {
      margin-bottom: 0.5rem;
      font-size: 1.1rem;
      font-weight: 550;
      cursor: pointer;
      &:hover {
        color: ${GRAY_6};
      }
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .toggle-btn {
    width: 12rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.6rem;
  }
`;
