export enum Column {
  checkbox = "체크박스",
  order = "순서",
  id = "아이디",
  no = "번호",
  validCheck = "유효성",
  title = "제목",
  url = "음원",
  originalArtist = "오리지널",
  mainPerformer = "메인 퍼폼",
  composer = "작곡가",
  genre = "장르",
  mood = "무드",
  lyrics = "가사",
  license = "라이센스",
  info = "재생시간",
  mp3 = "MP3",
  wav = "WAV",
  aac = "AAC",
  flac = "FLAC",
  file = "ZIP/TXT"
}
