import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly tags: string;
};

export const GoodsTag = ({ tags }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>{`태그 *`}</Style.Caption>
    <Style.SubCaption>띄어쓰기 기준으로 입력해주세요. ex. #샤이니 #태민 #키 ...* .</Style.SubCaption>
    <Style.InputTextArea
      placeholder={"#샤이니 #태민 #키"}
      defaultValue={tags}
      onBlur={event => GoodsCreateStore.dispatch(GoodsCreateActions.setTags(event.target.value))}
    />
  </Style.Row>
);
