/** @jsx jsx  */

import { ValidType } from "constants/ValidType";
import { EnumScalar, Field, jsx, ListScalar, Query, render, SelectionSet, StringScalar, IntScalar, Variable } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  keyword: string;
  isParent?: boolean;
}>;

export const findGenreList = async ({ keyword, isParent }: Option) => {
  const $isParent = <Variable name="isParent" scalar={<IntScalar isNullable />} value={isParent === undefined ? undefined : +isParent} />;
  const $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="GENRE_SEARCH_LIST">
      <SelectionSet
        name="genre_title"
        alias="list"
        argument={{
          first: 20,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Genre_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            genre__some: {
              weight: $isParent,
              type_kind: "genre",
              valid_check__not: $remove
            },
            tsvec_words__search: $keyword,
            type_genre_title__starts_with: "name"
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <SelectionSet name="genre">
          <Field name="genre_id" alias="id" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="name" />
          <SelectionSet name="genre_self_relation_child">
            <SelectionSet name="genre_self_relation_parent">
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: ReadonlyArray<{
      id: string;
      genre: {
        id: string;
        validType: ValidType;
        name: string;
        genre_self_relation_child: {
          genre_self_relation_parent: {
            name: string;
          }[];
        }[];
      }[];
    }>;
  }>;
  const { data, errors } = await clients.genre.request<Data>(query, variables);
  if (!data || errors) {
    return [];
  }
  const result = data.list.map(({ genre }) => {
    const { id, name, genre_self_relation_child, validType } = genre[0];
    const parent = !genre_self_relation_child?.length ? "-" : genre_self_relation_child[0]?.genre_self_relation_parent[0]?.name;
    return { id, name, parent, validType };
  });
  return result;
};
