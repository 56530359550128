import React, { ReactElement, useMemo } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsDetail/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions, TypeInfo } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { GoodsType as TypeGoodsType } from "models/GoodsModel";

type Props = {
  readonly goodsTypes: TypeGoodsType[];
  readonly typeInfo: TypeInfo;
};

export const GoodsType = ({ goodsTypes, typeInfo }: Props): ReactElement => {
  const typeOptionList = goodsTypes.map(item => ({ id: item.id.toString(), name: item.displayType, extra: item.category }));
  const defaultValue = useMemo(() => (typeOptionList as any[])?.find(({ id }) => id === typeInfo?.id), [typeInfo, typeOptionList]);
  return (
    <Style.Row>
      <Style.Caption>타입</Style.Caption>
      <Style.SubCaption>기본값은 포토카드입니다.</Style.SubCaption>
      <Style.Select
        isDisabled={false}
        style={SelectStyle}
        defaultValue={defaultValue}
        optionList={typeOptionList}
        onChange={info => info && GoodsDetailStore.dispatch(GoodsDetailActions.setType(info))}
      />
    </Style.Row>
  );
};
