import { FloatingButton } from "App/Atomics/FloatingButton";
import { Input } from "App/Atomics/Input";
import { Loading } from "App/Atomics/Loading";
import { Tag } from "App/Atomics/Tag";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { MultiFloatingButton } from "App/Molecules/MultiFloatingButton";
import { Pagination } from "App/Molecules/Pagination";
import { allowPlaylistCreate } from "App/Routes/AdminRoutes/allowTables";
import { useAppStore } from "App/Store";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { LoadingAction } from "App/Store/Loading";
import { FilterModalTemplate } from "App/Templates/FilterModalTemplate";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { TableTemplate } from "App/Templates/TableTemplate";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { ReactComponent as CDIcon } from "assets/icons/cd.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as ExcelIcon } from "assets/icons/excel.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { configs } from "configs";
import { Column } from "constants/Column";
import { DATA_VOUCHER_KIND_LIST, ORIGINAL_CSV_KIND_LIST } from "constants/PlaylistCompanyList";
import { PLAYLIST, PLAYLIST_DETAIL, TRACKS } from "constants/route";
import { HEADER_HEIGHT_PX, MARGING_LARGE_PX, PADDING_X_LARGE_PX } from "constants/size";
import { TargetTableInput } from "constants/TargetTableInput";
import { UserRole } from "constants/UserRole";
import { ValidType } from "constants/ValidType";
import Time from "dayjs";
import fileSaver from "file-saver";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { CheckPlaylistCopyright, DeletePlaylistRelation } from "GraphQL/Queries/Playlist";
import { Parser } from "json2csv";
import { KeyInfo } from "lib/key-info";
import { Modal } from "lib/modal";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { useToggle } from "lib/use-toggle";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { usePlaylistDetailStore } from "../Store";
import { PlaylistDetailActions, PlaylistRelation } from "../Store/PlaylistDetail";
import { DataTemplate } from "./DataTemplate";
import { ChangeOrderModal } from "./Modal/ChangeOrderModal";
import { CheckDuplicatedModal } from "./Modal/CheckDulicatedTrackModal";
import { MusicDownloadModal } from "./Modal/MusicDownloadModal";
import { SelectCsvTypeModal } from "./Modal/SelectCsvTypeModal";
import { SelectMusicTitleModal } from "./Modal/SelectMusicTitleModal";
import { useUpdateTrackInformation } from "./useUpdateTrackInformation";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow-x: scroll;
  overflow-y: auto;
  font-size: 0.9rem;
  width: 100%;

  table {
    margin-top: ${HEADER_HEIGHT_PX};

    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }
  }

  .floating-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: #fff;
  }

  .big {
    width: 1.8rem;
    height: 1.8rem;
    fill: #fff;
  }
`;

const ButtonGroup = styled.div<{ isShow: boolean }>`
  display: ${props => (props.isShow ? "flex" : "none")};
  margin-left: auto;
  & > button {
    width: 8rem;
    font-weight: 400;
    padding-left: ${PADDING_X_LARGE_PX};
    padding-right: ${PADDING_X_LARGE_PX};
  }
`;

export const toLicenseFormat = (license: any, language?: string) => {
  const track =
    license.find((item: any) => item?.countryCode === (language ?? "KR")) ?? license.find((item: any) => item?.countryCode === "ZZ");
  if (track) {
    try {
      return `${JSON.parse(track.notice).text}${track.licenseDate ? `\n(updated: ${track.licenseDate})` : ""}`;
    } catch {
      return "";
    }
  }
  return "";
};

type GenreRelation = {
  genre: any[];
  mood: any[];
};

export const toPlaylistFormat = (
  { id, order, metadata }: PlaylistRelation,
  csvType: string,
  playlistId: string,
  playlistName?: string,
  playlistKind?: string
) => {
  // TODO: Artist Relation
  const { composer, performer, instrument } = metadata[0].artists.reduce(
    (result: any, { role: [role], artist: [artist] }: any) => {
      if (!role) {
        return result;
      } else {
        const { typeRole, name } = role;
        switch (typeRole) {
          case "job": {
            if (name === "Composer") {
              result.composer.push(artist.name);
            } else if (name === "perform main") {
              result.performer.push(artist.name);
            }
            break;
          }
          case "instrument": {
            result.instrument.push(name);
            break;
          }
        }
        return result;
      }
    },
    { composer: [], performer: [], instrument: [] }
  );
  if (playlistKind && ORIGINAL_CSV_KIND_LIST.includes(playlistKind)) {
    const originalArtist = metadata[0].artists.filter(({ role }) => role[0]?.name === "Original")[0]?.artist[0]?.name ?? "-";
    const extraData = metadata[0]?.track_information[0]?.extraData ?? "-";
    const tempo = metadata[0]?.track_information[0]?.tempo;
    const bpm = !tempo ? "-" : `${tempo} BPM`;
    const genreRelation: GenreRelation = {
      genre: [],
      mood: []
    };
    for (const {
      genre: [{ typeKind, name }]
    } of metadata[0].genres) {
      genreRelation[typeKind as keyof GenreRelation].push(name);
    }
    const result = {
      순서: order,
      제목: metadata[0].title,
      오리지널: originalArtist,
      가수: performer.join(", "),
      장르: genreRelation.genre.join(", "),
      BPM: bpm,
      기타정보: extraData
    };
    return result;
  } else if (playlistKind && DATA_VOUCHER_KIND_LIST.includes(playlistKind)) {
    let workSummary = "";
    let trackSummary = "";
    for (const { parent } of metadata[0].structures) {
      for (const { parentMetadata } of parent) {
        if (parentMetadata[0].typeSubclass !== "album") {
          workSummary = parentMetadata[0].title;
          trackSummary = metadata[0].title;
        } else {
          workSummary = metadata[0].title;
        }
      }
    }
    const license = toLicenseFormat(metadata[0].license);
    const result = {
      playlist_id: playlistId,
      name: playlistName,
      id: metadata[0].metadataId,
      title: metadata[0].title,
      work_summary: workSummary,
      track_summary: trackSummary,
      composer: composer.join(", "),
      performer: performer.length ? performer[0] : composer[0],
      license
    };
    return result;
  }
  // TODO: csvType === "default"
  else if (csvType === "default") {
    const genreRelation: GenreRelation = {
      genre: [],
      mood: []
    };
    for (const {
      genre: [{ typeKind, name }]
    } of metadata[0].genres) {
      genreRelation[typeKind as keyof GenreRelation].push(name);
    }

    let workSummary = "";
    let trackSummary = "";

    for (const { parent } of metadata[0].structures) {
      for (const { parentMetadata } of parent) {
        if (parentMetadata[0].typeSubclass !== "album") {
          workSummary = parentMetadata[0].title;
          trackSummary = metadata[0].title;
        } else {
          workSummary = metadata[0].title;
        }
      }
    }
    const license = toLicenseFormat(metadata[0].license);
    const result = {
      playlist_id: playlistId,
      name: playlistName,
      id: metadata[0].metadataId,
      title: metadata[0].title,
      work_summary: workSummary,
      track_summary: trackSummary,
      genre: genreRelation.genre.join(", "),
      mood: genreRelation.mood.join(", "),
      instrument: instrument.join(", "),
      composer: composer.join(", "),
      performer: performer.length ? performer[0] : composer[0],
      license
    };
    return result;
  } else if (csvType === "audioClip" || csvType === "audioClip-cn") {
    // TODO: csvType === "audioClip"
    const url = !metadata[0].metadataUrl.length ? undefined : metadata[0].metadataUrl.filter(track => track.typeUrl === "mp3high");
    const mp3highPath = !url || !url.length ? "" : `${configs.urls.audio}/${url[0].url}`;

    const { korName } = metadata[0].artists.reduce(
      (result: any, { artist }) => {
        if (!artist[0].artist_history || !artist[0].artist_history.length) {
          return result;
        } else {
          const { artist_title } = artist[0];
          result.korName.push(...artist_title.map(({ value }) => value));
          return result;
        }
      },
      { korName: [] }
    );

    const trackTextKor =
      !metadata[0].titleRelation || !metadata[0].titleRelation.length ? "" : metadata[0].titleRelation.map(({ name }) => name).join(", ");
    const roles = !metadata[0].artists.length
      ? ""
      : metadata[0].artists
          .filter(({ role }) => role.length)
          .map(({ role }) => role[0].name)
          .join(", ");
    const albumLabel =
      !metadata[0].structures.length || !metadata[0].structures[0].parent.length
        ? ""
        : metadata[0].structures[0].list.filter(({ metadata }: { metadata: any }) => metadata[0].typeSubclass === "album")[0].metadata[0]
            .title;
    const playTime =
      !metadata[0].subdataUnique || !metadata[0].subdataUnique.length || !JSON.parse(metadata[0].subdataUnique[0].value).duration
        ? ""
        : Time(+`${JSON.parse(metadata[0].subdataUnique[0].value).duration}000`).format("mm:ss");
    const birth = !metadata[0].artists[0].artist[0].artist_history.length ? "" : metadata[0].artists[0].artist[0].artist_history[0].birth;
    const death = !metadata[0].artists[0].artist[0].artist_history.length ? "" : metadata[0].artists[0].artist[0].artist_history[0].death;
    const { composer, performs, performMain } = metadata[0].artists.reduce(
      (result: any, { role: [role], artist: [artist] }: any) => {
        if (!role) {
          return result;
        } else {
          const { typeRole, name } = role;
          result.performs.push(artist.name);
          switch (typeRole) {
            case "job": {
              if (name === "Composer") {
                result.composer.push(artist.name);
              } else if (name === "perform main") {
                result.performMain.push(artist.name);
              }
              break;
            }
          }
          return result;
        }
      },
      { composer: [], performs: [], performMain: [] }
    );
    let workSummary = "";
    let trackSummary = "";

    for (const { parent } of metadata[0].structures) {
      for (const { parentMetadata } of parent) {
        if (parentMetadata[0].typeSubclass !== "album") {
          workSummary = parentMetadata[0].title;
          trackSummary = metadata[0].title;
        } else {
          workSummary = metadata[0].title;
        }
      }
    }
    const license = toLicenseFormat(metadata[0].license);
    const licenseCN = toLicenseFormat(metadata[0].license, "CN");
    const result = {
      id,
      mp3high_path: mp3highPath,
      track_id: metadata[0].metadataId,
      play_time: playTime,
      author: composer.join(", "),
      kor_name: korName.join(", "),
      birth,
      death,
      perform_main: performMain.join(""),
      performs: performs.join(" / "),
      roles: roles,
      genres: metadata[0].genres
        .filter(({ genre }) => genre[0].typeKind === "genre")
        .map(genre => genre.genre[0].name)
        .join(", "),
      work_summary: workSummary,
      track_summary: trackSummary,
      track_text_korean: trackTextKor,
      album_label: albumLabel,
      license,
      license_cn: licenseCN,
      rec_year: !metadata[0].licenseExtra.length ? "" : metadata[0].licenseExtra[0].recordYear,
      publish_year: !metadata[0].licenseExtra.length ? "" : metadata[0].licenseExtra[0].publishYear
    };
    return result;
  } else if (csvType === "kaist") {
    // TODO: CsvType === "Kaist"
    const result = {
      Album:
        !metadata[0].structures.length || !metadata[0].structures[0].parent.length
          ? ""
          : metadata[0].structures[0].parent[0].parentMetadata[0].title,
      Composer: composer.join(", "),
      Performer: performer.join(", "),
      Instrument: instrument.join(", "),
      ID: metadata[0].metadataId,
      Title: metadata[0].title,
      No: metadata[0].no,
      Work: !metadata[0].metadata_self_relations_list_metadata.length
        ? ""
        : metadata[0].metadata_self_relations_list_metadata[0].metadata_self_relations_element_metadata[0].title,
      Genre: metadata[0].genres
        .filter(({ genre }) => genre[0].typeKind === "genre")
        .map(genre => genre.genre[0].name)
        .join(", "),
      Mood: metadata[0].genres
        .filter(({ genre }) => genre[0].typeKind === "mood")
        .map(genre => genre.genre[0].name)
        .join(", "),
      RecordYear: !metadata[0].licenseExtra.length ? "" : metadata[0].licenseExtra[0].recordYear,
      PublishYear: !metadata[0].licenseExtra.length ? "" : metadata[0].licenseExtra[0].publishYear,
      FirstEdition: !metadata[0].licenseExtra.length ? "" : metadata[0].licenseExtra[0].firstEdition,
      Place: !metadata[0].licenseExtra.length ? "" : metadata[0].licenseExtra[0].place
    };
    return result;
  } else if (csvType === "taskManage") {
    const originalArtist = metadata[0].artists.filter(({ role }) => role[0]?.name === "Original")[0]?.artist[0]?.name ?? "-";
    const extraData = metadata[0]?.track_information[0]?.extraData ?? "-";
    const genreRelation: GenreRelation = {
      genre: [],
      mood: []
    };
    for (const {
      genre: [{ typeKind, name }]
    } of metadata[0].genres) {
      genreRelation[typeKind as keyof GenreRelation].push(name);
    }
    const result = {
      순번: order,
      아이디: metadata[0].metadataId,
      곡명: metadata[0].title,
      아티스트: originalArtist,
      가창자: extraData,
      장르: genreRelation.genre.join(", ")
    };
    return result;
  } else {
    const metadata_license = metadata[0].license;
    const ytv_license =
      !metadata_license.length || metadata_license[0].validCheck === ValidType.V3
        ? "미확인"
        : metadata_license[0].validCheck === ValidType.DONE
        ? "사용 가능"
        : "만료";
    const license = toLicenseFormat(metadata_license);
    const result = {
      playlist_name: playlistName,
      playlist_id: playlistId,
      artist: performer.length ? performer[0] : "",
      track_id: metadata[0].metadataId,
      title: metadata[0].title,
      license,
      ytv_license
    };
    return result;
  }
};

const downloadLicenseData = async (playlistRelations: PlaylistRelation[], csvType: string) => {
  try {
    let data = [];
    let parser;
    if (!playlistRelations.length) {
      Toast.warning("다운로드할 음원이 없습니다.");
      return;
    }
    const { playlist } = playlistRelations[0];
    const { playlistId, name, kind } = playlist[0];
    if (ORIGINAL_CSV_KIND_LIST.includes(kind)) {
      data = playlistRelations.map((tracks, index) => {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name, kind);
        Object.assign(result, { "#": index + 1 });
        return result;
      });
      if (!data.length) {
        return;
      }
      parser = new Parser({
        fields: ["순서", "제목", "오리지널", "가수", "장르", "BPM", "기타정보"]
      });
    } else if (DATA_VOUCHER_KIND_LIST.includes(kind)) {
      data = playlistRelations.map((tracks, index) => {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name, kind);
        Object.assign(result, { "#": index + 1 });
        return result;
      });
      if (!data.length) {
        return;
      }
      parser = new Parser({
        fields: ["#", "playlist_id", "name", "id", "title", "work_summary", "track_summary", "composer", "performer", "license"]
      });
    } else if (csvType === "default") {
      data = playlistRelations.map((tracks, index) => {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        Object.assign(result, { "#": index + 1 });
        return result;
      });
      if (!data.length) {
        return;
      }
      parser = new Parser({
        fields: [
          "#",
          "playlist_id",
          "name",
          "id",
          "title",
          "work_summary",
          "track_summary",
          "genre",
          "mood",
          "instrument",
          "composer",
          "performer",
          "license"
        ]
      });
    } else if (csvType === "audioClip-cn") {
      for (const tracks of playlistRelations) {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        data.push(result);
      }
      parser = new Parser({
        fields: [
          "mp3high_path",
          "track_id",
          "play_time",
          "author",
          "kor_name",
          "birth",
          "death",
          "perform_main",
          "performs",
          "roles",
          "genres",
          "work_summary",
          "track_summary",
          "track_text_korean",
          "album_label",
          "license",
          "license_cn",
          "rec_year",
          "publish_year"
        ]
      });
    } else if (csvType === "audioClip") {
      for (const tracks of playlistRelations) {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        data.push(result);
      }
      parser = new Parser({
        fields: [
          "mp3high_path",
          "track_id",
          "play_time",
          "author",
          "kor_name",
          "birth",
          "death",
          "perform_main",
          "performs",
          "roles",
          "work_summary",
          "track_summary",
          "track_text_korean",
          "album_label",
          "license",
          "rec_year",
          "publish_year"
        ]
      });
    } else if (csvType === "audioClip-cn") {
      for (const tracks of playlistRelations) {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        data.push(result);
      }
      parser = new Parser({
        fields: [
          "mp3high_path",
          "track_id",
          "play_time",
          "author",
          "kor_name",
          "birth",
          "death",
          "perform_main",
          "performs",
          "roles",
          "genres",
          "work_summary",
          "track_summary",
          "track_text_korean",
          "album_label",
          "license",
          "license_cn",
          "rec_year",
          "publish_year"
        ]
      });
    } else if (csvType === "kaist") {
      for (const tracks of playlistRelations) {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        data.push(result);
      }
      parser = new Parser({
        fields: [
          "Album",
          "Composer",
          "Performer",
          "Instrument",
          "ID",
          "Title",
          "No",
          "Work",
          "Genre",
          "Mood",
          "RecordYear",
          "PublishYear",
          "FirstEdition",
          "Place"
        ]
      });
    } else if (csvType === "taskManage") {
      for (const tracks of playlistRelations) {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        data.push(result);
      }
      parser = new Parser({
        fields: ["순번", "아이디", "곡명", "아티스트", "가창자", "장르"]
      });
    } else {
      for (const tracks of playlistRelations) {
        const result = toPlaylistFormat(tracks, csvType, playlistId, name);
        data.push(result);
      }
      parser = new Parser({
        fields: ["playlist_name", "playlist_id", "artist", "track_id", "title", "license", "ytv_license"]
      });
    }
    const file = new Blob(["\ufeff", parser.parse(data)], { type: "text/csv;charset=utf-8;" });
    const extraFileName =
      csvType === "default"
        ? ""
        : csvType === "audioClip"
        ? "_audioclip"
        : csvType === "kaist"
        ? "_kaist"
        : csvType === "taskManage"
        ? "_task"
        : "_youtube";
    const fileName = `playlist_${playlistId}${extraFileName}.csv`;
    fileSaver.saveAs(file, fileName);
    return;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const PlaylistDetailTable = () => {
  const router = useHistory();
  const [{ userRole, loading, store }, dispatchApp] = useAppStore(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading,
    store
  }));
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [selectedMode, setSelectedMode] = useState<boolean>(false);
  const queryParams = useQueryParams();
  const updateTrackInformation = useUpdateTrackInformation();
  const allowEditPlaylist = userRole === UserRole.Master || (userRole === UserRole.Arbeit && allowPlaylistCreate(store));
  const playlistId = queryParams.get("id", { default: undefined });
  const count = queryParams.get("count", { default: 30, cast: v => +v });
  const filter = queryParams.get("filter", { default: undefined });

  const [{ playlistRelation, visibleField, edge, checkList, deleteList, titleType, typeUrl, csvType }, dispatch] = usePlaylistDetailStore(
    store => ({
      playlistRelation: store.PlaylistDetail.playlistRelation,
      visibleField: store.PlaylistDetail.playlistRelation[0]?.playlist[0].userRelation.filter(({ owner }) => !owner)[0]?.visibleField ?? [],
      edge: store.PlaylistDetail.edge,
      checkList: store.PlaylistDetail.checkList,
      deleteList: store.PlaylistDetail.deleteList,
      titleType: store.PlaylistDetail.titleType,
      typeUrl: store.PlaylistDetail.typeUrl,
      csvType: store.PlaylistDetail.csvType,
      order: store.PlaylistDetail.order
    })
  );
  const { serviceType, playlistKind, validCheck } = !playlistRelation.length
    ? { serviceType: undefined, playlistKind: undefined, validCheck: ValidType.V3 }
    : {
        serviceType: playlistRelation[0].playlist[0].service,
        playlistKind: playlistRelation[0].playlist[0].kind,
        validCheck: playlistRelation[0].playlist[0].validCheck
      };

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const onToggleAllCheck = useCallback(
    (checked: boolean) => {
      playlistRelation.forEach(({ id, metadata }) => {
        dispatch([
          PlaylistDetailActions.setPlyalistDetailCheckList({ id: metadata[0].metadataId, checked }),
          PlaylistDetailActions.setPlaylistDetailDeleteList({ id, checked })
        ]);
      });
    },
    [dispatch, playlistRelation]
  );

  const isChecked = (list: Map<string, boolean>) => {
    const filteredList = Array.from(list).filter(item => playlistRelation.map(({ metadata }) => metadata[0].metadataId).includes(item[0]));
    return list.size === 0 || !filteredList.length ? false : filteredList.every(item => item[1]);
  };

  const PLAYLIST_SUB_HEAD = [
    <AnimatedCheckbox
      id={playlistId}
      isChecked={isChecked(checkList)}
      onToggle={() =>
        onToggleAllCheck(
          checkList.size === 0
            ? true
            : Array.from(checkList)
                .filter(item => playlistRelation.map(({ metadata }) => metadata[0].metadataId).includes(item[0]))
                .some(item => !item[1])
        )
      }
    />,
    "순서",
    "ID",
    "VC",
    "No",
    "제목",
    "음원",
    "가수",
    "오리지널",
    "장르",
    "무드",
    "가사",
    "라이센스",
    "재생시간"
  ];
  const PLAYLIST_PARTNER_SUB_HEAD: (Column | JSX.Element)[] = Object.entries(Column)
    .filter(
      ([_, value]) =>
        visibleField.includes(value) &&
        value !== Column.mp3 &&
        value !== Column.wav &&
        value !== Column.aac &&
        value !== Column.flac &&
        value !== Column.file
    )
    .map(([_, value]) => value);
  if (PLAYLIST_PARTNER_SUB_HEAD.includes(Column.checkbox)) {
    PLAYLIST_PARTNER_SUB_HEAD.splice(
      0,
      1,
      <AnimatedCheckbox
        id={playlistId}
        isChecked={isChecked(checkList)}
        onToggle={() =>
          onToggleAllCheck(
            checkList.size === 0
              ? true
              : Array.from(checkList)
                  .filter(item => playlistRelation.map(({ metadata }) => metadata[0].metadataId).includes(item[0]))
                  .some(item => !item[1])
          )
        }
      />
    );
  }
  const HEAD_LIST = [
    { name: "플레이리스트 상세정보", colSpan: userRole === UserRole.Partner ? PLAYLIST_PARTNER_SUB_HEAD.length : PLAYLIST_SUB_HEAD.length }
  ];

  const musicDownloadModal = useToggle();
  const selectMusicTitleModal = useToggle();
  const selectCsvTypeModal = useToggle();
  const checkDuplicatedTrackModal = useToggle();
  const changeOrderModal = useToggle();
  const filterModal = useToggle();

  const handleFloatViewButton = useCallback(() => {
    if (Array.from(checkList).filter(item => item[1]).length <= 0) {
      Toast.error("선택한 트랙이 없습니다.", undefined, "top-center");
      return;
    }
    const idList = Array.from(checkList)
      .filter(item => item[1])
      .map(item => `${item[0]}`);
    router.push(TRACKS + `?page=1&ids=[${idList}]${!filter ? "" : `&filter=${filter}`}`);
  }, [checkList, filter, router]);

  const handleFloatDeleteButton = useCallback(async () => {
    if (Array.from(checkList).filter(item => item[1]).length <= 0) {
      Toast.error("선택한 트랙이 없습니다.", undefined, "top-center");
      return;
    }
    const deleteIds = Array.from(checkList)
      .filter(item => item[1])
      .map(item => item[0]);
    const deleteUuids = Array.from(deleteList)
      .filter(item => item[1])
      .map(item => item[0]);
    if (window.confirm(`선택한 ${deleteIds.length}곡을 삭제하시겠습니까?`)) {
      setEditLoading(true);
      const accessId = await requestAccessRecord({ targetId: playlistId, targetTable: TargetTableInput.Playlist });
      if (accessId) {
        try {
          for (const uuid of _.reverse(deleteUuids)) {
            const { errors } = await DeletePlaylistRelation({ uuid });
            if (errors) {
              throw new Error(errors[0].message);
            }
          }
          dispatch([PlaylistDetailActions.deletePlaylistDetailTrackByIds(deleteIds)]);
          await DeleteAccessRecord({ id: accessId });
          setEditLoading(false);
          window.alert("삭제되었습니다.");
          window.location.reload();
        } catch (err) {
          console.log(err);
          await DeleteAccessRecord({ id: accessId });
          setEditLoading(false);
          Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
        }
      }
    }
  }, [checkList, deleteList, dispatch, playlistId]);

  const onCheckCopyright = async (id: string) => {
    setEditLoading(true);
    try {
      const { metadataPlaylistRelation } = await CheckPlaylistCopyright({ id });
      if (!metadataPlaylistRelation.length) {
        Toast.primary("만료된 곡이 없습니다.", undefined, "top-center");
        return;
      }
      metadataPlaylistRelation.forEach(({ metadata }) => {
        const metadataId = metadata[0].id;
        dispatch([
          PlaylistDetailActions.setPlyalistDetailCheckList({ id: metadataId, checked: true }),
          PlaylistDetailActions.setPlaylistDetailDeleteList({ id, checked: true })
        ]);
      });
      Toast.error(`총 ${metadataPlaylistRelation.length}곡이 만료된 곡이 선택되었습니다.`, undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("저작권 검사에 실패하였습니다.", undefined, "top-center");
    } finally {
      setEditLoading(false);
    }
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        dispatch(PlaylistDetailActions.clearPlyalistDetailCheckList());
      }
    },
    [dispatch]
  );

  const onUpdateTrackInformation = async () => {
    if (window.confirm("모든 트랙의 시간 정보를 업데이트하시겠습니까?")) {
      setEditLoading(true);
      try {
        await updateTrackInformation(playlistId);
        Toast.success("업데이트에 성공하였습니다.");
      } catch (err) {
        console.log(err);
        Toast.error("업데이트에 실패하였습니다.");
      } finally {
        setEditLoading(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        if (!playlistRelation.length && edge) {
          dispatchApp(LoadingAction.setLoading(LOADING.NULL));
        }
        const ids = playlistRelation.map(({ metadata }) => metadata[0].metadataId);
        dispatch([PlaylistDetailActions.setPlaylistDetailCheckListInit(ids), PlaylistDetailActions.setPlaylistDetailDeleteListInit(ids)]);
      }
    },
    [playlistRelation]
  );

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        {(userRole === UserRole.Master || allowEditPlaylist) && <Input.Button onClick={filterModal.on}>필터</Input.Button>}
        {(userRole === UserRole.Master || allowEditPlaylist) && <Input.Button onClick={changeOrderModal.on}>순서 변경</Input.Button>}
        {userRole === UserRole.Master && playlistRelation.length ? (
          <Input.Button onClick={checkDuplicatedTrackModal.on}>중복 검사</Input.Button>
        ) : null}
        {userRole !== UserRole.Partner && allowEditPlaylist && playlistRelation.length ? (
          <Input.Button onClick={() => onCheckCopyright(playlistId)}>저작권 검사</Input.Button>
        ) : null}
        {(userRole === UserRole.Master || allowEditPlaylist) && (
          <Input.Button onClick={onUpdateTrackInformation}>시간 업데이트</Input.Button>
        )}
        <ButtonGroup isShow>
          <Tag color="warning" onClick={() => router.push(PLAYLIST)}>
            돌아가기
          </Tag>
        </ButtonGroup>
      </TopbarTemplate>
      <TableTemplate
        headList={HEAD_LIST}
        subHeadList={userRole === UserRole.Partner ? PLAYLIST_PARTNER_SUB_HEAD : PLAYLIST_SUB_HEAD}
        keyBy={data => data.id}
        dataList={loading === LOADING.UNLOAD ? [] : playlistRelation}
        Column={DataTemplate}
      />
      <LoadingTemplate loading={loading} />
      <Loading loading={editLoading} />
      {userRole !== UserRole.Partner || visibleField.length ? (
        <Pagination
          edge={edge}
          goTo={index =>
            `${PLAYLIST_DETAIL}?page=${index}&count=${count}${!playlistId ? "" : `&id=${playlistId}`}${!filter ? "" : `&filter=${filter}`}`
          }
        />
      ) : null}

      <Modal isOpen={selectMusicTitleModal.isToggled}>
        <SelectMusicTitleModal
          visibleField={visibleField}
          openAllDownloadModal={() => {
            setSelectedMode(false);
            musicDownloadModal.on();
          }}
          openSelectedMusicDownloadModal={() => {
            setSelectedMode(true);
            musicDownloadModal.on();
          }}
          toClose={selectMusicTitleModal.off}
        />
      </Modal>
      <Modal isOpen={selectCsvTypeModal.isToggled}>
        <SelectCsvTypeModal
          playlistId={playlistId}
          downloadLicenseData={csvPlaylistRelation => downloadLicenseData(csvPlaylistRelation, csvType)}
          toClose={selectCsvTypeModal.off}
        />
      </Modal>
      <Modal isOpen={musicDownloadModal.isToggled}>
        <MusicDownloadModal
          selectedMode={selectedMode}
          playlistId={playlistId}
          titleType={titleType}
          typeUrl={typeUrl}
          toClose={musicDownloadModal.off}
        />
      </Modal>
      <Modal isOpen={checkDuplicatedTrackModal.isToggled}>
        <CheckDuplicatedModal id={playlistId} kind={playlistKind} serviceType={serviceType} toClose={checkDuplicatedTrackModal.off} />
      </Modal>
      <Modal isOpen={filterModal.isToggled} onClose={filterModal.off}>
        <FilterModalTemplate route={PLAYLIST_DETAIL} toClose={filterModal.off} />
      </Modal>
      <Modal isOpen={changeOrderModal.isToggled} onClose={changeOrderModal.off}>
        <ChangeOrderModal id={playlistId} toClose={changeOrderModal.off} />
      </Modal>
      {(userRole === UserRole.Master || allowEditPlaylist) && (
        <MultiFloatingButton>
          <MultiFloatingButton.Item title="download mp3" onClick={selectMusicTitleModal.on}>
            <DownloadIcon className="floating-icon" />
          </MultiFloatingButton.Item>
          <MultiFloatingButton.Item title="download excel" onClick={selectCsvTypeModal.on}>
            <ExcelIcon className="floating-icon" />
          </MultiFloatingButton.Item>
          <MultiFloatingButton.Item title="view tracks info" onClick={handleFloatViewButton}>
            <CDIcon className="floating-icon" />
          </MultiFloatingButton.Item>
          <MultiFloatingButton.Item title="delete track" onClick={handleFloatDeleteButton}>
            <TrashIcon className="floating-icon" />
          </MultiFloatingButton.Item>
        </MultiFloatingButton>
      )}
      {(userRole === UserRole.Partner || userRole === UserRole.Client) && validCheck === ValidType.DONE && (
        <>
          <FloatingButton color="primary" style={{ bottom: "105px" }} onClick={selectMusicTitleModal.on}>
            <DownloadIcon className="floating-icon big" />
          </FloatingButton>
          <FloatingButton color="success" onClick={selectCsvTypeModal.on}>
            <ExcelIcon className="floating-icon big" />
          </FloatingButton>
        </>
      )}
    </Layout>
  );
};
