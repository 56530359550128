import React, { useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "constants/media";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { configs } from "configs";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { useToggle } from "lib/use-toggle";
import { Toast } from "lib/toast";
import { Loading } from "App/Atomics/Loading";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as BarcodeIcon } from "assets/icons/barcode.svg";
import { AC_WHITE, DANGER_ALERT_COLOR } from "constants/color";
import { ProductModel } from "models/ProductModel";
import { useDeleteProduct } from "App/Routes/Product/Hooks/useDeleteProduct";
import { useHistory } from "lib/use-router";
import { PRODUCT } from "constants/route";
import NoImage from "assets/images/noImage.png";

type Props = {
  readonly data: ProductModel;
};

export const CardItem = ({ data: product }: Props) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  const router = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const confirmModal = useToggle();
  const { deleteProduct } = useDeleteProduct();
  const image = product.urls?.find(item => item?.typeUrl === "cover")?.url;
  const onGoToDetail = () => router.push(`${PRODUCT}/${product.id.raw}`);
  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteProduct(product.id.key);
      Toast.primary("삭제되었습니다.");
    } catch (err) {
      console.log(err);
      Toast.error("삭제에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };
  return (
    <Layout>
      <ImageContainter onClick={onGoToDetail}>
        <Link className="link">
          <Image src={image ? `${configs.urls.image}/${image}` : NoImage} alt={`cover`} />
        </Link>
        <ActiveGroup className="active-group" onClick={e => e.stopPropagation()}>
          {userRole === UserRole.Master && <DeleteIcon onClick={confirmModal.on} />}
        </ActiveGroup>
        <SerialNumber>{product.quality}</SerialNumber>
        {product.barcode && (
          <BarcodeBox error={product.barcode.length > 14} onClick={e => e.stopPropagation()}>
            <ClipBoard text={product.barcode}>
              <BarcodeIcon />
            </ClipBoard>
          </BarcodeBox>
        )}
      </ImageContainter>
      <Info>
        <ClipBoard text={product.id.raw}>
          <Id>{product.id.raw}</Id>
        </ClipBoard>
        <ClipBoard text={product.displayName}>
          <Title>{product.displayName}</Title>
        </ClipBoard>
        <ClipBoard text={product.price.toString()}>
          <Price>{`${Intl.NumberFormat("ko").format(product.price)}원`}</Price>
        </ClipBoard>
      </Info>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm title={"알림"} context={"해당 상품을 삭제하시겠습니까?"} toSave={onDelete} toClose={confirmModal.off} />
      </Modal>
      {loading && <Loading loading={loading} />}
    </Layout>
  );
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;
  transition: width 0.2s ease-in-out;
  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  cursor: pointer;
  &:hover {
    .active-group {
      opacity: 1;
    }
    .link::after {
      opacity: 1;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 9em;
  max-height: 8em;

  span {
    white-space: pre-wrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .id {
    font-size: 0.8em;
    color: #bbb;
  }
  .title {
    font-weight: bold;
    flex-wrap: wrap;
  }
`;

const Link = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &::after {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    z-index: 1;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent, rgba(0, 0, 0, 0.3));
    transition: opacity 0.2s ease-in;
  }
`;

const Image = styled.img`
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #eee;
`;
const Id = styled.span`
  font-size: 0.8em;
  color: #bbb;
`;
const SerialNumber = styled.span`
  position: absolute;
  top: 3%;
  left: 3%;
  font-size: 1em;
  font-weight: bold;
  color: ${AC_WHITE};
  z-index: 2;
  &:hover {
    text-decoration: underline;
  }
`;
const Title = styled.span`
  flex-wrap: wrap;
  font-weight: 600;
`;
const Price = styled.span`
  flex-wrap: wrap;
  font-size: 11px;
`;

const ActiveGroup = styled.div`
  position: absolute;
  left: 3%;
  bottom: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  z-index: 2;
  transition: opacity 0.2s;
  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
    cursor: pointer;
    transition: fill 0.2s;
    &:hover {
      fill: ${DANGER_ALERT_COLOR};
    }
  }
`;

const BarcodeBox = styled.div<{ error?: boolean }>`
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  svg {
    width: 36px;
    height: 36px;
    fill: ${({ error }) => (!error ? "#fff" : "red")};
    cursor: pointer;
    &:hover {
      fill: #eee;
    }
  }
`;
