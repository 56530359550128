import React from "react";
import styled from "styled-components";
import { PADDING_XX_LARGE_PX, MARGING_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { TextDivider } from "../..";
import { GRAY_6 } from "constants/baseColor";

const Layout = styled.div``;

const ArtistContainer = styled.form`
  padding: 0 ${PADDING_XX_LARGE_PX};
`;

const InfoGridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-column-gap: ${MARGING_LARGE_PX};
  div {
    margin: ${MARGING_SMALL_PX} 0;
  }

  div:nth-child(odd) {
    color: ${GRAY_6};
  }
`;

export const RightTemplate = () => {
  return (
    <Layout>
      <ArtistContainer>
        <TextDivider>
          <span>채널 정보</span>
          <div />
        </TextDivider>
        <InfoGridBox>
          <div>채널명</div>
          <div>-</div>
          <div>생성일</div>
          <div>-</div>
          <div>권리사</div>
          <div>-</div>
          <div>국적</div>
          <div>-</div>
        </InfoGridBox>

        <TextDivider>
          <span>아티스트 소개</span>
          <div />
        </TextDivider>
        <InfoGridBox>
          <div>데뷔</div>
          <div>-</div>
          <div>유형</div>
          <div>-</div>
          <div>장르</div>
          <div>-</div>
          <div>소속사</div>
          <div>-</div>
        </InfoGridBox>
        <TextDivider>
          <span>소셜 정보</span>
          <div />
        </TextDivider>
        <InfoGridBox>
          <div>트위터</div>
          <div>-</div>
          <div>페이스북</div>
          <div>-</div>
          <div>유튜브</div>
          <div>-</div>
          <div>국적</div>
          <div>-</div>
        </InfoGridBox>
      </ArtistContainer>
    </Layout>
  );
};
