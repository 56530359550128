/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  metadataId: string;
  type: string;
  value: string;
  languageCode: string;
  order: number;
}>;

export const CreateDescAlbum = async (option: Option) => {
  const $metadataId = <Variable name="metadataId" scalar={<IdScalar />} value={option.metadataId} />;
  const $type = <Variable name="type_metadata_title" scalar={<StringScalar isNullable={false} />} value={option.type} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_DESC_ALBUM">
      <SelectionSet
        name="updateMetadata"
        alias="createDescAlbum"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            type_metadata_class: "record",
            metadata_title: {
              create: [
                {
                  type_metadata_title: $type,
                  language: { connect: { language_code: $languageCode } },
                  value: $value,
                  exposure_order: $order
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata_title">
          <Field name="id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createDescAlbum: {
      metadata_id: string;
      metadata_title: {
        id: string;
      }[];
    };
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
