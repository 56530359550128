/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar, IdScalar } from "graphql-jsx";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
  name?: string;
  detail?: string;
  validCheck?: string;
}>;

export const UpdateGenreRenewal = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const $name = <Variable name="type_url" scalar={<StringScalar isNullable={true} />} value={option.name} />;
  const $detail = <Variable name="url" scalar={<StringScalar isNullable={true} />} value={option.detail} />;
  const $validCheck = <Variable name="validCheck" scalar={<ValidTypeScalar />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GENRE">
      <SelectionSet
        name="updateGenre"
        argument={{
          where: { genre_id: $id },
          data: {
            name: $name,
            detail: $detail,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="genre_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    updateGenre: {
      id: string;
    };
  };
  return await clients.genre.request<Data>(query, variables);
};
