import React from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { Element, Role } from "GraphQL/Queries/Role/GetRole";
import { useRolesDispatch } from "App/Routes/Roles/Store";
import { RoleActions } from "App/Routes/Roles/Store/Role";
import { RoleTypeFunctionInfo } from "constants/RoleTypeFunctionInfo";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";

type SectionProps = {
  index: number;
  data: Role;
  pageIndex: Page;
};

const typeClassOptionInfo = [
  { id: "character", name: "배역 (character)" },
  { id: "job", name: "직업 (job)" },
  { id: "instrument", name: "악기 (instrument)" }
];

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 600px;
  padding: 0.5rem;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const InputNumber = styled(Input.Number)`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `2rem 40% 52%` : `2rem 34% 28% 28%`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

export const ColumnSection = ({ index, data, pageIndex }: SectionProps) => {
  const dispatch = useRolesDispatch();
  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        // eslint-disable-next-line array-callback-return
        .map(({ key, value }) => {
          if (value === null) return null;
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "id":
              case "name":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "id" ? "ID" : "이름"}</span>
                    <InputText
                      isDisabled={key === "id"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(RoleActions.setRoleName(index, val))}
                    />
                  </LabelGroup>
                );
              case "typeClass":
                return (
                  <LabelGroup key={key}>
                    <span>{"클래스"}</span>
                    <Input.TextSelect
                      className="select"
                      optionList={typeClassOptionInfo}
                      defaultValue={typeClassOptionInfo.find(({ id }) => id === (value as string))}
                      onBlur={info => {
                        if (info) {
                          dispatch(RoleActions.setRoleTypeClass(index, info.id));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(RoleActions.setRoleVC(index, vc));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "count":
              case "expose":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "count" ? "역할 개수" : "우선 순위"}</span>
                    <InputNumber
                      step={1}
                      min={0}
                      max={99}
                      defaultValue={value as number}
                      onChange={val => dispatch(RoleActions.setRoleCountExpose(index, key, val))}
                    />
                  </LabelGroup>
                );
              case "listRelation":
                return (
                  <LabelGroup key={key}>
                    <span>역할 관계</span>
                    <RowGroup className={"border"}>
                      {!(value as Element[]).length
                        ? null
                        : (value as Element[]).map(({ uuid, typeFunction, elementRelation }, subIndex) => {
                            return (
                              <ExtraInfo key={uuid} column={3} className="subdata">
                                <RoundButton
                                  color="danger"
                                  key={`${uuid}-remove`}
                                  onClick={() => {
                                    dispatch(RoleActions.deleteRoleElement(index, subIndex));
                                  }}
                                >
                                  -
                                </RoundButton>
                                <RoleTextAutoComplete
                                  className="select"
                                  key={`${uuid}-role`}
                                  defaultValue={
                                    !elementRelation[0].id || !elementRelation[0].name
                                      ? undefined
                                      : { id: elementRelation[0].id, name: elementRelation[0].name }
                                  }
                                  onBlur={info => {
                                    if (info?.id.length) {
                                      dispatch(RoleActions.updateRoleElementRole(index, subIndex, info));
                                    }
                                  }}
                                />
                                <Input.TextSelect
                                  className="select"
                                  key={`${uuid}-category`}
                                  optionList={RoleTypeFunctionInfo}
                                  defaultValue={
                                    !typeFunction
                                      ? undefined
                                      : RoleTypeFunctionInfo.find(({ id }) => id === typeFunction) ?? {
                                          id: typeFunction,
                                          name: typeFunction
                                        }
                                  }
                                  onBlur={info => {
                                    if (info?.id.length) {
                                      dispatch(RoleActions.updateRoleElementTypeFunction(index, subIndex, info.id));
                                    }
                                  }}
                                />
                              </ExtraInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          dispatch(RoleActions.createRoleElement(index));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          }
        })}
    </>
  );
};
