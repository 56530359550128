import { createDuck } from "lib/store/v2";
import { Edge } from "models/Edge";
import { Metadata, Artist, Company, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import { ValidType } from "constants/ValidType";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Title } from "GraphQL/Queries/Metadata/GetMetadataTitleRelation";
import { Genre } from "App/Routes/WorkDetail/Store/WorkDetail";

const createInitialState = () => ({
  edge: null as null | Edge,
  albums: [] as Metadata[],
  origin: [] as Metadata[],
  genreList: [] as Genre[],
  moodList: [] as Genre[],
  loading: LOADING.ONLOAD as LOADING,
  editLoading: false as boolean,
  titleRelation: [] as Title[],
  modalId: "" as string
});

export const AlbumActions = createDuck({
  namespace: "Album",
  createInitialState,
  reducers: {
    setLoading(state, loading: LOADING) {
      state.loading = loading;
    },
    setEditLoading(state, loading: boolean) {
      state.editLoading = loading;
    },
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setAlbum(state, albums: Metadata[]) {
      state.albums = albums;
      state.origin = albums;
    },
    deleteAlbum(state, index: number) {
      state.albums.splice(index, 1);
    },
    deleteAlbumById(state, id: string) {
      const album = state.albums.filter(item => item.metadataId !== id);
      state.albums = album;
    },
    setAlbumClear(state) {
      state.albums = state.origin;
    },
    setAlbumSave(state) {
      state.origin = state.albums;
    },
    updateAlbumTitle(state, { index, title }: { index: number; title: string }) {
      state.albums[index].title = title;
    },
    updateAlbumVC(state, { index, vc }: { index: number; vc: ValidType }) {
      state.albums[index].validCheck = vc;
    },
    createAlbumImage(
      state,
      { index, uuid, typeUrl, url, order }: { index: number; uuid: string; typeUrl: string; url: string; order: number }
    ) {
      if (!state.albums[index].metadataUrl.length) {
        state.albums[index].metadataUrl = [{ uuid, typeUrl, url, validCheck: ValidType.V3, order }];
      } else {
        state.albums[index].metadataUrl.push({
          uuid,
          typeUrl,
          url,
          validCheck: ValidType.V3,
          order
        });
      }
    },
    updateAlbumImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.albums[index].metadataUrl.findIndex((url: any) => url.uuid === uuid);
      state.albums[index].metadataUrl[urlIndex].typeUrl = typeUrl;
      state.albums[index].metadataUrl[urlIndex].url = url;
    },
    updateOriginAlbumImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.origin[index].metadataUrl.findIndex((url: any) => url.uuid === uuid);
      state.origin[index].metadataUrl[urlIndex].typeUrl = typeUrl;
      state.origin[index].metadataUrl[urlIndex].url = url;
    },
    createAlbumArtist(state, index: number) {
      const { artistRelation } = state.albums[index];
      const uuid = uuidv4();
      const order = !artistRelation.length ? 0 : _.last(artistRelation)!.order + 1;
      const tempArtist = {
        uuid,
        artist: [{ id: undefined, name: undefined }],
        role: [{ id: undefined, name: undefined }],
        character: [{ id: undefined, name: undefined }],
        validCheck: ValidType.V3,
        order
      } as Artist;
      if (!artistRelation.length) {
        state.albums[index].artistRelation = [tempArtist];
      } else {
        state.albums[index].artistRelation.push(tempArtist);
      }
    },
    updateAlbumArtist(state, { index, artistIndex, info }: { index: number; artistIndex: number; info: Info }) {
      const { artistRelation } = state.albums[index];
      artistRelation[artistIndex].artist[0].id = info.id;
      artistRelation[artistIndex].artist[0].name = info.name;
    },
    updateAlbumArtistUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { artistRelation } = state.albums[index];
      const artistIndex = artistRelation.findIndex(({ uuid }) => uuid === prevUuid);
      artistRelation[artistIndex].uuid = uuid;
    },
    updateAlbumRole(state, { index, roleIndex, info }: { index: number; roleIndex: number; info: Info }) {
      const { artistRelation } = state.albums[index];
      artistRelation[roleIndex].role[0].id = info.id;
      artistRelation[roleIndex].role[0].name = info.name;
    },
    updateAlbumCharacter(state, { index, characterIndex, info }: { index: number; characterIndex: number; info: Info }) {
      const { artistRelation } = state.albums[index];
      if (!artistRelation[characterIndex].character.length) {
        artistRelation[characterIndex].character = [{ id: info.id, name: info.name }];
      } else {
        artistRelation[characterIndex].character[0].id = info.id;
        artistRelation[characterIndex].character[0].name = info.name;
      }
    },
    deleteAlbumArtist(state, { index, artistIndex }: { index: number; artistIndex: number }) {
      state.albums[index].artistRelation.splice(artistIndex, 1);
    },
    setAlbumRightsCompany(state, { index, rightsCompany }: { index: number; rightsCompany: Company[] }) {
      state.albums[index].rightsCompany = rightsCompany;
    },
    setAlbumProductions(state, { index, productions }: { index: number; productions: Company[] }) {
      state.albums[index].productions = productions;
    },
    setAlbumPublications(state, { index, publications }: { index: number; publications: Company[] }) {
      state.albums[index].publications = publications;
    },
    updateAlbumCompanyUuid(
      state,
      { index, typeKind, prevUuid, uuid }: { index: number; typeKind: string; prevUuid: string; uuid: string }
    ) {
      if (typeKind === "rightsCompany") {
        const companyIndex = state.albums[index].rightsCompany.findIndex(({ uuid }) => uuid === prevUuid);
        state.albums[index].rightsCompany[companyIndex].uuid = uuid;
      }
      if (typeKind === "productions") {
        const companyIndex = state.albums[index].productions.findIndex(({ uuid }) => uuid === prevUuid);
        state.albums[index].productions[companyIndex].uuid = uuid;
      }
      if (typeKind === "publications") {
        const companyIndex = state.albums[index].publications.findIndex(({ uuid }) => uuid === prevUuid);
        state.albums[index].publications[companyIndex].uuid = uuid;
      }
    },
    createAlbumUrl(state, { index, url }: { index: number; url: Url }) {
      if (!state.albums[index].metadataUrl.length) {
        state.albums[index].metadataUrl = [url];
      } else {
        state.albums[index].metadataUrl.push(url);
      }
    },
    updateAlbumTypeUrl(state, { index, urlIndex, typeUrl }: { index: number; urlIndex: number; typeUrl: string }) {
      const order = (_.last(state.albums[index].metadataUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.albums[index].metadataUrl[urlIndex].typeUrl = typeUrl;
      state.albums[index].metadataUrl[urlIndex].order = order;
    },
    updateAlbumUrl(state, { index, urlIndex, url }: { index: number; urlIndex: number; url: string }) {
      state.albums[index].metadataUrl[urlIndex].url = url;
    },
    updateAlbumUrlUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { metadataUrl } = state.albums[index];
      const urlIndex = metadataUrl.findIndex(({ uuid }) => uuid === prevUuid);
      metadataUrl[urlIndex].uuid = uuid;
    },
    deleteAlbumUrl(state, { index, urlIndex }: { index: number; urlIndex: number }) {
      state.albums[index].metadataUrl.splice(urlIndex, 1);
    },
    setAlbumTitleRelation(state, titleRelation: Title[]) {
      state.titleRelation = titleRelation;
    },
    createAlbumTitleRelation(
      state,
      {
        uuid,
        typeTitle,
        value,
        languageCode,
        order
      }: { uuid: string; typeTitle: string; value: string; languageCode: string; order: number }
    ) {
      const newTitle = {
        uuid,
        typeTitle,
        value,
        validCheck: ValidType.V3,
        order,
        language: [
          {
            languageCode
          }
        ]
      };
      state.titleRelation.push(newTitle);
    },
    updateAlbumTitleRelation(
      state,
      { index, typeTitle, value, languageCode }: { index: number; typeTitle: string; value: string; languageCode: string }
    ) {
      state.titleRelation[index].typeTitle = typeTitle;
      state.titleRelation[index].value = value;
      state.titleRelation[index].language[0].languageCode = languageCode;
    },
    deleteAlbumTitleRelation(state, uuid: string) {
      const index = state.titleRelation.findIndex(title => title.uuid === uuid);
      state.titleRelation.splice(index, 1);
    },
    setAlbumTitleRelationModalId(state, uuid: string) {
      state.modalId = uuid;
    }
  }
});
