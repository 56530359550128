/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";

type Company = {
  id: string;
  name: string;
};
type Production = Company;
type Publication = Company;

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

type Option = Readonly<{
  no: string;
  title: string;
  class: string;
  subClass: string;
  rightsCompany: Company;
  productions?: Production[];
  publications?: Publication[];
  urls?: Url[];
  parentId?: number;
  genreIds?: string[];
  record?: number;
  publish?: number;
  edition?: number;
}>;

export const CreateEffect = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar isNullable />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $title_no_space =
    option.title === option.title.replace(/\s/gi, "") ? (
      undefined
    ) : (
      <Variable name="title_no_space" scalar={<StringScalar isNullable={false} />} value={option.title.replace(/\s/gi, "")} />
    );
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.rightsCompany.id} />;
  const $rightsCompany = <MetadataRightScalar isNullable value={MetadataRightType.rightCompany} />;
  const $production = <MetadataRightScalar isNullable value={MetadataRightType.production} />;
  const $publication = <MetadataRightScalar isNullable value={MetadataRightType.publication} />;
  const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable={false} />} value={option.parentId} />;
  const $recordYear = <Variable name="recordYear" scalar={<IntScalar isNullable />} value={option.record} />;
  const $publishYear = <Variable name="publishYear" scalar={<IntScalar isNullable />} value={option.publish} />;
  const $firstEdition = <Variable name="firstEdition" scalar={<IntScalar isNullable />} value={option.edition} />;
  const genreIds = option.genreIds?.map((id, order) => ({ id, order })) ?? [];

  const { query, variables } = render(
    <Mutation operationName="CREATE_EFFECT">
      <SelectionSet
        name="createMetadata"
        argument={{
          data: {
            no: $no,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                !$title_no_space
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_metadata_title: "name",
                      value: $title_no_space,
                      exposure_order: 1
                    }
              ]
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $rightsCompany,
                  exposure_order: 0
                },
                ...(option.productions?.map(({ id: company_id }, order) => ({
                  company: {
                    connect: {
                      company_id: company_id
                    }
                  },
                  type_kind: $production,
                  exposure_order: order
                })) ?? []),
                ...(option.publications?.map(({ id: company_id }, order) => ({
                  company: {
                    connect: {
                      company_id: company_id
                    }
                  },
                  type_kind: $publication,
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_genre_relation: {
              create: !genreIds.length
                ? []
                : genreIds.map(({ id }, order) => ({
                    genre: {
                      connect: {
                        genre_id: id
                      }
                    },
                    exposure_order: order
                  }))
            },
            metadata_url: {
              create: [...(option.urls?.map(({ typeUrl, url }) => ({ type_url: typeUrl, url })) ?? [])]
            },
            track_license_extra: {
              create: [
                {
                  record_year: $recordYear,
                  publish_year: $publishYear,
                  first_edition: $firstEdition
                }
              ]
            },
            metadata_structure: {
              create: [{ parent_id: $parentId }]
            }
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata_url">
          <Field name="id" />
          <Field name="type_url" />
          <Field name="url" />
        </SelectionSet>
        <SelectionSet name="metadata_structure">
          <Field name="structure_id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createMetadata: Readonly<{
      metadata_id: string;
      metadata_url: {
        id: string;
        type_url: string;
        url: string;
      }[];
      metadata_structure: {
        structure_id: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
