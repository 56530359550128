import React, { ReactElement, useRef } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import styled from "styled-components";
import { GRAY_1, GRAY_2 } from "constants/baseColor";
import { ProductCreateStore } from "App/Routes/ProductCreate/Store";
import { ProductCreateActions, Url } from "App/Routes/ProductCreate/Store/ProductCreate";
import { v4 as uuidv4 } from "uuid";

type Props = {
  readonly urls: Map<string, Url>;
};

export const ProductUrls = ({ urls }: Props): ReactElement => {
  const inputCoverRef = useRef<HTMLInputElement>(null);

  const addCoverUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    const id = uuidv4();
    try {
      fileReader.readAsDataURL(file.files![0]);
      fileReader.onloadend = e => {
        const url = {
          id,
          file: file.files![0],
          data: e.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          url: file.files![0].name,
          typeUrl: file.files![0].type
        };
        ProductCreateStore.dispatch(ProductCreateActions.addCoverUrl(url));
        inputCoverRef.current!.value = "";
      };
    } catch (err) {
      return;
    }
  };

  const deleteUrl = (id: string) => ProductCreateStore.dispatch(ProductCreateActions.removeCoverUrl(id));

  return (
    <Style.Row>
      <Style.Caption>이미지</Style.Caption>
      <Style.SubCaption>이미지를 누르면 삭제됩니다.</Style.SubCaption>
      <ImageContainer>
        <UploadButton>
          <AddLabel htmlFor="input-file-thumbnail">+</AddLabel>
          <HiddenInput ref={inputCoverRef} type="file" id="input-file-thumbnail" accept="image/jpeg,image/png" onChange={addCoverUrl} />
        </UploadButton>
        {Array.from(urls).map(([key, value]) => (
          <Image key={key} src={URL.createObjectURL(value.file)} alt="cover" onClick={() => deleteUrl(key)} />
        ))}
      </ImageContainer>
    </Style.Row>
  );
};

const ImageContainer = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  align-items: center;
`;

const UploadButton = styled.div`
  width: 100%;
  height: 100%;
  min-height: 123px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  transition: background 0.15s;
  &:hover {
    background: ${GRAY_1};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;

const AddLabel = styled.label`
  font-size: 36px;
  color: ${GRAY_2};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HiddenInput = styled.input`
  display: none;
`;
