import React, { useState } from "react";
import { ProductDate } from "./ProductDate";
import { ProductQuality } from "./ProductQuality";
import { ProductDetail } from "./ProductDetail";
import styled from "styled-components";
import { ProductCreateStore, useProductCreateSelector } from "../../Store";
import { Style } from "../../Style";
import { Toast } from "lib/toast";
import { ProductGoods } from "./ProductGoods";
import { Loading } from "App/Atomics/Loading";
import { ProductUrls } from "./ProductUrls";
import { ProductPrice } from "./ProductPrice";
import { useCreateProduct } from "../../Hooks/useCreateProduct";
import { useCreateImage } from "../../Hooks/useCreateImage";
import { ProductCreateActions } from "../../Store/ProductCreate";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { ProductSubdataOption } from "./ProductSubdataOption";
import { useUpdateGoodsSale } from "../../Hooks/useUpdateGoodsSale";

export const Form = () => {
  const { goods, details, price, quality, urls, createdAt } = useProductCreateSelector(store => store.ProductCreate.product);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { createProduct } = useCreateProduct();
  const { createImage } = useCreateImage();
  const { updateGoodsSale } = useUpdateGoodsSale();
  const tryToCreate = async () => {
    if (!window.confirm("상품을 등록하시겠습니까?")) return;
    setLoading(true);
    const accessId = await requestAccessRecord({ targetId: goods!.id, targetTable: TargetTableInput.Goods });
    try {
      if (!goods?.extra?.isSale) {
        await updateGoodsSale(goods!.id);
      }
      const { data, errors } = await createProduct();
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (data) {
        const { goodsId, id: productId } = data.createCommodity;
        await createImage(goodsId, productId);
        Toast.primary("성공적으로 등록되었습니다.");
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      Toast.error("등록에 실패하였습니다.");
      return;
    } finally {
      await DeleteAccessRecord({ id: accessId! });
      setLoading(false);
    }
  };

  const onClear = () => {
    ProductCreateStore.dispatch(ProductCreateActions.clear());
    setSuccess(false);
  };
  return (
    <Layout>
      {!success ? (
        <Style.Title>상품 등록을 위해 아래 정보를 입력해주세요.</Style.Title>
      ) : (
        <Style.Title>성공적으로 등록되었습니다.</Style.Title>
      )}
      <ProductGoods key={goods?.id} goods={goods} />
      <ProductSubdataOption goods={goods} />
      <ProductQuality quality={quality} />
      <ProductDetail details={details} />
      <ProductPrice price={price} />
      <ProductDate createdAt={createdAt} />
      <ProductUrls urls={urls} />
      <Style.Row>
        <Style.ButtonGroup>
          {!success ? (
            <Style.IconButton onClick={tryToCreate}>등록</Style.IconButton>
          ) : (
            <Style.IconButton onClick={onClear}>초기화</Style.IconButton>
          )}
        </Style.ButtonGroup>
      </Style.Row>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div``;
