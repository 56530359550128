// * OpenColor Schema
// * https://yeun.github.io/open-color/

export const TRANSPARENT = "hsl(0 0% 0% / 0%)";

export const WHITE = "hsl(0 0% 100%)";
export const BLACK = "hsl(0 0% 0%)";

export const GRAY_0 = "hsl(210 1% 98%)";
export const GRAY_1 = "hsl(210 2% 95%)";
export const GRAY_2 = "hsl(210 3% 93%)";
export const GRAY_3 = "hsl(210 3% 89%)";
export const GRAY_4 = "hsl(210 6% 83%)";
export const GRAY_5 = "hsl(210 8% 71%)";
export const GRAY_6 = "hsl(210 11% 56%)";
export const GRAY_7 = "hsl(210 16% 31%)";
export const GRAY_8 = "hsl(210 19% 23%)";
export const GRAY_9 = "hsl(210 20% 15%)";

export const RED_0 = "hsl(0 4% 97%)";
export const RED_1 = "hsl(0 11% 93%)";
export const RED_2 = "hsl(0 21% 86%)";
export const RED_3 = "hsl(0 34% 77%)";
export const RED_4 = "hsl(0 47% 69%)";
export const RED_5 = "hsl(0 58% 61%)";
export const RED_6 = "hsl(0 67% 54%)";
export const RED_7 = "hsl(0 74% 48%)";
export const RED_8 = "hsl(0 78% 42%)";
export const RED_9 = "hsl(0 79% 37%)";

export const PINK_0 = "hsl(336 6% 97%)";
export const PINK_1 = "hsl(336 13% 93%)";
export const PINK_2 = "hsl(338 23% 86%)";
export const PINK_3 = "hsl(339 35% 79%)";
export const PINK_4 = "hsl(339 47% 72%)";
export const PINK_5 = "hsl(339 58% 64%)";
export const PINK_6 = "hsl(339 68% 56%)";
export const PINK_7 = "hsl(339 76% 49%)";
export const PINK_8 = "hsl(339 81% 42%)";
export const PINK_9 = "hsl(339 82% 36%)";

export const GRAPE_0 = "hsl(280 5% 97%)";
export const GRAPE_1 = "hsl(287 13% 93%)";
export const GRAPE_2 = "hsl(288 24% 89%)";
export const GRAPE_3 = "hsl(289 38% 82%)";
export const GRAPE_4 = "hsl(288 51% 76%)";
export const GRAPE_5 = "hsl(288 60% 69%)";
export const GRAPE_6 = "hsl(288 66% 63%)";
export const GRAPE_7 = "hsl(288 69% 57%)";
export const GRAPE_8 = "hsl(288 70% 51%)";
export const GRAPE_9 = "hsl(288 71% 44%)";

export const VIOLET_0 = "hsl(252 6% 96%)";
export const VIOLET_1 = "hsl(257 14% 92%)";
export const VIOLET_2 = "hsl(256 25% 85%)";
export const VIOLET_3 = "hsl(255 40% 76%)";
export const VIOLET_4 = "hsl(255 53% 68%)";
export const VIOLET_5 = "hsl(255 62% 62%)";
export const VIOLET_6 = "hsl(255 67% 58%)";
export const VIOLET_7 = "hsl(255 69% 54%)";
export const VIOLET_8 = "hsl(255 70% 50%)";
export const VIOLET_9 = "hsl(255 69% 46%)";

export const INDIGO_0 = "hsl(223 7% 96%)";
export const INDIGO_1 = "hsl(225 14% 92%)";
export const INDIGO_2 = "hsl(228 27% 84%)";
export const INDIGO_3 = "hsl(228 43% 74%)";
export const INDIGO_4 = "hsl(228 54% 67%)";
export const INDIGO_5 = "hsl(228 63% 61%)";
export const INDIGO_6 = "hsl(228 69% 56%)";
export const INDIGO_7 = "hsl(228 72% 52%)";
export const INDIGO_8 = "hsl(228 73% 48%)";
export const INDIGO_9 = "hsl(230 73% 43%)";

export const BLUE_0 = "hsl(205 9% 96%)";
export const BLUE_1 = "hsl(206 18% 91%)";
export const BLUE_2 = "hsl(206 35% 83%)";
export const BLUE_3 = "hsl(206 54% 73%)";
export const BLUE_4 = "hsl(207 69% 65%)";
export const BLUE_5 = "hsl(207 79% 58%)";
export const BLUE_6 = "hsl(208 85% 53%)";
export const BLUE_7 = "hsl(208 87% 48%)";
export const BLUE_8 = "hsl(209 87% 43%)";
export const BLUE_9 = "hsl(209 86% 39%)";

export const CYAN_0 = "hsl(185 10% 95%)";
export const CYAN_1 = "hsl(185 21% 91%)";
export const CYAN_2 = "hsl(186 37% 82%)";
export const CYAN_3 = "hsl(187 56% 72%)";
export const CYAN_4 = "hsl(187 73% 63%)";
export const CYAN_5 = "hsl(188 84% 56%)";
export const CYAN_6 = "hsl(187 89% 50%)";
export const CYAN_7 = "hsl(188 91% 45%)";
export const CYAN_8 = "hsl(189 92% 39%)";
export const CYAN_9 = "hsl(189 92% 34%)";

export const TEAL_0 = "hsl(161 9% 95%)";
export const TEAL_1 = "hsl(160 22% 88%)";
export const TEAL_2 = "hsl(162 38% 79%)";
export const TEAL_3 = "hsl(162 57% 68%)";
export const TEAL_4 = "hsl(162 74% 58%)";
export const TEAL_5 = "hsl(162 84% 50%)";
export const TEAL_6 = "hsl(162 90% 44%)";
export const TEAL_7 = "hsl(162 93% 39%)";
export const TEAL_8 = "hsl(162 94% 34%)";
export const TEAL_9 = "hsl(162 94% 30%)";

export const GREEN_0 = "hsl(131 6% 95%)";
export const GREEN_1 = "hsl(128 15% 88%)";
export const GREEN_2 = "hsl(128 26% 79%)";
export const GREEN_3 = "hsl(129 40% 69%)";
export const GREEN_4 = "hsl(130 52% 59%)";
export const GREEN_5 = "hsl(130 61% 51%)";
export const GREEN_6 = "hsl(131 67% 45%)";
export const GREEN_7 = "hsl(131 69% 41%)";
export const GREEN_8 = "hsl(131 70% 36%)";
export const GREEN_9 = "hsl(132 69% 32%)";

export const LIME_0 = "hsl(79 10% 95%)";
export const LIME_1 = "hsl(80 20% 89%)";
export const LIME_2 = "hsl(81 34% 81%)";
export const LIME_3 = "hsl(82 50% 71%)";
export const LIME_4 = "hsl(83 67% 62%)";
export const LIME_5 = "hsl(84 79% 53%)";
export const LIME_6 = "hsl(85 85% 47%)";
export const LIME_7 = "hsl(85 88% 42%)";
export const LIME_8 = "hsl(86 91% 37%)";
export const LIME_9 = "hsl(85 91% 33%)";

export const YELLO_0 = "hsl(50 14% 95%)";
export const YELLO_1 = "hsl(49 25% 90%)";
export const YELLO_2 = "hsl(49 40% 84%)";
export const YELLO_3 = "hsl(48 60% 76%)";
export const YELLO_4 = "hsl(47 77% 69%)";
export const YELLO_5 = "hsl(45 90% 62%)";
export const YELLO_6 = "hsl(42 98% 56%)";
export const YELLO_7 = "hsl(39 100% 53%)";
export const YELLO_8 = "hsl(35 100% 50%)";
export const YELLO_9 = "hsl(31 100% 46%)";

export const ORANGE_0 = "hsl(34 10% 95%)";
export const ORANGE_1 = "hsl(33 20% 90%)";
export const ORANGE_2 = "hsl(33 34% 84%)";
export const ORANGE_3 = "hsl(32 53% 74%)";
export const ORANGE_4 = "hsl(31 70% 65%)";
export const ORANGE_5 = "hsl(29 83% 58%)";
export const ORANGE_6 = "hsl(27 92% 52%)";
export const ORANGE_7 = "hsl(24 97% 47%)";
export const ORANGE_8 = "hsl(21 95% 44%)";
export const ORANGE_9 = "hsl(17 93% 40%)";

// Custom Color Schema
export const BROWN_0 = "hsl(23 54% 89%)";
export const BROWN_1 = "hsl(24 53% 78%)";
export const BROWN_2 = "hsl(25 49% 66%)";
export const BROWN_3 = "hsl(26 45% 55%)";
export const BROWN_4 = "hsl(27 56% 43%)";
export const BROWN_5 = "hsl(30 100% 29%)";
export const BROWN_6 = "hsl(29 91% 25%)";
export const BROWN_7 = "hsl(28 83% 21%)";
export const BROWN_8 = "hsl(28 76% 17%)";
export const BROWN_9 = "hsl(29 73% 12%)";
