import React from "react";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";
import { useTracksStore } from "../../Store";
import { Subhead } from "../../Store/TableInfo/sanitizeHeads";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_SMALL_PX } from "constants/size";
import itiriri from "itiriri";
import { Tooltip } from "App/Atomics/Tooltip";
import { Input } from "App/Atomics/Input";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { Link } from "App/Atomics/Link";
import { WorkTrackActions } from "../../Store/Track";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";

type TitleProps = Readonly<{
  titles: any[];
  subhead: Subhead;
}>;

type ArtistProps = Readonly<{
  artists: any[];
  subhead: Subhead;
}>;

type UrlProps = Readonly<{
  title: string;
  urls: any[];
  subhead: Subhead;
}>;

type CompanyProps = Readonly<{
  companies: any[];
  subhead: Subhead;
}>;

type ExtraProps = Readonly<{
  extras: any[];
  subhead: Subhead;
}>;

type GenreProps = Readonly<{
  genres: any[];
  subhead: Subhead;
}>;

type InfoProps = Readonly<{
  info: any[];
  subhead: Subhead;
}>;

const LongValue = styled.textarea`
  width: ${pixelize(UNIT * 20)};
  background-color: transparent;
  resize: vertical;
  overflow-x: hidden;
`;

const Multiline = styled(Table.Data)`
  & > div + div {
    margin-top: ${MARGING_SMALL_PX};
  }

  & a {
    width: 100%;
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const AlignCenterBox = styled.div`
  text-align: center;
`;

export const DataTemplate = ({ index, data }: ColumnProps<any>) => {
  const { metadataId, title, typeMetadataSubclass, validCheck, titles, artists, urls, companies, extras, genres, track_information } = data;
  const [{ subhead, checkList, tracks }, dispatch] = useTracksStore(store => ({
    subhead: store.WorkTrackTableInfo.subhead,
    checkList: store.WorkTrack.checkList,
    tracks: store.WorkTrack.tracks
  }));
  const { userRole, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    store
  }));

  // TODO: Input Check Event
  const onToggleCheck = (id: string, checked: boolean) => {
    const currentPageIdList = tracks.map(({ metadataId }: { metadataId: string }) => metadataId);
    if (
      !currentPageIdList.filter(id =>
        Array.from(checkList)
          .map(item => item[0])
          .includes(id)
      ).length
    ) {
      dispatch(WorkTrackActions.setTrackCheckListInit(currentPageIdList));
    }
    dispatch(WorkTrackActions.setTrackCheckList({ id, checked }));
  };

  return (
    <>
      <Table.Row key={index}>
        {(userRole === UserRole.Master || allowMetadataUpdate(store)) && subhead["#"]["#"] && (
          <Table.Data>
            <AnimatedCheckbox
              id={index.toString()}
              isChecked={checkList.get(metadataId)}
              onToggle={checked => onToggleCheck(metadataId, checked)}
            />
          </Table.Data>
        )}
        {artists && <Artist artists={artists} subhead={subhead} />}
        {validCheck && <AlignCenterData>{validCheck}</AlignCenterData>}
        {metadataId && <AlignCenterData>{metadataId}</AlignCenterData>}
        {subhead.Metadata.title && (
          <Table.Data style={{ minWidth: pixelize(UNIT * 20), whiteSpace: "pre-wrap" }}>{title && title}</Table.Data>
        )}
        {typeMetadataSubclass && <AlignCenterData>{typeMetadataSubclass}</AlignCenterData>}

        {titles && <Title titles={titles} subhead={subhead} />}
        {urls && <Url title={title} urls={urls} subhead={subhead} />}
        {companies && <Company companies={companies} subhead={subhead} />}
        {extras && <LicenseExtra extras={extras} subhead={subhead} />}
        {genres && <Genre genres={genres} subhead={subhead} />}
        {<Info info={track_information} subhead={subhead} />}
      </Table.Row>
    </>
  );
};

const Title = ({ titles, subhead }: TitleProps) => {
  if (!titles.length) {
    return (
      <>{itiriri(Object.entries(subhead.Title)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  }
  return (
    <>
      {subhead.Title.language && (
        <Multiline>
          {(titles as any[]).map((title, index) => (
            <AlignCenterBox key={index}>{title.language[0].languageCode && title.language[0].languageCode}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Title.typeMetadataTitle && (
        <Multiline>
          {(titles as any[]).map((title, index) => (
            <AlignCenterBox key={index}>{title.typeMetadataTitle && title.typeMetadataTitle}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Title.value && (
        <Multiline>
          {(titles as any[]).map((title, index) => (
            <LongValue key={index} rows={5} readOnly value={title.value && window.decodeURIComponent(title.value)} />
          ))}
        </Multiline>
      )}
    </>
  );
};

const Artist = ({ artists, subhead }: ArtistProps) => {
  if (!artists.length) {
    return (
      <>{itiriri(Object.entries(subhead.Artist)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  } else if (!artists[0]) {
    return (
      <>{itiriri(Object.entries(subhead.Artist)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  }

  return (
    <>
      {subhead.Artist.artist && (
        <Multiline>
          {(artists as any[]).map((artist, index) => (
            <AlignCenterBox key={index}>{artist.artist.length && artist.artist[0].name}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Artist.role && (
        <Multiline>
          {(artists as any[]).map((artist, index) => (
            <AlignCenterBox key={index}>{artist.role && artist.role.length && artist.role[0].name}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Artist.character && (
        <Multiline>
          {(artists as any[]).map((artist, index) => (
            <AlignCenterBox key={index}>{artist.character && artist.character.length ? artist.character[0].name : "-"}</AlignCenterBox>
          ))}
        </Multiline>
      )}
    </>
  );
};

const Url = ({ title, urls, subhead }: UrlProps) => {
  const userRole = useAppSelector(store => store.UserToken.role);

  if (!urls.length) {
    return (
      <>
        {itiriri(Object.entries(subhead.Url)).map(([key, value], index) => {
          if (userRole === UserRole.Arbeit && (key === "typeUrl" || key === "fileValidCheck")) {
            return null;
          } else {
            return value ? <Table.Data key={index}>{}</Table.Data> : null;
          }
        })}
      </>
    );
  }
  return (
    <>
      {userRole !== UserRole.Arbeit && subhead.Url.typeUrl && (
        <Multiline>
          {(urls as any[]).map((url, index) => (
            <div key={index}>{url.typeUrl && url.typeUrl}</div>
          ))}
        </Multiline>
      )}
      {subhead.Url.url && (
        /*
          TODO:
          필터로 url off 했다가 다시 on 했을 때 url이 undefined, audio 태그에서 링크 못 찾음
        */
        <Multiline>
          {(urls as any[]).map((url, index) => {
            if (url.typeUrl === "source") {
              return (
                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "1rem" }}>{url.url}</span>
                  <Tooltip text={url.url}>
                    <Link.External target={"_blank"} href={`${url.url}`}>
                      <Input.Button color="danger">이동</Input.Button>
                    </Link.External>
                  </Tooltip>
                </div>
              );
            }

            return <div key={index}>{url.url}</div>;
          })}
        </Multiline>
      )}
      {userRole !== UserRole.Arbeit && subhead.Url.fileValidCheck && (
        <Multiline>
          {(urls as any[]).map((url, index) => (
            <AlignCenterBox key={index}>{url.fileValidCheck && url.fileValidCheck}</AlignCenterBox>
          ))}
        </Multiline>
      )}
    </>
  );
};

const Company = ({ companies, subhead }: CompanyProps) => {
  if (!companies.length) {
    return <Table.Data>{}</Table.Data>;
  } else if (!companies[0].company) {
    return <Table.Data>{}</Table.Data>;
  }

  return (
    <>
      {subhead.Company.company && (
        <Table.Data>
          {(companies as any[]).map((company, index) => (
            <AlignCenterBox key={index}>{(company.company && company.company[0].license) || company.company[0].name}</AlignCenterBox>
          ))}
        </Table.Data>
      )}
    </>
  );
};

const LicenseExtra = ({ extras, subhead }: ExtraProps) => {
  if (!extras.length) {
    return (
      <>
        {itiriri(Object.entries(subhead.LicenseExtra)).map(([key, value], index) =>
          value ? <Table.Data key={index}>{}</Table.Data> : null
        )}
      </>
    );
  }

  return (
    <>
      {subhead.LicenseExtra.recordYear && <Table.Data>{extras[0].recordYear}</Table.Data>}
      {subhead.LicenseExtra.publishYear && <Table.Data>{extras[0].publishYear}</Table.Data>}
      {subhead.LicenseExtra.firstEdition && <Table.Data>{extras[0].firstEdition}</Table.Data>}
      {subhead.LicenseExtra.place && <Table.Data>{extras[0].place}</Table.Data>}
      {subhead.LicenseExtra.data && <Table.Data>{extras[0].data && extras[0].data.credit}</Table.Data>}
    </>
  );
};

const Genre = ({ genres, subhead }: GenreProps) => {
  if (!genres.length) {
    return (
      <>{itiriri(Object.entries(subhead.Genre)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  }
  return (
    <>
      {subhead.Genre.genre && (
        <Multiline>
          {(genres as any[]).map((genre, index) =>
            genre.genre[0].typeKind === "genre" ? <div key={index}>{genre.genre[0].name}</div> : null
          )}
        </Multiline>
      )}
      {subhead.Genre.mood && (
        <Multiline>
          {(genres as any[]).map((genre, index) =>
            genre.genre[0].typeKind === "mood" ? <AlignCenterBox key={index}>{genre.genre[0].name}</AlignCenterBox> : null
          )}
        </Multiline>
      )}
    </>
  );
};

const Info = ({ info, subhead }: InfoProps) => {
  if (!info?.length) {
    return (
      <>{itiriri(Object.entries(subhead.Info)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  }

  return (
    <>
      {subhead.Info.channel && <AlignCenterData>{info[0].channel}</AlignCenterData>}
      {subhead.Info.duration && <AlignCenterData>{info[0].duration}</AlignCenterData>}
      {subhead.Info.bitrate && <AlignCenterData>{info[0].bitrate}</AlignCenterData>}
      {subhead.Info.tempo && <AlignCenterData>{info[0].tempo}</AlignCenterData>}
      {subhead.Info.tonality && <AlignCenterData>{info[0].tonality}</AlignCenterData>}
      {subhead.Info.timeSignature && <AlignCenterData>{info[0].timeSignature}</AlignCenterData>}
    </>
  );
};
