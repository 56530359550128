import { removeStructure } from "./index";
import itiriri from "itiriri";
import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { Album, Track } from "../../Store/Album";
import _ from "lodash";

export const createTracksLog = async (album: Album, albumId: string, tracks: Track[], parentId: number, isEffect: boolean) => {
  const { albumCompany, productions, publications } = album;
  const createTrackQuery = itiriri(tracks)
    .map((value, index) => {
      const { trackTitle, trackArtist, trackGenre, trackLicense, trackLicenseExtra, trackUrl, work, sourceUrl } = value;
      let createTrackDataQuery = `
        no: "${value.no}"
        title: "${value.title}"
        type_metadata_class: "${value.type_metadata_class}"
        type_metadata_subclass: "${value.type_metadata_subclass}"
      `;

      createTrackDataQuery += `
          metadata_title: {
            create: [
              {
                language: {
                  connect: {
                    language_code: "OO"
                  }
                },
                type_metadata_title: "name",
                value: "${value.title}",
                exposure_order: 0
              },
              ${
                value.title !== value.title.replace(/\s/gi, "")
                  ? `  {
                language: {
                  connect: {
                    language_code: "OO"
                  }
                },
                type_metadata_title: "name",
                value: "${value.title.replace(/\s/gi, "")}",
                exposure_order: 1
              }`
                  : ""
              }
              ${
                !trackTitle
                  ? ""
                  : `,{
                language: {
                  connect: {
                    language_code: "XX"
                  }
                }
                ${itiriri(Object.entries(trackTitle))
                  .toArray(([key, value]) => `${key}: "${value}"`)
                  .join(" ")}
                }`
              }
            ]
          }
        `;

      if (work) {
        createTrackDataQuery += `
          metadata_self_relations_list_metadata: {
            create: [
              ${work.map(
                ({ id }, order) =>
                  `{
                type_function: "works"
                metadata_self_relations_element_metadata: {
                  connect: {
                    metadata_id: "${id}"
                  }
                },
                exposure_order: ${order}
              }`
              )}
              
            ]
          }
        `;
      }

      if (trackArtist && trackArtist.length) {
        createTrackDataQuery += `
        metadata_artist_relation: {
          create: [
          ${trackArtist
            .map(
              artist => `{
                artist: { connect: { artist_id: "${artist.artist_id}" } }
                role: { connect: { role_id: "${artist.role_id}" } }
                ${artist.character_id ? `character: { connect: { role_id: "${artist.character_id}" } }` : ""}
                exposure_order: ${artist.order}
              }`
            )
            .join(" ")}
          ]}
        `;
      }

      if (trackGenre && trackGenre.length) {
        const genre = trackGenre
          .filter(genre => genre.typeKind === "genre")
          .map((prevGenre, order) => ({ ...prevGenre, order: order + 1 }));
        const genreParent = _.uniq(trackGenre.filter(item => !!item?.parent).map(({ parent }) => ({ id: parent!.id, order: 0 })));
        const mood = trackGenre.filter(genre => genre.typeKind === "mood").map((prevMood, order) => ({ ...prevMood, order }));

        createTrackDataQuery += `
        metadata_genre_relation: {
          create: [
            ${genre.map(({ id, order }) => `{ genre: { connect: { genre_id: "${id}" } }, exposure_order: ${order} }`).join(" ")}
            ${genreParent.map(({ id, order }) => `{ genre: { connect: { genre_id: "${id}" } }, exposure_order: ${order} }`).join(" ")}
            ${mood
              .map(({ id, order }) => `{ genre: { connect: { genre_id: "${id}" } }, auto_tag_check: true , exposure_order: ${order} }`)
              .join(" ")}
          ]
        }`;
      }

      if (trackLicense && !isEffect) {
        createTrackDataQuery += `
          track_license: {
            create: [{
              copyright: true
              neighboring: true
              ${itiriri(Object.entries(trackLicense)).toArray(([key, value]) => {
                if (key === "publish_date") {
                  return `${key}: ${JSON.stringify(`${value} 05:00:00`)} `;
                }
                if (key === "is_service") {
                  return `${key}: ${value}`;
                }
                return `${key}: ${JSON.stringify(value)}`;
              })}
            }]
          }
        `;
      }

      if (trackLicenseExtra && !isEffect) {
        createTrackDataQuery += `
          track_license_extra: {
            create: [{
              ${itiriri(Object.entries(trackLicenseExtra)).toArray(([key, value]) => `${key}: ${value}`)}
            }]
          }
        `;
      }

      if (trackUrl) {
        createTrackDataQuery += `
        metadata_url: {
          create: [
            ${itiriri(Object.values(trackUrl))
              .filter(url => url.typeUrl !== undefined && url.url !== undefined)
              .toArray(
                value => `{
                  type_url: "${value.typeUrl}"
                  url: "${value.url}"
                  valid_check: REMOVE
                }`
              )}

            ${
              sourceUrl
                ? `{
                    type_url: "source"
                    url: "${sourceUrl}"
                  }`
                : ""
            }
          ]
        }
        `;
      }

      return `
        track: createMetadata(
          data: {
            ${createTrackDataQuery}
              metadata_company_relation: {
                create: [
                  { 
                    company: {
                      connect: { company_id: "${albumCompany.company_id}" } 
                    }
                    type_kind: rightCompany,
                    exposure_order: 0
                  }
                  ${
                    productions && productions.length
                      ? productions
                          .map(({ company_id }, order) => {
                            return `{ 
                        company: {
                          connect: { company_id: "${company_id}" }
                        } 
                        type_kind: production,
                        exposure_order: ${order}
                      }`;
                          })
                          .join(" ")
                      : ""
                  }
                    ${
                      publications && publications.length
                        ? publications
                            .map(({ company_id }, order) => {
                              return `{ 
                          company: {
                            connect: { company_id: "${company_id}" }
                          }
                          type_kind: publication,
                          exposure_order: ${order}
                        }`;
                            })
                            .join(" ")
                        : ""
                    }
                ]
              }
            metadata_structure: { create: [{ parent_id: ${parentId} }] }
          }
        ) {
          metadata_id
          metadata_url {
            id
            type_url
            url
            valid_check
          }
        }
      `;
    })
    .toArray();

  const resultTrackData = [];

  try {
    for (let trackQuery of createTrackQuery) {
      const query = gql`
        mutation CREATE_TRACKS {
          ${trackQuery}
        }
      `;
      const { data, errors } = await clients.metadata.mutation(query);

      if (errors) {
        throw errors;
      }

      resultTrackData.push(data.track);
    }

    return { data: resultTrackData };
  } catch (e) {
    console.log(`서버 오류로 ${!isEffect ? "트랙" : "효과음"} 생성에 실패했습니다.`);
    removeStructure(albumId, parentId.toString());
    return;
  }
};
