import React, { useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetMetadata } from "GraphQL/Queries/Metadata/index";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { useQueryParams } from "lib/use-query-params";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Filters } from "App/Templates/FilterModalTemplate";
import { LiveReplayStoreProvider, useLiveReplayDispatch } from "./Store";
import { LiveReplayActions } from "./Store/LiveReplay";
import { LiveReplayTable } from "./LiveReplayTable";

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;

const ChildLayout = () => {
  const dispatch = useLiveReplayDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchArtist = queryParams.get("artist", { default: undefined });

  const filter = useMemo(() => {
    const { lte, gte, count, artist, rights, url, validCheck, order } = JSON.parse(
      queryParams.get("filter", { default: undefined }) || "{}"
    ) as Filters;
    return { lte, gte, count, artist, rights, url, validCheck, order };
  }, [queryParams]);
  const { lte, gte, count, artist, rights, url, validCheck, order } = filter;
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          const { data: podcastData, errors: podcastErr } = await GetMetadata({
            first: count ? parseInt(count, 10) : 20,
            page,
            metadataId: searchId,
            title: searchTitle,
            artistName: searchArtist,
            typeClassIn: ["podcast"],
            typeSubClass: "cast",
            validCheck: validCheckIn,
            gte: gte,
            lte: lte,
            artistIdIn: artist?.map(({ id }) => id),
            rightsIdIn: rights?.map(({ id }) => id),
            url: url,
            order
          });
          if (podcastErr && podcastErr.length) {
            console.log(podcastErr[0].message);
            return;
          }
          if (podcastData) {
            dispatch([LiveReplayActions.setPodCasts(podcastData.metadata), LiveReplayActions.setEdge(podcastData.edge)]);
            if (!podcastData.metadata.length) {
              dispatch(LiveReplayActions.setLoading(LOADING.NULL));
            } else {
              dispatch(LiveReplayActions.setLoading(LOADING.ONLOAD));
            }
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
    },
    [page, searchId, searchTitle, searchArtist, filter]
  );
  return (
    <Layout>
      <LeftSideBar />
      <LiveReplayTable count={count ? parseInt(count) : 20} />
    </Layout>
  );
};

export const LiveReplay = () => (
  <LiveReplayStoreProvider>
    <Helmet title="라이브 리플레이" />
    <ChildLayout />
  </LiveReplayStoreProvider>
);
