/** @jsx jsx */

import { jsx, render, Field, Variable, SelectionSet, Query, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  no: string;
  title: string;
}>;

export const CheckExistAlbum = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar />} value={option.title} />;
  const { query, variables } = render(
    <Query operationName="CHECK_EXIST_ALBUM">
      <SelectionSet
        name="metadata"
        argument={{
          where: {
            no: $no,
            title: $title,
            valid_check__not: <ValidTypeScalar value={ValidType.REMOVE} />,
            type_metadata_class__in: ["record", "theater"],
            type_metadata_subclass: "album"
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet name="metadata_structure" alias="structure">
          <Field name="structure_root_id" alias="id" />
          <SelectionSet
            name="list"
            argument={{ where: { metadata__some: { type_metadata_class__in: ["record", "theater"], type_metadata_subclass: "track" } } }}
          >
            <SelectionSet name="metadata">
              <Field name="no" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: ReadonlyArray<{
      id: string;
      title: string;
      structure: {
        id: string;
        list: {
          metadata: {
            no: string;
          }[];
        }[];
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
