import styled, { css } from "styled-components";
import { Input } from "App/Atomics/Input";
import { AC_BLACK, DANGER_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { CYAN_8 } from "constants/baseColor";

const Title = styled.div`
  display: block;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
  color: #333d4b;
  word-break: keep-all;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const Caption = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  color: ${({ color }) => color ?? AC_BLACK};
`;

const SubCaption = styled.span`
  font-size: 0.85rem;
  margin-bottom: 2px;
  color: ${CYAN_8};
`;

const AlertCaption = styled.span`
  display: none;
  overflow: hidden;
  font-size: 0.85rem;
  color: ${DANGER_COLOR};
  margin-top: 4px;
  transition: all 0.15s;
  &[data-visible="true"] {
    display: block;
  }
`;

const InputStyle = css`
  position: relative;
  width: 100%;
  height: 44px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #cdcdcd;
  border: none;
  border-radius: 8px;
  line-height: 22px;
  padding: 1rem;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: inset 0 0 0 2px #adadad;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px ${PRIMARY_COLOR_LIGHT};
  }
`;
const InputText = styled(Input.Text)`
  ${InputStyle};
`;

const ColContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoCreateStyle = {
  Title,
  Row,
  Caption,
  SubCaption,
  AlertCaption,
  InputText,
  RowContainer,
  ButtonGroup,
  ColContainer
};
