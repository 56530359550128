import React, { useState } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  PADDING_XX_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  MARGING_XX_LARGE_PX,
  MARGING_X_LARGE_PX
} from "constants/size";
import { Divider } from "App/Atomics/Divider";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import { Input } from "App/Atomics/Input";
import { ARTIST_ROLE_FILTER } from "constants/storageKey";

type Role = Readonly<{
  id: string;
  name: string;
}>;

type Props = Readonly<{
  roleCheck: Role[];
  onClick: (roleCheck: Role[]) => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow: overlay;

  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 30)};
  padding: ${PADDING_XX_LARGE_PX};

  h3 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }

  .suggestSpan {
    display: flex;
    flex-direction: row;
    margin-bottom: ${MARGING_XX_LARGE_PX};

    & > .autocomplete {
      width: 100%;
      margin-right: ${MARGING_LARGE_PX};
    }

    button {
      height: fit-content;
    }
  }

  & > button {
    width: ${pixelize(UNIT * 5)};
    margin-top: auto;
    margin-left: auto;
  }
`;

const FilterGroup = styled.section`
  margin-bottom: ${MARGING_LARGE_PX};

  hr {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  .checkItem {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

export const RoleFilterModal = ({ roleCheck: initialRole, onClick }: Props) => {
  const [roleCheck, setRoleCheck] = useState(initialRole.length ? initialRole : ([] as Role[]));
  const [suggest, setSuggest] = useState({ id: "", name: "" });
  const toSave = () => {
    window.sessionStorage.setItem(ARTIST_ROLE_FILTER, JSON.stringify({ roleCheck }));
    onClick(roleCheck);
  };

  const addRole = (suggest: any) => {
    if (!roleCheck.some(value => value.id === suggest.id)) {
      setRoleCheck([...roleCheck, suggest]);
    }
  };

  const removeRole = (index: number) => {
    setRoleCheck([...roleCheck.filter((val, i) => index !== i)]);
  };

  return (
    <Layout>
      <h3>역할 필터</h3>
      <FilterGroup>
        <span className="suggestSpan">
          {<RoleTextAutoComplete className="autocomplete" onBlur={info => info && setSuggest(info)} />}
          <Input.Button color="default" onClick={() => suggest && addRole(suggest)}>
            추가
          </Input.Button>
        </span>
        <Divider />
        {roleCheck.map((value, index) => {
          return (
            <div key={index} className="checkItem">
              <Input.Group isAlign="center">
                <Input.Button color="danger" onClick={() => removeRole(index)}>
                  -
                </Input.Button>
                <span>{index}</span>
                <span>{value.name}</span>
              </Input.Group>
            </div>
          );
        })}
        <Divider />
      </FilterGroup>

      <Input.Button isWide onClick={toSave}>
        저장
      </Input.Button>
    </Layout>
  );
};
