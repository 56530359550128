import { createDuck } from "lib/store/v2";
import { createHash } from "utils/createHash";

const createInitialState = () => ({ hash: "", isVisible: false, percent: 0 });

export const ProgressActions = createDuck({
  namespace: "Progress",
  createInitialState,
  reducers: {
    end(state) {
      state.isVisible = false;
      state.percent = 0;
    },
    on(state, percent: number) {
      state.hash = createHash();
      state.isVisible = true;
      state.percent = percent;
    },
    add(state, point: number) {
      state.percent += point;
    },
    moveTo(state, percent: number) {
      state.percent = percent;
    }
  }
});
