/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  url: string;
  typeUrl?: string;
}>;

export const UpdateMetadataUrl = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $url = <Variable name="url" scalar={<StringScalar />} value={option.url} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable={true} />} value={option.typeUrl} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_URL">
      <SelectionSet
        name="updateMetadata_url"
        alias="updateMetadataUrl"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            url: $url,
            type_url: $typeUrl
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataUrl: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
