export const DATA_VOUCHER_KIND_LIST = [
  // "모에플소프트",
  // "애니펜",
  // "투니모션",
  // "바이믹스튜디오",
  // "조이플",
  // "모터헤드",
  // "마젤디파인",
  // "더라라",
  // "헬린캠프",
  // "스튜디오티앤티",
  // "빅레이더",
  // "라미파파",
  // "퓨처사이언테크",
  // "쉐커",
  // "미미엔터테인먼트",
  // "레트로봇",
  // "드레이크마운트",
  // "아이엠얼라이브"
] as string[];

export const ORIGINAL_CSV_KIND_LIST = ["수퍼톤", "폰에어", "젬픽홀딩스"];
