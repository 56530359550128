import React from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { Table } from "App/Atomics/Table";
import { GenreTextAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { MoodTextAutoComplete } from "App/Molecules/AutoCompletes/Mood";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { ColumnProps } from "App/Templates/TableTemplate";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX } from "constants/size";
import { useTracksDispatch, useTracksSelector } from "../Store";
import { Subhead } from "../Store/TableInfo/sanitizeHeads";
import { TrackActions } from "../Store/Track";
import { GRAY_0, GRAY_4, WHITE } from "constants/baseColor";
import { Tooltip } from "App/Atomics/Tooltip";
import { WorkTextAutoComplete } from "App/Molecules/AutoCompletes/Work";
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { EditFileModal } from "../Modals/EditFileModal";
import { TonalityOptionList } from "constants/TrackInfoOpionList";
import { Toast } from "lib/toast";
import { configs } from "configs";
import { TypeOptionInfo } from "constants/TypeOptionInfo";
import { TypeUrlOptionList } from "constants/TypeUrlOptionList";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { FileType } from "GraphQL/Scalars/FileType";
import { DeleteMetadataUrl, GetCompanyId } from "GraphQL/Queries/Metadata";
import { BookType } from "GraphQL/Scalars/BookType";
import { Loading } from "App/Atomics/Loading";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { Info as SelectInfo } from "App/Atomics/AutoComplete/TextAutoComplete";
import { GradeType } from "constants/GradeType";

const VoiceGenderOptionList = [
  { id: "Male", name: "Male" },
  { id: "Female", name: "Female" }
];

const GradeTypeOption = [
  { id: GradeType.A, name: GradeType.A },
  { id: GradeType.B, name: GradeType.B },
  { id: GradeType.C, name: GradeType.C },
  { id: GradeType.SFX, name: GradeType.SFX }
];

export const TrackItem = ({ index, data }: ColumnProps<any>) => {
  const {
    metadataId,
    title,
    no,
    typeMetadataSubclass,
    validCheck,
    titles,
    artists,
    urls,
    companies,
    extras,
    genres,
    works,
    track_information
  } = data;
  const { subhead, loading } = useTracksSelector(store => ({
    subhead: store.TableInfo.subhead,
    loading: store.Track.loading
  }));
  return (
    <ItemRow>
      {artists && <Artist index={index} artists={artists} subhead={subhead} />}
      <Metadata
        index={index}
        metadataId={metadataId}
        title={title}
        no={no}
        typeMetadataSubclass={typeMetadataSubclass}
        validCheck={validCheck}
      />
      {titles && <Title index={index} titles={titles} subhead={subhead} />}
      {urls && <Url index={index} urls={urls} metadataId={metadataId} no={no} subhead={subhead} />}
      {companies && <Company companies={companies} subhead={subhead} />}
      {extras && <Extra index={index} extras={extras} subhead={subhead} />}
      {works && <Work index={index} works={works} subhead={subhead} />}
      {genres && <Genre index={index} genres={genres} subhead={subhead} />}
      {track_information && <Info index={index} info={track_information} subhead={subhead} />}
      <Loading loading={loading} />
    </ItemRow>
  );
};

type MetadataProps = Readonly<{
  index: number;
  metadataId: any;
  title: any;
  no: any;
  typeMetadataSubclass: any;
  validCheck: any;
}>;

const customStyle = (width: number) => ({
  container: (base: any) => ({
    ...base,
    minWidth: width
  }),
  control: (base: any) => ({
    ...base,
    minHeight: 48,
    minWidth: width
  })
});

const Metadata = ({ index, metadataId, title, no, typeMetadataSubclass, validCheck }: MetadataProps) => {
  const dispatch = useTracksDispatch();

  return (
    <>
      {validCheck && (
        <Table.Data>
          <ValidTypeTextSelect
            className="validSelect"
            classNamePrefix="trackSelect"
            defaultValue={validCheck}
            onChange={validType => {
              if (validType) {
                dispatch(TrackActions.setValidCheckByIndex(index, validType));
              } else {
                // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
              }
            }}
          />
        </Table.Data>
      )}
      {metadataId && (
        <Table.Data>
          <p>{metadataId}</p>
        </Table.Data>
      )}
      <Table.Data>
        <Input.Text defaultValue={title} onBlur={value => dispatch(TrackActions.setTitleByIndex(index, value))} />
      </Table.Data>
      {no && (
        <Table.Data>
          <p>{no}</p>
        </Table.Data>
      )}
      {typeMetadataSubclass && (
        <Table.Data>
          <p>{typeMetadataSubclass}</p>
        </Table.Data>
      )}
    </>
  );
};

type TitleProps = Readonly<{ index: number; titles: any; subhead: Subhead }>;

const Title = ({ index, titles, subhead }: TitleProps) => {
  const dispatch = useTracksDispatch();
  const createTitle = (index: number) => dispatch(TrackActions.setCreateTitleByIndex(index, "title"));
  const removeTitle = (index: number, relationIndex: number) => dispatch(TrackActions.setRemoveTitleByIndex(index, relationIndex, "title"));

  if (!titles.length) {
    return (
      <Multiline>
        <div>
          <CircleButton color="primary" onClick={() => createTitle(index)}>
            +
          </CircleButton>
        </div>
      </Multiline>
    );
  }

  const decodeValue = (value: string) => {
    try {
      return window.decodeURIComponent(value);
    } catch (err) {
      console.log(err);
      return value;
    }
  };

  return (
    <>
      {subhead.Title.value && (
        <Multiline>
          {(titles as any[]).map(
            (title, titleIndex) =>
              title.relationId && (
                <div key={titleIndex}>
                  <CircleButton color="danger" onClick={() => removeTitle(index, titleIndex)}>
                    -
                  </CircleButton>
                  <LanguageTextSelect
                    className="languageSelect"
                    classNamePrefix="trackSelect"
                    defaultValue={title.language && { id: title.language[0]?.languageCode, name: title.language[0]?.name }}
                    onChange={language => {
                      if (language) {
                        dispatch(TrackActions.setTrackTitleLanguageByIndex(index, titleIndex, language.id));
                      } else {
                        // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                      }
                    }}
                  />
                  <Input.TextSelect
                    defaultValue={
                      !title.typeMetadataTitle
                        ? undefined
                        : TypeOptionInfo.find(({ id }) => id === title.typeMetadataTitle) ?? {
                            id: title.typeMetadataTitle,
                            name: title.typeMetadataTitle
                          }
                    }
                    optionList={TypeOptionInfo}
                    className="languageSelect"
                    onBlur={info => {
                      if (info) {
                        dispatch(TrackActions.setTrackTitleTypeByIndex(index, titleIndex, info.id));
                      }
                    }}
                  />
                  <TextArea
                    placeholder="설명을 입력하세요"
                    className="languageSelect"
                    defaultValue={title.value && decodeValue(title.value)}
                    onChange={e => {
                      if (e.target.value) {
                        const value = e.target.value;
                        dispatch(TrackActions.setTrackTitleValueByIndex(index, titleIndex, value));
                      }
                    }}
                  />
                </div>
              )
          )}
          <div>
            <CircleButton color="primary" onClick={() => createTitle(index)}>
              +
            </CircleButton>
          </div>
        </Multiline>
      )}
    </>
  );
};

type ArtistProps = Readonly<{ index: number; artists: any; subhead: Subhead }>;

const Artist = ({ index, artists, subhead }: ArtistProps) => {
  const dispatch = useTracksDispatch();

  const createArtist = (index: number) => dispatch(TrackActions.setCreateArtistByIndex(index, "artist"));
  const removeArtist = (index: number, relationIndex: number) =>
    dispatch(TrackActions.setRemoveArtistByIndex(index, relationIndex, "artist"));

  if (!artists.length) {
    return (
      <Multiline>
        <div>
          <CircleButton color="primary" onClick={() => createArtist(index)}>
            +
          </CircleButton>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.Artist.artist && (
        <Multiline>
          {(artists as any[]).map(
            (artist, artistIndex) =>
              artist.relationId && (
                <div key={artistIndex}>
                  <CircleButton color="danger" onClick={() => removeArtist(index, artistIndex)}>
                    -
                  </CircleButton>
                  {artist.artist && (
                    <ArtistTextAutoComplete
                      style={customStyle(400)}
                      defaultValue={artist.artist[0]}
                      onBlur={info => {
                        if (info) {
                          dispatch(TrackActions.setArtistByIndex(index, artistIndex, "artist", info));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                  {artist.role && (
                    <RoleTextAutoComplete
                      className="autocomplete"
                      classNamePrefix="trackSelect"
                      defaultValue={artist.role[0]}
                      style={customStyle(300)}
                      onBlur={info => {
                        if (info) {
                          dispatch(TrackActions.setRoleByIndex(index, artistIndex, "role", info));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                </div>
              )
          )}
          <div>
            <CircleButton color="primary" onClick={() => createArtist(index)}>
              +
            </CircleButton>
          </div>
        </Multiline>
      )}
    </>
  );
};

type UrlProps = Readonly<{ index: number; urls: any; metadataId: string; no: string; subhead: Subhead }>;

const Url = ({ index, urls, metadataId, no, subhead }: UrlProps) => {
  const dispatch = useTracksDispatch();
  const editAacFileModal = useToggle();
  const editFlacFileModal = useToggle();
  const editMp3FileModal = useToggle();
  const editWavFileModal = useToggle();
  const editZipFileModal = useToggle();
  const editTxtFileModal = useToggle();

  const createUrl = (index: number) => dispatch(TrackActions.setCreateUrlByIndex(index, "url"));
  const removeUrl = async (index: number, relationIndex: number, uuid?: string, url?: string) => {
    if (!url) {
      dispatch(TrackActions.setRemoveUrlByIndex(index, relationIndex, "url"));
      return;
    }
    if (window.confirm("음원 삭제는 즉시 삭제됩니다. 삭제하시겠습니까?")) {
      dispatch(TrackActions.setLoading(true));
      const accessId = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      try {
        const companyId = await GetCompanyId({ id: metadataId });
        if (accessId && companyId) {
          await DeleteFile({ filename: url, fileType: FileType.FILE, companyId, book: BookType.immediate });
          await DeleteMetadataUrl({ uuid });
          dispatch(TrackActions.setRemoveUrlByIndex(index, relationIndex, "url"));
          Toast.primary("삭제되었습니다.", undefined, "top-center");
        }
      } catch (err) {
        console.log(err);
        Toast.error("음원 삭제에 실패하였습니다.", undefined, "top-center");
      } finally {
        await DeleteAccessRecord({ id: accessId! });
        dispatch(TrackActions.setLoading(false));
      }
    }
  };

  if (!urls || !urls.length) {
    return (
      <Multiline>
        <div>
          <CircleButton color="primary" onClick={() => createUrl(index)}>
            +
          </CircleButton>
        </div>
      </Multiline>
    );
  }
  return (
    <>
      {subhead.Url.url && (
        <Multiline>
          {(urls as any[]).map((url, urlIndex) => {
            const key = `${url.relationId ?? ""}-${url.typeUrl ?? ""}-${url.url ?? ""}`;
            const filteredTypeUrlOptionList = TypeUrlOptionList.map(({ id, name }) => ({
              id,
              name,
              isDisabled: (urls as any[]).map(url => url.typeUrl).includes(id)
            }));
            return (
              <div key={key}>
                <>
                  <CircleButton color="danger" onClick={() => removeUrl(index, urlIndex, url.relationId, url.url)}>
                    -
                  </CircleButton>
                  {subhead.Url.typeUrl && (
                    <div style={{ width: "10rem", marginLeft: "8px" }}>
                      <Input.TextSelect
                        optionList={filteredTypeUrlOptionList}
                        defaultValue={url.typeUrl ? TypeUrlOptionList.find(({ id }) => id === url.typeUrl) : undefined}
                        onChange={info => {
                          if (info) {
                            dispatch(TrackActions.setTrackUrlTypeByIndex(index, urlIndex, info.id));
                          }
                        }}
                      />
                    </div>
                  )}

                  <FileDiv>
                    <Input.Text
                      style={{ width: pixelize(UNIT * 30), margin: "0 0.5rem" }}
                      defaultValue={url.url}
                      onBlur={value => dispatch(TrackActions.setTrackUrlByIndex(index, urlIndex, value))}
                    />
                    {url.typeUrl !== "youtube" && url.typeUrl !== "source" && (
                      <Input.Button
                        color="danger"
                        onClick={() => {
                          switch (url.typeUrl) {
                            case "mp3high":
                              editMp3FileModal.on();
                              break;
                            case "aac":
                              editAacFileModal.on();
                              break;
                            case "flac":
                              editFlacFileModal.on();
                              break;
                            case "wav":
                              editWavFileModal.on();
                              break;
                            case "zip":
                              editZipFileModal.on();
                              break;
                            case "txt":
                              editTxtFileModal.on();
                              break;
                            default:
                              Toast.warning("업로드할 수 있는 타입을 선택해주세요.", undefined, "top-center");
                              break;
                          }
                        }}
                      >
                        파일 업로드
                      </Input.Button>
                    )}
                  </FileDiv>
                  {subhead.Url.fileValidCheck && (
                    <ValidTypeTextSelect
                      className="validSelect"
                      classNamePrefix="trackSelect"
                      defaultValue={url.fileValidCheck}
                      onChange={validType => {
                        if (validType) {
                          dispatch(TrackActions.setTrackUrlValidCheckByIndex(index, urlIndex, validType));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                  {(url.typeUrl === "mp3high" || url.typeUrl === "aac" || url.typeUrl === "flac" || url.typeUrl === "wav") && (
                    <audio controls preload="none">
                      <source type={`audio/${url.typeUrl === "mp3high" ? "mpeg" : url.typeUrl}`} src={`${configs.urls.audio}/${url.url}`} />
                    </audio>
                  )}
                  {url.typeUrl === "aac" && (
                    <Modal isOpen={editAacFileModal.isToggled}>
                      <EditFileModal
                        id={url.relationId}
                        typeUrl={url.typeUrl}
                        url={url.url}
                        metadataId={metadataId}
                        toClose={editAacFileModal.off}
                      />
                    </Modal>
                  )}
                  {url.typeUrl === "flac" && (
                    <Modal isOpen={editFlacFileModal.isToggled}>
                      <EditFileModal
                        id={url.relationId}
                        typeUrl={url.typeUrl}
                        url={url.url}
                        metadataId={metadataId}
                        toClose={editFlacFileModal.off}
                      />
                    </Modal>
                  )}
                  {url.typeUrl === "mp3high" && (
                    <Modal isOpen={editMp3FileModal.isToggled}>
                      <EditFileModal
                        id={url.relationId}
                        typeUrl={url.typeUrl}
                        url={url.url}
                        metadataId={metadataId}
                        toClose={editMp3FileModal.off}
                      />
                    </Modal>
                  )}
                  {url.typeUrl === "wav" && (
                    <Modal isOpen={editWavFileModal.isToggled}>
                      <EditFileModal
                        id={url.relationId}
                        typeUrl={url.typeUrl}
                        url={url.url}
                        metadataId={metadataId}
                        toClose={editWavFileModal.off}
                      />
                    </Modal>
                  )}
                  {url.typeUrl === "zip" && (
                    <Modal isOpen={editZipFileModal.isToggled}>
                      <EditFileModal
                        id={url.relationId}
                        typeUrl={url.typeUrl}
                        url={url.url}
                        metadataId={metadataId}
                        toClose={editZipFileModal.off}
                      />
                    </Modal>
                  )}
                  {url.typeUrl === "txt" && (
                    <Modal isOpen={editTxtFileModal.isToggled}>
                      <EditFileModal
                        id={url.relationId}
                        typeUrl={url.typeUrl}
                        url={url.url}
                        metadataId={metadataId}
                        toClose={editTxtFileModal.off}
                      />
                    </Modal>
                  )}
                </>
              </div>
            );
          })}
          <div>
            <CircleButton color="primary" onClick={() => createUrl(index)}>
              +
            </CircleButton>
          </div>
        </Multiline>
      )}
    </>
  );
};

type CompanyProps = Readonly<{ companies: any; subhead: Subhead }>;

const Company = ({ companies, subhead }: CompanyProps) => {
  if (!companies || !companies.length || !companies[0].company) {
    return <Table.Data>{}</Table.Data>;
  }

  return (
    <>
      {subhead.Company.company && (
        <Multiline>
          {(companies as any[]).map((company, index) => (
            <div key={index}>
              <p>{company?.company[0]?.name || company?.company[0]?.license}</p>
            </div>
          ))}
        </Multiline>
      )}
    </>
  );
};

type ExtraProps = Readonly<{ index: number; extras: any; subhead: Subhead }>;

const Extra = ({ index, extras, subhead }: ExtraProps) => {
  const dispatch = useTracksDispatch();

  return (
    <>
      {subhead.LicenseExtra.data && (
        <Multiline>
          {(extras as any[]).map((extra, extraIndex) => (
            <div key={extraIndex}>
              {subhead.LicenseExtra.recordYear && (
                <Tooltip text="녹음연도">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 7) }}
                    defaultValue={extra.recordYear}
                    onBlur={value => dispatch(TrackActions.setTrackExtraRecordYear(index, value))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.publishYear && (
                <Tooltip text="발매년도">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 7) }}
                    defaultValue={extra.publishYear}
                    onBlur={value => dispatch(TrackActions.setTrackExtraPublishYear(index, value))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.firstEdition && (
                <Tooltip text="초판 발매일">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 7) }}
                    defaultValue={extra.firstEdition}
                    onBlur={value => dispatch(TrackActions.setTrackExtraFirstEdition(index, value))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.place && (
                <Tooltip text="장소">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 20) }}
                    defaultValue={extra.place}
                    onBlur={value => dispatch(TrackActions.setTrackExtraPlace(index, value))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.data &&
                (!extra.data ? null : (
                  <div style={{ width: extra.data ? pixelize(UNIT * 30) : "auto", padding: PADDING_X_LARGE_PX }}>{extra.data.credit}</div>
                ))}
            </div>
          ))}
        </Multiline>
      )}
    </>
  );
};

type WorkProps = Readonly<{ index: number; works: any; subhead: Subhead }>;

const Work = ({ index, works, subhead }: WorkProps) => {
  const dispatch = useTracksDispatch();

  const createWork = (index: number, column: string) => dispatch(TrackActions.setCreateWorkByIndex(index, column));
  const removeWork = (index: number, relationIndex: number, column: string) =>
    dispatch(TrackActions.setRemoveWorkByIndex(index, relationIndex, column));

  if (!works.length) {
    return (
      <Table.Data>
        {subhead.Work.work && (
          <>
            <CircleButton color="primary" onClick={() => createWork(index, "work")}>
              +
            </CircleButton>
          </>
        )}
      </Table.Data>
    );
  }
  return (
    <>
      {subhead.Work.work && (
        <Multiline>
          {(works as any[]).map((work, workIndex) => {
            return (
              work.relationId && (
                <div key={workIndex}>
                  <CircleButton color="danger" onClick={() => removeWork(index, workIndex, "work")}>
                    -
                  </CircleButton>
                  <WorkTextAutoComplete
                    className="autocomplete"
                    classNamePrefix="trackSelect"
                    defaultValue={work.work.length && { id: work.work[0].id, name: work.work[0].title }}
                    onBlur={info => {
                      if (info) {
                        dispatch(TrackActions.setTrackWorkByIndex(index, workIndex, "work", info));
                      }
                    }}
                  />
                </div>
              )
            );
          })}
          <div>
            <CircleButton color="primary" onClick={() => createWork(index, "work")}>
              +
            </CircleButton>
          </div>
        </Multiline>
      )}
    </>
  );
};

type GenreProps = Readonly<{ index: number; genres: any; subhead: Subhead }>;

const Genre = ({ index, genres, subhead }: GenreProps) => {
  const dispatch = useTracksDispatch();

  const createGenre = (index: number, column: string) => dispatch(TrackActions.setCreateGenreByIndex(index, column));
  const removeGenre = (index: number, relationIndex: number, column: string) =>
    dispatch(TrackActions.setRemoveGenreByIndex(index, relationIndex, column));
  const setGenre = (index: number, genreIndex: number, type: string, info: SelectInfo) => {
    if (genres.map(({ genre }: any) => genre[0].genreId).includes(info.id)) {
      const genreType = type === "genre" ? "장르" : "무드";
      Toast.warning(`중복된 ${genreType}가 포함됩니다. ${genreType}를 변경해주세요.`);
    }
    dispatch(TrackActions.setGenreByIndex(index, genreIndex, "genre", info));
  };
  if (!genres.length) {
    return (
      <>
        {subhead.Genre.genre && (
          <Multiline>
            <div>
              <CircleButton color="primary" onClick={() => createGenre(index, "genre")}>
                +
              </CircleButton>
            </div>
          </Multiline>
        )}
        {subhead.Genre.mood && (
          <Multiline>
            <div>
              <CircleButton color="primary" onClick={() => createGenre(index, "mood")}>
                +
              </CircleButton>
            </div>
          </Multiline>
        )}
      </>
    );
  }
  return (
    <>
      {subhead.Genre.genre && (
        <Multiline>
          {(genres as any[]).map((genre, genreIndex) => {
            const isValid = genre.genre[0].typeKind === "genre" && genre.relationId;
            return !isValid ? (
              ""
            ) : (
              <div key={genreIndex}>
                <CircleButton color="danger" onClick={() => removeGenre(index, genreIndex, "genre")}>
                  -
                </CircleButton>
                <GenreTextAutoComplete
                  className="autocomplete"
                  classNamePrefix="trackSelect"
                  defaultValue={genre.genre[0]}
                  onChange={info => {
                    if (info) {
                      setGenre(index, genreIndex, "genre", info);
                    }
                  }}
                />
              </div>
            );
          })}
          <div>
            <CircleButton color="primary" onClick={() => createGenre(index, "genre")}>
              +
            </CircleButton>
          </div>
        </Multiline>
      )}
      {subhead.Genre.mood && (
        <Multiline>
          {(genres as any[]).map((genre, genreIndex) => {
            const isValid = genre.genre[0].typeKind === "mood" && genre.relationId;
            return !isValid ? (
              ""
            ) : (
              <div key={genreIndex}>
                <CircleButton color="danger" onClick={() => removeGenre(index, genreIndex, "mood")}>
                  -
                </CircleButton>
                <MoodTextAutoComplete
                  className="autocomplete"
                  classNamePrefix="trackSelect"
                  defaultValue={genre.genre[0]}
                  onChange={info => {
                    if (info) {
                      setGenre(index, genreIndex, "mood", info);
                    } else {
                      // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                    }
                  }}
                />
              </div>
            );
          })}
          <div>
            <CircleButton color="primary" onClick={() => createGenre(index, "mood")}>
              +
            </CircleButton>
          </div>
        </Multiline>
      )}
    </>
  );
};

type TrackInfo = {
  uuid: string;
  channel?: number;
  duration?: number;
  bitrate?: number;
  tempo?: number;
  tonality?: string;
  timeSignature?: string;
  voiceGender?: string;
  extraData?: string;
  grade?: GradeType;
};

type InfoProps = Readonly<{ index: number; info: TrackInfo[]; subhead: Subhead }>;

const Info = ({ index, info, subhead }: InfoProps) => {
  const dispatch = useTracksDispatch();

  const createInfo = (index: number) => dispatch(TrackActions.setCreateInfoByIndex(index, "info"));
  const removeInfo = (index: number, relationIndex: number) => dispatch(TrackActions.setRemoveInfoByIndex(index, relationIndex, "info"));

  if (!info.length) {
    return (
      <Multiline>
        <div>
          <CircleButton color="primary" onClick={() => createInfo(index)}>
            +
          </CircleButton>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.Info.value && (
        <Multiline>
          {info.map(
            ({ uuid, channel, duration, bitrate, tempo, tonality, timeSignature, voiceGender, extraData, grade }, infoIndex) =>
              uuid && (
                <div key={`${uuid}${infoIndex}`}>
                  <CircleButton color="danger" onClick={() => removeInfo(index, infoIndex)}>
                    -
                  </CircleButton>
                  <ColGroup>
                    <RowGroup>
                      <InputTextSelect
                        placeholder="등급"
                        defaultValue={!grade ? undefined : { id: grade, name: grade }}
                        className="languageSelect"
                        optionList={GradeTypeOption}
                        onBlur={info => {
                          if (info) {
                            dispatch(TrackActions.setTrackInfoGradeByIndex(index, infoIndex, info.id as GradeType));
                          }
                        }}
                      />
                      <InputNumber
                        step={1}
                        min={0}
                        max={9999}
                        placeholder="채널"
                        defaultValue={channel && channel}
                        onBlur={value => dispatch(TrackActions.setTrackInfoChannelByIndex(index, infoIndex, value))}
                      />
                      <InputNumber
                        step={1}
                        min={0}
                        max={9999}
                        placeholder="재생시간"
                        defaultValue={duration && duration}
                        onBlur={value => dispatch(TrackActions.setTrackInfoDurationByIndex(index, infoIndex, value))}
                      />
                      <InputNumber
                        step={1}
                        min={0}
                        max={99999}
                        placeholder="비트"
                        defaultValue={bitrate && bitrate}
                        onBlur={value => dispatch(TrackActions.setTrackInfoBitrateByIndex(index, infoIndex, value))}
                      />
                      <InputNumber
                        step={0.1}
                        min={0}
                        max={99999}
                        placeholder="템포"
                        defaultValue={tempo && tempo}
                        onBlur={value => dispatch(TrackActions.setTrackInfoTempoByIndex(index, infoIndex, value))}
                      />
                    </RowGroup>
                    <RowGroup>
                      <InputTextSelect
                        placeholder="조성"
                        defaultValue={!tonality ? undefined : { id: tonality, name: tonality }}
                        className="languageSelect"
                        optionList={TonalityOptionList}
                        onBlur={info => {
                          if (info) {
                            dispatch(TrackActions.setTrackInfoTonalityByIndex(index, infoIndex, info.id));
                          }
                        }}
                      />
                      <TimeSignature timeSignature={timeSignature} index={index} infoIndex={infoIndex} />
                      <InputTextSelect
                        placeholder="Voice Gender"
                        className="languageSelect"
                        defaultValue={!voiceGender ? undefined : { id: voiceGender, name: voiceGender }}
                        optionList={VoiceGenderOptionList}
                        onBlur={info => {
                          if (info) {
                            dispatch(TrackActions.setTrackInfoVoiceGenderByIndex(index, infoIndex, info.id));
                          }
                        }}
                      />
                    </RowGroup>
                    <TextArea
                      placeholder="부가 설명을 입력하세요"
                      className="languageSelect"
                      defaultValue={extraData && extraData}
                      onChange={e => {
                        if (e.target.value) {
                          dispatch(TrackActions.setTrackInfoExtraDataByIndex(index, infoIndex, e.target.value));
                        }
                      }}
                    />
                  </ColGroup>
                </div>
              )
          )}
          {info.length >= 1 ? null : (
            <div>
              <CircleButton color="primary" onClick={() => createInfo(index)}>
                +
              </CircleButton>
            </div>
          )}
        </Multiline>
      )}
    </>
  );
};

const TimeSignatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #cdcdcd;
  border-radius: 4px;
  padding: 0.5rem;
  background: #fff;

  .time-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }

  .bar {
    width: 30px;
    height: 1px;
    background-color: #aaa;
    transform: rotate(-70deg);
  }
`;

const InputSelect = styled(Input.TextSelect)`
  width: 5rem;
  text-align: center;
`;

const TimeSignatureOptionList = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "4", name: "4" },
  { id: "8", name: "8" },
  { id: "16", name: "16" },
  { id: "32", name: "32" },
  { id: "64", name: "64" }
];

const TimeSignature = ({ timeSignature, index, infoIndex }: { timeSignature?: string; index: number; infoIndex: number }) => {
  // 3/4 박자 => signTop: 3, signBottom: 4
  let signTop: string | undefined;
  let signBottom: string | undefined;
  if (timeSignature) {
    signTop = timeSignature.split("/")[0];
    signBottom = timeSignature.split("/")[1];
  }
  const dispatch = useTracksDispatch();
  return (
    <TimeSignatureWrapper>
      <div className="time-box">
        <InputNumber
          step={1}
          min={1}
          max={64}
          style={{ width: "5rem" }}
          defaultValue={!signTop ? undefined : parseInt(signTop)}
          onChange={top => {
            const infoValue = `${top}/${!signBottom ? 1 : signBottom}`;
            dispatch(TrackActions.setTrackInfoTimeSignatureByIndex(index, infoIndex, infoValue));
          }}
        />
        <div className="bar"></div>
        <InputSelect
          placeholder=""
          optionList={TimeSignatureOptionList}
          defaultValue={!signBottom ? undefined : TimeSignatureOptionList.find(({ id }) => id === signBottom)}
          onChange={info => {
            if (info) {
              const infoValue = `${!signTop ? 1 : signTop}/${info.id}`;
              dispatch(TrackActions.setTrackInfoTimeSignatureByIndex(index, infoIndex, infoValue));
            }
          }}
        />
      </div>
      <span>박자</span>
    </TimeSignatureWrapper>
  );
};

const ItemRow = styled(Table.Row)`
  td {
    width: ${pixelize(7 * UNIT)};

    .validSelect {
      width: ${pixelize(8 * UNIT)};
      margin: 0 0.5rem;
    }

    .languageSelect,
    .autocomplete {
      width: ${pixelize(12 * UNIT)};
      margin: 0 0.5rem;
    }

    & p {
      margin: ${pixelize(0.5 * UNIT)} ${pixelize(1 * UNIT)};
    }
  }

  .trackSelect__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .trackSelect__menu {
    .trackSelect__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const Multiline = styled(Table.Data)`
  & > div {
    display: flex;
    align-items: center;

    & + div {
      margin-top: ${MARGING_LARGE_PX};
    }
  }

  input[type="date"] {
    padding: ${PADDING_X_LARGE_PX};

    &:hover {
      background-color: ${GRAY_0};
    }
  }
`;

const CircleButton = styled(Input.Button)`
  width: ${pixelize(3 * UNIT)};
  height: ${pixelize(3 * UNIT)};
  border-radius: 50%;
  transform: scale(0.75);
`;

const InputNumber = styled(Input.Number)`
  max-width: 8rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  background-color: #fff;
  margin: 0 0.25rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const InputTextSelect = styled(Input.TextSelect)`
  width: 12rem;
  padding: 0.5rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const FileDiv = styled.div`
  display: grid;
  grid-auto-flow: column;

  & > button {
    margin: 0;
  }
`;

const TextArea = styled.textarea`
  font-size: 14px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
  transition: border-color 0.15s;
  transition: height 0.8s ease-out;
  line-height: 22px;
  height: 4rem;
  &:hover {
    border-color: ${PRIMARY_COLOR_LIGHT};
  }
  &:focus {
    height: 15rem;
    border-color: ${PRIMARY_COLOR_LIGHT};
  }
`;

const ColGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowGroup = styled.div`
  display: flex;
  align-items: center;
`;
