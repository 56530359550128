import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg";
import { BLACK, GRAY_4, WHITE } from "constants/baseColor";
import {
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_X_LARGE,
  PADDING_LARGE,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
import { useAsyncEffect } from "lib/use-async-effect";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { DeleteLanguageLocale, GetLanguageLocale } from "GraphQL/Queries/Language";
import { useLanguageStore } from "../../Store";
import { LanguageActions } from "../../Store/Language";
import { DANGER_ALERT_COLOR, PRIMARY_COLOR } from "constants/color";
import { Button } from "App/Atomics/Input/Button";
import { CreateLocaleModal } from "./CreateLocaleModal";
import { UpdateLocaleModal } from "./UpdateLocaleModal";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { DeleteAccessRecord } from "GraphQL/Queries";

type Props = {
  index: number;
  code: string;
  name: string;
  onClose: () => void;
};

export const LocaleModal = ({ index, code, name, onClose }: Props) => {
  const [{ languageLocales }, dispatch] = useLanguageStore(store => store.Language);
  const [typeTitle, setTypeTitle] = useState<string | null>(null);
  const [displayCode, setDisplayCode] = useState<string | null>(null);
  const [currentUuid, setCurrentUuid] = useState<string | undefined>(undefined);
  const createLocaleModal = useToggle();
  const updateLocaleModal = useToggle();

  const setFilterReset = () => {
    setTypeTitle(null);
    setDisplayCode(null);
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        const { languageLocale } = await GetLanguageLocale({ languageCode: code, typeTitle, displayCode });
        dispatch(LanguageActions.setLanguageLocales(languageLocale));
      }
    },
    [typeTitle, displayCode]
  );

  const onDeleteLanguageLocale = async (index: number, targetCode: string, uuid: string) => {
    if (window.confirm("삭제하시겠습니까?")) {
      const accessId = await requestAccessRecord({ targetId: targetCode, targetTable: TargetTableInput.Language });
      if (accessId) {
        try {
          await DeleteLanguageLocale({ uuid });
          await DeleteAccessRecord({ id: accessId });
          dispatch(LanguageActions.deleteLanguageLocale(index));
          Toast.success("삭제되었습니다.");
        } catch (err) {
          console.log(err);
          Toast.error("삭제에 실패하였습니다.");
          return;
        }
      }
    }
  };

  return (
    <Layout>
      <Header>
        <div className="title">
          <TitleName>{name}</TitleName>
          <CancelIcon className="cancelIcon" onClick={onClose} />
        </div>
        <MenuContainer>
          <div>
            <span className="filter-title">타입 필터</span>
            <Input.TextSelect
              className="select"
              placeholder="타입을 선택해주세요."
              optionList={TitleTypeOptionInfo}
              value={!typeTitle ? null : { id: typeTitle, name: typeTitle }}
              onChange={info => {
                if (info) {
                  setTypeTitle(info.id);
                }
              }}
            />
          </div>
          <div>
            <span className="filter-title">표기 언어 필터</span>
            <LanguageTextSelect
              key={!displayCode ? "" : displayCode}
              className="select"
              placeholder="언어를 선택해주세요."
              defaultValue={LanguageCodeInfo.find(({ id }) => id === displayCode)}
              onChange={info => {
                if (info) {
                  setDisplayCode(info.id);
                }
              }}
            />
          </div>
        </MenuContainer>
        <FilterContainer>
          <FilterBox onClick={setFilterReset}>
            <ReloadIcon />
            <span>필터 초기화</span>
          </FilterBox>
        </FilterContainer>
      </Header>
      {!languageLocales.length ? (
        <NoData>
          <span>
            추가 설명이 없습니다.
            <>
              <button className="primary-underline" onClick={setFilterReset}>
                필터를 초기화
              </button>
              하거나
              <button className="danger-underline" onClick={createLocaleModal.on}>
                추가 버튼
              </button>
              <span>을 눌러 작성해주세요.</span>
            </>
          </span>
        </NoData>
      ) : (
        <Content>
          {languageLocales.map(({ uuid, typeTitle, displayCode, value }, i) => (
            <DescCard key={uuid}>
              <div className="titleContainer">
                <div>
                  <span>
                    {typeTitle} | {displayCode}
                  </span>
                </div>
              </div>
              <EplipsisMultiline
                isDisabled
                value={!value ? "내용이 없습니다." : typeTitle.includes("name") ? value : decodeURIComponent(value)}
              />
              <ButtonGroup>
                <Button className="delete-btn" onClick={() => onDeleteLanguageLocale(i, code, uuid)}>
                  삭제
                </Button>
                <Button
                  className="edit-btn"
                  onClick={() => {
                    setCurrentUuid(uuid);
                    updateLocaleModal.on();
                  }}
                >
                  수정
                </Button>
              </ButtonGroup>
              {currentUuid === uuid && (
                <Modal isOpen={updateLocaleModal.isToggled} onClose={updateLocaleModal.off}>
                  <UpdateLocaleModal
                    index={i}
                    uuid={uuid}
                    languageCode={code}
                    typeTitle={typeTitle}
                    displayCode={displayCode}
                    value={value}
                    onClose={updateLocaleModal.off}
                  />
                </Modal>
              )}
            </DescCard>
          ))}
        </Content>
      )}
      <FloatButtonContainer>
        <FloatingButton color="danger" onClick={createLocaleModal.on}>
          +
        </FloatingButton>
      </FloatButtonContainer>
      <Modal isOpen={createLocaleModal.isToggled} onClose={createLocaleModal.off}>
        <CreateLocaleModal targetCode={code} onClose={createLocaleModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 55)};
  height: ${pixelize(UNIT * 50)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  border-radius: 4px;
  font-size: 13px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  display: flex;
  flex-direction: column;

  .title {
    position: relative;
    padding: 1rem 1.5rem 0.5rem 1.5rem;
    margin-bottom: 0.5rem;

    .cancelIcon {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      fill: ${BLACK};
      width: 1.2rem;
      height: 1.2rem;
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
  .filter-title {
    font-size: 1rem;
    font-weight: bold;
  }
`;

const TitleName = styled.div`
  width: 740px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 4rem;
  font-weight: 600;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
`;
const MenuContainer = styled.div`
  width: 100%;
  padding: 0 ${pixelize(PADDING_X_LARGE * 1.4)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;

  & > div > span {
    font-weight: bold;
  }

  .select {
    width: 100%;
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: ${PADDING_X_LARGE_PX} ${pixelize(PADDING_X_LARGE * 1.4)};
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 3px;
  }

  span {
    font-weight: bold;
    color: #4c52bc;
    font-size: 1.25em;
    transform: translateY(-1px);
  }
`;

const FloatButtonContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  bottom: 15px;
  right: 15px;
`;

const FloatingButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  border-radius: 50%;
  box-shadow: 0 4px 4px ${GRAY_4};
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    .primary-underline {
      margin-left: 4px;
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      margin-left: 4px;
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${PADDING_X_LARGE_PX};
  margin: ${pixelize(MARGING_X_LARGE * 1.5)};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};

  .titleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${MARGING_SMALL_PX};
    height: ${pixelize(UNIT * 2)};
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;
    span {
      color: #757575;
      line-height: 1.43;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
const EplipsisMultiline = styled(Input.Multiline)`
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  height: ${pixelize(UNIT * 10)};
  border: none;
  padding: 0;
  color: #212121;
  line-height: 1.43;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  font-weight: bold;
  button {
    padding: ${pixelize(PADDING_LARGE * 0.8)} ${PADDING_X_LARGE_PX};
    border-radius: 4px;
    margin-right: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
  .edit-btn {
    background: #4c52bc;
    color: white;

    &:hover {
      background: #6c74dd;
    }
  }

  .delete-btn {
    background: ${WHITE};
    color: #4c52bc;
    border: 1.5px solid #4c52bc;

    &:hover {
      color: #6c74dd;
      border: 1.5px solid #6c74dd;
    }
  }
`;

const Content = styled.div``;
