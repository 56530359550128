import React, { useState } from "react";
import styled from "styled-components";
import Time from "dayjs";
import { pixelize, UNIT, PADDING_XX_LARGE_PX } from "constants/size";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAlbumStore } from "../../Store";
import { CreateAlbum } from "GraphQL/Queries/Track/CreateAlbum";
import { CreateTrack } from "GraphQL/Queries/Track/CreateTrack";
import { YtvDownload } from "GraphQL/Queries/Access/YtvDownload";
import { CreateAccessRecord, DeleteAccessRecord, CreateMultiAccessRecord, DeleteMultiAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { AnimatedProgress } from "App/Atomics/AnimatedProgress";
import { Input } from "App/Atomics/Input";
import { MetadataRightType } from "constants/MetadataRightType";
import { FileActionType } from "GraphQL/Scalars/FileActionScalar";

type Props = {
  isEffect: boolean;
  toClose: (failLog: string | undefined) => void;
};

enum LOG {
  ALBUM_ERROR = "오류: 앨범 생성에 실패하였습니다.",
  TRACK_ERROR = "오류: 트랙 생성에 실패하였습니다.",
  URL_ERROR = "오류: Youtube URL을 분석할 수 없습니다.",
  CONVERT_ERROR = "오류: 변환할 수 없는 URL입니다. 직접 등록해주세요.",
  ALBUM_UPLOAD = "앨범 업로드 중...",
  TRACK_UPLOAD = "트랙 업로드 중...",
  SERVER_UPLOAD = "서버 업로드 중...",
  SUCCESS_UPLOAD = "업로드가 완료되었습니다.",
  ERROR_CODE = "서버에서 에러가 발생하였습니다.",
  NO_VIDEO_ERROR = "오류: 해당 영상을 찾을 수 없습니다.",
  TYPE_ERROR = "오류: URL 타입이 맞지 않습니다.",
  DURATION_ERROR = "오류: 영상 길이가 10분을 넘습니다."
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${pixelize(32 * UNIT)};
  height: ${pixelize(12 * UNIT)};
  padding: ${PADDING_XX_LARGE_PX};

  button {
    display: flex;
    margin-top: auto;
    margin-left: auto;
  }
`;

export const YtvUploadModal = ({ isEffect, toClose }: Props) => {
  const [percent, setPercent] = useState<number>(0);
  const [failLog, setFailLog] = useState<LOG | undefined>(undefined);
  const [{ album, trackList }] = useAlbumStore(store => ({
    album: store.AlbumCreate.album,
    trackList: store.AlbumCreate.trackList
  }));
  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      const { no, title, type_metadata_class, type_metadata_subclass, albumCompany, ytv_Url, productions, publications } = album;
      const { trackLicense, trackLicenseExtra } = trackList[0];
      try {
        const { data: albumData, error: albumError } = await CreateAlbum({
          no,
          title,
          class: type_metadata_class,
          subClass: type_metadata_subclass,
          albumArtist: album.albumArtist,
          companyId: albumCompany.company_id,
          url: `${albumCompany.path!}/${album.no}/cover/album_cover`,
          typeKind: MetadataRightType.rightCompany,
          productions: productions?.map(({ company_id }) => ({ companyId: company_id })),
          publications: publications?.map(({ company_id }) => ({ companyId: company_id }))
        });
        if (albumError) {
          console.log(albumError.message);
          setFailLog(LOG.ALBUM_ERROR);
          return;
        }
        setPercent(33);
        if (albumData) {
          const parentId = +albumData.createAlbum.metadata_structure[0].structure_id;
          const track = trackList[0];
          try {
            const { data: trackData, error: TrackError, errors: ServerErrors } = await CreateTrack({
              no: track.no,
              title: track.title,
              class: track.type_metadata_class,
              subClass: track.type_metadata_subclass,
              trackArtist: track.trackArtist,
              companyId: albumCompany.company_id,
              genreIds: track.trackGenre ? track.trackGenre.filter(({ typeKind }) => typeKind === "genre").map(val => val.id) : [],
              moodIds: track.trackGenre ? track.trackGenre.filter(({ typeKind }) => typeKind === "mood").map(val => val.id) : [],
              workIds: track.work?.map(({ id }) => id),
              youtubeUrl: track.sourceUrl || ytv_Url!,
              mp3Url: `${albumCompany.path!}/${album.no}/mp3high/01.mp3`,
              parentId,
              notice: trackLicense.notice,
              countryCode: trackLicense.country_code,
              typeTrack: trackLicense.type_track,
              titleType: !track.trackTitle ? undefined : track.trackTitle.type_metadata_title,
              titleValue: !track.trackTitle ? undefined : track.trackTitle.value,
              publishDate: trackLicense.publish_date,
              recordYear: trackLicenseExtra ? parseInt(trackLicenseExtra.record_year, 10) : parseInt(Time().format("YYYY"), 10),
              publishYear: trackLicenseExtra ? parseInt(trackLicenseExtra.publish_year, 10) : parseInt(Time().format("YYYY"), 10),
              firstEdition: trackLicenseExtra ? parseInt(trackLicenseExtra.first_edition, 10) : parseInt(Time().format("YYYY"), 10),
              typeKind: MetadataRightType.rightCompany,
              isEffect
            });
            if (TrackError) {
              console.log(TrackError.message);
              setFailLog(LOG.TRACK_ERROR);
              throw new Error(TrackError.message);
            }
            if (ServerErrors && ServerErrors.length) {
              console.log(ServerErrors[0].message);
              setFailLog(LOG.TRACK_ERROR);
              throw new Error(ServerErrors[0].message);
            }
            setPercent(66);
            if (trackData) {
              try {
                const response = await YtvDownload({
                  url: ytv_Url!,
                  path: albumCompany.path!,
                  serial: album.no,
                  cover: "album_cover",
                  action: FileActionType.INSERT
                });
                const { error, errors } = response;

                if (errors) {
                  // * Ytv Url Error
                  const albumId = albumData.createAlbum.metadata_id;
                  const trackId = trackData.createTrack.metadata_id;
                  const { data: accessData } = await CreateMultiAccessRecord({
                    targetIdList: [albumId, trackId],
                    targetTable: TargetTableInput.Metadata
                  });
                  await DeleteMetadata({ id: trackId });
                  await DeleteMetadata({ id: albumId });

                  if (accessData) {
                    const idList = Object.values(accessData).map(({ id }) => id);
                    await DeleteMultiAccessRecord({ idList });
                  }

                  if (errors[0].message === LOG.ERROR_CODE) {
                    setFailLog(LOG.CONVERT_ERROR);
                  } else if (errors[0].message.includes("Error: No video id")) {
                    setFailLog(LOG.NO_VIDEO_ERROR);
                  } else if (errors[0].message.includes("TypeError: Video id")) {
                    setFailLog(LOG.NO_VIDEO_ERROR);
                  } else if (errors[0].message === "check vidoe duration time") {
                    setFailLog(LOG.DURATION_ERROR);
                  } else {
                    setFailLog(LOG.URL_ERROR);
                  }
                  return;
                } else if (error?.message === "ABORTED") {
                  // ![ALLOW] YtvDownload always call error from response because of aborting!
                  setPercent(100);
                } else {
                  setPercent(100);
                  return;
                }
              } catch (e) {
                console.error(e);
                // ![ALLOW] YtvDownload always call error from response because of aborting!
                setPercent(100);
                return;
              }
            }
          } catch (err) {
            // * Creating track error case
            console.error(err);
            const id = albumData.createAlbum.metadata_id;
            const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
            await DeleteMetadata({ id });
            if (accessData) {
              await DeleteAccessRecord({ id: accessData.createAccess.id });
            }
            setFailLog(LOG.TRACK_ERROR);
            return;
          }
        }
      } catch (err) {
        // * Creating album error case
        console.error(err);
        setFailLog(LOG.ALBUM_ERROR);
        return;
      }
    }
  }, []);

  return (
    <Layout>
      <h3>{`${
        failLog
          ? failLog
          : percent <= 30
          ? LOG.ALBUM_UPLOAD
          : percent <= 60
          ? LOG.TRACK_UPLOAD
          : percent <= 99
          ? LOG.SERVER_UPLOAD
          : LOG.SUCCESS_UPLOAD
      } `}</h3>
      <AnimatedProgress percent={percent} />
      <Input.Button disabled={percent !== 100 && !failLog} color={failLog ? "danger" : "success"} onClick={() => toClose(failLog)}>
        {failLog ? "돌아가기" : "닫기"}
      </Input.Button>
    </Layout>
  );
};
