import React from "react";
import styled from "styled-components";
import { MARGING_X_LARGE_PX, PADDING_LARGE_PX, PADDING_XX_LARGE_PX, PADDING_X_LARGE_PX, pixelize, UNIT } from "constants/size";
import { DEFAULT_BORDER_COLOR, MODAL_BACKGROUND, PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { getTrackList } from "./getTrackList";
import { CheckDuplicatedTypeTrackInPlaylist } from "GraphQL/Queries/Playlist/CheckDuplicatedTypeTrackInPlaylist";
import { ServiceType } from "constants/ServiceType";
import { PlaylistAutoCreateActions, Track } from "../Store/TrackList";
import { usePlaylistAutoCreateStore } from "../Store";
import { handleTrackInfoDownloadCsv } from "../util/handleTrackInfoDownloadCsv";
import uuid from "uuid";
import { handlePlaylistInfoDownloadCsv } from "../util/handlePlaylistInfoDownloadCsv";

const Layout = styled.div`
  width: ${pixelize(UNIT * 37.5)};
  height: 100vh;
  padding: ${PADDING_XX_LARGE_PX};
  overflow-y: scroll;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${PADDING_X_LARGE_PX};
  font-size: 0.9rem;

  button {
    &:hover {
      background-color: ${MODAL_BACKGROUND};
    }
  }

  .targetService__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .targetService__menu {
    .targetService__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const InfoContainer = styled.div`
  margin-top: 3rem;
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & > svg {
      position: relative;
      top: 4px;
    }
  }

  & > input {
    border: 1px solid ${DEFAULT_BORDER_COLOR};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Left = () => {
  const [{ TrackList }, dispatch] = usePlaylistAutoCreateStore(store => store);
  type Playlist = {
    track: Track[];
    name: string;
  };
  const duplicateTrack = TrackList.tracks.filter(({ duplicated }) => !!duplicated);
  const notDuplicateTrack = TrackList.tracks.filter(({ duplicated }) => !duplicated);

  const loadTrack = async () => {
    dispatch(PlaylistAutoCreateActions.updateLoadingState("Loading"));
    // 트랙 전체 가져오기
    const allTrackList = await loadAllTrackList(1000, 1);

    //플레이리스트 트랙 전체 가져오기
    const allPlaylistTrack = await loadAllPlayListInTrackIds(1000, 1);

    //등록되어있는 트랙 체크
    const newTrackList = allTrackList.map(list => {
      const isDuplicated = allPlaylistTrack.includes(list.id);
      return { ...list, duplicated: isDuplicated };
    });

    dispatch([PlaylistAutoCreateActions.updateLoadingState("None"), PlaylistAutoCreateActions.setTrack(newTrackList)]);
  };

  const loadAllTrackList = async (first: number, defaultPage: number) => {
    let page = defaultPage;
    let allTrackList = [];

    let endPage = defaultPage;
    while (page <= endPage) {
      //test
      // if (page > 2) {
      //   break;
      // }
      // const isEdge = endPage !== defaultPage;
      const result = await getTrackList({ first, page });

      if (!result?.trackList.length) {
        break;
      }
      const { trackList, edge: currentEdge } = result;
      allTrackList.push(...trackList);
      if (!!currentEdge?.pageCount) {
        endPage = currentEdge.pageCount;
      }
      page += 1;
    }

    return allTrackList;
  };

  const loadAllPlayListInTrackIds = async (first: number, defaultPage: number) => {
    let page = defaultPage;
    let allPlayList = [];

    let endPage = defaultPage;
    while (page <= endPage) {
      //test
      // if (page > 2) {
      //   break;
      // }
      // const isEdge = endPage !== defaultPage;
      const result = await CheckDuplicatedTypeTrackInPlaylist({
        first,
        page,
        kind: "앨범 발매",
        serviceType: ServiceType.CoverLala
      });

      if (!result?.data?.metadataPlaylistRelation.length) {
        break;
      }
      const { data } = result;

      const alereadyTrackList = data.metadataPlaylistRelation.map(playlistRelation => playlistRelation.metadata[0].id);
      allPlayList.push(...alereadyTrackList);

      if (!!data.edge?.pageCount) {
        endPage = data.edge.pageCount;
      }
      page += 1;
    }

    return allPlayList;
  };

  // 트랙을 무드 별로 분류하는 함수
  const classifyTracksByMood = (tracks: Track[]): Map<string, Track[]> => {
    const moodMap: Map<string, Track[]> = new Map();

    tracks.forEach(track => {
      track.mood.forEach(mood => {
        const moodTracks = moodMap.get(mood.name) || [];
        moodTracks.push(track);
        //대장르 소장르 무드 이름이 겹침
        moodMap.set(mood.name, [...new Set(moodTracks)]);
      });
    });

    return moodMap;
  };

  // 각 무드 별 트랙 개수를 계산하는 함수
  const countTracksByMood = (moodMap: Map<string, Track[]>): Map<string, number> => {
    const moodCount: Map<string, number> = new Map();

    moodMap.forEach((tracks, mood) => {
      moodCount.set(mood, tracks.length);
    });

    return moodCount;
  };

  const isTrackIdUniqueInPlaylists = (playlists: Playlist[]): { isUnique: boolean; duplicateTrackIds: string[] } => {
    const trackIdSet = new Set<string>();
    const duplicateTrackIds = [];

    for (const playlist of playlists) {
      for (const track of playlist.track) {
        if (trackIdSet.has(track.id)) {
          duplicateTrackIds.push(track.id);
        }
        trackIdSet.add(track.id);
      }
    }

    return {
      isUnique: duplicateTrackIds.length === 0,
      duplicateTrackIds
    };
  };

  const createAutoPlaylistInfoExcel = () => {
    //유효성검사
    const filteredTrack = notDuplicateTrack.filter(({ mood, genre }) => !!mood.length && !!genre.length);

    const playListSet = new Map<String, { track: Track[]; genre: { id: string; name: string } }>();

    filteredTrack.forEach(track => {
      const { genre } = track;
      const genreId = genre[0].id;
      if (playListSet.has(genreId)) {
        const appendTrack = playListSet.get(genreId)!;
        appendTrack?.track.push(track);
        playListSet.set(genreId, appendTrack);
      } else {
        playListSet.set(genreId, {
          track: [track],
          genre: genre[0]
        });
      }
    });
    const trackStatus: Map<string, boolean> = new Map();

    const playlist = Array.from(playListSet).reduce((resultPlaylist: Playlist[], current) => {
      const { genre, track } = current[1];
      // 10개 미만시 패스
      if (track.length < 10) {
        //10~20개까지
        // console.log("=== 나가리");
        // console.log(current);
        // console.log("=== 나가리");
        return resultPlaylist;
      } else if (track.length < 21) {
        // console.log("=== 10~20");
        // console.log(current);
        // console.log("=== ");
        const playlist = { track: track, name: `[${genre.name}]-${uuid()}` };
        resultPlaylist.push(playlist);
        return resultPlaylist;
      } else {
        const moodMap = classifyTracksByMood(track);

        //console.log(moodMap);

        const moodCount = countTracksByMood(moodMap);
        // console.log("=== 20이상");
        // console.log(current, moodMap, moodCount);

        let below10MoodTracks: Track[] = [];
        Array.from(moodCount.entries())
          .sort((a, b) => a[1] - b[1])
          .forEach(([mood, count]) => {
            let playlistTracks = moodMap.get(mood) || [];
            let unregisteredTracks = playlistTracks.filter(t => !trackStatus.get(t.id));

            if (count >= 10) {
              while (unregisteredTracks.length >= 10) {
                let tracksToAdd = unregisteredTracks.slice(0, 20);
                resultPlaylist.push({ track: tracksToAdd, name: `[${genre.name}]-[${mood}]-${uuid()}` });
                // console.log(tracksToAdd, `[${genre.name}]-[${mood}]-${uuid()}`);
                unregisteredTracks = unregisteredTracks.slice(20);
                tracksToAdd.forEach(t => trackStatus.set(t.id, true));
                below10MoodTracks = below10MoodTracks.filter(t => !trackStatus.get(t.id));
              }
            } else {
              unregisteredTracks.forEach(track => {
                // 아직 below10MoodTracks에 추가되지 않은 트랙만 추가합니다.
                if (!below10MoodTracks.some(existingTrack => existingTrack.id === track.id)) {
                  below10MoodTracks.push(track);
                }
              });
            }
          });

        while (below10MoodTracks.length >= 10) {
          let tracksToAdd = below10MoodTracks.slice(0, 20);
          resultPlaylist.push({ track: tracksToAdd, name: `[${genre.name}]-남은거-${uuid()}` });
          // console.log(tracksToAdd, `[${genre.name}]-남은거-${uuid()}`);
          tracksToAdd.forEach(t => trackStatus.set(t.id, true));
          below10MoodTracks = below10MoodTracks.slice(20);
        }
        // console.log("=== ");
      }

      return resultPlaylist;
    }, []);

    const { duplicateTrackIds, isUnique } = isTrackIdUniqueInPlaylists(playlist);
    console.log(duplicateTrackIds);
    if (isUnique) handlePlaylistInfoDownloadCsv(playlist);
  };

  const createSelectedTrackInfoExcel = () => {
    handleTrackInfoDownloadCsv(TrackList.tracks);
  };

  return (
    <Layout>
      <Container>
        <LabelGroup>
          <label>미등록 트랙 검색하기</label>
        </LabelGroup>
        {TrackList.state === "None" && (
          <Input.Button color="primary" onClick={loadTrack}>
            검색하기!
          </Input.Button>
        )}

        {TrackList.state === "Loading" && (
          <Input.Button color="default" disabled>
            진행중
          </Input.Button>
        )}

        {!!TrackList.tracks.length && (
          <InfoContainer>
            <LabelGroup>
              <p>트랙 총 개수: {TrackList.tracks.length}</p>
              <p>미등록 트랙 개수: {notDuplicateTrack.length}</p>
              <p>등록된 트랙 개수: {duplicateTrack.length}</p>
            </LabelGroup>
            <ButtonGroup>
              <Input.Button color="info" onClick={createSelectedTrackInfoExcel}>
                트랙 정보 다운로드
              </Input.Button>
              <Input.Button color="primary" onClick={createAutoPlaylistInfoExcel}>
                플레이리스트 엑셀 다운로드
              </Input.Button>
            </ButtonGroup>
          </InfoContainer>
        )}
      </Container>
    </Layout>
  );
};
