/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
  memo: string | null;
  validCheck?: string;
}>;

export const UpdateArtist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  // const $memo = <Variable name="memo" scalar={<StringScalar isNullable />} value={option.memo} />;
  const $validCheck = <Variable name="validCheck" scalar={<ValidTypeScalar />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST">
      <SelectionSet
        name="updateArtist"
        argument={{
          where: {
            artist_id: $id
          },
          data: {
            memo: option.memo,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateArtist: {
      id: string;
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
