/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  roleId: string;
  typeFunction: string;
}>;

export const UpdateRoleElementRelation = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.uuid} />;
  const $roleId = <Variable name="role_id" scalar={<IdScalar />} value={option.roleId} />;
  const $typeFunction = <Variable name="type_role_class" scalar={<StringScalar />} value={option.typeFunction} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ROLE_ELEMNT_RELATION">
      <SelectionSet
        name="updateRole_self_relations_element_role"
        alias="updateRoleSelfRelationsElementRole"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            typeFunction: $typeFunction,
            role_self_relation_element_role: {
              connect: {
                role_id: $roleId
              }
            }
          }
        }}
      >
        <Field name="role_id" alias="id" />
        <SelectionSet name="role_self_relation_element_role" alias="elementRelation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateRoleSelfRelationsElementRole: {
      id: string;
      elementRelation: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.role.request<Data>(query, variables);
};
