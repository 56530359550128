/** @jsx jsx */

import { GoodsUrlOrderByInput } from "constants/GoodsUrlOrderByInput";
import { jsx, EnumScalar } from "graphql-jsx";

type Props = Readonly<{
  value?: GoodsUrlOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  GoodsUrlOrderByInput.goods_id__ASC,
  GoodsUrlOrderByInput.goods_id__DESC,
  GoodsUrlOrderByInput.exposure_order__ASC,
  GoodsUrlOrderByInput.exposure_order__DESC
];

export const GoodsUrlOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="Goods_urlOrderByInput" value={value} isNullable={isNullable} items={items} />
);
