import React, { useState } from "react";
import styled from "styled-components";

import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { Input } from "App/Atomics/Input";
import { ArtistTagAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { CompanyTagAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { AllGenreTagAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { MoodTagAutoComplete } from "App/Molecules/AutoCompletes/Mood";
import { ValidTypeTagSelect } from "App/Molecules/Selects/ValidType";
import { ReactComponent as Down } from "assets/icons/down.svg";
import { ReactComponent as Search } from "assets/icons/search.svg";
import { WHITE } from "constants/baseColor";
import { DANGER_COLOR_LIGHT, DEFAULT_BORDER_COLOR, PRIMARY_COLOR } from "constants/color";
import { License } from "constants/License";
import {
  MARGING_X_LARGE_PX,
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  UNIT_PX,
  BORDER_RADIUS_PX,
  MARGING_XX_LARGE_PX,
  PADDING_XX_LARGE_PX
} from "constants/size";
import { ValidType } from "constants/ValidType";
import { usePlaylistCreateStore } from "../../../Store";
import { PlaylistCreateActions } from "../../../Store/TrackList";
import { getTrackList } from "./getTrackList";
import { useTrackSearchForm } from "./useTrackSearchForm";
import { CheckDuplicatedTrackInPlaylist } from "GraphQL/Queries/Playlist";
import { Toast } from "lib/toast";
import { GetGenreIncludeChild, GetMoodIncludeParent } from "GraphQL/Queries/Genre";

const Layout = styled.div`
  .pagination {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: ${MARGING_X_LARGE_PX};
    padding: ${PADDING_X_LARGE_PX} ${PADDING_XX_LARGE_PX};
    background-color: ${WHITE};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    & > div + div {
      margin-top: ${MARGING_X_LARGE_PX};
    }

    input {
      width: ${pixelize(UNIT * 7)};
      text-align: left;
      border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
      border-radius: 0;
    }
  }

  .detailOption {
    padding: 0;
    margin-bottom: ${MARGING_XX_LARGE_PX};
    color: #888888;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SearchButton = styled.a`
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: ${MARGING_XX_LARGE_PX};
  color: ${PRIMARY_COLOR};

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }

  & svg {
    position: relative;
    top: 4px;
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};

    & > g {
      stroke: ${PRIMARY_COLOR};
    }
  }
`;

const Carrot = styled(Down)<{ isOpened: boolean }>`
  position: relative;
  top: 4px;
  width: ${UNIT_PX};
  height: ${UNIT_PX};
  fill: #888888;
  transition: all 0.5s;
  transform: ${props => (props.isOpened ? "rotate(180deg)" : "rotate(360deg)")};
`;

const ToggleGroup = styled(Input.Group)`
  & > button {
    border-radius: 0;

    & + button {
      border-left: 1px solid ${WHITE};
    }

    &:first-child {
      border-top-left-radius: ${BORDER_RADIUS_PX};
      border-bottom-left-radius: ${BORDER_RADIUS_PX};
    }

    &:last-child {
      border-top-right-radius: ${BORDER_RADIUS_PX};
      border-bottom-right-radius: ${BORDER_RADIUS_PX};
    }
  }
`;

const RowGroup = styled.div`
  margin-bottom: ${MARGING_X_LARGE_PX};

  label {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 2px;
    .tip {
      margin-left: 6px;
      font-size: 0.7rem;
      color: ${DANGER_COLOR_LIGHT};
    }
  }
`;

type OptionList = Parameters<typeof Input.TextSelect>[0]["optionList"];

type DetailOptionProps = Readonly<{
  validTypeList: ReturnType<typeof useTrackSearchForm>[0]["validTypeList"];
  licenses: ReturnType<typeof useTrackSearchForm>[0]["licenses"];
  dispatch: ReturnType<typeof useTrackSearchForm>[1];
}>;

const DetailOption = ({ validTypeList, licenses, dispatch }: DetailOptionProps) => {
  const toggleLicense = (license: License) => () => dispatch({ type: "toggleLicense", license });
  const setCompanyList = (kind: "include" | "exclude") => (companyList: readonly Info[]) =>
    dispatch({ type: "setCompanyList", companyList, kind });
  const setArtistList = (kind: "include" | "exclude") => (artistList: readonly Info[]) =>
    dispatch({ type: "setArtistList", artistList, kind });
  const setGenreList = (kind: "include" | "exclude") => (genreList: readonly Info[]) => dispatch({ type: "setGenreList", genreList, kind });
  const setMoodList = (kind: "include" | "exclude") => (moodList: readonly Info[]) => dispatch({ type: "setMoodList", moodList, kind });
  const setValidTypeList = (validTypeList: ValidType[]) => dispatch({ type: "setValidTypeList", validTypeList });

  return (
    <>
      <RowGroup>
        <label>라이센스</label>

        <ToggleGroup>
          <Input.Toggle
            color="primary"
            isWide={true}
            isActive={licenses.has(License.PublicDomain)}
            toggleValue={License.PublicDomain}
            onClick={toggleLicense(License.PublicDomain)}
          />
          <Input.Toggle
            color="primary"
            isWide={true}
            isActive={licenses.has(License.CCL)}
            toggleValue={License.CCL}
            onClick={toggleLicense(License.CCL)}
          />
          <Input.Toggle
            color="primary"
            isWide={true}
            isActive={licenses.has(License.NoCopyright)}
            toggleValue={License.NoCopyright}
            onClick={toggleLicense(License.NoCopyright)}
          />
          <Input.Toggle
            color="primary"
            isWide={true}
            isActive={licenses.has(License.VL)}
            toggleValue={License.VL}
            onClick={toggleLicense(License.VL)}
          />
        </ToggleGroup>
      </RowGroup>

      <RowGroup>
        <label>포함할 유통사</label>
        <CompanyTagAutoComplete onBlur={setCompanyList("include")} />
      </RowGroup>

      <RowGroup>
        <label>제외할 유통사</label>
        <CompanyTagAutoComplete onBlur={setCompanyList("exclude")} />
      </RowGroup>

      <RowGroup>
        <label>포함할 아티스트</label>
        <ArtistTagAutoComplete onBlur={setArtistList("include")} />
      </RowGroup>

      <RowGroup>
        <label>제외할 아티스트</label>
        <ArtistTagAutoComplete onBlur={setArtistList("exclude")} />
      </RowGroup>

      <RowGroup>
        <label>
          포함할 장르<span className="tip">✭은 하위 장르를 포함합니다.</span>
        </label>
        <AllGenreTagAutoComplete
          onBlur={async info => {
            if (!info || !info.length) {
              setGenreList("include")([]);
              return;
            }
            const ids = info.map(({ id }) => id);
            const genreInfo = await GetGenreIncludeChild({ genreList: ids });
            setGenreList("include")(genreInfo);
          }}
        />
      </RowGroup>

      <RowGroup>
        <label>
          제외할 장르<span className="tip">✭은 하위 장르를 포함합니다.</span>
        </label>
        <AllGenreTagAutoComplete
          onBlur={async info => {
            if (!info || !info.length) {
              setGenreList("exclude")([]);
              return;
            }
            const ids = info.map(({ id }) => id);
            const genreInfo = await GetGenreIncludeChild({ genreList: ids });
            setGenreList("exclude")(genreInfo);
          }}
        />
      </RowGroup>

      <RowGroup>
        <label>포함할 무드</label>
        <MoodTagAutoComplete
          onBlur={async info => {
            if (!info || !info.length) {
              setMoodList("include")([]);
              return;
            }
            const ids = info.map(({ id }) => id);
            const moodInfo = await GetMoodIncludeParent({ moodList: ids });
            setMoodList("include")(moodInfo);
          }}
        />
      </RowGroup>

      <RowGroup>
        <label>제외할 무드</label>
        <MoodTagAutoComplete
          onBlur={async info => {
            console.log(info);
            if (!info || !info.length) {
              setMoodList("exclude")([]);
              return;
            }
            const ids = info.map(({ id }) => id);
            const moodInfo = await GetMoodIncludeParent({ moodList: ids });
            setMoodList("exclude")(moodInfo);
          }}
        />
      </RowGroup>

      <RowGroup>
        <label>Valid Check</label>
        <ValidTypeTagSelect defaultValue={validTypeList} onBlur={setValidTypeList} />
      </RowGroup>
    </>
  );
};

export const DetailSearchForm = () => {
  const [
    {
      licenses,
      kindList,
      includeCompanyList,
      excludeCompanyList,
      includeArtistList,
      excludeArtistList,
      includeGenreList,
      excludeGenreList,
      includeMoodList,
      excludeMoodList,
      validTypeList,
      skip,
      count
    },
    dispatch
  ] = useTrackSearchForm();
  const [{ serviceType, kind }, dispatchTraskGrant] = usePlaylistCreateStore(store => store.TrackList);
  const [isOpened, setBeOpened] = useState(true);
  const loadTrackList = async () => {
    if (
      !licenses.size &&
      !kindList.length &&
      !includeCompanyList.length &&
      !includeGenreList.length &&
      !includeMoodList.length &&
      !validTypeList.length
    ) {
      window.alert("라이센스, 유통사, 아티스트, 장르, 무드, valid check 중 1개 이상을 포함하도록 선택해야 합니다");
      return;
    }

    dispatchTraskGrant(PlaylistCreateActions.updateLoadingState("Loading"));
    const trackList = await getTrackList({
      first: count,
      skip,
      validTypeList,
      licenseList: Array.from(licenses),
      includeCompanyList: includeCompanyList.map(({ id }) => id),
      includeArtistList: includeArtistList.map(({ id }) => id),
      includeGenreList: includeGenreList.map(({ id }) => id),
      includeMoodList: includeMoodList.map(({ id }) => id),
      excludeCompanyList: excludeCompanyList.map(({ id }) => id),
      excludeArtistList: excludeArtistList.map(({ id }) => id),
      excludeGenreList: excludeGenreList.map(({ id }) => id),
      excludeMoodList: excludeMoodList.map(({ id }) => id)
    });
    if (trackList) {
      if (serviceType && kind?.length) {
        const metadataIdIn = trackList.map(({ id }) => id);
        const { data, errors } = await CheckDuplicatedTrackInPlaylist({ serviceType, kind, metadataIdIn });
        if (errors || !data) {
          Toast.error("중복 검사에 실패하였습니다", undefined, "top-center");
          return;
        }
        if (data) {
          const duplicateList = data.metadataPlaylistRelation.map(({ metadata }) => metadata[0].id);
          const newTrackList = trackList.map(list => {
            const isDuplicated = duplicateList.includes(list.id);
            return { ...list, duplicated: isDuplicated };
          });
          dispatchTraskGrant([
            PlaylistCreateActions.updateLoadingState("None"),
            PlaylistCreateActions.set({ kind: "searched", tracks: newTrackList })
          ]);
        }
      } else {
        const newTrackList = trackList.map(list => ({ ...list, duplicated: false }));
        dispatchTraskGrant([
          PlaylistCreateActions.updateLoadingState("None"),
          PlaylistCreateActions.set({ kind: "searched", tracks: newTrackList })
        ]);
      }
    }
  };

  const setSkip = (skip: number) => dispatch({ type: "setSkip", skip });
  const setCount = (count: number) => dispatch({ type: "setCount", count });

  const toggleDetailOption = () => setBeOpened(isOpened => !isOpened);

  return (
    <Layout>
      <div className="pagination">
        <div>
          <label>아래의 조건으로 </label>
        </div>
        <div>
          <Input.Number value={count} min={1} max={1000} step={1} onBlur={setCount} />
          <label>개를 </label>
          <Input.Number value={skip} min={0} max={100_0000} step={1} onBlur={setSkip} />
          <label>번째 이후부터</label>
          <SearchButton role="button" onClick={loadTrackList}>
            검색
            <Search />
          </SearchButton>
        </div>
      </div>

      <Input.Button className="detailOption" onClick={toggleDetailOption}>
        {isOpened ? "디테일 옵션 닫기" : "디테일 옵션 보기"} <Carrot isOpened={isOpened} />
      </Input.Button>

      {isOpened && <DetailOption validTypeList={validTypeList} licenses={licenses} dispatch={dispatch} />}
    </Layout>
  );
};
