import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as CheckboxIcon } from "assets/icons/checkbox.svg";
import { ReactComponent as CheckboxFillIcon } from "assets/icons/checkbox-fill.svg";

const Layout = styled.div`
  border-bottom: 1px solid #ebebeb;
`;
const Title = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 30px);
`;

const MainTitle = styled.span`
  position: relative;
  font-size: 13px;
  letter-spacing: -0.07px;
  font-weight: 600;
  cursor: pointer;
`;
const Placeholder = styled.span`
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  letter-spacing: -0.15px;
  color: rgba(34, 34, 34, 0.5);
`;

const IconBox = styled.div`
  height: 20px;
  svg {
    width: 12px;
    width: 12px;
    fill: #ccc;
  }
`;

const TitleMenu = styled.div`
  padding-bottom: 24px;
  display: none;
  &[data-visible="true"] {
    display: block;
  }
`;
const MenuList = styled.ul`
  max-height: 315px;
  overflow-y: auto;
  list-style: none;
  display: block;
`;
const MenuItem = styled.li`
  display: flex;
  cursor: pointer;

  & + & {
    margin-top: 5px;
  }
`;

const MenuText = styled.span`
  margin-left: 4px;
  max-width: 160px;
  /* font-weight: bold; */
`;

export const FilterStyle = {
  Layout,
  Title,
  TitleBox,
  MainTitle,
  Placeholder,
  IconBox,
  TitleMenu,
  MenuList,
  MenuItem,
  MenuText,
  PlusIcon,
  CheckboxIcon,
  CheckboxFillIcon
};
