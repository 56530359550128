import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_2, BLACK, GRAY_4 } from "constants/baseColor";
import { PRIMARY_COLOR, DANGER_ALERT_COLOR } from "constants/color";
import {
  pixelize,
  UNIT,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_LARGE_PX,
  PADDING_X_LARGE_PX,
  MARGING_X_LARGE,
  MARGING_SMALL_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { useAsyncEffect } from "lib/use-async-effect";
import {
  CreatePlaylistTitleRelation,
  DeletePlaylistTitleRelation,
  GetPlaylistTitleRelation,
  UpdatePlaylistTitleRelation
} from "GraphQL/Queries/Playlist";
import { usePlaylistsStore } from "App/Routes/Playlists/Store";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
import { useAppSelector } from "App/Store";
import { useToggle } from "lib/use-toggle";
import { Loading } from "App/Atomics/Loading";
import { UserRole } from "constants/UserRole";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Modal } from "lib/modal";
import { CreateTitleModal } from "./CreateTitleModal";
import _ from "lodash";
import { UpdateTitleModal } from "./UpdateTitleModal";

type Props = {
  id: string;
  toClose: () => void;
};

export const TitleModal = ({ id, toClose }: Props) => {
  const [{ titleRelation, editInfo }, dispatch] = usePlaylistsStore(store => store.Playlist);
  const [loading, setLoading] = useState<boolean>(false);
  const [typeTitle, setTypeTitle] = useState<string | null>(null);
  const [languageCode, setLanguageCode] = useState<string | null>(null);
  const userRole = useAppSelector(store => store.UserToken.role);
  const createTitleModal = useToggle();
  const updateTitleModal = useToggle();

  const onCreate = async (typeTitle: string, value: string, languageCode: string) => {
    setLoading(true);
    const order = !titleRelation || !titleRelation.length ? 0 : (_.first(titleRelation)?.order ?? 0) + 1;
    try {
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      if (accessId) {
        const { data } = await CreatePlaylistTitleRelation({ playlistId: id, typeTitle, value, languageCode, order });
        if (data) {
          dispatch(
            PlaylistsActions.createPlaylistTitleRelation({
              id: data.createPlaylistTitleRelation.playlist_title[0].uuid,
              typeTitle,
              value,
              languageCode,
              order: order
            })
          );
          Toast.primary("추가되었습니다.", undefined, "bottom-right");
        }
        await DeleteAccessRecord({ id: accessId });
        createTitleModal.off();
      }
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async (uuid: string, typeTitle: string, value: string, languageCode: string) => {
    setLoading(true);
    try {
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      if (accessId) {
        const { data } = await UpdatePlaylistTitleRelation({ id: uuid, typeTitle, value, languageCode });
        if (data) {
          dispatch(PlaylistsActions.updatePlaylistTitleRelation({ index: editInfo.index, id: uuid, typeTitle, value, languageCode }));
          Toast.primary("수정되었습니다.", undefined, "bottom-right");
        }
        await DeleteAccessRecord({ id: accessId });
        updateTitleModal.off();
      }
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setLoading(false);
    }
  };

  const openUpdateInputModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, index: number) => {
    e.preventDefault();
    dispatch(PlaylistsActions.setPlaylistTitleRelationEditInfo({ uuid: id, index }));
    updateTitleModal.on();
  };

  const onDeleteTitle = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, uuid: string, index: number) => {
      e.preventDefault();
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        try {
          const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
          if (accessId) {
            const { data } = await DeletePlaylistTitleRelation({ uuid });
            if (data) {
              dispatch(PlaylistsActions.deletePlaylistTitleRelation(index));
              Toast.primary("삭제되었습니다.", undefined, "top-center");
            }
            await DeleteAccessRecord({ id: accessId });
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
    },
    [dispatch, id]
  );

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const { data } = await GetPlaylistTitleRelation({ id, typeTitle, languageCode });
      if (data) {
        dispatch(PlaylistsActions.setPlaylistTitleRelation(data.playlist[0]?.titleRelation ?? []));
      }
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setLoading(false);
    }
  }, [typeTitle, languageCode]);

  const setFilterReset = () => {
    setTypeTitle(null);
    setLanguageCode(null);
  };

  return (
    <Layout>
      <Header>
        <div className="title">
          <span>플레이리스트 설명</span>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
        <MenuContainer>
          <div>
            <span>타입 필터</span>
            <Input.TextSelect
              className="select"
              optionList={TitleTypeOptionInfo}
              value={!typeTitle ? null : { id: typeTitle, name: typeTitle }}
              onChange={info => {
                if (info) {
                  setTypeTitle(info.id);
                }
              }}
            />
          </div>
          <div>
            <span>언어 필터</span>
            <LanguageTextSelect
              key={!languageCode ? "" : languageCode}
              className="select"
              defaultValue={LanguageCodeInfo.find(({ id }) => id === languageCode)}
              onChange={info => {
                if (info) {
                  setLanguageCode(info.id);
                }
              }}
            />
          </div>
        </MenuContainer>
        <FilterContainer>
          <FilterBox onClick={setFilterReset}>
            <RefreshIcon />
            <span>필터 초기화</span>
          </FilterBox>
        </FilterContainer>
      </Header>
      {!titleRelation.length ? (
        <NoData>
          <span>
            추가 설명이 없습니다.
            {userRole === UserRole.Master || userRole === UserRole.Arbeit ? (
              <>
                <button className="primary-underline" onClick={setFilterReset}>
                  필터를 초기화
                </button>
                하거나
                <button className="danger-underline" onClick={createTitleModal.on}>
                  추가 버튼
                </button>
                <span>을 눌러 작성해주세요.</span>
              </>
            ) : (
              ""
            )}
          </span>
        </NoData>
      ) : (
        titleRelation.map((title, i) => (
          <DescCard key={i}>
            <div className="titleContainer">
              <div>
                <span>
                  {title.typeTitle} | {title.language.length && title.language[0].name}
                </span>
              </div>
            </div>
            <EplipsisMultiline
              isDisabled
              value={!title.value ? "내용이 없습니다." : title.typeTitle.includes("name") ? title.value : decodeURIComponent(title.value)}
            />
            {(userRole === UserRole.Master || userRole === UserRole.Arbeit) && (
              <ButtonGroup>
                <button className="delete-btn" onClick={e => onDeleteTitle(e, title.id, i)}>
                  삭제
                </button>
                <button className="edit-btn" onClick={e => openUpdateInputModal(e, title.id, i)}>
                  수정
                </button>
              </ButtonGroup>
            )}
          </DescCard>
        ))
      )}
      <MarginDiv>{"0"}</MarginDiv>
      {(userRole === UserRole.Master || userRole === UserRole.Arbeit) && (
        <FloatButtonContainer>
          <FloatingButton color="danger" onClick={createTitleModal.on}>
            +
          </FloatingButton>
        </FloatButtonContainer>
      )}
      <Modal isOpen={createTitleModal.isToggled} onClose={createTitleModal.off}>
        <CreateTitleModal onCreate={onCreate} toClose={createTitleModal.off} />
      </Modal>
      <Modal isOpen={updateTitleModal.isToggled} onClose={updateTitleModal.off}>
        <UpdateTitleModal loading={loading} onUpdate={onUpdate} toClose={updateTitleModal.off} />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 42)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  min-height: ${pixelize(UNIT * 12.5)};
  display: flex;
  flex-direction: column;

  span {
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
    font-weight: bold;
  }
  .title {
    padding: ${pixelize(PADDING_X_LARGE * 1.4)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35%;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  padding: 0 ${pixelize(PADDING_X_LARGE * 1.4)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;

  & > div > span {
    font-size: 14px;
    font-weight: bold;
  }

  .select {
    width: 100%;
    font-size: 12px;
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: ${PADDING_X_LARGE_PX} ${pixelize(PADDING_X_LARGE * 1.4)};
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    transform: translateY(-1px);
  }
  span {
    font-size: 14px;
    font-weight: bold;
    color: #4c52bc;
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${PADDING_X_LARGE_PX};
  margin: ${pixelize(MARGING_X_LARGE * 1.5)};
  width: ${pixelize(UNIT * 32)};
  height: ${pixelize(UNIT * 16.25)};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};

  .titleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${MARGING_SMALL_PX};
    height: ${pixelize(UNIT * 2)};
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;
    span {
      color: #757575;
      line-height: 1.43;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const EplipsisMultiline = styled(Input.Multiline)`
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  height: ${pixelize(UNIT * 10)};
  border: none;
  padding: 0;
  color: #212121;
  line-height: 1.43;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

const FloatButtonContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;
const FloatingButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  border-radius: 50%;
  box-shadow: 0 4px 4px ${GRAY_4};
  margin: ${MARGING_LARGE_PX} ${MARGING_XX_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  font-weight: bold;
  button {
    padding: ${pixelize(PADDING_LARGE * 0.8)} ${PADDING_X_LARGE_PX};
    border-radius: 4px;
    margin-right: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
  .edit-btn {
    background: #4c52bc;
    color: white;

    &:hover {
      background: #6c74dd;
    }
  }

  .delete-btn {
    background: ${WHITE};
    color: #4c52bc;
    border: 1.5px solid #4c52bc;

    &:hover {
      color: #6c74dd;
      border: 1.5px solid #6c74dd;
    }
  }
`;
const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    .primary-underline {
      margin-left: 4px;
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      margin-left: 4px;
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
