import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { BLUE_6, GRAY_6 } from "constants/baseColor";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreateComment } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { ParsedComment, TrackRecord } from "App/Routes/Playlists/RightPlaylistTable/Modals/ExtraInfoModal";
import { Loading } from "App/Atomics/Loading";
import { AddedTrackInfo } from "../PlaylistModal";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetTrackInfo } from "GraphQL/Queries/Metadata";
import uuidv4 from "uuid/v4";
import dayjs from "dayjs";

type Props = {
  addedTrackInfo: AddedTrackInfo;
  uuid?: string;
  toClose: () => void;
};

export const WriteCommentModal = ({ addedTrackInfo, uuid, toClose }: Props) => {
  const [comment, setComment] = useState<ParsedComment>({} as ParsedComment);
  const [loading, setLoading] = useState<boolean>(false);

  const onSave = async () => {
    if (!comment) {
      Toast.error("댓글 내용을 작성해주세요.", undefined, "top-center");
      return;
    }
    try {
      setLoading(true);
      const { data, errors } = await CreateComment({
        targetId: addedTrackInfo.playlistId!,
        targetTable: TargetTableInput.Playlist,
        value: JSON.stringify(comment),
        parentId: uuid
      });
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (data) {
        setLoading(false);
        Toast.primary("저장되었습니다.", undefined, "top-center");
        toClose();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      Toast.error("오류가 발생하였습니다.", undefined, "top-center");
      return;
    }
  };

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      const trackInfo = await GetTrackInfo({ trackIdList: addedTrackInfo.trackList });
      const trackRecord: TrackRecord[] = trackInfo.map(({ id, title, artist }) => {
        const uuid = uuidv4();
        const operate = "INSERT" as const;
        const timestamp = dayjs(new Date()).toISOString();
        return { id: uuid, metadata_id: id, title, artist, operate, timestamp };
      });
      const text = trackInfo
        .map(({ id, title }) => {
          return `[${id}]${title} 곡을 추가하였습니다.`;
        })
        .join("\n");
      setComment({ data: trackRecord, text });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Layout>
      <Header>
        <h3>댓글 추가</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <span>내용</span>
        <textarea
          className="input-remark"
          placeholder={"설명을 입력해주세요"}
          defaultValue={comment.text}
          onChange={e => {
            const text = e.target.value;
            setComment(prev => ({ ...prev, text }));
          }}
        />
      </Section>
      <ButtonGroup>
        <Input.Button onClick={toClose}>취소</Input.Button>
        <Input.Button color="primary" onClick={onSave}>
          저장
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 800px;
  height: 600px;
  font-size: 0.8rem;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    color: #191919;
    margin-left: 0.25rem;
    margin-bottom: 0.3rem;
  }

  .input-price {
    border: 1px solid hsl(210 6% 83%);
    padding: 0.6rem;
    transition: border-color 0.15s;
    margin-bottom: 0.5rem;
    &:hover {
      border-color: hsl(210 6% 70%);
    }
    &:focus {
      border-color: ${BLUE_6};
      box-shadow: 0px 0px 0px 1.2px ${BLUE_6};
    }
  }

  .input-remark {
    border: 1px solid hsl(210 6% 83%);
    padding: 0.6rem;
    border-radius: 5px;
    height: 25rem;
    transition: border-color 0.15s;
    &:hover {
      border-color: hsl(210 6% 70%);
    }
    &:focus {
      border-color: ${BLUE_6};
      box-shadow: 0px 0px 0px 1.2px ${BLUE_6};
    }
  }
`;

const ButtonGroup = styled.div`
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 1rem 0;
`;
