/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  birthDate?: string;
  deathDate?: string;
  birthPlace?: string;
  deathPlace?: string;
}>;

export const UpdateArtistHistory = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $birthDate = <Variable name="birth_date" scalar={<StringScalar isNullable />} value={option.birthDate} />;
  const $deathDate = <Variable name="death_date" scalar={<StringScalar isNullable />} value={option.deathDate} />;
  const $birthPlace = <Variable name="birth_place" scalar={<StringScalar isNullable />} value={option.birthPlace} />;
  const $deathPlace = <Variable name="death_place" scalar={<StringScalar isNullable />} value={option.deathPlace} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST_HISTORY">
      <SelectionSet
        name="updateArtist_history"
        alias="updateArtistHistory"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            birth_date: $birthDate,
            birth_place: $birthPlace,
            death_date: $deathDate,
            death_place: $deathPlace
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateArtistHistory: Readonly<{
      artist_id: string;
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
