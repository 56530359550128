import { Helmet } from "App/Atomics/Helmet";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import React from "react";
import styled from "styled-components";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { GoodsForm } from "./GoodsForm";

const GoodsCreateLayout = () => {
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  return (
    <Layout>
      <LeftSideBar />
      <TopbarTemplate openSidebar={openSidebar}>{}</TopbarTemplate>
      <GoodsForm />
    </Layout>
  );
};

export const GoodsCreate = () => (
  <>
    <Helmet title="굿즈 등록" />
    <GoodsCreateLayout />
  </>
);

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;
