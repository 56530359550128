export const initializeKeyValue = <T extends Record<string, any>>(option: Record<string, any>, schema: T) => {
  const initializer = (fields: Record<string, any>, [key, value]: [string, any]): any => {
    if (Array.isArray(value)) {
      if (!value.length) {
        fields[key] = [];
      } else {
        const baseType = typeof value[0];
        const fieldOption = option[key] as any[];
        if (!Array.isArray(fieldOption)) {
          fields[key] = value;
        } else if (baseType === "object") {
          if (value.length >= 2) {
            throw new Error("array는 1번째에만 기본값을 지정할 수 있습니다");
          }
          fields[key] = fieldOption.map(childOption => initializeKeyValue(childOption, value[0]));
        } else {
          if (value.some(schema => typeof schema === "object")) {
            throw new Error("array는 1번째에만 기본값을 지정할 수 있습니다");
          }
          fields[key] = fieldOption.map(childOption => (baseType === typeof childOption ? childOption : schema));
        }
      }
    } else if (typeof value === "object") {
      fields[key] = option.hasOwnProperty(key) ? initializeKeyValue(option[key], value) : initializeKeyValue({}, value);
    } else {
      fields[key] = option.hasOwnProperty(key) && typeof option[key] === typeof value ? option[key] : value;
    }
    return fields;
  };
  return Object.entries(schema).reduce(initializer, {}) as T;
};
