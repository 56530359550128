/* eslint-disable @typescript-eslint/no-unused-vars */
import { createActions, createHandler, createReducer } from "lib/store";
import itiriri from "itiriri";
import Time from "dayjs";
export enum Page {
  FIRST,
  SECOND,
  THRID
}
type State = ReturnType<typeof createInitialState>;

type HeaderOptionList = {
  id: string;
  name: string;
  isDisabled?: boolean;
}[];

type Title = {
  type_metadata_title: string;
  value: string;
};

type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  order?: number;
};

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

type Company = {
  company_id: string;
  name: string;
  license: string;
  type: string;
  path?: string;
};

type Production = {
  company_id: string;
  name: string;
};

type Publication = {
  company_id: string;
  name: string;
};

type Genre = {
  id: string;
  name: string;
  typeKind: string;
};

type License = {
  notice: string;
  country_code: string;
  type_track: string;
  publish_date: string;
};

type Extra = {
  record_year: string;
  publish_year: string;
  first_edition: string;
};

type Work = {
  id: string;
  title: string;
  order?: number;
};

type TrackInfo = {
  trackId?: string;
  trackSubclass?: string;
  trackNo: string;
  trackName: string;
  trackTitle?: Title;
  trackArtist?: Artist[];
  trackUrl: {
    trackMp3: Url;
    trackAac: Url;
    trackFlac: Url;
    trackWav: Url;
  };
  trackGenre?: Genre[];
  trackLicense: License;
  trackLicenseExtra: Extra;

  work?: Work[];
  extraInfo: string[];
  isDone?: boolean;
};

type AlbumInfo = {
  albumId?: string;
  no: string;
  albumTitle: string;
  releaseDate: string;
  albumArtist?: Artist[];
  albumUrl?: Url;
  rightsCompany: Company;
  productions?: Production[];
  publications?: Publication[];
  trackInfo: TrackInfo[];

  isDone?: boolean;
};

const SET_CSV_UPLOAD_PAGE = Symbol("CsvUpload::Page::Set");
const SET_CSV_DATA = Symbol("CsvUpload::Data::Set");
const SET_CSV_FIELDS = Symbol("CsvUpload::Fields::Set");
const SET_CSV_ORIGIN_FIELDS = Symbol("CsvUpload::OriginFields::Set");
const SET_CSV_HEADER_OPTION = Symbol("CsvUpload::HeaderOption::Set");
const SET_CSV_COUNT = Symbol("CsvUpload::Count::Set");
const EDIT_CSV_HEADER = Symbol("CsvUpload::Header::Edit");

// TODO: PAGE - THIRD
const SET_CSV_ALBUM_INDEX = Symbol("CsvUpload::AlbumIndex::Set");
const SET_CSV_ALBUM_INFO = Symbol("CsvUpload::AlbumInfo::Set");
const SET_CSV_ALBUM_NO = Symbol("CsvUpload::AlbumNo::Set");
const SET_CSV_ALBUM_TITLE = Symbol("CsvUpload::AlbumTitle::Set");
const SET_CSV_ALBUM_ARTISTS = Symbol("CsvUpload::AlbumArtists::Set");
const SET_CSV_ALBUM_IMAGE = Symbol("CsvUpload::AlbumImage::Set");
const SET_CSV_ALBUM_RIGHTS_COMPANY = Symbol("CsvUpload::AlbumCompany::Set");
const SET_CSV_ALBUM_PRODUCTION = Symbol("CsvUpload::AlbumProduction::Set");
const SET_CSV_ALBUM_PUBLICATION = Symbol("CsvUpload::AlbumPublication::Set");
const SET_CSV_ALBUM_RELEASE_DATE = Symbol("CsvUpload::AlbumReleaseDate::Set");

const SET_CSV_TRACK_INDEX = Symbol("CsvUpload::TrackIndex::Set");
const SET_CSV_TRACK_NO = Symbol("CsvUpload::TrackNo::Set");
const SET_CSV_TRACK_TITLE = Symbol("CsvUpload::TrackTitle::Set");
const SET_CSV_TRACK_DESCRIPTION = Symbol("CsvUpload::TrackDescription::Set");
const SET_CSV_TRACK_WORK = Symbol("CsvUpload::TrackWork::Set");
const SET_CSV_TRACK_ARTIST = Symbol("CsvUpload::TrackArtist::Set");
const SET_CSV_TRACK_GENRE = Symbol("CsvUpload::TrackGenre::Set");
const SET_CSV_TRACK_URL = Symbol("CsvUpload::TrackUrl::Set");
const SET_CSV_TRACK_LICENSE = Symbol("CsvUpload::TrackLicense::Set");
const SET_CSV_TRACK_LICENSE_NOTICE = Symbol("CsvUpload::TrackLicenseNotice::Set");
const SET_CSV_TRACK_LICENSE_NOTICE_DATE = Symbol("CsvUpload::TrackLicenseNoticeDate::Set");
const SET_CSV_TRACK_LICENSE_NOTICE_SELECTED = Symbol("CsvUpload::TrackLicenseNoticeSelected::Set");
const SET_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_INIT = Symbol("CsvUpload::TrackLicenseNoticeCompanyIdInit::Set");
const SET_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_SELECTED = Symbol("CsvUpload::TrackLicenseNoticeCompanyIdSelected::Set");
const REMOVE_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_SELECTED = Symbol("CsvUpload::TrackLicenseNoticeCompanyIdSelected::Remove");
const SET_CSV_TRACK_EXTRA_RECORD = Symbol("CsvUpload::TrackExtraRecord::Set");
const SET_CSV_TRACK_EXTRA_PUBLISH = Symbol("CsvUpload::TrackExtraPublish::Set");
const SET_CSV_TRACK_EXTRA_EDITION = Symbol("CsvUpload::TrackExtraEdition::Set");

const SET_CSV_TASK_INFO = Symbol("CsvUpload::TaskInfo::Set");
const SHIFT_CSV_ALBUM_TASK = Symbol("CsvUpload::AlbumTask::Shift");
const SHIFT_CSV_TRACK_TASK = Symbol("CsvUpload::TrackTask::Shift");

const SET_CSV_ALBUM_DONE = Symbol("CsvUpload::AlbumDone::Set");
const SET_CSV_TRACK_DONE = Symbol("CsvUpload::TrackDone::Set");

export const CsvUploadActions = createActions({
  setCsvUploadPage(page: Page) {
    return { type: SET_CSV_UPLOAD_PAGE, page };
  },
  setCsvAlbumIndex(albumIndex: number) {
    return { type: SET_CSV_ALBUM_INDEX, albumIndex };
  },
  setCsvTrackIndex(trackIndex: number) {
    return { type: SET_CSV_TRACK_INDEX, trackIndex };
  },
  setCsvData(csvData: any) {
    return { type: SET_CSV_DATA, csvData };
  },
  setCsvFields(fields: string[]) {
    return { type: SET_CSV_FIELDS, fields };
  },
  setCsvOriginFields(fields: string[]) {
    return { type: SET_CSV_ORIGIN_FIELDS, fields };
  },
  setCsvHeaderOption(nextHeader: string, prevHeader: string) {
    return { type: SET_CSV_HEADER_OPTION, nextHeader, prevHeader };
  },
  setCsvCount() {
    return { type: SET_CSV_COUNT };
  },
  editCsvHeader(header: string, index: number) {
    return { type: EDIT_CSV_HEADER, header, index };
  },
  // TODO: PAGE - THIRD
  setCsvAlbumInfo(albumInfo?: AlbumInfo[]) {
    return { type: SET_CSV_ALBUM_INFO, albumInfo };
  },
  setCsvAlbumNo(index: number, no: string) {
    return { type: SET_CSV_ALBUM_NO, index, no };
  },
  setCsvAlbumTitle(index: number, title: string) {
    return { type: SET_CSV_ALBUM_TITLE, index, title };
  },
  setCsvAlbumArtists(index: number, artistInfo: Artist[]) {
    return { type: SET_CSV_ALBUM_ARTISTS, index, artistInfo };
  },
  setCsvAlbumImage(index: number, file: File, value: string, ext: string, name: string, valueType: string) {
    return { type: SET_CSV_ALBUM_IMAGE, index, file, value, ext, name, valueType };
  },
  setCsvAlbumRightsCompany(index: number, rightsCompany: Company) {
    return { type: SET_CSV_ALBUM_RIGHTS_COMPANY, index, rightsCompany };
  },
  setCsvAlbumProduction(index: number, productions: Production[]) {
    return { type: SET_CSV_ALBUM_PRODUCTION, index, productions };
  },
  setCsvAlbumPublication(index: number, publications: Publication[]) {
    return { type: SET_CSV_ALBUM_PUBLICATION, index, publications };
  },
  setCsvAlbumReleaseDate(index: number, releaseDate: string) {
    return { type: SET_CSV_ALBUM_RELEASE_DATE, index, releaseDate };
  },

  setCsvTrackNo(index: number, trackIndex: number, no: string) {
    return { type: SET_CSV_TRACK_NO, index, trackIndex, no };
  },
  setCsvTrackTitle(index: number, trackIndex: number, title: string) {
    return { type: SET_CSV_TRACK_TITLE, index, trackIndex, title };
  },
  setCsvTrackDescription(index: number, trackIndex: number, desc: string) {
    return { type: SET_CSV_TRACK_DESCRIPTION, index, trackIndex, desc };
  },
  setCsvTrackArtist(index: number, trackIndex: number, artist: Artist[]) {
    return { type: SET_CSV_TRACK_ARTIST, index, trackIndex, artist };
  },
  setCsvTrackWork(index: number, trackIndex: number, work: Work[]) {
    return { type: SET_CSV_TRACK_WORK, index, trackIndex, work };
  },
  setCsvTrackGenre(index: number, trackIndex: number, genre: Genre[]) {
    return { type: SET_CSV_TRACK_GENRE, index, trackIndex, genre };
  },
  setCsvTrackUrl(index: number, trackIndex: number, file: File, data: string, fileType: string, name: string) {
    return { type: SET_CSV_TRACK_URL, index, trackIndex, file, data, fileType, name };
  },
  setCsvTrackLicense(index: number, trackIndex: number, notice: string, typeTrack: string, publishDate: string) {
    return { type: SET_CSV_TRACK_LICENSE, index, trackIndex, notice, typeTrack, publishDate };
  },
  setCsvTrackLicenseNotice(index: number, trackIndex: number, notice: string) {
    return { type: SET_CSV_TRACK_LICENSE_NOTICE, index, trackIndex, notice };
  },
  setCsvTrackLicenseNoticeDate(index: number, trackIndex: number, publishDate: string) {
    return { type: SET_CSV_TRACK_LICENSE_NOTICE_DATE, index, trackIndex, publishDate };
  },
  setCsvTrackLicenseNoticeSelected(index: number, trackIndex: number, noticeType: string, notice: string) {
    return { type: SET_CSV_TRACK_LICENSE_NOTICE_SELECTED, index, trackIndex, noticeType, notice };
  },
  setCsvTrackLicenseNoticeCompanyIdInit(index: number, trackIndex: number) {
    return { type: SET_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_INIT, index, trackIndex };
  },
  setCsvTrackLicenseNoticeCompanyIdSelected(
    index: number,
    trackIndex: number,
    companyIndex: number,
    noticeType: string | null,
    value: string | null
  ) {
    return { type: SET_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_SELECTED, index, trackIndex, companyIndex, noticeType, value };
  },
  removeCsvTrackLicenseNoticeSelected(index: number, trackIndex: number, companyIndex: number) {
    return { type: REMOVE_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_SELECTED, index, trackIndex, companyIndex };
  },
  setCsvTrackExtraRecord(index: number, trackIndex: number, recordYear: number) {
    return { type: SET_CSV_TRACK_EXTRA_RECORD, index, trackIndex, recordYear };
  },
  setCsvTrackExtraPublish(index: number, trackIndex: number, publishDate: number) {
    return { type: SET_CSV_TRACK_EXTRA_PUBLISH, index, trackIndex, publishDate };
  },
  setCsvTrackExtraEdition(index: number, trackIndex: number, firstEdition: number) {
    return { type: SET_CSV_TRACK_EXTRA_EDITION, index, trackIndex, firstEdition };
  },
  setCsvTaskInfo() {
    return { type: SET_CSV_TASK_INFO };
  },
  shiftCsvAlbumTask() {
    return { type: SHIFT_CSV_ALBUM_TASK };
  },
  shiftCsvTrackTask() {
    return { type: SHIFT_CSV_TRACK_TASK };
  },
  setCsvAlbumDone(albumNo: string) {
    return { type: SET_CSV_ALBUM_DONE, albumNo };
  },
  setCsvTrackDone(albumNo: string, trackNo: string) {
    return { type: SET_CSV_TRACK_DONE, albumNo, trackNo };
  }
});

const handler = createHandler<State>({
  [SET_CSV_UPLOAD_PAGE](state, payload: { page: Page }) {
    state.page = payload.page;
  },
  [SET_CSV_ALBUM_INDEX](state, payload: { albumIndex: number }) {
    state.albumIndex = payload.albumIndex;
  },
  [SET_CSV_TRACK_INDEX](state, payload: { trackIndex: number }) {
    state.trackIndex = payload.trackIndex;
  },
  [SET_CSV_DATA](state, payload: { csvData: any }) {
    state.csvData = payload.csvData;
  },
  [SET_CSV_FIELDS](state, payload: { fields: string[] }) {
    state.fields = payload.fields;
  },
  [SET_CSV_ORIGIN_FIELDS](state, payload: { fields: string[] }) {
    state.originFields = payload.fields;
  },
  [SET_CSV_HEADER_OPTION](state, payload: { nextHeader: string; prevHeader: string }) {
    const nextIdx = state.headerOption.findIndex(option => option.name === payload.nextHeader);
    const prevIdx = state.headerOption.findIndex(option => option.name === payload.prevHeader);
    state.headerOption[nextIdx].isDisabled = !state.headerOption[nextIdx].isDisabled;
    if (state.headerOption[prevIdx]) {
      state.headerOption[prevIdx].isDisabled = !state.headerOption[prevIdx].isDisabled;
    }
  },
  [SET_CSV_COUNT](state) {
    state.csvCount += 1;
  },
  [EDIT_CSV_HEADER](state, payload: { header: string; index: number }) {
    state.fields[payload.index] = payload.header;
    state.csvData = Object.values(state.csvData).map(data => {
      const temp = {};
      itiriri(Object.entries(data)).toArray(([key, value], i) => {
        if (i === payload.index) {
          return Object.assign(temp, { [payload.header]: value });
        } else {
          return Object.assign(temp, { [key]: value });
        }
      });
      return temp;
    });
  },
  // TODO: PAGE - THIRD
  [SET_CSV_ALBUM_INFO](state, payload: { albumInfo?: AlbumInfo[] }) {
    if (payload.albumInfo) {
      state.albumInfo = payload.albumInfo;
      state.albumIndex = 0;
      state.trackIndex = 0;
      return;
    }
    for (const csv of state.csvData) {
      const tempTrack = {
        trackNo: csv["트랙 번호"],
        trackName: csv["트랙 제목"],
        trackUrl: {
          trackMp3: {} as Url,
          trackAac: {} as Url,
          trackFlac: {} as Url,
          trackWav: {} as Url
        },
        trackLicense: {},
        trackLicenseExtra: {} as Extra,
        extraInfo: [csv["부가 정보 1"], csv["부가 정보 2"], csv["부가 정보 3"], csv["부가 정보 4"]].filter(Boolean) as string[],
        isDone: false
      } as TrackInfo;
      const tempAlbum = {
        no: csv["시리얼 넘버"],
        albumTitle: csv["앨범 제목"],
        releaseDate: Time().format("YYYY-MM-DD"),
        trackInfo: [tempTrack],
        isDone: false
      } as AlbumInfo;
      const albumIndex = state.albumInfo.findIndex(({ no, albumTitle }) => no === csv["시리얼 넘버"] && albumTitle === csv["앨범 제목"]);
      if (albumIndex < 0) {
        state.albumInfo.push(tempAlbum);
      } else {
        // when album already exist
        state.albumInfo[albumIndex].trackInfo.push(tempTrack);
      }
    }
  },
  [SET_CSV_ALBUM_NO](state, payload: { index: number; no: string }) {
    state.albumInfo[payload.index].no = payload.no;
  },
  [SET_CSV_ALBUM_TITLE](state, payload: { index: number; title: string }) {
    state.albumInfo[payload.index].albumTitle = payload.title;
  },
  [SET_CSV_ALBUM_ARTISTS](state, payload: { index: number; artistInfo: Artist[] }) {
    state.albumInfo[payload.index].albumArtist = payload.artistInfo;
  },
  [SET_CSV_ALBUM_IMAGE](state, payload: { index: number; file: File; data: string; ext: string; name: string; valueType: string }) {
    state.albumInfo[payload.index].albumUrl = {
      file: payload.file,
      ext: payload.ext,
      typeUrl: payload.valueType,
      data: payload.data,
      url: payload.name
    };
  },
  [SET_CSV_ALBUM_RIGHTS_COMPANY](state, payload: { index: number; rightsCompany: Company }) {
    state.albumInfo[payload.index].rightsCompany = payload.rightsCompany;
  },
  [SET_CSV_ALBUM_PRODUCTION](state, payload: { index: number; productions: Production[] }) {
    state.albumInfo[payload.index].productions = payload.productions;
  },
  [SET_CSV_ALBUM_PUBLICATION](state, payload: { index: number; publications: Production[] }) {
    state.albumInfo[payload.index].publications = payload.publications;
  },
  [SET_CSV_ALBUM_RELEASE_DATE](state, payload: { index: number; releaseDate: string }) {
    state.albumInfo[payload.index].releaseDate = `${payload.releaseDate}`; // ! constant값으로 05시 고정
  },
  [SET_CSV_TRACK_NO](state, payload: { index: number; trackIndex: number; no: string }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackNo = payload.no;
  },
  [SET_CSV_TRACK_TITLE](state, payload: { index: number; trackIndex: number; title: string }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackName = payload.title;
  },
  [SET_CSV_TRACK_DESCRIPTION](state, payload: { index: number; trackIndex: number; description: string }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackTitle = {
      type_metadata_title: "description",
      value: payload.description ? payload.description : ""
    } as Title;
  },
  [SET_CSV_TRACK_ARTIST](state, payload: { index: number; trackIndex: number; artist: Artist[] }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackArtist = payload.artist;
  },
  [SET_CSV_TRACK_WORK](state, payload: { index: number; trackIndex: number; work: Work[] }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].work = payload.work;
  },
  [SET_CSV_TRACK_GENRE](state, payload: { index: number; trackIndex: number; genre: Genre[] }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackGenre = payload.genre;
  },
  [SET_CSV_TRACK_URL](state, payload: { index: number; trackIndex: number; file: File; data: string; fileType: string; name: string }) {
    switch (payload.fileType) {
      case "mp3high":
        state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackUrl.trackMp3 = {
          file: payload.file,
          ext: "mp3",
          typeUrl: "mp3high",
          data: payload.data,
          url: payload.name
        } as Url;
        break;
      case "aac":
        state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackUrl.trackAac = {
          file: payload.file,
          ext: "m4a",
          typeUrl: "aac",
          data: payload.data,
          url: payload.name
        } as Url;
        break;
      case "flac":
        state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackUrl.trackFlac = {
          file: payload.file,
          ext: "flac",
          typeUrl: "flac",
          data: payload.data,
          url: payload.name
        } as Url;
        break;
      case "wav":
        state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackUrl.trackWav = {
          file: payload.file,
          ext: "wav",
          typeUrl: "wav",
          data: payload.data,
          url: payload.name
        } as Url;
        break;
      default:
        break;
    }
  },
  [SET_CSV_TRACK_LICENSE](state, payload: { index: number; trackIndex: number; notice: string; typeTrack: string; publishDate: string }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense = {
      notice: payload.notice,
      country_code: "ZZ",
      type_track: payload.typeTrack,
      publish_date: payload.publishDate
    } as License;
  },
  [SET_CSV_TRACK_LICENSE_NOTICE](state, payload: { index: number; trackIndex: number; notice: string }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense.notice = payload.notice;
  },
  [SET_CSV_TRACK_LICENSE_NOTICE_DATE](state, payload: { index: number; trackIndex: number; publishDate: string }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense.publish_date = payload.publishDate;
  },
  [SET_CSV_TRACK_LICENSE_NOTICE_SELECTED](state, payload: { index: number; trackIndex: number; noticeType: string; notice: string }) {
    const notice = JSON.parse(state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense.notice);
    notice[payload.noticeType] = payload.notice;
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense.notice = JSON.stringify(notice);
  },
  [SET_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_INIT](state, payload: { index: number; trackIndex: number }) {
    const { index, trackIndex } = payload;
    const track = state.albumInfo[index].trackInfo[trackIndex];
    const notice = JSON.parse(track.trackLicense.notice);
    if (track.extraInfo?.length) {
      const initInfo = {
        ALBUM_UCI: track.extraInfo[0] ?? null,
        UCI: track.extraInfo[1] ?? null,
        ISRC: track.extraInfo[2] ?? null
      };
      notice["companyIds"] = initInfo;
    }
    track.trackLicense.notice = JSON.stringify(notice);
  },
  [SET_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_SELECTED](
    state,
    payload: { index: number; trackIndex: number; companyIndex: number; noticeType: string | null; value: string | null }
  ) {
    const { index, trackIndex, companyIndex, noticeType, value } = payload;
    const notice = JSON.parse(state.albumInfo[index].trackInfo[trackIndex].trackLicense.notice);
    const companyIdsList: [string, string | null][] = Object.entries(notice["companyIds"] ?? {});
    if (noticeType === null && value === null) {
      companyIdsList.push(["", null]);
    } else if (noticeType && value === null) {
      companyIdsList[companyIndex][0] = noticeType;
    } else {
      companyIdsList[companyIndex][1] = value;
    }
    const companyNotice = Object.fromEntries(companyIdsList);
    notice["companyIds"] = companyNotice;
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense.notice = JSON.stringify(notice);
  },
  [REMOVE_CSV_TRACK_LICENSE_NOTICE_COMPANY_ID_SELECTED](state, payload: { index: number; trackIndex: number; companyIndex: number }) {
    const { index, trackIndex, companyIndex } = payload;
    const notice = JSON.parse(state.albumInfo[index].trackInfo[trackIndex].trackLicense.notice);
    const companyIdsList: [string | null, string | null][] = Object.entries(notice["companyIds"] ?? {});
    if (companyIdsList.length) {
      companyIdsList.splice(companyIndex, 1);
    }
    const companyNotice = Object.fromEntries(companyIdsList);
    notice["companyIds"] = companyNotice;
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicense.notice = JSON.stringify(notice);
  },
  [SET_CSV_TRACK_EXTRA_RECORD](state, payload: { index: number; trackIndex: number; recordYear: number }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicenseExtra.record_year = payload.recordYear.toString();
  },
  [SET_CSV_TRACK_EXTRA_PUBLISH](state, payload: { index: number; trackIndex: number; publishDate: number }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicenseExtra.publish_year = payload.publishDate.toString();
  },
  [SET_CSV_TRACK_EXTRA_EDITION](state, payload: { index: number; trackIndex: number; firstEdition: number }) {
    state.albumInfo[payload.index].trackInfo[payload.trackIndex].trackLicenseExtra.first_edition = payload.firstEdition.toString();
  },
  [SET_CSV_TASK_INFO](state) {
    state.taskInfo = state.albumInfo;
  },
  [SHIFT_CSV_ALBUM_TASK](state) {
    state.taskInfo.shift();
  },
  [SHIFT_CSV_TRACK_TASK](state) {
    state.taskInfo[0].trackInfo.shift();
  },
  [SET_CSV_ALBUM_DONE](state, payload: { albumNo: string }) {
    const index = state.taskInfo.findIndex(item => item.no === payload.albumNo);
    state.taskInfo[index].isDone = true;
  },
  [SET_CSV_TRACK_DONE](state, payload: { albumNo: string; trackNo: string }) {
    const albumIndex = state.taskInfo.findIndex(item => item.no === payload.albumNo);
    const trackIndex = state.taskInfo[albumIndex].trackInfo.findIndex(item => item.trackNo === payload.trackNo);
    state.taskInfo[albumIndex].trackInfo[trackIndex].isDone = true;
  }
});

const createInitialState = () => ({
  page: Page.FIRST as Page,
  albumIndex: 0 as number,
  trackIndex: 0 as number,
  csvData: [] as Record<string, string>[],
  originFields: [] as string[],
  fields: [] as string[],
  csvCount: 0 as number,
  headerOption: [
    { id: "시리얼 넘버", name: "시리얼 넘버", isDisabled: false },
    { id: "앨범 제목", name: "앨범 제목", isDisabled: false },
    { id: "트랙 번호", name: "트랙 번호", isDisabled: false },
    { id: "트랙 제목", name: "트랙 제목", isDisabled: false },
    { id: "부가 정보 1", name: "부가 정보 1", isDisabled: false },
    { id: "부가 정보 2", name: "부가 정보 2", isDisabled: false },
    { id: "부가 정보 3", name: "부가 정보 3", isDisabled: false },
    { id: "부가 정보 4", name: "부가 정보 4", isDisabled: false }
  ] as HeaderOptionList,
  albumInfo: [] as AlbumInfo[],
  taskInfo: [] as AlbumInfo[]
});

export const reducer = createReducer(handler, createInitialState);
