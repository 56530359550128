/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, render, Variable, Query, Field, StringScalar, SelectionSet, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  title: string;
  rootId: number;
}>;

export const CheckDuplicatedWorkTrack = async (option: Option) => {
  const $title = <Variable name="title" scalar={<StringScalar />} value={option.title} />;
  const $rootId = <Variable name="rootId" scalar={<IntScalar />} value={option.rootId} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_WORK_TRACK">
      <SelectionSet
        name="metadata"
        alias="work"
        argument={{
          where: {
            type_metadata_class: "work",
            type_metadata_subclass: "track",
            title: $title,
            metadata_structure__some: {
              structure_root_id: $rootId
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    work: ReadonlyArray<{
      id: string;
      title: string;
    }>;
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors[0].message;
  }
  return data!.work[0]?.title;
};
