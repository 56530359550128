import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_X_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { CreateMetadataArtist } from "GraphQL/Queries";
import { useAlbumDetailDispatch } from "../../Store";
import { AlbumDetailActions } from "../../Store/AlbumDetail";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";

type Props = Readonly<{
  metadataId: string;
  order: number;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 32.5)};
  height: ${pixelize(UNIT * 28)};
  padding: ${PADDING_XX_LARGE_PX};

  section {
    h5 {
      margin-bottom: ${MARGING_LARGE_PX};
    }
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${MARGING_X_LARGE_PX};

  h3 {
    font-size: 1.2rem;
  }
`;

export const AddArtistModal = ({ metadataId, order, toClose }: Props) => {
  const dispatch = useAlbumDetailDispatch();
  const [artist, setArtist] = useState({
    id: "",
    name: ""
  });
  const [role, setRole] = useState({
    id: "",
    name: ""
  });
  const handleButton = async () => {
    if (!artist.id.length) {
      window.alert(`아티스트를 먼저 선택해주세요.`);
      return;
    }
    if (!role.id.length) {
      window.alert(`역할을 먼저 선택해주세요.`);
      return;
    }
    try {
      const { data, errors } = await CreateMetadataArtist({ metadataId, artistId: artist.id, roleId: role.id, order });
      if (errors) {
        window.alert(`중복된 데이터가 있습니다. 다시 확인해주세요.`);
        return;
      }
      if (data) {
        dispatch(
          AlbumDetailActions.createAlbumArtist({
            uuid: data.createMetadataArtist.artistRelation[0].uuid,
            id: artist.id,
            name: artist.name,
            order
          })
        );
        toClose();
      }
    } catch (err) {
      console.log(err);
      window.alert("저장에 실패하였습니다.");
      return;
    }
  };

  return (
    <Layout>
      <Title>
        <h3>{`추가할 아티스트를 선택해주세요.`}</h3>
      </Title>
      <section>
        <h5>아티스트</h5>
        <ArtistTextAutoComplete
          onBlur={info => {
            if (info) {
              setArtist({
                id: info.id,
                name: info.name
              });
            }
          }}
        />
      </section>
      <section>
        <h5>역할</h5>
        <RoleTextAutoComplete
          onBlur={info => {
            if (info) {
              setRole({
                id: info.id,
                name: info.name
              });
            }
          }}
        />
      </section>
      <ButtonGroup>
        <Input.Button color="success" onClick={() => handleButton()}>
          추가
        </Input.Button>
        <Input.Button color="danger" onClick={toClose}>
          취소
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
