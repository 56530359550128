import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX, MARGING_LARGE, MARGING_SMALL_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_4, GRAY_6 } from "constants/baseColor";
import { useTracksStore, useTracksSelector } from "../../Store";
import { TrackActions } from "../../Store/Track";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { UpdateTrackLicense } from "GraphQL/Queries/Track";
import { Toast } from "lib/toast";
import { CountryCodeInfo } from "constants/CountryCodeInfo";
import { DANGER_COLOR, PRIMARY_COLOR, DANGER_COLOR_LIGHT } from "constants/color";
import { DatePicker } from "App/Molecules/DatePicker";
import { ValidType } from "constants/ValidType";
import { YtvLicenseValidCheckInfo } from "constants/TypeOptionInfo";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import uuidv4 from "uuid/v4";
import dayjs from "dayjs";

type Props = {
  metadataId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  toClose: () => void;
};

export const UpdateLicenseModal = ({ metadataId, toClose, setLoading }: Props) => {
  const modalId = useTracksSelector(store => store.Track.modalId);
  const [{ licenses }, dispatch] = useTracksStore(store => ({
    licenses: store.Track.license
  }));
  const license = licenses.filter(item => item.id === modalId)[0];
  const [type, setType] = useState<string>(license.type_track);
  const [countryCode, setCountryCode] = useState<string>(license.country_code);
  const [description, setDescription] = useState<string>(JSON.parse(license.notice).text);
  const [companyIds, setCompanyIds] = useState<Record<string, string | null> | null>(JSON.parse(license.notice).companyIds);
  const [copyright, setCopyright] = useState<boolean | undefined>(license.copyright);
  const [neighboring, setNeighboring] = useState<boolean | undefined>(license.neighboring);
  const [licenseDate, setLicenseDate] = useState<string | null>(license.license_date);
  const [publishDate, setPublishDate] = useState<string | null>(license.publish_date);
  const [isService, setBeService] = useState<number | undefined>(license.is_service);
  const [validCheck, setValidCheck] = useState<ValidType | undefined>(license.validCheck);

  const updateLicense = async (id: string, isSingle?: boolean) => {
    try {
      const notice = JSON.stringify({ text: description, companyIds });
      const { data } = await CreateAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      if (data) {
        const { data: licenseData } = await UpdateTrackLicense({
          id,
          typeTrack: type,
          countryCode: !isSingle ? undefined : countryCode,
          notice,
          copyright,
          neighboring,
          licenseDate,
          publishDate,
          isService,
          validCheck
        });
        if (licenseData) {
          await DeleteAccessRecord({ id: data.createAccess.id });
          dispatch(
            TrackActions.updateLicense({
              id,
              typeTrack: type,
              countryCode: !isSingle ? undefined : countryCode,
              notice,
              copyright,
              neighboring,
              licenseDate,
              publishDate,
              isService,
              validCheck
            })
          );
        }
      }
    } catch (e) {
      console.log(e);
      Toast.error("Internal Server Error");
      setLoading(false);
      return;
    }
  };

  const onUpdateSingleLicense = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toClose();
    setLoading(true);
    await updateLicense(modalId, true);
    Toast.primary("수정되었습니다.", undefined, "top-center");
    setLoading(false);
  };

  const onUpdateAllLicense = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toClose();
    setLoading(true);
    for (const targetLicense of licenses) {
      await updateLicense(targetLicense.id, false);
    }
    Toast.primary("일괄 수정되었습니다.", undefined, "top-center");
    setLoading(false);
  };

  return (
    <Layout>
      <Header>
        <h3>라이센스 수정</h3>
        <CancelIcon className="cancel" onClick={toClose} />
      </Header>
      <Section>
        <RowGroup>
          <h4>저작권 만료 여부</h4>
          <CopyrightBox>
            <Input.Toggle
              className="toggle"
              color="primary"
              toggleValue="저작권 만료"
              isActive={copyright}
              onClick={() => setCopyright(prev => !prev)}
            />
            <Input.Toggle
              className="toggle"
              color="primary"
              toggleValue="저작인접권 만료"
              isActive={neighboring}
              onClick={() => setNeighboring(prev => !prev)}
            />
          </CopyrightBox>
        </RowGroup>
        <RowGroup>
          <h4>유튜브 라이센스 체크</h4>
          <Input.TextSelect
            className="typeSelect"
            optionList={YtvLicenseValidCheckInfo}
            defaultValue={YtvLicenseValidCheckInfo.find(({ id }) => id === validCheck)}
            onChange={info => {
              if (info) {
                setValidCheck(info.id as ValidType);
              }
            }}
          />
        </RowGroup>
        <RowGroup>
          <h4>타입 선택</h4>
          <Input.TextSelect
            className="typeSelect"
            optionList={OptionLicenseService}
            defaultValue={OptionLicenseService.find(item => +item.id === isService)}
            onChange={info => {
              if (info) {
                setBeService(+info.id);
              }
            }}
          />
        </RowGroup>
        <GroupContainer>
          <RowGroup>
            <h4>타입 선택</h4>
            <Input.TextSelect
              className="typeSelect"
              optionList={TypeInfo}
              defaultValue={{ id: type, name: type }}
              onChange={info => {
                if (info) {
                  setType(info.id);
                }
              }}
            />
          </RowGroup>
          <RowGroup>
            <h4>국가 선택</h4>
            <Input.TextSelect
              key={!countryCode ? "" : countryCode}
              className="typeSelect"
              defaultValue={CountryCodeInfo.find(({ id }) => id === countryCode)}
              optionList={CountryCodeInfo}
              onChange={info => {
                if (info) {
                  setCountryCode(info.id);
                }
              }}
            />
          </RowGroup>
        </GroupContainer>
        <RowGroup>
          <h4>라이센스 설명</h4>
          <Multiline
            placeholder="설명을 입력하세요"
            defaultValue={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
          />
        </RowGroup>
        <RowGroup>
          <h4>
            부가 정보<span>중복된 이름은 입력할 수 없습니다.</span>
          </h4>
          {!companyIds
            ? null
            : Object.entries(companyIds).map((item, i) => {
                const uuid = uuidv4();
                const filteredOptionList = noticeOptionList.map(({ id, name }) => ({
                  id,
                  name,
                  isDisabled: Object.entries(companyIds)
                    .map(item => item[0])
                    .includes(id)
                }));
                return (
                  <ExtraInfo key={uuid}>
                    <RoundButton
                      color="danger"
                      onClick={() => {
                        const companyIdsList = Object.entries(companyIds);
                        if (companyIdsList.length) {
                          companyIdsList.splice(i, 1);
                        }
                        setCompanyIds(Object.fromEntries(companyIdsList));
                      }}
                    >
                      -
                    </RoundButton>
                    <Input.CreatableTextSelect
                      placeholder="이름"
                      name="createNotice"
                      optionList={filteredOptionList}
                      defaultValue={!item[0] ? undefined : item[0] === null ? { id: "", name: "" } : { id: item[0], name: item[0] }}
                      onBlur={info => {
                        if (info) {
                          const companyIdsList = Object.entries(companyIds);
                          companyIdsList[i][0] = info.name;
                          setCompanyIds(Object.fromEntries(companyIdsList));
                        }
                      }}
                    />
                    <Input.Text
                      placeholder="값을 입력하세요"
                      defaultValue={!item[1] ? undefined : (item[1] as any)}
                      onBlur={value => {
                        const companyIdsList = Object.entries(companyIds);
                        companyIdsList[i][1] = value;
                        setCompanyIds(Object.fromEntries(companyIdsList));
                      }}
                    />
                  </ExtraInfo>
                );
              })}
          <RoundButton
            style={{ marginBottom: MARGING_SMALL_PX }}
            color="primary"
            onClick={() => {
              const companyIdsList = Object.entries(companyIds || {});
              companyIdsList.push(["", null]);
              setCompanyIds(Object.fromEntries(companyIdsList));
            }}
          >
            +
          </RoundButton>
        </RowGroup>
        <RowGroup style={{ border: "none" }}>
          <h4>날짜 정보</h4>
          <DateContainer>
            <Picker
              style={{ marginRight: "1rem" }}
              dateInfoText="갱신 일자"
              defaultValue={!licenseDate ? undefined : dayjs(licenseDate).toDate()}
              format="yyyy년 MM월 dd일"
              onChange={date => {
                if (!Array.isArray(date)) {
                  setLicenseDate(dayjs(date).format("YYYY-MM-DD"));
                }
              }}
            />
            <Picker
              dateInfoText="발매 일자"
              defaultValue={!publishDate ? undefined : dayjs(publishDate).toDate()}
              showTimeSelect
              format="yyyy년 MM월 dd일 HH:mm:ss"
              maxDate={dayjs("2099-12-31").toDate()}
              onChange={date => {
                if (!Array.isArray(date)) {
                  setPublishDate(dayjs(date).toString());
                }
              }}
            />
          </DateContainer>
        </RowGroup>
      </Section>
      <ButtonGroup>
        <Input.Button className="btn cancel" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button className="btn cancel" disabled={!type.length || !countryCode.length} color="warning" onClick={onUpdateAllLicense}>
          전체 일괄 적용
        </Input.Button>
        <Input.Button disabled={!type.length || !countryCode.length} className="btn save" color="primary" onClick={onUpdateSingleLicense}>
          확인
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  width: ${pixelize(UNIT * 45)};
  height: ${pixelize(UNIT * 52)};
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fff;
  font-size: 0.9rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: rgb(106, 95, 221);
  padding: 1.5em;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancel {
    position: absolute;
    right: 3%;
    width: 2em;
    height: 2em;
    padding: 0.5em;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Multiline = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 8.5)};
  font-size: 14px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
`;

const Section = styled.section`
  padding: 1em 2em;
`;

const RowGroup = styled.div`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
    display: flex;
    align-items: center;
    span {
      font-size: 0.8em;
      color: ${DANGER_COLOR_LIGHT};
      font-weight: 400;
      margin-left: ${MARGING_LARGE_PX};
    }
  }

  .typeSelect {
    width: 100%;
    margin-bottom: ${MARGING_LARGE_PX};
    font-size: 12px;
  }
`;

const GroupContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const CopyrightBox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${MARGING_SMALL_PX};
  .toggle {
    margin-right: ${MARGING_SMALL_PX};
  }
`;

const ButtonGroup = styled.div`
  position: sticky;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  .btn {
    width: 10em;
  }
  .cancel,
  .reset {
    margin-right: 0.2em;
  }
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ExtraInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 180px auto;
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
  margin-bottom: ${MARGING_SMALL_PX};
  input[type="text"] {
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
  }
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => (props.color === "danger" ? DANGER_COLOR : PRIMARY_COLOR)};
`;

const Picker = styled(DatePicker)`
  input {
    width: 220px;
  }
`;

const TypeInfo = [
  { id: "PD", name: "PD" },
  { id: "ccl", name: "ccl" },
  { id: "NoCopyright", name: "NoCopyright" },
  { id: "VL", name: "VL" },
  { id: "Cover", name: "Cover" }
];
const OptionLicenseService = [
  { id: "0", name: "사용 가능 / 재생 가능" },
  { id: "1", name: "사용 가능 / 재생 불가" },
  { id: "2", name: "사용 불가 / 재생 불가" }
];

const noticeOptionList = [
  { id: "ALBUM_UCI", name: "ALBUM_UCI" },
  { id: "UCI", name: "UCI" },
  { id: "ISRC", name: "ISRC" }
];
