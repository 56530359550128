import React, { ReactNode } from "react";
import styled from "styled-components";

import { GRAY_5 } from "constants/baseColor";

type Props = Styleable & Readonly<{ children: ReactNode }>;

const Layout = styled.thead`
  text-align: center;
  border-bottom: 2px solid ${GRAY_5};
  height: 1rem;
`;

export const Header = ({ children, style, className }: Props) => (
  <Layout style={style} className={className}>
    {children}
  </Layout>
);
