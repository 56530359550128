import React from "react";
import { FilterGroup, Filters, ToggleButton, ToggleGroup } from "../..";

type VC = {
  V1: boolean;
  V2: boolean;
  V3: boolean;
  VC: boolean;
  REMOVE: boolean;
  DONE: boolean;
  ETC: boolean;
  MODIFY: boolean;
};

type Props = {
  validCheck: VC;
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const VCFilter = ({ validCheck, setFilters }: Props) => {
  const onToggleVC = (key: string) => {
    const vc = validCheck;
    const toggleValue = !vc[key as keyof typeof vc];
    setFilters(filter => ({ ...filter, validCheck: { ...vc, [key]: toggleValue } }));
  };
  return (
    <FilterGroup>
      <h4>유효성</h4>
      <span className="info">반드시 하나 이상의 버튼이 ON 이어야 합니다.</span>
      <ToggleGroup>
        {Object.keys(validCheck).map(key => {
          const vc = validCheck;
          const isActive = vc[key as keyof typeof vc];
          return <ToggleButton key={key} toggleValue={key} color="primary" isActive={isActive} onClick={() => onToggleVC(key)} />;
        })}
      </ToggleGroup>
    </FilterGroup>
  );
};
