/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  uuid: string;
  url: string;
  typeUrl?: string;
  order?: number;
  validCheck?: ValidType;
}>;

export const UpdateArtistUrl = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $url = <Variable name="url" scalar={<StringScalar />} value={option.url} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable />} value={option.typeUrl} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.order} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST_URL">
      <SelectionSet
        name="updateArtist_url"
        alias="updateArtistUrl"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            url: $url,
            type_url: $typeUrl,
            exposure_order: $order,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateArtistUrl: Readonly<{
      artist_id: string;
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
