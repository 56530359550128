export const USER_INFO = "DMS::USER_INFO";
export const TRACK_FILTER = "DMS::TRACK::FILTER";
export const TRACK_VC_FILTER = "DMS::TRACK_VC::FILTER";
export const TRACK_GENRE_FILTER = "DMS::TRACK_GENRE::FILTER";
export const WORK_FILTER = "DMS::WORK::FILTER";
export const WORK_EDIT_FILTER = "DMS::WORK_EDIT::FILTER";
export const ALBUM_FILTER = "DMS::ALBUM::FILTER";
export const ARTIST_FILTER = "DMS::ARTIST::FILTER";
export const ARTIST_VC_FILTER = "DMS::ARTIST_VC::FILTER";
export const ARTIST_ROLE_FILTER = "DMS::ARTIST_ROLE::FILTER";
export const ARTIST_TYPE_FILTER = "DMS::ARTIST_TYPE::FILTER";
export const TRACK_ARTIST_FILTER = "DMS::TRACK_ARTIST::FILTER";
export const TRACK_COMPANY_FILTER = "DMS::TRACK_COMPANY::FILTER";
export const TRACK_WORK_ARTIST_FILTER = "DMS::TRACK_WORK_ARTIST::FILTER";
export const ROLE_FILTER = "DMS::ROLE::FILTER";
export const TRACK_CHARACTER_FILTER = "DMS::CHARACTER::FILTER";
export const TRACK_ROLE_FILTER = "DMS::TRACK_ROLE::FILTER";
export const TRACK_LANGUAGE_FILTER = "DMS::TRACK_LANGUAGE::FILTER";
export const TRACK_COUNT = "DMS::TRACK::COUNT";
export const GENRE_LIST = "DMS::GENRE::LIST";
export const MOOD_LIST = "DMS::MOOD::LIST";
export const WORK_GENRE_LIST = "DMS::WORK_GENRE::LIST";
export const WORK_MOOD_LIST = "DMS::WORK_MOOD::LIST";
