/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { CommentOrderByInput } from "../../constants/CommentOrderByInput";

type Props = Readonly<{
  value?: CommentOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  CommentOrderByInput.id_ASC,
  CommentOrderByInput.id_DESC,
  CommentOrderByInput.timestamp_ASC,
  CommentOrderByInput.timestamp_DESC,
  CommentOrderByInput.user_id_ASC,
  CommentOrderByInput.user_id_DESC
];

export const CommentOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="CommentOrderByInput" value={value} isNullable={isNullable} items={items} />
);
