import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { GRAY_2 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { useAsyncEffect } from "lib/use-async-effect";
import { Loading } from "App/Atomics/Loading";
import { Toast } from "lib/toast";
import { GetPlaylistInfo } from "GraphQL/Queries/Playlist";
import { ListContainer } from "./ListContainer";
import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";
import { PlaylistInfo } from "GraphQL/Queries/Playlist/GetPlaylistInfo";

type Props = {
  id: string;
  toClose: () => void;
};

export type TrackItem = {
  index: number;
  uuid: string;
  id: string;
  title: string;
  artist: string;
};

export type TrackList = TrackItem[];

export const ChangeOrderModal = ({ id, toClose }: Props) => {
  const [trackList, setTrackList] = useState<TrackList>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchPlaylistInfo = useCallback(async (id: string, count: number, skip: number, list: PlaylistInfo[]): Promise<PlaylistInfo[]> => {
    try {
      const playlistInfo = await GetPlaylistInfo({
        id,
        trackOrder: MetadataPlaylistRelationOrderByInput.exposure_order_ASC,
        trackCount: count,
        trackSkip: skip
      });
      // when get first query
      if (!list.length) {
        list = playlistInfo;
      } else {
        list[0].metadataPlaylistRelation = list[0].metadataPlaylistRelation.concat(playlistInfo[0].metadataPlaylistRelation);
      }
      if (playlistInfo[0].metadataPlaylistRelation.length === 100) {
        return fetchPlaylistInfo(id, count, skip + count, list);
      }
      return list;
    } catch (err) {
      console.log(err);
      Toast.error("트랙 정보를 불러올 수 없습니다.");
      return list;
    }
  }, []);

  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const playlistInfo = await fetchPlaylistInfo(id, 100, 0, []);
        const item: TrackList = playlistInfo[0].metadataPlaylistRelation.map(({ uuid, metadata }, index) => {
          const { metadataId, title, artistRelation } = metadata[0];
          return {
            index,
            uuid,
            id: metadataId,
            title,
            artist: artistRelation[0]?.artist[0]?.name
          };
        });
        setTrackList(item);
      } catch (err) {
        Toast.error("데이터를 가져올 수 없습니다.");
      } finally {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <Layout>
      <Header>
        <span>플레이리스트 순서 변경</span>
        <CancelIcon className="cancel-icon" onClick={toClose} />
      </Header>
      <ListContainer playlistId={id} trackList={trackList} setTrackList={setTrackList} />
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 800px;
  height: 850px;
  overflow-y: scroll;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #6a5fdd;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 2px;
  z-index: 2;

  .cancel-icon {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    margin-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      fill: ${GRAY_2};
    }
  }
`;
