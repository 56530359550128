import React, { useState } from "react";
import styled from "styled-components";

import { MARGING_LARGE_PX, pixelize, UNIT, MARGING_XX_LARGE_PX, MARGING_X_LARGE_PX } from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { Toast } from "lib/toast";
import { GRAY_6 } from "constants/baseColor";
import { TrackTextAutoComplete } from "App/Molecules/AutoCompletes/Track";
import { Input } from "App/Atomics/Input";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";
import { AddTrackToPlaylist } from "GraphQL/Queries/Playlist";
import { Playlist } from "GraphQL/Queries/Playlist/GetPlaylistWithTrack";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import { usePlaylistsDispatch } from "App/Routes/Playlists/Store";
import { ParsedComment } from "../ExtraInfoModal";
import dayjs from "dayjs";
import uuidv4 from "uuid/v4";

type Props = Readonly<{
  index: number;
  id: string;
  setPlaylistInfo: React.Dispatch<React.SetStateAction<Playlist>>;
  setComment: React.Dispatch<React.SetStateAction<ParsedComment | undefined>>;
  toClose: () => void;
}>;

type TrackInfo = {
  id: string;
  title: string;
  artistName: string;
  metadataUrl: {
    uuid: string;
    typeUrl: string;
    url: string;
  }[];
};

export const AddTrackToPlaylistModal = ({ index, id, setPlaylistInfo, setComment, toClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [trackInfo, setTrackInfo] = useState<TrackInfo | undefined>(undefined);
  const dispatch = usePlaylistsDispatch();

  const onAddTrack = async () => {
    if (!trackInfo) {
      return;
    }
    try {
      setLoading(true);
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      if (accessId) {
        try {
          const { data, errors } = await AddTrackToPlaylist({ id, trackId: trackInfo.id, bookingOrder: 0 });
          if (errors) {
            throw errors[0].message;
          }
          if (data) {
            const { uuid, order } = data.updatePlaylist.metadata_playlist_relation[0];
            const relation = {
              uuid,
              order,
              metadata: [
                {
                  id: trackInfo.id,
                  title: trackInfo.title,
                  artistRelation: [
                    {
                      artist: [{ name: trackInfo.artistName }]
                    }
                  ],
                  metadataUrl: trackInfo.metadataUrl
                }
              ]
            };
            dispatch(PlaylistsActions.addTrackToPlaylist({ index, uuid, order, metadataId: trackInfo.id }));
            await DeleteAccessRecord({ id: accessId });
            Toast.primary("추가되었습니다");
            setLoading(false);
            setPlaylistInfo(prev => ({ ...prev, metadataPlaylistRelation: prev.metadataPlaylistRelation.concat(relation) }));
            setComment(prevState => {
              const data = !prevState ? [] : prevState.data;
              const timestamp = dayjs(new Date()).toISOString();
              const newData = [
                ...data,
                {
                  id: uuidv4(),
                  metadata_id: trackInfo.id,
                  title: trackInfo.title,
                  artist: trackInfo.artistName,
                  operate: "INSERT" as const,
                  timestamp
                }
              ];
              const text = !prevState ? "" : prevState.text;
              return { data: newData, text: `${!text ? "" : text}[${trackInfo.id}] ${trackInfo.title} 곡을 추가하였습니다.\n` };
            });
            toClose();
          }
        } catch (err) {
          console.log(err);
          Toast.error("추가에 실패하였습니다.");
          await DeleteAccessRecord({ id: accessId });
          setLoading(false);
          return;
        }
      }
    } catch (err) {
      console.log(err);
      Toast.error("권한 요청에 실패하였습니다.");
      setLoading(false);
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h3>플레이리스트 트랙 추가</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <div className="tip">
          <span>트랙의 제목을 입력해주세요.</span>
        </div>
        <FilterGroup>
          <span className="suggestSpan">
            <TrackTextAutoComplete
              className="autocomplete"
              onBlur={(info: any) => {
                if (info) {
                  const { id, title, artistRelation, metadataUrl } = info;
                  setTrackInfo({ id, title, artistName: artistRelation[0]?.artist[0].name ?? "", metadataUrl });
                }
              }}
            />
          </span>
        </FilterGroup>
      </Section>
      <ButtonGroup>
        <Input.Button className="cancel-btn" onClick={toClose}>
          닫기
        </Input.Button>
        <Input.Button color="primary" disabled={!trackInfo} onClick={onAddTrack}>
          추가
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  width: ${pixelize(UNIT * 55)};
  height: ${pixelize(UNIT * 22)};

  h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .tip {
    span {
      font-size: 0.8rem;
      color: #999;
    }
    margin-bottom: 0.5rem;
  }
  .center-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .suggestSpan {
    display: flex;
    flex-direction: row;
    margin-bottom: ${MARGING_XX_LARGE_PX};

    & > .autocomplete {
      width: 100%;
      margin-right: ${MARGING_LARGE_PX};
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > button {
    width: ${pixelize(UNIT * 5)};
    margin-top: auto;
    margin-left: auto;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const FilterGroup = styled.section`
  margin-bottom: ${MARGING_LARGE_PX};

  hr {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled.div`
  position: absolute;
  bottom: 5%;
  right: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  h4 {
    text-align: center;
  }
  .cancel-btn {
    margin-right: 8px;
  }
`;

const Section = styled.div`
  padding: 1rem 2rem;
`;
