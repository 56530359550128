/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
}>;

export const DeleteDescArtist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_DESC_ARTIST">
      <SelectionSet
        name="deleteArtist_title"
        alias="deleteDescArtist"
        argument={{
          where: {
            id: $id
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteDescArtist: {
      id: string;
    }[];
  }>;

  return await clients.artist.request<Data>(query, variables);
};
