import React, { useCallback } from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { gql } from "lib/gql-tag";
import { useQueryParams } from "lib/use-query-params";
import { useAsyncEffect } from "lib/use-async-effect";
import { clients } from "utils/clients";
import { AlbumDetailStoreProvider, useAlbumDetailDispatch, useAlbumDetailSelector } from "./Store";
import { AlbumDetailActions } from "./Store/AlbumDetail";
import { RightDetailTable } from "./RightDetailTable";
import { StructureActions } from "./Store/Structure";
import { UserRole } from "constants/UserRole";
import { useAppStore } from "App/Store";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import { ValidType } from "constants/ValidType";
import { Helmet } from "App/Atomics/Helmet";

type Metadata = [
  {
    metadataId: string;
    title: string;
    typeSubClass: string;
    validCheck: ValidType;
  }
];

type Child = {
  metadata: Metadata;
};

export type StructureProps = {
  metadata: Metadata;
  child: Child[];
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100vh;

  table {
    width: 100%;
    margin-top: ${HEADER_HEIGHT_PX};
  }

  & form {
    align-self: right;
  }
`;

const ChildLayout = () => {
  const [{ userRole }, dispatchApp] = useAppStore(store => ({
    userRole: store.UserToken.role
  }));
  const { mounted } = useAlbumDetailSelector(store => store.Structure);
  const dispatch = useAlbumDetailDispatch();
  const queryParams = useQueryParams();
  const searchString = queryParams.get("q", { default: "" });
  const rootId = queryParams.get("id", { default: 1, cast: value => +value });

  const GET_DEPTH_1_IN_ALBUM = gql`
    query GET_DEPTH_1_IN_ALBUM($rootId: Int, $count: Int, $skip: Int) {
      albumDetail: metadata_structure(
        where: {
           structure_id: "${rootId}"
           ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
          }
        ) {
        album: metadata {
          metadataId: metadata_id
          title
          no
          typeClass: type_metadata_class
          typeSubClass: type_metadata_subclass
          validCheck: valid_check
          titleRelation: metadata_title(
            where: {
              type_metadata_title__not_starts_with: "search"  
            }
          ) {
            id
            order: exposure_order
            type: type_metadata_title
            value
            language {
              languageCode: language_code
            }
          }
          rightCompany: metadata_company_relation(
            where: {
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
              type_kind: rightCompany
            }
          ) {
            uuid: id
            typeKind: type_kind
            order: exposure_order
            company {
              id: company_id
              name
              displayName: display_name
            }
          }

          productions: metadata_company_relation(
            orderBy:exposure_order__ASC
            where: {
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
              type_kind: production
            }
          ) {
            uuid: id
            typeKind: type_kind
            order: exposure_order
            production: company {
              id: company_id
              name
              displayName: display_name
            }
          }

          publications: metadata_company_relation(
            orderBy:exposure_order__ASC
            where: {
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
              type_kind: publication
            }
          ) {
            uuid: id
            typeKind: type_kind
            order: exposure_order
            publication: company {
              id: company_id
              name
              displayName: display_name
            }
          }

          artistRelation: metadata_artist_relation(
            first: 99,
            where: { 
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
            }) {
            uuid: id
            order: exposure_order
            artist {
              id: artist_id
              name
            }
          }

          metadataUrl: metadata_url(
            orderBy: [type_url__ASC, exposure_order__ASC]
            where: {
              type_url__in: ["cover", "thumbnail", "head"]
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
            }
          ) {
            uuid: id
            typeUrl: type_url
            url
            validCheck: valid_check
          }

          metadataStructure: metadata_structure(
            where: {
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
            }
          ) {
            structureId: structure_id
    	      structureRootId: structure_root_id
          }
        }
      }
      structures: metadata_structure(
        first: $count,
        skip: $skip,
        orderBy: [depth__ASC, inner_order__ASC],
        group: true,
        where: {
          structure_root_id: $rootId
          ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
          ${
            !searchString
              ? "depth: 1"
              : `
              metadata__some: { type_metadata_class: "record", type_metadata_subclass__in: ["track", "text", "effect"] }
              OR: [
                { metadata__some: { title__contains: "${searchString}" } }
                {
                  metadata__some: {
                    metadata_artist_relation__some: {
                      artist__some: { name__contains: "${searchString}" }
                    }
                  }
                }
              ]
            `
          }
        }) {
        metadata {
          metadataId: metadata_id
          no
          title
          typeClass: type_metadata_class
          typeSubClass: type_metadata_subclass
          validCheck: valid_check

          titleRelation: metadata_title(where: { 
            ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
            type_metadata_title__not_starts_with: "search"
          }) {
            uuid: id
            type: type_metadata_title
            value
          }

          companyRelation: metadata_company_relation(where: {
            type_kind: rightCompany
            ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
          }) {
            type_kind
            company {
              id: company_id
              name
            }
          }

          artistRelation: metadata_artist_relation(where: {
            role__some: { role_id: "343" }
            ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
          }) {
            artist {
              name
            }
          }

          metadataUrl: metadata_url {
            id
            typeUrl: type_url
            url
          }

          metadataStructure: metadata_structure(
            where: {
              ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
            }
          ) {
            structureId: structure_id
    	      structureRootId: structure_root_id
            order: inner_order
          }
        }
        ${
          !searchString
            ? `child(first: 100, orderBy: inner_order__ASC) {
                metadata {
                  metadataId: metadata_id
                  no
                  title
                  typeClass: type_metadata_class
                  typeSubClass: type_metadata_subclass
                  validCheck: valid_check
                  titleRelation: metadata_title(where: { 
                    ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
                    type_metadata_title__not_starts_with: "search"
                  }) {
                    uuid: id
                    type: type_metadata_title
                    value
                  }
                  artistRelation: metadata_artist_relation(first: 99, where: {
                    role__some: { role_id: "343" }
                    ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
                  }) {
                    artist {
                      artistId: artist_id
                      name
                    }
                  }
                  metadataStructure: metadata_structure(
                    where: {
                      ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
                    }
                  ) {
                    structureId: structure_id
                    structureRootId: structure_root_id
                    order: inner_order
                  }
                }
               }
             `
            : ""
        } 
      }
    }
  `;

  const GET_ALBUM_TRACK_COUNT = gql`
    query GET_ALBUM_TRACK_COUNT {
      getCount(
        node: "metadata"
        where: {
          metadata: {
            type_metadata_class: "record"
            type_metadata_subclass: "track"
            ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `valid_check__not: REMOVE` : ""}
            metadata_structure__some: {
              structure_root_id: ${rootId}
            }
          }
        }
      ) {
      count 
      }
    }
  `;

  const fetchAlbumData = useCallback(
    async (rootId: number, count: number, skip: number, list: any): Promise<any> => {
      const { data } = await clients.metadata.query(GET_DEPTH_1_IN_ALBUM, { rootId, count, skip });
      if (data.structures.length === 100) {
        const newList = { ...data, structures: list.structures.concat(data.structures ?? []) };
        return fetchAlbumData(rootId, count, skip + count, newList);
      }
      const result = { ...data, structures: list.structures.concat(data.structures) };
      return result;
    },
    [GET_DEPTH_1_IN_ALBUM]
  );

  useAsyncEffect(
    async isMounted => {
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      try {
        const albumItems = await fetchAlbumData(rootId, 100, 0, { structures: [] });
        const { data: countData } = await clients.metadata.query(GET_ALBUM_TRACK_COUNT);
        if (!isMounted()) {
          return;
        }
        if (isMounted()) {
          let idList: string[] = [];
          albumItems.structures.forEach(({ metadata, child }: StructureProps) => {
            if (metadata.length && metadata[0].typeSubClass === "track" && metadata[0].validCheck !== ValidType.REMOVE) {
              idList.push(metadata[0].metadataId);
            }
            if (child && child.length) {
              child.forEach(({ metadata }) => {
                if (metadata.length && metadata[0].typeSubClass === "track" && metadata[0].validCheck !== ValidType.REMOVE) {
                  idList.push(metadata[0].metadataId);
                }
              });
            }
          });
          dispatch([
            StructureActions.setAlbumStructure(albumItems.structures),
            AlbumDetailActions.setAlbumDetail(albumItems.albumDetail),
            AlbumDetailActions.setAlbumTrackCount(countData.getCount.count),
            AlbumDetailActions.setAlbumCheckListInit(idList)
          ]);

          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    [rootId, searchString, mounted]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightDetailTable />
    </Layout>
  );
};

export const AlbumDetail = () => (
  <>
    <AlbumDetailStoreProvider>
      <Helmet title="앨범 상세" />
      <ChildLayout />
    </AlbumDetailStoreProvider>
  </>
);
