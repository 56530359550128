import { CategoryOfGoods } from "GraphQL/Scalars/CategoryOfGoodsScalar";
import { TypeOfGoods } from "GraphQL/Scalars/TypeOfGoodsScalar";
import { createDuck } from "lib/store/v3";
import { Edge } from "models/Edge";
import { GoodsModel } from "models/GoodsModel";

export type GoodsFilters = {
  category: Set<CategoryOfGoods>;
  type: Set<TypeOfGoods>;
};

const createInitialState = () => ({
  goods: [] as GoodsModel[],
  edge: null as Edge | null,
  filter: {
    category: new Set(),
    type: new Set()
  } as GoodsFilters
});

export const GoodsActions = createDuck({
  namespace: "Goods",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setGoods(state, goodsList: GoodsModel[]) {
      state.goods = goodsList;
    },
    removeGoods(state, key: string) {
      const result = state.goods.filter(item => item.id.key !== key);
      state.goods = result;
    },
    clearFilter(state) {
      state.filter.category = new Set();
      state.filter.type = new Set();
    },
    toggleCategory(state, category: CategoryOfGoods) {
      if (!state.filter.category.has(category)) {
        state.filter.category.add(category);
      } else {
        state.filter.category.delete(category);
      }
    },
    toggleType(state, typeOfGoods: TypeOfGoods) {
      if (!state.filter.type.has(typeOfGoods)) {
        state.filter.type.add(typeOfGoods);
      } else {
        state.filter.type.delete(typeOfGoods);
      }
    }
  }
});
