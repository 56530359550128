import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { GRAY_4, WHITE, GRAY_1, GRAY_2 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, DANGER_COLOR, SUCCESS_COLOR_LIGHT } from "constants/color";
import {
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  MARGING_SMALL_PX,
  MARGING_X_LARGE_PX,
  PADDING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  UNIT_PX,
  BORDER_RADIUS_PX,
  MARGING_LARGE_PX,
  PADDING_SMALL_PX,
  widthViewportize
} from "constants/size";
import { useRightsStore } from "../Store";
import { RightsCreateActions } from "../Store/Rights";
import { CheckDuplicatedRights } from "GraphQL/Queries/Rights/CheckDuplicatedRights";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { SubmitModal } from "../Modals/SubmitModal";
import { CreateRights } from "GraphQL/Queries";

const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 80%;
  height: 85%;
  padding: ${PADDING_XX_LARGE_PX} ${widthViewportize(0.4 * UNIT)};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};

  h2 {
    text-align: left;
    padding: ${PADDING_X_LARGE_PX} 0px;
  }

  .nextButton {
    margin-top: ${MARGING_X_LARGE_PX};
    margin-left: auto;
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & svg {
      position: relative;
      top: 4px;
      width: ${pixelize(1.25 * UNIT)};
      height: ${pixelize(1.25 * UNIT)};
    }

    .tooltip {
      color: ${DANGER_COLOR};
      margin-left: ${MARGING_LARGE_PX};
      font-size: 0.8rem;
    }
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 5)};
  grid-template-columns: 80% 20%;
  grid-gap: ${UNIT_PX};
  align-items: center;

  input {
    width: 100%;
    background-color: ${WHITE};
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }

  button {
    width: calc(100% - ${UNIT_PX});
  }
`;

const RadioGroup = styled.div`
  text-align: left;

  input[type="radio"] {
    width: min-content;
    margin-bottom: ${MARGING_SMALL_PX};
  }

  label {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: normal;

    & + input[type="radio"] {
      margin-left: ${MARGING_X_LARGE_PX};
    }
  }
`;

const CheckInputContainer = styled.div`
  position: relative;
  .checkbtn {
    position: absolute;
    width: ${pixelize(UNIT * 5)};
    right: 1%;
    bottom: 12%;
    z-index: 1;
    background: ${WHITE};
    border-radius: 8px;
    padding: ${PADDING_SMALL_PX};
    border: 1px solid ${GRAY_4};
    transition: all 0.2s;
    &:hover {
      background: ${GRAY_1};
    }
    &:active {
      font-weight: bold;
      background: ${GRAY_2};
      transform: translateY(1.5px);
    }
  }
`;

const AlertSpan = styled.span<{ color: string }>`
  text-align: left;
  font-size: 0.8rem;
  color: ${props => props.color};
`;

const optionList = [
  { id: "Artists Card", name: "Artists Common License" },
  { id: "Original Artist", name: "Original Artist" },
  { id: "Individual Artist (not yet connected by Artists Card)", name: "Individual Artist" },
  { id: "Artists Card Cover License (edited)", name: "Artists Card Cover License" }
];

export const RightsForm = () => {
  const [{ rights }, dispatch] = useRightsStore(store => ({
    rights: store.Rights.rights
  }));
  const [inputName, setInputName] = useState("");
  const [isDisable, setBeDisable] = useState<boolean>(true);
  const submitModal = useToggle();
  const onCreate = useCallback(async () => {
    if (!rights.name || !rights.license) {
      alert("권리사정보가 모두 작성되지 않았습니다.");
      return;
    }
    const name = rights.rightsType === `Cover` ? `cover-${rights.name}` : `${rights.name}`;
    const path = rights.rightsType === "Cover" ? `covers` : rights.rightsType === "Original" ? `original` : `vl`;

    try {
      const { data, errors } = await CreateRights({
        name,
        path,
        license: rights.license,
        type: rights.rightsType,
        displayName: rights.displayName
      });

      if (errors) {
        console.log(errors);
        window.alert("권리사 생성에 실패하였습니다.");
        return;
      }
      if (data) {
        window.alert("권리사가 생성되었습니다.");
        dispatch(RightsCreateActions.clearRights());
        window.location.reload();
        return;
      }
    } catch (err) {
      console.log(err);
      window.alert("네트워크 오류로 권리사 생성에 실패하였습니다.");
      return;
    }
  }, [dispatch, rights.displayName, rights.license, rights.name, rights.rightsType]);

  const checkDuplicatedName = useCallback(async () => {
    if (!rights.name) {
      window.alert("권리사 명을 입력해주세요.");
      return;
    }
    const res = await CheckDuplicatedRights({ name: rights.rightsType !== "Cover" ? rights.name : `cover-${rights.name}` });
    if (res.data) {
      if (!res.data.company.length) {
        window.alert("중복된 권리사 명이 없습니다.");
        setBeDisable(false);
      } else {
        window.alert("중복된 권리사 명이 존재합니다.");
        setBeDisable(true);
      }
    } else {
      window.alert("검사에 실패하였습니다.");
      return;
    }
  }, [rights.name, rights.rightsType]);

  const checkRightsName = (text: string) => {
    return text.match(/^[가-힣a-zA-Z0-9À-ÖØ-öø-ÿ$&A-Яа-я-,(),[,\], 一-龥, [ぁ-ゔ]+|[ァ-ヴー]+[々〆〤]]+$/)?.length;
  };

  return (
    <Layout>
      <h2>권리사 정보 입력</h2>
      <Divider />

      <LabelGroup>
        <label>타입</label>
        <RowGroup>
          <RadioGroup>
            <input
              key="Original"
              type="radio"
              id="typeOriginal"
              name="rightsType"
              value="Original"
              onChange={event =>
                dispatch([
                  RightsCreateActions.setRightsType(event.currentTarget.value),
                  RightsCreateActions.setRightsLicense(optionList[1].id)
                ])
              }
            />
            <label htmlFor="typeOriginal">Original</label>
            <input
              key="Cover"
              type="radio"
              id="typeCover"
              name="rightsType"
              value="Cover"
              onChange={event =>
                dispatch([
                  RightsCreateActions.setRightsType(event.currentTarget.value),
                  RightsCreateActions.setRightsLicense(optionList[2].id)
                ])
              }
            />
            <label htmlFor="typeCover">Cover</label>
            <input
              key="vl"
              type="radio"
              id="typeVL"
              name="rightsType"
              value="vl"
              onChange={event =>
                dispatch([
                  RightsCreateActions.setRightsType(event.currentTarget.value),
                  RightsCreateActions.setRightsLicense(optionList[0].id)
                ])
              }
            />
            <label htmlFor="typeVL">유통사</label>
          </RadioGroup>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>권리사 명</label>
        <RowGroup>
          <CheckInputContainer>
            <Input.Text
              placeholder="권리사 이름을 입력하세요."
              onChange={text => {
                setBeDisable(true);
                setInputName(text);
              }}
              onBlur={value => dispatch(RightsCreateActions.setRightsName(value))}
              isRequired
            />
            <button type="button" className="checkbtn" onClick={checkDuplicatedName}>
              중복 검사
            </button>
          </CheckInputContainer>
        </RowGroup>
        {!inputName ? null : !checkRightsName(inputName) ? (
          <AlertSpan color={DANGER_COLOR}>
            적합하지 않은 권리사명입니다. (한글, 영어, 숫자, 특수문자(, (), &, [])만 입력가능합니다.)
          </AlertSpan>
        ) : (
          <AlertSpan color={SUCCESS_COLOR_LIGHT}>적합한 권리사명입니다.</AlertSpan>
        )}
      </LabelGroup>

      <LabelGroup>
        <label>권리사 표기명</label>
        <RowGroup>
          <CheckInputContainer>
            <Input.Text
              placeholder="권리사 표기명을 입력하세요."
              onBlur={value => dispatch(RightsCreateActions.setRightsDisplayName(value))}
              isRequired
            />
          </CheckInputContainer>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>
          라이센스
          <span className="tooltip">직접 입력하여 작성할 수 있습니다.</span>
        </label>
        <RowGroup>
          <Input.CreatableTextSelect
            name="rightsLicense"
            key={rights.rightsType}
            defaultValue={rights.rightsType === "Original" ? optionList[1] : rights.rightsType === "Cover" ? optionList[2] : optionList[0]}
            placeholder="라이센스를 입력하세요."
            optionList={optionList}
            onBlur={info => {
              if (info && info.id.length) {
                dispatch(RightsCreateActions.setRightsLicense(info.id));
              }
            }}
          />
        </RowGroup>
      </LabelGroup>

      <Input.Button
        disabled={isDisable || !inputName || !checkRightsName(inputName)}
        className="nextButton"
        color="warning"
        onClick={submitModal.on}
      >
        정보 확인
      </Input.Button>
      <Modal isOpen={submitModal.isToggled}>
        <SubmitModal toCreate={onCreate} toCancel={submitModal.off} />
      </Modal>
    </Layout>
  );
};
