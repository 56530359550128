/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { FileActionScalar, FileActionType } from "GraphQL/Scalars/FileActionScalar";

type Option = Readonly<{
  url: string;
  path: string;
  serial: string;
  cover: string;
  action?: FileActionType;
}>;

export const YtvDownload = async (option: Option) => {
  const $url = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.url} />;
  const $path = <Variable name="path" scalar={<StringScalar isNullable={false} />} value={option.path} />;
  const $serial = <Variable name="serial" scalar={<StringScalar isNullable={false} />} value={option.serial} />;
  const $cover = <Variable name="cover" scalar={<StringScalar isNullable={false} />} value={option.cover} />;
  const $action = <Variable name="action" scalar={<FileActionScalar isNullable={false} />} value={option.action} />;
  const { query, variables } = render(
    <Mutation operationName="YTV_DOWNLOAD">
      <Field
        name="ytvDownload"
        alias="ytvDownload"
        argument={{
          where: {
            file: "01",
            cover: $cover,
            url: $url,
            groupPath: $path,
            serial: $serial,
            action: $action
          }
        }}
      />
    </Mutation>
  );
  type Data = Readonly<{
    ytvDownload: boolean;
  }>;
  const req = clients.files.request<Data>(query, variables);
  setTimeout(() => {
    req.cancel();
  }, 3000);

  return req;
};
