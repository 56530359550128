import React, { useState, SetStateAction, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Input } from "App/Atomics/Input";
import { DEFAULT_BORDER_COLOR, DANGER_COLOR_LIGHT, SECONDARY_COLOR, DANGER_COLOR, PRIMARY_COLOR, INFO_COLOR_LIGHT } from "constants/color";
import {
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  BORDER_RADIUS_PX,
  MARGING_X_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  MARGING_LARGE_PX,
  MARGING_XX_LARGE_PX,
  MARGING_SMALL_PX
} from "constants/size";
import { useWorkStore } from "../../Store";
import { WHITE, GRAY_4, GRAY_6, BLACK, GRAY_1, GRAY_2 } from "constants/baseColor";
import { ReactComponent as AlbumIcon } from "assets/icons/cd.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { ArtistModal } from "../../Modals/ArtistModal";
import { WorkCreateActions } from "../../Store/Work";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import { Toggle } from "App/Atomics/Input/Toggle";
import { Confirm } from "App/Molecules/Confirm";
import { CheckDuplicatedWorkTrack, CreateMetadata } from "GraphQL/Queries/Metadata";
import { UrlOptionList } from "constants/UrlOptionList";
import { WorkTextAutoComplete } from "App/Molecules/AutoCompletes/Work";
import { v4 as uuidv4 } from "uuid";
import { useCreateWorkImage } from "../../Hooks/useCreateWorkImage";

export const WorkForm = () => {
  const [{ work }, dispatch] = useWorkStore(store => ({
    work: store.Work.work
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const inputImageRef = useRef<HTMLInputElement>(null);
  const { createImage } = useCreateWorkImage();

  const artistModal = useToggle();
  const confirmModal = useToggle();

  const onError = (err: string, alertMsg: string, loadingAction: SetStateAction<any>) => {
    console.log(err);
    window.alert(alertMsg);
    loadingAction(false);
    return;
  };

  const addCoverUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.currentTarget.files!;
    for (const file of fileList) {
      const fileReader = new FileReader();
      const id = uuidv4();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = e => {
        const url = {
          id,
          file: file,
          data: e.target?.result as string,
          ext: file.type.split("/")[1],
          url: file.name,
          typeUrl: file.type
        };
        dispatch(WorkCreateActions.addCoverUrl(url));
        inputImageRef.current!.value = "";
      };
    }
  };

  const deleteUrl = (id: string) => dispatch(WorkCreateActions.removeCoverUrl(id));

  const onCreate = async () => {
    const {
      title,
      titleEn,
      description,
      descriptionEn,
      subClass,
      artist,
      rightsCompany,
      productions,
      publications,
      lyrics,
      urls,
      subdatas,
      parentId,
      ytvCode
    } = work;
    setLoading(true);
    try {
      if (subClass === "track" && parentId) {
        const isDuplicated = await CheckDuplicatedWorkTrack({ title, rootId: parentId });
        if (isDuplicated) {
          if (!window.confirm("해당 앨범에 중복된 곡이 존재합니다. 그대로 생성하시겠습니까?")) {
            setLoading(false);
            return;
          }
        }
      }

      const { data, errors } = await CreateMetadata({
        title,
        titleEn,
        description,
        descriptionEn,
        class: "work",
        subClass,
        artistRelation: artist,
        rightsCompany,
        productions,
        publications,
        lyrics,
        urls,
        subdatas,
        parentId: subClass === "track" ? parentId : 0,
        ytvCode: subClass === "track" ? ytvCode : undefined
      });
      if (errors && errors.length) {
        onError(errors[0].message, "작품 등록을 실패하였습니다.", setLoading);
      }
      if (data) {
        const workId = data.createMetadata.metadata_id;
        if (work.images.size) {
          await createImage(workId);
        }
        setLoading(false);
        window.alert("작품이 생성되었습니다.");
        window.location.reload();
      }
    } catch (err) {
      onError(err, "작품 등록을 실패하였습니다.", setLoading);
    }
  };

  return (
    <Layout>
      <Title>
        <AlbumIcon />
        <h2>작품 등록</h2>
        <span>*은 필수값입니다. 입력하지않으면 등록할 수 없습니다.</span>
      </Title>
      <CustomDivider />
      <RowGroup>
        <h4>분류*</h4>
        <ToggleGroup>
          <Toggle
            className="toggle"
            color="primary"
            isActive={work.subClass === "album"}
            toggleValue={"대작품"}
            onClick={() => dispatch(WorkCreateActions.setWorkSubClass("album"))}
          />
          <Toggle
            className="toggle"
            color="primary"
            isActive={work.subClass === "track"}
            toggleValue={"소작품"}
            onClick={() => dispatch(WorkCreateActions.setWorkSubClass("track"))}
          />
        </ToggleGroup>
      </RowGroup>
      {work.subClass === "track" && (
        <RowGroup>
          <h4>
            대작품 선택*<span className="info">선택한 대작품의 소작품으로 등록됩니다.</span>
          </h4>
          <WorkTextAutoComplete
            className="autocomplete"
            workClass="album"
            onBlur={(info: any) => {
              if (info) {
                dispatch(WorkCreateActions.setWorkParentId(parseInt(info.extra.structureId)));
              }
            }}
          />
        </RowGroup>
      )}
      <RowGroup>
        <h4>작품 제목*</h4>
        <Input.Text
          isRequired
          placeholder="제목을 입력하세요."
          defaultValue={work.title}
          onBlur={text => dispatch(WorkCreateActions.setWorkTitle(text))}
        />
      </RowGroup>
      <RowGroup>
        <h4>작품 제목(영어)*</h4>
        <Input.Text
          isRequired
          placeholder="영문 제목을 입력하세요."
          defaultValue={work.titleEn}
          onBlur={text => dispatch(WorkCreateActions.setWorkTitleEn(text))}
        />
      </RowGroup>
      <RowGroup>
        <h4>작품 설명*</h4>
        <Multiline placeholder="설명을 입력하세요" onChange={e => dispatch(WorkCreateActions.setWorkDescription(e.target.value))} />
      </RowGroup>
      <RowGroup>
        <h4>작품 설명 (영어)*</h4>
        <Multiline placeholder="영문 설명을 입력하세요" onChange={e => dispatch(WorkCreateActions.setWorkDescriptionEn(e.target.value))} />
      </RowGroup>
      <RowGroup>
        <h4>아티스트*</h4>
        <div
          className="grid-box"
          onClick={e => {
            e.preventDefault();
            artistModal.on();
          }}
        >
          <TagButton style={{ color: work.artist?.length ? BLACK : GRAY_6 }} className="autocomplete">
            {work.artist?.length
              ? work.artist.map((item, index) => {
                  return (
                    <div className="artist" key={index}>
                      <span>{window.decodeURIComponent(item.artist_name)}</span>
                    </div>
                  );
                })
              : "아티스트를 선택해주세요."}
          </TagButton>
          <Input.Button className="search-button" color="default" isWide onClick={artistModal.on}>
            검색
          </Input.Button>
        </div>
      </RowGroup>
      <RowGroup>
        <h4>권리사*</h4>
        <CompanyTextAutoComplete
          className="autocomplete"
          onChange={info => {
            if (info) {
              dispatch(
                WorkCreateActions.setWorkRightsCompany({
                  company_id: info.id,
                  name: info.name
                })
              );
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>
          제작사<span>(production)</span>
        </h4>
        <CompanyTagAutoComplete
          className="autocomplete"
          onChange={info => {
            if (info) {
              const productions = info.map(({ id, name }) => ({ company_id: id, name }));
              dispatch(WorkCreateActions.setWorkProductions(productions));
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>
          배급사<span>(publication)</span>
        </h4>
        <CompanyTagAutoComplete
          className="autocomplete"
          onChange={info => {
            if (info) {
              const publications = info.map(({ id, name }) => ({ company_id: id, name }));
              dispatch(WorkCreateActions.setWorkPublications(publications));
            }
          }}
        />
      </RowGroup>
      {work.subClass === "track" && (
        <RowGroup>
          <h4>유튜브 코드</h4>
          <Input.Text
            isRequired
            placeholder="유튜브 뮤직의 곡명을 그대로 입력해주세요."
            defaultValue={work.ytvCode}
            onBlur={code => dispatch(WorkCreateActions.setWorkYtvCode(code))}
          />
        </RowGroup>
      )}
      {work.subClass === "track" && (
        <RowGroup>
          <h4>
            가사<span>(lyrics)</span>
          </h4>
          <Multiline placeholder="가사를 입력하세요" onChange={e => dispatch(WorkCreateActions.setWorkLyrics(e.target.value))} />
        </RowGroup>
      )}
      {work.subClass === "album" && (
        <RowGroup>
          <h4>이미지</h4>
          <ImageContainer>
            <UploadButton>
              <AddLabel htmlFor="input-file-image">+</AddLabel>
              <HiddenInput
                ref={inputImageRef}
                type="file"
                multiple
                id="input-file-image"
                accept="image/jpeg,image/png,image/webp"
                onChange={event => addCoverUrl(event)}
              />
            </UploadButton>
            {Array.from(work.images).map(([key, value]) => (
              <Image key={key} src={URL.createObjectURL(value.file)} alt="cover" onClick={() => deleteUrl(key)} />
            ))}
          </ImageContainer>
        </RowGroup>
      )}
      <RowGroup>
        <h4>
          URL<span className="info">타입을 직접 입력할 수도 있습니다.</span>
        </h4>
        {!work.urls?.length
          ? null
          : work.urls.map(item => (
              <ExtraInfo key={item.id} column={3}>
                <RoundButton color="danger" onClick={() => dispatch(WorkCreateActions.deleteWorkUrl(item.id))}>
                  -
                </RoundButton>
                <CreatableTextSelect
                  name="createSelect"
                  optionList={UrlOptionList}
                  placeholder="타입"
                  defaultValue={!item.typeUrl ? undefined : { id: item.typeUrl, name: item.typeUrl }}
                  onBlur={info => {
                    if (info) {
                      dispatch(WorkCreateActions.updateWorkUrl({ id: item.id, typeUrl: info.id, url: undefined }));
                    }
                  }}
                />
                <Input.Text
                  placeholder="값"
                  defaultValue={!item.url ? undefined : item.url}
                  onBlur={text => dispatch(WorkCreateActions.updateWorkUrl({ id: item.id, typeUrl: undefined, url: text }))}
                />
              </ExtraInfo>
            ))}
        <RoundButton color="primary" onClick={() => dispatch(WorkCreateActions.createWorkUrl())}>
          +
        </RoundButton>
      </RowGroup>
      <RowGroup>
        <h4>
          SubData<span className="info">날짜의 경우 YYYY-MM-DD 형식으로 입력해주세요.</span>
        </h4>
        {!work.subdatas?.length
          ? null
          : work.subdatas.map(item => (
              <ExtraInfo key={item.id} column={4}>
                <RoundButton color="danger" onClick={() => dispatch(WorkCreateActions.deleteWorkSubdata(item.id))}>
                  -
                </RoundButton>
                <CreatableTextSelect
                  name="createSelect"
                  optionList={[{ id: "etc", name: "etc" }]}
                  placeholder="카테고리"
                  defaultValue={!item.typeSubdataCategory ? undefined : { id: item.typeSubdataCategory, name: item.typeSubdataCategory }}
                  onBlur={info => {
                    if (info) {
                      dispatch(WorkCreateActions.updateWorkSubdata({ id: item.id, category: info.id, field: undefined, value: undefined }));
                    }
                  }}
                />
                <CreatableTextSelect
                  name="createSelect"
                  optionList={[
                    { id: "publish_date", name: "publish_date" },
                    { id: "work_style", name: "work_style" },
                    { id: "note", name: "note" },
                    { id: "imslp_url", name: "imslp_url" },
                    { id: "first_publication", name: "first_publication" },
                    { id: "dedication_text", name: "dedication_text" }
                  ]}
                  placeholder="필드"
                  defaultValue={!item.typeSubdataField ? undefined : { id: item.typeSubdataField, name: item.typeSubdataField }}
                  onBlur={info => {
                    if (info) {
                      dispatch(WorkCreateActions.updateWorkSubdata({ id: item.id, category: undefined, field: info.id, value: undefined }));
                    }
                  }}
                />
                <Input.Text
                  placeholder="값"
                  defaultValue={!item.value ? undefined : item.value}
                  onBlur={text =>
                    dispatch(WorkCreateActions.updateWorkSubdata({ id: item.id, category: undefined, field: undefined, value: text }))
                  }
                />
              </ExtraInfo>
            ))}
        <RoundButton color="primary" onClick={() => dispatch(WorkCreateActions.createWorkSubdata())}>
          +
        </RoundButton>
      </RowGroup>

      <ButtonGroup>
        <Input.Button
          className="btn"
          color="primary"
          disabled={!work.title || !work.artist?.length || !work.rightsCompany || (work.subClass === "track" && !work.parentId)}
          onClick={confirmModal.on}
        >
          등록
        </Input.Button>
      </ButtonGroup>
      <Modal isOpen={artistModal.isToggled}>
        <ArtistModal artistList={work.artist?.length ? work.artist : []} toClose={artistModal.off} />
      </Modal>
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm title="알림" context="작품을 생성하시겠습니까?" toSave={onCreate} toClose={confirmModal.off} />
      </Modal>
      {!loading ? null : (
        <LoadingBox>
          <LoadingIcon />
        </LoadingBox>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  display: inherit;
  width: 1024px;
  flex-direction: column;
  overflow: auto;
  background: ${WHITE};
  padding: ${PADDING_XX_LARGE_PX};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};
  margin-top: 1rem;
  margin-bottom: 1rem;
  h2 {
    text-align: left;
  }

  h4 {
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  .autocomplete {
    width: 100%;
  }

  input {
    width: 100%;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${MARGING_LARGE_PX};

  svg {
    width: ${pixelize(UNIT * 1.5)};
    height: ${pixelize(UNIT * 1.5)};
  }

  span {
    color: ${INFO_COLOR_LIGHT};
  }
`;

const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_X_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${PADDING_LARGE_PX};

  h4 {
    display: flex;
    align-items: center;
    span {
      font-weight: 400;
      font-size: 0.85rem;
      color: ${DANGER_COLOR_LIGHT};
      margin-left: 0.4rem;
    }
    .info {
      color: ${INFO_COLOR_LIGHT};
    }
  }

  input[type="text"],
  input[type="file"],
  input[type="number"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
  input[type="file"] {
    font-size: 0.9rem;
  }
  .genre {
    margin-right: ${MARGING_LARGE_PX};
  }
  .genre,
  .mood {
    margin-top: ${MARGING_SMALL_PX};
    min-width: ${pixelize(12 * UNIT)};
    width: 100%;
  }

  .grid-box {
    width: 100%;
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-gap: ${MARGING_SMALL_PX};
    align-items: center;
  }

  .search-button {
    height: ${pixelize(UNIT * 2.75)};
  }
`;

const TagButton = styled.div`
  width: 100%;
  min-height: 2.8rem;
  display: flex;
  align-items: center;
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  padding: ${PADDING_LARGE_PX};
  overflow-x: scroll;
  background-color: ${WHITE};
  .artist {
    display: flex;
    font-size: 0.9rem;
    border-radius: 8px;
    padding: ${PADDING_LARGE_PX};
    background: ${GRAY_1};
    align-items: center;
    margin-right: 0.5rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  .btn {
    width: 8rem;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `40px 250px auto` : `40px 200px 200px auto`)};
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
  margin-bottom: ${MARGING_SMALL_PX};
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => (props.color === "danger" ? DANGER_COLOR : PRIMARY_COLOR)};
`;

const Multiline = styled.textarea`
  width: 70%;
  height: 12rem;
  font-size: 13px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  line-height: 1.6em;
  &:focus {
    border-color: ${PRIMARY_COLOR};
  }
`;

const ToggleGroup = styled(Input.Group)`
  .toggle {
    margin-right: 0.2rem;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  svg {
    width: ${pixelize(UNIT * 2)};
    height: ${pixelize(UNIT * 2)};
    animation: ${rotate} infinite linear 2s;
  }
`;

const ImageContainer = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 120px);
  grid-gap: 12px;
  align-items: center;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;

const AddLabel = styled.label`
  font-size: 36px;
  color: ${GRAY_2};
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const UploadButton = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  transition: background 0.15s;
  &:hover {
    background: ${GRAY_1};
  }
`;

const HiddenInput = styled.input`
  display: none;
`;
