import { createDuck } from "lib/store/v3";
import { Hash } from "utils/createHash";

export const createProgressActions = () => {
  const createInitialState = () => ({ hash: null as null | string, isVisible: false, percent: 0, hasGuard: false });

  return createDuck({
    namespace: "Progress",
    createInitialState,
    reducers: {
      clear(state, hash: Hash): void {
        if (state.hash === hash) {
          state.hash = null;
          state.isVisible = false;
          state.percent = 0;
        }
      },
      current(state, hash: Hash): void {
        state.hash = hash;
      },
      setGuard(state, hash: Hash, hasGuard: boolean): void {
        if (state.hash === hash) {
          state.hasGuard = hasGuard;
        }
      },
      on(state, hash: Hash): void {
        if (state.hash === hash) {
          state.isVisible = true;
        }
      },
      add(state, hash: Hash, percent: number): void {
        if (state.hash === hash) {
          state.percent += percent;
        }
      },
      moveTo(state, hash: Hash, percent: number): void {
        if (state.hash === hash) {
          state.percent = percent;
        }
      }
    }
  });
};
