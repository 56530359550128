import React, { useState } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_LARGE_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_X_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_X_LARGE,
  MARGING_SMALL_PX,
  PADDING_LARGE,
  PADDING_SMALL_PX
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as CircleRIcon } from "assets/icons/circle-r.svg";
import { ReactComponent as CircleCIcon } from "assets/icons/circle-c.svg";
import { ReactComponent as YtvIcon } from "assets/icons/ytv.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play-button.svg";
import { WHITE, GRAY_4, GRAY_7, BLACK, BLUE_5 } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { PRIMARY_COLOR, DANGER_ALERT_COLOR, SUCCESS_COLOR, DANGER_COLOR, WARNING_COLOR, WARNING_COLOR_LIGHT } from "constants/color";
import { Modal } from "lib/modal";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetTrackLicense, UpdateTrackLicenseRight } from "GraphQL/Queries/Track";
import { useTracksStore } from "../../Store";
import { TrackActions } from "../../Store/Track";
import { useToggle } from "lib/use-toggle";
import { CreateLicenseModal } from "./CreateLicenseModal";
import { UpdateLicenseModal } from "./UpdateLicenseModal";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteTrackLicense } from "GraphQL/Queries/Track";
import { Toast } from "lib/toast";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { CountryCodeInfo } from "constants/CountryCodeInfo";
import { DetailLicenseModal } from "./DetailLicenseModal";
import { EditAllTextModal } from "./EditAllTextModal";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Loading } from "App/Atomics/Loading";
import { ValidType } from "constants/ValidType";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import dayjs from "dayjs";

type Props = Readonly<{
  metadataId: string;
  toClose: () => void;
}>;

const TypeInfo = [
  { id: "PD", name: "PD" },
  { id: "ccl", name: "ccl" },
  { id: "NoCopyright", name: "NoCopyright" },
  { id: "VL", name: "VL" },
  { id: "Cover", name: "Cover" }
];

export const LicenseModal = ({ metadataId, toClose }: Props) => {
  const [{ licenses }, dispatch] = useTracksStore(store => ({
    licenses: store.Track.license
  }));
  const { userRole, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    store: store
  }));
  const [loading, setLoading] = useState<boolean>(true);
  const [typeTrack, setTypeTrack] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string[] | null>([]);
  const [allCopyright, setAllCopyRight] = useState<boolean>(true);
  const [allNeighboring, setAllNeighboring] = useState<boolean>(true);

  const createLicenseModal = useToggle();
  const updateLicenseModal = useToggle();
  const detailLicenseModal = useToggle();
  const editAllTextModal = useToggle();

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          const { data } = await GetTrackLicense({ id: metadataId, typeTrack, countryCode });
          if (data) {
            dispatch(TrackActions.setLicense(data.metadata[0].licenses));
          }
          setLoading(false);
        } catch (e) {
          console.log(e);
          window.alert("Internal Server Error");
        }
      }
    },
    [metadataId, typeTrack, countryCode]
  );

  const openUpdateLicenseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(TrackActions.setLicenseModalId(id));
    updateLicenseModal.on();
  };

  const openDetailLicenseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(TrackActions.setLicenseModalId(id));
    detailLicenseModal.on();
  };

  const deleteLicense = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    if (!window.confirm("해당 라이센스를 삭제하시겠습니까?")) return;
    try {
      const { data } = await CreateAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      if (data) {
        const { data: licenseData } = await DeleteTrackLicense({ id });

        if (licenseData) {
          await DeleteAccessRecord({ id: data.createAccess.id });
          Toast.primary("삭제되었습니다.", undefined, "bottom-right");
          dispatch(TrackActions.deleteLicense(id));
        }
      }
    } catch (e) {
      console.log(e);
      window.alert("Internal Server Error");
    }
  };

  const setFilterReset = () => {
    setTypeTrack(null);
    setCountryCode(null);
  };

  const onToggle = async (toggleKey: string, toggleValue: boolean) => {
    setLoading(true);
    try {
      const licenseList = licenses.map(({ id }) => id);
      const accessId = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        for (const id of licenseList) {
          const { errors } = await UpdateTrackLicenseRight({ id, [toggleKey]: toggleValue });
          if (errors) {
            throw new Error(errors[0].message);
          }
        }
        await DeleteAccessRecord({ id: accessId });
        if (toggleKey === "copyright") {
          setAllCopyRight(toggleValue);
          dispatch(TrackActions.toggleTrackAllCopyright(toggleValue));
        } else {
          setAllNeighboring(toggleValue);
          dispatch(TrackActions.toggleTrackAllNeighboring(toggleValue));
        }
        Toast.primary("업데이트 되었습니다.", undefined, "top-center");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      Toast.error("업데이트에 실패하였습니다.", undefined, "top-center");
      setLoading(false);
      return;
    }
  };

  const toEditAllText = async (text: string | undefined) => {
    setLoading(true);
    try {
      const licenseList = licenses.map(({ id, notice }) => ({ id, notice }));
      const accessId = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        for (const { id, notice } of licenseList) {
          const companyIds = JSON.parse(notice).companyIds;
          const newNotice = JSON.stringify({ text: !text ? "" : text, companyIds });
          const { errors } = await UpdateTrackLicenseRight({ id, notice: newNotice });
          if (errors) {
            throw new Error(errors[0].message);
          }
          dispatch(TrackActions.setTrackNotice({ id, notice: newNotice }));
        }
        await DeleteAccessRecord({ id: accessId });
        Toast.primary("업데이트 되었습니다.", undefined, "top-center");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      Toast.error("업데이트에 실패하였습니다.", undefined, "top-center");
      setLoading(false);
      return;
    }
  };

  const onToggleRadio = (region: string, checked: boolean) => {
    const codes = CountryCodeInfo.filter(({ asia, eu }) => (region === "asia" ? !!asia : !!eu)).map(({ id }) => id);
    checked
      ? setCountryCode(prevCode => (!prevCode ? codes : prevCode.concat(codes)))
      : setCountryCode(prevCode => (!prevCode ? null : prevCode.filter(id => !codes.includes(id))));
  };

  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>라이센스 정보</h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
        <MenuContainer>
          <div style={{ marginBottom: "1.3rem" }}>
            <span>타입 필터</span>
            <Input.TextSelect
              className="select type"
              optionList={TypeInfo}
              style={{
                ...{
                  control: base => ({ ...base, overflowY: "scroll", maxHeight: "3rem" }),
                  indicatorsContainer: base => ({ ...base, position: "sticky", top: 1, height: "3rem" }),
                  valueContainer: base => ({ ...base, paddingRight: "5em" }),
                  menuList: base => ({ ...base, maxHeight: "16em" })
                }
              }}
              value={!typeTrack ? null : { id: typeTrack, name: typeTrack }}
              onChange={info => {
                if (info) {
                  setTypeTrack(info.id);
                }
              }}
            />
          </div>
          <div>
            <span>국가 필터</span>
            <Input.TagSelect
              className="select"
              style={{
                ...{
                  control: base => ({ ...base, overflowY: "scroll", maxHeight: "3rem" }),
                  indicatorsContainer: base => ({ ...base, position: "sticky", top: 1, height: "3rem" }),
                  valueContainer: base => ({ ...base, paddingRight: "5em" }),
                  menuList: base => ({ ...base, maxHeight: "16em" })
                }
              }}
              optionList={CountryCodeInfo}
              value={CountryCodeInfo.filter(({ id }) => countryCode?.includes(id))}
              onChange={info => {
                if (info) {
                  setCountryCode(info.map(({ id }) => id));
                }
              }}
            />
            <RadioBox>
              <input type="checkbox" id="asia" onClick={e => onToggleRadio("asia", e.currentTarget.checked)} />
              <label htmlFor="asia">ASIA</label>
              <input type="checkbox" id="eu" onClick={e => onToggleRadio("eu", e.currentTarget.checked)} />
              <label htmlFor="eu">EUROPE</label>
            </RadioBox>
          </div>
        </MenuContainer>
        <FilterContainer>
          <FilterBox onClick={setFilterReset}>
            <RefreshIcon />
            <span>필터 초기화</span>
          </FilterBox>
        </FilterContainer>
        {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
          <ButtonGroup>
            <Input.Toggle
              color="primary"
              toggleValue="전체 저작권 만료"
              isActive={allCopyright}
              onClick={() => onToggle("copyright", !allCopyright)}
            />
            <Input.Toggle
              color="primary"
              toggleValue="전체 저작인접권 만료"
              isActive={allNeighboring}
              onClick={() => onToggle("neighboring", !allNeighboring)}
            />
            <Input.Button color="primary" isFill={false} onClick={editAllTextModal.on}>
              전체 내용 수정
            </Input.Button>
          </ButtonGroup>
        )}
      </Header>

      {!licenses.length ? (
        <NoData>
          <span>
            데이터가 없습니다.{" "}
            <button className="primary-underline" onClick={setFilterReset}>
              필터를 초기화
            </button>
            하거나{" "}
            <button className="danger-underline" onClick={createLicenseModal.on}>
              추가 버튼
            </button>
            을 눌러주세요.
          </span>
        </NoData>
      ) : (
        licenses.map((license, i) => {
          const {
            id,
            country_code,
            type_track,
            notice,
            copyright,
            neighboring,
            publish_date,
            license_date,
            is_service,
            validCheck
          } = license;
          const CountryName = CountryCodeInfo.find(({ id }) => id === country_code)?.name ?? "-";
          return (
            <DescCard key={i}>
              <div className="titleContainer">
                <div className="titleBox">
                  <span>
                    {type_track} | {CountryName}
                  </span>
                  <EyeIcon fill={is_service === 0 || is_service === 1 ? BLUE_5 : GRAY_4} />
                  <PlayIcon fill={is_service === 0 ? BLUE_5 : GRAY_4} />
                </div>
                <RightBox>
                  <span className="date">{!publish_date ? "0000-00-00" : dayjs(publish_date).format("YYYY년 MM월 DD일 HH:mm:ss")}</span>
                  <CircleCIcon fill={copyright === false ? DANGER_COLOR : copyright === true ? SUCCESS_COLOR : GRAY_4} />
                  <CircleRIcon fill={neighboring === false ? DANGER_COLOR : neighboring === true ? SUCCESS_COLOR : GRAY_4} />
                  <YtvIcon
                    fill={
                      validCheck === ValidType.MODIFY ? DANGER_COLOR : (validCheck === ValidType.DONE) === true ? SUCCESS_COLOR : GRAY_4
                    }
                  />
                </RightBox>
              </div>
              <EplipsisMultiline
                isDisabled
                value={notice ? `${JSON.parse(notice).text}\n(updated: ${license_date ?? "0000-00-00"})` : ""}
              />
              {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
                <ButtonGroup>
                  <button className="detail-btn" onClick={e => openDetailLicenseModal(e, id)}>
                    자세히
                  </button>
                  <button className="edit-btn" onClick={e => openUpdateLicenseModal(e, id)}>
                    수정
                  </button>
                  <button className="delete-btn" onClick={e => deleteLicense(e, id)}>
                    삭제
                  </button>
                </ButtonGroup>
              )}
            </DescCard>
          );
        })
      )}
      <MarginDiv>{"0"}</MarginDiv>
      {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
        <FloatButtonContainer>
          <FloatingButton color="danger" onClick={createLicenseModal.on}>
            +
          </FloatingButton>
        </FloatButtonContainer>
      )}
      <Modal isOpen={createLicenseModal.isToggled}>
        <CreateLicenseModal metadataId={metadataId} toClose={createLicenseModal.off} />
      </Modal>
      <Modal isOpen={updateLicenseModal.isToggled}>
        <UpdateLicenseModal metadataId={metadataId} toClose={updateLicenseModal.off} setLoading={setLoading} />
      </Modal>
      <Modal isOpen={detailLicenseModal.isToggled}>
        <DetailLicenseModal toClose={detailLicenseModal.off} />
      </Modal>
      <Modal isOpen={editAllTextModal.isToggled}>
        <EditAllTextModal toSave={toEditAllText} toClose={editAllTextModal.off} />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 900px;
  height: 80vh;
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  min-height: ${pixelize(UNIT * 17)};
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.25rem;
  }
  .title {
    padding: ${pixelize(PADDING_X_LARGE * 1.4)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35%;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  padding: 0 ${pixelize(PADDING_X_LARGE * 1.4)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;

  & > div > span {
    font-size: 14px;
    font-weight: bold;
  }

  .select {
    width: 100%;
    font-size: 12px;
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: ${PADDING_X_LARGE_PX} ${pixelize(PADDING_X_LARGE * 1.4)};
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    transform: translateY(-1px);
  }
  span {
    font-size: 14px;
    font-weight: bold;
    color: #4c52bc;
  }
`;

const RadioBox = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 4px 4px 0;
  label {
    margin: 0 4px 0 2px;
    font-weight: 400;
    font-size: 0.6rem;
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  margin: ${pixelize(MARGING_X_LARGE * 1.5)} ${pixelize(MARGING_X_LARGE * 1.5)} ${pixelize(MARGING_X_LARGE * 0.5)};
  padding: ${PADDING_X_LARGE_PX};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${pixelize(UNIT * 2)};
    margin-bottom: ${MARGING_SMALL_PX};
    padding-bottom: ${PADDING_SMALL_PX};
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;

    .titleBox {
      font-size: 12px;
      font-weight: 600;
      color: #757575;
      display: flex;
      align-items: center;
      span {
        margin-right: ${MARGING_SMALL_PX};
      }
      svg {
        width: ${pixelize(UNIT * 1)};
        height: ${pixelize(UNIT * 1)};
        margin-left: ${MARGING_SMALL_PX};
      }
    }
  }
`;

const RightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
    margin-left: ${MARGING_SMALL_PX};
  }

  .date {
    color: ${GRAY_7};
    font-size: 0.8rem;
  }
`;

const EplipsisMultiline = styled(Input.Multiline)`
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  height: ${pixelize(UNIT * 10)};
  border: none;
  padding: 0;
  color: #212121;
  font-size: 12px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const FloatButtonContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;

const FloatingButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  border-radius: 50%;
  box-shadow: 0 4px 4px ${GRAY_4};
  margin: ${MARGING_LARGE_PX} ${MARGING_XX_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  font-weight: bold;
  button {
    padding: ${pixelize(PADDING_LARGE * 0.8)} ${PADDING_X_LARGE_PX};
    border-radius: 4px;
    margin-right: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
  .edit-btn {
    background: #4c52bc;
    color: white;

    &:hover {
      background: #6c74dd;
    }
  }

  .delete-btn {
    background: ${WHITE};
    color: #4c52bc;
    border: 1.5px solid #4c52bc;

    &:hover {
      color: #6c74dd;
      border: 1.5px solid #6c74dd;
    }
  }

  .detail-btn {
    background: ${WARNING_COLOR};
    color: white;

    &:hover {
      background: ${WARNING_COLOR_LIGHT};
    }
  }
`;

const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;

    .primary-underline {
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
