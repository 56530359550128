type Key = string;
type Value = string;
type Header = Record<Key, Value>;

let cachedHeader: Header = {};

export const GraphQLClientHeader = {
  get() {
    return cachedHeader;
  },
  getByKey(key: string) {
    return cachedHeader[key];
  },
  set(header: Header) {
    Object.assign(cachedHeader, header);
  },
  setByKey(key: Key, value: Value) {
    cachedHeader[key] = value;
  },
  clear() {
    cachedHeader = {};
  },
  remove(keyList: Key | readonly Key[]) {
    if (!Array.isArray(keyList)) {
      keyList = [keyList as Key];
    }
    for (const key of keyList) {
      // @ts-ignore
      cachedHeader[key] = null;
      delete cachedHeader[key];
    }
  }
};
