/** @jsx jsx */

import { jsx, render, Variable, Query, SelectionSet, StringScalar, Field } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  no: string;
}>;

export const serialValidate = async (option: Option) => {
  const no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const { query, variables } = render(
    <Query operationName="METADATA_SERIAL_VALIDATE">
      <SelectionSet
        name="metadata"
        argument={{
          where: {
            AND: [{ no }, { type_metadata_class: "record", type_metadata_subclass: "album" }]
          }
        }}
      >
        <Field name="metadata_id" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      metadata_id: string;
    }[];
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
