export enum MetadataSelfRelationOrderByInput {
  id_ASC = "id__ASC",
  id_DESC = "id__DESC",
  exposure_order_ASC = "exposure_order__ASC",
  exposure_order_DESC = "exposure_order__DESC",
  type_function_ASC = "type_function__ASC",
  type_function_DESC = "type_function__DESC",
  valid_check_ASC = "valid_check__ASC",
  valid_check_DESC = "valid_check__DESC",
  createdAt_ASC = "createdAt__ASC",
  createdAt_DESC = "createdAt__DESC",
  updatedAt_ASC = "updatedAt__ASC",
  updatedAt_DESC = "updatedAt__DESC"
}
