import { AllowTables } from "../../../utils/clients/UserInfo";
import Time from "dayjs";

import { createActions, createHandler, createReducer } from "lib/store";
import { UserInfo } from "utils/clients/UserInfo";
import { GraphQLClientHeader } from "utils/clients/GraphQLClientHeader";

type State = ReturnType<typeof createInitialState>;

type AccessRecord = Record<string, any>;

const CLEAR = Symbol("User::clear");
const SET_EMAIL = Symbol("User::Email::Set");
const SET_EXPIRES_AT = Symbol("User::ExpiresAt::Set");
const SET_ACCESS_RECORD = Symbol("User::AccessRecord::Set");
const CLEAR_ACCESS_RECORD = Symbol("User::AccessRecord::Clear");
const UPDATE_USER_NAME = Symbol("User::Username::Update");

export const UserTokenActions = createActions({
  signOut() {
    return { type: CLEAR };
  },
  signIn(email: string) {
    return { type: SET_EMAIL, email };
  },
  setExpiresAt(expiresAt: Time.Dayjs) {
    return { type: SET_EXPIRES_AT, expiresAt };
  },
  setAccessRecord(accessRecord: AccessRecord[]) {
    return { type: SET_ACCESS_RECORD, accessRecord };
  },
  clearAccessRecord() {
    return { type: CLEAR_ACCESS_RECORD };
  },
  updateUsername(name: string) {
    return { type: UPDATE_USER_NAME, name };
  }
});

const handler = createHandler<State>({
  [CLEAR](state) {
    state.email = null;
    state.name = null;
    state.role = null;
    state.accessRecord = {};
    state.expiresAt = null;
    UserInfo.clear();
  },
  [SET_EMAIL](state, payload: { email: string }) {
    state.email = payload.email;
    const userInfo = UserInfo.load()!;
    state.role = UserInfo.getRole(userInfo.userToken);
    state.name = userInfo.name;
    state.expiresAt = userInfo.expiresAt;
    state.allowTables = userInfo.allowTables;
  },
  [SET_ACCESS_RECORD](state, payload: { accessRecord: any[] }) {
    state.accessRecord = payload.accessRecord;
  },
  [SET_EXPIRES_AT](state, payload: Omit<ReturnType<typeof UserTokenActions.setExpiresAt>, "type">) {
    state.expiresAt = Time(payload.expiresAt);
  },
  [CLEAR_ACCESS_RECORD](state) {
    state.accessRecord = {};
  },
  [UPDATE_USER_NAME](state, payload: { name: string }) {
    state.name = payload.name;
  }
});

const createInitialState = () => {
  const info = UserInfo.load();
  if (!info) {
    UserInfo.clear();
    return {
      email: null,
      name: null,
      role: null,
      expiresAt: null,
      allowTables: [] as AllowTables,
      accessRecord: {} as AccessRecord
    };
  }
  const { refreshToken, accessToken, userToken, payloadToken, allowTables, email, name, role, expiresAt } = info;
  GraphQLClientHeader.set({
    refresh_user_token: refreshToken,
    access_token: accessToken,
    user_token: userToken,
    payload_token: payloadToken
  });
  return {
    email,
    name,
    role,
    expiresAt,
    allowTables,
    accessRecord: {} as AccessRecord
  };
};

export const reducer = createReducer(handler, createInitialState);
