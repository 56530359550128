import { gql } from "lib/gql-tag";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { clients } from "utils/clients";
import { useTracksStore } from "./Store";
import { TrackActions } from "./Store/Track";
import { useAppStore } from "App/Store";
import { UserRole } from "constants/UserRole";

const subheadDataEvery = (subheadData: Record<string, boolean>) => Object.values(subheadData).every(item => !item);

export const useTrackHeadline = () => {
  const [{ userRole, column }] = useAppStore(store => ({
    accessRecord: store.UserToken.accessRecord,
    userRole: store.UserToken.role,
    column: store.AlbumTrack.column
  }));
  const [{ head, subhead }, dispatch] = useTracksStore(store => ({
    tracks: store.Track.tracks,
    events: store.Track.events,
    head: store.TableInfo.head,
    subhead: store.TableInfo.subhead
  }));
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchString = queryParams.get("q", { default: "" });
  const checkList = queryParams.getAll("ids");
  const strCheckList = JSON.stringify(queryParams.getAll("ids"));
  useAsyncEffect(
    async isMounted => {
      const metadataTitleQuery =
        userRole === UserRole.Arbeit || !head.Title || !subhead.Title.value
          ? ""
          : `
          titles: metadata_title(
            where: {
              type_metadata_title__not_starts_with: "search"  
            }
          ) {
            relationId: id
            language { languageCode: language_code }
            typeMetadataTitle: type_metadata_title
            value
          }
        `;
      const metadataArtistQuery =
        !head.Artist || !subhead.Artist.artist
          ? ""
          : `
          artists: metadata_artist_relation(first: 30) {
            relationId: id
            artist {
              artistId: artist_id
              type: type_artist_class
              name
            }
            role {
              roleId: role_id
              type: type_role_class
              name
            }
          }
          `;
      const metadataUrlQuery =
        !head.Url || subheadDataEvery(subhead.Url)
          ? ""
          : `
        urls: metadata_url {
          relationId: id
          typeUrl: type_url
          url
          fileValidCheck: valid_check
        }
      `;
      const metadataExtraQuery =
        userRole === UserRole.Arbeit || !head.LicenseExtra || subheadDataEvery(subhead.LicenseExtra)
          ? ""
          : `extras: track_license_extra {
            relationId: id
          ${subhead.LicenseExtra.publishYear ? "publishYear: publish_year" : ""}
          ${subhead.LicenseExtra.recordYear ? "recordYear: record_year" : ""}
          ${subhead.LicenseExtra.data ? "data" : ""}
          ${subhead.LicenseExtra.firstEdition ? "firstEdition: first_edition" : ""}
          ${subhead.LicenseExtra.place ? "place" : ""}
        }`;
      const metadataCompanyQuery =
        userRole === UserRole.Arbeit || !head.Company || subheadDataEvery(subhead.Company)
          ? ""
          : `
          companies: metadata_company_relation {
            relationId: id
            ${!subhead.Company.company ? "" : `company { name license }`}
          }
        `;

      const metadataGenreQuery =
        !head.Genre || subheadDataEvery(subhead.Genre)
          ? ""
          : `
        genres: metadata_genre_relation {
          relationId: id
          genre {
            genreId: genre_id
            name
            typeKind: type_kind
          }
        }
      `;
      const metadataInfoQuery =
        !head.Info || subheadDataEvery(subhead.Info)
          ? ""
          : `track_information(
              first: 99
            ) {
              uuid: id
              channel
              duration
              bitrate: sample_bitrate
              tempo
              tonality
              timeSignature: time_signature
            }`;

      const query = gql`
        query METADATA_GET_TRACK_LIST($page: Int) {
          tracks: metadata(
            page: $page,
            group: true,
            first: 200,
            where: {
              AND: [
                { type_metadata_class: "work" }
                { type_metadata_subclass: "track" }
                ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `{ valid_check__not: REMOVE }` : ""}
                ${!checkList.length ? "" : `{ metadata_id__in: ${strCheckList} }`}
                ${
                  !searchString
                    ? ""
                    : column === "제목"
                    ? `{ 
                        OR: [
                          {title__contains: "${searchString}"}
                          {metadata_title__some: { value__contains: "${searchString}" } }
                          {metadata_title__some: { type_metadata_title__contains: "${searchString}" } }
                        ]
                      }`
                    : column === "아이디"
                    ? `{ metadata_id: "${searchString}" }`
                    : `{ metadata_artist_relation__some: { artist__some: { name__contains: "${searchString}" }  } }`
                }
              ]
            },
            orderBy: metadata_id__DESC) {
            ${
              !(head.Metadata && subhead.Metadata)
                ? ""
                : `
                  ${subhead.Metadata.metadataId ? `metadataId: metadata_id` : ``}
                  ${subhead.Metadata.title ? `title` : ``}
                  typeMetadataClass: type_metadata_class
                  ${subhead.Metadata.typeMetadataSubclass ? `typeMetadataSubclass: type_metadata_subclass` : ``}
                  ${subhead.Metadata.validCheck ? `validCheck: valid_check` : ``}
                `
            }
            ${metadataTitleQuery}
            ${metadataArtistQuery}
            ${metadataUrlQuery}
            ${metadataCompanyQuery}
            ${metadataExtraQuery}
            ${metadataGenreQuery}
            ${metadataInfoQuery}
          }
        }
      `;

      const { data } = await clients.metadata.query(query, { page });
      if (isMounted()) {
        dispatch(TrackActions.setTracks(data.tracks.reverse()));
        if (userRole !== UserRole.Arbeit && head.LicenseExtra && !subheadDataEvery(subhead.LicenseExtra)) {
          data.tracks.forEach((track: any, index: number) => {
            if (!track.extras.length) {
              dispatch(TrackActions.setCreateLicenseExtraByIndex({ index, column: "extra" }));
            }
          });
        }
      }
    },
    [head, subhead]
  );

  return { head, subhead, page };
};
