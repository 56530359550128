import immer from "immer";
import { Action } from "./combineReducers";

type DuckOption<S, R> = {
  readonly namespace: string;
  readonly createInitialState: S | ((s: S) => S);
  readonly reducers: R;
};

type Reducer<S> = (state: S, action: Action<any>) => S;

export type Actions<S, R extends Record<string, (state: S, ...payload: readonly any[]) => void>> = {
  readonly [key in keyof R]: R[key] extends (state: S, ...payload: infer P) => void ? (...payload: P) => Action<P> : never;
};

export const createDuck = <S, R extends Record<string, (state: S, ...payload: readonly any[]) => void>>({
  namespace,
  createInitialState,
  reducers
}: DuckOption<S, R>) => {
  const reducer: Reducer<S> = (
    previousState = typeof createInitialState === "function" ? (createInitialState as () => S)() : createInitialState,
    action
  ) => (!map.has(action.type) ? previousState : immer(previousState, draft => void map.get(action.type)!(draft as S, ...action.payload)));
  const map = new Map(
    Object.entries(reducers).map(([key, value]) => {
      const type = `${namespace}.${key}`;
      (reducer as Record<string, any>)[key] = (...payload: readonly any[]) => ({ type, payload, namespace, key });
      return [type, value];
    })
  );
  return reducer as Reducer<S> & Actions<S, R>;
};
