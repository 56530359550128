import { createStore, combineReducers } from "lib/store/v2";
import { SingleAlbumActions as SingleAlbum } from "./SingleAlbum";
import { createStoreLogger } from "utils/createStoreLogger";

const reducers = { SingleAlbum };

const reducer = combineReducers(reducers, createStoreLogger("SingleAlbumCreate"));

export const {
  StoreProvider: SingleAlbumStoreProvider,
  useStore: useSingleAlbumStore,
  useSelector: useSingleAlbumSelector,
  useDispatch: useSingleAlbumDispatch
} = createStore(reducer);
