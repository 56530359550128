import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as ShuffleIcon } from "assets/icons/shuffle.svg";
import { GRAY_6, GRAY_2 } from "constants/baseColor";
import { DANGER_COLOR, PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { Loading } from "App/Atomics/Loading";
import { Confirm } from "App/Molecules/Confirm";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { WorkAlbumTextAutoComplete } from "App/Molecules/AutoCompletes/Work/WorkAlbumTextAutoComplete";
import { UpdateMetadataStructure, UpdateMetadataSubClass } from "GraphQL/Queries/Metadata";
import { useWorkStore } from "../../Store";
import { WorkActions } from "../../Store/Work";

type Props = {
  index: number;
  id: string;
  artist?: string;
  title: string;
  structureId: string;
  toClose: () => void;
};

export const ChangeClassModal = ({ index, id, title, artist, structureId, toClose }: Props) => {
  const [{ targetWorkInfo, works }, dispatch] = useWorkStore(store => store.Work);
  const [currentWorkInfo, setCurrentWorkInfo] = useState<Info>({
    id,
    name: `[${id}] ${title} ${!artist ? "" : `(🎤${artist})`}`,
    extra: structureId
  });
  const [loading, setLoading] = useState<boolean>(false);
  const confirmModal = useToggle();

  const onSave = async (structureId: string, targetWork: Info[]) => {
    setLoading(true);
    try {
      for (const work of targetWork) {
        const accessId = await requestAccessRecord({ targetId: work.id, targetTable: TargetTableInput.Metadata });
        if (accessId) {
          const { errors: structureErr } = await UpdateMetadataStructure({
            structureId: work.extra,
            parentId: parseInt(structureId, 10),
            bookingOrder: 0
          });
          if (structureErr) {
            throw new Error(structureErr[0].message);
          }
          const { errors: subClassErr } = await UpdateMetadataSubClass({ metadataId: work.id, subClass: "track" });
          if (subClassErr) {
            throw new Error(subClassErr[0].message);
          }
          await DeleteAccessRecord({ id: accessId });
        }
      }
      const filteredWorkInfo = works.filter(({ metadataId }) => !targetWork.map(info => info.id).includes(metadataId));
      dispatch(WorkActions.setWork(filteredWorkInfo));
      Toast.primary("변경되었습니다", undefined, "top-center");
      closeModal();
    } catch (err) {
      console.log(err);
      Toast.error("작품 변경에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    dispatch(WorkActions.clearTargetWorkInfo());
    toClose();
  };

  return (
    <Layout>
      <Header>
        <ShuffleIcon className="shuffle" />
        <h3>작품 클래스 이동</h3>
        <CancelIcon className="cancel" onClick={closeModal} />
      </Header>
      <Section>
        <div className="wrapper">
          <div className="info">
            <h3>대상 대작품</h3>
          </div>
          <WorkAlbumTextAutoComplete
            defaultValue={currentWorkInfo}
            onChange={info => {
              if (info) {
                setCurrentWorkInfo(info);
              }
            }}
          />
        </div>
      </Section>
      <Section>
        <div className="wrapper">
          <div className="info">
            <h3>위 작품으로 이동할 대작품 </h3>
            <span>{"(자동으로 소작품으로 변경됨)"}</span>
          </div>
          {!targetWorkInfo.length
            ? null
            : targetWorkInfo.map((item, i) => {
                return (
                  <div key={`${item.id}-${i}`} className="row-group">
                    <RoundButton color="danger" onClick={() => dispatch(WorkActions.deleteTargetWorkInfo(i))}>
                      -
                    </RoundButton>
                    <WorkAlbumTextAutoComplete
                      className="autocomplete"
                      placeholder="작품을 검색해주세요 ('작품명'+'아티스트'로 검색가능합니다.)"
                      defaultValue={!item.id ? undefined : item}
                      onChange={info => {
                        if (info) {
                          const updateInfo = { id: info.id, name: info.name, extra: info.extra };
                          dispatch(WorkActions.updateTargetWorkInfo({ infoIndex: i, info: updateInfo }));
                        }
                      }}
                    />
                  </div>
                );
              })}
          <RoundButton color="primary" onClick={() => dispatch(WorkActions.createTargetWorkInfo())}>
            +
          </RoundButton>
        </div>
      </Section>
      <SectionInfo>
        <span>주의 : 현재 변경하려는 대작품의 소작품이 반드시 없어야합니다.</span>
      </SectionInfo>
      <ButtonGroup>
        <Input.Button className="btn cancel" onClick={closeModal}>
          취소
        </Input.Button>
        <Input.Button className="btn save" color="primary" disabled={!targetWorkInfo.length} onClick={confirmModal.on}>
          변경
        </Input.Button>
      </ButtonGroup>
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm
          title={"알림"}
          context={"작품을 변경하시겠습니까?"}
          toClose={confirmModal.off}
          toSave={() => onSave(currentWorkInfo.extra, targetWorkInfo)}
        />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 1000px;
  height: 550px;
  overflow: scroll;
  background-color: #fff;
  font-size: 0.8rem;
  border-radius: 8px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.8em;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .shuffle {
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.4em;
    fill: ${PRIMARY_COLOR};
  }

  .cancel {
    position: absolute;
    right: 3%;
    width: 2em;
    height: 2em;
    padding: 0.5em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1em 2em;

  .wrapper {
    margin: 1rem 0 2rem;
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      span {
        margin-left: 0.2rem;
        color: ${GRAY_6};
      }
    }
  }
  .row-group {
    display: grid;
    grid-template-columns: 3rem auto;
    margin-bottom: 0.2rem;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 9rem;
  justify-content: center;
  border-bottom: 1px solid ${GRAY_2};
  h4 {
    font-size: 1rem;
    margin-bottom: 0.2em;
  }
  .info {
    color: ${GRAY_6};
    margin-bottom: 1em;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  padding: 2em;
  .btn {
    width: 10em;
    height: 3em;
  }
  .cancel {
    margin-right: 0.2em;
  }
`;

const SectionInfo = styled.section`
  margin: 1.5em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 1.5em;
  span {
    color: ${DANGER_COLOR};
  }
`;

const RoundButton = styled(Input.Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
