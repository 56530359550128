/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, render, Variable, Query, Field, StringScalar, SelectionSet } from "graphql-jsx";

type Option = Readonly<{
  name: string;
}>;

export const CheckDuplicatedRights = async (option: Option) => {
  const name = <Variable name="name" scalar={<StringScalar />} value={option.name} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_RIGHTS">
      <SelectionSet name="company" argument={{ where: { name } }}>
        <Field name="company_id" alias="companyId" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    company: ReadonlyArray<{
      companyId: string;
    }>;
  }>;
  return await clients.company.request<Data>(query, variables);
};
