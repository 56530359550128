/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IntScalar, IdScalar } from "graphql-jsx";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  id: string;
  playlistId?: string;
  validCheck?: ValidType;
  order?: number;
}>;

export const UpdateMetadataPlaylistRelation = async (option: Option) => {
  const $id = <Variable name="uuid" scalar={<UuidScalar />} value={option.id} />;
  const $playlistId = <Variable name="id" scalar={<IdScalar />} value={option.playlistId} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const $order = <Variable name="order" scalar={<IntScalar isNullable />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_PLAYLIST_RELATION">
      <SelectionSet
        name="updateMetadata_playlist_relation"
        alias="updateMetadataPlaylistRelation"
        argument={{
          where: {
            id: $id
          },
          data: {
            playlist: {
              connect: {
                playlist_id: $playlistId
              }
            },
            valid_check: $validCheck,
            booking_order: $order
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataPlaylistRelation: {
      id: string;
    };
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
