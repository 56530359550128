import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Artist, Url, Structure, GetMetadata, Company } from "GraphQL/Queries/Metadata/GetMetadata";
import { configs } from "configs";
import { useToggle } from "lib/use-toggle";
import { ReactComponent as TitleIcon } from "assets/icons/title.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/photo.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as YtvIcon } from "assets/icons/ytv.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as PlayIcon } from "assets/icons/circle-play.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import defaultImage from "assets/images/cover.png";
import { mediaQuery } from "constants/media";
import { Modal } from "lib/modal";
import { ImageViewModal, EditInfoModal, TitleModal, YtvModal, PublishDateModal } from "../../../Modal";
import { ALBUM_DETAIL, ARTISTS } from "constants/route";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, DANGER_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { Confirm } from "App/Molecules/Confirm";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";
import { DeleteMetadataUrl, GetMetadataAudioDataByAlbum } from "GraphQL/Queries/Metadata";
import { useAlbumDispatch } from "App/Routes/AlbumRenewal/Store";
import { AlbumActions } from "App/Routes/AlbumRenewal/Store/Album";
import { AddTrackModal } from "App/Routes/AlbumRenewal/Modal/AddTrackModal";
import { useAppStore } from "App/Store";
import { UserRole } from "constants/UserRole";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { GRAY_2 } from "constants/baseColor";
import { AudioPlayerActions } from "App/Store/AudioPlayer";
import { Loading } from "App/Atomics/Loading";

type Props = {
  index: number;
  metadataId: string;
  no: string | null;
  title: string;
  metadataUrl: Url[];
  artistRelation: Artist[];
  validCheck: ValidType;
  structures: Structure[];
  rightsCompany: Company[];
};
export const CardItem = ({ index, metadataId, no, title, metadataUrl, artistRelation, rightsCompany, validCheck, structures }: Props) => {
  const [{ store, userRole }, dispatchApp] = useAppStore(store => ({
    store: store,
    userRole: store.UserToken.role
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAlbumDispatch();
  const router = useHistory();
  const imageViewModal = useToggle();
  const editInfoModal = useToggle();
  const confirmModal = useToggle();
  const titleModal = useToggle();
  const ytvModal = useToggle();
  const addTrackModal = useToggle();
  const publishDateModal = useToggle();

  const imageUrl = metadataUrl?.filter(({ typeUrl }) => typeUrl === "head" || typeUrl === "thumbnail" || typeUrl === "cover") ?? [];
  const url =
    imageUrl && imageUrl.length
      ? `${configs.urls.image}/${imageUrl[0].url}${imageUrl[0].url!.includes("?") ? `&` : `?`}mode=m`
      : defaultImage;

  const onPlayMusicInAlbum = async () => {
    try {
      setLoading(true);
      const audioData = await GetMetadataAudioDataByAlbum({
        rootId: parseInt(structures[0].structureId, 10)
      });
      if (!audioData.length) {
        Toast.warning("재생할 음원이 없습니다.");
        return;
      }
      dispatchApp([
        AudioPlayerActions.toggleVisible(true),
        AudioPlayerActions.addMultiAudioInPlaylist(audioData),
        AudioPlayerActions.setAudioData(audioData[0]),
        AudioPlayerActions.setAudioPlay("PLAY")
      ]);
    } catch (err) {
      console.log(err);
      Toast.error("재생목록을 불러올 수 없습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };

  // [Order] 1. Remove Music File -> 2. Remove Track -> 3. Remove Album Images -> 4. Remove Album
  const onDelete = async (id: string) => {
    try {
      dispatch(AlbumActions.setEditLoading(true));
      const { data } = await GetMetadata({ metadataId: id, typeClassIn: ["record", "theater"], typeSubClass: "album", detail: true });
      if (data?.metadata[0]?.structures.length) {
        const tracks = data.metadata[0].structures[0].child;
        for (const track of tracks) {
          const { metadataId, metadataUrl, rightsCompany: trackCompany } = track.metadata[0];
          const accessId = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
          if (accessId) {
            const urls = !metadataUrl.length
              ? []
              : metadataUrl.filter(
                  ({ typeUrl }) =>
                    typeUrl === "mp3high" ||
                    typeUrl === "aac" ||
                    typeUrl === "flac" ||
                    typeUrl === "wav" ||
                    typeUrl === "txt" ||
                    typeUrl === "zip"
                );
            if (urls.length) {
              for (const url of urls) {
                const { errors: fileErr } = await DeleteFile({
                  companyId: trackCompany[0].company[0].id,
                  filename: url.url,
                  fileType: FileType.FILE,
                  book: BookType.immediate
                });
                if (fileErr) {
                  if (fileErr[0].message.includes("not exist file")) {
                    Toast.warning("URL 파일이 없습니다. 삭제는 그대로 진행됩니다.");
                  } else {
                    throw fileErr[0].message;
                  }
                }
              }
            }

            const { errors: trackErr } = await DeleteMetadata({ id: metadataId });
            if (trackErr) {
              await DeleteAccessRecord({ id: accessId });
              throw trackErr[0].message;
            }
            await DeleteAccessRecord({ id: accessId });
          }
        }
      }
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        for (const image of imageUrl) {
          await DeleteFile({
            filename: image.url,
            companyId: rightsCompany[0].company[0].id,
            fileType: FileType.FILE,
            book: BookType.immediate
          });
          await DeleteMetadataUrl({ uuid: image.uuid });
        }
        const { errors } = await DeleteMetadata({ id });
        if (errors) {
          await DeleteAccessRecord({ id: accessId });
          throw errors[0].message;
        }
        await DeleteAccessRecord({ id: accessId });
        dispatch(AlbumActions.setEditLoading(false));
        Toast.primary("삭제되었습니다", undefined, "top-center");
        dispatch(AlbumActions.deleteAlbum(index));
      }
    } catch (err) {
      console.log(err);
      dispatch(AlbumActions.setEditLoading(false));
      Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
      return;
    }
  };

  return (
    <Layout key={metadataId}>
      <ImageContainter>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="link"
          onClick={() => {
            if (!structures.length) {
              Toast.error("트랙 정보를 가져올 수 없습니다.", undefined, "top-center");
              return;
            }
            router.push(`${ALBUM_DETAIL}?id=${structures[0].structureId}`);
          }}
        >
          <img key={`${metadataId}-${url}`} src={url} alt={`${title}-cover`} />
        </a>
        <MusicPlayButton className="play-btn" onClick={e => e.stopPropagation()}>
          <PlayIcon className="play" onClick={onPlayMusicInAlbum} />
        </MusicPlayButton>

        <Badge vc={validCheck}>{validCheck}</Badge>
        {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
          <div className="svg-wrapper" onClick={e => e.stopPropagation()}>
            {userRole === UserRole.Master && <DeleteIcon className="delete" onClick={confirmModal.on} />}
            <YtvIcon className="ytv" onClick={ytvModal.on} />
            <TitleIcon className="title" onClick={titleModal.on} />
            <DocumentIcon className="document" onClick={editInfoModal.on} />
            <PhotoIcon className="photo" onClick={imageViewModal.on} />
            <PlusIcon className="plus" onClick={addTrackModal.on} />
            <CalendarIcon className="calandar" onClick={publishDateModal.on} />
          </div>
        )}
      </ImageContainter>
      <Info>
        <IdNoWrapper>
          <ClipBoard text={metadataId}>
            <span className="id">{metadataId}</span>
          </ClipBoard>
          <ClipBoard text={no ?? ""}>
            <span className="no">{`${no ?? ""}`}</span>
          </ClipBoard>
        </IdNoWrapper>
        <ClipBoard text={title}>
          <span className="title">{title}</span>
        </ClipBoard>

        <div>
          {!artistRelation.length ? (
            <span className="artist">{"-"}</span>
          ) : (
            <>
              <span className="artist" onClick={() => router.push(`${ARTISTS}?page=1&q=${artistRelation[0].artist[0].id}&type=아이디`)}>
                {`${artistRelation[0].artist[0].name}`}
              </span>
              <span className="extra">{`${artistRelation.length > 2 ? ` 외 ${artistRelation.length - 1}명` : ""}`}</span>
            </>
          )}
        </div>
      </Info>
      <Modal isOpen={imageViewModal.isToggled} onClose={imageViewModal.off}>
        <ImageViewModal index={index} id={metadataId} metadataUrl={imageUrl} toClose={imageViewModal.off} />
      </Modal>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        <EditInfoModal index={index} toClose={editInfoModal.off} />
      </Modal>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm
          title={"경고"}
          context={"앨범에 포함된 트랙도 함께 삭제됩니다.\n정말로 삭제하시겠습니까?"}
          toSave={() => onDelete(metadataId)}
          toClose={confirmModal.off}
        />
      </Modal>
      <Modal isOpen={titleModal.isToggled} onClose={titleModal.off}>
        <TitleModal id={metadataId} toClose={titleModal.off} />
      </Modal>
      <Modal isOpen={ytvModal.isToggled} onClose={ytvModal.off}>
        <YtvModal metadataId={metadataId} toClose={ytvModal.off} />
      </Modal>
      <Modal isOpen={addTrackModal.isToggled} onClose={addTrackModal.off}>
        <AddTrackModal index={index} toClose={addTrackModal.off} />
      </Modal>
      <Modal isOpen={publishDateModal.isToggled} onClose={publishDateModal.off}>
        <PublishDateModal index={index} onClose={publishDateModal.off} />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;

  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  cursor: pointer;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #eee;
    }
  }

  a.link::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6em;
    content: "";
    z-index: 1;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    transition: opacity 0.2s ease-in;
  }
  .play-btn,
  .play {
    opacity: 0;
  }

  &:hover {
    a.link::after {
      opacity: 1;
    }

    .wrapper,
    .delete,
    .photo,
    .document,
    .title,
    .ytv,
    .plus,
    .play-btn,
    .play,
    .calandar {
      opacity: 1;
    }
  }
  .svg-wrapper {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 0;
    right: 0;
    z-index: 2;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }
  }
  .delete,
  .photo,
  .document,
  .title,
  .ytv,
  .plus,
  .calandar {
    position: absolute;
    bottom: 1.2em;
    z-index: 3;
    opacity: 0;
    fill: #eee;
    width: 1.2em;
    height: 1.2em;
    transition: all 0.15s;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }

    &:hover {
      fill: #fff;
      transform: scale(1.3, 1.3);
    }
  }

  .delete {
    left: 1.2em;
    &:hover {
      fill: #f00;
    }
  }

  .photo {
    right: 1.2em;
  }
  .document {
    right: 3.5em;
  }
  .title {
    right: 5.8em;
  }
  .ytv {
    right: 8.1em;
  }
  .plus {
    right: 10.4em;
  }
  .calandar {
    right: 1.2em;
    bottom: 3.2em;
  }
  ${mediaQuery(500)} {
    .ytv,
    .plus {
      display: none;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 9em;
  max-height: 8em;

  span {
    white-space: pre-wrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .id,
  .no {
    font-size: 0.8em;
    color: #aaa;
  }
  .title {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .artist {
    color: #666;
    cursor: pointer;
    font-size: 0.9em;
    margin-right: 0.2rem;
  }
  .extra {
    font-size: 0.9em;
    color: #777;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  }
`;

const IdNoWrapper = styled.div`
  display: flex;
  align-items: center;
  .id {
    margin-right: 0.3rem;
  }
  .no {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.8;
    width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    ${mediaQuery(1200)} {
      width: 8rem;
    }

    ${mediaQuery(1024)} {
      width: 6rem;
    }

    ${mediaQuery(768)} {
      width: 6rem;
    }
  }
`;

const Badge = styled.div<{ vc: ValidType }>`
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 3%;
  color: ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR : "#eee")};
  text-shadow: 0 0 2px ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR_LIGHT : PRIMARY_COLOR)};
`;

const MusicPlayButton = styled.div`
  position: absolute;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  top: 3%;
  left: 3%;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  transition: opacity 0.1s ease-in;
  .play {
    width: 2rem;
    height: 2rem;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_2};
    }
  }
`;
