import React, { ChangeEvent } from "react";
import styled from "styled-components";
import {
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  MARGING_X_LARGE_PX,
  PADDING_SMALL_PX
} from "constants/size";
import { GRAY_4, GRAY_6, GRAY_5 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, SECONDARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { Dropzone } from "App/Routes/AlbumRenewal/Modal/AddTrackModal/Dropzone";
import { useAlbumStore } from "App/Routes/AlbumRenewal/Store";
import { SingleTrackActions } from "App/Routes/AlbumRenewal/Store/SingleTrack";
import { Page } from "App/Routes/AlbumRenewal/Store/SingleTrack/index";

export const MusicUploadForm = () => {
  const [{ singleTrack }, dispatch] = useAlbumStore(store => ({
    singleTrack: store.SingleTrack.singleTrack
  }));

  const setSingleTrackFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = _ => {
      dispatch(SingleTrackActions.setSingleTrackData({ file: file.files![0], fileType, name: file.files![0].name }));
    };
  };

  const toNextPage = () => {
    dispatch(SingleTrackActions.setPage(Page.SECOND));
  };

  return (
    <Layout>
      <Title>
        <h2>음원 파일을 업로드해주세요!</h2>
      </Title>
      <CustomDivider />
      <MainForm>
        <RowGroup>
          <h4>MP3</h4>
          <Input.Text
            isRequired
            placeholder="선택된 MP3 파일 없음. (MP3 / AAC / FLAC / WAV 파일 중 1개의 파일만 업로드하셔도 가능합니다.)"
            value={!singleTrack.trackUrl.trackMp3.url ? "" : singleTrack.trackUrl.trackMp3.url}
          />
        </RowGroup>
        <RowGroup>
          <h4>AAC</h4>
          <Input.Text
            isRequired
            placeholder="선택된 AAC 파일 없음. (MP3 / AAC / FLAC / WAV 파일 중 1개의 파일만 업로드하셔도 가능합니다.)"
            value={!singleTrack.trackUrl.trackAac.url ? "" : singleTrack.trackUrl.trackAac.url}
          />
        </RowGroup>
        <RowGroup>
          <h4>FLAC</h4>
          <Input.Text
            isRequired
            placeholder="선택된 FLAC 파일 없음. (MP3 / AAC / FLAC / WAV 파일 중 1개의 파일만 업로드하셔도 가능합니다.)"
            value={!singleTrack.trackUrl.trackFlac.url ? "" : singleTrack.trackUrl.trackFlac.url}
          />
        </RowGroup>
        <RowGroup>
          <h4>WAV</h4>
          <Input.Text
            isRequired
            placeholder="선택된 WAV 파일 없음. (MP3 / AAC / FLAC / WAV 파일 중 1개의 파일만 업로드하셔도 가능합니다.)"
            value={!singleTrack.trackUrl.trackWav.url ? "" : singleTrack.trackUrl.trackWav.url}
          />
        </RowGroup>
        <RowGroup>
          <FileGroup>
            <DropContainer>
              <Dropzone
                accept="audio/mpeg"
                type="mp3"
                fileType="mp3high"
                isUploaded={!!singleTrack.trackUrl.trackMp3.url}
                onChange={e => setSingleTrackFile(e, "mp3high")}
              />
              <p>
                • 또는 여기에 <span>MP3</span> 파일을 끌어놓으세요.
              </p>
            </DropContainer>
            <DropContainer>
              <Dropzone
                accept="audio/x-m4a"
                type="aac"
                fileType="aac"
                isUploaded={!!singleTrack.trackUrl.trackAac.url}
                onChange={e => setSingleTrackFile(e, "aac")}
              />
              <p>
                • 또는 여기에 <span>AAC</span> 파일을 끌어놓으세요.
              </p>
            </DropContainer>
            <DropContainer>
              <Dropzone
                accept="audio/flac"
                type="flac"
                fileType="flac"
                isUploaded={!!singleTrack.trackUrl.trackFlac.url}
                onChange={e => setSingleTrackFile(e, "flac")}
              />
              <p>
                • 또는 여기에 <span>FLAC</span> 파일을 끌어놓으세요.
              </p>
            </DropContainer>
            <DropContainer>
              <Dropzone
                accept="audio/wav"
                type="wav"
                fileType="wav"
                isUploaded={!!singleTrack.trackUrl.trackWav.url}
                onChange={e => setSingleTrackFile(e, "wav")}
              />
              <p>
                • 또는 여기에 <span>WAV</span> 파일을 끌어놓으세요.
              </p>
            </DropContainer>
          </FileGroup>
        </RowGroup>
        <RowGroup>
          <ButtonGroup>
            <Input.Button
              disabled={
                !singleTrack.trackUrl.trackMp3.url &&
                !singleTrack.trackUrl.trackAac.url &&
                !singleTrack.trackUrl.trackFlac.url &&
                !singleTrack.trackUrl.trackWav.url
              }
              color="danger"
              onClick={toNextPage}
            >
              다음 (앨범 정보 입력)
            </Input.Button>
          </ButtonGroup>
        </RowGroup>
      </MainForm>
    </Layout>
  );
};

const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  background: #fff;
  height: 100%;
  padding: ${PADDING_XX_LARGE_PX};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};
  overflow: auto;
  h2 {
    text-align: left;
    padding-top: ${PADDING_SMALL_PX};
  }

  h4 {
    color: #757575;
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${PADDING_LARGE_PX};

  .input-box {
    display: grid;
    grid-template-columns: 8fr 1fr;
    grid-gap: 5px;
  }
  input[type="text"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    font-size: 0.9em;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
  input[type="file"] {
    font-size: 0.9rem;
  }
`;

const FileGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${pixelize(UNIT * 16)};
  justify-content: space-around;
  align-items: center;
`;

const MainForm = styled.div`
  margin: 2rem;
  margin-bottom: ${MARGING_XX_LARGE_PX};
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;

const DropContainer = styled.div`
  width: ${pixelize(UNIT * 9.375)};
  p {
    margin-top: ${MARGING_X_LARGE_PX};
    display: inline-block;
    font-size: 0.8rem;
    color: ${GRAY_4};
    line-height: 1.5;
    white-space: pre-wrap;
    text-align: left;
    span {
      text-decoration: underline ${GRAY_5};
    }
  }
`;
