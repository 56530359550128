import React from "react";
import { Input } from "App/Atomics/Input";
import { identity } from "utils/identity";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";

type Props = Omit<Parameters<typeof Input.TextSelect>[0], "optionList" | "onChange" | "onBlur"> &
  Readonly<{
    placeholder?: string;
    onChange?: (validType: null | Info) => void;
    onBlur?: (validType: null | Info) => void;
  }>;

export const LanguageTextSelect = ({
  align,
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  placeholder = "언어를 선택해주세요",
  onChange = identity,
  onBlur = identity
}: Props) => {
  const change = (info: null | Info) => onChange(info);
  const blur = (info: null | Info) => onBlur(info);
  return (
    <Input.TextSelect
      align={align}
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      optionList={LanguageCodeInfo.map(({ id, name }) => ({ id, name: `${name} (${id})` }))}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      onChange={change}
      onBlur={blur}
    />
  );
};
