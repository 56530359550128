import React, { ReactNode, forwardRef, MouseEvent } from "react";
import styled from "styled-components";
import { GRAY_5, GRAY_2 } from "constants/baseColor";

type Props = Styleable &
  Readonly<{
    children: ReactNode;
    onClick?: (event: MouseEvent) => void;
  }>;

const Layout = styled.tr`
  margin-top: 1ch;

  & + tr {
    border-top: 1px solid ${GRAY_5};
  }

  &:hover {
    background-color: ${GRAY_2};
  }
`;

export const Row = forwardRef<HTMLTableRowElement, Props>(({ children, style, className, onClick }, ref) => (
  <Layout ref={ref} style={style} className={className} onClick={onClick}>
    {children}
  </Layout>
));
