import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { DeleteMetadataUrl } from "GraphQL/Queries/Metadata";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { BookType } from "GraphQL/Scalars/BookType";
import { FileType } from "GraphQL/Scalars/FileType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { GetMetadataUrl } from "./GetMetadataUrl";

export const useDeleteAlbum = () => {
  const deleteAlbum = async (id: string) => {
    const { urls, companyId } = await GetMetadataUrl({ id });
    const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
    try {
      for (const item of urls) {
        await DeleteFile({ filename: item.url, companyId: companyId, fileType: FileType.FILE, book: BookType.immediate });
        await DeleteMetadataUrl({ uuid: item.id });
      }
      await DeleteMetadata({ id });
    } catch (err) {
      console.log(err);
      Toast.error("이전 앨범 삭제에 실패하였습니다.");
    } finally {
      await DeleteAccessRecord({ id: accessId! });
    }
  };
  return { deleteAlbum };
};
