import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_X_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { UpdateAlbum } from "GraphQL/Queries";
import { MetadataRightType } from "constants/MetadataRightType";
import { useAlbumDetailStore } from "../../Store";
import { AlbumDetailActions } from "../../Store/AlbumDetail";

type Props = Readonly<{
  type: string;
  metadataId: string;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 32.5)};
  height: ${pixelize(UNIT * 24)};
  padding: ${PADDING_XX_LARGE_PX};

  section {
    h5 {
      margin-bottom: ${MARGING_LARGE_PX};
    }
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${MARGING_X_LARGE_PX};

  h3 {
    font-size: 1.2rem;
  }
`;

export const AddCompanyModal = ({ type, metadataId, toClose }: Props) => {
  const companyType = type === "production" ? "제작사" : "배급사";
  const [{ productions, publications }, dispatch] = useAlbumDetailStore(store => store.AlbumDetail.albumDetail[0].album[0]);
  const [company, setCompany] = useState({
    id: "",
    name: ""
  });

  const handleButton = async () => {
    if (!company.id.length) {
      window.alert(`${companyType}를 먼저 선택해주세요.`);
      return;
    }
    try {
      const typeKind: MetadataRightType = type === "production" ? MetadataRightType.production : MetadataRightType.publication;
      const order =
        type === "production"
          ? productions.length
            ? productions[productions.length - 1].order + 1
            : 0
          : publications.length
          ? publications[publications.length - 1].order + 1
          : 0;
      const { data, errors } = await UpdateAlbum({ metadataId, companyId: company.id, typeKind, order });
      if (errors) {
        window.alert(`중복된 ${companyType}가 있습니다. 다시 확인해주세요.`);
        return;
      }
      if (data) {
        dispatch(
          AlbumDetailActions.createAlbumCompany({
            typeKind: type,
            uuid: data.updateAlbum.metadata_company_relation[0].uuid,
            id: company.id,
            name: company.name,
            order
          })
        );
        toClose();
      }
    } catch (err) {
      console.log(err);
      window.alert("저장에 실패하였습니다.");
      return;
    }
  };

  return (
    <Layout>
      <Title>
        <h3>{`추가할 ${companyType}를 선택해주세요.`}</h3>
      </Title>
      <section>
        <h5>{companyType}</h5>
        <CompanyTextAutoComplete
          onBlur={info => {
            if (info) {
              setCompany({
                id: info.id,
                name: info.name
              });
            }
          }}
        />
      </section>
      <ButtonGroup>
        <Input.Button color="success" onClick={() => handleButton()}>
          추가
        </Input.Button>
        <Input.Button color="danger" onClick={toClose}>
          취소
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
