/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar, IdScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
  name?: string;
  displayName?: string;
  validCheck?: ValidType;
}>;

export const UpdateRights = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $displayName = <Variable name="displayName" scalar={<StringScalar isNullable />} value={option.displayName} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_RIGHTS">
      <SelectionSet
        name="updateCompany"
        argument={{
          where: {
            company_id: $id
          },
          data: {
            name: $name,
            display_name: $displayName,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="company_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateRights: {
      id: string;
    };
  }>;

  return await clients.company.request<Data>(query, variables);
};
