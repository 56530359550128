import React, { ComponentType } from "react";
import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findArtistListExceptNaxos } from "./findArtistListExceptNaxos";
import styled from "styled-components";
import { GRAY_5, GRAY_8, BLACK, PINK_1, PINK_0 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";
import { components, OptionProps, Styles } from "react-select";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  "style" | "className" | "classNamePrefix" | "isDisabled" | "defaultValue" | "onChange" | "onBlur" | "autoFocus"
> & { searchType?: string };

export const ArtistAutoCompleteByNameStartExceptNaxos = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  autoFocus,
  searchType
}: Props) => (
  <TextAutoComplete
    placeholder="아티스트를 입력해주세요."
    isDisabled={isDisabled}
    defaultValue={defaultValue}
    delay={0}
    style={{ ...styles, ...style }}
    className={className}
    classNamePrefix={classNamePrefix}
    autoFocus={autoFocus}
    onChange={onChange}
    onBlur={onBlur}
    loadInfoList={keyword => findArtistListExceptNaxos(keyword, searchType)}
    components={{ Option }}
  />
);

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="id">{props.data.id}</span>
        <span className="name">{props.data.name}</span>
        <span className="extra">{props.data.type}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .id {
    font-size: 7px;
    color: ${GRAY_5};
  }
  .name {
    font-size: 13px;
    font-weight: 500;
  }
  .extra {
    font-size: 10px;
    font-weight: 400;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;

const styles: Styles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "#ddd",
    borderColor: state.isFocused ? GRAY_8 : "#cdcdcd",
    ":hover": {
      borderColor: GRAY_8
    }
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    color: isSelected && BLACK,
    backgroundColor: (isSelected || isFocused) && PINK_1,
    ":hover": {
      backgroundColor: PINK_0
    }
  })
};
