/** @jsx jsx  */

import { ValidType } from "constants/ValidType";
import { EnumScalar, Field, IntScalar, jsx, ListScalar, Query, render, SelectionSet, StringScalar, Variable } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  keyword: string;
  isParent?: boolean;
}>;

export const findMoodList = async ({ keyword, isParent }: Option) => {
  const $isParent = <Variable name="isParent" scalar={<IntScalar />} value={isParent && +isParent} />;
  const $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="MOOD_SEARCH_LIST">
      <SelectionSet
        name="genre_title"
        alias="list"
        argument={{
          first: 20,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Genre_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            genre__some: {
              weight: $isParent,
              type_kind: "mood",
              valid_check__not: $remove
            },
            tsvec_words__search: $keyword,
            type_genre_title__starts_with: "name"
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <SelectionSet name="genre">
          <Field name="genre_id" alias="id" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="name" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: ReadonlyArray<{
      id: string;
      genre: {
        id: string;
        type: ValidType;
        name: string;
      }[];
    }>;
  }>;
  const { data, errors } = await clients.genre.request<Data>(query, variables);
  if (!data || errors) {
    return [];
  }
  const result = data.list.map(({ genre }) => ({ ...genre[0] }));
  return result;
};
