import { LoadGoodsDetail } from "GraphQL/Queries/Goods";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { GoodsDetailStore } from "../Store";
import { GoodsDetailActions, GoodsParent, TypeInfo } from "../Store/GoodsDetail";

type Props = {
  readonly id?: string;
};

export const useLoadGoodsDetail = ({ id }: Props) => {
  useAsyncEffect(async () => {
    const { data, errors } = await LoadGoodsDetail({
      id
    });
    if (errors || !data) {
      console.log(errors);
      Toast.error("컨텐츠를 불러올 수 없습니다.");
      return;
    }
    const {
      id: goodsId,
      name,
      goodsTitle,
      weight,
      askPrice,
      officialPrice,
      officialBarcode,
      askQuantity,
      createdAt,
      metadata_goods_relation: goodsMetadataRelation,
      goodsUrl,
      goodsType,
      goodsCompanyRelation,
      goodsArtist,
      goodsSubdata,
      goodsStructure,
      isCollection
    } = data.goods[0];
    const type: TypeInfo = { id: goodsType.id.toString(), name: goodsType.displayType, extra: goodsType.category };
    const coverUrls = goodsUrl.filter(({ typeUrl }) => typeUrl === "cover");
    const detailUrls = goodsUrl.filter(({ typeUrl }) => typeUrl === "detail");
    const videoUrls = goodsUrl.filter(({ typeUrl }) => typeUrl === "video");
    const officialUrls = goodsUrl.filter(({ typeUrl }) => typeUrl === "official");
    const youtubeUrls = goodsUrl.filter(({ typeUrl }) => typeUrl === "youtube");
    const parents: GoodsParent[] = goodsStructure
      .filter(({ parent }) => !!parent && !!parent[0]?.goods?.length)
      .map(({ id, order, parent }) => {
        const { id: goodsId, name: goodsName } = parent[0]?.goods[0];
        return {
          id,
          order,
          goods: {
            id: goodsId,
            name: goodsName
          }
        };
      });

    GoodsDetailStore.dispatch(
      GoodsDetailActions.setGoods({
        id: goodsId,
        name,
        titles: goodsTitle,
        type,
        weight,
        askPrice,
        officialPrice,
        askQuantity,
        createdAt,
        artists: goodsArtist,
        works: goodsMetadataRelation,
        company: goodsCompanyRelation[0],
        coverUrls,
        detailUrls,
        videoUrls,
        officialUrls,
        youtubeUrls,
        officialBarcode: officialBarcode ?? undefined,
        subdatas: goodsSubdata,
        parents,
        isCollection
      })
    );
  }, [id]);
};
