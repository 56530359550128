import React, { useMemo } from "react";
import styled from "styled-components";

import { TableTemplate } from "App/Templates/TableTemplate";
import { HEADER_HEIGHT_PX, pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { ArtistEditActions } from "./Store/Artist";
import { useArtistsStore, ArtistsStoreProvider } from "./Store";
import { useHistory } from "react-router";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { DataTemplate } from "./ArtistItem";
import { commitToEditingLog } from "./commitToEditingLog";
import { Head, Subhead } from "./Store/ArtistInfo/sanitizeHeads";
import { UserRole } from "constants/UserRole";
import itiriri from "itiriri";
import { subheadKeyValue } from "./subheadKeyValue";
import { useArtistHeadline } from "./useArtistHeadline";
import { useAppStore } from "App/Store";
import { ReactComponent as BackButton } from "assets/icons/back.svg";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import { LoadingTemplate } from "App/Templates/LoadingTemplate";
import { useQueryParams } from "lib/use-query-params";
import { CreateMultiAccessRecord, DeleteMultiAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;
  text-align: center;

  width: 100%;
  height: 100vh;
  font-size: 0.9rem;
  table {
    margin-top: ${HEADER_HEIGHT_PX};
  }

  input[type="text"] {
    padding: ${pixelize(1 * UNIT)} ${pixelize(2 * UNIT)};
    border: 1px solid ${DEFAULT_BORDER_COLOR};

    &:hover {
      border: 1px solid ${PRIMARY_COLOR_LIGHT};
    }

    &:focus {
      border: 1px solid ${PRIMARY_COLOR};
    }
  }
`;

const RemoveButton = styled.div`
  width: min-content;
  margin-left: auto;
`;

const transformToTableHeadline = (head: Head, subhead: Subhead, userRole: UserRole | null) => {
  const subheadList = [] as string[];
  const headList = itiriri(Object.entries(head))
    .filter(([key, isToggledKey]) => {
      return isToggledKey;
    })
    .toArray(([key]) => {
      const localSubheadList = itiriri(Object.entries(subhead[key as keyof typeof subhead]))
        .filter(([subKey, isToggledSubKey]) => {
          const subKeyValidate = subKey === "thumbnail" || subKey === "typeUrl";

          if (subKeyValidate) {
            return false;
          }

          return isToggledSubKey;
        })
        .toArray(([subKey]) => subheadKeyValue[key][subKey]);
      subheadList.push(...localSubheadList);
      return {
        name: key,
        colSpan: localSubheadList.length
      };
    });
  return { headList, subheadList };
};

export const ChildLayout = () => {
  const router = useHistory();
  const [{ userRole, loading }] = useAppStore(store => ({ userRole: store.UserToken.role, loading: store.Loading.loading }));
  const [{ artists, events }, artistDispatch] = useArtistsStore(store => store.Artist);

  const { head, subhead, openTableFilterModal } = useArtistHeadline();
  const createTableHeadline = () => transformToTableHeadline(head, subhead, userRole);
  const { headList, subheadList } = useMemo(createTableHeadline, [head, subhead]);
  const queryParams = useQueryParams();
  const checkList = queryParams.getAll("ids");

  const updateArtists = async () => {
    try {
      const { data: accessData } = await CreateMultiAccessRecord({ targetIdList: checkList, targetTable: TargetTableInput.Artist });
      const deleteIdList = Object.values(accessData!).map(({ id }) => id);
      await commitToEditingLog(artists, events);
      await DeleteMultiAccessRecord({ idList: deleteIdList });
      artistDispatch(ArtistEditActions.setEventClear());
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const cancelToEdit = async () => {
    router.goBack();
  };

  return (
    <Layout>
      <TopbarTemplate>
        <Input.Button onClick={cancelToEdit}>
          <BackButton />
        </Input.Button>
        <Input.Button onClick={openTableFilterModal}>필터</Input.Button>
        <RemoveButton>
          <Input.Button color="danger" onClick={updateArtists}>
            저장
          </Input.Button>
        </RemoveButton>
      </TopbarTemplate>
      <TableTemplate headList={headList} subHeadList={subheadList} keyBy={data => data.artistId} dataList={artists} Column={DataTemplate} />
      <LoadingTemplate loading={loading} />
    </Layout>
  );
};

export const ArtistEdit = () => (
  <ArtistsStoreProvider>
    <Helmet title="아티스트 편집" />
    <ChildLayout />
  </ArtistsStoreProvider>
);
