import { GoodsType } from "models/GoodsModel";
import { createDuck } from "lib/store/v3";

type Info = {
  id: string;
  name: string;
};

export type GoodsParent = {
  id: string;
  name: string;
  parentId: number;
};

export type GoodsSubdata = {
  id: string;
  field: string;
  value: string;
  category: string;
};

export type Url = {
  id: string;
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

export type TypeInfo = {
  id: string;
  name: string;
  extra?: string;
};

export enum Section {
  First,
  Second,
  Third
}

export type Goods = {
  name: string;
  globalName: string;
  subtitle: string;
  details: string;
  typeInfo: TypeInfo;
  tags: string;
  weight?: number;
  createdAt: string;
  artists: Info[];
  company: Info;
  work: Info;
  coverUrls: Map<string, Url>;
  detailUrls: Map<string, Url>;
  videoUrls: Map<string, Url>;
  officialUrl?: string;
  officialBarcode?: string;
  askPrice: number;
  officialPrice: number;
  subdatas: GoodsSubdata[];
  parent: GoodsParent[];
};

const DEFAULT_OPTION = [
  { id: "subdata-onesize-1", category: "option", field: "option", value: "ONE SIZE" },
  { id: "subdata-quantity-1", category: "quantity", field: "quantity", value: "1" },
  { id: "subdata-weight-1", category: "weight", field: "weight", value: "" }
];

const createInitialState = () => ({
  section: Section.First,
  goodsTypes: [] as GoodsType[],
  goods: {
    askPrice: 0,
    officialPrice: 0,
    coverUrls: new Map(),
    detailUrls: new Map(),
    videoUrls: new Map(),
    subdatas: DEFAULT_OPTION as GoodsSubdata[],
    parent: [] as GoodsParent[]
  } as Goods
});

export const GoodsCreateActions = createDuck({
  namespace: "GoodsCreate",
  createInitialState,
  reducers: {
    clear(state) {
      state = createInitialState();
    },
    setSection(state, section: Section) {
      state.section = section;
    },
    setGoodsTypes(state, goodsTypes: GoodsType[]) {
      state.goodsTypes = goodsTypes;
    },
    setName(state, value: string) {
      state.goods.name = value;
    },
    setDetail(state, value: string) {
      state.goods.details = value;
    },
    setTags(state, tags: string) {
      state.goods.tags = tags;
    },
    setGlobalName(state, value: string) {
      state.goods.globalName = value;
    },
    setSubtitle(state, value: string) {
      state.goods.subtitle = value;
    },
    setTypeInfo(state, typeInfo: TypeInfo) {
      state.goods.typeInfo = typeInfo;
    },
    setWeight(state, value: number) {
      state.goods.weight = value;
    },
    setAskPrice(state, value: number) {
      state.goods.askPrice = value;
    },
    setOfficialPrice(state, value: number) {
      state.goods.officialPrice = value;
    },
    setArtists(state, info: Info[]) {
      state.goods.artists = info;
    },
    setCompany(state, company: Info) {
      state.goods.company = company;
    },
    setWork(state, work: Info) {
      state.goods.work = work;
    },
    setCreatedAt(state, date: string) {
      state.goods.createdAt = date;
    },
    addCoverUrl(state, url: Url) {
      state.goods.coverUrls.set(url.id, url);
    },
    removeCoverUrl(state, id: string) {
      state.goods.coverUrls.delete(id);
    },
    addDetailUrl(state, url: Url) {
      state.goods.detailUrls.set(url.id, url);
    },
    addVideoUrl(state, url: Url) {
      state.goods.videoUrls.set(url.id, url);
    },
    removeDetailUrl(state, id: string) {
      state.goods.detailUrls.delete(id);
    },
    removeVideoUrl(state, id: string) {
      state.goods.videoUrls.delete(id);
    },
    sortCoverUrl(state) {
      state.goods.coverUrls = new Map(
        Array.from(state.goods.coverUrls).sort(([, prev], [, next]) => {
          return +prev.file.name > +next.file.name ? 1 : -1;
        })
      );
    },
    sortDetailUrl(state) {
      state.goods.detailUrls = new Map(
        Array.from(state.goods.detailUrls).sort(([, prev], [, next]) => {
          return +prev.file.name > +next.file.name ? 1 : -1;
        })
      );
    },
    sortVideoUrl(state) {
      state.goods.videoUrls = new Map(
        Array.from(state.goods.videoUrls).sort(([, prev], [, next]) => {
          return +prev.file.name > +next.file.name ? 1 : -1;
        })
      );
    },
    addSubdata(state, id: string) {
      state.goods.subdatas.push({ id, category: "option", field: "option" } as GoodsSubdata);
    },
    addSubdataList(state, list: GoodsSubdata[]) {
      state.goods.subdatas = [...state.goods.subdatas, ...list];
    },
    updateSubdataCategory(state, { index, category }: { index: number; category: string }) {
      state.goods.subdatas[index].category = category;
    },
    updateSubdataField(state, { index, field }: { index: number; field: string }) {
      state.goods.subdatas[index].field = field;
    },
    updateSubdataValue(state, { index, value }: { index: number; value: string }) {
      state.goods.subdatas[index].value = value;
    },
    removeSubdataField(state, index: number) {
      state.goods.subdatas.splice(index, 1);
    },
    clearSubdatas(state) {
      state.goods.subdatas = [];
    },
    setOfficialUrl(state, url: string) {
      state.goods.officialUrl = url;
    },
    setOfficialBarcode(state, barcode: string) {
      state.goods.officialBarcode = barcode;
    },
    addParent(state) {
      state.goods.parent.push({} as GoodsParent);
    },
    updateParent(state, { index, parent }: { index: number; parent: GoodsParent }) {
      state.goods.parent[index] = parent;
    },
    removeParent(state, index: number) {
      state.goods.parent.splice(index, 1);
    }
  }
});
