/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  id: string;
  coverUrls?: string[];
  detailUrls?: string[];
  videoUrls?: string[];
}>;

export const CreateGoodsUrl = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_GOODS_URL">
      <SelectionSet
        name="updateGoods"
        alias="createGoodsUrl"
        argument={{
          where: {
            goods_id: $id
          },
          data: {
            _onlySearch: true,
            goods_url: {
              create: [
                ...(option.coverUrls?.map((url, order) => ({ type_url: "cover", url, exposure_order: order })) ?? []),
                ...(option.detailUrls?.map((url, order) => ({ type_url: "detail", url, exposure_order: order })) ?? []),
                ...(option.videoUrls?.map((url, order) => ({ type_url: "video", url, exposure_order: order })) ?? [])
              ]
            }
          }
        }}
      >
        <Field name="goods_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createGoodsUrl: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
