import styled from "styled-components";
import React from "react";
import { PADDING_XX_LARGE_PX, pixelize, UNIT } from "constants/size";
import { CsvUploadForm } from "./CsvUploadForm";
import { usePlaylistAutoCreateSelector } from "../Store";
import { CreateTrackForm } from "./CreateTrackForm";

const Layout = styled.div`
  width: ${pixelize(UNIT * 37.5)};
  height: 100vh;
  padding: ${PADDING_XX_LARGE_PX};
  overflow-y: scroll;
`;

export const Right = () => {
  const { csvData } = usePlaylistAutoCreateSelector(store => store.CsvUpload);
  return (
    <Layout>
      {!csvData.length && <CsvUploadForm />}
      {!!csvData.length && <CreateTrackForm />}
    </Layout>
  );
};
