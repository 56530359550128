import axios from "axios";
import { DeleteAccessRecord, UploadFile } from "GraphQL/Queries";
import { createFormData } from "lib/createFormData";
import { UpdateMetadataUrl } from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

export const handleCreateFile = async (targetId: string, uuid: string, url: Url, companyId: string, contentType: string) => {
  try {
    const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Metadata });
    if (accessId) {
      try {
        const fileUrl = url.url;
        const { errors: uploadErr, data: uploadData } = await UploadFile({ filename: fileUrl, companyId });
        if (uploadErr) {
          throw uploadErr;
        }
        if (uploadData) {
          const formData = uploadData.uploadFile;
          await axios({
            method: "post",
            url: formData.url,
            data: createFormData(formData, url!.file),
            headers: {
              "Content-Type": contentType
            }
          });
          const { errors: updateErr } = await UpdateMetadataUrl({ uuid, url: formData.key });
          if (updateErr) {
            throw updateErr;
          }
        }
      } catch (err) {
        throw err;
      } finally {
        await DeleteAccessRecord({ id: accessId });
      }
    }
  } catch (err) {
    throw err;
  }
};
