import React, { useState } from "react";
import styled from "styled-components";

import { Input } from "App/Atomics/Input";
import { ServiceTypeTextSelect } from "App/Molecules/Selects/ServiceType";
import { DEFAULT_BORDER_COLOR, MODAL_BACKGROUND, PRIMARY_COLOR } from "constants/color";
import { usePlaylistCreateStore } from "../../Store";
import { createPlaylist } from "./createPlaylist";
import { PADDING_X_LARGE_PX, MARGING_LARGE_PX, MARGING_X_LARGE_PX, PADDING_LARGE_PX } from "constants/size";
import { useHistory } from "react-router";
import { PLAYLIST_DETAIL } from "constants/route";
import { PlaylistCreateActions } from "../../Store/TrackList";
import { ServiceType } from "constants/ServiceType";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { PlaylistModal } from "../../Modals";
import { Loading } from "App/Atomics/Loading";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${PADDING_X_LARGE_PX};
  font-size: 0.9rem;

  button {
    &:hover {
      background-color: ${MODAL_BACKGROUND};
    }
  }

  .targetService__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .targetService__menu {
    .targetService__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & > svg {
      position: relative;
      top: 4px;
    }
  }

  & > input {
    border: 1px solid ${DEFAULT_BORDER_COLOR};
  }
`;

const ButtonField = styled.div`
  margin-top: ${MARGING_LARGE_PX};
  margin-left: auto;

  & > span {
    font-size: 1.1rem;
    font-weight: bold;
  }

  & > button {
    display: inline;
    margin-left: ${MARGING_X_LARGE_PX};
  }
`;

export const GrantForm = () => {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [{ trackList, serviceType, kind }, dispatch] = usePlaylistCreateStore(store => ({
    trackList: store.TrackList.selectedTrackList,
    serviceType: store.TrackList.serviceType,
    kind: store.TrackList.kind
  }));
  const playlistModal = useToggle();

  const startToCreatePlaylist = async () => {
    if (!serviceType || !kind || !title) {
      window.alert("타겟 서비스, 플레이리스트 분류, 플레이리스트 제목 중 빈 입력이 있습니다");
      return;
    }
    if (!trackList.length) {
      window.alert("플레이리스트에 넣을 트랙이 없습니다");
      return;
    }
    setLoading(true);
    console.log(serviceType, kind, title);
    await createPlaylist({
      serviceType,
      kind,
      title,
      metadataIdList: trackList.map(track => track.id),
      moveTo(playlistId) {
        history.push(`${PLAYLIST_DETAIL}?id=${playlistId}`);
      }
    });
  };

  const trackCount = trackList.length;

  return (
    <Layout>
      <LabelGroup>
        <label>타겟 서비스</label>
        <ServiceTypeTextSelect
          classNamePrefix="targetService"
          placeholder="클래식매니저, 뮤직포샵 등"
          defaultValue={!serviceType ? undefined : ("클래식매니저" as ServiceType)}
          onBlur={service => dispatch(PlaylistCreateActions.setSercieType(service))}
        />
      </LabelGroup>

      <LabelGroup>
        <label>플레이리스트 분류</label>
        <Input.Text placeholder="주스, fassker, neocomix 등" onBlur={k => dispatch(PlaylistCreateActions.setKind(k))} />
      </LabelGroup>

      <LabelGroup>
        <label>플레이리스트 제목</label>
        <Input.Text placeholder="아주아주 멋있는 제목" onBlur={setTitle} />
      </LabelGroup>

      <ButtonField>
        <span>총 {trackCount}개 트랙</span>
        <Input.Button color="default" disabled={isDisabled} onClick={playlistModal.on}>
          기존 플레이리스트에 등록
        </Input.Button>
        <Input.Button
          color="default"
          disabled={isDisabled}
          onClick={() => {
            setIsDisabled(prev => !prev);
            startToCreatePlaylist().finally(() => {
              setIsDisabled(prev => !prev);
              setLoading(false);
            });
          }}
        >
          새 플레이리스트 생성
        </Input.Button>
        <Modal isOpen={playlistModal.isToggled} onClose={playlistModal.off}>
          <PlaylistModal toClose={playlistModal.off} />
        </Modal>
      </ButtonField>
      <Loading loading={loading} />
    </Layout>
  );
};
