import React, { MouseEvent, useCallback } from "react";
import styled from "styled-components";
import { ColumnProps } from "App/Templates/TableTemplate";
import { useHistory } from "react-router";
import { Table } from "App/Atomics/Table";
import { PLAYLIST_DETAIL } from "constants/route";
import { Button } from "App/Atomics/Input/Button";
import { usePlaylistsStore } from "../../Store";
import { PlaylistsActions } from "../../Store/Playlist";
import { Input } from "App/Atomics/Input";
import { MARGING_SMALL_PX, pixelize, UNIT } from "constants/size";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { Toast } from "lib/toast";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { configs } from "configs";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { Playlist } from "GraphQL/Queries/Playlist/GetPlaylist";
import { DeletePlaylist, UpdatePlaylistUsersRelation } from "GraphQL/Queries/Playlist";
import { DANGER_COLOR, PRIMARY_COLOR_LIGHT, SUCCESS_COLOR } from "constants/color";
import { ImageViewModal } from "../Modals/ImageViewModal";
import { ExtraInfoModal } from "../Modals/ExtraInfoModal";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { EditModal } from "../Modals/EditModal";
import { ValidType } from "constants/ValidType";
import { TitleModal } from "../Modals/TitleModal";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { ReactComponent as CheckIcon } from "assets/icons/circle-check.svg";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { ColumnModal } from "../Modals/ColumnModal";
import { EditInfoModal } from "../Modals/EditInfoModal";
import { BLUE_3, BLACK, GRAY_5, GRAY_4, BLUE_6, BLUE_5 } from "constants/baseColor";
import { FloatingButton } from "App/Atomics/FloatingButton";
import { ReactComponent as ExcelIcon } from "assets/icons/excel.svg";
import { SelectCsvTypeModal } from "../Modals/SelectCsvTypeModal";
import { allowPlaylistCreate } from "App/Routes/AdminRoutes/allowTables";

type Props = ColumnProps<Playlist> & {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const ValidCheckKorean = [
  { id: ValidType.V1, name: "V1" },
  { id: ValidType.V2, name: "V2" },
  { id: ValidType.V3, name: "미승인" },
  { id: ValidType.VC, name: "VC" },
  { id: ValidType.MODIFY, name: "변경" },
  { id: ValidType.DONE, name: "승인" }
] as { id: ValidType; name: string }[];

export const DataTemplate = ({ index, data, setLoading }: Props) => {
  const router = useHistory();
  const { userRole, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    store
  }));
  const [{ checkList, partnerValidCheck }, dispatch] = usePlaylistsStore(store => ({
    checkList: store.Playlist.checkList,
    partnerValidCheck: store.Playlist.playlists[index]?.userRelation.filter(({ owner }) => !owner)[0]?.validCheck ?? ValidType.V3
  }));
  const imageViewModal = useToggle();
  const commentModal = useToggle();
  const editModal = useToggle();
  const titleModal = useToggle();
  const columnModal = useToggle();
  const editInfoModal = useToggle();
  const selectCsvTypeModal = useToggle();
  const { id, name, kind, service, price, validCheck, playlistUrl, userRelation, count = 0 } = data;

  const allowEditPlaylist = userRole === UserRole.Master || (userRole === UserRole.Arbeit && allowPlaylistCreate(store));

  const toDetailPage = useCallback(() => {
    router.push(PLAYLIST_DETAIL + `?id=${id}`);
  }, [id, router]);

  const onDelete = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (window.confirm("정말로 삭제하시겠습니까?")) {
        try {
          setLoading(true);
          const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
          if (accessData) {
            const { errors } = await DeletePlaylist({ id });
            if (errors) {
              window.alert("플레이리스트 삭제에 실패하였습니다.");
              return;
            }
            const accessId = accessData.createAccess.id;
            if (accessId) {
              await DeleteAccessRecord({ id: accessId });
            }
            dispatch(PlaylistsActions.deletePlaylistById(id));
            Toast.primary("삭제되었습니다.", undefined, "bottom-right");
          }
        } catch (e) {
          console.log(e);
          alert("서버에서 에러가 발생했습니다.");
          return;
        } finally {
          setLoading(false);
        }
      }
    },
    [dispatch, id, setLoading]
  );

  const convertNumberToMoney = useCallback((money: number) => {
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const onToggleCheck = (checked: boolean, id: string) => {
    dispatch(PlaylistsActions.setPlaylistCheckList({ id, checked }));
  };

  const onChangeUserVC = useCallback(
    async (vc: ValidType) => {
      if (userRole === UserRole.Master || userRole === UserRole.Arbeit) {
        Toast.warning("관리자가 변경할 수 없습니다.");
        return;
      }
      const prevValidCheck = partnerValidCheck;
      if (prevValidCheck === vc) {
        return;
      }
      try {
        dispatch(PlaylistsActions.updatePlaylistUserValidCheck({ index, validCheck: vc }));
        const accessId = await requestAccessRecord({ targetTable: TargetTableInput.Users });
        if (accessId) {
          try {
            await UpdatePlaylistUsersRelation({ uuid: userRelation[0].uuid, validCheck: vc });
          } catch (err) {
            dispatch(PlaylistsActions.updatePlaylistUserValidCheck({ index, validCheck: prevValidCheck }));
            Toast.error("변경할 수 없습니다.");
            console.log(err);
          } finally {
            await DeleteAccessRecord({ id: accessId });
          }
        }
      } catch (err) {
        Toast.error("권한 요청에 실패하였습니다.");
        console.log(err);
      }
    },
    [dispatch, index, partnerValidCheck, userRelation, userRole]
  );

  return (
    <>
      <Layout onClick={toDetailPage}>
        <Table.Data style={{ width: "5%" }}>
          <div onClick={e => e.stopPropagation()}>
            <AnimatedCheckbox
              isDisabled={userRole === UserRole.Partner && validCheck !== ValidType.DONE}
              id={id}
              isChecked={checkList.get(id)}
              onToggle={checked => onToggleCheck(checked, id)}
            />
          </div>
        </Table.Data>
        {id && (
          <AlignCenterData style={{ width: "5%" }}>
            <ClipBoard text={id} />
          </AlignCenterData>
        )}
        {kind && (
          <Table.Data style={{ width: "10%" }}>
            <ClipBoard text={kind} />
          </Table.Data>
        )}
        {name && (
          <AlignCenterData style={{ minWidth: "20%" }}>
            <ClipBoard text={name} />
          </AlignCenterData>
        )}
        {userRole === UserRole.Partner ? null : !playlistUrl ? (
          <Table.Data style={{ width: "8%" }}>{}</Table.Data>
        ) : (
          <AlignCenterData style={{ width: "8%" }}>
            {!playlistUrl.length ? (
              <Input.Button
                isFill={false}
                isWide
                disabled={!allowEditPlaylist}
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  imageViewModal.on();
                }}
              >
                썸네일 등록
              </Input.Button>
            ) : (
              <ImageContainer>
                <img
                  src={`${configs.urls.image}/${playlistUrl[0].url}${playlistUrl[0].url!.includes("timestamp") ? "&" : "?"}mode=s`}
                  alt=""
                  onClick={e => {
                    e.stopPropagation();
                    imageViewModal.on();
                  }}
                />
              </ImageContainer>
            )}
          </AlignCenterData>
        )}
        {userRole !== UserRole.Partner && (
          <AlignCenterData style={{ width: "8%" }}>
            <Input.Button
              isFill={false}
              color="primary"
              isWide
              disabled={!allowEditPlaylist}
              onClick={e => {
                e.stopPropagation();
                titleModal.on();
              }}
            >
              창 열기
            </Input.Button>
          </AlignCenterData>
        )}
        <AlignCenterData style={{ width: "5%" }}>{`${count}개`}</AlignCenterData>
        {userRole !== UserRole.Partner && service && <AlignCenterData style={{ width: "5%" }}>{service}</AlignCenterData>}
        {userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? null : userRelation.length ? (
          <AlignCenterData style={{ width: "10%" }}>
            {userRelation.map(({ uuid, users, owner }) => {
              const subject = !!owner
                ? `[DMS] 플레이리스트 ${id}번에 관해 문의드립니다.`
                : `[DMS] 플레이리스트 ${id}번에 변경사항이 있습니다.`;
              return (
                <LinkBox key={uuid} onClick={e => e.stopPropagation()}>
                  <a href={`mailto:${users[0].email}?subject=${subject}`}>{users[0].email}</a>
                </LinkBox>
              );
            })}
          </AlignCenterData>
        ) : (
          <AlignCenterData style={{ width: "10%" }}>{}</AlignCenterData>
        )}
        <AlignCenterData style={{ width: "5%" }}>{`${convertNumberToMoney(!price ? 0 : price)} 원`}</AlignCenterData>
        <AlignCenterData style={{ width: "5%" }}>
          <ValidContainer validCheck={validCheck}>
            {ValidCheckKorean.find(({ id }) => id === validCheck)?.name ?? ValidType.V3}
          </ValidContainer>
        </AlignCenterData>
        <AlignCenterData style={{ width: "5%" }}>
          {userRelation.filter(({ owner }) => !owner).length ? (
            <ImojiBox onClick={e => e.stopPropagation()}>
              <CheckIcon
                className={`${partnerValidCheck === ValidType.DONE ? "active" : ""}`}
                onClick={() => onChangeUserVC(partnerValidCheck === ValidType.V3 ? ValidType.DONE : ValidType.V3)}
              />
            </ImojiBox>
          ) : (
            <span style={{ fontSize: ".8rem" }}>공유된 계정이 없습니다.</span>
          )}
        </AlignCenterData>
        <AlignCenterData style={{ width: "5%" }}>
          <Input.Button
            color="primary"
            isWide
            isFill={false}
            disabled={userRole === UserRole.Arbeit && !allowEditPlaylist}
            onClick={e => {
              e.stopPropagation();
              commentModal.on();
            }}
          >
            창 열기
          </Input.Button>
        </AlignCenterData>
        {userRole === UserRole.Master && (
          <AlignCenterData style={{ width: "5%" }}>
            <Input.Button
              color="primary"
              isWide
              isFill={false}
              onClick={e => {
                e.stopPropagation();
                if (userRelation.length < 2) {
                  Toast.warning("공유된 계정이 없습니다.");
                  return;
                }
                columnModal.on();
              }}
            >
              관리
            </Input.Button>
          </AlignCenterData>
        )}
        {userRole !== UserRole.Partner && (
          <ButtonContainer>
            <Button
              color="primary"
              isWide
              disabled={!allowEditPlaylist}
              onClick={e => {
                e.stopPropagation();
                editInfoModal.on();
              }}
            >
              편집
            </Button>
            <Button color="danger" disabled={!allowEditPlaylist} isWide onClick={onDelete}>
              삭제
            </Button>
          </ButtonContainer>
        )}
      </Layout>
      {userRole === UserRole.Partner && !!Array.from(checkList).some(item => item[1]) && (
        <FloatingButton color="success" onClick={selectCsvTypeModal.on}>
          <ExcelIcon className="floating-icon big" />
        </FloatingButton>
      )}
      <Modal isOpen={imageViewModal.isToggled} onClose={imageViewModal.off}>
        <ImageViewModal index={index} id={id} playlistUrl={playlistUrl} toClose={imageViewModal.off} />
      </Modal>
      <Modal isOpen={commentModal.isToggled}>
        <ExtraInfoModal index={index} id={id} toClose={commentModal.off} />
      </Modal>
      <Modal isOpen={editModal.isToggled} onClose={editModal.off}>
        <EditModal index={index} toClose={editModal.off} />
      </Modal>
      <Modal isOpen={titleModal.isToggled} onClose={titleModal.off}>
        <TitleModal id={id} toClose={titleModal.off} />
      </Modal>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        <EditInfoModal index={index} toClose={editInfoModal.off} />
      </Modal>
      <Modal isOpen={columnModal.isToggled} onClose={columnModal.off}>
        <ColumnModal id={id} toClose={columnModal.off} />
      </Modal>
      <Modal isOpen={selectCsvTypeModal.isToggled} onClose={selectCsvTypeModal.off}>
        <SelectCsvTypeModal toClose={selectCsvTypeModal.off} />
      </Modal>
    </>
  );
};

const Layout = styled(Table.Row)`
  cursor: pointer;
`;

const ButtonContainer = styled(Table.Data)`
  width: 10%;

  button + button {
    margin-top: ${MARGING_SMALL_PX};
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const ImageContainer = styled.div`
  img {
    width: ${pixelize(UNIT * 6.25)};
    height: ${pixelize(UNIT * 6.25)};
    cursor: pointer;
    &:hover {
      border: 1px solid ${BLUE_3};
    }
  }
`;

const LinkBox = styled.div`
  margin: 0;
  padding: 0.5rem 0;
  a {
    &:hover {
      color: ${PRIMARY_COLOR_LIGHT};
      text-decoration: underline;
    }
  }
`;

const ValidContainer = styled.div<{ validCheck?: ValidType }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${props => (props.validCheck === ValidType.V3 ? DANGER_COLOR : props.validCheck === ValidType.DONE ? SUCCESS_COLOR : BLACK)};
`;

const ImojiBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${GRAY_5};
    &:hover {
      fill: ${GRAY_4};
    }
  }

  .active {
    fill: ${BLUE_6};
    &:hover {
      fill: ${BLUE_5};
    }
  }
`;
