/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  first?: number;
  id?: string;
  name?: string;
}>;

type Company = {
  uuid: string;
  company: {
    id: string;
    name: string;
    displayName: string;
  }[];
};

type Url = {
  uuid: string;
  typeUrl: string;
  url: string;
  validCheck: ValidType;
};

export type Artist = Readonly<{
  id: string;
  name: string;
  artistUrl: Url[];
  companyRelation: Company[];
}>;

export const GetArtist = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable={true} />} value={option.first ?? 20} />;
  const $id = <Variable name="id" scalar={<IdScalar isNullable={true} />} value={option.id} />;
  const $name = <Variable name="artist_name" scalar={<StringScalar isNullable={true} />} value={option.name} />;
  const { query, variables } = render(
    <Query operationName="GET_ARTIST">
      <SelectionSet
        name="artist"
        argument={{
          first: $first,
          where: {
            artist_id: $id,
            artist_title__some: {
              type_artist_title__starts_with: !option.name ? undefined : "name",
              tsvec_words__search: $name
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <Field name="name" />
        <SelectionSet name="artist_company_relation" alias="companyRelation">
          <Field name="id" alias="uuid" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="display_name" alias="displayName" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="artist_url" alias="artistUrl">
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="valid_check" alias="validCheck" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    artist: Artist[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
