import { useState, useEffect, useCallback } from "react";

type Options = IntersectionObserverInit;

export type InterSect = (entry: IntersectionObserverEntry, observer: IntersectionObserver) => Promise<void> | void;
export type UnInterSect = () => Promise<void> | void;

export const useIntersectionObserver = (
  onIntersect?: InterSect,
  unIntersect?: UnInterSect,
  option?: Options
): [HTMLDivElement | null, (instance: HTMLDivElement | null) => void] => {
  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const checkIntersect = useCallback(
    ([entry]: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      if (entry.isIntersecting) {
        onIntersect && onIntersect(entry, observer);
      } else {
        unIntersect && unIntersect();
      }
    },
    [onIntersect, unIntersect]
  );
  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(checkIntersect, { ...option });
      observer.observe(target);
    }
  }, [checkIntersect, option, target]);
  return [target, setTarget];
};
