import React from "react";
import { VideoCreateStoreProvider } from "./Store";
import { Helmet } from "App/Atomics/Helmet";
import { VideoCreateForm } from "./VideoCreateForm";

export const VideoCreate = () => (
  <VideoCreateStoreProvider>
    <Helmet title="앨범 생성" />
    <VideoCreateForm />
  </VideoCreateStoreProvider>
);
