import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_6, GREEN_5, GREEN_4, GREEN_6 } from "constants/baseColor";
import { SUCCESS_COLOR_DARK, SUCCESS_COLOR } from "constants/color";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { mediaQuery } from "constants/media";
import { Input } from "App/Atomics/Input";
import { Genre } from "GraphQL/Queries/Genre/GetGenre";
import { ColumnSection } from "./ColumnSection";
import { useGenresStore } from "App/Routes/GenreRenewal/Store";
import { GenreActions } from "App/Routes/GenreRenewal/Store/Genre";
import _ from "lodash";
import { Toast } from "lib/toast";
import { Loading } from "App/Atomics/Loading";
import { TargetTableInput } from "constants/TargetTableInput";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { UpdateGenreRenewal } from "GraphQL/Queries/Genre/UpdateGenreRenwal";
import { ValidType } from "constants/ValidType";

type Props = {
  index: number;
  toClose: () => void;
};

type Keys = keyof Genre;
type Values = Genre[Keys];

export enum Page {
  FIRST,
  SECOND
}

export const EditInfoModal = ({ index, toClose }: Props) => {
  const [{ genre, origin }, dispatch] = useGenresStore(store => ({
    genre: store.Genre.genres[index],
    origin: store.Genre.origin[index]
  }));
  const [pageIndex, setPageIndex] = useState<Page>(Page.FIRST);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  const onClearAndClose = () => {
    dispatch(GenreActions.setGenreClear());
    toClose();
  };

  const onSave = async (origin: Genre, genre: Genre) => {
    if (_.isEqual(origin, genre)) {
      Toast.warning("변경사항이 없습니다.", undefined, "bottom-right");
      return;
    }
    setEditLoading(true);
    const changeMap = new Map<Keys, { from: Values; to: Values }>();
    const GenreEntries = Object.entries(genre);
    const OriginValue = Object.values<Values>(origin);
    GenreEntries.forEach(([key, value], i) => {
      switch (key) {
        case "name":
        case "detail":
        case "validCheck":
          if (OriginValue[i] !== value) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        default:
          break;
      }
    });

    const accessId = await requestAccessRecord({ targetId: genre.id, targetTable: TargetTableInput.Genre });
    try {
      for (const [key, { to }] of changeMap) {
        switch (key) {
          case "name":
            await UpdateGenreRenewal({ id: genre.id, name: to as string });
            break;
          case "detail":
            await UpdateGenreRenewal({ id: genre.id, detail: to as string });
            break;
          case "validCheck":
            await UpdateGenreRenewal({ id: genre.id, validCheck: to as ValidType });
            break;
          default:
            break;
        }
      }
      Toast.primary("저장되었습니다.", undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("저장을 실패하였습니다.", undefined, "top-center");
      return;
    }

    await DeleteAccessRecord({ id: accessId! });
    setEditLoading(false);
    dispatch(GenreActions.setGenreSave());
    toClose();
  };

  return (
    <Layout>
      <Header>
        <DocumentIcon className="document" />
        <h3>장르 정보 및 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <ul className="left-container">
          <li className={pageIndex === Page.FIRST ? "active" : ""} onClick={() => setPageIndex(Page.FIRST)}>
            메인 정보
          </li>
        </ul>
        <div className="right">
          <ColumnSection index={index} data={genre} pageIndex={pageIndex} setLoading={setEditLoading} />
          <ButtonGroup>
            <Input.Button className="btn cancel" disabled={editLoading} isFill={false} color="success" onClick={onClearAndClose}>
              닫기
            </Input.Button>
            <Input.Button className="btn save" disabled={editLoading} isFill color="success" onClick={() => onSave(origin, genre)}>
              저장
            </Input.Button>
          </ButtonGroup>
        </div>
      </Section>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  right: 0%;
  top: 0%;
  width: 1000px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.8rem;
  ${mediaQuery(1000)} {
    width: 100%;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .document {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: 0.4rem;
    fill: ${SUCCESS_COLOR_DARK};
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  display: flex;
  height: 100%;
  .left-container {
    width: 7rem;
    border-right: 1px solid #eee;
    li {
      display: flex;
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      color: #fff;
      background-color: ${GREEN_5};

      &:hover {
        background-color: ${GREEN_4};
      }
      &:focus {
        background-color: ${GREEN_6};
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 5rem;
    overflow-y: scroll;
  }

  ${mediaQuery(965)} {
    flex-direction: column;
    .left-container {
      width: 100%;
      display: flex;
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
  .btn {
    width: 6rem;
    margin-right: 0.5rem;
  }
  .cancel {
    &:hover {
      background-color: #eee;
    }
  }
  /* Input.Button 기본 css를 수정해야함. */
  .save {
    border: 1px solid ${SUCCESS_COLOR};
    &:hover {
      border: 1px solid ${SUCCESS_COLOR};
    }
  }
`;
