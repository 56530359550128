import React, { useState, forwardRef, useRef, useImperativeHandle } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "./ReactCrop.css";

export type CropRef = {
  cropImage: CropState;
};

type ImageData = {
  ext: string;
  type: string;
  url: string;
};

export type CropState = {
  src?: any;
  crop?: Crop;
  croppedImageUrl?: string | unknown;
  croppedImageFile?: File;
  imageData: ImageData;
};

type Props = Styleable &
  Readonly<{
    cropImage: CropState;
    minWidth: number;
    minHeight: number;
    setCropState: (cropState: CropState | ((cropState: CropState) => CropState)) => void;
  }>;

export const ImageCrop = forwardRef<CropRef, Props>(({ style, className, cropImage, minWidth, minHeight, setCropState }, ref) => {
  const cropRef = useRef<ReactCrop>(null);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  let scaleX, scaleY;

  const reactCrop = document.getElementsByClassName("ReactCrop") as HTMLCollectionOf<HTMLElement>;
  if (reactCrop.length && imageRef) {
    reactCrop[0].style.width = imageRef.width + "px";
    reactCrop[0].style.height = imageRef.height + "px";
    scaleX = imageRef.naturalWidth / imageRef.width;
    scaleY = imageRef.naturalHeight / imageRef.height;
  }

  useImperativeHandle(
    ref,
    () => ({
      get cropImage() {
        return cropImage;
      }
    }),
    [cropImage]
  );

  const getCroppedImg = (image: HTMLImageElement, crop: Crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(
        image,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width!,
        crop.height!
      );
      return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(
          blob => {
            if (!blob) {
              console.error("Canvas is empty");
              return;
            }
            resolve(blob);
          },
          "image/*",
          1
        );
      });
    }
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef, crop);
      setCropState({ ...cropImage, croppedImageUrl });
    }
  };

  const onCropComplete = (crop: Crop) => {
    if (crop.aspect !== cropImage.crop?.aspect) {
      makeClientCrop(crop);
    }
  };
  const onImageLoaded = (image: HTMLImageElement) => {
    setImageRef(image);
  };

  const onCropChange = (crop: Crop) => {
    setCropState({ ...cropImage, crop });
  };

  return (
    <ReactCrop
      ref={cropRef}
      style={style}
      minWidth={scaleX && minWidth / scaleX}
      minHeight={scaleY && minHeight / scaleY}
      className={className}
      src={cropImage.src}
      crop={cropImage.crop}
      ruleOfThirds={true}
      onImageLoaded={onImageLoaded}
      onChange={onCropChange}
      onComplete={onCropComplete}
    />
  );
});
