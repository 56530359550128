import React, { Fragment, useState } from "react";
import styled from "styled-components";

import {
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  MARGING_X_LARGE_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { RoleTextAutoComplete, CharacterTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { useWorkDispatch } from "../../Store";
import { WorkCreateActions } from "../../Store/Work";
import { BLUE_1 } from "constants/baseColor";

type Artist = Readonly<{
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  character_id?: string;
  character_name?: string;
  order?: number;
}>;

type Props = Readonly<{
  artistList: Artist[];
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow: overlay;

  width: ${pixelize(UNIT * 60)};
  height: ${pixelize(UNIT * 32)};
  padding: ${PADDING_XX_LARGE_PX};

  h3 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  .suggestSpan {
    display: flex;
    flex-direction: row;
    margin-bottom: ${MARGING_XX_LARGE_PX};

    & > .autocomplete {
      width: 100%;
      margin-right: ${MARGING_LARGE_PX};
    }

    button {
      height: fit-content;
    }
  }
`;

const FilterGroup = styled.section`
  margin-bottom: ${MARGING_LARGE_PX};

  hr {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  .container {
    padding: 0.5rem;
    min-height: 15rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: scroll;
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 7)};
  }
`;

const Menu = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
  font-weight: 600;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: ${BLUE_1};
  box-shadow: 0 2px 2px #eee;
  height: 2.5rem;
  align-items: center;
`;

const NameList = styled(Input.Group)`
  text-align: left;
  grid-template-columns: 30% 30% 30% 10%;
  & > span {
    padding: 0px ${PADDING_LARGE_PX};
  }

  & > button {
    margin-left: auto;
    border-radius: 50%;
    transform: scale(0.75);
  }
`;

export const ArtistModal = ({ artistList: initialArtist, toClose }: Props) => {
  const dispatch = useWorkDispatch();
  const [artistList, setArtistList] = useState(initialArtist.length ? initialArtist : ([] as Artist[]));
  const [artist, setArtist] = useState({} as Info);
  const [role, setRole] = useState({} as Info);
  const [character, setCharacter] = useState({} as Info);

  const toSave = () => {
    dispatch(WorkCreateActions.setWorkArtist(artistList));
    toClose();
  };
  const addArtist = (artist: any, role: any, character: any, order: number) => {
    if (!artistList.some(value => value.artist_id === artist.id && value.role_id === role.id && value.character_id === character.id)) {
      setArtistList([
        ...artistList,
        {
          artist_id: artist.id,
          artist_name: artist.name,
          role_id: role.id,
          role_name: role.name,
          character_id: character.id,
          character_name: character.name,
          order
        }
      ]);
    }
  };

  const removeArtist = (index: number) => setArtistList([...artistList.filter((value, idx) => index !== idx)]);

  return (
    <Layout>
      <h3>아티스트 / 역할 / 캐릭터 검색</h3>
      <FilterGroup>
        <span className="suggestSpan">
          <ArtistTextAutoComplete
            className="autocomplete"
            autoFocus
            onBlur={info => {
              if (info) {
                setArtist(info);
              } else {
                // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
              }
            }}
          />
          <RoleTextAutoComplete
            className="autocomplete"
            onBlur={info => {
              if (info) {
                setRole(info);
              } else {
                // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
              }
            }}
          />
          <CharacterTextAutoComplete
            className="autocomplete"
            onBlur={info => {
              if (info) {
                setCharacter(info);
              } else {
                // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
              }
            }}
          />
          <Input.Button
            color="default"
            onClick={() => {
              if (artist && role) {
                const lastOrder = !artistList.length ? 0 : artistList[artistList.length - 1].order! + 1;
                addArtist(artist, role, character, lastOrder!);
              }
            }}
          >
            추가
          </Input.Button>
        </span>
        <Menu>
          <span>{"아티스트"}</span>
          <span>{"역할"}</span>
          <span>{"캐릭터"}</span>
        </Menu>
        <div className="container">
          {artistList.map((value, index) => {
            return (
              <Fragment key={index}>
                <NameList isAlign="baseline">
                  <span>{value.artist_name}</span>
                  <span>{value.role_name}</span>
                  <span>{value.character_name}</span>
                  <Input.Button color="danger" onClick={() => removeArtist(index)}>
                    -
                  </Input.Button>
                </NameList>
              </Fragment>
            );
          })}
        </div>
      </FilterGroup>
      <ButtonGroup>
        <Input.Button onClick={toClose}>취소</Input.Button>
        <Input.Button color="primary" onClick={toSave}>
          입력
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
