/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  name?: string;
  nickname?: string;
  password?: string;
  newPassword?: string;
}>;

export const UpdateUser = async (option: Option) => {
  const name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const nickname = <Variable name="nickname" scalar={<StringScalar isNullable />} value={option.nickname} />;
  const password = <Variable name="password" scalar={<StringScalar />} value={option.password} />;
  const newPassword = <Variable name="newPassword" scalar={<StringScalar isNullable />} value={option.newPassword} />;
  const { query, variables } = render(
    <Mutation operationName="ACCESS_UPDATE_USER">
      <Field
        name="updateUser"
        argument={{
          where: { password },
          data: { name, nickname, password: newPassword }
        }}
      />
    </Mutation>
  );
  type Data = Readonly<{
    updateUser: boolean;
  }>;
  return await clients.access.request<Data>(query, variables);
};
