import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly officialBarcode?: string;
};

export const GoodsOfficialBarcode = ({ officialBarcode }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>공식 바코드</Style.Caption>
    <Style.SubCaption>- KMCA 로그 연동 규격서 참조</Style.SubCaption>
    <Style.InputText
      key={officialBarcode}
      placeholder={"바코드"}
      defaultValue={officialBarcode}
      onBlur={value => GoodsCreateStore.dispatch(GoodsCreateActions.setOfficialBarcode(value))}
    />
  </Style.Row>
);
