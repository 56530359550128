import classNames from "classnames";
import React, { useMemo, useState } from "react";
import ReactSelect from "react-select/async";
import debounce from "debounce-promise";
import { identity } from "utils/identity";
import makeAnimated from "react-select/animated";
import { SelectComponents } from "react-select/src/components";
import { FocusEventHandler } from "react-select";

type Value = string;
type Label = string;

export type Info = Readonly<{
  id: Value;
  name: Label;
  extra?: any;
  type?: string;
}>;

type Props = Readonly<{
  style?: import("react-select/src/styles").Styles;
  components?: Partial<SelectComponents<any>>;
  className?: string;
  isDisabled?: boolean;
  delay?: number;
  placeholder?: string;
  menuPlacement?: "auto" | "bottom" | "top" | undefined;
  defaultValue?: readonly Info[];
  defaultOptions?: readonly Info[];
  loadInfoList: (keyword: string) => readonly Info[] | Promise<readonly Info[]>;
  onFocus?: FocusEventHandler;
  onChange?: (info: readonly Info[]) => void;
  onBlur?: (info: readonly Info[]) => void;
}>;

type SelectComponentType =
  | Partial<
      SelectComponents<
        Readonly<{
          id: string;
          name: string;
          type?: string | undefined;
        }>
      >
    >
  | undefined;

export const TagAutoComplete = ({
  style,
  components,
  className,
  isDisabled = false,
  defaultValue = [],
  defaultOptions,
  delay = 500,
  placeholder,
  menuPlacement,
  loadInfoList,
  onFocus,
  onChange = identity,
  onBlur = identity
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const createSuggestionInfoLoader = () =>
    debounce(
      async (keyword: string, callback: (infoList: readonly Info[]) => void) => Promise.resolve(loadInfoList(keyword)).then(callback),
      delay
    );
  const loadSuggestionInfoByKeyword = useMemo(createSuggestionInfoLoader, []);
  const change: ReactSelect<Info>["props"]["onChange"] = nextInfo => {
    setInfo((nextInfo || []) as readonly Info[]);
    onChange((nextInfo || []) as readonly Info[]);
  };
  const blur = () => onBlur(info);
  const animatedComponents: SelectComponentType = makeAnimated();
  return (
    <ReactSelect
      styles={style}
      className={classNames(className)}
      isDisabled={isDisabled}
      cacheOptions={true}
      menuPosition={"fixed"}
      menuPlacement={menuPlacement}
      defaultValue={defaultValue}
      defaultOptions={defaultOptions}
      getOptionLabel={info => info.name}
      getOptionValue={info => info.id}
      isMulti={true}
      components={{ ...animatedComponents, ...components }}
      placeholder={placeholder}
      loadOptions={loadSuggestionInfoByKeyword}
      onFocus={onFocus}
      onChange={change}
      onBlur={blur}
    />
  );
};
