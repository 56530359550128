import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { SettleActions as Settle } from "./Settle";

const reducers = { Settle };

const reducer = combineReducers(reducers, createStoreLogger("Settle"));

export const {
  StoreProvider: SettleStoreProvider,
  useStore: useSettleStore,
  useSelector: useSettleSelector,
  useDispatch: useSettleDispatch
} = createStore(reducer);
