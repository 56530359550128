/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const DeleteGoodsStructure = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_GOODS_STRUCTURE">
      <SelectionSet
        name="deleteGoods_structure"
        alias="deleteGoodsStructure"
        argument={{
          where: { id: $id }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteGoodsStructure: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
