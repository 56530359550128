/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const CheckPlaylistCopyright = async (option: Option) => {
  const $id = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="CHECK_PLAYLIST_COPYRIGHT">
      <SelectionSet
        name="metadata_playlist_relation"
        alias="metadataPlaylistRelation"
        argument={{
          group: true,
          first: 999,
          where: {
            playlist__some: { playlist_id: $id },
            metadata__some: {
              OR: [{ track_license__some: { copyright: false } }, { track_license__some: { neighboring: false } }]
            }
          }
        }}
      >
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataPlaylistRelation: {
      metadata: {
        id: string;
      }[];
    }[];
  }>;
  const { data, error } = await clients.metadata.request<Data>(query, variables);
  if (error || !data) {
    throw error;
  }
  return data;
};
