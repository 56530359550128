/** @jsx jsx */

import { MetadataRightType } from "constants/MetadataRightType";
import { jsx, render, Query, SelectionSet, Field, Variable, IntScalar } from "graphql-jsx";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";

type Option = Readonly<{
  rootId: number;
}>;

export const GetMetadataCompanyInfo = async (option: Option) => {
  const $rootId = <Variable name="structure_root_id" scalar={<IntScalar isNullable={false} />} value={option.rootId!} />;
  const $rightCompany = <MetadataRightScalar isNullable value={MetadataRightType.rightCompany} />;

  const { query, variables } = render(
    <Query operationName="GET_METADATA_COMPANY_INFO">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 99,
          where: {
            type_metadata_class: "record",
            type_metadata_subclass: "track",
            metadata_structure__some: {
              structure_root_id: $rootId
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata_company_relation" alias="companyRelation" argument={{ where: { type_kind: $rightCompany } }}>
          <Field name="id" alias="uuid" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="group_path" alias="path" />
            <Field name="license" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      companyRelation: {
        uuid: string;
        company: {
          id: string;
          name: string;
          path: string;
          license: string;
        }[];
      }[];
    }[];
  }>;
  const { data } = await clients.metadata.request<Data>(query, variables);
  return data?.metadata ?? [];
};
