/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum CategoryOfGoods {
  패키지 = "Package",
  컬렉션 = "Collection",
  콘서트 = "Concert",
  패션 = "Fashion",
  문구 = "Stationery"
}

type Props = Readonly<{
  isNullable?: boolean;
  value?: CategoryOfGoods;
}>;

const items = Object.values(CategoryOfGoods);

export const CategoryOfGoodsScalar = ({ isNullable = false, value }: Props) => (
  <EnumScalar type="CategoryOfGoods" value={value} isNullable={isNullable} items={items} />
);
