import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { PADDING_SMALL_PX, HEADER_HEIGHT_PX } from "constants/size";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { VIOLET_2, VIOLET_3, VIOLET_4, GRAY_4 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { IdIsExist, CreateUser } from "GraphQL/Queries";
import { SetAccess } from "GraphQL/Queries/Access/SetAccess";
import { UserRole } from "constants/UserRole";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { Helmet } from "App/Atomics/Helmet";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";

const AdminSignUpLayout = () => {
  const [allowName, setAllowName] = useState<boolean>(false);
  const [tier, setTier] = useState<UserRole | undefined>(UserRole.Master);
  const [companyInfo, setCompanyInfo] = useState<Info | undefined>(undefined);

  const useHtmlInputElementRef = () => useRef<HTMLInputElement>(null);
  const nameRef = useHtmlInputElementRef(); // 이름
  const nicknameRef = useHtmlInputElementRef(); // 닉네임
  const signUpEmailRef = useHtmlInputElementRef(); // 이메일
  const signUpPasswordRef = useHtmlInputElementRef(); // 패스워드

  const onEmailCheck = async () => {
    const email = signUpEmailRef.current!.value;
    if (!email) {
      alert("이메일을 입력해주세요!");
      signUpEmailRef.current!.focus();
      setAllowName(false);
      return;
    }
    const response = await IdIsExist({ email });
    if (response.errors) {
      alert("서버 에러.");
      setAllowName(false);
      return;
    }
    if (response.data && !response.data.idIsExist) {
      alert("중복된 이메일이 있습니다. 다른 이메일을 입력해주세요!");
      signUpEmailRef.current!.focus();
      setAllowName(false);
      return;
    } else {
      alert("중복된 이메일이 없습니다.");
      setAllowName(true);
    }
  };

  const tryToSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const name = nameRef.current!.value;
    const nickname = nicknameRef.current!.value;
    const email = signUpEmailRef.current!.value;
    const password = signUpPasswordRef.current!.value;
    if (!allowName) {
      alert("이메일 중복검사를 해주세요.");
      return;
    }
    try {
      const { data, errors } = await CreateUser({ name, nickname, email, password });
      if (errors) {
        alert("SignUp Failed.");
        return;
      }
      if (data) {
        if (tier === UserRole.Client) {
          if (!companyInfo) {
            window.alert("권리사를 선택해주세요.");
            return;
          }
          await SetAccess({ email, tier: tier as UserRole, owner: [parseInt(companyInfo!.id, 10)] });
        } else {
          await SetAccess({ email, tier: tier as UserRole });
        }
        window.alert("생성이 완료되었습니다.");
        window.location.reload();
      }
    } catch (e) {
      window.alert("Server Error");
      return;
    }
  };

  return (
    <Layout>
      <Container>
        <Title>
          <h1>계정 생성</h1>
          <span>파트너 사의 경우 관리자 계정 생성 후 권한 등록을 해주셔야 합니다.</span>
        </Title>
        <Section>
          <ButtonGroup>
            <TierButton
              active={tier === UserRole.Master}
              onClick={() => {
                setTier(UserRole.Master);
              }}
            >
              <CrownIcon />
              <span>Master</span>
            </TierButton>
            <TierButton
              active={tier === UserRole.Client}
              onClick={() => {
                setTier(UserRole.Client);
              }}
            >
              <UserIcon />
              <span>Client</span>
            </TierButton>
            <CompanySelect
              isVisible={tier === UserRole.Client}
              style={{
                control: base => ({
                  ...base,
                  height: "3rem",
                  borderRadius: "1rem"
                }),
                menu: base => ({
                  ...base,
                  borderRadius: "1rem"
                })
              }}
              defaultValue={companyInfo}
              onChange={info => {
                if (info) {
                  setCompanyInfo(info);
                }
              }}
            />
          </ButtonGroup>
          <InputText>
            <span>email</span>
            <div className="duplicate-box">
              <Input.Text type="email" autoComplete="off" ref={signUpEmailRef} maxLength={40} placeholder="" />
              <button type="button" className="checkbtn" onClick={onEmailCheck}>
                중복 검사
              </button>
            </div>
          </InputText>
          <InputText>
            <span>name</span>
            <Input.Text autoComplete="off" ref={nameRef} maxLength={20} placeholder="" />
          </InputText>
          <InputText>
            <span>nickname</span>
            <Input.Text autoComplete="off" ref={nicknameRef} maxLength={20} placeholder="" />
          </InputText>
          <InputText>
            <span>password</span>
            <Input.Password autoComplete="off" ref={signUpPasswordRef} maxLength={20} placeholder="최소 여섯자리를 입력해주세요." />
          </InputText>
          <RowGroup>
            <Input.Button className="create-btn" type="submit" color="default" disabled={!tier} onClick={tryToSignUp}>
              생성
            </Input.Button>
          </RowGroup>
        </Section>
      </Container>
    </Layout>
  );
};

export const AdminSignUp = () => (
  <ResponsiveToSideNavigatorTemplate>
    <Helmet title="회원 생성" />
    <AdminSignUpLayout />
  </ResponsiveToSideNavigatorTemplate>
);

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${HEADER_HEIGHT_PX};
  font-size: 0.9rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 48rem;
  height: 45rem;
  padding: 3rem;
  background: #fff;
  box-shadow: 0 0 4px #ddd;
  border-radius: 2rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: #191919;
    font-weight: 700;
    margin-bottom: 0.4em;
  }
  span {
    color: #999;
  }
`;

const Section = styled.section`
  margin: 1.5rem 0;
`;

const InputText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  span {
    color: #999;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  input {
    width: 60%;
    height: 3.2rem;
    padding: 0.9rem;
    border: 1px solid hsl(210 6% 83%);
    border-radius: 1rem;
    transition: border-color 0.2s;
    &:focus {
      border-color: ${PRIMARY_COLOR};
      border-width: 1.5px;
    }
  }
  .duplicate-box {
    width: 60%;
    position: relative;
    input {
      width: 100%;
      padding-right: 75px;
    }
    .checkbtn {
      color: ${VIOLET_4};
      font-size: 0.9rem;
      position: absolute;
      height: 2.5rem;
      right: 2%;
      top: 5px;
      background: #fff;
      border-radius: 8px;
      padding: ${PADDING_SMALL_PX};
      transition: all 0.2s;
      &:hover {
        box-shadow: 0 0 4px ${VIOLET_2};
      }
      &:active {
        box-shadow: 0 0 4px ${VIOLET_3};
        transform: translateY(1.5px);
      }
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const TierButton = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 10rem;
  height: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 4px ${props => (!props.active ? GRAY_4 : PRIMARY_COLOR)};
  color: ${props => (!props.active ? GRAY_4 : PRIMARY_COLOR)};
  font-weight: ${props => (!props.active ? 400 : 600)};
  margin-right: 1rem;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.01);
  }
  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const RowGroup = styled.div`
  width: 100%;
  display: flex;
  .create-btn {
    width: 8rem;
    border-radius: 1rem;
    height: 3rem;
    text-align: center;
    margin-top: 1rem;
    margin-right: 1rem;
    font-size: 1.01rem;
  }
`;

const anim = keyframes`
  from {
    opacity: 0%;
    transform: translateX(-50px);
  }
  to {
    opacity: 100%;
    transform: translateX(0);
  }
`;

const CompanySelect = styled(CompanyTextAutoComplete)<{ isVisible: boolean }>`
  width: 60%;
  display: ${props => (props.isVisible ? "block" : "none")};
  animation: ${anim} 0.3s ease-in-out;
`;
