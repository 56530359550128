import React from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { SingleAlbumStoreProvider, useSingleAlbumSelector } from "./Store";
// import { SingleAlbumForm } from "./Forms/SingleAlbumForm";
import { HEADER_HEIGHT_PX } from "constants/size";
import { MusicUploadForm } from "./Forms/MusicUploadForm";
import { Page } from "./Store/SingleAlbum";
import { SingleAlbumForm } from "./Forms/SingleAlbumForm";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

const SingleAlbumCreateLayout = () => {
  const page = useSingleAlbumSelector(store => store.SingleAlbum.page);
  return (
    <ResponsiveToSideNavigatorTemplate>
      <Layout>{page === Page.FIRST ? <MusicUploadForm /> : <SingleAlbumForm />}</Layout>
    </ResponsiveToSideNavigatorTemplate>
  );
};

export const SingleAlbumCreate = () => (
  <SingleAlbumStoreProvider>
    <Helmet title="싱글 앨범 등록" />
    <SingleAlbumCreateLayout />
  </SingleAlbumStoreProvider>
);
