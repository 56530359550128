/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
  typeClass: string;
  typeSubClassIn: string[];
}>;

export const GetMetadataLicenseInfo = async (option: Option) => {
  const $id = <Variable name="metadata_id" scalar={<IdScalar />} value={option.id} />;
  const $typeClass = <Variable name="type_class" scalar={<StringScalar />} value={option.typeClass} />;
  const $typeSubClassIn = (
    <Variable name="type_subclass" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={option.typeSubClassIn} />
  );
  const { query, variables } = render(
    <Query operationName="GET_METADATA_LICENSE_INFO">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          where: {
            metadata_id: $id,
            type_metadata_class: $typeClass,
            type_metadata_subclass__in: $typeSubClassIn
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet
          name="track_license"
          alias="trackLicense"
          argument={{
            first: 999
          }}
        >
          <Field name="id" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      trackLicense: {
        id: string;
      }[];
    }[];
  }>;
  const { data, errors } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }

  return data!.metadata[0].trackLicense.map(({ id }) => id);
};
