import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { StructureEvents } from "../../Store/Structure";
import itiriri from "itiriri";

export const toEditStructureLog = async (events: StructureEvents) => {
  const { updateLog } = events;

  const structureUpdateQuery = itiriri(updateLog.entries())
    .toArray(([metadataId, event]) => {
      if (event[metadataId].type === "Order") {
        return `
          structure${event[metadataId].structureId}: updateMetadata_structure(
            where: { structure_id: "${event[metadataId].structureId}" }
            data: {
              booking_order: ${event[metadataId].to}
            }
          ) {
            structure_id
          }
        `;
      }
    })
    .join(" ");

  if (window.confirm("데이터를 변경하시겠습니까?")) {
    try {
      const query = gql`
        mutation UPDATE_STRUCTURE {
          ${structureUpdateQuery}
        }
      `;

      await clients.metadata.query(query);
    } catch (e) {
      throw e;
    }
  }
};
