import React, { ReactNode, MouseEvent } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { WHITE, GRAY_5 } from "constants/baseColor";
import {
  SECONDARY_COLOR,
  SECONDARY_COLOR_LIGHT,
  INFO_COLOR,
  INFO_COLOR_LIGHT,
  WARNING_COLOR,
  WARNING_COLOR_LIGHT,
  SECONDARY_COLOR_DARK,
  INFO_COLOR_DARK,
  WARNING_COLOR_DARK
} from "constants/color";
import { pixelize, UNIT } from "constants/size";

export type Color = "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "default";

export type FloatingButtonProps = Styleable &
  Readonly<{
    color?: Color;
    children: ReactNode;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  }>;

const Layout = styled.button`
  position: fixed;
  width: ${pixelize(UNIT * 4)};
  height: ${pixelize(UNIT * 4)};
  bottom: ${pixelize(UNIT * 2)};
  right: ${pixelize(UNIT * 2)};
  color: #fff;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 4px 1px ${GRAY_5};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &.primary {
    color: ${WHITE};
    background: #a8c0ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #a8c0ff, #3f2b96); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #a8c0ff, #3f2b96); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
      transform: scale(1.05);
    }
  }

  &.secondary {
    color: ${WHITE};
    background-color: ${SECONDARY_COLOR};

    &:hover {
      transform: scale(1.05);
      background-color: ${SECONDARY_COLOR_LIGHT};
    }

    &:active {
      background-color: ${SECONDARY_COLOR_DARK};
    }
  }

  &.info {
    color: ${WHITE};
    background-color: ${INFO_COLOR};

    &:hover {
      transform: scale(1.05);
      background-color: ${INFO_COLOR_LIGHT};
    }

    &:active {
      background-color: ${INFO_COLOR_DARK};
    }
  }

  &.success {
    color: ${WHITE};
    background: #11998e; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #38ef7d, #11998e); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
      transform: scale(1.05);
    }
  }

  &.warning {
    color: ${WHITE};
    background-color: ${WARNING_COLOR};

    &:hover {
      transform: scale(1.05);
      background-color: ${WARNING_COLOR_LIGHT};
    }

    &:active {
      background-color: ${WARNING_COLOR_DARK};
    }
  }

  &.danger {
    color: ${WHITE};
    background: #f12711; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f5af19, #f12711); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f5af19, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
      transform: scale(1.05);
    }
  }

  &.default {
    background: #ece9e6; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ffffff, #ece9e6); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffffff, #ece9e6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &:hover {
      transform: scale(1.05);
    }
  }
`;

/**
 * 플로팅 버튼입니다.
 *
 * - `position: fixed`값으로,우측 화면 하단에 표시됩니다.
 */
export const FloatingButton = ({ className, style, children, color, onClick }: FloatingButtonProps) => (
  <Layout style={style} className={classNames(className, color)} onClick={onClick}>
    {children}
  </Layout>
);
