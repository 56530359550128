import axios from "axios";
import { UploadFile } from "GraphQL/Queries";
import { CreateGoodsRelation } from "GraphQL/Queries/Goods/CreateGoodsRelation";
import { GoodsUrl } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { createFormData } from "lib/createFormData";

type Props = {
  readonly id: string;
  readonly companyId: string;
  readonly coverUrl?: GoodsUrl[];
  readonly detailUrl?: GoodsUrl[];
  readonly videoUrl?: GoodsUrl[];
};

export const useCreateImage = () => {
  const createImage = async ({ id, companyId, coverUrl = [], detailUrl = [], videoUrl = [] }: Props) => {
    const coverUrlSet = await uploadImage(id, companyId, coverUrl);
    const detailUrlSet = await uploadImage(id, companyId, detailUrl);
    const videoUrlSet = await uploadImage(id, companyId, videoUrl);
    await CreateGoodsRelation({
      id,
      coverUrl: Array.from(coverUrlSet),
      detailUrl: Array.from(detailUrlSet),
      videoUrl: Array.from(videoUrlSet)
    });
  };
  return { createImage };
};

const uploadImage = async (goodsId: string, companyId: string, urls: GoodsUrl[]) => {
  const result = new Set<GoodsUrl>();
  try {
    for (const { id, typeUrl, file, ext, order } of urls) {
      const type = typeUrl === "video/mp4" ? "video" : "cover";
      const { data } = await UploadFile({ filename: `goods/${type}/${goodsId}_${id}.${ext!}`, companyId });
      if (!data) {
        throw new Error();
      }
      const bucket = data.uploadFile;
      await axios({
        method: "post",
        url: bucket.url,
        data: createFormData(bucket, file!),
        headers: {
          "Content-Type": typeUrl
        }
      });
      result.add({
        id,
        typeUrl,
        url: bucket.key,
        order
      });
    }
    return result;
  } catch (err) {
    console.log(err);
    return result;
  }
};
