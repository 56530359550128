import React, { ReactNode } from "react";
import styled from "styled-components";

import { WHITE } from "constants/baseColor";
import { pixelize, SIDEBAR_WIDTH, UNIT, widthViewportize } from "constants/size";
import { mediaQuery } from "constants/media";

type Props = Readonly<{ children: ReactNode }>;

const SideNavigatorLayout = styled.div`
  position: fixed;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: none;

  width: ${pixelize(SIDEBAR_WIDTH)};
  height: 100%;

  color: ${WHITE};
  background-image: linear-gradient(187deg, #6a5fdd 37%, #241d8c 100%);
  z-index: 20;

  & > div {
    display: flex;
    flex-direction: column;

    & > a {
      height: 66px;
      &[aria-current="page"] {
        position: relative;
        opacity: 1;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: inherit;
          height: 66px;
          opacity: 0.2;
          mix-blend-mode: soft-light;
          border-radius: 4px;
          box-shadow: 0 2px 5px 0 rgba(83, 97, 255, 0.1);
          background-color: #ffffff;
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          height: 66px;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          background-color: ${WHITE};
        }
      }
    }

    & .collapsible {
      display: flex;
      flex-direction: column;

      & > a {
        height: 66px;
        padding: ${pixelize(1.25 * UNIT)} 0px ${pixelize(1.25 * UNIT)} ${widthViewportize(0.3 * UNIT)};
        font-size: 0.95rem !important;
        opacity: 0.8;
        background-color: rgba(0, 0, 0, 0.15);
        transition: all 0.2s;

        &.active {
          text-decoration: underline;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .close {
      fill: #fff;
      position: absolute;
      width: 1.2em;
      height: 1.2em;
      top: 1em;
      right: 1em;
      cursor: pointer;
      display: none;
      ${mediaQuery(768)} {
        display: block;
      }
    }
  }

  ${mediaQuery(768)} {
    width: 18rem;
  }
`;

export const Layout = ({ children }: Props) => (
  <SideNavigatorLayout>
    <div>{children}</div>
  </SideNavigatorLayout>
);
