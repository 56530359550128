import React, { Fragment } from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Metadata, Artist, Company, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import { RoleTextAutoComplete, CharacterTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import uuidv4 from "uuid/v4";
import { useAlbumDispatch } from "App/Routes/AlbumRenewal/Store";
import { AlbumActions } from "App/Routes/AlbumRenewal/Store/Album";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { BLUE_7 } from "constants/baseColor";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { UpdateMetadataUrl } from "GraphQL/Queries/Metadata";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { useAppStore } from "App/Store";
import { UserRole } from "constants/UserRole";

type SectionProps = {
  index: number;
  data: Metadata;
  pageIndex: Page;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 700px;
  padding: 0.5rem;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `2rem 150px auto` : `2rem 34% 28% 28%`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;

  .refresh {
    width: 1.3rem;
    height: 1.3rem;
    position: absolute;
    right: 5px;
    cursor: pointer;
    fill: ${BLUE_7};
    background-color: #eef;
    border-radius: 50%;
    padding: 4px;
  }
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

export const ColumnSection = ({ index, data, pageIndex, setLoading }: SectionProps) => {
  const isAdmin = useAppStore(store => store.UserToken.role === UserRole.Master);
  const dispatch = useAlbumDispatch();

  const onRefreshImage = async (uuid: string, typeUrl: string, url?: string) => {
    if (!url) {
      Toast.error("올바른 URL이 아닙니다.", undefined, "top-center");
      return;
    }
    const refreshUrl = `${url.replace(/\?.+/, "")}?timestamp=${Date.now()}`;
    try {
      setLoading(true);
      const accessId = await requestAccessRecord({ targetId: data.metadataId, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        try {
          const { errors } = await UpdateMetadataUrl({ uuid, typeUrl, url: refreshUrl });
          if (errors) {
            throw new Error(errors[0].message);
          } else {
            Toast.primary("갱신되었습니다.");
            dispatch([
              AlbumActions.updateAlbumImage({ index, uuid, typeUrl, url: refreshUrl }),
              AlbumActions.updateOriginAlbumImage({ index, uuid, typeUrl, url: refreshUrl })
            ]);
          }
          await DeleteAccessRecord({ id: accessId });
          setLoading(false);
        } catch (err) {
          console.log(err);
          Toast.error("오류가 발생하였습니다.", undefined, "top-center");
          await DeleteAccessRecord({ id: accessId });
          setLoading(false);
          return;
        }
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          if (value === null) return null;
          // 메인 정보
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "metadataId":
              case "title":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "metadataId" ? "ID" : "제목"}</span>
                    <InputText
                      isDisabled={key === "metadataId"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(AlbumActions.updateAlbumTitle({ index, title: val }))}
                    />
                  </LabelGroup>
                );
              case "artistRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>{"아티스트"}</span>
                      <RowGroup className={!value.length ? "" : "border"}>
                        {!(value as Artist[]).length
                          ? null
                          : (value as Artist[]).map(({ artist, role, character }, artistIndex) => {
                              const uniqKey = `${artist[0]?.name ?? "-"}-${role[0]?.name ?? "-"}-${character[0]?.name ?? "-"}`;
                              return (
                                <ExtraInfo key={uniqKey} column={4}>
                                  <RoundButton
                                    color="danger"
                                    key={`${uniqKey}-remove`}
                                    onClick={() => {
                                      dispatch(AlbumActions.deleteAlbumArtist({ index, artistIndex }));
                                    }}
                                  >
                                    -
                                  </RoundButton>
                                  <ArtistTextAutoComplete
                                    className="select"
                                    key={`${uniqKey}-artist`}
                                    defaultValue={
                                      !artist.length || !artist[0].id ? undefined : { id: artist[0].id!, name: artist[0].name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const artistInfo = { id: info.id, name: info.name };
                                        dispatch(AlbumActions.updateAlbumArtist({ index, artistIndex, info: artistInfo }));
                                      }
                                    }}
                                  />
                                  <RoleTextAutoComplete
                                    className="select"
                                    key={`${uniqKey}-role`}
                                    defaultValue={!role.length || !role[0].id ? undefined : { id: role[0].id!, name: role[0].name! }}
                                    onChange={info => {
                                      if (info) {
                                        const roleInfo = { id: info.id, name: info.name };
                                        dispatch(AlbumActions.updateAlbumRole({ index, roleIndex: artistIndex, info: roleInfo }));
                                      }
                                    }}
                                  />
                                  <CharacterTextAutoComplete
                                    className="select character"
                                    key={`${uniqKey}-character`}
                                    defaultValue={
                                      !character.length || !character[0].id ? undefined : { id: character[0].id!, name: character[0].name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const characterInfo = { id: info.id, name: info.name };
                                        dispatch(
                                          AlbumActions.updateAlbumCharacter({ index, characterIndex: artistIndex, info: characterInfo })
                                        );
                                      }
                                    }}
                                  />
                                </ExtraInfo>
                              );
                            })}
                        <RoundButton
                          color="success"
                          onClick={() => {
                            dispatch(AlbumActions.createAlbumArtist(index));
                          }}
                        >
                          +
                        </RoundButton>
                      </RowGroup>
                    </LabelGroup>
                  </Fragment>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(AlbumActions.updateAlbumVC({ index, vc }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "rightsCompany":
                const rightsCompany = (value as Company[])[0]?.company ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"권리사"}</span>
                    <CompanyTextAutoComplete
                      isDisabled={!isAdmin}
                      defaultValue={!rightsCompany?.length ? undefined : { id: rightsCompany[0].id, name: rightsCompany[0].name }}
                      onBlur={info => {
                        if (info) {
                          const uuid = uuidv4();
                          const rightsCompany = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeKind: "rightsCompany",
                            order: 0,
                            company: [
                              {
                                id: info.id,
                                name: info.name
                              }
                            ]
                          };
                          dispatch(AlbumActions.setAlbumRightsCompany({ index, rightsCompany: [rightsCompany] }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "productions":
                const productions = (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"제작사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={productions}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "productions",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(AlbumActions.setAlbumProductions({ index, productions: list }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "publications":
                const publications =
                  (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"배급사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={publications}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "publications",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(AlbumActions.setAlbumPublications({ index, publications: list }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "class":
              case "subclass":
              default:
                return null;
            }
            // 부가 정보
          } else {
            switch (key) {
              case "metadataUrl":
                const urls = value as Url[];
                return (
                  <LabelGroup key={key}>
                    <span>{"URL"}</span>
                    <RowGroup className={!urls.length ? "" : "border"}>
                      {!urls?.length
                        ? null
                        : urls.map((url, i) => (
                            <ExtraInfo key={i} column={3}>
                              <RoundButton color="danger" onClick={() => dispatch(AlbumActions.deleteAlbumUrl({ index, urlIndex: i }))}>
                                -
                              </RoundButton>
                              <CreatableTextSelect
                                name="select"
                                className="select"
                                optionList={[
                                  { id: "source", name: "source" },
                                  { id: "score", name: "score" },
                                  { id: "imslp", name: "imslp" },
                                  { id: "promotion", name: "promotion" }
                                ]}
                                placeholder="타입"
                                defaultValue={!url.typeUrl ? undefined : { id: url.typeUrl, name: url.typeUrl }}
                                onBlur={info => {
                                  if (info) {
                                    dispatch(AlbumActions.updateAlbumTypeUrl({ index, urlIndex: i, typeUrl: info.id }));
                                  }
                                }}
                              />
                              <InputText
                                placeholder="값"
                                defaultValue={!url.url ? undefined : url.url}
                                onBlur={text => {
                                  dispatch(AlbumActions.updateAlbumUrl({ index, urlIndex: i, url: text }));
                                }}
                              />
                              {url.typeUrl === "cover" && (
                                <RefreshIcon className="refresh" onClick={() => onRefreshImage(url.uuid, url.typeUrl!, url.url)} />
                              )}
                            </ExtraInfo>
                          ))}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const tempUrl = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeUrl: undefined,
                            url: undefined
                          } as Url;
                          dispatch(AlbumActions.createAlbumUrl({ index, url: tempUrl }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          }
        })}
    </>
  );
};
