/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = {
  id?: number;
};

export type TotalAccount = {
  accountsPayable: number;
  depositAmount: number;
  cumulativeAmount: number;
  paidAmount: number;
};

export const GetTotalAccount = async (option: Option) => {
  const $id = <Variable name="user_id" scalar={<IntScalar isNullable={true} />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_TOTAL_ACCOUNT">
      <SelectionSet
        name="get_totalAccount"
        alias="getTotalAccount"
        argument={{
          where: {
            users_id: $id
          }
        }}
      >
        <Field name="accounts_payable" alias="accountsPayable" />
        <Field name="deposit_amount" alias="depositAmount" />
        <Field name="cumulative_amount" alias="cumulativeAmount" />
        <Field name="paid_amount" alias="paidAmount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    getTotalAccount: TotalAccount;
  }>;
  return await clients.access.request<Data>(query, variables);
};
