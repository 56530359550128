/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
}>;

export const DeleteTrackArtistRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_TRACK_ARTIST_RELATION">
      <SelectionSet
        name="deleteMetadata_artist_relation"
        alias="deleteArtistRelation"
        argument={{
          where: {
            id: $uuid
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteArtistRelation: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
