/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
}>;

export const DeleteLanguageLocale = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_LANGUAGE_LOCALE">
      <SelectionSet
        name="deleteLanguage_title"
        alias="deleteLanguageLocale"
        argument={{
          where: {
            id: $uuid
          }
        }}
      >
        <Field name="id" alias="uuid" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteLanguageLocale: Readonly<{
      uuid: string;
    }>[];
  }>;
  const { errors, data } = await clients.language.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data;
};
