/** @jsx jsx */

import { Field, jsx, Mutation, render, SelectionSet, StringScalar, Variable } from "graphql-jsx";

import { ServiceType } from "constants/ServiceType";
import { ServiceTypeScalar } from "GraphQL/Scalars/ServiceTypeScalar";
import { clients } from "utils/clients";
import { TargetTableInputScalar } from "GraphQL/Scalars";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries/Access/DeleteAccessRecord";
import { Toast } from "lib/toast";

type Option = Readonly<{
  serviceType: ServiceType;
  kind: string;
  title: string;
  metadataIdList: readonly string[];
  moveTo: (playlistId: string) => void;
}>;

export const createPlaylist = async ({ serviceType, kind, title, metadataIdList, moveTo }: Option) => {
  const accessRecord = await getAccessRecord();
  if (!accessRecord) {
    return;
  }
  const playlistId = await commitPlaylist(serviceType, kind, title, metadataIdList);
  if (playlistId) {
    DeleteAccessRecord({ id: accessRecord });
    if (window.confirm("플레이리스트 생성에 성공했습니다! 해당 플레이리스트를 확인하시겠습니까?")) {
      moveTo(playlistId);
    }
  }
};

const getAccessRecord = async () => {
  const { query, variables } = render(
    <Mutation operationName="CREATE_PLAYLIST">
      <SelectionSet
        name="createAccess_record"
        alias="accessRecord"
        argument={{
          data: {
            target_table: <TargetTableInputScalar value={TargetTableInput.Users} />
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    accessRecord: {
      id: string;
    };
  };
  const response = await clients.access.request<Data>(query, variables);
  if (response.errors) {
    window.alert("플레이리스트 생성 권한을 요청하는 중 에러가 발생했습니다");
    return;
  }
  return response.data!.accessRecord.id;
};

const commitPlaylist = async (serviceType: ServiceType, kind: string, name: string, metadataIdList: readonly string[]) => {
  const $serviceType = <Variable name="serviceType" scalar={<ServiceTypeScalar isNullable={false} />} value={serviceType} />;
  const $kind = <Variable name="kind" scalar={<StringScalar isNullable={false} />} value={kind} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={name} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_PLAYLIST">
      <SelectionSet
        name="createPlaylist"
        alias="playlist"
        argument={{
          data: {
            service: $serviceType,
            kind: $kind,
            name: $name,
            playlist_title: {
              create: {
                language: {
                  connect: {
                    language_code: "OO"
                  }
                },
                type_title: "name",
                value: $name
              }
            },
            metadata_playlist_relation: {
              create: metadataIdList.map((metadataId, index) => ({
                exposure_order: index,
                metadata: {
                  connect: {
                    metadata_id: metadataId
                  }
                }
              }))
            }
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    playlist: {
      id: string;
    };
  };
  const { data, errors } = await clients.playlist.request<Data>(query, variables);
  if (errors) {
    console.log(errors[0].message);
    if (errors[0].message.includes("duplicate key value")) {
      Toast.error("플레이리스트 제목이 중복됩니다.", undefined, "top-center");
    } else {
      Toast.error("생성 도중 오류가 발생하였습니다.", undefined, "top-center");
    }
    return;
  }
  return data!.playlist.id;
};
