import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_SMALL_PX, MARGING_X_LARGE_PX } from "constants/size";
import { GRAY_4, BLUE_4 } from "constants/baseColor";
import { useAsyncEffect } from "lib/use-async-effect";
import { CreateAccessRecord, DeleteAccessRecord, CreateMetadataUrl } from "GraphQL/Queries";
import { YtvDownload } from "GraphQL/Queries/Access/YtvDownload";
import { TargetTableInput } from "constants/TargetTableInput";
import { UpdateMetadataUrl } from "GraphQL/Queries/Track";
import { FileActionType } from "GraphQL/Scalars/FileActionScalar";
import { Loading } from "App/Atomics/Loading";
import { GetMetadata } from "GraphQL/Queries/Metadata/GetMetadata";
import { Toast } from "lib/toast";

type Props = {
  metadataId: string;
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 42)};
  height: ${pixelize(UNIT * 16)};
  padding: ${PADDING_XX_LARGE_PX};

  input[type="text"] {
    margin: 1rem 0.5rem;
    padding: 1rem;
    border: 1.2px solid ${GRAY_4};
    transition: all 0.2s;
    &:focus {
      border-color: ${BLUE_4};
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${MARGING_X_LARGE_PX};

  h3 {
    font-size: 1.2rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: auto;
  margin-left: auto;
  align-items: center;

  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }

  svg {
    margin: 0.5rem;
  }
`;

type UrlState = {
  trackId?: string;
  serial?: string;
  coverUrl?: string;
  path?: string;
  coverUuid?: string;
  uuid?: string;
  ytvUrl?: string;
};

// * YTV upload order
// 1. 기존에 cover가 없다면, ytvUpload 이후 updateMetadata createURL로 cover데이터 추가
// 2. 기존에 cover가 있다면, ytvUpload 이후 updateMetadata로 order가 0이고, cover인 대상의 album_cover?timestamp=${timestamp}로 업데이트
// 3. 기존 cover가 있는데 다른 url을 사용하는 경우, updateMetadataUrl로 album_cover?timestamp=${timestamp}로 업데이트 이후 updateMetadataUrl로 track의 youtubeUrl 업데이트

export const YtvModal = ({ metadataId, toClose }: Props) => {
  const [urlState, setUrlState] = useState<UrlState>({} as UrlState);
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      const { data: albumData } = await GetMetadata({
        metadataId,
        typeClassIn: ["record", "theater"],
        typeSubClass: "album",
        detail: true
      });
      if (albumData) {
        const album = albumData.metadata[0];
        if (!album.structures.length) {
          Toast.error("트랙 정보를 가져올 수 없습니다", undefined, "top-center");
          toClose();
        }
        const serial = album.no;
        const path = album.rightsCompany[0].company[0].path;
        const coverUrl = album.metadataUrl.filter(({ typeUrl }) => typeUrl === "cover")[0];
        const track = album.structures[0].child[0]?.metadata[0]; // YTV는 음원이 1개이므로 0인덱스만 검색해도 무관.
        const youtubeUrl = !track ? undefined : track.metadataUrl.filter(({ typeUrl }) => typeUrl === "youtube");
        const mp3 = !track ? undefined : track.metadataUrl.filter(({ typeUrl }) => typeUrl === "mp3high");
        if (!youtubeUrl?.length || !mp3?.length) {
          Toast.error("음원 정보를 가져올 수 없습니다.");
          return;
        }
        if (!coverUrl) {
          Toast.error("앨범 커버URL이 존재하지 않습니다.");
          return;
        }
        if (!youtubeUrl[0]?.url?.includes("youtube.com")) {
          window.alert("YTV 업로드 파일이 아닙니다. Youtube Url을 확인해주세요.");
          toClose();
          return;
        }
        setUrlState({
          serial: serial!,
          path: path!,
          coverUuid: coverUrl.uuid,
          coverUrl: coverUrl.url!,
          trackId: track.metadataId,
          uuid: youtubeUrl[0].uuid,
          ytvUrl: youtubeUrl[0].url
        });
      }
    }
  }, []);

  const onYtvUpload = async () => {
    setLoading(true);
    const timestamp = Date.now();
    const { serial, path, coverUuid, coverUrl, trackId, uuid, ytvUrl } = urlState;
    try {
      const { errors } = await YtvDownload({
        url: ytvUrl!,
        path: path!,
        serial: serial!,
        action: !coverUrl ? FileActionType.INSERT : FileActionType.UPDATE,
        cover: !coverUrl
          ? "album_cover"
          : coverUrl.match(/[^\\/]+?(?=\.\w+$)/)?.[0] ??
            coverUrl
              .split("/")
              .pop()!
              .replace(/\.[^/.]+$/, "")
      });
      if (errors) {
        console.log(errors);
        window.alert("YTV 업로드에 실패하였습니다.");
        setLoading(false);
        return;
      }
    } catch (err) {
      const { data: accessData, errors: accessError } = await CreateAccessRecord({
        targetId: trackId!,
        targetTable: TargetTableInput.Metadata
      });
      const { data: accessData2, errors: accessError2 } = await CreateAccessRecord({
        targetId: metadataId!,
        targetTable: TargetTableInput.Metadata
      });
      if (accessError || accessError2) {
        console.log(accessError || accessError2);
        window.alert("액세스 권한 신청을 실패하였습니다.");
        setLoading(false);
        return;
      }
      if (accessData && accessData2) {
        const accessId = accessData.createAccess.id;
        const accessId2 = accessData2.createAccess.id;
        try {
          if (!coverUrl) {
            await CreateMetadataUrl({ metadataId, typeUrl: "cover", url: `${path}/${serial}/cover/album_cover`, order: 0 });
          } else {
            await UpdateMetadataUrl({ uuid: coverUuid!, url: `${path}/${serial}/cover/album_cover?timestamp=${timestamp}` });
            await UpdateMetadataUrl({ uuid: uuid!, url: ytvUrl! });
          }
        } catch (err) {
          console.log(err);
          await DeleteAccessRecord({ id: accessId });
          await DeleteAccessRecord({ id: accessId2 });
          window.alert("URL 업데이트에 실패하였습니다.");
          return;
        }
        await DeleteAccessRecord({ id: accessId });
        window.alert("변경되었습니다. 적용에는 1분 정도 소요됩니다.");
      }
      setLoading(false);
      return;
    }
  };
  return (
    <Layout>
      <Title>
        <h3>YTV 주소를 입력해주세요.</h3>
      </Title>
      <Input.Text
        key={urlState.ytvUrl}
        ref={inputRef}
        defaultValue={urlState.ytvUrl}
        placeholder={"예시) https://www.youtube.com/watch?v=XXXXXX"}
        onBlur={url => setUrlState(prev => ({ ...prev, ytvUrl: url }))}
      />
      <ButtonGroup>
        <Input.Button onClick={toClose}>취소</Input.Button>
        <Input.Button color="primary" disabled={loading} onClick={onYtvUpload}>
          실행
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};
