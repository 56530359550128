/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
}>;

export const DeleteArtistTitleRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_ARTIST_TITLE_RELATION">
      <SelectionSet
        name="deleteArtist_title"
        alias="deleteArtistTitleRelation"
        argument={{
          where: {
            id: $uuid
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteArtistTitleRelation: {
      id: string;
    }[];
  }>;

  return await clients.artist.request<Data>(query, variables);
};
