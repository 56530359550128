/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  companyId: string;
}>;

export const UpdateGoodsCompany = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar isNullable={false} />} value={option.uuid} />;
  const $companyId = <Variable name="company_id" scalar={<IdScalar isNullable={false} />} value={option.companyId} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS_COMPANY_RELATION">
      <SelectionSet
        name="updateGoods_company_relation"
        alias="updateGoodsCompany"
        argument={{
          where: { id: $id },
          data: {
            company: {
              connect: {
                company_id: $companyId
              }
            }
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoodsCompany: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
