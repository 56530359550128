import React from "react";
import styled from "styled-components";
import LoadingGif from "assets/images/ae_loading.gif";
type Props = {
  loading: boolean;
  dark?: boolean;
};

const Layout = styled.div<{ visible?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => (props.visible === "visible" ? "flex" : "none")};
  background-color: rgba(100, 100, 100, 0.15);
  background: url(${LoadingGif}) center center no-repeat;
  background-size: 146px 63px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 9999;
`;

export const Loading = ({ loading, dark = false }: Props) => {
  const isVisible = loading ? "visible" : undefined;
  // {!dark ? <LoadingIcon className="loading" /> : <LoadingDarkIcon className="loading" />}
  return <Layout visible={isVisible}></Layout>;
};
