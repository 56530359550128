import React, { useMemo } from "react";
import styled from "styled-components";

import { RoleActions } from "./Store/Role";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { LeftSideBar } from "./LeftSidebar";
import { RightRoleTable } from "./RightRoleTable";
import { RolesStoreProvider, useRolesDispatch } from "./Store";
import { Helmet } from "App/Atomics/Helmet";
import { GetRole } from "GraphQL/Queries/Role";
import { Toast } from "lib/toast";
import { useAppDispatch } from "App/Store";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Filters } from "App/Templates/FilterModalTemplate";

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

const ChildLayout = () => {
  const dispatch = useRolesDispatch();
  const dispatchApp = useAppDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchName = queryParams.get("name", { default: undefined });

  const filter = useMemo(() => {
    const { count, roleTypeClass, expose, validCheck } = JSON.parse(queryParams.get("filter", { default: undefined }) || "{}") as Filters;
    return { count, roleTypeClass, expose, validCheck };
  }, [queryParams]);
  const { count, roleTypeClass, expose, validCheck } = filter;
  const roleTypeClassIn = !roleTypeClass ? undefined : roleTypeClass.map(({ id }) => id);
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);

  useAsyncEffect(
    async isMounted => {
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      const { data: roleData, errors } = await GetRole({
        first: count ? +count : 20,
        page,
        id: searchId,
        name: searchName,
        typeClassIn: roleTypeClassIn,
        expose: expose === undefined ? undefined : +expose,
        validCheck: validCheckIn
      });

      if (errors) {
        Toast.error("페이지를 불러올 수 없습니다.");
        dispatchApp(LoadingAction.setLoading(LOADING.NULL));
        throw new Error(errors[0].message);
      }

      if (isMounted() && roleData) {
        dispatch([RoleActions.setRole(roleData.role), RoleActions.setEdge(roleData.edge)]);
        dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
      }
    },
    [page, searchId, searchName, filter]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightRoleTable />
    </Layout>
  );
};

export const Roles = () => (
  <RolesStoreProvider>
    <Helmet title="역할" />
    <ChildLayout />
  </RolesStoreProvider>
);
