export enum TargetTableInput {
  Goods = "goods",
  Product = "commodity",
  Metadata = "metadata",
  Artist = "artist",
  Genre = "genre",
  Role = "role",
  Company = "company",
  Users = "users",
  Playlist = "playlist",
  Language = "language",
  MetadataPlaylistRelation = "metadata_playlist_relation"
}
