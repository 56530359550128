import { Info } from "../../Atomics/AutoComplete/TextAutoComplete";
import { createActions, createHandler, createReducer } from "lib/store";
import { Account, BillInfo } from "GraphQL/Queries/Access/GetAccount";
import { TotalAccount } from "GraphQL/Queries/Access/GetTotalAccount";
import { CompanyUserRelation } from "GraphQL/Queries/Access/GetCompanyUserInfo";

type State = ReturnType<typeof createInitialState>;

export enum Page {
  SettleForm,
  TrackForm
}

const SET_SETTLE_ACCOUNT = Symbol("Settle::Account::Set");
const SET_SETTLE_TOTAL_ACCOUNT = Symbol("Settle::TotalAccount::Set");
const SET_SETTLE_PRODUCT_INFO = Symbol("Settle::ProductInfo::Set");
const SET_SETTLE_PAGE = Symbol("Settle::Page::Set");
const SET_SETTLE_COMPANY_INFO = Symbol("Settle::CompanyInfo::Set");
const SET_SETTLE_CURRENT_COMPANY_INFO = Symbol("Settle::CurrentCompanyInfo::Set");

export const SettleActions = createActions({
  setSettleAccount(account: Account[]) {
    return { type: SET_SETTLE_ACCOUNT, account };
  },
  setSettleTotalAccount(totalAccount: TotalAccount) {
    return { type: SET_SETTLE_TOTAL_ACCOUNT, totalAccount };
  },
  setSettleProductInfo(info: BillInfo) {
    return { type: SET_SETTLE_PRODUCT_INFO, info };
  },
  setSettlePage(page: Page) {
    return { type: SET_SETTLE_PAGE, page };
  },
  setSettleCompanyInfo(companyInfo: CompanyUserRelation[]) {
    return { type: SET_SETTLE_COMPANY_INFO, companyInfo };
  },
  setSettleCurrentCompanyInfo(info: Info | undefined) {
    return { type: SET_SETTLE_CURRENT_COMPANY_INFO, info };
  }
});

const handler = createHandler<State>({
  [SET_SETTLE_ACCOUNT](state, payload: { account: Account[] }) {
    state.account = payload.account;
  },
  [SET_SETTLE_TOTAL_ACCOUNT](state, payload: { totalAccount: TotalAccount }) {
    state.totalAccount = payload.totalAccount;
  },
  [SET_SETTLE_PRODUCT_INFO](state, payload: { info: BillInfo }) {
    state.productInfo = payload.info;
  },
  [SET_SETTLE_PAGE](state, payload: { page: Page }) {
    state.page = payload.page;
  },
  [SET_SETTLE_COMPANY_INFO](state, payload: { companyInfo: CompanyUserRelation[] }) {
    state.companyInfo = payload.companyInfo;
  },
  [SET_SETTLE_CURRENT_COMPANY_INFO](state, payload: { info: Info | undefined }) {
    state.currentCompanyInfo = payload.info;
  }
});

const createInitialState = () => ({
  account: [] as Account[],
  totalAccount: {} as TotalAccount,
  productInfo: {} as BillInfo,
  page: Page.SettleForm as Page,
  companyInfo: [] as CompanyUserRelation[],
  currentCompanyInfo: undefined as Info | undefined
});

export const reducer = createReducer(handler, createInitialState);
