export class Permission {
  user: number;
  group: number;
  other: number;

  constructor([user, group, other]: string[]) {
    this.user = +user;
    this.group = +group;
    this.other = +other;
  }

  isUserReadable() {
    return (this.user & 4) === 4;
  }
  isUserWritable() {
    return (this.user & 2) === 2;
  }
  isUserEditable() {
    return (this.user & 1) === 1;
  }
  isGroupReadable() {
    return (this.group & 4) === 4;
  }
  isGroupWritable() {
    return (this.group & 2) === 2;
  }
  isGroupEditable() {
    return (this.group & 1) === 1;
  }
  isOtherReadable() {
    return (this.other & 4) === 4;
  }
  isOtherWritable() {
    return (this.other & 2) === 2;
  }
  isOtherEditable() {
    return (this.other & 1) === 1;
  }
}
