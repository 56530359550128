import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { useAsyncEffect } from "lib/use-async-effect";
import { RightStoreProvider, useRightStore } from "./Store";
import { RightsRightTable } from "./RightsRightTable";
import { GetRights } from "GraphQL/Queries/Rights";
import { RightsActions } from "./Store/Right";
import { useQueryParams } from "lib/use-query-params";
import { useAppDispatch } from "App/Store";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;
  text-align: center;

  width: 100%;
  height: 100vh;

  table {
    width: 100%;
    margin-top: ${HEADER_HEIGHT_PX};
  }

  & form {
    align-self: right;
  }
`;

const ChildLayout = () => {
  const [{ count }, dispatch] = useRightStore(store => ({
    count: store.Right.count
  }));
  const dispatchApp = useAppDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchName = queryParams.get("name", { default: undefined });
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        const { data, errors } = await GetRights({ page, searchId, searchName, count });
        if (errors) {
          window.alert("서버에서 에러가 발생했습니다.");
          return;
        }
        if (data) {
          dispatch([RightsActions.setRight(data.rights), RightsActions.setEdge(data.edge)]);
          if (data.rights.length) {
            dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
          } else {
            dispatchApp(LoadingAction.setLoading(LOADING.NULL));
          }
        }
      }
    },
    [page, searchId, searchName, count]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightsRightTable />
    </Layout>
  );
};

export const Rights = () => (
  <>
    <RightStoreProvider>
      <Helmet title="권리사" />
      <ChildLayout />
    </RightStoreProvider>
  </>
);
