import { configs } from "configs";
import { GraphQLClient } from "lib/gql-client";
import { GraphQLClientHeader } from "./GraphQLClientHeader";

const createOption = (baseUrl: string): import("lib/gql-client/gql-client").GraphQLClientOption => ({
  baseUrl,
  getHeader: GraphQLClientHeader.get,
  isFailover: true
});

export const clients = {
  access: GraphQLClient.getInstance(createOption(configs.urls.access)),
  files: GraphQLClient.getInstance(createOption(configs.urls.files)),
  artist: GraphQLClient.getInstance(createOption(configs.urls.artist)),
  company: GraphQLClient.getInstance(createOption(configs.urls.company)),
  genre: GraphQLClient.getInstance(createOption(configs.urls.genre)),
  language: GraphQLClient.getInstance(createOption(configs.urls.language)),
  metadata: GraphQLClient.getInstance(createOption(configs.urls.metadata)),
  goods: GraphQLClient.getInstance(createOption(configs.urls.goods)),
  role: GraphQLClient.getInstance(createOption(configs.urls.role)),
  playlist: GraphQLClient.getInstance(createOption(configs.urls.playlist))
};
