import React, { useState, ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { DANGER_COLOR } from "constants/color";
import { useLoadGoodsSubadatas } from "App/Routes/ProductCreate/Hooks/useLoadGoodsSubdatas";
import styled from "styled-components";
import { Button } from "App/Atomics/Input/Button";
import { Tag } from "App/Atomics/Tag";
import { ProductDetailStore, useProductDetailSelector } from "App/Routes/ProductDetail/Store";
import { ProductDetailActions } from "App/Routes/ProductDetail/Store/ProductDetail";
import { v4 as uuidv4 } from "uuid";

type Props = {
  readonly goods?: Info;
};

export const ProductSubdataOption = ({ goods }: Props): ReactElement | null => {
  const { subdatas, productSubdata } = useProductDetailSelector(store => ({
    subdatas: store.ProductDetail.subdatas,
    productSubdata: store.ProductDetail.product.optionList
  }));
  const [selectOption, setSelectOption] = useState<Info | undefined>(undefined);
  useLoadGoodsSubadatas(goods?.id);
  const optionList = subdatas.map(({ id, field, value }) => ({ id, name: value, extra: field }));

  const onAddSubdata = (id: string) => {
    const subdata = subdatas.find(item => item.id === id);
    if (subdata) {
      const uuid = uuidv4();
      const option = {
        id: uuid,
        goods_subdata_unique: [subdata]
      };
      ProductDetailStore.dispatch(ProductDetailActions.addSubadata(option));
      setSelectOption(undefined);
    }
  };

  const onRemoveSubdata = (index: number) => {
    ProductDetailStore.dispatch(ProductDetailActions.removeSubadata(index));
  };

  return !goods || !optionList.length ? null : (
    <Style.Row>
      <Style.Caption color={DANGER_COLOR}>상품 옵션 * (변경 기능 준비 중)</Style.Caption>
      <Style.SubCaption>· 상품의 추가 옵션을 선택해주세요.</Style.SubCaption>
      <RowGroup>
        <Style.Select
          key={selectOption?.id}
          isDisabled={false}
          style={SelectStyle}
          defaultValue={selectOption}
          optionList={optionList}
          placeholder="추가 옵션"
          onChange={info => info && setSelectOption(info)}
        />
        <Button color="primary" onClick={() => selectOption && onAddSubdata(selectOption.id)}>
          추가
        </Button>
      </RowGroup>
      <SubdataGroup>
        {!productSubdata?.length
          ? null
          : productSubdata.map((data, index) => (
              <StyledTag key={data.id} onClick={() => onRemoveSubdata(index)}>
                {data.goods_subdata_unique[0].value}
              </StyledTag>
            ))}
      </SubdataGroup>
    </Style.Row>
  );
};

const RowGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 4rem;
  align-items: center;
  grid-gap: 8px;
`;

const SubdataGroup = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;
const StyledTag = styled(Tag)`
  margin-right: 8px;
  margin-bottom: 4px;
`;
