import React, { ReactNode, forwardRef } from "react";
import styled from "styled-components";
import { GRAY_5 } from "constants/baseColor";
import { PADDING_X_LARGE_PX } from "constants/size";

type Props = Styleable &
  Readonly<{
    children: ReactNode;
    mergeVerticalCount?: number;
    mergeHorizontalCount?: number;
  }>;

const Layout = styled.td`
  padding: ${PADDING_X_LARGE_PX};
  user-select: text;

  & + td {
    border-left: 1px dashed ${GRAY_5};
  }
`;

export const Data = forwardRef<HTMLTableDataCellElement, Props>(
  ({ children, style, className, mergeVerticalCount = 1, mergeHorizontalCount = 1 }, ref) => (
    <Layout ref={ref} style={style} className={className} colSpan={mergeHorizontalCount} rowSpan={mergeVerticalCount}>
      {children}
    </Layout>
  )
);
