import React, { Fragment } from "react";
import styled from "styled-components";
import uuidv4 from "uuid/v4";
import { Input } from "App/Atomics/Input";
import { Link } from "App/Atomics/Link";
import { Table } from "App/Atomics/Table";
import { Tooltip } from "App/Atomics/Tooltip";
import { useAppDispatch } from "App/Store";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import { UserRole } from "constants/UserRole";
import { Structure } from "App/Routes/PlaylistDetail/Store/PlaylistDetail";
import { MARGING_SMALL_PX } from "constants/size";
import { configs } from "configs";
import { ValidType } from "constants/ValidType";
import { Column } from "constants/Column";

type UrlData = {
  id: string;
  typeUrl: string;
  url: string;
};

type UrlProps = Readonly<{
  index: number;
  metadataUrl: any;
  title: string;
  userRole: UserRole;
  isService?: number;
  structures: Structure[];
  mainPerformer?: string;
  validCheck: ValidType;
  visibleField: string[];
}>;

const onDisableTrack = (userRole: UserRole | null, service?: number) => {
  if (userRole === UserRole.Partner && service === 2) {
    return true;
  }
  return false;
};

export const Url = ({ index, userRole, metadataUrl, title, structures, isService, mainPerformer, validCheck, visibleField }: UrlProps) => {
  const AudioDispatch = useAppDispatch();

  const playAudio = (audio: TypeAudio) => {
    AudioDispatch([
      AudioPlayerActions.toggleVisible(true),
      AudioPlayerActions.setAudioData(audio),
      AudioPlayerActions.addAudioInPlaylist(audio)
    ]);
  };

  if (!metadataUrl?.length) {
    return <Table.Data>{}</Table.Data>;
  }
  return (
    <Table.Data key={index} style={{ width: "8%" }}>
      <div onClick={e => e.stopPropagation()}>
        {metadataUrl.map((urlData: UrlData, urlIndex: number) => {
          if (urlData.typeUrl === "youtube") return null;
          if (urlData.typeUrl === "source" && userRole === UserRole.Partner) return <Fragment key={`${urlIndex}+${urlData.id}`}></Fragment>;
          if (urlData.typeUrl === "zip" || urlData.typeUrl === "txt") {
            const downloadUrl = `${configs.urls.audio}/${urlData.url}`;
            return !urlData.url || !visibleField.includes(Column.file) ? null : (
              <Link.External key={`${urlIndex}+${urlData.id}`} style={{ width: "100%", margin: "4px 0" }} href={downloadUrl}>
                <Input.Button color={isService === 2 ? "info" : "primary"} isWide>
                  {`${urlData.typeUrl} 다운`}
                </Input.Button>
              </Link.External>
            );
          }
          if (urlData.typeUrl === "source") {
            return (
              <AudioButtonGroup key={`${urlIndex}+${urlData.id}`}>
                <Tooltip text={urlData.url}>
                  <Link.External style={{ width: "100%" }} target={"_blank"} href={`${urlData.url}`}>
                    <Input.Button color="danger" isWide>
                      이동
                    </Input.Button>
                  </Link.External>
                </Tooltip>
              </AudioButtonGroup>
            );
          }
          return (
            <AudioButtonGroup key={`${urlIndex}+${urlData.id}`}>
              <Input.Button
                title={isService === 2 ? "사용이 중지된 음원입니다." : "음원 듣기"}
                disabled={onDisableTrack(userRole, isService)}
                color={userRole === UserRole.Master && isService === 2 ? "info" : "primary"}
                isWide
                onClick={() => {
                  const audioUuid = uuidv4();
                  const audioData = { title, url: urlData.url, type: urlData.typeUrl, uuid: audioUuid, artist: mainPerformer } as TypeAudio;
                  audioData.coverUrl = structures[0]?.list[0]?.metadata[0]?.metadataUrl[0]?.url;
                  playAudio(audioData);
                }}
              >
                {urlData.typeUrl === "mp3high" ? "mp3" : urlData.typeUrl} 듣기
              </Input.Button>
            </AudioButtonGroup>
          );
        })}
      </div>
    </Table.Data>
  );
};

const AudioButtonGroup = styled.div`
  & + & {
    margin-top: ${MARGING_SMALL_PX};
  }
`;
