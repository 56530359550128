export enum MetadataPlaylistRelationOrderByInput {
  playlist_id_ASC = "playlist_id__ASC",
  playlist_id_DESC = "playlist_id__DESC",
  id_ASC = "id__ASC",
  id_DESC = "id__DESC",
  metadata_id_ASC = "metadata_id__ASC",
  metadata_id_DESC = "metadata_id__DESC",
  exposure_order_ASC = "exposure_order__ASC",
  exposure_order_DESC = "exposure_order__DESC"
}
