import { ProductCreateStore } from "App/Routes/ProductCreate/Store";
import { ProductCreateActions } from "App/Routes/ProductCreate/Store/ProductCreate";
import { LoadGoodsSubdatas } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";

export const useLoadGoodsSubadatas = (goodsId?: string) => {
  useAsyncEffect(async () => {
    if (goodsId) {
      const { data, errors } = await LoadGoodsSubdatas({ id: goodsId });
      if (errors || !data) {
        Toast.error("굿즈 타입을 불러올 수 없습니다.");
        return;
      }
      ProductCreateStore.dispatch(ProductCreateActions.setGoodsSubadatas(data.goodsSubdatas));
    }
  }, [goodsId]);
  return;
};
