/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const GetPlaylistVisibleField = async (option: Option) => {
  const $id = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_VISIBLE_FIELD">
      <SelectionSet
        name="playlist"
        argument={{
          first: 1,
          group: true,
          where: {
            playlist_id: $id
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="name" />
        <SelectionSet name="playlist_users_relation" alias="userRelation" argument={{ where: { is_onwer: false } }}>
          <Field name="id" alias="uuid" />
          <Field name="visible_field" alias="visibleField" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    playlist: Readonly<{
      id: string;
      name: string;
      userRelation: {
        uuid: string;
        visibleField: string[] | null;
      }[];
    }>[];
  }>;

  const { errors, data } = await clients.playlist.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  return data!.playlist[0].userRelation;
};
