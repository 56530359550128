/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, EnumScalar, ListScalar, IdScalar } from "graphql-jsx";

import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

export const findWorkList = async (keyword: string, workSubclass?: string) => {
  let $id = undefined;
  if (keyword.startsWith("$")) {
    const searchId = keyword.match(/\$([0-9]+)/)?.[1];
    if (searchId) {
      $id = <Variable name="id" scalar={<IdScalar />} value={searchId} />;
    }
  }
  let $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  let $backword = undefined;
  if (keyword.includes("+")) {
    $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword.split("+")[0]} />;
    $backword = <Variable name="backword" scalar={<StringScalar />} value={keyword.split("+")[1]} />;
  }
  const $workSubClass = <Variable name="work_sub_class" scalar={<StringScalar isNullable />} value={workSubclass} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="WORK_SEARCHED_LIST">
      <SelectionSet
        name="metadata_title"
        alias="list"
        argument={{
          first: 40,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Metadata_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            ...($id
              ? {
                  metadata__some: {
                    metadata_id: $id,
                    type_metadata_class: "work",
                    type_metadata_subclass: $workSubClass,
                    valid_check__not: $remove
                  }
                }
              : {
                  metadata__some: {
                    type_metadata_class: "work",
                    type_metadata_subclass: $workSubClass,
                    valid_check__not: $remove,
                    metadata_artist_relation__some: {
                      artist__some: {
                        name__starts_with: $backword
                      }
                    }
                  },
                  type_metadata_title__starts_with: "name",
                  tsvec_words__search: $keyword
                })
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
          <Field name="title" alias="name" />
          <SelectionSet name="metadata_artist_relation" alias="artistRelation" argument={{ where: { role__some: { role_id: 343 } } }}>
            <SelectionSet name="artist">
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
          <SelectionSet name="metadata_structure" alias="structures">
            <Field name="structure_id" alias="id" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: {
      metadata_id: string;
      metadata: {
        id: string;
        name: string;
        artistRelation: ReadonlyArray<{
          artist: ReadonlyArray<{
            name: string;
          }>;
        }>;
        structures: ReadonlyArray<{
          id: string;
        }>;
      }[];
    }[];
  }>;
  const response = await clients.metadata.request<Data>(query, variables);
  if (response.data) {
    try {
      let dataList = response.data.list.map(({ metadata }) => {
        const { id, name, artistRelation, structures } = metadata[0];
        const artist = artistRelation[0]?.artist[0].name ?? "-";
        return {
          id,
          name,
          extra: {
            artist,
            structureId: structures[0].id
          }
        };
      });
      return dataList;
    } catch (error) {
      console.error(error);
    }
    return [];
  } else {
    return [];
  }
};
