import React, { useState } from "react";
import styled from "styled-components";
import { ImageCrop } from "App/Molecules/ImageCrop";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_2, GRAY_6, GRAY_1 } from "constants/baseColor";
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { ImageTypeOptionInfo } from "constants/ImageTypeOptionInfo";
import { useAsyncEffect } from "lib/use-async-effect";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAlbumStore } from "../../Store";
import { AlbumActions } from "../../Store/Album";
import { MediaType } from "GraphQL/Scalars/MediaType";
import { Loading } from "App/Atomics/Loading";
import { handleCreateMetadataUrl, handleUpdateFile, handleUpdateMetadataUrl, handleUploadFile } from "GraphQL/Handler";

type Props = {
  index: number;
  uuid?: string;
  src: string;
  ext: string;
  typeUrl?: string;
  url?: string;
  file?: File;
  action: string;
  toClose: () => void;
};

export const ImageCropModal = ({ index, uuid, src, ext, typeUrl = "cover", url, file, action, toClose }: Props) => {
  const [imageType, setImageType] = useState<string | undefined>(typeUrl);
  const [imageUrl, setImageUrl] = useState<string | undefined>(src);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [{ album }, dispatch] = useAlbumStore(store => ({
    album: store.Album.albums[index]
  }));

  const onSave = async (typeUrl: string, blob: Blob | File, ext: string) => {
    if (!album.rightsCompany.length) {
      window.alert("권리사 정보가 없습니다.");
      return;
    }
    const company = album.rightsCompany[0].company;
    const timestamp = Date.now();
    const fileName =
      action === "UPDATE" && url
        ? `${url
            .split("/")
            .splice(2)
            .join("/")
            .replace(/\.[^/.]+$/, "")}`.replace(/\?.+/, "")
        : `${album.metadataId}/cover/${uuidv4()}`;
    const order =
      !album.metadataUrl.length || !album.metadataUrl.filter(url => url.typeUrl === typeUrl).length
        ? 0
        : (_.last(album.metadataUrl.filter(url => url.typeUrl === typeUrl))?.order ?? 0) + 1;

    try {
      setLoading(true);
      if (action === "UPDATE") {
        const updateFile = await handleUpdateFile(fileName, company[0].id, blob, "image/jpeg", MediaType.image);
        await handleUpdateMetadataUrl(
          album.metadataId,
          TargetTableInput.Metadata,
          uuid!,
          typeUrl,
          `${updateFile.key}?timestamp=${timestamp}`
        );
        dispatch(
          AlbumActions.updateAlbumImage({
            index,
            uuid: uuid!,
            typeUrl: typeUrl,
            url: `${updateFile.key}?timestamp=${timestamp}`
          })
        );
      } else {
        const uploadFile = await handleUploadFile(fileName, company[0].id, blob, "image/jpeg");
        const uuid = await handleCreateMetadataUrl(album.metadataId, TargetTableInput.Metadata, typeUrl!, uploadFile.key, order);
        dispatch(
          AlbumActions.createAlbumImage({
            index,
            uuid: uuid!,
            typeUrl: typeUrl!,
            url: uploadFile.key,
            order
          })
        );
      }
      Toast.primary("이미지가 변경되었습니다.", undefined, "bottom-right");
      setLoading(false);
      toClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
      return;
    }
  };

  useAsyncEffect(
    isMounted => {
      if (isMounted()) {
        if (imageBlob) {
          const urlCreator = window.URL || window.webkitURL;
          setImageUrl(urlCreator.createObjectURL(imageBlob));
        }
      }
    },
    [imageBlob]
  );

  return (
    <Layout>
      <Header>
        <h2>앨범 이미지 업데이트</h2>
        <CancelIcon onClick={toClose} />
      </Header>
      <SelectContainer>
        <LabelBox>
          <label>
            * <span>이미지 타입</span>을 선택해주세요.
          </label>
          <TextSelect
            optionList={ImageTypeOptionInfo}
            defaultValue={imageType ? { id: imageType, name: imageType } : undefined}
            onChange={info => {
              if (info) {
                setImageType(info.id);
              }
            }}
          />
        </LabelBox>
        <div className="img-box">
          <h4>미리보기</h4>
          <img src={imageUrl} className={imageType === "head" ? "wide" : "normal"} alt="preview" />
        </div>
      </SelectContainer>
      <ImageCrop
        src={src}
        style={{ width: "100%", height: 480 }}
        aspectRatio={imageType === "head" ? 16 / 9 : 1}
        preview={".preview"}
        minWidth={300}
        minHeight={300}
        onCropping={() => setLoading(true)}
        onCropEnd={blob => {
          setImageBlob(blob);
          setLoading(false);
        }}
      />
      <Footer>
        <span>드래그로 이미지를 편집하실 수 있습니다.</span>
        <ButtonGroup>
          <Input.Button
            disabled={!imageType || !imageBlob || loading}
            className="btn"
            color="primary"
            onClick={() => onSave(imageType!, imageBlob!, ext)}
          >
            저장
          </Input.Button>
          <Input.Button
            disabled={!imageType || !imageBlob || loading}
            className="origin-btn"
            color="secondary"
            onClick={() => onSave(imageType!, file!, ext)}
          >
            원본 이미지 업로드
          </Input.Button>
          <Input.Button className="btn" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Footer>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 1024px;
  height: 800px;
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 10%;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    right: 3%;
    width: 35px;
    height: 35px;
    padding: 0.5rem;
    fill: black;
    border-radius: 50%;
    background-color: ${GRAY_2};
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
      background-color: ${GRAY_1};
    }
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;

  .img-box {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 0.9rem;
    }
    img {
      width: 90px;
      height: 90px;
      border: 1px dotted rgba(0, 0, 0, 0.1);
    }
    .wide {
      width: 160px;
    }
  }
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
    span {
      font-weight: 600;
      color: ${PRIMARY_COLOR};
    }
  }
`;

const TextSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 20)};
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  background-color: #fff;

  span {
    text-align: center;
    color: ${PRIMARY_COLOR_LIGHT};
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  margin-right: 1rem;
  .btn {
    width: ${pixelize(UNIT * 8)};
    margin-right: 0.5rem;
  }
  .origin-btn {
    width: ${pixelize(UNIT * 12)};
    margin-right: 0.5rem;
  }
`;
