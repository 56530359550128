import { LOADING } from "App/Templates/LoadingTemplate";
import { ValidType } from "constants/ValidType";
import { Title } from "GraphQL/Queries/Artist/GetArtistTitleRelation";
import { createDuck } from "lib/store/v2";
import { Edge } from "models/Edge";
import _ from "lodash";
import { Artist, GenreRelation, RoleRelation, Url } from "GraphQL/Queries/Artist/GetArtistRenewal";

const createInitialState = () => ({
  edge: null as null | Edge,
  artists: [] as Artist[],
  origin: [] as Artist[],
  loading: LOADING.ONLOAD as LOADING,
  editLoading: false as boolean,
  titleRelation: [] as Title[],
  modalId: "" as string
});

type Genre = {
  id: string;
  name: string;
  typeKind: string;
};

type Role = {
  id: string;
  name: string;
};

export const ArtistActions = createDuck({
  namespace: "Artist",
  createInitialState,
  reducers: {
    setLoading(state, loading: LOADING) {
      state.loading = loading;
    },
    setEditLoading(state, loading: boolean) {
      state.editLoading = loading;
    },
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setArtists(state, podcasts: Artist[]) {
      state.artists = podcasts;
      state.origin = podcasts;
    },
    deleteArtist(state, index: number) {
      state.artists.splice(index, 1);
    },
    setArtistClear(state) {
      state.artists = state.origin;
    },
    setArtistSave(state) {
      state.origin = state.artists;
    },
    setArtistGenre(state, { index, genre }: { index: number; genre: GenreRelation[] }) {
      state.artists[index].genreRelation = genre;
    },
    updateArtistName(state, { index, name }: { index: number; name: string }) {
      state.artists[index].name = name;
    },
    updateWeight(state, { index, weight }: { index: number; weight: string }) {
      state.artists[index].weight = weight;
    },
    updateArtistMemo(state, { index, memo }: { index: number; memo: string }) {
      state.artists[index].memo = memo;
    },
    updateArtistVC(state, { index, vc }: { index: number; vc: ValidType }) {
      state.artists[index].validCheck = vc;
    },
    createArtistImage(
      state,
      { index, uuid, typeUrl, url, order }: { index: number; uuid: string; typeUrl: string; url: string; order: number }
    ) {
      if (!state.artists[index].artistUrl.length) {
        state.artists[index].artistUrl = [{ uuid, typeUrl, url, validCheck: ValidType.V3, order }];
      } else {
        state.artists[index].artistUrl.push({
          uuid,
          typeUrl,
          url,
          validCheck: ValidType.V3,
          order
        });
      }
    },
    updateArtistImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.artists[index].artistUrl.findIndex((url: any) => url.uuid === uuid);
      state.artists[index].artistUrl[urlIndex].typeUrl = typeUrl;
      state.artists[index].artistUrl[urlIndex].url = url;
    },
    createArtistUrl(state, { index, url }: { index: number; url: Url }) {
      if (!state.artists[index].artistUrl.length) {
        state.artists[index].artistUrl = [url];
      } else {
        state.artists[index].artistUrl.push(url);
      }
    },
    updateArtistTypeUrl(state, { index, urlIndex, typeUrl }: { index: number; urlIndex: number; typeUrl: string }) {
      const order = (_.last(state.artists[index].artistUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.artists[index].artistUrl[urlIndex].typeUrl = typeUrl;
      state.artists[index].artistUrl[urlIndex].order = order;
    },
    updateArtistUrl(state, { index, urlIndex, url }: { index: number; urlIndex: number; url: string }) {
      state.artists[index].artistUrl[urlIndex].url = url;
    },
    updateArtistUrlUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { artistUrl } = state.artists[index];
      const urlIndex = artistUrl.findIndex(({ uuid }) => uuid === prevUuid);
      artistUrl[urlIndex].uuid = uuid;
    },
    deleteArtistUrl(state, { index, urlIndex }: { index: number; urlIndex: number }) {
      state.artists[index].artistUrl.splice(urlIndex, 1);
    },

    createArtistGenre(state, { index, genre }: { index: number; genre: GenreRelation }) {
      if (!state.artists[index].genreRelation.length) {
        state.artists[index].genreRelation = [genre];
      } else {
        state.artists[index].genreRelation.push(genre);
      }
    },
    updateArtistGenre(state, { index, genreIndex, genre }: { index: number; genreIndex: number; genre: Genre }) {
      state.artists[index].genreRelation[genreIndex].genre = [genre];
    },
    updateArtistGenreUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { genreRelation } = state.artists[index];
      const genreIndex = genreRelation.findIndex(({ uuid }) => uuid === prevUuid);
      genreRelation[genreIndex].uuid = uuid;
    },
    deleteArtistGenre(state, { index, genreIndex }: { index: number; genreIndex: number }) {
      state.artists[index].genreRelation.splice(genreIndex, 1);
    },

    createArtistRole(state, { index, role }: { index: number; role: RoleRelation }) {
      if (!state.artists[index].roleRelation.length) {
        state.artists[index].roleRelation = [role];
      } else {
        state.artists[index].roleRelation.push(role);
      }
    },
    updateArtistRole(state, { index, roleIndex, role }: { index: number; roleIndex: number; role: Role }) {
      state.artists[index].roleRelation[roleIndex].role = [role];
    },
    updateArtistRoleUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { roleRelation } = state.artists[index];
      const roleIndex = roleRelation.findIndex(({ uuid }) => uuid === prevUuid);
      roleRelation[roleIndex].uuid = uuid;
    },
    deleteArtistRole(state, { index, roleIndex }: { index: number; roleIndex: number }) {
      state.artists[index].roleRelation.splice(roleIndex, 1);
    },

    setArtistTitleRelation(state, titleRelation: Title[]) {
      state.titleRelation = titleRelation;
    },
    createArtistTitleRelation(
      state,
      {
        uuid,
        typeArtistTitle,
        value,
        languageCode,
        order
      }: { uuid: string; typeArtistTitle: string; value: string; languageCode: string; order: number }
    ) {
      const newTitle = {
        uuid,
        typeArtistTitle,
        value,
        validCheck: ValidType.V3,
        order,
        language: [
          {
            languageCode
          }
        ]
      };
      state.titleRelation.push(newTitle);
    },
    updateArtistTitleRelation(
      state,
      { index, typeArtistTitle, value, languageCode }: { index: number; typeArtistTitle: string; value: string; languageCode: string }
    ) {
      state.titleRelation[index].typeArtistTitle = typeArtistTitle;
      state.titleRelation[index].value = value;
      state.titleRelation[index].language[0].languageCode = languageCode;
    },
    deleteArtistTitleRelation(state, uuid: string) {
      const index = state.titleRelation.findIndex(title => title.uuid === uuid);
      state.titleRelation.splice(index, 1);
    },
    setArtistTitleRelationModalId(state, uuid: string) {
      state.modalId = uuid;
    },
    setArtistBirthDate(state, { index, birthDate }: { index: number; birthDate: string }) {
      if (!state.artists[index].artistHistory.length) {
        state.artists[index].artistHistory.push({ uuid: "new", birthDate: `${birthDate}` });
      } else {
        state.artists[index].artistHistory[0].birthDate = `${birthDate}`;
      }
    },
    setArtistDeathDate(state, { index, deathDate }: { index: number; deathDate: string }) {
      if (!state.artists[index].artistHistory.length) {
        state.artists[index].artistHistory.push({ uuid: "new", deathDate: `${deathDate}` });
      } else {
        state.artists[index].artistHistory[0].deathDate = `${deathDate}`;
      }
    },
    setArtistBirthPlace(state, { index, birthPlace }: { index: number; birthPlace: string }) {
      if (!state.artists[index].artistHistory.length) {
        state.artists[index].artistHistory.push({ uuid: "new", birthPlace: `${birthPlace}` });
      } else {
        state.artists[index].artistHistory[0].birthPlace = `${birthPlace}`;
      }
    },
    setArtistDeathPlace(state, { index, deathPlace }: { index: number; deathPlace: string }) {
      if (!state.artists[index].artistHistory.length) {
        state.artists[index].artistHistory.push({ uuid: "new", deathPlace: `${deathPlace}` });
      } else {
        state.artists[index].artistHistory[0].deathPlace = `${deathPlace}`;
      }
    },
    updateArtistHistoryUuid(state, { index, uuid }: { index: number; uuid: string }) {
      const { artistHistory } = state.artists[index];
      artistHistory[0].uuid = uuid;
    }
  }
});
