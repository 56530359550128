import { createDuck } from "lib/store/v2";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";

type State = ReturnType<typeof createInitialState>;

export type ArtistEditEvents = State["events"];

type Artist = any;

type ID = string;
type ArtistId = ID;

type CreateEvent = {
  type: "Create";
  column?: string;
  from?: string;
  to: string;
  order?: number;
};

type UpdateEvent = {
  type: "Update";
  column?: string;
  from: string;
  to: string;
  order?: number;
};

type RemoveEvent = {
  type: "Remove";
  column: string;
  from?: string;
  to?: string;
  order?: number;
};

type EventLog = CreateEvent | UpdateEvent | RemoveEvent;
const createInitialState = () => {
  const state = {
    artists: [] as Artist[],
    events: {
      artists: new Map<ArtistId, Record<string, EventLog>>(),
      titleRelation: new Map<ArtistId, Record<string, Map<string, EventLog>>>(),
      urlRelation: new Map<ArtistId, Record<string, EventLog>>(),
      genreRelation: new Map<ArtistId, Record<string, EventLog>>(),
      historyRelation: new Map<ArtistId, Record<string, Map<string, EventLog>>>(),
      roleRelation: new Map<ArtistId, Record<string, EventLog>>()
    }
  };
  return state;
};

export const ArtistEditActions = createDuck({
  namespace: "ArtistEdit",
  createInitialState,
  reducers: {
    // artist getData
    setArtist(state, artists: any[]) {
      state.artists = artists;
    },

    // artist update
    setNameByIndex(state, { index, name }: { index: number; name: string }) {
      const artist = state.artists[index];
      const { artistId } = artist;
      const { artists } = state.events;
      updateEventLog(artistId, "", artists, "name", artist.name, name);
      artist.name = name;
    },
    setThumbUrlByIndex(state, { index, thumbUrl }: { index: number; thumbUrl: string }) {
      const artist = state.artists[index];
      const { artistId } = artist;
      const { artists } = state.events;
      updateEventLog(artistId, "", artists, "thumb_url", artist.name, thumbUrl);
      artist.thumbUrl = thumbUrl;
    },
    setValidCheckByIndex(state, { index, validCheck }: { index: number; validCheck: string }) {
      const artist = state.artists[index];
      const { artistId } = artist;
      const { artists } = state.events;
      updateEventLog(artistId, "", artists, "valid_check", artist.validCheck, validCheck);
      artist.validCheck = validCheck;
    },
    setTypeByIndex(state, { index, typeArtistClass }: { index: number; typeArtistClass: string }) {
      const artist = state.artists[index];
      const { artistId } = artist;
      const { artists } = state.events;
      updateEventLog(artistId, "", artists, "type_artist_class", artist.name, typeArtistClass);
      artist.typeArtistClass = typeArtistClass;
    },

    // relation title update
    setTitleByIndex(
      state,
      { index, relationIndex, column, title }: { index: number; relationIndex: number; column: string; title: string }
    ) {
      const artist = state.artists[index];
      const { artistId, titles } = artist;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { titleRelation } = state.events;
      updateMapEventLog(artistId, relationId, titleRelation, column, relation.typeArtistTitle, title);
      relation.typeArtistTitle = title;
    },
    setValueByIndex(
      state,
      { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: string }
    ) {
      const artist = state.artists[index];
      const { artistId, titles } = artist;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { titleRelation } = state.events;
      updateMapEventLog(artistId, relationId, titleRelation, column, relation.typeArtistTitle, value);
      relation.value = value;
    },
    setLanguageByIndex(
      state,
      { index, relationIndex, column, language }: { index: number; relationIndex: number; column: string; language: string }
    ) {
      const artist = state.artists[index];
      const { artistId, titles } = artist;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { titleRelation } = state.events;
      updateMapEventLog(artistId, relationId, titleRelation, column, relation.language[0].languageCode, language);
      relation.language[0].languageCode = language;
    },

    // relation url update
    setArtistUrlTypeByIndex(
      state,
      { index, relationIndex, column, typeUrl }: { index: number; relationIndex: number; column: string; typeUrl: string }
    ) {
      const artist = state.artists[index];
      const { artistId, artistUrls } = artist;
      const relation = artistUrls[relationIndex];
      const { relationId } = relation;
      const { urlRelation } = state.events;

      updateEventLog(artistId, relationId, urlRelation, column, relation.typeUrl, typeUrl);
      relation.typeUrl = typeUrl;
    },
    setArtistUrlByIndex(
      state,
      { index, relationIndex, column, url }: { index: number; relationIndex: number; column: string; url: string }
    ) {
      const artist = state.artists[index];
      const { artistId, artistUrls } = artist;
      const relation = artistUrls[relationIndex];
      const { relationId } = relation;
      const { urlRelation } = state.events;

      updateEventLog(artistId, relationId, urlRelation, column, relation.url, url);
      relation.url = url;
    },

    // relation genre update
    setGenreByIndex(state, { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: Info }) {
      const artist = state.artists[index];
      const { artistId, genreRelation } = artist;
      const relation = genreRelation[relationIndex];
      const { relationId, genre, order } = relation;
      const { id: genreId } = genre;
      const { genreRelation: genreEvents } = state.events;

      updateEventLog(artistId, relationId, genreEvents, column, genreId, value.id, order);
      relation.genre = [
        {
          id: value.id || genreId,
          name: value.name,
          type: "genre"
        }
      ];
    },

    // relation history update
    setBirthDateByIndex(
      state,
      { index, relationIndex, column, date }: { index: number; relationIndex: number; column: string; date: string }
    ) {
      const artist = state.artists[index];
      const { artistId, history } = artist;
      const { historyRelation } = state.events;
      if (!history.length) {
        history.push({
          birthDate: "",
          birthPlace: "",
          deathDate: "",
          deathPlace: ""
        });
        createMapEventLog(artistId, "", historyRelation, column, "", date);
        history[relationIndex].birthDate = date;
      } else {
        const relation = history[relationIndex];
        const { relationId } = relation;
        updateMapEventLog(artistId, relationId, historyRelation, column, relation.birthDate, date);
        relation.birthDate = date;
      }
    },
    setBirthPlaceByIndex(
      state,
      { index, relationIndex, column, place }: { index: number; relationIndex: number; column: string; place: string }
    ) {
      const artist = state.artists[index];
      const { artistId, history } = artist;
      const { historyRelation } = state.events;
      if (!history.length) {
        history.push({
          birthDate: "",
          birthPlace: "",
          deathDate: "",
          deathPlace: ""
        });
        createMapEventLog(artistId, "", historyRelation, column, "", place);
        history[relationIndex].birthPlace = place;
      } else {
        const relation = history[relationIndex];
        const { relationId } = relation;
        updateMapEventLog(artistId, relationId, historyRelation, column, relation.birthPlace, place);
        relation.birthPlace = place;
      }
    },
    setDeathDateByIndex(
      state,
      { index, relationIndex, column, date }: { index: number; relationIndex: number; column: string; date: string }
    ) {
      const artist = state.artists[index];
      const { artistId, history } = artist;
      const { historyRelation } = state.events;
      if (!history.length) {
        history.push({
          birthDate: "",
          birthPlace: "",
          deathDate: "",
          deathPlace: ""
        });
        createMapEventLog(artistId, "", historyRelation, column, "", date);
        history[relationIndex].deathDate = date;
      } else {
        const relation = history[relationIndex];
        const { relationId } = relation;
        updateMapEventLog(artistId, relationId, historyRelation, column, relation.deathDate, date);
        relation.deathDate = date;
      }
    },
    setDeathPlaceByIndex(
      state,
      { index, relationIndex, column, place }: { index: number; relationIndex: number; column: string; place: string }
    ) {
      const artist = state.artists[index];
      const { artistId, history } = artist;
      const { historyRelation } = state.events;
      if (!history.length) {
        history.push({
          birthDate: "",
          birthPlace: "",
          deathDate: "",
          deathPlace: ""
        });
        createMapEventLog(artistId, "", historyRelation, column, "", place);
        history[relationIndex].deathPlace = place;
      } else {
        const relation = history[relationIndex];
        const { relationId } = relation;
        updateMapEventLog(artistId, relationId, historyRelation, column, relation.deathPlace, place);
        relation.deathPlace = place;
      }
    },

    // relation role update
    setRoleByIndex(state, { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: Info }) {
      const artist = state.artists[index];
      const { artistId, roleRelation } = artist;
      const relation = roleRelation[relationIndex];
      const { relationId, role } = relation;
      const { roleId } = role;
      const { roleRelation: roleEvents } = state.events;

      updateEventLog(artistId, relationId, roleEvents, column, roleId, value.id);
      relation.role = [
        {
          roleId: value.id || roleId,
          name: value.name
        }
      ];
    },
    setTypeRoleClassByIndex(
      state,
      { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: string }
    ) {
      const artist = state.artists[index];
      const { artistId, role } = artist;
      const relation = role[relationIndex];
      const { relationId } = relation;
      const { roleId } = relation.role[0];
      const { roleRelation } = state.events;

      updateEventLog(artistId, relationId, roleRelation, column, roleId, value);
      relation.role[0].typeRoleClass = value;
    },
    setCountByIndex(
      state,
      { index, relationIndex, column, count }: { index: number; relationIndex: number; column: string; count: string }
    ) {
      const artist = state.artists[index];
      const { artistId, role } = artist;
      const relation = role[relationIndex];
      const { relationId } = relation;
      const { roleId } = relation.role[0];
      const { roleRelation } = state.events;

      updateEventLog(artistId, relationId, roleRelation, column, roleId, count);
      relation.role[0].roleCount = count;
    },

    // create
    setCreateTitleByindex(state, { index, column }: { index: number; column: string }) {
      const artist = state.artists[index];
      const { artistId, titles } = artist;
      const { artists } = state.events;
      const emptyTitle = {
        relationId: column + titles.length.toString(),
        typeArtistTitle: undefined,
        language: [{ languageCode: undefined, name: undefined }]
      };
      createEventLog(artistId, column + titles.length.toString(), artists, column, "", index.toString());
      titles.push(emptyTitle);
    },
    setCreateUrlByIndex(state, { index, column }: { index: number; column: string }) {
      const artist = state.artists[index];
      const { artistId, artistUrls } = artist;
      const { artists } = state.events;
      const relationId = column + artistUrls.length.toString();
      const emptyUrl = {
        relationId: relationId,
        typeUrl: undefined,
        url: undefined
      };

      createEventLog(artistId, relationId, artists, column, "", index.toString());
      artistUrls.push(emptyUrl);
    },
    setCreateGenreByIndex(state, { index, column, order }: { index: number; column: string; order: number }) {
      const artist = state.artists[index];
      const { artistId, genreRelation } = artist;
      const { artists } = state.events;
      const emptyGenre = {
        relationId: column + genreRelation.length.toString(),
        genre: [{ id: "", name: "" }],
        order: order
      };
      createEventLog(artistId, column + genreRelation.length.toString(), artists, column, "", index.toString(), order);
      genreRelation.push(emptyGenre);
    },
    setCreateRoleByIndex(state, { index, column }: { index: number; column: string }) {
      const artist = state.artists[index];
      const { artistId, roleRelation } = artist;
      const { artists } = state.events;
      const emptyRole = {
        relationId: column + roleRelation.length.toString(),
        role: { name: undefined, roleId: undefined, typeRoleClass: undefined }
      };
      createEventLog(artistId, column + roleRelation.length.toString(), artists, column, "", index.toString());
      roleRelation.push(emptyRole);
    },

    // remove
    setRemoveArtistTitleByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const artist = state.artists[index];
      const { artistId, titles } = artist;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { artists, titleRelation } = state.events;

      if (artists.get(artistId) && artists.get(artistId)![relationId]) {
        artists.get(artistId)![relationId].type = "Remove";
      } else {
        removeMapEventLog(artistId, relationId, titleRelation, column, "", "");
      }

      relation.relationId = undefined;
    },
    setRemoveGenreByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const artist = state.artists[index];
      const { artistId, genreRelation } = artist;
      const relation = genreRelation[relationIndex];
      const { relationId } = relation;
      const { artists, genreRelation: genreEvents } = state.events;

      if (artists.get(artistId) && artists.get(artistId)![relationId]) {
        artists.get(artistId)![relationId].type = "Remove";
      } else {
        removeEventLog(artistId, relationId, genreEvents, column, "", "");
      }
      relation.relationId = undefined;
    },
    setRemoveRoleByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const artist = state.artists[index];
      const { artistId, roleRelation } = artist;
      const relation = roleRelation[relationIndex];
      const { relationId } = relation;
      const { artists, roleRelation: roleEvents } = state.events;

      if (artists.get(artistId) && artists.get(artistId)![relationId]) {
        artists.get(artistId)![relationId].type = "Remove";
      } else {
        removeEventLog(artistId, relationId, roleEvents, column, "", "");
      }
      relation.relationId = undefined;
    },

    setEventClear(state) {
      state.events.artists.clear();
      state.events.historyRelation.clear();
      state.events.roleRelation.clear();
      state.events.titleRelation.clear();
      state.events.urlRelation.clear();
    }
  }
});

const createEventLog = (
  artistId: ArtistId,
  relationId: string,
  eventMap: Map<ArtistId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string,
  order?: number
) => {
  if (!eventMap.has(artistId)) {
    eventMap.set(artistId, {});
  }
  const eventLogs = eventMap.get(artistId)!;
  if (!eventLogs[relationId]) {
    eventLogs[relationId] = { type: "Create", from, to, column, order };
  } else {
    eventLogs[relationId].to = to;
  }
};

const createMapEventLog = (
  artistId: ArtistId,
  relationId: string,
  eventMap: Map<ArtistId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: string,
  to: string,
  order?: number
) => {
  if (!eventMap.has(artistId)) {
    eventMap.set(artistId, {});
  }
  const eventLogs = eventMap.get(artistId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (eventLogs[relationId]) {
    eventLogs[relationId].set("Create", { type: "Create", from, to, column, order });
  }
};

const removeMapEventLog = (
  artistId: ArtistId,
  relationId: string,
  eventMap: Map<ArtistId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(artistId)) {
    eventMap.set(artistId, {});
  }
  const eventLogs = eventMap.get(artistId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (eventLogs[relationId]) {
    eventLogs[relationId].set("Remove", { type: "Remove", from, to, column });
  }
};

const removeEventLog = (
  artistId: ArtistId,
  relationId: string,
  eventMap: Map<ArtistId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(artistId)) {
    eventMap.set(artistId, {});
  }
  const eventLogs = eventMap.get(artistId)!;
  if (!eventLogs[relationId]) {
    eventLogs[relationId] = { type: "Remove", from, to, column };
  }
};

const updateEventLog = (
  artistId: ArtistId,
  relationId: string,
  eventMap: Map<ArtistId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string,
  order?: number
) => {
  if (!eventMap.has(artistId)) {
    eventMap.set(artistId, {});
  }
  const eventLogs = eventMap.get(artistId)!;

  if (relationId === "") {
    if (!eventLogs[column]) {
      eventLogs[column] = { type: "Update", from, to, column, order };
    } else {
      eventLogs[column].to = to;
    }
  } else {
    if (!eventLogs[relationId]) {
      eventLogs[relationId] = { type: "Update", from, to, column, order };
    } else {
      eventLogs[relationId].to = to;
    }
  }
};

const updateMapEventLog = (
  artistId: ArtistId,
  relationId: string,
  eventMap: Map<ArtistId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(artistId)) {
    eventMap.set(artistId, {});
  }
  const eventLogs = eventMap.get(artistId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (!eventLogs[relationId].has(column)) {
    eventLogs[relationId].set(column, { type: "Update", from, to, column });
  } else {
    eventLogs[relationId].get(column)!.to = to;
  }
};
