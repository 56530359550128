import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_LARGE_PX, PADDING_X_LARGE, MARGING_LARGE_PX, MARGING_X_LARGE_PX } from "constants/size";
import { WHITE, GRAY_2, BLACK, GRAY_4, GRAY_5, GRAY_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as SearchIcon } from "assets/icons/custom-search.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { Input } from "App/Atomics/Input";
import { DANGER_COLOR } from "constants/color";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { timeout } from "utils/timeout";
import { AnimatedProgress } from "App/Atomics/AnimatedProgress";
import { WorkAlbumTextAutoComplete } from "App/Molecules/AutoCompletes/Work/WorkAlbumTextAutoComplete";
import { GetTracksInAlbumById, UpdateMetadataStructure } from "GraphQL/Queries/Metadata";
import { Metadata } from "GraphQL/Queries/Metadata/GetTracksInAlbumById";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { Toast } from "lib/toast";
import { CSSTransition, TransitionGroup } from "react-transition-group";

type Props = {
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 65)};
  height: ${pixelize(UNIT * 52)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
`;

const Header = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  padding: ${pixelize(PADDING_X_LARGE * 1.4)};
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
    font-weight: bold;
  }
  .cancelIcon {
    fill: ${BLACK};
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    transition: all 0.1s;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_4};
    }
  }
`;

const InputButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: ${MARGING_X_LARGE_PX};
  svg {
    transform: scale(1.4);
    stroke: ${WHITE};
  }
`;

const CustomDivider = styled.div`
  display: block;
  width: 90%;
  height: 2px;
  margin: 0 auto;
  border: 1.2px solid ${GRAY_5};
  border-bottom-color: ${WHITE};
`;

const SpanContainer = styled.div`
  width: 100%;
  min-height: ${pixelize(UNIT * 6)};
  display: grid;
  grid-template-columns: 10rem auto 2rem;
  justify-content: center;
  align-items: center;
  padding-right: 4rem;
  span {
    font-size: 1rem;
    font-weight: bold;
    text-align: right;
    margin-right: 1rem;
  }
`;

const Section = styled.section`
  width: 100%;
  padding: 0 2rem;
  min-height: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    margin: 1rem 0;
    font-size: 1.5rem;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

    span {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

const TrackContainer = styled.div`
  width: 100%;
  height: 20rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;

  .item-exit {
    opacity: 1;
    transform: translate(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-property: transform, opacity;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
  }
  .item-exit.item-exit-active {
    opacity: 0;
    transform: translate(400px, 0);
    transform: translate3d(400px, 0, 0);
  }
`;

const TrackItem = styled.div`
  display: grid;
  grid-template-columns: 4.5rem 6fr auto;
  align-items: center;
  padding: 0.4rem;
  animation: all 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  span {
    color: ${GRAY_6};
  }

  .btn-group {
    display: flex;
    .btn {
      margin-left: 0.2rem;
    }
  }
`;

const AutoComplete = styled(WorkAlbumTextAutoComplete)`
  width: ${pixelize(UNIT * 40)};
  margin: 0 ${MARGING_LARGE_PX};
`;

const ButtonContainer = styled.div`
  width: 80%;
  margin: 1rem auto 0 auto;
`;

const ArrowBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const BatchEditModal = ({ toClose }: Props) => {
  const [prevId, setPrevId] = useState(""); // 바꾸기 이전의 artist_id
  const [nextId, setNextId] = useState(""); // 바꿀 artist_id
  const [tracks, setTracks] = useState<Metadata[] | undefined>(undefined);
  const [percent, setPercent] = useState<number>(-1);
  const [isFail, setBeFail] = useState<boolean>(false); // 반복문 중단을 위한 fail state

  const onSearch = async () => {
    const { data, errors } = await GetTracksInAlbumById({ metadataId: prevId, typeClass: "work", typeSubClassIn: ["track"], first: 999 });
    if (errors) {
      window.alert("데이터를 가져올 수 없습니다.");
      return;
    }
    if (data) {
      setTracks(data.metadata);
    }
  };

  const onDeleteTrack = async (id: string) => {
    if (window.confirm("연관된 트랙이 없어야 삭제가 가능합니다. 삭제하시겠습니까?")) {
      try {
        const { data, errors } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
        if (errors) {
          console.log(errors);
          window.alert("권한 요청에 실패하였습니다.");
          return;
        }
        if (data) {
          const accessId = data.createAccess.id;
          try {
            const { data, errors } = await DeleteMetadata({ id });
            if (errors) {
              console.log(errors);
              await DeleteAccessRecord({ id: accessId });
              window.alert("작품 삭제에 실패하였습니다.");
              return;
            }
            if (data) {
              await DeleteAccessRecord({ id: accessId });
              setTracks(tracks!.filter(({ metadataId }) => metadataId !== id));
              Toast.primary("작품이 삭제되었습니다.", undefined, "bottom-right");
              return;
            }
          } catch (err) {
            console.log(err);
            return;
          }
        }
      } catch (err) {
        console.log(err);
        window.alert("작품 삭제에 실패하였습니다.");
        return;
      }
    }
  };

  // TODO
  const onSave = async () => {
    if (window.confirm(`이 작업은 복구가 어렵습니다. 정말로 변경하시겠습니까?`)) {
      try {
        setBeFail(false);
        setPercent(0);
        const { data } = await CreateAccessRecord({ targetId: prevId, targetTable: TargetTableInput.Metadata });
        if (data) {
          const accessId = data.createAccess.id;
          let count = 0;
          for (const { structures } of tracks!) {
            if (isFail) {
              return;
            }
            const { structureId } = structures[0];
            try {
              await timeout(1000).then(async () => {
                const { errors } = await UpdateMetadataStructure({ structureId, parentId: parseInt(nextId), bookingOrder: 0 });
                if (errors && errors.length) {
                  console.log(errors[0].message);
                  setBeFail(true);
                  window.alert(`업데이트에 실패하였습니다.`);
                  await DeleteAccessRecord({ id: accessId });
                  return;
                }
              });
              count += 1;
              setPercent((count / tracks!.length) * 100);
            } catch (e) {
              window.alert(`${count}번째 변경에 실패하였습니다.`);
              await DeleteAccessRecord({ id: accessId });
              console.log(e);
              return;
            }
          }
          try {
            await DeleteAccessRecord({ id: accessId });
            setPercent(100);
            window.alert("변경이 완료되었습니다.");
            window.location.reload();
          } catch (e) {
            window.alert("권한 삭제 요청을 실패하였습니다.");
            console.log(e);
            return;
          }
        }
      } catch (e) {
        window.alert("권한 요청을 실패하였습니다.");
        console.log(e);
        return;
      }
    } else {
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h2>일괄 편집</h2>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <SpanContainer>
        <span>이전 대작품 검색</span>
        <AutoComplete
          onChange={info => {
            if (info) {
              setPrevId(info.id);
            }
          }}
        />
        <InputButton color="default" onClick={onSearch}>
          <SearchIcon />
        </InputButton>
      </SpanContainer>
      <ArrowBox>
        <ArrowDownIcon />
      </ArrowBox>
      <SpanContainer>
        <span>이동할 대상 대작품</span>
        <AutoComplete
          onChange={(info: any) => {
            if (info) {
              setNextId(info.extra);
            }
          }}
        />
      </SpanContainer>
      <CustomDivider />

      <Section>
        {tracks === undefined ? (
          <span style={{ color: DANGER_COLOR }}>작품을 먼저 검색해주세요.</span>
        ) : tracks.length === 0 ? (
          <span style={{ color: BLACK }}>해당 대작품의 소작품이 존재하지 않습니다.</span>
        ) : (
          <>
            <h4>
              소작품 목록<span> ({tracks.length}개)</span>
            </h4>
            <TrackContainer>
              <TransitionGroup>
                {tracks.map(track => {
                  const { metadataId, title } = track;
                  return (
                    <CSSTransition key={metadataId} timeout={500} transitionName="item" classNames="item">
                      <TrackItem>
                        <span>{metadataId}</span>
                        <div className="title">{title}</div>
                        <div className="btn-group">
                          <Input.Button
                            className="btn"
                            color="warning"
                            onClick={() => setTracks(tracks.filter(track => track.metadataId !== metadataId))}
                          >
                            목록에서 제거
                          </Input.Button>
                          <Input.Button className="btn" color="danger" onClick={() => onDeleteTrack(metadataId)}>
                            삭제
                          </Input.Button>
                        </div>
                      </TrackItem>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </TrackContainer>
          </>
        )}
      </Section>

      <ButtonContainer>
        {percent < 0 ? (
          <Input.Button disabled={!prevId || !nextId || !tracks || !tracks.length} color="primary" isWide onClick={onSave}>
            작업량에 따라 약간의 시간이 소요될 수 있습니다. 확인 후 버튼을 눌러주세요.
          </Input.Button>
        ) : (
          <AnimatedProgress percent={percent} />
        )}
      </ButtonContainer>
    </Layout>
  );
};
