import React from "react";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Input } from "App/Atomics/Input";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { RoleTitleModal } from "../Modals/RoleTitleModal";
import styled from "styled-components";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { useRolesStore } from "../../Store";
import { RoleActions } from "../../Store/Role";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { ValidType } from "constants/ValidType";
import { Element, Role } from "GraphQL/Queries/Role/GetRole";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { RoleTypeFunctionInfo } from "constants/RoleTypeFunctionInfo";
import { EditInfoModal } from "../Modals/EditInfoModal";
import { allowRoleCreate } from "App/Routes/AdminRoutes/allowTables";

type MainProps = Readonly<{
  index: number;
  id: string;
  name: string;
  typeClass: string;
  validCheck: ValidType;
  count: number;
  expose: number;
}>;

type TitleProps = Readonly<{
  index: number;
  modal: any;
}>;

type ElementProps = Readonly<{
  index: number;
  listRelation: Element[];
}>;

export const DataTemplate = ({ index, data }: ColumnProps<Role>) => {
  const { store, userRole } = useAppSelector(store => ({
    store,
    userRole: store.UserToken.role
  }));
  const allowRoleEdit = userRole === UserRole.Master || allowRoleCreate(store);
  const { expose, name, typeClass, validCheck, count, id, listRelation } = data;
  const roleTitleModal = useToggle();
  const editInfoModal = useToggle();
  return (
    <Table.Row onClick={editInfoModal.on}>
      <Main index={index} id={id} name={name} typeClass={typeClass} validCheck={validCheck} count={count} expose={expose} />
      <Title index={index} modal={roleTitleModal} />
      <ListRelation index={index} listRelation={!listRelation ? [] : listRelation} />
      {allowRoleEdit && (
        <>
          <Modal isOpen={roleTitleModal.isToggled}>
            <RoleTitleModal index={index} roleId={id} toClose={roleTitleModal.off} />
          </Modal>
          <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
            <EditInfoModal index={index} toClose={editInfoModal.off} />
          </Modal>
        </>
      )}
    </Table.Row>
  );
};

const AlignCenterDiv = styled.div`
  text-align: center;
  width: 100%;
`;

const Main = ({ index, id, name, typeClass, validCheck, count, expose }: MainProps) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  const [{ checkList, roles }, dispatch] = useRolesStore(store => store.Role);

  const onToggleCheck = (id: string, checked: boolean) => {
    const currentPageIdList = roles.map(({ id }) => id);
    if (
      !currentPageIdList.filter(id =>
        Array.from(checkList)
          .map(item => item[0])
          .includes(id)
      ).length
    ) {
      dispatch(RoleActions.setRoleCheckListInit(currentPageIdList));
    }
    dispatch(RoleActions.setRoleCheckList(id, checked));
  };

  return (
    <>
      {userRole === UserRole.Master && (
        <Table.Data style={{ width: "5%" }}>
          <div onClick={e => e.stopPropagation()}>
            <AnimatedCheckbox id={id} isChecked={checkList.get(id)} onToggle={checked => onToggleCheck(id, checked)} />
          </div>
        </Table.Data>
      )}
      {id && (
        <Table.Data style={{ width: "5%" }}>
          <AlignCenterDiv>{<ClipBoard text={id} />}</AlignCenterDiv>
        </Table.Data>
      )}
      {name && (
        <Table.Data>
          <AlignCenterDiv>{<ClipBoard text={name} />}</AlignCenterDiv>
        </Table.Data>
      )}
      {typeClass && (
        <Table.Data style={{ width: "10%" }}>
          <AlignCenterDiv>{typeClass}</AlignCenterDiv>
        </Table.Data>
      )}
      {validCheck && (
        <Table.Data style={{ width: "10%" }}>
          <AlignCenterDiv>{validCheck}</AlignCenterDiv>
        </Table.Data>
      )}
      {count !== undefined && (
        <Table.Data style={{ width: "5%" }}>
          <AlignCenterDiv>{count}</AlignCenterDiv>
        </Table.Data>
      )}
      <Table.Data style={{ width: "5%" }}>
        <AlignCenterDiv>{expose === undefined ? "-" : expose}</AlignCenterDiv>
      </Table.Data>
    </>
  );
};

const Title = ({ index, modal }: TitleProps) => (
  <Table.Data style={{ width: "5%" }}>
    <Input.Button
      isWide
      color="primary"
      isFill={false}
      onClick={e => {
        e.stopPropagation();
        modal.on();
      }}
    >
      VIEW
    </Input.Button>
  </Table.Data>
);

const ListRelation = ({ index, listRelation }: ElementProps) => (
  <>
    <Table.Data style={{ width: "5%" }}>
      {listRelation.length
        ? listRelation.map(({ elementRelation }, key) => (
            <AlignCenterDiv key={key}>{<ClipBoard text={elementRelation[0].id ?? ""} />}</AlignCenterDiv>
          ))
        : null}
    </Table.Data>
    <Table.Data style={{ width: "15%" }}>
      {listRelation.length
        ? listRelation.map(({ elementRelation }, key) => <div key={key}>{<ClipBoard text={elementRelation[0].name ?? ""} />}</div>)
        : ""}
    </Table.Data>
    <Table.Data style={{ width: "15%" }}>
      {listRelation.length
        ? listRelation.map(({ typeFunction }, key) => {
            const typeName = RoleTypeFunctionInfo.find(({ id }) => id === typeFunction)?.name;
            return (
              <AlignCenterDiv key={key}>
                <ClipBoard text={typeName ?? typeFunction ?? ""} />
              </AlignCenterDiv>
            );
          })
        : ""}
    </Table.Data>
  </>
);
