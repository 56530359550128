import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XXX_LARGE_PX, MARGING_XX_LARGE_PX, MARGING_X_LARGE_PX, PADDING_SMALL_PX } from "constants/size";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { ChangePasswordStoreProvider, useChangePasswordStore } from "./Store";
import { GRAY_6, WHITE } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT, DANGER_COLOR_LIGHT, DEFAULT_BORDER_COLOR, SECONDARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { ChangePasswordActions } from "./Store/UserInfo";
import { UpdateUser } from "GraphQL/Queries";
import { useHistory } from "react-router";
import { HOME } from "constants/route";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: 1px solid gray;

  width: 100%;
  height: 100vh;
  padding: ${PADDING_XXX_LARGE_PX};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${pixelize(UNIT * 42)};
  min-height: ${pixelize(UNIT * 32)};
  padding: ${PADDING_XXX_LARGE_PX};
  background: ${WHITE};
  box-shadow: 0 0 4px ${GRAY_6};
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${MARGING_X_LARGE_PX};
  h1 {
    color: #212121;
    font-weight: 700;
    font-family: Rubik;
  }
`;

const RowGroup = styled.div`
  position: relative;
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 4)};
  grid-template-columns: ${pixelize(UNIT * 8)} ${pixelize(UNIT * 15)} auto;
  grid-gap: ${pixelize(UNIT * 1.5)};
  margin-bottom: ${MARGING_XX_LARGE_PX};
  align-items: center;

  label,
  input {
    width: 100%;
  }

  label {
    text-align: right;
    font-weight: bold;
  }

  span {
    font-size: 0.8rem;
    color: ${GRAY_6};
  }

  .valid {
    color: ${PRIMARY_COLOR_LIGHT};
  }
  .invalid {
    color: ${DANGER_COLOR_LIGHT};
  }

  input {
    padding: ${PADDING_SMALL_PX};
    font-size: 0.9rem;
    background-color: transparent;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: 0;
    text-align: left;
    transition: border-bottom 0.5s;

    &:focus {
      border-bottom: 1px solid ${SECONDARY_COLOR};
    }
  }

  svg {
    position: absolute;
    right: 30%;
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    cursor: pointer;
    &:active {
      fill: ${DANGER_COLOR_LIGHT};
    }
  }
`;

const Section = styled.section`
  margin: ${MARGING_XX_LARGE_PX} 0;
`;

const WideButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 15)};
  align-self: center;
`;

const ChangePasswordLayout = () => {
  const [topVisible, setTopVisible] = useState<boolean>(false);
  const [midVisible, setMidVisible] = useState<boolean>(false);
  const [bottomVisible, setBottomVisible] = useState<boolean>(false);
  const [{ currentPassword, newPassword, checkPassword }, dispatch] = useChangePasswordStore(store => store.UserInfo.userInfo);
  const router = useHistory();
  const isPasswordMatch = () => newPassword === checkPassword;
  const isValidClassName = () => {
    if (!checkPassword) return "";
    return isPasswordMatch() === true ? "valid" : "invalid";
  };
  const feedbackMessage = () => {
    switch (true) {
      case !checkPassword:
        return "비밀번호를 입력해주세요";
      case newPassword !== checkPassword:
        return "비밀번호가 일치하지 않습니다";
      case newPassword.length < 6:
        return "최소 6자리 이상을 입력해주세요";
      default:
        return "비밀번호가 일치합니다.";
    }
  };

  const updatePassword = async () => {
    const response = await UpdateUser({ password: currentPassword, newPassword });
    if (!response.data) {
      alert("서버 에러.");
    }
    if (response.data && response.data.updateUser) {
      alert("비밀번호가 변경되었습니다.");
      router.push(HOME);
    } else {
      alert("현재 비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <Layout>
      <Container>
        <Title>
          <h1>비밀번호 변경</h1>
        </Title>
        <Section>
          <RowGroup>
            <label>현재 비밀번호</label>
            <input
              type={!topVisible ? "password" : "text"}
              autoComplete="nope"
              value={currentPassword}
              placeholder={"비밀번호를 입력해주세요"}
              onChange={e => dispatch(ChangePasswordActions.setCurrentPassword(e.target.value))}
            />
            <EyeIcon onMouseDown={() => setTopVisible(prev => !prev)} onMouseUp={() => setTopVisible(prev => !prev)} />
          </RowGroup>
          <RowGroup>
            <label>신규 비밀번호</label>
            <input
              type={!midVisible ? "password" : "text"}
              autoComplete="nope"
              value={newPassword}
              placeholder={"비밀번호를 입력해주세요"}
              onChange={e => dispatch(ChangePasswordActions.setNewPassword(e.target.value))}
            />
            <EyeIcon onMouseDown={() => setMidVisible(prev => !prev)} onMouseUp={() => setMidVisible(prev => !prev)} />
          </RowGroup>
          <RowGroup>
            <label>신규 비밀번호 확인</label>
            <input
              type={!bottomVisible ? "password" : "text"}
              autoComplete="nope"
              value={checkPassword}
              placeholder={"비밀번호를 입력해주세요"}
              onChange={e => dispatch(ChangePasswordActions.setCheckPassword(e.target.value))}
            />
            <EyeIcon onMouseDown={() => setBottomVisible(prev => !prev)} onMouseUp={() => setBottomVisible(prev => !prev)} />
            <span className={isValidClassName()}>{feedbackMessage()}</span>
          </RowGroup>
        </Section>
        <WideButton
          disabled={!currentPassword || !newPassword || !checkPassword || !isPasswordMatch()}
          color="secondary"
          onClick={updatePassword}
        >
          저장
        </WideButton>
      </Container>
    </Layout>
  );
};

export const ChangePassword = () => (
  <ChangePasswordStoreProvider>
    <Helmet title="비밀번호 변경" />
    <ResponsiveToSideNavigatorTemplate>
      <ChangePasswordLayout />
    </ResponsiveToSideNavigatorTemplate>
  </ChangePasswordStoreProvider>
);
