import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, SIDEBAR_WIDTH, UNIT, widthViewportize } from "constants/size";
import { GRAY_8, WHITE, GRAY_9, BLACK } from "constants/baseColor";
import { transparentize } from "constants/color";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

type Props = Styleable &
  Readonly<{
    isDrawer?: boolean;
    isOpen?: boolean;
    children: ReactNode;
  }>;

const slideIn = keyframes`
  from {
    left: ${pixelize(SIDEBAR_WIDTH * -1)}
  }

  to {
    left: 0;
  }
`;

const Layout = styled.aside`
  width: ${pixelize(SIDEBAR_WIDTH)};
  min-width: ${pixelize(SIDEBAR_WIDTH)};
  height: 100vh;
  background-color: ${GRAY_8};
  border-right: 1px solid ${GRAY_9};
  color: ${WHITE};
  z-index: 10;
`;

const DrawerLayout = styled.aside`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${transparentize(BLACK, 30)};

  & > div {
    position: inherit;
    height: 100vh;
    color: ${WHITE};
    border-right: 1px solid ${GRAY_9};
    animation: ${slideIn} 0.2s linear;
  }

  button {
    width: 100%;
    padding: ${pixelize(1.25 * UNIT)} ${widthViewportize(0.15 * UNIT)};
    text-align: left;
    opacity: 0.5;
    font-size: 1.1rem;
    transition: all 0.2s;

    &[data-open="true"] {
      position: relative;
      opacity: 1;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: inherit;
        height: 66px;
        opacity: 0.2;
        mix-blend-mode: soft-light;
        border-radius: 4px;
        box-shadow: 0 2px 5px 0 rgba(83, 97, 255, 0.1);
        background-color: #ffffff;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 66px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        background-color: ${WHITE};
      }
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const Sidebar = ({ children, isDrawer = false, isOpen = true }: Props) => {
  if (!isOpen) {
    return null;
  }
  if (isDrawer) {
    const closeSidebar = () => AppStore.dispatch(SidebarActions.close());
    return (
      <DrawerLayout onClick={closeSidebar}>
        <div onClick={event => event.stopPropagation()}>{children}</div>
      </DrawerLayout>
    );
  }

  return <Layout>{children}</Layout>;
};
