import React, { ReactElement } from "react";
import styled from "styled-components";
import { Style } from "App/Routes/GoodsCreate/Style";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { v4 as uuidv4 } from "uuid";
import { GoodsCreateActions, GoodsSubdata as TypeGoodsSubdata } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsSubdataCategoryList } from "constants/GoodsSubdataOptionList";
import { Button } from "App/Atomics/Input/Button";

type Props = {
  readonly subdatas: TypeGoodsSubdata[];
};

export const GoodsSubdata = ({ subdatas = [] }: Props): ReactElement => {
  const onAddSubdata = () => {
    const tempId = uuidv4();
    GoodsCreateStore.dispatch(GoodsCreateActions.addSubdata(tempId));
  };
  const onUpdateField = (index: number, field: string) => {
    GoodsCreateStore.dispatch(GoodsCreateActions.updateSubdataField({ index, field }));
    GoodsCreateStore.dispatch(GoodsCreateActions.updateSubdataCategory({ index, category: field }));
  };

  const onUpdateValue = (index: number, value: string) =>
    GoodsCreateStore.dispatch(GoodsCreateActions.updateSubdataValue({ index, value }));
  const onRemoveSubdata = (index: number) => GoodsCreateStore.dispatch(GoodsCreateActions.removeSubdataField(index));

  const onAddSubdataList = (type: "weight") => {
    const subdataList: TypeGoodsSubdata[] = [];
    switch (type) {
      case "weight":
        const id = uuidv4();
        subdataList.push({
          id,
          category: "weight",
          field: "weight",
          value: ""
        });
        break;
    }
    GoodsCreateStore.dispatch(GoodsCreateActions.addSubdataList(subdataList));
    return;
  };

  const onClearSubadata = () => GoodsCreateStore.dispatch(GoodsCreateActions.clearSubdatas());

  return (
    <Style.Row>
      <Style.Caption>기타 정보</Style.Caption>
      <Style.SubCaption>* 옵션은 굿즈의 부가 선택 옵션을 추가합니다. (ex. size: S, model: iPhone 13, ...)</Style.SubCaption>
      <Style.RowContainer>
        <StyledButton color="primary" onClick={() => onAddSubdataList("weight")}>
          무게 추가
        </StyledButton>
        <StyledButton color="danger" onClick={onClearSubadata}>
          전체 삭제
        </StyledButton>
      </Style.RowContainer>
      <Style.ColContainer>
        {subdatas.map((data, index) => {
          // const defaultCategory = GoodsSubdataCategoryList.find(item => item.id === data.category);
          const defaultField = GoodsSubdataCategoryList.find(item => item.id === data.field);
          return (
            <GridGroup key={data.id}>
              <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveSubdata(index)}>
                -
              </Style.RoundButton>
              {/* <Style.Select
                optionList={GoodsSubdataCategoryList}
                placeholder="분류명"
                defaultValue={defaultCategory ? { id: defaultCategory.id, name: defaultCategory.name } : undefined}
                onBlur={info => info && onUpdateCategory(index, info.id)}
              /> */}
              <Style.Select
                optionList={GoodsSubdataCategoryList}
                placeholder="필드명"
                defaultValue={defaultField ? { id: defaultField.id, name: defaultField.name } : undefined}
                onBlur={info => info && onUpdateField(index, info.id)}
              />
              <Style.InputText style={{ marginTop: 0 }} defaultValue={data.value} onBlur={value => onUpdateValue(index, value)} />
            </GridGroup>
          );
        })}
        <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddSubdata}>
          +
        </Style.RoundButton>
      </Style.ColContainer>
    </Style.Row>
  );
};

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2.6rem 7rem auto;
  grid-gap: 4px;

  & + & {
    margin-top: 4px;
  }
`;

const StyledButton = styled(Button)`
  margin: 4px 0;
  & + & {
    margin-left: 4px;
  }
`;
