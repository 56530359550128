import classNames from "classnames";
import React, { FC, useState } from "react";
import ReactSelect from "react-select/creatable";

import { identity } from "utils/identity";

import makeAnimated from "react-select/animated";
import { SelectComponents } from "react-select/src/components";

type Value = string;
type Label = string;

export type Info = Readonly<{
  id: Value;
  name: Label;
  isDisabled?: boolean;
}>;

type SelectComponentType =
  | Partial<
      SelectComponents<
        Readonly<{
          id: string;
          name: string;
          isDisabled?: boolean;
          type?: string | undefined;
        }>
      >
    >
  | undefined;

type Props = Readonly<{
  style?: import("react-select/src/styles").Styles;
  className?: string;
  classNamePrefix?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMenuDisable?: boolean;
  defaultValue?: readonly Info[];
  placeholder?: string;
  optionList?: readonly Info[];
  indicator?: null | FC;
  autoFocus?: boolean;
  onInputChange?: (value: string) => void;
  onChange?: (option: readonly Info[]) => void;
  onBlur?: (option: readonly Info[]) => void;
}>;

export const CreatableTagSelect = ({
  style,
  className,
  classNamePrefix,
  isClearable = true,
  isDisabled,
  isMenuDisable,
  defaultValue = [],
  placeholder,
  optionList,
  indicator,
  autoFocus,
  onInputChange = identity,
  onChange = identity,
  onBlur = identity
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const [currentValue, setCurrentValue] = useState("");

  const updateInput: ReactSelect<Info>["props"]["onInputChange"] = (nextInfo, meta) => {
    switch (meta.action) {
      case "input-change":
      case "set-value": {
        setCurrentValue(nextInfo);
        onInputChange(nextInfo);
      }
    }
  };
  const change: ReactSelect<Info>["props"]["onChange"] = nextInfo => {
    setInfo((nextInfo || []) as readonly Info[]);
    onChange((nextInfo || []) as readonly Info[]);
  };

  const blur = () => onBlur(info);

  const keydown: ReactSelect<Info>["props"]["onKeyDown"] = event => {
    if (!currentValue) {
      return;
    }

    switch (event.keyCode) {
      case 13: // Enter
      case 9: // Tab
      case 32: // Space
        setInfo([...info, createOption(currentValue)]);
        setCurrentValue("");
        event.preventDefault();
        break;
    }
  };

  const createOption = (value: string) => {
    return {
      id: value,
      name: value
    } as Info;
  };

  const animatedComponents: SelectComponentType = makeAnimated();

  return (
    <ReactSelect
      styles={style}
      className={classNames(className)}
      classNamePrefix={classNamePrefix}
      isClearable={isClearable}
      isDisabled={isDisabled}
      menuIsOpen={false}
      components={{
        ...animatedComponents,
        ...(indicator ? { DropdownIndicator: indicator } : isMenuDisable && { DropdownIndicator: null })
      }}
      inputValue={currentValue}
      value={info}
      defaultValue={defaultValue}
      isMulti={true}
      getOptionValue={info => info.id}
      getOptionLabel={info => info.name}
      placeholder={placeholder}
      options={optionList}
      autoFocus={autoFocus}
      onInputChange={updateInput}
      onChange={change}
      onBlur={blur}
      onKeyDown={keydown}
    />
  );
};
