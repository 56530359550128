/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";

export type DropdownProps = Styleable & {
  children: ReactNode;
  direction?: "top" | "bottom";
};

type ListItemProps = {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export const Dropdown = ({ style, children, direction = "bottom" }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <Layout style={style}>
      <div className="option-btn" role="menu" onClick={() => setIsOpen(prev => !prev)}>
        <MoreIcon />
        <span className="blind">옵션</span>
      </div>
      {isOpen && (
        <DropdwnList role="menu" direction={direction}>
          {children}
        </DropdwnList>
      )}
    </Layout>
  );
};

Dropdown.Item = ({ children, onClick }: ListItemProps) => {
  return <ListItem onClick={onClick}>{children}</ListItem>;
};

const Layout = styled.div`
  position: relative;
  cursor: pointer;
  .option-btn {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .blind {
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }
`;

const DropdwnList = styled.ul<{ direction: "top" | "bottom" }>`
  display: block;
  position: absolute;
  z-index: 15;
  padding: 11px 0;
  white-space: nowrap;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  list-style: none;
  top: ${props => (props.direction === "top" ? "20px" : "-70px")};
  left: ${props => (props.direction === "top" ? "30px" : "30px")};
`;

const ListItem = styled.li`
  display: block;
  width: 100%;
  padding: 6px 20px 7px;
  font-size: 14px;
  line-height: 1.8px;
  text-align: left;
  color: #232323;
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  &:hover {
    background-color: #eee;
  }
  cursor: pointer;
`;
