import React from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_X_LARGE,
  PADDING_X_LARGE_PX,
  PADDING_LARGE
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { WHITE, GRAY_4, GRAY_2, BLACK } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { DANGER_ALERT_COLOR } from "constants/color";
import { useAlbumDetailStore } from "../../Store";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { CreateInputModal } from "../CreateInputModal";
import { UpdateInputModal } from "../UpdateInputModal";
import { AlbumDetailActions } from "../../Store/AlbumDetail";
import { Toast } from "lib/toast";

type TitleRelation = {
  id: string;
  type: string;
  order?: number;
  value: string;
};

type Props = Readonly<{
  titleRelation: TitleRelation[];
  onCreate: (type: string, value: string, languageCode: string, order: number) => void;
  onUpdate: (id: string, type: string, value: string, languageCode: string) => void;
  onDelete: (id: string) => void;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 37.5)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: ${pixelize(UNIT * 5)};
  display: flex;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  padding: ${pixelize(PADDING_X_LARGE * 1.4)};
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
    font-weight: bold;
  }
  .cancelIcon {
    fill: ${BLACK};
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      fill: ${GRAY_4};
    }
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${PADDING_X_LARGE_PX};
  margin: ${pixelize(MARGING_X_LARGE * 1.5)};
  width: ${pixelize(UNIT * 32)};
  height: ${pixelize(UNIT * 16.25)};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};

  .titleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${MARGING_SMALL_PX};
    height: ${pixelize(UNIT * 2)};
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;
    span {
      color: #757575;
      line-height: 1.43;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const EplipsisMultiline = styled(Input.Multiline)`
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  height: ${pixelize(UNIT * 10)};
  border: none;
  padding: 0;
  color: #212121;
  line-height: 1.43;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

const FloatButtonContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;
const FloatingButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  border-radius: 50%;
  box-shadow: 0 4px 4px ${GRAY_4};
  margin: ${MARGING_LARGE_PX} ${MARGING_XX_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  font-weight: bold;
  button {
    padding: ${pixelize(PADDING_LARGE * 0.8)} ${PADDING_X_LARGE_PX};
    border-radius: 4px;
    margin-right: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
  .edit-btn {
    background: #4c52bc;
    color: white;

    &:hover {
      background: #6c74dd;
    }
  }

  .delete-btn {
    background: ${WHITE};
    color: #4c52bc;
    border: 1.5px solid #4c52bc;

    &:hover {
      color: #6c74dd;
      border: 1.5px solid #6c74dd;
    }
  }
`;

const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    .danger-underline {
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const EditDescModal = ({ titleRelation, onCreate, onUpdate, onDelete, toClose }: Props) => {
  const [{ album }, dispatch] = useAlbumDetailStore(store => ({
    album: store.AlbumDetail.albumDetail[0].album[0]
  }));
  const createInputModal = useToggle();
  const updateInputModal = useToggle();

  const openUpdateInputModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(AlbumDetailActions.setAlbumDetailModalId(id));
    updateInputModal.on();
  };

  const toSave = (type: string, value: string, languageCode: string) => {
    onCreate(type, value, languageCode, album.titleRelation.length);
    createInputModal.off();
    Toast.primary("추가되었습니다.", undefined, "bottom-right");
  };

  const toUpdate = (id: string, type: string, value: string, languageCode: string) => {
    onUpdate(id, type, value, languageCode);
    updateInputModal.off();
    Toast.primary("저장되었습니다.", undefined, "bottom-right");
  };

  const toDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      onDelete(id);
      Toast.primary("삭제되었습니다.", undefined, "bottom-right");
    } else {
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <span>앨범 설명</span>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      {!titleRelation.length ? (
        <NoData>
          <span>
            추가 설명이 없습니다.{" "}
            <button className="danger-underline" onClick={createInputModal.on}>
              추가 버튼
            </button>
            을 눌러 작성해주세요.
          </span>
        </NoData>
      ) : (
        titleRelation.map((title, i) => (
          <DescCard key={i}>
            <div className="titleContainer">
              <div>
                <span>{title.type}</span>
              </div>
            </div>
            <EplipsisMultiline isDisabled value={!title.value ? "내용이 없습니다." : decodeURIComponent(title.value)} />
            <ButtonGroup>
              <button className="delete-btn" onClick={e => toDelete(e, title.id)}>
                삭제
              </button>
              <button className="edit-btn" onClick={e => openUpdateInputModal(e, title.id)}>
                수정
              </button>
            </ButtonGroup>
          </DescCard>
        ))
      )}
      <MarginDiv>{"0"}</MarginDiv>
      <FloatButtonContainer>
        <FloatingButton color="danger" onClick={createInputModal.on}>
          +
        </FloatingButton>
      </FloatButtonContainer>
      <Modal isOpen={createInputModal.isToggled}>
        <CreateInputModal onCreate={toSave} toClose={() => createInputModal.off()} />
      </Modal>
      <Modal isOpen={updateInputModal.isToggled}>
        <UpdateInputModal onUpdate={toUpdate} toClose={() => updateInputModal.off()} />
      </Modal>
    </Layout>
  );
};
