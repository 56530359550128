import React, { useState, useEffect, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import defaultImage from "assets/images/cover.png";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { GRAY_0, GRAY_5, GRAY_6, GRAY_8, WHITE } from "constants/baseColor";
import { AudioPlayerActions, PLAY_STATE } from "App/Store/AudioPlayer";
import Draggable from "react-draggable";
import { ReactComponent as PlayButton } from "assets/icons/audio-play.svg";
import { ReactComponent as PreviousButton } from "assets/icons/audio-previous.svg";
import { ReactComponent as NextButton } from "assets/icons/audio-next.svg";
import { ReactComponent as PauseButton } from "assets/icons/pause-button.svg";
import { ReactComponent as MinimizeIcon } from "assets/icons/audio-minus.svg";
import { ReactComponent as MaximizeIcon } from "assets/icons/circle-plus.svg";
import { ReactComponent as CloseIcon } from "assets/icons/remove-circle.svg";
import { ReactComponent as VolumeIcon } from "assets/icons/volume.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/audio-loading.svg";
import { Input } from "App/Atomics/Input";
import { useAppStore } from "App/Store";
import { configs } from "configs";
import { Progress } from "App/Atomics/Progress";
import { AudioPlaylist } from "./AudioPlaylist";
import { KeyInfo } from "lib/key-info";
import { DANGER_COLOR, SUCCESS_COLOR, WARNING_COLOR } from "constants/color";

const Opacity = keyframes`
  from {
    opacity: 0.0;
  }

  to {
    opacity: 1.0;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const audioElement = new Audio();
audioElement.preload = "auto";

const Seeker = (props: { current: number; duration: number; isPlay: PLAY_STATE }) => {
  return (
    <>
      <SeekerLayout>
        <Progress
          percent={props.current}
          onClick={event => {
            if (props.isPlay !== "STOP") {
              const rectX = event.currentTarget.getClientRects().item(0)?.x;
              const rectWidth = event.currentTarget.getClientRects().item(0)?.width;
              audioElement.currentTime = ((event.clientX - (rectX ? rectX : 0)) / (rectWidth ? rectWidth : 1)) * props.duration;
            }
          }}
        />
        <TimeChecker>
          <span className="time">
            {!isNaN(audioElement.currentTime)
              ? `${Math.floor(audioElement.currentTime / 60)}:${("0" + Math.floor(audioElement.currentTime % 60)).slice(-2)}`
              : "0:00"}
          </span>
          <span>{" / "}</span>
          <span className="time">
            {!isNaN(props.duration) ? `${Math.floor(props.duration / 60)}:${("0" + Math.floor(props.duration % 60)).slice(-2)}` : "0:00"}
          </span>
        </TimeChecker>
      </SeekerLayout>
    </>
  );
};

export const AudioPlayer = () => {
  const [{ isVisible, audio, isPlay, playlist }, dispatch] = useAppStore(store => store.AudioPlayer);
  const [seek, setSeek] = useState(0);
  const [duration, setDuration] = useState(NaN);
  const [volume, setVolume] = useState<number>(audioElement.volume);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMinimize, setIsMinimize] = useState<boolean>(false);
  const trackUri = audio?.url ?? "";

  useEffect(() => {
    if (isVisible && trackUri.length) {
      audioElement.src = `${trackUri && configs.urls.audio + "/" + trackUri}`;
      dispatch(AudioPlayerActions.setAudioPlay("PLAY"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, trackUri]);

  audioElement.onloadstart = () => {
    setLoading(true);
  };
  audioElement.onloadeddata = () => {
    setLoading(false);
  };
  audioElement.onplay = () => {
    setLoading(false);
  };
  audioElement.onseeking = () => {
    setLoading(true);
  };
  audioElement.onseeked = () => {
    setLoading(false);
  };
  audioElement.onerror = err => {
    console.log(err);
    setLoading(false);
    return;
  };
  audioElement.onloadedmetadata = () => setDuration(Math.floor(audioElement.duration));
  audioElement.ontimeupdate = () => setSeek((audioElement.currentTime / duration) * 100);
  audioElement.onpause = () => onPause();
  audioElement.onended = () => {
    audioElement.pause();
    audioElement.currentTime = 0;
    if (playlist.length !== 1) {
      onNextTrack();
    }
  };

  const onPlay = () => dispatch(AudioPlayerActions.setAudioPlay("PLAY"));
  const onPause = () => dispatch(AudioPlayerActions.setAudioPlay("PAUSE"));
  const onChangeVolume = (vol: number) => {
    setVolume(vol);
    audioElement.volume = vol;
  };

  const onPreviousTrack = useCallback(() => {
    const currentIndex = playlist.findIndex(({ uuid }) => uuid === audio.uuid);
    if (playlist[currentIndex - 1]) {
      dispatch([AudioPlayerActions.setAudioData(playlist[currentIndex - 1]), AudioPlayerActions.setAudioPlay("PLAY")]);
    } else {
      dispatch([AudioPlayerActions.setAudioData(playlist[0]), AudioPlayerActions.setAudioPlay("PLAY")]);
    }
  }, [audio.uuid, dispatch, playlist]);

  const onNextTrack = useCallback(() => {
    const currentIndex = playlist.findIndex(({ uuid }) => uuid === audio.uuid);
    if (playlist[currentIndex + 1]) {
      dispatch([AudioPlayerActions.setAudioData(playlist[currentIndex + 1]), AudioPlayerActions.setAudioPlay("PLAY")]);
    } else {
      dispatch([AudioPlayerActions.setAudioData(playlist[0]), AudioPlayerActions.setAudioPlay("PLAY")]);
    }
  }, [audio, dispatch, playlist]);

  const onClose = useCallback(() => {
    dispatch(AudioPlayerActions.toggleVisible(false));
    dispatch(AudioPlayerActions.setAudioPlay("STOP"));
  }, [dispatch]);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        onClose();
      } else if (e.key === " ") {
        dispatch(AudioPlayerActions.setAudioPlay(isPlay === "PLAY" ? "PAUSE" : "PLAY"));
      }
    },
    [dispatch, isPlay, onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    if (isVisible && isPlay === "PLAY") {
      audioElement.play();
    } else if (isVisible && isPlay === "PAUSE") {
      audioElement.pause();
    } else {
      audioElement.pause();
      audioElement.currentTime = 0;
    }

    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isPlay, isVisible, seek, onKeyPress]);

  return (
    <Draggable>
      <Layout
        className={!isMinimize ? "" : "minimize"}
        isVisible={isVisible}
        coverImage={!audio.coverUrl ? defaultImage : `${configs.urls.image}/${audio.coverUrl}`}
      >
        <div key={audio.uuid} className="player">
          <Header>
            <CloseIcon className="close-icon" onClick={onClose} />
            {!isMinimize ? (
              <MinimizeIcon className="mini-icon" onClick={() => setIsMinimize(true)} />
            ) : (
              <MaximizeIcon className="max-icon" onClick={() => setIsMinimize(false)} />
            )}
          </Header>
          <AudioInfo>
            <div className="cover-image">
              <img src={!audio.coverUrl ? defaultImage : `${configs.urls.image}/${audio.coverUrl}`} alt="cover" />
              <div className="audio-type">{audio.type && audio.type.toUpperCase()}</div>
            </div>
            <div className="audio-info">
              <div className="audio-title">{audio.title}</div>
              <div className="audio-artist">{audio.artist}</div>
            </div>
          </AudioInfo>
          <ButtonGroup>
            <Input.Button disabled={!playlist.length} onClick={onPreviousTrack}>
              <PreviousButton className="previous-button" />
            </Input.Button>
            <Input.Button onClick={isPlay === "PLAY" ? onPause : onPlay}>
              {loading ? <LoadingIcon className="audio-loading" /> : isPlay === "PLAY" ? <PauseButton /> : <PlayButton />}
            </Input.Button>
            <Input.Button disabled={!playlist.length} onClick={onNextTrack}>
              <NextButton className="next-button" />
            </Input.Button>
          </ButtonGroup>
          <Seeker current={seek} duration={duration} isPlay={isPlay} />
          <VolumeRange>
            <VolumeIcon />
            <Input.Range id="range" min={0} max={1} step={0.01} value={volume} onChange={onChangeVolume} />
          </VolumeRange>
        </div>
        <AudioPlaylist isMinimize={isMinimize} playlist={playlist} />
      </Layout>
    </Draggable>
  );
};

const Layout = styled.div<{ isVisible: boolean; coverImage: string }>`
  display: ${props => (props.isVisible ? "grid" : "none")};
  position: fixed;
  z-index: 999;
  right: 3%;
  top: 5%;
  width: 960px;
  min-height: 600px;
  padding: 2rem;
  animation: ${Opacity} 1s;
  background-color: ${GRAY_5};
  border-radius: 12px;
  overflow: hidden;
  grid-template-columns: auto 350px;
  &.minimize {
    width: 600px;
  }
  .playlist {
    display: flex;
    flex-direction: column;
    border: 1px solid #fff;
  }
  .player {
    display: flex;
    flex-direction: column;
  }
  &::after {
    content: "";
    background: url(${props => props.coverImage}) center no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    filter: blur(8px) brightness(80%);
    -webkit-filter: blur(8px) brightness(50%);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 6px;
    cursor: pointer;
  }
  .close-icon {
    fill: ${DANGER_COLOR};
  }
  .mini-icon {
    fill: ${WARNING_COLOR};
  }
  .max-icon {
    fill: ${SUCCESS_COLOR};
  }
`;

const SeekerLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${PADDING_X_LARGE_PX};
  color: ${WHITE};
  & > div {
    width: 100%;
    height: 4px;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    cursor: pointer;
    .background-bar {
      background-color: ${GRAY_8};
    }

    .progress-bar {
      background-color: ${WHITE};
    }
  }
`;

const TimeChecker = styled.div`
  display: flex;
  color: ${WHITE};
  margin-top: ${MARGING_SMALL_PX};
  justify-content: flex-end;
  & > .time {
    width: ${pixelize(UNIT * 3)};
    text-align: center;
    font-size: 0.9rem;
  }
  span {
    cursor: default;
  }
`;

const AudioInfo = styled.div`
  padding: 2rem 4rem;
  color: ${WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .cover-image {
    position: relative;
    width: 240px;
    height: 240px;
    img {
      width: 240px;
      height: 240px;
      object-fit: cover;
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
  .audio-info {
    width: 425px;
    height: 100%;
    text-align: center;
    margin-top: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .audio-title {
      font-size: 1.2rem;
      margin-bottom: 2px;
    }
    .audio-artist {
      font-size: 1rem;
      margin-bottom: 4px;
      color: ${GRAY_6};
    }
  }
  .audio-type {
    position: absolute;
    right: 5%;
    bottom: 5%;
    padding: 2px 6px 3px;
    background: rgba(0, 0, 0, 0.15);
    color: ${GRAY_0};
    font-size: 0.95rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & button {
    padding: ${PADDING_X_LARGE_PX};
    transition: fill 0.2s;

    & > svg {
      width: 2rem;
      height: 2rem;
      fill: rgba(255, 255, 255, 0.85);
    }

    &:hover {
      opacity: 0.5;
    }
  }
  .next-button,
  .previous-button {
    width: 1.25rem;
    height: 1.25rem;
    fill: rgba(255, 255, 255, 0.85);
  }
  .audio-loading {
    width: 2rem;
    height: 2rem;
    animation: ${spin} 4s linear infinite;
  }
`;

const VolumeRange = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;

  #range {
    max-width: 8rem;
    outline: none;
  }
  svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
  }
`;
