import { initializeKeyValue } from "lib/initialize-key-value";

export type Language = ReturnType<typeof sanitizeLanguages>["languageCheck"];

export const sanitizeLanguages = (option: Record<string, boolean>) => {
  const { LanguageCheck: languageCheck } = initializeKeyValue(option, {
    LanguageCheck: {
      KO: true,
      EN: true,
      JA: true,
      XX: true,
      OO: true,
      DE: true,
      ES: true,
      FR: true,
      IT: true
    }
  });
  return { languageCheck };
};
