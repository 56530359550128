import React, { useMemo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { SIGN } from "constants/route";
import { gql } from "lib/gql-tag";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { clients } from "utils/clients";
import { LeftSideBar } from "./LeftSidebar";
import { RightTrackTable } from "./RightTrackTable";
import { TracksStoreProvider, useTracksStore } from "./Store";
import { TrackActions } from "./Store/Track";
import { useAppSelector, useAppDispatch } from "App/Store";
import { UserRole } from "constants/UserRole";
import { AudioPlayerActions } from "App/Store/AudioPlayer";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Helmet } from "App/Atomics/Helmet";
import { Filters } from "App/Templates/FilterModalTemplate";

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
`;

const subheadDataEvery = (subheadData: Record<string, boolean>) => Object.values(subheadData).every(item => !item);

const ChildLayout = () => {
  const router = useHistory();
  const { userRole } = useAppSelector(store => ({
    userRole: store.UserToken.role
  }));
  const [{ head, subhead }, dispatch] = useTracksStore(store => store.TableInfo);
  const dispatchApp = useAppDispatch();

  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const checkList = queryParams.get("ids");
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchArtist = queryParams.get("artist", { default: undefined });
  const searchNo = queryParams.get("no", { default: undefined });
  const filter = useMemo(() => {
    const { count, artist, artistType, role, workArtist, rights, validCheck, genre, mood, licenseType } = JSON.parse(
      queryParams.get("filter", { default: undefined }) || "{}"
    ) as Filters;
    return { count, artist, artistType, role, workArtist, rights, validCheck, genre, mood, licenseType };
  }, [queryParams]);
  const { count, artist, artistType, role, workArtist, rights, validCheck: filterVC, genre, mood, licenseType } = filter;
  const validCheckIn = !filterVC
    ? undefined
    : Object.entries(filterVC)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  const genreMoodList = [...(genre ?? []), ...(mood ?? [])];

  useAsyncEffect(
    async isMounted => {
      const metadataQuery = `
          ${subhead.Metadata.metadataId ? `metadataId: metadata_id` : ""}
          ${subhead.Metadata.title ? `title` : ""}
          ${subhead.Metadata.no ? `no` : ""}
          typeMetadataClass: type_metadata_class
          ${subhead.Metadata.typeMetadataSubclass ? `typeMetadataSubclass: type_metadata_subclass` : ""}
          ${subhead.Metadata.validCheck ? `validCheck: valid_check` : ""}
        `;
      const metadataTitleQuery =
        userRole === UserRole.Arbeit || !head.Title || subheadDataEvery(subhead.Title)
          ? ""
          : `
          titles: metadata_title(
            where: {
              type_metadata_title__not_starts_with: "search"
            }
          ) {
            ${subhead.Title.language ? `language { languageCode: language_code }` : ""}
            ${subhead.Title.typeMetadataTitle ? `typeMetadataTitle: type_metadata_title` : ""}
            ${subhead.Title.value ? `value` : ""}
          }
        `;
      const metadataArtistQuery =
        !head.Artist || subheadDataEvery(subhead.Artist)
          ? ""
          : `
          artists: metadata_artist_relation(first: 30) {
            ${subhead.Artist.artist ? `artist { name }` : ""}
            ${subhead.Artist.role ? `role { id: role_id, name }` : ""}
            ${subhead.Artist.character ? `character { name }` : ""}
            order: exposure_order
          }
        `;
      const metadataUrlQuery =
        !head.Url || subheadDataEvery(subhead.Url)
          ? ""
          : `
        urls: metadata_url(
          where: {
            type_url__in: ["mp3high", "aac", "flac", "wav", "source"]
          }
        ) {
          ${subhead.Url.typeUrl ? `typeUrl: type_url` : ""}
          ${subhead.Url.url ? `url` : ""}
          ${subhead.Url.fileValidCheck ? `fileValidCheck: valid_check` : ""}
        }
      `;
      const metadataCompanyQuery =
        userRole === UserRole.Arbeit || !head.Company || subheadDataEvery(subhead.Company)
          ? ""
          : `
          companies: metadata_company_relation {
            company { name license }
          }
        `;
      const metadataExtraQuery =
        userRole !== UserRole.Master || !head.LicenseExtra || subheadDataEvery(subhead.LicenseExtra)
          ? ""
          : `extras: track_license_extra {
          ${subhead.LicenseExtra.publishYear ? "publishYear: publish_year" : ""}
          ${subhead.LicenseExtra.recordYear ? "recordYear: record_year" : ""}
          ${subhead.LicenseExtra.data ? "data" : ""}
          ${subhead.LicenseExtra.firstEdition ? "firstEdition: first_edition" : ""}
          ${subhead.LicenseExtra.place ? "place" : ""}
        }`;
      const metadataWorkQuery =
        (userRole !== UserRole.Master && userRole !== UserRole.Arbeit) || !head.Work || subheadDataEvery(subhead.Work)
          ? ""
          : `works: metadata_self_relations_list_metadata(orderBy: exposure_order__ASC) {
              relationId: id
              work: metadata_self_relations_element_metadata {
                id: metadata_id
                title
                artistRelation: metadata_artist_relation(
                  where: {
                    role__some: {
                      role_id: 343
                    }
                  }
                ) {
                  artist {
                    id: artist_id
                    name
                  }
                }
              }
            }`;
      const metadataGenreQuery =
        !head.Genre || subheadDataEvery(subhead.Genre)
          ? ""
          : `
          genres: metadata_genre_relation(first: 30, orderBy: exposure_order__ASC) {
            genre {
              genreId: genre_id
              name
              typeKind: type_kind
            }
          }
        `;
      const metadataTrackInfoQuery =
        !head.Info || subheadDataEvery(subhead.Info)
          ? ""
          : `track_information(
        first: 99
      ) {
        uuid: id
        channel
        duration
        bitrate: sample_bitrate
        tempo
        tonality
        grade
        timeSignature: time_signature
        voiceGender: voice_gender
        extraData: extra_data
      }`;

      const metadataStructureQuery = `
        structures: metadata_structure {
          list(where: { depth: 0 }) {
            metadata {
              metadataUrl: metadata_url(
                where: {
                  type_url:"cover"
                }
              ) {
                id
                typeUrl: type_url
                url
              }
            }
          }
        }
      `;

      const getTrackListQuery = gql`
        query METADATA_GET_TRACK_LIST($page: Int, $validList: [ValidType!]) {
          tracks: metadata(
            page: $page,
            group: true,
            first: ${!count ? 20 : parseInt(count, 10)},
            where: {
              AND: [
                { type_metadata_class__in: ["record", "theater"] }
                { type_metadata_subclass__in: ["track", "effect"] }
                { valid_check__in: $validList }
                ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `{ valid_check__not: REMOVE }` : ""}
                ${!checkList || !checkList.length ? "" : `{ metadata_id__in: ${checkList} }`}
                ${!searchId ? "" : `{ metadata_id: "${searchId}" }`}
                ${!searchNo ? "" : `{ no: "${searchNo}" }`}
                ${
                  !searchTitle
                    ? ""
                    : `{ metadata_title__some: { tsvec_words__search: "${searchTitle}", type_metadata_title__starts_with: "name" } }`
                }
                ${!searchArtist ? "" : `{ metadata_artist_relation__some: { artist__some: { name__contains: "${searchArtist}" }  } }`}
                ${
                  !genreMoodList.length
                    ? ""
                    : `${genreMoodList.map(({ id }) =>
                        id
                          ? `{
                      metadata_genre_relation__some: {
                          genre__some: {
                            genre_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !rights?.length
                    ? ""
                    : `${rights.map(({ id }) =>
                        id
                          ? `{
                      metadata_company_relation__some: {
                          type_kind: rightCompany
                          company__some: {
                            company_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !artist?.length
                    ? ""
                    : `${artist.map(({ id }) =>
                        id
                          ? `{
                      metadata_artist_relation__some: {
                          artist__some: {
                            artist_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !role?.length
                    ? ""
                    : `${role.map(({ id }) =>
                        id
                          ? `{
                      metadata_artist_relation__some: {
                          role__some: {
                            role_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !artistType?.length
                    ? ""
                    : `
                  {
                    metadata_artist_relation__some: {
                      artist__some: {
                        type_artist_class__in: [${artistType.map(({ id }) => `"${id}"`)}]
                      }
                    }

                  }
                `
                }
                ${
                  !workArtist?.length
                    ? ""
                    : `${workArtist.map(({ id }) =>
                        id
                          ? `{
                                metadata_self_relations_list_metadata__some: {
                                  metadata_self_relations_element_metadata__some: {
                                    metadata_artist_relation__some: {
                                      artist__some: {
                                        artist_id: ${id}
                                      }
                                    }
                                  }
                                }
                              }`
                          : ""
                      )}`
                }
                ${
                  !licenseType?.length
                    ? ""
                    : `{
                    track_license__some: {
                        type_track__in: [${licenseType.map(({ id }) => `"${id}"`)}]
                      }
                  }`
                }
              ]
            },
            orderBy: metadata_id__DESC) {
            ${metadataQuery}
            ${metadataTitleQuery}
            ${metadataArtistQuery}
            ${metadataUrlQuery}
            ${metadataCompanyQuery}
            ${metadataExtraQuery}
            ${metadataWorkQuery}
            ${metadataGenreQuery}
            ${metadataTrackInfoQuery}
            ${metadataStructureQuery}
          }
          edge(node: "metadata", page: $page, first: ${!count ? 20 : parseInt(count, 10)},
            where: { metadata: {
            AND: [
                { type_metadata_class__in: ["record", "theater"] }
                { type_metadata_subclass__in: ["track", "effect"] },
                { valid_check__in: $validList },
                ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `{ valid_check__not: REMOVE }` : ""}
                ${!checkList || !checkList.length ? "" : `{ metadata_id__in: ${checkList} }`}
                ${!searchId ? "" : `{ metadata_id: "${searchId}" }`}
                ${!searchNo ? "" : `{ no: "${searchNo}" }`}
                ${
                  !searchTitle
                    ? ""
                    : `{ metadata_title__some: { tsvec_words__search: "${searchTitle}", type_metadata_title__starts_with: "name" } }`
                }
                ${!searchArtist ? "" : `{ metadata_artist_relation__some: { artist__some: { name__contains: "${searchArtist}" }  } }`}
                ${
                  !genreMoodList.length
                    ? ""
                    : `${genreMoodList.map(({ id }) =>
                        id
                          ? `{
                      metadata_genre_relation__some: {
                          genre__some: {
                            genre_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !rights?.length
                    ? ""
                    : `${rights.map(({ id }) =>
                        id
                          ? `{
                      metadata_company_relation__some: {
                          type_kind: rightCompany
                          company__some: {
                            company_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !artist?.length
                    ? ""
                    : `${artist.map(({ id }) =>
                        id
                          ? `{
                      metadata_artist_relation__some: {
                          artist__some: {
                            artist_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !role?.length
                    ? ""
                    : `${role.map(({ id }) =>
                        id
                          ? `{
                      metadata_artist_relation__some: {
                          role__some: {
                            role_id: ${id}
                        }
                      }}`
                          : ""
                      )}`
                }
                ${
                  !artistType?.length
                    ? ""
                    : `
                  {
                    metadata_artist_relation__some: {
                      artist__some: {
                        type_artist_class__in: [${artistType.map(({ id }) => `"${id}"`)}]
                      }
                    }

                  }
                `
                }
                ${
                  !workArtist?.length
                    ? ""
                    : `${workArtist.map(({ id }) =>
                        id
                          ? `{
                                metadata_self_relations_list_metadata__some: {
                                  metadata_self_relations_element_metadata__some: {
                                    metadata_artist_relation__some: {
                                      artist__some: {
                                        artist_id: ${id}
                                      }
                                    }
                                  }
                                }
                              }`
                          : ""
                      )}`
                }
                ${
                  !licenseType?.length
                    ? ""
                    : `{
                    track_license__some: {
                        type_track__in: [${licenseType.map(({ id }) => `"${id}"`)}]
                      }
                  }`
                }
              ]
            }}) {
            hasNextPage
            hasPreviousPage
            currentPage
            pageCount
          }
        }
      `;

      try {
        dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
        const { data, errors } = await clients.metadata.query(getTrackListQuery, { page, validList: validCheckIn });
        if (!isMounted()) {
          return;
        }
        if (errors) {
          dispatchApp(AudioPlayerActions.toggleVisible(false));
          router.push(SIGN);
        } else {
          dispatch([
            TrackActions.setEdge(data?.edge),
            TrackActions.setTrack(!checkList || !checkList.length ? data?.tracks : data?.tracks.reverse())
          ]);
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    [page, searchId, searchNo, searchArtist, searchTitle, checkList, head, subhead, filter, count]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightTrackTable />
    </Layout>
  );
};

export const Tracks = () => (
  <TracksStoreProvider>
    <Helmet title="트랙" />
    <ChildLayout />
  </TracksStoreProvider>
);
