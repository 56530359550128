import React, { useState } from "react";
import styled from "styled-components";
import { ImageCrop } from "App/Molecules/ImageCrop";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_2, GRAY_6, GRAY_1 } from "constants/baseColor";
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useAlbumDetailStore } from "../../Store";
import { s3SingleUpload } from "App/Routes/SingleAlbumCreate/Query/s3SingleUpload";
import axios from "axios";
import { createFormData } from "lib/createFormData";
import { CreateInvalidation, CreateAccessRecord, DeleteAccessRecord, CreateMetadataUrl } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { UpdateMetadataUrl } from "GraphQL/Queries/Track";
import { AlbumDetailActions } from "../../Store/AlbumDetail";
import { Toast } from "lib/toast";
import { ImageTypeOptionInfo } from "constants/ImageTypeOptionInfo";
import { useAsyncEffect } from "lib/use-async-effect";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { MediaType } from "GraphQL/Scalars/MediaType";
import { Loading } from "App/Atomics/Loading";

type Props = {
  uuid?: string;
  src: string;
  ext: string;
  typeUrl?: string;
  url?: string;
  file?: File;
  action: string;
  toClose: () => void;
};

const Layout = styled.div`
  width: 1024px;
  height: 800px;
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 10%;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    right: 3%;
    width: 35px;
    height: 35px;
    padding: 0.5rem;
    fill: black;
    border-radius: 50%;
    background-color: ${GRAY_2};
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
      background-color: ${GRAY_1};
    }
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;

  .img-box {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 0.9rem;
    }
    img {
      width: 90px;
      height: 90px;
      border: 1px dotted rgba(0, 0, 0, 0.1);
    }
    .wide {
      width: 160px;
    }
  }
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
    span {
      font-weight: 600;
      color: ${PRIMARY_COLOR};
    }
  }
`;

const TextSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 20)};
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  background-color: #fff;

  span {
    text-align: center;
    color: ${PRIMARY_COLOR_LIGHT};
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  margin-right: 1rem;
  .btn {
    width: ${pixelize(UNIT * 8)};
    margin-right: 0.5rem;
  }
  .origin-btn {
    width: ${pixelize(UNIT * 12)};
    margin-right: 0.5rem;
  }
`;

export const ImageCropModal = ({ uuid, src, ext, typeUrl = "cover", url, file, action, toClose }: Props) => {
  const [imageType, setImageType] = useState<string | undefined>(typeUrl);
  const [imageUrl, setImageUrl] = useState<string | undefined>(src);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [{ album }, dispatch] = useAlbumDetailStore(store => ({
    album: store.AlbumDetail.albumDetail[0].album[0]
  }));

  const onSave = async (typeUrl: string, blob: Blob | File, ext: string) => {
    if (!album.rightCompany.length) {
      window.alert("권리사 정보가 없습니다.");
      return;
    }
    setLoading(true);
    const company = album.rightCompany.filter((company: any) => company.typeKind === "rightCompany");
    const fileName = `${album.no}/cover/album_${uuidv4()}`;
    const order =
      !album.metadataUrl.length || !album.metadataUrl.filter((image: any) => image.typeUrl === typeUrl).length
        ? 0
        : (_.last(album.metadataUrl.filter((image: any) => image.typeUrl === typeUrl) as any[])?.order ?? 0) + 1;
    try {
      // 1. Request S3 SingleUpload
      const { data: s3Data, errors: s3Error } = await s3SingleUpload({
        filename:
          action === "UPDATE" && url
            ? url
                .split("/")
                .splice(2)
                .join("/")
            : fileName,
        companyId: company[0].company[0].id,
        action
      });

      if (s3Error || !s3Data) {
        console.log(s3Error);
        window.alert("S3 권한 요청에 실패하였습니다.");
        return;
      }

      if (s3Data.singleUpload) {
        // 2. Axios S3 Upload
        await axios({
          method: "post",
          url: s3Data.singleUpload.url,
          data: createFormData(s3Data.singleUpload, blob),
          headers: {
            "Content-Type": "image/jpeg"
          }
        })
          .then(async () => {
            if (action === "UPDATE") {
              // 3. Create Invalidation (* Only excute in Update)
              await CreateInvalidation({
                token: s3Data.singleUpload.invalidateToken,
                mediaType: MediaType.image
              });
              // 3. Update Image Url
              const urlIndex = album.metadataUrl.findIndex((image: any) => image.uuid === uuid);
              if (album.metadataUrl[urlIndex].url !== s3Data.singleUpload.key) {
                try {
                  const { data: accessData, errors: accessError } = await CreateAccessRecord({
                    targetId: album.metadataId,
                    targetTable: TargetTableInput.Metadata
                  });
                  if (accessError || !accessData) {
                    console.log(accessError);
                    window.alert("권한 요청에 실패하였습니다.");
                    setLoading(false);
                    return;
                  }
                  if (accessData) {
                    const { errors: urlError } = await UpdateMetadataUrl({ uuid: uuid!, typeUrl, url: s3Data.singleUpload.key });
                    if (urlError && urlError.length) {
                      console.log(urlError[0].message);
                      window.alert("URL 업데이트에 실패하였습니다.");
                      await DeleteAccessRecord({ id: accessData.createAccess.id });
                      setLoading(false);
                      return;
                    }
                    await DeleteAccessRecord({ id: accessData.createAccess.id });
                  }
                } catch (err) {
                  console.log(err);
                  setLoading(false);
                  Toast.primary("이미지 변경에 실패했습니다.", undefined, "bottom-right");
                  return;
                }
                setLoading(false);
                dispatch(AlbumDetailActions.updateAlbumDetailImage({ uuid: uuid!, typeUrl, url: `${s3Data.singleUpload.key}` }));
                window.location.reload();
                Toast.primary("이미지가 변경되었습니다.", undefined, "bottom-right");
              } else {
                setLoading(false);
                Toast.primary("이미지 변경에 실패했습니다.", undefined, "bottom-right");
                return;
              }
            } else {
              // 4. Create Metadata Url
              const { data: accessData } = await CreateAccessRecord({ targetId: album.metadataId, targetTable: TargetTableInput.Metadata });
              if (accessData) {
                const { data: urlData, errors: urlErrors } = await CreateMetadataUrl({
                  metadataId: album.metadataId,
                  typeUrl: typeUrl!,
                  url: s3Data.singleUpload.key,
                  order
                });
                if (urlErrors) {
                  window.alert("이미지 생성에 실패하였습니다.");
                  await DeleteAccessRecord({ id: accessData.createAccess.id });
                  setLoading(false);
                  console.log(urlErrors);
                  return;
                }
                if (urlData) {
                  dispatch(
                    AlbumDetailActions.createAlbumDetailImage({
                      uuid: urlData.createMetadataUrl.metadata_url[0].uuid,
                      typeUrl: typeUrl!,
                      url: s3Data.singleUpload.key
                    })
                  );
                }
                await DeleteAccessRecord({ id: accessData.createAccess.id });
                setLoading(false);
                Toast.primary("이미지가 추가되었습니다.", undefined, "bottom-right");
                toClose();
              }
            }
          })
          .catch(err => {
            console.log(err);
            window.alert("데이터 업로드를 실패하였습니다.");
            return;
          });
      }
    } catch (err) {
      console.log(err);
      window.alert("서버에서 에러가 발생하였습니다.");
      return;
    }
  };

  useAsyncEffect(
    isMounted => {
      if (isMounted()) {
        if (imageBlob) {
          const urlCreator = window.URL || window.webkitURL;
          setImageUrl(urlCreator.createObjectURL(imageBlob));
        }
      }
    },
    [imageBlob]
  );

  return (
    <Layout>
      <Header>
        <h2>앨범 이미지 업데이트</h2>
        <CancelIcon onClick={toClose} />
      </Header>
      <SelectContainer>
        <LabelBox>
          <label>
            * <span>이미지 타입</span>을 선택해주세요.
          </label>
          <TextSelect
            optionList={ImageTypeOptionInfo}
            defaultValue={imageType ? { id: imageType, name: imageType } : undefined}
            onChange={info => {
              if (info) {
                setImageType(info.id);
              }
            }}
          />
        </LabelBox>
        <div className="img-box">
          <h4>미리보기</h4>
          <img src={imageUrl} className={imageType === "head" ? "wide" : "normal"} alt="preview" />
        </div>
      </SelectContainer>
      <ImageCrop
        src={src}
        style={{ width: "100%", height: 480 }}
        aspectRatio={imageType === "head" ? 16 / 9 : 1}
        preview={".preview"}
        minWidth={300}
        minHeight={300}
        onCropping={() => setLoading(true)}
        onCropEnd={blob => {
          setImageBlob(blob);
          setLoading(false);
        }}
      />
      <Footer>
        <span>드래그로 이미지를 편집하실 수 있습니다.</span>
        <ButtonGroup>
          <Input.Button
            disabled={!imageType || !imageBlob || loading}
            className="btn"
            color="primary"
            onClick={() => onSave(imageType!, imageBlob!, ext)}
          >
            저장
          </Input.Button>
          <Input.Button
            disabled={!imageType || !imageBlob || loading}
            className="origin-btn"
            color="secondary"
            onClick={() => onSave(imageType!, file!, ext)}
          >
            원본 이미지 업로드
          </Input.Button>
          <Input.Button className="btn" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Footer>
      <Loading loading={loading} />
    </Layout>
  );
};
