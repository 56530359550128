import React from "react";
import { Sidebar } from "App/Molecules/Sidebar";
import { SideNavigatorTemplate } from "App/Templates/SideNavigatorTemplate";
import { useAppSelector } from "App/Store-v3";

export const LeftSideBar = () => {
  const isOpenSidebar = useAppSelector(store => store.Sidebar.isOpen);

  return (
    <Sidebar isDrawer={true} isOpen={isOpenSidebar}>
      <SideNavigatorTemplate />
    </Sidebar>
  );
};
