import React from "react";
import { FilterItem, inputCustomStyle } from "../../formStyle";
import { ValidTypeTagSelect } from "App/Molecules/Selects/ValidType";
import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { ValidType } from "constants/ValidType";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import { GrantKey } from "App/Routes/AuthGrant/Store/Grant";

export const ValidTypeFilter = () => {
  const [{ mode, searchList }, dispatch] = useAuthGrantStore(store => store.IdList);
  const validTypeList =
    mode === GrantKey.TRACK
      ? searchList.Track.validTypeList
      : mode === GrantKey.ARTIST
      ? searchList.Artist.validTypeList
      : mode === GrantKey.GENRE
      ? searchList.Genre.validTypeList
      : mode === GrantKey.PLAYLIST
      ? searchList.Playlist.validTypeList
      : mode === GrantKey.COMPANY
      ? searchList.Company.validTypeList
      : undefined;
  const onSetValidType = (validType: ValidType[]) => {
    dispatch(IdActions.setValidCheck({ idType: mode, validTypeList: validType }));
  };
  return (
    <FilterItem>
      <div className="item-label">
        <span>유효성 필터</span>
      </div>
      <div className="item-option-wrapper">
        <ValidTypeTagSelect
          key={mode}
          style={inputCustomStyle}
          placeholder="기본값은 전체입니다."
          defaultValue={validTypeList}
          onBlur={onSetValidType}
        />
      </div>
    </FilterItem>
  );
};
