import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX, PADDING_XX_LARGE_PX, MARGING_LARGE, MARGING_SMALL_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_4, GRAY_0 } from "constants/baseColor";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";
import { Title } from "GraphQL/Queries/Artist/GetArtistTitleRelation";
import { useArtistSelector } from "../../Store";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
type Props = {
  titleRelation: Title[];
  onUpdate: (id: string, type: string, value: string, languageCode: string) => void;
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 38.5)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  font-size: 0.7rem;
  justify-content: center;
  align-items: center;
`;

const Multiline = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 17.5)};
  font-size: 14px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
  }

  .typeSelect {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const LanguageSelect = styled(LanguageTextSelect)`
  margin-bottom: ${MARGING_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  font-size: 1rem;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

export const UpdateTitleModal = ({ titleRelation, onUpdate, toClose }: Props) => {
  const { modalId } = useArtistSelector(store => store.Artist);
  const filteredTitleRelation = titleRelation.filter(title => title.uuid === modalId)[0];
  const [type, setType] = useState<string>(filteredTitleRelation?.typeArtistTitle ?? "");
  const [languageCode, setLanguageCode] = useState<string>(filteredTitleRelation?.language[0].languageCode ?? "");
  const [content, setContent] = useState<string>(filteredTitleRelation?.value ?? "");

  return (
    <Layout>
      <RowGroup>
        <h2>수정</h2>
      </RowGroup>
      <RowGroup>
        <h4>타입 선택</h4>
        <Input.TextSelect
          className="typeSelect"
          optionList={TitleTypeOptionInfo}
          defaultValue={{ id: type, name: type }}
          onChange={info => {
            if (info) {
              setType(info.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>언어 선택</h4>
        <LanguageSelect
          key={!languageCode ? "" : languageCode}
          defaultValue={LanguageCodeInfo.find(({ id }) => id === languageCode)}
          onChange={code => {
            if (code) {
              setLanguageCode(code.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>대작품 설명</h4>
        <Multiline placeholder="설명을 입력하세요" defaultValue={decodeURIComponent(content)} onChange={e => setContent(e.target.value)} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="cancelButton" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button
          onClick={() =>
            onUpdate(modalId, type, type.indexOf("name") === -1 ? content : content.replace(/(\r\n|\n|\r)/gm, ""), languageCode)
          }
        >
          저장
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
