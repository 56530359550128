/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  id: string;
  typeTrack: string | null;
  countryCode: string | null;
}>;

export const GetPlaylistLicense = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.id} />;
  const $typeTrack = <Variable name="type_track" scalar={<StringScalar />} value={option.typeTrack} />;
  const $countryCode = <Variable name="country_code" scalar={<StringScalar />} value={option.countryCode} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_LICENSE">
      <SelectionSet
        name="metadata"
        argument={{
          where: {
            metadata_id: $metadataId
          }
        }}
      >
        <SelectionSet
          name="track_license"
          alias="licenses"
          argument={{
            where: {
              type_track: $typeTrack,
              country_code__in: ["XX", "ZZ", "KR"],
              country_code: $countryCode
            }
          }}
        >
          <Field name="id" />
          <Field name="copyright" />
          <Field name="neighboring" />
          <Field name="notice" />
          <Field name="country_code" />
          <Field name="license_date" />
          <Field name="publish_date" />
          <Field name="type_track" />
          <Field name="valid_check" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: Readonly<{
      licenses: Readonly<{
        id: string;
        copyright: boolean | undefined;
        neighboring: boolean | undefined;
        notice: string;
        country_code: string;
        license_date: string | null;
        publish_date: string | null;
        type_track: string;
        valid_check: ValidType;
      }>[];
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
