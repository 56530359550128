import React from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { CsvUploadStoreProvider, useCsvUploadSelector } from "./Store";
import { CsvUploadForm } from "./Forms/CsvUploadForm";
import { CsvViewForm } from "./Forms/CsvViewForm";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { Helmet } from "App/Atomics/Helmet";
import { Page } from "./Store/CsvUpload";
import { AlbumEditForm } from "./Forms/AlbumEditForm";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  height: 100%;
  width: 100%;
`;

const CsvUploadLayout = () => {
  const { page } = useCsvUploadSelector(store => store.CsvUpload);
  return page === Page.FIRST ? (
    <ResponsiveToSideNavigatorTemplate>
      <Layout>
        <CsvUploadForm />
      </Layout>
    </ResponsiveToSideNavigatorTemplate>
  ) : page === Page.SECOND ? (
    <>
      <LeftSideBar />
      <CsvViewForm />
    </>
  ) : (
    <>
      <LeftSideBar />
      <AlbumEditForm />
    </>
  );
};

export const CsvUpload = () => (
  <CsvUploadStoreProvider>
    <Helmet title="CSV 업로드" />
    <CsvUploadLayout />
  </CsvUploadStoreProvider>
);
