/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum PackageOfGoods {
  패키지 = "Package"
}

export enum CollectionOfGoods {
  포토북 = "PhotoBook",
  포토카드 = "PhotoCard",
  포스트카드 = "PostCard",
  포스터 = "Poster",
  매거진 = "Magazine",
  앨범 = "Album",
  티켓 = "Ticket",
  ETC = "ETC"
}
export enum ConcertOfGoods {
  응원봉 = "LightStick",
  슬로건 = "Slogan"
}
export enum FasionOfGoods {
  의류 = "Clothes",
  스카프 = "Scarf",
  가방 = "Bag",
  모자 = "Hat"
}
export enum StationeryOfGoods {
  키링 = "KeyRing",
  스티커 = "Sticker",
  홀더 = "Holder",
  플래그 = "Flag",
  이어폰케이스 = "EarPhoneCase",
  폰케이스 = "PhoneCase",
  그립톡 = "Griptok",
  프린트 = "Print",
  뱃지 = "Badge",
  러그 = "Rug",
  머그 = "Mug",
  텀블러 = "Tumbler",
  파우치 = "Pouch",
  인형 = "Doll",
  피규어 = "Figure"
}

export enum TypeOfGoods {
  패키지 = "Package",
  포토북 = "PhotoBook",
  포토카드 = "PhotoCard",
  포스트카드 = "PostCard",
  포스터 = "Poster",
  매거진 = "Magazine",
  앨범 = "Album",
  티켓 = "Ticket",
  응원봉 = "LightStick",
  슬로건 = "Slogan",
  의류 = "Clothes",
  스카프 = "Scarf",
  가방 = "Bag",
  모자 = "Hat",
  키링 = "KeyRing",
  스티커 = "Sticker",
  홀더 = "Holder",
  플래그 = "Flag",
  이어폰케이스 = "EarPhoneCase",
  폰케이스 = "PhoneCase",
  그립톡 = "Griptok",
  프린트 = "Print",
  뱃지 = "Badge",
  러그 = "Rug",
  머그 = "Mug",
  텀블러 = "Tumbler",
  파우치 = "Pouch",
  인형 = "Doll",
  피규어 = "Figure",
  ETC = "ETC"
}

type Props = Readonly<{
  isNullable?: boolean;
  value?: TypeOfGoods;
}>;

const items = Object.values(TypeOfGoods);

export const TypeOfGoodsScalar = ({ isNullable = false, value }: Props) => (
  <EnumScalar type="TypeOfGoods" value={value} isNullable={isNullable} items={items} />
);
