import React, { ReactNode } from "react";
import styled from "styled-components";
import classNames from "classnames";

import { BORDER_RADIUS_PX, PADDING_LARGE_PX } from "constants/size";
import { WHITE, GRAY_3, GRAY_4, GRAY_5, BLUE_8, GRAY_1, BLACK, GRAY_7, GRAY_2 } from "constants/baseColor";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  PRIMARY_COLOR_DARK,
  WARNING_COLOR,
  DANGER_COLOR,
  DEFAULT_BORDER_COLOR,
  SECONDARY_COLOR_DARK,
  WARNING_COLOR_DARK,
  DANGER_COLOR_DARK,
  SUCCESS_COLOR,
  SUCCESS_COLOR_DARK
} from "constants/color";

type Color = "default" | "primary" | "secondary" | "warning" | "danger" | "success";

type Props = Styleable &
  Readonly<{
    children: ReactNode;
    color?: Color;
    filled?: boolean;
    isWide?: boolean;
    disabled?: boolean;
    onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }>;

const Layout = styled.button<{ isWide: boolean; filled: boolean; disabled?: boolean }>`
  width: ${props => (props.isWide ? "100%" : "min-content")};
  height: 2.5rem;
  display: ${props => (props.isWide ? "block" : "inline-block")};
  user-select: none;
  padding: ${PADDING_LARGE_PX};
  border-radius: ${BORDER_RADIUS_PX};
  font-size: 0.9rem;
  transition: all 0.2s;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${props => (props.disabled ? GRAY_4 : WHITE)};
  color: ${props => (props.disabled ? GRAY_7 : BLACK)};
  overflow-x: hidden;
  text-overflow: ellipsis;

  &.default {
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    box-shadow: 0 0 4px 0px ${GRAY_4};

    &:hover {
      background-color: ${GRAY_1};
      border-color: ${GRAY_5};
    }

    ${props =>
      !props.filled
        ? ""
        : `
        background-color: ${GRAY_2};
        border: 1px solid ${GRAY_5};
        &:hover {
          background-color: ${GRAY_3};
        }`}
  }

  &.primary {
    border: 1px solid ${PRIMARY_COLOR};
    box-shadow: 0 0 4px 0px ${GRAY_4};

    &:hover {
      ${props => (props.disabled ? "" : `background-color: ${GRAY_1}`)};
      border-color: ${PRIMARY_COLOR_DARK};
    }

    ${props =>
      !props.filled
        ? ""
        : `
        color: ${WHITE};
        background-color: ${PRIMARY_COLOR};
        border: 1px solid ${PRIMARY_COLOR_DARK};
        &:hover {
          background-color: ${BLUE_8};
        }`}
  }

  &.secondary {
    border: 1px solid ${SECONDARY_COLOR};
    box-shadow: 0 0 4px 0px ${GRAY_4};

    &:hover {
      background-color: ${GRAY_1};
      border-color: ${SECONDARY_COLOR_DARK};
    }

    ${props =>
      !props.filled
        ? ""
        : `
        color: ${WHITE};
        background-color: ${SECONDARY_COLOR};
        border: 1px solid ${SECONDARY_COLOR_DARK};
        &:hover {
          background-color: ${SECONDARY_COLOR_DARK};
        }`}
  }

  &.warning {
    border: 1px solid ${WARNING_COLOR};
    box-shadow: 0 0 4px 0px ${GRAY_4};

    &:hover {
      background-color: ${GRAY_1};
      border-color: ${WARNING_COLOR_DARK};
    }

    ${props =>
      !props.filled
        ? ""
        : `
        color: ${WHITE};
        background-color: ${WARNING_COLOR};
        border: 1px solid ${WARNING_COLOR_DARK};
        &:hover {
          background-color: ${WARNING_COLOR_DARK};
        }`}
  }

  &.danger {
    border: 1px solid ${DANGER_COLOR};
    box-shadow: 0 0 4px 0px ${GRAY_4};

    &:hover {
      background-color: ${GRAY_1};
      border-color: ${DANGER_COLOR_DARK};
    }

    ${props =>
      !props.filled
        ? ""
        : `
        color: ${WHITE};
        background-color: ${DANGER_COLOR};
        border: 1px solid ${DANGER_COLOR_DARK};
        &:hover {
          background-color: ${DANGER_COLOR_DARK};
        }`}
  }

  &.success {
    border: 1px solid ${SUCCESS_COLOR};
    box-shadow: 0 0 4px 0px ${GRAY_4};

    &:hover {
      background-color: ${GRAY_1};
      border-color: ${SUCCESS_COLOR_DARK};
    }

    ${props =>
      !props.filled
        ? ""
        : `
        color: ${WHITE};
        background-color: ${SUCCESS_COLOR};
        border: 1px solid ${SUCCESS_COLOR_DARK};
        &:hover {
          background-color: ${SUCCESS_COLOR_DARK};
        }`}
  }
`;

export const Tag = ({
  className,
  style,
  children,
  color = "default",
  filled = false,
  isWide = false,
  disabled,
  onClick,
  onMouseDown
}: Props) => (
  <Layout
    style={style}
    disabled={disabled}
    className={classNames(color, className)}
    filled={filled}
    isWide={isWide}
    onClick={onClick}
    onMouseDown={onMouseDown}
  >
    {children}
  </Layout>
);
