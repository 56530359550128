import { ValidType } from "constants/ValidType";
import { createActions, createHandler, createReducer } from "lib/store";

type State = ReturnType<typeof createInitialState>;

export type RoleSelfRelation = {
  id?: string;
  name?: string;
  typeFunctionId?: string;
  typeFunctionName?: string;
};

export type Role = {
  name: string;
  typeClass: string;
  validCheck: ValidType;
  count: number;
  expose: number;
  selfRelation: RoleSelfRelation[];
};

const SET_ROLE = Symbol("Role::Role::Set");
const SET_ROLE_NAME = Symbol("Role::Name::Set");
const SET_ROLE_TYPE_CLASS = Symbol("Role::TypeClass::Set");
const SET_ROLE_COUNT = Symbol("Role::Count::Set");
const CREATE_ROLE_SELF_RELATION = Symbol("Role::SelfRelation::Create");
const UPDATE_ROLE_SELF_RELATION_TYPE_FUNCTION = Symbol("Role::SelfRelationTypeFuntion::Update");
const UPDATE_ROLE_SELF_RELATION_ROLE = Symbol("Role::SelfRelationRole::Update");
const DELETE_ROLE_SELF_RELATION = Symbol("Role::SelfRelationRole::Delete");

const CLEAR_ROLE = Symbol("Role::Clear");

export const RoleCreateActions = createActions({
  setRole(role: Role) {
    return { type: SET_ROLE, role };
  },
  setRoleName(name: string) {
    return { type: SET_ROLE_NAME, name };
  },
  setRoleTypeClass(typeClass: string) {
    return { type: SET_ROLE_TYPE_CLASS, typeClass };
  },
  setRoleCount(count: number) {
    return { type: SET_ROLE_COUNT, count };
  },
  createRoleSelfRelation() {
    return { type: CREATE_ROLE_SELF_RELATION };
  },
  updateRoleSelfRelationTypeFuncion(index: number, typeFunctionId: string, typeFunctionName: string) {
    return { type: UPDATE_ROLE_SELF_RELATION_TYPE_FUNCTION, index, typeFunctionId, typeFunctionName };
  },
  updateRoleSelfRelationRole(index: number, id: string, name: string) {
    return { type: UPDATE_ROLE_SELF_RELATION_ROLE, index, id, name };
  },
  deleteRoleSelfRelation(index: number) {
    return { type: DELETE_ROLE_SELF_RELATION, index };
  },
  clearRole() {
    return { type: CLEAR_ROLE };
  }
});

const handler = createHandler<State>({
  [SET_ROLE](state, payload: { role: Role }) {
    state.role = payload.role;
  },
  [SET_ROLE_NAME](state, payload: { name: string }) {
    state.role.name = payload.name;
  },
  [SET_ROLE_TYPE_CLASS](state, payload: { typeClass: string }) {
    state.role.typeClass = payload.typeClass;
  },
  [SET_ROLE_COUNT](state, payload: { count: number }) {
    state.role.count = payload.count;
  },
  [CREATE_ROLE_SELF_RELATION](state) {
    const tempRole = { id: undefined, name: undefined, typeFunctionId: undefined, typeFunctionName: undefined };
    if (!state.role.selfRelation) {
      state.role.selfRelation = [tempRole] as RoleSelfRelation[];
    } else {
      state.role.selfRelation.push(tempRole as RoleSelfRelation);
    }
  },
  [UPDATE_ROLE_SELF_RELATION_TYPE_FUNCTION](state, payload: { index: number; typeFunctionId: string; typeFunctionName: string }) {
    state.role.selfRelation[payload.index].typeFunctionId = payload.typeFunctionId;
    state.role.selfRelation[payload.index].typeFunctionName = payload.typeFunctionName;
  },
  [UPDATE_ROLE_SELF_RELATION_ROLE](state, payload: { index: number; id: string; name: string }) {
    state.role.selfRelation[payload.index].id = payload.id;
    state.role.selfRelation[payload.index].name = payload.name;
  },
  [DELETE_ROLE_SELF_RELATION](state, payload: { index: number }) {
    state.role.selfRelation.splice(payload.index, 1);
  },
  [CLEAR_ROLE](state) {
    state.role = {} as Role;
  }
});

const createInitialState = () => ({
  role: {
    validCheck: ValidType.V3,
    count: 0,
    expose: 0
  } as Role
});

export const reducer = createReducer(handler, createInitialState);
