import { GENRE_LIST } from "constants/storageKey";
import { GetGenreList } from "GraphQL/Queries/Genre";
import { err, ok, Result } from "utils/result";

type Parent = { id: string; name: string };

export type Genre = {
  id: string;
  name: string;
  parent?: Parent;
};

type Item = Genre[];

export const loadGenreList = async (): Promise<Result<Item, any>> => {
  try {
    const { data: genreData, errors: genreErr } = await GetGenreList();
    if (genreErr) {
      return err(genreErr[0].message);
    }
    const genreList =
      genreData?.genre
        .filter(({ weight }) => weight === 0)
        .map(({ id, name, genre_self_relation_child }) => {
          const parent = genre_self_relation_child[0]?.parent?.[0] ?? undefined;
          return { id, name, parent };
        }) ?? [];
    window.localStorage.setItem(GENRE_LIST, JSON.stringify(genreList));
    return ok(genreList);
  } catch (error) {
    console.log(error);
    return err(error);
  }
};
