import React, { useRef } from "react";
import styled from "styled-components";
import { PADDING_SMALL_PX, HEADER_HEIGHT_PX } from "constants/size";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { VIOLET_2, VIOLET_3, VIOLET_4 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { Helmet } from "App/Atomics/Helmet";
import { ResetUser } from "GraphQL/Queries/Access/ResetUser";

const RESET_PASSWORD = "@abc123!";

const ResetPasswordLayout = () => {
  const useInputRef = () => useRef<HTMLInputElement>(null);
  const emailRef = useInputRef();

  const onResetPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const email = emailRef.current!.value;
    try {
      const { data, errors } = await ResetUser({ email, password: RESET_PASSWORD });
      if (errors) {
        alert("계정정보를 다시 확인해주세요.");
        return;
      }
      if (data) {
        window.alert("변경이 완료되었습니다.");
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
      window.alert("관리자에게 문의해주세요.");
      return;
    }
  };

  return (
    <Layout>
      <Container>
        <Title>
          <h1>비밀번호 초기화</h1>
          <span>비밀번호는 '@abc123!'으로 변경됩니다.</span>
        </Title>
        <Section>
          <InputText>
            <span>email</span>
            <Input.Text type="email" autoComplete="off" ref={emailRef} maxLength={40} placeholder="example@artistscard.com" />
          </InputText>
          <InputText>
            <span>password</span>
            <Input.Text autoComplete="off" maxLength={20} isDisabled value={RESET_PASSWORD} />
          </InputText>
          <RowGroup>
            <Input.Button className="create-btn" type="submit" color="default" onClick={onResetPassword}>
              변경
            </Input.Button>
          </RowGroup>
        </Section>
      </Container>
    </Layout>
  );
};

export const ResetPassword = () => (
  <ResponsiveToSideNavigatorTemplate>
    <Helmet title="회원 생성" />
    <ResetPasswordLayout />
  </ResponsiveToSideNavigatorTemplate>
);

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${HEADER_HEIGHT_PX};
  font-size: 0.9rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 35rem;
  height: 29rem;
  padding: 3rem;
  background: #fff;
  box-shadow: 0 0 4px #ddd;
  border-radius: 2rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: #191919;
    font-weight: 700;
    margin-bottom: 0.4em;
  }
  span {
    color: #999;
  }
`;

const Section = styled.section`
  margin: 1.5rem 0;
`;

const InputText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  span {
    color: #999;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
  }
  input {
    width: 100%;
    height: 3.2rem;
    padding: 0.9rem;
    border: 1px solid hsl(210 6% 83%);
    border-radius: 1rem;
    transition: border-color 0.2s;
    &:focus {
      border-color: ${PRIMARY_COLOR};
      border-width: 1.5px;
    }
  }
  .duplicate-box {
    width: 60%;
    position: relative;
    input {
      width: 100%;
      padding-right: 75px;
    }
    .checkbtn {
      color: ${VIOLET_4};
      font-size: 0.9rem;
      position: absolute;
      height: 2.5rem;
      right: 2%;
      top: 5px;
      background: #fff;
      border-radius: 8px;
      padding: ${PADDING_SMALL_PX};
      transition: all 0.2s;
      &:hover {
        box-shadow: 0 0 4px ${VIOLET_2};
      }
      &:active {
        box-shadow: 0 0 4px ${VIOLET_3};
        transform: translateY(1.5px);
      }
    }
  }
`;

const RowGroup = styled.div`
  width: 100%;
  display: flex;
  .create-btn {
    width: 8rem;
    border-radius: 1rem;
    height: 3rem;
    text-align: center;
    margin-top: 1rem;
    margin-right: 1rem;
    font-size: 1.01rem;
  }
`;
