import { DeleteAccessRecord } from "GraphQL/Queries/Access/DeleteAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { ProductStore, useProductSelector } from "../Store";
import { ProductActions } from "../Store/Product";
import { DeleteCommodity, DeleteFile, LoadCommodityGoods } from "GraphQL/Queries";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";

export const useDeleteProduct = () => {
  const product = useProductSelector(store => store.Product.product);
  const deleteProduct = async (key: string) => {
    const { id, urls } = product.find(item => item.id.key === key)!;
    const { goodsId, companyId } = await LoadCommodityGoods({ id: id.raw });
    const accessId = await requestAccessRecord({ targetId: goodsId, targetTable: TargetTableInput.Goods });
    for (const item of urls) {
      if (item.url) {
        await DeleteFile({ filename: item.url, companyId, fileType: FileType.FILE, book: BookType.immediate });
      }
    }
    await DeleteCommodity({ id: id.raw });
    await DeleteAccessRecord({ id: accessId! });
    ProductStore.dispatch(ProductActions.removeProduct(key));
  };
  return { deleteProduct };
};
