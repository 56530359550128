import { createDuck } from "lib/store/v3";

const createInitialState = () => ({ isVisible: true, isOpen: false, isFullWidth: false });

export const SidebarActions = createDuck({
  namespace: "Sidebar",
  createInitialState,
  reducers: {
    toggle(state) {
      state.isOpen = !state.isOpen;
    },
    close(state) {
      state.isOpen = false;
    },
    open(state) {
      state.isOpen = true;
    }
  }
});
