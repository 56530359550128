import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { Progress } from "App/Atomics/Progress";
import itiriri from "itiriri";
import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { AlbumStoreProvider, useAlbumDispatch } from "../../Store";
import { Album, AlbumActions, Track } from "../../Store/Album";
import axios from "axios";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAppStore } from "App/Store";
import { UserTokenActions } from "App/Store/UserToken";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { s3SingleUpload } from "App/Routes/SingleAlbumCreate/Query/s3SingleUpload";
import { createFormData } from "lib/createFormData";
import { GRAY_6, PINK_0 } from "constants/baseColor";
import { createAlbumLog } from "./createAlbumLog";
import { createTracksLog } from "./createTracksLog";

type Props = Readonly<{
  album: Album;
  trackList: Track[];
  isEffect: boolean;
  onClick: () => void;
}>;

type LoadedData = {
  type: string;
  name: string;
  loaded: number | undefined;
  total: number | undefined;
};

type FileInfo = Readonly<{
  ext: string;
  accept: string;
}>;

const reduceCreateAccessQuery = (result: string, trackId: any) =>
  result +
  `
    reqAccess${trackId}: createAccess_record(data: { target_id: "${trackId}", target_table: metadata }) {
      id
      target_id
      target_table
    }
  `;

const reduceRemoveAccessQuery = (result: string, record: any) =>
  result +
  `
    deleteReqAccess${record.target_id}:
      deleteAccess_record(where: { id: "${record.id}" })
  `;

const createAccessQuery = (table: string = "metadata") => gql`
  mutation CREATE_ACCESS_QUERY($id: String) {
    createAccess_record(data: { target_id: $id, target_table: ${table} }) {
      id
      target_id
      target_table
    }
  }
`;

const removeMetadataQuery = gql`
  mutation REMOVE_METADATA_QUERY($id: ID) {
    deleteMetadata(where: { metadata_id: $id }) {
      metadata_id
    }
  }
`;

const getStructureQuery = gql`
  query GET_STRUCTURE_QUERY($rootId: Int) {
    structureList: metadata_structure(first: 200, orderBy: [depth__DESC, structure_id__DESC], where: { structure_root_id: $rootId }) {
      metadata_id
      structure_id
    }
  }
`;

const removeStructureQuery = gql`
  mutation REMOVE_STRUCTRUE($structureId: ID) {
    deleteMetadata_structure(where: { structure_id: $structureId }) {
      parent_id
    }
  }
`;

const serialValidateQuery = gql`
  query METADATA_SERIAL_VALIDATE($no: String) {
    metadata(where: { AND: [{ no: $no }, { type_metadata_class: "record", type_metadata_subclass: "album" }] }) {
      metadata_id
    }
  }
`;

export const removeStructure = async (albumId: string, structureId: string) => {
  try {
    if (structureId) {
      const { data } = await clients.metadata.query(getStructureQuery, { rootId: parseInt(structureId) });
      data.structureList.forEach((metadata: any) => {
        clients.metadata
          .mutation(removeStructureQuery, { structureId: metadata.structure_id })
          .then(() =>
            clients.access
              .mutation(createAccessQuery(), { id: metadata.metadata_id })
              .then(() => clients.metadata.mutation(removeMetadataQuery, { id: metadata.metadata_id }))
          );
      });
    } else {
      clients.access
        .mutation(createAccessQuery(), { id: albumId })
        .then(() => clients.metadata.mutation(removeMetadataQuery, { id: albumId }));
    }
  } catch (e) {
    console.log(e);
    window.alert("삭제에 실패하였습니다.");
  }
};

const ProgressItem = (props: { value: LoadedData }) => {
  const percent = props.value.loaded && props.value.total ? (props.value.loaded / props.value.total) * 100 : 0;

  return (
    <>
      <span className={props.value.type === "IMAGE" ? "type_image" : "type_track"}>{props.value.type}</span>
      <div className="info-area">
        <span className="title">{props.value.name}</span>
      </div>
      <Progress className="progress-item" percent={percent} />
    </>
  );
};

const ProgressLayout = (props: { loadedData: LoadedData[] }) => {
  return (
    <div className="progress-wrapper">
      {props.loadedData.length &&
        props.loadedData.map((value, index) => (
          <ProgressField key={index}>
            <ProgressItem value={value as LoadedData} />
          </ProgressField>
        ))}
    </div>
  );
};

const albumSerialValidate = async (value: string) => {
  const { data } = await clients.metadata.query(serialValidateQuery, { no: value });

  if (!data.metadata.length) {
    return false;
  }

  return true;
};

const updateMetadataSource = async (urlId: string, uploadKey: string) => {
  if (urlId) {
    const updateMetadataQuery = `
      updateMetadata_url (
        where: {
          id: "${urlId}"
        }
        data: {
          url: "${uploadKey}"
          valid_check: V3
        }
      ) {
        id
        valid_check
      }
    `;

    const query = gql`
      mutation UPDATE_TRACK_SOURCE {
        ${updateMetadataQuery}
      }
    `;

    await clients.metadata.mutation(query);
  }
};

const updateAlbumValidCheck = async (albumId: string) => {
  const updateAlbumValidCheckQuery = `
    updateMetadata (
      where: {
        metadata_id: "${albumId}"
      }
      data: {
        valid_check: V3
      }
    ) {
      metadata_id
    }
  `;

  const query = gql`
    mutation UPDATE_ALBUM_VALID_CHECK {
      ${updateAlbumValidCheckQuery}
    }
  `;

  const accessId = await requestAccessRecord({ targetId: albumId, targetTable: TargetTableInput.Metadata });
  if (accessId) {
    await clients.metadata.mutation(query);
    await DeleteAccessRecord({ id: accessId });
  }
};

const FileUploadModalForm = ({ album, trackList, isEffect, onClick }: Props) => {
  const dispatch = useAlbumDispatch();
  const [accessRecord, dispatchApp] = useAppStore(store => store.UserToken.accessRecord);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeStamp, setTimeStamp] = useState(0);
  const [loadedData, setLoadedData] = useState(
    album.albumUrl && album.albumUrl.file
      ? [{ type: "IMAGE", name: album.title, loaded: undefined, total: undefined }].concat(
          itiriri(trackList).toArray(value => {
            return { type: "TRACK", name: value.title, loaded: undefined, total: undefined };
          })
        )
      : itiriri(trackList).toArray(value => {
          return { type: "TRACK", name: value.title, loaded: undefined, total: undefined };
        })
  );

  const [albumId, setAlbumId] = useState("");
  const [structureId, setStructureId] = useState("");
  const [failed, setFailed] = useState(false);

  const getFileInfo = (typeUrl: string, ext: string) => {
    switch (typeUrl) {
      case "mp3high":
        return { ext: "mp3", accept: "audio/mpeg" } as FileInfo;
      case "aac":
        if (ext === "aac") {
          return { ext: "aac", accept: "audio/aac" } as FileInfo;
        }
        if (ext === "m4a") {
          return { ext: "m4a", accept: "audio/x-m4a" } as FileInfo;
        }
        return { ext: "", accept: "" };
      case "flac":
        return { ext: "flac", accept: "audio/flac" } as FileInfo;
      case "wav":
        return { ext: "wav", accept: "audio/wav" } as FileInfo;
      case "txt":
        return { ext: "txt", accept: "text/plain" } as FileInfo;
      case "zip":
        return { ext: "zip", accept: "application/zip" } as FileInfo;
      default:
        return { ext: "", accept: "" };
    }
  };

  useAsyncEffect(async () => {
    try {
      if (album && trackList.length) {
        if (!albumSerialValidate(album.no)) {
          alert("앨범 시리얼이 중복됩니다.");
          dispatch(AlbumActions.setPageState("ALBUM"));
        } else {
          try {
            let coverData;
            // TODO: Get Access S3 Image Upload
            if (album.albumUrl && album.albumUrl.file) {
              const { data, errors } = await s3SingleUpload({
                filename: album.albumUrl.url,
                companyId: album.albumCompany.company_id,
                action: "INSERT"
              });
              if (errors) {
                throw errors;
              }
              coverData = data!.singleUpload;
            }

            createAlbumLog(album, coverData, isEffect)
              .then(async response => {
                const { value, singleUpload } = response!;
                const parentId = value.metadata_structure[0].structure_id;
                setAlbumId(value.metadata_id);
                setStructureId(parentId);

                if (singleUpload) {
                  await axios({
                    method: "post",
                    url: singleUpload.url,
                    data: createFormData(singleUpload, album.albumUrl.file),
                    onUploadProgress: progressEvent => {
                      loadedData[0].loaded = progressEvent.loaded;
                      loadedData[0].total = progressEvent.total;
                      setTimeStamp(progressEvent.timeStamp);
                      setLoadedData(loadedData);
                    },
                    headers: {
                      "Content-Type": "image/jpeg"
                    }
                  });
                }

                await createTracksLog(album, value.metadata_id, trackList, parentId, isEffect)
                  .then(async ({ data }: any) => {
                    const trackIds = itiriri(Object.entries(data))
                      .map(([key, track]: [string, any]) => track.metadata_id)
                      .toArray();
                    const trackUrlIds = new Map<string, string>();
                    itiriri(Object.entries(data)).forEach(([key, track]: [string, any]) =>
                      track.metadata_url.forEach((url: any) => trackUrlIds.set(track.metadata_id + url.type_url, url.id))
                    );

                    const createAccessRecord = gql`
                      mutation REQUEST_MULTI_ACCESS_RECORD {
                        ${trackIds.reduce(reduceCreateAccessQuery, "")}
                      }
                    `;

                    const { data: albumAccessRecord } = await clients.access.mutation(createAccessRecord);
                    dispatchApp(UserTokenActions.setAccessRecord(albumAccessRecord));

                    trackList.reduce((prevTrack, item, index) => {
                      const trackUrls = Object.entries(item.trackUrl).filter(([key, value]) => !!value?.url);
                      return prevTrack.then(() =>
                        trackUrls
                          .reduce(
                            (prevValue, [key, url], urlIndex) =>
                              prevValue.then(async () => {
                                if (url.url) {
                                  try {
                                    const { data, errors } = await s3SingleUpload({
                                      filename: url.url,
                                      companyId: album.albumCompany.company_id,
                                      action: "INSERT"
                                    });
                                    if (errors) {
                                      throw errors;
                                    }
                                    return await axios({
                                      method: "post",
                                      url: data!.singleUpload.url,
                                      data: createFormData(data!.singleUpload, url.file),
                                      onUploadProgress: progressEvent => {
                                        if (trackUrls.length === urlIndex + 1) {
                                          // 가장 마지막 progress 반영
                                          loadedData[singleUpload ? index + 1 : index].loaded = progressEvent.loaded;
                                          loadedData[singleUpload ? index + 1 : index].total = progressEvent.total;
                                          setTimeStamp(progressEvent.timeStamp);
                                          setLoadedData(loadedData);
                                        }
                                      },
                                      headers: {
                                        "Content-Type": getFileInfo(url.typeUrl, url.ext).accept
                                      }
                                    })
                                      .then(async () => {
                                        const urlMapKey = trackIds[index] + url.typeUrl;
                                        if (trackUrlIds.has(urlMapKey)) {
                                          await updateMetadataSource(trackUrlIds.get(urlMapKey)!, data!.singleUpload.key);
                                        }
                                      })
                                      .catch(e => {
                                        alert("파일 업로드에 실패했습니다.");
                                        removeStructure(value.metadata_id, parentId);
                                        setFailed(true);
                                        throw e;
                                      });
                                  } catch (e) {
                                    Promise.reject();
                                    throw e;
                                  }
                                }
                              }),
                            Promise.resolve()
                          )
                          .catch(e => {
                            Promise.reject();
                            throw e;
                          })
                      );
                    }, Promise.resolve());
                  })
                  .catch(e => {
                    throw e;
                  });
                await updateAlbumValidCheck(value.metadata_id);
              })
              .catch(e => {
                throw e;
              });
          } catch (e) {
            removeStructure(albumId, structureId);
            setFailed(true);
            alert("서버에서 에러가 발생했습니다.");
            return;
          }
        }
      }
    } catch (e) {
      removeStructure(albumId, structureId);
      setFailed(true);
      alert("서버에서 에러가 발생했습니다.");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTimeStamp]);

  const closeModal = () => {
    const deleteAccessQuery = gql`
      mutation DELETE_ACCESS_RECORD {
        ${Object.values(accessRecord).reduce(reduceRemoveAccessQuery, "")}
      }
    `;

    clients.access.mutation(deleteAccessQuery);
    onClick();
  };

  const isButtonDisabled = () =>
    !failed &&
    !(
      loadedData.length > 0 && loadedData.every(data => data.loaded !== undefined && data.total !== undefined && data.loaded === data.total)
    );
  return (
    <Layout>
      <Header>
        <span>음원을 업로드 중입니다.</span>
      </Header>
      <ProgressLayout loadedData={loadedData} />
      <ButtonGroup>
        <Input.Button color={isButtonDisabled() || failed ? "danger" : "success"} isWide onClick={closeModal} disabled={isButtonDisabled()}>
          {failed
            ? "앨범 생성을 실패하였습니다. 입력한 정보를 확인 후 다시 시도해주세요"
            : isButtonDisabled()
            ? "업로드가 종류된 후에 닫기 버튼이 활성화됩니다. 음원 개수에 따라 약간의 시간이 소요될 수 있습니다."
            : "성공적으로 앨범이 생성되었습니다. 창을 닫으면 처음화면으로 돌아갑니다."}
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

export const FileUploadModal = ({ album, trackList, isEffect, onClick }: Props) => (
  <AlbumStoreProvider>
    <FileUploadModalForm album={album} trackList={trackList} isEffect={isEffect} onClick={onClick} />
  </AlbumStoreProvider>
);

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 865px;
  height: 700px;

  .progress-wrapper {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 4rem);
    box-shadow: 0 4px rgba(0, 0, 0, 0.1);
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #6a5fdd;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 2px;
  z-index: 2;
`;

const ProgressField = styled.div`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
  height: 40px;
  padding: 1.15rem 1rem;
  user-select: none;
  background: #fff;
  border-bottom: 1px solid #eee;
  transition: background 0.15s;
  &:hover {
    background-color: ${PINK_0};
  }
  .type_image,
  .type_track {
    width: 4rem;
    display: table-cell;
    text-align: left;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    font-weight: bold;
  }
  .type_image {
    color: #1e88e5;
  }
  .type_track {
    color: #6a5fdd;
  }

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    span {
      display: block;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      white-space: nowrap;
    }
    .title {
      height: 22px;
      line-height: 22px;
      color: #191919;
      font-size: 0.9rem;
    }
    .artist {
      height: 17px;
      line-height: 17px;
      font-size: 13px;
      color: ${GRAY_6};
    }
  }

  .progress-item {
    display: table-cell;
    width: 250px;
    vertical-align: middle;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;
