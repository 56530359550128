/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar, FloatScalar } from "graphql-jsx";
import { GenderTypeScalar } from "GraphQL/Scalars";
import { GenderType } from "constants/GenderType";

type Option = Readonly<{
  metadataId: string;
  channel?: number;
  duration?: number;
  bitrate?: number;
  tempo?: number;
  tonality?: string;
  timeSignature?: string;
  voiceGender?: GenderType;
  extraData?: string;
}>;

export const CreateTrackInformation = async (option: Option) => {
  const $metadataId = <Variable name="metadataId" scalar={<IdScalar />} value={option.metadataId} />;
  const $channel = <Variable name="channel" scalar={<IntScalar isNullable />} value={option.channel} />;
  const $duration = <Variable name="duration" scalar={<IntScalar isNullable />} value={option.duration} />;
  const $bitrate = <Variable name="bitrate" scalar={<IntScalar isNullable />} value={option.bitrate} />;
  const $tempo = <Variable name="tempo" scalar={<FloatScalar isNullable />} value={option.tempo} />;
  const $tonality = <Variable name="tonality" scalar={<StringScalar isNullable />} value={option.tonality} />;
  const $timeSignature = <Variable name="time_signature" scalar={<StringScalar isNullable />} value={option.timeSignature} />;
  const $voiceGender = <Variable name="voice_gender" scalar={<GenderTypeScalar isNullable />} value={option.voiceGender} />;
  const $extraData = <Variable name="extra_data" scalar={<StringScalar isNullable />} value={option.extraData} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_TRACK_INFORMATION">
      <SelectionSet
        name="updateMetadata"
        alias="createTrackInformation"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            _onlySearch: true,
            track_information: {
              create: [
                {
                  channel: $channel,
                  duration: $duration,
                  sample_bitrate: $bitrate,
                  tempo: $tempo,
                  tonality: $tonality,
                  time_signature: $timeSignature,
                  voice_gender: $voiceGender,
                  extra_data: $extraData
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createTrackInformation: {
      id: string;
    };
  }>;

  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.createTrackInformation;
};
