import React from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_X_LARGE_PX,
  PADDING_XX_LARGE_PX,
  PADDING_SMALL_PX,
  MARGING_LARGE_PX,
  MARGING_XX_LARGE_PX
} from "constants/size";
import { GRAY_4, GREEN_4, WHITE } from "constants/baseColor";
import Papa from "papaparse";
import { CsvDropzone } from "./CsvDropzone";
import { usePlaylistAutoCreateDispatch } from "../../Store";
import { CsvDataActions } from "../../Store/CsvUpload";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 35)};
  background: ${WHITE};
  padding: ${PADDING_XX_LARGE_PX};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};
  overflow: auto;
  h2 {
    text-align: left;
    padding-top: ${PADDING_SMALL_PX};
  }

  h4 {
    color: #757575;
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
`;
const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const DropContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin-top: ${MARGING_X_LARGE_PX};
    display: inline-block;
    font-size: 0.8rem;
    color: ${GRAY_4};
    line-height: 1.5;
    white-space: pre-wrap;
    text-align: left;
    span {
      color: ${GREEN_4};
      text-decoration: underline ${GREEN_4};
    }
  }
`;

export const CsvUploadForm = () => {
  const dispatch = usePlaylistAutoCreateDispatch();
  const handleCsvUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length) {
      const csvData: Record<string, string>[] = [];
      let fields: string[] = [];
      let isError = false;
      Papa.parse(e.currentTarget.files![0], {
        worker: true,
        header: true,
        step: ({ data, errors, meta }) => {
          if (!fields.length && meta.fields) {
            fields = meta.fields;
          }
          if (errors.length) {
            isError = true;
            console.log(errors);
            return;
          }
          csvData.push((data as any) as Record<string, string>);
        },
        complete: _ => {
          if (isError) {
            window.alert("CSV파일 읽기에 실패하였습니다. 파일을 확인해주세요.");
            return;
          } else {
            const csvDataList = csvData.map(({ name, track_id }) => {
              return { name, track_id: track_id.split(",") };
            });
            console.log(csvData);
            dispatch([CsvDataActions.setCsvData(csvDataList)]);
          }
        }
      });
    }
  };
  return (
    <Layout>
      <Title>
        <h2>CSV 업로드</h2>
      </Title>
      <CustomDivider />
      <RowGroup>
        <DropContainer>
          <CsvDropzone accept=".csv" text="CSV" fileType="csv" onChange={handleCsvUpload} />
          <p>
            • 또는 여기에 <span>CSV</span> 파일을 끌어놓으세요.
          </p>
        </DropContainer>
      </RowGroup>
    </Layout>
  );
};
