import { Helmet } from "App/Atomics/Helmet";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { HEADER_HEIGHT_PX } from "constants/size";
import React from "react";
import styled from "styled-components";
import { AlbumForm } from "./Forms/AlbumForm";
import { SubmitForm } from "./Forms/SubmitForm";
import { TrackForm } from "./Forms/TrackForm";
import { AlbumStoreProvider, useAlbumSelector } from "./Store";
import { ContentsState } from "./Store/Album";

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

const ViewForm = (props: { state: ContentsState }) => {
  switch (props.state) {
    case "ALBUM":
      return <AlbumForm />;
    case "TRACK":
      return <TrackForm />;
    case "SUBMIT":
      return <SubmitForm />;
    default:
      return <div></div>;
  }
};

const AlbumCreateLayout = () => {
  const pageState = useAlbumSelector(store => store.AlbumCreate.pageState);

  return (
    <ResponsiveToSideNavigatorTemplate>
      <Layout>
        <ViewForm state={pageState} />
      </Layout>
    </ResponsiveToSideNavigatorTemplate>
  );
};

export const AlbumCreate = () => (
  <AlbumStoreProvider>
    <Helmet title="앨범 등록" />
    <AlbumCreateLayout />
  </AlbumStoreProvider>
);
