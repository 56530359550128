import { Head, Subhead, sanitizeHeads } from "./sanitizeHeads";
import { createDuck } from "lib/store";
import { ARTIST_FILTER, ARTIST_VC_FILTER } from "constants/storageKey";
import { sanitizeVCs, ValidCheck } from "App/Routes/Artists/Store/ArtistInfo/sanitizeVCs";

type State = ReturnType<typeof createInitialState>;

const createInitialState = () => {
  return {
    ...loadHeads(),
    ...loadValidCheck()
  };
};

const afterChange = (state: State) => {
  const { head, subhead, validCheck } = state;
  window.localStorage[ARTIST_FILTER] = JSON.stringify({
    Head: head,
    Subhead: subhead
  });
  window.sessionStorage[ARTIST_VC_FILTER] = JSON.stringify({
    ValidCheck: validCheck
  });
};

export const ArtistEditInfoActions = createDuck({
  namespace: "ArtistEditInfo",
  createInitialState,
  reducers: {
    setHead(state, head: Head) {
      state.head = head;
    },
    setSubhead(state, subhead: Subhead) {
      state.subhead = subhead;
    },
    setValidCheck(state, validCheck: ValidCheck) {
      state.validCheck = validCheck;
    }
  },
  afterChange
});

const loadHeads = () => {
  try {
    const artistFilter = window.localStorage.getItem(ARTIST_FILTER);
    return sanitizeHeads(artistFilter ? JSON.parse(artistFilter) : {});
  } catch (e) {
    return sanitizeHeads({});
  }
};

const loadValidCheck = () => {
  try {
    const validCheck = window.sessionStorage.getItem(ARTIST_VC_FILTER);
    return sanitizeVCs(validCheck ? JSON.parse(validCheck) : {});
  } catch (error) {
    return sanitizeVCs({});
  }
};
