/** @jsx jsx */

import { jsx, render, Variable, StringScalar, Mutation, IdScalar, Field } from "graphql-jsx";
import { clients } from "utils/clients";
import { PathTypeScalar } from "GraphQL/Scalars";
import { FileType, FileTypeScalar } from "GraphQL/Scalars/FileType";
import { BookType, BookTypeScalar } from "GraphQL/Scalars/BookType";

type Option = Readonly<{
  filename?: string;
  companyId?: string;
  path?: string;
  book?: BookType;
  fileType: FileType;
}>;

/**
 * @param companyId: ID
 * @param path: pathType
 * @param filename: String!
 * @param book: bookType!
 * @param fileType: fileType
 */
export const DeleteFile = async (option: Option) => {
  const $companyId = <Variable name="companyId" scalar={<IdScalar isNullable={true} />} value={option.companyId} />;
  const $path = <Variable name="path" scalar={<PathTypeScalar isNullable={true} />} value={option.path} />;
  const $filename = <Variable name="filename" scalar={<StringScalar isNullable={false} />} value={option.filename} />;
  const $book = <Variable name="bookType" scalar={<BookTypeScalar isNullable={false} />} value={option.book} />;
  const $fileType = <FileTypeScalar isNullable={true} value={option.fileType} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_FILE">
      <Field
        name="deleteFile"
        argument={{
          where: { filename: $filename, company_id: $companyId, path: $path, book: $book, fileType: $fileType }
        }}
      />
    </Mutation>
  );
  type Data = Readonly<{
    deleteFile: boolean;
  }>;
  return await clients.files.request<Data>(query, variables);
};
