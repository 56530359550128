import React, { useState } from "react";
import styled from "styled-components";
import { ImageCrop } from "App/Molecules/ImageCrop";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_2, GRAY_6, GRAY_1 } from "constants/baseColor";
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import axios from "axios";
import { createFormData } from "lib/createFormData";
import {
  CreateInvalidation,
  CreateAccessRecord,
  DeleteAccessRecord,
  UpdateArtistUrl,
  CreateArtistUrl,
  UpdateFile,
  UploadFile
} from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { useArtistsStore } from "../../../Store";
import { ArtistActions } from "../../../Store/Artist";
import { Toast } from "lib/toast";
import { ImageTypeOptionInfo } from "constants/ImageTypeOptionInfo";
import { useAsyncEffect } from "lib/use-async-effect";
import { v4 } from "uuid";
import _ from "lodash";
import { MediaType } from "GraphQL/Scalars/MediaType";
import { Loading } from "App/Atomics/Loading";

type Props = {
  index: number;
  uuid?: string;
  src: string;
  ext: string;
  typeUrl?: string;
  url?: string;
  file?: File;
  action: string;
  toClose: () => void;
};

const Layout = styled.div`
  width: 1024px;
  height: 820px;
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 10%;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    right: 3%;
    width: 35px;
    height: 35px;
    padding: 0.5rem;
    fill: black;
    border-radius: 50%;
    background-color: ${GRAY_2};
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
      background-color: ${GRAY_1};
    }
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem;

  .img-box {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 0.9rem;
    }
    img {
      width: 90px;
      height: 90px;
      border: 1px dotted rgba(0, 0, 0, 0.1);
    }
    .wide {
      width: 160px;
    }
  }
`;

const LabelBox = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
    span {
      font-weight: 600;
      color: ${PRIMARY_COLOR};
    }
  }
`;

const TextSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 20)};
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  background-color: #fff;

  span {
    text-align: center;
    color: ${PRIMARY_COLOR_LIGHT};
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-right: 1rem;
  text-align: center;
  .btn {
    width: ${pixelize(UNIT * 8)};
    margin-right: 0.5rem;
  }

  .origin-btn {
    width: ${pixelize(UNIT * 12)};
    margin-right: 0.5rem;
  }
`;

export const ImageCropModal = ({ index, uuid, src, ext, typeUrl = "cover", url, action, file, toClose }: Props) => {
  const [imageType, setImageType] = useState<string | undefined>(typeUrl);
  const [imageUrl, setImageUrl] = useState<string | undefined>(src);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [{ artist, loading }, dispatch] = useArtistsStore(store => ({
    artist: store.Artist.artists[index],
    loading: store.Artist.loading
  }));

  const onSave = async (typeUrl: string, blob: Blob | File, ext: string) => {
    dispatch(ArtistActions.setLoading(true));
    const uuidv4 = v4();
    const timestamp = Date.now();
    const fileName =
      action === "UPDATE" && url
        ? `${url
            .split("/")
            .splice(1)
            .join("/")
            .replace(/\.[^/.]+$/, "")}`.replace(/\?.+/, "")
        : `artists/${artist.artistId}_${uuidv4}`;
    const order =
      !artist.artistUrls.length || !artist.artistUrls.filter((image: any) => image.typeUrl === typeUrl).length
        ? 0
        : (_.last(artist.artistUrls.filter((image: any) => image.typeUrl === typeUrl) as any[])?.order ?? 0) + 1;
    try {
      if (action === "UPDATE") {
        const { data: updateData, errors: updateErr } = await UpdateFile({ filename: fileName, path: "imgs" });
        if (updateErr || !updateData) {
          console.log(updateErr);
          window.alert("S3 권한 요청에 실패하였습니다.");
          return;
        }
        if (updateData.updateFile) {
          await axios({
            method: "post",
            url: updateData.updateFile.url,
            data: createFormData(updateData.updateFile, blob),
            headers: {
              "Content-Type": "image/jpeg"
            }
          })
            .then(async () => {
              await CreateInvalidation({
                token: updateData.updateFile.invalidateToken,
                mediaType: MediaType.image
              });
              try {
                const { data: accessData, errors: accessError } = await CreateAccessRecord({
                  targetId: artist.artistId,
                  targetTable: TargetTableInput.Artist
                });
                if (accessError || !accessData) {
                  console.log(accessError);
                  window.alert("권한 요청에 실패하였습니다.");
                  dispatch(ArtistActions.setLoading(false));
                  return;
                }
                if (accessData) {
                  const { errors: urlError } = await UpdateArtistUrl({
                    uuid: uuid!,
                    typeUrl,
                    url: `${updateData.updateFile.key}?timestamp=${timestamp}`
                  });
                  if (urlError && urlError.length) {
                    console.log(urlError[0].message);
                    window.alert("URL 업데이트에 실패하였습니다.");
                    await DeleteAccessRecord({ id: accessData.createAccess.id });
                    dispatch(ArtistActions.setLoading(false));
                    return;
                  }
                  await DeleteAccessRecord({ id: accessData.createAccess.id });
                }
              } catch (err) {
                console.log(err);
                Toast.primary("이미지 변경에 실패했습니다.", undefined, "bottom-right");
                dispatch(ArtistActions.setLoading(false));
                return;
              }
              dispatch([
                ArtistActions.updateArtistUrl({
                  index,
                  uuid: uuid!,
                  typeUrl,
                  url: `${updateData.updateFile.key}?timestamp=${timestamp}`
                }),
                ArtistActions.setLoading(false)
              ]);
              Toast.primary("이미지가 변경되었습니다.", undefined, "bottom-right");
              toClose();
            })
            .catch(err => {
              console.log(err);
              window.alert("데이터 업로드를 실패하였습니다.");
              dispatch(ArtistActions.setLoading(false));
              return;
            });
        }
      } else {
        const { data: uploadData, errors: uploadErr } = await UploadFile({
          filename: fileName,
          path: "imgs"
        });
        if (uploadErr || !uploadData) {
          console.log(uploadErr);
          window.alert("S3 권한 요청에 실패하였습니다.");
          return;
        }
        if (uploadData.uploadFile) {
          await axios({
            method: "post",
            url: uploadData.uploadFile.url,
            data: createFormData(uploadData.uploadFile, blob),
            headers: {
              "Content-Type": "image/jpeg"
            }
          })
            .then(async () => {
              const { data: accessData } = await CreateAccessRecord({ targetId: artist.artistId, targetTable: TargetTableInput.Artist });
              if (accessData) {
                const { data: urlData, errors: urlErrors } = await CreateArtistUrl({
                  artistId: artist.artistId,
                  typeUrl: typeUrl!,
                  url: uploadData.uploadFile.key,
                  order
                });
                if (urlErrors) {
                  window.alert("이미지 생성에 실패하였습니다.");
                  await DeleteAccessRecord({ id: accessData.createAccess.id });
                  dispatch(ArtistActions.setLoading(false));
                  console.log(urlErrors);
                  return;
                }
                if (urlData) {
                  dispatch(
                    ArtistActions.createArtistUrl({
                      index,
                      uuid: urlData.createArtistUrl.artist_url[0].uuid,
                      typeUrl: typeUrl!,
                      url: uploadData.uploadFile.key,
                      order
                    })
                  );
                }
                await DeleteAccessRecord({ id: accessData.createAccess.id });
                dispatch(ArtistActions.setLoading(false));
                Toast.primary("이미지가 추가되었습니다.", undefined, "bottom-right");
                toClose();
              }
            })
            .catch(err => {
              console.log(err);
              window.alert("데이터 업로드를 실패하였습니다.");
              dispatch(ArtistActions.setLoading(false));
              return;
            });
        }
      }
    } catch (err) {
      console.log(err);
      window.alert("서버에서 에러가 발생하였습니다.");
      dispatch(ArtistActions.setLoading(false));
      return;
    }
  };

  useAsyncEffect(
    isMounted => {
      if (isMounted()) {
        if (imageBlob) {
          const urlCreator = window.URL || window.webkitURL;
          setImageUrl(urlCreator.createObjectURL(imageBlob));
        }
      }
    },
    [imageBlob]
  );

  return (
    <Layout>
      <Header>
        <h2>아티스트 이미지 업데이트</h2>
        <CancelIcon onClick={toClose} />
      </Header>
      <SelectContainer>
        <LabelBox>
          <label>
            * <span>이미지 타입</span>을 선택해주세요.
          </label>
          <TextSelect
            optionList={ImageTypeOptionInfo}
            defaultValue={imageType ? { id: imageType, name: imageType } : undefined}
            onChange={info => {
              if (info) {
                setImageType(info.id);
              }
            }}
          />
        </LabelBox>
        <div className="img-box">
          <h4>미리보기</h4>
          <img src={imageUrl} className={imageType === "head" ? "wide" : "normal"} alt="preview" />
        </div>
      </SelectContainer>
      <ImageCrop
        src={src}
        style={{ width: "100%", height: 480 }}
        aspectRatio={imageType === "head" ? 16 / 9 : 1}
        preview={".preview"}
        minWidth={300}
        minHeight={300}
        onCropping={() => dispatch(ArtistActions.setLoading(true))}
        onCropEnd={blob => {
          setImageBlob(blob);
          dispatch(ArtistActions.setLoading(false));
        }}
      />
      <Footer>
        <span>드래그로 이미지를 편집하실 수 있습니다.</span>
        <ButtonGroup>
          <Input.Button
            disabled={!imageType || !imageBlob || loading}
            className="btn"
            color="primary"
            onClick={() => onSave(imageType!, imageBlob!, ext)}
          >
            저장
          </Input.Button>
          <Input.Button
            disabled={!imageType || !imageBlob || loading}
            className="origin-btn"
            color="secondary"
            onClick={() => onSave(imageType!, file!, ext)}
          >
            원본 이미지 업로드
          </Input.Button>
          <Input.Button className="btn" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Footer>
      <Loading loading={loading} />
    </Layout>
  );
};
