/** @jsx jsx */

import { Field, IdScalar, IntScalar, jsx, ListScalar, Query, render, SelectionSet, StringScalar, Variable } from "graphql-jsx";

import { License } from "constants/License";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { MetadataSelectionSet } from "GraphQL/SelectionSet";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";

type Option = Readonly<{
  first: number;
  page: number;
}>;

export const getTrackList = async ({ first, page }: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar />} value={first} />;
  const $page = <Variable name="page" scalar={<IntScalar />} value={page} />;
  const $licenseList = (
    <Variable name="licenseList" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={[License.VL]} />
  );

  const $includeCompanyList = (
    <Variable name="includeCompanyList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={["44021"]} />
  );

  const $countryCodeIn = (
    <Variable name="countryCodeIn" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={["ZZ", "KR"]} />
  );

  const $V3_AND_REMOVE = <ListScalar scalar={<ValidTypeScalar />} isNullable value={[ValidType.V3, ValidType.REMOVE]} />;

  const { query, variables } = render(
    <Query operationName="GET_TRACK_LIST">
      <MetadataSelectionSet
        alias="trackList"
        argument={{
          first: $first,
          page: $page,
          group: true,
          where: {
            metadata_url__some: {
              valid_check__in: $V3_AND_REMOVE
            },
            metadata_company_relation__some: {
              company__some: {
                company_id__in: $includeCompanyList
              }
            },
            track_license__some: {
              type_track__in: $licenseList,
              country_code__in: $countryCodeIn,
              copyright: true,
              neighboring: true
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet
          name="metadata_url"
          alias="audioList"
          argument={{
            first: 1,
            where: { type_url__in: ["mp3high", "aac", "flac", "wav"] }
          }}
        >
          <Field name="type_url" alias="type" />
          <Field name="url" />
        </SelectionSet>
        <SelectionSet
          name="metadata_genre_relation"
          alias="genreRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "genre"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_genre_relation"
          alias="moodRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "mood"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre" alias="mood">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </MetadataSelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "metadata",
          first: $first,
          page: $page,
          where: {
            metadata: {
              metadata_url__some: {
                valid_check__in: $V3_AND_REMOVE
              },
              metadata_company_relation__some: {
                company__some: {
                  company_id__in: $includeCompanyList
                }
              },
              track_license__some: {
                type_track__in: $licenseList,
                country_code__in: $countryCodeIn,
                copyright: true,
                neighboring: true
              }
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );

  type Data = {
    trackList: Array<{
      id: string;
      title: string;
      audioList: [{ type: string; url: string }];
      moodRelation: [{ mood: [{ id: string; name: string }] }];
      genreRelation: [{ genre: [{ id: string; name: string }] }];
    }>;
    edge: Edge;
  };
  const response = await clients.metadata.request<Data>(query, variables);
  if (response.errors) {
    window.alert("데이터를 불러오는 중 에러가 발생했습니다");
    return;
  }

  const trackList = response.data!.trackList.map(({ id, title, audioList: [audio], genreRelation, moodRelation }) => {
    const genre = genreRelation.map(relation => relation.genre[0]);
    const mood = moodRelation.map(relation => relation.mood[0]);

    return {
      id,
      title,
      audio,
      genre,
      mood
    };
  });

  const edge = response.data!.edge;
  return {
    trackList,
    edge
  };
};
