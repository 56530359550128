/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, render, Variable, IdScalar, Query, Field, StringScalar } from "graphql-jsx";

type Option = Readonly<{
  email?: string;
  channel?: string;
}>;

export const IdIsExist = async (option: Option) => {
  const email = <Variable name="email" scalar={<IdScalar />} value={option.email} />;
  const channel = <Variable name="channel" scalar={<StringScalar />} value={option.channel} />;
  const { query, variables } = render(
    <Query operationName="ID_IS_EXIST">
      <Field name="idIsExist" argument={{ where: { email, channel } }} />
    </Query>
  );
  type Data = Readonly<{
    idIsExist: boolean;
  }>;
  return await clients.access.request<Data>(query, variables);
};
