import React, { ComponentType, useState } from "react";

import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findCharacterList } from "./findCharacterList";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { components, OptionProps } from "react-select";
import styled from "styled-components";
import { GRAY_5 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  "style" | "className" | "classNamePrefix" | "isDisabled" | "defaultValue" | "defaultOptions" | "onChange" | "onBlur" | "placeholder"
>;

export const CharacterTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  defaultOptions,
  onChange,
  onBlur,
  placeholder = "캐릭터를 입력해주세요."
}: Props) => {
  const [initialOptions, setInitialOptions] = useState<Info[] | undefined>(defaultOptions);

  const onFocus = async () => {
    const info = await findCharacterList("");
    setInitialOptions(info);
  };
  return (
    <TextAutoComplete
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      defaultOptions={initialOptions}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={findCharacterList}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="search_id">{props.data.id}</span>
        <span className="search_name">{props.data.name}</span>
        <span className="search_extra">{props.data.type}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .search_id {
    font-size: 9px;
    color: ${GRAY_5};
  }
  .search_name {
    font-size: 13px;
    font-weight: 500;
  }
  .search_extra {
    font-size: 10px;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;
