import { ServiceType } from "constants/ServiceType";

export const INFO_LIST = [
  {
    id: ServiceType.ClassicManager,
    name: "클래식매니저"
  },
  {
    id: ServiceType.MusicForShop,
    name: "뮤직포샵"
  },
  {
    id: ServiceType.DataVoucher,
    name: "데이터바우처"
  },
  {
    id: ServiceType.CoverLala,
    name: "아티스츠카드"
  }
];
