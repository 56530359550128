import React from "react";
import { FilterGroup, Filters } from "../..";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { RoleTagAutoComplete } from "App/Molecules/AutoCompletes/Role";
import { Input } from "App/Atomics/Input";
import { RoleTypeClassOptionInfo } from "constants/TypeOptionInfo";
import styled from "styled-components";

type RoleProps = {
  role?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

type RoleTypeClassProps = {
  typeClass?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

type RoleExposeProps = {
  expose?: string;
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const RoleFilter = ({ role, setFilters }: RoleProps) => (
  <FilterGroup>
    <h4>역할</h4>
    <span className="info">여러 개의 역할을 선택할 수 있습니다.</span>
    <RoleTagAutoComplete
      defaultValue={role}
      onChange={info => {
        if (info) {
          const list = info.map(({ id, name }) => ({ id, name }));
          setFilters(filter => ({ ...filter, role: list }));
        }
      }}
    />
  </FilterGroup>
);
export const RoleTypeClassFilter = ({ typeClass, setFilters }: RoleTypeClassProps) => {
  return (
    <FilterGroup>
      <h4>역할 클래스</h4>
      <span className="info">역할의 종류를 설정합니다.</span>
      <Input.TagSelect
        optionList={RoleTypeClassOptionInfo}
        defaultValue={typeClass?.length ? typeClass.map(({ id, name }) => ({ id, name })) : undefined}
        onChange={info => {
          if (info) {
            const list = info.map(({ id, name }) => ({ id, name }));
            setFilters(filter => ({ ...filter, roleTypeClass: list }));
          }
        }}
      />
    </FilterGroup>
  );
};

export const RoleExposeFilter = ({ expose, setFilters }: RoleExposeProps) => {
  return (
    <FilterGroup>
      <h4>우선 순위</h4>
      <span className="info">역할의 노출 순위를 조절합니다.</span>
      <NumberSelect
        defaultValue={!expose ? undefined : { id: expose, name: expose }}
        optionList={[
          { id: "0", name: "0" },
          { id: "1", name: "1" }
        ]}
        placeholder="우선 순위"
        onChange={info => {
          if (info) {
            setFilters(filter => ({ ...filter, expose: info.id }));
          }
        }}
      />
    </FilterGroup>
  );
};

const NumberSelect = styled(Input.TextSelect)`
  width: 8rem;
  text-align: center;
`;
