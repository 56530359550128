import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly officialUrl?: string;
};

export const GoodsOfficialUrl = ({ officialUrl }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>오피셜 URL</Style.Caption>
    <Style.SubCaption>ex. https://smtownandstore.com/product... (없다면 공식 홈페이지를 입력해주세요.)</Style.SubCaption>
    <Style.InputText
      key={officialUrl}
      placeholder={"공식 URL"}
      defaultValue={officialUrl}
      onBlur={value => GoodsCreateStore.dispatch(GoodsCreateActions.setOfficialUrl(value))}
    />
    <Style.AlertCaption>오피셜 URL을 입력해주세요.</Style.AlertCaption>
  </Style.Row>
);
