import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_LARGE_PX, PADDING_LARGE_PX } from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { WHITE, GRAY_4, BLACK, GRAY_6 } from "constants/baseColor";
import { PRIMARY_COLOR, DANGER_ALERT_COLOR } from "constants/color";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetTrackLyrics, LyricsInfo } from "GraphQL/Queries/Metadata/GetTrackLyrics";
import { Loading } from "App/Atomics/Loading";
import defaultImage from "assets/images/cover.png";
import { configs } from "configs";
import { KeyInfo } from "lib/key-info";

type Props = Readonly<{
  metadataId: string;
  toClose: () => void;
}>;

export const LyricsModal = ({ metadataId, toClose }: Props) => {
  const [lyricsInfo, setLyricsInfo] = useState<LyricsInfo>({} as LyricsInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const onCreateImageUrl = useCallback((url?: string) => {
    if (!url) {
      return defaultImage;
    }
    return `${configs.urls.image}/${url}${url.includes("?") ? `&` : `?`}mode=m`;
  }, []);

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      try {
        const data = await GetTrackLyrics({ id: metadataId });
        setLyricsInfo(data);
      } catch (e) {
        console.log(e);
        window.alert("Internal Server Error");
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        toClose();
      }
    },
    [toClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  return loading ? (
    <Loading loading={loading} />
  ) : (
    <Layout>
      <Header>
        <div className="title">
          <div className="track_info">
            <ImageContainer>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <div className="image-box">
                <img src={onCreateImageUrl(lyricsInfo?.cover ?? "")} alt={`cover`} />
              </div>
            </ImageContainer>
            <div className="track_name">
              <span className="title">{lyricsInfo?.title ?? "-"}</span>
              <span className="artist">{lyricsInfo?.artist ?? "-"}</span>
            </div>
          </div>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
      </Header>
      {!lyricsInfo?.lyrics ? (
        <NoData>
          <span>가사 정보가 없습니다.</span>
        </NoData>
      ) : (
        <Context>
          <div>
            <span>{window.decodeURIComponent(lyricsInfo.lyrics)}</span>
            <MarginDiv>{"0"}</MarginDiv>
          </div>
        </Context>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 600px;
  height: 600px;
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #fff;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  .track_info {
    display: flex;
    align-items: center;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
  .track_name {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    .title {
      display: block;
      width: 30rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    .artist {
      font-size: 0.9rem;
      color: ${GRAY_6};
      margin-top: 2px;
    }
  }
`;

const MarginDiv = styled.div`
  height: ${pixelize(UNIT * 1.5)};
  color: transparent;
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    margin-bottom: 1rem;
    .primary-underline {
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  width: 4rem;
  height: 4rem;
  cursor: normal;
  .image-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #eee;
      object-fit: fill;
    }
  }
`;

const Context = styled.div`
  display: flex;
  height: 100%;
  padding: 1.5rem;
  user-select: text;
  div {
    span {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 22px;
      color: #666;
      text-align: left;
    }
  }
`;
