import React from "react";
import { TypeOfGoods } from "GraphQL/Scalars/TypeOfGoodsScalar";
import { FilterTemplate } from "../FilterTemplate";
import { GoodsStore } from "App/Routes/Goods/Store";
import { GoodsActions } from "App/Routes/Goods/Store/Goods";

type Props = {
  readonly type: Set<TypeOfGoods>;
};

export const TypeFilter = ({ type }: Props) => {
  const optionList = Object.entries(TypeOfGoods).map(([key, value]) => ({ key, value, isSelected: type.has(value) }));
  const onSelectItem = (value: string) => GoodsStore.dispatch(GoodsActions.toggleType(value as TypeOfGoods));
  const selectedList = optionList.filter(({ isSelected }) => isSelected).map(({ key }) => key);
  const subTitle = !selectedList.length ? "모든 타입" : selectedList.join(", ");
  return <FilterTemplate title={"타입"} subTitle={subTitle} optionList={optionList} onSelectItem={onSelectItem} />;
};
