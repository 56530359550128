import axios from "axios";
import { UploadFile } from "GraphQL/Queries";
import { CreateCommodityUrlWithOrder } from "GraphQL/Queries/Goods/CreateCommodityUrlWithOrder";
import { createFormData } from "lib/createFormData";
import { ProductUrl } from "../Store/ProductDetail";

type Props = {
  readonly id: string;
  readonly companyId: string;
  readonly urls?: ProductUrl[];
};

export const useCreateImage = () => {
  const createImage = async ({ id, companyId, urls = [] }: Props) => {
    const coverUrlSet = await uploadImage(id, companyId, urls);
    const coverUrls = Array.from(coverUrlSet).map(item => ({ url: item.url, order: item.order }));
    await CreateCommodityUrlWithOrder({ id, urls: coverUrls });
  };
  return { createImage };
};

const uploadImage = async (commodityId: string, companyId: string, urls: ProductUrl[]) => {
  const result = new Set<ProductUrl>();
  try {
    for (const { id, typeUrl, file, ext, order } of urls) {
      const { data } = await UploadFile({ filename: `goods/commodity/${commodityId}_${id}.${ext!}`, companyId });
      if (!data) {
        throw new Error();
      }
      const bucket = data.uploadFile;
      await axios({
        method: "post",
        url: bucket.url,
        data: createFormData(bucket, file!),
        headers: {
          "Content-Type": typeUrl
        }
      });
      result.add({
        id,
        typeUrl,
        url: bucket.key,
        order
      });
    }
    return result;
  } catch (err) {
    console.log(err);
    return result;
  }
};
