import React, { ReactElement } from "react";
import styled from "styled-components";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions, GoodsParent as TypeGoodsParent } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { GoodsTextAutoComplete } from "App/Molecules/AutoCompletes/Goods";
import { RED_4 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";

type Props = {
  readonly typeId?: string;
  readonly parent: TypeGoodsParent[];
};

export const GoodsParent = ({ typeId, parent }: Props): ReactElement => {
  const addParent = () => GoodsCreateStore.dispatch(GoodsCreateActions.addParent());
  const updateParent = (info: Info, index: number) => {
    const officialUrl = info.extra.officialUrl;
    const createdAt = info.extra.createdAt;
    GoodsCreateStore.dispatch([
      GoodsCreateActions.updateParent({
        index,
        parent: {
          id: info.id,
          name: info.name,
          parentId: +info.extra.structureId
        }
      })
    ]);
    if (officialUrl) {
      GoodsCreateStore.dispatch(GoodsCreateActions.setOfficialUrl(officialUrl));
    }
    if (createdAt) {
      GoodsCreateStore.dispatch(GoodsCreateActions.setCreatedAt(createdAt));
    }
  };
  const removeParent = (index: number) => GoodsCreateStore.dispatch(GoodsCreateActions.removeParent(index));
  return (
    <Style.Row>
      <Style.Caption>패키지</Style.Caption>
      <Style.SubCaption>· 굿즈가 들어갈 패키지를 선택해주세요.</Style.SubCaption>
      <Style.SubCaption>· 패키지일 경우엔 입력할 수 없어요!</Style.SubCaption>
      <Style.ColContainer>
        {parent.map((data, index) => {
          return (
            <GridGroup key={`parent-${data.id ?? "0"}-${index}`}>
              <Style.RoundButton color={RED_4} onClick={() => removeParent(index)}>
                -
              </Style.RoundButton>
              <GoodsTextAutoComplete
                type="Package"
                isDisabled={!!typeId && +typeId === 21}
                style={SelectStyle}
                placeholder={"패키지명 ( ID검색 : $ID )"}
                defaultValue={!data?.id ? undefined : { id: data?.id, name: data?.name }}
                onChange={info => info && updateParent(info, index)}
              />
            </GridGroup>
          );
        })}
        <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={addParent}>
          +
        </Style.RoundButton>
      </Style.ColContainer>
    </Style.Row>
  );
};

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2.6rem auto;
  grid-gap: 4px;
  align-items: center;

  & + & {
    margin-top: 4px;
  }
`;
