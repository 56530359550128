import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { v4 as uuidv4 } from "uuid";
import { GoodsUrl } from "GraphQL/Queries/Goods/LoadGoodsDetail";

type Props = {
  readonly officialUrls: GoodsUrl[];
};

export const GoodsOfficialUrls = ({ officialUrls = [] }: Props): ReactElement => {
  const onAddUrl = () => {
    const tempId = uuidv4();
    GoodsDetailStore.dispatch(GoodsDetailActions.addOfficialUrl(tempId));
  };
  const onUpdateUrl = (index: number, url: string) => GoodsDetailStore.dispatch(GoodsDetailActions.updateOfficialUrl({ index, url }));
  const onRemoveUrl = (index: number) => GoodsDetailStore.dispatch(GoodsDetailActions.removeOfficialUrl(index));

  return (
    <Style.Row>
      <Style.Caption>오피셜 URL</Style.Caption>
      <Style.ColContainer>
        {officialUrls.map((url, index) => (
          <Style.RowContainer key={url.id}>
            <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveUrl(index)}>
              -
            </Style.RoundButton>
            <Style.InputText defaultValue={url.url} onBlur={value => onUpdateUrl(index, value)} />
          </Style.RowContainer>
        ))}
        <Style.RowContainer>
          <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddUrl}>
            +
          </Style.RoundButton>
        </Style.RowContainer>
      </Style.ColContainer>
    </Style.Row>
  );
};
