/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  category?: string;
  field?: string;
  value?: string;
  validCheck?: ValidType;
}>;

export const UpdateMetadataSubdataUnique = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $category = <Variable name="category" scalar={<StringScalar isNullable />} value={option.category} />;
  const $field = <Variable name="field" scalar={<StringScalar isNullable />} value={option.field} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable />} value={option.value} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_SUBDATA_UNIQUE">
      <SelectionSet
        name="updateMetadata_subdata_unique"
        alias="updateMetadataSubdataUnique"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            type_subdata_category: $category,
            type_subdata_field: $field,
            value: $value,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="id" alias="uuid" />
        <Field name="type_subdata_category" alias="category" />
        <Field name="type_subdata_field" alias="field" />
        <Field name="value" />
        <Field name="valid_check" alias="validCheck" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateMetadataSubdataUnique: {
      uuid: string;
      category: string;
      field: string;
      value: string;
      validCheck: ValidType;
    }[];
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
