import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { MARGING_LARGE_PX, pixelize, UNIT, MARGING_XX_LARGE_PX, MARGING_X_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { GenreTextAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { Divider } from "App/Atomics/Divider";
import { useArtistsStore } from "App/Routes/Artists/Store";
import { ValidType } from "constants/ValidType";
import { ArtistActions } from "App/Routes/Artists/Store/Artist";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreateArtistGenre, DeleteAccessRecord, DeleteArtistGenre } from "GraphQL/Queries";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { Toast } from "lib/toast";
import _ from "lodash";
import { GRAY_6 } from "constants/baseColor";
import { Loading } from "App/Atomics/Loading";

type Genre = {
  id: string;
  name: string;
  type: string;
};

type GenreRelation = {
  id: string;
  validCheck: ValidType;
  genre: Genre[];
  order: number;
};

type Props = Readonly<{
  index: number;
  toClose: () => void;
}>;

export const EditGenreModal = ({ index, toClose }: Props) => {
  const [{ artistId, genreRelation }, dispatch] = useArtistsStore(store => ({
    artistId: store.Artist.artists[index].artistId as string,
    genreRelation: store.Artist.artists[index].genreRelation as GenreRelation[]
  }));
  const [currentGenre, setCurrentGenre] = useState<Genre | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onAddArtistGenre = useCallback(
    async (genreId: string, genreName: string) => {
      const genreIds = genreRelation.map(({ genre }) => genre[0].id);
      if (genreIds.includes(genreId)) {
        Toast.warning("동일한 장르를 추가할 수 없습니다.", undefined, "top-center");
        return;
      }
      try {
        setLoading(true);
        const accessId = await requestAccessRecord({ targetId: artistId, targetTable: TargetTableInput.Artist });
        if (accessId) {
          const order = !genreRelation || !genreRelation.length ? 0 : (_.last(genreRelation)?.order ?? -1) + 1;
          const { data, errors } = await CreateArtistGenre({ id: artistId, genreId, order });
          if (errors) {
            throw errors;
          }
          if (data) {
            const uuid = data.createArtistGenre.artist_genre_relation[0].uuid;
            dispatch(ArtistActions.addArtistGenre({ index, uuid, genreId, genreName, order }));
            await DeleteAccessRecord({ id: accessId });
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        Toast.error("오류가 발생하였습니다", undefined, "top-center");
        setLoading(false);
        return;
      }
    },
    [artistId, dispatch, genreRelation, index]
  );

  const onRemoveArtistGenre = useCallback(
    async (uuid: string, relationIndex: number) => {
      setLoading(true);
      try {
        const accessId = await requestAccessRecord({ targetId: artistId, targetTable: TargetTableInput.Artist });
        if (accessId) {
          const { data, errors } = await DeleteArtistGenre({ uuid });
          if (errors) {
            throw errors;
          }
          if (data) {
            dispatch(ArtistActions.removeArtistGenre({ index, relationIndex }));
            await DeleteAccessRecord({ id: accessId });
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        Toast.error("오류가 발생하였습니다", undefined, "top-center");
        setLoading(false);
        return;
      }
    },
    [artistId, dispatch, index]
  );

  return (
    <Layout>
      <Header>
        <h3>아티스트 장르 편집</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <div className="tip">
          <span>실시간으로 변경됩니다.</span>
        </div>
        <FilterGroup>
          <span className="suggestSpan">
            <GenreTextAutoComplete
              className="autocomplete"
              onBlur={info => {
                if (info) {
                  setCurrentGenre({ id: info.id, name: info.name, type: "genre" });
                }
              }}
            />
            <Input.Button
              color="default"
              onClick={() => {
                if (currentGenre) {
                  onAddArtistGenre(currentGenre.id, currentGenre.name);
                }
              }}
            >
              추가
            </Input.Button>
          </span>
          <Divider />
          {!genreRelation.length ? (
            <div className="center-div">
              <span>장르가 없습니다.</span>
            </div>
          ) : (
            genreRelation.map(({ id, genre }, index) => {
              return (
                <ButtonGroup key={id}>
                  <Input.Button color="danger" onClick={() => onRemoveArtistGenre(id, index)}>
                    -
                  </Input.Button>
                  <h4>{index + 1}</h4>
                  <span>{genre[0]?.name ?? "unknown"}</span>
                </ButtonGroup>
              );
            })
          )}
          <Divider />
        </FilterGroup>
      </Section>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  width: ${pixelize(UNIT * 45)};
  height: ${pixelize(UNIT * 40)};

  h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .tip {
    span {
      font-size: 0.8rem;
      color: #999;
    }
    margin-bottom: 0.5rem;
  }
  .center-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .suggestSpan {
    display: flex;
    flex-direction: row;
    margin-bottom: ${MARGING_XX_LARGE_PX};

    & > .autocomplete {
      width: 100%;
      margin-right: ${MARGING_LARGE_PX};
    }

    button {
      height: fit-content;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > button {
    width: ${pixelize(UNIT * 5)};
    margin-top: auto;
    margin-left: auto;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const FilterGroup = styled.section`
  margin-bottom: ${MARGING_LARGE_PX};

  hr {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 40px 40px auto;
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};
  h4 {
    text-align: center;
  }
`;

const Section = styled.div`
  padding: 1rem 2rem;
`;
