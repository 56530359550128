import { useMemo } from "react";

import { identity } from "utils/identity";

export const useQueryParams = (queryString = window.location.search) => {
  const createQueryParamsGetter = () => {
    const urlSearchParams = new window.URLSearchParams(queryString);

    type Get = {
      /**
       * ```typescript
       * get('key', { default: false, cast: value => !!value }) // boolean
       * get('key', { default: 1, cast: value => +value }) // number
       * ```
       */
      <T>(key: string, option: Readonly<{ default: T; cast: (value: string) => T }>): T;
      /**
       * ```typescript
       * get('key') // string
       * get('key', { default: 'value' }) // string
       * get('key', { default: 'value', cast: value => value }) // string
       * ```
       */
      <T extends string>(key: string, option?: Readonly<{ default?: T; cast?: (value: string) => T }>): T;
    };
    const get: Get = <T = string>(key: string, option: { default?: T; cast?: (value: string) => T } = {}) => {
      const cast = option.cast || identity;
      const value = urlSearchParams.get(key);
      return value ? cast(value)! : option.default;
    };

    type GetAll = {
      /**
       * ```typescript
       * getAll('key') // string
       * getAll('key', { cast: value => value}) // string
       * getAll('key', { cast: (value, index) => value }) // string
       * ```
       */
      <T extends string>(key: string, option?: { cast?: (value: string, index: number) => T }): T[];
      /**
       * ```typescript
       * getAll('key', { cast: value => !!value}) // boolean
       * getAll('key', { cast: value => +value}) // number
       * getAll('key', { cast: (value, index) => !!value }) // boolean
       * getAll('key', { cast: (value, index) => +value }) // number
       * ```
       */
      <T>(key: string, option: { cast: (value: string, index: number) => T }): T[];
    };
    const getAll: GetAll = <T = string>(key: string, option: { cast?: (value: string, index: number) => T } = {}) => {
      const valueList = urlSearchParams.getAll(key);
      return valueList.map(option.cast || (identity as (value: string, index: number) => T));
    };

    return { get, getAll };
  };
  return useMemo(createQueryParamsGetter, [queryString]);
};
