import React, { useRef, useMemo, ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { Pagination } from "App/Molecules/Pagination";
import { useAppStore } from "App/Store";
import { TableTemplate } from "App/Templates/TableTemplate";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { BLACK } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR } from "constants/color";
import { ARTISTS_EDIT, ARTISTS } from "constants/route";
import { HEADER_HEIGHT_PX, pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { useArtistsStore } from "../Store";
import { DataTemplate } from "./ArtistItem";
import { useQueryParams } from "lib/use-query-params";
import itiriri from "itiriri";
import { UserRole } from "constants/UserRole";
import { subheadKeyValue } from "./subheadKeyValue";
import { TableFiltermodal } from "./Filter/TableFilterModal";
import { ArtistInfoActions } from "../Store/ArtistInfo";
import { useModal, Modal } from "lib/modal";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { useAsyncEffect } from "lib/use-async-effect";
import { LoadingAction } from "App/Store/Loading";
import { useToggle } from "lib/use-toggle";
import { BatchEditModal } from "./Modals/BatchEditModal";
import { VCFilterModal } from "./Filter/VCFilterModal";
import { ValidCheck } from "../Store/ArtistInfo/sanitizeVCs";
import { ArtistActions } from "../Store/Artist";
import { RoleFilterModal } from "./Filter/RoleFilterModal";
import { AppArtistActions } from "App/Store/Artist";
import { TypeFilterModal } from "./Filter/TypeFilterModal";
import { TypeArtistClass } from "../Store/ArtistInfo/sanitizeType";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { KeyInfo } from "lib/key-info";
import { SelectMenu } from "App/Molecules/SelectMenu";
import { ReactComponent as EditIcon } from "assets/icons/menu-edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as BlockIcon } from "assets/icons/block.svg";
import { Toast } from "lib/toast";
import _ from "lodash";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteArtist } from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";
import { allowArtistUpdate } from "App/Routes/AdminRoutes/allowTables/allowArtistUpdate";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100vh;
  table {
    margin-top: ${HEADER_HEIGHT_PX};
  }
`;

const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: ${MARGING_SMALL_PX};
  }
`;
const SearchColumnSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 7)};
  margin-right: ${MARGING_LARGE_PX};

  .searchColumn__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .searchColumn__menu {
    .searchColumn__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const SearchBar = styled(Input.Group)`
  position: relative;
  min-width: ${pixelize(UNIT * 22)};
  margin-left: auto;
  grid-template-columns: auto min-content;

  & > svg {
    position: absolute;
    top: 10px;
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
  }

  input {
    color: ${BLACK};
    background-color: transparent;
    padding-left: ${PADDING_XX_LARGE_PX};
    margin-right: ${MARGING_LARGE_PX};
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: 0;
    transition: border-bottom 0.5s;
  }
`;

const MarginDiv = styled.div`
  height: ${pixelize(UNIT * 0.2)};
  color: transparent;
`;

const SearchColumnList = [
  { id: "이름", name: "이름" },
  { id: "아이디", name: "아이디" }
];

export const RightArtistTable = () => {
  const router = useHistory();
  const filterModal = useModal();
  const [{ userRole, loading, column, store }, dispatchApp] = useAppStore(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading,
    column: store.Artist.column,
    store: store
  }));
  const searchStringRef = useRef<HTMLInputElement>(null);
  const queryParams = useQueryParams();
  const batchEditModal = useToggle();
  const vcFilterModal = useToggle();
  const roleFilterModal = useToggle();
  const typeFilterModal = useToggle();
  const searchString = queryParams.get("q", { default: "" });
  const searchType = queryParams.get("type", { default: "" });
  const hasCompany = queryParams.get("company", { default: "" });
  const hasUrl = queryParams.get("url", { default: "" });
  const [{ edge, artists, head, subhead, validCheck, roleCheck, typeCheck, checkList }, dispatch] = useArtistsStore(store => ({
    edge: store.Artist.edge,
    artists: store.Artist.artists,
    head: store.ArtistInfo.head,
    subhead: store.ArtistInfo.subhead,
    validCheck: store.ArtistInfo.validCheck,
    roleCheck: store.ArtistInfo.roleCheck,
    typeCheck: store.ArtistInfo.typeCheck,
    checkList: store.Artist.checkList
  }));
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const allowArtistEdit = userRole === UserRole.Master || allowArtistUpdate(store);

  const onToggleAllCheck = (checked: boolean) => {
    artists.forEach(artist => {
      dispatch(ArtistActions.setArtistCheckList({ id: artist.artistId, checked }));
    });
  };

  const isChecked = (list: Map<string, boolean>) => {
    const filteredList = Array.from(list).filter(item => artists.map(({ artistId }: { artistId: string }) => artistId).includes(item[0]));
    return list.size === 0 || !filteredList.length ? false : filteredList.every(item => item[1]);
  };

  const subheadComponentMap = {
    "#": () => (
      <AnimatedCheckbox
        id="allCheck"
        isChecked={isChecked(checkList)}
        isDisabled={!allowArtistEdit}
        onToggle={() =>
          onToggleAllCheck(
            checkList.size === 0
              ? true
              : Array.from(checkList)
                  .filter(item => artists.map(({ artistId }: { artistId: string }) => artistId).includes(item[0]))
                  .some(item => !item[1])
          )
        }
      />
    ),
    typeArtistClass: (props: { subhead: string }) => (
      <CenterBox>
        <span>{props.subhead}</span>
        <Input.Button color="default" onClick={typeFilterModal.on}>
          필터
        </Input.Button>
      </CenterBox>
    ),
    validCheck: (props: { subhead: string }) => (
      <CenterBox>
        <span>{props.subhead}</span>
        <Input.Button color="default" onClick={vcFilterModal.on}>
          필터
        </Input.Button>
      </CenterBox>
    ),
    role: (props: { subhead: string }) => (
      <CenterBox>
        <span>{props.subhead}</span>
        <Input.Button color="default" onClick={roleFilterModal.on}>
          필터
        </Input.Button>
      </CenterBox>
    )
  };

  const createHeads = () => {
    const subheadList = [] as ReactNode[];
    const headList = itiriri(Object.entries(head))
      .filter(([key, isToggledKey]) => {
        if (userRole === UserRole.Client && key === "ArtistTitle") {
          return false;
        } else if (userRole !== UserRole.Master && userRole !== UserRole.Arbeit && key === "#") {
          return false;
        } else {
          return isToggledKey;
        }
      })
      .toArray(([key]) => {
        const localSubheadList = itiriri(Object.entries(subhead[key as keyof typeof subhead]))
          .filter(([subKey, isToggledSubKey]) => {
            if (userRole !== UserRole.Master && userRole !== UserRole.Arbeit && subKey === "memo") {
              return false;
            }
            return isToggledSubKey;
          })
          .toArray(([subKey]) => {
            const Component = subheadComponentMap[subKey as keyof typeof subheadComponentMap];
            if (!Component) {
              return subheadKeyValue[key][subKey];
            }
            return <Component subhead={subheadKeyValue[key][subKey]} />;
          });
        subheadList.push(...localSubheadList);
        return {
          name: key,
          colSpan: localSubheadList.length
        };
      });
    return { headList, subheadList };
  };

  const { headList, subheadList } = useMemo(createHeads, [head, subhead, artists, checkList]);
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const handleFloatingEditButton = async () => {
    if (Array.from(checkList).filter(list => list[1]).length >= 50) {
      window.alert("50개 이상은 한 번에 편집할 수 없습니다.");
      return;
    }
    const url = Array.from(checkList)
      .filter(item => item[1])
      .map(item => `ids=${item[0]}`)
      .join("&");
    router.push(ARTISTS_EDIT + `?page=1&${url}`);
  };

  const handleFloatingDeleteButton = async () => {
    const deleteIds = Array.from(checkList)
      .filter(item => item[1])
      .map(item => item[0]);
    if (window.confirm(`선택한 ${deleteIds.length}개의 아티스트를 삭제하시겠습니까?`)) {
      try {
        setEditLoading(true);
        for (const id of _.reverse(deleteIds)) {
          const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Artist });
          if (accessId) {
            const { errors } = await DeleteArtist({ id });
            if (errors) {
              await DeleteAccessRecord({ id: accessId });
              throw new Error(errors[0].message);
            }
            await DeleteAccessRecord({ id: accessId });
            dispatch(ArtistActions.deleteArtist(id));
          }
        }
        Toast.primary("모두 삭제되었습니다.");
      } catch (err) {
        console.log(err);
        Toast.error("아티스트 삭제에 실패하였습니다.");
        return;
      } finally {
        setEditLoading(false);
      }
    }
  };

  const openTableFilterModal = () => {
    const updateHeads: Parameters<typeof TableFiltermodal>[0]["onClick"] = (head, subhead) => {
      dispatch([ArtistInfoActions.setHead(head), ArtistInfoActions.setSubhead(subhead)]);
      filterModal.close();
    };
    filterModal.open(<TableFiltermodal head={head} subhead={subhead} userRole={userRole} onClick={updateHeads} />);
  };

  const artistSearch = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const searchStr = searchStringRef.current!.value.trim();
    if (searchString === searchStr && column === searchType) {
      return;
    }
    if (searchStr && column === "아이디" && !searchStr.match(/^[0-9]+$/)) {
      window.alert("아이디는 숫자만 입력가능합니다.");
      return;
    }
    dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
    const encodeString = encodeURIComponent(searchStr);
    router.push(
      `?page=1${!searchStr ? "" : `&q=${encodeString}`}${!column ? "" : `&type=${column}`}${!hasCompany ? "" : `&company=true`}${
        !hasUrl ? "" : `&url=true`
      }`
    );
  };

  const updateRole: Parameters<typeof RoleFilterModal>[0]["onClick"] = roleCheck => {
    dispatch(ArtistInfoActions.setRoleCheck(roleCheck));
    roleFilterModal.off();
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        dispatch(ArtistActions.clearArtistCheckList());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        if (edge && !artists.length) {
          dispatchApp(LoadingAction.setLoading(LOADING.NULL));
        }
      }
      const ids = artists.map(artist => artist.artistId);
      dispatch(ArtistActions.setArtistCheckListInit(ids));
    },
    [edge, artists]
  );

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        {allowArtistEdit && <Input.Button onClick={batchEditModal.on}>일괄 편집</Input.Button>}
        {userRole === UserRole.Master && <Input.Button onClick={openTableFilterModal}>필터</Input.Button>}
        {!hasUrl ? (
          <Input.Button
            onClick={() =>
              router.push(
                `${ARTISTS}?page=1${!searchString ? "" : `&q=${encodeURIComponent(searchString)}`}${!column ? "" : `&type=${column}`}${
                  !hasCompany ? "" : `&company=true`
                }&url=true`
              )
            }
          >
            URL 보기
          </Input.Button>
        ) : (
          <Input.Button
            onClick={() =>
              router.push(
                `${ARTISTS}?page=1${!searchString ? "" : `&q=${encodeURIComponent(searchString)}`}${
                  !column ? "" : `&type=${column}${!hasCompany ? "" : `&company=true`}`
                }`
              )
            }
          >
            URL 보기 해제
          </Input.Button>
        )}
        <form style={{ display: "flex" }} onSubmit={artistSearch}>
          <SearchColumnSelect
            classNamePrefix="searchColumn"
            optionList={SearchColumnList}
            defaultValue={{ id: "searchType", name: `${column}` }}
            onChange={info => {
              if (info) {
                dispatchApp(AppArtistActions.setArtistSearchColumn(info.name));
              }
            }}
          />
          <SearchBar>
            <SearchIcon />
            <Input.Text ref={searchStringRef} placeholder="검색어를 입력하세요."></Input.Text>
            <Input.Button type="submit" color="default">
              검색
            </Input.Button>
          </SearchBar>
        </form>
      </TopbarTemplate>
      <TableTemplate
        headList={headList}
        subHeadList={subheadList}
        keyBy={data => data.artistId}
        dataList={loading === LOADING.UNLOAD ? [] : artists}
        Column={DataTemplate}
      />
      <LoadingTemplate loading={loading} searchString={searchString} />
      <Pagination
        edge={edge}
        goTo={index =>
          `${ARTISTS}?page=${index}${!searchString ? "" : `&q=${encodeURIComponent(searchString)}`}${
            !column ? "" : `&type=${column}${!hasCompany ? "" : `&company=true`}${!hasUrl ? "" : `&url=true`}`
          }`
        }
        onIntersect={() => setIsBottom(true)}
        unIntersect={() => setIsBottom(false)}
      />
      <MarginDiv>{"0"}</MarginDiv>
      <Modal isOpen={batchEditModal.isToggled}>
        <BatchEditModal toClose={batchEditModal.off} />
      </Modal>
      <Modal isOpen={vcFilterModal.isToggled}>
        <VCFilterModal
          userRole={userRole}
          validCheck={validCheck}
          onClick={(vc: ValidCheck) => {
            dispatch(ArtistInfoActions.setValidCheck(vc));
            vcFilterModal.off();
          }}
        />
      </Modal>
      <Modal isOpen={typeFilterModal.isToggled}>
        <TypeFilterModal
          userRole={userRole}
          typeCheck={typeCheck}
          onClick={(tc: TypeArtistClass) => {
            dispatch(ArtistInfoActions.setTypeCheck(tc));
            typeFilterModal.off();
          }}
        />
      </Modal>
      <Modal isOpen={roleFilterModal.isToggled}>
        <RoleFilterModal roleCheck={roleCheck} onClick={updateRole} />
      </Modal>
      {allowArtistEdit && (
        <SelectMenu
          selectCount={Array.from(checkList).filter(item => item[1]).length}
          selectInfoString="개를"
          isOpen={!!Array.from(checkList).some(item => item[1])}
          isBottom={isBottom}
        >
          <SelectMenu.IconButton onClick={handleFloatingEditButton}>
            <EditIcon />
            <span style={{ fontSize: "0.8rem" }}>편집</span>
          </SelectMenu.IconButton>
          <SelectMenu.IconButton onClick={handleFloatingDeleteButton}>
            <TrashIcon />
            <span style={{ fontSize: "0.8rem" }}>삭제</span>
          </SelectMenu.IconButton>
          <SelectMenu.IconButton onClick={() => Toast.warning("기능 준비중입니다.")}>
            <BlockIcon />
            <span style={{ fontSize: "0.8rem" }}>준비 중</span>
          </SelectMenu.IconButton>
        </SelectMenu>
      )}
      <Loading loading={editLoading} />
    </Layout>
  );
};
