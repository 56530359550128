import { initializeKeyValue } from "lib/initialize-key-value";

export type ValidCheck = ReturnType<typeof sanitizeVCs>["validCheck"];

export const sanitizeVCs = (option: Record<string, boolean>) => {
  const { ValidCheck: validCheck } = initializeKeyValue(option, {
    ValidCheck: {
      V1: true,
      V2: true,
      V3: true,
      VC: true,
      DONE: true,
      MODIFY: true,
      REMOVE: true,
      ETC: true
    }
  });
  return { validCheck };
};
