/** @jsx jsx */

import { Field, IdScalar, IntScalar, jsx, ListScalar, Query, render, SelectionSet, Variable } from "graphql-jsx";

import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";
import { IdType } from "App/Routes/AuthGrant/Store/IdList";

type Option = Readonly<{
  count: number;
  skip: number;
  idGte?: number;
  idLte?: number;
  idIncludeList: string[];
  idExcludeList: string[];
  validTypeList: ValidType[];
}>;

export const findPlaylistSearchList = async ({ count, skip, idGte, idLte, idIncludeList, idExcludeList, validTypeList }: Option) => {
  const $count = <Variable name="count" scalar={<IntScalar isNullable />} value={count} />;
  const $skip = <Variable name="skip" scalar={<IntScalar isNullable />} value={skip} />;
  const $gte = <Variable name="gte" scalar={<IdScalar isNullable />} value={idGte} />;
  const $lte = <Variable name="lte" scalar={<IdScalar isNullable />} value={idLte} />;
  const $idIncludeList = (
    <Variable name="idIncludeList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={idIncludeList} />
  );
  const $validTypeList = (
    <Variable name="validTypeList" scalar={<ListScalar scalar={<ValidTypeScalar isNullable={false} />} />} value={validTypeList} />
  );

  const { query, variables } = render(
    <Query operationName="FIND_PLAYLIST_SEARCH_LIST">
      <SelectionSet
        name="playlist"
        argument={{
          first: $count,
          skip: $skip,
          group: true,
          where: {
            playlist_id__gte: $gte,
            playlist_id__lte: $lte,
            playlist_id__in: $idIncludeList,
            valid_check__in: $validTypeList
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
      </SelectionSet>
    </Query>
  );

  const response = await clients.playlist.request<{ playlist: IdType[] }>(query, variables);
  return response.errors ? [] : response.data!.playlist;
};
