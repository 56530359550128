import { useToggle } from "lib/use-toggle";
import React from "react";
import { FilterStyle } from "../Style";

export type FilterOption = {
  readonly key: string;
  readonly value: string;
  readonly isSelected?: boolean;
};

type Props = {
  readonly title: string;
  readonly subTitle: string;
  readonly optionList: FilterOption[];
  readonly onSelectItem: (value: string) => void;
};

export const FilterTemplate = ({ title, subTitle, optionList, onSelectItem }: Props) => {
  const toggleFilter = useToggle(false);

  return (
    <FilterStyle.Layout>
      <FilterStyle.Title onClick={() => toggleFilter.toggle()}>
        <FilterStyle.TitleBox>
          <FilterStyle.MainTitle>{title}</FilterStyle.MainTitle>
          <FilterStyle.Placeholder>{subTitle}</FilterStyle.Placeholder>
        </FilterStyle.TitleBox>
        <FilterStyle.IconBox>
          <FilterStyle.PlusIcon />
        </FilterStyle.IconBox>
      </FilterStyle.Title>
      <FilterStyle.TitleMenu data-visible={toggleFilter.isToggled}>
        <FilterStyle.MenuList>
          {optionList.map(({ key, value, isSelected }) => (
            <FilterStyle.MenuItem key={key} onClick={() => onSelectItem(value)}>
              {isSelected ? <FilterStyle.CheckboxFillIcon /> : <FilterStyle.CheckboxIcon />}

              <FilterStyle.MenuText>{key}</FilterStyle.MenuText>
            </FilterStyle.MenuItem>
          ))}
        </FilterStyle.MenuList>
      </FilterStyle.TitleMenu>
    </FilterStyle.Layout>
  );
};
