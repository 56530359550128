import React from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { Progress } from "utils/progress";
import { useParams } from "lib/use-router";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { useScrollToTop } from "../GoodsCreate/Hooks/useScrollToTop";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { Input } from "App/Atomics/Input";
import { useHistory } from "lib/use-router";
import { ProductDetailForm } from "./ProductDetailForm";
import { useLoadProductDetail } from "./Hooks/useLoadProductDetail";
import { useUpdateProduct } from "./Hooks/useUpdateProduct";
import { useLoadGoodsSubadatas } from "./Hooks/useLoadGoodsSubdatas";
import { useProductDetailSelector } from "./Store";

export const ProductDetail = () => {
  const { id } = useParams<{ id: string }>();
  const goods = useProductDetailSelector(store => store.ProductDetail.product.goods);
  const { updateProduct } = useUpdateProduct();
  const navigate = useHistory();
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  useLoadProductDetail({ id: id! });
  useLoadGoodsSubadatas(goods?.id);
  useScrollToTop();

  const goBack = () => {
    if (window.confirm("이전 페이지로 돌아가시겠습니까?")) {
      navigate.goBack();
    }
  };

  return (
    <Layout>
      <Helmet title="상품 상세 정보" />
      <Progress.Bottom />
      <LeftSideBar />
      <TopbarTemplate openSidebar={openSidebar}>
        <ButtonGroup>
          <UpdateButton>
            <Input.Button color="danger" onClick={goBack}>
              돌아가기
            </Input.Button>
          </UpdateButton>
          <UpdateButton>
            <Input.Button color="primary" onClick={updateProduct}>
              저장하기
            </Input.Button>
          </UpdateButton>
        </ButtonGroup>
      </TopbarTemplate>
      <ProductDetailForm />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;
const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
const UpdateButton = styled.div`
  & + & {
    margin-left: 8px;
  }
`;
