import React, { ReactElement, useRef } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";

type Props = {
  readonly name: string;
};

export const GoodsName = ({ name }: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Style.Row>
      <Style.Caption>상품명</Style.Caption>
      <Style.SubCaption>ex. 블랙핑크 - THE ALBUM / 1집 정규앨범</Style.SubCaption>
      <Style.InputText
        ref={inputRef}
        placeholder={"상품명"}
        defaultValue={name}
        onBlur={value => GoodsDetailStore.dispatch(GoodsDetailActions.setName(value))}
      />
      <Style.AlertCaption data-visible={!name?.length}>이름을 입력해주세요.</Style.AlertCaption>
    </Style.Row>
  );
};
