import { MoodRelation } from "./../../../../Organisms/TagModal/services/loadMoodList";
import { createDuck } from "lib/store/v2";

type Title = {
  type_metadata_title: string;
  value: string;
};

type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  character_id?: string;
  character_name?: string;
  order?: number;
};

type Work = {
  no: string;
  id: string;
  title: string;
};

type Company = {
  company_id: string;
  name: string;
  license: string;
  type: string;
  path?: string;
};

type Production = {
  company_id: string;
  name: string;
};

type Publication = {
  company_id: string;
  name: string;
};

type Genre = {
  id: string;
  name: string;
  typeKind?: string;
  order?: number;
  parent?: {
    id: string;
    name: string;
  };
  relationList?: MoodRelation[];
};

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
  youtubeUrl?: string;
};

export type License = {
  notice: string;
  country_code: string;
  type_track: string;
  publish_date: string;
  is_service: number;
};

export type Extra = {
  record_year: string;
  publish_year: string;
  first_edition: string;
};

export type Album = {
  type_metadata_class: string;
  type_metadata_subclass: string;
  title: string;
  no: string;
  releaseDate: string;

  albumArtist: Artist[];
  albumUrl: Url;
  albumCompany: Company;

  productions: Production[];
  publications: Publication[];

  ytv_Url?: string;
};

export type Track = {
  type_metadata_class: string;
  type_metadata_subclass: string;
  title: string;
  no: string;

  trackTitle: Title;
  trackArtist: Artist[];
  trackUrl: {
    trackMp3: Url;
    trackAac: Url;
    trackFlac: Url;
    trackWav: Url;
    trackTxt: Url;
    trackZip: Url;
  };
  trackGenre: Genre[];
  trackLicense: License;
  trackLicenseExtra: Extra;
  sourceUrl: string;

  work: {
    id: string;
    title: string;
    order?: number;
  }[];
};

type OptionList = {
  id: string;
  name: string;
}[];

export enum FileMode {
  URL,
  FILE,
  EFFECT
}

export type ContentsState = "ALBUM" | "TRACK" | "SUBMIT" | undefined;

const createInitialState = () => ({
  album: {} as Album,
  trackList: [] as Track[],
  pageState: "ALBUM" as ContentsState,
  isSingle: true,
  fileMode: FileMode.FILE as FileMode,
  isEffect: false
});

export const AlbumActions = createDuck({
  namespace: "AlbumCreate",
  createInitialState,
  reducers: {
    setAlbum(state, album: Album) {
      state.album = album;
    },
    setIsEffect(state, isEffect: boolean) {
      state.isEffect = isEffect;
    },
    setAlbumNo(state, no: string) {
      state.album.no = no;
    },
    setAlbumDescription(state, description: string) {
      state.album.title = description;
    },
    setAlbumClass(state) {
      state.album.type_metadata_class = "record";
      state.album.type_metadata_subclass = "album";
    },
    setAlbumArtist(state, artist: Artist[]) {
      state.album.albumArtist = artist;
    },
    setAlbumUrl(state, { file, value, ext, name, valueType }: { file: File; value: string; ext: string; name: string; valueType: string }) {
      state.album.albumUrl = {
        file: file,
        ext: ext,
        typeUrl: valueType,
        data: value,
        url: name
      };
    },
    setAlbumCompany(state, company: Company) {
      state.album.albumCompany = company;
    },
    setAlbumProductions(state, productions: Production[]) {
      state.album.productions = productions;
    },
    setAlbumPublications(state, publications: Publication[]) {
      state.album.publications = publications;
    },
    setAlbumRelease(state, releaseDate: string) {
      state.album.releaseDate = `${releaseDate}`;
    },
    setAlbumIsSingle(state, isSingle: boolean) {
      state.isSingle = isSingle;
    },
    setAlbumYtvUrl(state, url: string) {
      state.album.ytv_Url = url;
    },
    setNewTrack(state, { subClass, no, license }: { subClass: "track" | "effect"; no: string; license: License }) {
      state.trackList.push({
        no: no.length === 1 ? "0" + no : no,
        type_metadata_class: "record",
        type_metadata_subclass: subClass,
        title: "",
        trackArtist:
          subClass === "effect"
            ? []
            : state.album.albumArtist.map(({ artist_id, artist_name, role_id, role_name, order }) => ({
                artist_id,
                artist_name,
                role_id,
                role_name,
                order
              })),
        trackUrl: {
          trackMp3: {} as Url,
          trackAac: {} as Url,
          trackFlac: {} as Url,
          trackWav: {} as Url,
          trackTxt: {} as Url,
          trackZip: {} as Url
        },
        trackLicense: license,
        trackLicenseExtra: {} as Extra
      } as Track);
    },
    setTrackRenamedNo(state) {
      state.trackList.forEach((track, index) => {
        const no = (index + 1).toString();
        return (track.no = no.length >= 2 ? no : "0" + no);
      });
    },
    setTrackTitle(state, { no, title }: { no: string; title: string }) {
      state.trackList[parseInt(no) - 1].title = title;
    },
    setTrackDescription(state, { no, typeTitle, description }: { no: string; typeTitle: string; description: string }) {
      state.trackList[parseInt(no) - 1].trackTitle = {
        type_metadata_title: typeTitle,
        value: description ? description : ""
      } as Title;
    },
    setTrackWork(state, work: Work[]) {
      const workInfo = work.map(({ id, title }) => ({ id, title }));
      state.trackList[parseInt(work[0].no) - 1].work = workInfo;
    },
    setTrackArtist(state, { no, artist }: { no: string; artist: Artist[] }) {
      state.trackList[parseInt(no) - 1].trackArtist = artist;
    },
    setTrackGenre(state, { no, genre }: { no: string; genre: Genre[] }) {
      state.trackList[parseInt(no) - 1].trackGenre = genre;
    },
    setTrackUrl(state, { no, fileType, url }: { no: string; fileType: string; url: string }) {
      switch (fileType) {
        case "mp3high":
          state.trackList[parseInt(no) - 1].trackUrl.trackMp3 = {
            file: state.trackList[parseInt(no) - 1].trackUrl.trackMp3.file,
            ext: "mp3",
            typeUrl: "mp3high",
            data: state.trackList[parseInt(no) - 1].trackUrl.trackMp3.data,
            url: url
          };
          break;
        case "aac":
          state.trackList[parseInt(no) - 1].trackUrl.trackAac = {
            file: state.trackList[parseInt(no) - 1].trackUrl.trackAac.file,
            ext: state.trackList[parseInt(no) - 1].trackUrl.trackAac.ext,
            typeUrl: "aac",
            data: state.trackList[parseInt(no) - 1].trackUrl.trackAac.data,
            url: url
          };
          break;
        case "flac":
          state.trackList[parseInt(no) - 1].trackUrl.trackFlac = {
            file: state.trackList[parseInt(no) - 1].trackUrl.trackFlac.file,
            ext: "flac",
            typeUrl: "flac",
            data: state.trackList[parseInt(no) - 1].trackUrl.trackFlac.data,
            url: url
          };
          break;
        case "wav":
          state.trackList[parseInt(no) - 1].trackUrl.trackWav = {
            file: state.trackList[parseInt(no) - 1].trackUrl.trackWav.file,
            ext: "wav",
            typeUrl: "wav",
            data: state.trackList[parseInt(no) - 1].trackUrl.trackWav.data,
            url: url
          };
          break;
        case "txt":
          state.trackList[parseInt(no) - 1].trackUrl.trackTxt = {
            file: state.trackList[parseInt(no) - 1].trackUrl.trackTxt.file,
            ext: "txt",
            typeUrl: "txt",
            data: state.trackList[parseInt(no) - 1].trackUrl.trackTxt.data,
            url: url
          };
          break;
        case "zip":
          state.trackList[parseInt(no) - 1].trackUrl.trackZip = {
            file: state.trackList[parseInt(no) - 1].trackUrl.trackZip.file,
            ext: "zip",
            typeUrl: "zip",
            data: state.trackList[parseInt(no) - 1].trackUrl.trackZip.data,
            url: url
          };
          break;
        default:
          break;
      }
    },
    setTrackData(state, { no, file, data, fileType, name }: { no: string; file: File; data: string; fileType: string; name: string }) {
      switch (fileType) {
        case "mp3high":
          state.trackList[parseInt(no) - 1].trackUrl.trackMp3 = {
            file: file,
            ext: "mp3",
            typeUrl: "mp3high",
            data: data,
            url: name
          } as Url;
          break;
        case "aac":
          state.trackList[parseInt(no) - 1].trackUrl.trackAac = {
            file: file,
            ext: name.split(".").pop(), // "aac" or "m4a"
            typeUrl: "aac",
            data: data,
            url: name
          } as Url;
          break;
        case "flac":
          state.trackList[parseInt(no) - 1].trackUrl.trackFlac = {
            file: file,
            ext: "flac",
            typeUrl: "flac",
            data: data,
            url: name
          } as Url;
          break;
        case "wav":
          state.trackList[parseInt(no) - 1].trackUrl.trackWav = {
            file: file,
            ext: "wav",
            typeUrl: "wav",
            data: data,
            url: name
          } as Url;
          break;
        case "txt":
          state.trackList[parseInt(no) - 1].trackUrl.trackTxt = {
            file: file,
            ext: "txt",
            typeUrl: "txt",
            data: data,
            url: name
          } as Url;
          break;
        case "zip":
          state.trackList[parseInt(no) - 1].trackUrl.trackZip = {
            file: file,
            ext: "zip",
            typeUrl: "zip",
            data: data,
            url: name
          } as Url;
          break;
        default:
          break;
      }
    },
    setTrackLicense(
      state,
      {
        no,
        notice,
        typeTrack,
        publishDate,
        isService
      }: { no: string; notice: string; typeTrack: string; publishDate: string; isService: number }
    ) {
      state.trackList[parseInt(no) - 1].trackLicense = {
        notice: notice,
        country_code: "ZZ",
        type_track: typeTrack,
        publish_date: publishDate,
        is_service: isService
      } as License;
    },
    setTrackLicenseService(state, { no, isService }: { no: string; isService: number }) {
      state.trackList[parseInt(no) - 1].trackLicense.is_service = isService;
    },
    setTrackLicenseNotice(state, { no, notice }: { no: string; notice: string }) {
      state.trackList[parseInt(no) - 1].trackLicense.notice = notice;
    },
    setTrackLicenseNoticeSelected(state, { no, noticeType, notice }: { no: string; noticeType: string; notice: string }) {
      const stateNotice = JSON.parse(state.trackList[parseInt(no) - 1].trackLicense.notice);
      stateNotice[noticeType] = notice;
      state.trackList[parseInt(no) - 1].trackLicense.notice = JSON.stringify(stateNotice);
    },
    setTrackLicenseNoticeCompanyIdSelected(
      state,
      { no, companyIndex, noticeType, value }: { no: string; companyIndex: number; noticeType: string | null; value: string | null }
    ) {
      const notice = JSON.parse(state.trackList[parseInt(no) - 1].trackLicense.notice);
      const companyIdsList: [string, string | null][] = Object.entries(notice["companyIds"] ?? {});
      if (noticeType === null && value === null) {
        companyIdsList.push(["", null]);
      } else if (noticeType && value === null) {
        companyIdsList[companyIndex][0] = noticeType;
      } else {
        companyIdsList[companyIndex][1] = value;
      }
      const companyNotice = Object.fromEntries(companyIdsList);
      notice["companyIds"] = companyNotice;
      state.trackList[parseInt(no) - 1].trackLicense.notice = JSON.stringify(notice);
    },
    removeTrackLicenseNoticeSelected(state, { no, companyIndex }: { no: string; companyIndex: number }) {
      const notice = JSON.parse(state.trackList[parseInt(no) - 1].trackLicense.notice);
      const companyIdsList: [string | null, string | null][] = Object.entries(notice["companyIds"] ?? {});
      if (companyIdsList.length) {
        companyIdsList.splice(companyIndex, 1);
      }
      const companyNotice = Object.fromEntries(companyIdsList);
      notice["companyIds"] = companyNotice;
      state.trackList[parseInt(no) - 1].trackLicense.notice = JSON.stringify(notice);
    },
    setTrackExtraRecord(state, { no, recordYear }: { no: string; recordYear: number }) {
      state.trackList[parseInt(no) - 1].trackLicenseExtra.record_year = recordYear.toString();
    },
    setTrackExtraPublish(state, { no, publishDate }: { no: string; publishDate: number }) {
      state.trackList[parseInt(no) - 1].trackLicenseExtra.publish_year = publishDate.toString();
    },
    setTrackExtraEdition(state, { no, firstEdition }: { no: string; firstEdition: number }) {
      state.trackList[parseInt(no) - 1].trackLicenseExtra.first_edition = firstEdition.toString();
    },
    setTrackSource(state, { no, sourceUrl }: { no: string; sourceUrl: string }) {
      state.trackList[parseInt(no) - 1].sourceUrl = sourceUrl;
    },
    removeTrackByNo(state, no: string) {
      state.trackList.splice(parseInt(no) - 1, 1);
    },
    clearTrack(state) {
      state.trackList = [] as Track[];
    },
    setPageState(state, pageState: ContentsState) {
      state.pageState = pageState;
    },
    setTrackFileMode(state, mode: FileMode) {
      state.fileMode = mode;
    }
  }
});
