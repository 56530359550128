import React from "react";
import styled from "styled-components";
import { MARGING_LARGE_PX, MARGING_SMALL_PX, PADDING_XX_LARGE_PX, pixelize, UNIT, UNIT_PX, HEADER_HEIGHT_PX } from "constants/size";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { WHITE, GRAY_2, GRAY_5, GRAY_4, GRAY_6, BLUE_8 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, SECONDARY_COLOR } from "constants/color";
import { BottomTemplate } from "./BottomTemplate";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  background: ${GRAY_2};

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: ${pixelize(UNIT * 55)};
  background: ${WHITE};
  align-self: center;
  box-shadow: 0 0 8px ${GRAY_5};
  box-sizing: border-box;
  padding: ${PADDING_XX_LARGE_PX};

  input[type="text"],
  input[type="password"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 8px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
`;

export const TextDivider = styled.div`
  display: flex;
  align-items: center;
  margin: ${pixelize(28)} 0;
  span {
    color: ${GRAY_4};
    margin-right: ${MARGING_LARGE_PX};
  }
  div {
    width: 100%;
    height: 1px;
    background-color: ${GRAY_4};
  }
`;

export const IconInputText = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 35%;
    right: 4%;
    z-index: 1;
    display: none;
    width: ${UNIT_PX};
    height: ${UNIT_PX};
  }

  &:hover {
    svg {
      display: block;
      cursor: pointer;

      &:hover {
        fill: ${BLUE_8};
      }
    }
  }
`;

const ProfileLayout = () => {
  return (
    <ResponsiveToSideNavigatorTemplate>
      <Helmet title="프로필" />
      <Layout>
        <ProfileContainer>
          <BottomTemplate />
        </ProfileContainer>
      </Layout>
    </ResponsiveToSideNavigatorTemplate>
  );
};

export const Profile = () => <ProfileLayout />;
