import React, { useState } from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { ColumnProps, TableTemplate } from "App/Templates/TableTemplate";
import { PRIMARY_COLOR, SUCCESS_COLOR_LIGHT, DANGER_COLOR, DANGER_COLOR_LIGHT, SUCCESS_COLOR } from "constants/color";
import {
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_X_LARGE_PX,
  PADDING_XXX_LARGE_PX,
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_XX_LARGE
} from "constants/size";
import { useAsyncEffect } from "lib/use-async-effect";
import { Divider } from "App/Atomics/Divider";
import { GetAccount, GetTotalAccount, GetCompanyUserInfo } from "GraphQL/Queries";
import { Account } from "GraphQL/Queries/Access/GetAccount";
import { GRAY_7, WHITE, GRAY_4 } from "constants/baseColor";
import { UserRole } from "constants/UserRole";
import { Input } from "App/Atomics/Input";
import { mediaQuery } from "constants/media";
import { convertNumberToMoney } from "lib/convertNumberToMoney";
import { useSettleStore } from "../../Store";
import { SettleActions } from "../../Store/Settle";
import { useAppStore } from "App/Store";
import { CompanyUserRelation } from "GraphQL/Queries/Access/GetCompanyUserInfo";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { LoadingAction } from "App/Store/Loading";
import { Toast } from "lib/toast";
import { useHistory } from "react-router";
import { SETTLEMENT_MONTHLY } from "constants/route";
import Time from "dayjs";

const SettleLayout = styled.div`
  margin: 2rem;
  min-height: 84vh;
  padding: ${PADDING_XXX_LARGE_PX};
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  h2 {
    margin-top: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  .cursor-table {
    tr {
      cursor: pointer;
    }
  }

  ${mediaQuery(1200)} {
    padding: ${pixelize(PADDING_XX_LARGE * 1.2)};
  }

  ${mediaQuery(768)} {
    padding: ${PADDING_XX_LARGE_PX};
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const Heading3 = styled.h3`
  display: flex;
  margin-top: ${MARGING_X_LARGE_PX};
  margin-bottom: ${MARGING_LARGE_PX};
  align-items: center;
  span {
    margin-left: ${MARGING_SMALL_PX};
    font-size: 0.8em;
    font-weight: normal;
    color: ${PRIMARY_COLOR};
  }

  .danger {
    color: ${DANGER_COLOR};
  }
`;

const NoData = styled.div`
  width: 100%;
  padding: ${PADDING_XX_LARGE_PX} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${GRAY_7};
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyTextSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 18)};
  margin-right: ${MARGING_LARGE_PX};
`;

const EmptySection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 12rem;
  font-size: 1rem;
  font-weight: 400;
`;

export const SettleMain = () => {
  const [{ account, totalAccount, companyInfo }, dispatch] = useSettleStore(store => ({
    account: store.Settle.account,
    totalAccount: store.Settle.totalAccount,
    companyInfo: store.Settle.companyInfo
  }));
  const [companyOptionList, setCompanyOptionList] = useState<CompanyUserRelation[]>([]);
  const [{ userRole, loading }, dispatchApp] = useAppStore(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading
  }));
  const [fromDate, setFromDate] = useState<string>(
    Time()
      .add(-2, "y")
      .format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState<string>(Time().format("YYYY-MM-DD"));
  const router = useHistory();

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          // TODO: Account Qeury
          dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
          const { data: accountData, errors: accountError } = await GetAccount({
            id: companyInfo.users ? companyInfo.users[0].id : undefined,
            fromDate,
            toDate
          });
          if (accountError && accountError.length) {
            console.log(accountError[0].message);
            return;
          }
          // Total Qeury
          const { data: totalData, errors: totalError } = await GetTotalAccount({
            id: companyInfo.users ? companyInfo.users[0].id : undefined
          });
          if (totalError && totalError.length) {
            console.log(totalError[0].message);
            return;
          }
          // Company-User Qeury
          const { data: companyData, errors: companyError } = await GetCompanyUserInfo({});
          if (companyError && companyError.length) {
            console.log(companyError[0].message);
            return;
          }

          if (accountData && totalData && companyData) {
            setCompanyOptionList(companyData.companyUserRelation);
            dispatch([
              SettleActions.setSettleAccount(accountData.getAccount),
              SettleActions.setSettleTotalAccount(totalData.getTotalAccount)
            ]);
          }
        } catch (err) {
          console.log(err);
          Toast.error("정보를 불러올 수 없습니다.");
          return;
        } finally {
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      }
    },
    [companyInfo, fromDate, toDate]
  );

  const totalHeadList = [{ name: "누적 수익" }, { name: "선급금" }, { name: "지급 금액" }, { name: "미지급 금액" }];
  const totalDataList = [{ resultData: totalAccount }];
  const totalTemplate = ({ data }: ColumnProps<any>) => {
    return (
      <Table.Row>
        <AlignCenterData>
          {!data.resultData.cumulativeAmount ? "0 원" : `${convertNumberToMoney(data.resultData.cumulativeAmount)} 원`}
        </AlignCenterData>
        <AlignCenterData>
          {!data.resultData.depositAmount ? "0 원" : `${convertNumberToMoney(data.resultData.depositAmount)} 원`}
        </AlignCenterData>
        <AlignCenterData>{!data.resultData.paidAmount ? "0 원" : `${convertNumberToMoney(data.resultData.paidAmount)} 원`}</AlignCenterData>
        <AlignCenterData>
          {!data.resultData.accountsPayable ? "0 원" : `${convertNumberToMoney(data.resultData.accountsPayable)} 원`}
        </AlignCenterData>
      </Table.Row>
    );
  };

  const serviceHeadList = [{ name: "수익 발생 연월" }, { name: "정산 금액" }, { name: "상태" }];
  const masterHeadList = serviceHeadList.concat({ name: "이메일" });

  const onClickRow = (year: string, month: string) => {
    const fromDate = `${year}-${month}-01`;
    const date = new Date(parseInt(year), parseInt(month), 0);
    const toDate = `${year}-${month}-${date.getDate().toString()}`;

    dispatch(SettleActions.setSettleDateInfo({ fromDate, toDate }));
    router.push(SETTLEMENT_MONTHLY);
  };

  const serviceTemplate = ({ data, index }: ColumnProps<Account>) => {
    const { createdAt, totalAmount, users } = data;
    const year = createdAt.slice(0, 4);
    const month = createdAt.slice(5, 7);
    const changeAmount = index + 1 === account.length ? 0 : totalAmount - account[index + 1].totalAmount;
    return (
      <Table.Row onClick={() => onClickRow(year, month)}>
        {data && (
          <>
            <AlignCenterData style={{ width: "20%" }}>{`${year}년 ${month}월`}</AlignCenterData>
            <AlignCenterData>
              <Amount isPlus={changeAmount >= 0}>
                <span>{`${convertNumberToMoney(totalAmount)}원`}</span>
                {/* {changeAmount !== 0 && <span className="change_amount">{`${changeAmount >= 0 ? "+" : ""}${changeAmount}원`}</span>} */}
              </Amount>
            </AlignCenterData>
            <AlignCenterData style={{ color: SUCCESS_COLOR, width: "15%" }}>{"정산"}</AlignCenterData>
            {userRole === UserRole.Master && (
              <AlignCenterData style={{ width: "20%", fontSize: "0.9rem" }}>{!users.length ? "" : users[0].email}</AlignCenterData>
            )}
          </>
        )}
      </Table.Row>
    );
  };

  return (
    <SettleLayout>
      <h1>정산 내역</h1>
      <Divider />
      {userRole === UserRole.Master ? (
        <>
          <Heading3>권리사 선택</Heading3>
          <SelectContainer>
            <CompanyTextSelect
              key={companyInfo.uuid}
              optionList={
                !companyOptionList || !companyOptionList.length
                  ? []
                  : companyOptionList.map(data => ({ id: data.uuid, name: data.company[0].name, extra: data }))
              }
              placeholder="권리사를 선택하세요."
              defaultValue={companyInfo.company ? { id: companyInfo.uuid, name: companyInfo.company[0].name } : undefined}
              onChange={info => {
                if (info) {
                  dispatch(SettleActions.setSettleCompanyInfo(info.extra as CompanyUserRelation));
                }
              }}
            />
            <Input.Button color="danger" onClick={() => dispatch(SettleActions.setSettleCompanyInfo({} as CompanyUserRelation))}>
              초기화
            </Input.Button>
          </SelectContainer>
        </>
      ) : null}
      <>
        <Heading3>날짜 선택</Heading3>
        <DateGroup>
          <InputDate type="date" defaultValue={fromDate} onChange={e => setFromDate(e.target.value)} />
          <span>부터</span>
          <InputDate type="date" defaultValue={toDate} onChange={e => setToDate(e.target.value)} />
          <span>까지</span>
        </DateGroup>
      </>
      {loading === LOADING.UNLOAD ? (
        <LoadingTemplate loading={loading} />
      ) : (
        <>
          {userRole === UserRole.Master && !companyInfo.uuid ? (
            <EmptySection>권리사를 먼저 선택해주세요.</EmptySection>
          ) : (
            <>
              <Heading3>
                누적 정산 내역
                {userRole === UserRole.Master && <span className="danger">(권리사를 선택하면 누적 정산 내역이 표시됩니다.)</span>}
              </Heading3>
              <TableTemplate headList={totalHeadList} keyBy={(data, index) => index} dataList={totalDataList} Column={totalTemplate} />
              <Heading3>
                월별 상세 내역 <span>(선택하시면 상세정보를 확인할 수 있습니다.)</span>
              </Heading3>
              <TableTemplate
                className="cursor-table"
                headList={userRole !== UserRole.Master ? serviceHeadList : masterHeadList}
                keyBy={(data, index) => index}
                dataList={account}
                Column={serviceTemplate}
              />
            </>
          )}
          {account.length ? null : <NoData>내역이 없습니다.</NoData>}
        </>
      )}
    </SettleLayout>
  );
};

const Amount = styled.span<{ isPlus: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  .change_amount {
    margin-left: 8px;
    color: ${props => (props.isPlus ? SUCCESS_COLOR_LIGHT : DANGER_COLOR_LIGHT)};
    font-size: 0.8rem;
  }
`;

const DateGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${MARGING_LARGE_PX} 0;
  span {
    margin: 0 ${MARGING_LARGE_PX};
  }
`;

const InputDate = styled.input`
  width: ${pixelize(UNIT * 10)};
  height: ${pixelize(UNIT * 2.25)};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  background: ${WHITE};
  text-align: center;
`;
