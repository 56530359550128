import React from "react";
import styled from "styled-components";
import { BLUE_8 } from "constants/baseColor";
import { FilterGroup, Filters } from "../../index";
import { Input } from "App/Atomics/Input";

type Props = {
  gte?: number;
  lte?: number;
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

const RangeSearchGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 9px;

  & > div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 0.5rem;
      color: red;
      margin-left: 5px;
    }
  }
  & > span {
    margin-top: 0.8rem;
    color: #ddd;
  }
  & > div > input {
    border: 1.2px solid #ddd;
    width: 8rem;
    margin: 0.1rem;
    padding: 0.6rem;
    font-size: 0.8rem;
    text-align: left;
    border-radius: 8px;
    transition: 0.2s all;
    &:focus {
      border-color: ${BLUE_8};
    }
  }
`;

export const GteLteFilter = ({ gte, lte, setFilters }: Props) => (
  <FilterGroup>
    <h4>아이디</h4>
    <span className="info">아이디는 숫자만 입력가능합니다.</span>
    <RangeSearchGroup>
      <div>
        <span>최소 ID</span>
        <Input.Text
          placeholder="1 ~ 9999999"
          pattern="\d*"
          defaultValue={!gte ? undefined : gte.toString()}
          onChange={id => setFilters(filter => ({ ...filter, gte: parseInt(id, 10) }))}
        />
      </div>
      <span>~</span>
      <div>
        <span>최대 ID</span>
        <Input.Text
          placeholder="1 ~ 9999999"
          pattern="\d*"
          defaultValue={!lte ? undefined : lte.toString()}
          onChange={id => setFilters(filter => ({ ...filter, lte: parseInt(id, 10) }))}
        />
      </div>
    </RangeSearchGroup>
  </FilterGroup>
);
