type Subheading = Record<string, Record<string, string>>;

export const subheadKeyValue = {
  Artist: {
    artistId: "ID",
    name: "이름",
    typeArtistClass: "타입",
    validCheck: "유효성"
  },
  ArtistGenre: {
    name: "이름"
  },
  ArtistUrl: {
    url: "썸네일 | 타입 | 주소"
  },
  History: {
    birthDate: "태어난 날",
    birthPlace: "태어난 곳",
    deathDate: "사망한 날",
    deathPlace: "사망한 곳"
  },
  Role: {
    name: "이름"
  }
} as Subheading;
