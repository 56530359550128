/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar, StringScalar, IntScalar } from "graphql-jsx";
import { DateTimeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
  name?: string;
  barcode?: string;
  quality?: string;
  price?: number;
  createdAt?: string;
}>;

export const UpdateCommodity = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const $barcode = <Variable name="barcode" scalar={<StringScalar isNullable />} value={option.barcode} />;
  const $quality = <Variable name="quality" scalar={<StringScalar isNullable />} value={option.quality} />;
  const $price = <Variable name="price" scalar={<IntScalar isNullable />} value={option.price} />;
  const $createdAt = <Variable name="createdAt" scalar={<DateTimeScalar isNullable />} value={option.createdAt} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_COMMODITY">
      <SelectionSet
        name="updateCommodity"
        argument={{
          where: {
            commodity_id: $id
          },
          data: {
            price: $price,
            barcode: $barcode,
            quality: $quality,
            created_at: $createdAt
          }
        }}
      >
        <Field name="commodity_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateCommodity: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
