import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";
import { GRAY_7 } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { InitComment, ParsedComment, SubPage } from "../..";
import { PRIMARY_COLOR } from "constants/color";
import { Playlist } from "GraphQL/Queries/Playlist/GetPlaylistWithTrack";
import { Loading } from "App/Atomics/Loading";
import { Toast } from "lib/toast";
import { CreateComment } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { PlaylistSection } from "./PlaylistSection";

type Props = {
  index: number;
  id: string;
  initialComment?: InitComment;
  setInitialComment: React.Dispatch<React.SetStateAction<InitComment>>;
  setPage: React.Dispatch<React.SetStateAction<SubPage>>;
};

export const CommentWriteForm = ({ index, id, initialComment, setInitialComment, setPage }: Props) => {
  const [playlistInfo, setPlaylistInfo] = useState<Playlist>({} as Playlist);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<ParsedComment | undefined>(initialComment?.value);
  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    const data = !comment ? [] : comment.data;
    setComment({ data, text });
  };

  const onThumbDown = useCallback(
    (id: string, title: string) => {
      const data = !comment ? [] : comment.data;
      const text = !comment ? "" : comment.text;
      setComment({ data, text: `${text}[${id}] ${title} 곡이 마음에 들지 않습니다.\n` });
    },
    [comment]
  );

  const onSaveComment = async () => {
    if (!comment) {
      Toast.error("댓글 내용을 작성해주세요.", undefined, "top-center");
      return;
    }
    try {
      setLoading(true);
      const { data, errors } = await CreateComment({
        targetId: id,
        targetTable: TargetTableInput.Playlist,
        value: JSON.stringify(comment),
        parentId: initialComment?.isReply ? initialComment.uuid : undefined
      });
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (data) {
        setLoading(false);
        Toast.primary("저장되었습니다.", undefined, "top-center");
        setPage(SubPage.FIRST);
        setInitialComment({} as InitComment);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      Toast.error("오류가 발생하였습니다.", undefined, "top-center");
      return;
    }
  };

  return (
    <Layout>
      <CommentSection>
        <Header>
          <CommentIcon className="comment-icon" />
          <h3>댓글 작성</h3>
        </Header>
        <div className="playlist-info">
          <div className="playlist-info-box">
            <strong>{playlistInfo.kind}</strong>
            <span>{playlistInfo.name}</span>
          </div>
        </div>
        <form className="comment-form">
          <textarea placeholder="댓글을 작성해주세요" value={!comment ? undefined : comment.text} onChange={onChangeComment} />
        </form>
        <div className="comment-info">
          <p>
            <span>댓글 작성 팁</span>
            우측 트랙 목록의 재생버튼으로 음원을 들을 수 있습니다.
            <br />
            해당 음악이 마음에 들지 않을 경우, 댓글에 적거나 '싫어요' 아이콘을 눌러주시면 문구가 작성됩니다.
          </p>
        </div>
        <ButtonGroup>
          <Input.Button
            className="back-btn"
            onClick={() => {
              if (
                comment &&
                comment.text &&
                !window.confirm(
                  `작성중인 내용이 있습니다. ${
                    !comment.data.length ? "" : "저장하지 않을 경우 기록 관리에 영향이 갈 수 있습니다. "
                  }돌아가시겠습니까?`
                )
              ) {
                return;
              }
              setPage(SubPage.FIRST);
              setInitialComment({} as InitComment);
            }}
          >
            돌아가기
          </Input.Button>
          <Input.Button color="primary" onClick={onSaveComment}>
            작성완료
          </Input.Button>
        </ButtonGroup>
      </CommentSection>
      <PlaylistSection
        index={index}
        id={id}
        onThumbDown={onThumbDown}
        playlistInfo={playlistInfo}
        setPlaylistInfo={setPlaylistInfo}
        setComment={setComment}
      />
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.section`
  width: 100%;
`;

const Header = styled.header`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
  .comment-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.6rem;
    fill: ${PRIMARY_COLOR};
    transform: translateY(2px);
  }
`;

const CommentSection = styled.div`
  width: calc(100% - 350px);
  padding: 0 1rem 1rem;
  .playlist-info {
    padding: 1.25rem 0;
    border-bottom: 1px solid #d4d4d4;
    .playlist-info-box {
      position: relative;
      min-height: 2.8rem;
      strong {
        color: ${GRAY_7};
        display: block;
        font-weight: 600;
        margin-bottom: 0.1rem;
      }
      span {
        display: --webkit-box;
        overflow: hidden;
        max-height: 32px;
        text-overflow: ellipsis;
        font-size: 1.05rem;
        --webkit-line-break: 2;
        --webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }

  .comment-form {
    position: relative;
    padding: 0;
    height: 460px;
    border-bottom: 1px solid #d4d4d4;
    textarea {
      display: block;
      overflow: auto;
      width: 100%;
      height: 100%;
      padding: 1rem 1rem 0 0;
      border: transparent;
      background: transparent;
      outline: none;
      line-height: 22px;
      color: #000;
      box-sizing: border-box;
      resize: none;
    }
  }
  .comment-info {
    padding: 20px 0;
    font-size: 0.85rem;
    line-height: 18px;
    color: #a0a0a0;
    span {
      display: block;
      margin-bottom: 4px;
      color: #ff4800;
    }
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
  .back-btn {
    margin-right: 0.2rem;
  }
`;
