import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import itiriri from "itiriri";

import { FloatingButton } from "App/Atomics/FloatingButton";
import { Input } from "App/Atomics/Input";
import { useAppStore } from "App/Store";
import { TableTemplate } from "App/Templates/TableTemplate";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { WHITE, BLACK, GRAY_4, GRAY_1 } from "constants/baseColor";
import { PRIMARY_COLOR, DANGER_COLOR, FONT_BASIC, DEFAULT_BORDER_COLOR, SUCCESS_COLOR_LIGHT } from "constants/color";
import { TRACKS, ALBUMS, TRACKS_EDIT } from "constants/route";
import {
  HEADER_HEIGHT_PX,
  MARGING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  MARGING_XX_LARGE_PX,
  MARGING_X_LARGE_PX,
  PADDING_LARGE_PX,
  MARGING_XXX_LARGE_PX,
  BORDER_RADIUS_PX,
  PADDING_SMALL_PX
} from "constants/size";
import { useQueryParams } from "lib/use-query-params";
import { useAlbumDetailStore } from "../Store";
import { DataTemplate } from "./DataTemplate";
import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { ReactComponent as SortSymbol } from "assets/icons/sort.svg";
import { AlbumDetailActions } from "../Store/AlbumDetail";
import { configs } from "configs";
import { Modal } from "lib/modal";
import { StructureActions } from "../Store/Structure";
import { OrderedTextModal } from "../Modals/OrderedTextModal";
import { useToggle } from "lib/use-toggle";
import { TrackActions } from "../Store/TrackList";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as WriteIcon } from "assets/icons/write.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as CircleRemoveIcon } from "assets/icons/circle-remove.svg";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { useAsyncEffect } from "lib/use-async-effect";
import { LoadingAction } from "App/Store/Loading";
import { EditDescModal } from "../Modals/EditDescModal";
import { ValidType } from "constants/ValidType";
import { StructureProps } from "..";
import {
  CreateDescAlbum,
  UpdateDescAlbum,
  CreateAccessRecord,
  DeleteAccessRecord,
  DeleteDescAlbum,
  UpdateAlbum,
  DeleteMetadataCompany,
  DeleteMetadataArtist,
  DeleteFile
} from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { Tag } from "App/Atomics/Tag";
import { AddCompanyModal } from "../Modals/AddCompanyModal";
import { AddArtistModal } from "../Modals/AddArtistModal";
import { ImageViewModal } from "../Modals/ImageViewModal";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { Loading } from "App/Atomics/Loading";
import { UserRole } from "constants/UserRole";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

type AlbumInputProps = {
  title: string;
  companyRelation: string;
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100vh;

  table {
    margin-top: 0px !important;

    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }
  }
`;

const SearchBar = styled(Input.Group)`
  position: relative;
  min-width: ${pixelize(UNIT * 22)};
  margin-left: auto;
  grid-template-columns: auto min-content;

  & > svg {
    position: absolute;
    top: 10px;
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
  }

  input {
    color: ${BLACK};
    padding-left: ${PADDING_XX_LARGE_PX};
    margin-right: ${MARGING_LARGE_PX};
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: 0;
    transition: border-bottom 0.5s;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  margin-top: ${HEADER_HEIGHT_PX};
  flex-direction: column;
`;

const DetailLayout = styled.div`
  display: flex;
  min-width: ${pixelize(UNIT * 95)};
  width: 90%;
  margin: ${MARGING_XX_LARGE_PX} auto;
  padding: ${PADDING_XX_LARGE_PX};
  align-items: center;
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-radius: ${BORDER_RADIUS_PX};
  background-color: ${WHITE};
  figure {
    width: ${pixelize(UNIT * 20)};
    height: ${pixelize(UNIT * 20)};
  }

  button {
    margin-left: auto;
  }
`;

const RowItem = styled.div`
  display: grid;
  grid-template-columns: ${pixelize(UNIT * 7)} ${pixelize(UNIT * 40)} auto;
  font-size: 1.2rem;
  width: ${pixelize(UNIT * 46)};
  align-items: center;
  user-select: text;

  & > span {
    word-break: break-all;
    white-space: normal;
  }

  & > *:first-child {
    font-weight: bold;
    align-self: center;
  }

  & + & {
    margin-top: ${MARGING_X_LARGE_PX};
  }

  .row-value {
    border-bottom: 1px solid ${GRAY_4};
    padding: 5px;
    font-size: 0.8em;
  }

  svg {
    cursor: pointer;
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-left: ${MARGING_LARGE_PX};
  }
  .check {
    fill: ${SUCCESS_COLOR_LIGHT};
  }
`;

const RowLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 48)};
  padding: 0px ${PADDING_XX_LARGE_PX};
`;

const ButtonGroup = styled.div`
  display: flex;
  align-self: flex-end;
  margin-left: auto;
`;

const DescButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 9)};
  padding: ${PADDING_LARGE_PX} ${PADDING_XX_LARGE_PX};
  font-size: 1.2rem;
  text-align: center;
`;

const EditInput = styled(Input.Text)`
  padding: ${PADDING_LARGE_PX};
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditTextArea = styled.textarea`
  height: ${pixelize(UNIT * 10)};
  padding: ${PADDING_LARGE_PX};
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  font-size: 0.8em;
`;

const DescTextArea = styled(Input.Multiline)`
  font-size: 0.8em;
  border: 0.5px solid ${GRAY_4};
  border-radius: ${BORDER_RADIUS_PX};
  padding: ${PADDING_LARGE_PX};
  min-height: ${pixelize(UNIT * 10)};
  max-height: ${pixelize(UNIT * 30)};
  user-select: text;
`;

export const EffectWrap = styled.div`
  .container {
    margin: ${MARGING_XX_LARGE_PX};
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    padding: 0;
  }
  .container figcaption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    text-align: center;
    color: ${WHITE};
    text-transform: uppercase;
    font-size: 20px;
    opacity: 0;
    transition: all 0.3s ease;
  }
  .container figcaption h4 {
    font-size: 1.2em;
    letter-spacing: 1.5px;
    font-weight: 100;
  }
  .container::before,
  .container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    border-top: ${MARGING_XXX_LARGE_PX} solid rgba(0, 0, 0, 0.4);
    border-bottom: ${MARGING_XXX_LARGE_PX} solid rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease;
    z-index: 1;
    opacity: 0;
  }
  .container::before {
    transform: scaley(2);
  }
  .container::after {
    transform: scaley(2);
  }
  .container:hover::before,
  .container:hover::after {
    opacity: 1;
    transform: scale(1);
  }
  .container:hover > img {
    opacity: 0.7;
  }
  .container:hover figcaption {
    opacity: 1;
    transition-delay: 0.1s;
  }
`;

const ButtonField = styled.div`
  margin: ${MARGING_XXX_LARGE_PX} ${MARGING_XX_LARGE_PX} ${MARGING_X_LARGE_PX} auto;
`;

const TextButton = styled.a`
  font-size: 1.1rem;
  font-weight: bold;
  transition: color 0.2s;

  &:hover {
    color: ${FONT_BASIC} !important;
    opacity: 0.8;

    & > svg {
      fill: ${FONT_BASIC} !important;
      opacity: 0.8;
    }
  }

  & + & {
    margin-left: ${MARGING_X_LARGE_PX};
  }
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: ${MARGING_XX_LARGE_PX};
  border: 2px solid ${DEFAULT_BORDER_COLOR};
  font-size: 1.5rem;
  font-weight: bold;
`;

const TagButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  border: 1px solid ${SUCCESS_COLOR_LIGHT};
  border-radius: 4px;
  padding: ${PADDING_SMALL_PX};
  .company,
  .artist {
    display: flex;
    font-size: 0.9rem;
    border-radius: 8px;
    padding: ${PADDING_SMALL_PX};
    background: ${GRAY_1};
    align-items: center;
    svg {
      width: ${pixelize(UNIT * 0.8)};
      height: ${pixelize(UNIT * 0.8)};
      fill: ${DANGER_COLOR};
    }
  }
`;

const DELETE_ACCESS_RECORD = gql`
  mutation DELETE_ACCESS_RECORD($accessId: ID) {
    deleteAccess_record(where: { id: $accessId })
  }
`;

type Title = {
  id: string;
  type: string;
  order: string;
  value: string;
};

const AlbumItemLayout = (props: { albumDetail: any; structures: any[] }) => {
  const [{ count, albumItemList }, dispatch] = useAlbumDetailStore(store => ({
    count: store.AlbumDetail.count,
    albumItemList: store.AlbumDetail.albumItemList
  }));
  const [currentAccessId, setCurrentAccessId] = useState<string | undefined>(undefined);
  const addProductionModal = useToggle();
  const addPublicationModal = useToggle();
  const addArtistModal = useToggle();

  const toEdit = async (key: string) => {
    if (currentAccessId) {
      window.alert("다른 편집을 저장하고 편집해주세요.");
      return;
    }
    try {
      const { data: accessData } = await CreateAccessRecord({
        targetId: props.albumDetail.metadataId,
        targetTable: TargetTableInput.Metadata
      });
      if (accessData) {
        setCurrentAccessId(accessData.createAccess.id);
        dispatch(AlbumDetailActions.setAlbumDetailItemListMode({ key, mode: true }));
      }
    } catch (err) {
      console.log(err);
      window.alert("권한 요청에 실패하였습니다.");
      return;
    }
  };

  const toRemoveCompany = async (type: string, uuid: string) => {
    try {
      await DeleteMetadataCompany({ uuid });
      dispatch(AlbumDetailActions.deleteAlbumCompany({ companyType: type, uuid }));
    } catch (err) {
      console.log(err);
      window.alert("삭제에 실패하였습니다.");
      return;
    }
  };

  const toRemoveArtist = async (uuid: string) => {
    try {
      await DeleteMetadataArtist({ uuid });
      dispatch(AlbumDetailActions.deleteAlbumArtist(uuid));
    } catch (err) {
      console.log(err);
      window.alert("삭제에 실패하였습니다.");
      return;
    }
  };

  const toSave = async (key: string) => {
    try {
      switch (key) {
        case "title":
          await UpdateAlbum({ metadataId: props.albumDetail.metadataId, title: props.albumDetail.title });
          break;
      }
    } catch (err) {
      console.log(err);
      window.alert("데이터 변경에 실패하였습니다.");
    }

    dispatch(AlbumDetailActions.setAlbumDetailItemListMode({ key, mode: false }));
    await DeleteAccessRecord({ id: currentAccessId! });
    setCurrentAccessId(undefined);
    return;
  };

  const RowList = itiriri(albumItemList).map((item, index) => {
    let value;
    switch (item.key) {
      case "title":
        value = props.albumDetail[item.key];
        if (item.isEditMode) {
          return (
            <RowItem key={index}>
              <span>{item.value}</span>
              <EditInput
                id={item.key}
                defaultValue={value}
                onBlur={text => dispatch(AlbumDetailActions.editAlbumInputText({ title: text } as AlbumInputProps))}
              />
              <CheckIcon className="check" onClick={() => toSave(item.key)} />
            </RowItem>
          );
        }
        break;
      case "titleRelation":
        value =
          props.albumDetail[item.key].length && props.albumDetail[item.key].filter((item: Title) => item.type === "description").length
            ? decodeURIComponent(props.albumDetail[item.key].filter((item: Title) => item.type === "description")[0].value)
            : "";
        break;
      case "rightCompany":
        const rightCompany = props.albumDetail[item.key];
        if (!rightCompany.length) {
          value = "-";
        }
        value = rightCompany.map((item: any) => item.company[0].displayName || window.decodeURIComponent(item.company[0].name)).join(", ");
        break;
      case "productions":
        const production = props.albumDetail[item.key];
        if (!production.length) {
          value = "-";
        }
        value = production
          .map((item: any) => item.production[0].displayName || window.decodeURIComponent(item.production[0].name))
          .join(", ");
        if (item.isEditMode) {
          return (
            <>
              <RowItem key={index}>
                <span>{item.value}</span>
                <TagButton>
                  {props.albumDetail.productions.map((item: any, index: number) => {
                    return (
                      <div className="company" key={index}>
                        <span>{window.decodeURIComponent(item.production[0].name)}</span>
                        <CircleRemoveIcon onClick={() => toRemoveCompany("production", item.uuid)} />
                      </div>
                    );
                  })}
                  <Tag onClick={addProductionModal.on}>추가</Tag>
                </TagButton>
                <CheckIcon className="check" onClick={() => toSave(item.key)} />
              </RowItem>
              <Modal isOpen={addProductionModal.isToggled}>
                <AddCompanyModal type="production" metadataId={props.albumDetail.metadataId} toClose={addProductionModal.off} />
              </Modal>
            </>
          );
        }
        break;

      case "publications":
        const publication = props.albumDetail[item.key];

        if (!publication.length) {
          value = "-";
        }

        value = publication
          .map((item: any) => item.publication[0].displayName || window.decodeURIComponent(item.publication[0].name))
          .join(", ");

        if (item.isEditMode) {
          return (
            <>
              <RowItem key={index}>
                <span>{item.value}</span>
                <TagButton>
                  {props.albumDetail.publications.map((item: any, index: number) => {
                    return (
                      <div className="company" key={index}>
                        <span>{window.decodeURIComponent(item.publication[0].name)}</span>
                        <CircleRemoveIcon onClick={() => toRemoveCompany("publication", item.uuid)} />
                      </div>
                    );
                  })}
                  <Tag onClick={addPublicationModal.on}>추가</Tag>
                </TagButton>
                <CheckIcon className="check" onClick={() => toSave(item.key)} />
              </RowItem>
              <Modal isOpen={addPublicationModal.isToggled}>
                <AddCompanyModal type="publication" metadataId={props.albumDetail.metadataId} toClose={addPublicationModal.off} />
              </Modal>
            </>
          );
        }
        break;
      case "artistRelation":
        value = props.albumDetail[item.key].length ? props.albumDetail[item.key].map((item: any) => item.artist[0].name).join(", ") : "-";
        const order = !props.albumDetail.artistRelation.length
          ? 0
          : props.albumDetail.artistRelation[props.albumDetail.artistRelation.length - 1].order + 1;
        if (item.isEditMode) {
          return (
            <>
              <RowItem key={index}>
                <span>{item.value}</span>
                <TagButton>
                  {props.albumDetail.artistRelation.map((item: any, index: number) => {
                    return (
                      <div className="artist" key={index}>
                        <span>{item.artist[0].name}</span>
                        <CircleRemoveIcon onClick={() => toRemoveArtist(item.uuid)} />
                      </div>
                    );
                  })}
                  <Tag onClick={addArtistModal.on}>추가</Tag>
                </TagButton>
                <CheckIcon className="check" onClick={() => toSave(item.key)} />
              </RowItem>
              <Modal isOpen={addArtistModal.isToggled}>
                <AddArtistModal metadataId={props.albumDetail.metadataId} order={order} toClose={addArtistModal.off} />
              </Modal>
            </>
          );
        }
        break;
      case "structures":
        value = count;
        break;
      default:
        value = props.albumDetail[item.key] ? props.albumDetail[item.key] : "-";
        break;
    }

    return (
      <RowItem key={index}>
        <span>{item.value}</span>
        {item.key !== "titleRelation" ? (
          <span className="row-value">{!value ? "-" : value}</span>
        ) : (
          <DescTextArea isDisabled={true} value={value} />
        )}
        {(item.key === "title" || item.key === "artistRelation" || item.key === "productions" || item.key === "publications") && (
          <WriteIcon className="write" onClick={() => toEdit(item.key)} />
        )}
      </RowItem>
    );
  });

  return <RowLayout>{RowList}</RowLayout>;
};

export const RightDetailTable = () => {
  const router = useHistory();
  const [{ loading, userRole }, dispatchApp] = useAppStore(store => ({
    loading: store.Loading.loading,
    userRole: store.UserToken.role
  }));
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const queryParams = useQueryParams();
  const searchStringRef = useRef<HTMLInputElement>(null);
  const searchString = queryParams.get("q", { default: "" });
  const imageViewModal = useToggle();
  const orderedTextModal = useToggle();
  const editDescModal = useToggle();
  const rootId = queryParams.get("id", { default: 1, cast: value => +value });
  const [{ structures, albumDetail, checkList }, albumDetailDispatch] = useAlbumDetailStore(store => ({
    structures: store.Structure.structures,
    albumDetail: !store.AlbumDetail.albumDetail.length ? null : store.AlbumDetail.albumDetail[0].album[0],
    checkList: store.AlbumDetail.checkList
  }));

  const structuresRef = useRef(structures);
  structuresRef.current = structures;
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  const searchAlbumsDetail = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const searchStr = searchStringRef.current!.value.trim();
    if (searchStr !== searchString) {
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      let encodeString = encodeURIComponent(searchStr);
      router.push(`?id=${rootId}${!searchStr ? "" : `&q=${encodeString}`}`);
    }
  };

  const toCloseTextOrdered: Parameters<typeof OrderedTextModal>[0]["toClose"] = () => {
    albumDetailDispatch(TrackActions.clear());
    orderedTextModal.off();
  };

  const openOrderedTextModal = () => orderedTextModal.on();

  const handleViewButton = () => {
    const idList = Array.from(checkList)
      .filter(list => list[1])
      .map(list => list[1] && `${list[0]}`);
    router.push(TRACKS + `?page=1&ids=[${idList}]`);
  };

  const handleEditButton = () => {
    if (Array.from(checkList).filter(list => list[1]).length >= 50) {
      Toast.warning("최대 50개까지 편집할 수 있습니다.");
    }
    const idList = Array.from(checkList)
      .filter(list => list[1])
      .slice(0, 50)
      .map(list => list[1] && `${list[0]}`);
    router.push(TRACKS_EDIT + `?page=1&ids=[${idList}]`);
  };

  const handleDeleteButton = async () => {
    if (userRole !== UserRole.Master) {
      Toast.warning("트랙을 삭제할 권한이 없습니다.");
      return;
    }
    const idList = Array.from(checkList)
      .filter(list => list[1])
      .map(list => list[0]);
    if (structures.length === idList.length) {
      Toast.warning("한 앨범에는 최소 1곡의 트랙이 존재해야합니다.");
      return;
    } else if (window.confirm("선택한 곡을 삭제하시겠습니까?")) {
      setDeleteLoading(true);
      for (const id of idList) {
        const metadata = structures.find(({ metadata }) => metadata[0].metadataId === id)?.metadata[0];
        const companyId = metadata.companyRelation[0].company[0].id;
        const urls = metadata.metadataUrl;
        const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
        if (accessId) {
          try {
            if (urls.length) {
              for (const url of urls) {
                const { errors: fileErr } = await DeleteFile({
                  companyId,
                  filename: url.url,
                  fileType: FileType.FILE,
                  book: BookType.immediate
                });
                if (fileErr) {
                  if (fileErr[0].message.includes("not exist file")) {
                    Toast.warning("URL 파일이 없습니다. 삭제는 그대로 진행됩니다.");
                  } else {
                    throw fileErr[0].message;
                  }
                }
              }
            }
            await DeleteMetadata({ id });
            albumDetailDispatch(StructureActions.deleteAlbumTrack(id));
          } catch (err) {
            console.log(err);
            Toast.error("곡 삭제에 실패하였습니다.");
          } finally {
            await DeleteAccessRecord({ id: accessId });
          }
        }
      }
      albumDetailDispatch(AlbumDetailActions.setAlbumCheckListInit(idList));
      setDeleteLoading(false);
      Toast.primary("선택한 곡이 삭제되었습니다.");
    }
  };

  const createAlbumText = async (title: string) => {
    const query = gql`
      mutation CREATE_ALBUM_TEXT($title: String!) {
        createMetadata(
          data: {
            title: $title
            type_metadata_class: "record"
            type_metadata_subclass: "text"
            metadata_structure: {
              create: [{
                parent_id: ${albumDetail.metadataStructure[0].structureId}
              }]
            }
          }
        ) {
          metadata_id
        }
      }
    `;
    await clients.metadata.mutation(query, { title: title });
  };

  const onCreateText = () => {
    const title = window.prompt("제목을 입력해주세요.");
    if (title) {
      createAlbumText(title).then(() => albumDetailDispatch(StructureActions.toggleMountedFlag()));
    }
  };

  const createDescTitle: Parameters<typeof EditDescModal>[0]["onCreate"] = async (type, value, languageCode, order) => {
    try {
      const metadataId = albumDetail.metadataId;
      const { data } = await CreateAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      const response = await CreateDescAlbum({ metadataId: metadataId, type, value, languageCode, order });
      if (response.data) {
        const id = response.data.createDescAlbum.metadata_title[0].id;
        albumDetailDispatch(AlbumDetailActions.setAlbumTitleDesc({ id, typeTitle: type, value, languageCode, order }));
        if (data) {
          const accessId = data.createAccess.id;
          await DeleteAccessRecord({ id: accessId });
        }
      }
    } catch (e) {
      console.log(e);
      alert("서버에서 에러가 발생했습니다.");
      return;
    }
  };

  const updateDescTitle: Parameters<typeof EditDescModal>[0]["onUpdate"] = async (id, type, value, languageCode) => {
    try {
      const metadataId = albumDetail.metadataId;
      const { data } = await CreateAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      const response = await UpdateDescAlbum({ id, type, value, languageCode });
      if (response.data) {
        albumDetailDispatch(AlbumDetailActions.updateAlbumTitleDesc({ id, typeTitle: type, value, languageCode }));
        if (data) {
          const accessId = data.createAccess.id;
          await clients.access.mutation(DELETE_ACCESS_RECORD, { accessId });
        }
      }
    } catch (e) {
      console.log(e);
      window.alert("서버에서 에러가 발생했습니다.");
      return;
    }
  };

  const deleteDescTitle: Parameters<typeof EditDescModal>[0]["onDelete"] = async (id: string) => {
    try {
      const metadataId = albumDetail.metadataId;
      const { data } = await CreateAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
      const response = await DeleteDescAlbum({ id });
      if (response.data) {
        albumDetailDispatch(AlbumDetailActions.deleteAlbumTitleDesc(id));
        if (data) {
          const accessId = data.createAccess.id;
          await DeleteAccessRecord({ id: accessId });
        }
      }
    } catch (e) {
      console.log(e);
      alert("서버에서 에러가 발생했습니다.");
      return;
    }
  };

  const onToggleAllCheck = (checked: boolean) => {
    structures.forEach(({ metadata, child }: StructureProps) => {
      if (
        metadata.length &&
        (metadata[0].typeSubClass === "track" || metadata[0].typeSubClass === "effect") &&
        metadata[0].validCheck !== ValidType.REMOVE
      ) {
        albumDetailDispatch(AlbumDetailActions.setAlbumCheckList({ id: metadata[0].metadataId, checked }));
      }
      if (child.length) {
        child.forEach(({ metadata }) => {
          if (
            metadata.length &&
            (metadata[0].typeSubClass === "track" || metadata[0].typeSubClass === "effect") &&
            metadata[0].validCheck !== ValidType.REMOVE
          ) {
            albumDetailDispatch(AlbumDetailActions.setAlbumCheckList({ id: metadata[0].metadataId, checked }));
          }
        });
      }
    });
  };
  const HEAD_LIST = [{ name: "앨범 상세 목록", colSpan: 5 }];
  const ALBUM_DETAIL_SUB_HEAD = [
    <AnimatedCheckbox
      id="allCheck"
      isChecked={checkList.size === 0 ? false : Array.from(checkList).every(item => item[1])}
      onToggle={() => onToggleAllCheck(checkList.size === 0 ? true : Array.from(checkList).some(item => !item[1]))}
    />,
    "아이디",
    "No",
    "제목",
    "가수"
  ];

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        if (!structuresRef.current.length) {
          dispatchApp(LoadingAction.setLoading(LOADING.NULL));
        }
      }
    },
    [structures, albumDetail]
  );
  return (
    <>
      <Layout>
        <TopbarTemplate openSidebar={openSidebar}>
          <Input.Button onClick={() => router.push(`${ALBUMS}?page=1`)}>앨범 전체 보기</Input.Button>
          <Input.Button onClick={() => router.push(`${TRACKS}?page=1`)}>트랙 전체 보기</Input.Button>
          <form onSubmit={searchAlbumsDetail}>
            <SearchBar>
              <SearchIcon />
              <Input.Text ref={searchStringRef} placeholder="검색 할 트랙 제목을 입력하세요."></Input.Text>
              <Input.Button type="submit" color="default">
                검색
              </Input.Button>
            </SearchBar>
          </form>
        </TopbarTemplate>

        <BottomContainer>
          {albumDetail ? (
            <DetailLayout>
              <EffectWrap onClick={imageViewModal.on}>
                <figure className="container">
                  {!albumDetail.metadataUrl.length ? (
                    <NoImage>NO IMAGE</NoImage>
                  ) : (
                    <img src={`${configs.urls.image}/${albumDetail.metadataUrl[0].url}?mode=m`} alt="Album Cover" />
                  )}
                  <figcaption>
                    <h4>VIEW</h4>
                  </figcaption>
                </figure>
              </EffectWrap>

              <AlbumItemLayout albumDetail={albumDetail} structures={structures} />
              <ButtonGroup>
                <DescButton color="primary" onClick={editDescModal.on}>
                  설명 편집
                </DescButton>
              </ButtonGroup>
            </DetailLayout>
          ) : null}
          <ButtonField>
            <TextButton role="button" style={{ color: DANGER_COLOR }} onClick={onCreateText}>
              + 트랙 그룹 추가
            </TextButton>
            <TextButton role="button" style={{ color: PRIMARY_COLOR }} onClick={openOrderedTextModal}>
              <SortSymbol style={{ fill: PRIMARY_COLOR, width: UNIT, height: UNIT }} /> 상세목록 정렬
            </TextButton>
          </ButtonField>
          <TableTemplate
            headList={HEAD_LIST}
            subHeadList={ALBUM_DETAIL_SUB_HEAD}
            keyBy={data => data.metadata[0]?.metadataId ?? []}
            dataList={loading === LOADING.UNLOAD ? [] : structures}
            Column={DataTemplate}
          />
          <LoadingTemplate loading={loading} searchString={searchString} />
        </BottomContainer>
        {checkList.size !== 0 && Array.from(checkList).some(item => item[1]) ? (
          <>
            <FloatingButton style={{ bottom: `${pixelize(UNIT * 11)}` }} color="primary" onClick={handleViewButton}>
              VIEW
            </FloatingButton>
            <FloatingButton style={{ bottom: `${pixelize(UNIT * 6.5)}` }} color="success" onClick={handleEditButton}>
              EDIT
            </FloatingButton>
            <FloatingButton color="danger" onClick={handleDeleteButton}>
              DELETE
            </FloatingButton>
          </>
        ) : null}
      </Layout>
      <Modal isOpen={orderedTextModal.isToggled}>
        <OrderedTextModal toClose={toCloseTextOrdered} />
      </Modal>
      <Modal isOpen={editDescModal.isToggled}>
        <EditDescModal
          titleRelation={albumDetail && albumDetail.titleRelation.length ? albumDetail.titleRelation : []}
          onCreate={createDescTitle}
          onUpdate={updateDescTitle}
          onDelete={deleteDescTitle}
          toClose={() => editDescModal.off()}
        />
      </Modal>
      <Modal isOpen={imageViewModal.isToggled}>
        <ImageViewModal metadataUrl={!albumDetail ? [] : albumDetail.metadataUrl} toClose={imageViewModal.off} />
      </Modal>
      <Loading loading={deleteLoading} />
    </>
  );
};
