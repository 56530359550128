/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  roleId: string;
  typeClass: string;
  type: string;
  languageCode: string;
  value: string;
  order: number;
}>;

export const CreateRoleTitle = async (option: Option) => {
  const $roleId = <Variable name="roleId" scalar={<IdScalar />} value={option.roleId} />;
  const $typeClass = <Variable name="type_role_class" scalar={<StringScalar />} value={option.typeClass} />;
  const $type = <Variable name="type_role_title" scalar={<StringScalar />} value={option.type} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ROLE_TITLE">
      <SelectionSet
        name="updateRole"
        alias="createRoleTitle"
        argument={{
          where: {
            role_id: $roleId
          },
          data: {
            type_role_class: $typeClass,
            role_title: {
              create: [
                {
                  type_role_title: $type,
                  language: { connect: { language_code: $languageCode } },
                  value: $value,
                  exposure_order: $order
                }
              ]
            }
          }
        }}
      >
        <Field name="role_id" />
        <SelectionSet name="role_title">
          <Field name="id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createRoleTitle: {
      role_id: string;
      role_title: {
        id: string;
      }[];
    };
  }>;

  return await clients.role.request<Data>(query, variables);
};
