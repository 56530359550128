import { Input } from "App/Atomics/Input";
import { GRAY_6 } from "constants/baseColor";
import React, { useState } from "react";
import styled from "styled-components";

type Props = {
  toSave: (text: string | undefined) => void;
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 760px;
  height: 60vh;
  padding-bottom: 1rem;
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  h3 {
    margin: 2rem 2rem 1rem;
  }
  span {
    margin-left: 2rem;
    color: ${GRAY_6};
  }
`;

const TextArea = styled.textarea`
  border: 1px solid lightgray;
  height: 75%;
  margin: 1rem 2rem;
  padding: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 2rem;

  button {
    width: 8rem;
  }
`;

export const EditAllTextModal = ({ toSave, toClose }: Props) => {
  const [text, setText] = useState<string | undefined>(undefined);

  const onSaveAndClose = () => {
    toSave(text);
    toClose();
  };

  return (
    <Layout>
      <h3>라이센스 설명 일괄 적용</h3>
      <span>필터링된 모든 라이센스에 적용됩니다.</span>
      <TextArea placeholder="라이센스 설명을 적어주세요." onChange={e => setText(e.target.value)} />
      <ButtonGroup>
        <Input.Button className="btn" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button className="btn" color="primary" onClick={onSaveAndClose}>
          확인
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
