import React from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_X_LARGE, MARGING_LARGE_PX } from "constants/size";
import { GRAY_2, GRAY_4 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as NoDataIcon } from "assets/icons/no-data.svg";
import { Input } from "App/Atomics/Input";
import { IdActions, IdType } from "../../Store/IdList";
import { Tag } from "App/Atomics/Tag";
import { useAuthGrantDispatch } from "../../Store";

type Props = {
  idType: string;
  idList: IdType[];
  setSearchIds: React.Dispatch<React.SetStateAction<IdType[]>>;
  toClose: () => void;
};

export const SearchResultModal = ({ idType, idList, setSearchIds, toClose }: Props) => {
  const dispatch = useAuthGrantDispatch();
  const searchIds = !idList.length ? [] : Array.from(idList);
  const onRemoveId = (id: string) => {
    setSearchIds(prev => prev.filter(item => item.id !== id));
  };
  const onAddIdList = () => {
    dispatch(IdActions.set({ idType, idList: searchIds }));
    toClose();
  };
  return (
    <Layout>
      <Header>
        <h2>{`검색된 아이디 목록 - ${searchIds?.length ?? 0}개`}</h2>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        {!searchIds || !searchIds.length ? (
          <NoData>
            <NoDataIcon />
            <span>검색된 아이디가 없습니다.</span>
          </NoData>
        ) : (
          <TrackContainer>
            {searchIds.map(({ id }) => {
              return (
                <ListItem key={id}>
                  <Tag className="info-area" onClick={() => onRemoveId(id)}>
                    <span className="title">{id}</span>
                  </Tag>
                </ListItem>
              );
            })}
          </TrackContainer>
        )}
      </Section>
      <ButtonContainer>
        <Input.Button color="success" disabled={!searchIds || !searchIds.length} isWide onClick={onAddIdList}>
          목록에 추가
        </Input.Button>
      </ButtonContainer>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 40)};
  height: 645px;
`;

const Header = styled.div`
  width: 100%;
  height: 4.2rem;
  display: flex;
  background-color: #000;
  color: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  padding: ${pixelize(PADDING_X_LARGE * 1.4)};
  justify-content: space-between;
  align-items: center;
  h2 {
    font-weight: lighter;
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
  }
  .cancelIcon {
    fill: #fff;
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    transition: all 0.1s;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_4};
    }
  }
`;

const Section = styled.section`
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  .list-wrap {
    position: absolute;
    top: 4rem;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .no-track {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
`;

const TrackContainer = styled.ul`
  width: 100%;
  height: 525px;
  padding: 0.5rem 1rem 1rem;
  overflow-x: hidden;
  overflow-y: scroll;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10ch, 1fr));
  grid-gap: 4px;
`;

const ListItem = styled.li`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  user-select: none;
  transition: background-color 0.15s;

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`;

const NoData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 525px;
  svg {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }
  span {
    font-size: 0.9rem;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }
`;
