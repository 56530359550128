import { createDuck } from "lib/store/v2";
import { v4 as uuidv4 } from "uuid";

type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  character_id?: string;
  character_name?: string;
  order?: number;
};

type Company = {
  company_id: string;
  name: string;
};

type Production = Company;
type Publication = Company;

type Url = {
  id: string;
  typeUrl?: string;
  url?: string;
};

type ImageUrl = {
  id: string;
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

type Subdata = {
  id: string;
  typeSubdataCategory?: string;
  typeSubdataField?: string;
  value?: string;
};

export type Work = {
  title: string;
  titleEn: string;
  description: string;
  descriptionEn: string;
  class: string;
  subClass: string;
  artist: Artist[];
  rightsCompany: Company;
  productions?: Production[];
  publications?: Publication[];
  lyrics?: string;
  urls: Url[];
  images: Map<string, ImageUrl>;
  subdatas: Subdata[];
  parentId?: number;
  ytvCode?: string;
};

const createInitialState = () => ({
  work: {
    class: "work",
    subClass: "album",
    images: new Map(),
    subdatas: [{ id: "publish_date", typeSubdataCategory: "etc", typeSubdataField: "publish_date", value: "" }]
  } as Work
});

export const WorkCreateActions = createDuck({
  namespace: "WorkCreate",
  createInitialState,
  reducers: {
    setWork(state, work: Work) {
      state.work = work;
    },
    setWorkTitle(state, title: string) {
      state.work.title = title;
    },
    setWorkTitleEn(state, titleEn: string) {
      state.work.titleEn = titleEn;
    },
    setWorkDescription(state, description: string) {
      state.work.description = description;
    },
    setWorkDescriptionEn(state, descEn: string) {
      state.work.descriptionEn = descEn;
    },
    setWorkSubClass(state, subClass: string) {
      state.work.subClass = subClass;
    },
    setWorkArtist(state, artistInfo: Artist[]) {
      state.work.artist = artistInfo;
    },
    setWorkRightsCompany(state, rightsCompany: Company) {
      state.work.rightsCompany = rightsCompany;
    },
    setWorkProductions(state, productions: Production[]) {
      state.work.productions = productions;
    },
    setWorkPublications(state, publications: Publication[]) {
      state.work.publications = publications;
    },
    setWorkLyrics(state, lyrics: string) {
      state.work.lyrics = lyrics;
    },
    createWorkUrl(state) {
      if (!state.work.urls) {
        state.work.urls = [] as Url[];
      }
      const uuid = uuidv4();
      state.work.urls.push({ id: uuid, typeUrl: undefined, url: undefined });
    },
    updateWorkUrl(state, { id, typeUrl, url }: { id: string; typeUrl?: string; url?: string }) {
      const index = state.work.urls.findIndex(item => item.id === id);
      if (typeUrl) {
        state.work.urls[index].typeUrl = typeUrl;
      } else {
        state.work.urls[index].url = url;
      }
    },
    deleteWorkUrl(state, id: string) {
      const index = state.work.urls.findIndex(item => item.id === id);
      state.work.urls.splice(index, 1);
    },
    createWorkSubdata(state) {
      if (!state.work.subdatas) {
        state.work.subdatas = [] as Subdata[];
      }
      const uuid = uuidv4();
      state.work.subdatas.push({ id: uuid, typeSubdataCategory: undefined, typeSubdataField: undefined, value: undefined });
    },
    updateWorkSubdata(state, { id, category, field, value }: { id: string; category?: string; field?: string; value?: string }) {
      const index = state.work.subdatas.findIndex(item => item.id === id);
      if (category) {
        state.work.subdatas[index].typeSubdataCategory = category;
      } else if (field) {
        state.work.subdatas[index].typeSubdataField = field;
      } else {
        state.work.subdatas[index].value = value;
      }
    },
    deleteWorkSubdata(state, id: string) {
      const index = state.work.subdatas.findIndex(item => item.id === id);
      state.work.subdatas.splice(index, 1);
    },
    setWorkParentId(state, id: number) {
      state.work.parentId = id;
    },
    setWorkYtvCode(state, ytvCode: string) {
      state.work.ytvCode = ytvCode;
    },
    addCoverUrl(state, url: ImageUrl) {
      state.work.images.set(url.id, url);
    },
    removeCoverUrl(state, id: string) {
      state.work.images.delete(id);
    },
    clearWorkCreate(state) {
      state.work = {
        class: "work",
        subClass: "album"
      } as Work;
    }
  }
});
