import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { AC_WHITE, PRIMARY_COLOR } from "constants/color";
import { AlbumTagAutoComplete, AlbumTextAutoComplete } from "App/Molecules/AutoCompletes/Album";
import { GRAY_2, GRAY_6, RED_7 } from "constants/baseColor";
import { useLoadTracksInAlbum } from "./useLoadTracksInAlbum";
import { useUpdateTrackParent } from "./useUpdateTrackParent";
import { Toast } from "lib/toast";
import { useDeleteAlbum } from "./useDeleteAlbum";
import { Loading } from "App/Atomics/Loading";
import { useAlbumDispatch } from "../../Store";
import { AlbumActions } from "../../Store/Album";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { CreateEmptyAlbumModal } from "../CreateEmptyAlbumModal";

type Props = {
  readonly onClose: () => void;
};

type AlbumInfo = {
  readonly id: string;
  readonly name: string;
  readonly structureId: string;
};

export const MergeAlbumModal = ({ onClose }: Props) => {
  const [prevAlbumList, setPrevAlbum] = useState<AlbumInfo[] | null>(null);
  const [nextAlbum, setNextAlbum] = useState<AlbumInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAlbumDispatch();
  const createEmptyAlbumModal = useToggle();
  const { loadTracks } = useLoadTracksInAlbum();
  const { updateTrackParent } = useUpdateTrackParent();
  const { deleteAlbum } = useDeleteAlbum();

  const onClick = async () => {
    if (!prevAlbumList || !nextAlbum) {
      Toast.error("앨범을 선택해주세요.");
      return;
    }
    try {
      setLoading(true);
      for (const prevAlbum of prevAlbumList) {
        const structureIds = await loadTracks(prevAlbum.id);
        const rootId = +nextAlbum.structureId;
        await updateTrackParent(structureIds, rootId);
        await deleteAlbum(prevAlbum.id);
        dispatch(AlbumActions.deleteAlbumById(prevAlbum.id));
      }
      Toast.primary("통합되었습니다.");
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Header>통합할 앨범을 선택해주세요.</Header>
      <Container>
        <RowGroup>
          <Label>통합 이전 앨범</Label>
          <AlbumTagAutoComplete
            onBlur={info => {
              const list = info.map(({ id, name, extra }) => ({ id, name, structureId: extra!.structureId }));
              setPrevAlbum(list);
            }}
          />
        </RowGroup>
        <RowGroup>
          <Label>통합 이후 앨범</Label>
          <AlbumTextAutoComplete
            onChange={info =>
              info &&
              setNextAlbum({
                id: info.id,
                name: info.name,
                structureId: info.extra.structureId
              })
            }
          />
        </RowGroup>
        <Tooltip>· 이전 앨범의 수록곡이 이후 앨범으로 통합됩니다.</Tooltip>
        <Tooltip>· 이전 앨범은 자동으로 삭제됩니다.</Tooltip>
        <Tooltip>· 아이디 검색 시, 트랙 아이디를 입력할 수도 있습니다.</Tooltip>
        <Tooltip>
          · 대상 앨범이 없다면 <ClickText onClick={createEmptyAlbumModal.on}>빈 앨범 생성</ClickText>을 클릭해주세요
        </Tooltip>
      </Container>
      <ButtonGroup>
        <Input.Button onClick={onClose}>취소</Input.Button>
        <Input.Button color="primary" onClick={onClick}>
          확인
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
      <Modal isOpen={createEmptyAlbumModal.isToggled}>
        <CreateEmptyAlbumModal onClose={createEmptyAlbumModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  width: 550px;
  height: 500px;
  overflow: hidden;
  border-radius: 2px;
`;

const Header = styled.div`
  width: 100%;
  padding: 16px;
  color: ${AC_WHITE};
  background-color: ${PRIMARY_COLOR};
`;
const Container = styled.div`
  padding: 16px;
  height: 395px;
  overflow-y: scroll;
`;
const RowGroup = styled.div`
  & + & {
    margin-top: 16px;
  }
`;
const Label = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;
const Tooltip = styled.div`
  font-size: 12px;
  color: ${GRAY_6};
  margin-top: 4px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${GRAY_2};
  padding: 8px 16px;
`;

const ClickText = styled.span`
  color: ${RED_7};
  cursor: pointer;
  text-decoration: underline;
`;
