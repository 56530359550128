import React, { useState } from "react";
import styled from "styled-components";
import DatePickerLib, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import { PRIMARY_COLOR_LIGHT } from "constants/color";
registerLocale("ko", ko);

export type DatePickerProps = Styleable &
  Readonly<{
    id?: string;
    isRange?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    isReadonly?: boolean;
    placeholder?: string;
    defaultValue?: Date;
    format?: string;
    dateInfoText?: string;
    onChange?: (date: Date | [Date, Date]) => void;
    maxDate?: Date | null;
    showTimeSelect?: boolean;
  }>;

/**
 * `DatePicker`
 *
 * - `react-datepicker`의 커스텀 컴포넌트입니다.
 * - `isRange`로 범위로 날짜를 선택할 수 있습니다. (isRange: true)
 * - `isReadonly`로 읽기 전용으로 변경할 수 있습니다.
 * - `isReadonly`로 비활성화 시킬 수 있습니다.
 * - `placeholder`를 변경할 수 있습니다.
 * - `defaultValue`로 초기값을 설정할 수 있습니다.
 * - `maxDate`로 최대 날짜를 설정할 수 있습니다. 기본값은 현재 날짜입니다.
 */
export const DatePicker = ({
  id,
  style,
  className,
  isRange,
  isClearable,
  defaultValue,
  placeholder = "날짜를 선택하세요",
  isDisabled,
  isReadonly,
  format = "yyyy년 MM월 dd일",
  dateInfoText,
  onChange = () => {},
  maxDate = new Date(),
  showTimeSelect
}: DatePickerProps) => {
  const [startDate, setStartDate] = useState<Date | null>(!defaultValue ? null : defaultValue);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onDateChange = (date: [Date, Date] | Date) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(date);
    }
    onChange(date);
  };
  return (
    <Layout style={style} className={className} isRange={isRange}>
      {!dateInfoText ? null : <span>{dateInfoText}</span>}
      <Picker
        id={id}
        locale="ko"
        selected={startDate}
        isClearable={isClearable}
        onChange={onDateChange}
        disabled={isDisabled}
        readOnly={isReadonly}
        dateFormat={format}
        showTimeSelect={showTimeSelect}
        placeholderText={placeholder}
        startDate={startDate}
        endDate={endDate}
        selectsRange={isRange}
        inline={isRange}
        maxDate={maxDate}
      />
    </Layout>
  );
};

type PickerProps = {
  readOnly?: boolean;
  disabled?: boolean;
};

const Layout = styled.div<{ isRange?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  span {
    width: 90%;
    text-align: left;
    color: ${PRIMARY_COLOR_LIGHT};
    font-size: 0.7rem;
  }
`;

const Picker = styled(DatePickerLib)<PickerProps>`
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 0.6rem 0.5rem 0.3rem;
  transition: all 0.2s;
  font-size: 15px;
  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }
  &:focus {
    outline: 0;
    border-color: ${PRIMARY_COLOR_LIGHT};
  }
  .react-datepicker__input-container {
    border: 1px solid blue;
    input {
      background-color: #fff;
      padding: 2rem;
      color: red;
    }
  }
  .react-datepicker {
    border: 1px soid #ddd;
    box-shadow: 0 2px 2px #ddd;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background: #eef !important;
    }
  }
`;
