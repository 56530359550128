/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, ChangeEvent } from "react";
import styled from "styled-components";
import {
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_X_LARGE_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  BORDER_RADIUS_PX,
  MARGING_X_LARGE_PX
} from "constants/size";
import { GRAY_4, WHITE, GRAY_6 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, SECONDARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { useSingleAlbumStore } from "../../Store";
import { InputTextRef } from "App/Atomics/Input/Text";
import { GenreTagAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { MoodTagAutoComplete } from "App/Molecules/AutoCompletes/Mood";
import { serialValidate } from "../../Query/serialValidate";
import uuid from "uuid";
import { SingleAlbumActions, SingleAlbum } from "../../Store/SingleAlbum";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { FileUploadModal } from "../../Modals/FileUploadModal";
import { useAsyncEffect } from "lib/use-async-effect";
import { findCompanyList } from "App/Molecules/AutoCompletes/Company/findCompanyList";

type Genre = {
  id: string;
  name: string;
  typeKind: string;
};

const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  overflow: auto;
  width: ${pixelize(UNIT * 57.18)};
  height: ${pixelize(UNIT * 51)};
  background: ${WHITE};
  padding: ${PADDING_XX_LARGE_PX};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};

  h2 {
    text-align: left;
    padding: 0px ${PADDING_X_LARGE_PX} ${PADDING_LARGE_PX} 0;
  }

  h4 {
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }
`;

const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_X_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${PADDING_LARGE_PX};

  .input-box {
    display: grid;
    grid-template-columns: 9fr 1fr;
    width: 100%;
  }
  input[type="text"],
  input[type="file"],
  input[type="number"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
  input[type="file"] {
    font-size: 0.9rem;
  }
  .genre {
    margin-right: ${MARGING_LARGE_PX};
  }
  .genre,
  .mood {
    margin-top: ${MARGING_SMALL_PX};
    min-width: ${pixelize(12 * UNIT)};
    width: 100%;
  }
`;

const FormButton = styled(Input.Button)`
  transform: scale(0.85) translateX(5px);
`;
const GenreMood = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  h4 {
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 10px;
  align-items: center;
  img {
    width: ${pixelize(UNIT * 6.25)};
    height: ${pixelize(UNIT * 6.25)};
  }
`;

const MultiDesc = styled(Input.Multiline)`
  margin-top: ${MARGING_LARGE_PX};
  min-height: ${pixelize(UNIT * 8)};
  border: 1px solid ${GRAY_4};
  border-radius: ${BORDER_RADIUS_PX};
  font-size: 0.9rem;
  padding: ${PADDING_LARGE_PX};
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 6.25)};
  height: ${pixelize(UNIT * 6.25)};
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

export const SingleAlbumForm = () => {
  const [{ singleAlbum, trackList }, dispatch] = useSingleAlbumStore(store => ({
    singleAlbum: store.SingleAlbum.singleAlbum,
    trackList: store.SingleAlbum.trackList
  }));
  const serialRef = useRef<InputTextRef>(null);
  const uploadModal = useToggle();

  const singleAlbumSerialValidate = async (value: string) => {
    const { data } = await serialValidate({ no: value });
    if (data && !data.metadata.length) {
      dispatch(SingleAlbumActions.setSingleAlbumNo(value));
    } else {
      alert("앨범 시리얼 넘버가 중복됩니다. 다시 입력하세요.");
      serialRef.current?.focus();
      serialRef.current!.value = "";
    }
  };

  const singleAlbumAutoSerialValidate = async () => {
    while (true) {
      const singleAlbumNo = uuid();
      const { data } = await serialValidate({ no: singleAlbumNo });
      if (data && !data.metadata.length) {
        dispatch(SingleAlbumActions.setSingleAlbumNo(singleAlbumNo));
        break;
      }
    }
  };

  const setSingleAlbumCover = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        SingleAlbumActions.setSingleAlbumUrl({
          file: file.files![0],
          value: event.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          name: file.files![0].name,
          valueType: file.files![0].type
        })
      );
    };
  };

  const onCreateAlbum = () => {
    if (singleAlbum.albumUrl?.file) {
      dispatch(
        SingleAlbumActions.setSingleAlbumUrl({
          file: singleAlbum.albumUrl.file,
          value: singleAlbum.albumUrl.data,
          ext: singleAlbum.albumUrl.ext,
          name: `${singleAlbum.no}/cover/album_cover.${singleAlbum.albumUrl.ext}`,
          valueType: singleAlbum.albumUrl.typeUrl
        })
      );
    }
    dispatch(SingleAlbumActions.setSingleAlbumClass());
    trackList.trackUrl?.trackMp3 &&
      dispatch(
        SingleAlbumActions.setSingleTrackUrl({
          fileType: trackList.trackUrl.trackMp3.typeUrl,
          url: `${singleAlbum.no}/${trackList.trackUrl.trackMp3.typeUrl}/${trackList.no}.${trackList.trackUrl.trackMp3.ext}`
        })
      );

    trackList.trackUrl?.trackAac &&
      dispatch(
        SingleAlbumActions.setSingleTrackUrl({
          fileType: trackList.trackUrl.trackAac.typeUrl,
          url: `${singleAlbum.no}/${trackList.trackUrl.trackAac.typeUrl}/${trackList.no}.${trackList.trackUrl.trackAac.ext}`
        })
      );

    trackList.trackUrl?.trackFlac &&
      dispatch(
        SingleAlbumActions.setSingleTrackUrl({
          fileType: trackList.trackUrl.trackFlac.typeUrl,
          url: `${singleAlbum.no}/${trackList.trackUrl.trackFlac.typeUrl}/${trackList.no}.${trackList.trackUrl.trackFlac.ext}`
        })
      );
    uploadModal.on();
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        if (!singleAlbum.no) {
          try {
            await singleAlbumAutoSerialValidate();
            // TODO: Client는 company를 자동으로 등록. Master는 따로 생성해주어야 하므로 우선 주석
            // const res = await findCompanyList("ARTISTSCARD-ORIGINAL");
            // dispatch(SingleAlbumActions.setSingleAlbumCompany(res[0].id, res[0].name, res[0].license));
          } catch (e) {
            console.log(e);
            return;
          }
        }
      }
    },
    [singleAlbum.no]
  );

  return (
    <Layout>
      <h2>싱글 앨범정보 입력</h2>
      <CustomDivider />
      <RowGroup>
        <h4>시리얼 넘버</h4>
        <div className="input-box">
          <Input.Text
            isRequired
            key={singleAlbum.no}
            ref={serialRef}
            placeholder="시리얼 넘버를 입력하세요."
            defaultValue={!singleAlbum.no ? "" : singleAlbum.no}
            onBlur={val => val && singleAlbumSerialValidate(val)}
          />
          <FormButton color="secondary" onClick={singleAlbumAutoSerialValidate}>
            자동생성
          </FormButton>
        </div>
      </RowGroup>
      <RowGroup>
        <h4>앨범 타이틀</h4>
        <Input.Text
          isRequired
          placeholder="제목를 입력하세요."
          defaultValue={!singleAlbum.title ? "" : singleAlbum.title}
          onChange={val => dispatch([SingleAlbumActions.setSingleAlbumDescription(val), SingleAlbumActions.setSingleTrackTitle(val)])}
        />
      </RowGroup>
      <RowGroup>
        <h4>앨범 커버</h4>
        <ImageContainer>
          {!singleAlbum.albumUrl ? <NoImage>NO IMAGE</NoImage> : <img src={URL.createObjectURL(singleAlbum.albumUrl.file)} alt="cover" />}
          <input type="file" accept="image/jpeg,image/png" onChange={setSingleAlbumCover} />
        </ImageContainer>
      </RowGroup>
      <RowGroup>
        <GenreMood>
          <div className="genre">
            <h4>장르</h4>
            <GenreTagAutoComplete
              onBlur={info => {
                if (info && info.length) {
                  dispatch(SingleAlbumActions.setSingleTrackGenre(info as Genre[]));
                }
              }}
            />
          </div>
          <div className="mood">
            <h4>무드</h4>
            <MoodTagAutoComplete
              onBlur={info => {
                if (info && info.length) {
                  dispatch(SingleAlbumActions.setSingleTrackMood(info as Genre[]));
                }
              }}
            />
          </div>
        </GenreMood>
      </RowGroup>
      <RowGroup>
        <h4>설명</h4>
        <MultiDesc
          value={!trackList.trackTitle ? "" : decodeURIComponent(trackList.trackTitle.value)}
          onChange={val => dispatch(SingleAlbumActions.setSingleTrackDescription(val))}
        />
      </RowGroup>
      <RowGroup>
        <ButtonContainer>
          <Input.Button disabled={!singleAlbum.title} color="danger" onClick={onCreateAlbum}>
            앨범 생성
          </Input.Button>
        </ButtonContainer>
      </RowGroup>
      <Modal isOpen={uploadModal.isToggled}>
        <FileUploadModal
          album={singleAlbum}
          trackList={trackList}
          onClick={() => {
            uploadModal.off();
            dispatch([SingleAlbumActions.setSingleAlbum({} as SingleAlbum), SingleAlbumActions.clearSingleTrack()]);
            window.location.reload();
          }}
        />
      </Modal>
    </Layout>
  );
};
