/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
  trackId: string;
  bookingOrder?: number;
  exposureOrder?: number;
}>;

export const AddTrackToPlaylist = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar isNullable />} value={option.trackId} />;
  const $bookingOrder = <Variable name="booking_order" scalar={<IntScalar isNullable />} value={option.bookingOrder} />;
  const $exposureOrder = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.exposureOrder} />;
  const { query, variables } = render(
    <Mutation operationName="ADD_TRACK_TO_PLAYLIST">
      <SelectionSet
        name="updatePlaylist"
        argument={{
          where: {
            playlist_id: $playlistId
          },
          data: {
            _onlySearch: true,
            metadata_playlist_relation: {
              create: [
                {
                  metadata: {
                    connect: {
                      metadata_id: $metadataId
                    }
                  },
                  booking_order: $bookingOrder,
                  exposure_order: $exposureOrder
                }
              ]
            }
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <SelectionSet name="metadata_playlist_relation">
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updatePlaylist: {
      id: string;
      metadata_playlist_relation: {
        uuid: string;
        order: number;
      }[];
    };
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
