/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet } from "graphql-jsx";
import { clients } from "utils/clients";
import { TargetTableInput } from "constants/TargetTableInput";
import { TargetTableInputScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  targetIdList: string[];
  targetTable: TargetTableInput;
}>;

export const CreateMultiAccessRecord = async (option: Option) => {
  const $targetTable = <Variable name="target_table" scalar={<TargetTableInputScalar isNullable={false} />} value={option.targetTable} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_MULTI_ACCESS_RECORD">
      {option.targetIdList.map(targetId => {
        const $targetId = <Variable name={`targetId__${targetId}`} scalar={<StringScalar />} value={targetId} />;
        return (
          <SelectionSet
            name="createAccess_record"
            alias={`createAccess_${targetId}`}
            argument={{
              data: { target_id: $targetId, target_table: $targetTable }
            }}
          >
            <Field name="id" />
            <Field name="target_id" alias="targetId" />
            <Field name="target_table" alias="targetTable" />
            <Field name="uid" />
            <Field name="createdAt" />
            <Field name="updatedAt" />
          </SelectionSet>
        );
      })}
    </Mutation>
  );
  type Data = Readonly<{
    [key: string]: Readonly<{
      id: string;
      targetId: string;
      targetTable: string;
      uid: string;
      createdAt: string;
      updatedAt: string;
    }>;
  }>;
  return await clients.access.request<Data>(query, variables);
};
