/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  artistId: string;
}>;

export const UpdateArtistRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $artistId = <Variable name="artist_id" scalar={<IdScalar />} value={option.artistId} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST">
      <SelectionSet
        name="updateMetadata_artist_relation"
        alias="updateArtistRelation"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            artist: {
              connect: {
                artist_id: $artistId
              }
            }
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateArtistRelation: {
      id: string;
    }[];
  }>;

  return await clients.artist.request<Data>(query, variables);
};
