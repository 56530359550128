import { ValidType } from "constants/ValidType";
import { createDuck } from "lib/store/v2";
import { Edge } from "models/Edge";

export type Right = {
  id: string;
  license: string | null;
  name: string;
  displayName?: string;
  path: string | null;
  type: string | null;
  validCheck: string;
  weight: number;
};

const createInitialState = () => ({
  edge: null as null | Edge,
  right: [] as Right[],
  count: 10 as number
});

export const RightsActions = createDuck({
  namespace: "Rights",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setRight(state, right: Right[]) {
      state.right = right;
    },
    setRightCount(state, count: number) {
      state.count = count;
    },
    deleteRight(state, index: number) {
      state.right.splice(index, 1);
    },
    updateRights(
      state,
      { index, name, displayName, validCheck }: { index: number; name: string; displayName: string; validCheck: ValidType }
    ) {
      state.right[index].name = name;
      state.right[index].displayName = displayName;
      state.right[index].validCheck = validCheck;
    }
  }
});
