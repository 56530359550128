import { createActions, createHandler, createReducer } from "lib/store";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { GradeType } from "constants/GradeType";

type State = ReturnType<typeof createInitialState>;

export type TrackEditEvents = State["events"];

type Track = any;

type ID = string;
type MetadataId = ID;

type CreateEvent = {
  type: "Create";
  from?: string;
  to: string;
  column?: string;
};

type UpdateEvent = {
  type: "Update";
  from: string;
  to: string;
  column?: string;
};

type RemoveEvent = {
  type: "Remove";
  column: string;
  from?: string;
  to?: string;
};

type EventLog = CreateEvent | UpdateEvent | RemoveEvent;

const SET_TRACKS = Symbol("Track::Tracks::Set");

const SET_TRACK_TITLE_BY_INDEX = Symbol("Track::Title::SetByIndex");
const SET_TRACK_VALIDCHECK_BY_INDEX = Symbol("Track::ValidCheck::SetByIndex");
const SET_TRACK_TITLE_LANGUAGE_BY_INDEX = Symbol("Track::TrackLanguage::SetByIndex");
const SET_TRACK_TITLE_TYPE_BY_INDEX = Symbol("Track::TrackType::SetByIndex");
const SET_TRACK_TITLE_VALUE_BY_INDEX = Symbol("Track::TrackTitle::SetByIndex");
const SET_TRACK_ARTIST_BY_INDEX = Symbol("Track::Artist::SetByIndex");
const SET_TRACK_URL_TYPE_BY_INDEX = Symbol("Track::UrlType::SetByIndex");
const SET_TRACK_URL_BY_INDEX = Symbol("Track::Url::SetByIndex");
const SET_TRACK_URL_VALID_TYPE_BY_INDEX = Symbol("Track::UrlValidType::SetByIndex");
const SET_TRACK_GENRE_BY_INDEX = Symbol("Track::Genre::SetByIndex");
const SET_TRACK_ROLE_BY_INDEX = Symbol("Track::Role::SetByIndex");
const SET_TRACK_LICENSE_COUNTRY = Symbol("Track::License::CountryCode::Set");
const SET_TRACK_LICENSE_NOTICE = Symbol("Track::License::Notice::Set");
const SET_TRACK_LICENSE_PUBLISH_DATE = Symbol("Track::License::PublishDate::Set");
const SET_TRACK_EXTRA_RECORD_YEAR = Symbol("Track::Extra::RecordYear::Set");
const SET_TRACK_EXTRA_PUBLISH_YEAR = Symbol("Track::Extra::PublishYear::Set");
const SET_TRACK_EXTRA_FIRST_EDITION = Symbol("Track::Extra::FirstEdition::Set");
const SET_TRACK_EXTRA_PLACE = Symbol("Track::Extra::Place::Set");
const SET_TRACK_WORK_BY_INDEX = Symbol("Track::Work::SetByIndex");
const SET_TRACK_INFO_GRADE_BY_INDEX = Symbol("Track::InfoGrade::SetByIndex");
const SET_TRACK_INFO_CHANNEL_BY_INDEX = Symbol("Track::InfoChannel::SetByIndex");
const SET_TRACK_INFO_DURATION_BY_INDEX = Symbol("Track::InfoDuration::SetByIndex");
const SET_TRACK_INFO_BITRATE_BY_INDEX = Symbol("Track::InfoBitrate::SetByIndex");
const SET_TRACK_INFO_TEMPO_BY_INDEX = Symbol("Track::InfoTempo::SetByIndex");
const SET_TRACK_INFO_TONALITY_BY_INDEX = Symbol("Track::InfoTonality::SetByIndex");
const SET_TRACK_INFO_VOICE_GENDER_BY_INDEX = Symbol("Track::InfoVoiceGender::SetByIndex");
const SET_TRACK_INFO_EXTRA_DATA_BY_INDEX = Symbol("Track::InfoExtraData::SetByIndex");
const SET_TRACK_INFO_TIMESIGNATURE_BY_INDEX = Symbol("Track::InfoTimeSignature::SetByIndex");

const SET_CREATE_TRACK_TITLE_BY_INDEX = Symbol("Track::TitleCreate::SetByIndex");
const SET_CREATE_TRACK_ARTIST_BY_INDEX = Symbol("Track::ArtistCreate::SetByIndex");
const SET_CREATE_TRACK_URL_BY_INDEX = Symbol("Track::UrlCreate::SetByIndex");
const SET_CREATE_TRACK_LICENSE_BY_INDEX = Symbol("Track::LicenseCreate::SetByIndex");
const SET_CREATE_TRACK_LICENSE_EXTRA_BY_INDEX = Symbol("Track::Extra::Set");
const SET_CREATE_TRACK_GENRE_BY_INDEX = Symbol("Track::GenreCreate::SetByIndex");
const SET_CREATE_TRACK_WORK_BY_INDEX = Symbol("Track::WorkCreate::SetByIndex");
const SET_CREATE_TRACK_INFO_BY_INDEX = Symbol("Track::InfoCreate::SetByIndex");

const SET_REMOVE_TRACK_TITLE_BY_INDEX = Symbol("Track::TitleRemove::SetByIndex");
const SET_REMOVE_TRACK_ARTIST_BY_INDEX = Symbol("Track::ArtistRemove::SetByIndex");
const SET_REMOVE_TRACK_URL_BY_INDEX = Symbol("Track::UrlRemove::SetByIndex");
const SET_REMOVE_TRACK_LICENSE_BY_INDEX = Symbol("Track::LicenseRemove::SetByIndex");
const SET_REMOVE_TRACK_GENRE_BY_INDEX = Symbol("Track::GenreRemove::SetByIndex");
const SET_REMOVE_TRACK_WORK_BY_INDEX = Symbol("Track::WorkRemove::SetByIndex");
const SET_REMOVE_TRACK_INFO_BY_INDEX = Symbol("Track::InfoRemove::SetByIndex");

const SET_EVENT_CLEAR = Symbol("Track::Event::Clear");

const SET_LOADING = Symbol("Track::Loading::Set");

export const TrackActions = createActions({
  setTracks(tracks: Track[]) {
    return { type: SET_TRACKS, tracks };
  },
  setLoading(loading: boolean) {
    return { type: SET_LOADING, loading };
  },

  // Update Event
  setTitleByIndex(index: number, title: string) {
    return { type: SET_TRACK_TITLE_BY_INDEX, index, title };
  },
  setValidCheckByIndex(index: number, validCheck: string) {
    return { type: SET_TRACK_VALIDCHECK_BY_INDEX, index, validCheck };
  },
  setTrackTitleLanguageByIndex(index: number, relationIndex: number, titleValue: any) {
    return { type: SET_TRACK_TITLE_LANGUAGE_BY_INDEX, index, relationIndex, titleValue };
  },
  setTrackTitleTypeByIndex(index: number, relationIndex: number, titleValue: any) {
    return { type: SET_TRACK_TITLE_TYPE_BY_INDEX, index, relationIndex, titleValue };
  },
  setTrackTitleValueByIndex(index: number, relationIndex: number, titleValue: any) {
    return { type: SET_TRACK_TITLE_VALUE_BY_INDEX, index, relationIndex, titleValue };
  },
  setArtistByIndex(index: number, relationIndex: number, column: string, value: Info) {
    return { type: SET_TRACK_ARTIST_BY_INDEX, index, relationIndex, column, value };
  },
  setTrackUrlTypeByIndex(index: number, relationIndex: number, typeUrl: string) {
    return { type: SET_TRACK_URL_TYPE_BY_INDEX, index, relationIndex, typeUrl };
  },
  setTrackUrlByIndex(index: number, relationIndex: number, url: string) {
    return { type: SET_TRACK_URL_BY_INDEX, index, relationIndex, url };
  },
  setTrackUrlValidCheckByIndex(index: number, relationIndex: number, fileValidCheck: string) {
    return { type: SET_TRACK_URL_VALID_TYPE_BY_INDEX, index, relationIndex, fileValidCheck };
  },
  setTrackLicenseCountryCode(index: number, relationIndex: number, countryCode: string) {
    return { type: SET_TRACK_LICENSE_COUNTRY, index, relationIndex, countryCode };
  },
  setTrackLicenseNotice(index: number, relationIndex: number, notice: string) {
    return { type: SET_TRACK_LICENSE_NOTICE, index, relationIndex, notice };
  },
  setTrackLicensePublishDate(index: number, relationIndex: number, publishDate: string) {
    return { type: SET_TRACK_LICENSE_PUBLISH_DATE, index, relationIndex, publishDate };
  },
  setTrackExtraRecordYear(index: number, recordYear: string) {
    return { type: SET_TRACK_EXTRA_RECORD_YEAR, index, recordYear };
  },
  setTrackExtraPublishYear(index: number, publishYear: string) {
    return { type: SET_TRACK_EXTRA_PUBLISH_YEAR, index, publishYear };
  },
  setTrackExtraFirstEdition(index: number, firstEdition: string) {
    return { type: SET_TRACK_EXTRA_FIRST_EDITION, index, firstEdition };
  },
  setTrackExtraPlace(index: number, place: string) {
    return { type: SET_TRACK_EXTRA_PLACE, index, place };
  },
  setGenreByIndex(index: number, relationIndex: number, column: string, value: Info) {
    return { type: SET_TRACK_GENRE_BY_INDEX, index, relationIndex, column, value };
  },
  setRoleByIndex(index: number, relationIndex: number, column: string, value: Info) {
    return { type: SET_TRACK_ROLE_BY_INDEX, index, relationIndex, column, value };
  },
  setTrackWorkByIndex(index: number, relationIndex: number, column: string, value: Info) {
    return { type: SET_TRACK_WORK_BY_INDEX, index, relationIndex, column, value };
  },
  setTrackInfoGradeByIndex(index: number, relationIndex: number, infoValue: GradeType) {
    return { type: SET_TRACK_INFO_GRADE_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoChannelByIndex(index: number, relationIndex: number, infoValue: number) {
    return { type: SET_TRACK_INFO_CHANNEL_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoDurationByIndex(index: number, relationIndex: number, infoValue: number) {
    return { type: SET_TRACK_INFO_DURATION_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoBitrateByIndex(index: number, relationIndex: number, infoValue: number) {
    return { type: SET_TRACK_INFO_BITRATE_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoTempoByIndex(index: number, relationIndex: number, infoValue: number) {
    return { type: SET_TRACK_INFO_TEMPO_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoTonalityByIndex(index: number, relationIndex: number, infoValue: string) {
    return { type: SET_TRACK_INFO_TONALITY_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoVoiceGenderByIndex(index: number, relationIndex: number, infoValue: string) {
    return { type: SET_TRACK_INFO_VOICE_GENDER_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoExtraDataByIndex(index: number, relationIndex: number, infoValue: string) {
    return { type: SET_TRACK_INFO_EXTRA_DATA_BY_INDEX, index, relationIndex, infoValue };
  },
  setTrackInfoTimeSignatureByIndex(index: number, relationIndex: number, infoValue: string) {
    return { type: SET_TRACK_INFO_TIMESIGNATURE_BY_INDEX, index, relationIndex, infoValue };
  },
  // Create Event
  setCreateTitleByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_TITLE_BY_INDEX, index, column };
  },
  setCreateArtistByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_ARTIST_BY_INDEX, index, column };
  },
  setCreateUrlByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_URL_BY_INDEX, index, column };
  },
  setCreateLicenseByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_LICENSE_BY_INDEX, index, column };
  },
  setCreateLicenseExtraByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_LICENSE_EXTRA_BY_INDEX, index, column };
  },
  setCreateGenreByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_GENRE_BY_INDEX, index, column };
  },
  setCreateWorkByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_WORK_BY_INDEX, index, column };
  },
  setCreateInfoByIndex(index: number, column: string) {
    return { type: SET_CREATE_TRACK_INFO_BY_INDEX, index, column };
  },

  // Remove Event
  setRemoveTitleByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_TITLE_BY_INDEX, index, relationIndex, column };
  },
  setRemoveArtistByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_ARTIST_BY_INDEX, index, relationIndex, column };
  },
  setRemoveUrlByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_URL_BY_INDEX, index, relationIndex, column };
  },
  setRemoveLicenseByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_LICENSE_BY_INDEX, index, relationIndex, column };
  },
  setRemoveGenreByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_GENRE_BY_INDEX, index, relationIndex, column };
  },
  setRemoveWorkByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_WORK_BY_INDEX, index, relationIndex, column };
  },
  setRemoveInfoByIndex(index: number, relationIndex: number, column: string) {
    return { type: SET_REMOVE_TRACK_INFO_BY_INDEX, index, relationIndex, column };
  },
  // ETC Event
  setEventClear() {
    return { type: SET_EVENT_CLEAR };
  }
});

const handler = createHandler<State>({
  [SET_TRACKS](state, payload: { tracks: any[] }) {
    state.tracks = payload.tracks;
  },
  [SET_LOADING](state, payload: { loading: boolean }) {
    state.loading = payload.loading;
  },
  // Update Event
  [SET_TRACK_TITLE_BY_INDEX](state, payload: { index: number; title: string }) {
    const track = state.tracks[payload.index];
    const { metadataId } = track;
    const { metadata } = state.events;
    updateEventLog(metadataId, "", metadata, "title", track.title, payload.title);
    track.title = payload.title;
  },
  [SET_TRACK_VALIDCHECK_BY_INDEX](state, payload: { index: number; validCheck: string }) {
    const track = state.tracks[payload.index];
    const { metadataId } = track;
    const { metadata } = state.events;
    updateEventLog(metadataId, "", metadata, "valid_check", track.validCheck, payload.validCheck);
    track.validCheck = payload.validCheck;
  },
  [SET_TRACK_TITLE_TYPE_BY_INDEX](state, payload: { index: number; relationIndex: number; titleValue: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, titles } = track;
    const relation = titles[payload.relationIndex];
    const { relationId } = relation;
    const { titleRelation } = state.events;

    updateMapEventLog(metadataId, relationId, titleRelation, "type_metadata_title", relation.typeMetadataTitle, payload.titleValue);
    relation.typeMetadataTitle = payload.titleValue;
  },
  [SET_TRACK_TITLE_LANGUAGE_BY_INDEX](state, payload: { index: number; relationIndex: number; titleValue: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, titles } = track;
    const relation = titles[payload.relationIndex];
    const { relationId } = relation;
    const { titleRelation } = state.events;

    updateMapEventLog(metadataId, relationId, titleRelation, "language_code", relation.languageCode, payload.titleValue);
    relation.language[0].languageCode = payload.titleValue;
  },
  [SET_TRACK_TITLE_VALUE_BY_INDEX](state, payload: { index: number; relationIndex: number; titleValue: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, titles } = track;
    const relation = titles[payload.relationIndex];
    const { relationId } = relation;
    const { titleRelation } = state.events;

    updateMapEventLog(metadataId, relationId, titleRelation, "value", relation.value, payload.titleValue);
    relation.value = payload.titleValue;
  },
  [SET_TRACK_ARTIST_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string; value: Info }) {
    const track = state.tracks[payload.index];
    const { metadataId, artists } = track;
    const relation = artists[payload.relationIndex];
    const { relationId } = relation;
    const { artistId } = relation.artist[0];
    const { artistRelation } = state.events;

    updateEventLog(metadataId, relationId, artistRelation, payload.column, artistId, payload.value.id);
    relation.artist[0].artistId = payload.value.id || artistId;
    relation.artist[0].name = payload.value.name;
  },
  [SET_TRACK_ROLE_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string; value: Info }) {
    const track = state.tracks[payload.index];
    const { metadataId, artists } = track;
    const relation = artists[payload.relationIndex];
    const { relationId } = relation;
    const { roleId } = relation.role[0];
    const { roleRelation } = state.events;

    updateEventLog(metadataId, relationId, roleRelation, payload.column, roleId, payload.value.id);
    relation.role[0].roleId = payload.value.id || roleId;
    relation.role[0].name = payload.value.name;
  },
  [SET_TRACK_URL_TYPE_BY_INDEX](state, payload: { index: number; relationIndex: number; typeUrl: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, urls } = track;
    const relation = urls[payload.relationIndex];
    const { relationId } = relation;
    const { urlRelation } = state.events;

    updateMapEventLog(metadataId, relationId, urlRelation, "type_url", relation.typeUrl, payload.typeUrl);
    relation.typeUrl = payload.typeUrl;
  },
  [SET_TRACK_URL_BY_INDEX](state, payload: { index: number; relationIndex: number; url: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, urls } = track;
    const relation = urls[payload.relationIndex];
    const { relationId } = relation;
    const { urlRelation } = state.events;

    updateMapEventLog(metadataId, relationId, urlRelation, "url", relation.url, payload.url);
    relation.url = payload.url;
  },
  [SET_TRACK_URL_VALID_TYPE_BY_INDEX](state, payload: { index: number; relationIndex: number; fileValidCheck: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, urls } = track;
    const relation = urls[payload.relationIndex];
    const { relationId } = relation;
    const { urlRelation } = state.events;

    updateMapEventLog(metadataId, relationId, urlRelation, "valid_check", relation.fileValidCheck, payload.fileValidCheck);
    relation.fileValidCheck = payload.fileValidCheck;
  },
  [SET_TRACK_LICENSE_COUNTRY](state, payload: { index: number; relationIndex: number; countryCode: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, licenses } = track;
    const relation = licenses[payload.relationIndex];
    const { relationId } = relation;
    const { licenseRelation } = state.events;

    updateMapEventLog(metadataId, relationId, licenseRelation, "country_code", relation.countryCode, payload.countryCode);
    relation.countryCode = payload.countryCode;
  },
  [SET_TRACK_LICENSE_NOTICE](state, payload: { index: number; relationIndex: number; notice: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, licenses } = track;
    const relation = licenses[payload.relationIndex];
    const { relationId } = relation;
    const { licenseRelation } = state.events;

    const temp = !relation.notice ? {} : JSON.parse(decodeURIComponent(relation.notice));
    temp.text = payload.notice;

    updateMapEventLog(metadataId, relationId, licenseRelation, "notice", relation.notice, JSON.stringify(temp));
    relation.notice = JSON.stringify(temp);
  },
  [SET_TRACK_LICENSE_PUBLISH_DATE](state, payload: { index: number; relationIndex: number; publishDate: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, licenses } = track;
    const relation = licenses[payload.relationIndex];
    const { relationId } = relation;
    const { licenseRelation } = state.events;

    updateMapEventLog(metadataId, relationId, licenseRelation, "publish_date", relation.publishDate, payload.publishDate);
    relation.publishDate = payload.publishDate;
  },
  [SET_TRACK_EXTRA_RECORD_YEAR](state, payload: { index: number; recordYear: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, extras } = track;
    const relation = extras[0];
    const { relationId } = relation;
    const { extraRelation } = state.events;

    updateMapEventLog(metadataId, relationId, extraRelation, "record_year", relation.recordYear, payload.recordYear);
    relation.recordYear = payload.recordYear;
  },
  [SET_TRACK_EXTRA_PUBLISH_YEAR](state, payload: { index: number; publishYear: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, extras } = track;
    const relation = extras[0];
    const { relationId } = relation;
    const { extraRelation } = state.events;

    updateMapEventLog(metadataId, relationId, extraRelation, "publish_year", relation.publishYear, payload.publishYear);
    relation.publishYear = payload.publishYear;
  },
  [SET_TRACK_EXTRA_FIRST_EDITION](state, payload: { index: number; firstEdition: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, extras } = track;
    const relation = extras[0];
    const { relationId } = relation;
    const { extraRelation } = state.events;

    updateMapEventLog(metadataId, relationId, extraRelation, "first_edition", relation.firstEdition, payload.firstEdition);
    relation.firstEdition = payload.firstEdition;
  },
  [SET_TRACK_EXTRA_PLACE](state, payload: { index: number; place: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, extras } = track;
    const relation = extras[0];
    const { relationId } = relation;
    const { extraRelation } = state.events;

    updateMapEventLog(metadataId, relationId, extraRelation, "place", relation.place, payload.place);
    relation.place = payload.place;
  },
  [SET_TRACK_WORK_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string; value: Info }) {
    const track = state.tracks[payload.index];
    const { metadataId, works } = track;
    const relation = works[payload.relationIndex];
    const { relationId } = relation;
    const { id } = relation.work[0];
    const { workRelation } = state.events;

    updateEventLog(metadataId, relationId, workRelation, payload.column, id, payload.value.id);
    relation.work[0].id = payload.value.id || id;
    relation.work[0].title = payload.value.name;
  },
  [SET_TRACK_GENRE_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string; value: Info }) {
    const track = state.tracks[payload.index];
    const { metadataId, genres } = track;
    const relation = genres[payload.relationIndex];
    const { relationId } = relation;
    const { genreId } = relation.genre[0];
    const { genreRelation } = state.events;

    updateEventLog(metadataId, relationId, genreRelation, payload.column, genreId, payload.value.id);
    relation.genre[0].genreId = payload.value.id || genreId;
    relation.genre[0].name = payload.value.name;
  },
  [SET_TRACK_INFO_GRADE_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: number }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;
    updateMapEventLog(metadataId, uuid, infoRelation, "grade", relation.grade, payload.infoValue);
    relation.grade = payload.infoValue;
  },
  [SET_TRACK_INFO_CHANNEL_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: number }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;
    updateMapEventLog(metadataId, uuid, infoRelation, "channel", relation.channel, payload.infoValue);
    relation.channel = payload.infoValue;
  },
  [SET_TRACK_INFO_DURATION_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: number }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "duration", relation.duration, payload.infoValue);
    relation.duration = payload.infoValue;
  },
  [SET_TRACK_INFO_BITRATE_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: number }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "bitrate", relation.bitrate, payload.infoValue);
    relation.bitrate = payload.infoValue;
  },
  [SET_TRACK_INFO_TEMPO_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: number }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "tempo", relation.tempo, payload.infoValue);
    relation.tempo = payload.infoValue;
  },
  [SET_TRACK_INFO_TONALITY_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "tonality", relation.tonality, payload.infoValue);
    relation.tonality = payload.infoValue;
  },
  [SET_TRACK_INFO_VOICE_GENDER_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "voice_gender", relation.tonality, payload.infoValue);
    relation.voiceGender = payload.infoValue;
  },
  [SET_TRACK_INFO_EXTRA_DATA_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "extra_data", relation.tonality, payload.infoValue);
    relation.extraData = payload.infoValue;
  },
  [SET_TRACK_INFO_TIMESIGNATURE_BY_INDEX](state, payload: { index: number; relationIndex: number; infoValue: number }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { infoRelation } = state.events;

    updateMapEventLog(metadataId, uuid, infoRelation, "time_signature", relation.timeSignature, payload.infoValue);
    relation.timeSignature = payload.infoValue;
  },

  [SET_CREATE_TRACK_TITLE_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, titles } = track;
    const { metadata } = state.events;
    const relationId = payload.column + titles.length.toString();
    const nextOrder = titles[titles.length - 1].exposureOrder + 1;
    const { titleRelation } = state.events;
    const emptyTitle = {
      relationId: relationId,
      language: [{ languageCode: undefined }],
      value: undefined
    };

    createEventLog(metadataId, relationId, metadata, payload.column, "", payload.index.toString());
    updateMapEventLog(metadataId, relationId, titleRelation, "exposure_order", "", nextOrder);
    titles.push(emptyTitle);
  },
  [SET_CREATE_TRACK_ARTIST_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, artists } = track;
    const { metadata } = state.events;
    const emptyArtist = {
      relationId: payload.column + artists.length.toString(),
      artist: [{ artistId: undefined, name: undefined }],
      exposureOrder: 0,
      role: [{ roleId: undefined, name: undefined }]
    };

    createEventLog(metadataId, payload.column + artists.length.toString(), metadata, payload.column, "", payload.index.toString());
    artists.push(emptyArtist);
  },
  [SET_CREATE_TRACK_URL_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, urls } = track;
    const { metadata } = state.events;
    const relationId = payload.column + urls.length.toString();
    const emptyUrl = {
      relationId: relationId,
      typeUrl: undefined,
      url: undefined,
      validCheck: undefined
    };

    createEventLog(metadataId, relationId, metadata, payload.column, "", payload.index.toString());
    urls.push(emptyUrl);
  },
  [SET_CREATE_TRACK_LICENSE_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, licenses } = track;
    const { metadata } = state.events;
    const relationId = payload.column + licenses.length.toString();
    const emptyLicense = {
      relationId: relationId,
      countryCode: undefined,
      notice: undefined,
      typeTrack: undefined,
      publish_date: undefined
    };

    createEventLog(metadataId, relationId, metadata, payload.column, "", payload.index.toString());
    licenses.push(emptyLicense);
  },
  [SET_CREATE_TRACK_LICENSE_EXTRA_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, extras } = track;
    const { metadata } = state.events;
    const relationId = payload.column + extras.length.toString();
    const emptyExtra = {
      relationId: relationId,
      recordYear: "",
      publishYear: "",
      firstEdition: "",
      place: "",
      data: ""
    };

    createEventLog(metadataId, relationId, metadata, payload.column, "", payload.index.toString());
    extras.push(emptyExtra);
  },
  [SET_CREATE_TRACK_GENRE_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, genres } = track;
    const { metadata } = state.events;
    const emptyGenre = {
      relationId: payload.column + genres.length.toString(),
      genre: [{ genreId: undefined, name: undefined, typeKind: payload.column }]
    };

    createEventLog(metadataId, payload.column + genres.length.toString(), metadata, payload.column, "", payload.index.toString());
    genres.push(emptyGenre);
  },
  [SET_CREATE_TRACK_WORK_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, works } = track;
    const { metadata } = state.events;
    const emptyWork = {
      relationId: payload.column + works.length.toString(),
      work: [{ id: undefined, title: undefined, typeKind: payload.column }]
    };
    createEventLog(metadataId, payload.column + works.length.toString(), metadata, payload.column, "", payload.index.toString());
    works.push(emptyWork);
  },
  [SET_CREATE_TRACK_INFO_BY_INDEX](state, payload: { index: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const { metadata } = state.events;
    const uuid = payload.column + track_information.length.toString();
    const emptyInfo = {
      uuid: uuid,
      channel: undefined,
      duration: undefined,
      bitrate: undefined
    };

    createEventLog(metadataId, uuid, metadata, payload.column, "", payload.index.toString());
    track_information.push(emptyInfo);
  },

  // Remove Event
  [SET_REMOVE_TRACK_TITLE_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, titles } = track;
    const relation = titles[payload.relationIndex];
    const { relationId } = relation;
    const { metadata, titleRelation } = state.events;

    if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
      metadata.get(metadataId)![relationId].type = "Remove";
    } else {
      removeMapEventLog(metadataId, relationId, titleRelation, payload.column, "", "");
    }

    relation.relationId = undefined;
  },
  [SET_REMOVE_TRACK_ARTIST_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, artists } = track;
    const relation = artists[payload.relationIndex];
    const { relationId } = relation;
    const { metadata, artistRelation } = state.events;

    if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
      metadata.get(metadataId)![relationId].type = "Remove";
    } else {
      removeEventLog(metadataId, relationId, artistRelation, payload.column, "", "");
    }

    relation.relationId = undefined;
  },
  [SET_REMOVE_TRACK_URL_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    track.urls.splice(payload.relationIndex, 1);
  },
  [SET_REMOVE_TRACK_LICENSE_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, licenses } = track;
    const relation = licenses[payload.relationIndex];
    const { relationId } = relation;
    const { metadata, licenseRelation } = state.events;

    if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
      metadata.get(metadataId)![relationId].type = "Remove";
    } else {
      removeMapEventLog(metadataId, relationId, licenseRelation, payload.column, "", "");
    }

    relation.relationId = undefined;
  },
  [SET_REMOVE_TRACK_GENRE_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, genres } = track;
    const relation = genres[payload.relationIndex];
    const { relationId } = relation;
    const { metadata, genreRelation } = state.events;

    if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
      metadata.get(metadataId)![relationId].type = "Remove";
    } else {
      removeEventLog(metadataId, relationId, genreRelation, payload.column, "", "");
    }
    relation.relationId = undefined;
  },
  [SET_REMOVE_TRACK_WORK_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, works } = track;
    const relation = works[payload.relationIndex];
    const { relationId } = relation;
    const { metadata, workRelation } = state.events;

    if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
      metadata.get(metadataId)![relationId].type = "Remove";
    } else {
      removeEventLog(metadataId, relationId, workRelation, payload.column, "", "");
    }
    relation.relationId = undefined;
  },
  [SET_REMOVE_TRACK_INFO_BY_INDEX](state, payload: { index: number; relationIndex: number; column: string }) {
    const track = state.tracks[payload.index];
    const { metadataId, track_information } = track;
    const relation = track_information[payload.relationIndex];
    const { uuid } = relation;
    const { metadata, infoRelation } = state.events;

    if (metadata.get(metadataId) && metadata.get(metadataId)![uuid]) {
      metadata.get(metadataId)![uuid].type = "Remove";
    } else {
      removeMapEventLog(metadataId, uuid, infoRelation, payload.column, "", "");
    }

    relation.uuid = undefined;
  },

  // ETC Event
  [SET_EVENT_CLEAR](state) {
    state.events.metadata.clear();
    state.events.artistRelation.clear();
    state.events.roleRelation.clear();
    state.events.genreRelation.clear();
    state.events.extraRelation.clear();
    state.events.licenseRelation.clear();
    state.events.titleRelation.clear();
    state.events.urlRelation.clear();
    state.events.infoRelation.clear();
  }
});

const updateEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, EventLog>>,
  column: string,
  from: any,
  to: any
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;

  if (relationId === "") {
    if (!eventLogs[column]) {
      eventLogs[column] = { type: "Update", from, to, column };
    } else {
      eventLogs[column].to = to;
    }
  } else {
    if (!eventLogs[relationId]) {
      eventLogs[relationId] = { type: "Update", from, to, column };
    } else {
      eventLogs[relationId].to = to;
    }
  }
};

const updateMapEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: any,
  to: any
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (!eventLogs[relationId].has(column)) {
    eventLogs[relationId].set(column, { type: "Update", from, to, column });
  } else {
    eventLogs[relationId].get(column)!.to = to;
  }
};

const createEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;
  if (!eventLogs[relationId]) {
    eventLogs[relationId] = { type: "Create", from, to, column };
  } else {
    eventLogs[relationId].to = to;
  }
};

const removeEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;
  if (!eventLogs[relationId]) {
    eventLogs[relationId] = { type: "Remove", from, to, column };
  }
};

const removeMapEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (eventLogs[relationId]) {
    eventLogs[relationId].set("Remove", { type: "Remove", from, to, column });
  }
};

const createInitialState = () => ({
  tracks: [] as Track[],
  loading: false as boolean,
  events: {
    metadata: new Map<MetadataId, Record<string, EventLog>>(),
    titleRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    urlRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    artistRelation: new Map<MetadataId, Record<string, EventLog>>(),
    roleRelation: new Map<MetadataId, Record<string, EventLog>>(),
    licenseRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    extraRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    genreRelation: new Map<MetadataId, Record<string, EventLog>>(),
    workRelation: new Map<MetadataId, Record<string, EventLog>>(),
    infoRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>()
  }
});

export const reducer = createReducer(handler, createInitialState);
