/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, ListScalar, EnumScalar } from "graphql-jsx";

import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

export const findCharacterList = async (keyword: string) => {
  const $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query: queryA, variables: variablesA } = render(
    <Query operationName="CHARACTER_SEARCHED_LIST">
      <SelectionSet
        name="role_title"
        alias="list"
        argument={{
          group: true,
          first: 40,
          orderBy: (
            <ListScalar scalar={<EnumScalar type="Role_titleOrderByInput" items={["value__SIMILARITY"]} />} value={["value__SIMILARITY"]} />
          ),
          where: {
            role__some: { type_role_class: "character", valid_check__not: $remove },
            type_role_title__starts_with: "name",
            tsvec_words__search: $keyword
          }
        }}
      >
        <Field name="role_id" />
        <SelectionSet name="role">
          <Field name="role_id" alias="id" />
          <Field name="name" />
          <Field name="type_role_class" alias="type" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: ReadonlyArray<{
      role_id: string;
      role: {
        id: string;
        name: string;
        type: string;
      }[];
    }>;
  }>;
  const { data, errors } = await clients.role.request<Data>(queryA, variablesA);
  if (errors || !data) {
    return [];
  }
  const result = data.list.map(({ role }) => ({ ...role[0] }));
  return result;
};
