import immer, { Draft } from "immer";
import { useReducer } from "react";

import { Input } from "App/Atomics/Input";
import { Info } from "App/Atomics/Input/Select/TagSelect";
import { ValidType } from "constants/ValidType";
import { License } from "constants/License";

type Company = Info;
type Artist = Info;
type Genre = Info;
type Mood = Info;
type OptionList = Parameters<typeof Input.TextSelect>[0]["optionList"];

type State = ReturnType<typeof createInitialState>;

type Action = Readonly<
  | {
      type: "setKindList";
      kindList: OptionList;
    }
  | {
      type: "toggleLicense";
      license: License;
    }
  | {
      type: "setCompanyList";
      kind: "include" | "exclude";
      companyList: readonly Company[];
    }
  | {
      type: "setArtistList";
      kind: "include" | "exclude";
      artistList: readonly Artist[];
    }
  | {
      type: "setGenreList";
      kind: "include" | "exclude";
      genreList: readonly Genre[];
    }
  | {
      type: "setMoodList";
      kind: "include" | "exclude";
      moodList: readonly Mood[];
    }
  | {
      type: "setValidTypeList";
      validTypeList: ValidType[];
    }
  | {
      type: "setSkip";
      skip: number;
    }
  | {
      type: "setCount";
      count: number;
    }
>;

const reducer = (state: State, action: Action) =>
  immer(state, draft => {
    switch (action.type) {
      case "setKindList": {
        draft.kindList = action.kindList as Draft<OptionList>;
        break;
      }
      case "toggleLicense": {
        const { license } = action;
        if (state.licenses.has(license)) {
          draft.licenses.delete(license);
        } else {
          draft.licenses.add(license);
        }
        break;
      }
      case "setCompanyList": {
        const key = action.kind === "include" ? "includeCompanyList" : "excludeCompanyList";
        draft[key] = action.companyList as Company[];
        break;
      }
      case "setArtistList": {
        const key = action.kind === "include" ? "includeArtistList" : "excludeArtistList";
        draft[key] = action.artistList as Artist[];
        break;
      }
      case "setGenreList": {
        const key = action.kind === "include" ? "includeGenreList" : "excludeGenreList";
        draft[key] = action.genreList as Genre[];
        break;
      }
      case "setMoodList": {
        const key = action.kind === "include" ? "includeMoodList" : "excludeMoodList";
        draft[key] = action.moodList as Mood[];
        break;
      }
      case "setValidTypeList": {
        draft.validTypeList = action.validTypeList;
        break;
      }
      case "setSkip": {
        draft.skip = action.skip;
        break;
      }
      case "setCount": {
        draft.count = action.count;
        break;
      }
    }
  });

const createInitialState = () => ({
  licenses: new Set<License>(),
  kindList: [] as OptionList,
  validTypeList: [] as ValidType[],
  includeCompanyList: [] as Company[],
  excludeCompanyList: [] as Company[],
  includeArtistList: [] as Artist[],
  excludeArtistList: [] as Artist[],
  includeGenreList: [] as Genre[],
  excludeGenreList: [] as Genre[],
  includeMoodList: [] as Mood[],
  excludeMoodList: [] as Mood[],
  skip: 0,
  count: 100
});

export const useTrackSearchForm = () => useReducer(reducer, {}, createInitialState);
