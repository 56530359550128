/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
  genreId: string;
  order: number;
}>;

export const CreateArtistGenre = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $genreId = <Variable name="genre_id" scalar={<IdScalar />} value={option.genreId} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ARTIST_GENRE">
      <SelectionSet
        name="updateArtist"
        alias="createArtistGenre"
        argument={{
          where: {
            artist_id: $id
          },
          data: {
            _onlySearch: true,
            artist_genre_relation: {
              create: [{ genre: { connect: { genre_id: $genreId } }, exposure_order: $order }]
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <SelectionSet name="artist_genre_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createArtistGenre: {
      id: string;
      artist_genre_relation: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
