import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_LARGE_PX, PADDING_X_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { GRAY_1 } from "constants/baseColor";

type Props = Readonly<{
  title: string;
  titleRelation: any[];
  onClick: (title: string | undefined, desc: string | undefined) => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 41)};
  padding: ${PADDING_XX_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  margin-bottom: ${MARGING_LARGE_PX};

  h4 {
    margin-bottom: ${MARGING_SMALL_PX};
  }

  input[type="text"] {
    width: 100%;
    background-color: ${GRAY_1};
    padding: ${PADDING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

const TrackDetailText = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 20)};
  background-color: ${GRAY_1};
  padding: ${PADDING_X_LARGE_PX};
`;

export const EditTitleModal = ({ title, titleRelation, onClick }: Props) => {
  const [text, setText] = useState(title);
  const [desc, setDesc] = useState(!titleRelation.length ? "" : titleRelation[0].value);
  const toSave = () => onClick(text, desc);
  const toCancel = () => onClick(undefined, undefined);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDesc(e.target.value);
  };

  return (
    <Layout>
      <h3>트랙 타이틀 정보</h3>
      <Divider />
      <RowGroup>
        <h4>트랙 타이틀</h4>
        <Input.Text defaultValue={text} placeholder="트랙 타이틀을 입력해주세요." onBlur={val => setText(val)} />
      </RowGroup>
      <RowGroup>
        <h4>트랙 상세정보</h4>
        <TrackDetailText placeholder="타이틀 상세정보를 입력하세요." value={window.decodeURIComponent(desc)} onChange={handleChange} />
      </RowGroup>
      <Divider />
      <ButtonGroup>
        <Input.Button onClick={toSave}>입력</Input.Button>
        <Input.Button onClick={toCancel}>취소</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
