import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX, PADDING_XX_LARGE_PX, MARGING_LARGE, MARGING_SMALL_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_4, GRAY_0 } from "constants/baseColor";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";

type Props = {
  onCreate: (type: string, value: string, languageCode: string) => void;
  toClose: () => void;
};

export const CreateTitleModal = ({ onCreate, toClose }: Props) => {
  const [type, setType] = useState<string>("");
  const [languageCode, setLanguageCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  return (
    <Layout>
      <RowGroup>
        <h2>추가</h2>
      </RowGroup>
      <RowGroup>
        <h4>타입 선택</h4>
        <Input.TextSelect
          className="typeSelect"
          optionList={TitleTypeOptionInfo}
          onChange={info => {
            if (info) {
              setType(info.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>언어 선택</h4>
        <LanguageSelect
          onChange={code => {
            if (code) {
              setLanguageCode(code.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>설명</h4>
        <Multiline placeholder="설명을 입력하세요" defaultValue="" onChange={e => setDescription(e.target.value)} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="cancelButton" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button
          disabled={!type.length || !languageCode.length || !description.length}
          onClick={() => onCreate(type, !type.includes("name") ? description : description.replace(/(\r\n|\n|\r)/gm, ""), languageCode)}
        >
          입력
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 38.5)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  font-size: 0.7rem;
  justify-content: center;
  align-items: center;
`;

const Multiline = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 17.5)};
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }

  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
  }

  .typeSelect {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const LanguageSelect = styled(LanguageTextSelect)`
  margin-bottom: ${MARGING_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  font-size: 1rem;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;
