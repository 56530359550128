import React, { ChangeEvent, useRef, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

import { PADDING_LARGE_PX, PADDING_X_LARGE_PX, BORDER_RADIUS_PX } from "constants/size";
import { WHITE } from "constants/baseColor";
import { identity } from "utils/identity";

export type InputTextRef = {
  value: string;
  focus: () => void;
};

export type TextProps = Styleable &
  Readonly<{
    id?: string;
    value?: string;
    pattern?: string;
    title?: string;
    defaultValue?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    minLength?: number;
    maxLength?: number;
    autoComplete?: string;
    a11y?: string;
    placeholder?: string;
    type?: "text" | "number" | "email";
    onChange?: (value: string) => void;
    onFocus?: (value: string) => void;
    onBlur?: (value: string) => void;
  }>;

const Layout = styled.input<{ isDisabled?: boolean }>`
  padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
  background-color: ${props => (!props.isDisabled ? WHITE : "hsl(0,0%,95%) !important")};
  border-radius: ${BORDER_RADIUS_PX};
`;

/**
 * Input Text 입력창 입니다.
 *
 * - 일반 `input`과 다르게 `onChange`는 `text`값을 리턴합니다.
 * - `defaultValue`에 초기값을 입력하는 것에 주의해야합니다.
 */
export const Text = forwardRef<InputTextRef, TextProps>(
  (
    {
      id,
      value,
      defaultValue,
      isDisabled,
      isRequired,
      minLength = 0,
      maxLength,
      autoComplete,
      pattern,
      title,
      a11y,
      placeholder = "값을 입력해주세요.",
      type = "text",
      style,
      className,
      onChange = identity,
      onFocus = identity,
      onBlur = identity
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const getValue = () => {
      if (pattern) {
        const value = inputRef.current!.value.match(pattern);
        return value ? value.join("") : "";
      }

      return inputRef.current!.value.trim() || "";
    };

    useImperativeHandle(ref, () => ({
      get value() {
        return getValue();
      },
      set value(value) {
        inputRef.current!.value = value.trim();
      },
      focus() {
        inputRef.current!.focus();
      }
    }));

    const change = (event: ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.currentTarget.value;
      onChange(nextValue);
    };

    const focus = () => {
      onFocus(getValue());
    };

    const blur = () => {
      const value = getValue();
      if (value !== inputRef.current!.value) {
        inputRef.current!.value = value;
      }
      onBlur(value);
    };

    return (
      <Layout
        type={type}
        id={id}
        ref={inputRef}
        isDisabled={isDisabled}
        disabled={isDisabled}
        required={isRequired}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
        title={title}
        autoComplete={autoComplete}
        spellCheck={false}
        alt={a11y}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        style={style}
        className={className}
        onChange={change}
        onFocus={focus}
        onBlur={blur}
      />
    );
  }
);
