import React, { ComponentType } from "react";
import { TagAutoComplete } from "App/Atomics/AutoComplete";
import { components, OptionProps } from "react-select";
import styled from "styled-components";
import { GRAY_5 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";
import { findWorkList } from "./findWorkList";

type Props = Pick<Parameters<typeof TagAutoComplete>[0], "style" | "className" | "isDisabled" | "defaultValue" | "onChange" | "onBlur"> & {
  subClass?: SubClass;
};

type SubClass = "album" | "track";

export const WorkTagAutoComplete = ({ style, className, isDisabled, defaultValue, subClass, onChange, onBlur }: Props) => {
  return (
    <TagAutoComplete
      placeholder="값을 입력해주세요."
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findWorkList(keyword, subClass)}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="id">{props.data.id}</span>
        <span>{children}</span>
        <span className="artist">{props.data.extra.artist}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .id {
    font-size: 9px;
    color: ${GRAY_5};
  }
  .artist {
    font-size: 11px;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;
