import React from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { HEADER_HEIGHT_PX } from "constants/size";
import { Helmet } from "App/Atomics/Helmet";
import { GenreForm } from "./GenreForm";
import { GenreCreateStoreProvider } from "./Store";

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

const GenreCreateLayout = () => (
  <ResponsiveToSideNavigatorTemplate>
    <Layout>
      <GenreForm />
    </Layout>
  </ResponsiveToSideNavigatorTemplate>
);

export const GenreCreate = () => (
  <GenreCreateStoreProvider>
    <Helmet title="장르 등록" />
    <GenreCreateLayout />
  </GenreCreateStoreProvider>
);
