/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  order: number;
}>;

export const CreateGenreUrl = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable={false} />} value={option.typeUrl} />;
  const $url = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.url} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GENRE">
      <SelectionSet
        name="updateGenre"
        alias="createGenreUrl"
        argument={{
          where: { genre_id: $id },
          data: { _onlySearch: true, genre_url: { create: [{ type_url: $typeUrl, url: $url, exposure_order: $order }] } }
        }}
      >
        <Field name="genre_id" alias="id" />
        <SelectionSet name="genre_url" alias="genreUrl">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    createGenreUrl: {
      id: string;
      genreUrl: {
        uuid: string;
      }[];
    };
  };
  return await clients.genre.request<Data>(query, variables);
};
