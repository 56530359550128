import { GoodsSubdata } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { createDuck } from "lib/store/v3";

export type ProductUrl = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  order: number;
  file?: File;
  ext?: string;
  data?: string;
}>;

export type ProductGoodsSubdataUniqueRelation = Readonly<{
  id: string;
  goods_subdata_unique: GoodsSubdata[];
}>;
export type ProductDetail = {
  id: string;
  quality: string;
  price: number;
  barcode: string;
  createdAt: string;
  goods: {
    id: string;
    name: string;
  };
  urls: ProductUrl[];
  optionList: ProductGoodsSubdataUniqueRelation[];
};

const createInitialState = () => ({
  product: {} as ProductDetail,
  origin: {} as ProductDetail,
  subdatas: [] as GoodsSubdata[]
});

export const ProductDetailActions = createDuck({
  namespace: "ProductDetail",
  createInitialState,
  reducers: {
    reset(state) {
      state.product = state.origin;
    },
    setProduct(state, product: ProductDetail) {
      state.product = product;
      state.origin = product;
    },
    setGoods(state, goods: { id: string; name: string }) {
      state.product.goods = goods;
    },
    setGoodsSubadatas(state, subdatas: GoodsSubdata[]) {
      state.subdatas = subdatas;
    },
    addSubadata(state, subdata: ProductGoodsSubdataUniqueRelation) {
      state.product.optionList.push(subdata);
    },
    removeSubadata(state, index: number) {
      state.product.optionList.splice(index, 1);
    },
    setBarcode(state, value: string) {
      state.product.barcode = value;
    },
    setQuality(state, value: string) {
      state.product.quality = value;
    },
    setPrice(state, value: number) {
      state.product.price = value;
    },
    setCreatedAt(state, date: string) {
      state.product.createdAt = date;
    },
    addCoverUrl(state, url: ProductUrl) {
      state.product.urls.push(url);
    },
    removeCoverUrl(state, index: number) {
      state.product.urls.splice(index, 1);
    }
  }
});
