import { createDuck } from "lib/store/v2";

export type Genre = {
  name: string;
};

const createInitialState = () => ({
  genre: {} as Genre
});

export const GenreCreateActions = createDuck({
  namespace: "GenreCreate",
  createInitialState,
  reducers: {
    setGenre(state, genre: Genre) {
      state.genre = genre;
    }
  }
});
