import React, { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { BLACK } from "constants/baseColor";
import { pixelize, UNIT, PADDING_LARGE_PX, PADDING_X_LARGE_PX, MARGING_LARGE_PX } from "constants/size";

type Direction = "top" | "left" | "right" | "bottom";

type Props = Styleable &
  Readonly<{
    text: string;
    direction?: Direction;
    children: ReactNode;
  }>;

const Shake = keyframes`
  0% { 
    transform: rotate(2deg);
  }
  50% {
   transform: rotate(-3deg);
  }
  70% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

const Layout = styled.div`
  display: inline-block;
  span {
    display: flex;
    width: ${pixelize(UNIT * 1.25)};
    margin: 0 ${MARGING_LARGE_PX};
  }

  [data-tooltip]:hover {
    animation: ${Shake} 700ms ease-in-out;
  }
  [data-tooltip] {
    position: relative;
    z-index: 1;
  }
  [data-tooltip]:before,
  [data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + 5px);
    pointer-events: none;
    transition: 0.2s;
    will-change: transform;
    z-index: 8;
  }

  [data-tooltip]:before {
    content: attr(data-tooltip);
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    min-width: ${pixelize(UNIT * 3)};
    max-width: ${pixelize(UNIT * 20)};
    width: max-content;
    border-radius: ${pixelize(UNIT * 0.6)};
    font-size: 0.8rem;
    text-align: left;
    background-color: #cfcfff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    color: ${BLACK};
    white-space: pre-wrap;
    transform: translate(-50%, -5px) scale(0.5);
  }

  [data-tooltip]:after {
    content: "";
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: #cfcfff transparent transparent transparent;
    transition-duration: 0s;
    transform-origin: top;
    transform: translateX(-50%) scaleY(0);
  }

  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
  [data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, -5px) scale(1);
  }

  [data-tooltip]:hover:after {
    transition-delay: 0.5s;
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1);
  }

  /* LEFT */
  [data-tooltip-location="left"]:before,
  [data-tooltip-location="left"]:after {
    left: auto;
    right: calc(100% + 5px);
    bottom: 50%;
  }

  [data-tooltip-location="left"]:before {
    transform: translate(-5px, 50%) scale(0.5);
  }
  [data-tooltip-location="left"]:hover:before {
    transform: translate(-5px, 50%) scale(1);
  }

  [data-tooltip-location="left"]:after {
    border-width: 5px 0px 5px 5px;
    border-color: transparent transparent transparent #cfcfff;
    transform-origin: left;
    transform: translateY(50%) scaleX(0);
  }
  [data-tooltip-location="left"]:hover:after {
    transform: translateY(50%) scaleX(1);
  }

  /* RIGHT */
  [data-tooltip-location="right"]:before,
  [data-tooltip-location="right"]:after {
    left: calc(100% + 5px);
    bottom: 50%;
  }

  [data-tooltip-location="right"]:before {
    transform: translate(5px, 50%) scale(0.5);
  }
  [data-tooltip-location="right"]:hover:before {
    transform: translate(5px, 50%) scale(1);
  }

  [data-tooltip-location="right"]:after {
    border-width: 5px 5px 5px 0px;
    border-color: transparent #cfcfff transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0);
  }
  [data-tooltip-location="right"]:hover:after {
    transform: translateY(50%) scaleX(1);
  }

  /* BOTTOM */
  [data-tooltip-location="bottom"]:before,
  [data-tooltip-location="bottom"]:after {
    top: calc(100% + 5px);
    bottom: auto;
  }

  [data-tooltip-location="bottom"]:before {
    transform: translate(-50%, 5px) scale(0.5);
  }
  [data-tooltip-location="bottom"]:hover:before {
    transform: translate(-50%, 5px) scale(1);
  }

  [data-tooltip-location="bottom"]:after {
    border-width: 0px 5px 5px 5px;
    border-color: transparent transparent #cfcfff transparent;
    transform-origin: bottom;
  }
`;

export const AnimatedTooltip = ({ className, style, text, direction = "top", children }: Props) => (
  <Layout>
    <span className={className} style={style} data-tooltip={text} data-tooltip-location={direction}>
      {children}
    </span>
  </Layout>
);
