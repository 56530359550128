import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { Time } from "lib/time";
type Props = {
  readonly createdAt: string;
};

export const GoodsDate = ({ createdAt }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>발매 일자</Style.Caption>
    <Style.InputDate
      key={createdAt}
      type="date"
      defaultValue={Time(createdAt).format("YYYY-MM-DD")}
      required
      min="1900-01-01"
      max="2099-12-31"
      onBlurCapture={event => GoodsDetailStore.dispatch(GoodsDetailActions.setCreatedAt(event.target.value))}
    />
    <Style.AlertCaption>날짜를 선택해주세요.</Style.AlertCaption>
  </Style.Row>
);
