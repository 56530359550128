import React from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { WorkDetailStoreProvider, useWorkDetailDispatch } from "./Store/index";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { GetMetadata } from "GraphQL/Queries/Metadata";
import { RightWorkDetailTable } from "./RightWorkDetailTable";
import { WorkDetailActions } from "./Store/WorkDetail";

const Layout = styled.div`
  font-size: 0.9rem;
`;

const ChildLayout = () => {
  const dispatch = useWorkDetailDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const id = queryParams.get("id", { default: undefined });

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        const { data: workData, errors: workError } = await GetMetadata({
          detail: true,
          page,
          metadataId: id,
          typeClassIn: ["work"],
          typeSubClass: "album"
        });
        if (workError && workError.length) {
          console.log(workError[0].message);
          return;
        }
        if (workData) {
          dispatch([WorkDetailActions.setWorkAlbum(workData.metadata[0])]);
        }
      }
    },
    [page, id]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightWorkDetailTable />
    </Layout>
  );
};

export const WorkDetail = () => (
  <WorkDetailStoreProvider>
    <Helmet title="작품 상세" />
    <ChildLayout />
  </WorkDetailStoreProvider>
);
