/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useHistory } from "lib/use-router";
import { GoodsFilters } from "../../Store/Goods";
import { GOODS } from "constants/route";
import { useQueryParams } from "lib/use-query-params";

type Props = {
  readonly filter: GoodsFilters;
};

export const useFilterRoute = ({ filter }: Props) => {
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: v => +v });
  const id = queryParams.get("id", { default: undefined });
  const title = queryParams.get("title", { default: undefined });
  const parentId = queryParams.get("parentId", { default: undefined });
  const router = useHistory();
  useEffect(() => {
    const url = new window.URLSearchParams();
    Object.entries(filter).forEach(([key, value]) => {
      const params = [...value].join(" ");
      !value.size ? url.delete(key) : url.append(key, params);
    });
    router.push(
      `${GOODS}?page=${page}${!url.toString().length ? "" : `&${url}`}${!id ? "" : `&id=${id}`}${!title ? "" : `&title=${title}`}${
        !parentId ? "" : `&parentId=${parentId}`
      }`
    );
  }, [filter]);
  return;
};
