/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  page?: number;
  first?: number;
}>;

export const LoadGoodsSubtitle = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable />} value={option.page} />;
  const { query, variables } = render(
    <Query operationName="LOAD_GOODS_SUBTITLE">
      <SelectionSet
        name="goods_title"
        alias="items"
        argument={{
          group: true,
          first: $first,
          page: $page,
          where: {
            type_title: "subtitle"
          }
        }}
      >
        <Field name="value" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    items: {
      value: string;
    }[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
