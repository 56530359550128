/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  id: string;
  genreId?: string;
  validCheck: ValidType;
}>;

export const CreatePlaylistGenre = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $genreId = <Variable name="genre_id" scalar={<IdScalar />} value={option.genreId} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_PLAYLIST_GENRE">
      <SelectionSet
        name="updatePlaylist"
        alias="createPlaylistGenre"
        argument={{
          where: { playlist_id: $id },
          data: {
            _onlySearch: true,
            playlist_genre_relation: {
              create: {
                genre: {
                  connect: {
                    genre_id: $genreId
                  }
                },
                valid_check: $validCheck
              }
            }
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <SelectionSet name="playlist_genre_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createPlaylistGenre: Readonly<{
      id: string;
      playlist_genre_relation: {
        uuid: string;
      }[];
    }>;
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
