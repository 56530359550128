/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar } from "graphql-jsx";
import { ServiceTypeScalar } from "GraphQL/Scalars";
import { ServiceType } from "constants/ServiceType";

type Option = Readonly<{
  serviceType: ServiceType;
  kind: string;
  name: string;
}>;

export const CreatePlaylist = async (option: Option) => {
  const $serviceType = <Variable name="serviceType" scalar={<ServiceTypeScalar isNullable={false} />} value={option.serviceType} />;
  const $kind = <Variable name="kind" scalar={<StringScalar isNullable={false} />} value={option.kind} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={option.name} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_PLAYLIST">
      <SelectionSet
        name="createPlaylist"
        alias="playlist"
        argument={{
          data: {
            service: $serviceType,
            kind: $kind,
            name: $name,
            playlist_title: {
              create: {
                language: {
                  connect: {
                    language_code: "OO"
                  }
                },
                type_title: "name",
                value: $name,
                exposure_order: 0
              }
            }
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    playlist: {
      id: string;
    };
  };
  return await clients.playlist.request<Data>(query, variables);
};
