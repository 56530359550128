/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  id: string;
  urls?: string[];
}>;

export const CreateMetadataUrls = async (option: Option) => {
  const $id = <Variable name="metadata_id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_MEATADA_URLS">
      <SelectionSet
        name="updateMetadata"
        alias="updateMetadataUrl"
        argument={{
          where: {
            metadata_id: $id
          },
          data: {
            _onlySearch: true,
            metadata_url: {
              create: [...(option.urls?.map((url, order) => ({ type_url: "cover", url, exposure_order: order })) ?? [])]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataUrl: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
