import React from "react";
import styled from "styled-components";
import itiriri from "itiriri";
import { Input } from "App/Atomics/Input";
import { DEFAULT_BORDER_COLOR, MODAL_BACKGROUND, PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { pixelize, UNIT, MARGING_X_LARGE_PX, MARGING_XX_LARGE_PX } from "constants/size";
import { GRAY_1, WHITE } from "constants/baseColor";
import { useAuthGrantStore } from "../Store";
import { AuthGrantActions } from "../Store/Grant";
import { useAsyncEffect } from "lib/use-async-effect";

enum RWE {
  R = 4,
  W = 2,
  E = 1
}

const Layout = styled.div`
  width: 100%;
  margin-top: ${MARGING_X_LARGE_PX};

  input {
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
  }

  button {
    &:hover {
      background-color: ${MODAL_BACKGROUND};
    }
  }
`;

const Containter = styled.div`
  width: 100%;
  min-width: ${pixelize(UNIT * 65)};
  display: flex;
  flex-direction: column;
  background: ${GRAY_1};
  border-top: 2px solid ${PRIMARY_COLOR};
  border-bottom: 2px solid ${PRIMARY_COLOR};

  & + & {
    margin-top: ${MARGING_XX_LARGE_PX};
  }
`;

const GrantHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${pixelize(UNIT * 10)} auto auto auto;
  grid-auto-rows: ${pixelize(UNIT * 5)};
  border-bottom: 2px solid ${PRIMARY_COLOR_LIGHT};
  background-color: ${GRAY_1};

  & > div {
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-self: center;
  }
`;

const GrantItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${pixelize(UNIT * 10)} auto auto auto;
  grid-auto-rows: ${pixelize(UNIT * 5)};
  border-bottom: 1px dashed ${PRIMARY_COLOR_LIGHT};
  background-color: ${WHITE};
  &:hover {
    background-color: #ededf8;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const GrantBody = styled.div`
  display: "block";
`;

const GrantItemHeader = styled.div`
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-self: center;
`;

const GrantItemBody = styled.div`
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const CheckBoxGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  line-height: 1.65;
  div + div {
    margin-left: ${MARGING_X_LARGE_PX};
  }
`;

export const GrantFormDev = () => {
  const [{ allows }, dispatch] = useAuthGrantStore(store => ({
    allows: store.Grant.allows
  }));

  const onToggleRWX = (task: string, key: string, index: number, rwe: string) => {
    dispatch(AuthGrantActions.setArbeitAllowByKey({ task, key, index, rwe }));
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
      }
    },
    [JSON.stringify(allows)]
  );

  return (
    <Layout>
      {itiriri(Object.entries(allows)).map(([tableKey, table]) => (
        <Containter key={tableKey}>
          <GrantHeader>
            <div>{table.name}</div>
            <div>하위 그룹</div>
            <div>상위 그룹</div>
            <div>메뉴 노출</div>
          </GrantHeader>

          <GrantBody>
            {itiriri(Object.entries(table.tables)).map(([valueKey, value]) => (
              <GrantItem key={valueKey}>
                <GrantItemHeader>
                  <span>{value.name}</span>
                </GrantItemHeader>
                {itiriri(Object.entries(value.allow)).map(([allowKey, allow], allowIndex) => {
                  const allowBinary = (+allow).toString(2);
                  const fixedAllowBinary =
                    allowBinary.length === 3 ? allowBinary : new Array(3 - allowBinary.length + 1).join("0") + allowBinary;
                  return (
                    <GrantItemBody key={allowKey}>
                      <CheckBoxGroup>
                        <Input.CheckBox
                          id={table.name + value.name + valueKey + allowKey + "allow0"}
                          isChecked={parseInt(fixedAllowBinary[0]) ? true : false}
                          onToggle={() => onToggleRWX(table.name, value.key, allowIndex, RWE.R.toString(2))}
                        >
                          READ
                        </Input.CheckBox>
                        <Input.CheckBox
                          id={table.name + value.name + valueKey + allowKey + "allow1"}
                          isChecked={parseInt(fixedAllowBinary[1]) ? true : false}
                          onToggle={() => onToggleRWX(table.name, value.key, allowIndex, RWE.W.toString(2))}
                        >
                          WRITE
                        </Input.CheckBox>
                        <Input.CheckBox
                          id={table.name + value.name + valueKey + allowKey + "allow2"}
                          isChecked={parseInt(fixedAllowBinary[2]) ? true : false}
                          onToggle={() => onToggleRWX(table.name, value.key, allowIndex, RWE.E.toString(2))}
                        >
                          EDIT
                        </Input.CheckBox>
                      </CheckBoxGroup>
                    </GrantItemBody>
                  );
                })}
              </GrantItem>
            ))}
          </GrantBody>
        </Containter>
      ))}
    </Layout>
  );
};
