/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, IntScalar, EnumScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  first?: number;
  typeClass: string;
  typeSubClass: string;
  gt?: number;
  lt?: number;
  order?: string;
}>;

export const GetMetadataSimple = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $typeClass = <Variable name="class" scalar={<StringScalar />} value={option.typeClass} />;
  const $typeSubClass = <Variable name="subclass" scalar={<StringScalar />} value={option.typeSubClass} />;
  const $gt = <Variable name="metadata_id__gt" scalar={<IdScalar isNullable />} value={option.gt} />;
  const $lt = <Variable name="metadata_id__lt" scalar={<IdScalar isNullable />} value={option.lt} />;
  const $order = (
    <EnumScalar type="MetadataOrderByInput" value={option.order ?? "metadata_id__DESC"} items={[option.order ?? "metadata_id__DESC"]} />
  );
  const { query, variables } = render(
    <Query operationName="GET_METADATA_SIMPLE">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: $first,
          orderBy: $order,
          where: {
            metadata_id__gt: $gt,
            metadata_id__lt: $lt,
            type_metadata_class: $typeClass,
            type_metadata_subclass: $typeSubClass
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
    }[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
