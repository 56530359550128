import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as ShuffleIcon } from "assets/icons/shuffle.svg";
import { GRAY_6, GRAY_2 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { Loading } from "App/Atomics/Loading";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { WorkAlbumTextAutoComplete } from "App/Molecules/AutoCompletes/Work/WorkAlbumTextAutoComplete";
import { UpdateMetadataStructure } from "GraphQL/Queries/Metadata";
import { useWorkDetailStore } from "../../Store";
import { WorkDetailActions } from "../../Store/WorkDetail";

type Props = {
  index: number;
  toClose: () => void;
};

export const ChangeParentModal = ({ index, toClose }: Props) => {
  const [{ album, work }, dispatch] = useWorkDetailStore(store => ({
    album: store.WorkDetail.album,
    work: store.WorkDetail.tracks[index]
  }));
  const [parentInfo, setParentInfo] = useState<Info | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const onSave = async (targetId: string, structureId: string, parentId: number) => {
    if (+album.structures[0].structureId === parentId) {
      Toast.warning("같은 작품으로 이동은 할 수 없습니다.");
      return;
    }
    setLoading(true);
    try {
      const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        const { errors: structureErr } = await UpdateMetadataStructure({ structureId, parentId, bookingOrder: 0 });
        if (structureErr) {
          throw new Error(structureErr[0].message);
        }
        await DeleteAccessRecord({ id: accessId });
      }
      dispatch(WorkDetailActions.removeWorkByIndex(index));
      Toast.primary("변경되었습니다", undefined, "top-center");
      closeModal();
    } catch (err) {
      console.log(err);
      Toast.error("작품 변경에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    toClose();
  };

  return (
    <Layout>
      <Header>
        <ShuffleIcon className="shuffle" />
        <h3>작품 부모 이동</h3>
        <CancelIcon className="cancel" onClick={closeModal} />
      </Header>
      <Section>
        <div className="wrapper">
          <div className="info">
            <h3>이동할 대작품</h3>
          </div>
          <div className="info-tip">
            <span>'작품제목'+'가수'로 검색하실 수 있습니다.</span>
          </div>
          <WorkAlbumTextAutoComplete
            defaultValue={parentInfo}
            onChange={info => {
              if (info) {
                console.log(info);
                setParentInfo(info);
              }
            }}
          />
        </div>
      </Section>
      <ButtonGroup>
        <Input.Button className="btn cancel" onClick={closeModal}>
          취소
        </Input.Button>
        <Input.Button
          className="btn save"
          disabled={!parentInfo}
          color="primary"
          onClick={() => onSave(work.metadataId, work.structures[0].structureId, parseInt(parentInfo!.extra, 10))}
        >
          변경
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 800px;
  height: 300px;
  overflow: scroll;
  background-color: #fff;
  font-size: 0.8rem;
  border-radius: 8px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.8em;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .shuffle {
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.4em;
    fill: ${PRIMARY_COLOR};
  }

  .cancel {
    position: absolute;
    right: 3%;
    width: 2em;
    height: 2em;
    padding: 0.5em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1em 2em;

  .wrapper {
    margin: 1rem 0 2rem;
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
    }
    .info-tip {
      margin-bottom: 0.5rem;
      color: ${GRAY_6};
    }
  }
  .row-group {
    display: grid;
    grid-template-columns: 3rem auto;
    margin-bottom: 0.2rem;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 9rem;
  justify-content: center;
  border-bottom: 1px solid ${GRAY_2};
  h4 {
    font-size: 1rem;
    margin-bottom: 0.2em;
  }
  .info {
    color: ${GRAY_6};
    margin-bottom: 1em;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  padding: 2em;
  .btn {
    width: 10em;
    height: 3em;
  }
  .cancel {
    margin-right: 0.2em;
  }
`;
