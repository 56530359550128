import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

export type Rights = {
  name: string;
  displayName: string | undefined;
  license: string;
  rightsType: string;
};

const createInitialState = () => ({
  rights: {} as Rights
});

export const RightsCreateActions = createDuck({
  namespace: "RightsCreate",
  createInitialState,
  reducers: {
    setRights(state, rights: Rights) {
      state.rights = rights;
    },
    setRightsName(state, name: string) {
      state.rights.name = name;
    },
    setRightsDisplayName(state, displayName: string) {
      state.rights.displayName = displayName;
    },
    setRightsLicense(state, license: string) {
      state.rights.license = license;
    },
    setRightsType(state, rightsType: string) {
      state.rights.rightsType = rightsType;
    },
    clearRights(state) {
      state.rights = {} as Rights;
    }
  }
});
