/** @jsx jsx */

import { Field, IdScalar, IntScalar, jsx, ListScalar, Query, render, SelectionSet, StringScalar, Variable } from "graphql-jsx";

import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";
import { IdType } from "App/Routes/AuthGrant/Store/IdList";

type Option = Readonly<{
  count: number;
  skip: number;
  idGte?: number;
  idLte?: number;
  idIncludeList: string[];
  idExcludeList: string[];
  licenseList: string[];
  validTypeList: ValidType[];
  includeGenreList: string[];
  includeMoodList: string[];
  excludeGenreList: string[];
  excludeMoodList: string[];
  includeCompanyList: string[];
}>;

export const findTrackSearchList = async ({
  count,
  skip,
  idGte,
  idLte,
  idIncludeList,
  idExcludeList,
  licenseList,
  validTypeList,
  includeGenreList,
  includeMoodList,
  excludeGenreList,
  excludeMoodList,
  includeCompanyList
}: Option) => {
  const $count = <Variable name="count" scalar={<IntScalar isNullable />} value={count} />;
  const $skip = <Variable name="skip" scalar={<IntScalar isNullable />} value={skip} />;
  const $gte = <Variable name="gte" scalar={<IdScalar isNullable />} value={idGte} />;
  const $lte = <Variable name="lte" scalar={<IdScalar isNullable />} value={idLte} />;
  const $idIncludeList = (
    <Variable name="idIncludeList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={idIncludeList} />
  );
  const $licenseList = (
    <Variable name="licenseList" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={licenseList} />
  );
  const $includeGenreList = (
    <Variable
      name="includeGenreList"
      scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />}
      value={includeGenreList.concat(includeMoodList)}
    />
  );
  const $excludeGenreList = (
    <Variable
      name="excludeGenreList"
      scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />}
      value={excludeGenreList.concat(excludeMoodList)}
    />
  );
  const $includeCompanyList = (
    <Variable name="companyList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={includeCompanyList} />
  );
  const $validTypeList = (
    <Variable name="validTypeList" scalar={<ListScalar scalar={<ValidTypeScalar isNullable={false} />} />} value={validTypeList} />
  );

  const { query, variables } = render(
    <Query operationName="FIND_TRACK_SEARCH_LIST">
      <SelectionSet
        name="metadata"
        alias="trackList"
        argument={{
          first: $count,
          skip: $skip,
          group: true,
          where: {
            metadata_id__gte: $gte,
            metadata_id__lte: $lte,
            metadata_id__in: $idIncludeList,
            type_metadata_class: "record",
            type_metadata_subclass: "track",
            valid_check__in: $validTypeList,
            track_license__some: {
              type_track__in: $licenseList
            },
            metadata_company_relation__some: {
              company__some: {
                company_id__in: $includeCompanyList
              }
            },
            metadata_genre_relation__some: {
              genre_id__not_include: $excludeGenreList,
              genre__some: {
                genre_id__in: $includeGenreList
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
      </SelectionSet>
    </Query>
  );

  const response = await clients.metadata.request<{ trackList: IdType[] }>(query, variables);
  return response.errors ? [] : response.data!.trackList;
};
