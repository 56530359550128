import { initializeKeyValue } from "lib/initialize-key-value";

export type Head = ReturnType<typeof sanitizeHeads>["head"];
export type Subhead = ReturnType<typeof sanitizeHeads>["subhead"];

export const sanitizeHeads = (option: Record<string, boolean>) => {
  const { Head: head, Subhead: subhead } = initializeKeyValue(option, {
    Head: {
      Artist: true,
      Metadata: true,
      Title: true,
      Url: true,
      Company: true,
      LicenseExtra: true,
      Genre: true,
      Info: true
    },
    Subhead: {
      Artist: {
        artist: true,
        role: true
      },
      Metadata: {
        validCheck: true,
        metadataId: true,
        title: true,
        typeMetadataSubclass: true
      },
      Title: {
        language: true,
        typeMetadataTitle: true,
        value: true
      },
      Url: {
        typeUrl: true,
        url: true,
        fileValidCheck: true
      },
      Company: {
        company: true
      },
      LicenseExtra: {
        recordYear: true,
        publishYear: true,
        firstEdition: true,
        place: true,
        data: true
      },
      Genre: {
        genre: true,
        mood: true
      },
      Info: {
        value: true
      }
    }
  });
  return { head, subhead };
};
