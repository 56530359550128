import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { configs } from "configs";
import { useToggle } from "lib/use-toggle";
import { ReactComponent as TitleIcon } from "assets/icons/title.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/photo.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import defaultImage from "assets/images/cover.png";
import { mediaQuery } from "constants/media";
import { Modal } from "lib/modal";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, DANGER_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import { Confirm } from "App/Molecules/Confirm";
import { Toast } from "lib/toast";
import { Genre } from "GraphQL/Queries/Genre/GetGenre";
import { GENRES } from "constants/route";
import { ImageViewModal, TitleModal } from "../../Modals";
import { EditInfoModal } from "../../Modals/EditInfoModal";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { DeleteGenre } from "GraphQL/Queries/Genre";
import { useGenresDispatch } from "App/Routes/GenreRenewal/Store";
import { GenreActions } from "App/Routes/GenreRenewal/Store/Genre";
import { Loading } from "App/Atomics/Loading";
import { allowGenreCreate } from "App/Routes/AdminRoutes/allowTables";

type Props = {
  index: number;
  genre: Genre;
};

export const CardItem = ({ index, genre }: Props) => {
  const { userRole, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    store
  }));
  const dispatch = useGenresDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, name, typeKind, validCheck, weight, genreUrl, genre_self_relation_parent } = genre;
  const allowGenreEdit = userRole === UserRole.Master || allowGenreCreate(store);
  const router = useHistory();
  const imageViewModal = useToggle();
  const confirmModal = useToggle();
  const titleModal = useToggle();
  const editInfoModal = useToggle();

  const imageUrl = genreUrl?.filter(({ typeUrl }) => typeUrl === "head" || typeUrl === "thumbnail" || typeUrl === "cover") ?? [];
  const url =
    imageUrl && imageUrl.length
      ? `${configs.urls.image}/${imageUrl[0].url}${imageUrl[0].url!.includes("?") ? `&` : `?`}mode=m`
      : defaultImage;

  const toGenreChild = async (event: React.MouseEvent) => {
    const button = event.currentTarget.querySelector("button");
    if (button?.hasAttribute("clicked")) {
      return;
    }
    if (weight === 1 && genre_self_relation_parent.length) {
      router.push(`${GENRES}?page=1&weight=0&type=${typeKind}&id=${id}`);
    } else if (weight === 1 && !genre_self_relation_parent.length) {
      Toast.warning(`하위 ${typeKind === "genre" ? "장르" : "무드"}가 없습니다.`);
      return;
    }
  };

  const onDelete = async () => {
    if (genre_self_relation_parent.length) {
      Toast.warning("하위 장르를 먼저 삭제해주세요.");
      return;
    }
    try {
      setLoading(true);
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Genre });
      if (accessId) {
        await DeleteGenre({ genreId: genre.id });
        dispatch(GenreActions.deleteGenre(index));
        Toast.primary("삭제되었습니다.");
        await DeleteAccessRecord({ id: accessId });
      }
    } catch (err) {
      console.log(err);
      Toast.error("장르 삭제에 실패하였습니다.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout key={index}>
      <ImageContainter>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="link" onClick={e => toGenreChild(e)}>
          <img src={url} alt={`${name}-cover`} />
        </a>
        <Badge vc={validCheck as ValidType}>{validCheck}</Badge>
        <span className="name">{name}</span>
        <div className="svg-wrapper" onClick={e => e.stopPropagation()}>
          {userRole === UserRole.Master && <DeleteIcon className="delete" onClick={confirmModal.on} />}
          {allowGenreEdit && (
            <>
              <DocumentIcon className="document" onClick={editInfoModal.on} />
              <PhotoIcon className="photo" onClick={imageViewModal.on} />
              <TitleIcon className="title" onClick={titleModal.on} />
            </>
          )}
        </div>
        c
      </ImageContainter>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm title={"경고"} context={"정말로 삭제하시겠습니까?"} toSave={onDelete} toClose={confirmModal.off} />
      </Modal>
      <Modal isOpen={imageViewModal.isToggled} onClose={imageViewModal.off}>
        <ImageViewModal index={index} id={id} genreUrl={genreUrl} toClose={imageViewModal.off} />
      </Modal>
      <Modal isOpen={titleModal.isToggled} onClose={titleModal.off}>
        <TitleModal id={id} toClose={titleModal.off} />
      </Modal>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        <EditInfoModal index={index} toClose={editInfoModal.off} />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;

  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  cursor: pointer;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      filter: brightness(0.8);
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #eee;
    }
  }
  margin-bottom: 1.3rem;
  ${mediaQuery(768)} {
    margin-bottom: 0.6rem;
  }

  .name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 550;

    ${mediaQuery(768)} {
      font-size: 1.2rem;
    }
  }

  a.link::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6em;
    content: "";
    z-index: 1;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    a.link::after {
      opacity: 1;
    }

    .wrapper,
    .delete,
    .photo,
    .document,
    .title {
      opacity: 1;
    }
  }
  .svg-wrapper {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 0;
    right: 0;
    z-index: 2;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }
  }

  .delete,
  .photo,
  .document,
  .title {
    position: absolute;
    bottom: 1.2em;
    z-index: 3;
    opacity: 0;
    fill: #eee;
    width: 1.2em;
    height: 1.2em;
    transition: all 0.15s;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }

    &:hover {
      fill: #fff;
      transform: scale(1.3, 1.3);
    }
  }

  .delete {
    left: 1.2em;
    &:hover {
      fill: #f00;
    }
  }

  .photo {
    right: 1.2em;
  }
  .document {
    right: 3.5em;
  }
  .title {
    right: 5.8em;
  }
`;

const Badge = styled.div<{ vc: ValidType }>`
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 3%;
  color: ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR : "#eee")};
  text-shadow: 0 0 2px ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR_LIGHT : PRIMARY_COLOR)};
`;
