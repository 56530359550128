import React from "react";
import { Helmet } from "App/Atomics/Helmet";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { PlaylistAutoCreateStoreProvider } from "./Store";
import { Left } from "./Left";
import { Right } from "./Right";

const Layout = styled.div`
  overflow-x: auto;
  display: grid;
  grid-template-columns: min-content 1fr;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

export const PlaylistAutoCreate = () => {
  return (
    <PlaylistAutoCreateStoreProvider>
      <Helmet title="플레이리스트 등록" />
      <ResponsiveToSideNavigatorTemplate>
        <Layout>
          <Left />
          <Right />
        </Layout>
      </ResponsiveToSideNavigatorTemplate>
    </PlaylistAutoCreateStoreProvider>
  );
};
