import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { GenreActions as Genre } from "./Genre";

const reducers = { Genre };

const reducer = combineReducers(reducers, createStoreLogger("Genre"));

export const {
  StoreProvider: GenresStoreProvider,
  useStore: useGenresStore,
  useSelector: useGenresSelector,
  useDispatch: useGenresDispatch
} = createStore(reducer);
