/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, IntScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";
import { ProductOrderScalar } from "GraphQL/Scalars";
import { ProductOrderByInput } from "constants/ProductOrderByInput";
import { GoodsSubdata } from "./LoadGoodsDetail";

type Option = Readonly<{
  page?: number;
  first?: number;
  id?: string;
  idIn?: number[];
  barcode?: string;
  goodsId?: string;
  order?: ProductOrderByInput;
}>;

type Url = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  order: number;
}>;

type Company = Readonly<{
  company: {
    id: string;
    name: string;
  };
}>;

type ProductGoodsSubdataUniqueRelation = Readonly<{
  id: string;
  goods_subdata_unique: GoodsSubdata[];
}>;

type Commodity = Readonly<{
  id: string;
  goodsId: string;
  barcode: string;
  quality: string;
  price: number;
  value: string;
  goods: {
    id: string;
    name: string;
    typeId: number;
  }[];
  urls: Url[];
  commodity_goods_subdata_unique_relation: ProductGoodsSubdataUniqueRelation[];
  createdAt: string;
}>;

export const LoadCommodity = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable />} value={option.page} />;
  const $id = <Variable name="commodity_id" scalar={<IdScalar isNullable />} value={option.id} />;
  const $barcode = <Variable name="barcode" scalar={<StringScalar isNullable />} value={option.barcode} />;
  const $goodsId = <Variable name="goods_id" scalar={<IdScalar isNullable />} value={option.goodsId} />;
  const $order = <ProductOrderScalar value={option.order ?? ProductOrderByInput.product_id__DESC} />;
  const { query, variables } = render(
    <Query operationName="LOAD_COMMODITY">
      <SelectionSet
        name="commodity"
        alias="product"
        argument={{
          group: true,
          first: $first,
          page: $page,
          orderBy: $order,
          where: {
            commodity_id: $id,
            commodity_id__in: !option.idIn?.length ? undefined : option.idIn,
            barcode: $barcode,
            goods_id: $goodsId
          }
        }}
      >
        <Field name="commodity_id" alias="id" />
        <Field name="goods_id" alias="goodsId" />
        <Field name="barcode" />
        <Field name="quality" />
        <Field name="price" />
        <SelectionSet name="goods">
          <Field name="goods_id" alias="id" />
          <Field name="name" />
          <Field name="type_id" alias="typeId" />
        </SelectionSet>
        <SelectionSet name="commodity_url" alias="urls">
          <Field name="id" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        {/* <SelectionSet name="commodity_goods_subdata_unique_relation">
          <Field name="id" />
          <SelectionSet name="goods_subdata_unique">
            <Field name="id" />
            <Field name="type_subdata_field" alias="field" />
            <Field name="type_subdata_category" alias="category" />
            <Field name="value" />
          </SelectionSet>
        </SelectionSet> */}
        <Field name="created_at" alias="createdAt" />
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "commodity",
          first: $first,
          page: $page,
          where: {
            commodity: {
              commodity_id: $id,
              goods_id: $goodsId,
              commodity_id__in: !option.idIn?.length ? undefined : option.idIn,
              barcode: $barcode
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    product: Commodity[];
    edge: Edge;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
