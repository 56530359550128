export const mediaQuery = (maxWidth: number) => `
  @media (max-width: ${maxWidth}px)
`;

const media = {
  default: mediaQuery(1024),
  tablet: mediaQuery(768),
  mobile: mediaQuery(375),
  custom: mediaQuery
};

export default media;
