/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar, StringScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
  name: string;
}>;

export const UpdateArtistName = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar />} value={option.name} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST_NAME">
      <SelectionSet
        name="updateArtist"
        argument={{
          where: {
            artist_id: $id
          },
          data: {
            name: $name
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateArtist: {
      id: string;
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
