import React from "react";

import { INFO_LIST } from "./infoList";
import { Input } from "App/Atomics/Input";
import { ValidType } from "constants/ValidType";
import { identity } from "utils/identity";
import { Info } from "App/Atomics/Input/Select/TextSelect";

type Props = Omit<Parameters<typeof Input.TextSelect>[0], "defaultValue" | "optionList" | "onChange" | "onBlur"> &
  Readonly<{
    defaultValue?: ValidType;
    onChange?: (validType: null | ValidType) => void;
    onBlur?: (validType: null | ValidType) => void;
  }>;

export const ValidTypeTextSelect = ({
  align,
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange = identity,
  onBlur = identity
}: Props) => {
  const change = (info: null | Info) => onChange(info && (info.id as ValidType));
  const blur = (info: null | Info) => onBlur(info && (info.id as ValidType));
  return (
    <Input.TextSelect
      placeholder="값을 입력해주세요."
      align={align}
      isDisabled={isDisabled}
      defaultValue={defaultValue && { id: defaultValue, name: defaultValue }}
      optionList={INFO_LIST}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      onChange={change}
      onBlur={blur}
    />
  );
};
