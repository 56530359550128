import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_6 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, UpdateUser } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { useAppDispatch } from "App/Store";
import { UserTokenActions } from "App/Store/UserToken";
import { UserInfo } from "utils/clients/UserInfo";
import { updateTokens } from "utils/clients/updateTokens";

type Props = {
  name?: string | null;
  toClose: () => void;
};

export const NameChangeModal = ({ name, toClose }: Props) => {
  const dispatchApp = useAppDispatch();
  const [username, setUsername] = useState<string | null | undefined>(name);
  const [password, setPassword] = useState<string | undefined>(undefined);

  const onSave = async (password: string, name: string) => {
    try {
      const accessId = await requestAccessRecord({ targetTable: TargetTableInput.Users });
      if (accessId) {
        try {
          const { errors } = await UpdateUser({ password, name });
          if (errors) {
            if (errors[0].message.includes("wrong password")) {
              Toast.error("잘못된 비밀번호입니다.", undefined, "top-center");
              return;
            }
            throw new Error(errors[0].message);
          }
          Toast.primary("수정되었습니다.");
          dispatchApp(UserTokenActions.updateUsername(name));
          const { data: tokenData } = await updateTokens();
          if (tokenData) {
            UserInfo.saveTokens(tokenData.updateTokens.tokens);
          }
          toClose();
        } catch (err) {
          console.log(err);
          Toast.error("오류가 발생하였습니다.", undefined, "top-center");
          return;
        } finally {
          await DeleteAccessRecord({ id: accessId });
        }
      }
    } catch (err) {
      console.log(err);
      Toast.error("권한 신청에 실패하였습니다.", undefined, "top-center");
      return;
    }
  };

  const checkInputValid = useCallback((type: string, text?: string | null) => {
    if (!text) {
      return undefined;
    }
    if (type === "name") {
      const regex = /[가-힣0-9a-z]{2,}/;
      return regex.test(text);
    } else {
      const regex = /[\w!@#$%^&*-]{6,}/;
      return regex.test(text);
    }
  }, []);

  const onClearAndClose = () => {
    toClose();
  };

  return (
    <Layout>
      <Header>
        <h3>이름 변경</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <span>변경할 이름</span>
        <ValidContainer isValid={checkInputValid("name", username)}>
          <input type="text" defaultValue={!name ? undefined : name} onChange={e => setUsername(e.target.value)} />
        </ValidContainer>
        <span>비밀번호 확인</span>
        <ValidContainer isValid={checkInputValid("password", password)}>
          <input
            type="password"
            placeholder="비밀번호를 입력해주세요."
            defaultValue={password}
            onChange={e => setPassword(e.target.value)}
          />
        </ValidContainer>
      </Section>
      <ButtonGroup>
        <Input.Button onClick={onClearAndClose}>취소</Input.Button>
        <Input.Button
          disabled={!checkInputValid("name", username) || !checkInputValid("password", password)}
          color="primary"
          onClick={() => onSave(password!, username!)}
        >
          변경
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  width: 512px;
  height: 320px;
  font-size: 0.8rem;
  border-radius: 8px;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.15rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    color: #191919;
  }

  input {
    width: 100%;
    border: 1px solid hsl(210 6% 83%);
    margin: 0.7rem 0;
    padding: 0.7rem;
    padding-right: 48px;
    border-radius: 8px;
    transition: border-color 0.15s;
    &:focus {
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

const ButtonGroup = styled.div`
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 1rem 0;
`;

const ValidContainer = styled.div<{ isValid?: boolean }>`
  position: relative;
  &::after {
    content: "";
    display: inline-block;
    ${props =>
      props.isValid === false
        ? `background: rgba(0, 0, 0, 0)
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29ucyAvIFNldHRpbmdzIC8gSW52YWxpZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy0vLVNldHRpbmdzLS8tSW52YWxpZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IngtY2lyY2xlLWYiIGZpbGw9IiNEQjQyNDEiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4wNzA1NTU2LDE3LjA3IEMxMy4xODE2NjY3LDIwLjk1ODg4ODkgNi44MTgzMzMzMywyMC45NTg4ODg5IDIuOTI5NDQ0NDQsMTcuMDcgQy0wLjk1ODg4ODg4OSwxMy4xODE2NjY3IC0wLjk1ODg4ODg4OSw2LjgxODMzMzMzIDIuOTI5NDQ0NDQsMi45Mjk0NDQ0NCBDNi44MTgzMzMzMywtMC45NTg4ODg4ODkgMTMuMTgxNjY2NywtMC45NTg4ODg4ODkgMTcuMDcsMi45Mjk0NDQ0NCBDMjAuOTU4ODg4OSw2LjgxODMzMzMzIDIwLjk1ODg4ODksMTMuMTgxNjY2NyAxNy4wNzA1NTU2LDE3LjA3IEwxNy4wNzA1NTU2LDE3LjA3IFogTTEzLjg5Mzg4ODksNy42NjM4ODg4OSBMMTIuMzM2MTExMSw2LjEwNjExMTExIEwxMCw4LjQ0Mjc3Nzc4IEw3LjY2Mzg4ODg5LDYuMTA2MTExMTEgTDYuMTA2NjY2NjcsNy42NjM4ODg4OSBMOC40NDI3Nzc3OCwxMCBMNi4xMDY2NjY2NywxMi4zMzYxMTExIEw3LjY2Mzg4ODg5LDEzLjg5Mzg4ODkgTDEwLDExLjU1NzIyMjIgTDEyLjMzNjExMTEsMTMuODkzODg4OSBMMTMuODkzODg4OSwxMi4zMzYxMTExIEwxMS41NTcyMjIyLDEwIEwxMy44OTM4ODg5LDcuNjYzODg4ODkgTDEzLjg5Mzg4ODksNy42NjM4ODg4OSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
      no-repeat scroll 0% 0%`
        : props.isValid === true
        ? `background: rgba(0, 0, 0, 0)
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29ucyAvIFNldHRpbmdzIC8gVmFsaWQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iSWNvbnMtLy1TZXR0aW5ncy0vLVZhbGlkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iY2hlY2stY2lyY2xlLWYiIGZpbGw9IiMzQ0FBRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMCwxOS45OTg4ODg5IEM0LjUwMDU1NTU2LDE5Ljk5ODg4ODkgMC4wMDExMTExMTExMSwxNS40OTg4ODg5IDAuMDAxMTExMTExMTEsOS45OTk0NDQ0NCBDMC4wMDExMTExMTExMSw0LjUwMTExMTExIDQuNTAwNTU1NTYsMC4wMDExMTExMTExMSAxMCwwLjAwMTExMTExMTExIEMxNS40OTk0NDQ0LDAuMDAxMTExMTExMTEgMTkuOTk4ODg4OSw0LjUwMTExMTExIDE5Ljk5ODg4ODksOS45OTk0NDQ0NCBDMTkuOTk4ODg4OSwxNS40OTg4ODg5IDE1LjQ5OTQ0NDQsMTkuOTk4ODg4OSAxMCwxOS45OTg4ODg5IEwxMCwxOS45OTg4ODg5IFogTTEzLjM5Nzc3NzgsNi4xMTA1NTU1NiBMOC4xMTk0NDQ0NCwxMS4yOTYxMTExIEw2LjA0NjY2NjY3LDkuMjYgTDQuNDQzODg4ODksMTAuODM0NDQ0NCBMOC4xMTk0NDQ0NCwxNC40NDUgTDkuNzIyMjIyMjIsMTIuODcwNTU1NiBMMTUuMDAwNTU1Niw3LjY4NTU1NTU2IEwxMy4zOTc3Nzc4LDYuMTEwNTU1NTYgTDEzLjM5Nzc3NzgsNi4xMTA1NTU1NiBaIE04LjExOTQ0NDQ0LDExLjI5NjExMTEgTDguMTE5NDQ0NDQsMTEuMjk2MTExMSBMOS43MjIyMjIyMiwxMi44NzA1NTU2IEw4LjExOTQ0NDQ0LDExLjI5NjExMTEgTDguMTE5NDQ0NDQsMTEuMjk2MTExMSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
      no-repeat scroll 0% 0%`
        : ""};
    position: absolute;
    top: 50%;
    right: 12px;
    bottom: auto;
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
`;
