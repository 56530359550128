import { createDuck } from "lib/store/v2";
import { Artist, Company, Metadata, SubData, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import { LOADING } from "App/Templates/LoadingTemplate";
import { ValidType } from "constants/ValidType";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import { GenderType } from "constants/GenderType";

export type Genre = Readonly<{
  id: string;
  typeKind: string;
  name: string;
  genre_self_relation_child?: {
    parent?: {
      id: string;
      name?: string;
    }[];
    child?: {
      id: string;
      name?: string;
    }[];
  }[];
}>;

const createInitialState = () => ({
  loading: LOADING.ONLOAD as LOADING,
  editLoading: false as boolean,
  origin: {} as Metadata,
  track: {} as Metadata,
  genreList: [] as Genre[],
  moodList: [] as Genre[]
});

export const PlaylistTrackActions = createDuck({
  namespace: "PlaylistTrack",
  createInitialState,
  reducers: {
    setLoading(state, loading: LOADING) {
      state.loading = loading;
    },
    setEditLoading(state, loading: boolean) {
      state.editLoading = loading;
    },
    setTrackClear(state) {
      state.track = {} as Metadata;
      state.genreList = [];
      state.moodList = [];
    },
    setTrack(state, track: Metadata) {
      state.track = track;
      state.origin = track;
    },
    updateTrackTitle(state, title: string) {
      state.track.title = title;
    },
    updateTrackVC(state, vc: ValidType) {
      state.track.validCheck = vc;
    },
    createTrackArtist(state) {
      const { artistRelation } = state.track;
      const uuid = uuidv4();
      const order = !artistRelation.length ? 0 : _.last(artistRelation)!.order + 1;
      const tempArtist = {
        uuid,
        artist: [{ id: undefined, name: undefined }],
        role: [{ id: undefined, name: undefined }],
        character: [{ id: undefined, name: undefined }],
        validCheck: ValidType.V3,
        order
      } as Artist;
      if (!artistRelation.length) {
        state.track.artistRelation = [tempArtist];
      } else {
        state.track.artistRelation.push(tempArtist);
      }
    },
    updateTrackArtist(state, { artistIndex, info }: { artistIndex: number; info: Info }) {
      const { artistRelation } = state.track;
      artistRelation[artistIndex].artist[0].id = info.id;
      artistRelation[artistIndex].artist[0].name = info.name;
    },
    updateTrackArtistUuid(state, { prevUuid, uuid }: { prevUuid: string; uuid: string }) {
      const { artistRelation } = state.track;
      const artistIndex = artistRelation.findIndex(({ uuid }) => uuid === prevUuid);
      artistRelation[artistIndex].uuid = uuid;
    },
    updateTrackRole(state, { roleIndex, info }: { roleIndex: number; info: Info }) {
      const { artistRelation } = state.track;
      artistRelation[roleIndex].role[0].id = info.id;
      artistRelation[roleIndex].role[0].name = info.name;
    },
    updateTrackCharacter(state, { characterIndex, info }: { characterIndex: number; info: Info }) {
      const { artistRelation } = state.track;
      if (!artistRelation[characterIndex].character.length) {
        artistRelation[characterIndex].character = [{ id: info.id, name: info.name }];
      } else {
        artistRelation[characterIndex].character[0].id = info.id;
        artistRelation[characterIndex].character[0].name = info.name;
      }
    },
    deleteTrackArtist(state, artistIndex: number) {
      state.track.artistRelation.splice(artistIndex, 1);
    },
    setTrackRightsCompany(state, { id, name }: { id: string; name: string }) {
      state.track.rightsCompany[0].company[0].id = id;
      state.track.rightsCompany[0].company[0].name = name;
    },
    setTrackProductions(state, productions: Company[]) {
      state.track.productions = productions;
    },
    setTrackPublications(state, publications: Company[]) {
      state.track.publications = publications;
    },
    updateTrackCompanyUuid(state, { typeKind, prevUuid, uuid }: { typeKind: string; prevUuid: string; uuid: string }) {
      if (typeKind === "productions") {
        const companyIndex = state.track.productions.findIndex(({ uuid }) => uuid === prevUuid);
        state.track.productions[companyIndex].uuid = uuid;
      } else {
        const companyIndex = state.track.publications.findIndex(({ uuid }) => uuid === prevUuid);
        state.track.publications[companyIndex].uuid = uuid;
      }
    },
    createTrackUrl(state, url: Url) {
      if (!state.track.metadataUrl.length) {
        state.track.metadataUrl = [url];
      } else {
        state.track.metadataUrl.push(url);
      }
    },
    updateTrackTypeUrl(state, { urlIndex, typeUrl }: { urlIndex: number; typeUrl: string }) {
      const order = (_.last(state.track.metadataUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.track.metadataUrl[urlIndex].typeUrl = typeUrl;
      state.track.metadataUrl[urlIndex].order = order;
    },
    updateTrackUrl(state, { urlIndex, url }: { urlIndex: number; url: string }) {
      state.track.metadataUrl[urlIndex].url = url;
    },
    updateTrackUrlUuid(state, { prevUuid, uuid }: { prevUuid: string; uuid: string }) {
      const { metadataUrl } = state.track;
      const urlIndex = metadataUrl.findIndex(({ uuid }) => uuid === prevUuid);
      metadataUrl[urlIndex].uuid = uuid;
    },
    deleteTrackUrl(state, urlIndex: number) {
      state.track.metadataUrl.splice(urlIndex, 1);
    },
    createTrackSubdata(state) {
      const { subdataUnique } = state.track;
      const uuid = uuidv4();
      const temp = {
        uuid,
        category: undefined,
        field: undefined,
        value: undefined,
        validCheck: ValidType.V3
      } as SubData;
      if (!subdataUnique.length) {
        state.track.subdataUnique = [temp];
      } else {
        state.track.subdataUnique.push(temp);
      }
    },
    updateTrackSubdataUuid(state, { prevUuid, uuid }: { prevUuid: string; uuid: string }) {
      const { subdataUnique } = state.track;
      const subIndex = subdataUnique.findIndex(({ uuid }) => uuid === prevUuid);
      subdataUnique[subIndex].uuid = uuid;
    },
    updateTrackSubdataCategory(state, { subIndex, category }: { subIndex: number; category: string }) {
      const { subdataUnique } = state.track;
      subdataUnique[subIndex].category = category;
    },
    updateTrackSubdataField(state, { subIndex, field }: { subIndex: number; field: string }) {
      const { subdataUnique } = state.track;
      subdataUnique[subIndex].field = field;
    },
    updateTrackSubdataValue(state, { subIndex, value }: { subIndex: number; value: string }) {
      const { subdataUnique } = state.track;
      subdataUnique[subIndex].value = value;
    },
    deleteTrackSubdata(state, subIndex: number) {
      state.track.subdataUnique.splice(subIndex, 1);
    },
    createTrackElementRelation(state) {
      const { listRelation } = state.track;
      const uuid = uuidv4();
      const order = !listRelation.length ? 0 : _.last(listRelation)!.order + 1;
      const temp = {
        uuid,
        order,
        elementRelation: [
          {
            id: undefined,
            title: undefined
          }
        ]
      };
      if (!listRelation.length) {
        state.track.listRelation = [temp];
      } else {
        state.track.listRelation.push(temp);
      }
    },
    updateTrackElementRelationUuid(state, { prevUuid, uuid }: { prevUuid: string; uuid: string }) {
      const { listRelation } = state.track;
      const subIndex = listRelation.findIndex(({ uuid }) => uuid === prevUuid);
      listRelation[subIndex].uuid = uuid;
    },
    updateTrackElementRelationTrack(state, { subIndex, id, title }: { subIndex: number; id: string; title: string }) {
      const { listRelation } = state.track;
      listRelation[subIndex].elementRelation[0].id = id;
      listRelation[subIndex].elementRelation[0].title = title;
    },
    deleteTrackElementRelation(state, subIndex: number) {
      state.track.listRelation.splice(subIndex, 1);
    },
    setTrackGenre(state, genre: Genre[]) {
      state.genreList = genre;
    },
    setTrackMood(state, mood: Genre[]) {
      state.moodList = mood;
    },
    changeTrackGenre(state, genre: Genre[]) {
      state.genreList = genre;
      const trackGenre = state.track.genreRelation;
      const payloadGenre =
        genre?.map(({ id, name }) => ({
          uuid: uuidv4(),
          validCheck: ValidType.V3,
          genre: [
            {
              id,
              name
            }
          ]
        })) ?? [];
      if (trackGenre.length < payloadGenre.length) {
        const addGenreList = payloadGenre?.filter(({ genre }) => trackGenre.every(item => item.genre[0].id !== genre[0].id)) ?? [];
        state.track.genreRelation.push(...addGenreList);
      } else if (trackGenre.length > payloadGenre.length) {
        if (!payloadGenre.length) {
          state.track.genreRelation = [];
        } else {
          const removeIndex = trackGenre.findIndex(({ genre }) => !payloadGenre.some(item => item.genre[0].id === genre[0].id));
          state.track.genreRelation.splice(removeIndex, 1);
        }
      } else {
        return;
      }
    },
    changeTrackMood(state, mood: Genre[]) {
      state.moodList = mood;
      const trackMood = state.track.moodRelation;
      const payloadMood =
        mood?.map(({ id, name }) => ({
          uuid: uuidv4(),
          validCheck: ValidType.V3,
          mood: [
            {
              id,
              name
            }
          ]
        })) ?? [];
      if (trackMood.length < payloadMood.length) {
        const addMoodList = payloadMood?.filter(({ mood }) => trackMood.every(item => item.mood[0].id !== mood[0].id)) ?? [];
        state.track.moodRelation.push(...addMoodList);
      } else if (trackMood.length > payloadMood.length) {
        if (!payloadMood.length) {
          state.track.moodRelation = [];
        } else {
          const removeIndex = trackMood.findIndex(({ mood }) => !payloadMood.some(item => item.mood[0].id === mood[0].id));
          state.track.moodRelation.splice(removeIndex, 1);
        }
      } else {
        return;
      }
    },
    updateTrackGenreUuid(state, { prevUuid, uuid }: { prevUuid: string; uuid: string }) {
      const { genreRelation } = state.track;
      const genreIndex = genreRelation.findIndex(({ uuid }) => uuid === prevUuid);
      genreRelation[genreIndex].uuid = uuid;
    },
    updateTrackMoodUuid(state, { prevUuid, uuid }: { prevUuid: string; uuid: string }) {
      const { moodRelation } = state.track;
      const moodIndex = moodRelation.findIndex(({ uuid }) => uuid === prevUuid);
      moodRelation[moodIndex].uuid = uuid;
    },
    setTrackChannel(state, channel: number) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", channel });
      } else {
        trackInformation[0].channel = channel;
      }
    },
    setTrackDuration(state, duration: number) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", duration });
      } else {
        trackInformation[0].duration = duration;
      }
    },
    setTrackBirate(state, bitrate: number) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", bitrate });
      } else {
        trackInformation[0].bitrate = bitrate;
      }
    },
    setTrackTempo(state, tempo: number) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", tempo });
      } else {
        trackInformation[0].tempo = tempo;
      }
    },
    setTrackTonality(state, tonality: string) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", tonality });
      } else {
        trackInformation[0].tonality = tonality;
      }
    },
    setTrackVoiceGender(state, voiceGender: GenderType) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", voiceGender });
      } else {
        trackInformation[0].voiceGender = voiceGender;
      }
    },
    setTrackTimeSignature(state, timeSignature: string) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", timeSignature });
      } else {
        trackInformation[0].timeSignature = timeSignature;
      }
    },
    setTrackTimeExtraData(state, extraData: string) {
      const { trackInformation } = state.track;
      if (!trackInformation.length) {
        trackInformation.push({ uuid: "fake_uuid", extraData });
      } else {
        trackInformation[0].extraData = extraData;
      }
    }
  }
});
