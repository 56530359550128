import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly askPrice: number;
  readonly officialPrice: number;
};

export const GoodsPrice = ({ askPrice, officialPrice }: Props): ReactElement => (
  <>
    <Style.Row>
      <Style.Caption>매입가 *</Style.Caption>
      <Style.SubCaption>ex. 판매자의 판매가격책정에 도움을 줍니다. (0원일 경우 미정으로 노출됩니다.)</Style.SubCaption>
      <Style.Inputs
        type="number"
        key={`askprice-${askPrice}`}
        placeholder={"매입가를 입력해주세요."}
        defaultValue={askPrice}
        step={1000}
        min={0}
        onBlur={e => GoodsCreateStore.dispatch(GoodsCreateActions.setAskPrice(+e.target.value))}
      />
    </Style.Row>
    <Style.Row>
      <Style.Caption>판매 원가 *</Style.Caption>
      <Style.SubCaption>ex. 0원일 경우 -으로 노출됩니다.</Style.SubCaption>
      <Style.Inputs
        type="number"
        key={`officialprice-${officialPrice}`}
        placeholder={"원가 정보를 입력해주세요."}
        defaultValue={officialPrice}
        step={1000}
        min={0}
        onBlur={e => GoodsCreateStore.dispatch(GoodsCreateActions.setOfficialPrice(+e.target.value))}
      />
    </Style.Row>
  </>
);
