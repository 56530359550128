import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly details: string;
};

export const GoodsDetail = ({ details }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>상세 정보</Style.Caption>
    <Style.SubCaption>ex. * 초도한정으로 제공되는 SELFIE PHOTOCARD의 이미지는 버전별 구분없이 동일합니다.</Style.SubCaption>
    <Style.InputTextArea
      placeholder={"상세 정보"}
      defaultValue={details}
      onBlur={event => GoodsCreateStore.dispatch(GoodsCreateActions.setDetail(event.target.value))}
    />
    <Style.AlertCaption>상세정보를 입력해주세요.</Style.AlertCaption>
  </Style.Row>
);
