import classNames from "classnames";
import React, { useState } from "react";
import ReactSelect from "react-select";
import styled from "styled-components";

import { identity } from "utils/identity";

type Value = string;
type Label = string;
type Extra = any;

export type Info = Readonly<{
  id: Value;
  name: Label;
  isDisabled?: boolean;
  extra?: Extra;
}>;

type Props = Readonly<{
  style?: import("react-select/src/styles").Styles;
  classNamePrefix?: string;
  className?: string;
  isDisabled?: boolean;
  defaultValue?: null | Info;
  value?: null | Info;
  placeholder?: string;
  optionList: readonly Info[];
  align?: "left" | "center";
  isClearable?: boolean;
  onChange?: (info: null | Info) => void;
  onBlur?: (info: null | Info) => void;
}>;

const Layout = styled(ReactSelect)`
  &.left > div > div {
    justify-content: left;
  }
  &.center > div > div {
    justify-content: center;
  }
`;

export const TextSelect = ({
  style,
  className,
  classNamePrefix,
  isDisabled = false,
  defaultValue = null,
  value,
  placeholder,
  optionList,
  align = "left",
  isClearable,
  onChange = identity,
  onBlur = identity
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const change: ReactSelect<Info>["props"]["onChange"] = nextInfo => {
    setInfo(nextInfo as Info);
    onChange(nextInfo as Info);
  };
  const blur = () => onBlur(info);
  return (
    <Layout
      style={style}
      styles={style}
      className={classNames(className, { [align]: align })}
      classNamePrefix={classNamePrefix}
      menuPosition={"fixed"}
      isDisabled={isDisabled}
      isOptionDisabled={(info: Info) => info.isDisabled}
      defaultValue={defaultValue}
      value={value}
      isMulti={false}
      isClearable={isClearable}
      getOptionValue={(info: Info) => info.id}
      getOptionLabel={(info: Info) => {
        return info.extra ? `${info.name} (${info.extra})` : info.name;
      }}
      placeholder={placeholder}
      options={optionList}
      onChange={change}
      onBlur={blur}
    />
  );
};
