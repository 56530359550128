/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  structureId: string;
  parentId?: number;
  bookingOrder?: number;
  rootId?: number;
}>;

export const UpdateMetadataStructure = async (option: Option) => {
  const $structureId = <Variable name="structureId" scalar={<IdScalar />} value={option.structureId} />;
  const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable />} value={option.parentId} />;
  const $bookingOrder = <Variable name="booking_order" scalar={<IntScalar isNullable />} value={option.bookingOrder} />;
  const $rootId = <Variable name="root_id" scalar={<IntScalar isNullable />} value={option.rootId} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_STRUCTURE">
      <SelectionSet
        name="updateMetadata_structure"
        alias="updateMetadataStructure"
        argument={{
          where: { structure_id: $structureId },
          data: {
            parent_id: $parentId,
            booking_order: $bookingOrder,
            structure_root_id: $rootId
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataStructure: Readonly<{
      metadataId: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
