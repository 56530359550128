/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  metadataId: string;
  genreId?: string;
  validCheck: ValidType;
}>;

export const CreateMetadataGenre = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $genreId = <Variable name="genre_id" scalar={<IdScalar />} value={option.genreId} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_GENRE">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataGenre"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            _onlySearch: true,
            metadata_genre_relation: {
              create: {
                genre: {
                  connect: {
                    genre_id: $genreId
                  }
                },
                valid_check: $validCheck
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <SelectionSet name="metadata_genre_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createMetadataGenre: Readonly<{
      metadataId: string;
      metadata_genre_relation: {
        uuid: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
