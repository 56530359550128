import React from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  size?: string;
};

export const AnimatedCheckIcon = ({ size }: Props) => (
  <Layout size={size}>
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="fff" />
      <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </Layout>
);

const stroke = keyframes`
  100% {
      stroke-dashoffset: 0;
    }
`;

const scale = keyframes`
  0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
`;
const fill = keyframes`
   100% {
      box-shadow: inset 0px 0px 0px 30px #2196f3;
    }
`;

const Layout = styled.div<{ size?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  .checkmark {
    width: ${props => (props.size ? props.size : "56px")};
    height: ${props => (props.size ? props.size : "56px")};
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #2196f3;
    animation: ${fill} 0.4s ease-in-out 0.4s forwards, ${scale} 0.3s ease-in-out 0.9s both;
  }

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #2196f3;
    fill: none;
    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
`;
