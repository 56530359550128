import React from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { RoleStoreProvider } from "./Store";
import { RoleForm } from "./RoleForm";
import { HEADER_HEIGHT_PX } from "constants/size";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  font-size: 0.9rem;

  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT_PX});
`;

const RoleCreateLayout = () => (
  <ResponsiveToSideNavigatorTemplate>
    <Layout>
      <RoleForm />
    </Layout>
  </ResponsiveToSideNavigatorTemplate>
);

export const RoleCreate = () => (
  <RoleStoreProvider>
    <Helmet title="역할 등록" />
    <RoleCreateLayout />
  </RoleStoreProvider>
);
