/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
  typeTitle: string;
  value: string;
  languageCode: string;
}>;

export const UpdatePlaylistTitleRelation = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar />} value={option.id} />;
  const $typeTitle = <Variable name="typeTitle" scalar={<StringScalar />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_PLAYLIST_TITLE">
      <SelectionSet
        name="updatePlaylist_title"
        alias="updatePlaylistTitle"
        argument={{
          where: {
            id: $id
          },
          data: {
            type_title: $typeTitle,
            value: $value,
            language: {
              connect: {
                language_code: $languageCode
              }
            }
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updatePlaylistTitle: {
      id: string;
    };
  }>;

  return await clients.playlist.request<Data>(query, variables);
};
