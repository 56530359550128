import { SingleUpload } from "App/Routes/SingleAlbumCreate/Query/s3SingleUpload";

export const createFormData = (singleUpload: SingleUpload, file: Blob | File) => {
  const formData = new FormData();
  formData.append("key", `${singleUpload.key}`);
  formData.append("Policy", singleUpload.Policy);
  formData.append("X-Amz-Credential", singleUpload.X_Amz_Credential);
  formData.append("X-Amz-Date", singleUpload.X_Amz_Date);
  formData.append("X-Amz-Signature", singleUpload.X_Amz_Signature);
  formData.append("X-Amz-Algorithm", singleUpload.X_Amz_Algorithm);
  formData.append("file", file);

  return formData;
};
