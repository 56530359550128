/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, StringScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";
type Option = Readonly<{
  no: string;
  title: string;
  class: string;
  subClass: string;
  artistId?: string;
  companyId: string;
  workIds?: string[];
  mp3Url?: string;
  aacUrl?: string;
  flacUrl?: string;
  wavUrl?: string;
  parentId: number;
  recordYear: number;
  publishYear: number;
  firstEdition: number;
}>;

export const CreateSingleTrack = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $title_no_space = (
    <Variable name="title_no_space" scalar={<StringScalar isNullable={false} />} value={option.title.replace(/\s/gi, "")} />
  );
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $artistId = <Variable name="artistId" scalar={<IdScalar isNullable />} value={option.artistId} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.companyId} />;
  const $mp3Url = <Variable name="mp3url" scalar={<StringScalar isNullable={false} />} value={option.mp3Url} />;
  const $aacUrl = <Variable name="aacUrl" scalar={<StringScalar isNullable={false} />} value={option.aacUrl} />;
  const $flacUrl = <Variable name="flacUrl" scalar={<StringScalar isNullable={false} />} value={option.flacUrl} />;
  const $wavUrl = <Variable name="wavUrl" scalar={<StringScalar isNullable={false} />} value={option.wavUrl} />;
  const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable={false} />} value={option.parentId} />;
  const $recordYear = <Variable name="recordYear" scalar={<IntScalar />} value={option.recordYear} />;
  const $publishYear = <Variable name="publishYear" scalar={<IntScalar />} value={option.publishYear} />;
  const $firstEdition = <Variable name="firstEdition" scalar={<IntScalar />} value={option.firstEdition} />;
  const $rightCompany = <MetadataRightScalar value={MetadataRightType.rightCompany} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_TRACK_LOG">
      <SelectionSet
        name="createMetadata"
        alias="createTrack"
        argument={{
          data: {
            no: $no,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title_no_space,
                  exposure_order: 1
                }
              ]
            },
            metadata_self_relations_list_metadata: {
              create: [
                ...(option.workIds?.map((id, order) => ({
                  type_function: "works",
                  metadata_self_relations_element_metadata: {
                    connect: {
                      metadata_id: id
                    }
                  },
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_artist_relation: {
              create: [
                !option.artistId
                  ? {}
                  : {
                      artist: {
                        connect: {
                          artist_id: $artistId
                        }
                      },
                      role: {
                        connect: {
                          role_id: "343"
                        }
                      }
                    }
              ]
            },
            track_license_extra: {
              create: [
                {
                  record_year: $recordYear,
                  publish_year: $publishYear,
                  first_edition: $firstEdition
                }
              ]
            },
            metadata_url: {
              create: [
                !option.mp3Url
                  ? undefined
                  : {
                      type_url: "mp3high",
                      url: $mp3Url
                    },
                !option.aacUrl
                  ? undefined
                  : {
                      type_url: "aac",
                      url: $aacUrl
                    },
                !option.flacUrl
                  ? undefined
                  : {
                      type_url: "flac",
                      url: $flacUrl
                    },
                !option.wavUrl
                  ? undefined
                  : {
                      type_url: "wav",
                      url: $wavUrl
                    }
              ]
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $rightCompany,
                  exposure_order: 0
                }
              ]
            },
            metadata_structure: {
              create: { parent_id: $parentId }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata_url">
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createTrack: Readonly<{
      id: string;
      metadata_url: {
        uuid: string;
        typeUrl: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
