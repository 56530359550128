import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./Styles";
import { ModalProvider } from "lib/modal";
import { Routes } from "./Routes";
import { AppStoreProvider } from "./Store";
import { GraphQLClientProvider } from "utils/clients";
import { AudioPlayer } from "./Molecules/AudioPlayer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const App = () => (
  <BrowserRouter>
    <ModalProvider>
      <AppStoreProvider>
        <GraphQLClientProvider>
          <Routes />
          <AudioPlayer />
          <ToastContainer />
        </GraphQLClientProvider>
      </AppStoreProvider>
    </ModalProvider>
  </BrowserRouter>
);
