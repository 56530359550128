/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id?: string;
}>;

type Result = Readonly<{
  id: string;
  company: {
    id: string;
  }[];
}>;

export const LoadGoodsCompanyId = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="LOAD_GOODS_COMPANY_ID">
      <SelectionSet
        name="goods"
        argument={{
          group: true,
          where: {
            goods_id: $id
          }
        }}
      >
        <Field name="goods_id" alias="id" />
        <SelectionSet name="goods_company_relation" alias="company">
          <Field name="company_id" alias="id" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    goods: Result[];
  }>;
  const { data } = await clients.goods.request<Data>(query, variables);
  return data!.goods[0].company[0].id;
};
