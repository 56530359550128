/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar, MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";

type Option = Readonly<{
  metadataId: string;
  title?: string;
  artistId?: string;
  artistUuid?: string;
  companyId?: string;
  order?: number;
  typeKind?: MetadataRightType;
}>;

export const UpdateAlbum = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={true} />} value={option.title} />;
  const $artistId = <Variable name="artist_id" scalar={<IdScalar isNullable={true} />} value={option.artistId} />;
  const $artistUuid = <Variable name="artist_uuid" scalar={<UuidScalar isNullable={true} />} value={option.artistUuid} />;
  const $companyId = <Variable name="company_id" scalar={<IdScalar isNullable={true} />} value={option.companyId} />;
  const $typeKind = <MetadataRightScalar isNullable={true} value={option.typeKind} />;
  const $order = <Variable name="order" scalar={<IntScalar isNullable={true} />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="EDIT_INPUT_QUERY">
      <SelectionSet
        name="updateMetadata"
        alias="updateAlbum"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            title: $title,
            ...(option.artistId &&
              option.artistUuid && {
                _onlySearch: true,
                metadata_artist_relation: {
                  update: [
                    {
                      where: {
                        id: $artistUuid
                      },
                      data: {
                        artist: {
                          connect: {
                            artist_id: $artistId
                          }
                        }
                      }
                    }
                  ]
                }
              }),
            ...(option.companyId &&
              option.typeKind && {
                _onlySearch: true,
                metadata_company_relation: {
                  create: {
                    exposure_order: $order,
                    type_kind: $typeKind,
                    company: {
                      connect: {
                        company_id: $companyId
                      }
                    }
                  }
                }
              })
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <SelectionSet name="metadata_company_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateAlbum: Readonly<{
      metadataId: string;
      metadata_company_relation: {
        uuid: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
