import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";

type Props = {
  readonly askQuantity: number;
};

export const GoodsQuantity = ({ askQuantity }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>매입 수량 (개) *</Style.Caption>
    {/* <Style.SubCaption>ex. 판매자의 판매가격책정에 도움을 줍니다. (0원일 경우 미정으로 노출됩니다.)</Style.SubCaption> */}
    <Style.InputNumber
      type="number"
      key={`askQuantity-${askQuantity}`}
      placeholder={"수량"}
      defaultValue={askQuantity}
      step={1}
      min={0}
      onBlur={e => GoodsDetailStore.dispatch(GoodsDetailActions.setAskQuantity(+e.target.value))}
    />
  </Style.Row>
);
