import React from "react";
import { FilterGroup, Filters } from "../..";
import { ArtistTagAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";

type Props = {
  workArtist?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const WorkArtistFilter = ({ workArtist, setFilters }: Props) => (
  <>
    <FilterGroup>
      <h4>작품 아티스트</h4>
      <span className="info">여러 명의 아티스트를 선택할 수 있습니다.</span>
      <ArtistTagAutoComplete
        defaultValue={workArtist?.length ? workArtist.map(({ id, name }) => ({ id, name: window.decodeURIComponent(name) })) : undefined}
        onChange={info => {
          if (info) {
            const list = info.map(({ id, name }) => ({ id, name: window.encodeURIComponent(name) }));
            setFilters(filter => ({ ...filter, workArtist: list }));
          }
        }}
      />
    </FilterGroup>
  </>
);
