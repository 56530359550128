import React, { useRef } from "react";
import { useHistory, Prompt } from "react-router";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { Pagination } from "App/Molecules/Pagination";
import { useAppStore } from "App/Store";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { BLACK } from "constants/baseColor";
import { GENRES } from "constants/route";
import { HEADER_HEIGHT_PX, pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_LARGE_PX } from "constants/size";
import { useQueryParams } from "lib/use-query-params";
import { useGenresSelector } from "../Store";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { LoadingAction } from "App/Store/Loading";
import { useAsyncEffect } from "lib/use-async-effect";
import { CardList } from "./CardList";
import { configs } from "configs";
import defaultImage from "assets/images/cover.png";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

const Layout = styled.div<{ weight: number }>`
  display: inherit;
  flex-direction: column;
  overflow: auto;
  text-align: center;

  width: 100%;

  table {
    width: 100%;
    margin-top: ${props => (props.weight ? HEADER_HEIGHT_PX : "3rem")};
  }

  & form {
    align-self: right;
  }
`;

const SearchBar = styled(Input.Group)`
  position: relative;
  min-width: ${pixelize(UNIT * 22)};
  margin-left: auto;
  grid-template-columns: auto min-content;

  & > svg {
    position: absolute;
    top: 10px;
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
  }

  input {
    color: ${BLACK};
    background-color: transparent;
    padding-left: ${PADDING_XX_LARGE_PX};
    margin-right: ${MARGING_LARGE_PX};
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: 0;
    transition: border-bottom 0.5s;
  }
`;

export const RightGenreTable = () => {
  const router = useHistory();
  const [{ loading }, dispatchApp] = useAppStore(store => ({
    loading: store.Loading.loading
  }));
  const queryParams = useQueryParams();
  const searchStringRef = useRef<HTMLInputElement>(null);
  const searchString = queryParams.get("q", { default: "" });
  const weight = queryParams.get("weight", { default: 1, cast: value => +value });
  const typeKind = queryParams.get("type", { default: "genre" });
  const id = queryParams.get("id", { default: undefined });
  const { edge, genres, parent, editMode } = useGenresSelector(store => ({
    edge: store.Genre.edge,
    genres: store.Genre.genres,
    parent: store.Genre.genres[0]?.genre_self_relation_child[0]?.parent[0] ?? undefined,
    editMode: store.Genre.editMode
  }));
  const genresRef = useRef(genres);
  genresRef.current = genres;

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const genreSearch = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const searchStr = searchStringRef.current!.value.trim();
    if (searchStr === searchString) {
      return;
    }
    dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
    const encodeString = encodeURIComponent(searchStr);
    router.push(`?page=1&type=${typeKind}&weight=${weight}${encodeString.length ? `&q=${encodeString}` : ""}${id ? `&id=${id}` : ""}`);
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        if (edge && !genresRef.current.length) {
          dispatchApp(LoadingAction.setLoading(LOADING.NULL));
        } else {
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      }
    },
    [edge]
  );
  return (
    <Layout weight={weight}>
      {editMode.size > 0 && <Prompt message="이전 페이지로 돌아가면 저장되지 않습니다. 정말로 돌아가시겠습니까?" />}
      <TopbarTemplate openSidebar={openSidebar}>
        <Input.Button
          onClick={() => {
            router.push(`${GENRES}?page=1&weight=1&type=${typeKind === "genre" ? "mood" : "genre"}`);
          }}
        >
          {`${typeKind === "genre" ? "무드" : "장르"} 전체 보기`}
        </Input.Button>
        <form onSubmit={genreSearch}>
          <SearchBar>
            <SearchIcon />
            <Input.Text ref={searchStringRef} placeholder="검색어를 입력하세요."></Input.Text>
            <Input.Button type="submit" color="default">
              검색
            </Input.Button>
          </SearchBar>
        </form>
      </TopbarTemplate>
      {weight === 0 && parent && (
        <DetailLayout>
          <Wrapper>
            <figure className="container">
              {!parent.genreUrl.length ? (
                <img src={defaultImage} alt="Genre Cover" />
              ) : (
                <img src={`${configs.urls.image}/${parent.genreUrl[0].url}?mode=s`} alt="Genre Cover" />
              )}
              <span>{parent.name}</span>
            </figure>
          </Wrapper>
        </DetailLayout>
      )}
      {loading === LOADING.ONLOAD && <CardList weight={weight} genres={genres} />}
      <LoadingTemplate loading={loading} searchString={searchString} />
      {weight === 1 && (
        <Pagination
          edge={edge}
          goTo={index =>
            `${GENRES}?page=${index}${weight ? `&weight=${weight}` : ""}${typeKind ? `&type=${typeKind}` : ""}${
              searchString ? `&q=${encodeURIComponent(searchString)}` : ""
            }`
          }
        />
      )}
    </Layout>
  );
};

const DetailLayout = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  figure {
    width: 100%;
    height: 25rem;
    overflow: hidden;
    position: relative;
    float: left;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.1);
    filter: brightness(0.8);
    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      z-index: 2;
      color: #fff;
      font-size: 3rem;
      font-weight: 550;
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  background-color: #fff;
  overflow: hidden;
`;
