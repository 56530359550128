import { useState } from "react";
import { LoadCommodity } from "GraphQL/Queries/Goods";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { ProductModel } from "models/ProductModel";
import { ProductStore } from "../Store";
import { ProductActions } from "../Store/Product";
import { useQueryParams } from "lib/use-query-params";

type Result = { loading: boolean };
const PRODUCT_DEFAULT_COUNT = 20;

export const useLoadProduct = (): Result => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const goodsId = queryParams.get("goodsId", { default: undefined });
  const searchIdIn = queryParams.get("ids", { default: undefined });
  const searchBarcode = queryParams.get("barcode", { default: undefined });
  console.log(searchBarcode);
  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const { data, errors } = await LoadCommodity({
        first: PRODUCT_DEFAULT_COUNT,
        page,
        goodsId,
        idIn: !searchIdIn?.length ? undefined : (JSON.parse(searchIdIn) as number[]),
        barcode: searchBarcode
      });
      if (errors) {
        console.log(errors);
        throw new Error(errors[0].message);
      }
      const { product, edge } = data!;
      const productMs = product.map(({ id, goodsId, goods, barcode, quality, price, createdAt, urls }) => {
        const displayName = goods[0]?.name ?? "-";
        return ProductModel.from({
          id,
          goodsId,
          displayName,
          barcode,
          quality,
          price,
          createdAt,
          urls
        });
      });
      ProductStore.dispatch([ProductActions.setProduct(productMs), ProductActions.setEdge(edge)]);
    } catch (err) {
      console.log(err);
      Toast.error("상품 목록을 불러올 수 없습니다.");
    } finally {
      setLoading(false);
    }
  }, [page, goodsId, searchIdIn, searchBarcode]);

  return { loading };
};
