import { initializeKeyValue } from "lib/initialize-key-value";
import { Head, Subhead, sanitizeHeads } from "./sanitizeHeads";
import { createDuck } from "lib/store/v2";
import { ARTIST_FILTER, ARTIST_VC_FILTER, ARTIST_ROLE_FILTER, ARTIST_TYPE_FILTER } from "constants/storageKey";
import { sanitizeVCs, ValidCheck } from "./sanitizeVCs";
import { sanitizeType, TypeArtistClass } from "./sanitizeType";

type State = ReturnType<typeof createInitialState>;

type Role = Readonly<{
  id: string;
  name: string;
}>;

const createInitialState = () => {
  return {
    ...loadHeads(),
    ...loadValidCheck(),
    ...loadRoleCheck(),
    ...loadTypeCheck()
  };
};

const afterChange = (state: State) => {
  const { head, subhead, validCheck, roleCheck, typeCheck } = state;
  window.localStorage[ARTIST_FILTER] = JSON.stringify({
    Head: head,
    Subhead: subhead
  });
  window.sessionStorage[ARTIST_VC_FILTER] = JSON.stringify({
    ValidCheck: validCheck
  });
  window.sessionStorage[ARTIST_ROLE_FILTER] = JSON.stringify({
    RoleCheck: roleCheck
  });
  window.sessionStorage[ARTIST_TYPE_FILTER] = JSON.stringify({
    TypeCheck: typeCheck
  });
};

export const ArtistInfoActions = createDuck({
  namespace: "ArtistInfo",
  createInitialState,
  reducers: {
    setHead(state, head: Head) {
      state.head = head;
    },
    setSubhead(state, subhead: Subhead) {
      state.subhead = subhead;
    },
    setValidCheck(state, validCheck: ValidCheck) {
      state.validCheck = validCheck;
    },
    setRoleCheck(state, roleCheck: Role[]) {
      state.roleCheck = roleCheck;
    },
    setTypeCheck(state, typeCheck: TypeArtistClass) {
      state.typeCheck = typeCheck;
    }
  },
  afterChange
});

const loadHeads = () => {
  try {
    const artistFilter = window.localStorage.getItem(ARTIST_FILTER);
    return sanitizeHeads(artistFilter ? JSON.parse(artistFilter) : {});
  } catch (e) {
    return sanitizeHeads({});
  }
};

const loadValidCheck = () => {
  try {
    const validCheck = window.sessionStorage.getItem(ARTIST_VC_FILTER);
    return sanitizeVCs(validCheck ? JSON.parse(validCheck) : {});
  } catch (error) {
    return sanitizeVCs({});
  }
};
const loadRoleCheck = () => {
  try {
    const roleCheck = window.sessionStorage.getItem(ARTIST_ROLE_FILTER);
    return sanitizeRoles(roleCheck ? JSON.parse(roleCheck) : ([] as Role[]));
  } catch (error) {
    return sanitizeRoles({});
  }
};
const loadTypeCheck = () => {
  try {
    const typeCheck = window.sessionStorage.getItem(ARTIST_TYPE_FILTER);
    return sanitizeType(typeCheck ? JSON.parse(typeCheck) : {});
  } catch (error) {
    return sanitizeType({});
  }
};

const sanitizeRoles = (option: Record<string, boolean>) => {
  const { RoleCheck: roleCheck } = initializeKeyValue(option, {
    RoleCheck: [] as Role[]
  });
  return { roleCheck };
};
