import React from "react";
import styled from "styled-components";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { PlaylistsActions } from "./Store/Playlist";
import { LeftSideBar } from "./LeftSidebar";
import { PlaylistsStoreProvider, usePlaylistsStore } from "./Store";
import { RightPlaylistTable } from "./RightPlaylistTable";
import { useAppStore } from "App/Store";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Helmet } from "App/Atomics/Helmet";
import { GetPlaylist } from "GraphQL/Queries/Playlist/GetPlaylist";
import { Toast } from "lib/toast";
import { GetPlaylistMetadataRelationCount } from "GraphQL/Queries/Playlist/GetPlaylistMetadataRelationCount";

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;

const ChildLayout = () => {
  const [{ order }, dispatch] = usePlaylistsStore(store => ({ order: store.Playlist.order }));
  const queryParams = useQueryParams();
  const [{ userRole }, dispatchApp] = useAppStore(store => ({
    userRole: store.UserToken.role
  }));

  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchKind = queryParams.get("kind", { default: undefined });
  const searchTrackId = queryParams.get("track_id", { default: undefined });
  const searchTrackTitle = queryParams.get("track_title", { default: undefined });
  const email = queryParams.get("email", { default: undefined });

  useAsyncEffect(
    async isMounted => {
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      if (isMounted()) {
        const { data, errors } = await GetPlaylist({
          page,
          id: searchId,
          name: searchTitle,
          kind: searchKind,
          trackId: searchTrackId,
          trackTitle: searchTrackTitle,
          userRole: userRole ?? undefined,
          email
        });
        if (errors && errors.length) {
          console.log(errors[0].message);
          Toast.error("플레이리스트를 가져오는데 실패하였습니다.", undefined, "top-center");
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
          return;
        }
        if (data) {
          const playlist = data.playlist;
          for (const list of playlist) {
            const id = list.id;
            const count = await GetPlaylistMetadataRelationCount({ id });
            list.count = count;
          }
          dispatch([PlaylistsActions.setPlaylist(playlist), PlaylistsActions.setEdge(data.edge)]);
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      }
    },
    [page, email, order, searchId, searchTitle, searchKind, searchTrackId, searchTrackTitle]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightPlaylistTable />
    </Layout>
  );
};

export const Playlist = () => (
  <PlaylistsStoreProvider>
    <Helmet title="플레이리스트" />
    <ChildLayout />
  </PlaylistsStoreProvider>
);
