/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, IdScalar, ListScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { TierTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  email: string;
  tier: string;
  owner?: number[];
}>;

export const SetAccess = async (option: Option) => {
  const $email = <Variable name="email" scalar={<IdScalar isNullable={false} />} value={option.email} />;
  const $tier = <Variable name="tier" scalar={<TierTypeScalar isNullable={false} />} value={option.tier} />;
  const $owner = <Variable name="owner" scalar={<ListScalar scalar={<IntScalar />} />} value={option.owner} />;
  const { query, variables } = render(
    <Mutation operationName="SET_ACCESS">
      <Field
        name="set_access"
        alias="setAccess"
        argument={{
          where: {
            email: $email,
            tier: $tier,
            owner: $owner
          }
        }}
      />
    </Mutation>
  );
  type Data = Readonly<{
    setAccess: boolean;
  }>;
  return await clients.access.request<Data>(query, variables);
};
