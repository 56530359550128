import React, { useCallback, useState } from "react";
import styled from "styled-components";
import uuidv4 from "uuid/v4";
import { pixelize, UNIT, PADDING_X_LARGE, MARGING_LARGE_PX } from "constants/size";
import { GRAY_2, GRAY_4, GRAY_6, BLUE_4, PINK_0 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as CirclePlayIcon } from "assets/icons/circle-play.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as NoDataIcon } from "assets/icons/no-data.svg";
import { Input } from "App/Atomics/Input";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useAsyncEffect } from "lib/use-async-effect";
import { DeletePlaylistRelation, GetPlaylistWithTrack } from "GraphQL/Queries/Playlist";
import { ServiceType } from "constants/ServiceType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Loading } from "App/Atomics/Loading";
import { DANGER_COLOR } from "constants/color";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import { useAppDispatch } from "App/Store";
import { usePlaylistDetailDispatch } from "App/Routes/PlaylistDetail/Store";
import { PlaylistDetailActions } from "App/Routes/PlaylistDetail/Store/PlaylistDetail";
import { Playlist } from "GraphQL/Queries/Playlist/GetPlaylistWithTrack";

type PlaylistTrack = {
  uuid: string;
  id: string;
  title: string;
  artist: string;
  url: string;
};

type Props = {
  id: string;
  kind?: string;
  serviceType?: ServiceType;
  toClose: () => void;
};

export const CheckDuplicatedModal = ({ id, kind, serviceType, toClose }: Props) => {
  if (!kind || !serviceType) {
    Toast.error("플레이리스트 정보를 가져올 수 없습니다.");
  }
  const [tracks, setTracks] = useState<PlaylistTrack[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const audioDispatch = useAppDispatch();
  const dispatch = usePlaylistDetailDispatch();

  const onDeleteTrack = useCallback(
    async (uuid: string) => {
      setLoading(true);
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      if (accessId) {
        try {
          const { errors } = await DeletePlaylistRelation({ uuid });
          if (errors) {
            throw new Error(errors[0].message);
          }
          setTracks(prevTrack => prevTrack!.filter(track => track.uuid !== uuid));
          dispatch(PlaylistDetailActions.deletePlaylistDetailTrackByUuid(uuid));
          Toast.primary("삭제되었습니다", undefined, "top-center");
        } catch (err) {
          console.log(err);
          Toast.error("트랙을 삭제할 수 없습니다.", undefined, "top-center");
          return;
        } finally {
          await DeleteAccessRecord({ id: accessId });
          setLoading(false);
        }
      }
    },
    [dispatch, id]
  );

  const onDeleteAllTrack = useCallback(async () => {
    setLoading(true);
    const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
    if (accessId) {
      try {
        const uuids = tracks!.map(({ uuid }) => uuid);
        const { errors } = await DeletePlaylistRelation({ uuids });
        if (errors) {
          throw new Error(errors[0].message);
        }
        setTracks([]);
        dispatch(PlaylistDetailActions.deletePlaylistDetailTrackByUuids(uuids));
        Toast.primary("삭제되었습니다", { timeout: 500 }, "top-center");
      } catch (err) {
        console.log(err);
        Toast.error("트랙을 삭제할 수 없습니다.", undefined, "top-center");
        return;
      } finally {
        await DeleteAccessRecord({ id: accessId });
        setLoading(false);
      }
    }
  }, [dispatch, id, tracks]);

  const playAudio = (audio: TypeAudio) => {
    audioDispatch([AudioPlayerActions.toggleVisible(true), AudioPlayerActions.setAudioData(audio)]);
  };

  const fetchDuplicatedTrack = useCallback(
    async (idNot: string, idList: string[], count: number, skip: number, list: Playlist[]): Promise<Playlist[]> => {
      try {
        const { data: duplicatedTracks, errors: duplicatedErr } = await GetPlaylistWithTrack({
          first: count,
          idNot,
          idList,
          kind,
          serviceType
        });
        if (duplicatedErr) {
          throw duplicatedErr;
        }
        if (!list.length) {
          list = duplicatedTracks!.playlist;
        } else {
          list[0].metadataPlaylistRelation = list[0].metadataPlaylistRelation.concat(
            duplicatedTracks!.playlist[0].metadataPlaylistRelation
          );
        }
        if (duplicatedTracks!.playlist[0].metadataPlaylistRelation.length === 100) {
          return fetchDuplicatedTrack(idNot, idList, count, skip + count, list);
        }
        return list;
      } catch (err) {
        console.log(err);
        return list;
      }
    },
    [kind, serviceType]
  );

  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const { data: currentPlaylistData, errors: currentPlaylistErr } = await GetPlaylistWithTrack({
          id,
          first: 9999
        });
        if (currentPlaylistErr) {
          throw new Error(currentPlaylistErr[0].message);
        }
        if (currentPlaylistData) {
          const trackIdList: PlaylistTrack[] = currentPlaylistData.playlist[0].metadataPlaylistRelation.map(({ uuid, metadata }) => ({
            uuid,
            id: metadata[0].id,
            title: metadata[0].title,
            artist: metadata[0].artistRelation[0].artist[0].name,
            url: metadata[0].metadataUrl.filter(({ typeUrl }) => typeUrl === "mp3high")[0].url
          }));
          const idList = trackIdList.map(({ id }) => id);
          const playlist = await fetchDuplicatedTrack(id, idList, 100, 0, []);
          const duplicatedIdList: string[] = [];
          for (const list of playlist) {
            const tempList = list.metadataPlaylistRelation.map(({ metadata }) => metadata[0].id);
            duplicatedIdList.push(...tempList);
          }
          setTracks(trackIdList.filter(({ id }) => duplicatedIdList.includes(id)));
        }
      } catch (err) {
        console.log(err);
        Toast.error("트랙 목록을 불러오는데 실패하였습니다.", undefined, "top-center");
        return;
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return (
    <Layout>
      <Header>
        <h2>{`중복 트랙 목록 - ${tracks?.length ?? 0}개`}</h2>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>

      <Section>
        {!tracks || !tracks.length ? (
          <NoData>
            <NoDataIcon />
            <span>중복된 트랙이 없습니다.</span>
          </NoData>
        ) : (
          <TrackContainer>
            <TransitionGroup>
              {tracks.map(track => {
                const { uuid, id, title, artist, url } = track;
                const audioId = uuidv4();
                return (
                  <CSSTransition key={uuid} timeout={500} transitionName="item" classNames="item">
                    <ListItem key={uuid}>
                      <div className="thumb-play" onClick={() => playAudio({ title, url, type: "mp3high", uuid: audioId })}>
                        <CirclePlayIcon />
                      </div>
                      <div className="info-area">
                        <span className="title">{`[${id}] ${title}`}</span>
                        <span className="artist">{artist}</span>
                      </div>
                      <div className="trash">
                        <TrashIcon onClick={() => onDeleteTrack(uuid)} />
                      </div>
                    </ListItem>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </TrackContainer>
        )}
      </Section>

      <ButtonContainer>
        <Input.Button color="danger" disabled={!tracks || !tracks.length} isWide onClick={onDeleteAllTrack}>
          최대 300개의 중복트랙을 삭제합니다. 초과 시 삭제 후 다시 불러와야합니다.
        </Input.Button>
      </ButtonContainer>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 50)};
`;

const Header = styled.div`
  width: 100%;
  height: 4.2rem;
  display: flex;
  background-color: #000;
  color: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  padding: ${pixelize(PADDING_X_LARGE * 1.4)};
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
    font-weight: bold;
  }
  .cancelIcon {
    fill: #fff;
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    transition: all 0.1s;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_4};
    }
  }
`;

const Section = styled.section`
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  .list-wrap {
    position: absolute;
    top: 4rem;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .no-track {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
`;

const TrackContainer = styled.div`
  width: 100%;
  height: 44.7rem;
  padding: 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;

  .item-exit {
    opacity: 1;
    transform: translate(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transition-property: transform, opacity;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
  }
  .item-exit.item-exit-active {
    opacity: 0;
    transform: translate(400px, 0);
    transform: translate3d(400px, 0, 0);
  }
`;

const ListItem = styled.li`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  user-select: none;
  transition: background-color 0.15s;
  &:hover {
    background-color: ${PINK_0};
  }
  .thumb-play {
    display: table-cell;
    width: 72px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 36px;
      height: 36px;
      fill: #141414;
      vertical-align: middle;
      transition: fill 0.15s;
      &:hover {
        fill: ${BLUE_4};
      }
    }
  }

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      white-space: normal;
    }
    .title {
      height: 22px;
      line-height: 22px;
      color: #191919;
      font-size: 0.9rem;
    }
    .artist {
      height: 17px;
      line-height: 17px;
      font-size: 13px;
      color: ${GRAY_6};
    }
  }

  .trash {
    display: table-cell;
    width: 68px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 1.2rem;
      height: 1.2rem;
      transition: fill 0.15s;
      &:hover {
        fill: ${DANGER_COLOR};
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`;

const NoData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 44.7rem;
  svg {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
  }
  span {
    font-size: 0.9rem;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  }
`;
