import { createDuck } from "lib/store/v2";

export type CsvData = {
  track_id: string[];
  name: string;
};

const createInitialState = () => ({
  csvData: [] as readonly CsvData[]
});

export const CsvDataActions = createDuck({
  namespace: "CsvData",
  createInitialState,
  reducers: {
    setCsvData(state, csvData: CsvData[]) {
      state.csvData = csvData;
    }
  }
});
