import { initializeKeyValue } from "lib/initialize-key-value";

export type TypeArtistClass = ReturnType<typeof sanitizeType>["typeCheck"];

export const sanitizeType = (option: Record<string, boolean>) => {
  const { TypeArtistClass: typeCheck } = initializeKeyValue(option, {
    TypeArtistClass: {
      person: true,
      channel: true,
      organization: true
    }
  });
  return { typeCheck };
};
