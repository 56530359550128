export const TypeUrlOptionList = [
  { id: "mp3high", name: "mp3high" },
  { id: "aac", name: "aac" },
  { id: "flac", name: "flac" },
  { id: "wav", name: "wav" },
  { id: "zip", name: "zip" },
  { id: "txt", name: "txt" },
  { id: "source", name: "source" },
  { id: "youtube", name: "youtube" }
];
