import { TRACK_FILTER, TRACK_COUNT } from "constants/storageKey";
import { createDuck } from "lib/store/v2";
import { Head, sanitizeHeads, Subhead } from "./sanitizeHeads";

type State = ReturnType<typeof createInitialState>;

const createInitialState = () => {
  return {
    ...loadHeads(),
    count: loadTrackCount()
  };
};

const afterChange = (state: State) => {
  const { head, subhead, count } = state;
  window.localStorage[TRACK_FILTER] = JSON.stringify({
    Head: head,
    Subhead: subhead
  });
  window.localStorage[TRACK_COUNT] = JSON.stringify({
    count: count
  });
};

export const TableInfoActions = createDuck({
  namespace: "WorkTrackEdit",
  createInitialState,
  reducers: {
    setHead(state, head: Head) {
      state.head = head;
    },
    setSubhead(state, subhead: Subhead) {
      state.subhead = subhead;
    },
    setTrackCount(state, count: number) {
      state.count = count;
    }
  },
  afterChange
});

const loadHeads = () => {
  try {
    const trackFilter = window.localStorage.getItem(TRACK_FILTER);
    return sanitizeHeads(trackFilter ? JSON.parse(trackFilter) : {});
  } catch {
    return sanitizeHeads({});
  }
};

const loadTrackCount = () => {
  try {
    const trackCount = window.localStorage.getItem(TRACK_COUNT);
    return trackCount ? parseInt(JSON.parse(trackCount).count) : 10;
  } catch (e) {
    console.log(e);
    return 10;
  }
};
