import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { CardItem } from "./CardItem";
import { Artist } from "GraphQL/Queries/Artist/GetArtistRenewal";

type Props = {
  artists: Artist[];
};

const Layout = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${HEADER_HEIGHT_PX};
  padding: 2em;
  padding-bottom: 0;
`;

export const CardList = ({ artists }: Props) => {
  return (
    <Layout>
      {artists.map((artist: Artist, index) => {
        return <CardItem key={artist.id} index={index} {...artist} />;
      })}
    </Layout>
  );
};
