import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { GrantKey } from "App/Routes/AuthGrant/Store/Grant";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import React from "react";
import { FilterItem, OptionButton } from "../../formStyle";

export const ColumnFilter = () => {
  const [{ mode }, dispatch] = useAuthGrantStore(store => store.IdList);

  const onChangeMode = (mode: GrantKey) => {
    dispatch(IdActions.setMode(mode));
  };
  return (
    <FilterItem>
      <div className="item-label">
        <span>컬럼</span>
      </div>
      <div className="item-option-wrapper">
        <div className="item-option">
          <OptionButton isActive={mode === GrantKey.TRACK} onClick={() => onChangeMode(GrantKey.TRACK)}>
            {"트랙"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={mode === GrantKey.ARTIST} onClick={() => onChangeMode(GrantKey.ARTIST)}>
            {"아티스트"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={mode === GrantKey.GENRE} onClick={() => onChangeMode(GrantKey.GENRE)}>
            {"장르 / 무드"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={mode === GrantKey.PLAYLIST} onClick={() => onChangeMode(GrantKey.PLAYLIST)}>
            {"플레이리스트"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={mode === GrantKey.COMPANY} onClick={() => onChangeMode(GrantKey.COMPANY)}>
            {"권리사"}
          </OptionButton>
        </div>
      </div>
    </FilterItem>
  );
};
