/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, EnumScalar, Variable, IntScalar, IdScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id?: string;
  name?: string;
  page: number;
  weight: number;
  typeKind: string;
  first?: number;
}>;

export type Genre = {
  id: string;
  name: string;
  typeKind: string;
  validCheck: string;
  detail: string;
  weight: number;
  genreUrl: {
    uuid: string;
    url: string;
    typeUrl: string;
    validCheck: string;
    order: number;
  }[];
  genre_self_relation_child: {
    id: string;
    parent: Genre[];
  }[];
  genre_self_relation_parent: {
    id: string;
    child: {
      id: string;
      name: string;
    }[];
  }[];
};

export const GetGenre = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={true} />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $page = <Variable name="page" scalar={<IntScalar />} value={option.page} />;
  const $weight = <Variable name="weight" scalar={<IntScalar />} value={option.weight} />;
  const $typeKind = <Variable name="type_kind" scalar={<StringScalar />} value={option.typeKind} />;
  const $first = <Variable name="first" scalar={<IntScalar isNullable={true} />} value={option.first} />;

  const { query, variables } = render(
    <Query operationName="GET_GENRE">
      <SelectionSet
        name="genre"
        argument={{
          first: $first,
          page: $page,
          group: true,
          orderBy: <EnumScalar type="GenreOrderByInput" value="genre_id__ASC" items={["genre_id__ASC"]} />,
          where: {
            type_kind: $typeKind,
            weight: $weight,
            genre_self_relation_child__some: {
              genre_self_relation_parent__some: {
                genre_id: $id
              }
            },
            OR: [
              { genre_title__some: { tsvec_words__search: $name, type_genre_title__starts_with: "name" } },
              {
                genre_self_relation_parent__some: {
                  genre_self_relation_child__some: { genre_title__some: { tsvec_words__search: $name } }
                }
              }
            ]
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <Field name="name" />
        <Field name="type_kind" alias="typeKind" />
        <Field name="valid_check" alias="validCheck" />
        <Field name="detail" />
        <Field name="weight" />
        <SelectionSet
          name="genre_url"
          alias="genreUrl"
          argument={{
            orderBy: [
              <EnumScalar type="Genre_urlOrderByInput" value="type_url__ASC" items={["type_url__ASC"]} />,
              <EnumScalar type="Genre_urlOrderByInput" value="exposure_order__ASC" items={["exposure_order__ASC"]} />
            ]
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="url" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet name="genre_self_relation_child">
          <Field name="id" />
          <SelectionSet name="genre_self_relation_parent" alias="parent">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
            <Field name="type_kind" alias="typeKind" />
            <Field name="valid_check" alias="validCheck" />
            <Field name="detail" />
            <Field name="weight" />
            <SelectionSet
              name="genre_url"
              alias="genreUrl"
              argument={{
                orderBy: [
                  <EnumScalar type="Genre_urlOrderByInput" value="type_url__ASC" items={["type_url__ASC"]} />,
                  <EnumScalar type="Genre_urlOrderByInput" value="exposure_order__ASC" items={["exposure_order__ASC"]} />
                ]
              }}
            >
              <Field name="id" alias="uuid" />
              <Field name="url" />
              <Field name="type_url" alias="typeUrl" />
              <Field name="valid_check" alias="validCheck" />
              <Field name="exposure_order" alias="order" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="genre_self_relation_parent">
          <Field name="id" />
          <SelectionSet name="genre_self_relation_child" alias="child">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          first: $first,
          node: "genre",
          page: $page,
          where: {
            genre: {
              type_kind: $typeKind,
              weight: $weight,
              genre_self_relation_child__some: {
                genre_self_relation_parent__some: {
                  genre_id: $id
                }
              },
              OR: [
                { genre_title__some: { tsvec_words__search: $name, type_genre_title__starts_with: "name" } },
                {
                  genre_self_relation_parent__some: {
                    genre_self_relation_child__some: { genre_title__some: { tsvec_words__search: $name } }
                  }
                }
              ]
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = {
    genre: Genre[];
    edge: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
      currentPage: number;
      pageCount: number;
    };
  };
  return await clients.genre.request<Data>(query, variables);
};
