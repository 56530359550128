import { MoodRelation } from "App/Organisms/TagModal/services/loadMoodList";
import { createDuck } from "lib/store/v2";

type Company = {
  id: string;
  name: string;
};
type Production = Company;
type Publication = Company;

export type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

export enum UploadState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  DONE = "DONE",
  FAIL = "FAIL"
}

export type Effect = {
  tempId?: string;
  no?: string;
  title?: string;
  genre: Genre[];
  trackUrl: {
    trackMp3: Url;
    trackWav: Url;
  };
  record?: string;
  publish?: string;
  edition?: string;
  isUploaded?: UploadState;
};

type Album = {
  no?: string;
  title?: string;
  typeClass: string;
  typeSubclass: string;
  rightsCompany?: Company;
  production?: Production[];
  publication?: Publication[];
  albumUrl?: Url;
  releaseDate?: Date;
};

type Genre = {
  id: string;
  name: string;
  typeKind?: string;
  order?: number;
  relationList?: MoodRelation[];
};

const createInitialState = () => ({
  album: {
    typeClass: "record",
    typeSubclass: "album"
  } as Album,
  tracks: [] as Effect[]
});

export const EffectCreateActions = createDuck({
  namespace: "Effect",
  createInitialState,
  reducers: {
    setAlbumInit(state) {
      state.album = {
        typeClass: "record",
        typeSubclass: "album"
      };
    },
    setAlbumNo(state, no: string) {
      state.album.no = no;
    },
    setAlbumTitle(state, title: string | undefined) {
      state.album.title = title;
    },
    setAlbumCover(
      state,
      { file, value, ext, name, valueType }: { file: File; value: string; ext: string; name: string; valueType: string }
    ) {
      state.album.albumUrl = {
        file: file,
        ext: ext,
        typeUrl: valueType,
        data: value,
        url: name
      };
    },
    setAlbumRightsCompany(state, rightsCompany: Company) {
      state.album.rightsCompany = rightsCompany;
    },
    setAlbumPublication(state, publication: Company[]) {
      state.album.publication = publication;
    },
    setAlbumProduction(state, production: Company[]) {
      state.album.production = production;
    },
    setAlbumRelease(state, releaseDate: Date) {
      state.album.releaseDate = releaseDate;
    },
    setAlbumEffect(state, effect: Effect) {
      state.tracks.push(effect);
    },
    updateAlbumEffect(state, { index, effect }: { index: number; effect: Effect }) {
      state.tracks[index] = effect;
    },
    deleteAlbumEffect(state, index) {
      state.tracks.splice(index, 1);
    },
    setAlbumEffectTrackUrl(
      state,
      { index, file, fileType, data, url }: { index: number; file: File; fileType: string; data: string; url: string }
    ) {
      switch (fileType) {
        case "mp3high":
          state.tracks[index].trackUrl.trackMp3 = {
            file,
            typeUrl: "mp3high",
            ext: "mp3",
            data,
            url
          };
          break;
        case "wav":
          state.tracks[index].trackUrl.trackWav = {
            file,
            typeUrl: "wav",
            ext: "wav",
            data,
            url
          };
          break;
        default:
          break;
      }
    },
    setAlbumEffectUploaded(state, { index, isUploaded }: { index: number; isUploaded: UploadState }) {
      state.tracks[index].isUploaded = isUploaded;
    },
    setClear(state) {
      state.album = {
        typeClass: "record",
        typeSubclass: "album"
      };
      state.tracks = [] as Effect[];
    }
  }
});
