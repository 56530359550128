import React from "react";
import { FilterGroup, Filters } from "../..";
import { Input } from "App/Atomics/Input";
import { Info } from "App/Atomics/Input/Select/TextSelect";

type Props = {
  order?: string;
  optionList: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const OrderFilter = ({ order, optionList, setFilters }: Props) => {
  return (
    <FilterGroup>
      <h4>정렬 순서</h4>
      <span className="info">기본 값은 아이디 역순입니다.</span>
      <Input.TextSelect
        defaultValue={optionList.find(({ id }) => id === order) ?? { id: "metadata_id__DESC", name: "id_DESC" }}
        optionList={optionList}
        onChange={info => {
          if (info) {
            setFilters(filter => ({ ...filter, order: info.id }));
          }
        }}
      />
    </FilterGroup>
  );
};
