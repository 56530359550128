import React, { ReactNodeArray } from "react";
import styled from "styled-components";

type Align = "baseline" | "start" | "center" | "end";

type Props = Readonly<{
  className?: string;
  children: ReactNodeArray;
  isVertical?: boolean;
  isAlign?: Align;
}>;

const Layout = styled.div<{ children: ReactNodeArray; isAlign: Align }>`
  display: grid;
  grid-template-columns: repeat(${props => props.children.length}, 1fr);
  align-items: ${props => props.isAlign};
`;

export const Group = ({ className, children, isVertical = false, isAlign = "end" }: Props) => (
  <Layout className={className} isAlign={isAlign}>
    {children}
  </Layout>
);
