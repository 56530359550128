import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, UNIT, MARGING_LARGE_PX, PADDING_LARGE_PX, PADDING_X_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as AddToPlaylistIcon } from "assets/icons/add-to-playlist.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import { WHITE, GRAY_4, BLUE_6, GRAY_2, GRAY_1, GRAY_6 } from "constants/baseColor";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAppStore } from "App/Store";
import { AddTracksToPlaylist } from "GraphQL/Queries/Playlist";
import { useTracksStore } from "../../Store";
import { TrackActions } from "../../Store/Track";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreatePlaylistModal } from "./CreatePlaylistModal";
import { useHistory } from "react-router";
import { PLAYLIST_DETAIL } from "constants/route";
import { Loading } from "App/Atomics/Loading";
import { GetPlaylistMetadataRelationCount } from "GraphQL/Queries/Playlist/GetPlaylistMetadataRelationCount";
import { GetPlaylistInfoByEmail } from "GraphQL/Queries/Playlist/GetPlaylistInfoByEmail";
import { PlaylistInfo } from "GraphQL/Queries/Playlist/GetPlaylistInfo";
import { GetMetadataInPlaylist } from "GraphQL/Queries/Metadata/GetMetadataInPlaylist";
import { requestAccessRecord } from "lib/requestAccessRecord";

type Props = Readonly<{
  toClose: () => void;
}>;

export const PlaylistModal = ({ toClose }: Props) => {
  const [{ email }] = useAppStore(store => ({
    email: store.UserToken.email
  }));
  const [{ playlist, checkList }, dispatch] = useTracksStore(store => ({
    playlist: store.Track.playlist,
    checkList: Array.from(store.Track.checkList)
      .filter(list => list[1])
      .map(list => list[0])
  }));
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const router = useHistory();
  const confirmModal = useToggle();
  const createPlaylistModal = useToggle();

  const onMoveToPlaylist = (id: string) => {
    router.push(`${PLAYLIST_DETAIL}?id=${id}`);
  };
  const onInsertTrackToPlaylist = async (id: string, kind: string) => {
    try {
      setLoading(true);
      const result = await GetMetadataInPlaylist({ idList: checkList, playlistKind: kind });
      const duplicatedIdList = result.map(({ id }) => id);
      const filteredTrackList = checkList.filter(id => !duplicatedIdList.includes(id));
      if (!filteredTrackList.length) {
        setAlertMsg(`모든 음원이 중복됩니다.`);
      } else {
        const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
        await AddTracksToPlaylist({ id, trackInfo: filteredTrackList });
        await DeleteAccessRecord({ id: accessId! });
        dispatch(TrackActions.addTrackToPlaylist({ id, count: filteredTrackList.length }));
        setAlertMsg(`중복 음원을 제외한 ${filteredTrackList.length}개의 음원이 추가되었습니다.`);
      }
    } catch (err) {
      setAlertMsg("음원 추가에 실패하였습니다.");
      console.log(err);
    } finally {
      setLoading(false);
      confirmModal.on();
    }
  };

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      try {
        const playlistData = await GetPlaylistInfoByEmail({ email });
        for (const list of playlistData) {
          const count = await GetPlaylistMetadataRelationCount({ id: list.id });
          list.count = count ?? 0;
        }
        dispatch(TrackActions.setTrackPlaylist(playlistData as PlaylistInfo[]));
        setLoading(false);
      } catch (err) {
        console.log(err);
        return;
      }
    }
  }, []);

  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>
            <AddToPlaylistIcon />내 플레이리스트 추가
          </h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
      </Header>
      <NewPlaylistBox onClick={createPlaylistModal.on}>+ 새 플레이리스트 추가</NewPlaylistBox>
      {!playlist.length
        ? null
        : playlist.map(({ id, name, kind, count }) => {
            return (
              <ListBox key={id}>
                <div className="left">{id}</div>
                <div className="right">
                  <div className="right-top">
                    <span className="kind">[{kind}]</span>
                    <span className="name">{name}</span>
                  </div>
                  <span className="right-bottom">{count}곡</span>
                </div>
                <ButtonGroup>
                  <IconButton onClick={() => onInsertTrackToPlaylist(id, kind)}>
                    <PlusIcon />
                    <button>담기</button>
                  </IconButton>
                  <IconButton onClick={() => onMoveToPlaylist(id)}>
                    <ShareIcon />
                    <button>이동</button>
                  </IconButton>
                </ButtonGroup>
              </ListBox>
            );
          })}
      <Loading loading={loading} />
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm title="알림" context={alertMsg} toClose={confirmModal.off} />
      </Modal>
      <Modal isOpen={createPlaylistModal.isToggled}>
        <CreatePlaylistModal toClose={createPlaylistModal.off} />
      </Modal>
    </Layout>
  );
};

const colorAnim = keyframes`
  0% {
    background-color: ${WHITE};
  };
  50% {
    background-color: ${GRAY_1};
  };
  100% {
    background-color: ${GRAY_2};
  };
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 42)};
  height: ${pixelize(UNIT * 36)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  font-size: 0.9rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: #6a5fdd;
  color: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  width: 100%;
  display: flex;
  h3 {
    font-size: 1.1rem;
    svg {
      fill: #fff;
      width: ${pixelize(UNIT * 1.1)};
      height: ${pixelize(UNIT * 1.1)};
      margin-right: ${MARGING_LARGE_PX};
    }
  }
  .title {
    width: 100%;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: #fff;
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const ListBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
  grid-gap: ${MARGING_SMALL_PX};
  padding: ${PADDING_X_LARGE_PX};
  background-color: ${WHITE};
  animation: all 0.2s;
  border-bottom: 1px solid #ddd;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${MARGING_LARGE_PX};
  }
  .right {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .right-top {
      .name {
        margin-left: ${MARGING_SMALL_PX};
      }
      .kind {
        color: ${BLUE_6};
      }
    }
    .right-bottom {
      color: ${GRAY_6};
    }
  }

  &:hover {
    animation: ${colorAnim} 0.1s ease-in-out;
    background-color: ${GRAY_2};
  }
`;

const NewPlaylistBox = styled.div`
  height: ${pixelize(UNIT * 3.5)};
  margin: 4px 0;
  padding: ${PADDING_X_LARGE_PX};
  background-color: ${WHITE};
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    animation: ${colorAnim} 0.2s ease-in-out;
    background-color: ${GRAY_2};
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: ${MARGING_SMALL_PX};
`;

const IconButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  font-size: 0.8rem;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
    fill: #000;
    margin-bottom: 8px;
  }
  &:hover {
    color: ${GRAY_6};
    svg {
      fill: ${GRAY_6};
    }
  }
`;
