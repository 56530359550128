/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const DeleteAccessRecord = async (option: Option) => {
  const $id = <Variable name="deleteId" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_ACCESS_RECORD">
      <Field
        name="deleteAccess_record"
        alias="DeleteAccess"
        argument={{
          where: { id: $id }
        }}
      />
    </Mutation>
  );
  type Data = Readonly<{
    DeleteAccess: boolean;
  }>;
  return await clients.access.request<Data>(query, variables);
};
