import { CreateCommodity } from "GraphQL/Queries/Goods";
import { Time } from "lib/time";
import { formatUtcDateForGql } from "utils/format";
import { useProductCreateSelector } from "../Store";

export const useCreateProduct = () => {
  const { goods, quality, details: value, price, createdAt, subdatas } = useProductCreateSelector(store => store.ProductCreate.product);
  const subdataIds = subdatas.map(({ id }) => id);
  const createProduct = async () => {
    const { data, errors } = await CreateCommodity({
      goodsId: goods!.id,
      quality,
      value: value ?? "",
      price,
      subdatas: subdataIds,
      createdAt: formatUtcDateForGql(Time(createdAt))
    });
    return { data, errors };
  };
  return { createProduct };
};
