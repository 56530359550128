import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly name: string;
};

export const GoodsName = ({ name }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>상품명 *</Style.Caption>
    <Style.SubCaption>ex. 블랙핑크 - THE ALBUM / 1집 정규앨범</Style.SubCaption>
    <Style.InputText
      placeholder={"상품명"}
      defaultValue={name}
      onBlur={value => GoodsCreateStore.dispatch(GoodsCreateActions.setName(value))}
    />
    <Style.AlertCaption>상품명을 입력해주세요.</Style.AlertCaption>
  </Style.Row>
);
