import { createDuck } from "lib/store/v3";
import { GoodsArtist, GoodsCompany, GoodsSubdata, GoodsTitle, GoodsUrl, GoodsWork } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import _ from "lodash";
import { GoodsType } from "models/GoodsModel";

export type TypeInfo = {
  id: string;
  name: string;
  extra?: string;
};

export type GoodsParent = {
  id: string;
  order: number;
  goods: {
    id: string;
    name: string;
    parentId?: number;
  };
};

export type GoodsDetail = {
  id: string;
  name: string;
  titles: GoodsTitle[];
  type: TypeInfo;
  weight?: number;
  askPrice: number;
  askQuantity: number;
  officialPrice: number;
  officialBarcode?: string;
  createdAt: string;
  artists: GoodsArtist[];
  company: GoodsCompany;
  works: GoodsWork[];
  coverUrls: GoodsUrl[];
  detailUrls: GoodsUrl[];
  videoUrls: GoodsUrl[];
  officialUrls: GoodsUrl[];
  youtubeUrls: GoodsUrl[];
  subdatas: GoodsSubdata[];
  parents: GoodsParent[];
  isCollection: boolean;
};

const createInitialState = () => ({
  goodsTypes: [] as GoodsType[],
  goods: {} as GoodsDetail,
  origin: {} as GoodsDetail
});

export const GoodsDetailActions = createDuck({
  namespace: "GoodsDetail",
  createInitialState,
  reducers: {
    clear(state) {
      state.origin = state.goods;
    },
    reset(state) {
      state.goods = state.origin;
    },
    setGoodsTypes(state, goodsTypes: GoodsType[]) {
      state.goodsTypes = goodsTypes;
    },
    setGoods(state, goods: GoodsDetail) {
      state.goods = goods;
      state.origin = goods;
    },
    setName(state, value: string) {
      state.goods.name = value;
    },
    setOfficialBarcode(state, value: string) {
      state.goods.officialBarcode = value;
    },
    addArtist(state, id: string) {
      if (!state.goods.artists) {
        state.goods.artists = [{ uuid: id, order: 0, artist: [{ id: "", name: "" }] } as GoodsArtist];
        return;
      }
      const order = (_.last(state.goods.artists)?.order ?? 0) + 1;
      state.goods.artists.push({ uuid: id, order, artist: [{ id: "", name: "" }] } as GoodsArtist);
    },
    updateArtist(state, { index, artist }: { index: number; artist: GoodsArtist }) {
      state.goods.artists[index] = artist;
    },
    removeArtist(state, index: number) {
      state.goods.artists.splice(index, 1);
    },
    addWork(state, id: string) {
      if (!state.goods.works) {
        state.goods.works = [{ uuid: id, order: 0, metadata: [{ id: "", name: "" }] } as GoodsWork];
        return;
      }
      const order = (_.last(state.goods.works)?.order ?? 0) + 1;
      state.goods.works.push({ uuid: id, order, metadata: [{ id: "", name: "" }] } as GoodsWork);
    },
    updateWork(state, { index, work }: { index: number; work: GoodsWork }) {
      state.goods.works[index] = work;
    },
    removeWork(state, index: number) {
      state.goods.works.splice(index, 1);
    },
    addTitle(state, id: string) {
      if (!state.goods.titles) {
        state.goods.titles = [{ id, type: "name", languageCode: "OO", value: "", order: 0 } as GoodsTitle];
        return;
      }
      const order = (_.last(state.goods.titles)?.order ?? 0) + 1;
      state.goods.titles.push({ id, type: "name", languageCode: "OO", value: "", order } as GoodsTitle);
    },
    updateTitleType(state, { index, type }: { index: number; type: string }) {
      state.goods.titles[index].type = type;
    },
    updateTitleLanguageCode(state, { index, language }: { index: number; language: string }) {
      state.goods.titles[index].languageCode = language;
    },
    updateTitleValue(state, { index, value }: { index: number; value: string }) {
      state.goods.titles[index].value = value;
    },
    removeTitle(state, index: number) {
      state.goods.titles.splice(index, 1);
    },
    setType(state, type: TypeInfo) {
      state.goods.type = type;
    },
    setWeight(state, value: number) {
      state.goods.weight = value;
    },
    setAskPrice(state, value: number) {
      state.goods.askPrice = value;
    },
    setAskQuantity(state, value: number) {
      state.goods.askQuantity = value;
    },
    setOfficialPrice(state, value: number) {
      state.goods.officialPrice = value;
    },
    setArtists(state, info: GoodsArtist[]) {
      state.goods.artists = info;
    },
    setCompany(state, company: GoodsCompany) {
      state.goods.company = company;
    },
    setWorks(state, works: GoodsWork[]) {
      state.goods.works = works;
    },
    setCreatedAt(state, date: string) {
      state.goods.createdAt = date;
    },
    addCoverUrl(state, url: GoodsUrl) {
      state.goods.coverUrls.push(url);
    },
    updateCoverUrlOrder(state, { index, order }: { index: number; order: number }) {
      state.goods.coverUrls[index].order = order;
    },
    updateDetailUrlOrder(state, { index, order }: { index: number; order: number }) {
      state.goods.detailUrls[index].order = order;
    },
    updateVideoUrlOrder(state, { index, order }: { index: number; order: number }) {
      state.goods.videoUrls[index].order = order;
    },
    removeCoverUrl(state, index: number) {
      state.goods.coverUrls.splice(index, 1);
    },
    addDetailUrl(state, url: GoodsUrl) {
      state.goods.detailUrls.push(url);
    },
    removeDetailUrl(state, index: number) {
      state.goods.detailUrls.splice(index, 1);
    },
    addVideoUrl(state, url: GoodsUrl) {
      state.goods.videoUrls.push(url);
    },
    removeVideoUrl(state, index: number) {
      state.goods.videoUrls.splice(index, 1);
    },
    addOfficialUrl(state, id: string) {
      if (!state.goods.officialUrls) {
        state.goods.officialUrls = [{ id, typeUrl: "official" } as GoodsUrl];
        return;
      }
      const order = (_.last(state.goods.officialUrls)?.order ?? 0) + 1;
      state.goods.officialUrls.push({ id, typeUrl: "official", order } as GoodsUrl);
    },
    updateOfficialUrl(state, { index, url }: { index: number; url: string }) {
      state.goods.officialUrls[index].url = url;
    },
    removeOfficialUrl(state, index: number) {
      state.goods.officialUrls.splice(index, 1);
    },
    addYoutubeUrl(state, id: string) {
      if (!state.goods.youtubeUrls) {
        state.goods.youtubeUrls = [{ id, typeUrl: "youtube" } as GoodsUrl];
        return;
      }
      const order = (_.last(state.goods.youtubeUrls)?.order ?? 0) + 1;
      state.goods.youtubeUrls.push({ id, typeUrl: "youtube", order } as GoodsUrl);
    },
    updateYoutubeUrl(state, { index, url }: { index: number; url: string }) {
      state.goods.youtubeUrls[index].url = url;
    },
    removeYoutubeUrl(state, index: number) {
      state.goods.youtubeUrls.splice(index, 1);
    },
    addSubdata(state, id: string) {
      state.goods.subdatas.push({ id } as GoodsSubdata);
    },
    addSubdataList(state, list: GoodsSubdata[]) {
      state.goods.subdatas = [...state.goods.subdatas, ...list];
    },
    updateSubdataCategory(state, { index, category }: { index: number; category: string }) {
      state.goods.subdatas[index].category = category;
    },
    updateSubdataField(state, { index, field }: { index: number; field: string }) {
      state.goods.subdatas[index].field = field;
    },
    updateSubdataValue(state, { index, value }: { index: number; value: string }) {
      state.goods.subdatas[index].value = value;
    },
    removeSubdataField(state, index: number) {
      state.goods.subdatas.splice(index, 1);
    },
    addParent(state, id: string) {
      const order = (_.last(state.goods.parents)?.order ?? 0) + 1;
      console.group({ order });
      state.goods.parents.push({ id, goods: {}, order } as GoodsParent);
    },
    updateParentGoods(state, { index, id, name, parentId }: { index: number; id: string; name: string; parentId: number }) {
      state.goods.parents[index].goods = { id, name, parentId };
    },
    removeParent(state, index: number) {
      state.goods.parents.splice(index, 1);
    },
    toggleIsCollection(state) {
      state.goods.isCollection = !state.goods.isCollection;
    }
  }
});
