/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, EnumScalar, ListScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  metadataIdIn?: string[];
  typeUrl?: string;
}>;

export type MusicDownloadData = {
  metadataId: string;
  title: string;
  validCheck: ValidType;
  artistRelation: {
    artist: {
      id: string;
      name: string;
    }[];
  }[];
  metadataUrl: {
    url: string;
  }[];
  rightsCompany: {
    company: {
      id: string;
    }[];
  }[];
  order?: number;
};

export const GetMusicDownloadData = async (option: Option) => {
  const $metadataIdIn = (
    <Variable name="metadata_id__in" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={option.metadataIdIn} />
  );
  const $order = <EnumScalar type="MetadataOrderByInput" value={"metadata_id__ASC"} items={["metadata_id__ASC"]} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar />} value={option.typeUrl} />;
  const $rightsCompany = <MetadataRightScalar isNullable={true} value={MetadataRightType.rightCompany} />;
  const { query, variables } = render(
    <Query operationName="GET_MUSIC_DOWNLOAD_DATA">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 999,
          orderBy: $order,
          where: {
            metadata_id__in: $metadataIdIn,
            type_metadata_class__in: ["record", "theater"],
            type_metadata_subclass__in: ["track", "effect"]
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <Field name="title" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            first: 1,
            where: {
              role__some: {
                role_id: "343"
              }
            }
          }}
        >
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_url"
          alias="metadataUrl"
          argument={{
            where: {
              type_url: $typeUrl
            }
          }}
        >
          <Field name="url" />
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="rightsCompany"
          argument={{
            where: {
              type_kind: $rightsCompany
            }
          }}
        >
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: MusicDownloadData[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.metadata.map((arg, order) => ({ ...arg, order }));
};
