import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX, PADDING_X_LARGE_PX, PADDING_XX_LARGE_PX, pixelize, UNIT, MARGING_LARGE_PX } from "constants/size";
import { useAppStore } from "App/Store";
import { UserRole } from "constants/UserRole";
import { UserTokenActions } from "App/Store/UserToken";
import { SIGN } from "constants/route";
import { Link } from "App/Atomics/Link";
import { Input } from "App/Atomics/Input";
import { WHITE } from "constants/baseColor";
import { FONT_BASIC, PRIMARY_COLOR } from "constants/color";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { mediaQuery } from "constants/media";
import { AppStore } from "App/Store-v3";

type Props = Styleable & Readonly<{}>;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  position: fixed;
  padding: 0px ${PADDING_XX_LARGE_PX};

  min-height: ${HEADER_HEIGHT_PX};
  background-color: ${WHITE};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  z-index: 10;

  & > a,
  & > button {
    width: ${pixelize(7 * UNIT)};
    padding: ${PADDING_X_LARGE_PX};
    font-weight: bold;

    &:hover {
      opacity: 0.75;
    }
  }

  & > .localeSelect {
    width: ${pixelize(9 * UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    color: ${FONT_BASIC};
    font-size: 1.1rem;
    cursor: pointer;

    .locale__control {
      border: none;
      background-color: transparent;
    }

    .locale__menu {
      .locale__option {
        &--is-selected {
          background-color: ${PRIMARY_COLOR};
        }
      }
    }

    .locale__placeholder,
    .locale__input > input,
    .locale__single-value {
      color: ${FONT_BASIC};
      font-weight: bold !important;
    }

    .locale__indicator-separator {
      display: none;
    }

    .locale__indicator svg,
    .locale__dropdown-indicator svg {
      fill: ${FONT_BASIC};
    }
  }

  ${mediaQuery(1200)} {
    padding: 0;
    font-size: 0.9rem;
  }

  ${mediaQuery(768)} {
    font-size: 0.8rem;
  }

  .menu {
    fill: #333;
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const NotDrawerTopbarTemplate = ({ className, style }: Props) => {
  const [userInfo, dispatch] = useAppStore(store => {
    const { email, role } = store.UserToken;
    return {
      isSignedIn: email,
      isAdmin: role === UserRole.Master || role === UserRole.Owner,
      isArbeit: role === UserRole.Arbeit,
      isPartner: role === UserRole.Partner
    };
  });

  const signOut = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      dispatch(UserTokenActions.signOut());
      window.location.reload();
    }
  };

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  return (
    <Layout className={className} style={style}>
      <Input.Button style={{ marginRight: "auto" }} onClick={openSidebar}>
        <MenuIcon className="menu" />
      </Input.Button>
      {/* <Input.TextSelect
        className="localeSelect"
        classNamePrefix="locale"
        placeholder="LANGUAGE"
        optionList={[{ id: "KO", name: "KOREAN" }]}
      /> */}
      {userInfo.isSignedIn && <Input.Button onClick={signOut}>LOGOUT</Input.Button>}
      {!userInfo.isSignedIn && (
        <Link.Internal style={{ color: "#ff5252" }} to={SIGN}>
          LOGIN
        </Link.Internal>
      )}
    </Layout>
  );
};
