/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, ListScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { PlaylistOrderByInputScalar, MetadataPlaylistRelationOrderScalar, ValidTypeScalar, MetadataUrlOrderScalar } from "GraphQL/Scalars";
import { PlaylistOrderByInput } from "constants/PlaylistOrderByInput";
import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";
import { ValidType } from "constants/ValidType";
import { MetadataUrlOrderByInput } from "constants/MetadataUrlOrderByInput";
import { GenderType } from "constants/GenderType";

type Option = Readonly<{
  idList: string[];
  count?: number;
  skip?: number;
  csvType?: string;
}>;

export type Metadata = {
  metadataId: string;
  v1: string;
  no: string;
  title: string;
  validCheck: ValidType;
  subdataUnique: {
    value: string;
  }[];
  titleRelation: {
    name: string;
  }[];
  metadataUrl: {
    id: string;
    url: string;
    typeUrl: string;
  }[];
  genres: {
    id: string;
    genre: {
      genreId: string;
      name: string;
      typeKind: string;
    }[];
  }[];
  artists: {
    id: string;
    artist: {
      name: string;
      artist_history: {
        birth: string;
        death: string;
      }[];
      artist_title: {
        value: string;
      }[];
    }[];
    role: {
      name: string;
      typeRole: string;
    }[];
  }[];
  structures: {
    parent: {
      parentMetadata: {
        title: string;
        typeSubclass: string;
      }[];
    }[];
    list: {
      metadata: {
        title: string;
        typeSubclass: string;
      }[];
    }[];
  }[];
  license: {
    notice: string;
    licenseDate: string | null;
    validCheck: ValidType;
  }[];
  licenseExtra: {
    recordYear: number;
    publishYear: number;
    firstEdition: number;
    place: string;
  }[];
  metadata_self_relations_list_metadata: {
    metadata_self_relations_element_metadata: {
      title: string;
    }[];
  }[];
  track_information: {
    tempo: number;
    tonality: string;
    timeSignature: string;
    voiceGender: GenderType;
    extraData: string;
    bitrate: number;
    channel: number;
    duration: number;
  }[];
};

export type MetadataPlaylistRelation = {
  uuid: string;
  metadata: Metadata[];
};

export type PlaylistCsvData = {
  id: string;
  name: string;
  kind: string;
  service: string;
  validCheck: ValidType;
  metadataPlaylistRelation: MetadataPlaylistRelation[];
};

export const GetPlaylistCsvData = async (option: Option) => {
  const $idList = <Variable name="id" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={option.idList} />;
  const $validCheckNot = <Variable name="validCheckNot" scalar={<ValidTypeScalar />} value={ValidType.REMOVE} />;
  const $count = <Variable name="first" scalar={<IntScalar isNullable />} value={option.count} />;
  const $skip = <Variable name="skip" scalar={<IntScalar isNullable />} value={option.skip} />;
  const $urlOrder = (
    <Variable name="url_order" scalar={<ListScalar scalar={<MetadataUrlOrderScalar />} />} value={MetadataUrlOrderByInput.type_url_ASC} />
  );
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_CSV_DATA">
      <SelectionSet
        name="playlist"
        argument={{
          first: 999,
          group: true,
          orderBy: <PlaylistOrderByInputScalar value={PlaylistOrderByInput.playlist_id_DESC} />,
          where: {
            playlist_id__in: $idList,
            ...(option.csvType === "youtube" && {
              metadata_playlist_relation__some: {
                metadata__some: {
                  track_license__some: {
                    type_track__not: "PD"
                  }
                }
              }
            })
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="name" />
        <Field name="kind" />
        <Field name="service" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet
          name="metadata_playlist_relation"
          alias="metadataPlaylistRelation"
          argument={{
            group: true,
            first: !option.count ? 9999 : $count,
            skip: $skip,
            orderBy: <MetadataPlaylistRelationOrderScalar value={MetadataPlaylistRelationOrderByInput.exposure_order_ASC} />
          }}
        >
          <Field name="id" alias="uuid" />
          {/* METADATA */}
          <SelectionSet name="metadata">
            <Field name="metadata_id" alias="metadataId" />
            <Field name="compatibility_v1_id" alias="v1" />
            <Field name="no" />
            <Field name="title" />
            <Field name="valid_check" alias="validCheck" />
            {/* TITLE */}
            <SelectionSet
              name="metadata_title"
              alias="titleRelation"
              argument={{
                where: {
                  type_metadata_title: "name",
                  language__some: {
                    language_code: "KO"
                  }
                }
              }}
            >
              <Field name="value" alias="name" />
            </SelectionSet>
            {/* SUBDATA */}
            <SelectionSet name="metadata_subdata_unique" alias="subdataUnique" argument={{ where: { type_subdata_field: "source_data" } }}>
              <Field name="value" />
            </SelectionSet>
            {/* URL */}
            <SelectionSet
              name="metadata_url"
              alias="metadataUrl"
              argument={{ where: { valid_check__not: $validCheckNot }, orderBy: $urlOrder }}
            >
              <Field name="id" />
              <Field name="url" />
              <Field name="type_url" alias="typeUrl" />
            </SelectionSet>
            {/* GENRE */}
            <SelectionSet name="metadata_genre_relation" alias="genres" argument={{ where: { valid_check__not: $validCheckNot } }}>
              <Field name="id" />
              <SelectionSet name="genre">
                <Field name="genre_id" alias="genreId" />
                <Field name="name" />
                <Field name="type_kind" alias="typeKind" />
              </SelectionSet>
            </SelectionSet>
            {/* ARTIST */}
            <SelectionSet name="metadata_artist_relation" alias="artists" argument={{ where: { valid_check__not: $validCheckNot } }}>
              <Field name="id" />
              <SelectionSet name="artist">
                <Field name="name" />
                <SelectionSet name="artist_history">
                  <Field name="birth_date" alias="birth" />
                  <Field name="death_date" alias="death" />
                </SelectionSet>
                <SelectionSet
                  name="artist_title"
                  argument={{
                    where: {
                      type_artist_title: "name",
                      type_artist_title__not_starts_with: "search",
                      language__some: { language_code: "KO" }
                    }
                  }}
                >
                  <Field name="value" />
                </SelectionSet>
              </SelectionSet>
              <SelectionSet name="role">
                <Field name="name" />
                <Field name="type_role_class" alias="typeRole" />
              </SelectionSet>
            </SelectionSet>
            {/* STRUCTURE */}
            <SelectionSet name="metadata_structure" alias="structures">
              <SelectionSet name="parent">
                <SelectionSet name="metadata" alias="parentMetadata">
                  <Field name="title" />
                  <Field name="type_metadata_subclass" alias="typeSubclass" />
                </SelectionSet>
              </SelectionSet>
              <SelectionSet name="list">
                <SelectionSet name="metadata">
                  <Field name="title" />
                  <Field name="type_metadata_subclass" alias="typeSubclass" />
                </SelectionSet>
              </SelectionSet>
            </SelectionSet>
            {/* LICENSE */}
            <SelectionSet
              name="track_license"
              alias="license"
              argument={{
                where: {
                  country_code__in: ["KR", "ZZ"]
                }
              }}
            >
              <Field name="license_date" alias="licenseDate" />
              <Field name="notice" />
              <Field name="valid_check" alias="validCheck" />
            </SelectionSet>
            {/* EXTRA */}
            <SelectionSet name="track_license_extra" alias="licenseExtra">
              <Field name="record_year" alias="recordYear" />
              <Field name="publish_year" alias="publishYear" />
              <Field name="first_edition" alias="firstEdition" />
              <Field name="place" />
            </SelectionSet>
            {/* WORK */}
            <SelectionSet name="metadata_self_relations_list_metadata">
              <SelectionSet name="metadata_self_relations_element_metadata">
                <Field name="title" />
              </SelectionSet>
            </SelectionSet>
            {/* TRACK_INFORMATION */}
            <SelectionSet name="track_information">
              <Field name="tempo" />
              <Field name="tonality" />
              <Field name="time_signature" alias="timeSignature" />
              <Field name="voice_gender" alias="voiceGender" />
              <Field name="extra_data" alias="extraData" />
              <Field name="sample_bitrate" alias="bitrate" />
              <Field name="duration" />
              <Field name="channel" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    playlist: PlaylistCsvData[];
  }>;
  const { errors, data } = await clients.playlist.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  return data!.playlist;
};
