import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, UNIT, MARGING_SMALL_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useAsyncEffect } from "lib/use-async-effect";
import { GRAY_0, GRAY_2, RED_1 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as CrossIcon } from "assets/icons/circle-cancel.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/circle-refresh.svg";
import { AnimatedCheckIcon } from "App/Atomics/AnimatedCheckIcon";
import { useEffectCreateStore } from "../../Store";
import { CreateEffect } from "GraphQL/Queries/Metadata";
import { Toast } from "lib/toast";
import { handleCreateFile } from "./handleCreateFile";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { EffectCreateActions, UploadState } from "../../Store/Effect";
import { DeleteMetadata } from "GraphQL/Queries/Track";

type Props = Readonly<{
  onClose: () => void;
}>;

export const UploadEffectModal = ({ onClose }: Props) => {
  const [{ album, tracks }, dispatch] = useEffectCreateStore(store => store.EffectCreate);
  const [parentId, setParentId] = useState<number | undefined>(undefined);
  const [isDone, setIsDone] = useState<boolean>(false);

  const onCreateAlbum = async () => {
    try {
      const { data, errors } = await CreateEffect({
        class: "record",
        subClass: "album",
        no: album.no!,
        title: album.title!,
        rightsCompany: album.rightsCompany!,
        productions: album.production,
        publications: album.publication,
        urls: album.albumUrl?.url ? [album.albumUrl] : undefined,
        parentId: 0
      });
      if (errors || !data) {
        throw errors;
      }
      if (album.albumUrl?.url) {
        await handleCreateFile(
          data.createMetadata.metadata_id,
          data.createMetadata.metadata_url[0].id,
          album.albumUrl,
          album.rightsCompany!.id,
          "image/jpeg"
        );
      }
      const parentId = data!.createMetadata.metadata_structure[0].structure_id;
      setParentId(+parentId);
      return parentId;
    } catch (err) {
      console.log(err);
      Toast.error("앨범 생성에 실패하였습니다.");
      return;
    }
  };

  const onCreateEffect = async (parentId: number, index: number) => {
    try {
      dispatch(EffectCreateActions.setAlbumEffectUploaded({ index, isUploaded: UploadState.LOADING }));
      const effect = tracks[index];
      const no = index < 9 ? `0${index + 1}` : (index + 1).toString();
      const urls = [effect.trackUrl.trackMp3, effect.trackUrl.trackWav];
      const { data, errors } = await CreateEffect({
        class: "record",
        subClass: "effect",
        no,
        title: effect.title!,
        rightsCompany: album.rightsCompany!,
        productions: album.production,
        publications: album.publication,
        urls: urls,
        genreIds: effect.genre?.map(({ id }) => id),
        record: effect.record ? +effect.record : undefined,
        publish: effect.publish ? +effect.publish : undefined,
        edition: effect.edition ? +effect.edition : undefined,
        parentId
      });
      if (errors || !data) {
        throw errors;
      }
      const effectId = data.createMetadata.metadata_id;
      try {
        if (effect.trackUrl.trackMp3?.url) {
          const mp3 = data.createMetadata.metadata_url.find(({ type_url }) => type_url === "mp3high")!;
          await handleCreateFile(effectId, mp3.id, effect.trackUrl.trackMp3, album.rightsCompany!.id, "audio/mpeg");
        }
        if (effect.trackUrl.trackWav?.url) {
          const wav = data.createMetadata.metadata_url.find(({ type_url }) => type_url === "wav")!;
          await handleCreateFile(effectId, wav.id, effect.trackUrl.trackWav, album.rightsCompany!.id, "audio/wav");
        }
      } catch (err) {
        console.log(err);
        Toast.error("파일 업로드에 실패하였습니다.");
        dispatch(EffectCreateActions.setAlbumEffectUploaded({ index, isUploaded: UploadState.FAIL }));
        const accessId = await requestAccessRecord({ targetId: effectId, targetTable: TargetTableInput.Metadata });
        await DeleteMetadata({ id: effectId });
        await DeleteAccessRecord({ id: accessId! });
        return;
      }
      dispatch(EffectCreateActions.setAlbumEffectUploaded({ index, isUploaded: UploadState.DONE }));
      return data.createMetadata;
    } catch (err) {
      console.log(err);
      dispatch(EffectCreateActions.setAlbumEffectUploaded({ index, isUploaded: UploadState.FAIL }));
      return;
    }
  };

  const onClearAndClose = () => {
    onClose();
    window.location.reload();
  };

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      const parentId = await onCreateAlbum();
      if (!parentId) {
        Toast.error("앨범 정보를 가져올 수 없습니다.");
        return;
      }
      const accessId = await requestAccessRecord({ targetId: parentId.toString(), targetTable: TargetTableInput.Metadata });
      if (!accessId) {
        Toast.error("권한 신청에 실패하였습니다.");
        return;
      }

      const indexList = [...Array(tracks.length).keys()];
      for (const index of indexList) {
        await onCreateEffect(+parentId, index);
      }
      await DeleteAccessRecord({ id: accessId });
      setIsDone(true);
    }
  }, []);

  return (
    <Layout>
      <Header>
        <h3>효과음 생성</h3>
        {isDone && <CancelIcon className="cancelIcon" onClick={onClearAndClose} />}
      </Header>
      <Section>
        <ToolTipBox>
          <ul className="tip-box">
            <li>생성 도중 브라우저를 종료할 시 데이터가 손실될 위험이 있습니다.</li>
            <li>곡 수에 따라 많은 시간이 소요될 수 있습니다.</li>
            <li>실패한 음원은 종료 후, 재시도하실 수 있습니다.</li>
          </ul>
        </ToolTipBox>
        <ListForm>
          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  <th style={{ width: "80px" }}>번호</th>
                  <th>제목</th>
                  <th style={{ width: "80px" }}>상태</th>
                </tr>
              </thead>
              <tbody>
                {tracks.map(({ tempId, title, isUploaded }, index) => {
                  return (
                    <TrackRow key={tempId} state={isUploaded}>
                      <td>{index + 1}</td>
                      <td className="elipse">{title}</td>
                      <td>
                        {isUploaded === UploadState.FAIL ? (
                          <CrossIcon className="fail" onClick={() => onCreateEffect(parentId!, index)} />
                        ) : isUploaded === UploadState.DONE ? (
                          <AnimatedCheckIcon size={"1.3rem"} />
                        ) : (
                          <RefreshIcon className="spin" />
                        )}
                      </td>
                    </TrackRow>
                  );
                })}
              </tbody>
            </table>
          </div>
        </ListForm>
      </Section>
      <Divider>
        <div />
      </Divider>

      <ButtonGroup>
        {!isDone ? (
          <Input.Button className="btn" isFill={false} disabled color={"warning"}>
            업로드 중입니다...
          </Input.Button>
        ) : (
          <Input.Button className="btn" color="danger" isFill={false} onClick={onClearAndClose}>
            종료
          </Input.Button>
        )}
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  font-size: 0.9rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: ${pixelize(50 * UNIT)};
  height: ${pixelize(44 * UNIT)};
  align-items: center;
  padding: 1.5rem 1rem 0;
  h2 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const Header = styled.header`
  width: 100%;
  height: 4rem;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

  .cancelIcon {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    margin-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      fill: ${GRAY_2};
    }
  }
`;

const ToolTipBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
  .tip-box {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1rem;
    background-color: ${GRAY_0};
    li {
      list-style: inside;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem;
  .btn {
    width: 12rem;
    height: 3rem;
    border-radius: 20px;
  }
`;

const ListForm = styled.div`
  width: 100%;
  position: relative;
  .table-wrap {
    position: absolute;
    height: 550px;
    top: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      z-index: 10;
      tr {
        th {
          height: 48px;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          color: #606060;
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    tbody {
      overflow-y: scroll;
      tr {
        td {
          padding: 0 6px;
          height: 56px;
          border-bottom: 1px solid #f2f2f2;
          text-align: center;
        }
        .elipse {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
  }
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div {
    width: 100%;
    margin: 0 1rem;
    height: 1px;
    background-color: #ddd;
  }
`;

const spin = keyframes`
  0%  {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const TrackRow = styled.tr<{ state?: UploadState }>`
  transition: background-color 0.15s;
  background-color: ${props =>
    !props.state ? "#fff" : props.state === UploadState.LOADING ? "#fff9db" : props.state === UploadState.FAIL ? RED_1 : "#e3fafc"};
  &:hover {
    ${props => (!props.state ? `background-color: ${GRAY_0}` : "")}
  }

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 6px;
  }

  .fail {
    cursor: pointer;
  }

  .spin {
    animation: ${spin} 2s infinite linear;
  }
`;
