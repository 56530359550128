import fileSaver from "file-saver";
import { BillInfo } from "GraphQL/Queries/Access/GetAccount";
import { Parser } from "json2csv";

type DateInfo = {
  fromDate: string;
  toDate: string;
};

export const handleDownloadCsv = (billInfo: BillInfo, date: DateInfo) => {
  const { specificPlayedTracksEachCountPerMonth: trackData, product, specificplayedtrackscountpermonth: totalPlayCount } = billInfo;
  const totalCount = trackData.length;

  let csvData = trackData.map(data => {
    return {
      metadata_id: data.metadata_id.toString(),
      id_track: data.id_track,
      "재생 횟수": data.count,
      제목: data.title
    };
  });
  csvData.unshift({
    metadata_id: `총 ${totalCount}곡`,
    id_track: "-",
    "재생 횟수": `총 ${totalPlayCount}회`,
    제목: "-"
  });
  const parser = new Parser({
    fields: ["metadata_id", "id_track", "재생 횟수", "제목"]
  });
  const file = new Blob(["\ufeff", parser.parse(csvData)], { type: "text/csv;charset=utf-8;" });
  const fileName = `${product} [${date.fromDate}-${date.toDate}].csv`;
  fileSaver.saveAs(file, fileName);
  return;
};
