import React from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { pixelize, UNIT, MARGING_LARGE_PX } from "constants/size";
import { WHITE, GREEN_4, GREEN_2 } from "constants/baseColor";
import { ReactComponent as Upload } from "assets/icons/upload.svg";
import { useAsyncEffect } from "lib/use-async-effect";
import Papa from "papaparse";
import { CsvDataActions } from "App/Routes/PlaylistAutoCreate/Store/CsvUpload";
import { usePlaylistAutoCreateDispatch } from "App/Routes/PlaylistAutoCreate/Store";

type Props = {
  accept: string;
  text: string;
  fileType: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Layout = styled.div`
  width: ${pixelize(UNIT * 20)};
  height: ${pixelize(UNIT * 15)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 6px solid ${GREEN_2};
  background-color: ${WHITE};
  border-radius: 12px;
  outline: none;
  cursor: pointer;

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${GREEN_4};
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    cursor: pointer;

    span {
      margin-top: ${MARGING_LARGE_PX};
    }
  }
`;

export const CsvDropzone = ({ accept, text, fileType, onChange }: Props) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({ accept });
  const dispatch = usePlaylistAutoCreateDispatch();
  useAsyncEffect(
    async _ => {
      //드래그 앤 드랍
      if (acceptedFiles.length) {
        const csvData: Record<string, string>[] = [];
        let fields: string[] = [];
        Papa.parse(acceptedFiles[0], {
          worker: true,
          header: true,
          step: ({ data, errors, meta }) => {
            if (!fields.length && meta.fields) {
              fields = meta.fields;
            }
            if (errors.length) {
              window.alert("CSV파일을 읽기에 실패하였습니다.");
              return;
            }
            csvData.push((data as any) as Record<string, string>);
          },
          complete: _ => {
            const csvDataList = csvData.map(({ name, track_id }) => {
              return { name, track_id: track_id.split(",") };
            });
            dispatch([CsvDataActions.setCsvData(csvDataList)]);
          }
        });
      }
    },
    [acceptedFiles]
  );
  return (
    <Layout {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} onChange={onChange} />
      <label>
        <Upload fill={GREEN_4} />
        <span>{text}</span>
      </label>
    </Layout>
  );
};
