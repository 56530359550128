/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  id: string;
}>;

export type LyricsInfo = {
  id: string;
  title: string;
  artist?: string;
  cover?: string;
  lyrics?: string;
};

export const GetTrackLyrics = async (option: Option) => {
  const $id = <Variable name="track_id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_TRACK_LYRICS">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 1,
          where: {
            metadata_id: $id,
            type_metadata_class: "record",
            type_metadata_subclass: "track"
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet name="metadata_artist_relation" alias="artistRelation">
          <Field name="id" alias="uuid" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
          <SelectionSet name="role">
            <Field name="role_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="metadata_title" alias="lyrics" argument={{ where: { type_metadata_title: "lyrics" } }}>
          <Field name="id" />
          <Field name="value" />
        </SelectionSet>
        <SelectionSet name="metadata_structure" alias="structures">
          <SelectionSet name="parent">
            <SelectionSet name="metadata">
              <SelectionSet name="metadata_url" alias="metadataUrl">
                <Field name="id" alias="uuid" />
                <Field name="type_url" alias="typeUrl" />
                <Field name="url" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      title: string;
      artistRelation: {
        uuid: string;
        artist: {
          id: string;
          name: string;
        }[];
        role: {
          id: string;
          name: string;
        };
      }[];
      lyrics: {
        id: string;
        value: string;
      }[];
      structures: {
        parent: {
          metadata: {
            metadataUrl: {
              id: string;
              typeUrl: string;
              url: string;
            }[];
          }[];
        }[];
      }[];
    }[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  const result = data!.metadata.map(({ id, title, artistRelation, lyrics, structures }) => {
    const album = structures[0]?.parent[0]?.metadata[0]?.metadataUrl.filter(
      ({ typeUrl }) => typeUrl === "cover" || typeUrl === "thumbnail"
    );
    return { id, title, artist: artistRelation[0]?.artist[0]?.name, cover: album[0]?.url, lyrics: lyrics[0]?.value };
  }) as LyricsInfo[];
  return result[0];
};
