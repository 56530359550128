/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, render, Variable, Query, Field, StringScalar, SelectionSet, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  parentId: number;
  no: string;
}>;

export const CheckDuplicatedNo = async (option: Option) => {
  const $parentId = <Variable name="parentId" scalar={<IntScalar />} value={option.parentId} />;
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_NO">
      <SelectionSet
        name="metadata"
        argument={{
          where: {
            no: $no,
            metadata_structure__some: {
              parent_id: $parentId
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: ReadonlyArray<{
      id: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
