import { Time } from "lib/time";

export const formatUtcDateForGql = (time: Date | Time): string =>
  Time(time)
    .utc()
    .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
export const formatDuration = (seconds: number): string => {
  const hour = Math.floor(seconds / 3600);
  const min = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor(seconds % 60);

  let duration = "";

  hour > 0 && (duration += hour + ":");
  min < 10 && hour > 0 ? (duration += "0" + min + ":") : (duration += min + ":");
  sec < 10 ? (duration += "0" + sec) : (duration += sec);

  return duration;
};
