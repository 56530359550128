/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  value: string;
}>;

export const UpdateComment = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.uuid} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_COMMENT">
      <SelectionSet
        name="updateComment"
        argument={{
          where: { id: $uuid },
          data: { value: $value }
        }}
      >
        <Field name="id" />
        <Field name="timestamp" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateComment: Readonly<{
      id: string;
      timestamp: string;
    }>;
  }>;
  return await clients.access.request<Data>(query, variables);
};
