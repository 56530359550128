import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

export type Artist = {
  type_artist_class: string;
  name: string;

  artistCompany: Company;
  artistUrl: Url;
  ytvCode?: string;
};

type Company = {
  company_id: string;
  name: string;
  license: string;
};

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

export type ContentsState = "ARTIST" | "SUBMIT" | undefined;

const createInitialState = () => ({
  artist: {} as Artist,
  pageState: undefined as ContentsState
});

export const ArtistCreateActions = createDuck({
  namespace: "ArtistCreate",
  createInitialState,
  reducers: {
    setArtist(state, artist: Artist) {
      state.artist = artist;
    },
    setArtistName(state, name: string) {
      state.artist.name = name;
    },
    setArtistYtvCode(state, ytvCode: string) {
      state.artist.ytvCode = ytvCode;
    },
    setArtistType(state, typeClass: string) {
      state.artist.type_artist_class = typeClass;
    },
    setArtistCompany(state, company: Company) {
      state.artist.artistCompany = company;
    },
    setArtistThumb(
      state,
      { file, value, ext, name, valueType }: { file: File; value: string; ext: string; name: string; valueType: string }
    ) {
      state.artist.artistUrl = {
        file: file,
        ext: ext,
        typeUrl: valueType,
        data: value,
        url: name
      };
    },
    setPageState(state, pageState: ContentsState) {
      state.pageState = pageState;
    }
  }
});
