/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, EnumScalar, Variable, ListScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = {
  moodList: readonly string[];
};

export const GetMoodIncludeParent = async ({ moodList }: Option) => {
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const $moodList = <Variable name="mood_list" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={moodList} />;
  const { query, variables } = render(
    <Query operationName="GET_MOOD_INCLUDE_PARENT">
      <SelectionSet
        name="genre"
        alias="list"
        argument={{
          first: 999,
          group: true,
          orderBy: <EnumScalar type="GenreOrderByInput" value="genre_id__ASC" items={["genre_id__ASC"]} />,
          where: {
            type_kind: "mood",
            valid_check__not: $remove,
            OR: [
              {
                genre_id__in: $moodList
              },
              {
                genre_self_relation_parent__some: {
                  genre_self_relation_child__some: {
                    genre_id__in: $moodList
                  }
                }
              }
            ]
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <Field name="name" />
        <Field name="type_kind" alias="typeKind" />
      </SelectionSet>
    </Query>
  );
  type Data = {
    list: {
      readonly id: string;
      readonly name: string;
      readonly typeKind: string;
    }[];
  };
  const { data, errors } = await clients.genre.request<Data>(query, variables);
  if (!data || errors) {
    return [];
  }
  return data.list;
};
