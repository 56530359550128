import React from "react";
import styled from "styled-components";
import { BLUE_5, GRAY_6 } from "constants/baseColor";
import { ReactComponent as CDIcon } from "assets/icons/cd.svg";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { useAlbumStore } from "../../Store";
import { Page, SingleTrackActions } from "../../Store/SingleTrack";
import { MusicUploadForm } from "./Form/MusicUploadForm";
import { SingleTrackForm } from "./Form/SingleTrackForm";

type Props = {
  index: number;
  toClose: () => void;
};

export const AddTrackModal = ({ index, toClose }: Props) => {
  const [{ page }, dispatch] = useAlbumStore(store => store.SingleTrack);
  const onClearAndClose = () => {
    dispatch(SingleTrackActions.setSingleTrackClear());
    toClose();
  };
  return (
    <Layout>
      <Header>
        <CDIcon className="cd" />
        <h3>간편 트랙 등록</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      {page === Page.FIRST ? <MusicUploadForm /> : <SingleTrackForm index={index} toClose={onClearAndClose} />}
    </Layout>
  );
};

const Layout = styled.div`
  width: 1000px;
  height: 875px;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.9rem;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cd {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
    margin-top: 3px;
    fill: ${BLUE_5};
  }
  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;
