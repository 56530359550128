import React, { ReactNode } from "react";
import styled from "styled-components";

import { Link } from "App/Atomics/Link";
import { pixelize, UNIT, widthViewportize } from "constants/size";

type Props = Readonly<{
  to: string;
  isDisabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
}>;

const Layout = styled(Link.Internal)<{ isDisabled?: boolean }>`
  width: 100%;
  padding: ${pixelize(1.25 * UNIT)} ${widthViewportize(0.15 * UNIT)};
  text-align: left;
  opacity: 0.5;
  font-size: 1.1rem;
  transition: all 0.2s;

  svg {
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
    top: 4px;
    position: relative;
    margin-right: ${pixelize(0.5 * UNIT)};
  }
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};

  &:hover {
    opacity: 1;
  }
`;

export const LinkTab = ({ to, isDisabled, onClick, children }: Props) => (
  <Layout isDisabled={isDisabled} to={to} onClick={onClick}>
    {children}
  </Layout>
);
