import React, { useState, FormEvent, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { Input } from "App/Atomics/Input";
import { Pagination } from "App/Molecules/Pagination";
import { useAlbumStore } from "../Store";
import { ALBUMS, TRACKS } from "constants/route";
import { useHistory } from "react-router";
import { CardList } from "./CardList";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { AlbumActions } from "../Store/Album";
import { useQueryParams } from "lib/use-query-params";
import { Loading } from "App/Atomics/Loading";
import { FilterModalTemplate } from "App/Templates/FilterModalTemplate";
import { SearchForm } from "App/Organisms/SearchForm";
import { mediaQuery } from "constants/media";
import ContentLoader from "react-content-loader";
import { HEADER_HEIGHT_PX } from "constants/size";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { MergeAlbumModal } from "../Modal";

const Layout = styled.div`
  display: inherit;
  width: 100%;
  flex-direction: column;
  overflow: auto;
`;

const ToggleGroup = styled(Input.Group)`
  transform: scaleX(0.9);
  margin-right: 0.5rem;

  button {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;

  ${mediaQuery(765)} {
    display: none;
  }
`;

enum SearchMode {
  Album = "album",
  Track = "track"
}

const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "title", name: "타이틀" },
  { id: "artist", name: "아티스트" },
  { id: "no", name: "No" }
];

export const RightAlbumTable = ({ count }: { count: number }) => {
  const [{ albums, edge, loading, editLoading }, dispatch] = useAlbumStore(store => store.Album);
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.Album);
  const [screenX, setScreenX] = useState<number>(window.innerWidth);
  const router = useHistory();
  const filterModal = useToggle();
  const mergeAlbumModal = useToggle();
  const queryParams = useQueryParams();
  const searchIdList = queryParams.get("ids", { default: undefined });
  const filter = queryParams.get("filter", { default: undefined });
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);

  const getPageSearchType = () => {
    for (const { id } of SearchColumnList) {
      if (queryParams.get(id)) {
        return id;
      }
    }
    return undefined;
  };

  const onSearch = useCallback(
    (e: FormEvent<HTMLFormElement>, searchStr: string) => {
      e.preventDefault();
      if (searchStr.length <= 1) return;
      if (searchStr === getSearchString(searchType)) {
        return;
      }
      if (!searchStr) {
        router.push(`${ALBUMS}?page=1${!filter ? "" : `&filter=${filter}`}`);
        return;
      }
      dispatch(AlbumActions.setLoading(LOADING.UNLOAD));
      const matchedStr = searchStr.match(/[^ ,]+/g)?.map(v => +v);
      if (searchType === "id" && matchedStr!.length > 1) {
        router.push(
          `${searchMode === SearchMode.Track ? TRACKS : ALBUMS}?page=1${`&ids=[${matchedStr}]`}${!filter ? "" : `&filter=${filter}`}`
        );
      } else {
        router.push(
          `${searchMode === SearchMode.Track ? TRACKS : ALBUMS}?page=1&${searchType}=${searchStr}${!filter ? "" : `&filter=${filter}`}`
        );
      }
    },
    [dispatch, filter, getSearchString, router, searchMode, searchType]
  );

  const getPlaceholderWidth = useMemo(() => {
    if (screenX > 1024) {
      return screenX * 0.18;
    } else if (screenX > 768) {
      return screenX * 0.225;
    } else {
      return screenX * 0.45;
    }
  }, [screenX]);

  useEffect(() => {
    const handler = () => {
      setScreenX(document.body.clientWidth);
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <Input.Button onClick={() => router.push(`${TRACKS}?page=1`)}>트랙 이동</Input.Button>
        <Input.Button onClick={filterModal.on}>필터</Input.Button>
        <Input.Button onClick={mergeAlbumModal.on}>앨범 통합</Input.Button>
        <InputContainer>
          <ToggleGroup>
            <Input.Toggle
              color="primary"
              isActive={searchMode === SearchMode.Album}
              toggleValue="앨범"
              onClick={() => setSearchMode(SearchMode.Album)}
            />
            <Input.Toggle
              color="primary"
              isActive={searchMode === SearchMode.Track}
              toggleValue="트랙"
              onClick={() => setSearchMode(SearchMode.Track)}
            />
          </ToggleGroup>
          <SearchForm
            route={ALBUMS}
            searchType={searchType}
            onChangeSearchTypeInfo={info => setSearchType(info!.id)}
            onSearch={onSearch}
            optionColumnList={SearchColumnList}
          />
        </InputContainer>
      </TopbarTemplate>
      {loading === LOADING.UNLOAD && (
        <LoadingPlaceholder>
          {[...Array(count)].map((_, key) => (
            <Placeholder key={key} width={getPlaceholderWidth} />
          ))}
        </LoadingPlaceholder>
      )}
      {loading === LOADING.ONLOAD && <CardList albums={albums} />}
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      {loading === LOADING.ONLOAD && (
        <Pagination
          edge={edge}
          goTo={index => {
            const pageSearchType = getPageSearchType();
            return `${ALBUMS}?page=${index}${!searchIdList?.length ? "" : `&ids=${searchIdList}`}${
              !pageSearchType ? "" : `&${pageSearchType}=${getSearchString(pageSearchType)}`
            }${!filter ? "" : `&filter=${filter}`}`;
          }}
        />
      )}
      <Modal isOpen={filterModal.isToggled}>
        <FilterModalTemplate route={ALBUMS} toClose={filterModal.off} />
      </Modal>
      <Modal isOpen={mergeAlbumModal.isToggled}>
        <MergeAlbumModal onClose={mergeAlbumModal.off} />
      </Modal>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const LoadingPlaceholder = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${HEADER_HEIGHT_PX};
  justify-content: center;
  padding: 2em 0;
  svg {
    margin: 0 0.6em;
  }
  ${mediaQuery(768)} {
    svg {
      margin: 0 0.3em;
    }
  }
`;

const Placeholder = ({ width }: { width: number }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={width * 1.3}
    viewBox={`0 0 ${width} ${width * 1.3}`}
    backgroundColor="#f2f2f2"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="2" ry="2" width={width} height={width} />
    <rect x="0" y={width * 1.03} rx="2" ry="2" width={width * 0.2} height={width * 0.03} />
    <rect x="0" y={width * 1.07} rx="2" ry="2" width={width * 0.9} height={width * 0.03} />
    <rect x="0" y={width * 1.11} rx="2" ry="2" width={width * 0.3} height={width * 0.03} />
  </ContentLoader>
);
