/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  artistId: string;
  type: string;
  value: string;
  languageCode: string;
  order: number;
}>;

export const CreateDescArtist = async (option: Option) => {
  const $artistId = <Variable name="artistId" scalar={<IdScalar />} value={option.artistId} />;
  const $type = <Variable name="type_artist_title" scalar={<StringScalar isNullable={false} />} value={option.type} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_DESC_ARTIST">
      <SelectionSet
        name="updateArtist"
        alias="createDescArtist"
        argument={{
          where: {
            artist_id: $artistId
          },
          data: {
            _onlySearch: true,
            artist_title: {
              create: [
                {
                  type_artist_title: $type,
                  exposure_order: $order,
                  language: { connect: { language_code: $languageCode } },
                  value: $value
                }
              ]
            }
          }
        }}
      >
        <Field name="artist_id" alias="artistId" />
        <Field name="name" />
        <SelectionSet name="artist_title">
          <Field name="id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createDescArtist: {
      artistId: string;
      name: string;
      artist_title: {
        id: string;
      }[];
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
