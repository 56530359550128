import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { WorkDetailActions as WorkDetail } from "./WorkDetail";

const reducers = { WorkDetail };

const reducer = combineReducers(reducers, createStoreLogger("AlbumDetail"));

export const {
  StoreProvider: WorkDetailStoreProvider,
  useStore: useWorkDetailStore,
  useSelector: useWorkDetailSelector,
  useDispatch: useWorkDetailDispatch
} = createStore(reducer);
