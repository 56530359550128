import fileSaver from "file-saver";
import { Parser } from "json2csv";
import { Track } from "../Store/TrackList";

type Playlist = {
  track: Track[];
  name: string;
};

export const handlePlaylistInfoDownloadCsv = (playlist: readonly Playlist[]) => {
  let csvData = playlist.map(data => {
    return {
      name: data.name,
      track_id: data.track.map(({ id }) => id).join(",")
    };
  });
  const parser = new Parser({
    fields: ["name", "track_id"]
  });
  const file = new Blob(["\ufeff", parser.parse(csvData)], { type: "text/csv;charset=utf-8;" });
  const fileName = `플레이리스트 생성 결과 [${new Date().getTime()}].csv`;
  fileSaver.saveAs(file, fileName);
  return;
};
