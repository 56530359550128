/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const DeleteMetadata = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const OperationName = `DELETE_METADATA_${option.id}`;
  const { query, variables } = render(
    <Mutation operationName={OperationName}>
      <SelectionSet
        name="deleteMetadata"
        argument={{
          where: {
            metadata_id: $id
          }
        }}
      >
        <Field name="metadata_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteMetadata: Readonly<{
      metadata_id: string;
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
