import { Edge } from "models/Edge";
import { createDuck } from "lib/store/v2";
import { ValidType } from "constants/ValidType";

export type License = {
  id: string;
  notice: string;
  copyright?: boolean;
  neighboring?: boolean;
  country_code: string;
  license_date: string | null;
  publish_date: string | null;
  type_track: string;
  is_service?: number;
  validCheck?: ValidType;
};

export type Playlist = {
  id: string;
  name: string;
  kind: string;
  count: number;
  metadataPlaylistRelation: {
    metadata: {
      metadataId: string;
    }[];
    order?: number;
  }[];
};

const createInitialState = () => ({
  edge: null as null | Edge,
  tracks: [] as any[],
  license: [] as License[],
  modalId: "" as string,
  checkList: new Map<string, boolean>(),
  playlist: [] as Playlist[]
});

export const TrackActions = createDuck({
  namespace: "Tracks",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setTrack(state, tracks: any[]) {
      state.tracks = tracks;
    },
    setLicense(state, license: License[]) {
      state.license = license;
    },
    createLicense(state, license: License) {
      state.license.unshift(license);
    },
    updateLicense(
      state,
      {
        id,
        typeTrack,
        countryCode,
        notice,
        copyright,
        neighboring,
        licenseDate,
        publishDate,
        isService,
        validCheck
      }: {
        id: string;
        typeTrack: string;
        countryCode?: string;
        notice: string;
        copyright: boolean | undefined;
        neighboring: boolean | undefined;
        licenseDate: string | null;
        publishDate: string | null;
        isService: number | undefined;
        validCheck: ValidType | undefined;
      }
    ) {
      const idx = state.license.findIndex(item => item.id === id);
      state.license[idx].type_track = typeTrack;
      state.license[idx].notice = notice;
      state.license[idx].copyright = copyright;
      state.license[idx].neighboring = neighboring;
      state.license[idx].license_date = licenseDate;
      state.license[idx].publish_date = publishDate;
      state.license[idx].is_service = isService;
      state.license[idx].validCheck = validCheck;
      if (countryCode) {
        state.license[idx].country_code = countryCode;
      }
    },
    deleteLicense(state, id: string) {
      state.license = state.license.filter(item => item.id !== id);
    },
    setTrackCheckListInit(state, ids: string[]) {
      ids.forEach(id => {
        state.checkList.get(id) === undefined && state.checkList.set(id, false);
      });
    },
    setTrackCheckList(state, { id, checked }: { id: string; checked: boolean }) {
      state.checkList.set(id, checked);
    },
    clearTrackCheckList(state) {
      state.checkList.clear();
    },
    setLicenseModalId(state, id: string) {
      state.modalId = id;
    },
    setTrackPlaylist(state, playlists: Playlist[]) {
      state.playlist = playlists;
    },
    createTrackPlaylist(state, playlist: Playlist) {
      state.playlist.unshift(playlist);
    },
    addTrackToPlaylist(state, { id, count }: { id: string; count: number }) {
      const index = state.playlist.findIndex(item => item.id === id);
      state.playlist[index].count += count;
    },
    toggleTrackAllCopyright(state, toggleValue: boolean) {
      state.license = state.license.map(item => ({ ...item, copyright: toggleValue }));
    },
    toggleTrackAllNeighboring(state, toggleValue: boolean) {
      state.license = state.license.map(item => ({ ...item, neighboring: toggleValue }));
    },
    setTrackNotice(state, { id, notice }: { id: string; notice: string }) {
      const index = state.license.findIndex(item => item.id === id);
      state.license[index].notice = notice;
    }
  }
});
