import { createStore, combineReducers } from "lib/store";
import { afterChanges, afterDispatches } from "utils/store";
import { reducer as TableInfo } from "./TableInfo";
import { reducer as Track } from "./Track";

const reducers = { TableInfo, Track };

const reducer = combineReducers(reducers, afterDispatches, afterChanges);

export const {
  StoreProvider: TracksStoreProvider,
  useStore: useTracksStore,
  useSelector: useTracksSelector,
  useDispatch: useTracksDispatch
} = createStore(reducer);
