/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";
import { Artist } from "./GetMetadata";

type Option = Readonly<{
  metadataId: string;
  title?: string;
  validCheck?: ValidType;
  subClass?: string;
  structureId?: string;
  parentId?: number;
  artists?: Artist[];
}>;

export const UpdateMetadataSubClass = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $subClass = <Variable name="subClass" scalar={<StringScalar isNullable={true} />} value={option.subClass} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_SUBCLASS">
      <SelectionSet
        name="updateMetadata"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            type_metadata_subclass: $subClass
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadata: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
