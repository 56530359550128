import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsDetail/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { GoodsCompany as TypeGoodsCompany } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";

type Info = {
  id: string;
  name: string;
};

type Props = {
  readonly company: TypeGoodsCompany;
};

export const GoodsCompany = ({ company }: Props): ReactElement => {
  const isMaster = useAppSelector(store => store.UserToken.role === UserRole.Master);
  const onChange = (info: Info | null) =>
    info && GoodsDetailStore.dispatch(GoodsDetailActions.setCompany({ uuid: company.uuid, company: [info] }));
  return (
    <Style.Row>
      <Style.Caption>제조사</Style.Caption>
      <CompanyTextAutoComplete
        isDisabled={!isMaster}
        style={SelectStyle}
        defaultValue={company?.company[0]}
        placeholder="제조사를 입력해주세요"
        onBlur={onChange}
      />
    </Style.Row>
  );
};
