import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { createDuck } from "lib/store/v2";

type VideoUrl = {
  file: File;
  url: string;
};

export type VideoCreateState = {
  name?: string;
  artist: Info;
  role: Info;
  company: Info;
  url?: VideoUrl;
};

const createInitialState = (): VideoCreateState => ({
  artist: { id: "136495", name: "ARTISTSCARD-VIDEO" },
  role: { id: "343", name: "perform main" },
  company: { id: "58597", name: "ARTISTSCARD-VIDEO" }
});

export const VideoCreateActions = createDuck({
  namespace: "VideoCreate",
  createInitialState,
  reducers: {
    setName(state, name: string) {
      state.name = name;
    },
    setVideo(state, url: VideoUrl) {
      state.url = url;
    }
  }
});
