/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum FileActionType {
  INSERT = "INSERT",
  UPDATE = "UPDATE"
}

type Props = Readonly<{
  value?: FileActionType;
  isNullable?: boolean;
}>;

const items = [FileActionType.INSERT, FileActionType.UPDATE];

export const FileActionScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="FileAction" value={value} isNullable={isNullable} items={items} />
);
