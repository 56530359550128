import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { TrackList, TrackItem } from "../index";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { GRAY_0, GRAY_6, PINK_0 } from "constants/baseColor";
import { DANGER_COLOR } from "constants/color";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { Toast } from "lib/toast";
import { UpdateMetadataPlaylistRelation } from "GraphQL/Queries/Playlist";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";

type Props = {
  playlistId: string;
  trackList: TrackList;
  setTrackList: React.Dispatch<React.SetStateAction<TrackList>>;
};

export const ListContainer = ({ playlistId, trackList, setTrackList }: Props) => {
  const [accessId, setAccessId] = useState<string>("");
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      const { destination, source, reason } = result;
      if (!destination || reason === "CANCEL") {
        return;
      }
      // DROP SAME PLACE
      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }
      const orderedList = Array.from(trackList);
      const droppedItem = trackList[source.index];
      orderedList.splice(source.index, 1);
      orderedList.splice(destination.index, 0, droppedItem);
      setTrackList(orderedList);
      await UpdateMetadataPlaylistRelation({ id: droppedItem.uuid, order: destination.index + 1 });
      await DeleteAccessRecord({ id: accessId });
    },
    [accessId, setTrackList, trackList]
  );

  const renderUsers = (item: TrackItem, i: number) => {
    const { uuid, id, title, artist } = item;

    return (
      <Draggable key={uuid} draggableId={uuid} index={i}>
        {(provided, snapshot) => {
          return (
            <ListItem ref={provided.innerRef} {...provided.draggableProps} isDragging={snapshot.isDragging}>
              <div key={uuid}>
                <HamburgerHandler {...provided.dragHandleProps} className="index">
                  <MenuIcon />
                </HamburgerHandler>
                <div className="info-area">
                  <span className="title">{`[${id}] ${title}`}</span>
                  <span className="artist">{artist}</span>
                </div>
                <div className="trash">
                  <TrashIcon onClick={() => Toast.warning("기능 준비 중입니다.")} />
                </div>
              </div>
            </ListItem>
          );
        }}
      </Draggable>
    );
  };

  const onDragStart = async (id: string) => {
    try {
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      setAccessId(accessId!);
    } catch (err) {
      console.log(err);
      Toast.error("권한 요청에 실패하였습니다.");
    }
  };

  return (
    <DragDropContext onDragStart={() => onDragStart(playlistId)} onDragEnd={onDragEnd}>
      <Droppable droppableId="dp1">
        {(provided, snapshot) => (
          <List ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
            {trackList.map(renderUsers)}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const List = styled.div<{ isDraggingOver: boolean }>`
  background-color: ${props => (props.isDraggingOver ? GRAY_0 : "#fff")};
`;

const HamburgerHandler = styled.div`
  width: 1rem;
  display: table-cell;
  vertical-align: middle;
  align-items: center;
  svg {
    width: 1rem;
    height: 1rem;
    fill: #000;
  }
`;

const ListItem = styled.li<{ isDragging: boolean }>`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
  height: 40px;
  padding: 1rem 0.5rem;
  user-select: none;
  transition: background-color 0.15s;
  background: ${props => (props.isDragging ? "#FBD3E9" : "#fff")};
  background: ${props => (props.isDragging ? "-webkit-linear-gradient(to right, #FFFFFF, #FBD3E9)" : "#fff")};
  background: ${props => (props.isDragging ? "linear-gradient(to right, #FFFFFF, #FBD3E9)" : "#fff")};
  border-radius: ${props => (props.isDragging ? "8px" : "0")};
  border: ${props => (props.isDragging ? "1px solid #FBD3E9" : "")};
  opacity: ${props => (props.isDragging ? "0.8" : "1")};
  &:hover {
    background-color: ${PINK_0};
  }
  .index {
    display: table-cell;
    padding: 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
  }

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      white-space: normal;
    }
    .title {
      height: 22px;
      line-height: 22px;
      color: #191919;
      font-size: 0.9rem;
    }
    .artist {
      height: 17px;
      line-height: 17px;
      font-size: 13px;
      color: ${GRAY_6};
    }
  }

  .trash {
    display: table-cell;
    width: 68px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 1.2rem;
      height: 1.2rem;
      transition: fill 0.15s;
      &:hover {
        fill: ${DANGER_COLOR};
      }
    }
  }
`;
