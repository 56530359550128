/** @jsx jsx */

import { jsx, render, Variable, SelectionSet, StringScalar, Field, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { PathTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  filename?: string;
  companyId?: string;
  path?: string;
}>;

/**
 * @param filename
 * @param companyId
 * @param path
 */
export const UploadFile = async (option: Option) => {
  const $companyId = <Variable name="companyId" scalar={<IdScalar isNullable={true} />} value={option.companyId} />;
  const $path = <Variable name="path" scalar={<PathTypeScalar isNullable={true} />} value={option.path} />;
  const $filename = <Variable name="filename" scalar={<StringScalar isNullable={false} />} value={option.filename} />;
  const { query, variables } = render(
    <Mutation operationName="UPLOAD_FILE">
      <SelectionSet
        name="uploadFile"
        argument={{
          where: { filename: $filename, company_id: $companyId, path: $path }
        }}
      >
        <Field name="key" />
        <Field name="url" />
        <Field name="bucket" />
        <Field name="X_Amz_Date" />
        <Field name="X_Amz_Signature" />
        <Field name="X_Amz_Credential" />
        <Field name="X_Amz_Algorithm" />
        <Field name="Policy" />
        <Field name="invalidateToken" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    uploadFile: {
      key: string;
      url: string;
      bucket: string;
      X_Amz_Date: string;
      X_Amz_Signature: string;
      X_Amz_Credential: string;
      X_Amz_Algorithm: string;
      Policy: string;
      invalidateToken: string;
    };
  }>;
  return await clients.files.request<Data>(query, variables);
};
