/** @jsx jsx */

import { jsx, render, Variable, StringScalar, Mutation, Field } from "graphql-jsx";
import { clients } from "utils/clients";
import { MediaTypeScalar } from "GraphQL/Scalars";
import { MediaType } from "GraphQL/Scalars/MediaType";

type Option = Readonly<{
  token: string;
  mediaType: MediaType;
}>;

export const CreateInvalidation = async (option: Option) => {
  const $token = <Variable name="token" scalar={<StringScalar isNullable={false} />} value={option.token} />;
  const $mediaType = <MediaTypeScalar value={option.mediaType} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_INVALIDATION">
      <Field
        name="createInvalidation"
        argument={{
          where: {
            invalidateToken: $token,
            mediaType: $mediaType
          }
        }}
      />
    </Mutation>
  );
  type Data = Readonly<{
    createInvalidation: boolean;
  }>;

  const { errors, data } = await clients.files.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.createInvalidation;
};
