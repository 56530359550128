/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar, IntScalar, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  name: string;
  type: string;
  detail?: string;
  weight: number;
  parentId?: string;
}>;

export const CreateGenre = async (option: Option) => {
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={option.name} />;
  const $name_no_space = (
    <Variable name="name_no_space" scalar={<StringScalar isNullable={false} />} value={option.name.replace(/\s/gi, "")} />
  );
  const $type = <Variable name="type_kind" scalar={<StringScalar isNullable={false} />} value={option.type} />;
  const $detail = <Variable name="detail" scalar={<StringScalar isNullable={true} />} value={option.detail} />;
  const $weight = <Variable name="weight" scalar={<IntScalar isNullable={false} />} value={option.weight} />;
  const $parentId = <Variable name="parent_id" scalar={<IdScalar isNullable={true} />} value={option.parentId} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_GENRE">
      <SelectionSet
        name="createGenre"
        argument={{
          data: {
            name: $name,
            type_kind: $type,
            weight: $weight,
            detail: $detail,
            genre_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_genre_title: "name",
                  value: $name,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_genre_title: "name",
                  value: $name_no_space,
                  exposure_order: 1
                }
              ]
            },
            genre_self_relation_child: {
              create: {
                genre_self_relation_parent: {
                  connect: {
                    genre_id: $parentId
                  }
                }
              }
            }
          }
        }}
      >
        <Field name="genre_id" alias="genreId" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createGenre: {
      genreId: string;
    };
  }>;

  return await clients.genre.request<Data>(query, variables);
};
