/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id?: string;
  typeArtist?: string | null;
  valueIn?: string[];
  languageCode?: string | null;
}>;

export type Title = {
  uuid: string;
  order: number;
  typeArtistTitle: string;
  value: string;
  language: {
    languageCode: string;
    name?: string;
  }[];
};

export const GetArtistTitleRelation = async (option: Option) => {
  const $id = <Variable name="artist_id" scalar={<IdScalar />} value={option.id} />;
  const $typeArtist = <Variable name="type_artist" scalar={<StringScalar isNullable={true} />} value={option.typeArtist} />;
  const $valueIn = <ListScalar scalar={<StringScalar isNullable={false} />} value={option.valueIn} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar isNullable={true} />} value={option.languageCode} />;
  const { query, variables } = render(
    <Query operationName="GET_ARTIST_TITLE_RELATION">
      <SelectionSet
        name="artist"
        argument={{
          first: 99,
          where: {
            artist_id: $id,
            artist_title__some: {
              value__in: !option.valueIn?.length ? undefined : $valueIn,
              language__some: {
                language_code: $languageCode
              }
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <Field name="name" />
        <SelectionSet
          name="artist_title"
          alias="titleRelation"
          argument={{ first: 99, where: { type_artist_title: $typeArtist, type_artist_title__not_starts_with: "search" } }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <Field name="type_artist_title" alias="typeArtistTitle" />
          <Field name="value" />
          <SelectionSet name="language">
            <Field name="language_code" alias="languageCode" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    artist: Readonly<{
      id: string;
      name: string;
      titleRelation: Title[];
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
