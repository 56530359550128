/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IntScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  uuid: string;
  roleId: string;
  order?: number;
  validCheck?: ValidType;
}>;

export const UpdateArtistRoleRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $roleId = <Variable name="roleId" scalar={<IdScalar />} value={option.roleId} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.order} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST_ROLE">
      <SelectionSet
        name="updateArtist_role_relation"
        alias="updateArtistRoleRelation"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            role: {
              connect: {
                role_id: $roleId
              }
            },
            exposure_order: $order,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateArtistRoleRelation: Readonly<{
      artist_id: string;
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
