/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuidIn: string[];
}>;

export const DeleteGoodsArtist = async (option: Option) => {
  const $idIn = <Variable name="id_in" scalar={<ListScalar scalar={<UuidScalar isNullable={false} />} />} value={option.uuidIn} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_GOODS_ARTIST_RELATION">
      <SelectionSet
        name="deleteGoods_artist_relation"
        alias="deleteGoodsArtist"
        argument={{
          where: { id__in: $idIn }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteGoodsArtist: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
