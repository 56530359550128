import React, { ComponentType } from "react";
import { TagAutoComplete } from "App/Atomics/AutoComplete";
import { findCompanyList } from "./findCompanyList";
import styled from "styled-components";
import { components, OptionProps } from "react-select";
import { GRAY_5 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";

type Props = Pick<
  Parameters<typeof TagAutoComplete>[0],
  "style" | "className" | "isDisabled" | "defaultValue" | "defaultOptions" | "onChange" | "onBlur" | "placeholder"
> & { weight?: number };

export const CompanyTagAutoComplete = ({
  style,
  className,
  placeholder = "회사명을 입력해주세요.",
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  weight
}: Props) => {
  return (
    <TagAutoComplete
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findCompanyList(keyword, weight)}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="search_id">{props.data.id}</span>
        <span className="search_name">{props.data.name}</span>
        <span className="search_extra">{props.data.type}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .search_id {
    font-size: 9px;
    color: ${GRAY_5};
  }
  .search_name {
    font-size: 13px;
    font-weight: 500;
  }
  .search_extra {
    font-size: 10px;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;
