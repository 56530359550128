import React, { useMemo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { SIGN } from "constants/route";
import { gql } from "lib/gql-tag";
import { useAsyncEffect } from "lib/use-async-effect";
import { useQueryParams } from "lib/use-query-params";
import { clients } from "utils/clients";
import { LeftSideBar } from "./LeftSidebar";
import { RightTrackTable } from "./RightTrackTable";
import { TracksStoreProvider, useTracksStore } from "./Store";
import { WorkTrackActions } from "./Store/Track";
import { useAppSelector, useAppDispatch } from "App/Store";
import { UserRole } from "constants/UserRole";
import { AudioPlayerActions } from "App/Store/AudioPlayer";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Helmet } from "App/Atomics/Helmet";
import { Filters } from "App/Templates/FilterModalTemplate";

const Layout = styled.div`
  display: flex;
  width: 100%;
`;

const subheadDataEvery = (subheadData: Record<string, boolean>) => Object.values(subheadData).every(item => !item);

const ChildLayout = () => {
  const router = useHistory();
  const { userRole } = useAppSelector(store => ({
    userRole: store.UserToken.role
  }));
  const [{ head, subhead, count }, dispatch] = useTracksStore(store => store.WorkTrackTableInfo);
  const dispatchApp = useAppDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchArtist = queryParams.get("artist", { default: undefined });
  const checkList = queryParams.getAll("ids");
  const strCheckList = JSON.stringify(queryParams.getAll("ids"));
  const filter = useMemo(() => {
    const { lte, gte, artist, role, rights, validCheck, genre, mood, order } = JSON.parse(
      queryParams.get("filter", { default: undefined }) || "{}"
    ) as Filters;
    return { lte, gte, artist, role, rights, validCheck, genre, mood, order };
  }, [queryParams]);
  const { lte, gte, artist, role, rights, validCheck, genre, mood, order } = filter;
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  const genreMoodList = [...(genre ?? []), ...(mood ?? [])];

  useAsyncEffect(
    async isMounted => {
      const metadataQuery = `
          ${subhead.Metadata.metadataId ? `metadataId: metadata_id` : ""}
          ${subhead.Metadata.title ? `title` : ""}
          typeMetadataClass: type_metadata_class
          ${subhead.Metadata.typeMetadataSubclass ? `typeMetadataSubclass: type_metadata_subclass` : ""}
          ${subhead.Metadata.validCheck ? `validCheck: valid_check` : ""}
        `;
      const metadataTitleQuery =
        !head.Title || subheadDataEvery(subhead.Title)
          ? ""
          : `
          titles: metadata_title(
            where: {
              type_metadata_title__not_starts_with: "search"
            }
          )
          {
            ${subhead.Title.language ? `language { languageCode: language_code }` : ""}
            ${subhead.Title.typeMetadataTitle ? `typeMetadataTitle: type_metadata_title` : ""}
            ${subhead.Title.value ? `value` : ""}
          }
        `;
      const metadataArtistQuery =
        !head.Artist || subheadDataEvery(subhead.Artist)
          ? ""
          : `
          artists: metadata_artist_relation(first: 30) {
            ${subhead.Artist.artist ? `artist { name }` : ""}
            ${subhead.Artist.role ? `role { name }` : ""}
            ${subhead.Artist.character ? `character { name }` : ""}
            order: exposure_order
          }
        `;
      const metadataUrlQuery =
        !head.Url || subheadDataEvery(subhead.Url)
          ? ""
          : `
        urls: metadata_url {
          ${subhead.Url.typeUrl ? `typeUrl: type_url` : ""}
          ${subhead.Url.url ? `url` : ""}
          ${subhead.Url.fileValidCheck ? `fileValidCheck: valid_check` : ""}
        }
      `;
      const metadataCompanyQuery =
        userRole === UserRole.Arbeit || !head.Company || subheadDataEvery(subhead.Company)
          ? ""
          : `
          companies: metadata_company_relation {
            company { name license }
          }
        `;
      const metadataExtraQuery =
        userRole !== UserRole.Master || !head.LicenseExtra || subheadDataEvery(subhead.LicenseExtra)
          ? ""
          : `extras: track_license_extra {
          ${subhead.LicenseExtra.publishYear ? "publishYear: publish_year" : ""}
          ${subhead.LicenseExtra.recordYear ? "recordYear: record_year" : ""}
          ${subhead.LicenseExtra.data ? "data" : ""}
          ${subhead.LicenseExtra.firstEdition ? "firstEdition: first_edition" : ""}
          ${subhead.LicenseExtra.place ? "place" : ""}
        }`;
      const metadataGenreQuery =
        !head.Genre || subheadDataEvery(subhead.Genre)
          ? ""
          : `
          genres: metadata_genre_relation(first: 30, orderBy: exposure_order__ASC) {
            genre {
              genreId: genre_id
              name
              typeKind: type_kind
            }
          }
        `;
      const metadataInfoQuery =
        !head.Info || subheadDataEvery(subhead.Info)
          ? ""
          : `track_information(
        first: 99
      ) {
        uuid: id
        channel
        duration
        bitrate: sample_bitrate
        tempo
        tonality
        timeSignature: time_signature 
      }`;

      const getTrackListQuery = gql`
        query METADATA_GET_TRACK_LIST($page: Int) {
          tracks: metadata(
            page: $page,
            group: true,
            first: ${count},
            where: {
              AND: [
                { type_metadata_class: "work" }
                { type_metadata_subclass: "track" }
                ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `{ valid_check__not: REMOVE }` : ""}
                ${!checkList.length ? "" : `{ metadata_id__in: ${strCheckList} }`}
                ${!searchId ? "" : `{ metadata_id: ${searchId} }`}
                ${
                  !searchTitle
                    ? ""
                    : `{
                    metadata_title__some: {
                    tsvec_words__search: "${searchTitle}"
                    type_metadata_title__starts_with: "name"
                  }
                }`
                }
                ${
                  searchArtist && artist && artist.length
                    ? `
                    {
                      metadata_artist_relation__some: {
                        artist__some: {
                          name__contains: "${searchArtist}",
                          artist_id__in: ${artist.map(item => item.id)}
                        }
                      },
                    }
                  `
                    : searchArtist
                    ? `
                      { metadata_artist_relation__some: {
                        artist__some: {
                          name__contains: "${searchArtist}"
                        }
                      }
                      }
                    `
                    : artist && artist.length
                    ? `
                      { metadata_artist_relation__some: {
                          artist__some: {
                            artist_id__in: ${artist.map(item => item.id)}
                          }
                        }
                      }
                    `
                    : ""
                }
                ${
                  !role || !role.length
                    ? ""
                    : `
                    {
                      metadata_artist_relation__some: {
                        role__some: {
                          role_id__in: ${role.map(item => item.id)}
                        }
                      }
                    }
                  `
                }
                ${!gte ? "" : `{ metadata_id__gte: ${gte} }`}
                ${!lte ? "" : `{ metadata_id__lte: ${lte} }`}
                ${
                  !rights || !rights.length
                    ? ""
                    : `
                  { metadata_company_relation__some: {
                    company__some: {
                      company_id__in: ${rights.map(item => item.id)}
                    }
                  }, }
                `
                }
                ${!validCheck ? "" : `{ valid_check__in: [${validCheckIn}] }`}
                ${
                  !genreMoodList.length
                    ? ""
                    : `
                  {
                      metadata_genre_relation__some: {
                        genre__some: {
                          genre_id__in: [${genreMoodList.map(item => item.id)}]
                        }
                      }
                  }
                  `
                }
              ]
            },
            orderBy: ${order ?? "metadata_id__DESC"} ) {
            ${metadataQuery}
            ${metadataTitleQuery}
            ${metadataArtistQuery}
            ${metadataUrlQuery}
            ${metadataCompanyQuery}
            ${metadataExtraQuery}
            ${metadataGenreQuery}
            ${metadataInfoQuery}
          }
          edge(node: "metadata", page: $page, first: ${count},
            where: { metadata: {
            AND: [
              { type_metadata_class: "work" }
                { type_metadata_subclass: "track" }
                ${userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? `{ valid_check__not: REMOVE }` : ""}
                ${!checkList.length ? "" : `{ metadata_id__in: ${strCheckList} }`}
                ${!searchId ? "" : `{ metadata_id: ${searchId} }`}
                ${
                  !searchTitle
                    ? ""
                    : `{
                    metadata_title__some: {
                    tsvec_words__search: "${searchTitle}"
                    type_metadata_title__starts_with: "name"
                  }
                }`
                }
                ${
                  searchArtist && artist && artist.length
                    ? `
                    {
                      metadata_artist_relation__some: {
                        artist__some: {
                          name__contains: "${searchArtist}",
                          artist_id__in: ${artist.map(item => item.id)}
                        }
                      },
                    }
                  `
                    : searchArtist
                    ? `
                      { metadata_artist_relation__some: {
                        artist__some: {
                          name__contains: "${searchArtist}"
                        }
                      }
                      }
                    `
                    : artist && artist.length
                    ? `
                      { metadata_artist_relation__some: {
                          artist__some: {
                            artist_id__in: ${artist.map(item => item.id)}
                          }
                        }
                      }
                    `
                    : ""
                }
                ${
                  !role || !role.length
                    ? ""
                    : `
                    {
                      metadata_artist_relation__some: {
                        role__some: {
                          role_id__in: ${role.map(item => item.id)}
                        }
                      }
                    }
                  `
                }
                ${!gte ? "" : `{ metadata_id__gte: ${gte} }`}
                ${!lte ? "" : `{ metadata_id__lte: ${lte} }`}
                ${
                  !rights || !rights.length
                    ? ""
                    : `
                  { metadata_company_relation__some: {
                    company__some: {
                      company_id__in: ${rights.map(item => item.id)}
                    }
                  }, }
                `
                }
                ${!validCheck ? "" : `{ valid_check__in: [${validCheckIn}] }`}
                ${
                  !genreMoodList.length
                    ? ""
                    : `
                  {
                      metadata_genre_relation__some: {
                        genre__some: {
                          genre_id__in: [${genreMoodList.map(item => item.id)}]
                        }
                      }
                  }
                  `
                }
              ]
            }}) {
            hasNextPage
            hasPreviousPage
            currentPage
            pageCount
          }
        }
      `;

      try {
        dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
        const { data, errors } = await clients.metadata.query(getTrackListQuery, { page });
        if (!isMounted()) {
          return;
        }
        if (errors) {
          dispatchApp(AudioPlayerActions.toggleVisible(false));
          router.push(SIGN);
        } else {
          dispatch([
            WorkTrackActions.setEdge(data?.edge),
            WorkTrackActions.setTrack(!checkList.length ? data?.tracks : data?.tracks.reverse())
          ]);
          dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    [page, strCheckList, head, subhead, count, searchId, searchTitle, searchArtist, filter]
  );

  return (
    <Layout>
      <LeftSideBar />
      <RightTrackTable />
    </Layout>
  );
};

export const WorkTrack = () => (
  <TracksStoreProvider>
    <Helmet title="소작품" />
    <ChildLayout />
  </TracksStoreProvider>
);
