import React from "react";
import { FilterGroup, Filters } from "../..";
import { CompanyTagAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";

type Props = {
  rights?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const RightsFilter = ({ rights, setFilters }: Props) => (
  <FilterGroup>
    <h4>권리사</h4>
    <span className="info">여러 개의 권리사를 선택할 수 있습니다.</span>
    <CompanyTagAutoComplete
      defaultValue={rights?.length ? rights.map(({ id, name }) => ({ id, name: window.decodeURIComponent(name) })) : undefined}
      onChange={info => {
        if (info) {
          const list = info.map(({ id, name }) => ({ id, name: window.encodeURIComponent(name) }));
          setFilters(filter => ({ ...filter, rights: list }));
        }
      }}
    />
  </FilterGroup>
);
