/** @jsx jsx */

import { jsx, render, Field, Variable, SelectionSet, Query, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { RoleTitleOrderScalar } from "GraphQL/Scalars";
import { RoleTitleOrderByInput } from "constants/RoleTitleOrderByInput";

type Option = Readonly<{
  roleId: string;
  languageCode?: string;
}>;

export const GetRoleTitle = async (option: Option) => {
  const $roleId = <Variable name="roleId" scalar={<IdScalar />} value={option.roleId} />;
  const $languageCode = <Variable name="languageCode" scalar={<IdScalar />} value={option.languageCode} />;
  const { query, variables } = render(
    <Query operationName="GET_ROLE_TITLE">
      <SelectionSet
        name="role"
        alias="getRoleTitle"
        argument={{
          first: 100,
          where: { role_id: $roleId }
        }}
      >
        <Field name="type_role_class" alias="typeClass" />
        <SelectionSet
          name="role_title"
          alias="roleTitle"
          argument={{
            orderBy: <RoleTitleOrderScalar value={RoleTitleOrderByInput.exposure_order__ASC} />,
            where: {
              language__some: {
                language_code: $languageCode
              },
              type_role_title__not_starts_with: "search"
            }
          }}
        >
          <Field name="id" />
          <Field name="type_role_title" alias="type" />
          <Field name="exposure_order" alias="order" />
          <Field name="value" alias="value" />
          <SelectionSet name="language">
            <Field name="language_code" alias="languageCode" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    getRoleTitle: ReadonlyArray<{
      typeClass: string;
      roleTitle: Readonly<{
        id: string;
        type: string;
        value: string;
        language: Readonly<{
          languageCode: string;
          name: string;
        }>[];
      }>[];
    }>;
  }>;
  return await clients.role.request<Data>(query, variables);
};
