import React from "react";
import { FilterGroup, Filters } from "../..";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { Input } from "App/Atomics/Input";
import { LicenseTypeOptionInfo } from "constants/TypeOptionInfo";

type Props = {
  licenseType?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const LicenseFilter = ({ licenseType, setFilters }: Props) => (
  <FilterGroup>
    <h4>라이센스 타입</h4>
    <span className="info">여러 개의 타입을 선택할 수 있습니다.</span>
    <Input.TagSelect
      optionList={LicenseTypeOptionInfo}
      placeholder="타입을 선택해주세요."
      defaultValue={licenseType}
      onChange={info => {
        if (info) {
          const list = info.map(({ id, name }) => ({ id, name }));
          setFilters(filter => ({ ...filter, licenseType: list }));
        }
      }}
    />
  </FilterGroup>
);
