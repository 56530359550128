import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_2, PINK_0 } from "constants/baseColor";
import { Column } from "constants/Column";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import ToggleSwitch from "App/Atomics/Input/ToggleSwitch";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetPlaylistVisibleField, UpdatePlaylistUsersRelation } from "GraphQL/Queries/Playlist";
import { Toast } from "lib/toast";
import { Input } from "App/Atomics/Input";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";

type Props = {
  id: string;
  toClose: () => void;
};

type FieldInfo = {
  uuid: string;
  visibleField: string[] | null;
};

enum Level {
  LV1 = "LV1",
  LV2 = "LV2",
  LV3 = "LV3",
  LV4 = "LV4"
}

const LevelOptionList = [
  { id: Level.LV1, name: "LV1 (제목, 라이센스)" },
  { id: Level.LV2, name: "LV2 (체크박스, 번호, 제목, 음원, 오리지널, 메인 퍼폼)" },
  { id: Level.LV3, name: "LV3 (체크박스, 번호, 제목, 음원, 오리지널, 메인 퍼폼, 장르, 가사, 라이센스)" },
  { id: Level.LV4, name: "다운로드 표준 (MP3, WAV)" }
];

export const ColumnModal = ({ id, toClose }: Props) => {
  const [fieldInfo, setFieldInfo] = useState<FieldInfo>({} as FieldInfo);
  const [loading, setLoading] = useState<boolean>(false);

  const onToggleColumn = (checked: boolean, column: Column) => {
    if (checked) {
      setFieldInfo(prev => ({ ...prev, visibleField: [...(prev.visibleField ?? []), column] }));
    } else {
      const newField = fieldInfo.visibleField?.filter(item => item !== column);
      setFieldInfo(prev => ({ ...prev, visibleField: newField ?? [] }));
    }
  };

  const onChangeLevel = (level: Level) => {
    switch (level) {
      case Level.LV1:
        setFieldInfo(prev => ({ ...prev, visibleField: [Column.title, Column.license] }));
        break;
      case Level.LV2:
        setFieldInfo(prev => ({
          ...prev,
          visibleField: [Column.checkbox, Column.no, Column.title, Column.url, Column.originalArtist, Column.mainPerformer]
        }));
        break;
      case Level.LV3:
        setFieldInfo(prev => ({
          ...prev,
          visibleField: [
            Column.checkbox,
            Column.no,
            Column.title,
            Column.url,
            Column.originalArtist,
            Column.mainPerformer,
            Column.genre,
            Column.lyrics,
            Column.license
          ]
        }));
        break;
      case Level.LV4:
        setFieldInfo(prev => ({
          ...prev,
          visibleField: [
            Column.checkbox,
            Column.order,
            Column.title,
            Column.url,
            Column.mainPerformer,
            Column.genre,
            Column.license,
            Column.mp3,
            Column.wav
          ]
        }));
        break;
      default:
        break;
    }
  };

  const onSave = async () => {
    try {
      setLoading(true);
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      if (accessId) {
        const { errors } = await UpdatePlaylistUsersRelation({ uuid: fieldInfo.uuid, visibleField: fieldInfo.visibleField! });
        if (errors) {
          throw errors;
        }
        await DeleteAccessRecord({ id: accessId });
        Toast.primary("저장되었습니다.");
        setLoading(false);
        toClose();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      return;
    }
  };

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      setLoading(true);
      const userRelation = await GetPlaylistVisibleField({ id });
      setLoading(false);
      if (userRelation.length) {
        setFieldInfo(userRelation[0]);
      } else {
        Toast.error("공유된 계정이 없습니다.");
        toClose();
      }
    }
  }, []);

  return (
    <Layout>
      <Header>
        <h3>컬럼 관리</h3>
        <CancelIcon className="cancel-icon" onClick={toClose} />
      </Header>
      <Filter>
        <LevelSelect
          optionList={LevelOptionList}
          placeholder="미리 설정된 단계를 선택하실 수 있습니다."
          onChange={info => {
            if (info) {
              onChangeLevel(info.id as Level);
            }
          }}
        />
        <Input.Button color="primary" onClick={onSave}>
          저장
        </Input.Button>
      </Filter>
      <Section>
        <List>
          {Object.entries(Column)
            .filter(
              ([_, value]) =>
                value !== Column.validCheck &&
                value !== Column.mp3 &&
                value !== Column.aac &&
                value !== Column.flac &&
                value !== Column.wav &&
                value !== Column.file
            )
            .map(([key, value]) => {
              const isActive = !!fieldInfo?.visibleField?.includes(value);
              return (
                <ListItem key={key}>
                  <span>{value}</span>
                  <ToggleSwitch id={key} color="primary" isActive={isActive} onChange={() => onToggleColumn(!isActive, value as Column)} />
                </ListItem>
              );
            })}
        </List>
      </Section>
      <SectionInfo>
        <h3>파일 다운로드 관리</h3>
      </SectionInfo>
      <Section>
        <List>
          {Object.entries(Column)
            .filter(
              ([_, value]) =>
                value === Column.mp3 || value === Column.aac || value === Column.flac || value === Column.wav || value === Column.file
            )
            .map(([key, value]) => {
              const isActive = !!fieldInfo?.visibleField?.includes(value);
              return (
                <ListItem key={key}>
                  <span>{value}</span>
                  <ToggleSwitch id={key} color="success" isActive={isActive} onChange={() => onToggleColumn(!isActive, value as Column)} />
                </ListItem>
              );
            })}
        </List>
      </Section>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 680px;
  height: 650px;
  overflow: hidden;
  font-size: 14px;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 4rem;
  background-color: #6a5fdd;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 2px;
  z-index: 2;

  .cancel-icon {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    margin-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      fill: ${GRAY_2};
    }
  }
`;

const Filter = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  background-color: #fff;
  z-index: 2;
  border-bottom: 1.5px solid #ddd;
  padding: 0 1rem;
`;

const Section = styled.section`
  width: 100%;
`;

const List = styled.div`
  background-color: #fff;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 33%);
`;

const ListItem = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 2rem 1rem;
  user-select: none;
  transition: background-color 0.15s;
  border-bottom: 1px solid #eee;
  &:hover {
    background-color: ${PINK_0};
  }

  span {
    margin-bottom: 0.2rem;
  }
`;

const LevelSelect = styled(Input.TextSelect)`
  width: 32rem;
`;

const SectionInfo = styled.div`
  margin-top: 1.8rem;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  background-color: mediumseagreen;
  color: #fff;
  display: flex;
  align-items: center;
`;
