import React, { forwardRef, useImperativeHandle, useRef } from "react";

import { clamp } from "lib/clamp";
import { identity } from "utils/identity";

type ImperativeHandle = Readonly<{ value: number }>;

type Props = Styleable &
  Readonly<{
    defaultValue?: number;
    value?: number;
    isDisabled?: boolean;
    isRequired?: boolean;
    min: number;
    max: number;
    step: number;
    placeholder?: string;
    onChange?: (value: number) => void;
    onBlur?: (value: number) => void;
  }>;

export const Number = forwardRef<ImperativeHandle, Props>(
  (
    {
      style,
      className,
      defaultValue,
      value = defaultValue,
      isDisabled,
      isRequired,
      min = 0,
      max,
      step = 1,
      placeholder,
      onChange = identity,
      onBlur = identity
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const getValue = () => clamp(+inputRef.current!.value, min, max);
    const change = () => onChange(getValue());
    const blur = () => onBlur(getValue());

    useImperativeHandle(ref, () => ({
      get value() {
        return getValue();
      }
    }));

    return (
      <input
        type="number"
        style={style}
        className={className}
        ref={inputRef}
        disabled={isDisabled}
        required={isRequired}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        defaultValue={value}
        onChange={change}
        onBlur={blur}
      />
    );
  }
);
