import React, { ReactNode, Children } from "react";
import styled from "styled-components";
import Slick from "react-slick";
import { PRIMARY_COLOR } from "constants/color";

export type SliderProps = {
  children: ReactNode;
};

const Layout = styled.div`
  &[data-count="1"] {
    .slick-track {
      display: flex;
      justify-content: center;
    }
  }

  .slider {
    ul.slick-dots {
      bottom: 0;
      position: relative;
      margin-top: 0.8rem;

      & > li {
        background: #f7f7f7;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;

        &.slick-active {
          background-color: ${PRIMARY_COLOR};
        }

        /* 기본 도트 버튼 제거  */
        & > button {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: transparent;
          border: none;

          &:before {
            content: none;
          }
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 0;
    height: 0;
    z-index: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
  }

  .slick-next,
  .slick-prev {
    display: none;
  }

  .slick-slide {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

  [aria-hidden="true"] {
    pointer-events: none;
  }

  img {
    transition-property: transform, filter;
  }
  img:hover {
    filter: contrast(0.85);
    transform: scale(1.01);
  }

  label {
    outline: none;
  }
`;

export const Slider = ({ children }: SliderProps) => {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: false,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };
  const isSingle = Children.count(children);
  return (
    <Layout data-count={isSingle}>
      <Slick {...settings}>{children}</Slick>
    </Layout>
  );
};
