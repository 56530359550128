import React, { useState } from "react";
import styled from "styled-components";
import { PADDING_XX_LARGE_PX, PADDING_X_LARGE_PX, MARGING_XX_LARGE_PX, pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { Divider } from "App/Atomics/Divider";
import { useArtistStore } from "../../Store";
import { ArtistCreateActions } from "../../Store/Artist";
import { DEFAULT_BORDER_COLOR } from "constants/color";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;

  width: 80%;
  height: 85%;
  padding: ${PADDING_XX_LARGE_PX};

  h2 {
    text-align: left;
    padding: 0px ${PADDING_X_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  .prevButton {
    margin-right: auto;
    margin-top: auto;
  }
`;

const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: initial;
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 15)};
  height: ${pixelize(UNIT * 15)};
  margin-bottom: ${MARGING_XX_LARGE_PX};
  border: 2px solid ${DEFAULT_BORDER_COLOR};
  font-size: 1.5rem;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  margin-top: auto;

  & > button {
    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }
`;

export const SubmitForm = () => {
  const [{ artist }, dispatch] = useArtistStore(store => ({
    artist: store.Artist.artist
  }));
  const [isDisabled, setIsDisabled] = useState(false);

  const onPrevForm = () => dispatch(ArtistCreateActions.setPageState("ARTIST"));

  return (
    <Layout onSubmit={event => event.preventDefault()}>
      <h2>아티스트 등록정보 확인</h2>
      <Divider />
      <InfoSection>
        {artist.artistUrl && artist.artistUrl.data ? <img alt="" src={artist.artistUrl.data} /> : <NoImage>NO IMAGE</NoImage>}
        <h2>Artist Name.</h2>
        {artist.name && artist.name}
      </InfoSection>
      <ButtonGroup>
        <Input.Button color="danger" isFill={false} onClick={onPrevForm}>
          이전 (아티스트정보 입력)
        </Input.Button>
        <Input.Button
          color="danger"
          isFill
          disabled={isDisabled}
          onClick={() => {
            setIsDisabled(prev => !prev);
          }}
        >
          아티스트 생성
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
