import React from "react";
import { FilterItem, inputCustomStyle } from "../../formStyle";
import { CompanyTagAutoComplete } from "App/Molecules/AutoCompletes/Company";

export const CompanyIncludeFilter = () => {
  return (
    <FilterItem>
      <div className="item-label">
        <span>권리사 포함 리스트</span>
      </div>
      <div className="item-option-wrapper">
        <CompanyTagAutoComplete style={inputCustomStyle} defaultValue={undefined} onChange={() => {}} onBlur={() => {}} />
      </div>
    </FilterItem>
  );
};
