import React from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { useQueryParams } from "lib/use-query-params";
import { GoodsTable } from "./GoodsTable";
import { Progress } from "utils/progress";
import { useLoadGoods } from "./Hooks/useLoadGoods";
import { CategoryOfGoods } from "GraphQL/Scalars/CategoryOfGoodsScalar";
import { TypeOfGoods } from "GraphQL/Scalars/TypeOfGoodsScalar";

enum GoodsSearchType {
  ID = "id",
  TITLE = "title",
  ARTIST = "artist"
}

const GOODS_DEFAULT_COUNT = 20;

export const Goods = () => {
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const id = queryParams.get(GoodsSearchType.ID, { default: undefined });
  const name = queryParams.get(GoodsSearchType.TITLE, { default: undefined });
  const parentId = queryParams.get("parentId", { default: undefined, cast: v => +v });
  const categoryIn = queryParams.get("category", { default: undefined })?.split(" ") as CategoryOfGoods[];
  const typeIn = queryParams.get("type", { default: undefined })?.split(" ") as TypeOfGoods[];
  const { loading } = useLoadGoods({ first: GOODS_DEFAULT_COUNT, page, id, name, categoryIn, typeIn, parentId });

  return (
    <Layout>
      <Helmet title="굿즈 관리" />
      <Progress.Bottom />
      <LeftSideBar />
      <GoodsTable loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;
