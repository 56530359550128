/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IntScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { RoleSelfRelation } from "App/Routes/RoleCreate/Store/Role";

type Option = Readonly<{
  name: string;
  typeClass: string;
  validCheck: ValidType;
  count: number;
  expose?: number;
  roleSelfRelation?: RoleSelfRelation[];
}>;

export const CreateRole = async (option: Option) => {
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={option.name} />;
  const $name_no_space = (
    <Variable name="name_no_space" scalar={<StringScalar isNullable={false} />} value={option.name.replace(/\s/gi, "")} />
  );
  const $typeClass = <Variable name="type_role_class" scalar={<StringScalar isNullable={false} />} value={option.typeClass} />;
  const $count = <Variable name="count" scalar={<IntScalar />} value={option.count} />;
  const $expose = <Variable name="expose" scalar={<IntScalar />} value={option.expose} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ROLE">
      <SelectionSet
        name="createRole"
        argument={{
          data: {
            name: $name,
            type_role_class: $typeClass,
            valid_check: $validCheck,
            role_count: $count,
            expose: $expose,
            role_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_role_title: "name",
                  value: $name,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_role_title: "name",
                  value: $name_no_space,
                  exposure_order: 1
                }
              ]
            },
            ...(!option.roleSelfRelation?.length
              ? {}
              : {
                  role_self_relation_element_role: {
                    create: option.roleSelfRelation.map(({ id, typeFunctionId }, order) => ({
                      role_self_relation_list_role: {
                        connect: {
                          role_id: id
                        }
                      },
                      type_function: typeFunctionId,
                      exposure_order: order
                    }))
                  }
                })
          }
        }}
      >
        <Field name="role_id" alias="roleId" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createRole: {
      roleId: string;
    };
  }>;

  return await clients.role.request<Data>(query, variables);
};
