import { StringID } from "utils/id";
import { immerable } from "immer";
import Time, { Dayjs } from "dayjs";

export class GoodsID extends StringID {
  constructor(id: string) {
    super("goods", id);
  }
}

export type Url = {
  readonly id: string;
  readonly typeUrl: string;
  readonly url: string;
  readonly order: number;
};

export type Company = {
  readonly id: string;
  readonly name: string;
};

export type GoodsType = Readonly<{
  id: number;
  type: string;
  category: string;
  displayType: string;
}>;

export type GoodsPayload = Readonly<{
  id: string;
  name: string;
  goodsType: GoodsType;
  subtitle: string;
  weight: number;
  urls: Url[];
  createdAt: string;
  company: Company;
  structureId: string;
  isCollection: boolean;
  parentGoodsId?: string;
  parentName?: string;
}>;

export class GoodsModel {
  static from({
    id,
    name,
    goodsType,
    subtitle,
    weight,
    urls,
    company,
    structureId,
    isCollection,
    createdAt,
    parentGoodsId,
    parentName
  }: GoodsPayload): GoodsModel {
    return new GoodsModel(
      new GoodsID(id),
      name,
      goodsType,
      subtitle,
      weight,
      urls,
      company,
      structureId,
      isCollection,
      Time(createdAt),
      parentGoodsId,
      parentName
    );
  }

  readonly [immerable] = true;

  constructor(
    readonly id: GoodsID,
    readonly name: string,
    readonly goodsType: GoodsType,
    readonly subtitle: string,
    readonly weight: number,
    readonly urls: Url[],
    readonly company: Company,
    readonly structureId: string,
    readonly isCollection: boolean,
    readonly createdAt: Dayjs,
    readonly parentGoodsId?: string,
    readonly parentName?: string
  ) {}
}
