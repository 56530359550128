/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, StringScalar, BooleanScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  metadataId: string;
  typeTrack: string;
  countryCode: string;
  notice: string;
  copyright: boolean;
  neighboring: boolean;
  licenseDate?: string | null;
  publishDate?: string | null;
  isService?: number;
  validCheck?: ValidType;
}>;

export const CreateTrackLicense = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $typeTrack = <Variable name="type_track" scalar={<StringScalar isNullable={false} />} value={option.typeTrack} />;
  const $countryCode = <Variable name="country_code" scalar={<StringScalar isNullable={false} />} value={option.countryCode} />;
  const $notice = <Variable name="notice" scalar={<StringScalar />} value={option.notice} />;
  const $copyright = <Variable name="copyright" scalar={<BooleanScalar />} value={option.copyright} />;
  const $neighboring = <Variable name="neighboring" scalar={<BooleanScalar />} value={option.neighboring} />;
  const $licenseDate = <Variable name="licenseDate" scalar={<DateTimeScalar isNullable />} value={option.licenseDate} />;
  const $publishDate = <Variable name="publishDate" scalar={<DateTimeScalar isNullable />} value={option.publishDate} />;
  const $isService = <Variable name="is_service" scalar={<IntScalar />} value={option.isService} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_TRACK_LICENSE">
      <SelectionSet
        name="updateMetadata"
        alias="createTrackLicense"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            _onlySearch: true,
            track_license: {
              create: {
                type_track: $typeTrack,
                country_code: $countryCode,
                notice: $notice,
                copyright: $copyright,
                neighboring: $neighboring,
                license_date: !option.licenseDate ? undefined : $licenseDate,
                publish_date: !option.publishDate ? undefined : $publishDate,
                is_service: $isService,
                valid_check: $validCheck
              }
            }
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="track_license">
          <Field name="id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createTrackLicense: Readonly<{
      metadata_id: string;
      track_license: Readonly<{
        id: string;
      }>[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
