import React, { ReactNode } from "react";
import { Topbar } from "App/Molecules/Topbar";
import styled from "styled-components";
import { WHITE } from "constants/baseColor";
import { pixelize, UNIT, PADDING_XX_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as BurgerIcon } from "assets/icons/hamburger.svg";
import { mediaQuery } from "constants/media";

type Props = Styleable &
  Readonly<{
    children?: ReactNode;
    openSidebar?: () => void;
  }>;

const Layout = styled(Topbar)`
  width: 100%;
  background-color: ${WHITE};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  & > button:first-child {
    border-right: 1px solid #d8d8d8;
  }

  & > button,
  & > a {
    padding: 20px ${PADDING_XX_LARGE_PX};
    font-size: 1.1rem;
    text-align: center;
    border-radius: 0;
    transition: all 0.2s;

    & svg {
      position: relative;
      top: 4px;
      width: ${pixelize(1.25 * UNIT)};
      height: ${pixelize(1.25 * UNIT)};
    }

    &:hover {
      opacity: 0.5;
    }

    .menu,
    .burger {
      fill: #333;
      width: 1.6rem;
      height: 1.6rem;
    }
    .menu {
      display: none;
    }
    ${mediaQuery(1000)} {
      .burger {
        display: none;
      }
      .menu {
        display: block;
      }
    }
  }

  & > form {
    margin-left: auto;
  }
`;

export const TopbarTemplate = ({ className, style, children, openSidebar }: Props) => (
  <Layout className={className} style={style}>
    {openSidebar && (
      <Input.Button onClick={openSidebar}>
        <BurgerIcon className="burger" />
        <MenuIcon className="menu" />
      </Input.Button>
    )}
    {children}
  </Layout>
);
