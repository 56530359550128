/** @jsx jsx */

import { Field, IdScalar, IntScalar, jsx, ListScalar, Query, render, SelectionSet, StringScalar, Variable } from "graphql-jsx";

import { License } from "constants/License";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { MetadataSelectionSet } from "GraphQL/SelectionSet";
import { clients } from "utils/clients";

type Option = Readonly<{
  first: number;
  skip: number;
  licenseList: readonly License[];
  validTypeList: readonly ValidType[];
  includeCompanyList: readonly string[];
  includeArtistList: readonly string[];
  includeGenreList: readonly string[];
  includeMoodList: readonly string[];
  excludeCompanyList: readonly string[];
  excludeArtistList: readonly string[];
  excludeGenreList: readonly string[];
  excludeMoodList: readonly string[];
}>;

export const getTrackList = async ({
  first,
  skip,
  licenseList,
  validTypeList,
  includeCompanyList,
  includeArtistList,
  includeGenreList,
  includeMoodList,
  excludeCompanyList,
  excludeArtistList,
  excludeGenreList,
  excludeMoodList
}: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar />} value={first} />;
  const $skip = <Variable name="skip" scalar={<IntScalar />} value={skip} />;
  const $licenseList = (
    <Variable name="licenseList" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={licenseList} />
  );
  const $validTypeList = (
    <Variable name="validTypeList" scalar={<ListScalar scalar={<ValidTypeScalar isNullable={false} />} />} value={validTypeList} />
  );
  const $includeCompanyList = (
    <Variable name="includeCompanyList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={includeCompanyList} />
  );
  const $excludeCompanyList = (
    <Variable name="excludeCompanyList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={excludeCompanyList} />
  );
  const $includeArtistList = (
    <Variable name="includeArtistList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={includeArtistList} />
  );
  const $excludeArtistList = (
    <Variable name="excludeArtistList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={excludeArtistList} />
  );
  const $includeGenreList = (
    <Variable name="includeGenreList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={includeGenreList} />
  );
  const $excludeGenreList = (
    <Variable name="excludeGenreList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={excludeGenreList} />
  );
  const $includeMoodList = (
    <Variable name="includeMoodList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={includeMoodList} />
  );
  const $excludeMoodList = (
    <Variable name="excludeMoodList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={excludeMoodList} />
  );

  const $countryCodeIn = (
    <Variable name="countryCodeIn" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={["ZZ", "KR"]} />
  );

  const $V3_AND_REMOVE = <ListScalar scalar={<ValidTypeScalar />} isNullable value={[ValidType.V3, ValidType.REMOVE]} />;

  const { query, variables } = render(
    <Query operationName="GET_TRACK_LIST">
      <MetadataSelectionSet
        alias="trackList"
        argument={{
          first: $first,
          skip: $skip,
          group: true,
          where: {
            AND: [
              {
                metadata_genre_relation__some: {
                  genre__some: {
                    genre_id__in: $includeGenreList,
                    genre_id__not_in: $excludeGenreList
                  }
                }
              },
              {
                metadata_genre_relation__some: {
                  genre__some: {
                    genre_id__in: $includeMoodList,
                    genre_id__not_in: $excludeMoodList
                  }
                }
              }
            ],
            valid_check__in: $validTypeList,
            metadata_url__some: {
              valid_check__in: $V3_AND_REMOVE
            },
            metadata_company_relation__some: {
              company__some: {
                company_id__in: $includeCompanyList,
                company_id__not_in: $excludeCompanyList
              }
            },
            metadata_artist_relation__some: {
              artist__some: {
                artist_id__in: $includeArtistList,
                artist_id__not_in: $excludeArtistList
              }
            },
            track_license__some: {
              type_track__in: $licenseList,
              country_code__in: $countryCodeIn,
              copyright: true,
              neighboring: true
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />

        <SelectionSet
          name="metadata_url"
          alias="audioList"
          argument={{ first: 1, where: { type_url__in: ["mp3high", "aac", "flac", "wav"] } }}
        >
          <Field name="type_url" alias="type" />
          <Field name="url" />
        </SelectionSet>
      </MetadataSelectionSet>
    </Query>
  );

  type Data = {
    trackList: Array<{
      id: string;
      title: string;
      audioList: [{ type: string; url: string }];
    }>;
  };
  const response = await clients.metadata.request<Data>(query, variables);
  if (response.errors) {
    window.alert("데이터를 불러오는 중 에러가 발생했습니다");
    return;
  }
  return response.data!.trackList.map(({ id, title, audioList: [audio] }) => ({ id, title, audio }));
};
