import React from "react";
import styled from "styled-components";
import { EffectCreateForm } from "./Form";
import { EffectCreateStoreProvider } from "./Store";
import { Helmet } from "App/Atomics/Helmet";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { HEADER_HEIGHT_PX } from "constants/size";
import { ReactComponent as CDIcon } from "assets/icons/cd.svg";
import { RED_4 } from "constants/baseColor";
import { DANGER_COLOR } from "constants/color";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

const EffectCreateLayout = () => {
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  return (
    <Layout>
      <LeftSideBar />
      <TopbarTemplate openSidebar={openSidebar}>
        <div>{}</div>
      </TopbarTemplate>
      <Banner>
        <div className="banner-content">
          <div className="banner-title">
            <CDIcon />
            <h2>효과음 등록</h2>
          </div>
          <span className="banner-info"></span>
        </div>
      </Banner>
      <EffectCreateForm />
    </Layout>
  );
};

export const EffectCreate = () => (
  <EffectCreateStoreProvider>
    <Helmet title="앨범 생성" />
    <EffectCreateLayout />
  </EffectCreateStoreProvider>
);

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 15px;
  line-height: 1.5;
  color: #333;
`;

const Banner = styled.div`
  position: sticky;
  top: 36px;
  z-index: 2;
  margin-top: ${HEADER_HEIGHT_PX};
  background-color: #ffdc00;
  width: 100%;
  padding: 84px 0 16px;
  .banner-content {
    width: 940px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .banner-title {
      display: flex;
      align-items: center;
    }
    .banner-info {
      margin-top: 4px;
      color: ${RED_4};
      transition: all 0.15s;
      &:hover {
        color: ${DANGER_COLOR};
        text-decoration: underline;
        cursor: pointer;
      }
    }
    h2 {
      font-size: 2rem;
      color: #1e1e1e;
      transform: translateY(-2px);
    }
    svg {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 8px;
      fill: #1e1e1e;
    }
  }
`;
