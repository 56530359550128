type Subheading = Record<string, Record<string, string>>;

export const subheadKeyValue = {
  "#": {
    "#": "#"
  },
  Metadata: {
    metadataId: "ID",
    title: "Title",
    no: "No",
    typeMetadataSubclass: "Type",
    validCheck: "Validate"
  },
  Title: {
    language: "Language",
    typeMetadataTitle: "Type",
    value: "Content"
  },
  Artist: {
    artist: "Artist",
    role: "Role",
    character: "Character"
  },
  Url: {
    typeUrl: "URL Type",
    url: "URL",
    fileValidCheck: "Validate"
  },
  Company: {
    company: "Company"
  },
  License: {
    license: "license"
  },
  LicenseExtra: {
    recordYear: "Record Year",
    publishYear: "Publish Year",
    firstEdition: "First Edition",
    place: "Place",
    data: "License"
  },
  Work: {
    work: "Work",
    workArtist: "Artist"
  },
  Genre: {
    genre: "Genre",
    mood: "Mood"
  },
  Info: {
    grade: "Grade",
    channel: "Channel",
    duration: "Duration",
    bitrate: "Bitrate",
    tempo: "Tempo",
    tonality: "Tonality",
    timeSignature: "TimeSignature",
    voiceGender: "VoiceGender",
    extraData: "ExtraData"
  }
} as Subheading;
