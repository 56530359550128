import React from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { WorkStoreProvider } from "./Store";
import { WorkForm } from "./Forms/WorkForm";
import { Helmet } from "App/Atomics/Helmet";

const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.9rem;
`;

const WorkCreateLayout = () => {
  return (
    <ResponsiveToSideNavigatorTemplate>
      <Layout>
        <WorkForm />
      </Layout>
    </ResponsiveToSideNavigatorTemplate>
  );
};

export const WorkCreate = () => (
  <WorkStoreProvider>
    <Helmet title="작품 등록" />
    <WorkCreateLayout />
  </WorkStoreProvider>
);
