import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { ProductCreateActions } from "App/Routes/ProductCreate/Store/ProductCreate";
import { ProductCreateStore } from "App/Routes/ProductCreate/Store";

type Props = {
  readonly createdAt: string;
};

export const ProductDate = ({ createdAt }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>등록 일자 *</Style.Caption>
    <Style.Inputs
      key={createdAt}
      type="date"
      defaultValue={createdAt}
      required
      min="1900-01-01"
      max="2099-12-31"
      onChange={event => ProductCreateStore.dispatch(ProductCreateActions.setCreatedAt(event.target.value))}
    />
    <Style.AlertCaption>날짜를 선택해주세요.</Style.AlertCaption>
  </Style.Row>
);
