import React from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  PADDING_X_LARGE_PX,
  MARGING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  MARGING_LARGE,
  MARGING_SMALL_PX,
  PADDING_SMALL_PX,
  PADDING_LARGE_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_4, GRAY_0 } from "constants/baseColor";
import { useTracksStore, useTracksSelector } from "../../../Store";
import { DANGER_COLOR_LIGHT, DANGER_COLOR, SUCCESS_COLOR } from "constants/color";

type Props = {
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 52)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${GRAY_0};
  display: flex;
`;

const Multiline = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 10)};
  font-size: 14px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
    display: flex;
    align-items: center;
    span {
      font-size: 0.8em;
      color: ${DANGER_COLOR_LIGHT};
      font-weight: 400;
      margin-left: ${MARGING_LARGE_PX};
    }
  }

  .typeSelect {
    width: 100%;
    margin-bottom: ${MARGING_LARGE_PX};
    font-size: 12px;
  }
`;

const GroupContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const CopyrightBox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${MARGING_SMALL_PX};
  gap: ${MARGING_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

const ExtraInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
  margin-bottom: ${MARGING_SMALL_PX};
`;

const TextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  padding: ${PADDING_LARGE_PX};
  overflow-x: scroll;
  background-color: ${WHITE};
`;

const Date = styled.input`
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  padding: ${PADDING_SMALL_PX};
  font-size: 14px;
`;

const OptionLicenseService = [
  { id: "0", name: "사용 가능 / 재생 가능" },
  { id: "1", name: "사용 가능 / 재생 불가" },
  { id: "2", name: "사용 불가 / 재생 불가" }
];

export const DetailLicenseModal = ({ toClose }: Props) => {
  const modalId = useTracksSelector(store => store.WorkTrack.modalId);
  const [{ license }] = useTracksStore(store => ({
    license: store.WorkTrack.license.filter(item => item.id === modalId)[0]
  }));
  const {
    type_track: typeTrack,
    country_code: countryCode,
    notice,
    copyright,
    neighboring,
    publish_date: publishDate,
    is_service: isService
  } = license;
  const description = JSON.parse(notice).text;
  const companyIds = JSON.parse(notice).companyIds;
  return (
    <Layout>
      <RowGroup>
        <h2>상세 라이센스</h2>
      </RowGroup>
      <RowGroup>
        <h4>저작권 만료 여부</h4>
        <CopyrightBox>
          <TextBox style={{ color: copyright ? DANGER_COLOR : SUCCESS_COLOR }}>{copyright ? "저작권 만료" : "저작권 미만료"}</TextBox>
          <TextBox style={{ color: neighboring ? DANGER_COLOR : SUCCESS_COLOR }}>
            {neighboring ? "저작인접권 만료" : "저작인접권 미만료"}
          </TextBox>
        </CopyrightBox>
      </RowGroup>
      <RowGroup>
        <h4>서비스 타입</h4>
        <TextBox>{OptionLicenseService.find(item => +item.id === isService)?.name || ""}</TextBox>
      </RowGroup>
      <GroupContainer>
        <RowGroup>
          <h4>트랙 타입</h4>
          <TextBox>{typeTrack}</TextBox>
        </RowGroup>
        <RowGroup>
          <h4>국가</h4>
          <TextBox>{countryCode}</TextBox>
        </RowGroup>
      </GroupContainer>
      <RowGroup>
        <h4>라이센스 설명</h4>
        <Multiline disabled defaultValue={description} />
      </RowGroup>
      <RowGroup>
        <h4>부가 정보</h4>
        {!companyIds
          ? null
          : Object.entries(companyIds).map((item, i) => (
              <ExtraInfo key={i}>
                <TextBox>{!item[0] ? undefined : item[0] === null ? "" : item[0]}</TextBox>
                <TextBox>{!item[1] ? undefined : (item[1] as any)}</TextBox>
              </ExtraInfo>
            ))}
      </RowGroup>
      <RowGroup>
        <h4>발매 일자</h4>
        <Date type="date" min="1000-01-01" max="2099-12-31" disabled defaultValue={!publishDate ? undefined : publishDate} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="cancelButton" onClick={toClose}>
          닫기
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
