/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const GetArtistRelationsInTracks = async (option: Option) => {
  const $artistId = <Variable name="artistId" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_ARTIST_RELATIONS_IN_TRACKS">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 99999,
          where: {
            type_metadata_class__in: ["record", "theater"],
            type_metadata_subclass: "track",
            metadata_artist_relation__some: {
              artist__some: {
                artist_id: $artistId
              }
            }
          }
        }}
      >
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            where: {
              artist__some: {
                artist_id: $artistId
              }
            }
          }}
        >
          <Field name="id" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="artistId" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: Readonly<{
      artistRelation: Readonly<{
        id: string;
        artist: Readonly<{
          artistId: string;
        }>[];
      }>[];
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
