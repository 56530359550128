/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_X_LARGE_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  MARGING_X_LARGE_PX,
  BORDER_RADIUS_PX
} from "constants/size";
import { GRAY_4, WHITE, GRAY_6, GRAY_5, BLACK } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, SECONDARY_COLOR, DANGER_COLOR_LIGHT, DANGER_COLOR, PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { useCsvUploadStore } from "App/Routes/CsvUpload/Store";
import { CsvUploadActions } from "App/Routes/CsvUpload/Store/CsvUpload";
import { WorkTagAutoComplete } from "App/Molecules/AutoCompletes/Work";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { GenreModal } from "App/Routes/CsvUpload/Modals/GenreModal";
import itiriri from "itiriri";
import { MusicDropzone } from "App/Routes/CsvUpload/Dropzone/index";
import { FloatingButton } from "App/Atomics/FloatingButton";
import { ReactComponent as TrackIcon } from "assets/icons/music.svg";
import { ArtistModal } from "App/Routes/CsvUpload/Modals/ArtistModal";
import dayjs from "dayjs";

type Props = {
  index: number;
  trackIndex: number;
  scrollToElement: () => void;
};

const noticeOptionList = [
  { id: "ALBUM_UCI", name: "ALBUM_UCI" },
  { id: "UCI", name: "UCI" },
  { id: "ISRC", name: "ISRC" }
];

export const TrackForm = ({ index, trackIndex, scrollToElement }: Props) => {
  const [{ album, track, albumLength, trackLength }, dispatch] = useCsvUploadStore(store => ({
    album: store.CsvUpload.albumInfo[index],
    track: store.CsvUpload.albumInfo[index].trackInfo[trackIndex],
    albumLength: store.CsvUpload.albumInfo.length,
    trackLength: store.CsvUpload.albumInfo[index].trackInfo.length
  }));
  const scrollRef = useRef<HTMLHeadingElement>(null);

  const genreModal = useToggle();
  const artistModal = useToggle();

  const setTrackFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = e => {
      dispatch(
        CsvUploadActions.setCsvTrackUrl(index, trackIndex, file.files![0], e.target?.result as string, fileType, file.files![0].name)
      );
    };
  };

  const checkAlbumInComplete =
    album.no === undefined ||
    !album.albumTitle ||
    !album.albumArtist ||
    !album.albumArtist.length ||
    !album.rightsCompany ||
    !album.releaseDate;

  const checkTrackInComplete =
    track.trackNo === undefined ||
    !track.trackName ||
    !track.trackArtist ||
    !track.trackArtist.length ||
    !track.trackLicenseExtra.record_year;

  const moveNextTrack = () => {
    if (trackIndex === trackLength - 1) {
      if (checkAlbumInComplete || checkTrackInComplete) {
        if (!window.confirm("작성되지 않은 필수 정보가 있습니다. 그대로 진행하시겠습니까?")) {
          return;
        }
      }
      scrollToElement();
      dispatch([CsvUploadActions.setCsvAlbumIndex(index + 1), CsvUploadActions.setCsvTrackIndex(0)]);
    } else {
      if (checkTrackInComplete) {
        if (!window.confirm("작성되지 않은 트랙 필수 정보가 있습니다. 그대로 진행하시겠습니까?")) {
          return;
        }
      }
      scrollRef.current!.scrollIntoView({ block: "center", behavior: "smooth" });
      dispatch(CsvUploadActions.setCsvTrackIndex(trackIndex + 1));
    }
  };

  // initialize track
  useEffect(() => {
    if (!track.trackLicenseExtra.record_year && !track.trackLicenseExtra.publish_year && !track.trackLicenseExtra.first_edition) {
      const year = parseInt(dayjs(new Date()).format("YYYY"), 10);
      dispatch([
        CsvUploadActions.setCsvTrackExtraRecord(index, trackIndex, year),
        CsvUploadActions.setCsvTrackExtraPublish(index, trackIndex, year),
        CsvUploadActions.setCsvTrackExtraEdition(index, trackIndex, year)
      ]);
    }
  }, [
    dispatch,
    index,
    track.trackLicenseExtra.first_edition,
    track.trackLicenseExtra.publish_year,
    track.trackLicenseExtra.record_year,
    trackIndex
  ]);

  return (
    <Layout>
      <Title>
        <TrackIcon />
        <h2 ref={scrollRef}>트랙정보 입력</h2>
      </Title>
      <CustomDivider />
      <RowGroup>
        <h4>트랙 번호*</h4>
        <Input.Text
          key={`${index}-track${trackIndex}`}
          isRequired
          placeholder="트랙 번호 입력하세요."
          defaultValue={track.trackNo}
          onBlur={no => dispatch(CsvUploadActions.setCsvTrackNo(index, trackIndex, no))}
        />
      </RowGroup>
      <RowGroup>
        <h4>트랙 타이틀*</h4>
        <Input.Text
          key={`${index}-track${trackIndex}`}
          isRequired
          placeholder="제목을 입력하세요."
          defaultValue={track.trackName}
          onBlur={title => dispatch(CsvUploadActions.setCsvTrackTitle(index, trackIndex, title))}
        />
      </RowGroup>
      <RowGroup>
        <h4>트랙 아티스트 (타입)*</h4>
        <div
          className="grid-box"
          onClick={e => {
            e.preventDefault();
            artistModal.on();
          }}
        >
          <TextBox
            key={`${index}-track${trackIndex}`}
            style={{ color: !track.trackArtist || !track.trackArtist.length ? GRAY_6 : BLACK }}
            className="autocomplete"
          >
            {track.trackArtist && track.trackArtist.length
              ? itiriri(track.trackArtist)
                  .toArray(artist => `${artist.artist_name}(${artist.role_name})`)
                  .join(", ")
              : "아티스트를 선택해주세요."}
          </TextBox>
          <Input.Button className="search-button" color="default" isWide onClick={artistModal.on}>
            검색
          </Input.Button>
        </div>
      </RowGroup>
      <RowGroup>
        <h4>작품</h4>
        <WorkTagAutoComplete
          key={`${index}-track${trackIndex}`}
          className="autocomplete"
          subClass="track"
          defaultValue={
            track.work &&
            track.work.map(({ id, title }) => ({
              id,
              name: title
            }))
          }
          onBlur={info => {
            if (info) {
              const workInfo = info.map(({ id, name }, order) => ({ id, title: name, order }));
              dispatch(CsvUploadActions.setCsvTrackWork(index, trackIndex, workInfo));
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>장르 / 무드</h4>
        <div
          className="grid-box"
          onClick={e => {
            e.preventDefault();
            genreModal.on();
          }}
        >
          <TextBox key={`${index}-track${trackIndex}`} style={{ color: !track.trackGenre ? GRAY_6 : BLACK }} className="autocomplete">
            {track.trackGenre
              ? itiriri(track.trackGenre)
                  .toArray(genre => genre.name)
                  .join(", ")
              : "장르를 선택해주세요."}
          </TextBox>
          <Input.Button className="search-button" color="default" isWide onClick={genreModal.on}>
            검색
          </Input.Button>
        </div>
      </RowGroup>

      <RowGroup>
        <h4>라이센스</h4>
        <Input.TextSelect
          key={`${index}-track${trackIndex}-${track.trackLicense.notice}`}
          className="autocomplete"
          placeholder={!album.rightsCompany ? "권리사 선택 후, 라이센스를 선택할 수 있습니다." : "라이센스를 선택해주세요."}
          isDisabled={!album.rightsCompany}
          optionList={!album.rightsCompany ? [] : [{ id: "VL", name: album.rightsCompany.license }]}
          defaultValue={!album.rightsCompany || !track.trackLicense.notice ? undefined : { id: "VL", name: album.rightsCompany.license }}
          onChange={info => {
            if (info) {
              const notice = JSON.stringify({ text: `License : ${album.rightsCompany.license}` });
              dispatch(CsvUploadActions.setCsvTrackLicense(index, trackIndex, notice, "VL", album.releaseDate));
            }
          }}
        />
      </RowGroup>

      <RowGroup>
        <h4>라이센스 설명</h4>
        <Input.Multiline
          isDisabled={!track.trackLicense.notice}
          className="licenseDetail"
          placeholder={"라이센스 설명을 입력하세요."}
          value={
            track.trackLicense.notice && JSON.parse(track.trackLicense.notice).description
              ? JSON.parse(track.trackLicense.notice).description
              : ""
          }
          onBlur={value => dispatch(CsvUploadActions.setCsvTrackLicenseNoticeSelected(index, trackIndex, "description", value))}
        />
      </RowGroup>
      {!track.trackLicense.notice ? null : (
        <RowGroup>
          <h4>부가 정보</h4>
          {!JSON.parse(track.trackLicense.notice).companyIds
            ? null
            : Object.entries(JSON.parse(track.trackLicense.notice).companyIds).map((item, i) => (
                <ExtraInfo key={`${index}-track${trackIndex}-${item[0]}`}>
                  <RoundButton
                    color="danger"
                    onClick={() => dispatch(CsvUploadActions.removeCsvTrackLicenseNoticeSelected(index, trackIndex, i))}
                  >
                    -
                  </RoundButton>
                  <Input.TextSelect
                    placeholder="이름"
                    optionList={noticeOptionList}
                    defaultValue={!item[0] ? undefined : item[0] === null ? { id: "", name: "" } : { id: item[0], name: item[0] }}
                    onBlur={info => {
                      if (info) {
                        dispatch(CsvUploadActions.setCsvTrackLicenseNoticeCompanyIdSelected(index, trackIndex, i, info.name, null));
                      }
                    }}
                  />
                  <Input.TextSelect
                    placeholder="값을 입력하세요"
                    optionList={!track.extraInfo.length ? [] : track.extraInfo?.map(info => ({ id: info, name: info }))}
                    defaultValue={!item[1] ? undefined : { id: item[1] as string, name: item[1] as string }}
                    onChange={info => {
                      if (info) {
                        dispatch(CsvUploadActions.setCsvTrackLicenseNoticeCompanyIdSelected(index, trackIndex, i, null, info.name));
                      }
                    }}
                  />
                </ExtraInfo>
              ))}
          <RoundButton
            color="primary"
            onClick={() => dispatch(CsvUploadActions.setCsvTrackLicenseNoticeCompanyIdSelected(index, trackIndex, 0, null, null))}
          >
            +
          </RoundButton>
        </RowGroup>
      )}

      <RowGroup>
        <h4>녹음* / 발매 / 초판 연도</h4>
        <YearGroup>
          <input
            type="number"
            key={`${index}-track${trackIndex}`}
            placeholder="녹음연도를 입력하세요. (*형식: YYYY)"
            min={0}
            max={2099}
            step={1}
            defaultValue={track.trackLicenseExtra.record_year ? parseInt(track.trackLicenseExtra.record_year) : undefined}
            onChange={e => {
              dispatch([CsvUploadActions.setCsvTrackExtraRecord(index, trackIndex, parseInt(e.target.value))]);
            }}
          />
          <input
            type="number"
            key={`${index}-track${trackIndex}-publish`}
            placeholder="발매연도를 입력하세요. (*형식: YYYY)"
            min={0}
            max={2099}
            step={1}
            defaultValue={track.trackLicenseExtra.publish_year ? parseInt(track.trackLicenseExtra.publish_year) : undefined}
            onChange={e => {
              dispatch(CsvUploadActions.setCsvTrackExtraPublish(index, trackIndex, parseInt(e.target.value)));
            }}
          />
          <input
            type="number"
            key={`${index}-track${trackIndex}-edition`}
            placeholder="초판 발매연도를 입력하세요. (*형식: YYYY)"
            min={0}
            max={2099}
            step={1}
            defaultValue={track.trackLicenseExtra.first_edition ? parseInt(track.trackLicenseExtra.first_edition) : undefined}
            onChange={e => {
              dispatch(CsvUploadActions.setCsvTrackExtraEdition(index, trackIndex, parseInt(e.target.value)));
            }}
          />
        </YearGroup>
      </RowGroup>
      <RowGroup>
        <h4>음원 등록</h4>
        <FileGroup>
          <DropContainer>
            <MusicDropzone
              index={index}
              trackIndex={trackIndex}
              accept="audio/mpeg"
              type="mp3"
              fileType="mp3high"
              isUploaded={!!track.trackUrl.trackMp3.url}
              onChange={e => setTrackFile(e, "mp3high")}
            />
            <p>
              • 또는 여기에 <span>MP3</span> 파일을 끌어놓으세요.
            </p>
          </DropContainer>
          <DropContainer>
            <MusicDropzone
              index={index}
              trackIndex={trackIndex}
              accept="audio/x-m4a"
              type="aac"
              fileType="aac"
              isUploaded={!!track.trackUrl.trackAac.url}
              onChange={e => setTrackFile(e, "aac")}
            />
            <p>
              • 또는 여기에 <span>AAC</span> 파일을 끌어놓으세요.
            </p>
          </DropContainer>
          <DropContainer>
            <MusicDropzone
              index={index}
              trackIndex={trackIndex}
              accept="audio/flac"
              type="flac"
              fileType="flac"
              isUploaded={!!track.trackUrl.trackFlac.url}
              onChange={e => setTrackFile(e, "flac")}
            />
            <p>
              • 또는 여기에 <span>FLAC</span> 파일을 끌어놓으세요.
            </p>
          </DropContainer>
          <DropContainer>
            <MusicDropzone
              index={index}
              trackIndex={trackIndex}
              accept="audio/wav"
              type="wav"
              fileType="wav"
              isUploaded={!!track.trackUrl.trackWav.url}
              onChange={e => setTrackFile(e, "wav")}
            />
            <p>
              • 또는 여기에 <span>WAV</span> 파일을 끌어놓으세요.
            </p>
          </DropContainer>
        </FileGroup>
      </RowGroup>
      <RowGroup>
        <MusicInfoWrapper>
          <Input.Text
            key={`${index}-track${trackIndex}-mp3`}
            isRequired
            className="music-info"
            placeholder="선택된 MP3 파일 없음."
            value={!track.trackUrl.trackMp3.url ? "" : track.trackUrl.trackMp3.url}
          />
          <Input.Text
            key={`${index}-track${trackIndex}-aac`}
            isRequired
            className="music-info"
            placeholder="선택된 AAC 파일 없음."
            value={!track.trackUrl.trackAac.url ? "" : track.trackUrl.trackAac.url}
          />
          <Input.Text
            key={`${index}-track${trackIndex}-flac`}
            isRequired
            className="music-info"
            placeholder="선택된 FLAC 파일 없음."
            value={!track.trackUrl.trackFlac.url ? "" : track.trackUrl.trackFlac.url}
          />
          <Input.Text
            key={`${index}-track${trackIndex}-wav`}
            isRequired
            className="music-info"
            placeholder="선택된 WAV 파일 없음."
            value={!track.trackUrl.trackWav.url ? "" : track.trackUrl.trackWav.url}
          />
        </MusicInfoWrapper>
      </RowGroup>

      {!(index === albumLength - 1 && trackIndex === trackLength - 1) && (
        <FloatingButton
          style={{ fontSize: "0.85rem" }}
          color={
            trackIndex === trackLength - 1
              ? checkAlbumInComplete || checkTrackInComplete
                ? "danger"
                : "warning"
              : checkTrackInComplete
              ? "danger"
              : "success"
          }
          onClick={moveNextTrack}
        >
          {trackIndex === trackLength - 1 ? "다음 앨범" : "다음 트랙"}
        </FloatingButton>
      )}
      <Modal isOpen={genreModal.isToggled}>
        <GenreModal
          genreList={track.trackGenre && track.trackGenre.length ? track.trackGenre : []}
          index={index}
          trackIndex={trackIndex}
          toClose={genreModal.off}
        />
      </Modal>
      <Modal isOpen={artistModal.isToggled}>
        <ArtistModal
          index={index}
          trackIndex={trackIndex}
          artistList={track.trackArtist && track.trackArtist.length ? track.trackArtist : []}
          toClose={artistModal.off}
        />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;
  background: ${WHITE};
  padding: ${PADDING_XX_LARGE_PX};
  user-select: text;
  h2 {
    text-align: left;
  }

  h4 {
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  .autocomplete {
    width: 100%;
  }

  input {
    width: 100%;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }

  .button-group {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: ${MARGING_LARGE_PX} 0;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${MARGING_LARGE_PX};

  svg {
    width: ${pixelize(UNIT * 1.5)};
    height: ${pixelize(UNIT * 1.5)};
  }
`;

const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_X_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${PADDING_LARGE_PX};

  h4 {
    span {
      font-weight: 400;
      font-size: 0.9rem;
      color: ${DANGER_COLOR_LIGHT};
      margin-left: ${MARGING_SMALL_PX};
    }
  }

  input[type="text"],
  input[type="file"],
  input[type="number"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
  input[type="file"] {
    font-size: 0.9rem;
  }

  .grid-box {
    width: 100%;
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-gap: ${MARGING_SMALL_PX};
    align-items: center;
  }

  .search-button {
    height: ${pixelize(UNIT * 2.85)};
  }

  .licenseDetail {
    height: ${pixelize(UNIT * 5)};
  }
`;

const FileGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  justify-content: space-around;
  align-items: center;
`;

const DropContainer = styled.div`
  width: ${pixelize(UNIT * 9.375)};
  p {
    margin-top: ${MARGING_X_LARGE_PX};
    display: inline-block;
    font-size: 0.8rem;
    color: ${GRAY_4};
    line-height: 1.5;
    white-space: pre-wrap;
    text-align: left;
    span {
      text-decoration: underline ${GRAY_5};
    }
  }
`;

const YearGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
`;

const ExtraInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 250px auto;
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
  margin-bottom: ${MARGING_SMALL_PX};
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => (props.color === "danger" ? DANGER_COLOR : PRIMARY_COLOR)};
`;

const TextBox = styled.div`
  width: 100%;
  height: 2.8rem;
  display: flex;
  align-items: center;
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  padding: ${PADDING_LARGE_PX};
  overflow-x: scroll;
  background-color: ${WHITE};
`;

const MusicInfoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  .music-info {
    width: 12rem !important;
  }
`;
