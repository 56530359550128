/** @jsx jsx */

import { jsx, render, Field, Variable, SelectionSet, Query, StringScalar, ListScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  title: string;
  artistList?: string[];
}>;

export const AlbumOverlapCheck = async (option: Option) => {
  const $title = <Variable name="title" scalar={<StringScalar />} value={option.title} />;
  const $artistList = <ListScalar scalar={<IdScalar />} value={option.artistList} />;
  const { query, variables } = render(
    <Query operationName="ALBUM_OVERLAP_CHECK">
      <SelectionSet
        name="metadata"
        argument={{
          where: {
            title: $title,
            valid_check__not: <ValidTypeScalar value={ValidType.REMOVE} />,
            type_metadata_class: "record",
            type_metadata_subclass: "track",
            metadata_artist_relation__some: {
              artist__some: {
                artist_id__in: $artistList
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet name="metadata_artist_relation" alias="artistRelation">
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: ReadonlyArray<{
      id: string;
      title: string;
      artistRelation: {
        artist: {
          id: string;
          name: string;
        }[];
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
