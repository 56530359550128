/** @jsx jsx */

import { jsx, render, Field, Variable, SelectionSet, Query, IntScalar, EnumScalar, ListScalar, StringScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  page: number;
  first?: number;
  skip?: number;
  id?: string;
  name?: string;
  languageCode?: string;
  languageCodeIn?: string[];
  validCheckIn?: string[];
  expose?: number;
}>;

export type MetadataLanguageRelation = {
  uuid: string;
  metadata: {
    metadataId: string;
  }[];
  typeKind: string;
  order: number;
};

export type LanguageTitle = {
  uuid: string;
  typeTitle: string;
  value: string;
  order: number;
  languageCode: string;
  displayCode: string;
};

export type Language = {
  languageCode: string;
  name: string;
  validCheck: ValidType;
  metadataLanguageRelation: MetadataLanguageRelation[];
  languageTitle: LanguageTitle[];
};

export const GetLanguage = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar />} value={option.page} />;
  const $skip = <Variable name="skip" scalar={<IntScalar isNullable />} value={option.skip} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $languageCode = <Variable name="languageCode" scalar={<IdScalar isNullable />} value={option.languageCode} />;
  const $languageCodeIn = !option.languageCodeIn ? (
    undefined
  ) : (
    <ListScalar scalar={<StringScalar />} isNullable value={option.languageCodeIn} />
  );
  const $validCheckIn = !option.validCheckIn ? (
    undefined
  ) : (
    <ListScalar scalar={<ValidTypeScalar />} isNullable value={option.validCheckIn} />
  );
  const { query, variables } = render(
    <Query operationName="GET_LANGUAGE">
      <SelectionSet
        name="language"
        argument={{
          group: true,
          first: $first,
          page: $page,
          skip: $skip,
          orderBy: <EnumScalar type="LanguageOrderByInput" value="language_code__ASC" items={["language_code__ASC"]} />,
          where: {
            name__contains: $name,
            language_code: $languageCode,
            language_code__in: $languageCodeIn,
            valid_check__in: $validCheckIn
          }
        }}
      >
        <Field name="language_code" alias="languageCode" />
        <Field name="name" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet name="metadata_language_relation" alias="metadataLanguageRelation">
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <Field name="type_kind" alias="typeKind" />
          <SelectionSet name="metadata">
            <Field name="metadata_id" alias="id" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="language_title" alias="languageTitle" argument={{ first: 1 }}>
          <Field name="id" alias="uuid" />
          <Field name="type_title" alias="typeTitle" />
          <Field name="value" />
          <Field name="exposure_order" alias="order" />
          <Field name="language_code" alias="languageCode" />
          <Field name="display_code" alias="displayCode" />
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "language",
          first: $first,
          page: $page,
          skip: $skip,
          where: {
            language: {
              name__contains: $name,
              language_code: $languageCode,
              language_code__in: $languageCodeIn,
              valid_check__in: $validCheckIn
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    language: Language[];
    edge: Edge;
  }>;
  const { errors, data } = await clients.language.request<Data>(query, variables);

  if (errors || !data) {
    throw errors;
  }
  return data;
};
