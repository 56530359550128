import { UpdateMetadataStructure } from "GraphQL/Queries/Metadata";
import { Toast } from "lib/toast";

export const useUpdateTrackParent = () => {
  const updateTrackParent = async (structureIds: string[], parentId: number) => {
    try {
      for (const structureId of structureIds) {
        await UpdateMetadataStructure({ structureId, parentId });
      }
    } catch (err) {
      Toast.error("트랙 이동 중 오류가 발생하였습니다.");
      return;
    }
  };
  return { updateTrackParent };
};
