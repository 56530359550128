import { Style } from "App/Routes/GoodsDetail/Style";
import { HEADER_HEIGHT_PX } from "constants/size";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { useProductDetailSelector } from "../Store";
import { ProductDate, ProductQuality, ProductBarcode, ProductUrls, ProductPrice, ProductSubdataOption, ProductGoods } from "./Form";

export const ProductDetailForm = (): ReactElement => {
  const { goods, quality, barcode, price, urls, createdAt } = useProductDetailSelector(store => store.ProductDetail.product);
  return (
    <Layout>
      <Style.Title>상품 관리 및 편집</Style.Title>
      <ProductGoods goods={goods} />
      <ProductSubdataOption goods={goods} />
      <ProductQuality key={quality} quality={quality} />
      <ProductBarcode key={barcode} barcode={barcode} />
      <ProductPrice key={price} price={price} />
      <ProductDate createdAt={createdAt} />
      <ProductUrls urls={urls} />
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  width: 100%;
  max-width: 760px;
  margin: ${HEADER_HEIGHT_PX} auto 0;
  padding: 60px 26px 140px;
`;
