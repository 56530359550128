import React, { useState } from "react";
import styled from "styled-components";
import { TagSelect } from "App/Atomics/Input/Select";
import { Info } from "App/Atomics/Input/Select/TagSelect";
import { usePlaylistCreateStore } from "App/Routes/PlaylistCreate/Store";
import { PlaylistCreateActions } from "App/Routes/PlaylistCreate/Store/TrackList";
import { getTrackList } from "./getTrackList";
import { Input } from "App/Atomics/Input";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { BORDER_RADIUS_PX, MARGING_LARGE_PX, pixelize, UNIT } from "constants/size";
import { CheckDuplicatedTrackInPlaylist } from "GraphQL/Queries/Playlist";
import { Toast } from "lib/toast";

const Layout = styled.div`
  .simpleSearch__control {
    border: none;

    &--is-focused {
      box-shadow: none;

      & > .simpleSearch__value-container {
        box-shadow: 0px 0px 1px 1px #4c52bc;
      }
    }

    & > .simpleSearch__value-container {
      margin-right: ${MARGING_LARGE_PX};
      border: 1px solid ${DEFAULT_BORDER_COLOR};
      border-radius: ${BORDER_RADIUS_PX};
    }

    & > .simpleSearch__indicators {
      & > div.simpleSearch__clear-indicator {
        position: absolute;
        right: ${pixelize(UNIT * 6)};
      }
    }
  }

  .simpleSearch__menu {
    & .simpleSearch__option.simpleSearch__option--is-focused {
      background-color: #ededf8;
    }
  }
`;

export const SimpleSearchForm = () => {
  const [{ idSet, serviceType, kind }, dispatch] = usePlaylistCreateStore(store => ({
    idSet: store.TrackList.idSet,
    serviceType: store.TrackList.serviceType,
    kind: store.TrackList.kind
  }));
  const [input, setInput] = useState("");
  const updateIdSet = (value: string) => {
    const whiteSpacePattern = /[ ,\-_/]+/g;
    const idList = value.split(whiteSpacePattern);
    if (idList.length === 1) {
      setInput(value.replace(whiteSpacePattern, ""));
      return;
    } else if (idList.length > 300) {
      alert("플레이리스트 최대 트랙은 300개입니다. 이후 트랙은 제외될 수 있습니다.");
    }
    const nextIdSet = new Set(idSet);
    for (const id of idList.slice(0, -1)) {
      if (id) {
        nextIdSet.add(id);
      }
    }
    const id = idList[idList.length - 1];
    if (id.length > 1) {
      nextIdSet.add(id);
      setInput("");
    } else {
      setInput(id);
    }
    dispatch(PlaylistCreateActions.setIdSet(nextIdSet));
  };
  const addId = (infoList: readonly Info[]) => {
    if (infoList.length) {
      const nextId = infoList[infoList.length - 1].id;
      const nextIdSet = new Set(idSet).add(nextId);
      dispatch(PlaylistCreateActions.setIdSet(nextIdSet));
      setInput("");
    } else {
      dispatch(PlaylistCreateActions.setIdSet(new Set()));
    }
  };
  const clearInput = () => setInput("");

  const loadTrackList = async () => {
    if (!idSet.size) {
      window.alert("1개 이상의 아이디를 입력해야 합니다");
      return;
    }

    dispatch(PlaylistCreateActions.updateLoadingState("Loading"));
    const trackList = await getTrackList(Array.from(idSet));
    if (trackList) {
      if (trackList.length > 300) {
        trackList.splice(300);
      }

      if (serviceType && kind?.length) {
        const metadataIdIn = trackList.map(({ id }) => id);
        const { data, errors } = await CheckDuplicatedTrackInPlaylist({ serviceType, kind, metadataIdIn });
        if (errors || !data) {
          Toast.error("중복 검사에 실패하였습니다", undefined, "top-center");
          return;
        }
        if (data) {
          const duplicateList = data.metadataPlaylistRelation.map(({ metadata }) => metadata[0].id);
          const newTrackList = trackList.map(list => {
            const isDuplicated = duplicateList.includes(list.id);
            return { ...list, duplicated: isDuplicated };
          });
          dispatch([
            PlaylistCreateActions.updateLoadingState("None"),
            PlaylistCreateActions.set({ kind: "searched", tracks: newTrackList })
          ]);
          dispatch(PlaylistCreateActions.setIdSet(new Set()));
        }
      } else {
        dispatch([PlaylistCreateActions.updateLoadingState("None"), PlaylistCreateActions.set({ kind: "searched", tracks: trackList })]);
        dispatch(PlaylistCreateActions.setIdSet(new Set()));
      }
    }
  };

  const optionList = !input || idSet.has(input) ? [] : [{ id: input, name: input }];

  return (
    <Layout>
      <TagSelect
        classNamePrefix="simpleSearch"
        placeholder="아이디를 입력해주세요"
        inputValue={input}
        value={Array.from(idSet, id => ({ id, name: id }))}
        optionList={optionList}
        indicator={() => (
          <Input.Button color="primary" onClick={loadTrackList}>
            검색하기
          </Input.Button>
        )}
        onInputChange={updateIdSet}
        onChange={addId}
        onBlur={clearInput}
      />
    </Layout>
  );
};
