import fileSaver from "file-saver";
import { Parser } from "json2csv";
import { Track } from "../Store/TrackList";

export const handleTrackInfoDownloadCsv = (trackData: readonly Track[]) => {
  let csvData = trackData.map(data => {
    return {
      track_id: data.id,
      title: data.title,
      mood: data.mood.map(({ name, id }) => `${name}(${id})`).join(","),
      genre: data.genre.map(({ name, id }) => `${name}(${id})`).join(","),
      duplicated: !!data.duplicated ? "등록됨" : "미등록"
    };
  });
  const parser = new Parser({
    fields: ["track_id", "title", "mood", "genre", "duplicated"]
  });
  const file = new Blob(["\ufeff", parser.parse(csvData)], { type: "text/csv;charset=utf-8;" });
  const fileName = `트랙 검색 결과 [${new Date().getTime()}].csv`;
  fileSaver.saveAs(file, fileName);
  return;
};
