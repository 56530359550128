import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_9, GRAY_2 } from "constants/baseColor";
import { WARNING_COLOR } from "constants/color";

export const FilterBox = styled.div`
  margin-top: 1rem;
`;

export const FilterWrapper = styled.div`
  border: 1px solid #e4e5ed;
`;

export const FilterItem = styled.div`
  display: flex;
  padding: 6px 0;
  border-bottom: 1px solid #e4e5ed;
  span {
    line-height: 24px;
    color: #303441;
    font-weight: 500;
  }

  .item-label {
    min-width: 168px;
    flex-basis: 168px;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 0 20px;
    align-items: flex-start;
    background-color: #fafafc;
    border-right: 1px solid #e4e5ed;
  }

  .item-option-wrapper {
    display: flex;
    padding: 2px 12px;
    min-height: 44px;
    .item-option {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }
  }
`;

export const OptionButton = styled.button<{ isActive?: boolean }>`
  position: relative;
  margin-inline-end: 6px 12px 6px 0;
  line-height: 24px;
  margin: 2px 4px;
  padding: 4px 12px;
  border: none;
  border-radius: 14px;
  color: ${props => (props.isActive ? WHITE : GRAY_9)};
  transition: background-color 0.1s;
  background-color: ${props => (props.isActive ? WARNING_COLOR : "#fafafc")};
  &:hover {
    background-color: ${props => (props.isActive ? WARNING_COLOR : GRAY_2)};
  }
`;

export const InputNumber = styled(Input.Number)`
  width: 120px;
  margin: 2px 4px;
  padding: 4px 12px;
  border: 1px solid ${GRAY_2};
  &:focus {
    border-color: #ffdc00;
  }
`;

export const MultiSelect = styled(Input.TagSelect)`
  min-width: 250px;
`;

export const inputCustomStyle = {
  control: (base: any, state: any) => ({
    ...base,
    minWidth: 500,
    boxShadow: "#ddd",
    borderColor: state.isFocused ? "#ffdc00" : GRAY_2,
    ":hover": {
      borderColor: "#ffdc00"
    }
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "lightgray",
    fontWeight: 450
  })
};
