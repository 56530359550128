import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

export type TrackEditEvents = State["events"];

type Track = any;

type ID = string;
type MetadataId = ID;

type CreateEvent = {
  type: "Create";
  from?: string;
  to: string;
  column?: string;
};

type UpdateEvent = {
  type: "Update";
  from: string;
  to: string;
  column?: string;
};

type RemoveEvent = {
  type: "Remove";
  column: string;
  from?: string;
  to?: string;
};

type EventLog = CreateEvent | UpdateEvent | RemoveEvent;

const createInitialState = () => ({
  tracks: [] as Track[],
  events: {
    metadata: new Map<MetadataId, Record<string, EventLog>>(),
    titleRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    urlRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    artistRelation: new Map<MetadataId, Record<string, EventLog>>(),
    roleRelation: new Map<MetadataId, Record<string, EventLog>>(),
    licenseRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    extraRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>(),
    genreRelation: new Map<MetadataId, Record<string, EventLog>>(),
    workRelation: new Map<MetadataId, Record<string, EventLog>>(),
    infoRelation: new Map<MetadataId, Record<string, Map<string, EventLog>>>()
  }
});

export const TrackActions = createDuck({
  namespace: "WorkTrackEdit",
  createInitialState,
  reducers: {
    setTracks(state, tracks: Track[]) {
      state.tracks = tracks;
    },
    setTitleByIndex(state, { index, title }: { index: number; title: string }) {
      const track = state.tracks[index];
      const { metadataId } = track;
      const { metadata } = state.events;
      updateEventLog(metadataId, "", metadata, "title", track.title, title);
      track.title = title;
    },
    setValidCheckByIndex(state, { index, validCheck }: { index: number; validCheck: string }) {
      const track = state.tracks[index];
      const { metadataId } = track;
      const { metadata } = state.events;
      updateEventLog(metadataId, "", metadata, "valid_check", track.validCheck, validCheck);
      track.validCheck = validCheck;
    },
    setTrackTitleLanguageByIndex(state, { index, relationIndex, titleValue }: { index: number; relationIndex: number; titleValue: any }) {
      const track = state.tracks[index];
      const { metadataId, titles } = track;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { titleRelation } = state.events;

      updateMapEventLog(metadataId, relationId, titleRelation, "language_code", relation.languageCode, titleValue);
      relation.language[0].languageCode = titleValue;
    },
    setTrackTitleTypeByIndex(state, { index, relationIndex, titleValue }: { index: number; relationIndex: number; titleValue: any }) {
      const track = state.tracks[index];
      const { metadataId, titles } = track;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { titleRelation } = state.events;

      updateMapEventLog(metadataId, relationId, titleRelation, "type_metadata_title", relation.typeMetadataTitle, titleValue);
      relation.typeMetadataTitle = titleValue;
    },
    setTrackTitleValueByIndex(state, { index, relationIndex, titleValue }: { index: number; relationIndex: number; titleValue: any }) {
      const track = state.tracks[index];
      const { metadataId, titles } = track;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { titleRelation } = state.events;

      updateMapEventLog(metadataId, relationId, titleRelation, "value", relation.value, titleValue);
      relation.value = titleValue;
    },
    setArtistByIndex(
      state,
      { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: Info }
    ) {
      const track = state.tracks[index];
      const { metadataId, artists } = track;
      const relation = artists[relationIndex];
      const { relationId } = relation;
      const { artistId } = relation.artist[0];
      const { artistRelation } = state.events;

      updateEventLog(metadataId, relationId, artistRelation, column, artistId, value.id);
      relation.artist[0].artistId = value.id || artistId;
      relation.artist[0].name = value.name;
    },
    setTrackUrlTypeByIndex(state, { index, relationIndex, typeUrl }: { index: number; relationIndex: number; typeUrl: string }) {
      const track = state.tracks[index];
      const { metadataId, urls } = track;
      const relation = urls[relationIndex];
      const { relationId } = relation;
      const { urlRelation } = state.events;

      updateMapEventLog(metadataId, relationId, urlRelation, "type_url", relation.typeUrl, typeUrl);
      relation.typeUrl = typeUrl;
    },
    setTrackUrlByIndex(state, { index, relationIndex, url }: { index: number; relationIndex: number; url: string }) {
      const track = state.tracks[index];
      const { metadataId, urls } = track;
      const relation = urls[relationIndex];
      const { relationId } = relation;
      const { urlRelation } = state.events;

      updateMapEventLog(metadataId, relationId, urlRelation, "url", relation.url, url);
      relation.url = url;
    },
    setTrackUrlValidCheckByIndex(
      state,
      { index, relationIndex, fileValidCheck }: { index: number; relationIndex: number; fileValidCheck: string }
    ) {
      const track = state.tracks[index];
      const { metadataId, urls } = track;
      const relation = urls[relationIndex];
      const { relationId } = relation;
      const { urlRelation } = state.events;

      updateMapEventLog(metadataId, relationId, urlRelation, "valid_check", relation.fileValidCheck, fileValidCheck);
      relation.fileValidCheck = fileValidCheck;
    },
    setTrackLicenseCountryCode(
      state,
      { index, relationIndex, countryCode }: { index: number; relationIndex: number; countryCode: string }
    ) {
      const track = state.tracks[index];
      const { metadataId, licenses } = track;
      const relation = licenses[relationIndex];
      const { relationId } = relation;
      const { licenseRelation } = state.events;

      updateMapEventLog(metadataId, relationId, licenseRelation, "country_code", relation.countryCode, countryCode);
      relation.countryCode = countryCode;
    },
    setTrackLicenseNotice(state, { index, relationIndex, notice }: { index: number; relationIndex: number; notice: string }) {
      const track = state.tracks[index];
      const { metadataId, licenses } = track;
      const relation = licenses[relationIndex];
      const { relationId } = relation;
      const { licenseRelation } = state.events;

      const temp = !relation.notice ? {} : JSON.parse(decodeURIComponent(relation.notice));
      temp.text = notice;

      updateMapEventLog(metadataId, relationId, licenseRelation, "notice", relation.notice, JSON.stringify(temp));
      relation.notice = JSON.stringify(temp);
    },
    setTrackLicensePublishDate(
      state,
      { index, relationIndex, publishDate }: { index: number; relationIndex: number; publishDate: string }
    ) {
      const track = state.tracks[index];
      const { metadataId, licenses } = track;
      const relation = licenses[relationIndex];
      const { relationId } = relation;
      const { licenseRelation } = state.events;

      updateMapEventLog(metadataId, relationId, licenseRelation, "publish_date", relation.publishDate, publishDate);
      relation.publishDate = publishDate;
    },
    setTrackExtraRecordYear(state, { index, recordYear }: { index: number; recordYear: string }) {
      const track = state.tracks[index];
      const { metadataId, extras } = track;
      const relation = extras[0];
      const { relationId } = relation;
      const { extraRelation } = state.events;

      updateMapEventLog(metadataId, relationId, extraRelation, "record_year", relation.recordYear, recordYear);
      relation.recordYear = recordYear;
    },
    setTrackExtraPublishYear(state, { index, publishYear }: { index: number; publishYear: string }) {
      const track = state.tracks[index];
      const { metadataId, extras } = track;
      const relation = extras[0];
      const { relationId } = relation;
      const { extraRelation } = state.events;

      updateMapEventLog(metadataId, relationId, extraRelation, "publish_year", relation.publishYear, publishYear);
      relation.publishYear = publishYear;
    },
    setTrackExtraFirstEdition(state, { index, firstEdition }: { index: number; firstEdition: string }) {
      const track = state.tracks[index];
      const { metadataId, extras } = track;
      const relation = extras[0];
      const { relationId } = relation;
      const { extraRelation } = state.events;

      updateMapEventLog(metadataId, relationId, extraRelation, "first_edition", relation.firstEdition, firstEdition);
      relation.firstEdition = firstEdition;
    },
    setTrackExtraPlace(state, { index, place }: { index: number; place: string }) {
      const track = state.tracks[index];
      const { metadataId, extras } = track;
      const relation = extras[0];
      const { relationId } = relation;
      const { extraRelation } = state.events;

      updateMapEventLog(metadataId, relationId, extraRelation, "place", relation.place, place);
      relation.place = place;
    },
    setGenreByIndex(state, { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: Info }) {
      const track = state.tracks[index];
      const { metadataId, genres } = track;
      const relation = genres[relationIndex];
      const { relationId } = relation;
      const { genreId } = relation.genre[0];
      const { genreRelation } = state.events;

      updateEventLog(metadataId, relationId, genreRelation, column, genreId, value.id);
      relation.genre[0].genreId = value.id || genreId;
      relation.genre[0].name = value.name;
    },
    setRoleByIndex(state, { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: Info }) {
      const track = state.tracks[index];
      const { metadataId, artists } = track;
      const relation = artists[relationIndex];
      const { relationId } = relation;
      const { roleId } = relation.role[0];
      const { roleRelation } = state.events;

      updateEventLog(metadataId, relationId, roleRelation, column, roleId, value.id);
      relation.role[0].roleId = value.id || roleId;
      relation.role[0].name = value.name;
    },
    setTrackWorkByIndex(
      state,
      { index, relationIndex, column, value }: { index: number; relationIndex: number; column: string; value: Info }
    ) {
      const track = state.tracks[index];
      const { metadataId, works } = track;
      const relation = works[relationIndex];
      const { relationId } = relation;
      const { id } = relation.work[0];
      const { workRelation } = state.events;

      updateEventLog(metadataId, relationId, workRelation, column, id, value.id);
      relation.work[0].id = value.id || id;
      relation.work[0].title = value.name;
    },
    setTrackInfoChannelByIndex(state, { index, relationIndex, infoValue }: { index: number; relationIndex: number; infoValue: number }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { infoRelation } = state.events;
      updateMapEventLog(metadataId, uuid, infoRelation, "channel", relation.channel, infoValue);
      relation.channel = infoValue;
    },
    setTrackInfoDurationByIndex(state, { index, relationIndex, infoValue }: { index: number; relationIndex: number; infoValue: number }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { infoRelation } = state.events;
      updateMapEventLog(metadataId, uuid, infoRelation, "duration", relation.duration, infoValue);
      relation.duration = infoValue;
    },
    setTrackInfoBitrateByIndex(state, { index, relationIndex, infoValue }: { index: number; relationIndex: number; infoValue: number }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { infoRelation } = state.events;
      updateMapEventLog(metadataId, uuid, infoRelation, "bitrate", relation.bitrate, infoValue);
      relation.bitrate = infoValue;
    },
    setTrackInfoTempoByIndex(state, { index, relationIndex, infoValue }: { index: number; relationIndex: number; infoValue: number }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { infoRelation } = state.events;
      updateMapEventLog(metadataId, uuid, infoRelation, "tempo", relation.tempo, infoValue);
      relation.tempo = infoValue;
    },
    setTrackInfoTonalityByIndex(state, { index, relationIndex, infoValue }: { index: number; relationIndex: number; infoValue: string }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { infoRelation } = state.events;
      updateMapEventLog(metadataId, uuid, infoRelation, "tonality", relation.tonality, infoValue);
      relation.tonality = infoValue;
    },
    setTrackInfoTimeSignatureByIndex(
      state,
      { index, relationIndex, infoValue }: { index: number; relationIndex: number; infoValue: string }
    ) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { infoRelation } = state.events;
      updateMapEventLog(metadataId, uuid, infoRelation, "timeSignature", relation.timeSignature, infoValue);
      relation.timeSignature = infoValue;
    },

    // Create Event
    setCreateTitleByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, titles } = track;
      const { metadata } = state.events;
      const relationId = column + titles.length.toString();
      const emptyTitle = {
        relationId: relationId,
        language: [{ languageCode: undefined }],
        value: undefined
      };

      createEventLog(metadataId, relationId, metadata, column, "", index.toString());
      titles.push(emptyTitle);
    },
    setCreateArtistByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, artists } = track;
      const { metadata } = state.events;
      const emptyArtist = {
        relationId: column + artists.length.toString(),
        artist: [{ artistId: undefined, name: undefined }],
        exposureOrder: 0,
        role: [{ roleId: undefined, name: undefined }]
      };

      createEventLog(metadataId, column + artists.length.toString(), metadata, column, "", index.toString());
      artists.push(emptyArtist);
    },
    setCreateUrlByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, urls } = track;
      const { metadata } = state.events;
      const relationId = column + urls.length.toString();
      const emptyUrl = {
        relationId: relationId,
        typeUrl: undefined,
        url: undefined,
        validCheck: undefined
      };

      createEventLog(metadataId, relationId, metadata, column, "", index.toString());
      urls.push(emptyUrl);
    },
    setCreateLicenseByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, licenses } = track;
      const { metadata } = state.events;
      const relationId = column + licenses.length.toString();
      const emptyLicense = {
        relationId: relationId,
        countryCode: undefined,
        notice: undefined,
        typeTrack: undefined,
        publish_date: undefined
      };

      createEventLog(metadataId, relationId, metadata, column, "", index.toString());
      licenses.push(emptyLicense);
    },
    setCreateLicenseExtraByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, extras } = track;
      const { metadata } = state.events;
      const relationId = column + extras.length.toString();
      const emptyExtra = {
        relationId: relationId,
        recordYear: "",
        publishYear: "",
        firstEdition: "",
        place: "",
        data: ""
      };

      createEventLog(metadataId, relationId, metadata, column, "", index.toString());
      extras.push(emptyExtra);
    },
    setCreateGenreByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, genres } = track;
      const { metadata } = state.events;
      const emptyGenre = {
        relationId: column + genres.length.toString(),
        genre: [{ genreId: undefined, name: undefined, typeKind: column }]
      };

      createEventLog(metadataId, column + genres.length.toString(), metadata, column, "", index.toString());
      genres.push(emptyGenre);
    },
    setCreateWorkByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, works } = track;
      const { metadata } = state.events;
      const emptyWork = {
        relationId: column + works.length.toString(),
        work: [{ id: undefined, title: undefined, typeKind: column }]
      };
      createEventLog(metadataId, column + works.length.toString(), metadata, column, "", index.toString());
      works.push(emptyWork);
    },
    setCreateInfoByIndex(state, { index, column }: { index: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const { metadata } = state.events;
      const uuid = column + track_information.length.toString();
      const emptyInfo = {
        uuid: uuid,
        channel: undefined,
        duration: undefined,
        bitrate: undefined
      };
      createEventLog(metadataId, uuid, metadata, column, "", index.toString());
      track_information.push(emptyInfo);
    },

    // Remove Event
    setRemoveTitleByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, titles } = track;
      const relation = titles[relationIndex];
      const { relationId } = relation;
      const { metadata, titleRelation } = state.events;

      if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
        metadata.get(metadataId)![relationId].type = "Remove";
      } else {
        removeMapEventLog(metadataId, relationId, titleRelation, column, "", "");
      }

      relation.relationId = undefined;
    },
    setRemoveArtistByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, artists } = track;
      const relation = artists[relationIndex];
      const { relationId } = relation;
      const { metadata, artistRelation } = state.events;

      if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
        metadata.get(metadataId)![relationId].type = "Remove";
      } else {
        removeEventLog(metadataId, relationId, artistRelation, column, "", "");
      }

      relation.relationId = undefined;
    },
    setRemoveLicenseByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, licenses } = track;
      const relation = licenses[relationIndex];
      const { relationId } = relation;
      const { metadata, licenseRelation } = state.events;

      if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
        metadata.get(metadataId)![relationId].type = "Remove";
      } else {
        removeMapEventLog(metadataId, relationId, licenseRelation, column, "", "");
      }

      relation.relationId = undefined;
    },
    setRemoveGenreByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, genres } = track;
      const relation = genres[relationIndex];
      const { relationId } = relation;
      const { metadata, genreRelation } = state.events;

      if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
        metadata.get(metadataId)![relationId].type = "Remove";
      } else {
        removeEventLog(metadataId, relationId, genreRelation, column, "", "");
      }
      relation.relationId = undefined;
    },
    setRemoveWorkByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, works } = track;
      const relation = works[relationIndex];
      const { relationId } = relation;
      const { metadata, workRelation } = state.events;

      if (metadata.get(metadataId) && metadata.get(metadataId)![relationId]) {
        metadata.get(metadataId)![relationId].type = "Remove";
      } else {
        removeEventLog(metadataId, relationId, workRelation, column, "", "");
      }
      relation.relationId = undefined;
    },
    setRemoveInfoByIndex(state, { index, relationIndex, column }: { index: number; relationIndex: number; column: string }) {
      const track = state.tracks[index];
      const { metadataId, track_information } = track;
      const relation = track_information[relationIndex];
      const { uuid } = relation;
      const { metadata, infoRelation } = state.events;

      if (metadata.get(metadataId) && metadata.get(metadataId)![uuid]) {
        metadata.get(metadataId)![uuid].type = "Remove";
      } else {
        removeMapEventLog(metadataId, uuid, infoRelation, column, "", "");
      }
      relation.uuid = undefined;
    },
    // ETC Event
    setEventClear(state) {
      state.events.metadata.clear();
      state.events.artistRelation.clear();
      state.events.roleRelation.clear();
      state.events.genreRelation.clear();
      state.events.extraRelation.clear();
      state.events.licenseRelation.clear();
      state.events.titleRelation.clear();
      state.events.urlRelation.clear();
      state.events.infoRelation.clear();
    }
  }
});

const updateEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;

  if (relationId === "") {
    if (!eventLogs[column]) {
      eventLogs[column] = { type: "Update", from, to, column };
    } else {
      eventLogs[column].to = to;
    }
  } else {
    if (!eventLogs[relationId]) {
      eventLogs[relationId] = { type: "Update", from, to, column };
    } else {
      eventLogs[relationId].to = to;
    }
  }
};

const updateMapEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: any,
  to: any
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (!eventLogs[relationId].has(column)) {
    eventLogs[relationId].set(column, { type: "Update", from, to, column });
  } else {
    eventLogs[relationId].get(column)!.to = to;
  }
};

const createEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;
  if (!eventLogs[relationId]) {
    eventLogs[relationId] = { type: "Create", from, to, column };
  } else {
    eventLogs[relationId].to = to;
  }
};

const removeEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, EventLog>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;
  if (!eventLogs[relationId]) {
    eventLogs[relationId] = { type: "Remove", from, to, column };
  }
};

const removeMapEventLog = (
  metadataId: MetadataId,
  relationId: string,
  eventMap: Map<MetadataId, Record<string, Map<string, EventLog>>>,
  column: string,
  from: string,
  to: string
) => {
  if (!eventMap.has(metadataId)) {
    eventMap.set(metadataId, {});
  }
  const eventLogs = eventMap.get(metadataId)!;

  if (!eventLogs[relationId]) {
    eventLogs[relationId] = new Map<string, EventLog>();
  }

  if (eventLogs[relationId]) {
    eventLogs[relationId].set("Remove", { type: "Remove", from, to, column });
  }
};
