import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { ProductCreateStore } from "App/Routes/ProductCreate/Store";
import { ProductCreateActions } from "App/Routes/ProductCreate/Store/ProductCreate";

type Props = {
  readonly details: string;
};

export const ProductDetail = ({ details }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>기타 정보 *</Style.Caption>
    <Style.SubCaption>ex. * 상품에 대한 기타 정보를 입력해주세요.</Style.SubCaption>
    <Style.InputTextArea
      key={details}
      placeholder={"기타 정보"}
      defaultValue={details}
      onBlur={event => ProductCreateStore.dispatch(ProductCreateActions.setDetail(event.target.value))}
    />
  </Style.Row>
);
