/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
type Option = Readonly<{
  playlistId?: string;
  count?: number;
}>;

export const GetMetadataPlaylistRelationId = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.playlistId} />;
  const $count = <Variable name="count" scalar={<IntScalar />} value={option.count} />;
  const $validCheckNot = <Variable name="valid_check" scalar={<ValidTypeScalar />} value={ValidType.DONE} />;
  const { query, variables } = render(
    <Query operationName="METADATA_PLAYLIST_RELATION_ID">
      <SelectionSet
        name="metadata_playlist_relation"
        alias="metadataPlaylistRelationId"
        argument={{
          where: {
            playlist__some: {
              playlist_id: $playlistId
            },
            valid_check__not: $validCheckNot
          },
          first: $count,
          group: true
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataPlaylistRelationId: {
      id: string;
    }[];
  }>;
  const { data, errors } = await clients.playlist.request<Data>(query, variables);

  if (errors) {
    throw errors;
  }
  return data!.metadataPlaylistRelationId.map(({ id }) => id);
};
