import React from "react";
import styled from "styled-components";
import { SideNavigator } from "App/Molecules/SideNavigator";
import { useAppStore, useAppSelector } from "App/Store";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { UserTokenActions } from "App/Store/UserToken";
import {
  ALBUMS,
  ALBUM_CREATE,
  ARTISTS,
  ARTISTS_CREATE,
  GENRES,
  HOME,
  PLAYLIST,
  PLAYLIST_CREATE,
  PROFILE,
  SETTLEMENT,
  TASK_GRANT,
  WORKS,
  TRACKS,
  WORKS_CREATE,
  SEARCH,
  RIGHTS_CREATE,
  SIGN,
  ALBUM_DETAIL,
  AUTH_GRANT,
  CHANGE_PASSWORD,
  ADMIN_SIGUNUP,
  CSV_UPLOAD,
  ROLES,
  ROLES_CREATE,
  RIGHTS,
  GENRES_CREATE,
  ALBUM_RENEWAL_CREATE,
  LIVE_REPLAY,
  ARTISTS_RENEWAL,
  LANGUAGE,
  EFFECTS_CREATE,
  GOODS_CREATE,
  GOODS,
  PRODUCT_CREATE,
  PRODUCT,
  VIDEO_CREATE,
  ADMIN_RESET_PASSWORD,
  CREATE_AUTO_PLAYLIST
} from "constants/route";
import { pixelize, UNIT, widthViewportize } from "constants/size";
import { UserRole } from "constants/UserRole";
import { ReactComponent as MainIcon } from "assets/icons/main.svg";
import { ReactComponent as ContentsUploadIcon } from "assets/icons/contents-upload.svg";
import { ReactComponent as ContentsSetIcon } from "assets/icons/contents-set.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as UserSetIcon } from "assets/icons/user-set.svg";
import { ReactComponent as LoginIcon } from "assets/icons/login.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as SearchIcon } from "assets/icons/banner-search.svg";
import { ReactComponent as CloseIcon } from "assets/icons/cancel-button.svg";
import { useHistory } from "react-router";
import {
  allowTaskStatus,
  allowArtistCreate,
  allowArtistRead,
  allowAuthGrant,
  allowCompanyCreate,
  allowCompanyRead,
  allowCreateAccount,
  allowGenreCreate,
  allowGenreRead,
  allowMetadataCreate,
  allowMetadataRead,
  allowPlaylistCreate,
  allowPlaylistRead,
  allowRecordManage,
  allowRoleCreate,
  allowRoleRead,
  allowStatistics,
  allowLanguageRead,
  allowGoodsCreate,
  allowGoodsRead
} from "App/Routes/AdminRoutes/allowTables";
import { AppStore } from "App/Store-v3";

const Button = styled.a`
  width: 100%;
  padding: ${pixelize(1.25 * UNIT)} ${widthViewportize(0.15 * UNIT)};
  text-align: left;
  font-size: 1.1rem;
  opacity: 0.5;
  transition: all 0.2s;

  & svg {
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
    top: 4px;
    position: relative;
    margin-right: ${pixelize(0.5 * UNIT)};
  }

  &:hover {
    opacity: 1;
  }
`;

const Brand = styled.div`
  font-family: Rubik;
  height: ${pixelize(8 * UNIT)};
  display: grid !important;
  align-items: center;
  justify-items: center;
  font-weight: bold;
  font-size: 2rem;
`;

export const SideNavigatorTemplate = () => {
  const router = useHistory();
  const [userInfo, dispatch] = useAppStore(store => {
    const { email, role } = store.UserToken;
    return {
      isSignedIn: email,
      isAdmin: role === UserRole.Master || role === UserRole.Owner,
      isArbeit: role === UserRole.Arbeit,
      isPartner: role === UserRole.Partner,
      isClient: role === UserRole.Client
    };
  });
  const store = useAppSelector(store => store);
  const signOut = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      dispatch(UserTokenActions.signOut());
      window.location.reload();
    }
  };
  const closeModal = () => AppStore.dispatch(SidebarActions.close());

  const validateLinkTab = (category: string) => {
    switch (category) {
      case "create":
        const createSet = [
          GOODS_CREATE,
          ALBUM_CREATE,
          ALBUM_RENEWAL_CREATE,
          WORKS_CREATE,
          ARTISTS_CREATE,
          RIGHTS_CREATE,
          PLAYLIST_CREATE,
          ROLES_CREATE
        ];
        return createSet.some(value => router.location.pathname === value);
      case "view":
        const viewSet = [ALBUMS, TRACKS, WORKS, LIVE_REPLAY, ARTISTS, ARTISTS_RENEWAL, GENRES, PLAYLIST, ALBUM_DETAIL, LANGUAGE];
        return viewSet.some(value => router.location.pathname === value);
      case "profile":
        const profileSet = [PROFILE, CHANGE_PASSWORD];
        return profileSet.some(value => router.location.pathname === value);
      case "task":
        const taskSet = [TASK_GRANT, AUTH_GRANT];
        return taskSet.some(value => router.location.pathname === value);
      case "settle":
        const settleSet = [SETTLEMENT];
        return settleSet.some(value => router.location.pathname === value);
      default:
        return false;
    }
  };
  return (
    <SideNavigator.Layout>
      <CloseIcon className="close" onClick={closeModal} />
      <Brand>DMS</Brand>
      <SideNavigator.LinkTab to={HOME}>
        <MainIcon />
        메인
      </SideNavigator.LinkTab>
      {userInfo.isAdmin && (
        <SideNavigator.LinkTab to={SEARCH}>
          <SearchIcon />
          전체 검색
        </SideNavigator.LinkTab>
      )}
      {(userInfo.isAdmin || userInfo.isArbeit) && !userInfo.isPartner && (
        <SideNavigator.CollapsibleTab
          heading={
            <>
              <ContentsUploadIcon />
              컨텐츠 등록
            </>
          }
          isParent={validateLinkTab("create")}
        >
          {(userInfo.isAdmin || allowGoodsCreate(store)) && (
            <SideNavigator.LinkTab to={GOODS_CREATE} onClick={closeModal}>
              굿즈 정보 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowGoodsCreate(store)) && (
            <SideNavigator.LinkTab to={PRODUCT_CREATE} onClick={closeModal}>
              굿즈 상품 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowMetadataCreate(store)) && (
            <SideNavigator.LinkTab isDisabled={!userInfo.isAdmin && !allowMetadataCreate(store)} to={ALBUM_CREATE} onClick={closeModal}>
              음원 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowMetadataCreate(store)) && (
            <SideNavigator.LinkTab to={EFFECTS_CREATE} onClick={closeModal}>
              효과음 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowMetadataCreate(store)) && (
            <SideNavigator.LinkTab to={VIDEO_CREATE} onClick={closeModal}>
              영상 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowMetadataCreate(store)) && (
            <SideNavigator.LinkTab to={CSV_UPLOAD} onClick={closeModal}>
              CSV 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || !allowMetadataCreate(store)) && (
            <SideNavigator.LinkTab to={WORKS_CREATE} onClick={closeModal}>
              작품 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowArtistCreate(store)) && (
            <SideNavigator.LinkTab to={ARTISTS_CREATE} onClick={closeModal}>
              아티스트 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowCompanyCreate(store)) && (
            <SideNavigator.LinkTab to={RIGHTS_CREATE} onClick={closeModal}>
              권리사 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowRoleCreate(store)) && (
            <SideNavigator.LinkTab to={ROLES_CREATE} onClick={closeModal}>
              역할 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowGenreCreate(store)) && (
            <SideNavigator.LinkTab to={GENRES_CREATE} onClick={closeModal}>
              장르/무드 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowPlaylistCreate(store)) && (
            <SideNavigator.LinkTab to={PLAYLIST_CREATE} onClick={closeModal}>
              플레이리스트 등록
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowPlaylistCreate(store)) && (
            <SideNavigator.LinkTab to={CREATE_AUTO_PLAYLIST} onClick={closeModal}>
              플레이리스트 자동생성
            </SideNavigator.LinkTab>
          )}
        </SideNavigator.CollapsibleTab>
      )}
      {userInfo.isSignedIn && !userInfo.isClient && (
        <SideNavigator.CollapsibleTab
          heading={
            <>
              <ContentsSetIcon />
              컨텐츠 관리
            </>
          }
          isParent={validateLinkTab("view")}
        >
          {(userInfo.isAdmin || (!userInfo.isPartner && allowGoodsRead(store))) && (
            <SideNavigator.LinkTab to={GOODS} onClick={closeModal}>
              굿즈 정보 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowGoodsRead(store))) && (
            <SideNavigator.LinkTab to={PRODUCT} onClick={closeModal}>
              굿즈 상품 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowMetadataRead(store))) && (
            <SideNavigator.LinkTab to={ALBUMS} onClick={closeModal}>
              앨범 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowMetadataRead(store))) && (
            <SideNavigator.LinkTab to={WORKS} onClick={closeModal}>
              작품 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowArtistRead(store))) && (
            <SideNavigator.LinkTab to={ARTISTS} onClick={closeModal}>
              아티스트 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowArtistRead(store))) && (
            <SideNavigator.LinkTab to={ARTISTS_RENEWAL} onClick={closeModal}>
              아티스트 관리 (NEW)
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowCompanyRead(store))) && (
            <SideNavigator.LinkTab to={RIGHTS} onClick={closeModal}>
              권리사 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowRoleRead(store))) && (
            <SideNavigator.LinkTab to={ROLES} onClick={closeModal}>
              역할 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || (!userInfo.isPartner && allowGenreRead(store))) && (
            <SideNavigator.LinkTab to={GENRES} onClick={closeModal}>
              장르 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowPlaylistRead(store)) && (
            <SideNavigator.LinkTab to={PLAYLIST} onClick={closeModal}>
              플레이리스트 관리
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || !userInfo.isPartner || allowLanguageRead(store)) && (
            <SideNavigator.LinkTab to={LANGUAGE} onClick={closeModal}>
              언어팩 관리
            </SideNavigator.LinkTab>
          )}
        </SideNavigator.CollapsibleTab>
      )}
      {userInfo.isSignedIn && (
        <SideNavigator.CollapsibleTab
          heading={
            <>
              <ProfileIcon />
              프로필
            </>
          }
          isParent={validateLinkTab("profile")}
        >
          <SideNavigator.LinkTab to={PROFILE} onClick={closeModal}>
            내 정보
          </SideNavigator.LinkTab>
          <SideNavigator.LinkTab to={CHANGE_PASSWORD} onClick={closeModal}>
            비밀번호 변경
          </SideNavigator.LinkTab>
        </SideNavigator.CollapsibleTab>
      )}
      {userInfo.isSignedIn && (
        <SideNavigator.CollapsibleTab
          heading={
            <>
              <UserSetIcon />
              정산
            </>
          }
          isParent={validateLinkTab("settle")}
        >
          <SideNavigator.LinkTab isDisabled={!userInfo.isAdmin && !userInfo.isPartner} to={SETTLEMENT} onClick={closeModal}>
            정산 내역
          </SideNavigator.LinkTab>
        </SideNavigator.CollapsibleTab>
      )}
      {(userInfo.isAdmin ||
        (userInfo.isArbeit &&
          (allowCreateAccount(store) ||
            allowAuthGrant(store) ||
            allowTaskStatus(store) ||
            allowStatistics(store) ||
            allowRecordManage(store)))) && (
        <SideNavigator.CollapsibleTab
          heading={
            <>
              <UserSetIcon />
              관리 메뉴
            </>
          }
          isParent={validateLinkTab("task")}
        >
          {(userInfo.isAdmin || allowCreateAccount(store)) && (
            <SideNavigator.LinkTab isDisabled={!userInfo.isAdmin} to={ADMIN_SIGUNUP} onClick={closeModal}>
              계정 생성
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowCreateAccount(store)) && (
            <SideNavigator.LinkTab isDisabled={!userInfo.isAdmin} to={ADMIN_RESET_PASSWORD} onClick={closeModal}>
              비밀번호 초기화
            </SideNavigator.LinkTab>
          )}
          {(userInfo.isAdmin || allowAuthGrant(store)) && (
            <SideNavigator.LinkTab isDisabled={!userInfo.isAdmin} to={AUTH_GRANT} onClick={closeModal}>
              권한 등록
            </SideNavigator.LinkTab>
          )}
        </SideNavigator.CollapsibleTab>
      )}
      {userInfo.isSignedIn && (
        <Button onClick={signOut}>
          <LogoutIcon />
          로그아웃
        </Button>
      )}
      {!userInfo.isSignedIn && (
        <SideNavigator.LinkTab to={SIGN}>
          <LoginIcon />
          로그인
        </SideNavigator.LinkTab>
      )}
    </SideNavigator.Layout>
  );
};
