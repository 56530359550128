/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  metadataId: string;
  artistId: string;
  roleId: string;
  order: number;
}>;

export const CreateMetadataArtist = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $artistId = <Variable name="artist_id" scalar={<IdScalar />} value={option.artistId} />;
  const $roleId = <Variable name="role_id" scalar={<IdScalar />} value={option.roleId} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_ARTIST">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataArtist"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            _onlySearch: true,
            metadata_artist_relation: {
              create: [
                {
                  artist: {
                    connect: {
                      artist_id: $artistId
                    }
                  },
                  role: {
                    connect: {
                      role_id: $roleId
                    }
                  },
                  exposure_order: $order
                }
              ]
            }
          }
        }}
      >
        <SelectionSet name="metadata_artist_relation" alias="artistRelation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createMetadataArtist: {
      artistRelation: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
