/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum MediaType {
  image = "image",
  track = "track"
}

type Props = Readonly<{
  value?: MediaType;
  isNullable?: boolean;
}>;

const items = [MediaType.image, MediaType.track];

export const MediaTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="MediaType" value={value} isNullable={isNullable} items={items} />
);
