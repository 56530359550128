export const pixelize = (size: number) => `${size}px`;
export const percentize = (size: number) => `${size / 100}%`;
export const widthViewportize = (size: number) => `${size}vw`;

export const UNIT = 16;
export const UNIT_PX = pixelize(UNIT);

export const HEADER_HEIGHT = 4 * UNIT;
export const HEADER_HEIGHT_PX = pixelize(HEADER_HEIGHT);

export const SIDEBAR_WIDTH = 16.5 * UNIT;
export const SIDEBAR_WIDTH_PX = pixelize(SIDEBAR_WIDTH);

export const PADDING_XXX_LARGE = 64;
export const PADDING_XXX_LARGE_PX = pixelize(PADDING_XXX_LARGE);

export const PADDING_XX_LARGE = 32;
export const PADDING_XX_LARGE_PX = pixelize(PADDING_XX_LARGE);

export const PADDING_X_LARGE = 16;
export const PADDING_X_LARGE_PX = pixelize(PADDING_X_LARGE);

export const PADDING_LARGE = 8;
export const PADDING_LARGE_PX = pixelize(PADDING_LARGE);

export const PADDING_SMALL = 4;
export const PADDING_SMALL_PX = pixelize(PADDING_SMALL);

export const MARGING_XXX_LARGE = 64;
export const MARGING_XXX_LARGE_PX = pixelize(MARGING_XXX_LARGE);

export const MARGING_XX_LARGE = 32;
export const MARGING_XX_LARGE_PX = pixelize(MARGING_XX_LARGE);

export const MARGING_X_LARGE = 16;
export const MARGING_X_LARGE_PX = pixelize(MARGING_X_LARGE);

export const MARGING_LARGE = 8;
export const MARGING_LARGE_PX = pixelize(MARGING_LARGE);

export const MARGING_SMALL = 4;
export const MARGING_SMALL_PX = pixelize(MARGING_SMALL);

export const BORDER_RADIUS = 0.25 * UNIT;
export const BORDER_RADIUS_PX = pixelize(BORDER_RADIUS);

export const FONT_XXX_LARGE = 6 * UNIT;
export const FONT_XXX_LARGE_PX = pixelize(FONT_XXX_LARGE);

export const FONT_XX_LARGE = 4 * UNIT;
export const FONT_XX_LARGE_PX = pixelize(FONT_XX_LARGE);

export const FONT_X_LARGE = 2 * UNIT;
export const FONT_X_LARGE_PX = pixelize(FONT_X_LARGE);

export const FONT_LARGE = 1.5 * UNIT;
export const FONT_LARGE_PX = pixelize(FONT_LARGE);

export const FONT_MEDIUM = UNIT;
export const FONT_MEDIUM_PX = pixelize(FONT_MEDIUM);

export const FONT_SMALL = 0.875 * UNIT;
export const FONT_SMALL_PX = pixelize(FONT_SMALL);

export const FONT_X_SMALL = 0.75 * UNIT;
export const FONT_X_SMALL_PX = pixelize(FONT_X_SMALL);

export const BLUR = 4;
export const BLUR_PX = pixelize(BLUR);

export const CARD_PX = pixelize(200);
