export enum TrackPlayCountOrderByInput {
  id_ASC = "id__ASC",
  id_DESC = "id__DESC",
  metadata_id_ASC = "metadata_id__ASC",
  metadata_id_DESC = "metadata_id__DESC",
  servie_ASC = "servie__ASC",
  servie_DESC = "servie__DESC",
  timestamp_ASC = "time_stamp__ASC",
  timestamp_DESC = "time_stamp__DESC"
}
