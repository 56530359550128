import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_3, GRAY_6 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { MARGING_SMALL_PX } from "constants/size";
import { ReactComponent as NoDataIcon } from "assets/icons/no-data.svg";
import { ReactComponent as NewIcon } from "assets/icons/new.svg";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { useAsyncEffect } from "lib/use-async-effect";
import { Comment, GetComment } from "GraphQL/Queries/Access/GetComment";
import { TargetTableInput } from "constants/TargetTableInput";
import { CommentOrderByInput } from "constants/CommentOrderByInput";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ko";
import { AddedTrackInfo } from "../PlaylistModal";
import { Modal } from "lib/modal";
import { WriteCommentModal } from "../WriteCommentModal";
import { useToggle } from "lib/use-toggle";
import { Loading } from "App/Atomics/Loading";

type Props = {
  addedTrackInfo: AddedTrackInfo;
  toClose: () => void;
};

export const CommentModal = ({ addedTrackInfo, toClose }: Props) => {
  const [comment, setComment] = useState<Comment[]>([] as Comment[]);
  const [currentUuid, setCurrentUuid] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const writeCommentModal = useToggle();

  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const commentList = await GetComment({
          targetId: addedTrackInfo.playlistId!,
          targetTable: TargetTableInput.Playlist,
          order: CommentOrderByInput.timestamp_DESC,
          depth: 1
        });
        setComment(commentList);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        return;
      }
    }
  }, []);

  return (
    <Layout>
      <Header>
        <CommentIcon className="comment-icon" />
        <h3>댓글 목록</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <CommentForm>
          {!comment.length ? (
            <NoData>
              <NoDataIcon />
              <label>
                댓글이 없습니다. <span onClick={writeCommentModal.on}>새로운 댓글</span>에 작성하시겠습니까?
              </label>
            </NoData>
          ) : (
            comment.map(({ uuid, value, timestamp, users }) => {
              dayjs.locale("ko");
              dayjs.extend(relativeTime);
              const fromNow = dayjs(timestamp).fromNow();
              const { email } = users![0];
              const isNew = (Date.now() - new Date(timestamp).getTime()) / 1000 / 60 / 60 < 22;
              return (
                <div key={uuid} className="grid">
                  <DescCard>
                    <div className="titleContainer">
                      <div className="titleInfo">
                        <div className="title">
                          <span>{`${email}`}</span>
                        </div>
                        <span className="date">{fromNow}</span>
                        {isNew && <NewIcon />}
                      </div>
                    </div>
                    <TextArea disabled value={value.text} />
                  </DescCard>
                  <div className="right">
                    <WriteButton
                      onClick={() => {
                        setCurrentUuid(uuid);
                        writeCommentModal.on();
                      }}
                    >
                      <DocumentIcon className="write" />
                      <span>선택</span>
                    </WriteButton>
                  </div>
                </div>
              );
            })
          )}
        </CommentForm>
      </Section>
      <Modal
        isOpen={writeCommentModal.isToggled}
        onClose={() => {
          writeCommentModal.off();
          toClose();
        }}
      >
        <WriteCommentModal
          addedTrackInfo={addedTrackInfo}
          uuid={currentUuid!}
          toClose={() => {
            writeCommentModal.off();
            toClose();
          }}
        />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  overflow: hidden;
  position: relative;
  width: 1000px;
  height: 800px;
  background-color: #fff;
  font-size: 0.8rem;

  .grid {
    display: grid;
    grid-template-columns: 9fr 1fr;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      border-bottom: 1.5px solid hsl(210 3% 89%);
    }
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .comment-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.6rem;
    fill: ${PRIMARY_COLOR};
    transform: translateY(2px);
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  position: relative;
  display: flex;
  height: 100%;
  overflow-y: scroll;
`;

const CommentForm = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5rem;
  font-size: 0.9rem;
  user-select: text;
  padding-top: 1rem;

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0.5rem 1.5rem;
  background-color: #fff;
  border-bottom: 1.5px solid ${GRAY_3};

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    margin-bottom: ${MARGING_SMALL_PX};
    border-radius: 0;
    .titleInfo {
      display: flex;
      align-items: center;
      .title {
        font-size: 0.9rem;
        font-weight: 600;
        color: ${PRIMARY_COLOR};
        margin-right: 0.5rem;
        margin-bottom: 2px;
      }
      .date {
        font-size: 0.75rem;
        color: ${GRAY_6};
        margin-right: 0.3rem;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  margin: 0.5rem 0 0.3rem;
  height: 6.3rem;
  border: none;
  padding: 0;
  color: #212121;
  font-size: 14px;
  line-height: 1.43;
  overflow: hidden;
  background: transparent;
`;

const NoData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40rem;
  svg {
    width: 7rem;
    height: 7rem;
    margin-bottom: 1rem;
  }
  span {
    color: ${PRIMARY_COLOR};
    font-weight: 550;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const WriteButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .write {
    width: 2rem;
    height: 3rem;
    fill: ${PRIMARY_COLOR};
  }
  font-size: 12px;
`;
