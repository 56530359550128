/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { ServiceType } from "constants/ServiceType";

type Props = Readonly<{
  value?: ServiceType;
  isNullable?: boolean;
}>;

const items = [ServiceType.ClassicManager, ServiceType.MusicForShop, ServiceType.DataVoucher, ServiceType.CoverLala];

export const ServiceTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="ServiceType" value={value} isNullable={isNullable} items={items} />
);
