import React from "react";
import styled from "styled-components";
import { Metadata } from "GraphQL/Queries/Metadata/GetMetadata";
import { TableTemplate } from "App/Templates/TableTemplate";
import { TrackColumn } from "./TrackColumn";
import { TrackHeadList, TrackSubHeadList } from "./TrackHeadList";
import { VIOLET_0 } from "constants/baseColor";

type Props = {
  tracks: Metadata[];
};

const Layout = styled.div`
  background-color: #eee;

  table {
    tbody {
      tr {
        background-color: #fff;
        transition: background-color 0.1s;
        &:hover {
          background-color: ${VIOLET_0};
        }
      }
    }
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background: hsl(0 0% 98%);
`;

export const TracksTemplate = ({ tracks }: Props) => {
  return (
    <Layout>
      <TableTemplate
        headList={TrackHeadList}
        subHeadList={TrackSubHeadList}
        keyBy={(data, index) => index}
        dataList={!tracks || !tracks.length ? [] : tracks}
        Column={TrackColumn}
      />
      {!tracks.length && <NoData>소작품이 없습니다.</NoData>}
    </Layout>
  );
};
