/** @jsx jsx */

import { jsx, SelectionArgumentField, SelectionSet, SelectionSetChildren } from "graphql-jsx";

import { License } from "constants/License";
import { ValidType } from "constants/ValidType";

type Argument = {
  first?: SelectionArgumentField<number>;
  skip?: SelectionArgumentField<number>;
  page?: SelectionArgumentField<number>;
  group?: SelectionArgumentField<boolean>;
  where?: {
    OR?: any[];
    AND?: any[];
    metadata_id__in?: SelectionArgumentField<string[]>;
    valid_check__in?: SelectionArgumentField<ValidType | readonly ValidType[]>;
    type_metadata_subclass?: SelectionArgumentField<string>;
    metadata_company_relation__some?: {
      company__some?: {
        company_id__in?: SelectionArgumentField<string | string[]>;
        company_id__not_in?: SelectionArgumentField<string | string[]>;
      };
    };
    metadata_artist_relation__some?: {
      artist__some?: {
        artist_id__in?: SelectionArgumentField<string | string[]>;
        artist_id__not_in?: SelectionArgumentField<string | string[]>;
      };
    };
    metadata_genre_relation__some?: {
      genre__some?: {
        genre_id__in?: SelectionArgumentField<number | string | (number | string)[]>;
        genre_id__not_in?: SelectionArgumentField<number | string | (number | string)[]>;
      };
    };
    metadata_url__some?: {
      valid_check__in: SelectionArgumentField<ValidType | readonly ValidType[]>;
    };
    track_license__some?: {
      type_track__in?: SelectionArgumentField<License[]>;
      country_code__in: SelectionArgumentField<string[]>;
      copyright: SelectionArgumentField<boolean>;
      neighboring: SelectionArgumentField<boolean>;
    };
  };
};

type Props = Readonly<{
  alias?: string;
  argument?: Argument;
  children: SelectionSetChildren;
}>;

export const MetadataSelectionSet = ({ alias, argument, children }: Props) => (
  <SelectionSet name="metadata" alias={alias} argument={argument}>
    {children}
  </SelectionSet>
);
