import React from "react";
import styled from "styled-components";
import itiriri from "itiriri";
import { Page } from "../index";
import { useGenresDispatch } from "App/Routes/GenreRenewal/Store";
import { GenreActions } from "App/Routes/GenreRenewal/Store/Genre";
import { Genre } from "GraphQL/Queries/Genre/GetGenre";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { Input } from "App/Atomics/Input";
import { ValidType } from "constants/ValidType";

type SectionProps = {
  index: number;
  data: Genre;
  pageIndex: Page;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ColumnSection = ({ index, data, pageIndex, setLoading }: SectionProps) => {
  const dispatch = useGenresDispatch();
  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          // 메인 정보
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "id":
              case "name":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "id" ? "ID" : "제목"}</span>
                    <InputText
                      isDisabled={key === "id"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(GenreActions.updateGenreName({ index, name: val }))}
                    />
                  </LabelGroup>
                );
              case "detail":
                return (
                  <LabelGroup key={key}>
                    <span>{"상세 정보"}</span>
                    <InputText
                      defaultValue={value as string}
                      onBlur={val => dispatch(GenreActions.updateGenreDetail({ index, detail: val }))}
                    />
                  </LabelGroup>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(GenreActions.updateGenreValidCheck({ index, validCheck: vc }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              default:
                return null;
            }
            // 부가 정보
          } else {
            return null;
          }
        })}
    </>
  );
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;
