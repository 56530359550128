import styled from "styled-components";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { GRAY_0, GRAY_8 } from "constants/baseColor";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_LARGE, MARGING_LARGE_PX, MARGING_X_LARGE_PX } from "constants/size";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 38)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
`;

const InputTextArea = styled.textarea`
  padding: 1rem;

  resize: none;
  color: ${GRAY_8};
  line-height: 20px;
  height: 250px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }

  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
  }

  .typeSelect {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const LanguageSelect = styled(LanguageTextSelect)`
  margin-bottom: ${MARGING_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_X_LARGE_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  font-size: 1rem;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

export const LocaleModalStyle = { Layout, InputTextArea, RowGroup, LanguageSelect, ButtonGroup };
