import { requestAccessRecord } from "lib/requestAccessRecord";
import { GoodsDetail as TypeGoodsDetail, GoodsParent, TypeInfo } from "./../Store/GoodsDetail/index";
import { useGoodsDetailSelector } from "../Store";
import { isEqual } from "lodash";
import { Toast } from "lib/toast";
import { TargetTableInput } from "constants/TargetTableInput";
import {
  CreateGoodsRelation,
  DeleteAccessRecord,
  DeleteFile,
  DeleteGoodsArtist,
  DeleteGoodsStructure,
  DeleteGoodsSubdata,
  DeleteGoodsTitle,
  DeleteGoodsUrl,
  UpdateGoods,
  UpdateGoodsArtist,
  UpdateGoodsCompany,
  UpdateGoodsSubdata,
  UpdateGoodsTitle,
  UpdateGoodsUrl
} from "GraphQL/Queries";
import { GoodsArtist, GoodsCompany, GoodsSubdata, GoodsTitle, GoodsUrl, GoodsWork } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { useCreateImage } from "./useCreateImage";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";
import { UpdateGoodsStructure } from "GraphQL/Queries/Goods/UpdateGoodsStructure";
import { useState } from "react";
import { DeleteGoodsMetadata } from "GraphQL/Queries/Goods/DeleteGoodsMetadata";
import { UpdateGoodsMetadata } from "GraphQL/Queries/Goods/UpdateGoodsMetadata";

type Keys = keyof TypeGoodsDetail;
type Values = TypeGoodsDetail[Keys];

export const useUpdateGoods = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { goods, origin } = useGoodsDetailSelector(store => store.GoodsDetail);
  const { createImage } = useCreateImage();
  const updateGoods = async () => {
    if (isEqual(goods, origin)) {
      Toast.warning("변경사항이 없습니다.");
      return;
    }
    if (!window.confirm("저장하시겠습니까?")) return;
    const changeMap = formatDataToChangeMap(goods, origin);
    const targetId = goods.id;
    const companyId = goods.company.company[0].id;
    const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Goods });
    try {
      setLoading(true);
      const name = changeMap.get("name")?.to as string;
      const askPrice = changeMap.get("askPrice")?.to as number;
      const officialPrice = changeMap.get("officialPrice")?.to as number;
      const officialBarcode = changeMap.get("officialBarcode")?.to as string | null;
      const askQuantity = changeMap.get("askQuantity")?.to as number;
      const isCollection = changeMap.get("isCollection")?.to as boolean;
      const type = changeMap.get("type")?.to as TypeInfo;
      const createdAt = changeMap.get("createdAt")?.to as string;
      if (
        name ||
        type ||
        createdAt ||
        askPrice !== undefined ||
        officialPrice !== undefined ||
        officialBarcode !== undefined ||
        askQuantity !== undefined ||
        isCollection !== undefined
      ) {
        await UpdateGoods({
          id: targetId,
          name,
          typeId: type ? +type.id : undefined,
          askPrice,
          officialPrice,
          // officialBarcode: officialBarcode?.length ? officialBarcode : null,
          officialBarcode,
          askQuantity,
          createdAt,
          isCollection
        });
      }
      for (const [key, { from, to }] of changeMap) {
        switch (key) {
          case "titles":
            const deleteTitles = (from as [])
              .filter(({ id }) => !(to as GoodsTitle[]).map(({ id }) => id).includes(id))
              .map(({ id }) => id as string);
            const createTitles = (to as GoodsTitle[]).filter(
              ({ id, value }) => !(from as GoodsTitle[]).map(({ id }) => id).includes(id) && !!value.length
            );
            const updateTitles = (to as GoodsTitle[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsTitle[]) {
                if (fromItem.id === toItem.id && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteTitles.length) {
              await DeleteGoodsTitle({ uuidIn: deleteTitles });
            }
            if (createTitles.length) {
              await CreateGoodsRelation({ id: targetId, createTitles });
            }
            if (updateTitles.length) {
              for (const { id: uuid, type: typeTitle, languageCode, value, order } of updateTitles) {
                await UpdateGoodsTitle({ uuid, typeTitle, languageCode, value, order });
              }
            }
            break;
          case "artists":
            const deleteArtistList = (from as [])
              .filter(({ uuid }) => !(to as GoodsArtist[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid as string);
            const createArtists = (to as GoodsArtist[]).filter(
              ({ uuid, artist }) => !(from as GoodsArtist[]).map(({ uuid }) => uuid).includes(uuid) && !!artist?.[0]?.id
            );
            const updateArtists = (to as GoodsArtist[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsArtist[]) {
                if (fromItem.uuid === toItem.uuid && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteArtistList.length) {
              await DeleteGoodsArtist({ uuidIn: deleteArtistList });
            }
            if (createArtists.length) {
              await CreateGoodsRelation({ id: targetId, createArtists });
            }
            if (updateArtists.length) {
              for (const { uuid, artist, order } of updateArtists) {
                await UpdateGoodsArtist({ uuid, artistId: artist[0].id, order });
              }
            }
            break;
          case "works":
            console.log({ from, to });
            const deleteWorkList = (from as [])
              .filter(({ uuid }) => !(to as GoodsWork[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid as string);
            const createWorkList = (to as GoodsWork[]).filter(
              ({ uuid, metadata }) => !(from as GoodsWork[]).map(({ uuid }) => uuid).includes(uuid) && !!metadata?.[0]?.id
            );
            const updateWorkList = (to as GoodsWork[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsWork[]) {
                if (fromItem.uuid === toItem.uuid && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteWorkList.length) {
              await DeleteGoodsMetadata({ uuidIn: deleteWorkList });
            }
            if (createWorkList.length) {
              await CreateGoodsRelation({ id: targetId, createWorks: createWorkList });
            }
            if (updateWorkList.length) {
              console.log(updateWorkList);
              for (const { uuid, metadata, order } of updateWorkList) {
                await UpdateGoodsMetadata({ uuid, metadataId: metadata[0].id, order });
              }
            }
            break;
          case "company":
            const company = to as GoodsCompany;
            await UpdateGoodsCompany({ uuid: company.uuid, companyId: company.company[0].id });
            break;
          case "officialUrls":
            const deleteOfficialUrlList = (from as [])
              .filter(({ id }) => !(to as GoodsUrl[]).map(({ id }) => id).includes(id))
              .map(({ id }) => id as string);
            const createOfficialUrlList = (to as GoodsUrl[]).filter(
              ({ id, url }) => !(from as GoodsUrl[]).map(({ id }) => id).includes(id) && !!url?.length
            );
            const updateOfficialUrlList = (to as GoodsUrl[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsUrl[]) {
                if (fromItem.id === toItem.id && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteOfficialUrlList.length) {
              await DeleteGoodsUrl({ uuidIn: deleteOfficialUrlList });
            }
            if (createOfficialUrlList.length) {
              await CreateGoodsRelation({ id: targetId, officialUrl: createOfficialUrlList });
            }
            if (updateOfficialUrlList.length) {
              for (const { id, url, order } of updateOfficialUrlList) {
                await UpdateGoodsUrl({ uuid: id, url, order });
              }
            }
            break;
          case "youtubeUrls":
            const deleteYoutubeUrlList = (from as [])
              .filter(({ id }) => !(to as GoodsUrl[]).map(({ id }) => id).includes(id))
              .map(({ id }) => id as string);
            const createYoutubeUrlList = (to as GoodsUrl[]).filter(
              ({ id, url }) => !(from as GoodsUrl[]).map(({ id }) => id).includes(id) && !!url?.length
            );
            const updateYoutubeUrlList = (to as GoodsUrl[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsUrl[]) {
                if (fromItem.id === toItem.id && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteYoutubeUrlList.length) {
              await DeleteGoodsUrl({ uuidIn: deleteYoutubeUrlList });
            }
            if (createYoutubeUrlList.length) {
              await CreateGoodsRelation({ id: targetId, youtubeUrl: createYoutubeUrlList });
            }
            if (updateYoutubeUrlList.length) {
              for (const { id, url, order } of updateYoutubeUrlList) {
                await UpdateGoodsUrl({ uuid: id, url, order });
              }
            }
            break;
          case "coverUrls":
          case "detailUrls":
          case "videoUrls":
            const deleteUrlList = (from as GoodsUrl[])
              .filter(({ id }) => !(to as GoodsUrl[]).map(({ id }) => id).includes(id))
              .map(({ id, url }) => ({ id: id as string, filename: url }));
            const createUrlList = (to as GoodsUrl[]).filter(
              ({ id, typeUrl, url }) => !(from as GoodsUrl[]).map(({ id }) => id).includes(id) && !!typeUrl && !!url
            );
            const updateUrlList = (to as GoodsUrl[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsUrl[]) {
                if (fromItem.id === toItem.id && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteUrlList.length) {
              const uuidIn = deleteUrlList.map(({ id }) => id);
              for (const { filename } of deleteUrlList) {
                await DeleteFile({
                  filename,
                  companyId,
                  fileType: FileType.FILE,
                  book: BookType.immediate
                });
              }
              await DeleteGoodsUrl({ uuidIn });
            }
            if (createUrlList.length) {
              key === "coverUrls"
                ? await createImage({ id: targetId, companyId, coverUrl: createUrlList })
                : key === "detailUrls"
                ? await createImage({ id: targetId, companyId, detailUrl: createUrlList })
                : await createImage({ id: targetId, companyId, videoUrl: createUrlList });
            }
            if (updateUrlList.length) {
              for (const { id, url, order } of updateUrlList) {
                await UpdateGoodsUrl({ uuid: id, url, order });
              }
            }
            break;
          case "subdatas":
            const deleteSubdata = (from as [])
              .filter(({ id }) => !(to as GoodsSubdata[]).map(({ id }) => id).includes(id))
              .map(({ id }) => id as string);
            const createSubdatas = (to as GoodsSubdata[]).filter(
              ({ id, value }) => !(from as GoodsSubdata[]).map(({ id }) => id).includes(id) && !!value?.length
            );
            const updateSubadata = (to as GoodsSubdata[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsSubdata[]) {
                if (fromItem.id === toItem.id && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteSubdata.length) {
              await DeleteGoodsSubdata({ uuidIn: deleteSubdata });
            }
            if (createSubdatas.length) {
              await CreateGoodsRelation({ id: targetId, createSubdatas });
            }
            if (updateSubadata.length) {
              for (const { id, field, category, value } of updateSubadata) {
                await UpdateGoodsSubdata({ uuid: id, field, category, value });
              }
            }
            break;
          case "parents":
            const deleteParents = (from as [])
              .filter(({ id }) => !(to as GoodsParent[]).map(({ id }) => id).includes(id))
              .map(({ id }) => id as string);
            const createParents = (to as GoodsParent[]).filter(
              ({ id, goods }) => !(from as GoodsParent[]).map(({ id }) => id).includes(id) && !!goods.id
            );
            const updateParents = (to as GoodsParent[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GoodsParent[]) {
                if (fromItem.id === toItem.id && !isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (deleteParents.length) {
              for (const id of deleteParents) {
                await DeleteGoodsStructure({ id });
              }
            }
            if (createParents.length) {
              await CreateGoodsRelation({ id: targetId, createParents });
            }
            if (updateParents.length) {
              for (const { id, goods, order } of updateParents) {
                const parentId = goods.parentId;
                if (parentId) {
                  await UpdateGoodsStructure({ id, parentId, order });
                }
              }
            }
            break;
        }
      }
      await DeleteAccessRecord({ id: accessId! });
      window.alert("변경사항 적용을 위해 새로고침됩니다.");
      window.location.reload();
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setLoading(false);
    }
  };
  return { updateGoods, loading };
};

const formatDataToChangeMap = (goods: TypeGoodsDetail, origin: TypeGoodsDetail) => {
  const changeMap = new Map<Keys, { from: Values; to: Values }>();
  const GoodsEntries = Object.entries(goods);
  const OriginValue = Object.values<Values>(origin);
  GoodsEntries.forEach(([key, value], index) => {
    switch (key) {
      case "name":
      case "type":
      case "weight":
      case "askPrice":
      case "officialPrice":
      case "officialBarcode":
      case "askQuantity":
      case "isCollection":
      case "createdAt":
        if (OriginValue[index] !== value) {
          changeMap.set(key, { from: OriginValue[index], to: value });
        }
        break;
      case "titles":
      case "artists":
      case "works":
      case "company":
      case "coverUrls":
      case "detailUrls":
      case "videoUrls":
      case "subdatas":
      case "officialUrls":
      case "youtubeUrls":
      case "parents":
        if (!isEqual(OriginValue[index], value)) {
          changeMap.set(key, { from: OriginValue[index], to: value });
        }
        break;
      default:
        break;
    }
  });
  return changeMap;
};
