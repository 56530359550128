export enum GoodsTitleOrderByInput {
  goods_id__ASC = "goods_id__ASC",
  goods_id__DESC = "goods_id__DESC",
  id__ASC = "id__ASC",
  id__DESC = "id__DESC",
  exposure_order__ASC = "exposure_order__ASC",
  exposure_order__DESC = "exposure_order__DESC",
  type_title__ASC = "type_title__ASC",
  type_title__DESC = "type_title__DESC",
  value__ASC = "value__ASC",
  value__DESC = "value__DESC",
  value__SIMILARITY = "value__SIMILARITY",
  createdAt__ASC = "createdAt__ASC",
  createdAt__DESC = "createdAt__DESC",
  updatedAt__ASC = "updatedAt__ASC",
  updatedAt__DESC = "updatedAt__DESC",
  rand = "rand"
}
