/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, IntScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { PlaylistOrderByInputScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { PlaylistOrderByInput } from "constants/PlaylistOrderByInput";
import { Edge } from "models/Edge";
import { ValidType } from "constants/ValidType";
import { UserRole } from "constants/UserRole";

type Option = Readonly<{
  email?: string;
  page?: number;
  id?: string;
  name?: string;
  kind?: string;
  trackId?: string;
  trackTitle?: string;
  userRole?: UserRole;
}>;

type Genre = {
  uuid: string;
  validCheck: ValidType;
  genre: {
    id: string;
    name: string;
  }[];
};

type Mood = {
  uuid: string;
  validCheck: ValidType;
  mood: {
    id: string;
    name: string;
  }[];
};

export type Url = {
  uuid: string;
  typeUrl?: string;
  url?: string;
  validCheck: ValidType;
  order?: number;
};

export type Playlist = {
  id: string;
  name: string;
  kind: string;
  service: string;
  price: number;
  remark: string;
  validCheck: ValidType;
  playlistUrl: Url[];
  playlistTitle: {
    uuid: string;
  }[];
  count?: number;
  metadataPlaylistRelation: {
    uuid: string;
    metadata: {
      id: string;
    }[];
    order: number;
  }[];
  genreRelation: Genre[];
  moodRelation: Mood[];
  userRelation: {
    uuid: string;
    owner: boolean;
    validCheck: ValidType;
    visibleField: string[] | null;
    users: {
      email: string;
    }[];
  }[];
};

export const GetPlaylist = async (option: Option) => {
  const $email = <Variable name="email" scalar={<IdScalar isNullable={true} />} value={option.email} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable={true} />} value={option.page} />;
  const $id = <Variable name="id" scalar={<IdScalar isNullable={true} />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable={true} />} value={option.name} />;
  const $kind = <Variable name="kind" scalar={<StringScalar isNullable={true} />} value={option.kind} />;
  const $trackId = <Variable name="track_id" scalar={<IdScalar isNullable={true} />} value={option.trackId} />;
  const $trackTitle = <Variable name="track_title" scalar={<StringScalar isNullable={true} />} value={option.trackTitle} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST">
      <SelectionSet
        name="playlist"
        argument={{
          first: 10,
          page: $page,
          orderBy: <PlaylistOrderByInputScalar value={PlaylistOrderByInput.playlist_id_DESC} />,
          where: {
            playlist_id: $id,
            ...{
              playlist_users_relation__some: {
                users__some: {
                  email: $email
                }
              },
              valid_check__not: $remove
            },
            ...(option.name && { playlist_title__some: { tsvec_words__search: $name } }),
            metadata_playlist_relation__some: {
              metadata__some: { metadata_id: $trackId, metadata_title__some: { tsvec_words__search: $trackTitle } }
            },
            kind__contains: $kind
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="name" />
        <Field name="kind" />
        <Field name="service" />
        <Field name="price" />
        <Field name="remark" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet name="playlist_url" alias="playlistUrl">
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet
          name="playlist_title"
          alias="playlistTitle"
          argument={{
            where: {
              type_title: "name",
              language__some: {
                language_code: "OO"
              },
              type_title__not_starts_with: "search"
            }
          }}
        >
          <Field name="id" alias="uuid" />
        </SelectionSet>
        <SelectionSet
          name="playlist_genre_relation"
          alias="genreRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "genre"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="playlist_genre_relation"
          alias="moodRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "mood"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre" alias="mood">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="playlist_users_relation"
          alias="userRelation"
          argument={{ where: option.userRole === UserRole.Master || option.userRole === UserRole.Arbeit ? {} : { is_onwer: false } }}
        >
          <Field name="id" alias="uuid" />
          <Field name="is_onwer" alias="owner" />
          <Field name="visible_field" alias="visibleField" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="users">
            <Field name="email" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          first: 10,
          node: "playlist",
          page: $page,
          where: {
            playlist: {
              playlist_id: $id,
              ...{
                playlist_users_relation__some: {
                  users__some: {
                    email: $email
                  }
                },
                valid_check__not: $remove
              },
              ...(option.name && { playlist_title__some: { tsvec_words__search: $name } }),
              metadata_playlist_relation__some: {
                metadata__some: { metadata_id: $trackId, metadata_title__some: { tsvec_words__search: $trackTitle } }
              },
              kind__contains: $kind
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    playlist: Playlist[];
    edge: Edge;
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
