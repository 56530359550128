import React, { useState } from "react";
import styled from "styled-components";
import { MARGING_SMALL_PX, pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { GRAY_6 } from "constants/baseColor";
import { useTracksStore } from "../../Store";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { MusicDownloadModal } from "./MusicDownloadModal";

type Props = Readonly<{
  toClose: () => void;
}>;

export const SelectMusicTitleModal = ({ toClose }: Props) => {
  const [{ checkList }] = useTracksStore(store => ({
    checkList: store.Track.checkList
  }));
  const [titleType, setTitleType] = useState<string>("id");
  const [typeUrl, setTypeUrl] = useState<string>("mp3high");
  const musicDownloadModal = useToggle();

  const Mp3TitleOptionList = [
    { id: "track_id", name: "트랙 아이디" },
    { id: "title", name: "제목" }
  ];

  const typeUrlOptionList = [
    { id: "mp3high", name: "MP3" },
    { id: "wav", name: "WAV" },
    { id: "aac", name: "AAC" },
    { id: "flac", name: "FLAC" },
    { id: "zip", name: "ZIP" },
    { id: "txt", name: "TXT" }
  ];

  return (
    <Layout>
      <Header>
        <h3>다운로드 할 파일의 제목 및 파일 타입을 선택해주세요.</h3>
      </Header>
      <Section>
        <div className="row-group">
          <h5>제목</h5>
          <Input.TextSelect
            optionList={Mp3TitleOptionList}
            defaultValue={Mp3TitleOptionList.find(({ id }) => id === titleType)}
            onChange={info => {
              if (info) {
                setTitleType(info.id);
              }
            }}
          />
        </div>
        <div className="row-group">
          <h5>타입</h5>
          <Input.TextSelect
            optionList={typeUrlOptionList}
            defaultValue={typeUrlOptionList.find(({ id }) => id === typeUrl)}
            placeholder="타입을 선택하세요."
            onChange={info => {
              if (info) {
                setTypeUrl(info.id);
              }
            }}
          />
        </div>
        <ButtonGroup>
          <Input.Button color="primary" onClick={musicDownloadModal.on}>
            음원 다운로드
          </Input.Button>
          <Input.Button color="danger" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Section>
      <Modal isOpen={musicDownloadModal.isToggled}>
        <MusicDownloadModal checkList={checkList} titleType={titleType} typeUrl={typeUrl} toClose={musicDownloadModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;

  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 25)};
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background: rgb(106, 95, 221);

  color: #fff;
  padding: 1.3rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1.5rem 1.8rem;

  .row-group {
    margin-bottom: 1.6rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
`;

const ButtonGroup = styled(Input.Group)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 6.5rem;
  & > button {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: bold;
  }
`;
