/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, ListScalar, EnumScalar, Variable, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id?: number;
  fromDate?: string;
  toDate?: string;
  visibleBill?: boolean;
}>;

export type TrackInfo = {
  metadata_id: number; // 848380
  count: string; // "1"
  title: string; // "Piazzolla: Histoire Du Tango (History Of The Tango) - Bordel 1900"
  id_track?: string;
};

type Condition = {
  calculation: string;
  name: string;
  totalamount: number;
  type: number;
};

export type BillInfo = {
  conditionPricePerMembership: Condition | null;
  conditionPricePerPlayingTracks: Condition | null;
  conditionPricePerSales: Condition | null;
  currency: string; // "KRW",
  price: number; // 7900
  price_id: number; // 12
  product: string; // "Classicmanager - Premium Subscription Plan"
  product_id: number; // 5
  product_price: number; // 9500
  resultAmount: number; // 650
  subscriberRate: number; // 4345
  playingRate: number; // 5
  contractRate: number; // 0.55
  totalSalesAmount: number; // 15800
  membershipCount: number; // 5
  service: string; // "CM"
  specificPlayedTracksEachCountPerMonth: TrackInfo[];
  specificplayedtrackscountpermonth: string; // "130"
  totalplayedtrackscountpermonth: string; // "1129861"
};

export type Bill = {
  info: BillInfo[];
};

export type Account = {
  id: string;
  bill: Bill;
  createdAt: string;
  totalAmount: number;
  users: {
    id: number;
    email: string;
  }[];
};

export const GetAccount = async (option: Option) => {
  const $createdAtBetween = (
    <ListScalar scalar={<DateTimeScalar isNullable={true} />} value={[`"${option.fromDate}"`, `"${option.toDate}"`]} />
  );
  const $id = <Variable name="id" scalar={<IntScalar isNullable={true} />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_ACCOUNT">
      <SelectionSet
        name="get_account"
        alias="getAccount"
        argument={{
          first: 50,
          where: {
            users__some: {
              users_id: $id
            },
            created_at__between: $createdAtBetween
          },
          orderBy: <EnumScalar type="contract_billOrderByInput" value="created_at__DESC" items={["created_at__DESC"]} />
        }}
      >
        <Field name="id" />
        <Field name="created_at" alias="createdAt" />
        {!option.visibleBill ? null : <Field name="bill" />}
        <Field name="total_amount" alias="totalAmount" />
        <SelectionSet name="users">
          <Field name="users_id" alias="id" />
          <Field name="email" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    getAccount: Account[];
  }>;
  return await clients.access.request<Data>(query, variables);
};
