/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";

type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  character_id?: string;
  character_name?: string;
  order?: number;
};

type Company = {
  company_id: string;
  name: string;
};
type Production = Company;
type Publication = Company;

type Url = {
  typeUrl?: string;
  url?: string;
};

type Subdata = {
  typeSubdataCategory?: string;
  typeSubdataField?: string;
  value?: string;
};

type Option = Readonly<{
  no?: string;
  v1?: string;
  title: string;
  titleEn: string;
  description: string;
  descriptionEn: string;
  class: string;
  subClass: string;
  artistRelation: Artist[];
  rightsCompany: Company;
  productions?: Production[];
  publications?: Publication[];
  lyrics?: string;
  urls?: Url[];
  subdatas?: Subdata[];
  parentId?: number;
  ytvCode?: string;

  genreIds?: string[];
}>;

export const CreateMetadata = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar isNullable />} value={option.no} />;
  const $v1 = <Variable name="v1" scalar={<StringScalar isNullable />} value={option.v1} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $titleEn = <Variable name="title_en" scalar={<StringScalar isNullable={false} />} value={option.titleEn} />;
  const $description = <Variable name="description" scalar={<StringScalar isNullable={false} />} value={option.description} />;
  const $descriptionEn = <Variable name="description_en" scalar={<StringScalar isNullable={false} />} value={option.descriptionEn} />;
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.rightsCompany.company_id} />;
  const $rightsCompany = <MetadataRightScalar isNullable value={MetadataRightType.rightCompany} />;
  const $production = <MetadataRightScalar isNullable value={MetadataRightType.production} />;
  const $publication = <MetadataRightScalar isNullable value={MetadataRightType.publication} />;
  const $lyrics = <Variable name="lyrics" scalar={<StringScalar isNullable={!option.lyrics} />} value={option.lyrics} />;
  const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable={false} />} value={option.parentId} />;
  const $ytvCode = <Variable name="ytvCode" scalar={<StringScalar isNullable={!option.ytvCode} />} value={option.ytvCode} />;

  const genreIds = option.genreIds?.map((id, order) => ({ id, order })) ?? [];
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA">
      <SelectionSet
        name="createMetadata"
        argument={{
          data: {
            no: $no,
            compatibility_v1_id: $v1,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "EN"
                    }
                  },
                  type_metadata_title: "name",
                  value: $titleEn,
                  exposure_order: 1
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "description",
                  value: $description,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "EN"
                    }
                  },
                  type_metadata_title: "description",
                  value: $descriptionEn,
                  exposure_order: 1
                },
                !option.lyrics?.length
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_metadata_title: "lyrics",
                      value: $lyrics,
                      exposure_order: 0
                    },
                !option.ytvCode?.length
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_metadata_title: "name_youtube",
                      value: $ytvCode,
                      exposure_order: 0
                    }
              ]
            },
            metadata_artist_relation: {
              create: [
                ...option.artistRelation.map(({ artist_id, role_id, character_id, order }) => ({
                  artist: {
                    connect: {
                      artist_id: artist_id
                    }
                  },
                  role: {
                    connect: {
                      role_id: role_id
                    }
                  },
                  character: {
                    connect: {
                      role_id: character_id
                    }
                  },
                  exposure_order: order
                }))
              ]
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $rightsCompany,
                  exposure_order: 0
                },
                ...(option.productions?.map(({ company_id }, order) => ({
                  company: {
                    connect: {
                      company_id: company_id
                    }
                  },
                  type_kind: $production,
                  exposure_order: order
                })) ?? []),
                ...(option.publications?.map(({ company_id }, order) => ({
                  company: {
                    connect: {
                      company_id: company_id
                    }
                  },
                  type_kind: $publication,
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_genre_relation: {
              create: !genreIds.length
                ? []
                : genreIds.map(({ id }, order) => ({
                    genre: {
                      connect: {
                        genre_id: id
                      }
                    },
                    exposure_order: order
                  }))
            },
            metadata_url: {
              create: [...(option.urls?.map(({ typeUrl, url }) => ({ type_url: typeUrl, url })) ?? [])]
            },
            metadata_subdata_unique: {
              create: [
                ...(option.subdatas?.map(({ typeSubdataCategory, typeSubdataField, value }) => ({
                  type_subdata_category: typeSubdataCategory,
                  type_subdata_field: typeSubdataField,
                  value
                })) ?? [])
              ]
            },
            metadata_structure: {
              create: [{ parent_id: $parentId }]
            }
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata_url">
          <Field name="id" />
          <Field name="type_url" />
          <Field name="url" />
        </SelectionSet>
        <SelectionSet name="metadata_structure">
          <Field name="structure_id" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createMetadata: Readonly<{
      metadata_id: string;
      metadata_url: {
        id: string;
        type_url: string;
        url: string;
      }[];
      metadata_structure: {
        structure_id: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
