import Time from "dayjs";
import { ReactNode, useEffect } from "react";

import { useAppStore } from "App/Store";
import { UserTokenActions } from "App/Store/UserToken";
import { GraphQLClientHeader } from "./GraphQLClientHeader";
import { updateTokens } from "./updateTokens";
import { UserInfo } from "./UserInfo";
import { GraphQLClient } from "lib/gql-client";

type Props = Readonly<{ children: ReactNode }>;

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const TERM = 3 * MINUTE;

let isFailovering = false;
let isInitialized = false;

export const GraphQLClientProvider = ({ children }: Props) => {
  const [expiresAt, dispatch] = useAppStore(store => store.UserToken.expiresAt);
  const failover = async () => {
    if (!expiresAt || isFailovering) {
      isInitialized = true;
      return;
    }

    console.log("[현재 시각]", new Date().toISOString());
    console.log("[로그인 토큰 체크]", expiresAt.toISOString());

    const isExpiresAt = expiresAt.isBefore(Time());
    if (isExpiresAt) {
      window.alert(`로그인 토큰이 만료했습니다.\n다시 로그인 해주시기 바랍니다.\nuser token is expired.\nplease sign in again)`);
      dispatch(UserTokenActions.signOut());
      return;
    }

    isFailovering = true;
    while (true) {
      try {
        const response = await updateTokens();
        if (response.data?.updateTokens) {
          const { refreshToken, accessToken, userToken, payloadToken } = response.data.updateTokens.tokens;
          GraphQLClientHeader.set({
            refresh_user_token: refreshToken,
            access_token: accessToken,
            user_token: userToken,
            payload_token: payloadToken
          });
          const expiresAt = UserInfo.saveTokens({ refreshToken, accessToken, userToken, payloadToken });
          dispatch(UserTokenActions.setExpiresAt(expiresAt));
          isFailovering = false;
          return;
        }
      } catch {}
      if (!window.navigator.onLine) {
        return;
      }
      if (!window.confirm("로그인을 연장하시려면 확인 버튼을 눌러주세요.\n취소를 누르시면 로그아웃 됩니다.")) {
        GraphQLClientHeader.remove("refresh_user_token");
        GraphQLClientHeader.remove("access_token");
        GraphQLClientHeader.remove("user_token");
        GraphQLClientHeader.remove("payload_token");
        isFailovering = false;
        dispatch(UserTokenActions.signOut());
        return;
      }
    }
  };

  GraphQLClient.setFailover(failover);

  const manageUserToken = () => {
    if (!isInitialized) {
      isInitialized = true;
      failover();
    }
    const timer = window.setInterval(failover, TERM);
    return () => {
      window.clearInterval(timer);
    };
  };
  useEffect(manageUserToken, [expiresAt]);

  return children as JSX.Element;
};
