/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid?: string;
}>;

export const DeletePlaylistTitleRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar isNullable />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_PLAYLIST_TITLE_RELATION">
      <SelectionSet
        name="deletePlaylist_title"
        alias="deletePlaylistTitleRelation"
        argument={{
          where: {
            id: $uuid
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deletePlaylistTitleRelation: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
