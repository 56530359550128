/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar, IntScalar } from "graphql-jsx";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  metadataId: string;
  artistId?: string;
  roleId?: string;
  characterId?: string;
  order: number;
  validCheck: ValidType;
}>;

export const CreateMetadataArtist = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $artistId = <Variable name="artist_id" scalar={<IdScalar isNullable={true} />} value={option.artistId} />;
  const $roleId = <Variable name="role_id" scalar={<IdScalar isNullable={true} />} value={option.roleId} />;
  const $characterId = <Variable name="character_id" scalar={<IdScalar isNullable={true} />} value={option.characterId} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_ARTIST">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataArtist"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            _onlySearch: true,
            metadata_artist_relation: {
              create: [
                {
                  artist: {
                    connect: {
                      artist_id: $artistId
                    }
                  },
                  role: {
                    connect: {
                      role_id: $roleId
                    }
                  },
                  character: {
                    connect: {
                      role_id: $characterId
                    }
                  },
                  exposure_order: $order,
                  valid_check: $validCheck
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <SelectionSet name="metadata_artist_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createMetadataArtist: Readonly<{
      metadataId: string;
      metadata_artist_relation: {
        uuid: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
