import React, { FormEvent, useCallback, useState } from "react";
import styled from "styled-components";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { PRODUCT } from "constants/route";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { CardList } from "./CardList";
import { Pagination } from "App/Molecules/Pagination";
import { HEADER_HEIGHT_PX } from "constants/size";
import { mediaQuery } from "constants/media";
import ContentLoader from "react-content-loader";
import { useProductSelector } from "../Store";
import { usePlaceholder } from "lib/use-place-holder";
import { useQueryParams } from "lib/use-query-params";
import { SearchForm } from "App/Organisms/SearchForm";
import { useHistory } from "react-router";

type Props = {
  readonly loading: boolean;
};

const SearchColumnList = [
  { id: "ids", name: "아이디" },
  { id: "barcode", name: "바코드" }
];

export const ProductTable = ({ loading }: Props) => {
  const { edge } = useProductSelector(store => store.Product);
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const { placeholderWidth } = usePlaceholder();
  const router = useHistory();
  const queryParams = useQueryParams();
  const goodsId = queryParams.get("goodsId", { default: undefined });
  const searchBarcode = queryParams.get("barcode", { default: undefined });
  const searchIds = queryParams.get("ids", { default: undefined });
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);

  const onSearch = useCallback(
    (e: FormEvent<HTMLFormElement>, searchStr: string) => {
      e.preventDefault();
      if (searchStr === getSearchString(searchType)) {
        return;
      }
      const matchedStr = searchType === "ids" ? searchStr.match(/[^ ,]+/g)?.map(v => +v) : searchStr.trim();
      if (searchType === "ids") {
        router.push(`${PRODUCT}?page=1&ids=[${matchedStr}]`);
      } else {
        router.push(`${PRODUCT}?page=1&barcode=${matchedStr}`);
      }
    },
    [getSearchString, router, searchType]
  );

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <SearchForm
          route={PRODUCT}
          searchType={searchType}
          optionColumnList={SearchColumnList}
          onChangeSearchTypeInfo={info => setSearchType(info!.id)}
          onSearch={onSearch}
        />
      </TopbarTemplate>
      {loading && (
        <LoadingPlaceholder>
          {[...Array(20)].map((_, key) => (
            <Placeholder key={key} width={placeholderWidth} />
          ))}
        </LoadingPlaceholder>
      )}
      {!loading && <CardList />}
      <Pagination
        edge={edge}
        goTo={index =>
          `${PRODUCT}?page=${index}${!goodsId ? "" : `&goodsId=${goodsId}`}${!searchIds ? "" : `&ids=${searchIds}`}${
            !searchBarcode ? "" : `&barcode=${searchBarcode}`
          }`
        }
      />
    </Layout>
  );
};

const Layout = styled.div`
  display: inherit;
  width: 100%;
  flex-direction: column;
  overflow: auto;
`;

const LoadingPlaceholder = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${HEADER_HEIGHT_PX};
  justify-content: center;
  padding: 2em 0;
  svg {
    margin: 0 0.6em;
  }
  ${mediaQuery(768)} {
    svg {
      margin: 0 0.3em;
    }
  }
`;

const Placeholder = ({ width }: { width: number }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={width * 1.3}
    viewBox={`0 0 ${width} ${width * 1.3}`}
    backgroundColor="#f2f2f2"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="2" ry="2" width={width} height={width} />
    <rect x="0" y={width * 1.03} rx="2" ry="2" width={width * 0.2} height={width * 0.03} />
    <rect x="0" y={width * 1.07} rx="2" ry="2" width={width * 0.9} height={width * 0.03} />
    <rect x="0" y={width * 1.11} rx="2" ry="2" width={width * 0.3} height={width * 0.03} />
  </ContentLoader>
);
