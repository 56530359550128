import React, { useRef } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { useHistory } from "react-router";
import { ReactComponent as SearchIcon } from "assets/icons/color-search.svg";

const Layout = styled.form`
  min-width: 640px;
  input {
    width: 100%;
    margin-bottom: 0.5rem;
    border: 1.5px solid #c9c9c9;
    border-radius: 0.3rem;
    height: 3rem;
    transition: all 0.5s;
    &:focus {
      border: 1.5px solid #8a7fdd;
      box-shadow: 0 0 5px #8a7fdd;
      height: 4rem;
    }
  }

  & > svg {
    position: relative;
    top: 2px;
    right: 40px;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
`;

export const SearchForm = () => {
  const router = useHistory();
  const searchStringRef = useRef<HTMLInputElement>(null);

  const onSearch = () => {
    const searchString = searchStringRef.current!.value.trim();
    router.push(`?page=1${!searchString ? "" : `&query=${encodeURIComponent(searchString)}`}`);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <Layout onSubmit={onSubmit}>
      <Input.Text ref={searchStringRef} placeholder="검색어를 입력하세요..." />
      <SearchIcon onClick={onSearch} />
    </Layout>
  );
};
