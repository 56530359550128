import { Account, BillInfo } from "GraphQL/Queries/Access/GetAccount";
import { TotalAccount } from "GraphQL/Queries/Access/GetTotalAccount";
import { CompanyUserRelation } from "GraphQL/Queries/Access/GetCompanyUserInfo";
import { createDuck } from "lib/store/v2";

export enum Page {
  SettleForm,
  TrackForm
}

const createInitialState = () => ({
  account: [] as Account[],
  totalAccount: {} as TotalAccount,
  page: Page.SettleForm as Page,
  companyInfo: {} as CompanyUserRelation,
  dateInfo: {
    fromDate: "2020-01-01" as string,
    toDate: "2020-01-31" as string
  },
  billInfo: {} as BillInfo
});

export const SettleActions = createDuck({
  namespace: "Settle",
  createInitialState,
  reducers: {
    setSettleAccount(state, account: Account[]) {
      state.account = account;
    },
    setSettleTotalAccount(state, totalAccount: TotalAccount) {
      state.totalAccount = totalAccount;
    },
    setSettlePage(state, page: Page) {
      state.page = page;
    },
    setSettleCompanyInfo(state, companyInfo: CompanyUserRelation) {
      state.companyInfo = companyInfo;
    },
    setSettleDateInfo(state, { fromDate, toDate }: { fromDate: string; toDate: string }) {
      state.dateInfo.fromDate = fromDate;
      state.dateInfo.toDate = toDate;
    },
    setSettleBillInfo(state, billInfo: BillInfo) {
      state.billInfo = billInfo;
    }
  }
});
