import { Action } from "./../../lib/store/v2/combineReducers";

export const createStoreLogger = (name: string) => <S>(previous: S, next: S, action: Action) => {
  if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
    console.groupCollapsed(name, action.type.description, "=>", new Date().toISOString());
    console.log(previous);
    console.log(next);
    console.groupEnd();
  }
};
