/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IntScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  uuid: string;
  genreId: string;
  order?: number;
  validCheck?: ValidType;
}>;

export const UpdateArtistGenreRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $genreId = <Variable name="genreId" scalar={<IdScalar />} value={option.genreId} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.order} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_ARTIST_GENRE_RELATION">
      <SelectionSet
        name="updateArtist_genre_relation"
        alias="updateArtistGenreRelation"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            genre: {
              connect: {
                genre_id: $genreId
              }
            },
            exposure_order: $order,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateArtistGenreRelation: Readonly<{
      artist_id: string;
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
