import { parseBlob } from "music-metadata-browser";

export interface Mp3Information {
  readonly duration: number;
  readonly channelCount: number;
  readonly sampleRate: number;
}

export const analyzeAudio = async (file: File): Promise<null | Mp3Information> => {
  try {
    const { format } = await parseBlob(file);
    switch (format.container) {
      case "iso2/isom":
      case "isom/M4A":
      case "WAVE":
      case "MPEG": {
        switch (format.codec) {
          // reference: https://github.com/Borewit/music-metadata/blob/master/lib/mpeg/MpegParser.ts#L212
          case "MPEG null Layer 3":
          case "MPEG 1 Layer 3":
          case "MPEG 2 Layer 3":
          case "MPEG 2.5 Layer 3": {
            const MINIMUM_KBPS = 128000; // 128kbps
            if (MINIMUM_KBPS <= format.bitrate!) {
              const information = await decodingFile(file);
              return information;
            }
            break;
          }
          case "PCM":
          case "MPEG-4/AAC":
            const information = await decodingFile(file);
            return information;
        }
      }
    }
  } catch (error) {
    console.log({ error });
  }
  return null;
};

// Safari 외에는 AudioContext.prototype.decodeAudioData의 2번째 argument를 넘겨 줄 필요가 없음
const decodeAudioData = (file: File): Promise<Mp3Information> =>
  new Promise(resolve => {
    const audio = window.document.createElement("audio");
    audio.onloadedmetadata = async () => {
      audio.onloadedmetadata = null;
      new window.AudioContext().decodeAudioData(await file.arrayBuffer(), ({ numberOfChannels, sampleRate }) => {
        resolve({
          duration: Math.ceil(audio.duration),
          channelCount: numberOfChannels,
          sampleRate
        });
      });
    };
    audio.src = URL.createObjectURL(file);
  });

const decodingFile = async (file: File): Promise<Mp3Information | null> => {
  const { duration, channelCount, sampleRate } = await decodeAudioData(file);
  const MINUTE = 60;
  const HOUR = 60 * MINUTE;
  const MINIMUM_DURATION = MINUTE / 2;
  const MAXIMUM_DURATION = HOUR * 2;
  if (MINIMUM_DURATION <= duration && duration <= MAXIMUM_DURATION) {
    const information: Mp3Information = {
      duration,
      channelCount,
      sampleRate
    };
    return information;
  }
  return null;
};
