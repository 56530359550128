/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ServiceTypeScalar } from "GraphQL/Scalars";
import { ServiceType } from "constants/ServiceType";
import { Edge } from "models/Edge";

type Option = Readonly<{
  serviceType?: ServiceType;
  kind: string;
  first: number;
  page: number;
}>;

export const CheckDuplicatedTypeTrackInPlaylist = async (option: Option) => {
  const $serviceType = <Variable name="serviceType" scalar={<ServiceTypeScalar value={option.serviceType} />} />;
  const $kind = <Variable name="kind" scalar={<StringScalar />} value={option.kind} />;
  const $first = <Variable name="first" scalar={<IntScalar />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar />} value={option.page} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_TRACK">
      <SelectionSet
        name="metadata_playlist_relation"
        alias="metadataPlaylistRelation"
        argument={{
          group: true,
          first: $first,
          page: $page,
          where: {
            playlist__some: {
              service: $serviceType,
              kind: $kind
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "metadata_playlist_relation",
          first: $first,
          page: $page,
          where: {
            metadata_playlist_relation: {
              playlist__some: {
                service: $serviceType,
                kind: $kind
              }
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataPlaylistRelation: {
      metadata: {
        id: string;
      }[];
    }[];
    edge: Edge;
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
