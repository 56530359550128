import { createDuck } from "lib/store/v3";
import { Edge } from "models/Edge";
import { ProductModel } from "models/ProductModel";

const createInitialState = () => ({
  product: [] as ProductModel[],
  edge: null as Edge | null
});

export const ProductActions = createDuck({
  namespace: "Product",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setProduct(state, ProductList: ProductModel[]) {
      state.product = ProductList;
    },
    removeProduct(state, key: string) {
      const result = state.product.filter(item => item.id.key !== key);
      state.product = result;
    }
  }
});
