/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  roleId: string;
  listId: string;
  typeFunction: string;
  order: number;
}>;

export const CreateRoleElementRelation = async (option: Option) => {
  const $roleId = <Variable name="roleId" scalar={<IdScalar />} value={option.roleId} />;
  const $listId = <Variable name="list_role_id" scalar={<IdScalar />} value={option.listId} />;
  const $typeFunction = <Variable name="type_role_class" scalar={<StringScalar isNullable={false} />} value={option.typeFunction} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ROLE_ELEMNT_RELATION">
      <SelectionSet
        name="updateRole"
        alias="createRoleElementRelation"
        argument={{
          where: {
            role_id: $roleId
          },
          data: {
            _onlySearch: true,
            role_self_relation_element_role: {
              create: [
                {
                  type_function: $typeFunction,
                  exposure_order: $order,
                  role_self_relation_list_role: { connect: { role_id: $listId } }
                }
              ]
            }
          }
        }}
      >
        <Field name="role_id" alias="id" />
        <SelectionSet name="role_self_relation_element_role" alias="elementRelation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createRoleElementRelation: {
      id: string;
      elementRelation: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.role.request<Data>(query, variables);
};
