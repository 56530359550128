/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  metadataId: string;
  order: number;
  typeFunction: string;
  elementId: string;
}>;

export const CreateMetadataListRelation = async (option: Option) => {
  const $metadataId = <Variable name="metadataId" scalar={<IdScalar />} value={option.metadataId} />;
  const $order = <Variable name="order" scalar={<IntScalar isNullable />} value={option.order} />;
  const $typeFunction = <Variable name="type_function" scalar={<StringScalar isNullable={false} />} value={option.typeFunction} />;
  const $elementId = <Variable name="value" scalar={<IdScalar isNullable />} value={option.elementId} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_LIST_RELATION">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataListRelation"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            _onlySearch: true,
            metadata_self_relations_list_metadata: {
              create: [
                {
                  type_function: $typeFunction,
                  exposure_order: $order,
                  metadata_self_relations_element_metadata: {
                    connect: {
                      metadata_id: $elementId
                    }
                  }
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata_self_relations_list_metadata">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createMetadataListRelation: {
      id: string;
      metadata_self_relations_list_metadata: {
        uuid: string;
      }[];
    };
  }>;

  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.createMetadataListRelation;
};
