import { ValidType } from "constants/ValidType";

export const INFO_LIST = [
  ValidType.V1,
  ValidType.V2,
  ValidType.V3,
  ValidType.VC,
  ValidType.MODIFY,
  ValidType.REMOVE,
  ValidType.DONE,
  ValidType.ETC
].map((name: string) => ({ id: name, name }));
