/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const LoadCommodityGoods = async (option: Option) => {
  const $id = <Variable name="commodity_id" scalar={<IdScalar isNullable />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="LOAD_COMMODITY_GOODS">
      <SelectionSet
        name="commodity"
        alias="product"
        argument={{
          group: true,
          where: {
            commodity_id: $id
          }
        }}
      >
        <Field name="commodity_id" alias="id" />
        <Field name="goods_id" alias="goodsId" />
        <SelectionSet name="goods">
          <SelectionSet name="goods_company_relation" alias="goodsCompany">
            <Field name="company_id" alias="companyId" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    product: {
      id: string;
      goodsId: string;
      goods: {
        goodsCompany: {
          companyId: string;
        }[];
      }[];
    }[];
  }>;
  const { data } = await clients.goods.request<Data>(query, variables);
  const { id, goodsId, goods } = data!.product[0]!;
  return { id, goodsId, companyId: goods[0].goodsCompany[0].companyId };
};
