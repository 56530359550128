/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum QualityOfCommodity {
  NEW = "NEW",
  A = "A"
}

type Props = Readonly<{
  isNullable?: boolean;
  value?: QualityOfCommodity;
}>;

const items = Object.values(QualityOfCommodity);

export const QualityOfCommodityScalar = ({ isNullable = false, value }: Props) => (
  <EnumScalar type="QualityOfCommodity" value={value} isNullable={isNullable} items={items} />
);
