export const LanguageCodeInfo = [
  {
    id: "XX",
    name: "Unknown"
  },
  {
    id: "OO",
    name: "basic"
  },
  {
    id: "AA",
    name: "Afar"
  },
  {
    id: "AB",
    name: "Abkhazian"
  },
  {
    id: "AE",
    name: "Avestan"
  },
  {
    id: "AF",
    name: "Afrikaans"
  },
  {
    id: "AK",
    name: "Akan"
  },
  {
    id: "AM",
    name: "Amharic"
  },
  {
    id: "AN",
    name: "Aragonese"
  },
  {
    id: "AR",
    name: "Arabic"
  },
  {
    id: "AS",
    name: "Assamese"
  },
  {
    id: "AV",
    name: "Avaric"
  },
  {
    id: "AY",
    name: "Aymara"
  },
  {
    id: "AZ",
    name: "Azerbaijani"
  },
  {
    id: "BA",
    name: "Bashkir"
  },
  {
    id: "BE",
    name: "Belarusian"
  },
  {
    id: "BG",
    name: "Bulgarian"
  },
  {
    id: "BH",
    name: "Bihari languages"
  },
  {
    id: "BI",
    name: "Bislama"
  },
  {
    id: "BM",
    name: "Bambara"
  },
  {
    id: "BN",
    name: "Bengali"
  },
  {
    id: "BO",
    name: "Tibetan"
  },
  {
    id: "BR",
    name: "Breton"
  },
  {
    id: "BS",
    name: "Bosnian"
  },
  {
    id: "CA",
    name: "Catalan; Valencian"
  },
  {
    id: "CE",
    name: "Chechen"
  },
  {
    id: "CH",
    name: "Chamorro"
  },
  {
    id: "CO",
    name: "Corsican"
  },
  {
    id: "CR",
    name: "Cree"
  },
  {
    id: "CS",
    name: "Czech"
  },
  {
    id: "CU",
    name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
  },
  {
    id: "CV",
    name: "Chuvash"
  },
  {
    id: "CY",
    name: "Welsh"
  },
  {
    id: "DA",
    name: "Danish"
  },
  {
    id: "DE",
    name: "German"
  },
  {
    id: "DV",
    name: "Divehi; Dhivehi; Maldivian"
  },
  {
    id: "DZ",
    name: "Dzongkha"
  },
  {
    id: "EE",
    name: "Ewe"
  },
  {
    id: "EL",
    name: "Greek, Modern (1453-)"
  },
  {
    id: "EN",
    name: "English"
  },
  {
    id: "EO",
    name: "Esperanto"
  },
  {
    id: "ES",
    name: "Spanish; Castilian"
  },
  {
    id: "ET",
    name: "Estonian"
  },
  {
    id: "EU",
    name: "Basque"
  },
  {
    id: "FA",
    name: "Persian"
  },
  {
    id: "FF",
    name: "Fulah"
  },
  {
    id: "FI",
    name: "Finnish"
  },
  {
    id: "FJ",
    name: "Fijian"
  },
  {
    id: "FO",
    name: "Faroese"
  },
  {
    id: "FR",
    name: "French"
  },
  {
    id: "FY",
    name: "Western Frisian"
  },
  {
    id: "GA",
    name: "Irish"
  },
  {
    id: "GD",
    name: "Gaelic; Scottish Gaelic"
  },
  {
    id: "GL",
    name: "Galician"
  },
  {
    id: "GN",
    name: "Guarani"
  },
  {
    id: "GU",
    name: "Gujarati"
  },
  {
    id: "GV",
    name: "Manx"
  },
  {
    id: "HA",
    name: "Hausa"
  },
  {
    id: "HE",
    name: "Hebrew"
  },
  {
    id: "HI",
    name: "Hindi"
  },
  {
    id: "HO",
    name: "Hiri Motu"
  },
  {
    id: "HR",
    name: "Croatian"
  },
  {
    id: "HT",
    name: "Haitian; Haitian Creole"
  },
  {
    id: "HU",
    name: "Hungarian"
  },
  {
    id: "HY",
    name: "Armenian"
  },
  {
    id: "HZ",
    name: "Herero"
  },
  {
    id: "IA",
    name: "Interlingua (International Auxiliary Language Association)"
  },
  {
    id: "ID",
    name: "Indonesian"
  },
  {
    id: "IE",
    name: "Interlingue; Occidental"
  },
  {
    id: "IG",
    name: "Igbo"
  },
  {
    id: "II",
    name: "Sichuan Yi; Nuosu"
  },
  {
    id: "IK",
    name: "Inupiaq"
  },
  {
    id: "IO",
    name: "Ido"
  },
  {
    id: "IS",
    name: "Icelandic"
  },
  {
    id: "IT",
    name: "Italian"
  },
  {
    id: "IU",
    name: "Inuktitut"
  },
  {
    id: "JA",
    name: "Japanese"
  },
  {
    id: "JV",
    name: "Javanese"
  },
  {
    id: "KA",
    name: "Georgian"
  },
  {
    id: "KG",
    name: "Kongo"
  },
  {
    id: "KI",
    name: "Kikuyu; Gikuyu"
  },
  {
    id: "KJ",
    name: "Kuanyama; Kwanyama"
  },
  {
    id: "KK",
    name: "Kazakh"
  },
  {
    id: "KL",
    name: "Kalaallisut; Greenlandic"
  },
  {
    id: "KM",
    name: "Central Khmer"
  },
  {
    id: "KN",
    name: "Kannada"
  },
  {
    id: "KO",
    name: "Korean"
  },
  {
    id: "KR",
    name: "Kanuri"
  },
  {
    id: "KS",
    name: "Kashmiri"
  },
  {
    id: "KU",
    name: "Kurdish"
  },
  {
    id: "KV",
    name: "Komi"
  },
  {
    id: "KW",
    name: "Cornish"
  },
  {
    id: "KY",
    name: "Kirghiz; Kyrgyz"
  },
  {
    id: "LA",
    name: "Latin"
  },
  {
    id: "LB",
    name: "Luxembourgish; Letzeburgesch"
  },
  {
    id: "LG",
    name: "Ganda"
  },
  {
    id: "LI",
    name: "Limburgan; Limburger; Limburgish"
  },
  {
    id: "LN",
    name: "Lingala"
  },
  {
    id: "LO",
    name: "Lao"
  },
  {
    id: "LT",
    name: "Lithuanian"
  },
  {
    id: "LU",
    name: "Luba-Katanga"
  },
  {
    id: "LV",
    name: "Latvian"
  },
  {
    id: "MG",
    name: "Malagasy"
  },
  {
    id: "MH",
    name: "Marshallese"
  },
  {
    id: "MI",
    name: "Maori"
  },
  {
    id: "MK",
    name: "Macedonian"
  },
  {
    id: "ML",
    name: "Malayalam"
  },
  {
    id: "MN",
    name: "Mongolian"
  },
  {
    id: "MR",
    name: "Marathi"
  },
  {
    id: "MS",
    name: "Malay"
  },
  {
    id: "MT",
    name: "Maltese"
  },
  {
    id: "MY",
    name: "Burmese"
  },
  {
    id: "NA",
    name: "Nauru"
  },
  {
    id: "NB",
    name: "Bokmål, Norwegian; Norwegian Bokmål"
  },
  {
    id: "ND",
    name: "Ndebele, North; North Ndebele"
  },
  {
    id: "NE",
    name: "Nepali"
  },
  {
    id: "NG",
    name: "Ndonga"
  },
  {
    id: "NL",
    name: "Dutch; Flemish"
  },
  {
    id: "NN",
    name: "Norwegian Nynorsk; Nynorsk, Norwegian"
  },
  {
    id: "NO",
    name: "Norwegian"
  },
  {
    id: "NR",
    name: "Ndebele, South; South Ndebele"
  },
  {
    id: "NV",
    name: "Navajo; Navaho"
  },
  {
    id: "NY",
    name: "Chichewa; Chewa; Nyanja"
  },
  {
    id: "OC",
    name: "Occitan (post 1500)"
  },
  {
    id: "OJ",
    name: "Ojibwa"
  },
  {
    id: "OM",
    name: "Oromo"
  },
  {
    id: "OR",
    name: "Oriya"
  },
  {
    id: "OS",
    name: "Ossetian; Ossetic"
  },
  {
    id: "PA",
    name: "Panjabi; Punjabi"
  },
  {
    id: "PI",
    name: "Pali"
  },
  {
    id: "PL",
    name: "Polish"
  },
  {
    id: "PS",
    name: "Pushto; Pashto"
  },
  {
    id: "PT",
    name: "Portuguese"
  },
  {
    id: "QU",
    name: "Quechua"
  },
  {
    id: "RM",
    name: "Romansh"
  },
  {
    id: "RN",
    name: "Rundi"
  },
  {
    id: "RO",
    name: "Romanian; Moldavian; Moldovan"
  },
  {
    id: "RU",
    name: "Russian"
  },
  {
    id: "RW",
    name: "Kinyarwanda"
  },
  {
    id: "SA",
    name: "Sanskrit"
  },
  {
    id: "SC",
    name: "Sardinian"
  },
  {
    id: "SD",
    name: "Sindhi"
  },
  {
    id: "SE",
    name: "Northern Sami"
  },
  {
    id: "SG",
    name: "Sango"
  },
  {
    id: "SI",
    name: "Sinhala; Sinhalese"
  },
  {
    id: "SK",
    name: "Slovak"
  },
  {
    id: "SL",
    name: "Slovenian"
  },
  {
    id: "SM",
    name: "Samoan"
  },
  {
    id: "SN",
    name: "Shona"
  },
  {
    id: "SO",
    name: "Somali"
  },
  {
    id: "SQ",
    name: "Albanian"
  },
  {
    id: "SR",
    name: "Serbian"
  },
  {
    id: "SS",
    name: "Swati"
  },
  {
    id: "ST",
    name: "Sotho, Southern"
  },
  {
    id: "SU",
    name: "Sundanese"
  },
  {
    id: "SV",
    name: "Swedish"
  },
  {
    id: "SW",
    name: "Swahili"
  },
  {
    id: "TA",
    name: "Tamil"
  },
  {
    id: "TE",
    name: "Telugu"
  },
  {
    id: "TG",
    name: "Tajik"
  },
  {
    id: "TH",
    name: "Thai"
  },
  {
    id: "TI",
    name: "Tigrinya"
  },
  {
    id: "TK",
    name: "Turkmen"
  },
  {
    id: "TL",
    name: "Tagalog"
  },
  {
    id: "TN",
    name: "Tswana"
  },
  {
    id: "TO",
    name: "Tonga (Tonga Islands)"
  },
  {
    id: "TR",
    name: "Turkish"
  },
  {
    id: "TS",
    name: "Tsonga"
  },
  {
    id: "TT",
    name: "Tatar"
  },
  {
    id: "TW",
    name: "Twi"
  },
  {
    id: "TY",
    name: "Tahitian"
  },
  {
    id: "UG",
    name: "Uighur; Uyghur"
  },
  {
    id: "UK",
    name: "Ukrainian"
  },
  {
    id: "UR",
    name: "Urdu"
  },
  {
    id: "UZ",
    name: "Uzbek"
  },
  {
    id: "VE",
    name: "Venda"
  },
  {
    id: "VI",
    name: "Vietnamese"
  },
  {
    id: "VO",
    name: "Volapük"
  },
  {
    id: "WA",
    name: "Walloon"
  },
  {
    id: "WO",
    name: "Wolof"
  },
  {
    id: "XH",
    name: "Xhosa"
  },
  {
    id: "YI",
    name: "Yiddish"
  },
  {
    id: "YO",
    name: "Yoruba"
  },
  {
    id: "ZA",
    name: "Zhuang; Chuang"
  },
  {
    id: "ZH",
    name: "Chinese"
  },
  {
    id: "ZU",
    name: "Zulu"
  }
];
