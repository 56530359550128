import React, { Fragment } from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Metadata, Artist, Company, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import { useWorkDispatch } from "App/Routes/WorkRenewal/Store";
import { WorkActions } from "App/Routes/WorkRenewal/Store/Work";
import { RoleTextAutoComplete, CharacterTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import uuidv4 from "uuid/v4";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";

type SectionProps = {
  index: number;
  data: Metadata;
  pageIndex: Page;
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 700px;
  padding: 0.5rem;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `2rem 150px auto` : `2rem 34% 28% 28%`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

export const ColumnSection = ({ index, data, pageIndex }: SectionProps) => {
  const dispatch = useWorkDispatch();
  const userRole = useAppSelector(store => store.UserToken.role);
  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          if (value === null) return null;
          // 메인 정보
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "metadataId":
              case "title":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "metadataId" ? "ID" : "제목"}</span>
                    <InputText
                      isDisabled={key === "metadataId"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(WorkActions.updateWorkTitle({ index, title: val }))}
                    />
                  </LabelGroup>
                );
              case "subClass":
                return (
                  <LabelGroup key={key}>
                    <span>{"분류"}</span>
                    <InputText isDisabled defaultValue={value as string} />
                  </LabelGroup>
                );
              case "artistRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>{"아티스트"}</span>
                      <RowGroup className={!value.length ? "" : "border"}>
                        {!(value as Artist[]).length
                          ? null
                          : (value as Artist[]).map(({ artist, role, character }, artistIndex) => {
                              const key = `${artist[0]?.id ?? ""}-${role[0]?.id ?? ""}-${character[0]?.id ?? ""}-${artistIndex}`;
                              return (
                                <ExtraInfo key={key} column={4}>
                                  <RoundButton
                                    color="danger"
                                    key={`${key}-remove`}
                                    onClick={() => {
                                      dispatch(WorkActions.deleteWorkArtist({ index, artistIndex }));
                                    }}
                                  >
                                    -
                                  </RoundButton>
                                  <ArtistTextAutoComplete
                                    className="select"
                                    key={`${key}-artist`}
                                    defaultValue={
                                      !artist.length || !artist[0].id ? undefined : { id: artist[0].id!, name: artist[0].name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const artistInfo = { id: info.id, name: info.name };
                                        dispatch(WorkActions.updateWorkArtist({ index, artistIndex, info: artistInfo }));
                                      }
                                    }}
                                  />
                                  <RoleTextAutoComplete
                                    className="select"
                                    key={`${key}-role`}
                                    defaultValue={!role.length || !role[0].id ? undefined : { id: role[0].id!, name: role[0].name! }}
                                    onChange={info => {
                                      if (info) {
                                        const roleInfo = { id: info.id, name: info.name };
                                        dispatch(WorkActions.updateWorkRole({ index, roleIndex: artistIndex, info: roleInfo }));
                                      }
                                    }}
                                  />
                                  <CharacterTextAutoComplete
                                    className="select character"
                                    key={`${key}-character`}
                                    defaultValue={
                                      !character.length || !character[0].id ? undefined : { id: character[0].id!, name: character[0].name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const characterInfo = { id: info.id, name: info.name };
                                        dispatch(
                                          WorkActions.updateWorkCharacter({ index, characterIndex: artistIndex, info: characterInfo })
                                        );
                                      }
                                    }}
                                  />
                                </ExtraInfo>
                              );
                            })}
                        <RoundButton
                          color="success"
                          onClick={() => {
                            dispatch(WorkActions.createWorkArtist(index));
                          }}
                        >
                          +
                        </RoundButton>
                      </RowGroup>
                    </LabelGroup>
                  </Fragment>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(WorkActions.updateWorkVC({ index, vc }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "rightsCompany":
                const rightsCompany = (value as Company[])[0]?.company ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"권리사"}</span>
                    <CompanyTextAutoComplete
                      isDisabled={userRole !== UserRole.Master || !rightsCompany}
                      defaultValue={
                        !rightsCompany
                          ? { id: "none", name: "권리사 정보가 없습니다" }
                          : { id: rightsCompany[0].id, name: rightsCompany[0].name }
                      }
                      onBlur={info => {
                        if (info) {
                          dispatch(WorkActions.setWorkRightsCompany({ index, id: info.id, name: info.name }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "productions":
                const productions = (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"제작사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={productions}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "productions",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(WorkActions.setWorkProductions({ index, productions: list }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "publications":
                const publications =
                  (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"배급사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={publications}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "publications",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(WorkActions.setWorkPublications({ index, publications: list }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "class":
              default:
                return null;
            }
            // 부가 정보
          } else {
            switch (key) {
              case "metadataUrl":
                const urls = value as Url[];
                return (
                  <LabelGroup key={key}>
                    <span>{"URL"}</span>
                    <RowGroup className={!urls.length ? "" : "border"}>
                      {!urls?.length
                        ? null
                        : urls.map((url, i) => (
                            <ExtraInfo key={i} column={3}>
                              <RoundButton color="danger" onClick={() => dispatch(WorkActions.deleteWorkUrl({ index, urlIndex: i }))}>
                                -
                              </RoundButton>
                              <CreatableTextSelect
                                name="select"
                                className="select"
                                optionList={[
                                  { id: "source", name: "source" },
                                  { id: "score", name: "score" },
                                  { id: "imslp", name: "imslp" }
                                ]}
                                placeholder="타입"
                                defaultValue={!url.typeUrl ? undefined : { id: url.typeUrl, name: url.typeUrl }}
                                onBlur={info => {
                                  if (info) {
                                    dispatch(WorkActions.updateWorkTypeUrl({ index, urlIndex: i, typeUrl: info.id }));
                                  }
                                }}
                              />
                              <InputText
                                placeholder="값"
                                defaultValue={!url.url ? undefined : url.url}
                                onBlur={text => {
                                  dispatch(WorkActions.updateWorkUrl({ index, urlIndex: i, url: text }));
                                }}
                              />
                            </ExtraInfo>
                          ))}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const tempUrl = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeUrl: undefined,
                            url: undefined
                          } as Url;
                          dispatch(WorkActions.createWorkUrl({ index, url: tempUrl }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );

              default:
                return null;
            }
          }
        })}
    </>
  );
};
