import { LOADING } from "../../Templates/LoadingTemplate/index";
import { createReducer, createHandler, createActions } from "lib/store";

type State = ReturnType<typeof createInitialState>;

const SET_LOADING = Symbol("Loading::Loading::Set");

export const LoadingAction = createActions({
  setLoading(loading: LOADING) {
    return { type: SET_LOADING, loading };
  }
});

const handler = createHandler<State>({
  [SET_LOADING](state, payload: { loading: LOADING }) {
    state.loading = payload.loading;
  }
});

const createInitialState = () => ({
  loading: LOADING.UNLOAD as LOADING
});

export const reducer = createReducer(handler, createInitialState);
