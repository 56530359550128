import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { BLUE_4, GRAY_0, GRAY_6 } from "constants/baseColor";
import { ReactComponent as CirclePlayIcon } from "assets/icons/circle-play.svg";
import { ReactComponent as ThumbDownIcon } from "assets/icons/thumb-down.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/spinner.svg";
import { ReactComponent as AddToPlaylistIcon } from "assets/icons/add-to-playlist.svg";
import { useAsyncEffect } from "lib/use-async-effect";
import { DeletePlaylistRelation, GetPlaylistWithTrack } from "GraphQL/Queries/Playlist";
import { Playlist } from "GraphQL/Queries/Playlist/GetPlaylistWithTrack";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import { useAppDispatch, useAppSelector } from "App/Store";
import { DANGER_ALERT_COLOR } from "constants/color";
import { UserRole } from "constants/UserRole";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import { usePlaylistsDispatch } from "App/Routes/Playlists/Store";
import { Loading } from "App/Atomics/Loading";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { AddTrackToPlaylistModal } from "../../../../AddTrackToPlaylistModal/index";
import uuidv4 from "uuid/v4";
import { ParsedComment } from "../../..";
import dayjs from "dayjs";
import { PADDING_LARGE_PX } from "constants/size";

type Props = {
  index: number;
  id: string;
  onThumbDown: (id: string, title: string) => void;
  playlistInfo: Playlist;
  setPlaylistInfo: React.Dispatch<React.SetStateAction<Playlist>>;
  setComment: React.Dispatch<React.SetStateAction<ParsedComment | undefined>>;
};

const DEFAULT_TRACK_COUNT = 10;

export const PlaylistSection = ({ index, id, onThumbDown, playlistInfo, setPlaylistInfo, setComment }: Props) => {
  const [skip, setSkip] = useState<number>(DEFAULT_TRACK_COUNT);
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const audioDispatch = useAppDispatch();
  const dispatch = usePlaylistsDispatch();
  const userRole = useAppSelector(store => store.UserToken.role);
  const addTrackToPlaylistModal = useToggle();

  const playAudio = (audio: TypeAudio) => {
    audioDispatch([AudioPlayerActions.toggleVisible(true), AudioPlayerActions.setAudioData(audio)]);
  };

  const onLoadMore = async () => {
    setLoading(true);
    try {
      const { data: playlistData } = await GetPlaylistWithTrack({ id, first: DEFAULT_TRACK_COUNT, skip });
      const moreData = playlistData?.playlist[0].metadataPlaylistRelation;
      if (moreData?.length) {
        setPlaylistInfo(prev => ({ ...prev, metadataPlaylistRelation: [...playlistInfo.metadataPlaylistRelation, ...moreData] }));
        setSkip(prev => prev + DEFAULT_TRACK_COUNT);
      } else {
        Toast.warning("더 불러올 트랙이 없습니다.");
        setIsEnd(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      return;
    }
  };

  const onDeleteTrack = async (uuid: string, trackId: string, trackTitle: string, artist: string) => {
    if (window.confirm("목록에서 트랙을 삭제하시겠습니까?")) {
      try {
        setEditLoading(true);
        const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
        if (accessId) {
          try {
            const { errors } = await DeletePlaylistRelation({ uuid });
            if (errors) {
              throw new Error(errors[0].message);
            }
            await DeleteAccessRecord({ id: accessId });
            const newMetadataRelation = playlistInfo.metadataPlaylistRelation.filter(({ metadata }) => metadata[0].id !== trackId);
            setPlaylistInfo(prev => ({ ...prev, metadataPlaylistRelation: newMetadataRelation }));
            dispatch(PlaylistsActions.deletePlaylistTrack({ index, uuid }));
            setComment(prevState => {
              const data = !prevState ? [] : prevState.data;
              const timestamp = dayjs(new Date()).toISOString();
              const newData = [
                ...data,
                {
                  id: uuidv4(),
                  metadata_id: trackId,
                  title: trackTitle,
                  artist,
                  operate: "DELETE" as const,
                  timestamp
                }
              ];
              const text = !prevState ? "" : prevState.text;
              return { data: newData, text: `${!text ? "" : text}[${trackId}] ${trackTitle} 곡을 삭제하였습니다.\n` };
            });
            Toast.primary("삭제되었습니다");
          } catch (err) {
            console.log(err);
            await DeleteAccessRecord({ id: accessId });
            Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
          } finally {
            setEditLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        setEditLoading(false);
        return;
      }
    }
  };

  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const { data: playlistData } = await GetPlaylistWithTrack({ id, first: DEFAULT_TRACK_COUNT });
        if (playlistData) {
          setPlaylistInfo(playlistData.playlist[0]);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        return;
      }
    }
  }, []);

  return (
    <Layout>
      <div className="track-header">
        <span>플레이리스트 트랙 목록</span>
        {userRole === UserRole.Master && (
          <div className="add-track-icon">
            <AddToPlaylistIcon onClick={addTrackToPlaylistModal.on} />
          </div>
        )}
      </div>
      <div className="list-wrap">
        <ul>
          {!playlistInfo.metadataPlaylistRelation?.length ? (
            <div className="no-track">{loading ? "" : "트랙이 없습니다."}</div>
          ) : (
            playlistInfo.metadataPlaylistRelation.map(({ uuid, metadata }) => {
              const track = metadata[0];
              const audioId = uuidv4();
              const artistName = metadata[0].artistRelation[0]?.artist[0]?.name ?? "-";
              const { typeUrl, url } = metadata[0].metadataUrl.filter(({ typeUrl }) => typeUrl === "mp3high" || typeUrl === "wav")[0];
              return (
                <ListItem key={uuid} userRole={userRole}>
                  <div className="thumb-play" onClick={() => playAudio({ title: track.title, url, type: typeUrl, uuid: audioId })}>
                    <CirclePlayIcon />
                  </div>
                  <div className="info-area">
                    <span className="title">{track.title}</span>
                    <span className="artist">{artistName}</span>
                  </div>
                  <div className="thumb-down">
                    <ThumbDownIcon onClick={() => onThumbDown(track.id, track.title)} />
                  </div>
                  <div className="trash">
                    <TrashIcon onClick={() => onDeleteTrack(uuid, track.id, track.title, artistName)} />
                  </div>
                </ListItem>
              );
            })
          )}
          {!loading && !isEnd && <MoreButton onClick={onLoadMore}>더 보기</MoreButton>}
        </ul>
        <Loading loading={editLoading} />
        {loading && (
          <LoadingContainer>
            <LoadingIcon />
          </LoadingContainer>
        )}
      </div>
      <Modal isOpen={addTrackToPlaylistModal.isToggled}>
        <AddTrackToPlaylistModal
          index={index}
          id={id}
          setPlaylistInfo={setPlaylistInfo}
          setComment={setComment}
          toClose={addTrackToPlaylistModal.off}
        />
      </Modal>
    </Layout>
  );
};

const flowText = keyframes`
  to {
    transform: translateX(-200%);
  }
`;

const Layout = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 350px;
  background-color: #eee;
  .track-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    color: #fff;
    padding: 1.1rem;
    font-size: 1.03rem;
    font-weight: 600;
    border-bottom: 1px solid #d4d4d4;
    background-color: #000;

    .add-track-icon {
      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: #fff;
        cursor: pointer;
        transition: fill 0.15s;
        &:hover {
          fill: #ffe066;
        }
      }
    }
  }
  .list-wrap {
    position: absolute;
    top: 4rem;
    right: 0;
    left: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .no-track {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
`;

const shake = keyframes`
  10%, 90% {
    transform: translate3d(0,-2px,0);
  };
  20%, 80% {
    transform: translate3d(0,4px,0);
  };
  30%, 50%, 70% {
    transform: translate3d(0,-8px,0);
  };
  40%, 60% {
    transform: translate3d(0,8px,0);
  };
`;

const ListItem = styled.li<{ userRole: UserRole | null }>`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  user-select: none;
  transition: background-color 0.1s;
  &:hover {
    background-color: ${GRAY_0};
  }
  .thumb-play {
    display: table-cell;
    width: 72px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 36px;
      height: 36px;
      fill: #141414;
      vertical-align: middle;
      transition: fill 0.15s;
      &:hover {
        fill: ${BLUE_4};
      }
    }
  }

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;

    span {
      overflow: hidden;
      --webkit-line-break: 1;
      --webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      display: -webkit-box;
    }
    .title {
      height: 22px;
      line-height: 22px;
      color: #191919;
      &:hover {
        animation: ${flowText} 15s infinite linear;
      }
    }
    .artist {
      height: 17px;
      line-height: 17px;
      font-size: 13px;
      color: ${GRAY_6};
    }
  }

  .thumb-down {
    display: table-cell;
    width: 68px;
    height: 40px;
    padding: 0 12px 0 20px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
    }
    &:active {
      animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
  .trash {
    display: ${props => (props.userRole === UserRole.Master ? "table-cell" : "none")};
    width: 40px;
    height: 40px;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      vertical-align: middle;
      transition: fill 0.15s;
      &:hover {
        fill: ${DANGER_ALERT_COLOR};
      }
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const MoreButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  padding: ${PADDING_LARGE_PX} 0;
`;
