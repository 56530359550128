import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Artist, Url, Structure, GetMetadata } from "GraphQL/Queries/Metadata/GetMetadata";
import { configs } from "configs";
import { useToggle } from "lib/use-toggle";
import { ReactComponent as TitleIcon } from "assets/icons/title.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/photo.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as ShuffleIcon } from "assets/icons/shuffle.svg";
import defaultImage from "assets/images/cover.png";
import { mediaQuery } from "constants/media";
import { Modal } from "lib/modal";
import { ImageViewModal, EditInfoModal, TitleModal, ChangeClassModal } from "../../../Modal";
import { ARTISTS, WORKS_DETAIL } from "constants/route";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, DANGER_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import uuidv4 from "uuid/v4";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { Confirm } from "App/Molecules/Confirm";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";
import { DeleteMetadataUrl } from "GraphQL/Queries/Metadata";
import { WorkActions } from "App/Routes/WorkRenewal/Store/Work";
import { useWorkDispatch } from "App/Routes/WorkRenewal/Store";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";

type Props = {
  index: number;
  metadataId: string;
  title: string;
  metadataUrl: Url[];
  artistRelation: Artist[];
  validCheck: ValidType;
  structures: Structure[];
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;

  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  cursor: pointer;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #eee;
    }
  }

  a.link::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6em;
    content: "";
    z-index: 1;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    a.link::after {
      opacity: 1;
    }

    .wrapper,
    .delete,
    .photo,
    .document,
    .title,
    .shuffle {
      opacity: 1;
    }
  }
  .svg-wrapper {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 0;
    right: 0;
    z-index: 2;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }
  }

  .delete,
  .photo,
  .document,
  .title,
  .shuffle {
    position: absolute;
    bottom: 1.2em;
    z-index: 3;
    opacity: 0;
    fill: #eee;
    width: 1.2em;
    height: 1.2em;
    transition: all 0.15s;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }

    &:hover {
      fill: #fff;
      transform: scale(1.3, 1.3);
    }
  }

  .delete {
    left: 1.2em;
    &:hover {
      fill: #f00;
    }
  }

  .photo {
    right: 1.2em;
  }
  .document {
    right: 3.5em;
  }
  .title {
    right: 5.8em;
  }
  .shuffle {
    right: 8.1em;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 9em;
  max-height: 8em;

  span {
    white-space: pre-wrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .id {
    font-size: 0.8em;
    color: #bbb;
  }
  .title {
    font-weight: bold;
    flex-wrap: wrap;
  }
  .artist {
    color: #666;
    cursor: pointer;
    font-size: 0.9em;
    margin-right: 0.2rem;
  }
`;

const Badge = styled.div<{ vc: ValidType }>`
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 3%;
  color: ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR : "#eee")};
  text-shadow: 0 0 2px ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR_LIGHT : PRIMARY_COLOR)};
`;

export const CardItem = ({ index, metadataId, title, metadataUrl, artistRelation, validCheck, structures }: Props) => {
  const { store, userRole } = useAppSelector(store => ({
    store: store,
    userRole: store.UserToken.role
  }));
  const dispatch = useWorkDispatch();
  const router = useHistory();
  const imageViewModal = useToggle();
  const editInfoModal = useToggle();
  const confirmModal = useToggle();
  const titleModal = useToggle();
  const changeClassModal = useToggle();

  const imageUrl = metadataUrl?.filter(({ typeUrl }) => typeUrl === "head" || typeUrl === "thumbnail" || typeUrl === "cover") ?? [];
  const url =
    imageUrl && imageUrl.length
      ? `${configs.urls.image}/${imageUrl[0].url}${imageUrl[0].url!.includes("?") ? `&` : `?`}mode=m`
      : defaultImage;

  // [Order] 1. Remove Track -> 2. Remove Album Images -> 3. Remove Album
  const onDelete = async (id: string) => {
    try {
      dispatch(WorkActions.setEditLoading(true));
      const trackList: string[] = [];
      const { data } = await GetMetadata({ metadataId: id, typeClassIn: ["work"], typeSubClass: "album", detail: true });
      if (data?.metadata[0]?.structures.length) {
        const tracks = data.metadata[0].structures[0].child;
        if (tracks.length) {
          tracks.forEach(track => trackList.push(track.metadata[0].metadataId));
        }
      }
      for (const targetId of trackList) {
        const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Metadata });
        if (accessId) {
          const { errors } = await DeleteMetadata({ id: targetId });
          if (errors) {
            await DeleteAccessRecord({ id: accessId });
            throw errors[0].message;
          }
          await DeleteAccessRecord({ id: accessId });
        }
      }
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        for (const image of imageUrl) {
          await DeleteFile({
            filename: image.url,
            companyId: data?.metadata[0].rightsCompany[0].company[0].id,
            fileType: FileType.FILE,
            book: BookType.immediate
          });
          await DeleteMetadataUrl({ uuid: image.uuid });
        }
        const { errors } = await DeleteMetadata({ id });
        if (errors) {
          await DeleteAccessRecord({ id: accessId });
          throw errors[0].message;
        }
        await DeleteAccessRecord({ id: accessId });
        dispatch(WorkActions.setEditLoading(false));
        Toast.primary("삭제되었습니다", undefined, "top-center");
        dispatch(WorkActions.deleteWork(index));
      }
    } catch (err) {
      console.log(err);
      dispatch(WorkActions.setEditLoading(false));
      Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
      return;
    }
  };

  return (
    <Layout key={metadataId}>
      <ImageContainter>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="link" onClick={() => router.push(`${WORKS_DETAIL}?id=${metadataId}`)}>
          <img src={url} alt={`${title}-cover`} />
        </a>
        <Badge vc={validCheck}>{validCheck}</Badge>
        {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
          <div className="svg-wrapper" onClick={e => e.stopPropagation()}>
            {userRole === UserRole.Master && <DeleteIcon className="delete" onClick={confirmModal.on} />}
            <ShuffleIcon className="shuffle" onClick={changeClassModal.on} />
            <TitleIcon className="title" onClick={titleModal.on} />
            <DocumentIcon className="document" onClick={editInfoModal.on} />
            <PhotoIcon className="photo" onClick={imageViewModal.on} />
          </div>
        )}
      </ImageContainter>
      <Info>
        <ClipBoard text={metadataId}>
          <span className="id">{metadataId}</span>
        </ClipBoard>
        <ClipBoard text={title}>
          <span className="title">{title}</span>
        </ClipBoard>
        <div>
          {!artistRelation.length ? (
            <span className="artist">{"-"}</span>
          ) : (
            artistRelation.map(({ artist }, index) => {
              const uuid = uuidv4();
              return (
                <span className="artist" key={uuid} onClick={() => router.push(`${ARTISTS}?page=1&q=${artist[0].id}&type=아이디`)}>
                  {`${artist[0].name}${index !== artistRelation.length - 1 ? "," : ""}`}
                </span>
              );
            })
          )}
        </div>
      </Info>
      <Modal isOpen={imageViewModal.isToggled} onClose={imageViewModal.off}>
        <ImageViewModal index={index} id={metadataId} metadataUrl={imageUrl} toClose={imageViewModal.off} />
      </Modal>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        <EditInfoModal index={index} toClose={editInfoModal.off} />
      </Modal>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm
          title={"경고"}
          context={"작품에 포함된 소작품도 함께 삭제됩니다.\n정말로 삭제하시겠습니까?"}
          toSave={() => onDelete(metadataId)}
          toClose={confirmModal.off}
        />
      </Modal>
      <Modal isOpen={titleModal.isToggled} onClose={titleModal.off}>
        <TitleModal id={metadataId} toClose={titleModal.off} />
      </Modal>
      <Modal isOpen={changeClassModal.isToggled} onClose={changeClassModal.off}>
        <ChangeClassModal
          index={index}
          id={metadataId}
          structureId={structures[0].structureId}
          title={title}
          artist={artistRelation[0]?.artist[0]?.name ?? undefined}
          toClose={changeClassModal.off}
        />
      </Modal>
    </Layout>
  );
};
