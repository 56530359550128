import React from "react";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Input } from "App/Atomics/Input";
import { ArtistEditActions } from "../Store/Artist";
import { useArtistsDispatch, useArtistsSelector } from "../Store";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType/TextSelect";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role/TextAutoComplete";
import styled from "styled-components";
import { pixelize, UNIT } from "constants/size";
import { Subhead } from "../Store/ArtistInfo/sanitizeHeads";
import { PRIMARY_COLOR } from "constants/color";
import { configs } from "configs";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { EditFileModal } from "../Modals/EditFileModal";
import { GenreTextAutoComplete } from "App/Molecules/AutoCompletes/Genre";

const ItemRow = styled(Table.Row)`
  td {
    min-width: ${pixelize(5 * UNIT)};

    .validSelect,
    .languageSelect,
    .autocomplete {
      min-width: ${pixelize(7 * UNIT)};
    }

    & p {
      margin: ${pixelize(0.5 * UNIT)} ${pixelize(1 * UNIT)};
    }
    .history-input {
      width: 12rem;
    }
  }

  .artistSelect__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .artistSelect__menu {
    .artistSelect__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const Multiline = styled(Table.Data)`
  padding: ${pixelize(1 * UNIT)} ${pixelize(1.5 * UNIT)};

  & > div {
    display: flex;
    align-items: center;

    & + div {
      margin-top: 1rem;
    }

    * + * {
      margin-left: 1rem;
    }
  }

  & .removeBtn {
    width: ${pixelize(2.5 * UNIT)};
    border-radius: 50%;
    transform: scale(0.75);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: ${pixelize(UNIT * 6.25)};
    height: ${pixelize(UNIT * 6.25)};
  }
`;

const FileDiv = styled.div`
  display: flex;
  flex-direction: column;

  & > button {
    margin: 0;
  }
`;

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 40,
    width: 250
  })
};

export const DataTemplate = ({ index, data }: ColumnProps<any>) => {
  const { artistId, name, typeArtistClass, thumbUrl, validCheck, artistUrls, history, roleRelation, genreRelation } = data;
  const subhead = useArtistsSelector(store => store.ArtistInfo.subhead);
  return (
    <ItemRow>
      <Artist
        index={index}
        subhead={subhead}
        artistId={artistId}
        name={name}
        typeArtistClass={typeArtistClass}
        thumbUrl={thumbUrl}
        validCheck={validCheck}
      />
      {artistUrls && <ArtistUrl index={index} artistId={artistId} artistUrls={artistUrls} subhead={subhead} />}
      {genreRelation && <Genre index={index} genre={genreRelation} subhead={subhead} />}
      {history && <History index={index} history={history} subhead={subhead} />}
      {roleRelation && <Role index={index} role={roleRelation} subhead={subhead} />}
    </ItemRow>
  );
};

type ArtistProps = Readonly<{
  index: number;
  subhead: Subhead;
  artistId: any;
  name: any;
  typeArtistClass: any;
  thumbUrl: any;
  validCheck: any;
}>;

const artistTypeOptionList = [
  { id: "person", name: "person" },
  { id: "organization", name: "organization" },
  { id: "channel", name: "channel" }
];

const TypeClassSelect = styled(Input.TextSelect)`
  min-width: 10rem;
`;

const Artist = ({ index, subhead, artistId, name, typeArtistClass, thumbUrl, validCheck }: ArtistProps) => {
  const dispatch = useArtistsDispatch();

  return (
    <>
      {artistId && <Table.Data>{artistId}</Table.Data>}
      {subhead.Artist.name && name && (
        <Table.Data>
          <Input.Text defaultValue={name} onBlur={value => dispatch(ArtistEditActions.setNameByIndex({ index, name: value }))} />
        </Table.Data>
      )}
      {subhead.Artist.typeArtistClass && typeArtistClass && (
        <Table.Data>
          <TypeClassSelect
            optionList={artistTypeOptionList}
            defaultValue={!typeArtistClass ? undefined : { id: typeArtistClass, name: typeArtistClass }}
            onBlur={info => {
              if (info) {
                dispatch(ArtistEditActions.setTypeByIndex({ index, typeArtistClass: info.name }));
              }
            }}
          />
        </Table.Data>
      )}
      {subhead.Artist.validCheck && validCheck && (
        <Table.Data>
          <ValidTypeTextSelect
            className="validSelect"
            classNamePrefix="artistSelect"
            defaultValue={validCheck}
            onChange={vc => {
              if (vc) {
                dispatch(ArtistEditActions.setValidCheckByIndex({ index, validCheck: vc }));
              } else {
                // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
              }
            }}
          />
        </Table.Data>
      )}
    </>
  );
};

type ArtistUrlProps = Readonly<{ index: number; artistId: string; artistUrls: any[]; subhead: Subhead }>;

const ArtistUrl = ({ index, artistId, artistUrls, subhead }: ArtistUrlProps) => {
  const dispatch = useArtistsDispatch();
  const userRole = useAppSelector(store => store.UserToken.role);
  const editFileModal = useToggle();

  const createUrl = (index: number) => dispatch(ArtistEditActions.setCreateUrlByIndex({ index, column: "url" }));

  const thumbnail = (artistUrls as any[]).filter(url => url.typeUrl === "thumbnail")[0];
  const thumbnailUrl = thumbnail ? thumbnail.url : "";

  if (!artistUrls || !artistUrls.length) {
    if (userRole !== UserRole.Master) {
      return <Table.Data>{}</Table.Data>;
    }
    return (
      <Multiline>
        <div>
          <Input.Button color="primary" isWide onClick={() => createUrl(index)}>
            +
          </Input.Button>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.ArtistUrl.url && (
        <Multiline>
          {(artistUrls as any[]).map((artistUrl, artistUrlIndex) => (
            <div key={artistUrlIndex}>
              {
                <FileDiv>
                  <ImageContainer>{thumbnailUrl && <img src={`${configs.urls.image}/${thumbnailUrl}?mode=s`} alt="" />}</ImageContainer>
                  <Input.Button color="danger" isWide onClick={editFileModal.on}>
                    {thumbnailUrl ? "파일 변경" : "파일 등록"}
                  </Input.Button>
                </FileDiv>
              }
              {
                <>
                  <Input.Text
                    style={{ width: pixelize(UNIT * 10) }}
                    defaultValue={artistUrl.typeUrl}
                    onBlur={value =>
                      dispatch(
                        ArtistEditActions.setArtistUrlTypeByIndex({
                          index,
                          relationIndex: artistUrlIndex,
                          column: "typeUrl",
                          typeUrl: value
                        })
                      )
                    }
                  />
                  <Input.Text
                    style={{ width: pixelize(UNIT * 30) }}
                    defaultValue={artistUrl.url}
                    onBlur={value =>
                      dispatch(ArtistEditActions.setArtistUrlByIndex({ index, relationIndex: artistUrlIndex, column: "urls", url: value }))
                    }
                  />
                </>
              }
              <Modal isOpen={editFileModal.isToggled}>
                <EditFileModal
                  id={artistUrl.relationId}
                  url={artistUrl.url}
                  artistId={artistId}
                  action={thumbnailUrl ? "UPDATE" : "INSERT"}
                  toClose={editFileModal.off}
                />
              </Modal>
            </div>
          ))}
        </Multiline>
      )}
    </>
  );
};

type GenreProps = Readonly<{ index: number; genre: any; subhead: Subhead }>;

const Genre = ({ index, genre, subhead }: GenreProps) => {
  const dispatch = useArtistsDispatch();

  const toCreateGenre = (index: number, column: string, order: number) =>
    dispatch(ArtistEditActions.setCreateGenreByIndex({ index, column, order }));

  const toRemoveGenre = (index: number, relationIndex: number, column: string) =>
    dispatch(ArtistEditActions.setRemoveGenreByIndex({ index, relationIndex, column }));

  return (
    <>
      {subhead.ArtistGenre.name && (
        <Multiline>
          {(genre as any[]).map(
            (genre, genreIndex) =>
              genre.relationId && (
                <div key={genreIndex} className="genre_value">
                  <Input.Button color="danger" className="removeBtn" onClick={() => toRemoveGenre(index, genreIndex, "genre")}>
                    -
                  </Input.Button>
                  {genre.genre && (
                    <GenreTextAutoComplete
                      style={customStyles}
                      className="autocomplete"
                      classNamePrefix="artistSelect"
                      defaultValue={genre.genre[0]}
                      onBlur={info => {
                        if (info) {
                          dispatch(ArtistEditActions.setGenreByIndex({ index, relationIndex: genreIndex, column: "genre", value: info }));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                </div>
              )
          )}
          <div>
            <Input.Button
              color="primary"
              isWide
              onClick={() => {
                const order = !genre.length ? 0 : genre[genre.length - 1].order + 1;
                toCreateGenre(index, "genre", order);
              }}
            >
              +
            </Input.Button>
          </div>
        </Multiline>
      )}
    </>
  );
};

type HistoryProps = Readonly<{ index: number; history: any; subhead: Subhead }>;

const History = ({ index, history, subhead }: HistoryProps) => {
  const dispatch = useArtistsDispatch();

  return (
    <>
      {subhead.History.birthDate && (
        <Table.Data>
          <Input.Text
            className="history-input"
            defaultValue={history.length ? history[0].birthDate : ""}
            placeholder="ex. 19XX.XX.XX"
            pattern="(?:[0-9X]){4}\.(?:[0-9X]){2}\.(?:[0-9X]){2}"
            onBlur={value =>
              dispatch(ArtistEditActions.setBirthDateByIndex({ index, relationIndex: 0, column: "birth_date", date: value }))
            }
          />
        </Table.Data>
      )}
      {subhead.History.birthPlace && (
        <Table.Data>
          <Input.Text
            className="history-input"
            defaultValue={history.length ? history[0].birthPlace : ""}
            placeholder="ex. place, city, country"
            onBlur={value =>
              dispatch(ArtistEditActions.setBirthPlaceByIndex({ index, relationIndex: 0, column: "birth_place", place: value }))
            }
          />
        </Table.Data>
      )}
      {subhead.History.deathDate && (
        <Table.Data>
          <Input.Text
            className="history-input"
            defaultValue={history.length ? history[0].deathDate : ""}
            placeholder="ex. 19XX.XX.XX"
            pattern="(?:[0-9X]){4}\.(?:[0-9X]){2}\.(?:[0-9X]){2}"
            onBlur={value =>
              dispatch(ArtistEditActions.setDeathDateByIndex({ index, relationIndex: 0, column: "death_date", date: value }))
            }
          />
        </Table.Data>
      )}
      {subhead.History.deathPlace && (
        <Table.Data>
          <Input.Text
            className="history-input"
            placeholder="ex. place, city, country"
            defaultValue={history.length ? history[0].deathPlace : ""}
            onBlur={value =>
              dispatch(ArtistEditActions.setDeathPlaceByIndex({ index, relationIndex: 0, column: "death_place", place: value }))
            }
          />
        </Table.Data>
      )}
    </>
  );
};

type RoleProps = Readonly<{ index: number; role: any; subhead: Subhead }>;

const Role = ({ index, role, subhead }: RoleProps) => {
  const dispatch = useArtistsDispatch();

  const toCreateRole = (index: number, column: string) => dispatch(ArtistEditActions.setCreateRoleByIndex({ index, column }));

  const toRemoveRole = (index: number, relationIndex: number, column: string) =>
    dispatch(ArtistEditActions.setRemoveRoleByIndex({ index, relationIndex, column }));

  return (
    <>
      {subhead.Role.name && (
        <Multiline>
          {(role as any[]).map(
            (role, roleIndex) =>
              role.relationId && (
                <div key={roleIndex} className="role_value">
                  <Input.Button color="danger" className="removeBtn" onClick={() => toRemoveRole(index, roleIndex, "role")}>
                    -
                  </Input.Button>
                  {role.role && (
                    <RoleTextAutoComplete
                      style={customStyles}
                      className="autocomplete"
                      classNamePrefix="artistSelect"
                      defaultValue={role.role[0]}
                      onBlur={info => {
                        if (info) {
                          dispatch(ArtistEditActions.setRoleByIndex({ index, relationIndex: roleIndex, column: "role", value: info }));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                </div>
              )
          )}
          <div>
            <Input.Button color="primary" isWide onClick={() => toCreateRole(index, "role")}>
              +
            </Input.Button>
          </div>
        </Multiline>
      )}
    </>
  );
};
