import { MOOD_LIST } from "constants/storageKey";
import { GetMoodList } from "GraphQL/Queries/Genre";
import { GetMoodParentList } from "GraphQL/Queries/Genre/GetMoodParentList";
import { err, ok, Result } from "utils/result";

export type Mood = {
  id: string;
  name: string;
  relationList: MoodRelation[];
};

export type MoodRelation = {
  parent: Array<{ id: string }>;
  children: Array<{ id: string }>;
};

type Item = Mood[];

export const loadMoodList = async (): Promise<Result<Item, any>> => {
  // const moodList = JSON.parse(window.localStorage.getItem(MOOD_LIST) ?? "[]");
  const moodList = JSON.parse("[]"); // TODO: 로컬스토리지 초기화를 위해 캐싱을 잠시 멈춤
  if (moodList.length) {
    return ok(moodList);
  }

  try {
    const { data: parentData } = await GetMoodParentList();
    const { data: childData } = await GetMoodList();
    if (!parentData || !childData) {
      return err(null);
    }

    const nextParentMoodList = parentData.genre.map(transformToMood);
    const nextChildMoodList = childData.genre.map(transformToMood);
    const moodList = nextChildMoodList.concat(nextParentMoodList);
    window.localStorage.setItem(MOOD_LIST, JSON.stringify(moodList));
    return ok(moodList);
  } catch (error) {
    return err(error);
  }
};

const transformToMood = ({ id, name, genre_self_relation_child: child = [] }: any = {} as any): Mood => {
  return {
    id: id,
    name,
    relationList: child.map(transformToRelation)
  };
};

const transformToRelation = ({ parent = [], child = [] }: any): MoodRelation => {
  const transformToMood = ({ id }: any) => ({ id: id });
  return {
    parent: parent.map(transformToMood),
    children: child.map(transformToMood)
  };
};
