/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
}>;

export const DeleteMetadataArtist = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_METADATA_ARTIST_RELATION">
      <SelectionSet
        name="deleteMetadata_artist_relation"
        alias="deleteArtist"
        argument={{
          where: { id: $uuid }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteArtist: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
