import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { PlaylistCreateActions as TrackList } from "./TrackList";

const reducers = { TrackList };

const reducer = combineReducers(reducers, createStoreLogger("PlaylistCreate"));

export const {
  StoreProvider: PlaylistCreateStoreProvider,
  useStore: usePlaylistCreateStore,
  useSelector: usePlaylistCreateSelector,
  useDispatch: usePlaylistCreateDispatch
} = createStore(reducer);
