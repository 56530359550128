import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { CardItem } from "./CardItem";
import { Genre } from "GraphQL/Queries/Genre/GetGenre";

type Props = {
  weight: number;
  genres: Genre[];
};

const Layout = styled.ul<{ weight: number }>`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => (props.weight === 0 ? 0 : HEADER_HEIGHT_PX)};
  padding: 2em;
  padding-bottom: 0;
`;

export const CardList = ({ weight, genres }: Props) => {
  return (
    <Layout weight={weight}>
      {genres.map((genre, index) => {
        return <CardItem key={genre.id} index={index} genre={genre} />;
      })}
    </Layout>
  );
};
