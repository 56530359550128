import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { PlaylistAutoCreateActions as TrackList } from "./TrackList";
import { CsvDataActions as CsvUpload } from "./CsvUpload";

const reducers = { TrackList, CsvUpload };

const reducer = combineReducers(reducers, createStoreLogger("PlaylistAutoCreate"));

export const {
  StoreProvider: PlaylistAutoCreateStoreProvider,
  useStore: usePlaylistAutoCreateStore,
  useSelector: usePlaylistAutoCreateSelector,
  useDispatch: usePlaylistAutoCreateDispatch
} = createStore(reducer);
