import React from "react";
import styled from "styled-components";
import { useCsvUploadStore } from "../../Store";
import { TableTemplate } from "App/Templates/TableTemplate";
import { DataTemplate } from "./DataTemplate";
import { MARGING_LARGE_PX, HEADER_HEIGHT_PX, UNIT, pixelize } from "constants/size";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { Input } from "App/Atomics/Input";
import { CsvUploadActions, Page } from "../../Store/CsvUpload";
import { PRIMARY_COLOR } from "constants/color";
import { Prompt } from "react-router";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

export const CsvViewForm = () => {
  const [{ csvData, fields, headerOption }, dispatch] = useCsvUploadStore(store => store.CsvUpload);
  const HEAD_LIST = [{ name: "CSV", colSpan: fields.length }];

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const changeHeader = (index: number, header: string, prevHeader: string) => {
    dispatch([CsvUploadActions.editCsvHeader(header, index), CsvUploadActions.setCsvHeaderOption(header, prevHeader)]);
  };

  const SUB_HEAD_LIST = fields.map((field, index) => {
    const selectStyles = {
      control: (base: any) => ({
        ...base,
        borderColor: PRIMARY_COLOR
      }),
      singleValue: (base: any) => ({
        ...base,
        color: PRIMARY_COLOR
      })
    };
    return (
      <SubHeadInput
        style={selectStyles}
        optionList={headerOption}
        defaultValue={{ id: field, name: field, isDisabled: false }}
        onChange={info => {
          if (info) {
            changeHeader(index, info.name, field);
          }
        }}
      />
    );
  });

  const toMovePage = (page: Page) => {
    if (page === Page.FIRST) {
      if (window.confirm("이전 페이지로 돌아가면 저장되지 않습니다. 정말로 돌아가시겠습니까?")) {
        dispatch(CsvUploadActions.setCsvUploadPage(page));
      } else {
        return;
      }
    } else {
      if (headerOption.some(({ isDisabled }) => !isDisabled)) {
        if (window.confirm("선택되지 않은 컬럼이 존재합니다. 이대로 진행하시겠습니까?")) {
          dispatch([CsvUploadActions.setCsvAlbumInfo(), CsvUploadActions.setCsvUploadPage(page)]);
          return;
        } else {
          return;
        }
      }
      dispatch([CsvUploadActions.setCsvAlbumInfo(), CsvUploadActions.setCsvUploadPage(page)]);
    }
  };

  return (
    <Layout>
      <Prompt message="이전 페이지로 돌아가면 저장되지 않습니다. 정말로 돌아가시겠습니까?" />
      <TopbarTemplate openSidebar={openSidebar}>
        <Input.Button onClick={() => toMovePage(Page.FIRST)}>이전 페이지</Input.Button>
        <RightButton onClick={() => toMovePage(Page.THRID)}>다음 페이지</RightButton>
      </TopbarTemplate>
      <TableTemplate
        keyBy={(data, i) => i}
        headList={HEAD_LIST}
        subHeadList={SUB_HEAD_LIST}
        dataList={csvData.map(csv => ({ csv, fields }))}
        Column={DataTemplate}
      />
    </Layout>
  );
};

const Layout = styled.div`
  overflow-x: scroll;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);

  table {
    margin-top: ${HEADER_HEIGHT_PX};
    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }
  }

  form {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

const SubHeadInput = styled(Input.TextSelect)`
  text-overflow: ellipsis;
  min-width: ${pixelize(UNIT * 13.5)};
  font-size: 13px;
  border-color: red;
`;

const RightButton = styled(Input.Button)`
  align-self: right;
`;
