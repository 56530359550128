import { GoodsCreateStore, useGoodsCreateSelector } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions, Section } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { Style } from "../../../Style";
import { ReactComponent as RightIcon } from "assets/icons/angle-right.svg";
import { useScrollToTop } from "App/Routes/GoodsCreate/Hooks/useScrollToTop";
import {
  GoodsType,
  GoodsName,
  GoodsGlobalName,
  GoodsArtist,
  GoodsCompany,
  GoodsDate,
  GoodsDetail,
  GoodsParent,
  GoodsSubdata,
  GoodsOfficialUrl,
  GoodsTag,
  GoodsSubTitle,
  GoodsPrice,
  GoodsOfficialBarcode,
  GoodsWork
} from "./Form";
import { useLoadGoodsTypes } from "App/Routes/GoodsCreate/Hooks/useLoadGoodsTypes";
import { Toast } from "lib/toast";

export const First = (): ReactElement => {
  const { goods, goodsTypes } = useGoodsCreateSelector(store => ({
    goods: store.GoodsCreate.goods,
    goodsTypes: store.GoodsCreate.goodsTypes
  }));
  const {
    name,
    globalName,
    subtitle,
    details,
    artists,
    company,
    work,
    askPrice,
    officialPrice,
    officialBarcode,
    tags,
    createdAt,
    parent,
    subdatas,
    officialUrl,
    typeInfo
  } = goods;
  const validateAndGoNextPage = () => {
    switch (true) {
      case !name?.length || !globalName?.length:
        Toast.warning("상품명을 입력해주세요.");
        window.scrollTo(0, 0);
        return;
      case !subtitle?.length:
        Toast.warning("서브제목을 입력해주세요.");
        return;
      case !tags?.length:
        Toast.warning("태그를 입력해주세요.");
        return;
      case !artists?.length:
        Toast.warning("아티스트를 추가해주세요.");
        return;
      case !company?.id:
        Toast.warning("제조사를 추가해주세요.");
        return;
      case !subdatas.find(item => item.field === "weight")?.value:
        Toast.warning("무게를 추가해주세요.");
        return;
      default:
        GoodsCreateStore.dispatch(GoodsCreateActions.setSection(Section.Second));
        return;
    }
  };
  useLoadGoodsTypes();
  useScrollToTop();
  return (
    <Layout>
      <Style.Title>굿즈 등록을 위해 아래 정보를 입력해주세요.</Style.Title>
      <GoodsType goodsTypes={goodsTypes} typeInfo={typeInfo} />
      <GoodsParent typeId={typeInfo?.id} parent={parent} />
      <GoodsName name={name} />
      <GoodsGlobalName globalName={globalName} />
      <GoodsSubTitle subtitle={subtitle} />
      <GoodsDetail details={details} />
      <GoodsTag tags={tags} />
      <GoodsArtist artists={artists} />
      <GoodsCompany company={company} />
      <GoodsWork work={work} />
      <GoodsOfficialUrl officialUrl={officialUrl} />
      <GoodsOfficialBarcode officialBarcode={officialBarcode} />
      <GoodsPrice askPrice={askPrice} officialPrice={officialPrice} />
      <GoodsDate createdAt={createdAt} />
      <GoodsSubdata subdatas={subdatas} />
      <Style.Row>
        <Style.ButtonGroup>
          <Style.IconButton onClick={validateAndGoNextPage}>
            <RightIcon />
          </Style.IconButton>
        </Style.ButtonGroup>
      </Style.Row>
    </Layout>
  );
};

const Layout = styled.div``;
