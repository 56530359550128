import React, { useState } from "react";
import { Input } from "App/Atomics/Input";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";
import { Toast } from "lib/toast";
import { CreateLanguageLocale } from "GraphQL/Queries/Language";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { LocaleModalStyle } from "../style";
import { useLanguageStore } from "App/Routes/LanguageManage/Store";
import { LanguageActions } from "App/Routes/LanguageManage/Store/Language";
import _ from "lodash";

type Props = {
  targetCode: string;
  onClose: () => void;
};

export const CreateLocaleModal = ({ targetCode, onClose }: Props) => {
  const [{ languageLocales }, dispatch] = useLanguageStore(store => store.Language);
  const [typeTitle, setTypeTitle] = useState<string>("");
  const [displayCode, setDisplayCode] = useState<string>("");
  const [value, setValue] = useState<string>("");

  const onSave = async (languageCode: string, typeTitle: string, displayCode: string, value: string, order: number) => {
    const accessId = await requestAccessRecord({ targetId: languageCode, targetTable: TargetTableInput.Language });
    if (accessId) {
      try {
        const uuid = await CreateLanguageLocale({ languageCode, typeTitle, displayCode, value, order });
        await DeleteAccessRecord({ id: accessId });
        Toast.success("추가되었습니다.");
        dispatch(LanguageActions.createLanguageLocale({ uuid, languageCode, typeTitle, displayCode, value }));
        onClose();
      } catch (err) {
        console.log(err);
        await DeleteAccessRecord({ id: accessId });
        Toast.error("생성에 실패하였습니다.");
        return;
      }
    }
  };

  return (
    <LocaleModalStyle.Layout>
      <LocaleModalStyle.RowGroup>
        <h2>언어팩 추가</h2>
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.RowGroup>
        <h4>타입 선택 (Type Title)</h4>
        <Input.TextSelect
          placeholder="타입을 선택해주세요"
          className="typeSelect"
          optionList={TitleTypeOptionInfo}
          onChange={info => {
            if (info) {
              setTypeTitle(info.id);
            }
          }}
        />
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.RowGroup>
        <h4>표기 언어 선택 (Display Code)</h4>
        <LocaleModalStyle.LanguageSelect
          onChange={code => {
            if (code) {
              setDisplayCode(code.id);
            }
          }}
        />
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.RowGroup>
        <h4>내용</h4>
        <LocaleModalStyle.InputTextArea placeholder="내용을 입력하세요" defaultValue="" onChange={e => setValue(e.target.value)} />
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.ButtonGroup>
        <Input.Button className="cancelButton" onClick={onClose}>
          취소
        </Input.Button>
        <Input.Button
          color="primary"
          disabled={!typeTitle.length || !displayCode.length || !value.length}
          onClick={() => {
            const order = !languageLocales?.length ? 0 : (_.last(languageLocales)?.order ?? 0) + 1;
            onSave(targetCode, typeTitle, displayCode, value, order);
          }}
        >
          추가
        </Input.Button>
      </LocaleModalStyle.ButtonGroup>
    </LocaleModalStyle.Layout>
  );
};
