/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, ListScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar, UuidScalar, TargetTableInputScalar } from "GraphQL/Scalars";
import { TargetTableInput } from "constants/TargetTableInput";
import { CommentOrderByInput } from "constants/CommentOrderByInput";
import { CommentOrderScalar } from "GraphQL/Scalars/CommentOrderScalar";
import { ParsedComment } from "App/Routes/Playlists/RightPlaylistTable/Modals/ExtraInfoModal";

type Option = {
  uuid?: string;
  targetId: string;
  targetTable: TargetTableInput;
  startDate?: string;
  endDate?: string;
  order?: CommentOrderByInput;
  depth?: number;
  parentId?: string;
};

type User = {
  userId?: string;
  email: string;
  name?: string;
};

export type Comment = {
  uuid: string;
  targetId: string;
  targetTable: TargetTableInput;
  value: ParsedComment;
  timestamp: string;
  rootId: string;
  users?: User[];
};

export const GetComment = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.uuid} />;
  const $targetId = <Variable name="target_id" scalar={<IdScalar />} value={option.targetId} />;
  const $targetTable = <Variable name="target_table" scalar={<TargetTableInputScalar />} value={option.targetTable} />;
  const $startDate = <DateTimeScalar isNullable={false} value={option.startDate} />;
  const $endDate = <DateTimeScalar isNullable={false} value={option.endDate} />;
  const $orderBy = <Variable name="orderBy" scalar={<ListScalar scalar={<CommentOrderScalar isNullable />} />} value={option.order} />;
  const $depth = <Variable name="depth" scalar={<IntScalar isNullable />} value={option.depth} />;
  const $parentId = <Variable name="parentId" scalar={<UuidScalar isNullable />} value={option.parentId} />;
  const { query, variables } = render(
    <Query operationName="GET_COMMENT">
      <SelectionSet
        name="comment"
        argument={{
          first: 30,
          page: 1,
          orderBy: $orderBy,
          where: {
            id: $uuid,
            target_id: $targetId,
            target_table: $targetTable,
            timestamp__gte: $startDate,
            timestamp__lte: $endDate,
            depth: $depth,
            parent_id: $parentId
          }
        }}
      >
        <Field name="id" alias="uuid" />
        <Field name="target_id" alias="targetId" />
        <Field name="target_table" alias="targetTable" />
        <Field name="value" />
        <Field name="timestamp" />
        <Field name="root_id" alias="rootId" />
        <SelectionSet name="users">
          <Field name="users_id" alias="userId" />
          <Field name="email" />
          <Field name="name" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    comment: Comment[];
  }>;
  const { data, errors } = await clients.access.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  const commentList = data!.comment.map(item => ({ ...item, value: JSON.parse(item.value as any) }));
  return commentList as Comment[];
};
