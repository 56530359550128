import React from "react";
import styled from "styled-components";
import { VIOLET_6 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { usePlaylistsStore } from "App/Routes/Playlists/Store";
import { convertNumberToMoney } from "lib/convertNumberToMoney";
import { Input } from "App/Atomics/Input";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { EditModal } from "./EditModal";

type Props = {
  index: number;
  id: string;
  onCreateCommentForm: () => void;
};

export const MainForm = ({ index, id, onCreateCommentForm }: Props) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  const [{ price, remark }] = usePlaylistsStore(store => store.Playlist.playlists[index]);

  const editModal = useToggle();

  return (
    <Layout>
      <PriceContainer>
        <div className="price-wrapper">
          {userRole === UserRole.Master && (
            <Input.Button className="edit-button" onClick={editModal.on}>
              수정
            </Input.Button>
          )}
          <strong>가격 및 정보</strong>
          <h1 className="price">{!price ? "0" : convertNumberToMoney(price)}원</h1>
          <textarea className="remark" disabled value={!remark ? "입력된 정보가 없습니다." : window.decodeURIComponent(remark)} />
          <ul className="info">
            <li>해당 플레이리스트에 포함된 총 음원의 가격을 나타냅니다.</li>
            <li>위 정보는 해당 가격의 계산 정보를 나타냅니다.</li>
            <li>
              좌측 메뉴에서 댓글 목록 및{" "}
              <span className="create-comment" onClick={onCreateCommentForm}>
                댓글 작성
              </span>
              , 변경 기록을 확인하실 수 있습니다.
            </li>
          </ul>
        </div>
      </PriceContainer>
      <Modal isOpen={editModal.isToggled}>
        <EditModal index={index} id={id} price={price} remark={remark} toClose={editModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.section`
  position: relative;
  display: flex;
  padding: 1.2rem;
  flex-direction: column;
  margin-bottom: 5rem;
  font-size: 0.9rem;
  user-select: text;
  width: 90%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PriceContainer = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  padding: 36px 28px 32px;
  background: #e6eaf0;
  border-radius: 6px;

  .price-wrapper {
    position: relative;
    strong,
    .price {
      display: flex;
      padding-left: 1rem;
    }
    .price {
      font-size: 3.5rem;
      font-weight: 600;
      color: ${VIOLET_6};
      padding-bottom: 23rem;
    }

    .edit-button {
      position: absolute;
      top: 0;
      right: 0;
      color: #999;
    }

    .remark {
      position: absolute;
      top: 8rem;
      left: 1.5rem;
      right: 1.5rem;
      min-height: 20rem;
      width: 95%;
      background-color: hsla(0, 0%, 100%, 0.7);
      word-break: keep-all;
      border-radius: 1rem;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04);
      padding: 1rem;
      margin-bottom: 1rem;
    }
    .info {
      position: absolute;
      top: 30rem;
      left: 1.5rem;
      right: 1.5rem;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #d8d8d8;
      padding-left: 1rem;
      color: #666;
      font-size: 0.9rem;
      li {
        margin-bottom: 4px;
      }
      .create-comment {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 520;
        color: ${PRIMARY_COLOR};
      }
    }
  }
`;
