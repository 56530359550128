import React, { MouseEvent } from "react";
import styled from "styled-components";
import { pixelize, UNIT } from "constants/size";
import { PINK_4, PINK_6, GRAY_3, GRAY_1 } from "constants/baseColor";

type Props = Styleable & LayoutProps;

type LayoutProps = Readonly<{
  /**
   * 0 이상 100 이하의 숫자
   */
  percent: number;
  onClick?: (event: MouseEvent) => void;
}>;

const Layout = styled.div<LayoutProps>`
  position: relative;
  overflow: hidden;
  border: 1px solid ${PINK_6};
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0px ${GRAY_3};
  .background-bar {
    height: ${pixelize(UNIT * 1.5)};
    background-color: ${GRAY_1};
  }

  .progress-bar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    width: ${props => props.percent}%;
    height: ${pixelize(UNIT * 1.5)};

    background-color: ${PINK_4};
  }
`;

/**
 * # Usage
 *
 * ```tsx
 * <Progress percent={0} />
 * <Progress percent={20} />
 * <Progress percent={100} />
 * ```
 */
export const Progress = ({ style, className, percent, onClick }: Props) => (
  <Layout style={style} className={className} percent={percent} onClick={onClick}>
    <div className="background-bar" />
    {percent === 0 ? null : <div className="progress-bar" />}
  </Layout>
);
