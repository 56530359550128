import React, { useState, useRef } from "react";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_SMALL_PX, MARGING_LARGE_PX } from "constants/size";
import { Tooltip } from "App/Atomics/Tooltip";
import { useArtistsStore, useArtistsDispatch } from "../../Store";
import { Subhead } from "../../Store/ArtistInfo/sanitizeHeads";
import itiriri from "itiriri";
import { Input } from "App/Atomics/Input";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { EditDescModal } from "../Modals/EditDescModal";
import { ArtistActions } from "../../Store/Artist";
import { CreateAccessRecord, DeleteAccessRecord, UpdateArtist, UpdateArtistVC } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { useAppSelector } from "App/Store";
import { UserRole } from "constants/UserRole";
import { ArtistAutoCompleteByNameStartExceptNaxos } from "App/Molecules/AutoCompletes/Artist";
import { ReactComponent as RemoveIcon } from "assets/icons/remove-circle.svg";
import { ReactComponent as IdIcon } from "assets/icons/id.svg";
import { GRAY_6, GRAY_5 } from "constants/baseColor";
import { DANGER_COLOR, DANGER_COLOR_LIGHT } from "constants/color";
import { ValidType } from "constants/ValidType";
import { configs } from "configs";
import { Link } from "App/Atomics/Link";
import defaultImage from "assets/images/default.png";
import { ImageViewModal } from "../Modals/ImageViewModal";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { Toast } from "lib/toast";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { EditGenreModal } from "../Modals/EditGenreModal";
import { allowArtistUpdate } from "App/Routes/AdminRoutes/allowTables/allowArtistUpdate";

const MemoAutoComplete = styled(ArtistAutoCompleteByNameStartExceptNaxos)`
  width: ${pixelize(UNIT * 22)};
`;

const LongValue = styled.div`
  max-width: ${pixelize(UNIT * 20)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Multiline = styled(Table.Data)`
  & > div + div {
    margin-top: ${MARGING_SMALL_PX};
  }

  & a {
    width: 100%;
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const AlignCenterBox = styled.div`
  text-align: center;
`;

type Title = {
  id: string;
  language: any;
  typeArtistTitle: string;
  order: number;
  value: string;
};

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 10px;
  align-items: center;
  img {
    width: ${pixelize(UNIT * 6.25)};
    height: ${pixelize(UNIT * 6.25)};
  }
`;

const FileDiv = styled.div`
  display: grid;
  grid-auto-flow: column;

  & > button {
    margin: 0;
  }
`;

export const DataTemplate = ({ index, data }: ColumnProps<any>) => {
  const {
    artistId,
    name,
    typeArtistClass,
    validCheck,
    titles,
    artistUrls,
    genreRelation,
    history,
    roleRelation,
    metadata_artist_relation,
    artist_company_relation
  } = data;
  const [{ subhead, checkList }, dispatch] = useArtistsStore(store => ({
    subhead: store.ArtistInfo.subhead,
    checkList: store.Artist.checkList
  }));
  const email = artist_company_relation[0]?.company[0]?.company_users_relation[0]?.users[0]?.email;
  const { userRole, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    store
  }));
  const allowArtistEdit = userRole === UserRole.Master || allowArtistUpdate(store);
  const editDescModal = useToggle();
  const nameTitles = !titles
    ? []
    : titles.filter((title: Title) => title.typeArtistTitle === "name" || title.typeArtistTitle === "name_sur");

  // TODO: Input Check Event
  const onToggleCheck = (id: string, checked: boolean) => {
    dispatch(ArtistActions.setArtistCheckList({ id, checked }));
  };

  return (
    <Table.Row key={index}>
      {(userRole === UserRole.Master || userRole === UserRole.Arbeit) && subhead["#"]["#"] && (
        <Table.Data>
          <AnimatedCheckbox
            id={index.toString()}
            isDisabled={!allowArtistEdit}
            isChecked={checkList.get(artistId)}
            onToggle={checked => onToggleCheck(artistId, checked)}
          />
        </Table.Data>
      )}
      <Artist
        index={index}
        allowArtistEdit={allowArtistEdit}
        artistId={artistId}
        name={name}
        email={email}
        typeArtistClass={typeArtistClass}
        validCheck={validCheck}
      />
      {metadata_artist_relation && <Track trackRelation={metadata_artist_relation} subhead={subhead} />}
      {(userRole === UserRole.Master || userRole === UserRole.Arbeit) && (
        <Memo allowArtistEdit={allowArtistEdit} subhead={subhead} artistId={artistId} index={index} />
      )}
      {nameTitles && <Title allowArtistEdit={allowArtistEdit} titles={nameTitles} subhead={subhead} editDescModal={editDescModal} />}
      {artistUrls && (
        <ArtistUrl index={index} allowArtistEdit={allowArtistEdit} artistUrls={artistUrls} artistId={artistId} subhead={subhead} />
      )}
      {genreRelation && <Genre index={index} allowArtistEdit={allowArtistEdit} genre={genreRelation} subhead={subhead} />}
      {history && <History history={history} subhead={subhead} />}
      {roleRelation && <Role role={roleRelation} subhead={subhead} />}

      <Modal isOpen={editDescModal.isToggled}>
        <EditDescModal id={artistId} toClose={() => editDescModal.off()} />
      </Modal>
    </Table.Row>
  );
};

type ArtistProps = Readonly<{
  index: number;
  artistId: any;
  allowArtistEdit: boolean;
  name: any;
  email: any;
  typeArtistClass: any;
  validCheck: any;
}>;

const Artist = ({ index, allowArtistEdit, artistId, name, email, typeArtistClass, validCheck }: ArtistProps) => {
  const dispatch = useArtistsDispatch();
  const onChangeVC = async (vc: ValidType) => {
    try {
      const { data: accessData, errors: accessErr } = await CreateAccessRecord({
        targetId: artistId,
        targetTable: TargetTableInput.Artist
      });
      if (accessErr || !accessData) {
        window.alert("권한 신청에 실패하였습니다.");
        return;
      }
      const { data, errors } = await UpdateArtistVC({ id: artistId, validCheck: vc });
      if (errors || !data) {
        window.alert("유효성 업데이트에 실패하였습니다.");
        await DeleteAccessRecord({ id: accessData.createAccess.id });
        return;
      }
      dispatch(ArtistActions.setArtistVC({ index, vc }));
      await DeleteAccessRecord({ id: accessData.createAccess.id });
      Toast.primary("저장되었습니다.", undefined, "bottom-right");
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <>
      {artistId && <AlignCenterData>{<ClipBoard text={artistId} />}</AlignCenterData>}
      {name && <Table.Data>{<ClipBoard text={name} />}</Table.Data>}
      <Table.Data>{<ClipBoard text={email ?? ""} />}</Table.Data>
      {typeArtistClass && <AlignCenterData>{typeArtistClass}</AlignCenterData>}
      {validCheck && (
        <AlignCenterData>
          <ValidTypeTextSelect
            isDisabled={!allowArtistEdit}
            defaultValue={validCheck as ValidType}
            onChange={vc => onChangeVC(vc as ValidType)}
          />
        </AlignCenterData>
      )}
    </>
  );
};

type TrackProps = Readonly<{
  trackRelation: string[];
  subhead: Subhead;
}>;

const Track = ({ trackRelation, subhead }: TrackProps) => {
  if (!subhead.Track) {
    return null;
  }
  return (
    <>
      <AlignCenterData>{trackRelation.length}</AlignCenterData>
    </>
  );
};

type TitleProps = Readonly<{ titles: any; allowArtistEdit: boolean; subhead: Subhead; editDescModal: any }>;

const Title = ({ titles, allowArtistEdit, subhead, editDescModal }: TitleProps) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  if (userRole === UserRole.Client) {
    return null;
  }
  return (
    <>
      {subhead.ArtistTitle.description && (
        <Multiline>
          {
            <Input.Button disabled={!allowArtistEdit} isWide isFill={false} color="primary" onClick={editDescModal.on}>
              VIEW
            </Input.Button>
          }
        </Multiline>
      )}
    </>
  );
};

type ArtistUrlProps = Readonly<{ index: number; allowArtistEdit: boolean; artistUrls: any[]; artistId: string; subhead: Subhead }>;

const ArtistUrl = ({ index, allowArtistEdit, artistUrls, artistId, subhead }: ArtistUrlProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const imageViewModal = useToggle();

  if (!artistUrls.length) {
    return (
      <>
        {itiriri(Object.entries(subhead.ArtistUrl)).map(([key, value], i) =>
          value ? (
            <Table.Data key={i}>
              {key === "thumbnail" ? (
                <>
                  <FileDiv>
                    <ImageContainer>
                      <img src={defaultImage} alt="" />
                    </ImageContainer>
                    <Input.Button disabled={!allowArtistEdit} color="primary" isFill={false} isWide onClick={imageViewModal.on}>
                      썸네일 등록
                    </Input.Button>
                  </FileDiv>
                  <Modal isOpen={imageViewModal.isToggled}>
                    <ImageViewModal index={index} id={artistId} artistUrls={artistUrls} toClose={imageViewModal.off} />
                  </Modal>
                </>
              ) : (
                <div></div>
              )}
            </Table.Data>
          ) : null
        )}
      </>
    );
  }

  const thumbnail = (artistUrls as any[]).filter(
    url => url.typeUrl === "head" || url.typeUrl === "thumbnail" || url.typeUrl === "cover" || url.typeUrl === "card"
  )[0];
  const thumbnailUrl = thumbnail ? thumbnail.url : "";
  const hint = (artistUrls as any[]).filter(url => url.typeUrl === "hint")[0];
  const hintUrl = hint ? hint.url : "";

  return (
    <>
      {subhead.ArtistUrl.thumbnail && (
        <Multiline>
          <>
            <FileDiv>
              <ImageContainer>
                <img
                  key={thumbnailUrl}
                  ref={imgRef}
                  src={
                    !thumbnailUrl
                      ? defaultImage
                      : `${configs.urls.image}/${thumbnailUrl.includes("?") ? `${thumbnailUrl}&mode=s` : `${thumbnailUrl}?mode=s`}`
                  }
                  alt=""
                />
              </ImageContainer>

              <Input.Button
                disabled={!allowArtistEdit}
                color={thumbnail ? "danger" : "primary"}
                isFill={thumbnail ? true : false}
                onClick={imageViewModal.on}
              >
                {thumbnail ? "썸네일 변경" : "썸네일 등록"}
              </Input.Button>
            </FileDiv>

            {hint && (
              <Link.External style={{ marginTop: MARGING_SMALL_PX }} target="_blank" href={`${configs.urls.audio}/${hintUrl}`}>
                <Input.Button color="primary">힌트 이미지 보기</Input.Button>
              </Link.External>
            )}

            <Modal isOpen={imageViewModal.isToggled}>
              <ImageViewModal
                index={index}
                id={artistId}
                artistUrls={artistUrls.filter(
                  url => url.typeUrl === "head" || url.typeUrl === "thumbnail" || url.typeUrl === "cover" || url.typeUrl === "card"
                )}
                toClose={imageViewModal.off}
              />
            </Modal>
          </>
        </Multiline>
      )}
      {subhead.ArtistUrl.typeUrl && (
        <Multiline>
          {!thumbnail && <div></div>}
          {(artistUrls as any[]).map((artistUrl, index) => (
            <div key={index}>{artistUrl.typeUrl}</div>
          ))}
        </Multiline>
      )}
      {subhead.ArtistUrl.url && (
        <Multiline>
          {!thumbnail && <div></div>}
          {(artistUrls as any[]).map((artistUrl, index) => (
            <Tooltip key={index} text={artistUrl.url} isEnable={artistUrl.url && artistUrl.url.length > 30}>
              <LongValue>{artistUrl.url}</LongValue>
            </Tooltip>
          ))}
        </Multiline>
      )}
    </>
  );
};

type GenreProps = Readonly<{ index: number; allowArtistEdit: boolean; genre: any; subhead: Subhead }>;

const Genre = ({ index, allowArtistEdit, genre, subhead }: GenreProps) => {
  const onEditGenreModal = useToggle();
  return (
    <>
      {subhead.ArtistGenre.name && (
        <Multiline>
          {(genre as any[]).map((genre, key) => (
            <AlignCenterBox key={genre.id}>{genre.genre.length && genre.genre[0].name}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.ArtistGenre.type && (
        <Table.Data>
          <Input.Button disabled={!allowArtistEdit} color="primary" isFill={false} onClick={onEditGenreModal.on}>
            편집
          </Input.Button>
        </Table.Data>
      )}
      <Modal isOpen={onEditGenreModal.isToggled} onClose={onEditGenreModal.off}>
        <EditGenreModal index={index} toClose={onEditGenreModal.off} />
      </Modal>
    </>
  );
};

type HistoryProps = Readonly<{ history: any; subhead: Subhead }>;

const History = ({ history, subhead }: HistoryProps) => {
  if (!history.length) {
    return (
      <>{itiriri(Object.entries(subhead.History)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  }
  return (
    <>
      {subhead.History.birthDate && (
        <Multiline>
          {(history as any[]).map((data, index) => (
            <AlignCenterBox key={index}>{data.birthDate}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.History.birthPlace && (
        <Multiline>
          {(history as any[]).map((data, index) => (
            <AlignCenterBox key={index}>{data.birthPlace}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.History.deathDate && (
        <Multiline>
          {(history as any[]).map((data, index) => (
            <AlignCenterBox key={index}>{data.deathDate}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.History.deathPlace && (
        <Multiline>
          {(history as any[]).map((data, index) => (
            <AlignCenterBox key={index}>{data.deathPlace}</AlignCenterBox>
          ))}
        </Multiline>
      )}
    </>
  );
};

type RoleProps = Readonly<{ role: any; subhead: Subhead }>;

const Role = ({ role, subhead }: RoleProps) => {
  if (!role.length) {
    return (
      <>{itiriri(Object.entries(subhead.Role)).map(([key, value], index) => (value ? <Table.Data key={index}>{}</Table.Data> : null))}</>
    );
  }

  return (
    <>
      {subhead.Role.id && (
        <Multiline>
          {(role as any[]).map((role, key) => (
            <AlignCenterBox key={key}>{role.role.length && role.role[0].roleId}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Role.role && (
        <Multiline>
          {(role as any[]).map((role, key) => (
            <AlignCenterBox key={key}>{role.role.length && role.role[0].name}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Role.type && (
        <Multiline>
          {(role as any[]).map((role, key) => (
            <AlignCenterBox key={key}>{role.role.length && role.role[0].typeRoleClass}</AlignCenterBox>
          ))}
        </Multiline>
      )}
      {subhead.Role.count && (
        <Multiline>
          {(role as any[]).map((role, key) => (
            <AlignCenterBox key={key}>{role.role.length && role.role[0].roleCount}</AlignCenterBox>
          ))}
        </Multiline>
      )}
    </>
  );
};

type MemoProps = Readonly<{ allowArtistEdit: boolean; subhead: Subhead; artistId: string; index: number }>;

const MemoBox = styled.div<{ searchMode: string }>`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    cursor: pointer;
  }

  .id {
    position: relative;
    right: ${pixelize(UNIT * 4)};
    fill: ${props => (props.searchMode === "name" ? GRAY_6 : DANGER_COLOR)};
    &:hover {
      fill: ${props => (props.searchMode === "name" ? GRAY_5 : DANGER_COLOR_LIGHT)};
    }
  }

  .remove {
    fill: ${GRAY_6};
    margin-left: ${MARGING_LARGE_PX};
    transition: fill 0.2s;
    &:hover {
      fill: ${DANGER_COLOR};
    }
  }
`;

const Memo = ({ allowArtistEdit, subhead, artistId, index }: MemoProps) => {
  const [{ memo }, dispatch] = useArtistsStore(store => ({
    memo: store.Artist.artists[index].memo
  }));
  const [searchMode, setSearchMode] = useState<string>("name");

  /**
   * @param memo: JSON.stringify({ id: string, name: string })
   */
  const updateMemo = async (memo: string | null) => {
    try {
      const { data: accessData } = await CreateAccessRecord({ targetId: artistId, targetTable: TargetTableInput.Artist });
      if (accessData) {
        try {
          await UpdateArtist({ id: artistId, memo, validCheck: ValidType.DONE });
          dispatch(ArtistActions.setArtistMemo({ index, memo }));
          await DeleteAccessRecord({ id: accessData.createAccess.id });
        } catch (err) {
          console.log(err);
          await DeleteAccessRecord({ id: accessData.createAccess.id });
          return;
        }
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <>
      {subhead.Memo.memo && (
        <Table.Data>
          <MemoBox searchMode={searchMode}>
            <MemoAutoComplete
              key={memo + searchMode}
              isDisabled={!allowArtistEdit}
              searchType={searchMode}
              defaultValue={!memo ? undefined : JSON.parse(memo)}
              onChange={info => {
                if (info) {
                  const newMemo = { id: info.id, name: info.name };
                  updateMemo(JSON.stringify(newMemo));
                }
              }}
            />
            <IdIcon className="id" onClick={() => setSearchMode(searchMode === "name" ? "id" : "name")} />
            <RemoveIcon className="remove" onClick={() => updateMemo(null)} />
          </MemoBox>
        </Table.Data>
      )}
    </>
  );
};
