import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { AlbumActions as Album } from "./Album";
import { SingleTrackActions as SingleTrack } from "./SingleTrack";

const reducers = { Album, SingleTrack };

const reducer = combineReducers(reducers, createStoreLogger("Album"));

export const {
  StoreProvider: AlbumStoreProvider,
  useStore: useAlbumStore,
  useSelector: useAlbumSelector,
  useDispatch: useAlbumDispatch
} = createStore(reducer);
