export const defaultPartner = {
  Goods: {
    name: "Goods",
    key: "goods",
    tables: [
      {
        key: "goods",
        name: "Goods",
        allow: "400",
        relation: [
          {
            key: "goods_structure",
            allow: "400"
          },
          {
            key: "goods_subdata_unique",
            allow: "400"
          }
        ]
      },
      {
        key: "goods_title",
        name: "Title",
        allow: "400",
        relation: [
          {
            key: "language",
            allow: "400"
          }
        ]
      },
      {
        key: "goods_artist_relation",
        name: "Artist",
        allow: "400",
        relation: null
      },
      {
        key: "goods_url",
        name: "URL",
        allow: "400",
        relation: null
      },
      {
        key: "goods_company_relation",
        name: "Company",
        allow: "400",
        relation: null
      },
      {
        key: "commodity",
        name: "Commodity",
        allow: "400",
        relation: [
          {
            key: "commodity_url",
            allow: "400"
          }
        ]
      }
    ]
  },
  Metadata: {
    name: "Track",
    key: "metadata",
    tables: [
      {
        key: "metadata",
        name: "Metadata",
        allow: "440",
        relation: [
          {
            key: "metadata_structure",
            allow: "400"
          }
        ]
      },
      {
        key: "metadata_title",
        name: "Title",
        allow: "400",
        relation: [
          {
            key: "language",
            allow: "400"
          }
        ]
      },
      {
        key: "metadata_artist_relation",
        name: "Artist",
        allow: "400",
        relation: null
      },
      {
        key: "metadata_genre_relation",
        name: "Genre",
        allow: "400",
        relation: null
      },
      {
        key: "metadata_url",
        name: "URL",
        allow: "400",
        relation: null
      },
      {
        key: "track_license",
        name: "License",
        allow: "400",
        relation: null
      },
      {
        key: "track_information",
        name: "Info",
        allow: "400",
        relation: null
      }
    ]
  },
  Artist: {
    name: "Artist",
    key: "artist",
    tables: [
      {
        key: "artist",
        name: "Artist",
        allow: "440",
        relation: null
      },
      {
        key: "role",
        name: "Role",
        allow: "400",
        relation: null
      }
    ]
  },
  MetadataArtistRelation: {
    name: "MetadataArtistRelation",
    key: "metadata_artist_relation",
    tables: [{ key: "metadata_artist_relation", name: "MetadataArtistRelation", allow: "500", relation: null }]
  },
  Genre: {
    name: "Genre",
    key: "genre",
    tables: [
      {
        key: "genre",
        name: "Genre",
        allow: "400",
        relation: [
          {
            key: "genre_self_relation",
            allow: "400"
          }
        ]
      }
    ]
  },
  Playlist: {
    name: "Playlist",
    key: "playlist",
    tables: [
      {
        key: "playlist",
        name: "Playlist",
        allow: "554",
        relation: [
          {
            key: "users",
            allow: "550"
          },
          {
            key: "metadata_playlist_relation",
            allow: "500"
          }
        ]
      },
      {
        key: "playlist_url",
        name: "URL",
        allow: "500",
        relation: []
      },
      {
        key: "playlist_users_relation",
        name: "Users",
        allow: "550",
        relation: []
      }
    ]
  },
  Company: {
    name: "Company",
    key: "company",
    tables: [
      {
        key: "company",
        name: "Company",
        allow: "000",
        relation: null
      }
    ]
  }
};
