import { LoadCommodity } from "GraphQL/Queries/Goods";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { ProductDetailStore } from "../Store";
import { ProductDetailActions } from "../Store/ProductDetail";

type Props = {
  readonly id?: string;
};

export const useLoadProductDetail = ({ id }: Props) => {
  useAsyncEffect(async () => {
    const { data, errors } = await LoadCommodity({
      id
    });
    if (errors || !data) {
      console.log(errors);
      Toast.error("컨텐츠를 불러올 수 없습니다.");
      return;
    }
    const { id: productId, quality, barcode, price, goods, createdAt, commodity_goods_subdata_unique_relation, urls } = data.product[0];
    const coverUrls = urls.filter(({ typeUrl }) => typeUrl === "cover");
    const goodsInfo = { id: goods[0].id, name: goods[0].name };
    ProductDetailStore.dispatch(
      ProductDetailActions.setProduct({
        id: productId.toString(),
        goods: goodsInfo,
        quality,
        barcode,
        price,
        createdAt,
        urls: coverUrls,
        optionList: commodity_goods_subdata_unique_relation
      })
    );
  }, [id]);
};
