import { TargetTableInput } from "constants/TargetTableInput";
import { CreateAccessRecord } from "GraphQL/Queries";
import { Toast } from "lib/toast";

type Props = {
  targetId?: string;
  targetTable: TargetTableInput;
};

export const requestAccessRecord = async ({ targetId, targetTable }: Props) => {
  const { data: accessData, errors: accessErr } = await CreateAccessRecord({
    targetId,
    targetTable
  });

  const onError = () => Toast.error("권한 요청을 실패하였습니다.", undefined, "top-center");
  if (accessErr && accessErr.length) {
    console.log(accessErr[0].message);
    onError();
    return;
  }
  if (!accessData) {
    onError();
    return;
  }
  return accessData.createAccess.id;
};
