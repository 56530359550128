/** @jsx jsx */

import { jsx, render, Field, Variable, SelectionSet, Query, StringScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  languageCode: string;
  displayCode?: string | null;
  typeTitle?: string | null;
}>;

export type LanguageLocale = {
  uuid: string;
  languageCode: string;
  typeTitle: string;
  value: string;
  displayCode: string;
  order?: number;
};

export const GetLanguageLocale = async (option: Option) => {
  const $languageCode = <Variable name="languageCode" scalar={<IdScalar />} value={option.languageCode} />;
  const $typeTitle = <Variable name="typeTitle" scalar={<StringScalar isNullable />} value={option.typeTitle} />;
  const $displayCode = <Variable name="displayCode" scalar={<IdScalar isNullable />} value={option.displayCode} />;
  const { query, variables } = render(
    <Query operationName="GET_LANGUAGE">
      <SelectionSet
        name="language_title"
        alias="languageLocale"
        argument={{
          group: true,
          first: 99,
          // orderBy: <EnumScalar type="Language_titleOrderByInput" value="exposure_order__ASC" items={["exposure_order__ASC"]} />,
          where: {
            language__some: {
              language_code: $languageCode
            },
            type_title: $typeTitle,
            display_some: {
              connect: {
                language_code: $displayCode
              }
            }
          }
        }}
      >
        <Field name="id" alias="uuid" />
        <Field name="language_code" alias="languageCode" />
        <Field name="type_title" alias="typeTitle" />
        <Field name="value" />
        <Field name="display_code" alias="displayCode" />
        <Field name="exposure_order" alias="order" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    languageLocale: LanguageLocale[];
  }>;
  const { errors, data } = await clients.language.request<Data>(query, variables);

  if (errors || !data) {
    throw errors;
  }
  return data;
};
