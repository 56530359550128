import React from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { Table } from "App/Atomics/Table";
import { GenreTextAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { MoodTextAutoComplete } from "App/Molecules/AutoCompletes/Mood";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { ColumnProps } from "App/Templates/TableTemplate";
import { MARGING_X_LARGE_PX, pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX } from "constants/size";
import { useTracksDispatch, useTracksSelector } from "../Store";
import { Subhead } from "../Store/TableInfo/sanitizeHeads";
import { TrackActions } from "../Store/Track";
import { useAppSelector } from "App/Store";
import { GRAY_0, GRAY_4, WHITE } from "constants/baseColor";
import { Tooltip } from "App/Atomics/Tooltip";
import { UserRole } from "constants/UserRole";
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { EditFileModal } from "../Modals/EditFileModal";
import { TonalityOptionList } from "constants/TrackInfoOpionList";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { configs } from "configs";

const ItemRow = styled(Table.Row)`
  td {
    min-width: ${pixelize(7 * UNIT)};

    .validSelect,
    .languageSelect,
    .autocomplete {
      min-width: ${pixelize(12 * UNIT)};
    }

    & p {
      margin: ${pixelize(0.5 * UNIT)} ${pixelize(1 * UNIT)};
    }
  }

  .trackSelect__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .trackSelect__menu {
    .trackSelect__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const Multiline = styled(Table.Data)`
  padding: ${pixelize(1 * UNIT)} ${pixelize(1.5 * UNIT)};

  & > div {
    display: flex;
    align-items: center;

    & + div {
      margin-top: ${MARGING_X_LARGE_PX};
    }

    * + * {
      margin-left: ${MARGING_X_LARGE_PX};
    }
  }

  & .removeBtn {
    width: ${pixelize(2.5 * UNIT)};
    border-radius: 50%;
    transform: scale(0.75);
  }

  input[type="date"] {
    padding: ${PADDING_X_LARGE_PX};

    &:hover {
      background-color: ${GRAY_0};
    }
  }
`;

const FileDiv = styled.div`
  display: grid;
  grid-auto-flow: column;

  & > button {
    margin: 0;
  }
`;

export const TrackItem = ({ index, data }: ColumnProps<any>) => {
  const {
    metadataId,
    title,
    no,
    typeMetadataSubclass,
    validCheck,
    titles,
    artists,
    urls,
    companies,
    extras,
    genres,
    track_information
  } = data;
  const subhead = useTracksSelector(store => store.TableInfo.subhead);
  return (
    <ItemRow>
      {artists && <Artist index={index} artists={artists} subhead={subhead} />}
      <Metadata
        index={index}
        metadataId={metadataId}
        title={title}
        no={no}
        typeMetadataSubclass={typeMetadataSubclass}
        validCheck={validCheck}
      />
      {titles && <Title index={index} titles={titles} subhead={subhead} />}
      {urls && <Url index={index} urls={urls} metadataId={metadataId} no={no} subhead={subhead} />}
      {companies && <Company companies={companies} subhead={subhead} />}
      {extras && <Extra index={index} extras={extras} subhead={subhead} />}
      {genres && <Genre index={index} genres={genres} subhead={subhead} />}
      {track_information && <Info index={index} info={track_information} subhead={subhead} />}
    </ItemRow>
  );
};

type MetadataProps = Readonly<{
  index: number;
  metadataId: any;
  title: any;
  no: any;
  typeMetadataSubclass: any;
  validCheck: any;
}>;

const Metadata = ({ index, metadataId, title, no, typeMetadataSubclass, validCheck }: MetadataProps) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  const dispatch = useTracksDispatch();

  return (
    <>
      {validCheck && (
        <Table.Data>
          <ValidTypeTextSelect
            className="validSelect"
            classNamePrefix="trackSelect"
            defaultValue={validCheck}
            onChange={validType => {
              if (validType) {
                dispatch(TrackActions.setValidCheckByIndex({ index, validCheck: validType }));
              } else {
                // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
              }
            }}
          />
        </Table.Data>
      )}
      {metadataId && (
        <Table.Data>
          <p>{metadataId}</p>
        </Table.Data>
      )}
      {title && (
        <Table.Data>
          {userRole === UserRole.Arbeit ? (
            <p>{title}</p>
          ) : (
            <Input.Text defaultValue={title} onBlur={value => dispatch(TrackActions.setTitleByIndex({ index, title: value }))} />
          )}
        </Table.Data>
      )}
      {no && (
        <Table.Data>
          <p>{no}</p>
        </Table.Data>
      )}
      {typeMetadataSubclass && (
        <Table.Data>
          <p>{typeMetadataSubclass}</p>
        </Table.Data>
      )}
    </>
  );
};

type TitleProps = Readonly<{ index: number; titles: any; subhead: Subhead }>;

const Title = ({ index, titles, subhead }: TitleProps) => {
  const dispatch = useTracksDispatch();

  const createTitle = (index: number) => dispatch(TrackActions.setCreateTitleByIndex({ index, column: "title" }));
  const removeTitle = (index: number, relationIndex: number) =>
    dispatch(TrackActions.setRemoveTitleByIndex({ index, relationIndex, column: "title" }));

  if (!titles.length) {
    return (
      <Multiline>
        <div>
          <Input.Button color="primary" isWide onClick={() => createTitle(index)}>
            +
          </Input.Button>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.Title.value && (
        <Multiline>
          {(titles as any[]).map(
            (title, titleIndex) =>
              title.relationId && (
                <div key={titleIndex}>
                  <Input.Button color="danger" className="removeBtn" onClick={() => removeTitle(index, titleIndex)}>
                    -
                  </Input.Button>
                  <LanguageTextSelect
                    className="languageSelect"
                    classNamePrefix="trackSelect"
                    defaultValue={title.language && { id: title.language[0].languageCode, name: title.language[0].languageCode }}
                    onChange={language => {
                      if (language) {
                        dispatch(TrackActions.setTrackTitleLanguageByIndex({ index, relationIndex: titleIndex, titleValue: language }));
                      } else {
                        // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                      }
                    }}
                  />
                  <Input.Text
                    defaultValue={title.typeMetadataTitle}
                    onBlur={value =>
                      dispatch(TrackActions.setTrackTitleTypeByIndex({ index, relationIndex: titleIndex, titleValue: value }))
                    }
                  />
                  <TextArea
                    placeholder="설명을 입력하세요"
                    defaultValue={title.value && window.decodeURIComponent(title.value)}
                    onBlur={e => {
                      if (e.target.value) {
                        dispatch(TrackActions.setTrackTitleValueByIndex({ index, relationIndex: titleIndex, titleValue: e.target.value }));
                      }
                    }}
                  />
                </div>
              )
          )}
          <div>
            <Input.Button color="primary" isWide onClick={() => createTitle(index)}>
              +
            </Input.Button>
          </div>
        </Multiline>
      )}
    </>
  );
};

type ArtistProps = Readonly<{ index: number; artists: any; subhead: Subhead }>;

const Artist = ({ index, artists, subhead }: ArtistProps) => {
  const userRole = useAppSelector(store => store.UserToken.role);
  const dispatch = useTracksDispatch();

  const createArtist = (index: number) => dispatch(TrackActions.setCreateArtistByIndex({ index, column: "artist" }));
  const removeArtist = (index: number, relationIndex: number) =>
    dispatch(TrackActions.setRemoveArtistByIndex({ index, relationIndex, column: "artist" }));

  if (!artists.length) {
    if (userRole !== UserRole.Master) {
      return <Table.Data>{}</Table.Data>;
    }
    return (
      <Multiline>
        <div>
          <Input.Button color="primary" isWide onClick={() => createArtist(index)}>
            +
          </Input.Button>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.Artist.artist && (
        <Multiline>
          {(artists as any[]).map(
            (artist, artistIndex) =>
              artist.relationId &&
              (userRole === UserRole.Arbeit ? (
                <div key={artistIndex}>
                  <p>{artist.artist && artist.artist[0].name}</p>
                  <p>{artist.role && artist.role[0] && artist.role[0].name}</p>
                </div>
              ) : (
                <div key={artistIndex}>
                  <Input.Button color="danger" className="removeBtn" onClick={() => removeArtist(index, artistIndex)}>
                    -
                  </Input.Button>
                  {artist.artist && (
                    <ArtistTextAutoComplete
                      className="autocomplete"
                      classNamePrefix="trackSelect"
                      defaultValue={artist.artist[0]}
                      onBlur={info => {
                        if (info) {
                          dispatch(TrackActions.setArtistByIndex({ index, relationIndex: artistIndex, column: "artist", value: info }));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                  {artist.role && (
                    <RoleTextAutoComplete
                      className="autocomplete"
                      classNamePrefix="trackSelect"
                      defaultValue={artist.role[0]}
                      onBlur={info => {
                        if (info) {
                          dispatch(TrackActions.setRoleByIndex({ index, relationIndex: artistIndex, column: "role", value: info }));
                        } else {
                          // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                        }
                      }}
                    />
                  )}
                </div>
              ))
          )}
          {userRole === UserRole.Arbeit ? null : (
            <div>
              <Input.Button color="primary" isWide onClick={() => createArtist(index)}>
                +
              </Input.Button>
            </div>
          )}
        </Multiline>
      )}
    </>
  );
};

type UrlProps = Readonly<{ index: number; urls: any; metadataId: string; no: string; subhead: Subhead }>;

const Url = ({ index, urls, metadataId, no, subhead }: UrlProps) => {
  const dispatch = useTracksDispatch();
  const userRole = useAppSelector(store => store.UserToken.role);
  const editAacFileModal = useToggle();
  const editFlacFileModal = useToggle();
  const editMp3FileModal = useToggle();

  const createUrl = (index: number) => dispatch(TrackActions.setCreateUrlByIndex({ index, column: "url" }));

  if (!urls || !urls.length) {
    if (userRole !== UserRole.Master) {
      return <Table.Data>{}</Table.Data>;
    }
    return (
      <Multiline>
        <div>
          <Input.Button color="primary" isWide onClick={() => createUrl(index)}>
            +
          </Input.Button>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.Url.url && (
        <Multiline>
          {(urls as any[]).map((url, urlIndex) => {
            return (
              <div key={urlIndex}>
                <audio controls preload={url.typeUrl === "mp3high" && index < 5 ? "metadata" : "none"}>
                  <source type={`audio/${url.typeUrl === "mp3high" ? "mpeg" : url.typeUrl}`} src={`${configs.urls.audio}/${url.url}`} />
                </audio>
                {userRole === UserRole.Arbeit ? null : (
                  <>
                    {subhead.Url.typeUrl && (
                      <Input.Text
                        style={{ width: pixelize(UNIT * 10) }}
                        defaultValue={url.typeUrl}
                        onBlur={value => dispatch(TrackActions.setTrackUrlTypeByIndex({ index, relationIndex: urlIndex, typeUrl: value }))}
                      />
                    )}
                    <FileDiv>
                      <Input.Text
                        style={{ width: pixelize(UNIT * 30) }}
                        defaultValue={url.url}
                        onBlur={value => dispatch(TrackActions.setTrackUrlByIndex({ index, relationIndex: urlIndex, url: value }))}
                      />
                      {(url.typeUrl === "mp3high" || url.typeUrl === "aac" || url.typeUrl === "flac") && (
                        <Input.Button
                          color="danger"
                          onClick={() => {
                            switch (url.typeUrl) {
                              case "mp3high":
                                editMp3FileModal.on();
                                break;
                              case "aac":
                                editAacFileModal.on();
                                break;
                              case "flac":
                                editFlacFileModal.on();
                                break;
                              default:
                                break;
                            }
                          }}
                        >
                          파일 변경
                        </Input.Button>
                      )}
                    </FileDiv>
                    {subhead.Url.fileValidCheck && (
                      <ValidTypeTextSelect
                        className="validSelect"
                        classNamePrefix="trackSelect"
                        defaultValue={url.fileValidCheck}
                        onChange={validType => {
                          if (validType) {
                            dispatch(
                              TrackActions.setTrackUrlValidCheckByIndex({ index, relationIndex: urlIndex, fileValidCheck: validType })
                            );
                          } else {
                            // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                          }
                        }}
                      />
                    )}

                    {url.typeUrl === "aac" && (
                      <Modal isOpen={editAacFileModal.isToggled}>
                        <EditFileModal
                          id={url.relationId}
                          typeUrl={url.typeUrl}
                          url={url.url}
                          metadataId={metadataId}
                          toClose={editAacFileModal.off}
                        />
                      </Modal>
                    )}
                    {url.typeUrl === "flac" && (
                      <Modal isOpen={editFlacFileModal.isToggled}>
                        <EditFileModal
                          id={url.relationId}
                          typeUrl={url.typeUrl}
                          url={url.url}
                          metadataId={metadataId}
                          toClose={editFlacFileModal.off}
                        />
                      </Modal>
                    )}
                    {url.typeUrl === "mp3high" && (
                      <Modal isOpen={editMp3FileModal.isToggled}>
                        <EditFileModal
                          id={url.relationId}
                          typeUrl={url.typeUrl}
                          url={url.url}
                          metadataId={metadataId}
                          toClose={editMp3FileModal.off}
                        />
                      </Modal>
                    )}
                  </>
                )}
              </div>
            );
          })}
          {userRole !== UserRole.Arbeit && (
            <div>
              <Input.Button color="primary" isWide onClick={() => createUrl(index)}>
                +
              </Input.Button>
            </div>
          )}
        </Multiline>
      )}
    </>
  );
};

type CompanyProps = Readonly<{ companies: any; subhead: Subhead }>;

const Company = ({ companies, subhead }: CompanyProps) => {
  if (!companies || !companies.length || !companies[0].company) {
    return <Table.Data>{}</Table.Data>;
  }

  return (
    <>
      {subhead.Company.company && (
        <Multiline>
          {(companies as any[]).map((company, index) => (
            <div key={index}>
              <p>{(company.company && company.company[0].license) || company.company[0].name}</p>
            </div>
          ))}
        </Multiline>
      )}
    </>
  );
};

type ExtraProps = Readonly<{ index: number; extras: any; subhead: Subhead }>;

const Extra = ({ index, extras, subhead }: ExtraProps) => {
  const dispatch = useTracksDispatch();

  return (
    <>
      {subhead.LicenseExtra.data && (
        <Multiline>
          {(extras as any[]).map((extra, extraIndex) => (
            <div key={extraIndex}>
              {subhead.LicenseExtra.recordYear && (
                <Tooltip text="녹음연도">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 7) }}
                    defaultValue={extra.recordYear}
                    onBlur={value => dispatch(TrackActions.setTrackExtraRecordYear({ index, recordYear: value }))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.publishYear && (
                <Tooltip text="발매년도">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 7) }}
                    defaultValue={extra.publishYear}
                    onBlur={value => dispatch(TrackActions.setTrackExtraPublishYear({ index, publishYear: value }))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.firstEdition && (
                <Tooltip text="초판 발매일">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 7) }}
                    defaultValue={extra.firstEdition}
                    onBlur={value => dispatch(TrackActions.setTrackExtraFirstEdition({ index, firstEdition: value }))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.place && (
                <Tooltip text="장소">
                  <Input.Text
                    style={{ width: pixelize(UNIT * 20) }}
                    defaultValue={extra.place}
                    onBlur={value => dispatch(TrackActions.setTrackExtraPlace({ index, place: value }))}
                  />
                </Tooltip>
              )}
              {subhead.LicenseExtra.data && (
                <div style={{ width: extra.data ? pixelize(UNIT * 30) : "auto", padding: PADDING_X_LARGE_PX }}>
                  {extra.data ? extra.data.credit : "-"}
                </div>
              )}
            </div>
          ))}
        </Multiline>
      )}
    </>
  );
};

type GenreProps = Readonly<{ index: number; genres: any; subhead: Subhead }>;

const Genre = ({ index, genres, subhead }: GenreProps) => {
  const dispatch = useTracksDispatch();

  const createGenre = (index: number, column: string) => dispatch(TrackActions.setCreateGenreByIndex({ index, column }));
  const removeGenre = (index: number, relationIndex: number, column: string) =>
    dispatch(TrackActions.setRemoveGenreByIndex({ index, relationIndex, column }));

  if (!genres.length) {
    return (
      <>
        {subhead.Genre.genre && (
          <Multiline>
            <div>
              <Input.Button color="primary" isWide onClick={() => createGenre(index, "genre")}>
                +
              </Input.Button>
            </div>
          </Multiline>
        )}
        {subhead.Genre.mood && (
          <Multiline>
            <div>
              <Input.Button color="primary" isWide onClick={() => createGenre(index, "mood")}>
                +
              </Input.Button>
            </div>
          </Multiline>
        )}
      </>
    );
  }
  return (
    <>
      {subhead.Genre.genre && (
        <Multiline>
          {(genres as any[]).map((genre, genreIndex) => {
            const isValid = genre.genre[0].typeKind === "genre" && genre.relationId;
            return !isValid ? (
              ""
            ) : (
              <div key={genreIndex}>
                <Input.Button color="danger" className="removeBtn" onClick={() => removeGenre(index, genreIndex, "genre")}>
                  -
                </Input.Button>
                <GenreTextAutoComplete
                  className="autocomplete"
                  classNamePrefix="trackSelect"
                  defaultValue={genre.genre[0]}
                  onBlur={info => {
                    if (info) {
                      dispatch(TrackActions.setGenreByIndex({ index, relationIndex: genreIndex, column: "genre", value: info }));
                    } else {
                      // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                    }
                  }}
                />
              </div>
            );
          })}
          <div>
            <Input.Button color="primary" isWide onClick={() => createGenre(index, "genre")}>
              +
            </Input.Button>
          </div>
        </Multiline>
      )}
      {subhead.Genre.mood && (
        <Multiline>
          {(genres as any[]).map((genre, genreIndex) => {
            const isValid = genre.genre[0].typeKind === "mood" && genre.relationId;
            return !isValid ? (
              ""
            ) : (
              <div key={genreIndex}>
                <Input.Button color="danger" className="removeBtn" onClick={() => removeGenre(index, genreIndex, "mood")}>
                  -
                </Input.Button>
                <MoodTextAutoComplete
                  className="autocomplete"
                  classNamePrefix="trackSelect"
                  defaultValue={genre.genre[0]}
                  onBlur={info => {
                    if (info) {
                      dispatch(TrackActions.setGenreByIndex({ index, relationIndex: genreIndex, column: "mood", value: info }));
                    } else {
                      // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                    }
                  }}
                />
              </div>
            );
          })}
          <div>
            <Input.Button color="primary" isWide onClick={() => createGenre(index, "mood")}>
              +
            </Input.Button>
          </div>
        </Multiline>
      )}
    </>
  );
};

type TrackInfo = {
  uuid: string;
  channel?: number;
  duration?: number;
  bitrate?: number;
  tempo?: number;
  tonality?: string;
  timeSignature?: string;
};

type InfoProps = Readonly<{ index: number; info: TrackInfo[]; subhead: Subhead }>;

const Info = ({ index, info, subhead }: InfoProps) => {
  const dispatch = useTracksDispatch();

  const createInfo = (index: number) => dispatch(TrackActions.setCreateInfoByIndex({ index, column: "info" }));
  const removeInfo = (index: number, relationIndex: number) =>
    dispatch(TrackActions.setRemoveInfoByIndex({ index, relationIndex, column: "info" }));

  if (!info.length) {
    return (
      <Multiline>
        <div>
          <Input.Button color="primary" isWide onClick={() => createInfo(index)}>
            +
          </Input.Button>
        </div>
      </Multiline>
    );
  }

  return (
    <>
      {subhead.Info.value && (
        <Multiline>
          {info.map(
            ({ uuid, channel, duration, bitrate, tempo, tonality, timeSignature }, infoIndex) =>
              uuid && (
                <div key={`${uuid}${infoIndex}`}>
                  <Input.Button color="danger" className="removeBtn" onClick={() => removeInfo(index, infoIndex)}>
                    -
                  </Input.Button>
                  <InputNumber
                    step={1}
                    min={0}
                    max={9999}
                    placeholder="채널"
                    defaultValue={channel && channel}
                    onBlur={value =>
                      dispatch(TrackActions.setTrackInfoChannelByIndex({ index, relationIndex: infoIndex, infoValue: value }))
                    }
                  />
                  <InputNumber
                    step={1}
                    min={0}
                    max={9999}
                    placeholder="재생시간"
                    defaultValue={duration && duration}
                    onBlur={value =>
                      dispatch(TrackActions.setTrackInfoDurationByIndex({ index, relationIndex: infoIndex, infoValue: value }))
                    }
                  />
                  <InputNumber
                    step={1}
                    min={0}
                    max={99999}
                    placeholder="비트"
                    defaultValue={bitrate && bitrate}
                    onBlur={value =>
                      dispatch(TrackActions.setTrackInfoBitrateByIndex({ index, relationIndex: infoIndex, infoValue: value }))
                    }
                  />
                  <InputNumber
                    step={1}
                    min={0}
                    max={99999}
                    placeholder="템포"
                    defaultValue={tempo && tempo}
                    onBlur={value => dispatch(TrackActions.setTrackInfoTempoByIndex({ index, relationIndex: infoIndex, infoValue: value }))}
                  />
                  <InputTextSelect
                    placeholder="조성"
                    defaultValue={!tonality ? undefined : { id: tonality, name: tonality }}
                    optionList={TonalityOptionList}
                    onBlur={info => {
                      if (info) {
                        dispatch(TrackActions.setTrackInfoTonalityByIndex({ index, relationIndex: infoIndex, infoValue: info.id }));
                      }
                    }}
                  />
                  <InputText
                    placeholder="박자표"
                    defaultValue={timeSignature}
                    onBlur={val =>
                      dispatch(TrackActions.setTrackInfoTimeSignatureByIndex({ index, relationIndex: infoIndex, infoValue: val }))
                    }
                  />
                </div>
              )
          )}
          <div>
            <Input.Button color="primary" isWide onClick={() => createInfo(index)}>
              +
            </Input.Button>
          </div>
        </Multiline>
      )}
    </>
  );
};

const InputNumber = styled(Input.Number)`
  max-width: 10rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  background-color: #fff;
  margin: 0 0.25rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;
const InputText = styled(Input.Text)`
  max-width: 12rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  background-color: #fff;
  margin: 0 0.25rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const InputTextSelect = styled(Input.TextSelect)`
  width: 12rem;
  padding: 0.5rem;
  margin: 0 0.25rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const TextArea = styled.textarea`
  font-size: 14px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
  transition: border-color 0.15s;
  transition: height 0.8s ease-out;
  line-height: 22px;
  height: 4rem;
  &:hover {
    border-color: ${PRIMARY_COLOR_LIGHT};
  }
  &:focus {
    height: 15rem;
    border-color: ${PRIMARY_COLOR_LIGHT};
  }
`;
