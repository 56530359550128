/** @jsx jsx */

import { GoodsParent } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { GoodsArtist, GoodsSubdata, GoodsTitle, GoodsUrl, GoodsWork } from "./LoadGoodsDetail";

type Option = Readonly<{
  id: string;
  coverUrl?: GoodsUrl[];
  detailUrl?: GoodsUrl[];
  videoUrl?: GoodsUrl[];
  officialUrl?: GoodsUrl[];
  youtubeUrl?: GoodsUrl[];
  createArtists?: GoodsArtist[];
  createWorks?: GoodsWork[];
  createTitles?: GoodsTitle[];
  createSubdatas?: GoodsSubdata[];
  createParents?: GoodsParent[];
}>;

export const CreateGoodsRelation = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_GOODS_RELATION">
      <SelectionSet
        name="updateGoods"
        alias="createGoodsRelation"
        argument={{
          where: {
            goods_id: $id
          },
          data: {
            _onlySearch: true,
            goods_url: {
              create: [
                ...(option.coverUrl?.map(({ url, order }) => ({ type_url: "cover", url, exposure_order: order })) ?? []),
                ...(option.detailUrl?.map(({ url, order }) => ({ type_url: "detail", url, exposure_order: order })) ?? []),
                ...(option.videoUrl?.map(({ url, order }) => ({ type_url: "video", url, exposure_order: order })) ?? []),
                ...(option.officialUrl?.map(({ url, order }) => ({ type_url: "official", url, exposure_order: order })) ?? []),
                ...(option.youtubeUrl?.map(({ url, order }) => ({ type_url: "youtube", url, exposure_order: order })) ?? [])
              ]
            },
            goods_artist_relation: {
              create: [
                ...(option.createArtists?.map(
                  ({ artist, order }) => ({ exposure_order: order, artist: { connect: { artist_id: artist[0].id } } } ?? [])
                ) ?? [])
              ]
            },
            metadata_goods_relation: {
              create: [
                ...(option.createWorks?.map(
                  ({ metadata, order }) => ({ exposure_order: order, metadata: { connect: { metadata_id: metadata[0].id } } } ?? [])
                ) ?? [])
              ]
            },
            goods_title: {
              create: [
                ...(option.createTitles?.map(({ type, value, languageCode, order }) => ({
                  type_title: type,
                  value,
                  exposure_order: order,
                  language: {
                    connect: {
                      language_code: languageCode
                    }
                  }
                })) ?? [])
              ]
            },
            goods_structure: {
              create: [
                ...(option.createParents?.map(({ goods, order }) => ({
                  parent_id: goods.parentId,
                  booking_order: order
                })) ?? [])
              ]
            },
            goods_subdata_unique: {
              create: [
                ...(option.createSubdatas?.map(({ field, category, value }) => ({
                  type_subdata_category: category,
                  type_subdata_field: field,
                  value
                })) ?? [])
              ]
            }
          }
        }}
      >
        <Field name="goods_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createGoodsRelation: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
