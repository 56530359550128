import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_6, GREEN_5, GREEN_4, GREEN_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { SUCCESS_COLOR_DARK, SUCCESS_COLOR } from "constants/color";
import { mediaQuery } from "constants/media";
import { Input } from "App/Atomics/Input";
import { useArtistStore } from "../../Store";
import { ColumnSection } from "./ColumnSection";
import _ from "lodash";
import { Toast } from "lib/toast";
import { ValidType } from "constants/ValidType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import {
  CreateArtistGenre,
  CreateArtistRole,
  CreateArtistUrl,
  DeleteAccessRecord,
  DeleteArtistGenre,
  DeleteArtistRoleRelation,
  DeleteArtistTitleRelation,
  DeleteArtistUrl,
  UpdateArtistGenreRelation,
  UpdateArtistHistory,
  UpdateArtistName,
  UpdateArtistRoleRelation,
  UpdateArtistUrl,
  UpdateArtistVC
} from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";
import { ArtistActions } from "../../Store/Artist";
import { Artist, GenreRelation, History, RoleRelation, Url } from "GraphQL/Queries/Artist/GetArtistRenewal";
import { GetArtistTitleRelation } from "GraphQL/Queries/Artist/GetArtistTitleRelation";
import { CreateArtistTitleRelation } from "GraphQL/Queries/Artist/CreateArtistTitleRelation";
import { CreateArtistHistory } from "GraphQL/Queries/Artist/CreateArtistHistory";
import { UpdateArtistWeight } from "GraphQL/Queries/Artist/UpdateArtistWeight";

type Props = {
  index: number;
  toClose: () => void;
};

type Keys = keyof Artist;
type Values = Artist[Keys];

export enum Page {
  FIRST,
  SECOND,
  THRID
}

export const EditInfoModal = ({ index, toClose }: Props) => {
  const [{ artists, origin }, dispatch] = useArtistStore(store => ({
    artists: store.Artist.artists[index],
    origin: store.Artist.origin[index]
  }));
  const [pageIndex, setPageIndex] = useState<Page>(Page.FIRST);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  const onClearAndClose = () => {
    dispatch(ArtistActions.setArtistClear());
    toClose();
  };

  const onSave = async (origin: Artist, artist: Artist) => {
    if (_.isEqual(origin, artist)) {
      Toast.warning("변경사항이 없습니다.", undefined, "bottom-right");
      return;
    }
    setEditLoading(true);
    const changeMap = new Map<Keys, { from: Values; to: Values }>();
    const WorkEntries = Object.entries(artist);
    const OriginValue = Object.values<Values>(origin);
    WorkEntries.forEach(([key, value], i) => {
      switch (key) {
        case "name":
        case "memo":
        case "weight":
        case "validCheck":
          if (OriginValue[i] !== value) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        case "artistUrl":
        case "genreRelation":
        case "roleRelation":
        case "artistHistory":
          if (!_.isEqual(OriginValue[i], value)) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        default:
          break;
      }
    });
    const accessId = await requestAccessRecord({ targetId: artist.id, targetTable: TargetTableInput.Artist });
    try {
      for (const [key, { from, to }] of changeMap) {
        switch (key) {
          case "name":
            const splitedString = (to as string).replace(/\s/gi, "");
            const valueIn = [origin.name, origin.name.replace(/\s/gi, "")];
            const { errors: titleErr, data: titleData } = await GetArtistTitleRelation({ id: artist.id, typeArtist: "name", valueIn });
            if (titleErr || !titleData) {
              throw titleErr;
            }
            const titleRelationIds = titleData.artist[0]?.titleRelation.map(({ uuid: id }) => id) ?? [];
            await UpdateArtistName({ id: artist.id, name: to as string });
            if (titleRelationIds?.length) {
              for (const uuid of titleRelationIds) {
                await DeleteArtistTitleRelation({ uuid: uuid });
              }
              await CreateArtistTitleRelation({
                id: artist.id,
                typeArtist: "name",
                value: to as string,
                languageCode: "OO",
                order: 0
              });
              if ((to as string) !== splitedString) {
                await CreateArtistTitleRelation({
                  id: artist.id,
                  typeArtist: "name",
                  value: splitedString,
                  languageCode: "OO",
                  order: 1
                });
              }
            }
            break;
          case "weight":
            await UpdateArtistWeight({ id: artist.id, weight: +to });
            break;
          case "validCheck":
            await UpdateArtistVC({ id: artist.id, validCheck: to as ValidType });
            break;
          case "roleRelation":
            const deleteRoleList = (from as RoleRelation[])
              .filter(({ uuid }) => !(to as RoleRelation[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createRoleList = (to as RoleRelation[]).filter(
              ({ uuid, role }) => !(from as RoleRelation[]).map(({ uuid }) => uuid).includes(uuid) && !!role[0].id
            );
            const updateRoleList = (to as RoleRelation[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as RoleRelation[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteRoleList) {
              await DeleteArtistRoleRelation({ uuid });
            }
            for (const { uuid, role, order } of createRoleList) {
              const { id } = role[0];
              const { data } = await CreateArtistRole({
                id: artist.id,
                roleId: id,
                order: order
              });
              if (data) {
                dispatch(
                  ArtistActions.updateArtistRoleUuid({ index, prevUuid: uuid, uuid: data.createArtistRole.artist_role_relation[0].uuid })
                );
              }
            }
            for (const { uuid, role, order } of updateRoleList) {
              await UpdateArtistRoleRelation({ uuid, roleId: role[0].id, order });
            }
            break;
          case "genreRelation":
            const deleteGenreList = (from as GenreRelation[])
              .filter(({ uuid }) => !(to as GenreRelation[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createGenreList = (to as GenreRelation[]).filter(
              ({ uuid, genre }) => !(from as GenreRelation[]).map(({ uuid }) => uuid).includes(uuid) && !!genre[0].id
            );
            const updateGenreList = (to as GenreRelation[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as GenreRelation[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteGenreList) {
              await DeleteArtistGenre({ uuid });
            }
            for (const { uuid, genre, order } of createGenreList) {
              const { data } = await CreateArtistGenre({
                id: artist.id,
                genreId: genre[0].id,
                order: order
              });
              if (data) {
                dispatch(
                  ArtistActions.updateArtistGenreUuid({ index, prevUuid: uuid, uuid: data.createArtistGenre.artist_genre_relation[0].uuid })
                );
              }
            }
            for (const { uuid, genre, order } of updateGenreList) {
              await UpdateArtistGenreRelation({ uuid, genreId: genre[0].id, order });
            }
            break;
          case "artistUrl":
            const deleteUrlList = (from as Url[])
              .filter(({ uuid }) => !(to as Url[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createUrlList = (to as Url[]).filter(
              ({ uuid, typeUrl, url }) => !(from as Url[]).map(({ uuid }) => uuid).includes(uuid) && !!typeUrl && !!url
            );
            const updateUrlList = (to as Url[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as Url[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteUrlList) {
              await DeleteArtistUrl({ uuid });
            }
            for (const { uuid, typeUrl, url, order } of createUrlList) {
              const { data } = await CreateArtistUrl({
                artistId: artist.id,
                typeUrl: typeUrl!,
                url: url!,
                order: order
              });
              if (data) {
                dispatch(ArtistActions.updateArtistUrlUuid({ index, prevUuid: uuid, uuid: data.createArtistUrl.artist_url[0].uuid }));
              }
            }
            for (const { uuid, typeUrl, url, order, validCheck } of updateUrlList) {
              await UpdateArtistUrl({ uuid, typeUrl, url: url!, order, validCheck });
            }
            break;
          case "artistHistory":
            const toHistory = to as History[];
            if (toHistory[0]?.uuid === "new") {
              const { errors: historyErr, data: historyData } = await CreateArtistHistory({
                id: artist.id,
                birthDate: toHistory[0]?.birthDate,
                deathDate: toHistory[0]?.deathDate,
                birthPlace: toHistory[0]?.birthPlace,
                deathPlace: toHistory[0]?.deathPlace
              });
              if (historyErr || !historyData) {
                throw historyErr;
              }
              dispatch(ArtistActions.updateArtistHistoryUuid({ index, uuid: historyData.createArtistHistory.artist_history[0].uuid }));
            } else if (toHistory.length) {
              await UpdateArtistHistory({
                uuid: toHistory[0].uuid,
                birthDate: toHistory[0]?.birthDate ?? "",
                deathDate: toHistory[0]?.deathDate ?? "",
                birthPlace: toHistory[0]?.birthPlace ?? "",
                deathPlace: toHistory[0]?.deathPlace ?? ""
              });
            }
            break;
          default:
            break;
        }
      }
      Toast.primary("저장되었습니다.", undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("저장을 실패하였습니다.", undefined, "top-center");
      setEditLoading(false);
      await DeleteAccessRecord({ id: accessId! });
      return;
    }

    await DeleteAccessRecord({ id: accessId! });
    setEditLoading(false);
    dispatch(ArtistActions.setArtistSave());
    toClose();
  };

  return (
    <Layout>
      <Header>
        <DocumentIcon className="document" />
        <h3>아티스트 정보 및 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <ul className="left-container">
          <li className={pageIndex === Page.FIRST ? "active" : ""} onClick={() => setPageIndex(Page.FIRST)}>
            메인 정보
          </li>
          <li className={pageIndex === Page.SECOND ? "active" : ""} onClick={() => setPageIndex(Page.SECOND)}>
            장르 / 역할
          </li>
          <li className={pageIndex === Page.THRID ? "active" : ""} onClick={() => setPageIndex(Page.THRID)}>
            부가 정보
          </li>
        </ul>
        <div className="right">
          <ColumnSection index={index} data={artists} pageIndex={pageIndex} />
          <ButtonGroup>
            <Input.Button className="btn cancel" disabled={editLoading} isFill={false} color="success" onClick={onClearAndClose}>
              취소
            </Input.Button>
            <Input.Button
              className="btn save"
              disabled={editLoading}
              isFill
              color="success"
              onClick={async () => await onSave(origin, artists)}
            >
              저장
            </Input.Button>
          </ButtonGroup>
        </div>
      </Section>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  right: 0%;
  top: 0%;
  width: 1000px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.8rem;
  ${mediaQuery(1000)} {
    width: 100%;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .document {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: 0.4rem;
    fill: ${SUCCESS_COLOR_DARK};
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  display: flex;
  height: 100%;
  .left-container {
    width: 7rem;
    border-right: 1px solid #eee;
    li {
      display: flex;
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      color: #fff;
      background-color: ${GREEN_5};

      &:hover {
        background-color: ${GREEN_4};
      }
      &:focus {
        background-color: ${GREEN_6};
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 5rem;
    overflow-y: scroll;
  }

  ${mediaQuery(965)} {
    flex-direction: column;
    .left-container {
      width: 100%;
      display: flex;
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
  .btn {
    width: 6rem;
    margin-right: 0.5rem;
  }
  .cancel {
    &:hover {
      background-color: #eee;
    }
  }
  /* Input.Button 기본 css를 수정해야함. */
  .save {
    border: 1px solid ${SUCCESS_COLOR};
    &:hover {
      border: 1px solid ${SUCCESS_COLOR};
    }
  }
`;
