import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { HEADER_HEIGHT_PX } from "constants/size";
import React from "react";
import styled from "styled-components";
import { useVideoCreateSelector } from "../Store";
import { ArtistForm, TitleForm, CompanyForm, UrlForm, ButtonForm } from "./Form";

export const VideoCreateForm = () => {
  const { name, artist, company, url } = useVideoCreateSelector(store => store.VideoCreate);
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  return (
    <Layout>
      <LeftSideBar />
      <TopbarTemplate openSidebar={openSidebar} />
      <Container>
        <TitleForm name={name} />
        <ArtistForm artist={artist} />
        <CompanyForm company={company} />
        <UrlForm file={url?.file} />
        <ButtonForm />
      </Container>
    </Layout>
  );
};

const Layout = styled.div``;

const Container = styled.div`
  padding-top: ${HEADER_HEIGHT_PX};
  width: 100%;
  max-width: 570px;
  padding: 60px 26px 140px;
  margin-right: auto;
  margin-left: auto;
`;
