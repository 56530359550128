/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { MetadataSelfRelationOrderByInput } from "../../constants/MetadataSelfRelationOrderByInput";

type Props = Readonly<{
  value?: MetadataSelfRelationOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  MetadataSelfRelationOrderByInput.id_ASC,
  MetadataSelfRelationOrderByInput.id_DESC,
  MetadataSelfRelationOrderByInput.exposure_order_ASC,
  MetadataSelfRelationOrderByInput.exposure_order_DESC,
  MetadataSelfRelationOrderByInput.type_function_ASC,
  MetadataSelfRelationOrderByInput.type_function_DESC,
  MetadataSelfRelationOrderByInput.valid_check_ASC,
  MetadataSelfRelationOrderByInput.valid_check_DESC,
  MetadataSelfRelationOrderByInput.createdAt_ASC,
  MetadataSelfRelationOrderByInput.createdAt_DESC,
  MetadataSelfRelationOrderByInput.updatedAt_ASC,
  MetadataSelfRelationOrderByInput.updatedAt_DESC
];

export const MetadataSelfRelationOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="Metadata_self_relationOrderByInput" value={value} isNullable={isNullable} items={items} />
);
