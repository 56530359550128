import { initializeKeyValue } from "lib/initialize-key-value";

export type Head = ReturnType<typeof sanitizeHeads>["head"];
export type Subhead = ReturnType<typeof sanitizeHeads>["subhead"];

export const sanitizeHeads = (option: Record<string, boolean>) => {
  const { Head: head, Subhead: subhead } = initializeKeyValue(option, {
    Head: {
      Artist: true,
      ArtistUrl: true,
      ArtistGenre: true,
      History: true,
      Role: true
    },
    Subhead: {
      Artist: {
        artistId: true,
        name: true,
        typeArtistClass: true,
        validCheck: true
      },
      ArtistUrl: {
        thumbnail: true,
        typeUrl: true,
        url: true
      },
      ArtistGenre: {
        name: true
      },
      History: {
        birthDate: true,
        birthPlace: true,
        deathDate: true,
        deathPlace: true
      },
      Role: {
        name: true
      }
    }
  });
  return { head, subhead };
};
