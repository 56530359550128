import React, { useMemo, ReactNode, FormEvent, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import itiriri from "itiriri";
import { Input } from "App/Atomics/Input";
import { Pagination } from "App/Molecules/Pagination";
import { useAppDispatch, useAppSelector } from "App/Store";
import { TableTemplate } from "App/Templates/TableTemplate";
import { DANGER_COLOR, PRIMARY_COLOR } from "constants/color";
import { HEADER_HEIGHT_PX, MARGING_LARGE_PX } from "constants/size";
import { useTracksStore } from "../Store";
import { useHistory } from "react-router";
import { TRACKS_EDIT, ALBUMS, TRACKS } from "constants/route";
import { useModal, Modal } from "lib/modal";
import { TableFilterModal } from "./Filter/TableFilterModal";
import { TrackTableInfoActions } from "../Store/TableInfo";
import { DataTemplate } from "./TrackItem";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { useQueryParams } from "lib/use-query-params";
import { subheadKeyValue } from "./subheadKeyValue";
import { UserRole } from "constants/UserRole";
import { LoadingAction } from "App/Store/Loading";
import { useAsyncEffect } from "lib/use-async-effect";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { TrackActions } from "../Store/Track";
import { useToggle } from "lib/use-toggle";
import { BatchEditModal } from "./Modals/BatchEditModal";
import { PlaylistModal } from "./Modals/PlaylistModal";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { SearchForm } from "App/Organisms/SearchForm";
import { Toast } from "lib/toast";
import { SelectMenu } from "App/Molecules/SelectMenu";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "assets/icons/menu-edit.svg";
import { ReactComponent as PlayIcon } from "assets/icons/audio-play.svg";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { GetMetadataAudioData, GetMetadataLicenseInfo, UpdateTrackLicense } from "GraphQL/Queries/Metadata";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Confirm } from "App/Molecules/Confirm";
import { Loading } from "App/Atomics/Loading";
import dayjs from "dayjs";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import { FilterModalTemplate } from "App/Templates/FilterModalTemplate";
import { UpdateSelectedLicenseModal } from "./Modals/UpdateSelectedLicenseModal";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { Dropdown } from "App/Molecules/Dropdown";
import { SelectMusicTitleModal } from "./Modals/SelectMusicTitleModal";
import { KeyInfo } from "lib/key-info";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

type Track = {
  metadataId: string;
  title: string;
  no: string;
  validCheck: string;
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow-x: scroll;
  overflow-y: auto;

  width: 100%;

  table {
    margin-top: ${HEADER_HEIGHT_PX};

    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }
  }

  form {
    margin-left: ${MARGING_LARGE_PX};
  }

  .menu {
    fill: ${PRIMARY_COLOR};
  }

  .btn-active {
    color: ${DANGER_COLOR};
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const ToggleGroup = styled(Input.Group)`
  transform: scaleX(0.9);
  margin-right: 0.2rem;

  button {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
`;

enum SearchMode {
  Album = "album",
  Track = "track"
}

const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "title", name: "타이틀" },
  { id: "artist", name: "아티스트" },
  { id: "no", name: "No" }
];

export const RightTrackTable = () => {
  const router = useHistory();
  const dispatchApp = useAppDispatch();
  const { userRole, loading, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading,
    store
  }));
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.Track);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const queryParams = useQueryParams();
  const filter = queryParams.get("filter", { default: undefined });
  const encodeFilter = window.encodeURIComponent(filter);

  const searchIdList = queryParams.get("ids");
  const batchEditModal = useToggle();
  const playlistModal = useToggle();
  const confirmModal = useToggle();
  const selectMusicTitleModal = useToggle();
  const updateSelectedLicenseModal = useToggle();
  const [{ edge, tracks, head, subhead, editCheckList }, dispatchTracks] = useTracksStore(store => ({
    edge: store.Track.edge,
    tracks: store.Track.tracks,
    head: store.TableInfo.head,
    subhead: store.TableInfo.subhead,
    editCheckList: store.Track.checkList
  }));
  const onToggleAllCheck = (checked: boolean) => {
    tracks.forEach((track: Track) => {
      dispatchTracks(TrackActions.setTrackCheckList({ id: track.metadataId, checked }));
    });
  };

  const isChecked = (list: Map<string, boolean>) => {
    const filteredList = Array.from(list).filter(item =>
      tracks.map(({ metadataId }: { metadataId: string }) => metadataId).includes(item[0])
    );
    return list.size === 0 || !filteredList.length ? false : filteredList.every(item => item[1]);
  };

  const subheadComponentMap = {
    "#": () => (
      <AnimatedCheckbox
        id="allCheck"
        isChecked={isChecked(editCheckList)}
        onToggle={() => {
          return onToggleAllCheck(
            editCheckList.size === 0
              ? true
              : Array.from(editCheckList)
                  .filter(item => tracks.map(({ metadataId }: { metadataId: string }) => metadataId).includes(item[0]))
                  .some(item => !item[1])
          );
        }}
      />
    ),
    validCheck: (props: { subhead: string }) => <>{props.subhead}</>,
    language: (props: { subhead: string }) => <>{props.subhead}</>,
    artist: (props: { subhead: string }) => <>{props.subhead}</>,
    role: (props: { subhead: string }) => <>{props.subhead}</>,
    character: (props: { subhead: string }) => <>{props.subhead}</>,
    company: (props: { subhead: string }) => <>{props.subhead}</>,
    workArtist: (props: { subhead: string }) => <>{props.subhead}</>,
    genre: (props: { subhead: string }) => <>{props.subhead}</>,
    mood: (props: { subhead: string }) => <>{props.subhead}</>
  };

  const createHeads = () => {
    const subheadList = [] as ReactNode[];
    const headList = itiriri(Object.entries(head))
      .filter(([key, isToggledKey]) => {
        if (
          (!(userRole === UserRole.Master || allowMetadataUpdate(store)) && key === "#") ||
          (userRole === UserRole.Arbeit && (key === "Company" || key === "Title")) ||
          (userRole !== UserRole.Master && key === "LicenseExtra")
        ) {
          return false;
        } else {
          return isToggledKey;
        }
      })
      .toArray(([key]) => {
        const localSubheadList = itiriri(Object.entries(subhead[key as keyof typeof subhead]))
          .filter(([subKey, isToggledSubKey]) => {
            const subKeyValidate = subKey === "typeUrl" || subKey === "fileValidCheck";
            if (userRole === UserRole.Arbeit && subKeyValidate) {
              return false;
            }
            return isToggledSubKey;
          })
          .toArray(([subKey]) => {
            const Component = subheadComponentMap[subKey as keyof typeof subheadComponentMap];
            if (!Component) {
              return subheadKeyValue[key][subKey];
            }
            return <Component subhead={subheadKeyValue[key][subKey]} />;
          });
        subheadList.push(...localSubheadList);
        return {
          name: key,
          colSpan: localSubheadList.length
        };
      });
    return { headList, subheadList };
  };
  const { headList, subheadList } = useMemo(createHeads, [head, subhead, tracks, editCheckList]);

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const tableFilterModal = useModal();
  const filterModal = useToggle();
  const openTableFilterModal = () => {
    const updateHeads: Parameters<typeof TableFilterModal>[0]["onClick"] = (head, subhead) => {
      dispatchTracks([TrackTableInfoActions.setHead(head), TrackTableInfoActions.setSubhead(subhead)]);
      tableFilterModal.close();
    };
    tableFilterModal.open(
      <TableFilterModal head={head} subhead={subhead} userRole={userRole} onClick={updateHeads} toClose={tableFilterModal.close} />
    );
  };

  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);
  const onSearch = (e: FormEvent<HTMLFormElement>, searchStr: string) => {
    e.preventDefault();
    if (searchStr.length <= 1) return;
    if (searchStr === getSearchString(searchType) && router.location.pathname.includes(searchMode as string)) {
      return;
    }
    if (!searchStr) {
      router.push(`?page=1${searchIdList ? `&ids=${searchIdList}` : ""}${!filter ? "" : `&filter=${encodeFilter}`}`);
      return;
    }
    dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
    const matchedStr = searchStr.match(/[^ ,]+/g)?.map(v => +v);
    if (searchType === "id" && matchedStr!.length > 1) {
      router.push(
        `${searchMode === SearchMode.Track ? TRACKS : ALBUMS}?page=1${`&ids=[${matchedStr}]`}${!filter ? "" : `&filter=${encodeFilter}`}`
      );
    } else {
      router.push(
        `${searchMode === SearchMode.Track ? TRACKS : ALBUMS}?page=1&${searchType}=${searchStr}${!filter ? "" : `&filter=${encodeFilter}`}`
      );
    }
  };

  const handleFloatingPlayButton = async () => {
    const checkList = Array.from(editCheckList)
      .filter(item => item[1])
      .map(item => `${item[0]}`);
    const playlist = [] as TypeAudio[];
    setEditLoading(true);
    try {
      for (const id of checkList) {
        const audioData = await GetMetadataAudioData({ id, typeClass: "record" });
        if (audioData) {
          playlist.push(audioData);
        }
        dispatchTracks(TrackActions.setTrackCheckList({ id, checked: false }));
      }
      if (playlist.length) {
        dispatchApp([
          AudioPlayerActions.toggleVisible(true),
          AudioPlayerActions.addMultiAudioInPlaylist(playlist),
          AudioPlayerActions.setAudioData(playlist[0]),
          AudioPlayerActions.setAudioPlay("PLAY")
        ]);
      }
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setEditLoading(false);
    }
  };

  const handleFloatingAddButton = () => {
    playlistModal.on();
  };

  const handleFloatingEditButton = () => {
    if (Array.from(editCheckList).filter(list => list[1]).length >= 50) {
      Toast.warning("최대 50개까지 편집할 수 있습니다.");
    }
    const checkList = Array.from(editCheckList)
      .filter(item => item[1])
      .slice(0, 50)
      .map(item => `${item[0]}`);
    router.push(TRACKS_EDIT + `?page=1&ids=[${checkList}]${!filter ? "" : `&filter=${encodeFilter}`}`);
  };

  const onDisableActiveLicense = async (copyright: boolean, neighboring: boolean, isService: number) => {
    try {
      const checkList = Array.from(editCheckList)
        .filter(item => item[1])
        .map(item => item[0]);
      const licenseDate = dayjs(new Date()).format("YYYY-MM-DD");
      setEditLoading(true);
      for (const id of checkList) {
        const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
        if (accessId) {
          try {
            const licenseIdList = await GetMetadataLicenseInfo({ id, typeClass: "record", typeSubClassIn: ["track", "effect"] });
            for (const uuid of licenseIdList) {
              await UpdateTrackLicense({ uuid, copyright, neighboring, isService, licenseDate });
            }
          } catch (err) {
            throw err;
          } finally {
            await DeleteAccessRecord({ id: accessId });
          }
        }
      }
      Toast.primary("라이센스 변경이 완료되었습니다.", undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("오류가 발생했습니다.");
      return;
    } finally {
      setEditLoading(false);
    }
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        dispatchTracks(TrackActions.clearTrackCheckList());
      }
    },
    [dispatchTracks]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  useAsyncEffect(
    async isMounted => {
      if (isMounted() && edge && !tracks.length) {
        dispatchApp(LoadingAction.setLoading(LOADING.NULL));
      }
      const ids = tracks.map(track => track.metadataId);
      dispatchTracks(TrackActions.setTrackCheckListInit(ids));
    },
    [tracks, edge]
  );
  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <Input.Button onClick={() => router.push(ALBUMS)}>앨범 전체 보기</Input.Button>
        {userRole === UserRole.Master && <Input.Button onClick={batchEditModal.on}>일괄 편집</Input.Button>}
        {userRole === UserRole.Master && <Input.Button onClick={openTableFilterModal}>컬럼 조정</Input.Button>}
        <Input.Button onClick={filterModal.on}>필터</Input.Button>
        <InputContainer>
          <ToggleGroup>
            <Input.Toggle
              color="primary"
              isActive={searchMode === SearchMode.Album}
              toggleValue="앨범"
              onClick={() => setSearchMode(SearchMode.Album)}
            />
            <Input.Toggle
              color="primary"
              isActive={searchMode === SearchMode.Track}
              toggleValue="트랙"
              onClick={() => setSearchMode(SearchMode.Track)}
            />
          </ToggleGroup>
          <SearchForm onChangeSearchTypeInfo={info => setSearchType(info!.id)} onSearch={onSearch} optionColumnList={SearchColumnList} />
        </InputContainer>
      </TopbarTemplate>

      <TableTemplate
        headList={headList}
        subHeadList={subheadList}
        keyBy={(data, index) => index}
        dataList={loading === LOADING.UNLOAD ? [] : tracks}
        Column={DataTemplate}
      />
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      <Pagination
        edge={edge}
        goTo={index =>
          `${TRACKS}?page=${index}${searchIdList ? `&ids=${searchIdList}` : ""}${!filter ? "" : `&filter=${encodeFilter}`}${
            !getSearchString(searchType) ? "" : `&${searchType}=${getSearchString(searchType)}`
          }`
        }
        onIntersect={() => setIsBottom(true)}
        unIntersect={() => setIsBottom(false)}
      />
      <SelectMenu
        selectCount={Array.from(editCheckList).filter(item => item[1]).length}
        isOpen={!!Array.from(editCheckList).some(item => item[1])}
        isBottom={isBottom}
      >
        <SelectMenu.IconButton onClick={handleFloatingPlayButton}>
          <PlayIcon />
          <span>재생</span>
        </SelectMenu.IconButton>
        <SelectMenu.IconButton onClick={handleFloatingAddButton}>
          <PlusIcon />
          <span>담기</span>
        </SelectMenu.IconButton>
        <SelectMenu.IconButton onClick={handleFloatingEditButton}>
          <EditIcon />
          <span>편집</span>
        </SelectMenu.IconButton>
        <Dropdown style={{ margin: "20px" }}>
          <Dropdown.Item onClick={updateSelectedLicenseModal.on}>라이센스 수정</Dropdown.Item>
          <Dropdown.Item onClick={selectMusicTitleModal.on}>음원 다운로드</Dropdown.Item>
        </Dropdown>
      </SelectMenu>
      <Modal isOpen={batchEditModal.isToggled}>
        <BatchEditModal toClose={batchEditModal.off} />
      </Modal>
      <Modal isOpen={playlistModal.isToggled}>
        <PlaylistModal toClose={playlistModal.off} />
      </Modal>
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm
          title="알림"
          context="선택한 라이센스를 비활성화 하시겠습니까?"
          toSave={() => onDisableActiveLicense(false, false, 2)}
          toClose={confirmModal.off}
        />
      </Modal>
      <Modal isOpen={filterModal.isToggled}>
        <FilterModalTemplate route={TRACKS} toClose={filterModal.off} />
      </Modal>
      <Modal isOpen={updateSelectedLicenseModal.isToggled}>
        <UpdateSelectedLicenseModal
          selectedIdList={Array.from(editCheckList)
            .filter(item => item[1])
            .map(item => item[0])}
          toClose={updateSelectedLicenseModal.off}
        />
      </Modal>
      <Modal isOpen={selectMusicTitleModal.isToggled} onClose={selectMusicTitleModal.off}>
        <SelectMusicTitleModal toClose={selectMusicTitleModal.off} />
      </Modal>
      <Loading loading={editLoading} />
    </Layout>
  );
};
