import { Reducer } from "react";
import { createStore, combineReducers } from "lib/store";
import { afterChanges, afterDispatches } from "utils/store";
import { reducer as UserToken } from "./UserToken";
import { reducer as AudioPlayer } from "./AudioPlayer";
import { reducer as Settle } from "./Settle";
import { reducer as AlbumTrack } from "./AlbumTrack";
import { reducer as Album } from "./Album";
import { reducer as Loading } from "./Loading";
import { reducer as DevMode } from "./DevMode";
import { reducer as Artist } from "./Artist";
import { reducer as Work } from "./Work";
import { ProgressActions as Progress } from "./Progress";

const reducers = { UserToken, AudioPlayer, Settle, AlbumTrack, Album, Loading, DevMode, Artist, Work, Progress };

const reducer = combineReducers(reducers, afterDispatches, afterChanges);

export type AppStore = {
  [key in keyof typeof reducers]: typeof reducers[key] extends Reducer<infer S, any> ? S : never;
};

export const {
  StoreProvider: AppStoreProvider,
  useStore: useAppStore,
  useSelector: useAppSelector,
  useDispatch: useAppDispatch
} = createStore(reducer);
