import React from "react";
import styled from "styled-components";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { Input } from "App/Atomics/Input";
import { useWorkDetailStore } from "../Store";
import { AlbumTemplate } from "./DataTemplate";
import { TracksTemplate } from "./DataTemplate";
import { GetMetadataSimple } from "GraphQL/Queries/Metadata";
import { Toast } from "lib/toast";
import { useHistory } from "react-router";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

const Layout = styled.div`
  display: inherit;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
`;

enum Direction {
  PREV,
  NEXT
}

export const RightWorkDetailTable = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ album, tracks }, dispatch] = useWorkDetailStore(store => store.WorkDetail);
  const router = useHistory();

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const onMoveAlbum = async (direction: Direction) => {
    const isPrev = direction === Direction.PREV;
    try {
      const order = isPrev ? "metadata_id__ASC" : undefined;
      const lt = !isPrev ? parseInt(album.metadataId, 10) : undefined;
      const gt = isPrev ? parseInt(album.metadataId, 10) : undefined;
      const { data, errors } = await GetMetadataSimple({ first: 1, order, typeClass: "work", typeSubClass: "album", lt, gt });
      if (errors) {
        Toast.error("앨범 정보를 가져올 수 없습니다.");
      }
      if (data) {
        if (!data.metadata.length) {
          Toast.error(`${isPrev ? "이전" : "다음"} 앨범이 없습니다.`);
          return;
        }
        router.push(`?id=${data.metadata[0].id}`);
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <Input.Button onClick={() => onMoveAlbum(Direction.PREV)}>이전 앨범</Input.Button>
        <Input.Button onClick={() => onMoveAlbum(Direction.NEXT)}>다음 앨범</Input.Button>
      </TopbarTemplate>
      <AlbumTemplate album={album} />
      <TracksTemplate tracks={tracks} />
    </Layout>
  );
};
