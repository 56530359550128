import { Edge } from "models/Edge";
import { Metadata, Artist, Company, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import { ValidType } from "constants/ValidType";
import _ from "lodash";
import uuidv4 from "uuid/v4";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Title } from "GraphQL/Queries/Metadata/GetMetadataTitleRelation";
import { createDuck } from "lib/store/v2";

const createInitialState = () => ({
  edge: null as null | Edge,
  works: [] as Metadata[],
  origin: [] as Metadata[],
  loading: LOADING.ONLOAD as LOADING,
  editLoading: false as boolean,
  titleRelation: [] as Title[],
  modalId: "" as string,
  targetWorkInfo: [{ id: undefined, name: undefined, extra: undefined } as any] as Info[] // ChangeClassModal에서 이동할 대작품 리스트
});

export const WorkActions = createDuck({
  namespace: "Work",
  createInitialState,
  reducers: {
    setLoading(state, loading: LOADING) {
      state.loading = loading;
    },
    setEditLoading(state, loading: boolean) {
      state.editLoading = loading;
    },
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setWork(state, works: Metadata[]) {
      state.works = works;
      state.origin = works;
    },
    deleteWork(state, index: number) {
      state.works.splice(index, 1);
    },
    setWorkClear(state) {
      state.works = state.origin;
    },
    setWorkSave(state) {
      state.origin = state.works;
    },
    updateWorkTitle(state, { index, title }: { index: number; title: string }) {
      state.works[index].title = title;
    },
    updateWorkVC(state, { index, vc }: { index: number; vc: ValidType }) {
      state.works[index].validCheck = vc;
    },
    createWorkImage(
      state,
      { index, uuid, typeUrl, url, order }: { index: number; uuid: string; typeUrl: string; url: string; order: number }
    ) {
      if (!state.works[index].metadataUrl.length) {
        state.works[index].metadataUrl = [{ uuid, typeUrl, url, validCheck: ValidType.V3, order }];
      } else {
        state.works[index].metadataUrl.push({
          uuid,
          typeUrl,
          url,
          validCheck: ValidType.V3,
          order
        });
      }
    },
    updateWorkImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.works[index].metadataUrl.findIndex((url: any) => url.uuid === uuid);
      state.works[index].metadataUrl[urlIndex].typeUrl = typeUrl;
      state.works[index].metadataUrl[urlIndex].url = url;
    },
    createWorkArtist(state, index: number) {
      const { artistRelation } = state.works[index];
      const uuid = uuidv4();
      const order = !artistRelation.length ? 0 : _.last(artistRelation)!.order + 1;
      const tempArtist = {
        uuid,
        artist: [{ id: undefined, name: undefined }],
        role: [{ id: undefined, name: undefined }],
        character: [{ id: undefined, name: undefined }],
        validCheck: ValidType.V3,
        order
      } as Artist;
      if (!artistRelation.length) {
        state.works[index].artistRelation = [tempArtist];
      } else {
        state.works[index].artistRelation.push(tempArtist);
      }
    },
    updateWorkArtist(state, { index, artistIndex, info }: { index: number; artistIndex: number; info: Info }) {
      const { artistRelation } = state.works[index];
      artistRelation[artistIndex].artist[0].id = info.id;
      artistRelation[artistIndex].artist[0].name = info.name;
    },
    updateWorkArtistUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { artistRelation } = state.works[index];
      const artistIndex = artistRelation.findIndex(({ uuid }) => uuid === prevUuid);
      artistRelation[artistIndex].uuid = uuid;
    },
    updateWorkRole(state, { index, roleIndex, info }: { index: number; roleIndex: number; info: Info }) {
      const { artistRelation } = state.works[index];
      artistRelation[roleIndex].role[0].id = info.id;
      artistRelation[roleIndex].role[0].name = info.name;
    },
    updateWorkCharacter(state, { index, characterIndex, info }: { index: number; characterIndex: number; info: Info }) {
      const { artistRelation } = state.works[index];
      if (!artistRelation[characterIndex].character.length) {
        artistRelation[characterIndex].character = [{ id: info.id, name: info.name }];
      } else {
        artistRelation[characterIndex].character[0].id = info.id;
        artistRelation[characterIndex].character[0].name = info.name;
      }
    },
    deleteWorkArtist(state, { index, artistIndex }: { index: number; artistIndex: number }) {
      state.works[index].artistRelation.splice(artistIndex, 1);
    },
    setWorkRightsCompany(state, { index, id, name }: { index: number; id: string; name: string }) {
      state.works[index].rightsCompany[0].company[0].id = id;
      state.works[index].rightsCompany[0].company[0].name = name;
    },
    setWorkProductions(state, { index, productions }: { index: number; productions: Company[] }) {
      state.works[index].productions = productions;
    },
    setWorkPublications(state, { index, publications }: { index: number; publications: Company[] }) {
      state.works[index].publications = publications;
    },
    updateWorkCompanyUuid(state, { index, typeKind, prevUuid, uuid }: { index: number; typeKind: string; prevUuid: string; uuid: string }) {
      if (typeKind === "productions") {
        const companyIndex = state.works[index].productions.findIndex(({ uuid }) => uuid === prevUuid);
        state.works[index].productions[companyIndex].uuid = uuid;
      } else {
        const companyIndex = state.works[index].publications.findIndex(({ uuid }) => uuid === prevUuid);
        state.works[index].publications[companyIndex].uuid = uuid;
      }
    },
    createWorkUrl(state, { index, url }: { index: number; url: Url }) {
      if (!state.works[index].metadataUrl.length) {
        state.works[index].metadataUrl = [url];
      } else {
        state.works[index].metadataUrl.push(url);
      }
    },
    updateWorkTypeUrl(state, { index, urlIndex, typeUrl }: { index: number; urlIndex: number; typeUrl: string }) {
      const order = (_.last(state.works[index].metadataUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.works[index].metadataUrl[urlIndex].typeUrl = typeUrl;
      state.works[index].metadataUrl[urlIndex].order = order;
    },
    updateWorkUrl(state, { index, urlIndex, url }: { index: number; urlIndex: number; url: string }) {
      state.works[index].metadataUrl[urlIndex].url = url;
    },
    updateWorkUrlUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { metadataUrl } = state.works[index];
      const urlIndex = metadataUrl.findIndex(({ uuid }) => uuid === prevUuid);
      metadataUrl[urlIndex].uuid = uuid;
    },
    deleteWorkUrl(state, { index, urlIndex }: { index: number; urlIndex: number }) {
      state.works[index].metadataUrl.splice(urlIndex, 1);
    },
    setWorkTitleRelation(state, titleRelation: Title[]) {
      state.titleRelation = titleRelation;
    },
    createWorkTitleRelation(
      state,
      {
        uuid,
        typeTitle,
        value,
        languageCode,
        order
      }: { uuid: string; typeTitle: string; value: string; languageCode: string; order: number }
    ) {
      const newTitle = {
        uuid,
        typeTitle,
        value,
        validCheck: ValidType.V3,
        order,
        language: [
          {
            languageCode
          }
        ]
      };
      state.titleRelation.push(newTitle);
    },
    updateWorkTitleRelation(
      state,
      { index, typeTitle, value, languageCode }: { index: number; typeTitle: string; value: string; languageCode: string }
    ) {
      state.titleRelation[index].typeTitle = typeTitle;
      state.titleRelation[index].value = value;
      state.titleRelation[index].language[0].languageCode = languageCode;
    },
    deleteWorkTitleRelation(state, uuid: string) {
      const index = state.titleRelation.findIndex(title => title.uuid === uuid);
      state.titleRelation.splice(index, 1);
    },
    setWorkTitleRelationModalId(state, uuid: string) {
      state.modalId = uuid;
    },
    createTargetWorkInfo(state) {
      const tempInfo = { id: undefined, name: undefined, extra: undefined };
      state.targetWorkInfo.push(tempInfo as any);
    },
    updateTargetWorkInfo(state, { infoIndex, info }: { infoIndex: number; info: Info }) {
      state.targetWorkInfo[infoIndex] = info;
    },
    deleteTargetWorkInfo(state, infoIndex: number) {
      state.targetWorkInfo.splice(infoIndex, 1);
    },
    clearTargetWorkInfo(state) {
      state.targetWorkInfo = [{ id: undefined, name: undefined, extra: undefined } as any];
    }
  }
});
