import { Input } from "App/Atomics/Input";
import { useAppSelector, useAppStore } from "App/Store";
import { UserTokenActions } from "App/Store/UserToken";
import { TableTemplate } from "App/Templates/TableTemplate";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { ReactComponent as BackButton } from "assets/icons/back.svg";
import { ReactComponent as Loading } from "assets/icons/loading.svg";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { HEADER_HEIGHT_PX, pixelize, UNIT } from "constants/size";
import { UserRole } from "constants/UserRole";
import itiriri from "itiriri";
import { gql } from "lib/gql-tag";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import styled, { keyframes } from "styled-components";
import { clients } from "utils/clients";
import { commitToEditingLog } from "./commitToEditingLog";
import { TracksStoreProvider, useTracksDispatch, useTracksSelector } from "./Store";
import { Head, Subhead } from "./Store/TableInfo/sanitizeHeads";
import { TrackActions } from "./Store/Track";
import { subheadKeyValue } from "./subheadKeyValue";
import { TrackItem } from "./TrackItem";
import { useTrackHeadline } from "./useTrackHeadline";
import { CreateMultiAccessRecord, DeleteMultiAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { useQueryParams } from "lib/use-query-params";
import { Helmet } from "App/Atomics/Helmet";

const spin = keyframes`
  0%  {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const Layout = styled.div`
  display: flex;
  overflow: auto;

  width: 100%;
  height: 100vh;

  table {
    margin-top: ${HEADER_HEIGHT_PX};
  }

  input[type="text"] {
    padding: ${pixelize(1 * UNIT)} ${pixelize(2 * UNIT)};
    border: 1px solid ${DEFAULT_BORDER_COLOR};

    &:hover {
      border: 1px solid ${PRIMARY_COLOR_LIGHT};
    }

    &:focus {
      border: 1px solid ${PRIMARY_COLOR};
    }
  }

  svg {
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
  }

  .loading {
    opacity: 0.8;
    animation: ${spin} 1.5s linear infinite;
  }
`;

const RemoveButton = styled.div`
  width: min-content;
  margin-left: auto;
`;

const transformToTableHeadline = (head: Head, subhead: Subhead, userRole: UserRole | null) => {
  const subheadList = [] as string[];
  const headList = itiriri(Object.entries(head))
    .filter(([key, isToggledKey]) => {
      if (
        (userRole === UserRole.Arbeit && (key === "Company" || key === "Title" || key === "Work")) ||
        (userRole !== UserRole.Master && key === "LicenseExtra")
      ) {
        return false;
      } else {
        return isToggledKey;
      }
    })
    .toArray(([key]) => {
      const localSubheadList = itiriri(Object.entries(subhead[key as keyof typeof subhead]))
        .filter(([subKey, isToggledSubKey]) => {
          const subKeyValidate =
            subKey === "role" ||
            subKey === "language" ||
            subKey === "typeMetadataTitle" ||
            subKey === "typeUrl" ||
            subKey === "fileValidCheck" ||
            subKey === "publishDate" ||
            subKey === "recordYear" ||
            subKey === "publishYear" ||
            subKey === "firstEdition" ||
            subKey === "place";
          if (subKeyValidate) {
            return false;
          }
          return isToggledSubKey;
        })
        .toArray(([subKey]) => subheadKeyValue[key][subKey]);
      subheadList.push(...localSubheadList);
      return {
        name: key,
        colSpan: localSubheadList.length
      };
    });
  return { headList, subheadList };
};

const updateAccessQuery = (result: string, record: any) =>
  result +
  `
    reqAccess${record.target_id}: updateAccess_record(where: { id: "${record.id}" })
  `;

export const ChildLayout = () => {
  const router = useHistory();
  const queryParams = useQueryParams();
  const checkList = queryParams.getAll("ids");
  const userRole = useAppSelector(store => store.UserToken.role);
  const { tracks, events } = useTracksSelector(store => store.Track);
  const [{ accessRecord }, dispatchApp] = useAppStore(store => ({ accessRecord: store.UserToken.accessRecord }));
  const dispatch = useTracksDispatch();
  const { head, subhead } = useTrackHeadline();

  const createTableHeadline = () => transformToTableHeadline(head, subhead, userRole);
  const { headList, subheadList } = useMemo(createTableHeadline, [head, subhead]);
  const [loading, setLoading] = useState(true);

  const updateTracks = async () => {
    try {
      const { data: accessData } = await CreateMultiAccessRecord({ targetIdList: checkList, targetTable: TargetTableInput.Metadata });
      const deleteIdList = Object.values(accessData!).map(({ id }) => id);
      await commitToEditingLog(tracks, events);
      await DeleteMultiAccessRecord({ idList: deleteIdList });
      dispatch(TrackActions.setEventClear());
    } catch (err) {
      console.log(err);
      window.alert("서버에서 에러가 발생했습니다.");
    }
  };

  const cancelToEdit = async () => {
    dispatchApp(UserTokenActions.clearAccessRecord());
    router.goBack();
  };

  const manageAccessRecord = () => {
    setLoading(false);
    if (!Object.keys(accessRecord).length) {
      return;
    }

    const SECOND = 1000;
    const MINUTE = 60 * SECOND;

    const validateRecord = Object.values(accessRecord as Record<string, boolean>).every(value => value);
    if (!validateRecord) {
      window.alert("현재 데이터를 사용 중입니다. 15분 후에 다시 시도해주세요.\nSomeone using this data. Please try again in 15 minutes.");
      dispatchApp(UserTokenActions.clearAccessRecord());
      router.goBack();
      return;
    }

    let timer = window.setTimeout(async () => {
      if (!window.confirm("엑세스 시간이 곧 종료됩니다. 갱신하시겠습니까?")) {
        cancelToEdit();
        return;
      }

      const query = gql`
        mutation UPDATE_ACCESS_RECORD {
          ${Object.values(accessRecord).reduce(updateAccessQuery, "")}
        }
      `;
      const { data } = await clients.access.query(query);
      dispatchApp(UserTokenActions.setAccessRecord(data));
    }, 12 * MINUTE);

    return () => {
      window.clearTimeout(timer);
    };
  };

  useEffect(manageAccessRecord, [accessRecord]);

  return (
    <Layout>
      <TopbarTemplate>
        <Input.Button disabled={loading} onClick={cancelToEdit}>
          {loading ? <Loading className="loading" /> : <BackButton />}
        </Input.Button>
        <RemoveButton>
          <Input.Button color="danger" onClick={updateTracks}>
            저장
          </Input.Button>
        </RemoveButton>
      </TopbarTemplate>
      <TableTemplate headList={headList} subHeadList={subheadList} keyBy={data => data.metadataId} dataList={tracks} Column={TrackItem} />
    </Layout>
  );
};

export const WorkTrackEdit = () => (
  <TracksStoreProvider>
    <Helmet title="소작품 편집" />
    <ChildLayout />
  </TracksStoreProvider>
);
