import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import React, { useMemo } from "react";
import { FilterItem, InputNumber } from "../../formStyle";
import { GrantKey } from "App/Routes/AuthGrant/Store/Grant";

export const IdRangeFilter = () => {
  const [{ mode, searchList }, dispatch] = useAuthGrantStore(store => store.IdList);

  const idGte = useMemo(() => {
    switch (mode) {
      case GrantKey.TRACK:
        return searchList.Track.idGte;
      case GrantKey.ARTIST:
        return searchList.Artist.idGte;
      case GrantKey.GENRE:
        return searchList.Genre.idGte;
      case GrantKey.PLAYLIST:
        return searchList.Playlist.idGte;
      case GrantKey.COMPANY:
        return searchList.Company.idGte;
      default:
        break;
    }
  }, [mode, searchList.Artist.idGte, searchList.Genre.idGte, searchList.Playlist.idGte, searchList.Track.idGte, searchList.Company.idGte]);
  const idLte = useMemo(() => {
    switch (mode) {
      case GrantKey.TRACK:
        return searchList.Track.idLte;
      case GrantKey.ARTIST:
        return searchList.Artist.idLte;
      case GrantKey.GENRE:
        return searchList.Genre.idLte;
      case GrantKey.PLAYLIST:
        return searchList.Playlist.idLte;
      case GrantKey.COMPANY:
        return searchList.Company.idLte;
      default:
        break;
    }
  }, [mode, searchList.Artist.idLte, searchList.Genre.idLte, searchList.Playlist.idLte, searchList.Track.idLte, searchList.Company.idLte]);

  const onChangeNumber = (isGte: boolean, num?: number) => {
    if (isGte) {
      dispatch(IdActions.setIdGte({ idType: mode, gte: num }));
    } else {
      dispatch(IdActions.setIdLte({ idType: mode, lte: num }));
    }
  };

  return (
    <FilterItem>
      <div className="item-label">
        <span>아이디 범위</span>
      </div>
      <div className="item-option-wrapper">
        <div className="item-option">
          <InputNumber
            key={mode + idGte}
            min={1}
            max={9999999}
            step={1}
            placeholder="최소"
            defaultValue={idGte}
            onBlur={num => onChangeNumber(true, num)}
          />
        </div>
        <span style={{ marginTop: "6px" }}>{" ~ "}</span>
        <div className="item-option">
          <InputNumber
            key={mode + idLte}
            min={1}
            max={9999999}
            step={1}
            placeholder="최대"
            defaultValue={idLte}
            onBlur={num => onChangeNumber(false, num)}
          />
        </div>
      </div>
    </FilterItem>
  );
};
