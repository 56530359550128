import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { TrackEditEvents } from "./Store/Track";
import itiriri from "itiriri";

export const commitToEditingLog = async (tracks: any[], events: TrackEditEvents) => {
  let metadataUpdateQuery = "";
  if (tracks.length) {
    tracks.forEach(track => {
      const metadataEventLog = events.metadata.get(track.metadataId);
      const metadataId = track.metadataId;

      if (metadataEventLog) {
        let metadataQuery = `
          metadata${metadataId}: updateMetadata(
            data: {`;

        const createTitleQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "title")
          .toArray(([key]) => {
            const title = events.titleRelation.get(metadataId)![key];

            if (title) {
              return `{${itiriri(title.entries()).toArray(([key, value]) => {
                const titleValue = value.to!;
                return key === "language_code"
                  ? `language: { connect: { language_code: "${titleValue}" } }`
                  : `${value.column}: "${titleValue}"`;
              })}}`;
            }
          });

        const createArtistQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "artist")
          .toArray(([key]) => {
            const artist = events.artistRelation.get(metadataId);
            const role = events.roleRelation.get(metadataId);

            if (artist && role) {
              return `{
                artist: { connect: { artist_id: "${artist[key].to}" } }
                role: { connect: { role_id: "${role[key].to}" } }
              }`;
            }
          });

        const createUrlQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "url")
          .toArray(([key]) => {
            const url = events.urlRelation.get(metadataId)![key];

            if (url) {
              return `{${itiriri(url.entries()).toArray(([key, value]) =>
                key === "valid_check" ? `valid_check: ${value.to}` : `${value.column}: "${value.to}"`
              )}}`;
            }
          });

        const createExtraQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "extra")
          .toArray(([key]) => {
            const extra = events.extraRelation.get(metadataId);

            if (extra) {
              return `{${itiriri(extra[key].entries()).toArray(([key, value]) => {
                return key === "place" ? `${key}: "${value.to}"` : `${key}: ${value.to}`;
              })}}`;
            }
          });

        const createWorkQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "work")
          .toArray(([key]) => {
            const work = events.workRelation.get(metadataId);
            if (work) {
              return `
              { type_function: "work" 
                metadata_self_relations_element_metadata: {
                connect: {
                  metadata_id: "${work[key].to}"
                }
              }}
            `;
            }
          });

        const createGenreQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && (event.column === "genre" || event.column === "mood"))
          .toArray(([key]) => {
            const genre = events.genreRelation.get(metadataId);

            if (genre) {
              return `{
                genre: { connect: { genre_id: "${genre[key].to}" } }
              }`;
            }
          });

        const createInfoQuery = itiriri(Object.entries(metadataEventLog))
          .filter(([key, event]) => event.type === "Create" && event.column === "info")
          .toArray(([key]) => {
            const info = events.infoRelation.get(metadataId)![key];
            if (info) {
              return `{${itiriri(info.entries()).toArray(([key, value]) => {
                const column =
                  value.column === "bitrate" ? "sample_bitrate" : value.column === "timeSignature" ? "time_signature" : value.column;
                if (column === "time_signature" || column === "tonality") {
                  return `${column}: "${value.to}"`;
                }
                return `${column}: ${value.to}`;
              })}}`;
            }
          });

        if (createTitleQuery.length) {
          metadataQuery += `
            metadata_title: {
              create: [ ${createTitleQuery.join(" ")} ]
            }`;
        }

        if (createArtistQuery.length) {
          metadataQuery += `
            metadata_artist_relation: {
              create: [ ${createArtistQuery.join(" ")} ]
            }`;
        }

        if (createUrlQuery.length) {
          metadataQuery += `
            metadata_url: {
              create: [ ${createUrlQuery.join(" ")} ]
            }`;
        }
        if (createExtraQuery.length) {
          metadataQuery += `
            track_license_extra: {
              create: [
                ${createExtraQuery.join(" ")}
              ]
            }
          `;
        }

        if (createWorkQuery.length) {
          metadataQuery += `
          type_metadata_class: "record"
          metadata_self_relations_list_metadata: {
            create: [ ${createWorkQuery.join(" ")} ]
          }`;
        }

        if (createGenreQuery.length) {
          metadataQuery += `
            metadata_genre_relation: {
              create: [ ${createGenreQuery.join(" ")} ]
            }`;
        }

        if (createInfoQuery.length) {
          metadataQuery += `
          track_information: {
              create: [ ${createInfoQuery.join(" ")} ]
            }`;
        }

        const metadataUpdateList = itiriri(Object.entries(metadataEventLog)).filter(([key, event]) => event.type === "Update");

        if (metadataUpdateList.length() > 0) {
          metadataQuery += itiriri(Object.entries(metadataEventLog))
            .filter(([key, event]) => event.type === "Update")
            .toArray(([key]) => {
              return key === "valid_check"
                ? `
            ${key}: ${metadataEventLog[key].to}
            `
                : `
              ${key}: "${metadataEventLog[key].to}"
            `;
            })
            .join(" ");
        } else {
          metadataQuery += "_onlySearch: true";
        }

        metadataQuery += `}
          where: { metadata_id: "${track.metadataId}" }
        ) {
          metadata_id
          ${
            !createWorkQuery.length
              ? ""
              : `
              metadata_self_relations_list_metadata {
                id
                type_function
                metadata_self_relations_element_metadata {
                  metadata_id
                }
              }
          `
          }
        }`;

        metadataUpdateQuery += metadataQuery;
      }

      const titleEventLog = events.titleRelation.get(metadataId);

      if (titleEventLog) {
        const titleRemoveQuery = itiriri(Object.entries(titleEventLog))
          .filter(([key, event]) => event.has("Remove"))
          .toArray(([key], index) => {
            return `
              removeTitle${metadataId}${index}: deleteMetadata_title(
                where: { id: "${key}" }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += titleRemoveQuery;

        const titleUpdateQuery = itiriri(Object.entries(titleEventLog))
          .filter(
            ([key, event]) =>
              !event.has("Remove") &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key, value], index) => {
            const titleData = itiriri(value.entries())
              .toArray(([key, value]) => {
                const titleValue = value.to;
                return key === "language_code" ? `language: { connect: { language_code: "${titleValue}" } }` : `${key}: "${titleValue}"`;
              })
              .join(" ");
            return `
              updateTitle${metadataId}${index}: updateMetadata_title(
                where: { id: "${key}" }
                data: {
                  ${titleData}
                }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += titleUpdateQuery;
      }

      const artistEventLog = events.artistRelation.get(track.metadataId);
      const roleEventLog = events.roleRelation.get(track.metadataId);

      if (artistEventLog) {
        const artistRemoveQuery = itiriri(Object.entries(artistEventLog))
          .filter(([key, event]) => event.type === "Remove")
          .toArray(([key], index) => {
            return `
              removeArtist${metadataId}${index}: deleteMetadata_artist_relation(
                where: { id: "${key}" }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += artistRemoveQuery;

        const artistUpdateQuery = itiriri(Object.entries(artistEventLog))
          .filter(
            ([key, event]) =>
              event.type === "Update" &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key], index) => {
            const updateRole = roleEventLog
              ? itiriri(Object.entries(roleEventLog))
                  .filter(([roleKey, roleEvent]) => roleEvent.type === "Update" && key === roleKey)
                  .toArray(([key]) => {
                    return `
                      role: { connect: { role_id: "${roleEventLog![key].to}" } }
                    `;
                  })
                  .join(" ")
              : "";

            return `
              updateArtist${metadataId}${index}: updateMetadata_artist_relation(
                where: { id: "${key}" }
                data: {
                  artist: { connect: { artist_id: "${artistEventLog[key].to}" } }
                  ${updateRole}
                }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += artistUpdateQuery;
      }

      if (roleEventLog) {
        const roleUpdateQuery = roleEventLog
          ? itiriri(Object.entries(roleEventLog))
              .filter(([key, event]) => event.type === "Update" && (artistEventLog === undefined || !artistEventLog[key]))
              .toArray(([key], index) => {
                return `
                  updateRole${metadataId}${index}: updateMetadata_artist_relation(
                    where: { id: "${key}" }
                    data: {
                      role: { connect: { role_id: "${roleEventLog[key].to}" } }
                    }
                  ) {
                    id
                  }
                `;
              })
          : [];

        metadataUpdateQuery += roleUpdateQuery.join(" ");
      }

      const urlEventLog = events.urlRelation.get(track.metadataId);

      if (urlEventLog) {
        const urlUpdateQuery = itiriri(Object.entries(urlEventLog))
          .filter(
            ([key, event]) =>
              !event.has("Remove") &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key, value], index) => {
            const urlData = itiriri(value.entries())
              .toArray(([key, value]) => {
                return key === "valid_check" ? `${key}: ${value.to}` : `${key}: "${value.to}"`;
              })
              .join(" ");
            return `
              updateUrl${metadataId}${index}: updateMetadata_url(
                where: { id: "${key}" }
                data: {
                  ${urlData}
                }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += urlUpdateQuery;
      }

      const extraEventLog = events.extraRelation.get(track.metadataId);

      if (extraEventLog) {
        const updateExtraQuery = itiriri(Object.entries(extraEventLog))
          .filter(
            ([key, event]) =>
              !event.has("Remove") &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key, value], index) => {
            const extraData = itiriri(value.entries())
              .toArray(([key, value]) => {
                return key === "place" ? `${key}: "${value.to}"` : `${key}: ${value.to}`;
              })
              .join(" ");
            return `
            updateTrackLicenseExtra${metadataId}: updateTrack_license_extra(
              where: { id: "${key}" }
              data: {
                ${extraData}
              }
            ) {
              id
            }
          `;
          })
          .join(" ");

        metadataUpdateQuery += updateExtraQuery;
      }

      const workEventLog = events.workRelation.get(track.metadataId);

      if (workEventLog) {
        const workRemoveQuery = itiriri(Object.entries(workEventLog))
          .filter(([key, event]) => event.type === "Remove")
          .toArray(([key], index) => {
            return `
            removeWork${metadataId}${index}: deleteMetadata_self_relations_list_metadata(
              where: { id: "${key}" }
            ) {
              id
            }
          `;
          })
          .join(" ");
        metadataUpdateQuery += workRemoveQuery;

        const workUpdateQuery = itiriri(Object.entries(workEventLog))
          .filter(
            ([key, event]) =>
              event.type === "Update" &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key], index) => {
            return `
            updateWork${metadataId}${index}: updateMetadata_self_relations_list_metadata(
              where: { id: "${key}" }
              data: {
                metadata_self_relations_element_metadata: {
                  connect: {
                    metadata_id: "${workEventLog[key].to}"
                  }
                }
              }
            ) {
              id
            }
          `;
          })
          .join(" ");

        metadataUpdateQuery += workUpdateQuery;
      }

      const genreEventLog = events.genreRelation.get(track.metadataId);

      if (genreEventLog) {
        const genreRemoveQuery = itiriri(Object.entries(genreEventLog))
          .filter(([key, event]) => event.type === "Remove")
          .toArray(([key], index) => {
            return `
              removeGenre${metadataId}${index}: deleteMetadata_genre_relation(
                where: { id: "${key}" }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += genreRemoveQuery;

        const genreUpdateQuery = itiriri(Object.entries(genreEventLog))
          .filter(
            ([key, event]) =>
              event.type === "Update" &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key], index) => {
            return `
              updateGenre${metadataId}${index}: updateMetadata_genre_relation(
                where: { id: "${key}" }
                data: {
                  genre: { connect: { genre_id: "${genreEventLog[key].to}" } }
                }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += genreUpdateQuery;
      }

      const infoEventLog = events.infoRelation.get(metadataId);

      if (infoEventLog) {
        const infoRemoveQuery = itiriri(Object.entries(infoEventLog))
          .filter(([key, event]) => event.has("Remove"))
          .toArray(([key], index) => {
            return `
              removeInfo${metadataId}${index}: deleteTrack_information(
                where: { id: "${key}" }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += infoRemoveQuery;

        const infoUpdateQuery = itiriri(Object.entries(infoEventLog))
          .filter(
            ([key, event]) =>
              !event.has("Remove") &&
              (metadataEventLog === undefined || itiriri(Object.entries(metadataEventLog)).find(([subkey]) => subkey === key) === undefined)
          )
          .toArray(([key, value], index) => {
            const infoData = itiriri(value.entries())
              .toArray(([key, value]) => {
                const column = key === "bitrate" ? "sample_bitrate" : key === "timeSignature" ? "time_signature" : key;
                if (column === "time_signature" || column === "tonality") {
                  return `${column}: "${value.to}"`;
                }
                return `${column}: ${value.to}`;
              })
              .join(" ");
            return `
              updateInfo${metadataId}${index}: updateTrack_information(
                where: { id: "${key}" }
                data: {
                  ${infoData}
                }
              ) {
                id
              }
            `;
          })
          .join(" ");

        metadataUpdateQuery += infoUpdateQuery;
      }
    });
  }

  if (!metadataUpdateQuery.length) {
    alert("변경할 데이터가 없습니다.");
    return false;
  }

  if (window.confirm("데이터를 변경하시겠습니까?")) {
    try {
      const query = gql`
        mutation UPDATE_METADATA {
          ${metadataUpdateQuery}
        }
      `;

      const { data } = await clients.metadata.query(query);
      if (data) {
        window.alert("저장되었습니다.");
        return;
      }
    } catch (e) {
      throw e;
    }
  } else {
    return false;
  }
};
