import React, { ComponentType, useState } from "react";
import { TagAutoComplete } from "App/Atomics/AutoComplete";
import { findGenreList } from "./findGenreList";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { GRAY_5 } from "constants/baseColor";
import { PRIMARY_COLOR_LIGHT } from "constants/color";
import { components, OptionProps } from "react-select";
import styled from "styled-components";

type Props = Pick<
  Parameters<typeof TagAutoComplete>[0],
  "style" | "className" | "isDisabled" | "defaultValue" | "defaultOptions" | "onChange" | "onBlur" | "menuPlacement"
> &
  Readonly<{
    /**
     * | 값 | 의미 |
     * | - | - |
     * | undefined | 대분류/소분류 |
     * | true | 대분류 |
     * | false | 소분류 |
     */
    isParent?: boolean;
  }>;

export const GenreTagAutoComplete = ({
  style,
  className,
  isDisabled,
  defaultValue,
  defaultOptions,
  onChange,
  onBlur,
  isParent,
  menuPlacement
}: Props) => {
  const [initialOptions, setInitialOptions] = useState<Info[] | undefined | any>(defaultOptions);

  const onFocus = async () => {
    const info = await findGenreList({ keyword: "", isParent });
    setInitialOptions(info);
  };
  return (
    <TagAutoComplete
      placeholder="장르를 입력해주세요."
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      defaultOptions={initialOptions}
      delay={200}
      style={style}
      className={className}
      menuPlacement={menuPlacement}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      components={{ Option }}
      loadInfoList={keyword => findGenreList({ keyword, isParent })}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="id">{props.data.id}</span>
        <span className="name">{props.data.name}</span>
        <span className="extra">{props.data.parent}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .id {
    font-size: 9px;
    color: ${GRAY_5};
  }
  .name {
    font-size: 13px;
    font-weight: 500;
  }
  .extra {
    font-size: 10px;
    font-weight: 400;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;
