import { afterChanges, afterDispatches } from "utils/store";
import { createStore, combineReducers } from "lib/store";
import { reducer as CsvUpload } from "./CsvUpload";

const reducers = { CsvUpload };

const reducer = combineReducers(reducers, afterDispatches, afterChanges);

export const {
  StoreProvider: CsvUploadStoreProvider,
  useStore: useCsvUploadStore,
  useSelector: useCsvUploadSelector,
  useDispatch: useCsvUploadDispatch
} = createStore(reducer);
