import React, { Children, useState } from "react";
import styled from "styled-components";

import { MARGING_LARGE_PX, MARGING_XX_LARGE_PX, PADDING_XX_LARGE_PX, pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useAsyncEffect } from "lib/use-async-effect";
import { loadGenreList, Genre } from "./services/loadGenreList";
import { loadMoodList, Mood } from "./services/loadMoodList";
import { GRAY_4 } from "constants/baseColor";
import { Button } from "App/Atomics/Input/Button";
import { Loading } from "App/Atomics/Loading";
import { ScrollableTagList } from "App/Molecules/ScrollableTagList";
import { PRIMARY_ALERT_COLOR, PRIMARY_COLOR } from "constants/color";

type Props = Readonly<{
  genreList: Genre[];
  moodList: Mood[];
  onSave: (genreList: Genre[], moodList: Mood[]) => void;
  onCancel: () => void;
}>;

export const TagModal = ({ genreList: initialGenreList, moodList: initialMoodList, onSave, onCancel }: Props) => {
  // 기본(최종) 장르/무드 리스트
  const [genreList, setGenreList] = useState(initialGenreList);
  const [moodList, setMoodList] = useState(initialMoodList);
  // 모든 장르/무드를 담을 리스트
  const [optionGenreList, setOptionGenreList] = useState<Genre[]>([]);
  const [optionMoodList, setOptionMoodList] = useState<Mood[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const toSave = () => {
    console.log({ genreList, moodList });
    onSave(genreList, moodList);
  };
  const toCancel = () => onCancel();

  useAsyncEffect(async isMounted => {
    setLoading(true);
    const [genreList, moodList] = await Promise.all([loadGenreList(), loadMoodList()] as const);
    if (isMounted()) {
      if (genreList.isOk) {
        setOptionGenreList(genreList.item);
      }
      if (moodList.isOk) {
        setOptionMoodList(moodList.item);
      }
    }
    setLoading(false);
  }, []);

  return (
    <Layout>
      <BlockContainer>
        <Block>
          <Heading>장르 검색</Heading>
          <span className="info">자식을 등록하면 자동으로 부모가 추가됩니다.</span>
          <FilterGroup>
            <InputTagSelect
              className="autocomplete"
              optionList={optionGenreList}
              autoFocus
              value={genreList}
              onChange={genreList => {
                if (genreList) {
                  const nextGenreList = genreList.map(({ id, name, parent }) => ({ id, name, parent, typeKind: "genre" }));
                  setGenreList(nextGenreList);
                } else {
                  // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                }
              }}
            />
          </FilterGroup>
          {loading && <Loading loading={loading} />}
          {!loading && (
            <ScrollableTagList count={5}>
              {optionGenreList
                .filter(({ id }) => !genreList.find(genre => genre.id === id))
                .map((genre, index) => (
                  <ScrollableTagList.Item key={index} onClick={() => setGenreList(genreList => genreList.concat(genre))}>
                    {genre.name}
                  </ScrollableTagList.Item>
                ))}
            </ScrollableTagList>
          )}
        </Block>

        <Block>
          <Heading>무드 검색</Heading>
          <FilterGroup>
            <InputTagSelect
              className="autocomplete"
              optionList={optionMoodList.map(({ id, name, relationList }) => {
                const isDuplicated = moodList.some(mood =>
                  mood.relationList.find(({ parent, children }) => {
                    if (parent.length && relationList.length) {
                      return relationList.find(relation => relation.parent[0]?.id === parent[0].id) || parent.find(mood => mood.id === id);
                    }
                    return children.find(mood => mood.id === id);
                  })
                );
                return { id, name, isDisabled: isDuplicated };
              })}
              value={moodList}
              onChange={moodList => {
                if (moodList) {
                  const nextMoodList = optionMoodList.filter(({ id }) => moodList.find(item => item.id === id));
                  setMoodList(nextMoodList);
                } else {
                  // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                }
              }}
            />
          </FilterGroup>
          {loading && <Loading loading={loading} />}
          {!loading && (
            <ScrollableTagList count={7}>
              {optionMoodList
                .filter(({ id }) => !moodList.find(mood => mood.id === id))
                .map((mood, index) => (
                  <ScrollableTagList.Item
                    key={index}
                    isActive={moodList.some(({ relationList }) =>
                      relationList.find(({ parent, children }) => {
                        if (parent.length && mood.relationList.length) {
                          return (
                            mood.relationList.find(relation => relation.parent?.[0]?.id === parent[0]?.id) ||
                            parent.find(currentMood => currentMood.id === mood.id)
                          );
                        }
                        return children.find(currentMood => currentMood.id === mood.id);
                      })
                    )}
                    onClick={() => setMoodList(moodList => moodList.concat(mood))}
                  >
                    {mood.name}
                  </ScrollableTagList.Item>
                ))}
            </ScrollableTagList>
          )}
        </Block>
      </BlockContainer>

      <ButtonGroup>
        <Button className="btn cancel" color="primary" onClick={toSave}>
          입력
        </Button>
        <Button className="btn save" onClick={toCancel}>
          취소
        </Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  overflow: auto;
  overflow: overlay;

  display: flex;
  flex-direction: column;

  max-width: ${pixelize(UNIT * 55)};
  width: 100%;
  max-height: ${pixelize(UNIT * 50)};
  height: 100%;

  .suggestSpan {
    display: flex;
    flex-direction: row;
    margin-bottom: ${MARGING_XX_LARGE_PX};

    & > .autocomplete {
      width: 100%;
      margin-right: ${MARGING_LARGE_PX};
    }

    button {
      height: fit-content;
    }
  }
`;

const BlockContainer = styled.div`
  position: relative;
  overflow-y: hidden;

  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(
    ${props => Children.count(props.children)},
    calc(100% / ${props => Children.count(props.children)} - 1rem / ${props => Children.count(props.children)})
  );

  padding: ${PADDING_XX_LARGE_PX};
  padding-bottom: 0;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;

  .info {
    color: ${PRIMARY_COLOR};
    margin-bottom: 4px;
  }
`;

const Heading = styled.h3`
  margin-bottom: ${MARGING_LARGE_PX};
`;

const FilterGroup = styled.section`
  margin-bottom: ${MARGING_LARGE_PX};

  hr {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;

  .btn {
    width: 6rem;
    margin-right: 0.5rem;
  }
  .cancel {
    &:hover {
      background-color: #eee;
    }
  }
  /* Input.Button 기본 css를 수정해야함. */
  .save {
    border: 1px solid ${PRIMARY_ALERT_COLOR};
    &:hover {
      border: 1px solid ${PRIMARY_ALERT_COLOR};
    }
  }
`;

const InputTagSelect = styled(Input.TagSelect)`
  border-color: ${GRAY_4};
`;
