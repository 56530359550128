import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { Metadata } from "GraphQL/Queries/Metadata/GetMetadata";
import defaultImage from "assets/images/cover.png";
import { configs } from "configs";
import { mediaQuery } from "constants/media";
import { useHistory } from "react-router";
import { ARTISTS, TRACKS } from "constants/route";
import { Toast } from "lib/toast";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { GRAY_6 } from "constants/baseColor";

type Props = {
  album: Metadata;
};

const Layout = styled.div`
  width: 100%;
  min-width: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${HEADER_HEIGHT_PX};
  border: 1px solid lightgray;
  padding: 2rem 4rem;

  ${mediaQuery(765)} {
    padding: 2rem;
    flex-direction: column;
  }
  ${mediaQuery(375)} {
    padding: 1rem;
    font-size: 0.9em;
  }
`;

const ImageContainer = styled.div`
  width: 14rem;
  height: 14rem;
  margin-right: 2rem;
  border-radius: 0.2em;
  overflow: hidden;
  flex-shrink: 0;
  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  ${mediaQuery(765)} {
    width: 12rem;
    height: 12rem;
    justify-self: center;
    margin: 0 auto 1rem;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  min-height: 12rem;
`;

const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 4.5rem auto;
  padding: 0.4rem 0;
  white-space: pre-wrap;
  .link {
    margin-right: 0.2rem;
    cursor: pointer;
    &:hover {
      color: #666;
      text-decoration: underline;
    }
  }

  .elemntRelation {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .extra {
    color: ${GRAY_6};
  }
`;

export const AlbumTemplate = ({ album }: Props) => {
  const { metadataId, title, metadataUrl, artistRelation, rightsCompany, productions, publications, elementRelation } = album;
  const url = !metadataUrl ? "" : !metadataUrl.length ? defaultImage : `${configs.urls.image}/${metadataUrl[0].url}`;
  const router = useHistory();
  if (!album) {
    return null;
  }

  const onMovePage = (page: string, id?: string) => {
    if (!id) {
      Toast.error("정보를 가져올 수 없습니다.");
      return;
    }
    router.push(`${page}?page=1&q=${id}&type=아이디`);
  };

  const onMoveRelationTracks = () => {
    const url = elementRelation.map(({ listRelation }) => `ids=${listRelation[0].id}`).join("&");
    router.push(TRACKS + `?page=1&${url}`);
  };

  return (
    <Layout>
      <ImageContainer>
        <img src={url} alt={`${title ?? ""}`} />
      </ImageContainer>
      <InfoContainer>
        <InfoItem>
          <span>{"아이디"}</span>
          <ClipBoard text={metadataId} />
        </InfoItem>
        <InfoItem>
          <span>{"제목"}</span>
          <ClipBoard text={title} />
        </InfoItem>
        <InfoItem>
          <span>{"아티스트"}</span>
          <div>
            {!artistRelation
              ? ""
              : artistRelation.map(({ artist }, index) => {
                  const { id, name } = artist[0];
                  return (
                    <span key={id} className="link" onClick={() => onMovePage(ARTISTS, id)}>
                      {`${name}${index !== artistRelation.length - 1 ? "," : ""}`}
                    </span>
                  );
                })}
          </div>
        </InfoItem>
        <InfoItem>
          <span>{"권리사"}</span>
          <span>{(rightsCompany && rightsCompany[0]?.company[0].name) ?? ""}</span>
        </InfoItem>
        <InfoItem>
          <span>{"제작사"}</span>
          <div>
            {!productions || !productions.length
              ? ""
              : productions.map(({ company }, index) => {
                  const { id, name } = company[0];
                  return <span key={id}>{`${name}${index !== productions.length - 1 ? "," : ""}`}</span>;
                })}
          </div>
        </InfoItem>
        <InfoItem>
          <span>{"배급사"}</span>
          <div>
            {!publications || !publications.length
              ? ""
              : publications.map(({ company }, index) => {
                  const { id, name } = company[0];
                  return <span key={id}>{`${name}${index !== publications.length - 1 ? "," : ""}`}</span>;
                })}
          </div>
        </InfoItem>
        <InfoItem>
          <span>{"연관 트랙"}</span>
          <div>
            {!elementRelation || !elementRelation.length ? (
              ""
            ) : (
              <div className="elemntRelation" onClick={onMoveRelationTracks}>
                <span className="title">{elementRelation[0].listRelation[0].title}</span>{" "}
                <span className="extra">{`${elementRelation.length < 2 ? "" : `외 ${elementRelation.length - 1}곡`}`}</span>
              </div>
            )}
          </div>
        </InfoItem>
      </InfoContainer>
    </Layout>
  );
};
