import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_6 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { useRightStore } from "../../Store";
import { ValidType } from "constants/ValidType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { DeleteAccessRecord, UpdateRights } from "GraphQL/Queries";
import { RightsActions } from "../../Store/Right";
import { Loading } from "App/Atomics/Loading";

type Props = {
  index: number;
  toClose: () => void;
};

type RightInfo = {
  rightName?: string;
  rightDisplayName?: string;
  rightVC?: ValidType;
};

export const EditModal = ({ index, toClose }: Props) => {
  const [{ id, name, displayName, validCheck }, dispatch] = useRightStore(store => store.Right.right[index]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rightsInfo, setRightInfo] = useState<RightInfo>({
    rightName: name,
    rightDisplayName: displayName,
    rightVC: validCheck as ValidType
  });

  const onSave = useCallback(
    async (targetId: string, rightsInfo: RightInfo) => {
      setLoading(true);
      const { rightName, rightDisplayName, rightVC } = rightsInfo;
      try {
        const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Company });
        if (accessId) {
          try {
            const { data, errors } = await UpdateRights({ id, name: rightName, displayName: rightDisplayName, validCheck: rightVC });
            if (errors) {
              throw new Error(errors[0].message);
            }
            if (data) {
              dispatch(RightsActions.updateRights({ index, name: rightName!, displayName: rightDisplayName!, validCheck: rightVC! }));
              Toast.primary("업데이트되었습니다.", undefined, "top-center");
              toClose();
            }
          } catch (err) {
            Toast.error("업데이트에 실패하였습니다.", undefined, "top-center");
            console.log(err);
            return;
          } finally {
            await DeleteAccessRecord({ id: accessId });
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        Toast.error("권한 신청에 실패하였습니다.", undefined, "top-center");
        setLoading(false);
        return;
      }
    },
    [dispatch, id, index, toClose]
  );

  return (
    <Layout>
      <Header>
        <h3>권리사 편집</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <span>이름</span>
        <Input.Text
          className="input-text"
          placeholder="권리사 이름을 입력해주세요."
          defaultValue={rightsInfo.rightName}
          onBlur={text => setRightInfo(prev => ({ ...prev, rightName: text }))}
        />
        <span>표기명</span>
        <Input.Text
          className="input-text"
          placeholder="권리사 표기명을 입력해주세요."
          defaultValue={rightsInfo.rightDisplayName}
          onBlur={text => setRightInfo(prev => ({ ...prev, rightDisplayName: text }))}
        />
        <span>유효성</span>
        <ValidTypeTextSelect
          placeholder="유효성을 입력해주세요"
          defaultValue={rightsInfo.rightVC}
          style={selectStyle}
          onBlur={vc => {
            if (vc) {
              setRightInfo(prev => ({ ...prev, rightVC: vc }));
            }
          }}
        />
      </Section>
      <ButtonGroup>
        <Input.Button onClick={toClose}>취소</Input.Button>
        <Input.Button
          color="primary"
          disabled={!rightsInfo.rightName || !rightsInfo.rightDisplayName || !rightsInfo.rightVC}
          onClick={() => onSave(id, rightsInfo)}
        >
          저장
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 512px;
  height: 400px;
  font-size: 0.8rem;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    color: #191919;
    margin: 0.5rem 0;
  }

  .input-text {
    border: 1px solid hsl(210 6% 83%);
    padding: 0.6rem;
    transition: border-color 0.15s;
    &:focus {
      border-color: ${PRIMARY_COLOR};
    }
  }
`;

const ButtonGroup = styled.div`
  height: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 1rem 0;
`;

const selectStyle = {
  control: (base: any) => ({
    ...base,
    border: "1px solid hsl(210 6% 83%)",
    padding: "0.1rem"
  })
};
