import React, { ChangeEvent, useRef } from "react";
import styled from "styled-components";
import {
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_X_LARGE_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  MARGING_X_LARGE_PX,
  BORDER_RADIUS_PX
} from "constants/size";
import { GRAY_4, WHITE, GRAY_6 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, SECONDARY_COLOR, DANGER_COLOR_LIGHT } from "constants/color";
import { Input } from "App/Atomics/Input";
import { useCsvUploadStore } from "App/Routes/CsvUpload/Store";
import { CsvUploadActions } from "App/Routes/CsvUpload/Store/CsvUpload";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import Time from "dayjs";
import { TrackForm } from "./TrackForm";
import { Divider } from "App/Atomics/Divider";
import { ReactComponent as AlbumIcon } from "assets/icons/cd.svg";
import { ArtistTagAutoComplete } from "App/Molecules/AutoCompletes/Artist";

type Props = {
  index: number;
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;
  background: ${WHITE};
  padding: ${PADDING_XX_LARGE_PX};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};

  h2 {
    text-align: left;
  }

  h4 {
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  .autocomplete {
    width: 100%;
  }

  input {
    width: 100%;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${MARGING_LARGE_PX};

  svg {
    width: ${pixelize(UNIT * 1.5)};
    height: ${pixelize(UNIT * 1.5)};
  }

  span {
    color: ${DANGER_COLOR_LIGHT};
  }
`;

const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_X_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${PADDING_LARGE_PX};

  h4 {
    span {
      font-weight: 400;
      font-size: 0.9rem;
      color: ${DANGER_COLOR_LIGHT};
      margin-left: ${MARGING_SMALL_PX};
    }
  }

  input[type="text"],
  input[type="file"],
  input[type="number"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
  input[type="file"] {
    font-size: 0.9rem;
  }
  .genre {
    margin-right: ${MARGING_LARGE_PX};
  }
  .genre,
  .mood {
    margin-top: ${MARGING_SMALL_PX};
    min-width: ${pixelize(12 * UNIT)};
    width: 100%;
  }
`;

const ImageContainer = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 10px;
  align-items: center;
  img {
    width: ${pixelize(UNIT * 9)};
    height: ${pixelize(UNIT * 9)};
    cursor: pointer;
  }
  input[type="file"] {
    display: none;
  }
`;

const NoImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 6.25)};
  height: ${pixelize(UNIT * 6.25)};
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  font-weight: bold;
  cursor: pointer;
`;

const TrackTextSelect = styled(Input.TextSelect)`
  width: 100%;
  margin-bottom: ${MARGING_LARGE_PX};
`;

export const AlbumForm = ({ index }: Props) => {
  const [{ album, trackIndex }, dispatch] = useCsvUploadStore(store => ({
    album: store.CsvUpload.albumInfo[index],
    trackIndex: store.CsvUpload.trackIndex
  }));
  const scrollRef = useRef<HTMLHeadingElement>(null);
  const requiredElementRef = useRef(null);

  const scrollToElement = () => {
    scrollRef.current!.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  const today = Time().format("YYYY-MM-DD");

  const setCsvAlbumCover = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        CsvUploadActions.setCsvAlbumImage(
          index,
          file.files![0],
          event.target?.result as string,
          file.files![0].type.split("/")[1],
          file.files![0].name,
          file.files![0].type
        )
      );
    };
  };

  return (
    <Layout>
      <Title>
        <AlbumIcon />
        <h2 ref={scrollRef}>앨범정보 입력</h2>
        {!album.isDone ? null : <span>이미 생성된 앨범은 수정할 수 없습니다. (아래 트랙을 편집해주세요.)</span>}
      </Title>
      <CustomDivider />
      <RowGroup>
        <h4>시리얼 넘버*</h4>
        <Input.Text
          key={index}
          isRequired
          ref={requiredElementRef}
          isDisabled={album.isDone}
          placeholder="시리얼 넘버를 입력하세요."
          defaultValue={album.no}
          onBlur={no => dispatch(CsvUploadActions.setCsvAlbumNo(index, no))}
        />
      </RowGroup>
      <RowGroup>
        <h4>앨범 타이틀*</h4>
        <Input.Text
          key={index}
          isRequired
          ref={requiredElementRef}
          isDisabled={album.isDone}
          placeholder="제목을 입력하세요."
          defaultValue={album.albumTitle}
          onBlur={title => dispatch(CsvUploadActions.setCsvAlbumTitle(index, title))}
        />
      </RowGroup>
      <RowGroup>
        <h4>메인 아티스트 (타입)*</h4>
        <ArtistTagAutoComplete
          key={index}
          className="autocomplete"
          isDisabled={album.isDone}
          defaultValue={
            album.albumArtist &&
            album.albumArtist.map(({ artist_id, artist_name }) => ({
              id: artist_id,
              name: artist_name
            }))
          }
          onChange={info => {
            if (info) {
              const artistInfo = info.map(({ id, name }, order) => ({
                artist_id: id,
                artist_name: name,
                role_id: "343",
                role_name: "perform main",
                order
              }));
              dispatch([
                CsvUploadActions.setCsvAlbumArtists(index, artistInfo),
                ...album.trackInfo.map((_, i) => CsvUploadActions.setCsvTrackArtist(index, i, artistInfo))
              ]);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>앨범 커버</h4>
        <ImageContainer>
          {!album.albumUrl ? (
            <NoImage htmlFor="input-file">NO IMAGE</NoImage>
          ) : (
            <label htmlFor="input-file">
              <img src={URL.createObjectURL(album.albumUrl.file)} alt="cover" />
            </label>
          )}
          {album.isDone ? null : <input type="file" id="input-file" accept="image/jpeg,image/png" onChange={setCsvAlbumCover} />}
        </ImageContainer>
      </RowGroup>
      <RowGroup>
        <h4>권리사*</h4>
        <CompanyTextAutoComplete
          key={`${index}-${album.rightsCompany?.company_id}`}
          className="autocomplete"
          isDisabled={album.isDone}
          defaultValue={album.rightsCompany && { id: album.rightsCompany.company_id, name: album.rightsCompany.name }}
          onChange={info => {
            if (info) {
              dispatch(
                CsvUploadActions.setCsvAlbumRightsCompany(index, {
                  company_id: info.id,
                  name: info.name,
                  license: info.license ? info.license : "",
                  type: info.type ? info.type : "",
                  path: info.path ? info.path : ""
                })
              );
              album.trackInfo.forEach((track, i) => {
                const notice = JSON.stringify({ text: `License : ${info.license}` });
                dispatch(CsvUploadActions.setCsvTrackLicense(index, i, notice, "VL", album.releaseDate));
              });
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>
          제작사<span>(production)</span>
        </h4>
        <CompanyTagAutoComplete
          key={`${index}-${album.productions ? JSON.stringify(album.productions) : ""}`}
          className="autocomplete"
          isDisabled={album.isDone}
          defaultValue={album.productions?.map(({ company_id, name }) => ({ id: company_id, name }))}
          onChange={info => {
            if (info) {
              const productions = info.map(({ id, name }) => ({ company_id: id, name }));
              dispatch(CsvUploadActions.setCsvAlbumProduction(index, productions));
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>
          배급사<span>(publication)</span>
        </h4>
        <CompanyTagAutoComplete
          key={`${index}-${album.productions ? JSON.stringify(album.publications) : ""}`}
          className="autocomplete"
          isDisabled={album.isDone}
          defaultValue={album.publications?.map(({ company_id, name }) => ({ id: company_id, name }))}
          onChange={info => {
            if (info) {
              const publications = info.map(({ id, name }) => ({ company_id: id, name }));
              dispatch(CsvUploadActions.setCsvAlbumPublication(index, publications));
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>발매일자*</h4>
        <input
          type="date"
          disabled={album.isDone}
          defaultValue={!album.releaseDate ? today : album.releaseDate}
          onChange={event => {
            const date = event.currentTarget.value;
            dispatch(CsvUploadActions.setCsvAlbumReleaseDate(index, date));
            if (album.rightsCompany) {
              album.trackInfo.forEach((track, i) => {
                dispatch(CsvUploadActions.setCsvTrackLicenseNoticeDate(index, i, date));
              });
            }
          }}
        />
      </RowGroup>
      <Divider />
      <RowGroup>
        <h3 style={{ marginBottom: MARGING_LARGE_PX }}>트랙 목록</h3>
        <TrackTextSelect
          key={`${index}-${album.trackInfo[trackIndex].trackName}`}
          defaultValue={{ id: trackIndex.toString(), name: `${album.trackInfo[trackIndex].trackName}` }}
          optionList={album.trackInfo.map(({ trackName }, idx) => ({
            id: idx.toString(),
            name: `${!trackName ? "" : trackName}`
          }))}
          onChange={info => {
            if (info) {
              dispatch(CsvUploadActions.setCsvTrackIndex(+info.id));
            }
          }}
        />
      </RowGroup>
      <TrackForm
        key={`${index}-${album.trackInfo[trackIndex].trackName}-${album.albumArtist?.map(artist => artist.artist_id).join("-")}`}
        index={index}
        trackIndex={trackIndex}
        scrollToElement={scrollToElement}
      />
    </Layout>
  );
};
