/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  id: string;
}>;

export const DeleteGoods = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;

  const { query, variables } = render(
    <Mutation operationName="DELETE_GODOS">
      <SelectionSet
        name="deleteGoods"
        argument={{
          where: {
            goods_id: $id
          }
        }}
      >
        <Field name="goods_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteGoods: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
