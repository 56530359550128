import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { EffectCreateActions as EffectCreate } from "./Effect";

const reducers = { EffectCreate: EffectCreate };

const reducer = combineReducers(reducers, createStoreLogger("Effect"));

export const {
  StoreProvider: EffectCreateStoreProvider,
  useStore: useEffectCreateStore,
  useSelector: useEffectCreateSelector,
  useDispatch: useEffectCreateDispatch
} = createStore(reducer);
