import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { WorkTextAutoComplete } from "App/Molecules/AutoCompletes/Work";

type Info = {
  id: string;
  name: string;
};

type Props = {
  readonly work: Info;
};

export const GoodsWork = ({ work }: Props): ReactElement => {
  const onChange = (info: Info | null) => info && GoodsCreateStore.dispatch(GoodsCreateActions.setWork(info));
  return (
    <Style.Row>
      <Style.Caption>작품(앨범)</Style.Caption>
      <Style.SubCaption>굿즈와 관련된 앨범을 입력해주세요.</Style.SubCaption>
      <WorkTextAutoComplete style={SelectStyle} defaultValue={work} placeholder="작품을 입력해주세요" onBlur={onChange} workClass="album" />
      <Style.AlertCaption>앨범을 선택해주세요.</Style.AlertCaption>
    </Style.Row>
  );
};
