/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

enum PathType {
  images = "images",
  faces = "faces",
  imgs = "imgs"
}

type Props = Readonly<{
  value?: PathType;
  isNullable?: boolean;
}>;

const items = [PathType.images, PathType.faces, PathType.imgs];

export const PathTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="pathType" value={value} isNullable={isNullable} items={items} />
);
