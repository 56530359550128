import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_PX } from "constants/size";
import { CardItem } from "./CardItem";
import { useProductSelector } from "../../Store";

const Layout = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${HEADER_HEIGHT_PX};
  padding: 2em;
  padding-bottom: 0;
`;

export const CardList = () => {
  const product = useProductSelector(store => store.Product.product);
  return (
    <Layout>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      {!product.length && <EmptyData>상품이 없어요...😢</EmptyData>}
      {product.map(data => (
        <CardItem key={data.id.key} data={data} />
      ))}
    </Layout>
  );
};

const EmptyData = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding: 16px 0;
`;
