import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { RightsActions as Right } from "./Right";
const reducers = { Right };

const reducer = combineReducers(reducers, createStoreLogger("Rights"));

export const {
  StoreProvider: RightStoreProvider,
  useStore: useRightStore,
  useSelector: useRightSelector,
  useDispatch: useRightDispatch
} = createStore(reducer);
