import { Info } from "App/Atomics/Input/Select/TextSelect";
import { GoodsSubdata } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { createDuck } from "lib/store/v3";

export type Url = {
  id: string;
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

export enum Section {
  First,
  Second,
  Third
}

export type Product = {
  name: string;
  details: string;
  quality: string;
  createdAt: string;
  price: number;
  goods?: Info;
  subdatas: GoodsSubdata[];
  urls: Map<string, Url>;
};

const createInitialState = () => ({
  section: Section.First,
  product: {
    price: 0,
    subdatas: [] as GoodsSubdata[],
    urls: new Map()
  } as Product,
  subdatas: [] as GoodsSubdata[]
});

export const ProductCreateActions = createDuck({
  namespace: "ProductCreate",
  createInitialState,
  reducers: {
    clear(state) {
      state.section = createInitialState().section;
      state.product = createInitialState().product;
    },
    setSection(state, section: Section) {
      state.section = section;
    },
    setName(state, value: string) {
      state.product.name = value;
    },
    setDetail(state, value: string) {
      state.product.details = value;
    },
    setQuality(state, value: string) {
      state.product.quality = value;
    },
    setPrice(state, value: number) {
      state.product.price = value;
    },
    setGoods(state, goods: Info) {
      state.product.goods = goods;
      state.product.subdatas = [] as GoodsSubdata[];
      state.subdatas = [];
    },
    setGoodsSubadatas(state, subdatas: GoodsSubdata[]) {
      state.subdatas = subdatas;
    },
    addSubadata(state, subdata: GoodsSubdata) {
      state.product.subdatas.push(subdata);
    },
    removeSubadata(state, index: number) {
      state.product.subdatas.splice(index, 1);
    },
    setCreatedAt(state, date: string) {
      state.product.createdAt = date;
    },
    addCoverUrl(state, url: Url) {
      state.product.urls.set(url.id, url);
    },
    removeCoverUrl(state, id: string) {
      state.product.urls.delete(id);
    }
  }
});
