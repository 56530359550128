import React from "react";

import { AnimatedTooltip } from "App/Atomics/AnimatedTooltip";
import { ReactComponent as QuestionMark } from "assets/icons/question.svg";

type Direction = "top" | "left" | "right" | "bottom";

type Props = Readonly<{
  text: string;
  direction?: Direction;
}>;

export const GuideTooltip = ({ direction, text = "right" }: Props) => (
  <AnimatedTooltip text={text} direction={direction}>
    <QuestionMark />
  </AnimatedTooltip>
);
