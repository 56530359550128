import { CreateInvalidation } from "GraphQL/Queries";
import axios from "axios";
import { createFormData } from "lib/createFormData";
import { UpdateFile } from "GraphQL/Queries";
import { MediaType } from "GraphQL/Scalars/MediaType";
export const handleUpdateFile = async (filename: string, companyId: string, file: Blob, contentType: string, mediaType: MediaType) => {
  try {
    const { data: updateData, errors: updateErr } = await UpdateFile({ filename, companyId });
    if (updateErr || !updateData) {
      console.log(updateErr);
      throw updateErr;
    }
    await axios({
      method: "post",
      url: updateData.updateFile.url,
      data: createFormData(updateData.updateFile, file),
      headers: {
        "Content-Type": contentType
      }
    });
    await CreateInvalidation({
      token: updateData.updateFile.invalidateToken,
      mediaType
    });
    return updateData.updateFile;
  } catch (err) {
    throw err;
  }
};
