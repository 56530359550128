import React, { useMemo, useState } from "react";
import { FilterItem, MultiSelect, inputCustomStyle } from "../../formStyle";
import { Info } from "App/Atomics/Input/Select/TagSelect";
import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import { GrantKey } from "App/Routes/AuthGrant/Store/Grant";

export const IdIncludeFilter = () => {
  const [{ mode, searchList }, dispatch] = useAuthGrantStore(store => store.IdList);
  const [input, setInput] = useState("");

  const idIncludeList = useMemo(() => {
    switch (mode) {
      case GrantKey.TRACK:
        return searchList.Track.idIncludeList;
      case GrantKey.ARTIST:
        return searchList.Artist.idIncludeList;
      case GrantKey.GENRE:
        return searchList.Genre.idIncludeList;
      case GrantKey.PLAYLIST:
        return searchList.Playlist.idIncludeList;
      case GrantKey.COMPANY:
        return searchList.Company.idIncludeList;
      default:
        return new Set<string>();
    }
  }, [
    mode,
    searchList.Artist.idIncludeList,
    searchList.Genre.idIncludeList,
    searchList.Playlist.idIncludeList,
    searchList.Track.idIncludeList,
    searchList.Company.idIncludeList
  ]);

  const optionList = !input || idIncludeList.has(input) ? [] : [{ id: input, name: input }];
  const addId = (infoList: readonly Info[]) => {
    if (infoList.length) {
      const nextId = infoList[infoList.length - 1].id;
      const nextIdSet = new Set(idIncludeList).add(nextId);
      dispatch(IdActions.setIdIncludeList({ idType: mode, idList: nextIdSet }));
      setInput("");
    } else {
      dispatch(IdActions.setIdIncludeList({ idType: mode, idList: new Set<string>() }));
    }
  };
  const clearInput = () => setInput("");
  const updateIdSet = (value: string) => {
    const whiteSpacePattern = /[ ,\-_/]+/g;
    const idList = value.split(whiteSpacePattern);
    if (idList.length === 1) {
      setInput(value.replace(whiteSpacePattern, ""));
      return;
    }
    const nextIdSet = new Set(idIncludeList);
    for (const id of idList.slice(0, -1)) {
      if (id) {
        nextIdSet.add(id);
      }
    }
    const id = idList[idList.length - 1];
    if (id.length > 1) {
      nextIdSet.add(id);
      setInput("");
    } else {
      setInput(id);
    }
    dispatch(IdActions.setIdIncludeList({ idType: mode, idList: nextIdSet }));
  };

  return (
    <FilterItem>
      <div className="item-label">
        <span>아이디 포함 리스트</span>
      </div>
      <div className="item-option-wrapper">
        <MultiSelect
          key={mode}
          style={inputCustomStyle}
          placeholder="다수의 아이디를 붙여넣으실 수 있습니다."
          inputValue={input}
          value={Array.from(idIncludeList, id => ({ id, name: id }))}
          optionList={optionList}
          onInputChange={updateIdSet}
          onChange={addId}
          onBlur={clearInput}
        />
      </div>
    </FilterItem>
  );
};
