export enum RoleTitleOrderByInput {
  id_ASC = "id__ASC",
  id_DESC = "id__DESC",
  exposure_order__ASC = "exposure_order__ASC",
  exposure_order__DESC = "exposure_order__DESC",
  type_role_title__ASC = "type_role_title__ASC",
  type_role_title__DESC = "type_role_title__DESC",
  value__ASC = "value__ASC",
  value__DESC = "value__DESC",
  valid_check_ASC = "valid_check_ASC",
  valid_check_DESC = "valid_check_DESC",
  createdAt_ASC = "createdAt__ASC",
  createdAt_DESC = "createdAt__DESC",
  updatedAt_ASC = "updatedAt__ASC",
  updatedAt_DESC = "updatedAt__DESC"
}
