import React, { ReactNode } from "react";
import styled from "styled-components";
import { transparentize } from "constants/color";
import { BLACK, WHITE } from "constants/baseColor";
import { PADDING_X_LARGE_PX, BORDER_RADIUS_PX, pixelize, UNIT } from "constants/size";

const Layout = styled.div<{ text: string; isEnable: boolean }>`
  &:hover {
    position: relative;
  }

  &:hover::after {
    display: ${props => (props.isEnable ? "block" : "none")};
    position: absolute;
    left: 0;
    width: auto;
    height: auto;
    min-width: ${pixelize(10 * UNIT)};
    max-width: ${pixelize(30 * UNIT)};
    padding: ${PADDING_X_LARGE_PX};

    background-color: ${transparentize(BLACK, 90)};
    border-radius: ${BORDER_RADIUS_PX};
    content: "${props => props.text}";
    color: ${transparentize(WHITE, 90)};
    white-space: pre;
    word-break: break-all;
    z-index: 9;
  }
`;

type Props = Styleable &
  Readonly<{
    children: ReactNode;
    text: string;
    isEnable?: boolean;
  }>;

export const Tooltip = ({ style, className, children, text = "", isEnable = true }: Props) => (
  <Layout
    style={style}
    className={className}
    text={window.decodeURIComponent(text).replace(new RegExp('"', "g"), '\\"')}
    isEnable={isEnable}
  >
    {children}
  </Layout>
);
