import React from "react";
import styled from "styled-components";
import { VideoCreateStyle } from "App/Routes/VideoCreate/Style";
import { Button } from "App/Atomics/Input/Button";
import { useCreateVideo } from "App/Routes/VideoCreate/Hooks/useCreateVideo";

export const ButtonForm = () => {
  const { createVideo, loading } = useCreateVideo();
  const onClear = () => {
    if (!window.confirm("초기화하시겠습니까?")) return;
    window.location.reload();
  };
  return (
    <VideoCreateStyle.Row>
      <ButtonGroup>
        <StyledButton disabled={loading.isToggled} color="info" onClick={onClear}>
          초기화
        </StyledButton>
        <StyledButton disabled={loading.isToggled} color="primary" onClick={createVideo}>
          등록
        </StyledButton>
      </ButtonGroup>
    </VideoCreateStyle.Row>
  );
};

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;
const StyledButton = styled(Button)`
  width: 100%;
  & + & {
    margin-left: 10px;
  }
`;
