import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Props = {
  readonly globalName: string;
};

export const GoodsGlobalName = ({ globalName }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>상품명 (영어) *</Style.Caption>
    <Style.SubCaption>ex. BLACKPINK - THE ALBUM / 1ST FULL ALBUM</Style.SubCaption>
    <Style.InputText
      placeholder={"Product Name"}
      defaultValue={globalName}
      onBlur={value => GoodsCreateStore.dispatch(GoodsCreateActions.setGlobalName(value))}
    />
    <Style.AlertCaption>영문명을 입력해주세요.</Style.AlertCaption>
  </Style.Row>
);
