import React, { ChangeEvent, useState, useRef } from "react";
import styled from "styled-components";
import { pixelize, UNIT } from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_6, GRAY_2, GRAY_1 } from "constants/baseColor";
import { Slider } from "App/Molecules/Slider";
import { configs } from "configs";
import coverImage from "assets/images/cover.png";
import { Input } from "App/Atomics/Input";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { ImageCropModal } from "../ImageCropModal";

type ImageState = {
  file: File | undefined;
  imageData: string | undefined;
  imageType: string | undefined;
  uuid: string | undefined;
  ext: string;
  action: string;
  url: string | undefined;
};

type Url = {
  uuid: string;
  typeUrl: string;
  url: string;
  validCheck: string;
};

type Props = {
  toClose: () => void;
  metadataUrl: Url[];
};
const Layout = styled.div`
  width: ${pixelize(UNIT * 55)};
  height: ${pixelize(UNIT * 45)};
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    background-image: url(${coverImage});
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;
const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 10%;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    right: 3%;
    width: 35px;
    height: 35px;
    padding: 0.5rem;
    fill: black;
    border-radius: 50%;
    background-color: ${GRAY_2};
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
      background-color: ${GRAY_1};
    }
  }
`;

const Section = styled.section`
  margin: 0.5rem auto;
  padding: 2rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  background-color: #fff;
`;

const ImageLabel = styled.label`
  span {
    font-weight: 100;
    color: #eee;
    font-size: 1.3rem;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  input {
    display: none;
  }
  img {
    object-fit: fill;
    padding: 0;
    height: ${pixelize(UNIT * 30)};
    cursor: pointer;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  margin-right: 1rem;
  .btn {
    width: ${pixelize(UNIT * 8)};
    margin-right: 0.5rem;
  }
  input[type="file"] {
    display: none;
  }
`;

export const ImageViewModal = ({ metadataUrl, toClose }: Props) => {
  const [imageState, setImageState] = useState<ImageState>({
    file: undefined,
    imageData: undefined,
    imageType: undefined,
    uuid: undefined,
    ext: "",
    action: "INSERT",
    url: undefined
  });
  const { imageData, imageType, uuid, ext, action, url, file } = imageState;
  const inputFileRef = useRef<HTMLInputElement>(null);
  const imageCropModal = useToggle();
  const onChangeInputFile = (e: ChangeEvent<HTMLInputElement>, action: string, uuid?: string, typeUrl?: string, url?: string) => {
    const { files } = e.currentTarget;
    if (files?.length) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onloadend = e => {
        if (e.target && e.target.result) {
          let image = new Image();
          image.src = e.target.result as string;
          image.onload = () => {
            if (image.naturalWidth < 300 || image.naturalHeight < 300) {
              window.alert("이미지의 가로 / 세로 길이가 300이상 이어야합니다.");
              return;
            }
            let ext;
            switch (files[0].type) {
              case "image/jpeg":
                ext = "jpg";
                break;
              case "image/png":
                ext = "png";
                break;
              case "image/webp":
                ext = "webp";
                break;
              default:
                return;
            }
            setImageState({
              file: files[0],
              imageData: fileReader.result! as string,
              imageType: typeUrl,
              uuid: uuid,
              ext: ext,
              action,
              url
            });
            imageCropModal.on();
          };
        }
      };
    } else {
      window.alert("파일 정보를 불러올 수 없습니다.");
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h2>앨범 이미지 변경</h2>
        <CancelIcon onClick={toClose} />
      </Header>
      <Section>
        <Slider>
          {!metadataUrl.length ? (
            <ImageLabel>
              <input type="file" accept="image/*" onChange={e => onChangeInputFile(e, "INSERT")} />
              <img src={coverImage} alt="defaultCover" />
              <span>이미지가 없습니다.</span>
            </ImageLabel>
          ) : (
            metadataUrl.map(({ uuid, url, typeUrl }) => (
              <ImageLabel key={uuid}>
                <input type="file" accept="image/*" onChange={e => onChangeInputFile(e, "UPDATE", uuid, typeUrl, url)} />
                <img
                  alt={typeUrl}
                  src={`${configs.urls.image}/${url}${url.includes("?") ? "&" : "?"}${typeUrl === "head" ? `size=640x360` : `mode=l`}`}
                />
                <span>{typeUrl}</span>
              </ImageLabel>
            ))
          )}
        </Slider>
      </Section>
      <Footer>
        <ButtonGroup>
          <input ref={inputFileRef} type="file" name="image" accept="image/*" onChange={e => onChangeInputFile(e, "INSERT")} />
          <Input.Button className="btn" color="primary" onClick={() => inputFileRef.current!.click()}>
            이미지 추가
          </Input.Button>
          <Input.Button className="btn" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Footer>
      <Modal isOpen={imageCropModal.isToggled}>
        <ImageCropModal
          uuid={uuid}
          src={imageData ? imageData : ""}
          typeUrl={imageType}
          url={url}
          file={file}
          action={action}
          ext={ext}
          toClose={toClose}
        />
      </Modal>
    </Layout>
  );
};
