/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  listId?: string;
}>;

export const UpdateMetadataElementRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $listId = <Variable name="list_id" scalar={<IdScalar />} value={option.listId} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_ELEMENT_RELATION">
      <SelectionSet
        name="updateMetadata_self_relations_element_metadata"
        alias="updateMetadataSelfRelationsElementMetadata"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            metadata_self_relations_list_metadata: {
              connect: {
                metadata_id: $listId
              }
            }
          }
        }}
      >
        <Field name="id" alias="uuid" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateMetadataSelfRelationsElementMetadata: {
      uuid: string;
    }[];
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
