import React from "react";
import styled from "styled-components";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_6, GRAY_7, WHITE } from "constants/baseColor";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import defaultImage from "assets/images/cover.png";
import { configs } from "configs";
import { useAppDispatch } from "App/Store";

type Props = {
  isMinimize: boolean;
  playlist: TypeAudio[];
};

export const AudioPlaylist = ({ isMinimize, playlist }: Props) => {
  const dispatchApp = useAppDispatch();

  const onPlayTrack = (index: number) => {
    const targetTrack = playlist[index];
    dispatchApp([AudioPlayerActions.setAudioData(targetTrack), AudioPlayerActions.setAudioPlay("PLAY")]);
  };
  const onRemoveTrack = (index: number) => {
    dispatchApp(AudioPlayerActions.removeAudioInPlaylist(index));
  };
  const onClearPlaylist = () => {
    dispatchApp(AudioPlayerActions.clearAudioPlaylist());
  };
  return (
    <Layout isMinimize={isMinimize}>
      <div className="track-header">
        <span>재생 목록</span>
        <div className="trash-icon" title="재생목록 비우기" onClick={onClearPlaylist}>
          <TrashIcon />
        </div>
      </div>
      <div className="list-wrap">
        {playlist.map(({ uuid, title, artist, coverUrl }, index) => {
          const src = !coverUrl ? defaultImage : `${configs.urls.image}/${coverUrl}`;
          return (
            <ListItem key={`${uuid}-${index}`} onClick={() => onPlayTrack(index)}>
              <div className="cover-wrapper">
                <img src={src} alt="cover" />
              </div>
              <div className="info-area">
                <span className="title">{title}</span>
                <span className="artist">{!artist ? "" : artist}</span>
              </div>
              <div className="cancel-icon" onClick={e => e.stopPropagation()}>
                <CancelIcon onClick={() => onRemoveTrack(index)} />
              </div>
            </ListItem>
          );
        })}
      </div>
    </Layout>
  );
};

const Layout = styled.div<{ isMinimize: boolean }>`
  width: 360px;
  margin-left: 1rem;
  padding: 0 0.5rem;
  border-left: 1px solid ${GRAY_7};
  position: relative;
  display: ${props => (props.isMinimize ? "none" : "")};
  .track-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    color: #fff;
    padding: 0 0.8rem 0.8rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 1px solid ${GRAY_7};
    svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: ${GRAY_6};
      cursor: pointer;
      &:hover {
        fill: #fff;
      }
    }
  }
  .list-wrap {
    position: absolute;
    top: 4rem;
    right: 0;
    left: 0.5rem;
    bottom: 0;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .no-track {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
  }
`;

const ListItem = styled.li`
  position: relative;
  display: table;
  table-layout: fixed;
  overflow: hidden;
  height: 40px;
  padding: 8px;
  user-select: none;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    .cancel-icon {
      fill: ${GRAY_6};
    }
  }

  .cover-wrapper {
    display: table-cell;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    img {
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 0.5rem;
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  .info-area {
    display: table-cell;
    width: 100%;
    overflow: hidden;
    color: ${WHITE};
    span {
      overflow: hidden;
      --webkit-line-break: 1;
      --webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      display: -webkit-box;
    }
    .title {
      height: 22px;
      line-height: 22px;
      font-size: 12px;
    }
    .artist {
      height: 17px;
      line-height: 17px;
      font-size: 11px;
      color: ${GRAY_6};
    }
  }

  .cancel-icon {
    display: table-cell;
    width: 0.9rem;
    height: 0.9rem;
    overflow: hidden;
    vertical-align: middle;
    align-items: center;
    cursor: pointer;
    fill: transparent;
    svg {
      width: 0.9rem;
      height: 0.9rem;
      vertical-align: middle;
      &:hover {
        fill: #fff;
      }
    }
  }
`;
