import React, { useRef } from "react";
import ContentEditable from "react-contenteditable";
import styled from "styled-components";
import { PADDING_SMALL_PX, PADDING_X_LARGE_PX, BORDER_RADIUS_PX } from "constants/size";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { identity } from "utils/identity";

type Props = Readonly<{
  className?: string;
  value: string;
  isDisabled?: boolean;
  a11y?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
}>;

const Layout = styled(ContentEditable)`
  width: 100%;
  padding: ${PADDING_SMALL_PX} ${PADDING_X_LARGE_PX};
  background-color: transparent;
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-radius: ${BORDER_RADIUS_PX};
  outline: none;
  text-align: left;
  text-transform: none;
  word-break: break-all;
  white-space: pre-wrap;
  overflow: auto;
`;

export const Multiline = ({
  value,
  className,
  isDisabled,
  a11y,
  placeholder,
  onChange = identity,
  onFocus = identity,
  onBlur = identity
}: Props) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const getValue = () => inputRef.current!.innerText;
  const focus = () => onFocus(getValue());
  const blur = () => onBlur(getValue());
  const change = () => onChange(getValue());
  return (
    <Layout
      innerRef={inputRef}
      className={className}
      disabled={isDisabled}
      placeholder={placeholder}
      spellCheck={false}
      title={a11y}
      html={value}
      onChange={change}
      onFocus={focus}
      onBlur={blur}
    />
  );
};
