import React, { ReactElement } from "react";
import styled from "styled-components";
import { Style } from "App/Routes/GoodsDetail/Style";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { v4 as uuidv4 } from "uuid";
import { GoodsSubdataCategoryList, GoodsIPhoneModels, GoodsGalaxyModels } from "constants/GoodsSubdataOptionList";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { GoodsSubdata as TypeGoodsSubdata } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { Button } from "App/Atomics/Input/Button";

type Props = {
  readonly subdatas: TypeGoodsSubdata[];
};

export const GoodsSubdata = ({ subdatas = [] }: Props): ReactElement => {
  const onAddSubdata = () => {
    const tempId = uuidv4();
    GoodsDetailStore.dispatch(GoodsDetailActions.addSubdata(tempId));
  };
  const onUpdateField = (index: number, field: string) => {
    GoodsDetailStore.dispatch(GoodsDetailActions.updateSubdataCategory({ index, category: field }));
    GoodsDetailStore.dispatch(GoodsDetailActions.updateSubdataField({ index, field }));
  };

  const onUpdateValue = (index: number, value: string) =>
    GoodsDetailStore.dispatch(GoodsDetailActions.updateSubdataValue({ index, value }));
  const onRemoveSubdata = (index: number) => GoodsDetailStore.dispatch(GoodsDetailActions.removeSubdataField(index));

  const onAddSubdataList = (type: "iphone" | "galaxy" | "weight") => {
    const subdataList: TypeGoodsSubdata[] = [];
    switch (type) {
      case "iphone":
        for (const value of GoodsIPhoneModels) {
          const id = uuidv4();
          subdataList.push({
            id,
            category: "option",
            field: "option",
            value
          });
        }
        break;
      case "galaxy":
        for (const value of GoodsGalaxyModels) {
          const id = uuidv4();
          subdataList.push({
            id,
            category: "option",
            field: "model",
            value
          });
        }
        break;
      case "weight":
        const weightId = uuidv4();
        subdataList.push({
          id: weightId,
          category: "weight",
          field: "weight",
          value: ""
        });
        break;
    }
    GoodsDetailStore.dispatch(GoodsDetailActions.addSubdataList(subdataList));
    return;
  };

  return (
    <Style.Row>
      <Style.Caption>기타 정보</Style.Caption>
      <Style.RowContainer>
        <StyledButton color="primary" onClick={() => onAddSubdataList("weight")}>
          무게 추가
        </StyledButton>
        <StyledButton color="primary" onClick={() => onAddSubdataList("iphone")}>
          아이폰 기종 추가
        </StyledButton>
        <StyledButton color="primary" onClick={() => onAddSubdataList("galaxy")}>
          갤럭시 기종 추가
        </StyledButton>
      </Style.RowContainer>
      <Style.ColContainer>
        {subdatas.map((data, index) => {
          const defaultField = GoodsSubdataCategoryList.find(item => item.id === data.field);
          return (
            <GridGroup key={data.id}>
              <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveSubdata(index)}>
                -
              </Style.RoundButton>
              {/* <Style.Select
                optionList={GoodsSubdataCategoryList}
                placeholder="분류명"
                defaultValue={defaultCategory ? { id: defaultCategory.id, name: defaultCategory.name } : undefined}
                onBlur={info => info && onUpdateCategory(index, info.id)}
              /> */}
              <Style.Select
                optionList={GoodsSubdataCategoryList}
                placeholder="필드명"
                defaultValue={defaultField ? { id: defaultField.id, name: defaultField.name } : undefined}
                onBlur={info => info && onUpdateField(index, info.id)}
              />
              <Style.InputText style={{ marginTop: 0 }} defaultValue={data.value} onBlur={value => onUpdateValue(index, value)} />
            </GridGroup>
          );
        })}
        <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddSubdata}>
          +
        </Style.RoundButton>
      </Style.ColContainer>
    </Style.Row>
  );
};

const GridGroup = styled.div`
  display: grid;
  grid-template-columns: 2.6rem 7rem auto;
  grid-gap: 4px;

  & + & {
    margin-top: 4px;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 4px;
  & + & {
    margin-left: 4px;
  }
`;
