import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";
import { AppStore } from "App/Store";
import { SIGN, HOME, FORGET_PASSWORD, PROFILE, ERROR, SINGLE_ALBUM_CREATE, CHANGE_PASSWORD, SIGN_UP } from "constants/route";
import { AdminRoutes } from "./AdminRoutes";
import { Home } from "./Home";
import { AuthRoute } from "App/Atomics/AuthRoute";
import { ForgetPassword } from "./ForgetPassword";
import { Profile } from "./Profile";
import { SingleAlbumCreate } from "./SingleAlbumCreate";
import { ChangePassword } from "./ChangePassword";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { ErrorPage } from "./ErrorPage";

const RedirectToHome = () => <Redirect to={HOME} />;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RedirectToSignIn = () => {
  const { pathname, search } = window.location;
  const from = window.encodeURIComponent(`${pathname}${search}`);
  return <Redirect to={`${SIGN}?from=${from}`} />;
};

const isSignedIn = (store: AppStore) => !!store.UserToken.email;
const isUnsignedIn = (store: AppStore) => !store.UserToken.email;

export const Routes = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route path={HOME} component={Home} exact={true} />
      <Route path={ERROR} component={ErrorPage} exact={true} />
      {/* 회원시스템 관련 페이지 */}
      <AuthRoute path={FORGET_PASSWORD} component={ForgetPassword} fallback={RedirectToHome} exact={true} isAuthorized={isUnsignedIn} />
      <AuthRoute path={SIGN} component={SignIn} fallback={RedirectToHome} exact={true} isAuthorized={isUnsignedIn} />
      <AuthRoute path={SIGN_UP} component={SignUp} fallback={RedirectToHome} exact={true} isAuthorized={isUnsignedIn} />
      {/* 개인정보 관련 페이지 */}
      <AuthRoute path={PROFILE} component={Profile} fallback={RedirectToHome} exact={true} isAuthorized={isSignedIn} />
      <AuthRoute path={CHANGE_PASSWORD} component={ChangePassword} fallback={RedirectToHome} exact={true} isAuthorized={isSignedIn} />
      <AuthRoute
        path={SINGLE_ALBUM_CREATE}
        component={SingleAlbumCreate}
        fallback={RedirectToHome}
        exact={true}
        isAuthorized={isSignedIn}
      />
      {/* 어드민 관련 라우트 */}
      <AdminRoutes />
      <Redirect to={ERROR} />
    </Switch>
  </Suspense>
);
