import { LanguageLocale } from "./../../../../../GraphQL/Queries/Language/GetLanguageLocale";
import { createDuck } from "lib/store/v2";
import { Language } from "GraphQL/Queries/Language/GetLanguage";
import { Edge } from "models/Edge";

const createInitialState = () => ({
  languages: [] as Language[],
  edge: {} as Edge,
  languageLocales: [] as LanguageLocale[]
});

export const LanguageActions = createDuck({
  namespace: "Language",
  createInitialState,
  reducers: {
    setLanguage(state, languages: Language[]) {
      state.languages = languages;
    },
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    deleteLanguage(state, index: number) {
      state.languages.splice(index, 1);
    },
    setLanguageLocales(state, languageLocales: LanguageLocale[]) {
      state.languageLocales = languageLocales;
    },
    createLanguageLocale(state, languageLocale: LanguageLocale) {
      state.languageLocales.push(languageLocale);
    },
    updateLanguageLocale(
      state,
      { index, typeTitle, displayCode, value }: { index: number; typeTitle: string; displayCode: string; value: string }
    ) {
      state.languageLocales[index].typeTitle = typeTitle;
      state.languageLocales[index].displayCode = displayCode;
      state.languageLocales[index].value = value;
    },
    deleteLanguageLocale(state, index: number) {
      state.languageLocales.splice(index, 1);
    }
  }
});
