import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import { Collapsible } from "App/Atomics/Collapsible";
import { pixelize, UNIT, widthViewportize } from "constants/size";
import { WHITE } from "constants/baseColor";

type Props = Readonly<{
  as?: Parameters<typeof Collapsible>[0]["as"];
  isAllow?: boolean;
  isParent?: boolean;
  heading: ReactNode;
  children: ReactNode;
}>;

const Layout = styled.div`
  &[aria-hidden="true"] {
    cursor: not-allowed;

    button {
      cursor: not-allowed;
    }
  }

  & svg {
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
    top: 4px;
    position: relative;
    margin-right: ${pixelize(0.5 * UNIT)};
  }

  & > button {
    width: 100%;
    padding: ${pixelize(1.25 * UNIT)} ${widthViewportize(0.15 * UNIT)};
    text-align: left;
    opacity: 0.5;
    font-size: 1.1rem;
    transition: all 0.2s;

    &[data-open="true"] {
      position: relative;
      opacity: 1;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: inherit;
        height: 66px;
        opacity: 0.2;
        mix-blend-mode: soft-light;
        border-radius: 4px;
        box-shadow: 0 2px 5px 0 rgba(83, 97, 255, 0.1);
        background-color: #ffffff;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 66px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        background-color: ${WHITE};
      }
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const CollapsibleTab = ({ as, isAllow = true, isParent = false, heading, children }: Props) => {
  const [isOpen, setBeOpen] = useState(isParent);
  const toggle = () => {
    if (isAllow) {
      setBeOpen(isOpen => !isOpen);
    }
  };
  return (
    <Layout aria-hidden={!isAllow}>
      <button type="button" onClick={toggle} data-open={isOpen}>
        {heading}
      </button>
      <Collapsible as={as} className="collapsible" isOpen={isOpen}>
        {children}
      </Collapsible>
    </Layout>
  );
};
