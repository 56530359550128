/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id?: string;
}>;

export const GetPlaylistMetadataRelationCount = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_METADATA_RELATION_COUNT">
      <SelectionSet
        name="getCount"
        argument={{
          node: "metadata_playlist_relation",
          where: {
            metadata_playlist_relation: {
              playlist__some: {
                playlist_id: $playlistId
              }
            }
          }
        }}
      >
        <Field name="count" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    getCount: {
      count: number;
    };
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }

  return data!.getCount.count;
};
