/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  metadataId: string;
  category: string;
  field: string;
  value: string;
  validCheck?: ValidType;
}>;

export const CreateMetadataSubdataUnique = async (option: Option) => {
  const $metadataId = <Variable name="metadataId" scalar={<IdScalar />} value={option.metadataId} />;
  const $category = <Variable name="category" scalar={<StringScalar isNullable={false} />} value={option.category} />;
  const $field = <Variable name="field" scalar={<StringScalar isNullable={false} />} value={option.field} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_SUBDATA_UNIQUE">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataSubdataUnique"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            _onlySearch: true,
            metadata_subdata_unique: {
              create: [
                {
                  type_subdata_category: $category,
                  type_subdata_field: $field,
                  value: $value,
                  valid_check: $validCheck
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <SelectionSet name="metadata_subdata_unique">
          <Field name="id" alias="uuid" />
          <Field name="type_subdata_category" alias="category" />
          <Field name="type_subdata_field" alias="field" />
          <Field name="value" />
          <Field name="valid_check" alias="validCheck" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createMetadataSubdataUnique: {
      metadataId: string;
      metadata_subdata_unique: {
        uuid: string;
        category: string;
        field: string;
        value: string;
        validCheck: ValidType;
      }[];
    };
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
