import React, { ChangeEvent, useState, useEffect } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  MARGING_LARGE,
  PADDING_LARGE_PX,
  PADDING_X_LARGE_PX,
  BORDER_RADIUS_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { GRAY_0 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { gql } from "lib/gql-tag";
import { Progress } from "App/Atomics/Progress";
import { clients } from "utils/clients";
import axios from "axios";

type Props = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  metadataId: string;
  toClose: () => void;
}>;

type FileInfo = Readonly<{
  ext: string;
  accept: string;
}>;

type TrackData = Readonly<{
  name: string;
  file: File;
}>;

type LoadedData = {
  loaded: number | undefined;
  total: number | undefined;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 20)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RowGroup = styled.div`
  width: 100%;
  padding: ${PADDING_LARGE_PX} 0px;

  & > h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }

  & > input {
    width: 100%;
    background-color: transparent;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;

  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

const SEARCH_COMPANY_ID = gql`
  query SEARCH_COMPANY_ID($id: ID) {
    metadata_company_relation(where: { metadata__some: { metadata_id: $id } }) {
      company {
        companyId: company_id
      }
    }
  }
`;

const S3_UPLOAD_REQUIRED = gql`
  mutation S3_UPLOAD_REQUIRED($filename: String!, $companyId: ID) {
    singleUpload(where: { filename: $filename, action: UPDATE, company_id: $companyId }) {
      key
      url
      bucket
      X_Amz_Date
      X_Amz_Signature
      X_Amz_Credential
      X_Amz_Algorithm
      Policy
      invalidateToken
    }
  }
`;

const CREATE_INVALIDATION = gql`
  mutation CREATE_INVALIDATION($token: String!) {
    createInvalidation(where: { invalidateToken: $token, mediaType: track })
  }
`;

const CREATE_ACCESS_QUERY = (table: string = "metadata") => gql`
  mutation CREATE_ACCESS_QUERY($id: String) {
    createAccess_record(data: { target_id: $id, target_table: ${table} }) {
      id
      target_id
      target_table
    }
  }
`;

const DELETE_ACCESS_QUERY = gql`
  mutation DELETE_ACCESS_QUERY($id: ID) {
    deleteAccess_record(where: { id: $id })
  }
`;

const UPDATE_METADATA_URL = gql`
  mutation UPDATE_METADATA_URL($id: UUID, $url: String) {
    updateMetadata_url(where: { id: $id }, data: { url: $url }) {
      id
    }
  }
`;

const albumFormData = (singleUpload: any, file: File) => {
  const formData = new FormData();
  formData.append("key", `${singleUpload.key}`);
  formData.append("Policy", singleUpload.Policy);
  formData.append("X-Amz-Credential", singleUpload.X_Amz_Credential);
  formData.append("X-Amz-Date", singleUpload.X_Amz_Date);
  formData.append("X-Amz-Signature", singleUpload.X_Amz_Signature);
  formData.append("X-Amz-Algorithm", singleUpload.X_Amz_Algorithm);
  formData.append("file", file);

  return formData;
};

const getFileInfo = (typeUrl: string) => {
  switch (typeUrl) {
    case "mp3high":
      return { ext: "mp3", accept: "audio/mpeg" } as FileInfo;
    case "aac":
      return { ext: "m4a", accept: "audio/x-m4a" } as FileInfo;
    case "flac":
      return { ext: "flac", accept: "audio/flac" } as FileInfo;
    default:
      return { ext: "", accept: "" };
  }
};

export const EditFileModal = ({ id, typeUrl, url, metadataId, toClose }: Props) => {
  const [trackData, setTrackData] = useState({} as TrackData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeStamp, setTimeStamp] = useState(0);
  const [loadedData, setLoadedData] = useState({
    loaded: undefined,
    total: undefined
  } as LoadedData);
  const [failed, setFailed] = useState(false);
  const fileInfo: FileInfo = getFileInfo(typeUrl);

  useEffect(() => {}, [trackData, setTimeStamp]);

  const setTrackFile = (event: ChangeEvent<HTMLInputElement>, ext: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      setTrackData({
        name: file.files![0].name,
        file: file.files![0]
      } as TrackData);
    };
  };

  const editTrackFile = async () => {
    try {
      const { data: companyData, errors: getCompanyError } = await clients.metadata.query(SEARCH_COMPANY_ID, { id: metadataId });

      if (getCompanyError) {
        throw getCompanyError;
      }

      const companyId = companyData.metadata_company_relation[0].company[0].companyId;
      const keyUrl = url.split("/");

      const { data, errors } = await clients.access.mutation(S3_UPLOAD_REQUIRED, {
        filename: keyUrl.splice(2).join("/"),
        companyId: companyId
      });

      if (errors) {
        throw errors;
      }

      const uploadData = data.singleUpload;

      if (uploadData) {
        await axios({
          method: "post",
          url: uploadData.url,
          data: albumFormData(uploadData, trackData.file),
          onUploadProgress: progressEvent => {
            loadedData.loaded = progressEvent.loaded;
            loadedData.total = progressEvent.total;
            setTimeStamp(progressEvent.timeStamp);
            setLoadedData(loadedData);
          },
          headers: {
            "Content-Type": fileInfo.accept
          }
        })
          .then(async () => {
            const { data: invalidData, errors } = await clients.access.mutation(CREATE_INVALIDATION, {
              token: data.singleUpload.invalidateToken
            });

            if (errors) {
              throw errors;
            }

            if (invalidData) {
              const accessRecord = await clients.access.mutation(CREATE_ACCESS_QUERY(), {
                id: metadataId
              });

              await clients.metadata
                .mutation(UPDATE_METADATA_URL, {
                  id,
                  url: data.singleUpload.key
                })
                .then(() => clients.access.mutation(DELETE_ACCESS_QUERY, { id: accessRecord.data.createAccess_record.id }));

              alert("음원 변경에 성공했습니다.");
              toClose();
            } else {
              alert("음원 변경에 실패했습니다.");
            }
          })
          .catch(e => {
            throw e;
          });
      }
    } catch (e) {
      setFailed(true);
      alert("서버에서 에러가 발생했습니다.");
    }
  };

  return (
    <Layout>
      <RowGroup>
        <h2>음원 파일 편집</h2>
      </RowGroup>
      <RowGroup>
        <input type="file" accept={fileInfo.accept} onChange={event => setTrackFile(event, fileInfo.ext)} />
      </RowGroup>
      <RowGroup>
        <div style={{ textAlign: "left", whiteSpace: "normal" }}>
          <b>현재 파일: </b> {trackData && trackData.name ? trackData.name : url}
        </div>
      </RowGroup>
      <RowGroup>
        <Progress
          style={{ width: "100%" }}
          percent={loadedData.loaded && loadedData.total ? (loadedData.loaded / loadedData.total) * 100 : 0}
        />
      </RowGroup>
      <ButtonGroup>
        <Input.Button
          color="danger"
          className="cancelButton"
          disabled={!failed && loadedData.loaded !== undefined && loadedData.total !== undefined && loadedData.loaded === loadedData.total}
          onClick={toClose}
        >
          닫기
        </Input.Button>
        <Input.Button color="primary" disabled={!(trackData && trackData.name)} onClick={editTrackFile}>
          수정
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
