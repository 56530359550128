import React, { useState } from "react";
import styled from "styled-components";

export type RangeProps = {
  id: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (val: number) => void;
};

export const Range = ({ id, min, max, step, value, onChange }: RangeProps) => {
  const [val, setVal] = useState<number>(value);
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(+e.target.value);
    onChange(+e.target.value);
  };
  return (
    <Layout>
      <input id={id} type="range" name={id} min={min} max={max} step={step} value={val} onChange={onChangeValue} />
    </Layout>
  );
};

const Layout = styled.form`
  padding: 0 1.5em;
`;
