import { Helmet } from "App/Atomics/Helmet";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import React, { useEffect } from "react";
import styled from "styled-components";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { ProductForm } from "./ProductForm";
import { ProductCreateStore } from "./Store";
import { ProductCreateActions } from "./Store/ProductCreate";
import { Time } from "lib/time";
import { useScrollToTop } from "../GoodsCreate/Hooks/useScrollToTop";

const ProductCreateLayout = () => {
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  useScrollToTop();
  useEffect(() => {
    const date = Time().format("YYYY-MM-DD");
    ProductCreateStore.dispatch([ProductCreateActions.clear(), ProductCreateActions.setCreatedAt(date)]);
  }, []);
  return (
    <Layout>
      <LeftSideBar />
      <TopbarTemplate openSidebar={openSidebar}>{}</TopbarTemplate>
      <ProductForm />
    </Layout>
  );
};

export const ProductCreate = () => (
  <>
    <Helmet title="상품 등록" />
    <ProductCreateLayout />
  </>
);

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`;
