import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_LARGE_PX, PADDING_X_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { GRAY_1 } from "constants/baseColor";
import { useAlbumDetailStore } from "../../Store";
import { StructureActions } from "../../Store/Structure";
import { CreateAccessRecord, DeleteAccessRecord, UpdateAlbum } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";

type Props = Readonly<{
  index: number;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 16)};
  padding: ${PADDING_XX_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  margin-bottom: ${MARGING_LARGE_PX};

  h4 {
    margin-bottom: ${MARGING_SMALL_PX};
  }

  input[type="text"] {
    width: 100%;
    background-color: ${GRAY_1};
    padding: ${PADDING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

export const EditGroupTitleModal = ({ index, toClose }: Props) => {
  const [{ id, title }, dispatch] = useAlbumDetailStore(store => ({
    id: store.Structure.structures[index].metadata[0].metadataId,
    title: store.Structure.structures[index].metadata[0].title
  }));
  const [inputTitle, setInputTitle] = useState<string>(title);
  const toSave = async () => {
    try {
      const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
      if (accessData) {
        try {
          const { errors } = await UpdateAlbum({ metadataId: id, title: inputTitle });
          if (errors) {
            console.log(errors);
            window.alert("그룹명 변경에 실패하였습니다.");
          }
          dispatch(StructureActions.editAlbumGroupTitleByIndex({ index, title: inputTitle }));
        } catch (err) {
          console.log(err);
          window.alert("서버에서 에러가 발생하였습니다.");
        }
        await DeleteAccessRecord({ id: accessData.createAccess.id });
        toClose();
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };
  return (
    <Layout>
      <h3>그룹명 변경</h3>
      <Divider />
      <RowGroup>
        <Input.Text defaultValue={title} placeholder="그룹명을 입력해주세요." onBlur={str => setInputTitle(str)} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button onClick={toSave}>입력</Input.Button>
        <Input.Button onClick={toClose}>취소</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
