import Time from "dayjs";

import { USER_INFO } from "constants/storageKey";
import { UserRole } from "constants/UserRole";
import jwt_decode from "jwt-decode";

type Tokens = {
  refreshToken: string;
  accessToken: string;
  userToken: string;
  payloadToken: string;
};

type UserInfo = Tokens & { email: string };

export type AllowTables = { table: string; allow: string }[];

const parseUserToken = (userToken: string) => {
  try {
    const { email, exp, name, tier } = jwt_decode(userToken);
    const expiresAt = Time(exp * 1000);
    const currentTime = Time();
    if (expiresAt.isBefore(currentTime)) {
      return null;
    }
    return { email, name, role: tier as UserRole, expiresAt };
  } catch {
    return null;
  }
};

const parsePayloadToken = (payloadToken: string): AllowTables => {
  try {
    const { allowTables } = JSON.parse(window.atob(payloadToken.split(".")[1]));
    return allowTables;
  } catch {
    return [];
  }
};

const getRole = (userToken: string) => {
  try {
    const { tier } = JSON.parse(window.atob(userToken.split(".")[1]));
    return tier as UserRole;
  } catch {
    return null;
  }
};

const load = (token = window.localStorage.getItem(USER_INFO)) => {
  try {
    const { email, refreshToken, accessToken, userToken, payloadToken } = JSON.parse(token!);
    const parsedUserToken = parseUserToken(userToken);
    if (!parsedUserToken) {
      return null;
    }
    const allowTables = parsePayloadToken(payloadToken);
    if (!allowTables) {
      return null;
    }
    const { name, role, expiresAt } = parsedUserToken;
    return { email, refreshToken, accessToken, payloadToken, allowTables, userToken, name, role, expiresAt };
  } catch {
    return null;
  }
};

export const UserInfo = {
  getRole,
  load,
  clear() {
    window.localStorage.removeItem(USER_INFO);
  },
  save(userInfo: UserInfo) {
    window.localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
  },
  saveTokens(tokens: Tokens) {
    const { email, expiresAt } = parseUserToken(tokens.userToken)!;
    const userInfo = { ...tokens, email };
    const token = JSON.stringify(userInfo);
    window.localStorage.setItem(USER_INFO, token);
    return expiresAt;
  }
};
