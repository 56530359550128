import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { CheckBox } from "App/Atomics/Input/CheckBox";
import styled from "styled-components";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";

type Props = {
  readonly isCollection: boolean;
};

export const GoodsIsCollection = ({ isCollection }: Props): ReactElement => {
  const onToggle = () => GoodsDetailStore.dispatch(GoodsDetailActions.toggleIsCollection());
  return (
    <Style.Row>
      <Style.Caption>컬렉션</Style.Caption>
      <Style.SubCaption>* 드래곤볼 리스트(컬렉션 페이지)에 노출됩니다.</Style.SubCaption>
      <CollectionCheck>
        <CheckBox id={`checkbox-collection`} isChecked={isCollection} onToggle={onToggle} />
      </CollectionCheck>
    </Style.Row>
  );
};

const CollectionCheck = styled.div`
  margin-top: 8px;
  margin-right: auto;
`;
