export const TonalityOptionList = [
  { id: "a-flat minor", name: "a-flat minor" },
  { id: "a minor", name: "a minor" },
  { id: "a-sharp minor", name: "a-sharp minor" },
  { id: "A-flat major", name: "A-flat major" },
  { id: "A major", name: "A major" },
  { id: "A-sharp major", name: "A-sharp major" },
  { id: "b-flat minor", name: "b-flat minor" },
  { id: "b minor", name: "b minor" },
  { id: "b-sharp minor", name: "b-sharp minor" },
  { id: "B-flat major", name: "B-flat major" },
  { id: "B major", name: "B major" },
  { id: "B-sharp major", name: "B-sharp major" },
  { id: "c-flat minor", name: "c-flat minor" },
  { id: "c minor", name: "c minor" },
  { id: "c-sharp minor", name: "c-sharp minor" },
  { id: "C-flat major", name: "C-flat major" },
  { id: "C major", name: "C major" },
  { id: "C-sharp major", name: "C-sharp major" },
  { id: "d-flat minor", name: "d-flat minor" },
  { id: "d minor", name: "d minor" },
  { id: "d-sharp minor", name: "d-sharp minor" },
  { id: "D-flat major", name: "D-flat major" },
  { id: "D major", name: "D major" },
  { id: "D-sharp major", name: "D-sharp major" },
  { id: "e-flat minor", name: "e-flat minor" },
  { id: "e minor", name: "e minor" },
  { id: "e-sharp minor", name: "e-sharp minor" },
  { id: "E-flat major", name: "E-flat major" },
  { id: "E major", name: "E major" },
  { id: "E-sharp major", name: "E-sharp major" },
  { id: "f-flat minor", name: "f-flat minor" },
  { id: "f minor", name: "f minor" },
  { id: "f-sharp minor", name: "f-sharp minor" },
  { id: "F-flat major", name: "F-flat major" },
  { id: "F major", name: "F major" },
  { id: "F-sharp major", name: "F-sharp major" },
  { id: "g-flat minor", name: "g-flat minor" },
  { id: "g minor", name: "g minor" },
  { id: "g-sharp minor", name: "g-sharp minor" },
  { id: "G-flat major", name: "G-flat major" },
  { id: "G major", name: "G major" },
  { id: "G-sharp major", name: "G-sharp major" },
  { id: "Polytonality", name: "Polytonality" },
  { id: "Bitonality", name: "Bitonality" },
  { id: "Atonality", name: "Atonality" }
];
