type Subheading = Record<string, Record<string, string>>;
export const subheadKeyValue = {
  Metadata: {
    metadataId: "ID",
    title: "Title",
    no: "No",
    typeMetadataSubclass: "Type",
    validCheck: "Validate"
  },
  Title: {
    value: "Language | Type | Content"
  },
  Artist: {
    artist: "Artist | Role"
  },
  Url: {
    url: "URL Type | URL | Validate"
  },
  Company: {
    company: "Company"
  },
  LicenseExtra: {
    data: "Record Year | Publish Year | First Edition | Place | License"
  },
  Work: {
    work: "Work"
  },
  Genre: {
    genre: "Genre",
    mood: "Mood"
  },
  Info: {
    value: "Grade | Channel | Duration | Bitrate | Tempo | Tonality | VoiceGender | TimeSignature | ExtraData"
  }
} as Subheading;
