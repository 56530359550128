/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar, StringScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
  birthDate?: string;
  deathDate?: string;
  birthPlace?: string;
  deathPlace?: string;
}>;

export const CreateArtistHistory = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $birthDate = <Variable name="birth_date" scalar={<StringScalar isNullable />} value={option.birthDate} />;
  const $deathDate = <Variable name="death_date" scalar={<StringScalar isNullable />} value={option.deathDate} />;
  const $birthPlace = <Variable name="birth_place" scalar={<StringScalar isNullable />} value={option.birthPlace} />;
  const $deathPlace = <Variable name="death_place" scalar={<StringScalar isNullable />} value={option.deathPlace} />;

  const { query, variables } = render(
    <Mutation operationName="CREATE_ARTIST_HISTORY">
      <SelectionSet
        name="updateArtist"
        alias="createArtistHistory"
        argument={{
          where: {
            artist_id: $id
          },
          data: {
            _onlySearch: true,
            artist_history: {
              create: {
                birth_date: $birthDate,
                birth_place: $birthPlace,
                death_date: $deathDate,
                death_place: $deathPlace
              }
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <SelectionSet name="artist_history">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createArtistHistory: {
      id: string;
      artist_history: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
