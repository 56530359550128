/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field } from "graphql-jsx";
import { clients } from "utils/clients";

export const GetCountMetadata = async () => {
  const { query, variables } = render(
    <Query operationName="GET_COUNT_METADATA">
      <SelectionSet
        name="getCount"
        alias="goods"
        argument={{
          node: "goods"
        }}
      >
        <Field name="count" />
      </SelectionSet>
      <SelectionSet
        name="getCount"
        alias="metadata"
        argument={{
          node: "metadata",
          where: {
            metadata: {
              type_metadata_class__in: ["record", "theater"],
              type_metadata_subclass__in: ["track", "effect"]
            }
          }
        }}
      >
        <Field name="count" />
      </SelectionSet>
      <SelectionSet
        name="getCount"
        alias="work"
        argument={{
          node: "metadata",
          where: {
            metadata: {
              type_metadata_class: "work",
              type_metadata_subclass__in: ["track"]
            }
          }
        }}
      >
        <Field name="count" />
      </SelectionSet>
      <SelectionSet
        name="getCount"
        alias="podcast"
        argument={{
          node: "metadata",
          where: {
            metadata: {
              type_metadata_class: "podcast"
            }
          }
        }}
      >
        <Field name="count" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    goods: {
      count: number;
    };
    metadata: {
      count: number;
    };
    work: {
      count: number;
    };
    podcast: {
      count: number;
    };
  }>;
  const { data, errors } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    return { goods: 0, metadata: 0, work: 0, podcast: 0 };
  }
  const { goods, metadata, work, podcast } = data;
  return { goods: goods.count, metadata: metadata.count, work: work.count, podcast: podcast.count };
};
