/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, ListScalar, EnumScalar, IdScalar } from "graphql-jsx";

import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

export const findArtistRenewal = async (keyword: string, typeArtistClass?: string, typeArtistClassNot?: string) => {
  let $id = undefined;
  if (keyword.startsWith("$")) {
    const searchId = keyword.match(/\$([0-9]+)/)?.[1];
    if (searchId) {
      $id = <Variable name="id" scalar={<IdScalar />} value={searchId} />;
    }
  }
  const $typeArtistClass = <Variable name="type_artist_class" scalar={<StringScalar isNullable />} value={typeArtistClass} />;
  const $typeArtistClassNot = <Variable name="type_artist_class__not" scalar={<StringScalar isNullable />} value={typeArtistClassNot} />;
  const $v3 = <ValidTypeScalar value={ValidType.V3} />;
  const $keywordList = keyword.split(/[,.:;+\s]+/g).filter(word => !!word.length);
  const { query, variables } = render(
    <Query operationName="ARTIST_SEARCHED_LIST">
      <SelectionSet
        name="artist_title"
        alias="list"
        argument={{
          group: true,
          first: 60,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Artist_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            ...($id
              ? {
                  artist__some: {
                    artist_id: $id,
                    type_artist_class: $typeArtistClass,
                    type_artist_class__not: $typeArtistClassNot,
                    valid_check: $v3
                  }
                }
              : {
                  artist__some: {
                    artist_id: $id,
                    type_artist_class: $typeArtistClass,
                    type_artist_class__not: $typeArtistClassNot,
                    valid_check: $v3
                  },
                  type_artist_title__in: ["search_artist", "name"],
                  AND: [
                    ...$keywordList.map(word => ({
                      tsvec_words__search: word
                    }))
                  ]
                })
          }
        }}
      >
        <Field name="artist_id" />
        <SelectionSet name="artist">
          <Field name="artist_id" alias="id" />
          <Field name="name" />
          <Field name="type_artist_class" alias="type" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: ReadonlyArray<{
      artist_id: string;
      artist: {
        id: string;
        name: string;
        type: string;
      }[];
    }>;
  }>;
  const { data, errors } = await clients.artist.request<Data>(query, variables);
  if (errors || !data) {
    return [];
  }
  const result = data.list.map(({ artist }) => ({ ...artist[0] })) ?? [];
  return result;
};
