/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
  roleId: string;
  order: number;
}>;

export const CreateArtistRole = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $roleId = <Variable name="role_id" scalar={<IdScalar />} value={option.roleId} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_ARTIST_ROLE">
      <SelectionSet
        name="updateArtist"
        alias="createArtistRole"
        argument={{
          where: {
            artist_id: $id
          },
          data: {
            _onlySearch: true,
            artist_role_relation: {
              create: [{ role: { connect: { role_id: $roleId } }, exposure_order: $order }]
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <SelectionSet name="artist_role_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createArtistRole: {
      id: string;
      artist_role_relation: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
