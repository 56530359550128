/** @jsx jsx */

import { ProductOrderByInput } from "constants/ProductOrderByInput";
import { jsx, EnumScalar } from "graphql-jsx";

type Props = Readonly<{
  value?: ProductOrderByInput;
  isNullable?: boolean;
}>;

const items = [ProductOrderByInput.product_id__ASC, ProductOrderByInput.product_id__DESC];

export const ProductOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="CommodityOrderByInput" value={value} isNullable={isNullable} items={items} />
);
