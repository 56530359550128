import React from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { Tag } from "App/Atomics/Tag";
import { pixelize, UNIT } from "constants/size";

type Genre = {
  genre: any[];
};

type GenreInput = Genre & {
  id: string;
};

type GenreProps = Readonly<{
  index: number;
  genres: any[];
}>;
export const Genre = ({ genres }: GenreProps) => {
  if (!genres?.length) {
    return <Table.Data>{}</Table.Data>;
  }

  const filteredGenres = genres.filter((genre: Genre) => genre.genre[0]?.typeKind === "genre");

  return (
    <Table.Data>
      <GenreTagGroup>
        {filteredGenres.map((genre: GenreInput, index) => (
          <Tag className="tag" isWide style={{ width: "max-content" }} key={index}>
            {genre.genre[0].name}
          </Tag>
        ))}
      </GenreTagGroup>
    </Table.Data>
  );
};

export const Mood = ({ genres }: GenreProps) => {
  if (!genres?.length) {
    return <Table.Data>{}</Table.Data>;
  }

  const filteredMoods = genres.filter((genre: Genre) => genre.genre[0]?.typeKind === "mood");

  return (
    <Table.Data>
      <GenreTagGroup>
        {filteredMoods.map((genre: GenreInput, index) => (
          <Tag className="tag" isWide style={{ width: "max-content" }} key={index}>
            {genre.genre[0].name}
          </Tag>
        ))}
      </GenreTagGroup>
    </Table.Data>
  );
};

const GenreTagGroup = styled.div`
  display: flex;
  .tag {
    margin-right: ${pixelize(UNIT * 0.4)};
  }
`;
