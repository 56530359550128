import itiriri from "itiriri";
import React, { useState } from "react";
import styled from "styled-components";

import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import {
  MARGING_LARGE_PX,
  MARGING_XX_LARGE_PX,
  PADDING_XX_LARGE_PX,
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  BORDER_RADIUS_PX
} from "constants/size";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { FileUploadModal } from "../../Modals/FileUploadModal";
import { useAlbumStore } from "../../Store";
import { AlbumActions, Track, Album, FileMode } from "../../Store/Album";
import { WHITE } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { YtvUploadModal } from "../../Modals/YtvUploadModal";

const TrackInfoItem = (props: { trackList: Track[] }) => {
  const elements = itiriri(props.trackList)
    .map(track => {
      const artist = track.trackArtist
        ? itiriri(track.trackArtist)
            .toArray(artist => `${artist.artist_name}`)
            .join(", ")
        : "";

      return `${track.no}. ${artist} - ${track.title}`;
    })
    .toArray();

  return (
    <>
      <h3>Track List.</h3>
      {elements.map((element, index) => (
        <Item key={index}>
          <span>{element}</span>
          {props.trackList[index].trackTitle && <Description>{props.trackList[index].trackTitle.value}</Description>}
        </Item>
      ))}
    </>
  );
};

export const SubmitForm = () => {
  const [{ album, trackList, fileMode, isEffect }, dispatch] = useAlbumStore(store => ({
    album: store.AlbumCreate.album,
    trackList: store.AlbumCreate.trackList,
    fileMode: store.AlbumCreate.fileMode,
    isEffect: store.AlbumCreate.isEffect
  }));
  const [isDisabled, setIsDisabled] = useState(false);
  const uploadModal = useToggle();
  const ytvUploadModal = useToggle();
  const onPrevForm = () => dispatch(AlbumActions.setPageState("TRACK"));

  const onCreateAlbum = async () => {
    if (album.albumUrl && album.albumUrl.file) {
      dispatch(
        AlbumActions.setAlbumUrl({
          file: album.albumUrl.file,
          value: album.albumUrl.data,
          ext: album.albumUrl.ext,
          name: `${album.no}/cover/album_cover.${album.albumUrl.ext}`,
          valueType: album.albumUrl.typeUrl
        })
      );
    }
    itiriri(trackList).forEach(track => {
      track.trackUrl &&
        track.trackUrl.trackMp3 &&
        dispatch(
          AlbumActions.setTrackUrl({
            no: track.no,
            fileType: track.trackUrl.trackMp3.typeUrl,
            url: `${album.no}/${track.trackUrl.trackMp3.typeUrl}/${track.no}.${track.trackUrl.trackMp3.ext}`
          })
        );

      track.trackUrl &&
        track.trackUrl.trackAac &&
        dispatch(
          AlbumActions.setTrackUrl({
            no: track.no,
            fileType: track.trackUrl.trackAac.typeUrl,
            url: `${album.no}/${track.trackUrl.trackAac.typeUrl}/${track.no}.${track.trackUrl.trackAac.ext}`
          })
        );

      track.trackUrl &&
        track.trackUrl.trackFlac &&
        dispatch(
          AlbumActions.setTrackUrl({
            no: track.no,
            fileType: track.trackUrl.trackFlac.typeUrl,
            url: `${album.no}/${track.trackUrl.trackFlac.typeUrl}/${track.no}.${track.trackUrl.trackFlac.ext}`
          })
        );
      track.trackUrl &&
        track.trackUrl.trackWav &&
        dispatch(
          AlbumActions.setTrackUrl({
            no: track.no,
            fileType: track.trackUrl.trackWav.typeUrl,
            url: `${album.no}/${track.trackUrl.trackWav.typeUrl}/${track.no}.${track.trackUrl.trackWav.ext}`
          })
        );
      track.trackUrl &&
        track.trackUrl.trackTxt &&
        dispatch(
          AlbumActions.setTrackUrl({
            no: track.no,
            fileType: track.trackUrl.trackTxt.typeUrl,
            url: `${album.no}/${track.trackUrl.trackTxt.typeUrl}/${track.no}.${track.trackUrl.trackTxt.ext}`
          })
        );
      track.trackUrl &&
        track.trackUrl.trackZip &&
        dispatch(
          AlbumActions.setTrackUrl({
            no: track.no,
            fileType: track.trackUrl.trackZip.typeUrl,
            url: `${album.no}/${track.trackUrl.trackZip.typeUrl}/${track.no}.${track.trackUrl.trackZip.ext}`
          })
        );
    });
    uploadModal.on();
  };

  return (
    <Layout>
      <h2>앨범 등록정보 확인</h2>
      <Divider />
      <AlbumInfoArticle>
        {album.albumUrl && album.albumUrl.data ? <img alt="" src={album.albumUrl.data} /> : <NoImage>NO IMAGE</NoImage>}
        <SpanGroup>
          <span>
            <b>Serial No.</b>
            <br />
            {album.no && album.no}
          </span>
          <span>
            <b>Company.</b>
            <br />
            {album.albumCompany && album.albumCompany.name}
          </span>
          <span>
            <b>Artist.</b>
            <br />
            {album.albumArtist && album.albumArtist.map(artist => artist.artist_name).join(", ")}
          </span>
          <span>
            <b>Album Title.</b>
            <br />
            {album.title && album.title}
          </span>
        </SpanGroup>
      </AlbumInfoArticle>
      <TrackInfoArticle>
        <TrackInfoItem trackList={trackList} />
      </TrackInfoArticle>
      <ButtonGroup>
        <Input.Button color="danger" isFill={false} onClick={onPrevForm}>
          {`이전 (${!isEffect ? "트랙" : "효과음"} 정보 입력)`}
        </Input.Button>
        <Input.Button
          color="danger"
          isFill
          disabled={isDisabled}
          onClick={() => {
            setIsDisabled(prev => !prev);
            fileMode === FileMode.URL ? ytvUploadModal.on() : onCreateAlbum();
          }}
        >
          앨범 생성
        </Input.Button>
      </ButtonGroup>

      <Modal isOpen={uploadModal.isToggled}>
        <FileUploadModal
          album={album}
          trackList={trackList}
          isEffect={isEffect}
          onClick={() => {
            uploadModal.off();
            dispatch([AlbumActions.setAlbum({} as Album), AlbumActions.clearTrack(), AlbumActions.setPageState("ALBUM")]);
            setIsDisabled(prev => !prev);
          }}
        />
      </Modal>
      <Modal isOpen={ytvUploadModal.isToggled}>
        <YtvUploadModal
          isEffect={isEffect}
          toClose={fail => {
            ytvUploadModal.off();
            if (fail) {
              dispatch(AlbumActions.setPageState("ALBUM"));
            } else {
              window.location.reload();
            }
            return;
          }}
        />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;

  width: 80%;
  height: 100%;
  padding: ${PADDING_XX_LARGE_PX};

  h2 {
    text-align: left;
    padding: 0px ${PADDING_X_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  .prevButton {
    margin-right: auto;
    margin-top: auto;
  }
`;

const AlbumInfoArticle = styled.article`
  display: flex;
  flex-direction: row;

  padding: ${PADDING_XX_LARGE_PX};
  margin-bottom: ${MARGING_XX_LARGE_PX};
  background-color: ${WHITE};
  border: 1px solid #c9c9c9;
  border-radius: ${BORDER_RADIUS_PX};

  img {
    width: ${pixelize(UNIT * 15)};
    height: ${pixelize(UNIT * 15)};
  }

  span + span {
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const SpanGroup = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${PADDING_X_LARGE_PX};
  text-align: left;
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 15)};
  height: ${pixelize(UNIT * 15)};
  margin-right: ${MARGING_XX_LARGE_PX};
  border: 2px solid ${DEFAULT_BORDER_COLOR};
  font-size: 1.5rem;
  font-weight: bold;
`;

const TrackInfoArticle = styled.article`
  display: flex;
  flex-direction: column;
  text-align: left;

  h3 {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  margin-top: auto;

  & > button {
    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }
`;

const Item = styled.div`
  padding: ${PADDING_X_LARGE_PX};
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  border-top-left-radius: ${BORDER_RADIUS_PX};
  border-top-right-radius: ${BORDER_RADIUS_PX};
  background-color: ${WHITE};

  & + & {
    border-top: none;
  }

  &:last-child {
    border-bottom-left-radius: ${BORDER_RADIUS_PX};
    border-bottom-right-radius: ${BORDER_RADIUS_PX};
  }
`;

const Description = styled.div`
  white-space: pre;
  word-break: break-all;
`;
