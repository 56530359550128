import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useAppStore } from "App/Store";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { TableTemplate } from "App/Templates/TableTemplate";
import { HEADER_HEIGHT_PX, MARGING_LARGE_PX } from "constants/size";
import { usePlaylistsStore } from "../Store";
import { DataTemplate } from "./DataTemplate";
import { useQueryParams } from "lib/use-query-params";
import { Pagination } from "App/Molecules/Pagination";
import { UserRole } from "constants/UserRole";
import { PlaylistsActions } from "../Store/Playlist";
import { createHashGenerator } from "lib/createHashGenerator";
import { PLAYLIST } from "constants/route";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { useAsyncEffect } from "lib/use-async-effect";
import { LoadingAction } from "App/Store/Loading";
import { SearchForm } from "App/Organisms/SearchForm";
import { ReactComponent as ConversationIcon } from "assets/icons/conversation.svg";
import { ReactComponent as ExcelIcon } from "assets/icons/csv-3.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as MusicIcon } from "assets/icons/music.svg";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import uuidv4 from "uuid/v4";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { SelectCsvTypeModal } from "./Modals/SelectCsvTypeModal";
import { Loading } from "App/Atomics/Loading";
import { SelectMenu } from "App/Molecules/SelectMenu";
import { Confirm } from "App/Molecules/Confirm";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { GetMetadataPlaylistRelationId, UpdateMetadataPlaylistRelation, UpdatePlaylist } from "GraphQL/Queries/Playlist";
import { ValidType } from "constants/ValidType";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { KeyInfo } from "lib/key-info";
import { allowPlaylistCreate } from "App/Routes/AdminRoutes/allowTables";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;

  table {
    margin-top: ${HEADER_HEIGHT_PX};

    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }
  }
  .conversation {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 3px;
      &:hover {
        fill: black;
        cursor: default;
      }
    }
  }

  .floating-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: #fff;
  }

  .big {
    width: 1.8rem;
    height: 1.8rem;
    fill: #fff;
  }
`;

const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "title", name: "제목" },
  { id: "kind", name: "종류" },
  { id: "email", name: "계정" },
  { id: "track_id", name: "트랙 아이디" },
  { id: "track_title", name: "트랙 제목" }
];

export const RightPlaylistTable = () => {
  const router = useHistory();
  const [{ userRole, loading, store }, dispatchApp] = useAppStore(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading,
    store
  }));
  const [{ origin, edge, checkList }, dispatch] = usePlaylistsStore(store => ({
    origin: store.Playlist.origin,
    edge: store.Playlist.edge,
    checkList: store.Playlist.checkList
  }));
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const queryParams = useQueryParams();
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchKind = queryParams.get("kind", { default: undefined });
  const searchEmail = queryParams.get("email", { default: undefined });
  const searchTrack = queryParams.get("track_id", { default: undefined });
  const searchTrackTitle = queryParams.get("track_title", { default: undefined });

  const hash = createHashGenerator();
  const selectCsvTypeModal = useToggle();
  const shareConfirmModal = useToggle();
  const adminConfirmModal = useToggle();

  const allowEditPlaylist = userRole === UserRole.Master || (userRole === UserRole.Arbeit && allowPlaylistCreate(store));

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);

  const playlistSearch = useCallback(
    (e: React.FormEvent<HTMLFormElement>, searchStr: string): void => {
      e.preventDefault();
      if (searchStr === getSearchString(searchType)) {
        return;
      }
      if (!searchStr) {
        router.push(`?page=1`);
        return;
      }
      if (searchType === SearchColumnList[0].id && !searchStr.match(/^[0-9]+$/)) {
        window.alert("아이디는 숫자만 입력가능합니다.");
        return;
      }
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      router.push(`?page=1&${searchType}=${searchStr}`);
    },
    [dispatchApp, getSearchString, router, searchType]
  );

  const onToggleAllCheck = useCallback(
    (checked: boolean) => {
      origin.forEach(({ id }) => {
        dispatch([PlaylistsActions.setPlaylistCheckList({ id, checked })]);
      });
    },
    [dispatch, origin]
  );

  const isChecked = (list: Map<string, boolean>) => {
    const filteredList = Array.from(list).filter(item => origin.map(({ id }) => id).includes(item[0]));
    return list.size === 0 || !filteredList.length ? false : filteredList.every(item => item[1]);
  };

  const onSetValidCheckDone = async (list: Map<string, boolean>) => {
    const filteredList = Array.from(list)
      .filter(item => item[1])
      .map(item => item[0]);
    try {
      setEditLoading(true);
      for (const targetId of filteredList) {
        const index = origin.findIndex(({ id }) => id === targetId);
        const accessId = await requestAccessRecord({ targetId: targetId, targetTable: TargetTableInput.Playlist });
        if (accessId) {
          await UpdatePlaylist({ id: targetId, validCheck: ValidType.DONE });
          dispatch(PlaylistsActions.updatePlaylistValidCheck({ index, validCheck: ValidType.DONE }));
          await DeleteAccessRecord({ id: accessId });
        }
      }
      Toast.primary("변경이 완료되었습니다.", undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("오류가 발생하였습니다.", undefined, "top-center");
      return;
    } finally {
      setEditLoading(false);
    }
  };

  const onSetAdminConfirm = async (list: Map<string, boolean>) => {
    const filteredList = Array.from(list)
      .filter(item => item[1])
      .map(item => item[0]);
    try {
      setEditLoading(true);
      for (const targetId of filteredList) {
        try {
          const ids = await GetMetadataPlaylistRelationId({ playlistId: targetId, count: 999 });
          if (ids.length) {
            const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Playlist });
            if (accessId) {
              try {
                for (const uuid of ids) {
                  const { errors } = await UpdateMetadataPlaylistRelation({ id: uuid, validCheck: ValidType.DONE });
                  if (errors) {
                    throw errors;
                  }
                }
                await DeleteAccessRecord({ id: accessId });
              } catch (err) {
                console.log(err);
                await DeleteAccessRecord({ id: accessId });
                return;
              }
            }
          }
        } catch (err) {
          throw err;
        }
      }
      Toast.primary("성공적으로 변경되었습니다.");
    } catch (err) {
      console.log(err);
      Toast.error("오류가 발생하였습니다.", undefined, "top-center");
      return;
    } finally {
      setEditLoading(false);
    }
  };

  const PLAYLIST_SUB_HEAD = [
    <AnimatedCheckbox
      id={uuidv4()}
      isChecked={isChecked(checkList)}
      onToggle={() =>
        onToggleAllCheck(
          checkList.size === 0
            ? true
            : Array.from(checkList)
                .filter(item => origin.map(({ id }) => id).includes(item[0]))
                .some(item => !item[1])
        )
      }
    />,
    "아이디",
    "종류",
    "제목",
    "이미지",
    "언어팩",
    "수록곡",
    "서비스",
    "계정",
    "가격",
    "공유 승인",
    "공유사 확인",
    <div className="conversation">
      <ConversationIcon />
      <span>평가 및 댓글</span>
    </div>,
    "#"
  ];
  const PARTNER_SUB_HEAD = [
    "체크박스",
    "아이디",
    "종류",
    "제목",
    "수록곡",
    "가격",
    "공유 승인",
    "공유사 확인",
    <div className="conversation">
      <ConversationIcon />
      <span>평가 및 댓글</span>
    </div>
  ];
  const MASTER_PLAYLIST_SUB_HEAD = [
    <AnimatedCheckbox
      id={uuidv4()}
      isChecked={isChecked(checkList)}
      onToggle={() =>
        onToggleAllCheck(
          checkList.size === 0
            ? true
            : Array.from(checkList)
                .filter(item => origin.map(({ id }) => id).includes(item[0]))
                .some(item => !item[1])
        )
      }
    />,
    "아이디",
    "종류",
    "제목",
    "이미지",
    "언어팩",
    "수록곡",
    "서비스",
    "계정",
    "가격",
    "공유 승인",
    "공유사 확인",
    <div className="conversation">
      <ConversationIcon />
      <span>평가 및 댓글</span>
    </div>,
    "컬럼 관리",
    "#"
  ];
  const HEAD_LIST = [
    {
      name: "플레이 리스트",
      colSpan:
        userRole === UserRole.Partner
          ? PARTNER_SUB_HEAD.length
          : userRole !== UserRole.Master
          ? PLAYLIST_SUB_HEAD.length
          : MASTER_PLAYLIST_SUB_HEAD.length
    }
  ];

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        dispatch(PlaylistsActions.clearPlaylistCheckList());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  useAsyncEffect(
    async isMounted => {
      if (isMounted() && !edge) {
        dispatchApp(LoadingAction.setLoading(LOADING.NULL));
      }
      const ids = origin.map(({ id }) => id);
      dispatch(PlaylistsActions.setPlaylistCheckListInit(ids));
    },
    [origin, edge]
  );

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <SearchForm
          onSearch={playlistSearch}
          onChangeSearchTypeInfo={info => setSearchType(info!.id)}
          optionColumnList={SearchColumnList}
        />
      </TopbarTemplate>
      <TableTemplate
        headList={HEAD_LIST}
        subHeadList={
          userRole === UserRole.Partner ? PARTNER_SUB_HEAD : userRole !== UserRole.Master ? PLAYLIST_SUB_HEAD : MASTER_PLAYLIST_SUB_HEAD
        }
        keyBy={data => `${hash()}${data.kind}${data.name}`}
        dataList={loading === LOADING.UNLOAD ? [] : origin}
        Column={props => DataTemplate({ ...props, setLoading: setEditLoading })}
      />
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      {(userRole === UserRole.Master || allowEditPlaylist) && (
        <SelectMenu
          selectCount={Array.from(checkList).filter(item => item[1]).length}
          selectInfoString="개를"
          isOpen={!!Array.from(checkList).some(item => item[1])}
          isBottom={isBottom}
        >
          <SelectMenu.IconButton onClick={selectCsvTypeModal.on}>
            <ExcelIcon />
            <span style={{ fontSize: "0.8rem" }}>CSV 다운</span>
          </SelectMenu.IconButton>
          <SelectMenu.IconButton onClick={shareConfirmModal.on}>
            <MusicIcon />
            <span style={{ fontSize: "0.8rem" }}>공유 승인</span>
          </SelectMenu.IconButton>
          <SelectMenu.IconButton onClick={adminConfirmModal.on}>
            <CheckIcon />
            <span style={{ fontSize: "0.8rem" }}>관리자 확인</span>
          </SelectMenu.IconButton>
        </SelectMenu>
      )}
      <Pagination
        edge={edge}
        goTo={index =>
          `${PLAYLIST}?page=${index}${!searchId ? "" : `&id=${searchId}`}${!searchTitle ? "" : `&title=${searchTitle}`}${
            !searchKind ? "" : `&kind=${searchKind}`
          }${!searchEmail ? "" : `&email=${searchEmail}`}${!searchTrack ? "" : `&track_id=${searchTrack}`}${
            !searchTrackTitle ? "" : `&track_title=${searchTrackTitle}`
          }`
        }
        onIntersect={() => setIsBottom(true)}
        unIntersect={() => setIsBottom(false)}
      />
      <Loading loading={editLoading} />
      <Modal isOpen={selectCsvTypeModal.isToggled} onClose={selectCsvTypeModal.off}>
        <SelectCsvTypeModal toClose={selectCsvTypeModal.off} />
      </Modal>
      <Modal isOpen={shareConfirmModal.isToggled}>
        <Confirm
          title="알림"
          context="선택된 리스트를 모두 공유하시겠습니까?"
          toSave={() => onSetValidCheckDone(checkList)}
          toClose={shareConfirmModal.off}
        />
      </Modal>
      <Modal isOpen={adminConfirmModal.isToggled}>
        <Confirm
          title="알림"
          context="선택된 리스트의 모든 트랙이 확인처리 됩니다. 변경하시겠습니까?"
          toSave={() => onSetAdminConfirm(checkList)}
          toClose={adminConfirmModal.off}
        />
      </Modal>
    </Layout>
  );
};
