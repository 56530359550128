import { GRAY_2, GRAY_6 } from "constants/baseColor";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as SearchIcon } from "assets/icons/detail-search.svg";
import { PRIMARY_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { useHistory } from "react-router";
import { useQueryParams } from "lib/use-query-params";
import { Info } from "App/Atomics/Input/Select/TagSelect";
import {
  ArtistFilter,
  GenreFilter,
  GteLteFilter,
  LicenseFilter,
  MoodFilter,
  OrderFilter,
  RightsFilter,
  RoleFilter,
  RoleTypeClassFilter,
  UrlFilter,
  VCFilter,
  WorkArtistFilter
} from "./FilterGroup";
import { ALBUMS, ARTISTS_RENEWAL, PLAYLIST_DETAIL, ROLES, TRACKS, WORKS, WORKS_TRACK } from "constants/route";
import { MetadataOrderByInput } from "constants/MetadataOrderByInput";
import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";
import { CountFilter } from "./FilterGroup/CountFilter";
import { RoleExposeFilter } from "./FilterGroup/RoleFilter";

type Props = {
  route: string;
  toClose: () => void;
};

export type Filters = {
  lte?: number;
  gte?: number;
  count?: string;
  artist?: Info[];
  artistType?: Info[];
  role?: Info[];
  workArtist?: Info[];
  rights?: Info[];
  genre?: Info[];
  mood?: Info[];
  url?: boolean;
  validCheck: {
    V1: boolean;
    V2: boolean;
    V3: boolean;
    VC: boolean;
    REMOVE: boolean;
    DONE: boolean;
    ETC: boolean;
    MODIFY: boolean;
  };
  order?: string;
  expose?: string;
  roleTypeClass?: Info[];
  licenseType?: Info[];
};

export const FilterModalTemplate = ({ route, toClose }: Props) => {
  const router = useHistory();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchArtist = queryParams.get("artist", { default: undefined });
  const searchIdList = queryParams.get("ids", { default: undefined });
  const extraUrl = `?page=${page}${searchIdList ? `&ids=${searchIdList}` : ""}${!searchId ? "" : `&id=${searchId}`}${
    !searchTitle ? "" : `&title=${searchTitle}`
  }${!searchArtist ? "" : `&artist=${searchArtist}`}`;
  const filter = JSON.parse(queryParams.get("filter", { default: undefined }) || "{}") as Filters;
  const {
    gte,
    lte,
    count,
    artist,
    artistType,
    role,
    workArtist,
    rights,
    url,
    validCheck,
    genre,
    mood,
    order,
    roleTypeClass,
    expose,
    licenseType
  } = filter;
  const validCheckIn = validCheck ?? {
    V1: true,
    V2: true,
    V3: true,
    VC: true,
    REMOVE: true,
    DONE: true,
    ETC: true,
    MODIFY: true
  };
  const [filters, setFilters] = useState<Filters>({
    gte,
    lte,
    count,
    artist,
    artistType,
    role,
    workArtist,
    rights,
    url,
    validCheck: validCheckIn,
    genre,
    mood,
    order,
    roleTypeClass,
    expose,
    licenseType
  } as Filters);

  const onSave = useCallback(
    (filters: Filters) => {
      const filter = JSON.stringify(filters);
      router.push(`${route}${extraUrl}&filter=${filter}`);
      toClose();
    },
    [extraUrl, route, router, toClose]
  );

  const onReset = useCallback(() => {
    router.push(`${route}${extraUrl}`);
    toClose();
  }, [extraUrl, route, router, toClose]);

  const optionList = (route: string) => {
    switch (route) {
      case TRACKS:
      case WORKS:
      case WORKS_TRACK:
      case ALBUMS:
        return Object.entries(MetadataOrderByInput).map(([key, value]) => ({ id: value, name: key })); // key를 보여주고, value를 값으로 사용
      case PLAYLIST_DETAIL:
        return Object.entries(MetadataPlaylistRelationOrderByInput).map(([key, value]) => ({ id: value, name: key })); // key를 보여주고, value를 값으로 사용
      default:
        return Object.entries(MetadataOrderByInput).map(([key, value]) => ({ id: value, name: key })); // key를 보여주고, value를 값으로 사용
    }
  };

  const allowCount = (route: string) =>
    route === TRACKS || route === WORKS || route === ALBUMS || route === ROLES || route === ARTISTS_RENEWAL;
  const allowOrder = (route: string) => route === WORKS || route === WORKS_TRACK || route === ALBUMS || route === PLAYLIST_DETAIL;
  const allowGte = (route: string) => route === WORKS || route === WORKS_TRACK || route === ALBUMS;
  const allowArtist = (route: string) =>
    route === TRACKS || route === WORKS || route === WORKS_TRACK || route === ALBUMS || route === PLAYLIST_DETAIL;
  const allowWorkArtist = (route: string) => route === TRACKS;
  const allowRightsCompany = (route: string) =>
    route === TRACKS || route === WORKS || route === WORKS_TRACK || route === ALBUMS || route === ARTISTS_RENEWAL;
  const allowUrl = (route: string) => route === WORKS || route === ALBUMS || route === ARTISTS_RENEWAL;
  const allowGenre = (route: string) => route === TRACKS || route === WORKS_TRACK || route === PLAYLIST_DETAIL || route === ARTISTS_RENEWAL;
  const allowMood = (route: string) => route === TRACKS || route === WORKS_TRACK || route === PLAYLIST_DETAIL;
  const allowRole = (route: string) => route === TRACKS || route === WORKS_TRACK || route === ARTISTS_RENEWAL;
  const allowRoleTypeClass = (route: string) => route === ROLES;
  const allowRoleExpose = (route: string) => route === ROLES;
  const allowLicense = (route: string) => route === ALBUMS || route === TRACKS;

  return (
    <Layout>
      <Header>
        <SearchIcon className="search" />
        <h3>전체 필터</h3>
        <CancelIcon className="cancel" onClick={toClose} />
      </Header>
      <Section>
        {allowCount(route) && <CountFilter count={count} setFilters={setFilters} />}
        {allowOrder(route) && (
          <OrderFilter
            order={route === PLAYLIST_DETAIL && !order ? "exposure_order__ASC" : order}
            optionList={optionList(route)}
            setFilters={setFilters}
          />
        )}
        {allowGte(route) && <GteLteFilter gte={filters.gte} lte={filters.lte} setFilters={setFilters} />}
        {allowArtist(route) && <ArtistFilter artist={filters.artist} artistType={filters.artistType} setFilters={setFilters} />}
        {allowRole(route) && <RoleFilter role={filters.role} setFilters={setFilters} />}
        {allowWorkArtist(route) && <WorkArtistFilter workArtist={filters.workArtist} setFilters={setFilters} />}
        {allowRightsCompany(route) && <RightsFilter rights={filters.rights} setFilters={setFilters} />}
        {allowLicense(route) && <LicenseFilter licenseType={filters.licenseType} setFilters={setFilters} />}
        {allowGenre(route) && <GenreFilter genre={filter.genre} setFilters={setFilters} />}
        {allowMood(route) && <MoodFilter mood={filter.mood} setFilters={setFilters} />}
        {allowUrl(route) && <UrlFilter url={filters.url} setFilters={setFilters} />}
        <VCFilter validCheck={filters.validCheck} setFilters={setFilters} />
        {allowRoleTypeClass(route) && <RoleTypeClassFilter typeClass={filter.roleTypeClass} setFilters={setFilters} />}
        {allowRoleExpose(route) && <RoleExposeFilter expose={filter.expose} setFilters={setFilters} />}
      </Section>
      <ButtonGroup route={route}>
        <Input.Button className="btn cancel" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button className="btn reset" color="danger" isFill={false} onClick={onReset}>
          초기화
        </Input.Button>
        <Input.Button className="btn save" color="primary" onClick={() => onSave(filters)}>
          적용
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  width: 1000px;
  height: 95vh;
  overflow: scroll;
  background-color: #fff;
  font-size: 0.8rem;
  border-radius: 8px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.8em;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .search {
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.4em;
    fill: ${PRIMARY_COLOR};
  }

  .cancel {
    position: absolute;
    right: 3%;
    width: 2em;
    height: 2em;
    padding: 0.5em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1em 2em;
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  height: 9rem;
  justify-content: center;
  border-bottom: 1px solid ${GRAY_2};
  h4 {
    font-size: 1rem;
    margin-bottom: 0.2em;
  }
  .info {
    color: ${GRAY_6};
    margin-bottom: 1em;
  }
`;

const ButtonGroup = styled.div<{ route?: string }>`
  position: ${props => (props.route === ROLES ? "absolute" : "sticky")};
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  justify-content: flex-end;
  align-items: center;
  padding: 1.5em;
  .btn {
    width: 10em;
    height: 3em;
  }
  .cancel,
  .reset {
    margin-right: 0.2em;
  }
`;

export const ToggleGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleButton = styled(Input.Toggle)`
  margin-right: 0.2rem;
`;
