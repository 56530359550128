/* eslint-disable @typescript-eslint/no-unused-vars */
import itiriri from "itiriri";
import React, { ChangeEvent, FormEvent, useState } from "react";
import styled from "styled-components";

import { Input } from "App/Atomics/Input";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { useAppSelector } from "App/Store";
import { GRAY_4, WHITE, GRAY_6, GRAY_8 } from "constants/baseColor";
import { DANGER_COLOR, DEFAULT_BORDER_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from "constants/color";
import {
  BORDER_RADIUS_PX,
  MARGING_LARGE_PX,
  MARGING_X_LARGE_PX,
  PADDING_XX_LARGE_PX,
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_LARGE_PX,
  UNIT_PX,
  widthViewportize,
  MARGING_SMALL_PX
} from "constants/size";
import { UserRole } from "constants/UserRole";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { ArtistMultiModal } from "../../Modals/ArtistMultiModal";
import { GenreModal } from "../../Modals/GenreModal";
import { useAlbumDispatch, useAlbumStore, useAlbumSelector } from "../../Store";
import { AlbumActions, License, FileMode } from "../../Store/Album";
import { GuideTooltip } from "App/Molecules/AnimatedTooltips";
import { WorkModal } from "../../Modals/WorkModal";
import { Divider } from "App/Atomics/Divider";
import { useAsyncEffect } from "lib/use-async-effect";
import { TagModal } from "App/Organisms/TagModal";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";

type TrackProps = {
  no: string;
};

const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100%;
  padding: ${PADDING_XX_LARGE_PX} ${widthViewportize(0.4 * UNIT)};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};
  z-index: 1;

  h2 {
    text-align: left;
    padding: ${PADDING_X_LARGE_PX} 0px;
  }

  h3 {
    margin-bottom: ${MARGING_X_LARGE_PX};
    text-align: left;
  }

  .nextButton {
    margin-left: auto;
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & svg {
      position: relative;
      top: 4px;
      width: ${pixelize(1.25 * UNIT)};
      height: ${pixelize(1.25 * UNIT)};
    }
  }
  input[type="text"],
  input[type="file"],
  input[type="number"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${GRAY_8};
    }
  }

  .grid-box {
    width: 100%;
    display: grid;
    grid-template-columns: 80%;
    grid-gap: 8px;
    align-items: center;
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  grid-template-columns: 80% 20%;
  grid-gap: ${UNIT_PX};
  align-items: center;

  input {
    width: 100%;
    background-color: transparent;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid ${GRAY_8};
    }
  }

  button {
    width: calc(100% - ${UNIT_PX});
  }

  .trackDetail,
  .licenseDetail {
    height: ${pixelize(UNIT * 8)};
  }
`;

const ButtonGroup = styled.div`
  display: inline-flex;
  margin-top: auto;

  & > button {
    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }
`;

const InfoLayout = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const ListLayout = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  margin-right: ${MARGING_LARGE_PX};
  padding-bottom: ${PADDING_X_LARGE_PX};
`;

const Tab = styled.div<{ length: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: 1px solid ${PRIMARY_COLOR};
  border-bottom-color: ${WHITE};
  color: ${PRIMARY_COLOR};
  cursor: pointer;

  &[aria-current="true"] {
    color: ${WHITE};
    background-color: ${PRIMARY_COLOR};
    ${props =>
      props.length > 1
        ? `
    transition: background-color 0.25s, border 0.25s;
    &:hover {
      background-color: ${DANGER_COLOR};
      border: 1px solid ${DANGER_COLOR};
    }`
        : ""}
  }

  &:first-child {
    border-top-left-radius: ${BORDER_RADIUS_PX};
    border-top-right-radius: ${BORDER_RADIUS_PX};
  }

  & + div,
  & + button {
    border-radius: 0px;

    &:last-child {
      border-bottom-left-radius: ${BORDER_RADIUS_PX};
      border-bottom-right-radius: ${BORDER_RADIUS_PX};
    }
  }
`;

const TabsLayout = styled.div`
  display: flex;
  flex-direction: column;

  min-width: ${pixelize(UNIT * 4)};
  padding-right: ${PADDING_X_LARGE_PX};
  margin-right: ${MARGING_X_LARGE_PX};

  & > button,
  & > div {
    width: ${pixelize(UNIT * 2.5)};
    height: ${pixelize(UNIT * 2.5)};
  }
`;

const FileGroup = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  padding: ${PADDING_X_LARGE_PX};
  input {
    width: 100%;
    background-color: transparent;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid ${GRAY_8};
    }
  }
`;

const LicenseNoticeGroup = styled.div`
  display: none;

  &[data-visible="true"] {
    display: block;
  }
`;

const LicenseNotice = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${UNIT_PX};
  align-items: center;

  & > label {
    font-weight: bold;
    text-align: right;
  }

  & > .licenseDetail {
    height: ${pixelize(UNIT * 4)};
    grid-column: 2 / 5;
  }

  & > .sourceUrl {
    grid-column: 2 / 5;
  }
`;
const CustomMargin = styled.div`
  width: 100%;
  height: ${MARGING_LARGE_PX};
  color: transparent;
`;

const ExtraInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px 35% auto;
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
`;

const Description = styled.textarea`
  width: 100%;
  font-size: 13px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin: 0.5rem 0;
  padding: ${PADDING_X_LARGE_PX};
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => (props.color === "danger" ? DANGER_COLOR : PRIMARY_COLOR)};
`;

/**
 *  TODO:: TRACK 생성 글 Required
 *  - metadata table (album 기본 정보)
 *    + metadata_id
 *    + type_metadata_class
 *    + type_metadata_subclass
 *    + no
 *    + title
 *    + valid_check
 *
 *  - metadata_artist_relation table (artist 기본 정보)
 *    + metadata_id
 *    + artist_id
 *    + role_id
 *
 *  - metadata_subdata_unique table (음원 url)
 *    (차후 metadata_url table로)
 *    + metadata_id
 *    + type_subdata_category
 *    + type_subdata_field
 *    + value
 *
 *  - metadata_genre_relation table (트랙 장르/무드 정보)
 *    + metadata_id
 *    + genre_id
 *
 *  - metadata_structure table (앨범-트랙 구조 정보)
 *    + metadata_id
 *    + parent_id
 */

const CCLTypeOptions = [
  { id: "CC0", name: "CC0" },
  { id: "BY", name: "CC BY" },
  { id: "BY-SA", name: "CC BY-SA" }
];

const CCLVersionOptions = [
  { id: "1.0", name: "1.0" },
  { id: "2.0", name: "2.0" },
  { id: "2.5", name: "2.5" },
  { id: "3.0", name: "3.0" },
  { id: "4.0", name: "4.0" }
];

const noticeOptionList = [
  { id: "ALBUM_UCI", name: "ALBUM_UCI" },
  { id: "UCI", name: "UCI" },
  { id: "ISRC", name: "ISRC" }
];

const OptionLicenseService = [
  { id: "0", name: "사용 가능 / 재생 가능" },
  { id: "1", name: "사용 가능 / 재생 불가" },
  { id: "2", name: "사용 불가 / 재생 불가" }
];

const TrackInfo = ({ no }: TrackProps) => {
  const [{ album, trackList, isSingle, fileMode, isEffect }, dispatch] = useAlbumStore(store => store.AlbumCreate);
  const userRole = useAppSelector(store => store.UserToken.role);

  const artistModal = useToggle();
  const workModal = useToggle();
  const tagModal = useToggle();

  const [titleType, setTitleType] = useState<string | undefined>(undefined);

  const LicenseOption = [
    { id: album.albumCompany.type === "Cover" ? "Cover" : "VL", name: album.albumCompany.license, isDisabled: false },
    { id: "ccl", name: "Creative Commons", isDisabled: album.albumCompany.name === "ARTISTSCARD-NOCOPYRIGHT" },
    { id: "NoCopyright", name: "No Copyright", isDisabled: album.albumCompany.name === "ARTISTSCARD-CCL" }
  ];

  const track = trackList[parseInt(no) - 1];
  const [selectedLicense, setSelectedLicense] = useState(
    track.trackLicense.type_track ? track.trackLicense.type_track : LicenseOption[0].id
  );

  const licenseDefaultValue = () => {
    switch (album.albumCompany.name) {
      case "ARTISTSCARD-CCL":
        return LicenseOption[1];
      case "ARTISTSCARD-NOCOPYRIGHT":
        return LicenseOption[2];
      default:
        return LicenseOption[0];
    }
  };
  const serviceDefaultValue = () => {
    switch (track.trackLicense.is_service) {
      case 0:
        return OptionLicenseService[0];
      case 1:
        return OptionLicenseService[1];
      case 2:
        return OptionLicenseService[2];
    }
  };

  const updateWork: Parameters<typeof WorkModal>[0]["onClick"] = work => {
    if (work) {
      const works = work.map(({ id, name, order }) => ({ no: track.no, id, title: name, order }));
      dispatch(AlbumActions.setTrackWork(works));
    }
    workModal.off();
  };
  const updateArtist: Parameters<typeof ArtistMultiModal>[0]["onClick"] = artistList => {
    if (artistList && artistList.length) {
      dispatch(AlbumActions.setTrackArtist({ no: track.no, artist: artistList }));
    }
    artistModal.off();
  };

  const updateGenre: Parameters<typeof TagModal>[0]["onSave"] = (genreList, moodList) => {
    if (genreList) {
      dispatch(
        AlbumActions.setTrackGenre({
          no: track.no,
          genre: genreList
            .map(genre => Object.assign(genre, { typeKind: "genre" }))
            .concat(moodList.map(mood => Object.assign(mood, { typeKind: "mood" })))
        })
      );
    }
    tagModal.off();
  };

  const setTrackFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        AlbumActions.setTrackData({
          no: track.no,
          file: file.files![0],
          data: event.target?.result as string,
          fileType,
          name: file.files![0].name
        })
      );
    };
  };

  const setTrackLicense = (info: Info) => {
    let notice = "";
    let licenseType = "";
    setSelectedLicense(info.id);
    switch (info.id) {
      case "VL":
        notice = JSON.stringify({ text: `License : ${album.albumCompany.license}` });
        licenseType = "VL";
        break;
      case "ccl":
        notice = JSON.stringify({ url: "", text: "", type: "CC0", version: "", description: "" });
        licenseType = "ccl";
        break;
      case "NoCopyright":
        notice = JSON.stringify({ text: "No Copyright", description: "" });
        licenseType = "NoCopyright";
        break;
      case "Cover":
        notice = JSON.stringify({ text: `License : ${album.albumCompany.license}` });
        licenseType = "Cover";
        break;
      default:
        break;
    }
    dispatch(
      AlbumActions.setTrackLicense({
        no: track.no,
        notice: notice,
        typeTrack: licenseType,
        publishDate: album.releaseDate,
        isService: track.trackLicense.is_service
      })
    );
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        if (
          !track.sourceUrl &&
          fileMode === FileMode.URL &&
          album.ytv_Url &&
          (selectedLicense === "NoCopyright" || selectedLicense === "ccl")
        ) {
          dispatch(AlbumActions.setTrackSource({ no: track.no, sourceUrl: album.ytv_Url }));
        }

        switch (album.albumCompany.name) {
          case "ARTISTSCARD-CCL":
            setTrackLicense(LicenseOption[1]);
            return;
          case "ARTISTSCARD-NOCOPYRIGHT":
            setTrackLicense(LicenseOption[2]);
            return;
          default:
            setTrackLicense(LicenseOption[0]);
            return;
        }
      }
    },
    [selectedLicense, isSingle]
  );

  return (
    <InfoLayout>
      <h3>Track No. {track.no}</h3>
      {!isEffect && (
        <LabelGroup>
          <label>
            아티스트
            <GuideTooltip text="작사, 작곡을 포함한 모든 아티스트를 추가로 역할과 함께 입력할 수 있습니다." direction="right" />
          </label>
          <RowGroup onClick={artistModal.on}>
            <Input.Text
              key={track.no}
              isDisabled
              placeholder="트랙에 참여한 아티스트를 검색하세요."
              value={
                track.trackArtist
                  ? itiriri(track.trackArtist)
                      .toArray(value => `${value.artist_name}(${value.role_name})`)
                      .join(", ")
                  : ""
              }
            />
            <Input.Button color="default">검색</Input.Button>
          </RowGroup>
        </LabelGroup>
      )}
      <LabelGroup>
        <label>{`${!isEffect ? "트랙" : "효과음"} 타이틀`}</label>
        <RowGroup>
          <Input.Text
            key={track.no}
            className="trackTitle"
            placeholder="타이틀을 입력하세요."
            defaultValue={track.title ? track.title : ""}
            isRequired
            onChange={str => {
              if (str.includes(`"`)) {
                window.alert(`특수문자 "를 사용할 수 없습니다.`);
                return;
              }
            }}
            onBlur={value => dispatch(AlbumActions.setTrackTitle({ no: track.no, title: value }))}
          />
        </RowGroup>
      </LabelGroup>
      {(userRole === UserRole.Master || userRole === UserRole.Arbeit) && !isEffect && (
        <LabelGroup>
          <label>작품</label>
          <RowGroup onClick={workModal.on}>
            <Input.Text
              key={track.no}
              isDisabled
              placeholder="작품을 검색하세요."
              value={track.work && track.work.length ? track.work.map(({ title }) => title).join(", ") : ""}
            />
            <Input.Button color="default">검색</Input.Button>
          </RowGroup>
        </LabelGroup>
      )}
      <LabelGroup>
        <label>장르 / 무드</label>
        <RowGroup onClick={tagModal.on}>
          <Input.Text
            key={track.no}
            isDisabled
            placeholder="트랙의 장르를 검색하세요."
            value={
              track.trackGenre
                ? itiriri(track.trackGenre)
                    .toArray(genre => genre.name)
                    .join(", ")
                : ""
            }
          />
          <Input.Button color="default">검색</Input.Button>
        </RowGroup>
      </LabelGroup>
      {fileMode === FileMode.URL ? null : (
        <LabelGroup>
          <FileGroup>
            <LabelGroup>
              <label htmlFor="file_mp3">MP3</label>
              <input
                id="file_mp3"
                key={track.no}
                className="files"
                type="file"
                accept="audio/mpeg"
                onChange={event => setTrackFile(event, "mp3high")}
              />
            </LabelGroup>
            {track.trackUrl && track.trackUrl.trackMp3 && track.trackUrl.trackMp3.url && (
              <div style={{ textAlign: "left" }}>
                <b>현재 파일: </b> {track.trackUrl.trackMp3.url}
              </div>
            )}

            <LabelGroup>
              <label htmlFor="file_aac">AAC</label>
              <input
                id="file_aac"
                key={track.no}
                className="files"
                type="file"
                accept="audio/aac, audio/x-m4a"
                onChange={event => setTrackFile(event, "aac")}
              />
            </LabelGroup>
            {track.trackUrl && track.trackUrl.trackAac && track.trackUrl.trackAac.url && (
              <div style={{ textAlign: "left" }}>
                <b>현재 파일: </b> {track.trackUrl.trackAac.url}
              </div>
            )}

            <LabelGroup>
              <label htmlFor="file_flac">FLAC</label>
              <input
                id="file_flac"
                key={track.no}
                className="files"
                type="file"
                accept="audio/flac"
                onChange={event => setTrackFile(event, "flac")}
              />
            </LabelGroup>
            {track.trackUrl && track.trackUrl.trackFlac && track.trackUrl.trackFlac.url && (
              <div style={{ textAlign: "left" }}>
                <b>현재 파일: </b> {track.trackUrl.trackFlac.url}
              </div>
            )}

            <LabelGroup>
              <label htmlFor="file_wav">WAV</label>
              <input
                id="file_wav"
                key={track.no}
                className="files"
                type="file"
                accept="audio/wav"
                onChange={event => setTrackFile(event, "wav")}
              />
            </LabelGroup>
            {track.trackUrl && track.trackUrl.trackWav && track.trackUrl.trackWav.url && (
              <div style={{ textAlign: "left" }}>
                <b>현재 파일: </b> {track.trackUrl.trackWav.url}
              </div>
            )}
            {!isEffect && (
              <>
                <LabelGroup>
                  <label htmlFor="file_txt">TXT</label>
                  <input
                    id="file_txt"
                    key={track.no}
                    className="files"
                    type="file"
                    accept="text/plain"
                    onChange={event => setTrackFile(event, "txt")}
                  />
                </LabelGroup>
                {track.trackUrl && track.trackUrl.trackZip && track.trackUrl.trackZip.url && (
                  <div style={{ textAlign: "left" }}>
                    <b>현재 파일: </b> {track.trackUrl.trackZip.url}
                  </div>
                )}
                <LabelGroup>
                  <label htmlFor="file_zip">ZIP</label>
                  <input
                    id="file_zip"
                    key={track.no}
                    className="files"
                    type="file"
                    accept="application/zip"
                    onChange={event => setTrackFile(event, "zip")}
                  />
                </LabelGroup>
                {track.trackUrl && track.trackUrl.trackZip && track.trackUrl.trackZip.url && (
                  <div style={{ textAlign: "left" }}>
                    <b>현재 파일: </b> {track.trackUrl.trackZip.url}
                  </div>
                )}
              </>
            )}
          </FileGroup>
        </LabelGroup>
      )}
      {!isEffect && (
        <>
          <LabelGroup>
            <label>라이센스</label>
            <RowGroup>
              <Input.TextSelect
                key={track.no}
                optionList={LicenseOption}
                defaultValue={isEffect ? undefined : licenseDefaultValue()}
                isDisabled={isEffect}
                onChange={info => info && setTrackLicense(info)}
              />
            </RowGroup>
          </LabelGroup>
          <LabelGroup>
            <label>사용 및 재생 여부</label>
            <RowGroup>
              <Input.TextSelect
                key={track.no}
                optionList={OptionLicenseService}
                defaultValue={serviceDefaultValue()}
                isDisabled={isEffect}
                onChange={info => info && dispatch(AlbumActions.setTrackLicenseService({ no: track.no, isService: +info.id }))}
              />
            </RowGroup>
          </LabelGroup>
          <LicenseNoticeGroup data-visible={selectedLicense === "NoCopyright"}>
            <LabelGroup>
              <label>라이센스 설명</label>
              <RowGroup>
                <Input.Multiline
                  className="licenseDetail"
                  placeholder="라이센스 설명을 입력하세요."
                  value={JSON.parse(track.trackLicense.notice).description ? JSON.parse(track.trackLicense.notice).description : ""}
                  isDisabled={isEffect}
                  onBlur={value =>
                    dispatch(AlbumActions.setTrackLicenseNoticeSelected({ no: track.no, noticeType: "description", notice: value }))
                  }
                />
              </RowGroup>
            </LabelGroup>

            <LabelGroup>
              <label>원본 URL</label>
              <RowGroup>
                <Input.Text
                  key={track.sourceUrl}
                  placeholder="원본 URL을 입력하세요."
                  defaultValue={track.sourceUrl ? track.sourceUrl : album.ytv_Url ? album.ytv_Url : ""}
                  onBlur={value => dispatch(AlbumActions.setTrackSource({ no: track.no, sourceUrl: value }))}
                />
              </RowGroup>
            </LabelGroup>
          </LicenseNoticeGroup>
          <LicenseNoticeGroup data-visible={selectedLicense === "ccl" && !isEffect}>
            <LabelGroup>
              <label>CCL 추가 정보</label>
              <RowGroup>
                <LicenseNotice key={track.no}>
                  <label>CCL 타입</label>
                  <Input.TextSelect
                    optionList={CCLTypeOptions}
                    defaultValue={
                      JSON.parse(track.trackLicense.notice).type
                        ? CCLTypeOptions.find(license => JSON.parse(track.trackLicense.notice).type === license.id)
                          ? CCLTypeOptions.find(license => JSON.parse(track.trackLicense.notice).type === license.id)
                          : CCLTypeOptions[0]
                        : CCLTypeOptions[0]
                    }
                    onChange={info =>
                      dispatch(
                        AlbumActions.setTrackLicenseNoticeSelected({ no: track.no, noticeType: "type", notice: info?.id ? info.id : "" })
                      )
                    }
                  />
                  <label>CCL 버전</label>
                  <Input.TextSelect
                    optionList={CCLVersionOptions}
                    defaultValue={
                      JSON.parse(track.trackLicense.notice).version
                        ? CCLVersionOptions.find(license => JSON.parse(track.trackLicense.notice).version === license.id)
                          ? CCLVersionOptions.find(license => JSON.parse(track.trackLicense.notice).version === license.id)
                          : CCLVersionOptions[0]
                        : CCLVersionOptions[0]
                    }
                    onChange={info =>
                      dispatch(
                        AlbumActions.setTrackLicenseNoticeSelected({ no: track.no, noticeType: "version", notice: info?.id ? info.id : "" })
                      )
                    }
                    isDisabled={
                      track.trackLicense.notice
                        ? JSON.parse(track.trackLicense.notice).type
                          ? JSON.parse(track.trackLicense.notice).type === "CC0"
                          : false
                        : false
                    }
                  />
                  <label>라이센스 설명</label>
                  <Input.Multiline
                    className="licenseDetail"
                    placeholder="라이센스 설명을 입력하세요."
                    value={JSON.parse(track.trackLicense.notice).description ? JSON.parse(track.trackLicense.notice).description : ""}
                    onBlur={value =>
                      dispatch(AlbumActions.setTrackLicenseNoticeSelected({ no: track.no, noticeType: "description", notice: value }))
                    }
                  />
                </LicenseNotice>
              </RowGroup>
            </LabelGroup>

            <LabelGroup>
              <label>원본 URL</label>
              <RowGroup>
                <Input.Text
                  className="sourceUrl"
                  placeholder="원본 URL을 입력하세요."
                  defaultValue={track.sourceUrl ? track.sourceUrl : album.ytv_Url ? album.ytv_Url : ""}
                  onBlur={value => dispatch(AlbumActions.setTrackSource({ no: track.no, sourceUrl: value }))}
                />
              </RowGroup>
            </LabelGroup>
          </LicenseNoticeGroup>
          <LabelGroup>
            <label>
              트랙 설명
              <GuideTooltip
                text="Description을 선택 시, 특정 키워드를 잘 활용하면, 구글 검색에 효과적으로 노출될 수 있습니다."
                direction="right"
              />
            </label>
            <RowGroup>
              <div>
                <Input.TextSelect
                  optionList={TitleTypeOptionInfo}
                  isDisabled={isEffect}
                  onChange={info => {
                    if (info) {
                      setTitleType(info.id);
                    }
                  }}
                />
                <Description
                  key={track.no}
                  disabled={!titleType}
                  className="trackDetail"
                  placeholder="타입을 먼저 선택해주세요."
                  defaultValue={track.trackTitle ? track.trackTitle.value : ""}
                  onBlur={e =>
                    dispatch(AlbumActions.setTrackDescription({ no: track.no, typeTitle: titleType!, description: e.target.value }))
                  }
                />
              </div>
            </RowGroup>
          </LabelGroup>
          <LabelGroup>
            <label>부가 정보</label>
            <div className="grid-box">
              {!JSON.parse(track.trackLicense.notice).companyIds
                ? null
                : Object.entries(JSON.parse(track.trackLicense.notice).companyIds).map((item, i) => (
                    <ExtraInfo key={track.no + i}>
                      <RoundButton
                        color="danger"
                        onClick={() => dispatch(AlbumActions.removeTrackLicenseNoticeSelected({ no: track.no, companyIndex: i }))}
                      >
                        -
                      </RoundButton>
                      <Input.CreatableTextSelect
                        placeholder="이름"
                        name="createNotice"
                        optionList={noticeOptionList}
                        defaultValue={!item[0] ? undefined : item[0] === null ? { id: "", name: "" } : { id: item[0], name: item[0] }}
                        onBlur={info => {
                          if (info) {
                            dispatch(
                              AlbumActions.setTrackLicenseNoticeCompanyIdSelected({
                                no,
                                companyIndex: i,
                                noticeType: info.name,
                                value: null
                              })
                            );
                          }
                        }}
                      />
                      <Input.Text
                        placeholder="값을 입력하세요"
                        defaultValue={!item[1] ? undefined : (item[1] as any)}
                        onBlur={val => {
                          dispatch(
                            AlbumActions.setTrackLicenseNoticeCompanyIdSelected({ no, companyIndex: i, noticeType: null, value: val })
                          );
                        }}
                      />
                    </ExtraInfo>
                  ))}
              <RoundButton
                color="primary"
                disabled={isEffect}
                onClick={e => {
                  e.preventDefault();
                  dispatch(AlbumActions.setTrackLicenseNoticeCompanyIdSelected({ no, companyIndex: 0, noticeType: null, value: null }));
                }}
              >
                +
              </RoundButton>
            </div>
          </LabelGroup>
        </>
      )}
      <Modal isOpen={workModal.isToggled}>
        <WorkModal work={track.work ? track.work.map(({ id, title, order }) => ({ id, name: title, order })) : []} onClick={updateWork} />
      </Modal>
      <Modal isOpen={artistModal.isToggled}>
        <ArtistMultiModal artistList={track.trackArtist && track.trackArtist.length ? track.trackArtist : []} onClick={updateArtist} />
      </Modal>
      <Modal isOpen={tagModal.isToggled} onClose={tagModal.off}>
        <TagModal
          genreList={track.trackGenre?.filter(({ typeKind }) => typeKind === "genre").map(({ id, name }) => ({ id, name })) ?? []}
          moodList={
            track.trackGenre
              ?.filter(({ typeKind }) => typeKind === "mood")
              .map(({ id, name, relationList }) => ({ id, name, relationList: relationList! })) ?? []
          }
          onSave={updateGenre}
          onCancel={tagModal.off}
        />
      </Modal>
      <LabelGroup>
        <label>{`${selectedLicense === "VL" ? "녹음연도*" : "녹음연도"}`}</label>
        <RowGroup>
          <input
            type="number"
            key={track.no}
            required={selectedLicense === "VL"}
            placeholder="녹음연도를 입력하세요"
            min={0}
            max={9999}
            step={1}
            defaultValue={track.trackLicenseExtra.record_year ? parseInt(track.trackLicenseExtra.record_year) : undefined}
            onChange={e => {
              dispatch([
                AlbumActions.setTrackExtraRecord({ no: track.no, recordYear: parseInt(e.target.value) }),
                AlbumActions.setTrackExtraPublish({ no: track.no, publishDate: parseInt(e.target.value) }),
                AlbumActions.setTrackExtraEdition({ no: track.no, firstEdition: parseInt(e.target.value) })
              ]);
            }}
          />
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>발매연도</label>
        <RowGroup>
          <input
            type="number"
            key={track.no}
            required={selectedLicense === "VL"}
            placeholder="발매연도를 입력하세요"
            min={0}
            max={9999}
            step={1}
            defaultValue={track.trackLicenseExtra.publish_year ? parseInt(track.trackLicenseExtra.publish_year) : undefined}
            value={track.trackLicenseExtra.publish_year ? parseInt(track.trackLicenseExtra.publish_year) : undefined}
            onChange={e => dispatch(AlbumActions.setTrackExtraPublish({ no: track.no, publishDate: parseInt(e.target.value) }))}
          />
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>초판 발매연도</label>
        <RowGroup>
          <input
            type="number"
            key={track.no}
            required={selectedLicense === "VL"}
            placeholder="초판 발매연도를 입력하세요"
            min={0}
            max={9999}
            step={1}
            defaultValue={track.trackLicenseExtra.first_edition ? parseInt(track.trackLicenseExtra.first_edition) : undefined}
            value={track.trackLicenseExtra.first_edition ? parseInt(track.trackLicenseExtra.first_edition) : undefined}
            onChange={e => dispatch(AlbumActions.setTrackExtraEdition({ no: track.no, firstEdition: parseInt(e.target.value) }))}
          />
        </RowGroup>
      </LabelGroup>
    </InfoLayout>
  );
};

const TrackList = () => {
  const [{ album, trackList, isSingle, isEffect }, dispatch] = useAlbumStore(store => store.AlbumCreate);
  const [trackNo, setTrackNo] = useState("01");
  const subClass = !isEffect ? "트랙" : "효과음";
  const createNewTrack = () => {
    const inputTextCheck = document.getElementsByClassName("trackTitle")[0] as HTMLInputElement;
    if (!inputTextCheck.value.length) {
      alert(`현재 ${subClass}정보가 모두 작성되지 않았습니다.`);
    } else if (!Object.values(trackList[trackList.length - 1].trackUrl).filter(({ url }) => url).length) {
      alert("등록된 URL이 없습니다.");
    } else {
      dispatch(AlbumActions.setTrackRenamedNo());
      dispatch(
        AlbumActions.setNewTrack({
          no: (trackList.length + 1).toString(),
          subClass: !isEffect ? "track" : "effect",
          license: {
            notice: JSON.stringify({ text: `License : ${album.albumCompany.license}` }),
            country_code: "ZZ",
            type_track: album.albumCompany.type === "Cover" ? "Cover" : "VL",
            publish_date: album.releaseDate
          } as License
        })
      );
    }
  };

  const onOtherTrack = (no: string) => {
    const inputTextCheck = document.getElementsByClassName("trackTitle")[0] as HTMLInputElement;

    if (trackNo !== no) {
      if (!inputTextCheck.value.length) {
        alert(`현재 ${subClass}정보가 모두 작성되지 않았습니다.`);
        return;
      }

      setTrackNo(no);
    } else {
      if (window.confirm(`현재 ${subClass}을 삭제하시겠습니까?`)) {
        dispatch([AlbumActions.removeTrackByNo(no), AlbumActions.setTrackRenamedNo()]);
        setTrackNo(trackList[0].no);
      }
    }
  };

  return (
    <ListLayout>
      <TabsLayout>
        {trackList.map((value, index) => {
          return (
            <Tab
              key={value.no}
              role="button"
              aria-current={trackNo === value.no}
              length={trackList.length}
              onClick={() => onOtherTrack(value.no)}
            >
              {trackNo === value.no && trackList.length > 1 ? "-" : value.no}
            </Tab>
          );
        })}
        {!isSingle && (
          <Input.Button isWide color="primary" onClick={() => createNewTrack()}>
            +
          </Input.Button>
        )}
      </TabsLayout>
      <TrackInfo no={trackNo} />
    </ListLayout>
  );
};

export const TrackForm = () => {
  const dispatch = useAlbumDispatch();
  const { album, trackList, fileMode, isEffect } = useAlbumSelector(store => store.AlbumCreate);
  const subClass = !isEffect ? "트랙" : "효과음";
  const onPrevForm = () => dispatch(AlbumActions.setPageState("ALBUM"));

  const onNextForm = (event: FormEvent) => {
    const current = event.currentTarget;
    const inputTextCheck = current.getElementsByClassName("trackTitle")[0] as HTMLInputElement;
    const inputFiles = document.getElementsByClassName("files") as HTMLCollectionOf<HTMLInputElement>;
    const inputFileCheck = itiriri(inputFiles).some(element => (element.files && element.files.length ? true : false));
    const trackUrlCheck = itiriri(trackList).every(track => {
      if (!track.trackUrl) {
        return false;
      } else {
        return itiriri(Object.values(track.trackUrl)).some(url => (url.url ? true : false));
      }
    });

    if (!inputFileCheck && !trackUrlCheck && fileMode === FileMode.FILE) {
      alert("음악 파일이 업로드 되지 않은 트랙이 있습니다.");
      return;
    }

    if (!inputTextCheck.value.length) {
      alert(`${subClass}정보가 모두 작성되지 않았습니다.`);
    } else {
      const currentDate = new Date();

      trackList
        .filter(track => track.trackLicense.type_track === "ccl")
        .forEach(track => {
          const notice = JSON.parse(track.trackLicense.notice);
          notice.url = `https://creativecommons.org/licenses/${notice.type}/${notice.version}`;
          notice.text = `License filtered by Artists Card Inc. (updated ${currentDate.getFullYear()}. ${currentDate.getMonth() +
            1}. ${currentDate.getDate()}) \nLicense : ${notice.type === "CC0" ? notice.type : `CC ${notice.type} ${notice.version}`} ${
            notice.url
          }`;
          dispatch(AlbumActions.setTrackLicenseNotice({ no: track.no, notice: JSON.stringify(notice) }));
        });

      trackList
        .filter(track => track.trackLicense.type_track === "NoCopyright")
        .forEach(track => {
          const notice = JSON.parse(track.trackLicense.notice);
          notice.text = `License filtered by Artists Card Inc. (updated ${currentDate.getFullYear()}. ${currentDate.getMonth() +
            1}. ${currentDate.getDate()}) \nLicense : No Copyright \nArtist : ${album.albumArtist[0].artist_name} \nTitle : ${
            track.title
          } \n${track.sourceUrl ? track.sourceUrl : ""}
          `;
          dispatch(AlbumActions.setTrackLicenseNotice({ no: track.no, notice: JSON.stringify(notice) }));
        });

      trackList
        .filter(track => track.trackLicense.type_track !== "VL")
        .forEach(track => {
          if (!track.trackLicenseExtra.record_year) {
            dispatch(AlbumActions.setTrackExtraRecord({ no: track.no, recordYear: 0 }));
          }
          if (!track.trackLicenseExtra.publish_year) {
            dispatch(AlbumActions.setTrackExtraPublish({ no: track.no, publishDate: 0 }));
          }
          if (!track.trackLicenseExtra.first_edition) {
            dispatch(AlbumActions.setTrackExtraEdition({ no: track.no, firstEdition: 0 }));
          }
        });
      dispatch(AlbumActions.setPageState("SUBMIT"));
    }
  };

  return (
    <Layout
      onSubmit={event => {
        event.preventDefault();
        onNextForm(event);
      }}
    >
      <h2>{`${subClass} 정보 입력`}</h2>
      <Divider />
      <TrackList />
      <ButtonGroup>
        <Input.Button color="danger" isFill={false} onClick={onPrevForm}>
          이전 (앨범정보 입력)
        </Input.Button>
        <Input.Button color="danger" isFill type="submit">
          다음 (정보 확인 및 등록)
        </Input.Button>
      </ButtonGroup>
      <CustomMargin>{"-"}</CustomMargin>
    </Layout>
  );
};
