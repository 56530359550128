/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  metadataId: string;
  validCheck?: ValidType;
}>;

export const UpdateMetadataVC = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $validCheck = <ValidTypeScalar value={option.validCheck} isNullable={true} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_VC">
      <SelectionSet
        name="updateMetadata"
        alias="updateMetadataVC"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            valid_check: $validCheck
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataVC: Readonly<{
      metadataId: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
