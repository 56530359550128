/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IntScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
  parentId: number;
  order: number;
}>;

export const UpdateGoodsStructure = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const $parentId = <Variable name="parent_id" scalar={<IntScalar isNullable />} value={option.parentId} />;
  const $order = <Variable name="order" scalar={<IntScalar isNullable />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS_STRUCTURE">
      <SelectionSet
        name="updateGoods_structure"
        alias="updateGoodsStructure"
        argument={{
          where: { id: $id },
          data: {
            parent_id: $parentId,
            booking_order: $order
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoodsStructure: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
