/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar } from "graphql-jsx";

type Option = Readonly<{
  name: string;
  license: string;
  path: string;
  type: string;
  displayName?: string;
}>;

export const CreateRights = async (option: Option) => {
  const $name = <Variable name="name" scalar={<StringScalar isNullable={false} />} value={option.name} />;
  const $license = <Variable name="license" scalar={<StringScalar isNullable={false} />} value={option.license} />;
  const $path = <Variable name="path" scalar={<StringScalar isNullable={true} />} value={option.path} />;
  const $type = <Variable name="type" scalar={<StringScalar isNullable={true} />} value={option.type} />;
  const $displayName = <Variable name="displayName" scalar={<StringScalar isNullable={true} />} value={option.displayName} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_RIGHTS">
      <SelectionSet
        name="createCompany"
        argument={{
          data: {
            name: $name,
            license: $license,
            weight: 1,
            group_path: $path,
            type: $type,
            display_name: $displayName,
            company_title: {
              create: {
                language: {
                  connect: {
                    language_code: "OO"
                  }
                },
                type_company_title: "name",
                value: $name,
                exposure_order: 0
              }
            }
          }
        }}
      >
        <Field name="company_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createRights: {
      id: string;
    };
  }>;

  return await clients.company.request<Data>(query, variables);
};
