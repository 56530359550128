/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ServiceTypeScalar, DateTimeScalar } from "GraphQL/Scalars";
import { ServiceType } from "constants/ServiceType";

type Option = {
  service: ServiceType;
  productId: string;
  priceId: string;
  startDate: string;
  endDate: string;
  companyId?: string;
};

export type TrackPlayCount = Readonly<{
  product: string;
  track: string;
  playCount: number;
}>;

export const TrackPlayCount = async (option: Option) => {
  const $service = <ServiceTypeScalar isNullable={false} value={option.service} />;
  const $productId = <Variable name="product_id" scalar={<IdScalar isNullable={false} />} value={option.productId} />;
  const $priceId = <Variable name="price_id" scalar={<IdScalar isNullable={false} />} value={option.priceId} />;
  const $startDate = <DateTimeScalar isNullable={false} value={option.startDate} />;
  const $endDate = <DateTimeScalar isNullable={false} value={option.endDate} />;
  const $companyId = <Variable name="company_id" scalar={<IdScalar isNullable={true} />} value={option.companyId} />;
  const { query, variables } = render(
    <Query operationName="TRACK_PLAY_COUNT">
      <SelectionSet
        name="play_count"
        alias="trackPlayCount"
        argument={{
          first: 10,
          page: 1,
          where: {
            product_ids: $productId,
            price_ids: $priceId,
            service: $service,
            start_date: $startDate,
            end_date: $endDate,
            company_id: $companyId
          }
        }}
      >
        <Field name="product" />
        <Field name="track" />
        <Field name="play_count" alias="playCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    trackPlayCount: TrackPlayCount[];
  }>;
  return await clients.access.request<Data>(query, variables);
};
