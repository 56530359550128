import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_X_LARGE_PX, MARGING_LARGE_PX, PADDING_XX_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE } from "constants/size";
import { Input } from "App/Atomics/Input";
import { WHITE, GRAY_4, GRAY_0 } from "constants/baseColor";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { useRolesStore, useRolesSelector } from "App/Routes/Roles/Store";
import { UpdateRoleTitle } from "GraphQL/Queries/Role";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { RoleActions } from "App/Routes/Roles/Store/Role";
import { Toast } from "lib/toast";
import { Loading } from "App/Atomics/Loading";

type Props = {
  roleId: string;
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 27)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Multiline = styled.textarea`
  width: 100%;
  height: ${pixelize(UNIT * 6)};
  font-size: 14px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_4};
  border-radius: 4px;
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
  }

  .typeSelect {
    width: 50%;
    margin-bottom: ${MARGING_LARGE_PX};
    font-size: 12px;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

const TypeInfo = [{ id: "name", name: "name" }];

export const UpdateRoleTitleModal = ({ roleId, toClose }: Props) => {
  const { modalId } = useRolesSelector(store => store.Role);
  const [{ roleTitle, editLoading }, dispatch] = useRolesStore(store => ({
    roleTitle: store.Role.roleTitle.filter(item => item.id === modalId)[0],
    editLoading: store.Role.editLoading
  }));
  const [type, setType] = useState<string>(roleTitle.type);
  const [languageCode, setLanguageCode] = useState<string>(roleTitle.language[0].languageCode);
  const [value, setValue] = useState<string>(roleTitle.value);

  const updateRoleTitle = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(RoleActions.setRoleEditLoading(true));
    try {
      const { data } = await CreateAccessRecord({ targetId: roleId, targetTable: TargetTableInput.Role });
      if (data) {
        const { data: roleData } = await UpdateRoleTitle({
          uuid: modalId,
          type,
          languageCode,
          value
        });
        if (roleData) {
          dispatch(RoleActions.updateRoleTitle(modalId, type, value, languageCode));
        }
        await DeleteAccessRecord({ id: data.createAccess.id });
        dispatch(RoleActions.setRoleEditLoading(false));
        Toast.primary("수정되었습니다.", undefined, "bottom-right");
        toClose();
      }
    } catch (e) {
      console.log(e);
      dispatch(RoleActions.setRoleEditLoading(false));
      Toast.error("수정에 실패하였습니다", undefined, "top-center");
    }
  };

  return (
    <Layout>
      <RowGroup>
        <h2>수정</h2>
      </RowGroup>
      <RowGroup>
        <h4>타입 선택</h4>
        <Input.TextSelect
          className="typeSelect"
          optionList={TypeInfo}
          defaultValue={{ id: type, name: type }}
          onChange={info => {
            if (info) {
              setType(info.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>언어 선택</h4>
        <LanguageTextSelect
          key={!languageCode ? "" : languageCode}
          className="typeSelect"
          defaultValue={{ id: languageCode, name: languageCode }}
          onChange={info => {
            if (info) {
              setLanguageCode(info.id);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>설명</h4>
        <Multiline placeholder="설명을 입력하세요" defaultValue={value} onChange={e => setValue(e.target.value)} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="cancelButton" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button disabled={!type.length || !languageCode.length || !value.length} onClick={e => updateRoleTitle(e)}>
          수정
        </Input.Button>
      </ButtonGroup>
      <Loading loading={editLoading} />
    </Layout>
  );
};
