import React, { useState, useRef, ChangeEvent } from "react";
import styled from "styled-components";
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { GRAY_4, WHITE, GRAY_1, GRAY_2 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import {
  PADDING_SMALL_PX,
  PADDING_XX_LARGE_PX,
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  BORDER_RADIUS_PX,
  MARGING_SMALL_PX,
  MARGING_X_LARGE_PX,
  UNIT_PX,
  PADDING_LARGE_PX,
  widthViewportize
} from "constants/size";
import { useArtistStore } from "../../Store";
import { ArtistCreateActions } from "../../Store/Artist";
import { CompanyModal } from "../../Modals/CompanyModal";
import { useModal, Modal } from "lib/modal";
import { CheckDuplicatedArtist, CreateArtistUrl, DeleteAccessRecord, UpdateArtistUrl, UploadFile } from "GraphQL/Queries";
import { CreateArtist } from "GraphQL/Queries/Artist/CreateArtist";
import axios from "axios";
import { useToggle } from "lib/use-toggle";
import { SubmitModal } from "../../Modals/SubmitModal";
import { Toast } from "lib/toast";
import { createFormData } from "lib/createFormData";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";

const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 80%;
  height: 85%;
  padding: ${PADDING_XX_LARGE_PX} ${widthViewportize(0.4 * UNIT)};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};

  h2 {
    text-align: left;
    padding: ${PADDING_X_LARGE_PX} 0px;
  }

  .nextButton {
    margin-left: auto;
    margin-top: ${MARGING_X_LARGE_PX};
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    margin-top: ${MARGING_SMALL_PX};
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & > svg {
      position: relative;
      top: 4px;
    }
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 5)};
  grid-template-columns: 80% 20%;
  grid-gap: ${UNIT_PX};
  align-items: center;

  input {
    width: 100%;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }

  button {
    width: calc(100% - ${UNIT_PX});
  }
`;

const RadioGroup = styled.div`
  text-align: left;

  input[type="radio"] {
    width: min-content;
    margin-bottom: ${MARGING_SMALL_PX};
  }

  label {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: normal;

    & + input[type="radio"] {
      margin-left: ${MARGING_X_LARGE_PX};
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 10px;
  align-items: center;
  img {
    width: ${pixelize(UNIT * 6.25)};
    height: ${pixelize(UNIT * 6.25)};
  }
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 6.25)};
  height: ${pixelize(UNIT * 6.25)};
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  font-weight: bold;
`;

const CheckInputContainer = styled.div`
  position: relative;
  .checkbtn {
    position: absolute;
    width: ${pixelize(UNIT * 5)};
    right: 1%;
    bottom: 10%;
    z-index: 1;
    background: ${WHITE};
    border-radius: 8px;
    padding: ${PADDING_SMALL_PX};
    border: 1px solid ${GRAY_4};
    transition: all 0.2s;
    &:hover {
      background: ${GRAY_1};
    }
    &:active {
      font-weight: bold;
      background: ${GRAY_2};
      transform: translateY(1.5px);
    }
  }
`;

export const ArtistForm = () => {
  const [{ artist }, dispatch] = useArtistStore(store => ({
    artist: store.Artist.artist
  }));
  const [isDisabled, setBeDisabled] = useState<boolean>(true);
  const nameRef = useRef<HTMLInputElement>(null);
  const submitModal = useToggle();

  const onCreateArtist = async () => {
    try {
      const artistId = await CreateArtist({
        name: artist.name,
        type: artist.type_artist_class,
        companyId: artist.artistCompany.company_id,
        ytvCode: artist.ytvCode
      });
      return artistId;
    } catch (err) {
      Toast.error("아티스트 생성에 실패하였습니다.");
      console.log(err);
      return;
    }
  };

  const onCreateUrl = async (artistId: string) => {
    const accessId = await requestAccessRecord({ targetId: artistId, targetTable: TargetTableInput.Artist });
    if (accessId) {
      const { data: urlData, errors: urlErrors } = await CreateArtistUrl({
        artistId,
        typeUrl: "thumbnail",
        url: `imgs/artists/${artistId}`, // ? Fake Url before UploadFile
        order: 0
      });
      if (urlErrors) {
        window.alert("이미지 생성에 실패하였습니다.");
        await DeleteAccessRecord({ id: accessId });
        console.log(urlErrors);
        return;
      }
      if (urlData) {
        const uuid = urlData.createArtistUrl.artist_url[0].uuid;
        if (uuid) {
          const filename = `artists/${artistId}_${uuid}`;
          const { data: uploadData, errors: uploadErr } = await UploadFile({
            filename,
            path: "imgs"
          });
          if (uploadErr || !uploadData) {
            console.log(uploadErr);
            window.alert("S3 권한 요청에 실패하였습니다.");
            return;
          }
          if (uploadData.uploadFile) {
            await axios({
              method: "post",
              url: uploadData.uploadFile.url,
              data: createFormData(uploadData.uploadFile, artist.artistUrl.file),
              headers: {
                "Content-Type": "image/jpeg"
              }
            });
            await UpdateArtistUrl({ uuid, url: uploadData.uploadFile.key });
          }
        }
      }
      await DeleteAccessRecord({ id: accessId });
    }
  };

  const onCreate = async () => {
    const artistId = await onCreateArtist();
    if (artistId && artist.artistUrl) {
      await onCreateUrl(artistId);
    }
    window.alert("아티스트가 생성되었습니다.");
    window.location.reload();
  };

  const artistModal = useModal();
  const openCompanyModal = () => {
    const updateCompany: Parameters<typeof CompanyModal>[0]["onClick"] = async company => {
      if (company) {
        dispatch(
          ArtistCreateActions.setArtistCompany({
            company_id: company.id,
            name: company.name,
            license: company.license ? company.license : ""
          })
        );
      }
      artistModal.close();
    };
    artistModal.open(
      <CompanyModal
        company={artist.artistCompany ? { id: artist.artistCompany.company_id, name: artist.artistCompany.name } : undefined}
        onClick={updateCompany}
      />
    );
  };

  const checkDuplicatedChannel = async (e: React.MouseEvent<HTMLButtonElement>, name: string, type: string) => {
    e.stopPropagation();
    if (!type) {
      window.alert("타입을 선택해주세요.");
      return;
    }
    if (!name) {
      alert("이름을 입력해주세요.");
      nameRef.current!.focus();
      return;
    }
    const response = await CheckDuplicatedArtist({ name, type });
    if (response.errors) {
      alert("Check Error");
      return;
    }
    const { artist: artistData } = response.data!;
    if (artistData.length) {
      window.alert("중복된 이름이 있습니다. 다른 이름을 입력해주세요.");
      nameRef.current!.focus();
      setBeDisabled(true);
    } else {
      window.alert("중복된 이름이 없습니다.");
      setBeDisabled(false);
    }
  };

  const setArtistImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        ArtistCreateActions.setArtistThumb({
          file: file.files![0],
          value: event.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          name: file.files![0].name,
          valueType: file.files![0].type
        })
      );
    };
  };

  return (
    <Layout>
      <h2>아티스트 정보 입력</h2>
      <Divider />
      <LabelGroup>
        <label>타입</label>
        <RowGroup>
          <RadioGroup>
            <input
              type="radio"
              id="typePerson"
              name="artistType"
              value="person"
              onChange={e => {
                dispatch(ArtistCreateActions.setArtistType(e.currentTarget.value));
                setBeDisabled(true);
              }}
            />
            <label htmlFor="typePerson">개인</label>
            <input
              type="radio"
              id="typeOrganization"
              name="artistType"
              value="organization"
              onChange={e => {
                dispatch(ArtistCreateActions.setArtistType(e.currentTarget.value));
                setBeDisabled(true);
              }}
            />
            <label htmlFor="typeOrganization">그룹</label>
            <input
              type="radio"
              id="typeChannel"
              name="artistType"
              value="channel"
              onChange={e => {
                dispatch(ArtistCreateActions.setArtistType(e.currentTarget.value));
                setBeDisabled(true);
              }}
            />
            <label htmlFor="typeChannel">채널</label>
          </RadioGroup>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>이름</label>
        <RowGroup>
          <CheckInputContainer>
            <Input.Text
              ref={nameRef}
              placeholder="이름을 입력하세요."
              defaultValue={artist.name ? artist.name : ""}
              onBlur={value => dispatch(ArtistCreateActions.setArtistName(value))}
            />
            <button
              type="button"
              className="checkbtn"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                checkDuplicatedChannel(e, artist.name, artist.type_artist_class)
              }
            >
              중복 검사
            </button>
          </CheckInputContainer>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>유튜브 코드</label>
        <RowGroup>
          <CheckInputContainer>
            <Input.Text
              placeholder="유튜브 뮤직의 아티스트명을 그대로 입력해주세요."
              defaultValue={artist.ytvCode ? artist.ytvCode : ""}
              onBlur={value => dispatch(ArtistCreateActions.setArtistYtvCode(value))}
            />
          </CheckInputContainer>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>프로필 이미지</label>
        <RowGroup>
          <ImageContainer>
            {!artist.artistUrl ? <NoImage>NO IMAGE</NoImage> : <img src={URL.createObjectURL(artist.artistUrl.file)} alt="cover" />}
            <input accept="image/jpeg,image/png" type="file" onChange={setArtistImage} />
          </ImageContainer>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>권리사</label>
        <RowGroup onClick={openCompanyModal}>
          <Input.Text placeholder="권리사를 검색하세요." value={artist.artistCompany ? artist.artistCompany.name : ""} />
          <Input.Button color="primary">검색</Input.Button>
        </RowGroup>
      </LabelGroup>

      <Input.Button disabled={isDisabled || !artist.artistCompany} className="nextButton" color="warning" onClick={submitModal.on}>
        정보 확인
      </Input.Button>
      <Modal isOpen={submitModal.isToggled}>
        <SubmitModal toCreate={onCreate} toCancel={submitModal.off} />
      </Modal>
    </Layout>
  );
};
