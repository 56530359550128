import { createStore, combineReducers } from "lib/store";
import { afterChanges, afterDispatches } from "utils/store";
import { reducer as Role } from "./Role";

const reducers = { Role };

const reducer = combineReducers(reducers, afterDispatches, afterChanges);

export const {
  StoreProvider: RoleStoreProvider,
  useStore: useRoleStore,
  useSelector: useRoleSelector,
  useDispatch: useRoleDispatch
} = createStore(reducer);
