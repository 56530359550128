import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { RED_8, BLACK } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { HEADER_HEIGHT_PX, MARGING_LARGE_PX, UNIT, pixelize, MARGING_SMALL_PX, PADDING_XX_LARGE_PX } from "constants/size";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAppStore } from "App/Store";
import { TableTemplate } from "App/Templates/TableTemplate";
import { Pagination } from "App/Molecules/Pagination";
import { useRightStore } from "../Store";
import { RIGHTS } from "constants/route";
import { DataTemplate } from "./DataTemplate";
import { LoadingTemplate } from "App/Templates/LoadingTemplate";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR } from "constants/color";
import { useQueryParams } from "lib/use-query-params";
import { useHistory } from "react-router";
import { RightsActions } from "../Store/Right";
import { mediaQuery } from "constants/media";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

const Layout = styled.div`
  display: inherit;
  overflow: auto;

  width: 100%;
  height: 100vh;

  table {
    margin-top: ${HEADER_HEIGHT_PX};

    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }

    & svg {
      width: ${pixelize(UNIT * 1)};
      height: ${pixelize(UNIT * 0.8)};
      margin-left: ${MARGING_SMALL_PX};
      cursor: pointer;

      &:hover {
        fill: ${RED_8};
      }
    }
  }
`;

const SeachForm = styled.form`
  display: flex;

  ${mediaQuery(765)} {
    display: none;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const SearchBar = styled(Input.Group)`
  position: relative;
  min-width: ${pixelize(UNIT * 22)};
  margin-left: auto;
  grid-template-columns: auto min-content;

  & > svg {
    position: absolute;
    top: 10px;
    width: ${pixelize(1.25 * UNIT)};
    height: ${pixelize(1.25 * UNIT)};
  }

  input {
    color: ${BLACK};
    background-color: transparent;
    padding-left: ${PADDING_XX_LARGE_PX};
    margin-right: ${MARGING_LARGE_PX};
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: 0;
    transition: border-bottom 0.5s;
  }
`;

const CountSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 7.5)};
  min-width: ${pixelize(UNIT * 7)};
  margin-left: ${MARGING_LARGE_PX};
`;

const SearchColumnSelect = styled(Input.TextSelect)`
  width: 7rem;
  margin-right: 1rem;

  .searchColumn__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .searchColumn__menu {
    .searchColumn__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const RightsCountInfo = [
  { id: "10", name: "10개 보기" },
  { id: "20", name: "20개 보기" },
  { id: "30", name: "30개 보기" },
  { id: "40", name: "40개 보기" },
  { id: "50", name: "50개 보기" },
  { id: "100", name: "100개 보기" }
];

const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "name", name: "이름" }
];

export const RightsRightTable = () => {
  const [{ loading }] = useAppStore(store => ({
    loading: store.Loading.loading
  }));
  const [{ right, edge, count }, dispatch] = useRightStore(store => ({
    right: store.Right.right,
    edge: store.Right.edge,
    count: store.Right.count
  }));
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const router = useHistory();
  const queryParams = useQueryParams();
  const searchStringRef = useRef<HTMLInputElement>(null);
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  useAsyncEffect(async isMounted => {
    if (isMounted()) {
    }
  }, []);

  const rightSearch = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (searchStringRef.current) {
        const searchStr = searchStringRef.current.value;
        if (!searchStr) {
          router.push(`${RIGHTS}?page=1`);
          return;
        }
        if (searchType === SearchColumnList[0].id && !searchStr.match(/^[0-9]+$/)) {
          window.alert("아이디는 숫자만 입력가능합니다.");
          return;
        }
        const encodeString = encodeURIComponent(searchStr);
        router.push(`${RIGHTS}?page=1&${searchType}=${encodeString}`);
      }
    },
    [router, searchType]
  );

  const getSearchString = (type: string) => queryParams.get(type, { default: undefined });

  const RIGHT_SUB_HEAD_LIST = ["ID", "이름", "표기명", "타입", "경로", "라이센스", "유효성", "#"];
  const RIGHT_HEAD_LIST = [{ name: "권리사", colSpan: RIGHT_SUB_HEAD_LIST.length }];

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <CountSelect
          optionList={RightsCountInfo}
          defaultValue={{ id: "CountSelect", name: `${count}개 보기` }}
          onChange={info => {
            if (info) {
              dispatch(RightsActions.setRightCount(parseInt(info.id)));
            }
          }}
        />
        <InputContainer>
          <SeachForm onSubmit={rightSearch}>
            <SearchColumnSelect
              classNamePrefix="searchColumn"
              optionList={SearchColumnList}
              defaultValue={SearchColumnList[1]}
              onChange={info => {
                if (info) {
                  setSearchType(info.id);
                }
              }}
            />
            <SearchBar>
              <SearchIcon />
              <Input.Text ref={searchStringRef} placeholder="검색 할 권리사 이름을 입력하세요."></Input.Text>
              <Input.Button type="submit" color="default">
                검색
              </Input.Button>
            </SearchBar>
          </SeachForm>
        </InputContainer>
      </TopbarTemplate>
      <TableTemplate
        headList={RIGHT_HEAD_LIST}
        subHeadList={RIGHT_SUB_HEAD_LIST}
        keyBy={data => data.id}
        dataList={right}
        Column={DataTemplate}
      />
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      <Pagination
        edge={edge}
        goTo={index => `${RIGHTS}?page=${index}${!getSearchString(searchType) ? "" : `&${searchType}=${getSearchString(searchType)}`}`}
      />
    </Layout>
  );
};
