/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";

export enum BookType {
  immediate = "immediate",
  booking = "booking"
}

type Props = Readonly<{
  value?: BookType;
  isNullable?: boolean;
}>;

const items = [BookType.immediate, BookType.booking];

export const BookTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="bookType" value={value} isNullable={isNullable} items={items} />
);
