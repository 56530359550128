import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsDetail/Style";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { v4 as uuidv4 } from "uuid";
import { GoodsArtist as TypeGoodsArtist } from "GraphQL/Queries/Goods/LoadGoodsDetail";

type Info = {
  id: string;
  name: string;
};

type Props = {
  readonly artists: TypeGoodsArtist[];
};

export const GoodsArtist = ({ artists = [] }: Props): ReactElement => {
  const onAddArtist = () => {
    const tempId = uuidv4();
    GoodsDetailStore.dispatch(GoodsDetailActions.addArtist(tempId));
  };
  const onUpdateArtist = (index: number, artist: TypeGoodsArtist) =>
    GoodsDetailStore.dispatch(GoodsDetailActions.updateArtist({ index, artist }));
  const onRemoveArtist = (index: number) => GoodsDetailStore.dispatch(GoodsDetailActions.removeArtist(index));
  return (
    <Style.Row>
      <Style.Caption>아티스트</Style.Caption>
      <Style.ColContainer>
        {artists.map((artist, index) => (
          <Style.RowContainer key={artist.uuid}>
            <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveArtist(index)}>
              -
            </Style.RoundButton>
            <ArtistTextAutoComplete
              style={SelectStyle}
              defaultValue={artist.artist[0]}
              onBlur={info => {
                info && onUpdateArtist(index, { uuid: artist.uuid, order: artist.order, artist: [{ id: info.id, name: info.name }] });
              }}
            />
          </Style.RowContainer>
        ))}
        <Style.RowContainer>
          <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddArtist}>
            +
          </Style.RoundButton>
        </Style.RowContainer>
      </Style.ColContainer>
    </Style.Row>
  );
};
