import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { Input } from "App/Atomics/Input";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import defaultImage from "assets/images/cover.png";
import { configs } from "configs";
import { GRAY_2, GRAY_6 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, PRIMARY_COLOR } from "constants/color";
import { mediaQuery } from "constants/media";
import { ALBUMS, WORKS } from "constants/route";
import debounce from "debounce-promise";
import { useQueryParams } from "lib/use-query-params";
import { useOutsideClick } from "lib/useOutsideClick";
import React, { FormEvent, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { findWorkAlbumList } from "./findMetadataList";

type Props = {
  onChangeSearchTypeInfo: (info: Info | null) => void;
  onSearch: (e: FormEvent<HTMLFormElement>, searchString: string) => void;
  optionColumnList?: Info[];
  route?: string;
  searchType?: string;
};

const Layout = styled.form`
  position: relative;
  display: flex;

  ${mediaQuery(765)} {
    display: none;
  }
`;

const SearchBar = styled(Input.Group)`
  position: relative;
  min-width: 25rem;
  margin-left: auto;
  grid-template-columns: auto min-content;

  & > svg {
    position: absolute;
    top: 10px;
    width: 1.25rem;
    height: 1.25rem;
  }

  input {
    background-color: transparent;
    padding-left: 2rem;
    margin-right: 1rem;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: 0;
    transition: border-bottom 0.5s;
  }
`;

const SearchColumnSelect = styled(Input.TextSelect)`
  width: 7rem;
  margin-right: 1rem;

  .searchColumn__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .searchColumn__menu {
    .searchColumn__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

export const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "title", name: "타이틀" },
  { id: "artist", name: "아티스트" }
];

export const SearchForm = ({ onChangeSearchTypeInfo, onSearch, optionColumnList, route, searchType }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const [infoList, setInfoList] = useState<readonly Info[]>([]);
  const [showInfoList, setShowInfoList] = useState<boolean>(false);
  const router = useHistory();
  const queryParams = useQueryParams();
  const filter = queryParams.get("filter", { default: undefined });
  const typeClass = route === WORKS ? "work" : "record";
  useOutsideClick(infoRef, () => setShowInfoList(false));

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    const searchString = inputRef.current!.value.trim();
    setShowInfoList(false);
    onSearch(e, searchString);
  };
  const onMovePage = (route: string, id: string) => {
    router.push(`${route}?page=1&id=${id}${!filter ? "" : `filter=${filter}`}`);
    setShowInfoList(false);
  };

  const createSuggestionInfoLoader = () => {
    switch (route) {
      case WORKS:
      case ALBUMS:
        return debounce(
          async (keyword: string, callback: (infoList: readonly Info[]) => void) =>
            Promise.resolve(findWorkAlbumList(keyword, typeClass, searchType)).then(item => {
              if (item !== null) {
                callback(item);
              }
            }),
          300
        );
      default:
        return () => {};
    }
  };
  const loadSuggestionInfoByKeyword = useMemo(createSuggestionInfoLoader, [searchType]);

  return (
    <Layout onSubmit={onSubmit}>
      <SearchColumnSelect
        classNamePrefix="searchColumn"
        optionList={!optionColumnList ? SearchColumnList : optionColumnList}
        defaultValue={!optionColumnList ? SearchColumnList[1] : optionColumnList[1]}
        onChange={onChangeSearchTypeInfo}
      />
      <SearchBar>
        <SearchIcon />
        <Input.Text
          ref={inputRef}
          onChange={keyword => {
            if (keyword.length <= 1) {
              setShowInfoList(false);
              return;
            }
            !showInfoList && setShowInfoList(true);
            searchType !== "id" && loadSuggestionInfoByKeyword(keyword, setInfoList);
          }}
        />
        <Input.Button type="submit" color="default">
          검색
        </Input.Button>
        {!infoList.length || !showInfoList || (searchType !== "title" && searchType !== "artist") ? null : (
          <InputSearchAutoComplete ref={infoRef}>
            <ul className="auto-complete-content">
              {infoList.map(({ id, name, extra }) => {
                const src = !extra.url ? defaultImage : `${configs.urls.image}/${extra.url}?mode=s`;
                return (
                  <li key={id} onClick={() => route && onMovePage(route, id)}>
                    <div className="item">
                      <div className="cover-wrapper">
                        <img src={src} alt="" />
                      </div>
                      <div className="info-area">
                        <span className="title">{name}</span>
                        <span className="artist">{extra.artist}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </InputSearchAutoComplete>
        )}
      </SearchBar>
    </Layout>
  );
};

const InputSearchAutoComplete = styled.div`
  position: absolute;
  max-height: 285px;
  overflow: scroll;
  top: 43px;
  left: 0;
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 4px;
  background: #fff;
  .auto-complete-content {
    position: relative;
    left: 0px;
    top: 0px;
    width: 22.5rem;
    li {
      border-radius: 8px;
      &:hover {
        background-color: #eee;
      }
      .item {
        display: block;
        height: 55px;
        padding: 7px;
        cursor: pointer;
        .cover-wrapper {
          display: table-cell;
          overflow: hidden;
          vertical-align: middle;
          align-items: center;
          img {
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 0.5rem;
            background-color: ${GRAY_2};
            border: 1px solid rgba(255, 255, 255, 0.15);
            border-radius: 2px;
          }
        }

        .info-area {
          display: table-cell;
          width: 100%;
          overflow: hidden;
          span {
            overflow: hidden;
            --webkit-line-break: 1;
            --webkit-box-orient: vertical;
            text-overflow: ellipsis;
            white-space: normal;
            word-break: break-all;
            display: -webkit-box;
          }
          .title {
            height: 22px;
            line-height: 22px;
            font-size: 12px;
          }
          .artist {
            height: 17px;
            line-height: 17px;
            font-size: 11px;
            color: ${GRAY_6};
          }
        }
      }
    }
  }
`;
