import React, { ChangeEvent, useState, useRef } from "react";
import styled from "styled-components";
import { pixelize, UNIT } from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_6, GRAY_2, GRAY_1 } from "constants/baseColor";
import { Slider } from "App/Molecules/Slider";
import { configs } from "configs";
import coverImage from "assets/images/cover.png";
import { Input } from "App/Atomics/Input";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { ImageCropModal } from "../ImageCropModal";
import { usePlaylistsDispatch } from "App/Routes/Playlists/Store";
import { CreateAccessRecord, DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeletePlaylistUrl } from "GraphQL/Queries/Playlist";
import { SelectEditTypeModal } from "App/Routes/GenreRenewal/RightGenreTable/Modals";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";

type ImageState = {
  imageData: string | undefined;
  imageType: string | undefined;
  uuid: string | undefined;
  ext: string;
  action: string;
  url: string | undefined;
  file: File | undefined;
};

type Url = {
  uuid: string;
  typeUrl?: string;
  url?: string;
  validCheck: string;
  order?: number;
};

type Props = {
  index: number;
  id: string;
  playlistUrl: Url[];
  toClose: () => void;
};
const Layout = styled.div`
  width: ${pixelize(UNIT * 55)};
  height: ${pixelize(UNIT * 45)};
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    background-image: url(${coverImage});
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;
const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 10%;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    right: 3%;
    width: 35px;
    height: 35px;
    padding: 0.5rem;
    fill: black;
    border-radius: 50%;
    background-color: ${GRAY_2};
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
      background-color: ${GRAY_1};
    }
  }
`;

const Section = styled.section`
  margin: 0.5rem auto;
  padding: 2rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15%;
  background-color: #fff;
`;

const ImageLabel = styled.label`
  span {
    font-weight: 100;
    color: #eee;
    font-size: 1.3rem;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  input {
    display: none;
  }
  img {
    object-fit: fill;
    padding: 0;
    height: ${pixelize(UNIT * 30)};
    cursor: pointer;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row-reverse;
  margin-right: 1rem;
  .btn {
    width: ${pixelize(UNIT * 8)};
    margin-right: 0.5rem;
  }
  input[type="file"] {
    display: none;
  }
`;

type ImageSelectWrapperProps = Url & {
  id: string;
  index: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const ImageSelectWrapper = ({ id, index, uuid, url, typeUrl, onChange }: ImageSelectWrapperProps) => {
  const dispatch = usePlaylistsDispatch();
  const currentFileRef = useRef<HTMLInputElement>(null);
  const selectEditTypeModal = useToggle();
  const openImageSelect = () => {
    currentFileRef.current!.click();
  };

  const onDeleteImage = async () => {
    if (!window.confirm("삭제한 이미지는 복구할 수 없습니다. 정말로 삭제하시겠습니까?")) {
      selectEditTypeModal.off();
      return;
    }
    try {
      const filename = url;
      const { data: deleteData, errors: deleteErr } = await DeleteFile({
        filename,
        path: "imgs",
        fileType: FileType.FILE,
        book: BookType.immediate
      });
      if (deleteErr && deleteErr.length) {
        console.log(deleteErr[0].message);
        Toast.error("삭제 요청을 실패하였습니다", undefined, "top-center");
        return;
      }
      if (deleteData) {
        const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
        if (accessData) {
          const accessId = accessData.createAccess.id;
          const { errors } = await DeletePlaylistUrl({ uuid });
          if (errors) {
            console.log(errors);
            Toast.error("이미지 삭제를 실패하였습니다", undefined, "top-center");
            await DeleteAccessRecord({ id: accessId });
            return;
          }
          await DeleteAccessRecord({ id: accessId });
          Toast.primary("삭제되었습니다", undefined, "top-center");
          dispatch(PlaylistsActions.deletePlaylistImage({ index, uuid }));
          return;
        }
      }
    } catch (err) {
      console.log(err);
      Toast.error("서버에서 에러가 발생하였습니다", undefined, "top-center");
      return;
    } finally {
      selectEditTypeModal.off();
    }
  };

  return (
    <>
      <ImageLabel key={uuid}>
        <input ref={currentFileRef} type="file" accept="image/*" onChange={onChange} />
        <img
          alt={typeUrl}
          src={`${configs.urls.image}/${url}${url!.includes("?") ? "&" : "?"}${typeUrl === "head" ? `size=640x360` : `mode=l`}`}
          onClick={e => {
            e.preventDefault();
            selectEditTypeModal.on();
          }}
        />
        <span>{typeUrl}</span>
      </ImageLabel>
      <Modal isOpen={selectEditTypeModal.isToggled}>
        <SelectEditTypeModal onEdit={openImageSelect} onDelete={onDeleteImage} toClose={selectEditTypeModal.off} />
      </Modal>
    </>
  );
};

export const ImageViewModal = ({ index, id, playlistUrl, toClose }: Props) => {
  const [imageState, setImageState] = useState<ImageState>({
    file: undefined,
    imageData: undefined,
    imageType: undefined,
    uuid: undefined,
    ext: "",
    action: "INSERT",
    url: undefined
  });
  const { imageData, imageType, uuid, ext, url, file, action } = imageState;
  const inputFileRef = useRef<HTMLInputElement>(null);
  const imageCropModal = useToggle();
  const onChangeInputFile = (e: ChangeEvent<HTMLInputElement>, action: string, uuid?: string, typeUrl?: string, url?: string) => {
    const { files } = e.currentTarget;
    if (files?.length) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onloadend = e => {
        if (e.target && e.target.result) {
          let image = new Image();
          image.src = e.target.result as string;
          image.onload = () => {
            if (image.naturalWidth < 300 || image.naturalHeight < 300) {
              window.alert("이미지의 가로 / 세로 길이가 300이상 이어야합니다.");
              return;
            }
            let ext;
            switch (files[0].type) {
              case "image/jpeg":
                ext = "jpg";
                break;
              case "image/png":
                ext = "png";
                break;
              case "image/webp":
                ext = "webp";
                break;
              default:
                return;
            }
            setImageState({
              file: files[0],
              imageData: fileReader.result! as string,
              imageType: typeUrl,
              uuid: uuid,
              ext: ext,
              action,
              url
            });
            imageCropModal.on();
          };
        }
      };
    } else {
      window.alert("파일 정보를 불러올 수 없습니다.");
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h2>플레이리스트 이미지 변경</h2>
        <CancelIcon onClick={toClose} />
      </Header>
      <Section>
        <Slider>
          {!playlistUrl.length ? (
            <ImageLabel>
              <input type="file" accept="image/*" onChange={e => onChangeInputFile(e, "INSERT")} />
              <img src={coverImage} alt="defaultCover" />
              <span>이미지가 없습니다.</span>
            </ImageLabel>
          ) : (
            playlistUrl.map(url => (
              <ImageSelectWrapper
                key={url.uuid}
                id={id}
                index={index}
                {...url}
                onChange={e => onChangeInputFile(e, "UPDATE", url.uuid, url.typeUrl, url.url)}
              />
            ))
          )}
        </Slider>
      </Section>
      <Footer>
        <ButtonGroup>
          <input ref={inputFileRef} type="file" name="image" accept="image/*" onChange={e => onChangeInputFile(e, "INSERT")} />
          <Input.Button className="btn" color="primary" onClick={() => inputFileRef.current!.click()}>
            이미지 추가
          </Input.Button>
          <Input.Button className="btn" onClick={toClose}>
            취소
          </Input.Button>
        </ButtonGroup>
      </Footer>
      <Modal isOpen={imageCropModal.isToggled}>
        <ImageCropModal
          index={index}
          uuid={uuid}
          src={imageData ? imageData : ""}
          typeUrl={imageType}
          url={url}
          file={file}
          action={action}
          ext={ext}
          toClose={toClose}
        />
      </Modal>
    </Layout>
  );
};
