import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { WorkCreateActions as Work } from "./Work";

const reducers = { Work };

const reducer = combineReducers(reducers, createStoreLogger("WorkCreate"));

export const {
  StoreProvider: WorkStoreProvider,
  useStore: useWorkStore,
  useSelector: useWorkSelector,
  useDispatch: useWorkDispatch
} = createStore(reducer);
