import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import {
  SECONDARY_TOGGLE_ON,
  SUCCESS_TOGGLE_ON,
  WARNING_TOGGLE_ON,
  DANGER_TOGGLE_ON,
  SECONDARY_TOGGLE_OFF,
  SUCCESS_TOGGLE_OFF,
  INFO_TOGGLE_OFF,
  INFO_TOGGLE_ON,
  WARNING_TOGGLE_OFF,
  DANGER_TOGGLE_OFF,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DISABLED
} from "constants/color";

type Color = "primary" | "secondary" | "info" | "success" | "warning" | "danger";

type Size = "small" | "medium" | "big";

type Props = Styleable & {
  id: string;
  color?: Color;
  size?: Size;
  isActive: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SliderSpan = styled.span<{ size: string }>`
  width: ${props => (props.size === "big" ? "45px" : props.size === "medium" ? "30px" : "22px")};
  height: ${props => (props.size === "big" ? "46px" : props.size === "medium" ? "30px" : "22px")};
  border-radius: ${props => (props.size === "big" ? "45px" : props.size === "medium" ? "30px" : "22px")};
  position: absolute;

  top: 2px;
  left: 2px;
  content: "";

  background: #fff;
  transition: 0.3s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
`;

const SliderLabel = styled.label<{ isActive: boolean; size: string }>`
  width: ${props => (props.size === "big" ? "100px" : props.size === "medium" ? "65px" : "47px")};
  height: ${props => (props.size === "big" ? "50px" : props.size === "medium" ? "34px" : "26px")};
  border-radius: ${props => (props.size === "big" ? "100px" : props.size === "medium" ? "65px" : "47px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  position: relative;
  transition: background-color 0.2s;

  &:active ${SliderSpan} {
    width: ${props => (props.size === "big" ? "60px" : props.size === "medium" ? "40px" : "28px")};
  }

  &.primary {
    background: ${PRIMARY_COLOR_DISABLED};
    ${props => props.isActive && `background-color: ${PRIMARY_COLOR}`};
  }

  &.secondary {
    background: ${SECONDARY_TOGGLE_OFF};
    ${props => props.isActive && `background-color: ${SECONDARY_TOGGLE_ON}`};
  }

  &.info {
    background: ${INFO_TOGGLE_OFF};
    ${props => props.isActive && `background-color: ${INFO_TOGGLE_ON}`};
  }

  &.success {
    background: ${SUCCESS_TOGGLE_OFF};
    ${props => props.isActive && `background-color: ${SUCCESS_TOGGLE_ON}`};
  }

  &.warning {
    background: ${WARNING_TOGGLE_OFF};
    ${props => props.isActive && `background-color: ${WARNING_TOGGLE_ON}`};
  }

  &.danger {
    background: ${DANGER_TOGGLE_OFF};
    ${props => props.isActive && `background-color: ${DANGER_TOGGLE_ON}`};
  }
`;

const SliderInput = styled.input<{ isActive: boolean }>`
  width: 0;
  height: 0;
  visibility: hidden;

  & + ${SliderLabel} ${SliderSpan} {
    ${props =>
      props.isActive
        ? `left: calc(100% - 2px);
    transform: translateX(-100%);`
        : ``}
  }
`;

/** `ToggleSwitch` : on/off를 조절하는 스위치입니다. */
const ToggleSwitch = ({ className, style, id, size = "small", color = "primary", isActive, onChange }: Props) => {
  return (
    <>
      <SliderInput id={id} type="checkbox" isActive={isActive} onChange={onChange} />
      <SliderLabel className={classNames(className, color)} size={size} style={style} isActive={isActive} color={color} htmlFor={id}>
        <SliderSpan size={size} />
      </SliderLabel>
    </>
  );
};

export default ToggleSwitch;
