import React, { ComponentType, useCallback } from "react";
import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findGoodsList } from "./findGoodsList";
import { components, OptionProps } from "react-select";
import { GRAY_5 } from "constants/baseColor";
import styled from "styled-components";
import { PRIMARY_COLOR_LIGHT } from "constants/color";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  | "style"
  | "className"
  | "classNamePrefix"
  | "isDisabled"
  | "defaultValue"
  | "defaultOptions"
  | "onChange"
  | "onBlur"
  | "autoFocus"
  | "filterOption"
  | "placeholder"
> & { type?: string; typeNot?: string };

export const GoodsTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  autoFocus,
  filterOption,
  placeholder = "상품명을 입력해주세요.",
  type,
  typeNot
}: Props) => {
  const onLoadInfoList = useCallback((info: string) => findGoodsList({ keyword: info, type, typeNot }), [type, typeNot]);
  return (
    <TextAutoComplete
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      filterOption={filterOption}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      loadInfoList={info => onLoadInfoList(info)}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <Id>{props.data.id}</Id>
        <Text>{`${children}`}</Text>
        <Extra>{`${props.data.extra.goodsType.displayName} - ${props.data.extra.artist}`}</Extra>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const Id = styled.span`
  font-size: 9px;
  color: ${GRAY_5};
`;

const Text = styled.span``;

const Extra = styled.span`
  font-size: 11px;
  color: ${PRIMARY_COLOR_LIGHT};
`;
