import { createDuck } from "lib/store/v2";
import { Track } from "../TrackList";
import itiriri from "itiriri";

type State = ReturnType<typeof createInitialState>;

export type StructureEvents = State["events"];

export type Structure = {
  metadata: any;
  child: any[];
};

/**
 * from, to => structures index number
 */
type MoveEvent = {
  type: "Move";
  from: string;
  to: string;
  structureId: string;
  order: number;
};

type OrderEvent = {
  type: "Order";
  from: string;
  to: string;
  structureId: string;
  order: number;
};

type EventLog = MoveEvent | OrderEvent;

const createInitialState = () => ({
  structures: [] as Structure[],
  events: {
    updateLog: new Map<string, Record<string, EventLog>>()
  },
  mounted: true
});

export const StructureActions = createDuck({
  namespace: "AlbumStructure",
  createInitialState,

  reducers: {
    setAlbumStructure(state, structures: any[]) {
      state.structures = structures;
    },
    editAlbumTrackTitleByIndex(state, { title, index, childIndex }: { title: string; index: number; childIndex?: number }) {
      const album = state.structures[index];
      if (childIndex === undefined) {
        album.metadata[0].title = title;
      } else {
        album.child[childIndex].metadata[0].title = title;
      }
    },
    createAlbumTrackDescByIndex(state, { desc, index, childIndex }: { desc: any; index: number; childIndex?: number }) {
      const album = state.structures[index];
      if (childIndex === undefined) {
        album.metadata[0].titleRelation.push(desc);
      } else {
        album.child[childIndex].metadata[0].titleRelation.push(desc);
      }
    },
    editAlbumTrackDescByIndex(state, { desc, index, childIndex }: { desc: string; index: number; childIndex?: number }) {
      const album = state.structures[index];
      if (childIndex === undefined) {
        album.metadata[0].titleRelation[0].value = desc;
      } else {
        album.child[childIndex].metadata[0].titleRelation[0].value = desc;
      }
    },
    editAlbumGroupTitleByIndex(state, { index, title }: { index: number; title: string }) {
      state.structures[index].metadata[0].title = title;
    },
    movedAlbumTrack(state, { index, preTracks, tracks }: { index: number; preTracks: Track[]; tracks: Track[] }) {
      const { updateLog } = state.events;
      const removedTracks = preTracks.filter(track => tracks.findIndex(value => track.id === value.id) === -1);

      if (removedTracks.length) {
        removedTracks.forEach(track => {
          if (index === track.extra?.index) {
            movedEventLog(track.id, updateLog, track.extra!.index.toString(), "-1", track.extra);
          } else {
            movedEventLog(track.id, updateLog, track.extra!.index.toString(), track.extra!.index.toString(), track.extra);
          }
        });
      }

      itiriri(tracks).forEach((track, idx) => {
        const preIndex = track.extra?.index !== undefined ? track.extra!.index.toString() : "-1";
        movedEventLog(track.id, updateLog, preIndex, index.toString(), {
          index: track.extra?.index,
          structureId: track.extra?.structureId,
          order: idx + 1
        });
      });
    },
    orderedAlbumItem(
      state,
      { index, metadataId, structureId, order }: { index: number; metadataId: string; structureId: string; order: number }
    ) {
      const { updateLog } = state.events;
      const targetItem = state.structures[index].metadata[0];
      orderedEventLog(metadataId, updateLog, (targetItem.metadataStructure[0].order + 1).toString(), (order + 1).toString(), {
        structureId: structureId,
        order: targetItem.metadataStructure[0].order
      });
    },
    deleteAlbumTrack(state, id: string) {
      const filtered = state.structures.filter(({ metadata }) => metadata[0].metadataId !== id);
      state.structures = filtered;
    },
    clearEventLog(state) {
      state.events.updateLog.clear();
    },

    toggleMountedFlag(state) {
      state.mounted = !state.mounted;
    }
  }
});

const movedEventLog = (index: string, eventMap: Map<string, Record<string, EventLog>>, from: string, to: string, extra: any) => {
  if (!eventMap.has(index)) {
    if (from === to) {
      return;
    }

    eventMap.set(index, {});
  }

  const eventLogs = eventMap.get(index)!;

  if (!eventLogs[index]) {
    eventLogs[index] = { type: "Move", from, to, structureId: extra.structureId, order: extra.order };
  } else {
    if (eventLogs[index].from === to) {
      eventLogs[index] = { type: "Order", from, to, structureId: extra.structureId, order: extra.order };
    } else {
      eventLogs[index].to = to;
    }
  }
};

const orderedEventLog = (index: string, eventMap: Map<string, Record<string, EventLog>>, from: string, to: string, extra: any) => {
  if (!eventMap.has(index)) {
    eventMap.set(index, {});
  }

  const eventLogs = eventMap.get(index)!;
  if (!eventLogs[index]) {
    eventLogs[index] = { type: "Order", from, to, structureId: extra.structureId, order: extra.order };
  } else {
    eventLogs[index].to = to;
  }
};
