/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  uuid?: string;
  artistId?: string;
  roleId?: string;
  characterId?: string;
  order: number;
  validCheck: ValidType;
}>;

export const UpdateMetadataArtist = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $artistId = <Variable name="artist_id" scalar={<IdScalar isNullable={true} />} value={option.artistId} />;
  const $roleId = <Variable name="role_id" scalar={<IdScalar isNullable={true} />} value={option.roleId} />;
  const $characterId = <Variable name="character_id" scalar={<IdScalar isNullable={true} />} value={option.characterId} />;
  const $validCheck = <ValidTypeScalar value={option.validCheck} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_ARTIST">
      <SelectionSet
        name="updateMetadata_artist_relation"
        alias="updateMetadataArtist"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            artist: {
              connect: {
                artist_id: $artistId
              }
            },
            role: {
              connect: {
                role_id: $roleId
              }
            },
            character: {
              connect: {
                role_id: $characterId
              }
            },
            exposure_order: $order,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataArtist: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
