import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_X_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { RIGHTS_CREATE } from "constants/route";
import { PRIMARY_COLOR } from "constants/color";

type Company = Readonly<{
  id: string;
  name: string;
  license?: string;
}>;

type Props = Readonly<{
  company: Company | undefined;
  onClick: (company: Company | undefined) => void;
}>;

const RightPageSpan = styled.a`
  margin-left: auto;
  width: fit-content;
  font-size: 0.9rem;
  color: ${PRIMARY_COLOR};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 32.5)};
  height: ${pixelize(UNIT * 30)};
  padding: ${PADDING_XX_LARGE_PX};

  section {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

export const CompanyModal = ({ company: initCompany, onClick }: Props) => {
  const [suggest, setSuggest] = useState(initCompany ? initCompany : ({} as Company));

  const toSave = () => onClick(suggest);
  const toCancel = () => onClick(undefined);

  return (
    <Layout>
      <h3>권리사 검색</h3>
      <section>
        <CompanyTextAutoComplete
          className="autocomplete"
          onBlur={info => {
            if (info) {
              setSuggest(info);
            }
          }}
        />
      </section>
      <RightPageSpan href={RIGHTS_CREATE} target="_blank">
        권리사 등록하러 가기
      </RightPageSpan>
      <ButtonGroup>
        <Input.Button onClick={toSave}>입력</Input.Button>
        <Input.Button onClick={toCancel}>취소</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
