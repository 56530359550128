import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_6, VIOLET_4, VIOLET_5, VIOLET_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";
import { PRIMARY_COLOR } from "constants/color";
import { CommentListForm, CommentEditForm, CommentWriteForm } from "./CommentForm";
import { FloatingButton } from "App/Atomics/FloatingButton";
import { MainForm } from "./MainForm";
import { Prompt } from "react-router";
import { RecordForm } from "./RecordForm";

type Props = {
  index: number;
  id: string;
  toClose: () => void;
};

export enum MainPage {
  MAIN = "Main",
  COMMENT = "Comment",
  RECORD = "Record"
}

export enum SubPage {
  FIRST,
  SECOND,
  THIRD
}

export type Operate = "INSERT" | "UPDATE" | "DELETE";

export type TrackRecord = {
  id: string;
  metadata_id: string;
  title: string;
  artist: string;
  operate: Operate;
  timestamp: string;
};

export type ParsedComment = {
  data: TrackRecord[];
  text?: string;
};

export type EditComment = {
  uuid: string;
  value: ParsedComment;
};

export type InitComment = {
  uuid: string;
  value: ParsedComment;
  isReply?: boolean;
};

export const ExtraInfoModal = ({ index, id, toClose }: Props) => {
  const [mainPage, setMainPage] = useState<MainPage>(MainPage.MAIN);
  const [subPage, setSubPage] = useState<SubPage>(SubPage.FIRST);
  const [editComment, setEditComment] = useState<EditComment>({} as EditComment);
  const [initialComment, setInitialComment] = useState<InitComment>({} as InitComment);
  const onCreateCommentForm = () => {
    setMainPage(MainPage.COMMENT);
    setSubPage(SubPage.SECOND);
  };
  return (
    <Layout>
      <Prompt message="이전 페이지로 돌아가시겠습니까?" />
      {subPage === SubPage.FIRST && (
        <Header>
          <CommentIcon className="comment-icon" />
          <h3>부가 정보</h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </Header>
      )}
      <Section>
        {subPage === SubPage.FIRST && (
          <ul className="left-container">
            <li className={mainPage === MainPage.MAIN ? "active" : ""} onClick={() => setMainPage(MainPage.MAIN)}>
              가격 및 평가
            </li>
            <li className={mainPage === MainPage.COMMENT ? "active" : ""} onClick={() => setMainPage(MainPage.COMMENT)}>
              댓글 목록
            </li>
            <li className={mainPage === MainPage.RECORD ? "active" : ""} onClick={() => setMainPage(MainPage.RECORD)}>
              기록 관리
            </li>
          </ul>
        )}
        {mainPage === MainPage.MAIN && <MainForm index={index} id={id} onCreateCommentForm={onCreateCommentForm} />}
        {mainPage === MainPage.COMMENT &&
          (subPage === SubPage.FIRST ? (
            <CommentListForm id={id} setPage={setSubPage} setInitialComment={setInitialComment} setEditComment={setEditComment} />
          ) : subPage === SubPage.SECOND ? (
            <CommentWriteForm
              index={index}
              initialComment={initialComment}
              setInitialComment={setInitialComment}
              id={id}
              setPage={setSubPage}
            />
          ) : (
            <CommentEditForm id={id} editComment={editComment} setPage={setSubPage} />
          ))}
        {mainPage === MainPage.RECORD && <RecordForm id={id} />}
        {mainPage === MainPage.COMMENT && subPage === SubPage.FIRST && (
          <FloatingButton
            style={{ position: "absolute", left: "92%", top: "82%", zIndex: 999 }}
            color="primary"
            onClick={() => {
              setSubPage(SubPage.SECOND);
            }}
          >
            <CommentIcon className="floating-icon" />
          </FloatingButton>
        )}
      </Section>
    </Layout>
  );
};

const Layout = styled.div`
  overflow-y: hidden;
  position: relative;
  width: 1000px;
  height: 750px;
  background-color: #fff;
  font-size: 0.8rem;
  .floating-icon {
    width: 3.8rem;
    height: 2rem;
    fill: #fff;
    transform: translateY(2px);
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .comment-icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.6rem;
    fill: ${PRIMARY_COLOR};
    transform: translateY(2px);
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  position: relative;
  display: flex;
  height: 100%;
  .left-container {
    width: 7rem;
    border-right: 1px solid #eee;
    li {
      display: flex;
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      color: #fff;
      background-color: ${VIOLET_5};

      &:hover {
        background-color: ${VIOLET_4};
      }
      &:focus {
        background-color: ${VIOLET_6};
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 5rem;
    overflow-y: scroll;
  }
`;
