/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  email: string;
  password: string;
}>;

export const ResetUser = async (option: Option) => {
  const email = <Variable name="email" scalar={<IdScalar isNullable={false} />} value={option.email} />;
  const password = <Variable name="password" scalar={<StringScalar isNullable={false} />} value={option.password} />;
  const { query, variables } = render(
    <Mutation operationName="RESET_USER">
      <Field name="resetUser" argument={{ where: { email }, data: { password } }} />
    </Mutation>
  );
  type Data = Readonly<{
    resetUser: boolean;
  }>;
  return await clients.access.request<Data>(query, variables);
};
