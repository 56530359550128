/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
}>;

export const DeleteMetadataTitleRelation = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_METADATA_TITLE_RELATION">
      <SelectionSet
        name="deleteMetadata_title"
        alias="deleteMetadataTitleRelation"
        argument={{
          where: {
            id: $id
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteMetadataTitleRelation: {
      id: string;
    }[];
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
