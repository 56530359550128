import { AppStore, useAppSelector } from "App/Store";
import React, { FC } from "react";
import { Route } from "react-router";

type Props = Readonly<{
  path: string;
  component: FC;
  fallback: FC;
  exact?: boolean;
  isAuthorized: (store: AppStore) => boolean;
}>;

/**
 * * react-router의 Switch 컴포넌트는 props.path와 props.exact를 선제적으로 체크한 후 1번째로 match하는 컴포넌트만을 return 한다.
 */
export const AuthRoute = ({ path, component: Component, fallback: Fallback, isAuthorized }: Props) => {
  const store = useAppSelector(store => store);
  return isAuthorized(store) ? <Route path={path} component={Component} /> : <Fallback />;
};
