import React from "react";
import styled from "styled-components";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Metadata } from "GraphQL/Queries/Metadata/GetMetadata";
import { Table } from "App/Atomics/Table";
import { GRAY_6 } from "constants/baseColor";
import { Modal } from "lib/modal";
import { ChangeParentModal, EditInfoModal } from "App/Routes/WorkDetail/Modals";
import { useToggle } from "lib/use-toggle";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { TRACKS } from "constants/route";
import { useHistory } from "react-router";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";

type Align = "center" | "left" | "right";

const CustomData = styled(Table.Data)<{ align?: Align; width?: string }>`
  text-align: ${props => (!props.align ? ("center" as Align) : props.align)};
  width: ${props => (!props.width ? "min-content" : props.width)};

  .other {
    color: ${GRAY_6};
    margin-left: 0.2rem;
  }

  .elemntRelation {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .extra {
    color: ${GRAY_6};
  }
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    transition: fill 0.15s;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

export const TrackColumn = ({ index, data }: ColumnProps<Metadata>) => {
  const { metadataId, title, artistRelation, rightsCompany, elementRelation } = data;
  const artist = !artistRelation.length ? "-" : artistRelation[0].artist[0].name;
  const editInfoModal = useToggle();
  const router = useHistory();

  const openChangeParentModal = useToggle();

  const onMoveRelationTracks = () => {
    const url = elementRelation.map(({ listRelation }) => listRelation[0].id).join(",");
    router.push(TRACKS + `?page=1&ids=[${url}]`);
  };

  return (
    <>
      <Table.Row key={index} onClick={editInfoModal.on}>
        <CustomData width="8%">
          <ClipBoard text={metadataId} />
        </CustomData>
        <CustomData align="left">
          <IconWrapper>
            <ClipBoard text={title} />
            <div onClick={e => e.stopPropagation()}>
              <ShareIcon onClick={openChangeParentModal.on} />
            </div>
          </IconWrapper>
        </CustomData>
        <CustomData width="20%">
          <div>
            <span>{artist}</span>
            <span className="other">{artistRelation.length < 2 ? "" : `외 ${artistRelation.length - 1}명`}</span>
          </div>
        </CustomData>
        <CustomData width="15%">{!rightsCompany.length ? "-" : rightsCompany[0].company[0].name}</CustomData>
        <CustomData width="15%">
          {!elementRelation.length ? (
            "-"
          ) : (
            <div className="elemntRelation" onClick={onMoveRelationTracks}>
              <span className="title">{elementRelation[0].listRelation[0].title}</span>{" "}
              <span className="extra">{`${elementRelation.length < 2 ? "" : `외 ${elementRelation.length - 1}곡`}`}</span>
            </div>
          )}
        </CustomData>
      </Table.Row>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        {editInfoModal.isToggled && <EditInfoModal index={index} toClose={editInfoModal.off} />}
      </Modal>
      <Modal isOpen={openChangeParentModal.isToggled} onClose={openChangeParentModal.off}>
        <ChangeParentModal index={index} toClose={openChangeParentModal.off} />
      </Modal>
    </>
  );
};
