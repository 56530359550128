/** @jsx jsx */

import { jsx, Scalar } from "graphql-jsx";

type Props = Readonly<{
  value?: string;
  isNullable?: boolean;
}>;

export const DateTimeScalar = ({ value, isNullable }: Props) => <Scalar type="DateTime" value={value} isNullable={isNullable} />;
