import React, { useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetMetadata } from "GraphQL/Queries/Metadata/index";
import { WorkActions } from "./Store/Work";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { RightWorkTable } from "./RightWorkTable";
import { useQueryParams } from "lib/use-query-params";
import { WorkStoreProvider, useWorkDispatch } from "./Store";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Filters } from "App/Templates/FilterModalTemplate";

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;

const ChildLayout = () => {
  const dispatch = useWorkDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchTitle = queryParams.get("title", { default: undefined });
  const searchArtist = queryParams.get("artist", { default: undefined });

  const filter = useMemo(() => {
    const { lte, gte, count, artist, rights, url, validCheck, order } = JSON.parse(
      queryParams.get("filter", { default: undefined }) || "{}"
    ) as Filters;
    return { lte, gte, count, artist, rights, url, validCheck, order };
  }, [queryParams]);
  const { lte, gte, count, artist, rights, url, validCheck, order } = filter;
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          dispatch(WorkActions.setLoading(LOADING.UNLOAD));
          const { data: workData, errors: workError } = await GetMetadata({
            first: count ? parseInt(count, 10) : 20,
            page,
            metadataId: searchId,
            title: searchTitle,
            artistName: searchArtist,
            typeClassIn: ["work"],
            typeSubClass: "album",
            validCheck: validCheckIn,
            gte: gte,
            lte: lte,
            artistIdIn: artist?.map(({ id }) => id),
            rightsIdIn: rights?.map(({ id }) => id),
            url: url,
            order
          });
          if (workError && workError.length) {
            console.log(workError[0].message);
            return;
          }
          if (workData) {
            dispatch([WorkActions.setWork(workData.metadata), WorkActions.setEdge(workData.edge)]);
            if (!workData.metadata.length) {
              dispatch(WorkActions.setLoading(LOADING.NULL));
            } else {
              dispatch(WorkActions.setLoading(LOADING.ONLOAD));
            }
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
    },
    [page, searchId, searchTitle, searchArtist, filter]
  );
  return (
    <Layout>
      <LeftSideBar />
      <RightWorkTable count={count ? parseInt(count) : 20} />
    </Layout>
  );
};

export const WorkRenewal = () => (
  <WorkStoreProvider>
    <Helmet title="대작품" />
    <ChildLayout />
  </WorkStoreProvider>
);
