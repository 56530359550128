import React, { MouseEvent, ReactNode } from "react";
import styled from "styled-components";

import { WHITE } from "constants/baseColor";
import { MODAL_BACKGROUND } from "constants/color";
import { BLUR_PX } from "constants/size";

type Props = Styleable &
  Readonly<{
    isOpen: boolean;
    children: ReactNode;
    close?: () => void;
  }>;

const ModalLayout = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: ${MODAL_BACKGROUND};
  /*
   * * 점진적 향상progressive enhancement을 위한 부분
   * * Chrome 77 기준으로 backdrop-filter 처리는 아직 불안정한 것으로 보임
   */
  backdrop-filter: blur(${BLUR_PX});

  z-index: 999;

  & > div {
    border-radius: 4px;
    background-color: ${WHITE};
  }
`;

export const Layout = ({ isOpen, children, close = () => {} }: Props) => {
  const closeModal = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    close();
  };
  if (!isOpen) {
    return null;
  }
  return (
    <ModalLayout onClick={closeModal}>
      <div onClick={event => event.stopPropagation()}>{children}</div>
    </ModalLayout>
  );
};
