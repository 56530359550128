import React, { ChangeEvent, useState, useRef } from "react";
import styled from "styled-components";
import "react-image-crop/dist/ReactCrop.css";
import { PADDING_XX_LARGE_PX, MARGING_LARGE_PX, MARGING_SMALL_PX, PADDING_X_LARGE_PX, pixelize, UNIT } from "constants/size";
import { Input } from "App/Atomics/Input";
import { Divider } from "App/Atomics/Divider";
import { GRAY_1, GRAY_6 } from "constants/baseColor";
import { ImageCrop, CropState, CropRef } from "lib/image-crop";
import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import axios from "axios";

type Props = {
  id: string;
  url: string;
  artistId: string;
  action: string;
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: ${pixelize(UNIT * 50)};
  height: ${pixelize(UNIT * 50)};
  padding: ${PADDING_XX_LARGE_PX};
`;

const RowGroup = styled.div`
  width: 100%;
  margin-bottom: ${MARGING_LARGE_PX};

  h4 {
    margin-bottom: ${MARGING_SMALL_PX};
  }

  input[type="text"] {
    width: 100%;
    background-color: ${GRAY_1};
    padding: ${PADDING_X_LARGE_PX};
  }
`;

const HelperSpan = styled.span`
  font-size: 0.8rem;
  font-weight: normal;
  color: ${GRAY_6};
`;

const ModalHeaderGroup = styled(Input.Group)`
  display: flex;
  justify-content: space-between;
`;

const S3_UPLOAD_REQUIRED = gql`
  mutation S3_UPLOAD_REQUIRED($filename: String!, $path: pathType, $action: FileAction!) {
    singleUpload(where: { filename: $filename, action: $action, path: $path }) {
      key
      url
      bucket
      X_Amz_Date
      X_Amz_Signature
      X_Amz_Credential
      X_Amz_Algorithm
      Policy
      invalidateToken
    }
  }
`;

const CREATE_INVALIDATION = gql`
  mutation CREATE_INVALIDATION($token: String!) {
    createInvalidation(where: { invalidateToken: $token, fileType: image })
  }
`;

const CREATE_ACCESS_QUERY = (table: string = "artist") => gql`
  mutation CREATE_ACCESS_QUERY($id: String) {
    createAccess_record(data: { target_id: $id, target_table: ${table} }) {
      id
      target_id
      target_table
    }
  }
`;

const DELETE_ACCESS_QUERY = gql`
  mutation DELETE_ACCESS_QUERY($id: ID) {
    deleteAccess_record(where: { id: $id })
  }
`;

const CREATE_ARTIST_URL = gql`
  mutation CREATE_ARTIST_URL($id: ID, $typeUrl: String!, $url: String!) {
    updateArtist(where: { artist_id: $id }, data: { _onlySearch: true, artist_url: { create: [{ type_url: $typeUrl, url: $url }] } }) {
      artist_id
    }
  }
`;

const UPDATE_ARTIST_URL = gql`
  mutation UPDATE_ARTIST_URL($id: UUID, $url: String) {
    updateArtist_url(where: { id: $id }, data: { url: $url }) {
      id
    }
  }
`;

const albumFormData = (singleUpload: any, file: Blob | File) => {
  const formData = new FormData();
  formData.append("key", `${singleUpload.key}`);
  formData.append("Policy", singleUpload.Policy);
  formData.append("X-Amz-Credential", singleUpload.X_Amz_Credential);
  formData.append("X-Amz-Date", singleUpload.X_Amz_Date);
  formData.append("X-Amz-Signature", singleUpload.X_Amz_Signature);
  formData.append("X-Amz-Algorithm", singleUpload.X_Amz_Algorithm);
  formData.append("file", file);

  return formData;
};

export const EditFileModal = ({ id, url, artistId, action, toClose }: Props) => {
  const cropRef = useRef<CropRef>(null);
  const [cropImage, setCropImage] = useState<CropState>({
    src: null,
    crop: {
      aspect: undefined
    },
    imageData: {
      type: "",
      url: "",
      ext: ""
    }
  });

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget;
    if (file.files && file.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file.files[0]);
      fileReader.onloadend = () => {
        let ext;
        switch (file.files![0].type) {
          case "image/jpeg":
            ext = "jpg";
            break;
          case "image/png":
            ext = "png";
            break;
          default:
            return;
        }

        const imageData = {
          type: file.files![0].type,
          url: file.files![0].name,
          ext: ext
        };

        setCropImage({ ...cropImage, src: fileReader.result, croppedImageFile: file.files![0], imageData });
      };
    }
  };

  const onEditImage = async () => {
    if (cropRef.current!.cropImage && cropImage.src && cropImage.imageData) {
      const { data, errors } = await clients.access.mutation(S3_UPLOAD_REQUIRED, {
        filename: `artists/${artistId}`,
        path: "imgs",
        action
      });

      if (errors) {
        throw errors;
      }

      if (data.singleUpload) {
        await axios({
          method: "post",
          url: data.singleUpload.url,
          data: albumFormData(
            data.singleUpload,
            cropRef.current!.cropImage.croppedImageUrl
              ? (cropRef.current!.cropImage.croppedImageUrl! as Blob)
              : cropRef.current!.cropImage.croppedImageFile!
          ),
          headers: {
            "Content-Type": "image/jpeg"
          }
        })
          .then(async () => {
            if (action === "UPDATE") {
              const { data: invalidData, errors } = await clients.access.mutation(CREATE_INVALIDATION, {
                token: data.singleUpload.invalidateToken
              });

              if (errors) {
                throw errors;
              }

              if (invalidData.createInvalidation) {
                if (url !== data.singleUpload.key) {
                  const accessRecord = await clients.access.mutation(CREATE_ACCESS_QUERY(), {
                    id: artistId
                  });

                  await clients.artist
                    .mutation(UPDATE_ARTIST_URL, {
                      id: accessRecord.data.createAccess_record.id,
                      url: data.singleUpload.key
                    })
                    .then(async () => await clients.access.mutation(DELETE_ACCESS_QUERY, { id: accessRecord.data.createAccess_record.id }));
                }

                alert("이미지 변경에 성공했습니다.");
                toClose();
              } else {
                alert("이미지 변경에 실패했습니다.");
              }
            } else {
              const accessRecord = await clients.access.mutation(CREATE_ACCESS_QUERY(), {
                id: artistId
              });

              await clients.artist
                .mutation(CREATE_ARTIST_URL, {
                  id: artistId,
                  typeUrl: "thumbnail",
                  url: data.singleUpload.key
                })
                .then(async () => await clients.access.mutation(DELETE_ACCESS_QUERY, { id: accessRecord.data.createAccess_record.id }));

              alert("이미지 등록에 성공했습니다.");
              toClose();
            }
          })
          .catch(e => {
            alert("서버에서 에러가 발생했습니다.");
            return;
          });
      }
    }
  };

  return (
    <Layout>
      <ModalHeaderGroup>
        <h3>
          이미지 변경 <HelperSpan>이미지 변경 후, 적용에 시간이 걸립니다. 새로고침을 해주세요.</HelperSpan>
        </h3>
        <Input.Group>
          <Input.Button color="success" onClick={onEditImage}>
            확인
          </Input.Button>
          <Input.Button color="danger" onClick={toClose}>
            취소
          </Input.Button>
        </Input.Group>
      </ModalHeaderGroup>
      <Divider />
      <RowGroup>
        <h4>이미지 선택</h4>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </RowGroup>
      {cropImage.src && <ImageCrop ref={cropRef} minWidth={300} minHeight={300} cropImage={cropImage} setCropState={setCropImage} />}
    </Layout>
  );
};
