import React, { ReactNode } from "react";
import styled from "styled-components";

import { SideNavigatorTemplate } from "../SideNavigatorTemplate";
import { SIDEBAR_WIDTH_PX, HEADER_HEIGHT_PX } from "constants/size";
import { NotDrawerTopbarTemplate } from "../NotDrawerTopbarTemplate";
import { LeftSideBar } from "App/Molecules/LeftSidebar";

type Props = Readonly<{ children: ReactNode }>;

const Layout = styled.main`
  width: 100%;
  height: 100vh;

  padding-top: ${HEADER_HEIGHT_PX};
  padding-left: ${SIDEBAR_WIDTH_PX};

  @media only all and (max-width: 1200px) {
    padding-left: 0;
  }
`;

const FixedNavigator = styled.div`
  @media only all and (max-width: 1200px) {
    display: none;
  }

  @media only all and (min-width: 1200px) and (max-width: 1680px) {
    display: block;
  }
`;

const DrawerNavigator = styled.div`
  & > aside {
    z-index: 11 !important;
  }

  @media only all and (max-width: 1200px) {
    display: block;
  }

  @media only all and (min-width: 1200px) and (max-width: 1680px) {
    display: none;
  }
`;

export const ResponsiveToSideNavigatorTemplate = ({ children }: Props) => (
  <>
    <FixedNavigator>
      <SideNavigatorTemplate />
    </FixedNavigator>
    <DrawerNavigator>
      <LeftSideBar />
    </DrawerNavigator>
    <NotDrawerTopbarTemplate />
    <Layout>{children}</Layout>
  </>
);
