import React, { useState } from "react";

import { TagAutoComplete } from "App/Atomics/AutoComplete";
import { findMoodList } from "./findMoodList";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";

type Props = Pick<
  Parameters<typeof TagAutoComplete>[0],
  "style" | "className" | "isDisabled" | "defaultValue" | "defaultOptions" | "onChange" | "onBlur" | "menuPlacement"
> &
  Readonly<{ isParent?: boolean }>;

export const MoodTagAutoComplete = ({
  style,
  className,
  isDisabled,
  defaultValue,
  defaultOptions,
  isParent,
  onChange,
  onBlur,
  menuPlacement
}: Props) => {
  const [initialOptions, setInitialOptions] = useState<Info[] | undefined | any>(defaultOptions);

  const onFocus = async () => {
    const info = await findMoodList({ keyword: "", isParent });
    setInitialOptions(info);
  };
  return (
    <TagAutoComplete
      placeholder="무드를 입력해주세요."
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      defaultOptions={initialOptions}
      delay={200}
      style={style}
      className={className}
      menuPlacement={menuPlacement}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findMoodList({ keyword, isParent })}
    />
  );
};
