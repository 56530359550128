/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, BooleanScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar, DateTimeScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  id: string;
  typeTrack: string;
  countryCode?: string;
  notice: string;
  copyright: boolean | undefined;
  neighboring: boolean | undefined;
  licenseDate: string | null;
  publishDate: string | null;
  isService?: number;
  validCheck?: ValidType;
}>;

export const UpdateTrackLicense = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.id} />;
  const $typeTrack = <Variable name="type_track" scalar={<StringScalar isNullable={false} />} value={option.typeTrack} />;
  const $countryCode = option.countryCode ? (
    <Variable name="country_code" scalar={<StringScalar isNullable={false} />} value={option.countryCode} />
  ) : (
    undefined
  );
  const $notice = <Variable name="notice" scalar={<StringScalar />} value={option.notice} />;
  const $copyright = <Variable name="copyright" scalar={<BooleanScalar />} value={option.copyright} />;
  const $neighboring = <Variable name="neighboring" scalar={<BooleanScalar />} value={option.neighboring} />;
  const $licenseDate = <Variable name="licenseDate" scalar={<DateTimeScalar isNullable />} value={option.licenseDate} />;
  const $publishDate = <Variable name="publishDate" scalar={<DateTimeScalar isNullable />} value={option.publishDate} />;
  const $isService = <Variable name="is_service" scalar={<IntScalar />} value={option.isService} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_TRACK_LICENSE">
      <SelectionSet
        name="updateTrack_license"
        alias="updateTrackLicense"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            type_track: $typeTrack,
            country_code: $countryCode,
            notice: $notice,
            copyright: $copyright,
            neighboring: $neighboring,
            license_date: $licenseDate,
            publish_date: $publishDate,
            is_service: $isService,
            valid_check: $validCheck
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateTrackLicense: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
