import { useVideoCreateDispatch } from "App/Routes/VideoCreate/Store";
import { VideoCreateActions } from "App/Routes/VideoCreate/Store/VideoCreate";
import { VideoCreateStyle } from "App/Routes/VideoCreate/Style";
import React from "react";
import styled from "styled-components";

type Props = {
  readonly name?: string;
};

export const TitleForm = ({ name }: Props) => {
  const dispatch = useVideoCreateDispatch();
  const onChangeName = (val: string) => dispatch(VideoCreateActions.setName(val));
  return (
    <Layout>
      <VideoCreateStyle.Title>영상 등록을 위해 아래 정보를 입력해주세요.</VideoCreateStyle.Title>
      <VideoCreateStyle.Row>
        <VideoCreateStyle.Caption>영상 제목 *</VideoCreateStyle.Caption>
        <VideoCreateStyle.SubCaption>ex. [4K/Hi-Res] UNBOXING EXO Photo Book</VideoCreateStyle.SubCaption>
        <VideoCreateStyle.InputText placeholder={"상품명"} defaultValue={name} onBlur={onChangeName} />
      </VideoCreateStyle.Row>
    </Layout>
  );
};

const Layout = styled.div`
  margin: 30px 0 0;
`;
