/** @jsx jsx */

import { jsx, render, Field, Variable, SelectionSet, Query, IdScalar, IntScalar, EnumScalar, StringScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";
import { Edge } from "models/Edge";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  first: number;
  page: number;
  id?: string;
  name?: string;
  typeClassIn?: string[];
  validCheck?: string[];
  expose?: number;
}>;

export type Url = {
  uuid: string;
  typeUrl: string;
  url: string;
  validCheck: ValidType;
  order: number;
};

export type Element = Readonly<{
  uuid: string;
  typeFunction?: string;
  order: number;
  elementRelation: Readonly<{
    id?: string;
    name?: string;
  }>[];
}>;

export type Role = Readonly<{
  id: string;
  name: string;
  typeClass: string;
  count: number;
  expose: number;
  validCheck: ValidType;
  urlRelation: Url[];
  listRelation: Element[];
}>;

export const GetRole = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar />} value={option.page} />;
  const $id = <Variable name="id" scalar={<IdScalar isNullable />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $typeClassIn = !option.typeClassIn?.length ? (
    undefined
  ) : (
    <ListScalar scalar={<StringScalar />} isNullable value={option.typeClassIn} />
  );
  const $validCheck = !option.validCheck ? undefined : <ListScalar scalar={<ValidTypeScalar />} isNullable value={option.validCheck} />;
  const $expose = <Variable name="expose" scalar={<IntScalar isNullable />} value={option.expose} />;
  const { query, variables } = render(
    <Query operationName="GET_ROLE">
      <SelectionSet
        name="role"
        argument={{
          first: $first,
          orderBy: <EnumScalar type="RoleOrderByInput" value="role_id__DESC" items={["role_id__DESC"]} />,
          page: $page,
          where: {
            role_id: $id,
            role_title__some: {
              type_role_title__starts_with: !option.name ? undefined : "name",
              tsvec_words__search: $name
            },
            type_role_class__in: $typeClassIn,
            valid_check__in: $validCheck,
            expose: $expose
          }
        }}
      >
        <Field name="role_id" alias="id" />
        <Field name="name" />
        <Field name="type_role_class" alias="typeClass" />
        <Field name="role_count" alias="count" />
        <Field name="expose" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet name="role_url" alias="urlRelation">
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet name="role_self_relation_list_role" alias="listRelation">
          <Field name="id" alias="uuid" />
          <Field name="type_function" alias="typeFunction" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="role_self_relation_element_role" alias="elementRelation">
            <Field name="role_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "role",
          page: $page,
          first: $first,
          where: {
            role: {
              role_id: $id,
              role_title__some: {
                type_role_title__starts_with: !option.name ? undefined : "name",
                tsvec_words__search: $name
              },
              type_role_class__in: $typeClassIn,
              expose: $expose,
              valid_check__in: $validCheck
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    role: Role[];
    edge: Edge;
  }>;
  return await clients.role.request<Data>(query, variables);
};
