import React from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, UNIT, BORDER_RADIUS_PX, MARGING_X_LARGE_PX } from "constants/size";
import { GRAY_9, GRAY_4 } from "constants/baseColor";
import { ProgressLoading } from "./ProgressLoading";
import { DANGER_COLOR, WARNING_COLOR, SUCCESS_COLOR_LIGHT } from "constants/color";

type Props = Styleable & ProgressProps;

export type ProgressProps = Readonly<{
  percent: number;
  count?: number;
  total?: number;
}>;

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${MARGING_X_LARGE_PX};
  margin-bottom: ${MARGING_X_LARGE_PX};
  margin-left: ${MARGING_X_LARGE_PX};
`;

const animatedProgress = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
`;
const ProgressContainer = styled.div<ProgressProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .progress-bar {
    background-color: ${GRAY_9};
    height: ${pixelize(UNIT * 2)};
    width: ${pixelize(UNIT * 18.75)};
    border-radius: ${BORDER_RADIUS_PX};
    background-size: 30px 30px;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 75%,
      transparent 75%,
      transparent
    );
    animation: ${animatedProgress} 0.6s linear infinite;
    animation-duration: 1.25s;
    animation-direction: reverse;
  }

  .progress-bar-inner {
    display: block;
    height: ${pixelize(UNIT * 2)};
    width: ${props => props.percent}%;
    max-width: 100%;
    background-color: ${props => (props.percent < 40 ? DANGER_COLOR : props.percent < 100 ? WARNING_COLOR : SUCCESS_COLOR_LIGHT)};

    border-radius: ${BORDER_RADIUS_PX};
    position: relative;
    transition: all 0.5s ease-out;
  }

  label {
    display: inline-block;
    color: ${props => (props.percent < 40 ? DANGER_COLOR : props.percent < 100 ? WARNING_COLOR : SUCCESS_COLOR_LIGHT)};
    width: 100%;
    font-size: 0.7rem;
    text-align: right;
    transform: translateY(-4px);
    text-shadow: 0 0 2px ${GRAY_4};
  }
`;

const CircleLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${pixelize(UNIT * 3)};
  .circle {
    width: ${pixelize(UNIT * 1.8)};
    height: ${pixelize(UNIT * 1.8)};
    border: 1px solid ${GRAY_4};
    border-radius: 50%;
  }
`;

/**
 * 프로그레스 바 입니다.
 *
 * - percent는 현재 진행률을 표시합니다.
 * - 100%가 될 경우 체크박스가 체크됩니다. (최대 100%)
 * - count와 total은 현재 카운트 및 총 카운트를 표시합니다.
 * - count와 total 값이 모두 있을 때, 진행률이 count / total로 변경됩니다.
 */
export const AnimatedProgress = ({ style, className, percent, count, total }: Props) => (
  <Layout>
    <ProgressContainer style={style} className={className} percent={percent}>
      <div className="progress-bar">
        <span className="progress-bar-inner"></span>
        {count !== undefined && total !== undefined ? (
          <label>
            {count} / {total}
          </label>
        ) : (
          <label>{percent < 100 ? percent.toFixed(0) : 100}%</label>
        )}
      </div>
    </ProgressContainer>
    {percent < 100 ? (
      <CircleLoading>
        <div className="circle" />
      </CircleLoading>
    ) : (
      <ProgressLoading />
    )}
  </Layout>
);
