/** @jsx jsx */

import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const DeleteArtist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_ARTIST">
      <SelectionSet
        name="deleteArtist"
        argument={{
          where: {
            artist_id: $id
          }
        }}
      >
        <Field name="artist_id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteArtist: {
      artist_id: string;
    };
  }>;

  return await clients.artist.request<Data>(query, variables);
};
