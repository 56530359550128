import React, { ChangeEvent } from "react";
import styled from "styled-components";

import { identity } from "utils/identity";
import { PADDING_XX_LARGE_PX, pixelize, UNIT } from "constants/size";
import { FONT_BASIC, PRIMARY_COLOR, transparentize, transparentizeRGB } from "constants/color";

type Props = Styleable &
  Readonly<{
    id: string;
    isDisabled?: boolean;
    isChecked?: boolean;
    children?: string;
    onToggle?: (isChecked: boolean) => void;
  }>;

const Layout = styled.div<{ text: string | undefined }>`
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: ${PADDING_XX_LARGE_PX};
    margin: 0 auto;
    ${props => (props.text ? "" : "width: 0;")}
    height: ${pixelize(UNIT * 1.25)};
    color: ${transparentize(FONT_BASIC, 90)};
    cursor: pointer;
  }

  input[type="checkbox"] + label:before {
    content: "";
    display: block;
    width: ${pixelize(UNIT * 1.25)};
    height: ${pixelize(UNIT * 1.25)};
    border: 1px solid ${transparentizeRGB(PRIMARY_COLOR, 90)};
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.15s, border-color 0.1s;
    transition: all 0.15s, border-color 0.1s;
  }

  input[type="checkbox"]:checked + label:before {
    display: block;
    width: ${pixelize(UNIT * 0.625)};
    top: ${pixelize(UNIT * -0.375)};
    left: ${pixelize(UNIT * 0.375)};
    border-radius: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBox = ({ style, className, id, children, isDisabled, isChecked, onToggle = identity }: Props) => {
  const toggle = (event: ChangeEvent<HTMLInputElement>) => {
    onToggle(event.currentTarget!.checked);
  };
  return (
    <Layout text={children}>
      <input id={id} type="checkbox" style={style} className={className} disabled={isDisabled} checked={isChecked} onChange={toggle} />
      <label htmlFor={id}>{children}</label>
    </Layout>
  );
};
