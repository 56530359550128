/* eslint-disable jsx-a11y/accessible-emoji */
import { GoodsCreateStore, useGoodsCreateSelector } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions, Section } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import React, { ReactElement, useRef } from "react";
import styled from "styled-components";
import { Style } from "../../../Style";
import { ReactComponent as LeftIcon } from "assets/icons/angle-left.svg";
import { ReactComponent as RightIcon } from "assets/icons/angle-right.svg";
import { ReactComponent as SortIcon } from "assets/icons/sort_number_asc.svg";
import { useScrollToTop } from "App/Routes/GoodsCreate/Hooks/useScrollToTop";
import { v4 as uuidv4 } from "uuid";
import { GRAY_1, GRAY_2, BLUE_8 } from "constants/baseColor";

export const Second = (): ReactElement => {
  const { coverUrls, detailUrls, videoUrls } = useGoodsCreateSelector(store => store.GoodsCreate.goods);
  const inputCoverRef = useRef<HTMLInputElement>(null);
  const inputDetailRef = useRef<HTMLInputElement>(null);
  const inputVideoRef = useRef<HTMLInputElement>(null);
  const onPrevPage = () => GoodsCreateStore.dispatch(GoodsCreateActions.setSection(Section.First));
  const onNextPage = () => GoodsCreateStore.dispatch(GoodsCreateActions.setSection(Section.Third));
  useScrollToTop();

  const addCoverUrl = (event: React.ChangeEvent<HTMLInputElement>, type: "cover" | "detail" | "video") => {
    const fileList = event.currentTarget.files!;
    for (const file of fileList) {
      const fileReader = new FileReader();
      const id = uuidv4();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = e => {
        const url = {
          id,
          file: file,
          data: e.target?.result as string,
          ext: file.type.split("/")[1],
          url: file.name,
          typeUrl: file.type
        };
        if (type === "cover") {
          GoodsCreateStore.dispatch(GoodsCreateActions.addCoverUrl(url));
          inputCoverRef.current!.value = "";
        } else if (type === "detail") {
          GoodsCreateStore.dispatch(GoodsCreateActions.addDetailUrl(url));
          inputDetailRef.current!.value = "";
        } else {
          GoodsCreateStore.dispatch(GoodsCreateActions.addVideoUrl(url));
          inputVideoRef.current!.value = "";
        }
      };
    }
  };

  const deleteUrl = (id: string, type: "cover" | "detail" | "video") =>
    GoodsCreateStore.dispatch(
      type === "cover"
        ? GoodsCreateActions.removeCoverUrl(id)
        : type === "detail"
        ? GoodsCreateActions.removeDetailUrl(id)
        : GoodsCreateActions.removeVideoUrl(id)
    );

  const sortCoverUrl = () => GoodsCreateStore.dispatch(GoodsCreateActions.sortCoverUrl());
  const sortDetailUrl = () => GoodsCreateStore.dispatch(GoodsCreateActions.sortDetailUrl());
  const sortVideoUrl = () => GoodsCreateStore.dispatch(GoodsCreateActions.sortVideoUrl());

  return (
    <Layout>
      <Container>
        <Style.Title>다음 정보.</Style.Title>
        <Style.Row>
          <Style.Caption>
            썸네일 이미지
            <SortButton onClick={sortCoverUrl}>
              <SortIcon />
            </SortButton>
          </Style.Caption>
          <Style.SubCaption>- 이미지 등록 후, 정렬 버튼을 누르면 숫자 오름차순으로 정렬됩니다.</Style.SubCaption>
          <Style.SubCaption>- 이미지를 누르면 삭제됩니다.</Style.SubCaption>
          <ImageContainer>
            <UploadButton>
              <AddLabel htmlFor="input-file-thumbnail">+</AddLabel>
              <HiddenInput
                ref={inputCoverRef}
                type="file"
                multiple
                id="input-file-thumbnail"
                accept="image/jpeg,image/png"
                onChange={event => addCoverUrl(event, "cover")}
              />
            </UploadButton>
            {Array.from(coverUrls).map(([key, value]) => (
              <Image key={key} src={URL.createObjectURL(value.file)} alt="cover" onClick={() => deleteUrl(key, "cover")} />
            ))}
          </ImageContainer>
        </Style.Row>
        <Style.Row>
          <Style.Caption>
            상세 이미지
            <SortButton onClick={sortDetailUrl}>
              <SortIcon />
            </SortButton>
          </Style.Caption>
          <Style.SubCaption>- 이미지 등록 후, 정렬 버튼을 누르면 숫자 오름차순으로 정렬됩니다.</Style.SubCaption>
          <Style.SubCaption>- 이미지를 누르면 삭제됩니다.</Style.SubCaption>
          <ImageContainer>
            <UploadButton>
              <AddLabel htmlFor="input-file-detail">+</AddLabel>
              <HiddenInput
                ref={inputDetailRef}
                type="file"
                multiple
                id="input-file-detail"
                accept="image/jpeg,image/png"
                onChange={event => addCoverUrl(event, "detail")}
              />
            </UploadButton>
            {Array.from(detailUrls).map(([key, value]) => (
              <Image key={key} src={URL.createObjectURL(value.file)} alt="cover" onClick={() => deleteUrl(key, "detail")} />
            ))}
          </ImageContainer>
        </Style.Row>
        <Style.Row>
          <Style.Caption>
            영상
            <SortButton onClick={sortVideoUrl}>
              <SortIcon />
            </SortButton>
          </Style.Caption>
          <Style.SubCaption>- 영상 등록 후, 정렬 버튼을 누르면 숫자 오름차순으로 정렬됩니다.</Style.SubCaption>
          <Style.SubCaption>- 영상을 누르면 삭제됩니다.</Style.SubCaption>
          <ImageContainer>
            <UploadButton>
              <AddLabel htmlFor="input-file-video">+</AddLabel>
              <HiddenInput
                ref={inputVideoRef}
                type="file"
                multiple
                id="input-file-video"
                accept="video/mp4"
                onChange={event => addCoverUrl(event, "video")}
              />
            </UploadButton>
            {Array.from(videoUrls).map(([key, value]) => (
              <Video key={key} src={URL.createObjectURL(value.file)} onClick={() => deleteUrl(key, "video")} />
            ))}
          </ImageContainer>
        </Style.Row>
        <Style.Row>
          <Style.ButtonGroup>
            <Style.IconButton onClick={onPrevPage}>
              <LeftIcon />
            </Style.IconButton>
            <Style.IconButton onClick={onNextPage}>
              <RightIcon />
            </Style.IconButton>
          </Style.ButtonGroup>
        </Style.Row>
      </Container>
    </Layout>
  );
};

const Layout = styled.div``;

const Container = styled.div``;

const UploadButton = styled.div`
  width: 100%;
  height: 100%;
  min-height: 123px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  transition: background 0.15s;
  &:hover {
    background: ${GRAY_1};
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ImageContainer = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;

const AddLabel = styled.label`
  font-size: 36px;
  color: ${GRAY_2};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SortButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 50%;
  margin-left: 4px;
  background: ${BLUE_8};
  cursor: pointer;
  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;
