/** @jsx jsx  */

import { ValidType } from "constants/ValidType";
import { EnumScalar, Field, jsx, ListScalar, Query, render, SelectionSet, StringScalar, Variable } from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  keyword: string;
  isParent?: boolean;
}>;

export const findAllGenreList = async ({ keyword }: Option) => {
  const $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="GENRE_SEARCH_LIST">
      <SelectionSet
        name="genre_title"
        alias="list"
        argument={{
          first: 20,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Genre_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            genre__some: {
              type_kind: "genre",
              valid_check__not: $remove
            },
            tsvec_words__search: $keyword,
            type_genre_title__starts_with: "name"
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <SelectionSet name="genre">
          <Field name="genre_id" alias="id" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="name" />
          <Field name="weight" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: ReadonlyArray<{
      id: string;
      genre: {
        id: string;
        type: ValidType;
        name: string;
        weight: number;
      }[];
    }>;
  }>;
  const { data, errors } = await clients.genre.request<Data>(query, variables);
  if (!data || errors) {
    return [];
  }
  const result = data.list.map(({ genre }) => {
    const newName = genre[0].weight === 1 ? `${genre[0].name} ✭` : `${genre[0].name}`;
    return { ...genre[0], name: newName };
  });
  return result;
};
