/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useArtistsStore } from "./Store";
import { useQueryParams } from "lib/use-query-params";
import { useModal } from "lib/modal";
import { TableFiltermodal } from "./Modals/TableFilterModal";
import { ArtistEditInfoActions } from "./Store/ArtistInfo";
import { useAppStore } from "App/Store";
import { useAsyncEffect } from "lib/use-async-effect";
import { clients } from "utils/clients";
import { ArtistEditActions } from "./Store/Artist";
import { gql } from "lib/gql-tag";
import { UserRole } from "constants/UserRole";
import { LoadingAction } from "App/Store/Loading";
import { LOADING } from "App/Templates/LoadingTemplate";
import itiriri from "itiriri";

const subheadDataEvery = (subheadData: Record<string, boolean>) => Object.values(subheadData).every(item => !item);

export const useArtistHeadline = () => {
  const [{ userRole }, dispatchApp] = useAppStore(store => ({
    accessRecord: store.UserToken.accessRecord,
    userRole: store.UserToken.role
  }));
  const [{ head, subhead, validCheck }, dispatch] = useArtistsStore(store => ({
    head: store.ArtistInfo.head,
    subhead: store.ArtistInfo.subhead,
    validCheck: store.ArtistInfo.validCheck
  }));
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const checkList = queryParams.getAll("ids");
  const strCheckList = JSON.stringify(queryParams.getAll("ids"));
  const filterModal = useModal();

  const validList = itiriri(Object.entries(validCheck))
    .filter(([key, isToggledKey]) => isToggledKey)
    .toArray(([key]) => key);
  useAsyncEffect(
    async isMounted => {
      dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
      const artistQuery = `
        ${subhead.Artist.artistId ? `artistId: artist_id` : ""}
        ${subhead.Artist.name ? `name` : ""}
        ${subhead.Artist.typeArtistClass ? `typeArtistClass: type_artist_class` : ""}
        ${subhead.Artist.validCheck ? `validCheck: valid_check` : ""}
      `;

      const artistUrlQuery =
        !head.ArtistUrl || subheadDataEvery(subhead.ArtistUrl)
          ? ""
          : `
        artistUrls: artist_url {
          relationId: id
          ${subhead.ArtistUrl.typeUrl ? `typeUrl: type_url` : ""}
          ${subhead.ArtistUrl.url ? `url` : ""}
        }
      `;

      const artistGenreQuery =
        !head.ArtistGenre || subheadDataEvery(subhead.ArtistGenre)
          ? ""
          : `
        genreRelation: artist_genre_relation(
          orderBy: exposure_order__ASC
          where: {
            genre__some: {
              type_kind: "genre"
            }
          }
        ) {
          relationId: id
          order: exposure_order
          genre {
            id: genre_id
            name
            type: type_kind
          }
        }
      `;

      const artistHistoryQuery =
        !head.History || subheadDataEvery(subhead.History)
          ? ""
          : `
        history: artist_history {
          relationId: id
          ${subhead.History.birthDate ? `birthDate: birth_date` : ""}
          ${subhead.History.birthPlace ? `birthPlace: birth_place` : ""}
          ${subhead.History.deathDate ? `deathDate: death_date` : ""}
          ${subhead.History.deathPlace ? `deathPlace: death_place` : ""}
        }
      `;

      const artistRoleQuery =
        !head.Role || subheadDataEvery(subhead.Role)
          ? ""
          : `
        roleRelation: artist_role_relation {
          exposureOrder: exposure_order
          relationId: id
          role {
            ${subhead.Role.name ? `name` : ""}
          }
        }
      `;

      const QUERY = gql`
        query ARTIST_GET_LIST($page: Int, $validList: [ValidType!]) {
          artists: artist(
            page: $page,
            group: true,
            first: 50,
            orderBy: artist_id__DESC
            where: { 
              valid_check__in: $validList
              ${!checkList.length ? "" : `artist_id__in: ${strCheckList}`}
            }
          ) {
            ${artistQuery}
            ${artistUrlQuery}
            ${artistGenreQuery}
            ${artistHistoryQuery}
            ${artistRoleQuery}
          }
        }
      `;

      const { data } = await clients.artist.query(QUERY, { page, validList });

      if (isMounted()) {
        dispatch(ArtistEditActions.setArtist(data.artists));
        dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
      }
    },
    [page, subhead, validCheck]
  );

  const openTableFilterModal = () => {
    const children = (
      <TableFiltermodal
        head={head}
        subhead={subhead}
        userRole={userRole}
        onClick={(head, subhead) => {
          dispatch(ArtistEditInfoActions.setHead(head));
          dispatch(ArtistEditInfoActions.setSubhead(subhead));
          filterModal.close();
        }}
      />
    );
    filterModal.open(children);
  };

  return { head, subhead, page, openTableFilterModal };
};
