import { BLUE_2, BLUE_5, BLUE_6, BLUE_7, RED_5 } from "constants/baseColor";
import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { identity } from "utils/identity";

export type AnimatedCheckboxProps = Styleable &
  Readonly<{
    id: string;
    isDisabled?: boolean;
    isChecked?: boolean;
    onToggle?: (isChecked: boolean) => void;
  }>;

const Layout = styled.div<{ isDisabled?: boolean }>`
  position: relative;
  .cbx {
    -webkit-perspective: 20;
    perspective: 20;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.75rem;
    border: 2px solid ${BLUE_2};
    background: ${BLUE_7};
    border-radius: 4px;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s ease;
    cursor: ${props => (props.isDisabled === true ? "not-allowed" : "pointer")};
  }
  .cbx:hover {
    border-color: ${props => (props.isDisabled === true ? RED_5 : BLUE_5)};
  }
  .flip {
    display: block;
    transition: all 0.3s ease;
    transform-style: preserve-3d;
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
  }
  .cbx-label {
    display: none;
  }
  .cbx-label:checked + .cbx {
    border-color: ${BLUE_6};
  }
  .cbx-label:checked + .cbx .flip {
    transform: rotateY(180deg);
  }
  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2px;
  }
  .front {
    background: #fff;
    z-index: 1;
  }
  .back {
    transform: rotateY(180deg);
    background: ${BLUE_7};
    text-align: center;
    color: #fff;
    line-height: 1.25rem;
    box-shadow: 0 0 0 1px ${BLUE_7};
  }
  .back svg {
    margin-top: 3px;
    fill: none;
  }
  .back svg path {
    stroke: #fff;
    stroke-width: 2.5;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

/**
 * 토글 체크박스입니다.
 *
 * - `isChecked`에 상태를 저장해서 `onToggle`로 상태를 변경해야 합니다.
 * - `id`는 항상 고유해야 하며, 중복일 경우 제대로 동작하지 않습니다.
 */
export const AnimatedCheckbox = ({ id, style, isDisabled, isChecked, onToggle = identity }: AnimatedCheckboxProps) => {
  const onToggleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      onToggle(event.currentTarget.checked);
    }
  };
  return (
    <Layout isDisabled={isDisabled}>
      <input
        id={id}
        type="checkbox"
        style={style}
        className="cbx-label"
        disabled={isDisabled}
        checked={isChecked ?? false}
        onChange={onToggleCheck}
      />
      <label className="cbx" htmlFor={id}>
        <div className="flip">
          <div className="front" />
          <div className="back">
            <svg width="16" height="14" viewBox="0 0 16 14">
              <path d="M2 8.5L6 12.5L14 1.5"></path>
            </svg>
          </div>
        </div>
      </label>
    </Layout>
  );
};
