import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { UpdateMetadataUrl } from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";

export const handleUpdateMetadataUrl = async (
  targetId: string,
  targetTable: TargetTableInput,
  uuid: string,
  typeUrl: string,
  url: string
) => {
  try {
    const accessId = await requestAccessRecord({
      targetId,
      targetTable
    });
    await UpdateMetadataUrl({
      uuid: uuid!,
      typeUrl,
      url
    });
    await DeleteAccessRecord({ id: accessId! });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
