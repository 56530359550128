import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_LARGE_PX, PADDING_XX_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE } from "constants/size";
import { Input } from "App/Atomics/Input";
import { GRAY_0, GRAY_5, BLUE_6, GRAY_6 } from "constants/baseColor";
import { useTracksDispatch } from "../../Store";
import { ServiceTypeTextSelect } from "App/Molecules/Selects/ServiceType";
import { CreatePlaylist } from "GraphQL/Queries/Playlist";
import { ServiceType } from "constants/ServiceType";
import { TrackActions, Playlist } from "../../Store/Track";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";

type Props = {
  toClose: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 25)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RowGroup = styled.div`
  width: 100%;
  h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 14px;
    margin: ${MARGING_LARGE_PX} 0;
  }

  .typeSelect {
    width: 100%;
    margin-bottom: ${MARGING_LARGE_PX};
    font-size: 12px;
  }

  .textSelect {
    width: 100%;
    margin-bottom: ${MARGING_LARGE_PX};
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid ${GRAY_5};
    transition: all 0.2s;
    &:hover {
      border: 1px solid ${GRAY_6};
    }
    &:focus {
      border: 1px solid ${BLUE_6};
    }
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;
  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

export const CreatePlaylistModal = ({ toClose }: Props) => {
  const [serviceType, setServiceType] = useState<ServiceType | undefined>(undefined);
  const [kind, setKind] = useState<string>("");
  const [name, setName] = useState<string>("");
  const dispatch = useTracksDispatch();
  const alertModal = useToggle();

  const createLicense = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!serviceType || !kind.length || !name.length!) {
      window.alert("모든 값을 입력해주세요.");
      return;
    }
    try {
      try {
        const { data } = await CreatePlaylist({ serviceType: serviceType!, kind, name });
        if (data) {
          const playlist: Playlist = {
            id: data.playlist.id,
            kind,
            name,
            count: 0,
            metadataPlaylistRelation: []
          };
          dispatch(TrackActions.createTrackPlaylist(playlist));
          alertModal.on();
        }
      } catch (err) {
        console.log(err);
        window.alert("플레이리스트 생성에 실패하였습니다.");
        return;
      }
    } catch (err) {
      console.log(err);
      window.alert("Internal Server Error");
      return;
    }
  };

  return (
    <Layout>
      <RowGroup>
        <h2>새 플레이리스트 생성</h2>
      </RowGroup>
      <RowGroup>
        <h4>서비스</h4>
        <ServiceTypeTextSelect
          className="typeSelect"
          onChange={info => {
            if (info) {
              setServiceType(info);
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>분류</h4>
        <Input.Text className="textSelect" placeholder="주스, fassker, neocomix... 등" onBlur={text => setKind(text)} />
      </RowGroup>
      <RowGroup>
        <h4>제목</h4>
        <Input.Text className="textSelect" placeholder="제목을 입력해주세요." onBlur={text => setName(text)} />
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="cancelButton" onClick={toClose}>
          취소
        </Input.Button>
        <Input.Button onClick={e => createLicense(e)}>생성</Input.Button>
      </ButtonGroup>
      <Modal isOpen={alertModal.isToggled}>
        <Confirm
          title="알림"
          context="플레이리스트가 추가되었습니다."
          toClose={() => {
            alertModal.off();
            toClose();
          }}
        />
      </Modal>
    </Layout>
  );
};
