import axios from "axios";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, UploadFile } from "GraphQL/Queries";
import { createFormData } from "lib/createFormData";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { useWorkStore } from "../Store";
import { v4 as uuidv4 } from "uuid";
import { CreateMetadataUrls } from "GraphQL/Queries/Metadata/CreateMetadataUrls";

export const useCreateWorkImage = () => {
  const [{ work }] = useWorkStore(store => ({
    work: store.Work.work
  }));
  const createImage = async (id: string) => {
    const companyId = work.rightsCompany.company_id;
    const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
    if (!accessId) {
      Toast.error("이미지 등록에 실패하였습니다.");
      return;
    }
    const coverSet = await uploadImage(id, companyId, work.images);
    await CreateMetadataUrls({ id, urls: Array.from(coverSet) });
    await DeleteAccessRecord({ id: accessId });
  };
  return { createImage };
};

const uploadImage = async (workId: string, companyId: string, urls: Map<string, any>) => {
  const result = new Set<string>();
  try {
    for (const [, { typeUrl, file, ext }] of urls) {
      const { data } = await UploadFile({ filename: `${workId}/cover/${uuidv4()}.${ext}`, companyId });
      if (!data) {
        throw new Error();
      }
      const bucket = data.uploadFile;
      await axios({
        method: "post",
        url: bucket.url,
        data: createFormData(bucket, file),
        headers: {
          "Content-Type": typeUrl
        }
      });
      result.add(bucket.key);
    }
    return result;
  } catch (err) {
    console.log(err);
    Toast.error("이미지 업로드에 실패하였습니다.");
    return result;
  }
};
