export enum GoodsOrderByInput {
  goods_id__ASC = "goods_id__ASC",
  goods_id__DESC = "goods_id__DESC",
  name__ASC = "name__ASC",
  name__DESC = "name__DESC",
  type_kind__ASC = "type_kind__ASC",
  type_kind__DESC = "type_kind__DESC",
  weight__ASC = "weight__ASC",
  weight__DESC = "weight__DESC",
  createdAt__ASC = "createdAt__ASC",
  createdAt__DESC = "createdAt__DESC"
}
