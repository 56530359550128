import React from "react";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_LARGE_PX, PADDING_LARGE_PX } from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { WHITE, GRAY_4, BLACK } from "constants/baseColor";
import { PRIMARY_COLOR, DANGER_ALERT_COLOR } from "constants/color";
type Props = Readonly<{
  extraData: string;
  toClose: () => void;
}>;

export const ExtraDataModal = ({ extraData, toClose }: Props) => {
  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>기타 설명</h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
      </Header>
      {!extraData ? (
        <NoData>
          <span>기타 설명이 없습니다.</span>
        </NoData>
      ) : (
        <Context>
          <div>
            <span>{extraData}</span>
            <MarginDiv>{"0"}</MarginDiv>
          </div>
        </Context>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 600px;
  height: 600px;
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #fff;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  .track_info {
    display: flex;
    align-items: center;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    margin-bottom: 1rem;
    .primary-underline {
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const Context = styled.div`
  display: flex;
  height: 100%;
  padding: 1.5rem;
  user-select: text;
  div {
    span {
      white-space: pre-wrap;
      font-size: 14px;
      line-height: 22px;
      color: #666;
      text-align: left;
    }
  }
`;

const MarginDiv = styled.div`
  height: 1.5rem;
  color: transparent;
`;
