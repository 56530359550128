import {
  GRAY_8,
  INDIGO_6,
  BLUE_6,
  GREEN_7,
  GREEN_8,
  GRAY_7,
  YELLO_7,
  VIOLET_6,
  VIOLET_7,
  GRAY_6,
  YELLO_8,
  RED_6,
  BLUE_5,
  VIOLET_5,
  GRAY_5,
  GREEN_6,
  YELLO_6,
  RED_5,
  GRAY_2,
  GRAY_3,
  BLACK,
  RED_7,
  VIOLET_2,
  RED_2,
  BLUE_2,
  GREEN_1,
  YELLO_1,
  GREEN_5,
  GREEN_2,
  YELLO_2,
  YELLO_5,
  VIOLET_3,
  GREEN_3,
  YELLO_3,
  RED_3,
  INDIGO_4
} from "./baseColor";

export const transparentize = (hsl: string, percent: number) => `${hsl.slice(0, -1)} / ${percent}%)`;
export const transparentizeRGB = (rgb: string, percent: number) =>
  `#${rgb.slice(-6)}${parseInt(((255 * percent) / 100).toString()).toString(16)}`;

export const AC_BLACK = "rgb(8,8,13)";
export const AC_WHITE = "rgb(251,249,249)";
export const AC_PURPLE = "rgb(151,54,230)";
export const AC_ORCHID = "rgb(144,98,244)";
export const AC_INDIGO = "rgb(70,56,244)";
export const AC_CYAN = "rgb(114,238,244)";
export const AC_MAGENTA = "rgb(233,104,244)";
export const AC_CHARCOAL = "rgb(22,22,23)";
export const AC_SHADOW = "rgb(31,31,33)";
export const AC_GRAY = "rgb(112,112,132)";

export const FONT_BASIC = GRAY_8;

export const DEFAULT_BORDER_COLOR = "#c9c9c9";

export const DEFAULT_BUTTON_COLOR_LIGHT = GRAY_3;
export const DEFAULT_BUTTON_COLOR = GRAY_2;

export const PRIMARY_COLOR = "#4c52bc";
export const PRIMARY_COLOR_LIGHT = INDIGO_4;
export const PRIMARY_COLOR_DARK = INDIGO_6;
export const PRIMARY_COLOR_DISABLED = transparentizeRGB(PRIMARY_COLOR, 60);
export const PRIMARY_ALERT_BACKGROUND = BLUE_2;
export const PRIMARY_ALERT_COLOR = BLUE_6;
export const PRIMARY_TOGGLE_OFF = "#d8daf5";
export const PRIMARY_TOGGLE_ON = BLUE_5;

export const SECONDARY_COLOR_LIGHT = VIOLET_5;
export const SECONDARY_COLOR = VIOLET_6;
export const SECONDARY_COLOR_DARK = VIOLET_7;
export const SECONDARY_COLOR_DISABLED = VIOLET_3;
export const SECONDARY_ALERT_BACKGROUND = VIOLET_2;
export const SECONDARY_ALERT_COLOR = VIOLET_6;
export const SECONDARY_TOGGLE_OFF = VIOLET_2;
export const SECONDARY_TOGGLE_ON = VIOLET_5;

export const INFO_COLOR_LIGHT = GRAY_5;
export const INFO_COLOR = GRAY_6;
export const INFO_COLOR_DARK = GRAY_7;
export const INFO_COLOR_DISABLED = GRAY_3;
export const INFO_ALERT_BACKGROUND = GRAY_2;
export const INFO_ALERT_COLOR = GRAY_7;
export const INFO_TOGGLE_OFF = GRAY_2;
export const INFO_TOGGLE_ON = GRAY_5;

export const SUCCESS_COLOR_LIGHT = GREEN_6;
export const SUCCESS_COLOR = GREEN_7;
export const SUCCESS_COLOR_DARK = GREEN_8;
export const SUCCESS_COLOR_DISABLED = GREEN_3;
export const SUCCESS_ALERT_BACKGROUND = GREEN_1;
export const SUCCESS_ALERT_COLOR = GREEN_7;
export const SUCCESS_TOGGLE_OFF = GREEN_2;
export const SUCCESS_TOGGLE_ON = GREEN_5;

export const WARNING_COLOR_LIGHT = YELLO_6;
export const WARNING_COLOR = YELLO_7;
export const WARNING_COLOR_DARK = YELLO_8;
export const WARNING_COLOR_DISABLED = YELLO_3;
export const WARNING_ALERT_BACKGROUND = YELLO_1;
export const WARNING_ALERT_COLOR = YELLO_8;
export const WARNING_TOGGLE_OFF = YELLO_2;
export const WARNING_TOGGLE_ON = YELLO_5;

export const DANGER_COLOR = "#ff5252";
export const DANGER_COLOR_LIGHT = transparentizeRGB(DANGER_COLOR, 80);
export const DANGER_COLOR_DARK = RED_7;
export const DANGER_COLOR_DISABLED = RED_3;
export const DANGER_ALERT_BACKGROUND = RED_2;
export const DANGER_ALERT_COLOR = RED_6;
export const DANGER_TOGGLE_OFF = RED_2;
export const DANGER_TOGGLE_ON = RED_5;

export const MODAL_BACKGROUND = transparentize(BLACK, 16);
