import React from "react";
import styled from "styled-components";
import { configs } from "configs";
import { useToggle } from "lib/use-toggle";
import { ReactComponent as TitleIcon } from "assets/icons/title.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/photo.svg";
import defaultImage from "assets/images/cover.png";
import { mediaQuery } from "constants/media";
import { Modal } from "lib/modal";
import { ImageViewModal, EditInfoModal, TitleModal } from "../../../Modals";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, DANGER_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { Confirm } from "App/Molecules/Confirm";
import { Toast } from "lib/toast";
import { WorkActions } from "App/Routes/WorkRenewal/Store/Work";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { useArtistDispatch } from "App/Routes/ArtistRenewal/Store";
import { CompanyRelation, RoleRelation, Url } from "GraphQL/Queries/Artist/GetArtistRenewal";
import { allowArtistUpdate } from "App/Routes/AdminRoutes/allowTables/allowArtistUpdate";

type Props = {
  index: number;
  id: string;
  name: string;
  artistUrl: Url[];
  roleRelation: RoleRelation[];
  validCheck: ValidType;
  companyRelation: CompanyRelation[];
};

export const CardItem = ({ index, id, name, artistUrl, roleRelation, companyRelation, validCheck }: Props) => {
  const { store, userRole } = useAppSelector(store => ({
    store: store,
    userRole: store.UserToken.role
  }));
  const dispatch = useArtistDispatch();
  const imageViewModal = useToggle();
  const editInfoModal = useToggle();
  const confirmModal = useToggle();
  const titleModal = useToggle();
  const allowArtistEdit = userRole === UserRole.Master || allowArtistUpdate(store);
  const imageUrl =
    artistUrl?.filter(({ typeUrl }) => typeUrl === "head" || typeUrl === "thumbnail" || typeUrl === "card" || typeUrl === "cover") ?? [];
  const url =
    imageUrl && imageUrl.length
      ? `${configs.urls.image}/${imageUrl[0].url}${imageUrl[0].url!.includes("?") ? `&` : `?`}mode=m`
      : defaultImage;

  const onDelete = async (id: string) => {
    try {
      //
    } catch (err) {
      console.log(err);
      dispatch(WorkActions.setEditLoading(false));
      Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
      return;
    }
  };

  return (
    <Layout key={id}>
      <ImageContainter onClick={editInfoModal.on}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="link">
          <img src={url} alt={`${name}-cover`} />
        </a>
        <Badge vc={validCheck}>{validCheck}</Badge>
        {!roleRelation.length ? null : (
          <RoleContainer>
            {roleRelation.map(({ role }, index) => (
              <div className="tag-item" key={`${role[0]?.id}-${index}`}>
                {role[0]?.name}
              </div>
            ))}
          </RoleContainer>
        )}
        {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
          <div className="svg-wrapper" onClick={e => e.stopPropagation()}>
            {userRole === UserRole.Master && <DeleteIcon className="delete" onClick={confirmModal.on} />}
            <TitleIcon className="title" onClick={titleModal.on} />
            <PhotoIcon className="photo" onClick={imageViewModal.on} />
          </div>
        )}
      </ImageContainter>
      <Info>
        <ClipBoard text={id}>
          <span className="id">{id}</span>
        </ClipBoard>
        <ClipBoard text={name}>
          <span className="title">{name}</span>
        </ClipBoard>
        <ClipBoard text={!companyRelation?.length ? "-" : companyRelation[0].company[0].displayName ?? companyRelation[0].company[0].name}>
          <span className="company">
            {!companyRelation?.length ? "-" : companyRelation[0].company[0].displayName ?? companyRelation[0].company[0].name}
          </span>
        </ClipBoard>
      </Info>
      <Modal isOpen={imageViewModal.isToggled} onClose={imageViewModal.off}>
        <ImageViewModal index={index} id={id} artistUrl={imageUrl} toClose={imageViewModal.off} />
      </Modal>
      {allowArtistEdit && (
        <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
          <EditInfoModal index={index} toClose={editInfoModal.off} />
        </Modal>
      )}
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm title={"경고"} context={"정말로 삭제하시겠습니까?"} toSave={() => onDelete(id)} toClose={confirmModal.off} />
      </Modal>
      <Modal isOpen={titleModal.isToggled} onClose={titleModal.off}>
        <TitleModal id={id} toClose={titleModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;

  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  color: transparent;
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #eee;
    }
  }

  a.link::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6em;
    content: "";
    z-index: 1;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    a.link::after {
      opacity: 1;
    }

    .wrapper,
    .delete,
    .photo,
    .title {
      opacity: 1;
    }
  }
  .svg-wrapper {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 0;
    right: 0;
    z-index: 2;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }
  }

  .delete,
  .photo,
  .title {
    position: absolute;
    bottom: 1.2em;
    z-index: 3;
    opacity: 0;
    fill: #eee;
    width: 1.2em;
    height: 1.2em;
    transition: all 0.15s;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }

    &:hover {
      fill: #fff;
      transform: scale(1.3, 1.3);
    }
  }

  .delete {
    left: 0.8em;
    &:hover {
      fill: #f00;
    }
  }
  .photo {
    right: 0.8em;
  }
  .title {
    right: 3em;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 9em;
  max-height: 8em;

  span {
    white-space: pre-wrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .id {
    font-size: 0.8em;
    color: #bbb;
  }
  .title {
    font-weight: bold;
    flex-wrap: wrap;
  }
  .company {
    color: #666;
    cursor: pointer;
    font-size: 0.9em;
    margin-right: 0.2rem;
  }
`;

const Badge = styled.div<{ vc: ValidType }>`
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 3%;
  color: ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR : "#eee")};
  text-shadow: 0 0 2px ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR_LIGHT : PRIMARY_COLOR)};
  ${mediaQuery(768)} {
    font-size: 9px;
  }
`;

const RoleContainer = styled.div`
  position: absolute;
  top: 2%;
  left: 2%;
  max-height: 50%;

  .tag-item {
    font-size: 0.8rem;
    border: 1px solid #eee;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.35);
    color: #eee;
    padding: 2px 4px;
    margin-bottom: 4px;
    ${mediaQuery(768)} {
      font-size: 9px;
      border-radius: 4px;
    }
  }
`;
