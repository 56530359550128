import React, { ReactElement } from "react";
import styled from "styled-components";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsTextAutoComplete } from "App/Molecules/AutoCompletes/Goods";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { ProductCreateStore } from "App/Routes/ProductCreate/Store";
import { ProductCreateActions } from "App/Routes/ProductCreate/Store/ProductCreate";

type Props = {
  readonly goods?: Info;
};

export const ProductGoods = ({ goods }: Props): ReactElement => {
  const setGoods = (info: Info) => ProductCreateStore.dispatch(ProductCreateActions.setGoods(info));
  return (
    <Style.Row>
      <Style.Caption>원본 굿즈 *</Style.Caption>
      <Style.SubCaption>· 상품이 들어갈 굿즈를 선택해주세요.</Style.SubCaption>
      <AutoCompleteContainer>
        <GoodsTextAutoComplete
          style={SelectStyle}
          placeholder={"원본 굿즈"}
          defaultValue={goods}
          onChange={info => info && setGoods(info)}
        />
      </AutoCompleteContainer>
    </Style.Row>
  );
};

const AutoCompleteContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
`;
