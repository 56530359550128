import classNames from "classnames";
import React, { ComponentType, ReactNode } from "react";
import { SlideDown } from "react-slidedown";

import styles from "./index.module.css";

type Props = Styleable &
  Readonly<{
    as?: keyof JSX.IntrinsicElements | ComponentType<any>;
    isOpen: boolean;
    children: ReactNode;
  }>;

export const Collapsible = ({ as, style, className, isOpen, children }: Props) => (
  <SlideDown as={as} style={style || { overflowX: "hidden" }} className={classNames(styles.layout, className)} closed={!isOpen}>
    {children}
  </SlideDown>
);
