export function* shift<T>(list: T[], count: number): Generator<T[]> {
  let result: T[] = [];
  for (let index = 0; index < list.length; index += 1) {
    if (index !== 0 && index % count === 0) {
      yield result;
      result = [];
    }
    result.push(list[index]);
  }
  if (result.length) {
    yield result;
  }
}
