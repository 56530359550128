import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Link } from "App/Atomics/Link";
import { UNIT, pixelize } from "constants/size";
import classNames from "classnames";
import { Edge } from "models/Edge";
import { PRIMARY_COLOR } from "constants/color";
import { useAppSelector } from "App/Store";
import { mediaQuery } from "constants/media";
import { InterSect, UnInterSect, useIntersectionObserver } from "lib/use-intersection-observer";

type Props = Readonly<{
  edge: null | Edge;
  isFullWidth?: boolean;
  isPlaying?: boolean;
  goTo: (index: number) => string;
  onIntersect?: InterSect;
  unIntersect?: UnInterSect;
}>;

const Layout = styled.div<Pick<Props, "isFullWidth" | "isPlaying">>`
  display: grid;
  grid-gap: ${pixelize(1.5 * UNIT)};
  grid-auto-flow: column;
  justify-content: center;

  width: min-content;
  height: min-content;
  margin-top: ${pixelize(UNIT * 4)};
  margin-bottom: ${props => (!props.isPlaying ? pixelize(UNIT * 4) : pixelize(UNIT * 9))};

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.5;
    }

    &.current {
      color: ${PRIMARY_COLOR};
      font-weight: bolder;
      text-decoration: underline;
      transform: scale(1.2);
    }

    &.start {
    }

    &.end {
    }
  }

  ${mediaQuery(375)} {
    font-size: 0.7rem;
    grid-gap: ${pixelize(0.8 * UNIT)};
  }
`;

export const Pagination = ({ edge, isFullWidth = true, goTo, onIntersect, unIntersect }: Props) => {
  const [, setPageRef] = useIntersectionObserver(onIntersect, unIntersect);
  const { isVisible } = useAppSelector(store => store.AudioPlayer);
  const limit = 10;
  const createIndexList = () => {
    if (!edge) {
      return [];
    }
    const first = edge.currentPage! - ((edge.currentPage! - 1) % limit);
    const indexList = [first] as number[];
    while (indexList.length !== limit && indexList[indexList.length - 1] < edge.pageCount!) {
      indexList.push(indexList[indexList.length - 1] + 1);
    }
    return indexList;
  };
  const indexList = useMemo(createIndexList, [edge]);

  if (!edge) {
    return null;
  }
  const prevNum =
    edge.currentPage! - limit < 1
      ? 1
      : edge.currentPage! % limit === 0
      ? edge.currentPage! - limit
      : edge.currentPage! - (edge.currentPage! % limit);
  const nextNum =
    edge.currentPage! + limit - ((edge.currentPage! - 1) % limit) <= edge.pageCount!
      ? edge.currentPage! + limit - ((edge.currentPage! - 1) % limit)
      : edge.pageCount! - edge.currentPage! < limit
      ? edge.currentPage! + limit - ((edge.pageCount! - 1) % limit)
      : edge.pageCount!;

  return (
    <Layout ref={setPageRef} isFullWidth={isFullWidth} isPlaying={isVisible}>
      {edge.currentPage! !== 1 && (
        <Link.Internal className="start" to={goTo(1)}>
          {"<<"}
        </Link.Internal>
      )}

      {edge.hasPreviousPage && edge.currentPage! > limit && (
        <Link.Internal className="prev" to={goTo(prevNum)}>
          {"<"}
        </Link.Internal>
      )}

      {indexList.map(index => (
        <Link.Internal to={goTo(index)} className={classNames({ current: index === edge.currentPage })} key={index}>
          {index}
        </Link.Internal>
      ))}

      {edge.hasNextPage && edge.pageCount! - indexList[0] > limit && (
        <Link.Internal className="next" to={goTo(nextNum)}>
          {">"}
        </Link.Internal>
      )}

      {edge.currentPage !== edge.pageCount && (
        <Link.Internal className="end" to={goTo(edge.pageCount!)}>
          {">>"}
        </Link.Internal>
      )}
    </Layout>
  );
};
