import React from "react";
import { FilterGroup, Filters } from "../..";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { MoodTagAutoComplete } from "App/Molecules/AutoCompletes/Mood";

type Props = {
  mood?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const MoodFilter = ({ mood, setFilters }: Props) => (
  <FilterGroup>
    <h4>무드</h4>
    <span className="info">여러 개의 무드를 선택할 수 있습니다.</span>
    <MoodTagAutoComplete
      defaultValue={mood}
      menuPlacement="top"
      onChange={info => {
        if (info) {
          const list = info.map(({ id, name }) => ({ id, name }));
          setFilters(filter => ({ ...filter, mood: list }));
        }
      }}
    />
  </FilterGroup>
);
