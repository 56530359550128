import React, { useState } from "react";
import styled from "styled-components";

import { Input } from "App/Atomics/Input";
import { DEFAULT_BORDER_COLOR } from "constants/color";
import { DetailSearchForm } from "./DetailSearchForm";
import { SimpleSearchForm } from "./SimpleSearchForm";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, PADDING_X_LARGE_PX, MARGING_X_LARGE_PX, BORDER_RADIUS_PX } from "constants/size";
import { CopyPlaylistForm } from "./CopyPlaylistForm";

const Layout = styled.div`
  min-width: ${pixelize(28 * UNIT)};
  padding: ${PADDING_XX_LARGE_PX};
  border-top: 1px solid ${DEFAULT_BORDER_COLOR};

  & > .group {
    width: min-content;
  }

  & > .form {
    display: grid;
    grid-gap: ${PADDING_X_LARGE_PX};
    margin-top: ${MARGING_X_LARGE_PX};
  }
`;

const ToggleGroup = styled(Input.Group)`
  width: 100%;

  & > button {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: ${BORDER_RADIUS_PX};
      border-bottom-left-radius: ${BORDER_RADIUS_PX};
    }

    &:last-child {
      border-top-right-radius: ${BORDER_RADIUS_PX};
      border-bottom-right-radius: ${BORDER_RADIUS_PX};
    }
  }
`;

enum SearchMode {
  Simple,
  Detail,
  Copy
}

export const TrackSearchForm = () => {
  const [searchMode, setSearchMode] = useState(SearchMode.Simple);
  return (
    <Layout>
      <ToggleGroup>
        <Input.Toggle
          isActive={searchMode === SearchMode.Simple}
          color="primary"
          isWide
          toggleValue="단순 검색 모드"
          onClick={() => setSearchMode(SearchMode.Simple)}
        />
        <Input.Toggle
          isActive={searchMode === SearchMode.Detail}
          color="primary"
          isWide
          toggleValue="디테일 검색 모드"
          onClick={() => setSearchMode(SearchMode.Detail)}
        />
        <Input.Toggle
          isActive={searchMode === SearchMode.Copy}
          color="primary"
          isWide
          toggleValue="플레이리스트 복사"
          onClick={() => setSearchMode(SearchMode.Copy)}
        />
      </ToggleGroup>
      <div className="form">
        {searchMode === SearchMode.Simple ? (
          <SimpleSearchForm />
        ) : searchMode === SearchMode.Detail ? (
          <DetailSearchForm />
        ) : (
          <CopyPlaylistForm />
        )}
      </div>
    </Layout>
  );
};
