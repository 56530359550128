/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import { useAsyncEffect } from "lib/use-async-effect";
import { Toast } from "lib/toast";
import { CommentOrderByInput } from "constants/CommentOrderByInput";
import { GetComment } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import _ from "lodash";
import { TrackRecord } from "..";
import { GRAY_0 } from "constants/baseColor";
import { DANGER_COLOR, SUCCESS_COLOR, WARNING_COLOR } from "constants/color";

type Props = {
  id: string;
};

export const RecordForm = ({ id }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [record, setRecord] = useState<TrackRecord[]>([] as TrackRecord[]);
  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const commentList = await GetComment({
          targetId: id,
          targetTable: TargetTableInput.Playlist,
          order: CommentOrderByInput.timestamp_DESC
        });
        setRecord(_.flatten(commentList.map(({ value }) => value.data)));
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        return;
      }
    }
  }, []);

  return (
    <Layout>
      <div className="container">
        <div className="table-wrap">
          <table>
            <thead>
              <tr>
                <th style={{ width: "100px" }}>아이디</th>
                <th style={{ width: "350px" }}>제목</th>
                <th style={{ width: "250px" }}>아티스트</th>
                <th style={{ width: "100px" }}>상태</th>
                <th style={{ width: "150px" }}>날짜</th>
              </tr>
            </thead>
            <tbody>
              {record.map(({ id, metadata_id, title, artist, operate, timestamp }) => {
                console.log(operate);
                return (
                  <tr key={id}>
                    <td>{metadata_id ?? ""}</td>
                    <td className="title">{title ?? ""}</td>
                    <td>{artist ?? ""}</td>
                    <td>
                      <ColorSpan state={operate}>{operate ?? ""}</ColorSpan>
                    </td>
                    <td>{timestamp ? timestamp.slice(0, 10) : ""}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 0.5rem;
  .container {
    position: relative;
    padding: 1rem;
  }
  .table-wrap {
    position: absolute;
    height: 755px;
    top: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      tr {
        th {
          height: 48px;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          color: #606060;
          font-size: 0.8rem;
        }
      }
    }

    tbody {
      overflow-y: scroll;
      tr {
        transition: background 0.15;
        &:hover {
          background: ${GRAY_0};
        }
        td {
          padding: 0 6px;
          height: 84px;
          border-bottom: 1px solid #f2f2f2;
          text-align: center;
        }
        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
  }
`;

const ColorSpan = styled.span<{ state: string }>`
  color: ${props => (props.state === "INSERT" ? SUCCESS_COLOR : props.state === "DELETE" ? DANGER_COLOR : WARNING_COLOR)};
`;
