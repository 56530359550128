/** @jsx jsx */

import {
  jsx,
  render,
  SelectionSet,
  Field,
  Variable,
  Mutation,
  IdScalar,
  StringScalar,
  IntScalar,
  BooleanScalar,
  FloatScalar
} from "graphql-jsx";
import { DateTimeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
  name?: string;
  typeId?: number;
  barcode?: string;
  quality?: string;
  askPrice?: number;
  askQuantity?: number;
  isSale?: boolean;
  officialPrice?: number;
  officialBarcode?: string | null;
  isCollection?: boolean;
  createdAt?: string;
}>;

export const UpdateGoods = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $typeId = <Variable name="type_id" scalar={<IntScalar isNullable />} value={option.typeId} />;
  const $isSale = <Variable name="is_sale" scalar={<BooleanScalar isNullable />} value={option.isSale} />;
  const $askPrice = <Variable name="ask_price" scalar={<FloatScalar isNullable />} value={option.askPrice} />;
  const $officialPrice = <Variable name="official_price" scalar={<FloatScalar isNullable />} value={option.officialPrice} />;
  const $officialBarcode = <Variable name="official_barcode" scalar={<StringScalar isNullable />} value={option.officialBarcode} />;
  const $askQuantity = <Variable name="ask_quantity" scalar={<IntScalar isNullable />} value={option.askQuantity} />;
  const $barcode = <Variable name="barcode" scalar={<StringScalar isNullable />} value={option.barcode} />;
  const $quality = <Variable name="quality" scalar={<StringScalar isNullable />} value={option.quality} />;
  const $isCollection = <Variable name="is_collection" scalar={<BooleanScalar isNullable />} value={option.isCollection} />;
  const $createdAt = <Variable name="createdAt" scalar={<DateTimeScalar isNullable />} value={option.createdAt} />;

  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS">
      <SelectionSet
        name="updateGoods"
        argument={{
          where: {
            goods_id: $id
          },
          data: {
            name: $name,
            type_id: $typeId,
            is_sale: $isSale,
            ask_price: $askPrice,
            official_price: $officialPrice,
            // official_barcode: option.officialBarcode ? $officialBarcode : null,
            official_barcode: $officialBarcode,
            ask_quantity: $askQuantity,
            barcode: $barcode,
            quality: $quality,
            created_at: $createdAt,
            is_collection: $isCollection
          }
        }}
      >
        <Field name="goods_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoods: Readonly<{
      id: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
