import { LoadGoodsType } from "GraphQL/Queries/Goods";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { GoodsCreateStore } from "../Store";
import { GoodsCreateActions } from "../Store/GoodsCreate";

export const useLoadGoodsTypes = () => {
  useAsyncEffect(async () => {
    const { data, errors } = await LoadGoodsType();
    if (errors || !data) {
      Toast.error("굿즈 타입을 불러올 수 없습니다.");
      return;
    }
    GoodsCreateStore.dispatch(GoodsCreateActions.setGoodsTypes(data.goodsType));
  }, []);
  return;
};
