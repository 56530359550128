import React, { useState, MouseEvent } from "react";
import styled from "styled-components";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Table } from "App/Atomics/Table";
import { GRAY_5, GRAY_4, BLUE_7, RED_7 } from "constants/baseColor";
import { createHashGenerator } from "lib/createHashGenerator";
import { DANGER_COLOR, PRIMARY_COLOR_DARK } from "constants/color";
import { pixelize, UNIT, MARGING_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { useAlbumDetailStore, useAlbumDetailSelector } from "../../Store";
import { AlbumDetailActions } from "../../Store/AlbumDetail";
import { useModal, Modal } from "lib/modal";
import { EditTitleModal } from "../../Modals/EditTitleModal";
import { gql } from "lib/gql-tag";
import { clients } from "utils/clients";
import { StructureActions } from "../../Store/Structure";
import { ReactComponent as ListenIcon } from "assets/icons/listen.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as WriteIcon } from "assets/icons/write.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { TrackActions } from "../../Store/TrackList";
import { EditTextModal } from "../../Modals/EditTextModal";
import { useToggle } from "lib/use-toggle";
import itiriri from "itiriri";
import { ValidType } from "constants/ValidType";
import { EditGroupTitleModal } from "../../Modals/EditGroupTitleModal";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";

type Metadata = [
  {
    metadataId: string;
    title: string;
    typeSubClass: string;
    validCheck: ValidType;
  }
];

type Child = {
  metadata: Metadata;
};

type StructureProps = {
  metadata: Metadata;
  child: Child[];
};

const ParentRow = styled(Table.Row)<{ isValid: boolean }>`
  text-align: center;
  background: #f8f8f8;

  &:hover {
    background: #ededf8;
  }

  ${props =>
    props.isValid
      ? `
    & > td {
      background-color: ${GRAY_4} !important;
      color: ${GRAY_5} !important;
      text-decoration: line-through !important;
    }`
      : ``}
`;

const ChildRow = styled(Table.Row)<{ isOpen: boolean; isValid: boolean; isChecked: boolean }>`
  text-align: center;

  &:hover {
    background-color: #ededf8;
  }

  ${props =>
    props.isValid
      ? `
    & > td {
      background-color: ${GRAY_4} !important;
      color: ${GRAY_5} !important;
      text-decoration: line-through !important;
    }`
      : ``}

  ${props =>
    props.isChecked
      ? `
    background-color: #cfcfff;
  `
      : ""}
`;

const OnOffData = styled(Table.Data)<{ isOpen: boolean }>`
  font-size: 1.2rem;
  color: ${props => (props.isOpen ? `${DANGER_COLOR}` : `${PRIMARY_COLOR_DARK}`)};

  svg {
    width: ${pixelize(UNIT * 0.8)};
    height: ${pixelize(UNIT * 0.8)};
  }
  .plus {
    fill: ${BLUE_7};
  }

  .minus {
    fill: ${RED_7};
  }
`;

const TitleContainer = styled(Table.Data)`
  width: ${pixelize(UNIT * 55)};
  text-align: left;
  word-break: break-all;
  white-space: normal;

  .listen {
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
    transform: translateY(3px);
    margin-right: ${MARGING_SMALL_PX};
  }

  .hoverSVG {
    display: none;
    float: right;
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
    margin-left: ${MARGING_LARGE_PX};
  }

  &:hover {
    & > .hoverSVG {
      display: block;
      cursor: pointer;
    }
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;
type ChildProps = {
  index: number;
  metadata: any;
  childIndex: number;
  childLength: number;
  isOpen: boolean;
  onToggleCheck: (checked: boolean, metadataId: any) => void;
  toRemovedMetadata: (metadataId: any, structureId: any, _heck: boolean, validCheck: any, childLength: number) => Promise<void>;
  openEditTitleModal: (metadataId: any, title: any, index: number, childIndex: number) => Promise<void>;
};

const ChildDataTemplate = ({
  index,
  metadata,
  childIndex,
  childLength,
  isOpen,
  onToggleCheck,
  toRemovedMetadata,
  openEditTitleModal
}: ChildProps) => {
  const { metadataId, no, title, validCheck, artistRelation, metadataStructure } = metadata[0];
  const createHash = createHashGenerator();
  const checkList = useAlbumDetailSelector(store => store.AlbumDetail.checkList);

  return (
    <ChildRow key={childIndex} isOpen={isOpen} isValid={validCheck === "REMOVE"} isChecked={false}>
      <AlignCenterData style={{ width: "5%" }}>
        <AnimatedCheckbox
          id={metadataId}
          isChecked={checkList.get(metadataId)}
          isDisabled={validCheck === "REMOVE"}
          onToggle={checked => onToggleCheck(checked, metadataId)}
        />
      </AlignCenterData>
      {metadataId ? (
        <Table.Data style={{ width: "15%" }}>
          <ClipBoard text={metadataId} />
        </Table.Data>
      ) : (
        <Table.Data>{}</Table.Data>
      )}
      <Table.Data style={{ width: "5%" }}>{no ?? ""}</Table.Data>
      {title ? (
        <TitleContainer>
          <ListenIcon className="listen" />
          <ClipBoard text={title} />
          {validCheck !== "REMOVE" && (
            <>
              <DeleteIcon
                className="hoverSVG"
                onClick={() => toRemovedMetadata(metadataId, metadataStructure[0].structureId, true, validCheck, childLength)}
              />
              <WriteIcon className="hoverSVG" onClick={() => openEditTitleModal(metadataId, title, index, childIndex)} />
            </>
          )}
        </TitleContainer>
      ) : (
        <Table.Data>{}</Table.Data>
      )}
      {artistRelation.length ? (
        <Table.Data>
          {artistRelation.map(({ artist }: any) => (
            <div key={createHash()}>{artist[0].name}</div>
          ))}
        </Table.Data>
      ) : (
        <Table.Data>{}</Table.Data>
      )}
    </ChildRow>
  );
};

export const DataTemplate = ({ index, data }: ColumnProps<any>) => {
  const [isOpen, setOpen] = useState<boolean>(true);
  const editTitleModal = useModal();
  const editTextModal = useToggle();
  const editGroupTitleModal = useToggle();
  const { metadataId, no, title, typeSubClass, validCheck, artistRelation, titleRelation, metadataStructure } = data.metadata[0];
  const [{ structures, checkList }, albumDetailDispatch] = useAlbumDetailStore(store => ({
    structures: store.Structure.structures,
    checkList: store.AlbumDetail.checkList
  }));
  const createHash = createHashGenerator();

  // TODO: Input Check Event
  const onToggleCheck = (checked: boolean, id: string) => {
    if (checked) {
      albumDetailDispatch(AlbumDetailActions.setAlbumCheckList({ id, checked }));
    } else {
      albumDetailDispatch(AlbumDetailActions.setAlbumCheckList({ id, checked }));
    }
  };

  const toRemovedMetadata = async (metadataId: string, structureId: string, isTrack: boolean, validCheck: string, isChild: number) => {
    if (isTrack) {
      if (validCheck === "REMOVE") {
        return;
      }

      if (window.confirm("삭제 상태로 변경하시겠습니까?")) {
        const accessQuery = gql`
        mutation REQUEST_ACCESS_RECORD {
          accessRecord: createAccess_record(
            data: { target_id: "${metadataId}", target_table: metadata }
          ) {
              id
              target_id
              target_table
            }
          }
        `;

        const { data } = await clients.access.query(accessQuery);

        try {
          const EDIT_TRACK_VALID_QUERY = gql`
          mutation EDIT_TRACK_VALID_QUERY {
            updateMetadata(
              where: { metadata_id: "${metadataId}" },
              data: { 
                valid_check: REMOVE
              }
            ) {
              metadata_id
            }
          }
        `;

          await clients.metadata.mutation(EDIT_TRACK_VALID_QUERY);
        } catch (e) {
          console.log(e);
          return;
        } finally {
          const query = gql`
          mutation DELETE_ACCESS_RECORD {
            deleteAccess_record(where: { id: "${data.accessRecord.id}" })
          }
        `;

          await clients.access.query(query);
          albumDetailDispatch(StructureActions.toggleMountedFlag());
        }
      }
    } else {
      if (isChild) {
        alert("그룹에서 트랙을 모두 제외한 후에 실행해주세요.");
        return;
      } else {
        if (window.confirm("그룹을 삭제하시겠습니까?")) {
          const accessQuery = gql`
          mutation REQUEST_ACCESS_RECORD {
            accessRecord: createAccess_record(
              data: { target_id: "${metadataId}", target_table: metadata }
            ) {
                id
                target_id
                target_table
              }
            }
          `;

          const { data } = await clients.access.query(accessQuery);

          try {
            const EDIT_TEXT_REMOVE_QUERY = gql`
            mutation EDIT_TEXT_REMOVE_QUERY {
              deleteMetadata_structure(
                where: { structure_id: "${structureId}" }
              ) {
                structure_id
              }
              deleteMetadata(
                where: { metadata_id: "${metadataId}" }
              ) {
                metadata_id
              }
            }
          `;

            await clients.metadata.mutation(EDIT_TEXT_REMOVE_QUERY);
          } catch (e) {
            console.log(e);
            return;
          } finally {
            const query = gql`
            mutation DELETE_ACCESS_RECORD {
              deleteAccess_record(where: { id: "${data.accessRecord.id}" })
            }
          `;

            await clients.access.query(query);
            albumDetailDispatch(StructureActions.toggleMountedFlag());
          }
        }
      }
    }
  };

  const setInitTrackList = () => {
    albumDetailDispatch([
      TrackActions.set({
        kind: "searched",
        tracks: structures.flatMap(({ metadata, child }, index) => {
          if (child.length) {
            return child
              .filter((value: any) => value.metadata[0].typeSubClass === "track")
              .map((value: any) => ({
                id: value.metadata[0].metadataId,
                value: value.metadata[0].title,
                extra: {
                  index: index,
                  structureId: value.metadata[0].metadataStructure[0].structureId,
                  order: value.metadata[0].metadataStructure[0].order
                }
              }));
          }

          return metadata
            .filter((value: any) => value.typeSubClass === "track")
            .map((value: any) => ({
              id: value.metadataId,
              value: value.title,
              extra: {
                index: undefined,
                structureId: value.metadataStructure[0].structureId,
                order: value.metadataStructure[0].order
              }
            }));
        })
      }),
      TrackActions.set({
        kind: "selected",
        tracks: structures.flatMap(({ metadata, child }, index) => {
          if (child.length) {
            return child
              .filter((value: any) => value.metadata[0].typeSubClass === "track" && metadataId === metadata[0].metadataId)
              .map((value: any) => ({
                id: value.metadata[0].metadataId,
                value: value.metadata[0].title,
                extra: {
                  index: index,
                  structureId: value.metadata[0].metadataStructure[0].structureId,
                  order: value.metadata[0].metadataStructure[0].order
                }
              }));
          }

          return metadata
            .filter((value: any) => value.typeSubClass === "track" && metadataId === metadata.metadataId)
            .map((value: any) => ({
              id: value.metadataId,
              value: value.title,
              extra: {
                index: undefined,
                structureId: value.metadataStructure[0].structureId,
                order: value.metadataStructure[0].order
              }
            }));
        })
      })
    ]);
  };

  const toCloseTrackMoved: Parameters<typeof EditTextModal>[0]["toClose"] = () => {
    const svgButtonList = document.querySelectorAll("svg");
    itiriri(svgButtonList.entries()).forEach(([key, value]) => value.toggleAttribute("clicked", false));
    albumDetailDispatch([TrackActions.clear(), StructureActions.clearEventLog()]);
    editTextModal.off();
  };

  const openEditTitleModal = async (id: string, name: string, index: number, childIndex?: number) => {
    const titleRelationData = childIndex === undefined ? titleRelation : data.child[childIndex].metadata[0].titleRelation;
    const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
    if (accessData) {
      const updateTrackTitle: Parameters<typeof EditTitleModal>[0]["onClick"] = async (title, desc) => {
        // TODO: 앨범트랙 제목 변경
        if (title) {
          try {
            const EDIT_ALBUM_TRACK_TITLE_QUERY = gql`
              mutation {
                updateAlbumTrackTitle${id}: updateMetadata(
                  where: { metadata_id: "${id}" },
                  data: { 
                    title: "${title}"
                  }
                ) {
                  metadata_id
                }
              }
            `;
            await clients.metadata.mutation(EDIT_ALBUM_TRACK_TITLE_QUERY);
            albumDetailDispatch(StructureActions.editAlbumTrackTitleByIndex({ title, index, childIndex }));
          } catch (e) {
            console.log(e);
            return;
          }
        }
        // TODO: 앨범트랙 설명 추가/변경
        if (desc) {
          try {
            // TODO: 앨범트랙 설명이 비었다면 생성 쿼리
            if (!titleRelationData.length) {
              const CREATE_ALBUM_TRACK_DESC_QUERY = gql`
                mutation {
                  createAlbumTrackDesc${id}: updateMetadata(
                    where: { metadata_id: "${id}" }
                    data: {
                      _onlySearch: true
                      metadata_title: {
                        create: {
                          type_metadata_title: "description"
                          value: "${desc}"
                          language: { connect: { language_code: "KO" } }
                        }
                      }
                    }
                  ) {
                    metadata_id
                  }
                }
              `;
              const GET_ALBUM_TRACK_DESC_QUERY = gql`
                query EDIT_MODAL_QUERY {
                  metadata(
                    where: {
                      metadata_id: "${id}"
                    }
                  ) {
                    titleRelation: metadata_title {
                      uuid: id
                      type: type_metadata_title
                      value
                    }
                  }
                }
              `;
              await clients.metadata.mutation(CREATE_ALBUM_TRACK_DESC_QUERY);
              const { data } = await clients.metadata.query(GET_ALBUM_TRACK_DESC_QUERY);
              const descData = data.metadata[0].titleRelation[0];
              albumDetailDispatch(StructureActions.createAlbumTrackDescByIndex({ desc: descData, index, childIndex }));
            } else {
              // TODO: 앨범트랙 설명이 있다면 수정 쿼리
              const UUID = titleRelationData[0].uuid;
              const EDIT_ALBUM_TRACK_DESC_QUERY = gql`
                mutation {
                  editAlbumTrackDesc${id}: updateMetadata(
                    where: { metadata_id: "${id}" }
                    data: {
                      _onlySearch: true
                      metadata_title: {
                        update: {
                          where: { id: "${UUID}" }
                          data: {
                            value: "${desc}"
                          }
                        }
                      }
                    }
                  ) {
                    metadata_id
                  }
                }
              `;
              await clients.metadata.mutation(EDIT_ALBUM_TRACK_DESC_QUERY);
              albumDetailDispatch(StructureActions.editAlbumTrackDescByIndex({ desc, index, childIndex }));
            }
          } catch (e) {
            console.log(e);
            return;
          }
        }
        await DeleteAccessRecord({ id: accessData.createAccess.id });
        editTitleModal.close();
      };
      editTitleModal.open(<EditTitleModal title={name} titleRelation={titleRelationData} onClick={updateTrackTitle} />);
    }
  };

  const parentRowOpen = (event: MouseEvent) => {
    const svgButtonList = event.currentTarget.querySelectorAll("svg");
    const isClicked = itiriri(svgButtonList.entries()).some(([key, value]) => value.hasAttribute("clicked"));

    if (!isClicked) {
      setOpen(prevState => !prevState);
    }
  };
  return (
    <>
      <ParentRow key={metadataId} isValid={validCheck === "REMOVE"} onClick={event => parentRowOpen(event)}>
        {!data.child ? (
          <AlignCenterData style={{ width: "5%" }}>
            {
              <AnimatedCheckbox
                id={metadataId}
                isChecked={checkList.get(metadataId)}
                onToggle={checked => onToggleCheck(checked, metadataId)}
              />
            }
          </AlignCenterData>
        ) : !data.child.length && typeSubClass !== "track" && typeSubClass !== "effect" ? (
          <AlignCenterData style={{ width: "5%" }}>{}</AlignCenterData>
        ) : typeSubClass === "track" || typeSubClass === "effect" ? (
          <AlignCenterData style={{ width: "5%" }}>
            <AnimatedCheckbox
              id={metadataId}
              isChecked={checkList.get(metadataId)}
              isDisabled={validCheck === "REMOVE"}
              onToggle={checked => onToggleCheck(checked, metadataId)}
            />
          </AlignCenterData>
        ) : (
          <OnOffData style={{ width: "5%" }} isOpen={isOpen}>
            {isOpen ? <MinusIcon className="minus" /> : <PlusIcon className="plus" />}
          </OnOffData>
        )}
        {metadataId ? <Table.Data style={{ width: "15%" }}>{<ClipBoard text={metadataId} />}</Table.Data> : <Table.Data>{}</Table.Data>}
        <Table.Data style={{ width: "5%" }}>{no ?? ""}</Table.Data>
        {title ? (
          <TitleContainer>
            {typeSubClass !== "text" && <ListenIcon className="listen" />}
            <ClipBoard text={title} />
            {validCheck === ValidType.REMOVE ? null : typeSubClass !== "text" ? (
              <>
                <DeleteIcon
                  className="hoverSVG"
                  onClick={event => {
                    event.currentTarget.toggleAttribute("clicked");
                    toRemovedMetadata(metadataId, metadataStructure[0].structureId, true, validCheck, data.child.length).finally(() => {
                      const svgButtonList = document.querySelectorAll("svg");
                      itiriri(svgButtonList.entries()).forEach(([key, value]) => value.toggleAttribute("clicked", false));
                    });
                  }}
                />
                <WriteIcon
                  className="hoverSVG"
                  onClick={event => {
                    event.currentTarget.toggleAttribute("clicked");
                    openEditTitleModal(metadataId, title, index).finally(() => {
                      const svgButtonList = document.querySelectorAll("svg");
                      itiriri(svgButtonList.entries()).forEach(([key, value]) => value.toggleAttribute("clicked", false));
                    });
                  }}
                />
              </>
            ) : (
              <>
                <DeleteIcon
                  className="hoverSVG"
                  onClick={event => {
                    event.currentTarget.toggleAttribute("clicked");
                    toRemovedMetadata(metadataId, metadataStructure[0].structureId, false, validCheck, data.child.length).finally(() => {
                      const svgButtonList = document.querySelectorAll("svg");
                      itiriri(svgButtonList.entries()).forEach(([key, value]) => value.toggleAttribute("clicked", false));
                    });
                  }}
                />
                <SaveIcon
                  className="hoverSVG"
                  onClick={event => {
                    event.currentTarget.toggleAttribute("clicked");
                    setInitTrackList();
                    editTextModal.on();
                  }}
                />
                <WriteIcon
                  className="hoverSVG"
                  onClick={event => {
                    event.stopPropagation();
                    editGroupTitleModal.on();
                  }}
                />
              </>
            )}
          </TitleContainer>
        ) : (
          <Table.Data>{}</Table.Data>
        )}
        {artistRelation.length ? (
          <Table.Data>
            {artistRelation.map(({ artist }: any) => (
              <div key={createHash()}>{artist[0].name}</div>
            ))}
          </Table.Data>
        ) : (
          <Table.Data>{}</Table.Data>
        )}
      </ParentRow>
      {data.child && data.child.length && isOpen
        ? data.child.map(({ metadata }: any, childIndex: number) => {
            const uniqueIndex = parseInt(`${index}${childIndex}`);
            return (
              <ChildDataTemplate
                key={uniqueIndex}
                index={index}
                metadata={metadata}
                childIndex={childIndex}
                childLength={data.child.length}
                isOpen={isOpen}
                onToggleCheck={onToggleCheck}
                toRemovedMetadata={toRemovedMetadata}
                openEditTitleModal={openEditTitleModal}
              />
            );
          })
        : null}

      <Modal isOpen={editTextModal.isToggled}>
        <EditTextModal index={index} metadataStructure={metadataStructure} toClose={toCloseTrackMoved} />
      </Modal>
      <Modal isOpen={editGroupTitleModal.isToggled}>
        <EditGroupTitleModal index={index} toClose={editGroupTitleModal.off} />
      </Modal>
    </>
  );
};
