import React, { useMemo, ReactNode, FormEvent, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import itiriri from "itiriri";
import { Input } from "App/Atomics/Input";
import { Pagination } from "App/Molecules/Pagination";
import { useAppDispatch, useAppSelector } from "App/Store";
import { TableTemplate } from "App/Templates/TableTemplate";
import { PRIMARY_COLOR } from "constants/color";
import { HEADER_HEIGHT_PX, MARGING_LARGE_PX, pixelize, UNIT } from "constants/size";
import { useTracksStore } from "../Store";
import { useHistory } from "react-router";
import { WORKS_TRACK, WORKS, WORKS_TRACK_EDIT } from "constants/route";
import { Modal } from "lib/modal";
import { WorkTrackTableInfoActions } from "../Store/TableInfo";
import { DataTemplate } from "./TrackItem";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { useQueryParams } from "lib/use-query-params";
import { subheadKeyValue } from "./subheadKeyValue";
import { UserRole } from "constants/UserRole";
import { LoadingAction } from "App/Store/Loading";
import { useAsyncEffect } from "lib/use-async-effect";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { WorkTrackActions } from "../Store/Track";
import { FloatingButton } from "App/Atomics/FloatingButton";
import { useToggle } from "lib/use-toggle";
import { BatchEditModal } from "./Modals";
import { PlaylistModal } from "./Modals";
import { FilterModalTemplate } from "App/Templates/FilterModalTemplate";
import { SearchColumnList, SearchForm } from "App/Organisms/SearchForm";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { KeyInfo } from "lib/key-info";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

type Track = {
  metadataId: string;
  title: string;
  no: string;
  validCheck: string;
};

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow-x: scroll;
  overflow-y: auto;

  width: 100%;

  table {
    margin-top: ${HEADER_HEIGHT_PX};

    th button {
      display: inline;
      margin-left: ${MARGING_LARGE_PX};
      margin-right: 0;
    }
  }

  form {
    margin-left: ${MARGING_LARGE_PX};
  }
`;

const CountSelect = styled(Input.TextSelect)`
  width: ${pixelize(UNIT * 8)};
  min-width: ${pixelize(UNIT * 7)};
  margin-left: auto;

  .countSelect__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .countSelect__menu {
    .countSelect__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

const FloatingAddButton = styled(FloatingButton)`
  font-size: 0.9rem;
`;
const FloatingEditButton = styled(FloatingButton)`
  font-size: 0.9rem;
  bottom: ${pixelize(UNIT * 6.5)};
`;

const TrackCountInfo = [
  { id: "10", name: "10개 보기" },
  { id: "20", name: "20개 보기" },
  { id: "30", name: "30개 보기" },
  { id: "40", name: "40개 보기" },
  { id: "50", name: "50개 보기" },
  { id: "100", name: "100개 보기" }
];

export const RightTrackTable = () => {
  const router = useHistory();
  const dispatchApp = useAppDispatch();
  const { userRole, loading, store } = useAppSelector(store => ({
    userRole: store.UserToken.role,
    loading: store.Loading.loading,
    store
  }));
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const queryParams = useQueryParams();
  const filter = queryParams.get("filter", { default: undefined });
  const encodeFilter = window.encodeURIComponent(filter);
  const checkList = queryParams.getAll("ids");
  const url = checkList.map(id => `ids=${id}`);
  const batchEditModal = useToggle();
  const playlistModal = useToggle();
  const filterModal = useToggle();
  const [{ edge, tracks, head, subhead, count, editCheckList }, dispatchTracks] = useTracksStore(store => ({
    edge: store.WorkTrack.edge,
    tracks: store.WorkTrack.tracks,
    head: store.WorkTrackTableInfo.head,
    subhead: store.WorkTrackTableInfo.subhead,
    count: store.WorkTrackTableInfo.count,
    editCheckList: store.WorkTrack.checkList
  }));

  const onToggleAllCheck = (checked: boolean) => {
    tracks.forEach((track: Track) => {
      dispatchTracks(WorkTrackActions.setTrackCheckList({ id: track.metadataId, checked }));
    });
  };

  const isChecked = (list: Map<string, boolean>) => {
    const filteredList = Array.from(list).filter(item =>
      tracks.map(({ metadataId }: { metadataId: string }) => metadataId).includes(item[0])
    );
    return list.size === 0 || !filteredList.length ? false : filteredList.every(item => item[1]);
  };

  const subheadComponentMap = {
    "#": () => (
      <AnimatedCheckbox
        id="allCheck"
        isChecked={isChecked(editCheckList)}
        onToggle={() =>
          onToggleAllCheck(
            editCheckList.size === 0
              ? true
              : Array.from(editCheckList)
                  .filter(item => tracks.map(({ metadataId }: { metadataId: string }) => metadataId).includes(item[0]))
                  .some(item => !item[1])
          )
        }
      />
    ),
    validCheck: (props: { subhead: string }) => <>{props.subhead}</>,
    language: (props: { subhead: string }) => <>{props.subhead}</>,
    artist: (props: { subhead: string }) => <>{props.subhead}</>,
    role: (props: { subhead: string }) => <>{props.subhead}</>,

    character: (props: { subhead: string }) => <>{props.subhead}</>,

    company: (props: { subhead: string }) => <>{props.subhead}</>,
    genre: (props: { subhead: string }) => <>{props.subhead}</>,
    mood: (props: { subhead: string }) => <>{props.subhead}</>
  };

  const createHeads = () => {
    const subheadList = [] as ReactNode[];
    const headList = itiriri(Object.entries(head))
      .filter(([key, isToggledKey]) => {
        if (
          (!(userRole === UserRole.Master || allowMetadataUpdate(store)) && key === "#") ||
          (userRole === UserRole.Arbeit && (key === "Company" || key === "Title")) ||
          (userRole !== UserRole.Master && key === "LicenseExtra")
        ) {
          return false;
        } else {
          return isToggledKey;
        }
      })
      .toArray(([key]) => {
        const localSubheadList = itiriri(Object.entries(subhead[key as keyof typeof subhead]))
          .filter(([subKey, isToggledSubKey]) => {
            const subKeyValidate = subKey === "typeUrl" || subKey === "fileValidCheck";
            if (userRole === UserRole.Arbeit && subKeyValidate) {
              return false;
            }
            return isToggledSubKey;
          })
          .toArray(([subKey]) => {
            const Component = subheadComponentMap[subKey as keyof typeof subheadComponentMap];
            if (!Component) {
              return subheadKeyValue[key][subKey];
            }
            return <Component subhead={subheadKeyValue[key][subKey]} />;
          });
        subheadList.push(...localSubheadList);
        return {
          name: key,
          colSpan: localSubheadList.length
        };
      });
    return { headList, subheadList };
  };
  const { headList, subheadList } = useMemo(createHeads, [head, subhead, tracks, editCheckList]);

  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);

  const onSearch = (e: FormEvent<HTMLFormElement>, searchStr: string) => {
    e.preventDefault();
    if (searchStr.length <= 1) return;
    if (searchStr === getSearchString(searchType)) {
      return;
    }
    if (!searchStr) {
      router.push(`?page=1${url.length ? `&${url.join("&")}` : ""}`);
      return;
    }
    if (searchStr && searchType === "id" && !searchStr.match(/^[0-9]+$/)) {
      window.alert("아이디는 숫자만 입력가능합니다.");
      return;
    }
    dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
    const encodeStr = window.encodeURIComponent(searchStr);
    router.push(`?page=1${url.length ? `&${url.join("&")}` : ""}&${searchType}=${encodeStr}${!filter ? "" : `&filter=${encodeFilter}`}`);
  };

  const handleFloatingAddButton = () => {
    playlistModal.on();
  };

  const handleFloatingEditButton = () => {
    if (Array.from(editCheckList).filter(list => list[1]).length >= 50) {
      window.alert("50개 이상은 한 번에 편집할 수 없습니다.");
      return;
    }
    const url = Array.from(editCheckList)
      .filter(item => item[1])
      .map(item => `ids=${item[0]}`)
      .join("&");
    router.push(WORKS_TRACK_EDIT + `?page=1&${url}`);
  };

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        dispatchTracks(WorkTrackActions.clearTrackCheckList());
      }
    },
    [dispatchTracks]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  useAsyncEffect(
    async isMounted => {
      if (isMounted() && edge && !tracks.length) {
        dispatchApp(LoadingAction.setLoading(LOADING.NULL));
      }
      if (isMounted()) {
        const ids = tracks.map((track: Track) => track.metadataId);
        dispatchTracks(WorkTrackActions.setTrackCheckListInit(ids));
      }
    },
    [tracks, edge]
  );

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        {userRole === UserRole.Master && <Input.Button onClick={batchEditModal.on}>일괄 편집</Input.Button>}
        <Input.Button onClick={() => router.push(WORKS)}>대작품으로 이동</Input.Button>
        <Input.Button onClick={filterModal.on}>필터</Input.Button>
        <CountSelect
          classNamePrefix="countSelect"
          optionList={TrackCountInfo}
          defaultValue={{ id: "CountSelect", name: `${count}개 보기` }}
          onChange={info => {
            if (info) {
              dispatchTracks(WorkTrackTableInfoActions.setTrackCount(Number(info.id)));
            }
          }}
        />
        <SearchForm onChangeSearchTypeInfo={info => setSearchType(info!.id)} onSearch={onSearch} />
      </TopbarTemplate>

      <TableTemplate
        headList={headList}
        subHeadList={subheadList}
        keyBy={(data, index) => index}
        dataList={loading === LOADING.UNLOAD ? [] : tracks}
        Column={DataTemplate}
      />
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      <Pagination
        edge={edge}
        goTo={index =>
          `${WORKS_TRACK}?page=${index}${url.length ? `&${url.join("&")}` : ""}${
            !getSearchString(searchType) ? "" : `&${searchType}=${getSearchString(searchType)}`
          }${!filter ? "" : `&filter=${encodeFilter}`}`
        }
      />
      {!Array.from(editCheckList).some(item => item[1]) ? null : (
        <>
          <FloatingAddButton color="primary" onClick={handleFloatingAddButton}>
            ADD
          </FloatingAddButton>
          <FloatingEditButton color="success" onClick={handleFloatingEditButton}>
            EDIT
          </FloatingEditButton>
        </>
      )}
      <Modal isOpen={batchEditModal.isToggled}>
        <BatchEditModal toClose={batchEditModal.off} />
      </Modal>
      <Modal isOpen={playlistModal.isToggled}>
        <PlaylistModal toClose={playlistModal.off} />
      </Modal>
      <Modal isOpen={filterModal.isToggled}>
        <FilterModalTemplate route={WORKS_TRACK} toClose={filterModal.off} />
      </Modal>
    </Layout>
  );
};
