export enum MetadataOrderByInput {
  id_ASC = "metadata_id__ASC",
  id_DESC = "metadata_id__DESC",
  v1_ASC = "compatibility_v1_id__ASC",
  v1_DESC = "compatibility_v1_id__DESC",
  no_ASC = "no__ASC",
  no_DESC = "no__DESC",
  title_ASC = "title__ASC",
  title_DESC = "title__DESC",
  typeMetadataClass_ASC = "type_metadata_class__ASC",
  typeMetadataClass_DESC = "type_metadata_class__DESC",
  typeMetadata_subclass_ASC = "type_metadata_subclass__ASC",
  typeMetadata_subclass_DESC = "type_metadata_subclass__DESC",
  validCheck_ASC = "valid_check__ASC",
  validCheck_DESC = "valid_check__DESC",
  createdAt_ASC = "createdAt__ASC",
  createdAt_DESC = "createdAt__DESC",
  updatedAt_ASC = "updatedAt__ASC",
  updatedAt_DESC = "updatedAt__DESC"
}
