import React, { useState, FormEvent, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { Input } from "App/Atomics/Input";
import { useAppStore } from "App/Store";
import { Pagination } from "App/Molecules/Pagination";
import { useWorkStore } from "../Store";
import { WORKS, WORKS_TRACK } from "constants/route";
import { useHistory } from "react-router";
import { CardList } from "./CardList";
import { Modal } from "lib/modal";
import { BatchEditModal } from "../Modal";
import { useToggle } from "lib/use-toggle";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { WorkActions } from "../Store/Work";
import { useQueryParams } from "lib/use-query-params";
import { Loading } from "App/Atomics/Loading";
import { FilterModalTemplate } from "App/Templates/FilterModalTemplate";
import { SearchForm, SearchColumnList } from "App/Organisms/SearchForm";
import { Toast } from "lib/toast";
import ContentLoader from "react-content-loader";
import { HEADER_HEIGHT_PX } from "constants/size";
import { mediaQuery } from "constants/media";
import { UserRole } from "constants/UserRole";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

const Layout = styled.div`
  display: inherit;
  width: 100%;
  flex-direction: column;
  overflow: auto;
`;

export const RightWorkTable = ({ count }: { count: number }) => {
  const [{ store, userRole }] = useAppStore(store => ({
    store,
    userRole: store.UserToken.role
  }));
  const [{ works, edge, loading, editLoading }, dispatch] = useWorkStore(store => store.Work);
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const [screenX, setScreenX] = useState<number>(window.innerWidth);
  const router = useHistory();
  const batchEditModal = useToggle();
  const filterModal = useToggle();
  const queryParams = useQueryParams();
  const filter = queryParams.get("filter", { default: undefined });
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  const allowWorkEdit = userRole === UserRole.Master || allowMetadataUpdate(store);
  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);
  const getPageSearchType = () => {
    for (const { id } of SearchColumnList) {
      if (queryParams.get(id)) {
        return id;
      }
    }
    return undefined;
  };

  const onSearch = useCallback(
    (e: FormEvent<HTMLFormElement>, searchStr: string) => {
      e.preventDefault();
      if (searchStr.length <= 1) return;
      if (searchStr === getSearchString(searchType)) {
        return;
      }
      if (!searchStr) {
        router.push(`${WORKS}?page=1${!filter ? "" : `&filter=${filter}`}`);
        return;
      }
      if (searchType === SearchColumnList[0].id && !searchStr.match(/^[0-9]+$/)) {
        Toast.error("아이디는 숫자만 입력가능합니다.", undefined, "top-center");
        return;
      }
      dispatch(WorkActions.setLoading(LOADING.UNLOAD));
      router.push(`${WORKS}?page=1&${searchType}=${searchStr}${!filter ? "" : `&filter=${filter}`}`);
    },
    [dispatch, filter, getSearchString, router, searchType]
  );

  const getPlaceholderWidth = useMemo(() => {
    if (screenX > 1024) {
      return screenX * 0.18;
    } else if (screenX > 768) {
      return screenX * 0.225;
    } else {
      return screenX * 0.45;
    }
  }, [screenX]);

  useEffect(() => {
    const handler = () => {
      setScreenX(document.body.clientWidth);
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);
  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        {allowWorkEdit && <Input.Button onClick={batchEditModal.on}>일괄 편집</Input.Button>}
        <Input.Button onClick={() => router.push(`${WORKS_TRACK}?page=1`)}>소작품 이동</Input.Button>
        <Input.Button onClick={filterModal.on}>필터</Input.Button>
        <SearchForm route={WORKS} searchType={searchType} onChangeSearchTypeInfo={info => setSearchType(info!.id)} onSearch={onSearch} />
      </TopbarTemplate>
      {loading === LOADING.UNLOAD && (
        <LoadingPlaceholder>
          {[...Array(count)].map((_, key) => (
            <Placeholder key={key} width={getPlaceholderWidth} />
          ))}
        </LoadingPlaceholder>
      )}
      {loading === LOADING.ONLOAD && <CardList works={works} />}
      <LoadingTemplate loading={loading} searchString={getSearchString(searchType)} />
      {loading === LOADING.ONLOAD && (
        <Pagination
          edge={edge}
          goTo={index => {
            const pageSearchType = getPageSearchType();
            return `${WORKS}?page=${index}${!pageSearchType ? "" : `&${pageSearchType}=${getSearchString(pageSearchType)}`}${
              !filter ? "" : `&filter=${filter}`
            }`;
          }}
        />
      )}
      <Modal isOpen={batchEditModal.isToggled}>
        <BatchEditModal toClose={batchEditModal.off} />
      </Modal>
      <Modal isOpen={filterModal.isToggled}>
        <FilterModalTemplate route={WORKS} toClose={filterModal.off} />
      </Modal>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const LoadingPlaceholder = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${HEADER_HEIGHT_PX};
  justify-content: center;
  padding: 2em 2em 0;
  svg {
    margin: 0 0.6em;
  }
  ${mediaQuery(768)} {
    svg {
      margin: 0 0.3em;
    }
  }
`;

const Placeholder = ({ width }: { width: number }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={width * 1.3}
    viewBox={`0 0 ${width} ${width * 1.3}`}
    backgroundColor="#f2f2f2"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="2" ry="2" width={width} height={width} />
    <rect x="0" y={width * 1.03} rx="2" ry="2" width={width * 0.2} height={width * 0.03} />
    <rect x="0" y={width * 1.07} rx="2" ry="2" width={width * 0.9} height={width * 0.03} />
    <rect x="0" y={width * 1.11} rx="2" ry="2" width={width * 0.3} height={width * 0.03} />
  </ContentLoader>
);
