import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { VideoCreateActions as VideoCreate } from "./VideoCreate";

const reducers = { VideoCreate };

const reducer = combineReducers(reducers, createStoreLogger("VideoCreate"));

export const {
  StoreProvider: VideoCreateStoreProvider,
  useStore: useVideoCreateStore,
  useSelector: useVideoCreateSelector,
  useDispatch: useVideoCreateDispatch
} = createStore(reducer);
