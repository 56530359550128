/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, IntScalar, BooleanScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar, UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

type Option = Readonly<{
  uuid: string;
  isService?: number;
  neighboring?: boolean;
  copyright?: boolean;
  licenseDate?: string;
  validCheck?: ValidType;
  publishDate?: string;
}>;

export const UpdateTrackLicense = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $copyright = <Variable name="copyright" scalar={<BooleanScalar isNullable />} value={option.copyright} />;
  const $neighboring = <Variable name="neighboring" scalar={<BooleanScalar isNullable />} value={option.neighboring} />;
  const $isService = <Variable name="is_service" scalar={<IntScalar isNullable />} value={option.isService} />;
  const $licenseDate = <Variable name="license_date" scalar={<DateTimeScalar isNullable />} value={option.licenseDate} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const $publishDate = <Variable name="publish_date" scalar={<DateTimeScalar isNullable />} value={option.publishDate} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_TRACK_LICENSE">
      <SelectionSet
        name="updateTrack_license"
        alias="UpdateTrackLicense"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            copyright: $copyright,
            neighboring: $neighboring,
            is_service: $isService,
            license_date: $licenseDate,
            valid_check: $validCheck,
            publish_date: $publishDate
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    UpdateTrackLicense: Readonly<{
      id: string;
    }>;
  }>;

  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }

  return data!.UpdateTrackLicense.id;
};
