import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { ProductDetailStore } from "App/Routes/ProductDetail/Store";
import { ProductDetailActions } from "App/Routes/ProductDetail/Store/ProductDetail";

type Props = {
  readonly price: number;
};

export const ProductPrice = ({ price }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>가격</Style.Caption>
    <Style.SubCaption>ex. 12389912</Style.SubCaption>
    <Style.InputDate
      type="number"
      placeholder={"가격"}
      defaultValue={price}
      onBlur={event => ProductDetailStore.dispatch(ProductDetailActions.setPrice(+event.target.value))}
    />
    <Style.AlertCaption data-visible={price === undefined}>가격을 입력해주세요.</Style.AlertCaption>
  </Style.Row>
);
