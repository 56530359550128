import { AfterChange } from "lib/store/combineReducers";
import { Middleware } from "./createAfterChange";
import { identity } from "utils/identity";

type MiddlewareList<S> = readonly Middleware<S>[];

export const combineAfterChanges = <S>(middlewareList: MiddlewareList<S>): AfterChange<S> => (previousStore, nextStore) => {
  const [middleware, ...nextMiddlewareList] = middlewareList;
  if (middleware) {
    const next = nextMiddlewareList.length ? () => combineAfterChanges(nextMiddlewareList) : identity;
    middleware(previousStore, nextStore, next);
  }
};
