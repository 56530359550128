import { TRACK_FILTER, TRACK_COUNT } from "constants/storageKey";
import { createActions, createHandler, createReducer } from "lib/store";
import { Head, sanitizeHeads, Subhead } from "./sanitizeHeads";

type State = ReturnType<typeof createInitialState>;

const SET_HEAD = Symbol("TableInfo::Head::Set");
const SET_SUB_HEAD = Symbol("TableInfo::SubHead::Set");
const SET_TRACK_COUNT = Symbol("TableInfo::TrackCount::Set");

export const TableInfoActions = createActions({
  setHead(head: Head) {
    return { type: SET_HEAD, head };
  },
  setSubhead(subhead: Subhead) {
    return { type: SET_SUB_HEAD, subhead };
  },
  setTrackCount(count: number) {
    return { type: SET_TRACK_COUNT, count };
  }
});

const handler = createHandler<State>({
  [SET_HEAD](state, payload: { head: Head }) {
    state.head = payload.head;
  },
  [SET_SUB_HEAD](state, payload: { subhead: Subhead }) {
    state.subhead = payload.subhead;
  },
  [SET_TRACK_COUNT](state, payload: { count: number }) {
    state.count = payload.count;
  }
});

const loadHeads = () => {
  try {
    const trackFilter = window.localStorage.getItem(TRACK_FILTER);
    return sanitizeHeads(trackFilter ? JSON.parse(trackFilter) : {});
  } catch {
    return sanitizeHeads({});
  }
};

const loadTrackCount = () => {
  try {
    const trackCount = window.localStorage.getItem(TRACK_COUNT);
    return trackCount ? parseInt(JSON.parse(trackCount).count) : 10;
  } catch (e) {
    console.log(e);
    return 10;
  }
};

const createInitialState = () => {
  return {
    ...loadHeads(),
    count: loadTrackCount()
  };
};

const afterChanges = (state: State) => {
  const { head, subhead, count } = state;
  window.localStorage[TRACK_FILTER] = JSON.stringify({
    Head: head,
    Subhead: subhead
  });
  window.localStorage[TRACK_COUNT] = JSON.stringify({
    count: count
  });
};

export const reducer = createReducer(handler, createInitialState, afterChanges);
