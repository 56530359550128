export enum PlaylistOrderByInput {
  playlist_id_ASC = "playlist_id__ASC",
  playlist_id_DESC = "playlist_id__DESC",
  name_ASC = "name__ASC",
  name_DESC = "name__DESC",
  kind_ASC = "kind__ASC",
  kind_DESC = "kind__DESC",
  valid_check_ASC = "valid_check__ASC",
  valid_check_DESC = "valid_check__DESC",
  service_ASC = "service__ASC",
  service_DESC = "service__DESC"
}
