import React, { useState } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  PADDING_X_LARGE_PX,
  MARGING_X_LARGE,
  PADDING_LARGE,
  MARGING_X_LARGE_PX
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { WHITE, GRAY_4, BLACK } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { DANGER_ALERT_COLOR, PRIMARY_COLOR } from "constants/color";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { useRolesStore } from "App/Routes/Roles/Store";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetRoleTitle, DeleteRoleTitle } from "GraphQL/Queries/Role";
import { RoleActions } from "App/Routes/Roles/Store/Role";
import { CreateRoleTitleModal } from "../CreateRoleTitleModal";
import { UpdateRoleTitleModal } from "../UpdateRoleTitleModal";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, CreateAccessRecord } from "GraphQL/Queries";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { Toast } from "lib/toast";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
import { Loading } from "App/Atomics/Loading";

type Props = Readonly<{
  index: number;
  roleId: string;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 37.75)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  min-height: ${pixelize(UNIT * 9)};
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.25rem;
    margin: ${MARGING_X_LARGE_PX} 0;
  }
  .title {
    padding: ${pixelize(PADDING_X_LARGE * 1.4)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35%;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  padding: 0 ${pixelize(PADDING_X_LARGE * 1.4)};
  grid-gap: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;
  & > span {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: ${MARGING_LARGE_PX};
  }

  .row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${MARGING_LARGE_PX};
    .select {
      width: ${pixelize(UNIT * 18)};
      font-size: 12px;
      margin-right: ${MARGING_LARGE_PX};
    }
  }
`;

const FilterContainer = styled.div``;

const FilterBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    transform: translateY(-1px);
  }
  span {
    font-size: 14px;
    font-weight: bold;
    color: #4c52bc;
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${PADDING_X_LARGE_PX};
  margin: ${pixelize(MARGING_X_LARGE * 1.5)};
  width: ${pixelize(UNIT * 32)};
  height: ${pixelize(UNIT * 9.6)};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};

  .titleContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: ${MARGING_SMALL_PX};
    height: ${pixelize(UNIT * 2)};
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;
    span {
      color: #757575;
      line-height: 1.43;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const EplipsisMultiline = styled(Input.Multiline)`
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  height: ${pixelize(UNIT * 9.6)};
  border: none;
  padding: 0;
  color: #212121;
  line-height: 1.43;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const FloatButtonContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
`;
const FloatingButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  border-radius: 50%;
  box-shadow: 0 4px 4px ${GRAY_4};
  margin: ${MARGING_LARGE_PX} ${MARGING_XX_LARGE_PX};
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  font-weight: bold;
  button {
    padding: ${pixelize(PADDING_LARGE * 0.8)} ${PADDING_X_LARGE_PX};
    border-radius: 4px;
    margin-right: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
  .edit-btn {
    background: #4c52bc;
    color: white;

    &:hover {
      background: #6c74dd;
    }
  }

  .delete-btn {
    background: ${WHITE};
    color: #4c52bc;
    border: 1.5px solid #4c52bc;

    &:hover {
      color: #6c74dd;
      border: 1.5px solid #6c74dd;
    }
  }
`;

const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;
    .primary-underline {
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const RoleTitleModal = ({ index, roleId, toClose }: Props) => {
  const [{ typeClass, roleTitles, editLoading }, dispatch] = useRolesStore(store => ({
    roleTitles: !store.Role.roleTitle ? [] : store.Role.roleTitle,
    typeClass: store.Role.roles[index].typeClass,
    editLoading: store.Role.editLoading
  }));
  const [languageCode, setLanguageCode] = useState<string | undefined>(undefined);
  const createRoleTitle = useToggle();
  const updateRoleTitle = useToggle();
  useAsyncEffect(
    async isMounted => {
      dispatch(RoleActions.setRoleEditLoading(true));
      if (isMounted()) {
        try {
          const { data, errors } = await GetRoleTitle({ roleId, languageCode });
          if (errors) {
            throw new Error(errors[0].message);
          }
          if (data) {
            dispatch(RoleActions.setRoleTitle(data.getRoleTitle[0].roleTitle));
            dispatch(RoleActions.setRoleEditLoading(false));
          }
        } catch (e) {
          console.log(e);
          dispatch(RoleActions.setRoleEditLoading(false));
          Toast.error("데이터를 가져올 수 없습니다.", undefined, "top-center");
          return;
        }
      }
    },
    [languageCode]
  );

  const openUpdateModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(RoleActions.setModalId(id));
    updateRoleTitle.on();
  };

  const deleteRoleTitle = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    dispatch(RoleActions.setRoleEditLoading(true));
    try {
      const { data } = await CreateAccessRecord({ targetId: roleId, targetTable: TargetTableInput.Role });
      if (data) {
        const { data: RoleData } = await DeleteRoleTitle({ id });

        if (RoleData) {
          await DeleteAccessRecord({ id: data.createAccess.id });
          Toast.primary("삭제되었습니다.", undefined, "bottom-right");
          dispatch([RoleActions.deleteRoleTitle(id), RoleActions.setRoleEditLoading(false)]);
        }
      }
    } catch (e) {
      console.log(e);
      Toast.error("삭제에 실패하였습니다.");
      dispatch(RoleActions.setRoleEditLoading(false));
    }
  };

  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>Role Title</h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
        <MenuContainer>
          <span>언어 필터</span>
          <div className="row-center">
            <LanguageTextSelect
              key={!languageCode ? "" : languageCode}
              className="select"
              defaultValue={LanguageCodeInfo.find(({ id }) => id === languageCode)}
              onChange={info => {
                if (info) {
                  setLanguageCode(info.id);
                }
              }}
            />
            <FilterContainer>
              <FilterBox onClick={() => setLanguageCode(undefined)}>
                <RefreshIcon />
                <span>필터 초기화</span>
              </FilterBox>
            </FilterContainer>
          </div>
        </MenuContainer>
      </Header>
      {!roleTitles.length ? (
        <NoData>
          <span>
            데이터가 없습니다.{" "}
            <>
              필터를{" "}
              <button className="primary-underline" onClick={() => setLanguageCode(undefined)}>
                초기화
              </button>
              하거나{" "}
              <button className="danger-underline" onClick={createRoleTitle.on}>
                추가 버튼
              </button>
              을 눌러주세요.
            </>
          </span>
        </NoData>
      ) : (
        roleTitles.map(title => {
          const { id, type, value, language } = title;
          return (
            <DescCard key={id}>
              <div className="titleContainer">
                <div className="titleBox">
                  <span>
                    {type} | {language[0].languageCode}
                  </span>
                </div>
              </div>
              <EplipsisMultiline isDisabled value={value} />
              <ButtonGroup>
                <button className="delete-btn" onClick={e => deleteRoleTitle(e, id)}>
                  삭제
                </button>
                <button className="edit-btn" onClick={e => openUpdateModal(e, id)}>
                  수정
                </button>
              </ButtonGroup>
            </DescCard>
          );
        })
      )}
      <MarginDiv>{"0"}</MarginDiv>
      <Loading loading={editLoading} />
      <FloatButtonContainer>
        <FloatingButton color="danger" onClick={createRoleTitle.on}>
          +
        </FloatingButton>
      </FloatButtonContainer>
      <Modal isOpen={createRoleTitle.isToggled}>
        <CreateRoleTitleModal roleId={roleId} typeClass={typeClass} order={roleTitles.length} toClose={createRoleTitle.off} />
      </Modal>
      <Modal isOpen={updateRoleTitle.isToggled}>
        <UpdateRoleTitleModal roleId={roleId} toClose={updateRoleTitle.off} />
      </Modal>
    </Layout>
  );
};
