import React from "react";
import { CategoryOfGoods } from "GraphQL/Scalars/CategoryOfGoodsScalar";
import { FilterTemplate } from "../FilterTemplate";
import { GoodsStore } from "App/Routes/Goods/Store";
import { GoodsActions } from "App/Routes/Goods/Store/Goods";

type Props = {
  readonly category: Set<CategoryOfGoods>;
};

export const CategoryFilter = ({ category }: Props) => {
  const optionList = Object.entries(CategoryOfGoods).map(([key, value]) => ({ key, value, isSelected: category.has(value) }));
  const onSelectItem = (value: string) => GoodsStore.dispatch(GoodsActions.toggleCategory(value as CategoryOfGoods));
  const selectedList = optionList.filter(({ isSelected }) => isSelected).map(({ key }) => key);
  const subTitle = !selectedList.length ? "모든 카테고리" : selectedList.join(", ");
  return <FilterTemplate title={"카테고리"} subTitle={subTitle} optionList={optionList} onSelectItem={onSelectItem} />;
};
