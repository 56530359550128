import { createDuck } from "lib/store/v2";
import itiriri from "itiriri";
import { defaultArbeit } from "../../AuthDefault/Arbeit";
import { defaultPartner } from "../../AuthDefault/Partner";
import { UserRole } from "constants/UserRole";
import { Column } from "constants/Column";
import { defaultArtist } from "../../AuthDefault/Artist";

type AllowTables = typeof defaultArbeit | typeof defaultPartner | typeof defaultArtist;

type Relation = {
  key: string;
  allow: string;
};

type Table = {
  key: string;
  name: string;
  allow: string;
  relation: Relation[] | null;
};

export enum GrantKey {
  GOODS = "Goods",
  TRACK = "Track",
  ARTIST = "Artist",
  GENRE = "Genre",
  PLAYLIST = "Playlist",
  COMPANY = "Company",
  ROLE = "Role",
  LANGUAGE = "Language",
  METADATA_ARTIST_RELATION = "MetadataArtistRelation",
  ADMIN = "Admin"
}

const createInitialState = () => ({
  grantKey: UserRole.Arbeit,
  allows: defaultArbeit as AllowTables,
  arbeits: defaultArbeit,
  partners: defaultPartner,
  field: [] as Column[]
});

export const AuthGrantActions = createDuck({
  namespace: "Grant",
  createInitialState,
  reducers: {
    setGrantKey(state, grantKey) {
      state.grantKey = grantKey;
    },
    setArbeitAllowByKey(state, { task, key, index, rwe }: { task: string; key: string; index: number; rwe: string }) {
      let tables: Table[] = [];
      const arbeitAllow = state.allows as typeof defaultArbeit;
      switch (task) {
        case GrantKey.GOODS:
          tables = state.allows.Goods.tables;
          break;
        case GrantKey.TRACK:
          tables = state.allows.Metadata.tables;
          break;
        case GrantKey.ARTIST:
          tables = arbeitAllow.Artist.tables;
          break;
        case GrantKey.ROLE:
          tables = arbeitAllow.Role.tables;
          break;
        case GrantKey.GENRE:
          tables = arbeitAllow.Genre.tables;
          break;
        case GrantKey.PLAYLIST:
          tables = arbeitAllow.Playlist.tables;
          break;
        case GrantKey.COMPANY:
          tables = arbeitAllow.Company.tables;
          break;
        case GrantKey.LANGUAGE:
          tables = arbeitAllow.Langauge.tables;
          break;
        case GrantKey.METADATA_ARTIST_RELATION:
          tables = arbeitAllow.MetadataArtistRelation.tables;
          break;
        case GrantKey.ADMIN:
          tables = arbeitAllow.Admin.tables;
          break;
        default:
          break;
      }

      const targetTable = tables.find(table => table.key === key);

      if (targetTable) {
        const allowBinary = (+targetTable.allow[index]).toString(2);
        const allow =
          targetTable.allow.substr(0, index) +
          (parseInt(allowBinary, 2) ^ parseInt(rwe, 2)).toString() +
          targetTable.allow.substr(index + 1);
        targetTable.allow = allow;

        const filteredTables = itiriri(Object.entries(arbeitAllow))
          .filter(([key, value]) => value.name !== task)
          .map(([key, value]) => value.tables);
        filteredTables.forEach(tables => {
          tables.forEach(table => {
            if (table.key === key) {
              table.allow = allow;
            }
          });
        });
      }
    },
    setUsersAllowTables(state, allowTables: AllowTables) {
      state.allows = allowTables;
    },
    setField(state, columns: Column[]) {
      state.field = columns;
    },
    addField(state, column: Column) {
      state.field.push(column);
    },
    removeField(state, column: Column) {
      const newField = state.field.filter(col => col !== column);
      state.field = newField;
    }
  }
});
