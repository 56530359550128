/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar, IntScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  typeTitle: string;
  value: string;
  languageCode: string;
  order: number;
}>;

export const UpdateGoodsTitle = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar isNullable={false} />} value={option.uuid} />;
  const $typeTitle = <Variable name="type_title" scalar={<StringScalar />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS_TITLE">
      <SelectionSet
        name="updateGoods_title"
        alias="updateGoodsTitle"
        argument={{
          where: { id: $id },
          data: {
            value: $value,
            type_title: $typeTitle,
            language: {
              connect: {
                language_code: $languageCode
              }
            },
            exposure_order: $order
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoodsTitle: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
