import React, { useState } from "react";
import styled from "styled-components";
import { Divider } from "App/Atomics/Divider";
import { Input } from "App/Atomics/Input";
import { GRAY_4, WHITE, GRAY_1, GRAY_2 } from "constants/baseColor";
import { DEFAULT_BORDER_COLOR, DANGER_COLOR } from "constants/color";
import {
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  MARGING_SMALL_PX,
  MARGING_X_LARGE_PX,
  PADDING_XX_LARGE_PX,
  PADDING_LARGE_PX,
  UNIT_PX,
  BORDER_RADIUS_PX,
  MARGING_LARGE_PX,
  PADDING_SMALL_PX,
  widthViewportize
} from "constants/size";
import { CheckDuplicatedName, CreateGenre } from "GraphQL/Queries/Genre";
import { GenreTextAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { MoodTextAutoComplete } from "App/Molecules/AutoCompletes/Mood";
import { Info } from "App/Atomics/Input/Select/TextSelect";

const Layout = styled.form`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 80%;
  height: 85%;
  padding: ${PADDING_XX_LARGE_PX} ${widthViewportize(0.4 * UNIT)};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};

  h2 {
    text-align: left;
    padding: ${PADDING_X_LARGE_PX} 0px;
  }

  .nextButton {
    margin-top: ${MARGING_X_LARGE_PX};
    margin-left: auto;
  }
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & svg {
      position: relative;
      top: 4px;
      width: ${pixelize(1.25 * UNIT)};
      height: ${pixelize(1.25 * UNIT)};
    }

    .tooltip {
      color: ${DANGER_COLOR};
      margin-left: ${MARGING_LARGE_PX};
      font-size: 0.8rem;
    }
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 5)};
  grid-template-columns: 85% 5%;
  grid-gap: ${UNIT_PX};
  align-items: center;

  input {
    width: 100%;
    background-color: ${WHITE};
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }

  button {
    width: calc(100% - ${UNIT_PX});
  }
`;

const RadioGroup = styled.div`
  text-align: left;

  input[type="radio"] {
    width: min-content;
    margin-bottom: ${MARGING_SMALL_PX};
  }

  label {
    margin-left: ${MARGING_SMALL_PX};
    font-weight: normal;

    & + input[type="radio"] {
      margin-left: ${MARGING_X_LARGE_PX};
    }
  }
`;

const CheckInputContainer = styled.div`
  position: relative;
  .checkbtn {
    position: absolute;
    width: ${pixelize(UNIT * 5)};
    right: 1%;
    bottom: 10%;
    z-index: 1;
    background: ${WHITE};
    border-radius: 8px;
    padding: ${PADDING_SMALL_PX};
    border: 1px solid ${GRAY_4};
    transition: all 0.2s;
    &:hover {
      background: ${GRAY_1};
    }
    &:active {
      font-weight: bold;
      background: ${GRAY_2};
      transform: translateY(1.5px);
    }
  }
`;

export const GenreForm = () => {
  const [type, setType] = useState<string>("genre");
  const [name, setName] = useState<string | undefined>(undefined);
  const [detail, setDetail] = useState<string | undefined>(undefined);
  const [parentInfo, setParentInfo] = useState<Info | undefined>(undefined);
  const [isFail, setBeFail] = useState<boolean>(true);

  const onToggleChange = (type: string) => {
    setType(type);
    setBeFail(true);
    setParentInfo(undefined);
  };

  const checkDuplicatedName = async (type: string, name?: string) => {
    if (!name) {
      window.alert("이름을 입력해주세요.");
      return;
    }
    try {
      const { data, errors } = await CheckDuplicatedName({ type, name });
      if (errors) {
        window.alert("잘못된 형식입니다.");
        setBeFail(true);
        return;
      }
      if (data) {
        if (!data.genre.length) {
          window.alert("중복된 이름이 없습니다.");
          setBeFail(false);
          return;
        } else if (data.genre.length === 1) {
          window.alert("중복된 이름이 1개 존재합니다.");
          setBeFail(false);
          return;
        } else {
          window.alert("중복된 이름이 존재합니다.");
          setBeFail(true);
          return;
        }
      }
    } catch (err) {
      console.log(err);
      window.alert("서버에서 에러가 발생했습니다.");
      return;
    }
  };

  const onCreateGenre = async (type: string, name: string) => {
    try {
      const weight = parentInfo ? 0 : 1;
      const { data, errors } = await CreateGenre({ type, name, weight, detail, parentId: parentInfo?.id });
      if (errors) {
        window.alert(`생성에 실패하였습니다.`);
        console.error(errors[0].message);
        return;
      }
      if (data) {
        window.alert(`생성되었습니다.`);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      window.alert("서버에서 에러가 발생했습니다.");
      return;
    }
  };

  return (
    <Layout>
      <h2>장르 / 무드 등록</h2>
      <Divider />

      <LabelGroup>
        <label>타입*</label>
        <RowGroup>
          <RadioGroup>
            <input
              key="Genre"
              type="radio"
              id="genre"
              name="genre_radio"
              checked={type === "genre"}
              onChange={() => onToggleChange("genre")}
            />
            <label htmlFor="genre">장르</label>
            <input key="Mood" type="radio" id="mood" name="mood_radio" checked={type === "mood"} onChange={() => onToggleChange("mood")} />
            <label htmlFor="mood">무드</label>
          </RadioGroup>
        </RowGroup>
      </LabelGroup>

      <LabelGroup>
        <label>{`${type === "genre" ? "장르명*" : "무드명*"}`}</label>
        <RowGroup>
          <CheckInputContainer>
            <Input.Text placeholder="이름을 입력하세요." onBlur={str => setName(str)} isRequired />
            <button type="button" className="checkbtn" onClick={() => checkDuplicatedName(type, name)}>
              중복 검사
            </button>
          </CheckInputContainer>
        </RowGroup>
      </LabelGroup>
      <LabelGroup>
        <label>{"설명"}</label>
        <RowGroup>
          <CheckInputContainer>
            <Input.Text placeholder="설명을 입력하세요." onBlur={str => setDetail(str)} />
          </CheckInputContainer>
        </RowGroup>
      </LabelGroup>
      {type === "genre" ? (
        <LabelGroup>
          <label>
            상위 장르 연결<span className="tooltip">입력 시 자동으로 하위 장르로 생성됩니다.</span>
          </label>
          <RowGroup>
            <GenreTextAutoComplete
              key="genre"
              isParent={true}
              defaultValue={parentInfo}
              onChange={info => {
                if (info) {
                  setParentInfo(info);
                }
              }}
            />
          </RowGroup>
        </LabelGroup>
      ) : (
        <LabelGroup>
          <label>
            상위 무드 연결<span className="tooltip">입력 시 자동으로 하위 장르로 생성됩니다.</span>
          </label>
          <RowGroup>
            <MoodTextAutoComplete
              key="mood"
              isParent={true}
              defaultValue={parentInfo}
              onChange={info => {
                if (info) {
                  setParentInfo(info);
                }
              }}
            />
          </RowGroup>
        </LabelGroup>
      )}
      <Input.Button
        disabled={isFail}
        className="nextButton"
        color="default"
        onClick={e => {
          e.preventDefault();
          if (!name) {
            return;
          }
          onCreateGenre(type, name);
        }}
      >
        생성
      </Input.Button>
    </Layout>
  );
};
