import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import uuidv4 from "uuid/v4";
import { Playlist, Url } from "GraphQL/Queries/Playlist/GetPlaylist";
import { usePlaylistsStore } from "App/Routes/Playlists/Store";
import { GRAY_4 } from "constants/baseColor";
import { WORK_GENRE_LIST, WORK_MOOD_LIST } from "constants/storageKey";
import { Tag } from "App/Atomics/Tag";
import { Genre, PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetGenreList, GetMoodList } from "GraphQL/Queries/Genre";
import { GetMoodParentList } from "GraphQL/Queries/Genre/GetMoodParentList";

type SectionProps = {
  index: number;
  data: Playlist;
  pageIndex: Page;
};

export const ColumnSection = ({ index, data, pageIndex }: SectionProps) => {
  const [{ genreList, moodList }, dispatch] = usePlaylistsStore(store => store.Playlist);
  const { genreRelation, moodRelation } = data;
  // 모든 장르/무드를 담을 리스트
  const [filteredGenreList, setFilteredGenreList] = useState<Genre[]>(() => {
    const localGenre = window.localStorage.getItem(WORK_GENRE_LIST);
    if (!localGenre) {
      return [];
    }
    const parsedLocalGenre = JSON.parse(localGenre);
    return parsedLocalGenre;
  });
  const [filteredMoodList, setFilteredMoodList] = useState<Genre[]>(() => {
    const localMood = window.localStorage.getItem(WORK_MOOD_LIST);
    if (!localMood) {
      return [];
    }
    const parsedLocalMood = JSON.parse(localMood);
    return parsedLocalMood;
  });

  useAsyncEffect(
    async isMounted => {
      dispatch(PlaylistsActions.setEditLoading(true));
      // 로컬스토리지에 데이터가 없으면 쿼리 실행
      if (isMounted()) {
        if (!filteredGenreList.length) {
          const { data: genreData } = await GetGenreList();
          if (genreData) {
            window.localStorage.setItem(WORK_GENRE_LIST, JSON.stringify(genreData.genre));
            setFilteredGenreList(genreData.genre);
          }
          if (!filteredMoodList.length) {
            const { data: childData } = await GetMoodList();
            const { data: parentData } = await GetMoodParentList();
            if (childData && parentData) {
              const noChildParentData = parentData.genre.map(item => ({
                id: item.id,
                name: item.name,
                typeKind: item.typeKind,
                genre_self_relation_child: item.genre_self_relation_child as any[]
              }));
              const moodArr = childData.genre.concat(noChildParentData);
              window.localStorage.setItem(WORK_MOOD_LIST, JSON.stringify(moodArr));
              setFilteredMoodList(moodArr);
            }
          }
        }
      }
      dispatch(PlaylistsActions.setEditLoading(false));
      const initialGenre: Genre[] = !genreRelation.length
        ? []
        : filteredGenreList.filter(({ id }) => {
            for (const { genre } of genreRelation) {
              if (genre[0].id === id) {
                return true;
              }
            }
            return false;
          });
      const initialMood: Genre[] = !moodRelation.length
        ? []
        : filteredMoodList.filter(({ id }) => {
            for (const { mood } of moodRelation) {
              if (mood[0]?.id === id) {
                return true;
              }
            }
            return false;
          });
      dispatch([PlaylistsActions.setPlaylistGenre(initialGenre), PlaylistsActions.setPlaylistMood(initialMood)]);
    },
    [filteredGenreList, filteredMoodList, genreRelation, moodRelation]
  );

  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          if (value === null) return null;
          // 메인 정보
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "id":
              case "kind":
              case "name":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "id" ? "ID" : key === "kind" ? "종류" : "제목"}</span>
                    <InputText
                      isDisabled={key === "id"}
                      defaultValue={value as string}
                      onBlur={val => {
                        if (key === "kind") {
                          dispatch(PlaylistsActions.editPlaylistKindByIndex({ index, kind: val }));
                        } else {
                          dispatch(PlaylistsActions.editPlaylistNameByIndex({ index, name: val }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "service":
                return (
                  <LabelGroup key={key}>
                    <span>{"분류"}</span>
                    <InputText isDisabled defaultValue={value as string} />
                  </LabelGroup>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(PlaylistsActions.updatePlaylistValidCheck({ index, validCheck: vc }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              default:
                return null;
            }
            // 부가 정보
          } else if (pageIndex === Page.SECOND) {
            switch (key) {
              case "genreRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>장르</span>
                      <RowGroup>
                        <InputTagSelect
                          className="autocomplete"
                          autoFocus
                          optionList={filteredGenreList
                            .filter(({ genre_self_relation_child }) => genre_self_relation_child && genre_self_relation_child.length)
                            .map(({ id, name, typeKind, genre_self_relation_child }) => {
                              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                                const parentName = genre_self_relation_child[0].parent[0].name;
                                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                                return { id, name: mixedName, typeKind };
                              }
                              return { id, name, typeKind };
                            })}
                          value={genreList}
                          onChange={info => {
                            if (info) {
                              dispatch(
                                PlaylistsActions.changePlaylistGenre({
                                  index,
                                  genre: info.map(({ id, name }) => ({ id, name, typeKind: "genre" }))
                                })
                              );
                            }
                          }}
                        />
                        <TagContainer type="genre">
                          {filteredGenreList
                            .filter(({ id, genre_self_relation_child }) => {
                              if (!genre_self_relation_child || !genre_self_relation_child.length) {
                                return false;
                              }
                              return !genreList.find(item => item.id === id);
                            })
                            .map((genre, i) => {
                              const { name, genre_self_relation_child } = genre;
                              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                                const parentName = genre_self_relation_child[0].parent[0].name;
                                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                                const mixedGenre = {
                                  ...genre,
                                  name: mixedName
                                };
                                return (
                                  <Tag
                                    key={genre.id}
                                    style={{ width: "95%", textAlign: "center" }}
                                    color="primary"
                                    filled={false}
                                    onClick={() =>
                                      dispatch(PlaylistsActions.changePlaylistGenre({ index, genre: [...genreList, mixedGenre] }))
                                    }
                                  >
                                    {mixedName}
                                  </Tag>
                                );
                              }
                              return (
                                <Tag
                                  key={genre.id}
                                  style={{ width: "95%", textAlign: "center" }}
                                  color="primary"
                                  filled={false}
                                  onClick={() => dispatch(PlaylistsActions.changePlaylistGenre({ index, genre: [...genreList, genre] }))}
                                >
                                  {genre.name}
                                </Tag>
                              );
                            })}
                        </TagContainer>
                      </RowGroup>
                    </LabelGroup>
                    <div style={{ height: "1px", backgroundColor: "#ddd" }} />
                  </Fragment>
                );
              case "moodRelation":
                return (
                  <LabelGroup key={key}>
                    <span>무드</span>
                    <RowGroup>
                      <InputTagSelect
                        className="autocomplete"
                        autoFocus
                        optionList={filteredMoodList.map(({ id, name, genre_self_relation_child }) => {
                          let isDuplicateMood = moodList.some(list =>
                            list.genre_self_relation_child?.find(({ parent, child }) => {
                              if (parent && parent.length && genre_self_relation_child) {
                                const check1 = genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                                const check2 = parent.find(item => item.id === id);
                                return check1 || check2;
                              }
                              if (child && child.length) {
                                return child.find(item => item.id === id);
                              }
                            })
                          );
                          return { id, name, isDisabled: isDuplicateMood };
                        })}
                        value={moodList}
                        onChange={info => {
                          if (info) {
                            const selectedMood = filteredMoodList.filter(mood => info.find(item => item.id === mood.id));
                            dispatch(PlaylistsActions.changePlaylistMood({ index, mood: selectedMood }));
                          } else {
                            // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                          }
                        }}
                      />
                      <TagContainer type="mood">
                        {filteredMoodList
                          .filter(mood => !moodList.find(item => item.id === mood.id))
                          .map((mood, i) => (
                            <Tag
                              key={mood.id}
                              disabled={moodList.some(list =>
                                list.genre_self_relation_child?.find(({ parent, child }) => {
                                  if (parent && parent.length && mood.genre_self_relation_child) {
                                    const check1 = mood.genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                                    const check2 = parent.find(item => item.id === mood.id);
                                    return check1 || check2;
                                  }
                                  if (child && child.length) {
                                    return child.find(item => item.id === mood.id);
                                  }
                                })
                              )}
                              style={{ width: "90%", textAlign: "center" }}
                              filled={false}
                              color="primary"
                              onClick={() => dispatch(PlaylistsActions.changePlaylistMood({ index, mood: [...moodList, mood] }))}
                            >
                              {mood.name}
                            </Tag>
                          ))}
                      </TagContainer>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          } else {
            switch (key) {
              case "playlistUrl":
                const urls = value as Url[];
                return (
                  <LabelGroup key={key}>
                    <span>{"URL"}</span>
                    <RowGroup className={!urls.length ? "" : "border"}>
                      {!urls?.length
                        ? null
                        : urls.map((url, i) => (
                            <ExtraInfo key={i} column={3}>
                              <RoundButton
                                color="danger"
                                onClick={() => dispatch(PlaylistsActions.deletePlaylistUrl({ index, urlIndex: i }))}
                              >
                                -
                              </RoundButton>
                              <CreatableTextSelect
                                name="select"
                                className="select"
                                optionList={[
                                  { id: "source", name: "source" },
                                  { id: "score", name: "score" },
                                  { id: "imslp", name: "imslp" }
                                ]}
                                placeholder="타입"
                                defaultValue={!url.typeUrl ? undefined : { id: url.typeUrl, name: url.typeUrl }}
                                onBlur={info => {
                                  if (info) {
                                    dispatch(PlaylistsActions.updatePlaylistTypeUrl({ index, urlIndex: i, typeUrl: info.id }));
                                  }
                                }}
                              />
                              <InputText
                                placeholder="값"
                                defaultValue={!url.url ? undefined : url.url}
                                onBlur={text => {
                                  dispatch(PlaylistsActions.updatePlaylistUrl({ index, urlIndex: i, url: text }));
                                }}
                              />
                            </ExtraInfo>
                          ))}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const tempUrl = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeUrl: undefined,
                            url: undefined
                          } as Url;
                          dispatch(PlaylistsActions.createPlaylistUrl({ index, url: tempUrl }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );

              default:
                return null;
            }
          }
        })}
    </>
  );
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 700px;
  padding: 0.5rem;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `2rem 150px auto` : `2rem 34% 28% 28%`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

const InputTagSelect = styled(Input.TagSelect)`
  margin-bottom: 1rem;
  border-color: ${GRAY_4};
`;

const TagContainer = styled.div<{ type: string }>`
  width: 100%;
  height: 16rem;
  overflow: scroll;
  display: grid;
  grid-template-columns: ${props => (props.type === "genre" ? "repeat(4, 24.5%)" : "repeat(7, 14%)")};
  grid-gap: 5px;
  box-shadow: 0 0 4px ${GRAY_4};
  padding: 1rem;
`;
