import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, MARGING_LARGE_PX, PADDING_XX_LARGE_PX, MARGING_SMALL_PX, MARGING_LARGE, PADDING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { GRAY_4 } from "constants/baseColor";
import { DANGER_COLOR_LIGHT } from "constants/color";
import { Toast } from "lib/toast";
import { ValidType } from "constants/ValidType";
import { YtvLicenseValidCheckInfo } from "constants/TypeOptionInfo";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { Loading } from "App/Atomics/Loading";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { GetMetadataLicenseInfo, UpdateTrackLicense } from "GraphQL/Queries/Metadata";
import { DeleteAccessRecord } from "GraphQL/Queries";
import dayjs from "dayjs";

type Props = {
  selectedIdList: string[];
  toClose: () => void;
};

const OptionLicenseService = [
  { id: "0", name: "사용 가능 / 재생 가능" },
  { id: "1", name: "사용 가능 / 재생 불가" },
  { id: "2", name: "사용 불가 / 재생 불가" }
];

export const UpdateSelectedLicenseModal = ({ selectedIdList, toClose }: Props) => {
  const [copyright, setCopyright] = useState<boolean>(false);
  const [neighboring, setNeighboring] = useState<boolean>(false);
  const [validCheck, setValidCheck] = useState<ValidType>(ValidType.V3);
  const [isService, setBeService] = useState<number>(2);
  const [loading, setLoading] = useState<boolean>(false);

  const createLicense = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true);
    try {
      for (const trackId of selectedIdList) {
        const accessId = await requestAccessRecord({ targetId: trackId, targetTable: TargetTableInput.Metadata });
        if (accessId) {
          try {
            const licenseDate = dayjs(new Date()).format("YYYY-MM-DD");
            const licenseIdList = await GetMetadataLicenseInfo({ id: trackId, typeClass: "record", typeSubClassIn: ["track", "effect"] });
            for (const uuid of licenseIdList) {
              await UpdateTrackLicense({ uuid, copyright, neighboring, isService, licenseDate, validCheck });
            }
          } catch (err) {
            throw err;
          } finally {
            await DeleteAccessRecord({ id: accessId });
          }
        }
      }
      Toast.primary("성공적으로 수정되었습니다.", undefined, "top-center");
      setLoading(false);
      toClose();
    } catch (e) {
      console.log(e);
      setLoading(false);
      Toast.error("업데이트에 실패하였습니다.", undefined, "top-center");
    }
  };

  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>라이센스 일괄 수정</h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
      </Header>
      <Section>
        <RowGroup>
          <h4>저작권 만료 여부</h4>
          <CopyrightBox>
            <Input.Toggle
              className="toggle"
              color="primary"
              toggleValue="저작권 만료"
              isActive={copyright}
              onClick={() => setCopyright(prev => !prev)}
            />
            <Input.Toggle
              className="toggle"
              color="primary"
              toggleValue="저작인접권 만료"
              isActive={neighboring}
              onClick={() => setNeighboring(prev => !prev)}
            />
          </CopyrightBox>
        </RowGroup>
        <RowGroup>
          <h4>유튜브 라이센스 체크</h4>
          <Input.TextSelect
            className="typeSelect"
            optionList={YtvLicenseValidCheckInfo}
            defaultValue={YtvLicenseValidCheckInfo.find(({ id }) => id === validCheck)}
            onChange={info => {
              if (info) {
                setValidCheck(info.id as ValidType);
              }
            }}
          />
        </RowGroup>
        <RowGroup>
          <h4>사용 및 재생 여부</h4>
          <Input.TextSelect
            className="typeSelect"
            optionList={OptionLicenseService}
            defaultValue={OptionLicenseService.find(({ id }) => +id === isService)}
            onChange={info => {
              if (info) {
                setBeService(+info.id);
              }
            }}
          />
        </RowGroup>
        <ButtonGroup>
          <Input.Button className="button" onClick={toClose}>
            취소
          </Input.Button>
          <Input.Button className="button" color="primary" disabled={!validCheck} onClick={e => createLicense(e)}>
            적용
          </Input.Button>
        </ButtonGroup>
      </Section>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 40)};
  height: ${pixelize(UNIT * 30)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  font-size: 0.9rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: #6a5fdd;
  color: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  width: 100%;
  display: flex;
  h3 {
    font-size: 1.1rem;
    svg {
      fill: #fff;
      width: ${pixelize(UNIT * 1.1)};
      height: ${pixelize(UNIT * 1.1)};
      margin-right: ${MARGING_LARGE_PX};
    }
  }
  .title {
    width: 100%;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: #fff;
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const RowGroup = styled.div`
  margin: 1rem 0;
  h2 {
    font-size: 1.4rem;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }
  h4 {
    font-size: 1rem;
    margin: ${MARGING_LARGE_PX} 0;
    display: flex;
    align-items: center;
    span {
      font-size: 0.8em;
      color: ${DANGER_COLOR_LIGHT};
      font-weight: 400;
      margin-left: ${MARGING_LARGE_PX};
    }
  }

  .typeSelect {
    width: 100%;
    margin-bottom: ${MARGING_LARGE_PX};
    font-size: 12px;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: ${pixelize(UNIT * 6)} ${MARGING_LARGE_PX} 0;
  font-weight: bold;
  .button {
    margin-right: ${MARGING_LARGE_PX};
    width: 6rem;
  }
`;

const Section = styled.section`
  padding: ${PADDING_LARGE_PX} ${PADDING_XX_LARGE_PX};
`;

const CopyrightBox = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${MARGING_SMALL_PX};
  .toggle {
    margin-right: ${MARGING_SMALL_PX};
  }
`;
