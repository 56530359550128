import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_6, GREEN_5, GREEN_4, GREEN_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { SUCCESS_COLOR_DARK, SUCCESS_COLOR } from "constants/color";
import { mediaQuery } from "constants/media";
import { Input } from "App/Atomics/Input";
import { ColumnSection } from "./ColumnSection";
import _ from "lodash";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";
import { usePlaylistsStore } from "App/Routes/Playlists/Store";
import { Playlist, Url } from "GraphQL/Queries/Playlist/GetPlaylist";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import {
  CreatePlaylistGenre,
  CreatePlaylistUrl,
  DeletePlaylistGenre,
  DeletePlaylistUrl,
  UpdatePlaylist,
  UpdatePlaylistUrl
} from "GraphQL/Queries/Playlist";
import { ValidType } from "constants/ValidType";
import { Genre, Mood } from "GraphQL/Queries/Metadata/GetMetadata";

type Props = {
  index: number;
  toClose: () => void;
};

type Keys = keyof Playlist;
type Values = Playlist[Keys];

export enum Page {
  FIRST,
  SECOND
}

export const EditInfoModal = ({ index, toClose }: Props) => {
  const [{ playlist, origin }, dispatch] = usePlaylistsStore(store => ({
    playlist: store.Playlist.playlists[index],
    origin: store.Playlist.origin[index]
  }));
  const [pageIndex, setPageIndex] = useState<Page>(Page.FIRST);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  const onClearAndClose = () => {
    dispatch(PlaylistsActions.setClear());
    toClose();
  };

  const onSave = async (origin: Playlist, playlist: Playlist) => {
    if (_.isEqual(origin, playlist)) {
      Toast.warning("변경사항이 없습니다.", undefined, "bottom-right");
      return;
    }
    setEditLoading(true);
    const changeMap = new Map<Keys, { from: Values; to: Values }>();
    const PlaylistEntries = Object.entries(playlist);
    const OriginValue = Object.values<Values>(origin);
    PlaylistEntries.forEach(([key, value], i) => {
      switch (key) {
        case "kind":
        case "name":
        case "validCheck":
          if (OriginValue[i] !== value) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        case "playlistUrl":
        case "genreRelation":
        case "moodRelation":
          if (!_.isEqual(OriginValue[i], value)) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        default:
          break;
      }
    });
    const accessId = await requestAccessRecord({ targetId: playlist.id, targetTable: TargetTableInput.Playlist });
    try {
      for (const [key, { from, to }] of changeMap) {
        switch (key) {
          case "kind":
            await UpdatePlaylist({ id: playlist.id, kind: to as string });
            break;
          case "name":
            await UpdatePlaylist({ id: playlist.id, name: to as string });
            break;
          case "validCheck":
            await UpdatePlaylist({ id: playlist.id, validCheck: to as ValidType });
            break;
          case "playlistUrl":
            const deleteUrlList = (from as Url[])
              .filter(({ uuid }) => !(to as Url[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createUrlList = (to as Url[]).filter(
              ({ uuid, typeUrl, url }) => !(from as Url[]).map(({ uuid }) => uuid).includes(uuid) && !!typeUrl && !!url
            );
            const updateUrlList = (to as Url[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as Url[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteUrlList) {
              await DeletePlaylistUrl({ uuid });
            }
            for (const { uuid, typeUrl, url, order, validCheck } of createUrlList) {
              const { data } = await CreatePlaylistUrl({
                id: playlist.id,
                typeUrl: typeUrl!,
                url: url!,
                order: order,
                validCheck
              });
              if (data) {
                dispatch(
                  PlaylistsActions.updatePlaylistUrlUuid({ index, prevUuid: uuid, uuid: data.createPlaylistUrl.playlist_url[0].uuid })
                );
              }
            }

            for (const { uuid, typeUrl, url, order, validCheck } of updateUrlList) {
              await UpdatePlaylistUrl({ uuid, typeUrl, url: url!, order, validCheck });
            }
            break;
          case "genreRelation":
            const deleteGenreList = (from as Genre[])
              .filter(({ uuid }) => !(to as Genre[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createGenreList = (to as Genre[]).filter(({ uuid }) => !(from as Genre[]).map(({ uuid }) => uuid).includes(uuid));
            for (const uuid of deleteGenreList) {
              await DeletePlaylistGenre({ uuid });
            }
            for (const { uuid, genre, validCheck } of createGenreList) {
              const { data } = await CreatePlaylistGenre({
                id: playlist.id,
                genreId: genre[0].id,
                validCheck
              });
              if (data) {
                dispatch(
                  PlaylistsActions.updatePlaylistGenreUuid({
                    index,
                    prevUuid: uuid,
                    uuid: data.createPlaylistGenre.playlist_genre_relation[0].uuid
                  })
                );
              }
            }
            break;
          case "moodRelation":
            const deleteMoodList = (from as Mood[])
              .filter(({ uuid }) => !(to as Mood[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createMoodList = (to as Mood[]).filter(({ uuid }) => !(from as Mood[]).map(({ uuid }) => uuid).includes(uuid));
            for (const uuid of deleteMoodList) {
              await DeletePlaylistGenre({ uuid });
            }
            for (const { uuid, mood, validCheck } of createMoodList) {
              const { data } = await CreatePlaylistGenre({
                id: playlist.id,
                genreId: mood[0].id,
                validCheck
              });
              if (data) {
                dispatch(
                  PlaylistsActions.updatePlaylistMoodUuid({
                    index,
                    prevUuid: uuid,
                    uuid: data.createPlaylistGenre.playlist_genre_relation[0].uuid
                  })
                );
              }
            }
            break;

          default:
            break;
        }
      }
      Toast.primary("저장되었습니다.", undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("저장을 실패하였습니다.", undefined, "top-center");
      setEditLoading(false);
      await DeleteAccessRecord({ id: accessId! });
      return;
    }

    await DeleteAccessRecord({ id: accessId! });
    setEditLoading(false);
    dispatch(PlaylistsActions.setSave());
    toClose();
  };

  return (
    <Layout>
      <Header>
        <DocumentIcon className="document" />
        <h3>작품 정보 및 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <ul className="left-container">
          <li className={pageIndex === Page.FIRST ? "active" : ""} onClick={() => setPageIndex(Page.FIRST)}>
            메인 정보
          </li>
          <li className={pageIndex === Page.SECOND ? "active" : ""} onClick={() => setPageIndex(Page.SECOND)}>
            부가 정보
          </li>
        </ul>
        <div className="right">
          <ColumnSection index={index} data={playlist} pageIndex={pageIndex} />
          <ButtonGroup>
            <Input.Button className="btn cancel" disabled={editLoading} isFill={false} color="success" onClick={onClearAndClose}>
              취소
            </Input.Button>
            <Input.Button
              className="btn save"
              disabled={editLoading}
              isFill
              color="success"
              onClick={async () => await onSave(origin, playlist)}
            >
              저장
            </Input.Button>
          </ButtonGroup>
        </div>
      </Section>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  right: 0%;
  top: 0%;
  width: 1000px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.8rem;
  ${mediaQuery(1000)} {
    width: 100%;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .document {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: 0.4rem;
    fill: ${SUCCESS_COLOR_DARK};
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  display: flex;
  height: 100%;
  .left-container {
    width: 7rem;
    border-right: 1px solid #eee;
    li {
      display: flex;
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      color: #fff;
      background-color: ${GREEN_5};

      &:hover {
        background-color: ${GREEN_4};
      }
      &:focus {
        background-color: ${GREEN_6};
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 5rem;
    overflow-y: scroll;
  }

  ${mediaQuery(965)} {
    flex-direction: column;
    .left-container {
      width: 100%;
      display: flex;
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
  .btn {
    width: 6rem;
    margin-right: 0.5rem;
  }
  .cancel {
    &:hover {
      background-color: #eee;
    }
  }
  /* Input.Button 기본 css를 수정해야함. */
  .save {
    border: 1px solid ${SUCCESS_COLOR};
    &:hover {
      border: 1px solid ${SUCCESS_COLOR};
    }
  }
`;
