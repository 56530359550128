import { WHITE } from "constants/baseColor";
import { DANGER_COLOR } from "constants/color";
import { HEADER_HEIGHT_PX } from "constants/size";
import React, { ReactElement } from "react";
import { useHistory } from "lib/use-router";
import styled from "styled-components";
import { GoodsStore, useGoodsSelector } from "../../Store";
import { CategoryFilter, TypeFilter } from "./FilterList";
import { useFilterRoute } from "./useFilterRoute";
import { GOODS } from "constants/route";
import { GoodsActions } from "../../Store/Goods";

export const Filter = (): ReactElement => {
  const router = useHistory();
  const { filter } = useGoodsSelector(store => store.Goods);
  const hasFilter = Object.values(filter).filter(item => item.size).length;
  useFilterRoute({ filter });

  const onClear = () => {
    GoodsStore.dispatch(GoodsActions.clearFilter());
    router.push(`${GOODS}`);
  };

  return (
    <Layout>
      <Status>
        <Title>필터</Title>
        {!hasFilter ? null : <Clear onClick={onClear}>clear</Clear>}
      </Status>
      <CategoryFilter category={filter.category} />
      <TypeFilter type={filter.type} />
      {/* <FilterTemplate title={"타입"} subTitle={"모든 타입"} optionList={[]} /> */}
      {/* <FilterTemplate title={"순서"} subTitle={"아이디 역순"} optionList={[]} /> */}
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  width: 210px;
  margin: ${HEADER_HEIGHT_PX} 10px 0;
  padding: 20px 10px;
  overflow: hidden auto;
`;

const Status = styled.div`
  font-size: 14px;
  letter-spacing: -0.21px;
  font-weight: 700;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  margin-right: 4px;
`;
const Clear = styled.button`
  font-size: 8px;
  color: ${WHITE};
  background-color: ${DANGER_COLOR};
  padding: 2px 4px;
  border-radius: 8px;
`;
