import { Permission } from "models/Permission";
import { AppStore } from "App/Store";

export const allowGoodsRead = (store: AppStore) => {
  const { allowTables } = store.UserToken;
  for (const allowTable of allowTables) {
    const permission = new Permission(allowTable.allow.split(""));
    switch (allowTable.table) {
      case "goods":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "goods_title":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      default:
        break;
    }
  }
  return true;
};
