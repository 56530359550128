/** @jsx jsx */

import {
  jsx,
  render,
  Query,
  SelectionSet,
  Field,
  Variable,
  IdScalar,
  StringScalar,
  IntScalar,
  EnumScalar,
  ListScalar,
  BooleanScalar
} from "graphql-jsx";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";
import { MetadataRightType } from "constants/MetadataRightType";
import { MetadataRightScalar, MetadataSelfRelationOrderScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
import { MetadataSelfRelationOrderByInput } from "constants/MetadataSelfRelationOrderByInput";
import { GenderType } from "constants/GenderType";

type Option = Readonly<{
  page?: number;
  first?: number;
  metadataId?: string;
  metadataIdIn?: number[];
  title?: string;
  artistName?: string;
  no?: string;
  typeClassIn: string[];
  typeSubClass: string;
  detail?: boolean;
  validCheck?: string[];
  gte?: number;
  lte?: number;
  artistIdIn?: string[];
  rightsIdIn?: string[];
  url?: boolean;
  order?: string;
  typeArtistIn?: string[];
  licenseTypeIn?: string[];
}>;

export type Artist = {
  uuid: string;
  validCheck: ValidType;
  order: number;
  artist: {
    id?: string;
    name?: string;
  }[];
  role: {
    id?: string;
    name?: string;
  }[];
  character: {
    id?: string;
    name?: string;
  }[];
};

export type Company = {
  uuid: string;
  validCheck: ValidType;
  typeKind: string;
  order: number;
  company: {
    id: string;
    name: string;
    type?: string;
    path?: string;
    license?: string;
  }[];
};

export type Genre = {
  uuid: string;
  validCheck: ValidType;
  genre: {
    id: string;
    name: string;
  }[];
};

export type Mood = {
  uuid: string;
  validCheck: ValidType;
  mood: {
    id: string;
    name: string;
  }[];
};

export type Structure = {
  rootId: number;
  structureId: string;
  child: {
    innerOrder: number;
    metadata: Metadata[];
  }[];
};

export type Title = {
  uuid: string;
  validCheck: ValidType;
  typeTitle?: string;
  title?: string;
  order?: number;
  language?: {
    name: string;
    languageCode: string;
    validCheck: string;
  }[];
};

export type Url = {
  uuid: string;
  validCheck: ValidType;
  typeUrl?: string;
  url?: string;
  order?: number;
};

export type SubData = {
  uuid: string;
  field?: string;
  category?: string;
  value?: string;
  validCheck: ValidType;
};

export type ElementRelation = {
  uuid: string;
  order: number;
  listRelation: {
    id?: string;
    title?: string;
    artistRelation?: {
      artist: {
        name: string;
      }[];
    }[];
  }[];
};

export type ListRelation = {
  uuid: string;
  order: number;
  elementRelation: {
    id?: string;
    title?: string;
    artistRelation?: {
      artist: {
        name: string;
      }[];
    }[];
  }[];
};

export type TrackInformation = {
  uuid?: string;
  channel?: number;
  duration?: number;
  bitrate?: number;
  tempo?: number;
  tonality?: string;
  timeSignature?: string;
  voiceGender?: GenderType;
  extraData?: string;
};

export type Metadata = {
  metadataId: string;
  v1: string | null;
  no: string | null;
  class: string;
  subClass: string;
  title: string;
  validCheck: ValidType;
  artistRelation: Artist[];
  genreRelation: Genre[];
  moodRelation: Mood[];
  rightsCompany: Company[];
  productions: Company[];
  publications: Company[];
  titles: Title[];
  metadataUrl: Url[];
  structures: Structure[];
  subdataUnique: SubData[];
  elementRelation: ElementRelation[];
  listRelation: ListRelation[];
  trackInformation: TrackInformation[];
};

export const GetMetadata = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable />} value={option.page} />;
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar isNullable />} value={option.metadataId} />;
  const $no = <Variable name="no" scalar={<StringScalar isNullable />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable />} value={option.title} />;
  const $artistName = <Variable name="artistName" scalar={<StringScalar isNullable />} value={option.artistName} />;
  const $typeClassIn = !option.typeClassIn?.length ? (
    undefined
  ) : (
    <ListScalar scalar={<StringScalar />} isNullable value={option.typeClassIn} />
  );
  const $typeSubClass = <Variable name="subclass" scalar={<StringScalar />} value={option.typeSubClass} />;
  const $rightCompany = <MetadataRightScalar isNullable value={MetadataRightType.rightCompany} />;
  const $production = <MetadataRightScalar isNullable value={MetadataRightType.production} />;
  const $publication = <MetadataRightScalar isNullable value={MetadataRightType.publication} />;
  const $gte = <Variable name="metadata_id__gte" scalar={<IdScalar isNullable />} value={option.gte} />;
  const $lte = <Variable name="metadata_id__lte" scalar={<IdScalar isNullable />} value={option.lte} />;
  const $artistIdIn =
    !option.artistIdIn || !option.artistIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.artistIdIn} />;
  const $rightsIdIn =
    !option.rightsIdIn || !option.rightsIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.rightsIdIn} />;
  const $validCheck = !option.validCheck ? undefined : <ListScalar scalar={<ValidTypeScalar />} isNullable value={option.validCheck} />;
  const $urlExist = <Variable name="url_exist" scalar={<BooleanScalar />} value={option.url} />;
  const $order = (
    <EnumScalar type="MetadataOrderByInput" value={option.order ?? "metadata_id__DESC"} items={[option.order ?? "metadata_id__DESC"]} />
  );
  const $typeArtistIn =
    !option.typeArtistIn || !option.typeArtistIn.length ? (
      undefined
    ) : (
      <ListScalar scalar={<StringScalar />} isNullable value={option.typeArtistIn} />
    );
  const $licenseTypeIn =
    !option.licenseTypeIn || !option.licenseTypeIn.length ? (
      undefined
    ) : (
      <ListScalar scalar={<StringScalar />} isNullable value={option.licenseTypeIn} />
    );
  const { query, variables } = render(
    <Query operationName="GET_METADATA">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: $first,
          page: $page,
          orderBy: $order,
          where: {
            metadata_structure__some: {
              list__some: {
                metadata__some: {
                  metadata_id: $metadataId,
                  metadata_id__in: !option.metadataIdIn?.length ? undefined : option.metadataIdIn,
                  metadata_title__some: {
                    tsvec_words__search: $title,
                    type_metadata_title__not_starts_with: !option.title ? undefined : "search",
                    type_metadata_title__starts_with: !option.title ? undefined : "name"
                  },
                  track_license__some: {
                    type_track__in: $licenseTypeIn
                  }
                }
              }
            },
            no: $no,
            metadata_id__gte: $gte,
            metadata_id__lte: $lte,
            type_metadata_class__in: $typeClassIn,
            type_metadata_subclass: $typeSubClass,
            metadata_artist_relation__some: {
              artist__some: {
                artist_id__in: $artistIdIn,
                type_artist_class__in: $typeArtistIn,
                artist_title__some: {
                  type_artist_title__not_starts_with: !option.artistName ? undefined : "search",
                  tsvec_words__search: $artistName
                }
              }
            },
            metadata_company_relation__some: {
              type_kind: $rightCompany,
              company__some: {
                company_id__in: $rightsIdIn
              }
            },
            valid_check__in: $validCheck,
            metadata_url__some: {
              id__exist: $urlExist
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <Field name="compatibility_v1_id" alias="v1" />
        <Field name="no" />
        <Field name="title" />
        <Field name="type_metadata_class" alias="class" />
        <Field name="type_metadata_subclass" alias="subClass" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            first: 99,
            orderBy: (
              <EnumScalar type="Metadata_artist_relationOrderByInput" value={"exposure_order__ASC"} items={["exposure_order__ASC"]} />
            )
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
          <SelectionSet name="role">
            <Field name="role_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
          <SelectionSet name="character">
            <Field name="role_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_genre_relation"
          alias="genreRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "genre"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_genre_relation"
          alias="moodRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "mood"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre" alias="mood">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="rightsCompany"
          argument={{
            where: {
              type_kind: $rightCompany
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="type" />
            <Field name="group_path" alias="path" />
            <Field name="license" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="productions"
          argument={{
            where: {
              type_kind: $production
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="type" />
            <Field name="group_path" alias="path" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="publications"
          argument={{
            where: {
              type_kind: $publication
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="type" />
            <Field name="group_path" alias="path" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_title"
          alias="titles"
          argument={{
            where: {
              type_metadata_title__not_starts_with: "search"
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_metadata_title" alias="typeTitle" />
          <Field name="value" alias="title" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="language">
            <Field name="name" />
            <Field name="language_code" alias="languageCode" />
            <Field name="valid_check" alias="validCheck" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="metadata_url" alias="metadataUrl">
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet name="metadata_subdata_unique" alias="subdataUnique">
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_subdata_field" alias="field" />
          <Field name="type_subdata_category" alias="category" />
          <Field name="value" />
        </SelectionSet>
        <SelectionSet name="metadata_self_relations_element_metadata" alias="elementRelation">
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata_self_relations_list_metadata" alias="listRelation">
            <Field name="metadata_id" alias="id" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_self_relations_list_metadata"
          alias="listRelation"
          argument={{
            orderBy: <MetadataSelfRelationOrderScalar value={MetadataSelfRelationOrderByInput.exposure_order_ASC} />
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata_self_relations_element_metadata" alias="elementRelation">
            <Field name="metadata_id" alias="id" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="track_information" alias="trackInformation" argument={{ first: 1 }}>
          <Field name="id" alias="uuid" />
          <Field name="channel" />
          <Field name="duration" />
          <Field name="sample_bitrate" alias="bitrate" />
          <Field name="tempo" />
          <Field name="tonality" />
          <Field name="time_signature" alias="timeSignature" />
          <Field name="voice_gender" alias="voiceGender" />
          <Field name="extra_data" alias="extraData" />
        </SelectionSet>
        {/* when route to detail page, get child data */}
        {!option.detail ? (
          <SelectionSet name="metadata_structure" alias="structures">
            <Field name="structure_root_id" alias="rootId" />
            <Field name="structure_id" alias="structureId" />
          </SelectionSet>
        ) : (
          <SelectionSet name="metadata_structure" alias="structures">
            <Field name="structure_root_id" alias="rootId" />
            <Field name="structure_id" alias="structureId" />
            <SelectionSet name="child" argument={{ first: 999 }}>
              <Field name="inner_order" alias="innerOrder" />
              <SelectionSet name="metadata">
                <Field name="metadata_id" alias="metadataId" />
                <Field name="compatibility_v1_id" alias="v1" />
                <Field name="no" />
                <Field name="title" />
                <Field name="type_metadata_class" alias="class" />
                <Field name="type_metadata_subclass" alias="subClass" />
                <Field name="valid_check" alias="validCheck" />
                <SelectionSet
                  name="metadata_artist_relation"
                  alias="artistRelation"
                  argument={{
                    first: 99,
                    orderBy: (
                      <EnumScalar
                        type="Metadata_artist_relationOrderByInput"
                        value={"exposure_order__ASC"}
                        items={["exposure_order__ASC"]}
                      />
                    )
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="artist">
                    <Field name="artist_id" alias="id" />
                    <Field name="name" />
                  </SelectionSet>
                  <SelectionSet name="role">
                    <Field name="role_id" alias="id" />
                    <Field name="name" />
                  </SelectionSet>
                  <SelectionSet name="character">
                    <Field name="role_id" alias="id" />
                    <Field name="name" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet
                  name="metadata_genre_relation"
                  alias="genreRelation"
                  argument={{
                    first: 99,
                    where: {
                      genre__some: {
                        type_kind: "genre"
                      }
                    }
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="genre">
                    <Field name="genre_id" alias="id" />
                    <Field name="name" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet
                  name="metadata_genre_relation"
                  alias="moodRelation"
                  argument={{
                    first: 99,
                    where: {
                      genre__some: {
                        type_kind: "mood"
                      }
                    }
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="genre" alias="mood">
                    <Field name="genre_id" alias="id" />
                    <Field name="name" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet
                  name="metadata_company_relation"
                  alias="rightsCompany"
                  argument={{
                    where: {
                      type_kind: $rightCompany
                    }
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="type_kind" alias="typeKind" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="company">
                    <Field name="company_id" alias="id" />
                    <Field name="name" />
                    <Field name="type" />
                    <Field name="group_path" alias="path" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet
                  name="metadata_company_relation"
                  alias="productions"
                  argument={{
                    where: {
                      type_kind: $production
                    }
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="type_kind" alias="typeKind" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="company">
                    <Field name="company_id" alias="id" />
                    <Field name="name" />
                    <Field name="type" />
                    <Field name="group_path" alias="path" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet
                  name="metadata_company_relation"
                  alias="publications"
                  argument={{
                    where: {
                      type_kind: $publication
                    }
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="type_kind" alias="typeKind" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="company">
                    <Field name="company_id" alias="id" />
                    <Field name="name" />
                    <Field name="type" />
                    <Field name="group_path" alias="path" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet
                  name="metadata_title"
                  alias="titles"
                  argument={{
                    where: {
                      type_metadata_title__not_starts_with: "search"
                    },
                    orderBy: <EnumScalar type="Metadata_titleOrderByInput" value="exposure_order__ASC" items={["exposure_order__ASC"]} />
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="type_metadata_title" alias="typeTitle" />
                  <Field name="value" alias="title" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="language">
                    <Field name="name" />
                    <Field name="language_code" alias="languageCode" />
                    <Field name="valid_check" alias="validCheck" />
                  </SelectionSet>
                </SelectionSet>
                <SelectionSet name="metadata_url" alias="metadataUrl">
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="type_url" alias="typeUrl" />
                  <Field name="url" />
                  <Field name="exposure_order" alias="order" />
                </SelectionSet>
                <SelectionSet name="metadata_structure" alias="structures">
                  <Field name="structure_root_id" alias="rootId" />
                  <Field name="structure_id" alias="structureId" />
                </SelectionSet>
                <SelectionSet name="metadata_subdata_unique" alias="subdataUnique">
                  <Field name="id" alias="uuid" />
                  <Field name="valid_check" alias="validCheck" />
                  <Field name="type_subdata_field" alias="field" />
                  <Field name="type_subdata_category" alias="category" />
                  <Field name="value" />
                </SelectionSet>
                <SelectionSet name="track_information" alias="trackInformation" argument={{ first: 1 }}>
                  <Field name="id" alias="uuid" />
                  <Field name="channel" />
                  <Field name="duration" />
                  <Field name="sample_bitrate" alias="bitrate" />
                  <Field name="tempo" />
                  <Field name="tonality" />
                  <Field name="time_signature" alias="timeSignature" />
                  <Field name="voice_gender" alias="voiceGender" />
                  <Field name="extra_data" alias="extraData" />
                </SelectionSet>
                <SelectionSet
                  name="metadata_self_relations_element_metadata"
                  alias="elementRelation"
                  argument={{
                    first: 100,
                    orderBy: <MetadataSelfRelationOrderScalar value={MetadataSelfRelationOrderByInput.exposure_order_ASC} />,
                    where: {
                      metadata_self_relations_list_metadata__some: {
                        type_metadata_class: "record",
                        type_metadata_subclass: "track"
                      }
                    }
                  }}
                >
                  <Field name="id" alias="uuid" />
                  <Field name="exposure_order" alias="order" />
                  <SelectionSet name="metadata_self_relations_list_metadata" alias="listRelation">
                    <Field name="metadata_id" alias="id" />
                    <Field name="title" />
                    <SelectionSet name="metadata_artist_relation" alias="artistRelation">
                      <SelectionSet name="artist">
                        <Field name="name" />
                      </SelectionSet>
                    </SelectionSet>
                  </SelectionSet>
                </SelectionSet>
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        )}
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "metadata",
          first: $first,
          page: $page,
          where: {
            metadata: {
              metadata_structure__some: {
                list__some: {
                  metadata__some: {
                    metadata_id: $metadataId,
                    metadata_id__in: !option.metadataIdIn?.length ? undefined : option.metadataIdIn,
                    metadata_title__some: {
                      tsvec_words__search: $title,
                      type_metadata_title__not_starts_with: !option.title ? undefined : "search",
                      type_metadata_title__starts_with: !option.title ? undefined : "name"
                    },
                    track_license__some: {
                      type_track__in: $licenseTypeIn
                    }
                  }
                }
              },
              no: $no,
              metadata_id__gte: $gte,
              metadata_id__lte: $lte,
              type_metadata_class__in: $typeClassIn,
              type_metadata_subclass: $typeSubClass,
              metadata_artist_relation__some: {
                artist__some: {
                  artist_id__in: $artistIdIn,
                  type_artist_class__in: $typeArtistIn,
                  artist_title__some: {
                    type_artist_title__not_starts_with: !option.artistName ? undefined : "search",
                    tsvec_words__search: $artistName
                  }
                }
              },

              metadata_company_relation__some: {
                type_kind: $rightCompany,
                company__some: {
                  company_id__in: $rightsIdIn
                }
              },
              valid_check__in: $validCheck,
              metadata_url__some: {
                id__exist: $urlExist
              }
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: Metadata[];
    edge: Edge;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
