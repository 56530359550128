import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Right, RightsActions } from "../../Store/Right";
import { Input } from "App/Atomics/Input";
import { CheckMetadataCompanyExist, DeleteRights, CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { Toast } from "lib/toast";
import { TargetTableInput } from "constants/TargetTableInput";
import { useRightDispatch } from "../../Store";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { Loading } from "App/Atomics/Loading";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { EditModal } from "../../Modals/EditModal";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { allowCompanyCreate } from "App/Routes/AdminRoutes/allowTables";

export const DataTemplate = ({ index, data }: ColumnProps<Right>) => {
  const allowEditCompany = useAppSelector(store => allowCompanyCreate(store) || store.UserToken.role === UserRole.Master);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useRightDispatch();
  const { id, name, validCheck, type, path, license, displayName } = data;

  const editModal = useToggle();

  const onDelete = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      if (window.confirm("권리사를 삭제하시겠습니까?")) {
        setLoading(true);
        try {
          const { data: checkData, errors: checkErrors } = await CheckMetadataCompanyExist({ id });
          if (checkErrors && checkErrors.length) {
            console.log(checkErrors[0].messgae);
            window.alert("권리사에 연결된 데이터 확인에 실패하였습니다.");
            setLoading(false);
            return;
          }
          if (checkData) {
            if (checkData.company.length) {
              window.alert("권리사에 연결된 데이터가 존재합니다. 해당 데이터를 변경 후 재시도 해주세요.");
              setLoading(false);
              return;
            } else {
              try {
                const { data: accessData, errors: accessError } = await CreateAccessRecord({
                  targetId: id,
                  targetTable: TargetTableInput.Company
                });
                if (accessError && accessError.length) {
                  console.log(accessError[0].message);
                  window.alert("권한 요청에 실패하였습니다. 관리자에게 문의해주세요.");
                  setLoading(false);
                  return;
                }
                if (accessData) {
                  const accessId = accessData.createAccess.id;
                  const { errors: deleteErrors } = await DeleteRights({ id });
                  if (deleteErrors && deleteErrors.length) {
                    console.log(deleteErrors[0].message);
                    window.alert("권리사 삭제에 실패하였습니다. 관리자에게 문의해주세요.");
                    setLoading(false);
                    return;
                  }
                  await DeleteAccessRecord({ id: accessId });
                  dispatch(RightsActions.deleteRight(index));
                  Toast.primary("권리사가 삭제되었습니다", undefined, "bottom-right");
                  setLoading(false);
                  return;
                }
              } catch (err) {
                console.log(err);
                setLoading(false);
                return;
              }
            }
          }
        } catch (err) {
          console.log(err);
          setLoading(false);
          return;
        }
      }
    },
    [dispatch, id, index]
  );

  return (
    <>
      <Layout>
        <CustomTableData>{id && <ClipBoard text={id} />}</CustomTableData>
        <CustomTableData width={"22%"}>{name && <ClipBoard text={name} />}</CustomTableData>
        <CustomTableData width={"18%"}>{displayName && <ClipBoard text={displayName} />}</CustomTableData>
        <CustomTableData>{type && type}</CustomTableData>
        <CustomTableData>{path && <ClipBoard text={window.decodeURIComponent(path)} />}</CustomTableData>
        <CustomTableData>{license && license}</CustomTableData>
        <CustomTableData>{validCheck && validCheck}</CustomTableData>
        <CustomTableData>
          <ButtonGroup>
            <Input.Button color="primary" disabled={!allowEditCompany} isWide onClick={editModal.on}>
              편집
            </Input.Button>
            <Input.Button color="danger" disabled={!allowEditCompany} isWide onClick={onDelete}>
              삭제
            </Input.Button>
          </ButtonGroup>
        </CustomTableData>
        <Loading loading={loading} />
        <Modal isOpen={editModal.isToggled} onClose={editModal.off}>
          <EditModal index={index} toClose={editModal.off} />
        </Modal>
      </Layout>
    </>
  );
};

const Layout = styled(Table.Row)`
  font-size: 0.9rem;
`;

const CustomTableData = styled(Table.Data)<{ width?: string }>`
  text-align: center;
  ${props => props.width && `width: ${props.width}`};
`;

const ButtonGroup = styled.div`
  button {
    margin-bottom: 0.2rem;
  }
`;
