import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { LiveReplayActions as LiveReplay } from "./LiveReplay";

const reducers = { PodCast: LiveReplay };

const reducer = combineReducers(reducers, createStoreLogger("LiveReplay"));

export const {
  StoreProvider: LiveReplayStoreProvider,
  useStore: useLiveReplayStore,
  useSelector: useLiveReplaySelector,
  useDispatch: useLiveReplayDispatch
} = createStore(reducer);
