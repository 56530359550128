import React, { useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { useAsyncEffect } from "lib/use-async-effect";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { useQueryParams } from "lib/use-query-params";
import { LOADING } from "App/Templates/LoadingTemplate";
import { Filters } from "App/Templates/FilterModalTemplate";
import { ArtistStoreProvider, useArtistDispatch } from "./Store";
import { ArtistActions } from "./Store/Artist";
import { GetArtistRenewal } from "GraphQL/Queries";
import { RightArtistTable } from "./RightArtistTable";

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;

const ChildLayout = () => {
  const dispatch = useArtistDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const searchId = queryParams.get("id", { default: undefined });
  const searchName = queryParams.get("title", { default: undefined });

  const filter = useMemo(() => {
    const { count, url, role, genre, rights, validCheck } = JSON.parse(
      queryParams.get("filter", { default: undefined }) || "{}"
    ) as Filters;
    return { count, url, role, genre, rights, validCheck };
  }, [queryParams]);
  const { count, url, role, genre, rights, validCheck } = filter;
  const validCheckIn = !validCheck
    ? undefined
    : Object.entries(validCheck)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          dispatch(ArtistActions.setLoading(LOADING.UNLOAD));
          const { errors: artistErr, data: artistData } = await GetArtistRenewal({
            first: count ? parseInt(count, 10) : 20,
            page,
            artistId: searchId,
            name: searchName,
            urlExist: url,
            roleIdIn: role?.map(({ id }) => id),
            genreIdIn: genre?.map(({ id }) => id),
            rightsIdIn: rights?.map(({ id }) => id),
            validCheckIn
          });
          if (artistErr) {
            console.log(artistErr);
            throw artistErr;
          }
          if (artistData) {
            dispatch([ArtistActions.setArtists(artistData.artist), ArtistActions.setEdge(artistData.edge)]);
            if (!artistData.artist.length) {
              dispatch(ArtistActions.setLoading(LOADING.NULL));
            } else {
              dispatch(ArtistActions.setLoading(LOADING.ONLOAD));
            }
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
    },
    [page, searchId, searchName, filter]
  );
  return (
    <Layout>
      <LeftSideBar />
      <RightArtistTable count={count ? parseInt(count) : 20} />
    </Layout>
  );
};

export const ArtistRenewal = () => (
  <ArtistStoreProvider>
    <Helmet title="아티스트" />
    <ChildLayout />
  </ArtistStoreProvider>
);
