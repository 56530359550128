/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, EnumScalar, ListScalar } from "graphql-jsx";

import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

export const findTrackList = async (keyword: string) => {
  let $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  let $backword = undefined;
  if (keyword.includes("+")) {
    $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword.split("+")[0]} />;
    $backword = <Variable name="backword" scalar={<StringScalar />} value={keyword.split("+")[1]} />;
  }
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="TRACK_SEARCHED_LIST">
      <SelectionSet
        name="metadata_title"
        alias="list"
        argument={{
          first: 40,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Metadata_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            metadata__some: {
              type_metadata_class__in: ["record", "theater"],
              type_metadata_subclass: "track",
              valid_check__not: $remove,
              metadata_artist_relation__some: {
                artist__some: {
                  artist_title__some: {
                    tsvec_words__search: $backword
                  }
                }
              }
            },
            type_metadata_title__starts_with: "name",
            tsvec_words__search: $keyword
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
          <Field name="title" alias="name" />
          <SelectionSet name="metadata_artist_relation" alias="artistRelation">
            <SelectionSet name="artist">
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
          <SelectionSet name="metadata_structure" alias="structures">
            <Field name="structure_id" alias="id" />
          </SelectionSet>
          <SelectionSet name="metadata_url" alias="metadataUrl">
            <Field name="id" alias="uuid" />
            <Field name="type_url" alias="typeUrl" />
            <Field name="url" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: {
      metadata_id: string;
      metadata: {
        id: string;
        name: string;
        artistRelation: ReadonlyArray<{
          artist: ReadonlyArray<{
            name: string;
          }>;
        }>;
        structures: ReadonlyArray<{
          id: string;
        }>;
        metadataUrl: {
          uuid: string;
          typeUrl: string;
          url: string;
        }[];
      }[];
    }[];
  }>;
  const response = await clients.metadata.request<Data>(query, variables);
  if (response.data) {
    let dataList = response.data.list[0]?.metadata.map(item => {
      const title = item.name;
      item.name = `[${item.id}] ${item.name}  (🎤 ${
        !item.artistRelation.length ? "missing artist" : item.artistRelation[0].artist[0].name
      })`;
      return { ...item, title, extra: item.structures[0].id };
    });
    return dataList;
  } else {
    return [];
  }
};
