export const CountryCodeInfo = [
  {
    id: "ZZ",
    name: "All Region",
    eu: 0,
    asia: 0
  },
  {
    id: "XX",
    name: "Unknown",
    eu: 0,
    asia: 0
  },
  {
    id: "AD",
    name: "ANDORRA",
    eu: 0,
    asia: 0
  },
  {
    id: "JO",
    name: "JORDAN",
    eu: 0,
    asia: 0
  },
  {
    id: "JP",
    name: "JAPAN",
    eu: 0,
    asia: 0
  },
  {
    id: "KE",
    name: "KENYA",
    eu: 0,
    asia: 0
  },
  {
    id: "KG",
    name: "KYRGYZSTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "KI",
    name: "KIRIBATI",
    eu: 0,
    asia: 0
  },
  {
    id: "KM",
    name: "COMOROS",
    eu: 0,
    asia: 0
  },
  {
    id: "BN",
    name: "BRUNEI DARUSSALAM",
    eu: 0,
    asia: 1
  },
  {
    id: "KH",
    name: "CAMBODIA",
    eu: 0,
    asia: 1
  },
  {
    id: "LA",
    name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    eu: 0,
    asia: 1
  },
  {
    id: "MM",
    name: "MYANMAR",
    eu: 0,
    asia: 1
  },
  {
    id: "MY",
    name: "MALAYSIA",
    eu: 0,
    asia: 1
  },
  {
    id: "PH",
    name: "PHILIPPINES",
    eu: 0,
    asia: 1
  },
  {
    id: "TH",
    name: "THAILAND",
    eu: 0,
    asia: 1
  },
  {
    id: "VN",
    name: "VIET NAM",
    eu: 0,
    asia: 1
  },
  {
    id: "AE",
    name: "UNITED ARAB EMIRATES",
    eu: 0,
    asia: 0
  },
  {
    id: "AF",
    name: "AFGHANISTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "AG",
    name: "ANTIGUA AND BARBUDA",
    eu: 0,
    asia: 0
  },
  {
    id: "AI",
    name: "ANGUILLA",
    eu: 0,
    asia: 0
  },
  {
    id: "AL",
    name: "ALBANIA",
    eu: 0,
    asia: 0
  },
  {
    id: "AM",
    name: "ARMENIA",
    eu: 0,
    asia: 0
  },
  {
    id: "AN",
    name: "NETHERLANDS ANTILLES",
    eu: 0,
    asia: 0
  },
  {
    id: "AO",
    name: "ANGOLA",
    eu: 0,
    asia: 0
  },
  {
    id: "AQ",
    name: "ANTARCTICA",
    eu: 0,
    asia: 0
  },
  {
    id: "AR",
    name: "ARGENTINA",
    eu: 0,
    asia: 0
  },
  {
    id: "AS",
    name: "AMERICAN SAMOA",
    eu: 0,
    asia: 0
  },
  {
    id: "AT",
    name: "AUSTRIA",
    eu: 1,
    asia: 0
  },
  {
    id: "AU",
    name: "AUSTRALIA",
    eu: 0,
    asia: 0
  },
  {
    id: "AW",
    name: "ARUBA",
    eu: 0,
    asia: 0
  },
  {
    id: "AX",
    name: "AALAND ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "AZ",
    name: "AZERBAIJAN",
    eu: 0,
    asia: 0
  },
  {
    id: "BA",
    name: "BOSNIA AND HERZEGOWINA",
    eu: 0,
    asia: 0
  },
  {
    id: "BB",
    name: "BARBADOS",
    eu: 0,
    asia: 0
  },
  {
    id: "BD",
    name: "BANGLADESH",
    eu: 0,
    asia: 0
  },
  {
    id: "BE",
    name: "BELGIUM",
    eu: 1,
    asia: 0
  },
  {
    id: "BF",
    name: "BURKINA FASO",
    eu: 0,
    asia: 0
  },
  {
    id: "BG",
    name: "BULGARIA",
    eu: 1,
    asia: 0
  },
  {
    id: "BH",
    name: "BAHRAIN",
    eu: 0,
    asia: 0
  },
  {
    id: "BI",
    name: "BURUNDI",
    eu: 0,
    asia: 0
  },
  {
    id: "BJ",
    name: "BENIN",
    eu: 0,
    asia: 0
  },
  {
    id: "BM",
    name: "BERMUDA",
    eu: 0,
    asia: 0
  },
  {
    id: "BO",
    name: "BOLIVIA",
    eu: 0,
    asia: 0
  },
  {
    id: "BR",
    name: "BRAZIL",
    eu: 0,
    asia: 0
  },
  {
    id: "BS",
    name: "BAHAMAS",
    eu: 0,
    asia: 0
  },
  {
    id: "BT",
    name: "BHUTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "BV",
    name: "BOUVET ISLAND",
    eu: 0,
    asia: 0
  },
  {
    id: "BW",
    name: "BOTSWANA",
    eu: 0,
    asia: 0
  },
  {
    id: "BY",
    name: "BELARUS",
    eu: 0,
    asia: 0
  },
  {
    id: "BZ",
    name: "BELIZE",
    eu: 0,
    asia: 0
  },
  {
    id: "CA",
    name: "CANADA",
    eu: 0,
    asia: 0
  },
  {
    id: "CC",
    name: "COCOS (KEELING) ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "CD",
    name: "CONGO, Democratic Republic of (was Zaire)",
    eu: 0,
    asia: 0
  },
  {
    id: "CF",
    name: "CENTRAL AFRICAN REPUBLIC",
    eu: 0,
    asia: 0
  },
  {
    id: "CG",
    name: "CONGO, Republic of",
    eu: 0,
    asia: 0
  },
  {
    id: "CH",
    name: "SWITZERLAND",
    eu: 0,
    asia: 0
  },
  {
    id: "CI",
    name: "COTE D'IVOIRE",
    eu: 0,
    asia: 0
  },
  {
    id: "CK",
    name: "COOK ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "CL",
    name: "CHILE",
    eu: 0,
    asia: 0
  },
  {
    id: "CM",
    name: "CAMEROON",
    eu: 0,
    asia: 0
  },
  {
    id: "CN",
    name: "CHINA",
    eu: 0,
    asia: 0
  },
  {
    id: "CO",
    name: "COLOMBIA",
    eu: 0,
    asia: 0
  },
  {
    id: "CR",
    name: "COSTA RICA",
    eu: 0,
    asia: 0
  },
  {
    id: "CS",
    name: "SERBIA AND MONTENEGRO",
    eu: 0,
    asia: 0
  },
  {
    id: "CU",
    name: "CUBA",
    eu: 0,
    asia: 0
  },
  {
    id: "CV",
    name: "CAPE VERDE",
    eu: 0,
    asia: 0
  },
  {
    id: "CX",
    name: "CHRISTMAS ISLAND",
    eu: 0,
    asia: 0
  },
  {
    id: "CY",
    name: "CYPRUS",
    eu: 1,
    asia: 0
  },
  {
    id: "CZ",
    name: "CZECH REPUBLIC",
    eu: 1,
    asia: 0
  },
  {
    id: "DE",
    name: "GERMANY",
    eu: 1,
    asia: 0
  },
  {
    id: "DJ",
    name: "DJIBOUTI",
    eu: 0,
    asia: 0
  },
  {
    id: "DK",
    name: "DENMARK",
    eu: 1,
    asia: 0
  },
  {
    id: "DM",
    name: "DOMINICA",
    eu: 0,
    asia: 0
  },
  {
    id: "DO",
    name: "DOMINICAN REPUBLIC",
    eu: 0,
    asia: 0
  },
  {
    id: "DZ",
    name: "ALGERIA",
    eu: 0,
    asia: 0
  },
  {
    id: "EA",
    name: "SOUTH-EAST ASIA",
    eu: 0,
    asia: 0
  },
  {
    id: "EC",
    name: "ECUADOR",
    eu: 0,
    asia: 0
  },
  {
    id: "EE",
    name: "ESTONIA",
    eu: 1,
    asia: 0
  },
  {
    id: "EG",
    name: "EGYPT",
    eu: 0,
    asia: 0
  },
  {
    id: "EH",
    name: "WESTERN SAHARA",
    eu: 0,
    asia: 0
  },
  {
    id: "ER",
    name: "ERITREA",
    eu: 0,
    asia: 0
  },
  {
    id: "ES",
    name: "SPAIN",
    eu: 1,
    asia: 0
  },
  {
    id: "ET",
    name: "ETHIOPIA",
    eu: 0,
    asia: 0
  },
  {
    id: "FI",
    name: "FINLAND",
    eu: 1,
    asia: 0
  },
  {
    id: "FJ",
    name: "FIJI",
    eu: 0,
    asia: 0
  },
  {
    id: "TT",
    name: "TRINIDAD AND TOBAGO",
    eu: 0,
    asia: 0
  },
  {
    id: "FK",
    name: "FALKLAND ISLANDS (MALVINAS)",
    eu: 0,
    asia: 0
  },
  {
    id: "FM",
    name: "MICRONESIA, FEDERATED STATES OF",
    eu: 0,
    asia: 0
  },
  {
    id: "FO",
    name: "FAROE ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "FR",
    name: "FRANCE",
    eu: 1,
    asia: 0
  },
  {
    id: "GA",
    name: "GABON",
    eu: 0,
    asia: 0
  },
  {
    id: "GB",
    name: "UNITED KINGDOM",
    eu: 1,
    asia: 0
  },
  {
    id: "GD",
    name: "GRENADA",
    eu: 0,
    asia: 0
  },
  {
    id: "GE",
    name: "GEORGIA",
    eu: 0,
    asia: 0
  },
  {
    id: "GF",
    name: "FRENCH GUIANA",
    eu: 0,
    asia: 0
  },
  {
    id: "GH",
    name: "GHANA",
    eu: 0,
    asia: 0
  },
  {
    id: "GI",
    name: "GIBRALTAR",
    eu: 0,
    asia: 0
  },
  {
    id: "GL",
    name: "GREENLAND",
    eu: 0,
    asia: 0
  },
  {
    id: "GM",
    name: "GAMBIA",
    eu: 0,
    asia: 0
  },
  {
    id: "GN",
    name: "GUINEA",
    eu: 0,
    asia: 0
  },
  {
    id: "GP",
    name: "GUADELOUPE",
    eu: 0,
    asia: 0
  },
  {
    id: "GQ",
    name: "EQUATORIAL GUINEA",
    eu: 0,
    asia: 0
  },
  {
    id: "GR",
    name: "GREECE",
    eu: 1,
    asia: 0
  },
  {
    id: "GS",
    name: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "GT",
    name: "GUATEMALA",
    eu: 0,
    asia: 0
  },
  {
    id: "GU",
    name: "GUAM",
    eu: 0,
    asia: 0
  },
  {
    id: "GW",
    name: "GUINEA-BISSAU",
    eu: 0,
    asia: 0
  },
  {
    id: "GY",
    name: "GUYANA",
    eu: 0,
    asia: 0
  },
  {
    id: "HK",
    name: "HONG KONG",
    eu: 0,
    asia: 0
  },
  {
    id: "HM",
    name: "HEARD AND MC DONALD ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "HN",
    name: "HONDURAS",
    eu: 0,
    asia: 0
  },
  {
    id: "HR",
    name: "CROATIA (local name: Hrvatska)",
    eu: 1,
    asia: 0
  },
  {
    id: "HT",
    name: "HAITI",
    eu: 0,
    asia: 0
  },
  {
    id: "HU",
    name: "HUNGARY",
    eu: 1,
    asia: 0
  },
  {
    id: "ID",
    name: "INDONESIA",
    eu: 0,
    asia: 0
  },
  {
    id: "IE",
    name: "IRELAND",
    eu: 1,
    asia: 0
  },
  {
    id: "IL",
    name: "ISRAEL",
    eu: 0,
    asia: 0
  },
  {
    id: "IN",
    name: "INDIA",
    eu: 0,
    asia: 0
  },
  {
    id: "IO",
    name: "BRITISH INDIAN OCEAN TERRITORY",
    eu: 0,
    asia: 0
  },
  {
    id: "IQ",
    name: "IRAQ",
    eu: 0,
    asia: 0
  },
  {
    id: "IR",
    name: "IRAN (ISLAMIC REPUBLIC OF)",
    eu: 0,
    asia: 0
  },
  {
    id: "IS",
    name: "ICELAND",
    eu: 0,
    asia: 0
  },
  {
    id: "IT",
    name: "ITALY",
    eu: 1,
    asia: 0
  },
  {
    id: "JM",
    name: "JAMAICA",
    eu: 0,
    asia: 0
  },
  {
    id: "KN",
    name: "SAINT KITTS AND NEVIS",
    eu: 0,
    asia: 0
  },
  {
    id: "KP",
    name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    eu: 0,
    asia: 0
  },
  {
    id: "KR",
    name: "KOREA, REPUBLIC OF",
    eu: 0,
    asia: 0
  },
  {
    id: "KW",
    name: "KUWAIT",
    eu: 0,
    asia: 0
  },
  {
    id: "KY",
    name: "CAYMAN ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "KZ",
    name: "KAZAKHSTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "LB",
    name: "LEBANON",
    eu: 0,
    asia: 0
  },
  {
    id: "LC",
    name: "SAINT LUCIA",
    eu: 0,
    asia: 0
  },
  {
    id: "LI",
    name: "LIECHTENSTEIN",
    eu: 0,
    asia: 0
  },
  {
    id: "LK",
    name: "SRI LANKA",
    eu: 0,
    asia: 0
  },
  {
    id: "LR",
    name: "LIBERIA",
    eu: 0,
    asia: 0
  },
  {
    id: "LS",
    name: "LESOTHO",
    eu: 0,
    asia: 0
  },
  {
    id: "LT",
    name: "LITHUANIA",
    eu: 1,
    asia: 0
  },
  {
    id: "LU",
    name: "LUXEMBOURG",
    eu: 1,
    asia: 0
  },
  {
    id: "LV",
    name: "LATVIA",
    eu: 1,
    asia: 0
  },
  {
    id: "LY",
    name: "LIBYAN ARAB JAMAHIRIYA",
    eu: 0,
    asia: 0
  },
  {
    id: "MA",
    name: "MOROCCO",
    eu: 0,
    asia: 0
  },
  {
    id: "MC",
    name: "MONACO",
    eu: 0,
    asia: 0
  },
  {
    id: "MD",
    name: "MOLDOVA, REPUBLIC OF",
    eu: 0,
    asia: 0
  },
  {
    id: "MG",
    name: "MADAGASCAR",
    eu: 0,
    asia: 0
  },
  {
    id: "MH",
    name: "MARSHALL ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "MK",
    name: "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
    eu: 0,
    asia: 0
  },
  {
    id: "ML",
    name: "MALI",
    eu: 0,
    asia: 0
  },
  {
    id: "MN",
    name: "MONGOLIA",
    eu: 0,
    asia: 0
  },
  {
    id: "MO",
    name: "MACAU",
    eu: 0,
    asia: 0
  },
  {
    id: "MP",
    name: "NORTHERN MARIANA ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "MQ",
    name: "MARTINIQUE",
    eu: 0,
    asia: 0
  },
  {
    id: "MR",
    name: "MAURITANIA",
    eu: 0,
    asia: 0
  },
  {
    id: "MS",
    name: "MONTSERRAT",
    eu: 0,
    asia: 0
  },
  {
    id: "MT",
    name: "MALTA",
    eu: 1,
    asia: 0
  },
  {
    id: "MU",
    name: "MAURITIUS",
    eu: 0,
    asia: 0
  },
  {
    id: "MV",
    name: "MALDIVES",
    eu: 0,
    asia: 0
  },
  {
    id: "MW",
    name: "MALAWI",
    eu: 0,
    asia: 0
  },
  {
    id: "MX",
    name: "MEXICO",
    eu: 0,
    asia: 0
  },
  {
    id: "MZ",
    name: "MOZAMBIQUE",
    eu: 0,
    asia: 0
  },
  {
    id: "NA",
    name: "NAMIBIA",
    eu: 0,
    asia: 0
  },
  {
    id: "NC",
    name: "NEW CALEDONIA",
    eu: 0,
    asia: 0
  },
  {
    id: "NE",
    name: "NIGER",
    eu: 0,
    asia: 0
  },
  {
    id: "NF",
    name: "NORFOLK ISLAND",
    eu: 0,
    asia: 0
  },
  {
    id: "NG",
    name: "NIGERIA",
    eu: 0,
    asia: 0
  },
  {
    id: "NI",
    name: "NICARAGUA",
    eu: 0,
    asia: 0
  },
  {
    id: "NL",
    name: "NETHERLANDS",
    eu: 1,
    asia: 0
  },
  {
    id: "NO",
    name: "NORWAY",
    eu: 0,
    asia: 0
  },
  {
    id: "NP",
    name: "NEPAL",
    eu: 0,
    asia: 0
  },
  {
    id: "NR",
    name: "NAURU",
    eu: 0,
    asia: 0
  },
  {
    id: "NU",
    name: "NIUE",
    eu: 0,
    asia: 0
  },
  {
    id: "NZ",
    name: "NEW ZEALAND",
    eu: 0,
    asia: 0
  },
  {
    id: "OM",
    name: "OMAN",
    eu: 0,
    asia: 0
  },
  {
    id: "PA",
    name: "PANAMA",
    eu: 0,
    asia: 0
  },
  {
    id: "PE",
    name: "PERU",
    eu: 0,
    asia: 0
  },
  {
    id: "PF",
    name: "FRENCH POLYNESIA",
    eu: 0,
    asia: 0
  },
  {
    id: "PG",
    name: "PAPUA NEW GUINEA",
    eu: 0,
    asia: 0
  },
  {
    id: "PK",
    name: "PAKISTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "PL",
    name: "POLAND",
    eu: 1,
    asia: 0
  },
  {
    id: "PM",
    name: "SAINT PIERRE AND MIQUELON",
    eu: 0,
    asia: 0
  },
  {
    id: "PN",
    name: "PITCAIRN",
    eu: 0,
    asia: 0
  },
  {
    id: "PR",
    name: "PUERTO RICO",
    eu: 0,
    asia: 0
  },
  {
    id: "PS",
    name: "PALESTINIAN TERRITORY, Occupied",
    eu: 0,
    asia: 0
  },
  {
    id: "PT",
    name: "PORTUGAL",
    eu: 1,
    asia: 0
  },
  {
    id: "PW",
    name: "PALAU",
    eu: 0,
    asia: 0
  },
  {
    id: "PY",
    name: "PARAGUAY",
    eu: 0,
    asia: 0
  },
  {
    id: "QA",
    name: "QATAR",
    eu: 0,
    asia: 0
  },
  {
    id: "RE",
    name: "REUNION",
    eu: 0,
    asia: 0
  },
  {
    id: "RO",
    name: "ROMANIA",
    eu: 1,
    asia: 0
  },
  {
    id: "RU",
    name: "RUSSIAN FEDERATION",
    eu: 0,
    asia: 0
  },
  {
    id: "RW",
    name: "RWANDA",
    eu: 0,
    asia: 0
  },
  {
    id: "SA",
    name: "SAUDI ARABIA",
    eu: 0,
    asia: 0
  },
  {
    id: "SB",
    name: "SOLOMON ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "SC",
    name: "SEYCHELLES",
    eu: 0,
    asia: 0
  },
  {
    id: "SD",
    name: "SUDAN",
    eu: 0,
    asia: 0
  },
  {
    id: "SE",
    name: "SWEDEN",
    eu: 1,
    asia: 0
  },
  {
    id: "SG",
    name: "SINGAPORE",
    eu: 0,
    asia: 0
  },
  {
    id: "SH",
    name: "SAINT HELENA",
    eu: 0,
    asia: 0
  },
  {
    id: "SI",
    name: "SLOVENIA",
    eu: 1,
    asia: 0
  },
  {
    id: "SJ",
    name: "SVALBARD AND JAN MAYEN ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "SK",
    name: "SLOVAKIA",
    eu: 1,
    asia: 0
  },
  {
    id: "SL",
    name: "SIERRA LEONE",
    eu: 0,
    asia: 0
  },
  {
    id: "SM",
    name: "SAN MARINO",
    eu: 0,
    asia: 0
  },
  {
    id: "SN",
    name: "SENEGAL",
    eu: 0,
    asia: 0
  },
  {
    id: "SO",
    name: "SOMALIA",
    eu: 0,
    asia: 0
  },
  {
    id: "SR",
    name: "SURINAME",
    eu: 0,
    asia: 0
  },
  {
    id: "ST",
    name: "SAO TOME AND PRINCIPE",
    eu: 0,
    asia: 0
  },
  {
    id: "SV",
    name: "EL SALVADOR",
    eu: 0,
    asia: 0
  },
  {
    id: "SY",
    name: "SYRIAN ARAB REPUBLIC",
    eu: 0,
    asia: 0
  },
  {
    id: "SZ",
    name: "SWAZILAND",
    eu: 0,
    asia: 0
  },
  {
    id: "TC",
    name: "TURKS AND CAICOS ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "TD",
    name: "CHAD",
    eu: 0,
    asia: 0
  },
  {
    id: "TF",
    name: "FRENCH SOUTHERN TERRITORIES",
    eu: 0,
    asia: 0
  },
  {
    id: "TG",
    name: "TOGO",
    eu: 0,
    asia: 0
  },
  {
    id: "TJ",
    name: "TAJIKISTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "TK",
    name: "TOKELAU",
    eu: 0,
    asia: 0
  },
  {
    id: "TL",
    name: "TIMOR-LESTE",
    eu: 0,
    asia: 0
  },
  {
    id: "TM",
    name: "TURKMENISTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "TN",
    name: "TUNISIA",
    eu: 0,
    asia: 0
  },
  {
    id: "TO",
    name: "TONGA",
    eu: 0,
    asia: 0
  },
  {
    id: "TR",
    name: "TURKEY",
    eu: 0,
    asia: 0
  },
  {
    id: "TV",
    name: "TUVALU",
    eu: 0,
    asia: 0
  },
  {
    id: "TW",
    name: "TAIWAN",
    eu: 0,
    asia: 0
  },
  {
    id: "TZ",
    name: "TANZANIA, UNITED REPUBLIC OF",
    eu: 0,
    asia: 0
  },
  {
    id: "UA",
    name: "UKRAINE",
    eu: 0,
    asia: 0
  },
  {
    id: "UG",
    name: "UGANDA",
    eu: 0,
    asia: 0
  },
  {
    id: "UM",
    name: "UNITED STATES MINOR OUTLYING ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "US",
    name: "UNITED STATES",
    eu: 0,
    asia: 0
  },
  {
    id: "UY",
    name: "URUGUAY",
    eu: 0,
    asia: 0
  },
  {
    id: "UZ",
    name: "UZBEKISTAN",
    eu: 0,
    asia: 0
  },
  {
    id: "VA",
    name: "VATICAN CITY STATE (HOLY SEE)",
    eu: 0,
    asia: 0
  },
  {
    id: "VC",
    name: "SAINT VINCENT AND THE GRENADINES",
    eu: 0,
    asia: 0
  },
  {
    id: "VE",
    name: "VENEZUELA",
    eu: 0,
    asia: 0
  },
  {
    id: "VG",
    name: "VIRGIN ISLANDS (BRITISH)",
    eu: 0,
    asia: 0
  },
  {
    id: "VI",
    name: "VIRGIN ISLANDS (U.S.)",
    eu: 0,
    asia: 0
  },
  {
    id: "VU",
    name: "VANUATU",
    eu: 0,
    asia: 0
  },
  {
    id: "WF",
    name: "WALLIS AND FUTUNA ISLANDS",
    eu: 0,
    asia: 0
  },
  {
    id: "WS",
    name: "SAMOA",
    eu: 0,
    asia: 0
  },
  {
    id: "YE",
    name: "YEMEN",
    eu: 0,
    asia: 0
  },
  {
    id: "YT",
    name: "MAYOTTE",
    eu: 0,
    asia: 0
  },
  {
    id: "ZA",
    name: "SOUTH AFRICA",
    eu: 0,
    asia: 0
  },
  {
    id: "ZM",
    name: "ZAMBIA",
    eu: 0,
    asia: 0
  },
  {
    id: "ZW",
    name: "ZIMBABWE",
    eu: 0,
    asia: 0
  }
];
