import { UpdateGoods } from "GraphQL/Queries";

export const useUpdateGoodsSale = () => {
  const updateGoodsSale = async (goodsId: string) => {
    try {
      await UpdateGoods({ id: goodsId, isSale: true });
    } catch (err) {
      console.log(err);
      return;
    }
  };
  return { updateGoodsSale };
};
