import React, { useState } from "react";

import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findPlaylist } from "./findPlaylist";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  | "style"
  | "className"
  | "classNamePrefix"
  | "isDisabled"
  | "defaultValue"
  | "defaultOptions"
  | "onChange"
  | "onBlur"
  | "autoFocus"
  | "filterOption"
>;

export const PlaylistTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  defaultOptions,
  onChange,
  onBlur,
  autoFocus,
  filterOption
}: Props) => {
  const [initialOptions, setInitialOptions] = useState<Info[] | undefined>(defaultOptions);

  const onFocus = async () => {
    const info = await findPlaylist("");
    setInitialOptions(info);
  };
  return (
    <TextAutoComplete
      placeholder="플레이리스트 종류 또는 제목을 입력해주세요."
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      defaultOptions={initialOptions}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      filterOption={filterOption}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      loadInfoList={findPlaylist}
    />
  );
};
