import React, { useCallback } from "react";
import { Metadata } from "GraphQL/Queries/Metadata/GetMetadata";
import defaultImage from "assets/images/cover.png";
import { configs } from "configs";
import { Layout, RowTitle, RowGroup } from "../formStyle";
import { useHistory } from "react-router";
import { ALBUMS } from "constants/route";

type Props = {
  album: Metadata[];
  searchQuery: string;
};

export const AlbumForm = ({ album, searchQuery }: Props) => {
  const router = useHistory();
  const onMovePage = useCallback(() => router.push(`${ALBUMS}?page=1${!searchQuery ? "" : `&title=${searchQuery}`}`), [
    router,
    searchQuery
  ]);
  const onMoveById = useCallback((id: string) => router.push(`${ALBUMS}?page=1&id=${id}`), [router]);
  return (
    <Layout>
      <RowTitle style={{ marginLeft: "0.5rem" }}>
        <span>{"앨범"}</span>
        <button onClick={onMovePage}>{"＞"}</button>
      </RowTitle>
      {!album.length ? (
        <div className="no-data">해당 앨범이 없습니다.</div>
      ) : (
        album.map(({ metadataId, title, artistRelation, metadataUrl }) => {
          const cover = metadataUrl?.filter(({ typeUrl }) => typeUrl === "cover" || typeUrl === "thumbnail" || typeUrl === "head")[0]?.url;
          return (
            <RowGroup key={metadataId} onClick={() => onMoveById(metadataId)}>
              <div className="thumb">
                <img src={cover ? `${configs.urls.image}/${cover}?mode=s` : defaultImage} alt={title} />
              </div>
              <div className="info_area">
                <div className="title">
                  <span>{title}</span>
                </div>
                <div className="artist">
                  <span>{artistRelation[0]?.artist[0].name ?? "-"}</span>
                </div>
              </div>
            </RowGroup>
          );
        })
      )}
    </Layout>
  );
};
