import React from "react";
import styled from "styled-components";
import { MARGING_X_LARGE_PX, MARGING_XX_LARGE_PX } from "constants/size";

const Layout = styled.hr`
  width: 100%;
  margin-top: ${MARGING_X_LARGE_PX};
  margin-bottom: ${MARGING_XX_LARGE_PX};
`;

export const Divider = () => <Layout />;
