import React from "react";
import { FilterGroup, Filters, ToggleGroup, ToggleButton } from "../..";

type Props = {
  url?: boolean;
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const UrlFilter = ({ url, setFilters }: Props) => (
  <FilterGroup>
    <h4>URL</h4>
    <span className="info">URL을 필터하지 않으려면 모두 OFF상태여야 합니다.</span>
    <ToggleGroup>
      <ToggleButton
        toggleValue="유"
        color="primary"
        isActive={url}
        onClick={() => {
          setFilters(filter => ({ ...filter, url: url === true ? undefined : true }));
        }}
      />
      <ToggleButton
        toggleValue="무"
        color="primary"
        isActive={url === false}
        onClick={() => setFilters(filter => ({ ...filter, url: url === false ? undefined : false }))}
      />
    </ToggleGroup>
  </FilterGroup>
);
