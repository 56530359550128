/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  metadataId: string;
  title?: string;
}>;

export const UpdateMetadataTitle = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={true} />} value={option.title} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_TITLE">
      <SelectionSet
        name="updateMetadata"
        alias="updateMetadataTitle"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            title: $title
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateMetadataTitle: Readonly<{
      metadataId: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
