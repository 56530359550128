import React, { ReactNode } from "react";
import styled from "styled-components";
import { GRAY_5, GRAY_2 } from "constants/baseColor";
import { pixelize, UNIT } from "constants/size";

type Props = Styleable &
  Readonly<{
    children: ReactNode;
    rowSpan?: number;
    colSpan?: number;
  }>;

const Layout = styled.th`
  padding: ${pixelize(1 * UNIT)} ${pixelize(2 * UNIT)};
  background-color: ${GRAY_2};
  & + th,
  & + td {
    border-left: 1px dashed ${GRAY_5};
  }
`;

export const Head = ({ children, style, className, rowSpan = 1, colSpan = 1 }: Props) => (
  <Layout style={style} className={className} colSpan={colSpan} rowSpan={rowSpan}>
    {children}
  </Layout>
);
