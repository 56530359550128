import { Info } from "App/Atomics/Input/Select/TextSelect";
import { ValidType } from "constants/ValidType";
import { Element, Role } from "GraphQL/Queries/Role/GetRole";
import { createReducer, createHandler, createActions } from "lib/store";
import { Edge } from "models/Edge";
import uuidv4 from "uuid/v4";
import _ from "lodash";

type State = ReturnType<typeof createInitialState>;
export type RoleTitle = {
  id: string;
  type: string;
  value: string;
  language: {
    languageCode: string;
  }[];
};

const SET_EDGE = Symbol("Role::Edge::Set");
const SET_ROLE = Symbol("Role::Role:Set");
const SET_ROLE_EDIT_LOADING = Symbol("Role::RoleEditLoading::Set");
const SET_ROLE_TITLE = Symbol("Role::Title::Set");
const CREATE_ROLE_TITLE = Symbol("Role::Title::Create");
const UPDATE_ROLE_TITLE = Symbol("Role::Title::Update");
const DELETE_ROLE_TITLE = Symbol("Role::Title::Delete");

const SET_ROLE_MODAL_ID = Symbol("Role::ModalId::Set");

const SET_ROLE_CHECKLIST_INIT = Symbol("Role::CheckListInit::Set");
const SET_ROLE_CHECKLIST = Symbol("Role::CheckList::Set");
const CLEAR_ROLE_CHECKLIST = Symbol("Role::CheckList::Clear");

const SET_ROLE_NAME = Symbol("Role::Name::Set");
const SET_ROLE_TYPE_CLASS = Symbol("Role::TypeClass::Set");
const SET_ROLE_VALID_CHECK = Symbol("Role::ValidCheck::Set");
const SET_ROLE_COUNT_EXPOSE = Symbol("Role::CountExpose::Set");
const CREATE_ROLE_ELEMENT = Symbol("Role::Element::Create");
const UPDATE_ROLE_ELEMENT_ROLE = Symbol("Role::ElementRole::Update");
const UPDATE_ROLE_ELEMENT_TYPE_FUNCTION = Symbol("Role::ElementTypeFunction::Update");
const UPDATE_ROLE_ELEMENT_UUID = Symbol("Role::ElementUuid::Update");
const DELETE_ROLE_ELEMENT = Symbol("Role::Element::Delete");
const DELETE_ROLE = Symbol("Role::Role::Delete");
const SET_ROLE_CELAR = Symbol("Role::Clear::Set");
const SET_ROLE_SAVE = Symbol("Role::Save::Set");

export const RoleActions = createActions({
  setEdge(edge: Edge) {
    return { type: SET_EDGE, edge };
  },
  setRole(roles: Role[]) {
    return { type: SET_ROLE, roles };
  },
  setRoleEditLoading(loading: boolean) {
    return { type: SET_ROLE_EDIT_LOADING, loading };
  },
  setRoleTitle(titles: RoleTitle[]) {
    return { type: SET_ROLE_TITLE, titles };
  },
  createRoleTitle(id: string, typeRole: string, value: string, languageCode: string) {
    return { type: CREATE_ROLE_TITLE, id, typeRole, value, languageCode };
  },
  updateRoleTitle(id: string, typeRole: string, value: string, languageCode: string) {
    return { type: UPDATE_ROLE_TITLE, id, typeRole, value, languageCode };
  },
  deleteRoleTitle(id: string) {
    return { type: DELETE_ROLE_TITLE, id };
  },
  setModalId(id: string) {
    return { type: SET_ROLE_MODAL_ID, id };
  },
  setRoleCheckListInit(ids: string[]) {
    return { type: SET_ROLE_CHECKLIST_INIT, ids };
  },
  setRoleCheckList(id: string, checked: boolean) {
    return { type: SET_ROLE_CHECKLIST, id, checked };
  },
  clearRoleCheckList() {
    return { type: CLEAR_ROLE_CHECKLIST };
  },
  setRoleName(index: number, name: string) {
    return { type: SET_ROLE_NAME, index, name };
  },
  setRoleTypeClass(index: number, typeClass: string) {
    return { type: SET_ROLE_TYPE_CLASS, index, typeClass };
  },
  setRoleVC(index: number, vc: ValidType) {
    return { type: SET_ROLE_VALID_CHECK, index, vc };
  },
  setRoleCountExpose(index: number, key: string, value: number) {
    return { type: SET_ROLE_COUNT_EXPOSE, index, key, value };
  },
  createRoleElement(index: number) {
    return { type: CREATE_ROLE_ELEMENT, index };
  },
  updateRoleElementRole(index: number, elemIndex: number, roleInfo: Info) {
    return { type: UPDATE_ROLE_ELEMENT_ROLE, index, elemIndex, roleInfo };
  },
  updateRoleElementTypeFunction(index: number, elemIndex: number, typeFunction: string) {
    return { type: UPDATE_ROLE_ELEMENT_TYPE_FUNCTION, index, elemIndex, typeFunction };
  },
  updateRoleElementUuid(index: number, prevUuid: string, uuid: string) {
    return { type: UPDATE_ROLE_ELEMENT_UUID, index, prevUuid, uuid };
  },
  deleteRoleElement(index: number, elemIndex: number) {
    return { type: DELETE_ROLE_ELEMENT, index, elemIndex };
  },
  deleteRole(id: string) {
    return { type: DELETE_ROLE, id };
  },
  setRoleClear() {
    return { type: SET_ROLE_CELAR };
  },
  setRoleSave() {
    return { type: SET_ROLE_SAVE };
  }
});

const handler = createHandler<State>({
  [SET_EDGE](state, payload: { edge: Edge }) {
    state.edge = payload.edge;
  },
  [SET_ROLE](state, payload: { roles: any[] }) {
    state.roles = payload.roles;
    state.origin = payload.roles;
  },
  [SET_ROLE_EDIT_LOADING](state, payload: { loading: boolean }) {
    state.editLoading = payload.loading;
  },
  [SET_ROLE_TITLE](state, payload: { titles: RoleTitle[] }) {
    state.roleTitle = payload.titles;
  },
  [CREATE_ROLE_TITLE](state, payload: { id: string; typeRole: string; value: string; languageCode: string }) {
    const newRoleTitle = {
      id: payload.id,
      type: payload.typeRole,
      value: payload.value,
      language: [
        {
          languageCode: payload.languageCode
        }
      ]
    };
    state.roleTitle.push(newRoleTitle);
  },
  [UPDATE_ROLE_TITLE](state, payload: { id: string; typeRole: string; value: string; languageCode: string }) {
    const idx = state.roleTitle.findIndex(item => item.id === payload.id);
    state.roleTitle[idx].type = payload.typeRole;
    state.roleTitle[idx].value = payload.value;
    state.roleTitle[idx].language[0].languageCode = payload.languageCode;
  },
  [DELETE_ROLE_TITLE](state, payload: { id: string }) {
    state.roleTitle = state.roleTitle.filter(item => item.id !== payload.id);
  },
  [SET_ROLE_MODAL_ID](state, payload: { id: string }) {
    state.modalId = payload.id;
  },
  [SET_ROLE_CHECKLIST_INIT](state, payload: { ids: string[] }) {
    payload.ids.forEach(id => {
      state.checkList.set(id, false);
    });
  },
  [SET_ROLE_CHECKLIST](state, payload: { id: string; checked: boolean }) {
    state.checkList.set(payload.id, payload.checked);
  },
  [CLEAR_ROLE_CHECKLIST](state) {
    state.checkList.clear();
  },
  [SET_ROLE_NAME](state, payload: { index: number; name: string }) {
    state.roles[payload.index].name = payload.name;
  },
  [SET_ROLE_TYPE_CLASS](state, payload: { index: number; typeClass: string }) {
    state.roles[payload.index].typeClass = payload.typeClass;
  },
  [SET_ROLE_VALID_CHECK](state, payload: { index: number; vc: ValidType }) {
    state.roles[payload.index].validCheck = payload.vc;
  },
  [SET_ROLE_COUNT_EXPOSE](state, payload: { index: number; key: string; value: number }) {
    if (payload.key === "count") {
      state.roles[payload.index].count = payload.value;
    } else {
      state.roles[payload.index].expose = payload.value;
    }
  },
  [CREATE_ROLE_ELEMENT](state, payload: { index: number }) {
    const uuid = uuidv4();
    const order = !state.roles[payload.index].listRelation.length ? 0 : (_.last(state.roles[payload.index].listRelation)?.order ?? 0) + 1;
    const newElement = {
      uuid,
      typeFunction: undefined,
      order,
      elementRelation: [{ id: undefined, name: undefined }]
    } as Element;
    if (!state.roles[payload.index].listRelation.length) {
      state.roles[payload.index].listRelation = [newElement];
    } else {
      state.roles[payload.index].listRelation.push(newElement);
    }
  },
  [UPDATE_ROLE_ELEMENT_ROLE](state, payload: { index: number; elemIndex: number; roleInfo: Info }) {
    state.roles[payload.index].listRelation[payload.elemIndex].elementRelation[0].id = payload.roleInfo.id;
    state.roles[payload.index].listRelation[payload.elemIndex].elementRelation[0].name = payload.roleInfo.name;
  },
  [UPDATE_ROLE_ELEMENT_TYPE_FUNCTION](state, payload: { index: number; elemIndex: number; typeFunction: string }) {
    state.roles[payload.index].listRelation[payload.elemIndex].typeFunction = payload.typeFunction;
  },
  [UPDATE_ROLE_ELEMENT_UUID](state, payload: { index: number; prevUuid: string; uuid: string }) {
    const { listRelation } = state.roles[payload.index];
    const elemIndex = listRelation.findIndex(({ uuid }) => uuid === payload.prevUuid);
    listRelation[elemIndex].uuid = payload.uuid;
  },
  [DELETE_ROLE_ELEMENT](state, payload: { index: number; elemIndex: number }) {
    state.roles[payload.index].listRelation.splice(payload.elemIndex, 1);
  },
  [DELETE_ROLE](state, payload: { id: string }) {
    const roleIndex = state.roles.findIndex(({ id }) => id === payload.id);
    if (roleIndex < 0) {
      return;
    }
    state.roles.splice(roleIndex, 1);
  },
  [SET_ROLE_CELAR](state) {
    state.roles = state.origin;
  },
  [SET_ROLE_SAVE](state) {
    state.origin = state.roles;
  }
});

const createInitialState = () => ({
  roles: [] as Role[],
  origin: [] as Role[],
  roleTitle: [] as RoleTitle[],
  edge: null as null | Edge,
  modalId: "" as string,
  checkList: new Map<string, boolean>(),
  editLoading: false as boolean
});

export const reducer = createReducer(handler, createInitialState);
