import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { MARGING_XXX_LARGE_PX } from "constants/size";
import { GRAY_7 } from "constants/baseColor";
import { KeyInfo } from "lib/key-info";
import { ReactComponent as BulbIcon } from "assets/icons/bulb.svg";

type Props = {
  title: string;
  context: string;
  toSave?: () => void;
  toClose: () => void;
};

const Layout = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  width: 25rem;
  background: white;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.05);
  padding: 2rem;

  section {
    width: 100%;
    white-space: pre-wrap;
    line-height: 2;
    font-size: 1.125rem;
    margin: 0;
    color: ${GRAY_7};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  h3 {
    font-size: 1.5rem;
  }

  .bulb {
    width: 1.7rem;
    height: 1.7rem;
    margin-right: 5px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 5px;
  margin-top: ${MARGING_XXX_LARGE_PX};
`;

// * toSave ? (will do confirm) : (will do alert)
export const Confirm = ({ title, context, toSave, toClose }: Props) => {
  const handleClick = useCallback(() => {
    if (toSave) {
      toSave();
    }
    toClose();
  }, [toClose, toSave]);

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEnter) {
        handleClick();
      }
    },
    [handleClick]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <Layout>
      <Title>
        <BulbIcon className="bulb" />
        <h3>{title}</h3>
      </Title>

      <section>{context}</section>
      <ButtonGroup>
        {!toSave ? null : (
          <Input.Button color="primary" onClick={handleClick}>
            확인
          </Input.Button>
        )}
        {
          <Input.Button color={toSave ? undefined : "primary"} onClick={toClose}>
            {toSave ? "취소" : "확인"}
          </Input.Button>
        }
      </ButtonGroup>
    </Layout>
  );
};
