import { useState } from "react";
import { LoadGoodsBySearch } from "GraphQL/Queries/Goods";
import { Toast } from "lib/toast";
import { useAsyncEffect } from "lib/use-async-effect";
import { GoodsModel } from "models/GoodsModel";
import { GoodsStore } from "../Store";
import { GoodsActions } from "../Store/Goods";

type Props = {
  readonly first?: number;
  readonly page?: number;
  readonly id?: string;
  readonly name?: string;
  readonly parentId?: number;
  readonly categoryIn?: string[];
  readonly typeIn?: string[];
};

type Result = { loading: boolean };

export const useLoadGoods = ({ first, page, id, name, categoryIn, typeIn, parentId }: Props): Result => {
  const [loading, setLoading] = useState<boolean>(false);
  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const { data, errors } = await LoadGoodsBySearch({
        first,
        page,
        id,
        name,
        categoryIn,
        typeIn,
        parentId
      });
      if (errors || !data) {
        console.log(errors);
        Toast.error("컨텐츠를 불러올 수 없습니다.");
        return;
      }
      const { goodsTitle, edge } = data;
      const goodsMs = goodsTitle.map(({ goods }) => {
        const {
          id,
          name,
          goodsTitle: titles,
          weight,
          createdAt,
          goodsCompanyRelation: company,
          goodsUrl,
          goodsStructure,
          goodsType,
          isCollection
        } = goods[0];
        const subtitle = titles[0]?.value ?? "-";
        const parentGoodsId = goodsStructure[0]?.parent?.[0]?.goods[0]?.id;
        const parentName = goodsStructure[0]?.parent?.[0]?.goods[0]?.name;
        return GoodsModel.from({
          id,
          name,
          goodsType,
          subtitle,
          weight,
          createdAt,
          urls: goodsUrl,
          company: { id: company[0].company.id, name: company[0].company.name },
          structureId: goodsStructure[0]?.id,
          isCollection,
          parentGoodsId,
          parentName
        });
      });
      GoodsStore.dispatch([GoodsActions.setGoods(goodsMs), GoodsActions.setEdge(edge)]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [first, page, id, name, parentId, JSON.stringify(categoryIn), JSON.stringify(typeIn)]);

  return { loading };
};
