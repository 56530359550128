import React, { ReactElement } from "react";
import styled from "styled-components";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsTextAutoComplete } from "App/Molecules/AutoCompletes/Goods";
import { Info } from "App/Atomics/Input/Select/TextSelect";

type Props = {
  readonly goods?: Info;
};

export const ProductGoods = ({ goods }: Props): ReactElement => {
  return (
    <Style.Row>
      <Style.Caption>원본 굿즈 *</Style.Caption>
      <Style.SubCaption>· 상품이 들어갈 굿즈를 선택해주세요.</Style.SubCaption>
      <AutoCompleteContainer>
        <GoodsTextAutoComplete key={goods?.id} isDisabled style={SelectStyle} placeholder={"원본 굿즈"} defaultValue={goods} />
      </AutoCompleteContainer>
    </Style.Row>
  );
};

const AutoCompleteContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
`;
