import React from "react";
import styled from "styled-components";
import { Metadata } from "GraphQL/Queries/Metadata/GetMetadata";
import { HEADER_HEIGHT_PX } from "constants/size";
import { CardItem } from "./CardItem";

type Props = {
  works: Metadata[];
};

const Layout = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${HEADER_HEIGHT_PX};
  padding: 2em;
  padding-bottom: 0;
`;

export const CardList = ({ works }: Props) => {
  return (
    <Layout>
      {works.map((work: Metadata, index) => {
        return <CardItem key={work.metadataId} index={index} {...work} />;
      })}
    </Layout>
  );
};
