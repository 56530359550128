import { createDuck } from "lib/store/v2";

type LoadingState = "None" | "Loading" | "Error";

type Id = string;
export type Track = {
  id: Id;
  title: string;
  duplicated?: boolean;
  audio: {
    type: string;
    url: string;
  };
  mood: { id: string; name: string }[];
  genre: { id: string; name: string }[];
};

const createInitialState = () => ({
  state: "None" as LoadingState,
  tracks: [] as readonly Track[]
});

export const PlaylistAutoCreateActions = createDuck({
  namespace: "PlaylistAutoCreate",
  createInitialState,
  reducers: {
    setTrack(state, tracks: Track[]) {
      state.tracks = tracks;
    },
    updateLoadingState(state, loadingState: LoadingState) {
      state.state = loadingState;
    }
  }
});
