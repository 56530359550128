import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { LanguageActions as Language } from "./Language";

const reducers = { Language };

const reducer = combineReducers(reducers, createStoreLogger("Language"));

export const {
  StoreProvider: LanguageStoreProvider,
  useStore: useLanguageStore,
  useSelector: useLanguageSelector,
  useDispatch: useLanguageDispatch
} = createStore(reducer);
