import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { Input } from "App/Atomics/Input";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { SelectStyle } from "App/Routes/GoodsCreate/Style";
import { AC_WHITE, PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { Toast } from "lib/toast";
import React, { useState } from "react";
import styled from "styled-components";
import { useCreateEmptyAlbum } from "./useCreateEmptyAlbum";

type Props = Readonly<{
  onClose: () => void;
}>;

export const CreateEmptyAlbumModal = ({ onClose }: Props) => {
  const [title, setTitle] = useState<string>("");
  const [artist, setArtist] = useState<Info | null>(null);
  const [company, setCompany] = useState<Info | null>(null);
  const { createAlbum } = useCreateEmptyAlbum();

  const tryToCreateAlbum = async () => {
    if (!title?.length || !artist || !company) {
      Toast.error("모든 정보를 입력해주세요.");
      return;
    }
    await createAlbum({ title, artistId: artist.id, companyId: company.id });
    Toast.primary("앨범이 생성되었습니다.");
    onClose();
    return;
  };

  return (
    <Layout>
      <Header>빈 앨범 생성</Header>
      <Container>
        <RowGroup>
          <Label>앨범명</Label>
          <InputText onBlur={setTitle} />
        </RowGroup>
        <RowGroup>
          <Label>아티스트</Label>
          <ArtistTextAutoComplete style={SelectStyle} onBlur={setArtist} />
        </RowGroup>
        <RowGroup>
          <Label>권리사</Label>
          <CompanyTextAutoComplete style={SelectStyle} onBlur={setCompany} />
        </RowGroup>
        <ButtonGroup>
          <Input.Button onClick={onClose}>취소</Input.Button>
          <Input.Button color="primary" onClick={tryToCreateAlbum}>
            생성
          </Input.Button>
        </ButtonGroup>
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  width: 450px;
  height: 400px;
  overflow: hidden;
  border-radius: 2px;
`;

const Header = styled.div`
  width: 100%;
  padding: 16px;
  color: ${AC_WHITE};
  background-color: ${PRIMARY_COLOR};
`;
const Container = styled.div`
  padding: 16px;
`;

const RowGroup = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

const InputText = styled(Input.Text)`
  position: relative;
  width: 100%;
  height: 44px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #cdcdcd;
  border: none;
  border-radius: 8px;
  line-height: 22px;
  padding: 1rem;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: inset 0 0 0 2px #adadad;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px ${PRIMARY_COLOR_LIGHT};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
  padding: 8px 16px;
`;
