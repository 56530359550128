import React, { useRef, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { GRAY_4, WHITE } from "constants/baseColor";
import { BORDER_RADIUS_PX, pixelize, UNIT } from "constants/size";
import { PRIMARY_COLOR, SECONDARY_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { mediaQuery } from "constants/media";
import { Helmet } from "App/Atomics/Helmet";
import { useHistory } from "react-router";
import { useQueryParams } from "lib/use-query-params";
import { useAppDispatch } from "App/Store";
import { SIGN_UP, HOME } from "constants/route";
import { LogIn } from "GraphQL/Queries";
import { GraphQLClientHeader } from "utils/clients/GraphQLClientHeader";
import { UserInfo } from "utils/clients/UserInfo";
import { UserTokenActions } from "App/Store/UserToken";

const SingInLayout = () => {
  const router = useHistory();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const queryParams = useQueryParams();
  const dispatchApp = useAppDispatch();
  const from = queryParams.get("from", { default: HOME });
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;
    if (!email) {
      window.alert("계정을 입력해주세요.");
      return;
    }
    const { data: loginData, errors: loginError } = await LogIn({ email, password });
    if (loginError) {
      switch (loginError[0].message) {
        case "Error: wrong ID":
          window.alert("존재하지 않는 계정입니다.");
          break;
        case "Error: wrong password":
          window.alert("잘못된 비밀번호입니다.");
          break;
        default:
          window.alert("로그인에 실패하였습니다.");
          break;
      }
      return;
    }
    if (loginData) {
      const { refreshToken, accessToken, userToken, payloadToken } = loginData.logIn.tokens;
      GraphQLClientHeader.setByKey("refresh_user_token", refreshToken);
      GraphQLClientHeader.setByKey("access_token", accessToken);
      GraphQLClientHeader.setByKey("user_token", userToken);
      GraphQLClientHeader.setByKey("payload_token", payloadToken);
      UserInfo.save({ email, refreshToken, accessToken, userToken, payloadToken });
      dispatchApp(UserTokenActions.signIn(email));
      router.push(from);
    }
  };

  const checkInputValid = useCallback((type: string, text?: string) => {
    if (!text) {
      return undefined;
    }
    if (type === "email") {
      const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})/;
      return regex.test(text);
    } else {
      const regex = /[\w!@#$%^&*-]{6,}/;
      return regex.test(text);
    }
  }, []);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  return (
    <Layout>
      <Header>DMS</Header>
      <LoginForm onSubmit={onSubmit}>
        <label className="title">로그인</label>
        <hr className="bar" />
        <ValidContainer isValid={checkInputValid("email", email)}>
          <Input.Text ref={emailRef} autoComplete="email" placeholder="이메일을 입력하세요." onChange={setEmail} />
        </ValidContainer>
        <ValidContainer isValid={checkInputValid("password", password)}>
          <Input.Password ref={passwordRef} placeholder="비밀번호를 입력하세요." onChange={setPassword} />
        </ValidContainer>
        <div className="alert-password">
          <span>비밀번호를 잊으신 경우,</span>
          <span>관리자에게 문의해주시기 바랍니다.</span>
        </div>
        <LoginButton type="submit" color="default" disabled={!checkInputValid("email", email) || !checkInputValid("password", password)}>
          로그인하기
        </LoginButton>
        <span className="alert-signup">
          계정이 없으신가요 ? <button onClick={() => router.push(SIGN_UP)}>회원가입</button>
        </span>
      </LoginForm>
    </Layout>
  );
};

export const SignIn = () => (
  <>
    <Helmet title="로그인" />
    <SingInLayout />
  </>
);

const Layout = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  min-width: ${pixelize(UNIT * 20)};
  align-items: center;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7vh;
  max-height: ${pixelize(UNIT * 3.5)};
  width: 100%;
  background-image: linear-gradient(187deg, rgb(106, 95, 221) 38%, rgb(36, 29, 140) 100%);
  color: #fff;
  font-family: Rubik;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
`;

const LoginForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1rem;
  width: 80vw;
  min-width: ${pixelize(UNIT * 21)};
  max-width: ${pixelize(UNIT * 24)};
  grid-gap: 1rem;

  input {
    width: 100%;
    height: 3em;
    font-size: 0.9rem;
    border: 1px solid ${GRAY_4};
    border-radius: ${BORDER_RADIUS_PX};
    transition: all 0.1s;
    &:focus {
      border-color: ${PRIMARY_COLOR};
    }
    background-color: ${WHITE};
  }

  .title {
    text-align: center;
    margin-top: 1rem;
    height: 7vh;
    font-weight: 400;
    font-size: 2.5rem;
    color: ${PRIMARY_COLOR_LIGHT};
    text-shadow: 0 1.5px 1.5px ${PRIMARY_COLOR_LIGHT};
    ${mediaQuery(768)} {
      font-size: 2rem;
    }
    ${mediaQuery(375)} {
      font-size: 1.5rem;
    }
  }

  .bar {
    width: 100%;
    height: 3px;
    background-color: ${PRIMARY_COLOR_LIGHT};
    color: ${PRIMARY_COLOR_LIGHT};
  }

  .alert-password {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.7em;
    color: ${SECONDARY_COLOR_LIGHT};
    text-align: left;
  }

  .alert-signup {
    width: 100%;
    text-align: right;
    font-size: 0.9rem;
    button {
      color: ${PRIMARY_COLOR};
      font-weight: bold;
      &:hover {
        color: ${PRIMARY_COLOR_LIGHT};
      }
    }
  }
`;

const LoginButton = styled(Input.Button)`
  width: 100%;
  height: 3.5em;
  font-size: 1.1.em;
`;

const ValidContainer = styled.div<{ isValid?: boolean }>`
  position: relative;
  input {
    padding-right: 48px;
  }
  &::after {
    content: "";
    display: inline-block;
    ${props =>
      props.isValid === false
        ? `background: rgba(0, 0, 0, 0)
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29ucyAvIFNldHRpbmdzIC8gSW52YWxpZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy0vLVNldHRpbmdzLS8tSW52YWxpZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IngtY2lyY2xlLWYiIGZpbGw9IiNEQjQyNDEiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4wNzA1NTU2LDE3LjA3IEMxMy4xODE2NjY3LDIwLjk1ODg4ODkgNi44MTgzMzMzMywyMC45NTg4ODg5IDIuOTI5NDQ0NDQsMTcuMDcgQy0wLjk1ODg4ODg4OSwxMy4xODE2NjY3IC0wLjk1ODg4ODg4OSw2LjgxODMzMzMzIDIuOTI5NDQ0NDQsMi45Mjk0NDQ0NCBDNi44MTgzMzMzMywtMC45NTg4ODg4ODkgMTMuMTgxNjY2NywtMC45NTg4ODg4ODkgMTcuMDcsMi45Mjk0NDQ0NCBDMjAuOTU4ODg4OSw2LjgxODMzMzMzIDIwLjk1ODg4ODksMTMuMTgxNjY2NyAxNy4wNzA1NTU2LDE3LjA3IEwxNy4wNzA1NTU2LDE3LjA3IFogTTEzLjg5Mzg4ODksNy42NjM4ODg4OSBMMTIuMzM2MTExMSw2LjEwNjExMTExIEwxMCw4LjQ0Mjc3Nzc4IEw3LjY2Mzg4ODg5LDYuMTA2MTExMTEgTDYuMTA2NjY2NjcsNy42NjM4ODg4OSBMOC40NDI3Nzc3OCwxMCBMNi4xMDY2NjY2NywxMi4zMzYxMTExIEw3LjY2Mzg4ODg5LDEzLjg5Mzg4ODkgTDEwLDExLjU1NzIyMjIgTDEyLjMzNjExMTEsMTMuODkzODg4OSBMMTMuODkzODg4OSwxMi4zMzYxMTExIEwxMS41NTcyMjIyLDEwIEwxMy44OTM4ODg5LDcuNjYzODg4ODkgTDEzLjg5Mzg4ODksNy42NjM4ODg4OSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
      no-repeat scroll 0% 0%`
        : props.isValid === true
        ? `background: rgba(0, 0, 0, 0)
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29ucyAvIFNldHRpbmdzIC8gVmFsaWQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iSWNvbnMtLy1TZXR0aW5ncy0vLVZhbGlkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iY2hlY2stY2lyY2xlLWYiIGZpbGw9IiMzQ0FBRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMCwxOS45OTg4ODg5IEM0LjUwMDU1NTU2LDE5Ljk5ODg4ODkgMC4wMDExMTExMTExMSwxNS40OTg4ODg5IDAuMDAxMTExMTExMTEsOS45OTk0NDQ0NCBDMC4wMDExMTExMTExMSw0LjUwMTExMTExIDQuNTAwNTU1NTYsMC4wMDExMTExMTExMSAxMCwwLjAwMTExMTExMTExIEMxNS40OTk0NDQ0LDAuMDAxMTExMTExMTEgMTkuOTk4ODg4OSw0LjUwMTExMTExIDE5Ljk5ODg4ODksOS45OTk0NDQ0NCBDMTkuOTk4ODg4OSwxNS40OTg4ODg5IDE1LjQ5OTQ0NDQsMTkuOTk4ODg4OSAxMCwxOS45OTg4ODg5IEwxMCwxOS45OTg4ODg5IFogTTEzLjM5Nzc3NzgsNi4xMTA1NTU1NiBMOC4xMTk0NDQ0NCwxMS4yOTYxMTExIEw2LjA0NjY2NjY3LDkuMjYgTDQuNDQzODg4ODksMTAuODM0NDQ0NCBMOC4xMTk0NDQ0NCwxNC40NDUgTDkuNzIyMjIyMjIsMTIuODcwNTU1NiBMMTUuMDAwNTU1Niw3LjY4NTU1NTU2IEwxMy4zOTc3Nzc4LDYuMTEwNTU1NTYgTDEzLjM5Nzc3NzgsNi4xMTA1NTU1NiBaIE04LjExOTQ0NDQ0LDExLjI5NjExMTEgTDguMTE5NDQ0NDQsMTEuMjk2MTExMSBMOS43MjIyMjIyMiwxMi44NzA1NTU2IEw4LjExOTQ0NDQ0LDExLjI5NjExMTEgTDguMTE5NDQ0NDQsMTEuMjk2MTExMSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
      no-repeat scroll 0% 0%`
        : ""};
    position: absolute;
    top: 50%;
    right: 12px;
    bottom: auto;
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
`;
