/** @jsx jsx */

import { TypeAudio } from "App/Store/AudioPlayer";
import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import uuidv4 from "uuid/v4";

type Option = Readonly<{
  id: string;
  typeClass: string;
}>;

export const GetMetadataAudioData = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const $typeClass = <Variable name="class" scalar={<StringScalar />} value={option.typeClass} />;
  const { query, variables } = render(
    <Query operationName="GET_METADATA_AUDIO_DATA">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 1,
          where: {
            metadata_id: $id,
            type_metadata_class: $typeClass,
            type_metadata_subclass__in: ["track", "effect"]
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            first: 1,
            where: {
              role__some: {
                role_id: "343"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_url"
          alias="metadataUrl"
          argument={{
            where: {
              type_url__in: ["wav", "mp3high", "aac", "flac"]
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
        </SelectionSet>
        <SelectionSet name="metadata_structure" alias="structures">
          <SelectionSet name="list">
            <SelectionSet name="metadata">
              <SelectionSet
                name="metadata_url"
                alias="metadataUrl"
                argument={{
                  where: {
                    type_url: "cover"
                  }
                }}
              >
                <Field name="id" />
                <Field name="type_url" alias="typeUrl" />
                <Field name="url" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      title: string;
      artistRelation: {
        uuid: string;
        artist: {
          id: string;
          name: string;
        }[];
      }[];
      metadataUrl: {
        uuid: string;
        typeUrl: string;
        url: string;
      }[];
      structures: {
        list: {
          metadata: {
            metadataUrl: {
              id: string;
              typeUrl: string;
              url: string;
            }[];
          }[];
        }[];
      }[];
    }[];
  }>;
  const { errors, data: trackData } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  const data = trackData!.metadata[0];
  const uuid = uuidv4();
  const title = data.title;
  const artist = data.artistRelation[0]?.artist[0]?.name;
  const coverUrl = data.structures[0]?.list[0]?.metadata[0]?.metadataUrl[0]?.url;
  const url = data.metadataUrl[0]?.url;
  return { uuid, title, artist, coverUrl, url } as TypeAudio;
};
