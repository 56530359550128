import React from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_X_LARGE_PX, UNIT_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useArtistSelector } from "../../Store";
import { Divider } from "App/Atomics/Divider";
import { DEFAULT_BORDER_COLOR } from "constants/color";

type Props = Readonly<{
  toCreate: () => void;
  toCancel: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 30)};
  padding: ${PADDING_XX_LARGE_PX};

  section {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 5)};
  grid-template-columns: 10% 90%;
  grid-gap: ${UNIT_PX};
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};
`;

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};
  img {
    width: ${pixelize(UNIT * 6.25)};
    height: ${pixelize(UNIT * 6.25)};
  }
`;

const NoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 6.25)};
  height: ${pixelize(UNIT * 6.25)};
  border: 1px solid ${DEFAULT_BORDER_COLOR};
  font-weight: bold;
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;
  gap: ${MARGING_LARGE_PX};
  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

export const SubmitModal = ({ toCreate, toCancel }: Props) => {
  const { artist } = useArtistSelector(store => store.Artist);
  const { name, type_artist_class, artistUrl, artistCompany } = artist;
  return (
    <Layout>
      <h2>아티스트 입력 정보</h2>
      <Divider />
      <ImageContainer>
        {!artistUrl ? <NoImage>NO IMAGE</NoImage> : <img src={URL.createObjectURL(artistUrl.file)} alt="cover" />}
      </ImageContainer>
      <RowGroup>
        <h4>이름</h4>
        <span>{name}</span>
      </RowGroup>
      <RowGroup>
        <h4>타입</h4>
        <span>{type_artist_class === "person" ? "개인" : type_artist_class === "organization" ? "그룹" : "채널"}</span>
      </RowGroup>
      <RowGroup>
        <h4>권리사</h4>
        <span>{artistCompany.name}</span>
      </RowGroup>
      <ButtonGroup>
        <Input.Button color="primary" onClick={toCreate}>
          생성
        </Input.Button>
        <Input.Button color="danger" onClick={toCancel}>
          취소
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
