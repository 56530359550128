import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsDetail/Style";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { v4 as uuidv4 } from "uuid";
import { GoodsWork as TypeGoodsWork } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { WorkTextAutoComplete } from "App/Molecules/AutoCompletes/Work";

type Info = {
  id: string;
  name: string;
};

type Props = {
  readonly works: TypeGoodsWork[];
};

export const GoodsWork = ({ works = [] }: Props): ReactElement => {
  const onAddWork = () => {
    const tempId = uuidv4();
    GoodsDetailStore.dispatch(GoodsDetailActions.addWork(tempId));
  };
  const onUpdateWork = (index: number, work: TypeGoodsWork) => GoodsDetailStore.dispatch(GoodsDetailActions.updateWork({ index, work }));
  const onRemoveWork = (index: number) => GoodsDetailStore.dispatch(GoodsDetailActions.removeWork(index));
  return (
    <Style.Row>
      <Style.Caption>작품 (앨범)</Style.Caption>
      <Style.ColContainer>
        {works?.map((work, index) => (
          <Style.RowContainer key={work.uuid}>
            <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveWork(index)}>
              -
            </Style.RoundButton>
            <WorkTextAutoComplete
              style={SelectStyle}
              defaultValue={work.metadata[0]?.id ? work.metadata[0] : undefined}
              placeholder="작품을 입력해주세요."
              workClass="album"
              onBlur={info => {
                info && onUpdateWork(index, { uuid: work.uuid, order: work.order, metadata: [{ id: info.id, name: info.name }] });
              }}
            />
          </Style.RowContainer>
        ))}
        <Style.RowContainer>
          <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddWork}>
            +
          </Style.RoundButton>
        </Style.RowContainer>
      </Style.ColContainer>
    </Style.Row>
  );
};
