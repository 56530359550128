import { TRACK_FILTER } from "constants/storageKey";
import { Head, sanitizeHeads, Subhead } from "./sanitizeHeads";
import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

const createInitialState = () => {
  return {
    ...loadHeads()
  };
};

const afterChange = (state: State) => {
  const { head, subhead } = state;
  window.localStorage[TRACK_FILTER] = JSON.stringify({
    Head: head,
    Subhead: subhead
  });
};

export const TrackTableInfoActions = createDuck({
  namespace: "TrackTableInfo",
  createInitialState,
  reducers: {
    setHead(state, head: Head) {
      state.head = head;
    },
    setSubhead(state, subhead: Subhead) {
      state.subhead = subhead;
    }
  },
  afterChange
});

const loadHeads = () => {
  try {
    const trackFilter = window.localStorage.getItem(TRACK_FILTER);
    return sanitizeHeads(trackFilter ? JSON.parse(trackFilter) : {});
  } catch (error) {
    return sanitizeHeads({});
  }
};
