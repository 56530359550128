/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, EnumScalar, IntScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  first: number;
  metadataId?: string;
  typeClass: string;
  typeSubClassIn: string[];
}>;

export type Metadata = Readonly<{
  metadataId: string;
  class: string;
  subClass: string;
  title: string;
  validCheck: string;
  structures: {
    rootId: string;
    structureId: string;
  }[];
}>;

export const GetTracksInAlbumById = async (option: Option) => {
  const $first = <Variable name="fisrt" scalar={<IntScalar />} value={option.first} />;
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar isNullable={true} />} value={option.metadataId} />;
  const $typeClass = <Variable name="class" scalar={<StringScalar />} value={option.typeClass} />;
  const $typeSubClassIn = (
    <Variable name="subclass" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={option.typeSubClassIn} />
  );
  const { query, variables } = render(
    <Query operationName="GET_TRACKS_IN_ALBUM_BY_ID">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: $first,
          orderBy: <EnumScalar type="MetadataOrderByInput" value="metadata_id__ASC" items={["metadata_id__ASC"]} />,
          where: {
            type_metadata_class: $typeClass,
            type_metadata_subclass__in: $typeSubClassIn,
            metadata_structure__some: {
              depth: 1,
              list__some: {
                metadata__some: {
                  metadata_id: $metadataId
                }
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <Field name="title" />
        <Field name="type_metadata_class" alias="class" />
        <Field name="type_metadata_subclass" alias="subclass" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet name="metadata_structure" alias="structures">
          <Field name="structure_root_id" alias="rootId" />
          <Field name="structure_id" alias="structureId" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: Metadata[];
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
