export const defaultArbeit = {
  Goods: {
    name: "Goods",
    key: "goods",
    tables: [
      {
        key: "goods",
        name: "Goods",
        allow: "400",
        relation: [
          {
            key: "goods_structure",
            allow: "400"
          },
          {
            key: "goods_subdata_unique",
            allow: "400"
          },
          {
            key: "goods_type",
            allow: "400"
          }
        ]
      },
      {
        key: "goods_title",
        name: "Title",
        allow: "400",
        relation: [
          {
            key: "language",
            allow: "400"
          }
        ]
      },
      {
        key: "goods_artist_relation",
        name: "Artist",
        allow: "400",
        relation: null
      },
      {
        key: "goods_url",
        name: "URL",
        allow: "400",
        relation: null
      },
      {
        key: "goods_company_relation",
        name: "Company",
        allow: "400",
        relation: null
      },
      {
        key: "commodity",
        name: "Commodity",
        allow: "400",
        relation: [
          {
            key: "commodity_url",
            allow: "400"
          }
        ]
      }
    ]
  },
  Metadata: {
    name: "Track",
    key: "metadata",
    tables: [
      {
        key: "metadata",
        name: "Metadata",
        allow: "557",
        relation: [
          {
            key: "metadata_structure",
            allow: "700"
          },
          {
            key: "metadata_subdata_unique",
            allow: "550"
          },
          {
            key: "track_license",
            allow: "500"
          },
          {
            key: "track_license_extra",
            allow: "500"
          },
          {
            key: "type_artist_class",
            allow: "400"
          }
        ]
      },
      {
        key: "metadata_title",
        name: "Title",
        allow: "500",
        relation: [
          {
            key: "language",
            allow: "400"
          }
        ]
      },
      {
        key: "metadata_url",
        name: "URL",
        allow: "500",
        relation: null
      },
      {
        key: "metadata_genre_relation",
        name: "Genre",
        allow: "500",
        relation: null
      },
      {
        key: "metadata_company_relation",
        name: "Company",
        allow: "500",
        relation: null
      },
      {
        key: "track_information",
        name: "Info",
        allow: "500",
        relation: null
      },
      {
        key: "metadata_self_relation",
        name: "Self",
        allow: "500",
        relation: null
      }
    ]
  },
  Artist: {
    name: "Artist",
    key: "artist",
    tables: [
      {
        key: "artist",
        name: "Artist",
        allow: "554",
        relation: null
      },
      {
        key: "artist_title",
        name: "Title",
        allow: "500",
        relation: [
          {
            key: "language",
            allow: "000"
          }
        ]
      },
      {
        key: "artist_url",
        name: "URL",
        allow: "500",
        relation: null
      },
      {
        key: "artist_history",
        name: "History",
        allow: "500",
        relation: null
      },
      {
        key: "artist_company_relation",
        name: "Company",
        allow: "500",
        relation: null
      },
      {
        key: "artist_role_relation",
        name: "Role",
        allow: "500",
        relation: null
      },
      {
        key: "artist_genre_relation",
        name: "Genre",
        allow: "500",
        relation: null
      }
    ]
  },
  MetadataArtistRelation: {
    name: "MetadataArtistRelation",
    key: "metadata_artist_relation",
    tables: [{ key: "metadata_artist_relation", name: "MetadataArtistRelation", allow: "500", relation: null }]
  },
  Role: {
    name: "Role",
    key: "role",
    tables: [
      {
        key: "role",
        name: "Role",
        allow: "440",
        relation: null
      },
      {
        key: "role_title",
        name: "Title",
        allow: "400",
        relation: [
          {
            key: "artist_role_relation",
            allow: "400"
          },
          {
            key: "language",
            allow: "400"
          }
        ]
      }
    ]
  },
  Genre: {
    name: "Genre",
    key: "genre",
    tables: [
      {
        key: "genre",
        name: "Genre",
        allow: "440",
        relation: [
          {
            key: "genre_self_relation",
            allow: "400"
          }
        ]
      },
      {
        key: "genre_title",
        name: "Title",
        allow: "400",
        relation: [
          {
            key: "language",
            allow: "400"
          }
        ]
      },
      {
        key: "genre_url",
        name: "URL",
        allow: "400",
        relation: []
      }
    ]
  },
  Playlist: {
    name: "Playlist",
    key: "playlist",
    tables: [
      {
        key: "playlist",
        name: "Playlist",
        allow: "757",
        relation: [
          {
            key: "playlist_users_relation",
            allow: "700"
          },
          {
            key: "users",
            allow: "500"
          },
          {
            key: "metadata_playlist_relation",
            allow: "500"
          }
        ]
      },
      {
        key: "playlist_title",
        name: "Title",
        allow: "500",
        relation: [
          {
            key: "language",
            allow: "400"
          }
        ]
      },
      {
        key: "playlist_url",
        name: "URL",
        allow: "500",
        relation: []
      }
    ]
  },
  Company: {
    name: "Company",
    key: "company",
    tables: [
      {
        key: "company",
        name: "Company",
        allow: "440",
        relation: [
          {
            key: "company_self_relation",
            allow: "400"
          },
          {
            key: "company_users_relation",
            allow: "400"
          }
        ]
      },
      {
        key: "company_title",
        name: "Title",
        allow: "400",
        relation: null
      }
    ]
  },
  Langauge: {
    name: "Language",
    key: "language",
    tables: [
      {
        key: "language",
        name: "Language",
        allow: "400",
        relation: null
      },
      {
        key: "language_title",
        name: "Title",
        allow: "400",
        relation: null
      }
    ]
  },
  Admin: {
    name: "Admin",
    key: "admin",
    tables: [
      {
        key: "create_account",
        name: "Create Account",
        allow: "000",
        relation: null
      },
      {
        key: "auth_grant",
        name: "Auth Grant",
        allow: "000",
        relation: null
      },
      {
        key: "arbeit_status",
        name: "Arbeit Status",
        allow: "000",
        relation: null
      },
      {
        key: "row_record",
        name: "Record Manage",
        allow: "000",
        relation: null
      },
      {
        key: "statistics",
        name: "Statistics",
        allow: "000",
        relation: null
      }
    ]
  }
};
