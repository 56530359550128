import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useAsyncEffect } from "lib/use-async-effect";
import {
  UNIT,
  PADDING_XX_LARGE_PX,
  pixelize,
  MARGING_LARGE_PX,
  PADDING_X_LARGE_PX,
  MARGING_X_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_XX_LARGE_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { AnimatedProgress } from "App/Atomics/AnimatedProgress";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import { WHITE, GRAY_4 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { useCsvUploadStore } from "../../Store";
import { CsvUploadActions } from "../../Store/CsvUpload";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { Confirm } from "App/Molecules/Confirm";
import { s3SingleUpload } from "App/Routes/SingleAlbumCreate/Query/s3SingleUpload";
import { CreateCsvAlbum, CreateCsvTrack, UpdateMetadataUrl } from "GraphQL/Queries/Track/index";
import { CheckExistAlbum, CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

type Props = {
  onClick: () => void;
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${pixelize(32 * UNIT)};
  height: ${pixelize(12 * UNIT)};
  padding: ${PADDING_XX_LARGE_PX};
`;

const Header = styled.div`
  width: 100%;
  height: ${pixelize(3.5 * UNIT)};
  padding: ${PADDING_X_LARGE_PX};
  background-color: ${PRIMARY_COLOR};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    color: ${WHITE};
  }

  .cancelIcon {
    fill: ${WHITE};
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      fill: ${GRAY_4};
    }
    margin-right: ${MARGING_SMALL_PX};
  }
`;

const AlertSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${DANGER_COLOR_LIGHT};
  font-size: 0.8rem;
  text-align: center;
  margin-top: ${MARGING_XX_LARGE_PX};
  margin-top: ${MARGING_X_LARGE_PX};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: ${MARGING_LARGE_PX};
  padding: ${PADDING_X_LARGE_PX} ${PADDING_XX_LARGE_PX};
`;

export const CreateAlbumsModal = ({ onClick }: Props) => {
  const [{ taskInfo }, dispatch] = useCsvUploadStore(store => ({
    taskInfo: store.CsvUpload.taskInfo
  }));
  const [total, setTotal] = useState(0);
  const [failed, setFailed] = useState(false);
  const [percent, setPercent] = useState(0);
  const [count, setCount] = useState(0);
  const [alertMsg, setAlertMsg] = useState<string>("");
  const alertModal = useToggle();

  const albumFormData = (singleUpload: any, file: File) => {
    const formData = new FormData();
    formData.append("key", `${singleUpload.key}`);
    formData.append("Policy", singleUpload.Policy);
    formData.append("X-Amz-Credential", singleUpload.X_Amz_Credential);
    formData.append("X-Amz-Date", singleUpload.X_Amz_Date);
    formData.append("X-Amz-Signature", singleUpload.X_Amz_Signature);
    formData.append("X-Amz-Algorithm", singleUpload.X_Amz_Algorithm);
    formData.append("file", file);

    return formData;
  };

  const asyncUploadMusic = async (track: Url, companyId: string) => {
    const { data: s3Data, errors: s3Errors } = await s3SingleUpload({
      filename: track.url,
      action: "INSERT",
      companyId: companyId
    });
    if (!s3Data || s3Errors) {
      setFailed(true);
      setAlertMsg(s3Errors![0].message);
      return;
    }
    await axios({
      method: "post",
      url: s3Data.singleUpload.url,
      data: albumFormData(s3Data.singleUpload, track.file),
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return s3Data.singleUpload.key;
  };

  const onCreate = async () => {
    if (!taskInfo.length) {
      return;
    }
    const totalLength = taskInfo.length + taskInfo.map(({ trackInfo }) => trackInfo.length).reduce((acc, cur) => acc + cur);
    setCount(0);
    setFailed(false);
    setTotal(taskInfo[0].isDone ? totalLength - 1 : totalLength);
    for (const album of taskInfo) {
      try {
        const { data: checkData, errors: checkError } = await CheckExistAlbum({ no: album.no, title: album.albumTitle });
        if (!checkData || checkError) {
          setFailed(true);
          setAlertMsg(checkError![0].message);
          return;
        }
        let structureId;
        let existTracksNo: string[] = [];
        if (checkData.metadata.length && checkData.metadata[0].structure.length) {
          structureId = checkData.metadata[0].structure[0].id;
          existTracksNo = !checkData.metadata[0].structure[0].list.length
            ? []
            : checkData.metadata[0].structure[0].list.map(({ metadata }) => metadata[0].no);
        } else {
          let coverData;
          let coverUrl;
          if (album.albumUrl && album.albumUrl.file) {
            coverUrl = {
              file: album.albumUrl.file,
              ext: album.albumUrl.ext,
              typeUrl: album.albumUrl.typeUrl,
              data: album.albumUrl.data,
              url: `${album.no}/cover/album_cover.${album.albumUrl.ext}`
            } as Url;

            const { data: s3Data, errors: s3Errors } = await s3SingleUpload({
              filename: coverUrl.url,
              action: "INSERT",
              companyId: album.rightsCompany.company_id
            });
            if (!s3Data || s3Errors) {
              setFailed(true);
              setAlertMsg(s3Errors![0].message);
              return;
            }
            coverData = s3Data.singleUpload;
          }
          const { data: albumData, errors: albumErrors } = await CreateCsvAlbum({
            no: album.no,
            title: album.albumTitle,
            class: "record",
            subClass: "album",
            albumArtist: album.albumArtist!,
            companyId: album.rightsCompany.company_id,
            url: coverData?.key,
            productions: album.productions,
            publications: album.publications
          });
          if (!albumData || albumErrors) {
            setFailed(true);
            setAlertMsg(albumErrors![0].message);
            return;
          }
          structureId = albumData.createAlbum.metadata_structure[0].structure_id;
          if (coverData && coverUrl) {
            await axios({
              method: "post",
              url: coverData.url,
              data: albumFormData(coverData, coverUrl.file),
              headers: {
                "Content-Type": "image/jpeg"
              }
            });
          }
          // ? ALBUM SUCCESS
          dispatch(CsvUploadActions.setCsvAlbumDone(album.no));
          setCount(prevCount => prevCount + 1);
        }
        for (const track of album.trackInfo) {
          if (
            !track.trackUrl.trackMp3.url &&
            !track.trackUrl.trackAac.url &&
            !track.trackUrl.trackFlac.url &&
            !track.trackUrl.trackWav.url
          ) {
            window.alert("음원을 찾을 수 없습니다.");
            setFailed(true);
            return;
          }
          // TODO: 이미 앨범 내에 존재하는 트랙이라면, 생성하지 않음.
          if (!existTracksNo.includes(track.trackNo)) {
            let fixedTrackUrl = {
              trackMp3: {
                ...track.trackUrl.trackMp3,
                url: `${album.no}/${track.trackUrl.trackMp3.typeUrl}/${track.trackNo}.${track.trackUrl.trackMp3.ext}`
              } as Url,
              trackAac: {
                ...track.trackUrl.trackAac,
                url: `${album.no}/${track.trackUrl.trackAac.typeUrl}/${track.trackNo}.${track.trackUrl.trackAac.ext}`
              } as Url,
              trackFlac: {
                ...track.trackUrl.trackFlac,
                url: `${album.no}/${track.trackUrl.trackFlac.typeUrl}/${track.trackNo}.${track.trackUrl.trackFlac.ext}`
              } as Url,
              trackWav: {
                ...track.trackUrl.trackWav,
                url: `${album.no}/${track.trackUrl.trackWav.typeUrl}/${track.trackNo}.${track.trackUrl.trackWav.ext}`
              } as Url
            };
            const { data: trackData, errors: trackError } = await CreateCsvTrack({
              no: track.trackNo,
              title: track.trackName,
              class: "record",
              subClass: "track",
              trackArtist: track.trackArtist!,
              companyId: album.rightsCompany.company_id,
              productions: album.productions,
              publications: album.publications,
              genre: track.trackGenre,
              work: track.work,
              trackTitle: track.trackTitle,
              parentId: +structureId,
              notice: track.trackLicense.notice,
              typeTrack: track.trackLicense.type_track,
              countryCode: track.trackLicense.country_code,
              publishDate: track.trackLicense.publish_date,
              mp3Url: track.trackUrl.trackMp3.url!,
              aacUrl: track.trackUrl.trackAac.url,
              flacUrl: track.trackUrl.trackFlac.url,
              wavUrl: track.trackUrl.trackWav.url,
              recordYear: !track.trackLicenseExtra.record_year ? undefined : +track.trackLicenseExtra.record_year,
              publishYear: !track.trackLicenseExtra.publish_year ? undefined : +track.trackLicenseExtra.publish_year,
              firstEdition: !track.trackLicenseExtra.first_edition ? undefined : +track.trackLicenseExtra.first_edition
            });

            if (!trackData || trackError) {
              setFailed(true);
              setAlertMsg(trackError![0].message);
              return;
            }
            if (track.trackUrl.trackMp3.url || track.trackUrl.trackAac.url || track.trackUrl.trackFlac.url || track.trackUrl.trackWav.url) {
              const { metadata_id, metadata_url } = trackData.createTrack;
              const mp3List = metadata_url.filter(({ type_url }) => type_url === "mp3high");
              const aacList = metadata_url.filter(({ type_url }) => type_url === "aac");
              const flacList = metadata_url.filter(({ type_url }) => type_url === "flac");
              const wavList = metadata_url.filter(({ type_url }) => type_url === "wav");

              const { data: accessData } = await CreateAccessRecord({ targetId: metadata_id, targetTable: TargetTableInput.Metadata });
              if (!accessData) {
                setFailed(true);
                return;
              }
              if (track.trackUrl.trackMp3.url && fixedTrackUrl.trackMp3.url && mp3List) {
                const uploadKey = await asyncUploadMusic(fixedTrackUrl.trackMp3, album.rightsCompany.company_id);
                if (uploadKey) {
                  await UpdateMetadataUrl({ uuid: mp3List[0].id, url: uploadKey });
                }
              }
              if (track.trackUrl.trackAac.url && fixedTrackUrl.trackAac.url && aacList) {
                const uploadKey = await asyncUploadMusic(fixedTrackUrl.trackAac, album.rightsCompany.company_id);
                if (uploadKey) {
                  await UpdateMetadataUrl({ uuid: aacList[0].id, url: uploadKey });
                }
              }
              if (track.trackUrl.trackFlac.url && fixedTrackUrl.trackFlac.url && flacList) {
                const uploadKey = await asyncUploadMusic(fixedTrackUrl.trackFlac, album.rightsCompany.company_id);
                if (uploadKey) {
                  await UpdateMetadataUrl({ uuid: flacList[0].id, url: uploadKey });
                }
              }
              if (track.trackUrl.trackWav.url && fixedTrackUrl.trackWav.url && wavList) {
                const uploadKey = await asyncUploadMusic(fixedTrackUrl.trackWav, album.rightsCompany.company_id);
                if (uploadKey) {
                  await UpdateMetadataUrl({ uuid: wavList[0].id, url: uploadKey });
                }
              }
              await DeleteAccessRecord({ id: accessData.createAccess.id });
            }
            dispatch(CsvUploadActions.shiftCsvTrackTask());
            setCount(prevCount => prevCount + 1);
          }
        }
        dispatch(CsvUploadActions.shiftCsvAlbumTask());
      } catch (e) {
        console.log(e);
        setFailed(true);
        setAlertMsg("앨범 생성에 실패하였습니다.");
        alertModal.on();
        return;
      }
    }
    setPercent(100);
  };

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      await onCreate();
    }
  }, []);

  const closeModal = () => {
    onClick();
  };
  return (
    <>
      <Header>
        <h4>앨범 생성</h4>
        <CancelIcon className="cancelIcon" onClick={closeModal} />
      </Header>
      <Layout>
        <AnimatedProgress percent={percent !== 100 ? (count / total) * 100 : percent} count={count} total={total} />
        <AlertSpan>생성 중에 창을 종료하지 마십시오. 강제로 종료할 경우, 데이터가 손실될 수 있습니다.</AlertSpan>
      </Layout>
      <ButtonContainer>
        <Input.Button color="danger" disabled={percent < 100} onClick={() => window.location.reload()}>
          종료
        </Input.Button>
        <Input.Button color="warning" disabled={!failed} onClick={onCreate}>
          재시도
        </Input.Button>
        <Input.Button
          color="secondary"
          disabled={!failed}
          onClick={() => {
            dispatch(CsvUploadActions.setCsvAlbumInfo(taskInfo));
            onClick();
          }}
        >
          수정
        </Input.Button>
      </ButtonContainer>
      <Modal isOpen={alertModal.isToggled}>
        <Confirm
          title="알림"
          context={alertMsg}
          toClose={() => {
            alertModal.off();
          }}
        />
      </Modal>
    </>
  );
};
