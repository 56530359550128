import axios from "axios";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreateCommodityUrl, DeleteAccessRecord, LoadGoodsCompanyId, UploadFile } from "GraphQL/Queries";
import { createFormData } from "lib/createFormData";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { useProductCreateSelector } from "../Store";
import { Url } from "../Store/ProductCreate";

export const useCreateImage = () => {
  const { urls } = useProductCreateSelector(store => store.ProductCreate.product);
  const createImage = async (goodsId: string, productId: string) => {
    const companyId = await LoadGoodsCompanyId({ id: goodsId });
    const accessId = await requestAccessRecord({ targetId: productId.toString(), targetTable: TargetTableInput.Product });
    if (!accessId) {
      Toast.error("이미지 등록에 실패하였습니다.");
      return;
    }
    const coverSet = await uploadImage(productId, companyId, urls);
    await CreateCommodityUrl({ id: productId, urls: Array.from(coverSet) });
    await DeleteAccessRecord({ id: accessId });
  };
  return { createImage };
};

const uploadImage = async (commodityId: string, companyId: string, urls: Map<string, Url>) => {
  const result = new Set<string>();
  try {
    for (const [, { id, typeUrl, file, ext }] of urls) {
      const { data } = await UploadFile({ filename: `goods/commodity/${commodityId}_${id}.${ext}`, companyId });

      if (!data) {
        throw new Error();
      }
      const bucket = data.uploadFile;
      await axios({
        method: "post",
        url: bucket.url,
        data: createFormData(bucket, file),
        headers: {
          "Content-Type": typeUrl
        }
      });
      result.add(bucket.key);
    }
    return result;
  } catch (err) {
    console.log(err);
    Toast.error("이미지 업로드에 실패하였습니다.");
    return result;
  }
};
