/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar } from "GraphQL/Scalars";
import { QualityOfCommodity, QualityOfCommodityScalar } from "GraphQL/Scalars/QualityOfCommodityScalar";

type Option = Readonly<{
  goodsId: string;
  price: number;
  value: string;
  quality: string;
  subdatas: string[];
  createdAt: string;
}>;

export const CreateCommodity = async (option: Option) => {
  const $goodsId = <Variable name="goodsId" scalar={<IdScalar isNullable={false} />} value={option.goodsId} />;
  const $price = <Variable name="price" scalar={<IntScalar isNullable={false} />} value={option.price} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $quality = (
    <Variable name="quality" scalar={<QualityOfCommodityScalar isNullable={false} />} value={option.quality as QualityOfCommodity} />
  );
  const $createdAt = <Variable name="createdAt" scalar={<DateTimeScalar isNullable={false} />} value={option.createdAt} />;

  const { query, variables } = render(
    <Mutation operationName="CREATE_COMMODITY">
      <SelectionSet
        name="createCommodity"
        argument={{
          data: {
            goods: {
              connect: {
                goods_id: $goodsId
              }
            },
            quality: $quality,
            price: $price,
            value: $value,
            created_at: $createdAt,
            commodity_goods_subdata_unique_relation: {
              create: [...(option.subdatas?.map(id => ({ goods_subdata_unique: { connect: { id } } })) ?? [])]
            }
          }
        }}
      >
        <Field name="commodity_id" alias="id" />
        <Field name="goods_id" alias="goodsId" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createCommodity: Readonly<{
      id: string;
      goodsId: string;
    }>;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
