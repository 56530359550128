/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id?: string;
  typeTitle?: string | null;
  languageCode?: string | null;
}>;

export type Title = {
  id: string;
  order: number;
  typeTitle: string;
  value: string;
  language: {
    languageCode: string;
    name: string;
  }[];
};

export const GetGenreTitleRelation = async (option: Option) => {
  const $id = <Variable name="genre_id" scalar={<IdScalar />} value={option.id} />;
  const $typeTitle = <Variable name="type_title" scalar={<StringScalar isNullable />} value={option.typeTitle} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar isNullable />} value={option.languageCode} />;
  const { query, variables } = render(
    <Query operationName="GET_GENRE_TITLE_RELATION">
      <SelectionSet
        name="genre"
        argument={{
          first: 9999,
          group: true,
          where: {
            genre_id: $id,
            genre_title__some: {
              type_genre_title: $typeTitle,
              language__some: {
                language_code: $languageCode
              }
            }
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <Field name="name" />
        <SelectionSet
          name="genre_title"
          alias="titleRelation"
          argument={{ first: 999, where: { type_genre_title__not_starts_with: "search" } }}
        >
          <Field name="id" />
          <Field name="exposure_order" alias="order" />
          <Field name="type_genre_title" alias="typeTitle" />
          <Field name="value" />
          <SelectionSet name="language">
            <Field name="language_code" alias="languageCode" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    genre: Readonly<{
      id: string;
      name: string;
      titleRelation: Title[];
    }>[];
  }>;
  return await clients.genre.request<Data>(query, variables);
};
