/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IntScalar, FloatScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { GenderTypeScalar, UuidScalar } from "GraphQL/Scalars";
import { GenderType } from "constants/GenderType";

type Option = Readonly<{
  uuid: string;
  channel?: number;
  duration?: number;
  bitrate?: number;
  tempo?: number;
  tonality?: string;
  timeSignature?: string;
  voiceGender?: GenderType;
  extraData?: string;
}>;

export const UpdateTrackInformation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $channel = <Variable name="channel" scalar={<IntScalar isNullable />} value={option.channel} />;
  const $duration = <Variable name="duration" scalar={<IntScalar isNullable />} value={option.duration} />;
  const $bitrate = <Variable name="bitrate" scalar={<IntScalar isNullable />} value={option.bitrate} />;
  const $tempo = <Variable name="tempo" scalar={<FloatScalar isNullable />} value={option.tempo} />;
  const $tonality = <Variable name="tonality" scalar={<StringScalar isNullable />} value={option.tonality} />;
  const $timeSignature = <Variable name="time_signature" scalar={<StringScalar isNullable />} value={option.timeSignature} />;
  const $voiceGender = <Variable name="voice_gender" scalar={<GenderTypeScalar isNullable />} value={option.voiceGender} />;
  const $extraData = <Variable name="extra_data" scalar={<StringScalar isNullable />} value={option.extraData} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_TRACK_INFORMATION">
      <SelectionSet
        name="updateTrack_information"
        alias="updateTrackInformation"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            channel: $channel,
            duration: $duration,
            sample_bitrate: $bitrate,
            tempo: $tempo,
            tonality: $tonality,
            time_signature: $timeSignature,
            voice_gender: $voiceGender,
            extra_data: $extraData
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateTrackInformation: Readonly<{
      id: string;
    }>[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.updateTrackInformation;
};
