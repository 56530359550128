import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsDetail/Style";
import { DANGER_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions, GoodsParent as TypeGoodsParent } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { v4 as uuidv4 } from "uuid";
import { GoodsTextAutoComplete } from "App/Molecules/AutoCompletes/Goods";

type Props = {
  readonly parents: TypeGoodsParent[];
};

export const GoodsParent = ({ parents = [] }: Props): ReactElement => {
  const onAddParent = () => {
    const tempId = uuidv4();
    GoodsDetailStore.dispatch(GoodsDetailActions.addParent(tempId));
  };
  const onUpdateParent = (index: number, id: string, name: string, parentId: number) =>
    GoodsDetailStore.dispatch(GoodsDetailActions.updateParentGoods({ index, id, name, parentId }));
  const onRemoveParent = (index: number) => GoodsDetailStore.dispatch(GoodsDetailActions.removeParent(index));
  return (
    <Style.Row>
      <Style.Caption>패키지</Style.Caption>
      <Style.ColContainer>
        {parents.map((parent, index) => (
          <Style.RowContainer key={parent.id}>
            <Style.RoundButton color={DANGER_COLOR_LIGHT} onClick={() => onRemoveParent(index)}>
              -
            </Style.RoundButton>
            <GoodsTextAutoComplete
              type="Package"
              style={SelectStyle}
              defaultValue={!parent?.goods?.id ? undefined : { id: parent.goods.id, name: parent.goods.name }}
              onBlur={info => {
                info && onUpdateParent(index, info.id, info.name, +info.extra.structureId);
              }}
            />
          </Style.RowContainer>
        ))}
        <Style.RowContainer>
          <Style.RoundButton color={PRIMARY_COLOR_LIGHT} onClick={onAddParent}>
            +
          </Style.RoundButton>
        </Style.RowContainer>
      </Style.ColContainer>
    </Style.Row>
  );
};
