import React, { ReactElement, useMemo, useState } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { useAsyncEffect } from "lib/use-async-effect";
import { LoadGoodsSubtitle } from "./findGoodsSubtitleList";
import { Info } from "App/Atomics/Input/Select/CreatableTextSelect";

type Props = {
  readonly subtitle: string;
};

export const GoodsSubTitleSelect = ({ subtitle }: Props): ReactElement | null => {
  const [subtitles, setSubtitles] = useState<Info[]>([]);
  const defaultValue = useMemo(() => subtitles.find(item => item.name === subtitle), [subtitle, subtitles]);
  useAsyncEffect(async () => {
    const { data } = await LoadGoodsSubtitle({ first: 999 });
    if (data) {
      const list = data.items.map(({ value }) => ({ id: value, name: value }));
      setSubtitles(list);
    }
  }, []);
  if (!subtitles.length) return null;
  return (
    <Style.CreatableSelect
      placeholder={"서브제목"}
      name="subtitle"
      optionList={subtitles}
      defaultValue={defaultValue}
      onBlur={value => {
        console.log({ value });
        GoodsCreateStore.dispatch(GoodsCreateActions.setSubtitle(value?.name ?? ""));
      }}
    />
  );
};
