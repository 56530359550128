import React, { ReactNode } from "react";
import styled from "styled-components";

import { GRAY_5 } from "constants/baseColor";

type Props = Styleable & Readonly<{ children: ReactNode }>;

const Layout = styled.tbody`
  overflow: scroll;
  overflow: overlay;

  tr {
    border-bottom: 1px solid ${GRAY_5};
  }

  th,
  td {
    vertical-align: middle;
  }
`;

export const Body = ({ children, style, className }: Props) => (
  <Layout style={style} className={className}>
    {children}
  </Layout>
);
