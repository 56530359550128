/** @jsx jsx */

import { jsx, render, Variable, SelectionSet, StringScalar, Field, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { FileActionScalar } from "GraphQL/Scalars/FileActionScalar";
import { PathTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  filename?: string;
  companyId?: string;
  path?: string;
  action: string;
}>;

export type SingleUpload = {
  key: string;
  url: string;
  bucket: string;
  X_Amz_Date: string;
  X_Amz_Signature: string;
  X_Amz_Credential: string;
  X_Amz_Algorithm: string;
  Policy: string;
  invalidateToken: string;
};

export const s3SingleUpload = async (option: Option) => {
  const $filename = <Variable name="filename" scalar={<StringScalar isNullable={false} />} value={option.filename} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar isNullable={true} />} value={option.companyId} />;
  const $action = <Variable name="action" scalar={<FileActionScalar isNullable={false} />} value={option.action} />;
  const $path = <Variable name="path" scalar={<PathTypeScalar isNullable={true} />} value={option.path} />;
  const { query, variables } = render(
    <Mutation operationName="S3_UPLOAD_REQUIRED">
      <SelectionSet
        name="singleUpload"
        argument={{
          where: { filename: $filename, action: $action, company_id: $companyId, path: $path }
        }}
      >
        <Field name="key" />
        <Field name="url" />
        <Field name="bucket" />
        <Field name="X_Amz_Date" />
        <Field name="X_Amz_Signature" />
        <Field name="X_Amz_Credential" />
        <Field name="X_Amz_Algorithm" />
        <Field name="Policy" />
        <Field name="invalidateToken" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    singleUpload: SingleUpload;
  }>;
  return await clients.access.request<Data>(query, variables);
};
