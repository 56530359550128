import React from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { ColumnProps } from "App/Templates/TableTemplate";

const Layout = styled(Table.Row)``;

const RowData = styled(Table.Data)``;

export const DataTemplate = ({ index, data: { csv, fields } }: ColumnProps<any>) => {
  return (
    <Layout key={index}>
      {fields.map((field: string, i: number) => (
        <RowData key={i}>{csv[field] as string}</RowData>
      ))}
    </Layout>
  );
};
