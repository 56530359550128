import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_X_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { GuideTooltip } from "App/Molecules/AnimatedTooltips";
import { WORKS_CREATE } from "constants/route";
import { WorkTagAutoComplete } from "App/Molecules/AutoCompletes/Work";
import { PRIMARY_COLOR } from "constants/color";

type Work = Readonly<{
  id: string;
  name: string;
  order?: number;
}>;

type Props = Readonly<{
  work: Work[] | undefined;
  onClick: (work: Work[] | undefined) => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 55)};
  height: ${pixelize(UNIT * 32.5)};
  padding: ${PADDING_XX_LARGE_PX};

  section {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: ${MARGING_X_LARGE_PX};

  h3 {
    font-size: 1.2rem;
  }
`;

const WorkPageLink = styled.a`
  margin-left: auto;
  width: fit-content;
  font-size: 0.9rem;
  color: ${PRIMARY_COLOR};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const WorkModal = ({ work: initWork, onClick }: Props) => {
  const [suggest, setSuggest] = useState(initWork ? initWork : ([] as Work[]));

  const toSave = () => onClick(suggest);
  const toCancel = () => onClick(undefined);

  return (
    <Layout>
      <Title>
        <h3>작품 검색</h3>
        <GuideTooltip text="작품을 먼저 등록하시고, 검색을 해주셔야 합니다." direction="right" />
      </Title>
      <Title>
        <span style={{ color: "skyblue" }}>tip: (작품 이름)+(아티스트 이름) 으로 검색하실 수 있습니다.</span>
      </Title>
      <section>
        <WorkTagAutoComplete
          subClass="track"
          onBlur={info => {
            if (info) {
              setSuggest(info.map(({ id, name }) => ({ id, name })));
            }
          }}
        />
      </section>
      <WorkPageLink href={WORKS_CREATE} target="_blank">
        작품 등록하러 가기
      </WorkPageLink>
      <ButtonGroup>
        <Input.Button onClick={toSave}>입력</Input.Button>
        <Input.Button onClick={toCancel}>취소</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
