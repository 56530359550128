import React from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { useAppStore } from "App/Store";
import { ColumnProps, TableTemplate } from "App/Templates/TableTemplate";
import { PRIMARY_COLOR } from "constants/color";
import {
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  MARGING_X_LARGE_PX,
  PADDING_XXX_LARGE_PX,
  MARGING_XX_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_XX_LARGE_PX,
  PADDING_XX_LARGE
} from "constants/size";
import { useAsyncEffect } from "lib/use-async-effect";
import { Divider } from "App/Atomics/Divider";
import { GetAccount } from "GraphQL/Queries";
import { Account, BillInfo } from "GraphQL/Queries/Access/GetAccount";
import { Info } from "App/Atomics/Input/Select/TextSelect";
import { Input } from "App/Atomics/Input";
import { GRAY_7 } from "constants/baseColor";
import { LoadingAction } from "App/Store/Loading";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { mediaQuery } from "constants/media";
import { useSettleStore } from "../../Store";
import { SettleActions } from "../../Store/Settle";
import { convertNumberToMoney } from "lib/convertNumberToMoney";
import { useHistory } from "react-router";
import { SETTLEMENT, SETTLEMENT_DETAIL } from "constants/route";
import dayjs from "dayjs";

const SettleLayout = styled.div`
  margin: 2rem;
  min-height: 84vh;
  padding: ${PADDING_XXX_LARGE_PX};
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  h2 {
    margin-top: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  .cursor-table {
    tr {
      cursor: pointer;
    }
  }

  ${mediaQuery(1200)} {
    padding: ${pixelize(PADDING_XX_LARGE * 1.2)};
  }

  ${mediaQuery(768)} {
    padding: ${PADDING_XX_LARGE_PX};
  }
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const Heading3 = styled.h3`
  margin-top: ${MARGING_XX_LARGE_PX};
  margin-bottom: ${MARGING_LARGE_PX};

  span {
    margin-left: ${MARGING_SMALL_PX};
    font-size: 0.85em;
    font-weight: normal;
    color: ${PRIMARY_COLOR};
  }
`;

const DateField = styled.div`
  display: flex;
  margin-bottom: ${MARGING_LARGE_PX};
  align-items: center;
`;

const DateSelect = styled(Input.TextSelect)`
  min-width: ${pixelize(UNIT * 7)};

  & > div {
    border-radius: 0px;
  }
`;

const NoData = styled.div`
  width: 100%;
  padding: ${PADDING_XX_LARGE_PX} 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${GRAY_7};
`;

export const SettleMonthly = () => {
  const [{ dateInfo, account, companyInfo }, dispatch] = useSettleStore(store => ({
    dateInfo: store.Settle.dateInfo,
    account: store.Settle.account,
    companyInfo: store.Settle.companyInfo
  }));
  const [{ loading }, dispatchApp] = useAppStore(store => ({
    loading: store.Loading.loading
  }));
  const router = useHistory();
  const date = new Date();
  const year = dateInfo.fromDate.slice(0, 4);
  const month = parseInt(dateInfo.fromDate.slice(5, 7));
  const yearList = [] as Info[];
  const monthList = [] as Info[];
  const fullDate = new Date(`${year}-${month}-01`);
  for (let month = 1; month <= 12; month++) {
    monthList.push({ name: month.toString() + "월", id: month.toString() });
  }

  for (let year = 2019; year <= date.getUTCFullYear(); year++) {
    yearList.push({ name: year.toString() + "년", id: year.toString() });
  }

  const onClickRow = (billInfo: BillInfo) => {
    dispatch(SettleActions.setSettleBillInfo(billInfo));
    router.push(SETTLEMENT_DETAIL);
  };
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          const defaultDate = dayjs().format("YYYY-MM-DD");
          console.log(1, dateInfo?.fromDate, defaultDate);
          dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
          console.log(2, defaultDate);
          const userId = companyInfo.users ? companyInfo.users[0].id : undefined;
          console.log(3, defaultDate);
          const { data } = await GetAccount({
            id: userId,
            fromDate: dateInfo?.fromDate ?? defaultDate,
            toDate: dateInfo?.toDate ?? defaultDate,
            visibleBill: true
          });
          if (data) {
            dispatch(SettleActions.setSettleAccount(data.getAccount));
            dispatchApp(LoadingAction.setLoading(LOADING.ONLOAD));
          }
        } catch (err) {
          console.log(err);
          return;
        }
      }
    },
    [dateInfo]
  );
  const totalHeadList = [{ name: "수익" }, { name: "지급 금액" }, { name: "미지급 금액" }];
  const totalTemplate = ({ data }: ColumnProps<Account>) => {
    const { totalAmount } = data;
    const result = convertNumberToMoney(totalAmount);
    return (
      <Table.Row>
        <AlignCenterData>{`${result}원`}</AlignCenterData>
        <AlignCenterData>{`${result}원`}</AlignCenterData>
        <AlignCenterData>{`${0}원`}</AlignCenterData>
      </Table.Row>
    );
  };

  const serviceHeadList = [{ name: "#" }, { name: "서비스" }, { name: "상품명" }, { name: "통화" }, { name: "정산 금액" }];
  const serviceTemplate = ({ data, index }: ColumnProps<BillInfo>) => {
    const { service, product, resultAmount, currency } = data;
    const result = convertNumberToMoney(resultAmount);
    return (
      <Table.Row onClick={() => onClickRow(data as BillInfo)}>
        <AlignCenterData>{index + 1}</AlignCenterData>
        <AlignCenterData>{service === "CM" ? "Classic Manager" : service === "M4S" ? "Music For Shop" : service}</AlignCenterData>
        <Table.Data>{product}</Table.Data>
        <AlignCenterData>{currency === "KRW" ? "국내" : "국외"}</AlignCenterData>
        <AlignCenterData>{`${result}원`}</AlignCenterData>
      </Table.Row>
    );
  };

  return (
    <SettleLayout>
      <Header>
        <h1>월별 정산 내역</h1>
        <Input.Button color="primary" onClick={() => router.push(SETTLEMENT)}>
          이전 페이지
        </Input.Button>
      </Header>
      <Divider />
      <DateField>
        <DateSelect
          key={year}
          className="dateSelect"
          optionList={yearList}
          defaultValue={
            fullDate
              ? {
                  id: fullDate.getFullYear().toString(),
                  name: `${fullDate.getFullYear().toString()}년`
                }
              : yearList[0]
          }
          onChange={info => {
            if (info) {
              const month = dateInfo.fromDate.toString().slice(5, 7);
              const fullDate = new Date(parseInt(info.id), parseInt(month), 0);
              const fromDate = `${info.id}-${month}-01`;
              const toDate = `${info.id}-${month}-${fullDate.getDate().toString()}`;
              dispatch(SettleActions.setSettleDateInfo({ fromDate, toDate }));
            }
          }}
        />
        <DateSelect
          key={month}
          className="dateSelect"
          optionList={monthList}
          defaultValue={
            fullDate
              ? {
                  id: (fullDate.getMonth() + 1).toString(),
                  name: (fullDate.getMonth() + 1).toString() + "월"
                }
              : monthList[date.getMonth() ? date.getMonth() - 1 : 11]
          }
          onChange={info => {
            if (info) {
              const year = dateInfo.fromDate.toString().slice(0, 4);
              const fullDate = new Date(parseInt(year), parseInt(info.id), 0);
              const month = fullDate.getMonth() + 1;
              const fromDate = `${year}-${month < 10 ? `0${month}` : month}-01`;
              const toDate = `${year}-${month < 10 ? `0${month}` : month}-${fullDate.getDate().toString()}`;
              dispatch(SettleActions.setSettleDateInfo({ fromDate, toDate }));
            }
          }}
        />
      </DateField>
      {loading === LOADING.UNLOAD ? (
        <LoadingTemplate loading={loading} />
      ) : (
        <>
          <Heading3>{`${year}년 ${month}월 정산 내역`}</Heading3>
          <TableTemplate headList={totalHeadList} keyBy={(data, index) => index} dataList={account} Column={totalTemplate} />
          {account.length ? null : <NoData>내역이 없습니다.</NoData>}
          <Heading3>
            서비스별 상세 내역 <span>(선택하시면 상세정보를 확인할 수 있습니다.)</span>
          </Heading3>
          <TableTemplate
            className="cursor-table"
            headList={serviceHeadList}
            keyBy={(data, index) => index}
            dataList={!account.length ? [] : account[0].bill?.info} // TODO: 마스터 일 경우 account가 여러개 일 수 있음. (필요하다면 수정)
            Column={serviceTemplate}
          />
          {!account.length || !account[0].bill?.info.length ? <NoData>내역이 없습니다.</NoData> : null}
        </>
      )}
    </SettleLayout>
  );
};
