/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { TargetTableInput } from "constants/TargetTableInput";

type Props = Readonly<{
  value?: TargetTableInput;
  isNullable?: boolean;
}>;

const items = [
  TargetTableInput.Goods,
  TargetTableInput.Metadata,
  TargetTableInput.Artist,
  TargetTableInput.Genre,
  TargetTableInput.Role,
  TargetTableInput.Company,
  TargetTableInput.Users,
  TargetTableInput.Playlist,
  TargetTableInput.Language,
  TargetTableInput.MetadataPlaylistRelation,
  TargetTableInput.Product
];

export const TargetTableInputScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="targetTableInPut" value={value} isNullable={isNullable} items={items} />
);
