import React, { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { DANGER_COLOR, DEFAULT_BORDER_COLOR, WARNING_COLOR } from "constants/color";
import { Input } from "App/Atomics/Input";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import dayjs from "dayjs";
import { pixelize, UNIT } from "constants/size";
import { useEffectCreateStore } from "../../Store";
import { EffectCreateActions, Effect, Url } from "../../Store/Effect";
import uuidv4 from "uuid/v4";
import { YELLO_4 } from "constants/baseColor";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { useToggle } from "lib/use-toggle";
import { Toast } from "lib/toast";
import { CreateTrackModal } from "../../Modals/CreateTrackModal";
import { UploadEffectModal } from "../../Modals";
import { UpdateTrackModal } from "../../Modals/UpdateTrackModal";
import { EffectStyle } from "../../style";

export const EffectCreateForm = () => {
  const [{ album, tracks }, dispatch] = useEffectCreateStore(store => ({
    album: store.EffectCreate.album,
    tracks: store.EffectCreate.tracks
  }));
  const [updateIndex, setUpdateIndex] = useState<number | undefined>(undefined);
  const confirmModal = useToggle();
  const [albumTitle, setAlbumTitle] = useState<string | undefined>(undefined);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
  const createTrackModal = useToggle();
  const updateTrackModal = useToggle();
  const uploadEffectModal = useToggle();
  const currentYear = dayjs().format("YYYY");

  const { no, title, rightsCompany, publication, production, albumUrl } = album;

  const setAlbumCover = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        EffectCreateActions.setAlbumCover({
          file: file.files![0],
          value: event.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          name: file.files![0].name,
          valueType: file.files![0].type
        })
      );
    };
  };

  const onGenreateSerialNo = useCallback(() => {
    const uuid = uuidv4();
    dispatch(EffectCreateActions.setAlbumNo(uuid));
  }, [dispatch]);

  const onAlbumInit = useCallback(() => {
    const today = dayjs(Date.now()).toDate();
    dispatch([EffectCreateActions.setAlbumInit(), EffectCreateActions.setAlbumRelease(today)]);
  }, [dispatch]);

  const onCheckRequireFormData = useMemo(() => {
    if (!albumTitle?.length || !no?.length || !rightsCompany?.id || !tracks.length) {
      return true;
    }
    return false;
  }, [albumTitle, no, rightsCompany, tracks.length]);

  const openConfirmModal = () => {
    if (onCheckRequireFormData) {
      Toast.warning("작성되지 않은 필수 정보가 있습니다.", undefined, "bottom-center");
      setShowAlertMessage(true);
    } else {
      confirmModal.on();
    }
  };

  const openCreateTrackModal = () => {
    createTrackModal.on();
  };

  const onCreateEffect = async () => {
    if (albumUrl?.url) {
      dispatch(
        EffectCreateActions.setAlbumCover({
          file: albumUrl.file,
          value: albumUrl.data,
          ext: albumUrl.ext,
          name: `${no}/cover/effect_cover.${albumUrl.ext}`,
          valueType: albumUrl.typeUrl
        })
      );
    }

    tracks.forEach(({ title, trackUrl }, index) => {
      Object.entries(trackUrl).forEach(([key, url]) => {
        if (!url?.url) return;
        switch (key) {
          case "trackMp3":
            dispatch(
              EffectCreateActions.setAlbumEffectTrackUrl({
                index,
                file: trackUrl.trackMp3!.file,
                fileType: trackUrl.trackMp3!.typeUrl,
                data: trackUrl.trackMp3!.data,
                url: `${no}/${trackUrl.trackMp3!.typeUrl}/${index + 1}.${trackUrl.trackMp3!.ext}`
              })
            );
            break;
          case "trackWav":
            dispatch(
              EffectCreateActions.setAlbumEffectTrackUrl({
                index,
                file: trackUrl.trackWav!.file,
                fileType: trackUrl.trackWav!.typeUrl,
                data: trackUrl.trackWav!.data,
                url: `${no}/${trackUrl.trackWav!.typeUrl}/${index + 1}.${trackUrl.trackWav!.ext}`
              })
            );
            break;
        }
      });
    });

    uploadEffectModal.on();
  };

  const onDeleteEffect = (index: number) => {
    if (window.confirm("삭제하시겠습니까?")) {
      dispatch(EffectCreateActions.deleteAlbumEffect(index));
    }
  };

  const openUpdateModal = (index: number) => {
    setUpdateIndex(index);
    updateTrackModal.on();
  };

  const setMultipleTrackFile = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.currentTarget.files;
    if (fileList?.length) {
      for (const file of fileList) {
        const fileReader = new FileReader();
        const fileType = file.type as "audio/mpeg" | "audio/wav";
        fileReader.readAsDataURL(file);
        fileReader.onloadend = e => {
          const trackFile = {
            file: file,
            ext: fileType === "audio/mpeg" ? "mp3" : "wav",
            typeUrl: fileType === "audio/mpeg" ? "mp3high" : "wav",
            url: file.name,
            data: e.target?.result as string
          };
          switch (fileType) {
            case "audio/mpeg":
              const mp3Effect: Effect = {
                tempId: uuidv4(),
                title: file.name,
                trackUrl: {
                  trackMp3: trackFile,
                  trackWav: {} as Url
                },
                genre: [],
                record: currentYear,
                publish: currentYear,
                edition: currentYear
              };
              dispatch(EffectCreateActions.setAlbumEffect(mp3Effect));
              break;
            case "audio/wav":
              const wavEffect: Effect = {
                tempId: uuidv4(),
                title: file.name,
                trackUrl: {
                  trackWav: trackFile,
                  trackMp3: {} as Url
                },
                genre: [],
                record: currentYear,
                publish: currentYear,
                edition: currentYear
              };
              dispatch(EffectCreateActions.setAlbumEffect(wavEffect));
              break;
            default:
              break;
          }
        };
      }
    }
  };

  useEffect(() => {
    onAlbumInit();
    onGenreateSerialNo();
  }, [onAlbumInit, onGenreateSerialNo]);

  return (
    <Layout>
      <div>
        <Content>
          <span className="txt_require">
            <RequireStar />는 <em>필수입력사항</em>입니다.
          </span>
          <ImageContainer>
            {!album.albumUrl ? (
              // eslint-disable-next-line jsx-a11y/accessible-emoji
              <NoImage htmlFor="input-file">이미지를 등록해주세요. 👆🏻</NoImage>
            ) : (
              <label htmlFor="input-file">
                <img src={URL.createObjectURL(album.albumUrl.file)} alt="cover" />
              </label>
            )}
            <input type="file" id="input-file" accept="image/jpeg,image/png" onChange={setAlbumCover} />
          </ImageContainer>
          <BoxForm>
            <span className="album_caption">
              효과음 앨범 제목
              <RequireStar />
            </span>
            <p className="album_notice"></p>
            <InputText
              key={title}
              width={"600"}
              placeholder="제목"
              defaultValue={title}
              onChange={setAlbumTitle}
              onBlur={() => dispatch(EffectCreateActions.setAlbumTitle(albumTitle))}
            />
            <span className={`alert-message ${showAlertMessage && !albumTitle?.length ? "show" : ""}`}>! 제목을 입력해주세요.</span>
          </BoxForm>
          <BoxForm>
            <span className="album_caption">
              시리얼 넘버 (no)
              <RequireStar />
            </span>
            <p className="album_notice">
              자동으로 생성된 시리얼을 사용하시거나, 직접 입력하실 수 있습니다.
              <button className="regen-uuid" onClick={onGenreateSerialNo}>
                재생성
              </button>
            </p>
            <InputText
              key={no}
              width={"600"}
              placeholder="시리얼 넘버"
              defaultValue={no}
              onBlur={val => dispatch(EffectCreateActions.setAlbumNo(val))}
            />
            <span className={`alert-message ${showAlertMessage && !no?.length ? "show" : ""}`}>! 시리얼 넘버를 입력해주세요.</span>
          </BoxForm>
          <BoxForm>
            <span className="album_caption">
              Company
              <RequireStar />
            </span>
            <p className="album_notice">
              권리사 <RequireStar /> (rightsCompany) / 제작사 (production) / 배급사 (publication)
            </p>
            <RowContainer>
              <CompanyTextAutoComplete
                className="autocomplete"
                placeholder="권리사"
                style={selectStyle(300)}
                defaultValue={rightsCompany}
                onChange={info => {
                  if (info) {
                    dispatch(EffectCreateActions.setAlbumRightsCompany(info));
                  }
                }}
              />
              <CompanyTagAutoComplete
                className="autocomplete"
                placeholder="제작사"
                style={selectStyle(300)}
                defaultValue={production}
                onChange={info => {
                  if (info) {
                    const production = info.map(({ id, name }) => ({ id, name }));
                    dispatch(EffectCreateActions.setAlbumProduction(production));
                  }
                }}
              />
              <CompanyTagAutoComplete
                className="autocomplete"
                placeholder="배급사"
                style={selectStyle(300)}
                defaultValue={publication}
                onChange={info => {
                  if (info) {
                    const publication = info.map(({ id, name }) => ({ id, name }));
                    dispatch(EffectCreateActions.setAlbumPublication(publication));
                  }
                }}
              />
            </RowContainer>
            <span className={`alert-message ${showAlertMessage && !rightsCompany?.id ? "show" : ""}`}>! 권리사를 입력해주세요.</span>
          </BoxForm>
          <BoxForm>
            <FileUploadHeader>
              <span className="album_caption">
                음원
                <RequireStar />
              </span>
              <EffectStyle.InputFileForm>
                <input id="upload-multi-mp3" type="file" multiple accept="audio/mpeg, audio/wav" onChange={setMultipleTrackFile} />
                <label htmlFor="upload-multi-mp3">{"간편 등록"}</label>
              </EffectStyle.InputFileForm>
            </FileUploadHeader>
            <p className="album_notice">{"간편 등록의 제목은 파일 이름으로 자동 설정됩니다."}</p>
            {!tracks.length ? (
              <InputButton color="danger" onClick={() => openCreateTrackModal()}>
                +
              </InputButton>
            ) : (
              <>
                {tracks.map(({ title, tempId }, index) => {
                  return (
                    <Fragment key={tempId}>
                      <RowContainer>
                        <span className="track-no">{index + 1}</span>
                        <InputText key={title} width={"600"} placeholder="제목" isDisabled defaultValue={title} />
                        <InputButton color="warning" onClick={() => onDeleteEffect(index)}>
                          -
                        </InputButton>
                        <Input.Button style={{ marginLeft: "4px" }} color="success" onClick={() => openUpdateModal(index)}>
                          수정
                        </Input.Button>
                      </RowContainer>
                      {index === updateIndex && (
                        <Modal isOpen={updateTrackModal.isToggled}>
                          <UpdateTrackModal index={updateIndex} onClose={updateTrackModal.off} />
                        </Modal>
                      )}
                    </Fragment>
                  );
                })}
                <InputButton style={{ marginTop: "8px" }} color="danger" onClick={() => openCreateTrackModal()}>
                  +
                </InputButton>
              </>
            )}
            <span className={`alert-message ${showAlertMessage && !rightsCompany?.id ? "show" : ""}`}>
              ! 음원을 최소 1개 이상 등록해주세요.
            </span>
          </BoxForm>

          <ButtonGroup>
            <button onClick={openConfirmModal}>효과음 생성</button>
          </ButtonGroup>
        </Content>
      </div>
      <Modal isOpen={createTrackModal.isToggled}>
        <CreateTrackModal onClose={createTrackModal.off} />
      </Modal>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm title="알림" context="앨범을 생성하시겠습니까?" toSave={onCreateEffect} toClose={confirmModal.off} />
      </Modal>
      <Modal isOpen={uploadEffectModal.isToggled}>
        <UploadEffectModal onClose={uploadEffectModal.off} />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  width: 940px;
  margin: 0 auto;
  padding-bottom: 160px;
  position: relative;
  .txt_star {
    color: ${DANGER_COLOR};
    font-style: normal;
  }

  .autocomplete {
    margin-top: 4px;
    margin-right: 4px;
  }
`;

const RequireStar = () => <em className="txt_star">*</em>;

const Content = styled.div`
  position: relative;
  .txt_require {
    position: absolute;
    top: 18px;
    right: 18px;

    em {
      color: ${DANGER_COLOR};
      font-style: normal;
    }
  }
`;

const BoxForm = styled.div`
  position: relative;
  padding-top: 60px;
  .album_caption {
    font-size: 1.25rem;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-right: 4px;
  }
  .album_notice {
    margin-top: 8px;
    margin-bottom: 2px;
    color: #bababa;
  }
  .regen-uuid {
    margin-left: 4px;
    color: ${YELLO_4};
    text-decoration: underline;
    &:hover {
      color: ${WARNING_COLOR};
    }
  }
  .alert-message {
    display: none;
    color: ${DANGER_COLOR};
    margin-top: 4px;
    font-size: 0.9rem;
  }
  .alert-message.show {
    display: block;
  }
`;

const InputText = styled(Input.Text)<{ width: string }>`
  width: ${props => `${props.width}px`};
  height: 44px;
  margin-top: 4px;
  background-color: #fff;
  position: relative;
  border: 1px solid #cdcdcd;
  line-height: 22px;
  padding: 1rem;
  transition: border 0.15s;
  &:hover {
    border-color: #adadad;
  }
  &:focus {
    border-color: #ffdc00;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  margin-top: 60px;
  padding-top: 40px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: inline-block;
    width: 190px;
    height: 60px;
    margin: 0 3px;
    border: 1px solid #d4d4d4;
    line-height: 61px;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const selectStyle = (width: number) => ({
  container: (base: any) => ({
    ...base,
    width
  }),
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 44,
    width,
    boxShadow: "#ddd",
    borderColor: state.isFocused ? "#ffdc00" : "#cdcdcd",
    ":hover": {
      borderColor: "#ffdc00"
    }
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#cfcfcf"
  })
});

const RowContainer = styled.div`
  display: flex;
  align-items: center;

  .track-no {
    font-size: 20px;
    margin-right: 8px;
  }
`;

const ImageContainer = styled.form`
  position: absolute;
  top: ${pixelize(UNIT * 6.5)};
  right: 0;
  z-index: 1;
  img {
    width: ${pixelize(UNIT * 18)};
    height: ${pixelize(UNIT * 18)};
    cursor: pointer;
  }
  input[type="file"] {
    display: none;
  }
`;

const NoImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pixelize(UNIT * 18)};
  height: ${pixelize(UNIT * 18)};
  border: 1px solid ${DEFAULT_BORDER_COLOR};
`;
const InputButton = styled(Input.Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 8px;
`;

const FileUploadHeader = styled.div`
  display: flex;
  align-items: center;
`;
