import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";
import { Edge } from "models/Edge";
import { createDuck } from "lib/store/v2";
import { ServiceType } from "constants/ServiceType";
import { ValidType } from "constants/ValidType";

export type Structure = {
  parent: {
    parentMetadata: {
      title: string;
      typeSubclass: string;
    }[];
  }[];
  list: {
    metadata: {
      title: string;
      typeSubclass: string;
      metadataUrl: {
        id: string;
        typeUrl: string;
        url: string;
      }[];
    }[];
  }[];
};

export type License = {
  id: string;
  notice: string;
  copyright: boolean | undefined;
  neighboring: boolean | undefined;
  country_code: string;
  license_date: string | null;
  publish_date: string | null;
  type_track: string;
  valid_check: ValidType;
};

type Metadata = {
  metadataId: string;
  v1: string;
  no: string;
  title: string;
  validCheck: string;
  subdataUnique: {
    value: string;
  }[];
  titleRelation: {
    name: string;
  }[];
  metadataUrl: {
    id: string;
    url: string;
    typeUrl: string;
  }[];
  genres: {
    id: string;
    genre: {
      genreId: string;
      name: string;
      typeKind: string;
    }[];
  }[];
  artists: {
    id: string;
    artist: {
      name: string;
      artist_history: {
        birth: string;
        death: string;
      }[];
      artist_title: {
        value: string;
      }[];
    }[];
    role: {
      id: string;
      name: string;
      typeRole: string;
    }[];
  }[];
  structures: Structure[];
  license: {
    isService: number;
    notice: string;
    licenseDate: string | null;
    validCheck: ValidType;
  }[];
  licenseExtra: {
    recordYear: number;
    publishYear: number;
    firstEdition: number;
    place: string;
  }[];
  metadata_self_relations_list_metadata: {
    metadata_self_relations_element_metadata: {
      title: string;
    }[];
  }[];
  track_information: {
    tempo?: number;
    tonality?: string;
    timeSignature?: string;
    extraData?: string;
    bitrate?: number;
    channel?: number;
    duration?: number;
  }[];
};

export type PlaylistRelation = {
  id: string;
  metadata: Metadata[];
  validCheck: ValidType;
  playlist: {
    playlistId: string;
    name: string;
    kind: string;
    service: ServiceType;
    validCheck: ValidType;
    userRelation: {
      owner: boolean;
      visibleField: string[] | null;
    }[];
  }[];
  order: number;
};

const createInitialState = () => ({
  edge: null as null | Edge,
  playlistRelation: [] as PlaylistRelation[],
  csvPlaylistRelation: [] as PlaylistRelation[],
  checkList: new Map<string, boolean>(),
  deleteList: new Map<string, boolean>(),
  count: 10 as number,
  order: MetadataPlaylistRelationOrderByInput.exposure_order_ASC as MetadataPlaylistRelationOrderByInput,
  titleType: "id" as string,
  typeUrl: "mp3high" as string,
  csvType: "default" as string,
  downloadList: [] as PlaylistRelation[]
});

export const PlaylistDetailActions = createDuck({
  namespace: "PlaylistDetail",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setPlaylistDetail(state, playlistRelation: PlaylistRelation[]) {
      state.playlistRelation = playlistRelation;
    },
    setPlaylistDetailCheckListInit(state, ids: string[]) {
      ids.forEach(id => {
        state.checkList.get(id) === undefined && state.checkList.set(id, false);
      });
    },
    setPlyalistDetailCheckList(state, { id, checked }: { id: string; checked: boolean }) {
      state.checkList.set(id, checked);
    },
    clearPlyalistDetailCheckList(state) {
      state.checkList.clear();
      state.deleteList.clear();
    },
    setPlaylistDetailDeleteListInit(state, uuids: string[]) {
      uuids.forEach(id => {
        state.deleteList.get(id) === undefined && state.deleteList.set(id, false);
      });
    },
    setPlaylistDetailDeleteList(state, { id, checked }: { id: string; checked: boolean }) {
      state.deleteList.set(id, checked);
    },
    setPlaylistDetailCount(state, count: number) {
      state.count = count;
    },
    deletePlaylistDetailTrackByIds(state, ids: string[]) {
      const nextState = state.playlistRelation.filter(data => !ids.includes(data.metadata[0].metadataId));
      state.playlistRelation = nextState;
    },
    setPlaylistDetailOrder(state, order: MetadataPlaylistRelationOrderByInput) {
      state.order = order;
    },
    setPlaylistDetailMp3TitleType(state, titleType: string) {
      state.titleType = titleType;
    },
    setPlaylistDetailTypeUrl(state, typeUrl: string) {
      state.typeUrl = typeUrl;
    },
    setPlaylistDetailCsvType(state, csvType: string) {
      state.csvType = csvType;
    },
    setPlaylistDetailExposureOrder(state, { index, order }: { index: number; order: number }) {
      state.playlistRelation[index].order = order;
    },
    setPlaylistDetailDownloadList(state, downloadList: PlaylistRelation[]) {
      state.downloadList = downloadList;
    },
    setCsvPlaylist(state, playlistRelation: PlaylistRelation[]) {
      state.csvPlaylistRelation = [...state.csvPlaylistRelation, ...playlistRelation];
    },
    deletePlaylistDetailTrackByUuid(state, uuid: string) {
      state.playlistRelation = state.playlistRelation.filter(({ id }) => id !== uuid);
    },
    deletePlaylistDetailTrackByUuids(state, uuids: string[]) {
      state.playlistRelation = state.playlistRelation.filter(({ id }) => !uuids.includes(id));
    },
    updatePlaylistDetailTrackDurtaion(state, { id, duration }: { id: string; duration: number }) {
      const track = state.playlistRelation.find(({ metadata }) => metadata[0].metadataId === id);
      if (track) {
        if (!track.metadata[0].track_information.length) {
          track.metadata[0].track_information.push({ duration });
        } else {
          track.metadata[0].track_information[0].duration = duration;
        }
      }
    }
  }
});
