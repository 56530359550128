import React, { useState } from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_LARGE_PX } from "constants/size";
import { Divider } from "App/Atomics/Divider";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAlbumDetailStore } from "../../Store";
import { StructureActions } from "../../Store/Structure";
import { Input } from "App/Atomics/Input";
import { toEditStructureLog } from "./toEditStructureLog";
import { ColumnProps, TableTemplate } from "App/Templates/TableTemplate";
import { Table } from "App/Atomics/Table";
import { transparentize, PRIMARY_COLOR } from "constants/color";
import { Ordered, TrackActions } from "../../Store/TrackList";

type Structure = {
  structureId: string;
  structureRootId: number;
};

type Props = Readonly<{
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 70)};
  height: ${pixelize(UNIT * 40)};
  padding: ${PADDING_XX_LARGE_PX};
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

const TableLayout = styled.div`
  display: grid;
  grid-template-columns: ${pixelize(UNIT * 5)} auto;
  margin-bottom: ${MARGING_LARGE_PX};
`;

const CheckedRow = styled(Table.Row)<{ isChecked: boolean }>`
  ${props => (props.isChecked ? `background-color: ${transparentize(PRIMARY_COLOR, 20)}` : ``)};
`;

const OrderedButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;

  & > button + button {
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const HEAD_LIST = [{ name: "#" }, { name: "순서" }, { name: "아이디" }, { name: "타이틀" }];

export const OrderedTextModal = ({ toClose }: Props) => {
  const [{ structures, orderedTrackList, events }, albumDetailDispatch] = useAlbumDetailStore(store => ({
    album: store.AlbumDetail.albumDetail[0].album[0],
    structures: store.Structure.structures,
    orderedTrackList: store.TrackList.orderedTrackList,
    events: store.Structure.events
  }));
  const [orderedIndex, setOrderedIndex] = useState([] as number[]);

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      albumDetailDispatch(
        TrackActions.setOrderedTrackList(
          structures.map(structure => {
            return {
              metadataId: structure.metadata[0].metadataId,
              title: structure.metadata[0].title,
              structureId: structure.metadata[0].metadataStructure[0].structureId,
              order: structure.metadata[0].metadataStructure[0].order,
              checked: false
            } as Ordered;
          })
        )
      );
    }
  }, []);

  const DataTemplate = ({ index, data }: ColumnProps<any>) => {
    const { metadataId, title, checked } = data;

    const setOrderedIndexToItem = (check: boolean) => {
      if (check) {
        if (orderedIndex.findIndex(value => value === index) === -1) {
          orderedIndex.push(index);
        }
      } else {
        const findIndex = orderedIndex.findIndex(value => value === index);
        if (findIndex !== -1) {
          orderedIndex.splice(findIndex, 1);
        }
      }

      orderedIndex.sort();
      setOrderedIndex(orderedIndex);
    };

    return (
      <CheckedRow key={index} isChecked={checked}>
        <Table.Data>
          <Input.CheckBox
            id={"ordered" + metadataId}
            isChecked={checked}
            onToggle={value => {
              setOrderedIndexToItem(value);
              albumDetailDispatch(TrackActions.setOrderedCheck({ index, checked: value }));
            }}
          />
        </Table.Data>
        <Table.Data>{index + 1}</Table.Data>
        <Table.Data>{metadataId}</Table.Data>
        <Table.Data>{title}</Table.Data>
      </CheckedRow>
    );
  };

  const increaseOrderedIndex = () => {
    if (orderedIndex.length) {
      setOrderedIndex(
        orderedIndex.map((value, index) => {
          if (value - 1 < 0 || (index >= 0 && value < orderedIndex[index - 1])) {
            return value;
          }

          const orderedItem = orderedTrackList[value];
          albumDetailDispatch([
            TrackActions.swapOrderedList({ index: value, sort: "ASC" }),
            StructureActions.orderedAlbumItem({
              index: value,
              metadataId: orderedItem.metadataId,
              structureId: orderedItem.structureId,
              order: value - 1
            })
          ]);
          return value - 1;
        })
      );
    }
  };

  const decreaseOrderedIndex = () => {
    if (orderedIndex.length) {
      setOrderedIndex(
        orderedIndex
          .reverse()
          .map((value, index) => {
            if (value + 1 >= orderedTrackList.length || (index >= orderedTrackList.length - 1 && value < orderedIndex[index - 1])) {
              return value;
            }

            const orderedItem = orderedTrackList[value];
            albumDetailDispatch([
              TrackActions.swapOrderedList({ index: value, sort: "DESC" }),
              StructureActions.orderedAlbumItem({
                index: value,
                metadataId: orderedItem.metadataId,
                structureId: orderedItem.structureId,
                order: value + 1
              })
            ]);
            return value + 1;
          })
          .reverse()
      );
    }
  };

  return (
    <Layout>
      <h3>앨범 상세목록 정렬</h3>
      <Divider />

      <TableLayout>
        <div>
          <OrderedButtonGroup>
            <Input.Button color="primary" onClick={increaseOrderedIndex}>
              ▲
            </Input.Button>
            <Input.Button color="primary" onClick={decreaseOrderedIndex}>
              ▼
            </Input.Button>
          </OrderedButtonGroup>
        </div>
        <TableTemplate headList={HEAD_LIST} dataList={orderedTrackList} keyBy={data => data.metadataId} Column={DataTemplate} />
      </TableLayout>

      <ButtonGroup>
        <Input.Button
          onClick={() => {
            toEditStructureLog(events).then(() =>
              albumDetailDispatch([StructureActions.clearEventLog(), StructureActions.toggleMountedFlag()])
            );
            toClose();
          }}
        >
          저장
        </Input.Button>
        <Input.Button onClick={toClose}>닫기</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
