import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { PlaylistsActions as Playlist } from "./Playlist";

const reducers = { Playlist };

const reducer = combineReducers(reducers, createStoreLogger("Playlist"));

export const {
  StoreProvider: PlaylistsStoreProvider,
  useStore: usePlaylistsStore,
  useSelector: usePlaylistsSelector,
  useDispatch: usePlaylistsDispatch
} = createStore(reducer);
