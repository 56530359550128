import React from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetGenre } from "GraphQL/Queries/Genre";
import { useGenresDispatch, GenresStoreProvider } from "./Store";
import { GenreActions } from "./Store/Genre";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { RightGenreTable } from "./RightGenreTable";
import { useQueryParams } from "lib/use-query-params";

const Layout = styled.div``;

const ChildLayout = () => {
  const dispatch = useGenresDispatch();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: value => +value });
  const id = queryParams.get("id", { default: undefined });
  const weight = queryParams.get("weight", { default: 1, cast: value => +value });
  const typeKind = queryParams.get("type", { default: "genre" });
  const searchString = queryParams.get("q", { default: undefined });
  useAsyncEffect(async () => {
    try {
      const { data: genreData, error: genreError } = await GetGenre({
        id,
        page,
        typeKind,
        weight,
        first: weight === 1 ? 20 : 99,
        name: searchString
      });
      if (genreError) {
        console.log(genreError.message);
        return;
      }
      if (genreData) {
        dispatch([GenreActions.setGenre(genreData.genre), GenreActions.setEdge(genreData.edge)]);
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }, [page, searchString, weight, typeKind]);
  return (
    <Layout>
      <LeftSideBar />
      <RightGenreTable />
    </Layout>
  );
};

export const Genres = () => (
  <GenresStoreProvider>
    <Helmet title="장르" />
    <ChildLayout />
  </GenresStoreProvider>
);
