import React from "react";
import styled from "styled-components";
import uuidv4 from "uuid/v4";
import { usePlaylistCreateStore } from "../../Store";
import { DualListbox, DualListboxItem, DualListboxItemProps } from "App/Molecules/DualListbox";
import { PlaylistCreateActions } from "../../Store/TrackList";
import { PADDING_LARGE_PX, pixelize, UNIT, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { ReactComponent as Play } from "assets/icons/play-button.svg";
import { WHITE } from "constants/baseColor";
import { useAppDispatch } from "App/Store";
import { AudioPlayerActions } from "App/Store/AudioPlayer";
import { DANGER_COLOR } from "constants/color";
import { GetMetadataAudioData } from "GraphQL/Queries/Metadata";

const Layout = styled.div`
  width: 100%;
  height: inherit;

  white-space: pre-line;
`;

const ItemLayout = styled.div<{ duplicated?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: ${PADDING_LARGE_PX};
  color: ${props => (!props.duplicated ? "#000" : DANGER_COLOR)};
`;

const DataItem = styled.div`
  & > div {
    word-break: break-all;
  }
`;

const PlayButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  margin-right: ${MARGING_LARGE_PX};
  padding: ${PADDING_LARGE_PX};
  border-radius: 50%;
  transform: scale(0.75);

  & > svg {
    fill: ${WHITE};
    width: ${pixelize(UNIT * 2)};
    height: ${pixelize(UNIT * 2)};
  }
`;

type AudioModel = {
  type: string;
  url: string;
};

const Item = ({ id, value: title, extra }: DualListboxItemProps<AudioModel>) => {
  const { duplicated } = extra!;
  const dispatch = useAppDispatch();
  const playAudio = async () => {
    const audioId = uuidv4();
    const data = await GetMetadataAudioData({ id, typeClass: "record" });
    dispatch([
      AudioPlayerActions.toggleVisible(true),
      AudioPlayerActions.setAudioData({ ...data, uuid: audioId }),
      AudioPlayerActions.addAudioInPlaylist({ ...data, uuid: audioId })
    ]);
  };
  return (
    <ItemLayout duplicated={duplicated}>
      <PlayButton color="danger" onClick={playAudio}>
        <Play />
      </PlayButton>
      <DataItem>
        <div>[ {id} ]</div>
        <div>{title}</div>
      </DataItem>
    </ItemLayout>
  );
};

export const TrackList = () => {
  const [{ state, searchedTrackList, selectedTrackList }, dispatch] = usePlaylistCreateStore(store => {
    const { state, searchedTrackList, selectedTrackList } = store.TrackList;
    return {
      state,
      searchedTrackList: searchedTrackList.map(({ id, title, duplicated, audio }) => ({
        id,
        value: title,
        extra: { ...audio, duplicated }
      })),
      selectedTrackList: selectedTrackList.map(({ id, title, audio }) => ({ id, value: title, extra: audio }))
    };
  });
  const setSelectedTrackList = (itemList: readonly DualListboxItem<AudioModel>[]) => {
    const filteredList = itemList.filter(({ extra }) => !extra.duplicated);
    dispatch(
      PlaylistCreateActions.set({
        kind: "selected",
        tracks: filteredList.map(({ id, value, extra: audio }) => ({ id, title: value, audio: audio! }))
      })
    );
  };
  return (
    <Layout>
      <DualListbox
        left={{ label: "검색된 트랙 리스트", items: searchedTrackList }}
        right={{ label: "플레이리스트에 담을 트랙 리스트", items: selectedTrackList }}
        Item={Item}
        isLoading={state === "Loading"}
        onChange={setSelectedTrackList}
      />
    </Layout>
  );
};
