import React from "react";
import styled from "styled-components";
import { Prompt } from "react-router";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { Input } from "App/Atomics/Input";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { useCsvUploadStore } from "../../Store";
import { MARGING_X_LARGE_PX, HEADER_HEIGHT_PX, PADDING_XX_LARGE_PX } from "constants/size";
import { AlbumForm } from "./AlbumForm";
import { CsvUploadActions } from "../../Store/CsvUpload";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { CreateAlbumsModal } from "../../Modals/CreateAlbumsModal";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";

const Layout = styled.div`
  display: inherit;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100vh;
`;

const BottomTemplate = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  padding: ${PADDING_XX_LARGE_PX};
`;

const AlbumTextSelect = styled(Input.TextSelect)`
  width: 70%;
  margin-left: ${MARGING_X_LARGE_PX};
`;

export const AlbumEditForm = () => {
  const [{ albumInfo, albumIndex }, dispatch] = useCsvUploadStore(store => store.CsvUpload);
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  const AlbumSelectList = albumInfo.map((album, index) => ({ id: index.toString(), name: `${album.albumTitle}` }));

  const createAlbumsModal = useToggle();

  return (
    <Layout>
      <Prompt message="이전 페이지로 돌아가면 저장되지 않습니다. 정말로 돌아가시겠습니까?" />
      <TopbarTemplate>
        <Input.Button onClick={openSidebar}>
          <MenuIcon />
        </Input.Button>
        <AlbumTextSelect
          key={`${albumIndex}-${albumInfo[albumIndex].albumTitle}`}
          defaultValue={{ id: albumIndex.toString(), name: `${albumInfo[albumIndex].albumTitle || ""}` }}
          optionList={AlbumSelectList}
          onChange={info => {
            if (info) {
              dispatch([CsvUploadActions.setCsvAlbumIndex(+info.id), CsvUploadActions.setCsvTrackIndex(0)]);
            }
          }}
        />
        <form>
          <Input.Button
            color="primary"
            isFill={false}
            onClick={() => {
              if (window.confirm("앨범을 생성하시겠습니까?")) {
                dispatch(CsvUploadActions.setCsvTaskInfo());
                createAlbumsModal.on();
              } else {
                return;
              }
            }}
          >
            앨범 생성
          </Input.Button>
        </form>
      </TopbarTemplate>
      <BottomTemplate>
        <AlbumForm key={`${albumIndex}-${albumInfo[albumIndex].albumTitle}`} index={albumIndex} />
      </BottomTemplate>
      <Modal isOpen={createAlbumsModal.isToggled}>
        <CreateAlbumsModal onClick={createAlbumsModal.off} />
      </Modal>
    </Layout>
  );
};
