import { createDuck } from "lib/store/v2";
import { Edge } from "models/Edge";
import { Playlist, Url } from "GraphQL/Queries/Playlist/GetPlaylist";
import _ from "lodash";
import { ValidType } from "constants/ValidType";
import { ServiceType } from "constants/ServiceType";
import { Title } from "GraphQL/Queries/Playlist/GetPlaylistTitleRelation";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
import uuidv4 from "uuid/v4";

type EditInfo = {
  uuid: string;
  index: number;
};

export type Genre = Readonly<{
  id: string;
  typeKind: string;
  name: string;
  genre_self_relation_child?: {
    parent?: {
      id: string;
      name?: string;
    }[];
    child?: {
      id: string;
      name?: string;
    }[];
  }[];
}>;

const createInitialState = () => ({
  edge: null as null | Edge,
  playlists: [] as Playlist[],
  origin: [] as Playlist[],
  accessIds: new Map<string, string | undefined>(),
  editMode: new Map<string, boolean>(),
  order: ["playlist_id__DESC", "kind__ASC", "name__ASC"] as string[],
  titleRelation: [] as Title[],
  editInfo: {} as EditInfo,
  checkList: new Map<string, boolean>(),
  genreList: [] as Genre[],
  moodList: [] as Genre[],
  editLoading: false as boolean
});

export const PlaylistsActions = createDuck({
  namespace: "Playlist",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setPlaylist(state, playlists: Playlist[]) {
      state.playlists = playlists;
      state.origin = playlists;
    },
    setEditLoading(state, loading: boolean) {
      state.editLoading = loading;
    },
    setPlaylistAccessId(state, { playlistId, accessId }: { playlistId: string; accessId: string | undefined }) {
      state.accessIds.set(playlistId, accessId);
    },
    deletePlaylistAccessId(state, playlistId: string) {
      state.accessIds.delete(playlistId);
    },
    setPlaylistEditMode(state, { playlistId, mode }: { playlistId: string; mode: boolean }) {
      if (mode) {
        state.editMode.set(playlistId, mode);
      } else {
        state.editMode.delete(playlistId);
      }
    },
    deletePlaylistById(state, id: string) {
      state.origin = state.playlists.filter(playlist => {
        const isRemovedPlaylist = playlist.id === id;
        return !isRemovedPlaylist;
      });
    },
    editPlaylistNameByIndex(state, { index, name }: { index: number; name: string }) {
      state.playlists[index].name = name;
    },
    editPlaylistKindByIndex(state, { index, kind }: { index: number; kind: string }) {
      state.playlists[index].kind = kind;
    },
    editPlaylistServiceByIndex(state, { index, service }: { index: number; service: ServiceType }) {
      state.playlists[index].service = service;
    },
    editPlaylistPriceByIndex(state, { index, price }: { index: number; price: number }) {
      state.playlists[index].price = price;
    },
    editPlaylistRemarkByIndex(state, { index, remark }: { index: number; remark: string }) {
      state.playlists[index].remark = remark;
    },
    setPlaylistOrder(state, { prevOrder, nextOrder }: { prevOrder: string; nextOrder: string }) {
      let nextState = state.order.filter(order => order !== prevOrder);
      nextState.unshift(nextOrder);
      state.order = nextState;
    },
    createPlaylistImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const order = (_.last(state.playlists[index].playlistUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.playlists[index].playlistUrl.push({ uuid, typeUrl, url, validCheck: ValidType.V3, order });
    },
    updatePlaylistImage(state, { index, uuid, typeUrl, url }: { index: number; uuid: string; typeUrl: string; url: string }) {
      const urlIndex = state.playlists[index].playlistUrl.findIndex(image => image.uuid === uuid);
      state.playlists[index].playlistUrl[urlIndex].url = url;
      state.playlists[index].playlistUrl[urlIndex].typeUrl = typeUrl;
    },
    createPlaylistUrl(state, { index, url }: { index: number; url: Url }) {
      if (!state.playlists[index].playlistUrl.length) {
        state.playlists[index].playlistUrl = [url];
      } else {
        state.playlists[index].playlistUrl.push(url);
      }
    },
    updatePlaylistTypeUrl(state, { index, urlIndex, typeUrl }: { index: number; urlIndex: number; typeUrl: string }) {
      const order = (_.last(state.playlists[index].playlistUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.playlists[index].playlistUrl[urlIndex].typeUrl = typeUrl;
      state.playlists[index].playlistUrl[urlIndex].order = order;
    },
    updatePlaylistUrl(state, { index, urlIndex, url }: { index: number; urlIndex: number; url: string }) {
      state.playlists[index].playlistUrl[urlIndex].url = url;
    },
    updatePlaylistUrlUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { playlistUrl } = state.playlists[index];
      const urlIndex = playlistUrl.findIndex(({ uuid }) => uuid === prevUuid);
      playlistUrl[urlIndex].uuid = uuid;
    },
    deletePlaylistImage(state, { index, uuid }: { index: number; uuid: string }) {
      const imageIndex = state.playlists[index].playlistUrl.findIndex(item => item.uuid === uuid);
      state.playlists[index].playlistUrl.splice(imageIndex, 1);
    },
    deletePlaylistUrl(state, { index, urlIndex }: { index: number; urlIndex: number }) {
      state.playlists[index].playlistUrl.splice(urlIndex, 1);
    },
    updatePlaylistValidCheck(state, { index, validCheck }: { index: number; validCheck: ValidType }) {
      if (index >= 0) {
        state.origin[index].validCheck = validCheck;
      }
    },
    updatePlaylistUserValidCheck(state, { index, validCheck }: { index: number; validCheck: ValidType }) {
      state.playlists[index].userRelation[0].validCheck = validCheck;
    },
    deletePlaylistTrack(state, { index, uuid }: { index: number; uuid: string }) {
      const relationIndex = state.playlists[index].metadataPlaylistRelation.findIndex(item => item.uuid === uuid);
      state.playlists[index].metadataPlaylistRelation.splice(relationIndex, 1);
    },
    addTrackToPlaylist(state, { index, uuid, order, metadataId }: { index: number; uuid: string; order: number; metadataId: string }) {
      const relation = { order, uuid, metadata: [{ id: metadataId }] };
      state.playlists[index].metadataPlaylistRelation.unshift(relation);
    },
    setPlaylistTitleRelation(state, titleRelation: Title[]) {
      state.titleRelation = titleRelation;
    },
    setPlaylistTitleRelationEditInfo(state, { uuid, index }: { uuid: string; index: number }) {
      state.editInfo.uuid = uuid;
      state.editInfo.index = index;
    },
    createPlaylistTitleRelation(
      state,
      { id, typeTitle, value, languageCode, order }: { id: string; typeTitle: string; value: string; languageCode: string; order: number }
    ) {
      const name = LanguageCodeInfo.find(({ id }) => id === languageCode)?.name ?? "-";
      state.titleRelation.unshift({ id, typeTitle, value, language: [{ languageCode, name }], order });
    },
    updatePlaylistTitleRelation(
      state,
      { index, id, typeTitle, value, languageCode }: { index: number; id: string; typeTitle: string; value: string; languageCode: string }
    ) {
      state.titleRelation[index].typeTitle = typeTitle;
      state.titleRelation[index].value = value;
      state.titleRelation[index].language[0].languageCode = languageCode;
    },
    deletePlaylistTitleRelation(state, index: number) {
      state.titleRelation.splice(index, 1);
    },
    setPlaylistCheckListInit(state, ids: string[]) {
      ids.forEach(id => {
        state.checkList.get(id) === undefined && state.checkList.set(id, false);
      });
    },
    setPlaylistCheckList(state, { id, checked }: { id: string; checked: boolean }) {
      state.checkList.set(id, checked);
    },
    clearPlaylistCheckList(state) {
      state.checkList.clear();
    },
    setPlaylistGenre(state, genre: Genre[]) {
      state.genreList = genre;
    },
    setPlaylistMood(state, mood: Genre[]) {
      state.moodList = mood;
    },
    changePlaylistGenre(state, { index, genre }: { index: number; genre: Genre[] }) {
      state.genreList = genre;
      const trackGenre = state.playlists[index].genreRelation;
      const payloadGenre =
        genre?.map(({ id, name }) => ({
          uuid: uuidv4(),
          validCheck: ValidType.V3,
          genre: [
            {
              id,
              name
            }
          ]
        })) ?? [];
      if (trackGenre.length < payloadGenre.length) {
        const addGenreList = payloadGenre?.filter(({ genre }) => trackGenre.every(item => item.genre[0].id !== genre[0].id)) ?? [];
        state.playlists[index].genreRelation.push(...addGenreList);
      } else if (trackGenre.length > payloadGenre.length) {
        if (!payloadGenre.length) {
          state.playlists[index].genreRelation = [];
        } else {
          const removeIndex = trackGenre.findIndex(({ genre }) => !payloadGenre.some(item => item.genre[0].id === genre[0].id));
          state.playlists[index].genreRelation.splice(removeIndex, 1);
        }
      } else {
        return;
      }
    },
    changePlaylistMood(state, { index, mood }: { index: number; mood: Genre[] }) {
      state.moodList = mood;
      const trackMood = state.playlists[index].moodRelation;
      const payloadMood =
        mood?.map(({ id, name }) => ({
          uuid: uuidv4(),
          validCheck: ValidType.V3,
          mood: [
            {
              id,
              name
            }
          ]
        })) ?? [];
      if (trackMood.length < payloadMood.length) {
        const addMoodList = payloadMood?.filter(({ mood }) => trackMood.every(item => item.mood[0].id !== mood[0].id)) ?? [];
        state.playlists[index].moodRelation.push(...addMoodList);
      } else if (trackMood.length > payloadMood.length) {
        if (!payloadMood.length) {
          state.playlists[index].moodRelation = [];
        } else {
          const removeIndex = trackMood.findIndex(({ mood }) => !payloadMood.some(item => item.mood[0].id === mood[0].id));
          state.playlists[index].moodRelation.splice(removeIndex, 1);
        }
      } else {
        return;
      }
    },
    updatePlaylistGenreUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { genreRelation } = state.playlists[index];
      const genreIndex = genreRelation.findIndex(({ uuid }) => uuid === prevUuid);
      genreRelation[genreIndex].uuid = uuid;
    },
    updatePlaylistMoodUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { moodRelation } = state.playlists[index];
      const moodIndex = moodRelation.findIndex(({ uuid }) => uuid === prevUuid);
      moodRelation[moodIndex].uuid = uuid;
    },
    setClear(state) {
      state.playlists = state.origin;
    },
    setSave(state) {
      state.origin = state.playlists;
    }
  }
});
