export enum GoodsUrlOrderByInput {
  id__ASC = "id__ASC",
  id__DESC = "id__DESC",
  type_url__ASC = "type_url__ASC",
  type_url__DESC = "type_url__DESC",
  url__ASC = "url__ASC",
  url__DESC = "url__DESC",
  exposure_order__ASC = "exposure_order__ASC",
  exposure_order__DESC = "exposure_order__DESC",
  goods_id__ASC = "goods_id__ASC",
  goods_id__DESC = "goods_id__DESC"
}
