import React, { ReactNode } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { Toast } from "lib/toast";
import { GRAY_6 } from "constants/baseColor";

type Props = {
  text: string;
  children?: ReactNode;
};

const CopySpan = styled.span`
  cursor: copy;
  &:hover {
    color: ${GRAY_6};
    text-decoration: underline;
  }
`;

export const ClipBoard = ({ text, children }: Props) => {
  const handleCopy = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!text.length) {
      Toast.error("복사할 텍스트가 없습니다.");
      return;
    }
    copy(text);
    Toast.info("복사되었습니다.", undefined, "bottom-center");
  };
  return (
    <div style={{ display: "inline" }} onClick={handleCopy}>
      {!children ? <CopySpan>{text}</CopySpan> : children}
    </div>
  );
};
