import { GRAY_4 } from "constants/baseColor";
import { DANGER_COLOR, SUCCESS_COLOR, WARNING_COLOR } from "constants/color";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { Section } from "../../Store/GoodsCreate";

type Props = {
  readonly section: Section;
};

export const NavBar = ({ section }: Props): ReactElement => {
  return (
    <Layout>
      <Bar>
        <Progress section={section} />
      </Bar>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Bar = styled.div`
  width: 100%;
  height: 2px;
  background: ${GRAY_4};
  border-radius: 2px;
`;

const Progress = styled.div<{ section: Section }>`
  width: ${({ section }) => (section === Section.First ? "33%" : section === Section.Second ? "66%" : "100%")};
  height: 2px;
  background: ${({ section }) => (section === Section.First ? DANGER_COLOR : section === Section.Second ? WARNING_COLOR : SUCCESS_COLOR)};
  transition: all 0.5s ease-in-out;
`;
