import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { AuthGrantActions as Grant } from "./Grant";
import { IdActions as IdList } from "./IdList";

const reducers = { Grant, IdList };

const reducer = combineReducers(reducers, createStoreLogger("Grant"));

export const {
  StoreProvider: AuthGrantStoreProvider,
  useStore: useAuthGrantStore,
  useSelector: useAuthGrantSelector,
  useDispatch: useAuthGrantDispatch
} = createStore(reducer);
