import { combineReducers, createStore, Store } from "lib/store/v3";
import { createStoreLogger } from "lib/store/v3/createStoreLogger";
import { SidebarActions as Sidebar } from "./Sidebar";

const reducers = { Sidebar };
const reducer = combineReducers(reducers, createStoreLogger("App"));

export type AppStore = Store<typeof reducer>;

export const { useSelector: useAppSelector, ...AppStore } = createStore(reducer);
