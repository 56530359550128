import axios from "axios";
import { GetPlaylistTrackInfo } from "GraphQL/Queries/Playlist/GetPlaylistTrackInfo";
import { configs } from "configs";
import { analyzeAudio } from "utils/analyzeAudio";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreateTrackInformation, UpdateTrackInformation } from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { usePlaylistDetailDispatch } from "../Store";
import { PlaylistDetailActions } from "../Store/PlaylistDetail";

export type SimpleTrackInfo = {
  readonly id: string;
  readonly title: string;
  readonly uuid?: string;
};

export const useUpdateTrackInformation = () => {
  const dispatch = usePlaylistDetailDispatch();
  const loadTrackInfo = async (id: string): Promise<void> => {
    const { metadataPlaylistRelation } = await GetPlaylistTrackInfo({ id });
    const infoList: SimpleTrackInfo[] = metadataPlaylistRelation.map(({ metadata }) => {
      const track = metadata[0];
      return {
        id: track.id,
        title: track.title,
        uuid: track.trackInformation[0]?.id
      };
    });
    for (const { metadata } of metadataPlaylistRelation) {
      const trackId = metadata[0].id;
      const url = metadata[0].metadataUrl[0]?.url;
      if (!url) return;
      const accessId = await requestAccessRecord({ targetId: trackId, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        try {
          const data = await axios.get(`${configs.urls.audio}/${url}`, { responseType: "blob" });
          const file = data.data as File;
          const information = await analyzeAudio(file);
          const targetTrack = infoList.find(({ id }) => id === trackId)!;
          if (!targetTrack.uuid) {
            await CreateTrackInformation({
              metadataId: trackId,
              bitrate: information?.sampleRate,
              channel: information?.channelCount,
              duration: information?.duration
            });
          } else {
            await UpdateTrackInformation({
              uuid: targetTrack.uuid!,
              bitrate: information?.sampleRate,
              channel: information?.channelCount,
              duration: information?.duration
            });
          }
          dispatch(PlaylistDetailActions.updatePlaylistDetailTrackDurtaion({ id: targetTrack.id, duration: information?.duration ?? 0 }));
        } catch (err) {
          console.log(err);
        } finally {
          await DeleteAccessRecord({ id: accessId });
        }
      }
    }
    return;
  };
  return loadTrackInfo;
};
