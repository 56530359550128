import React, { ComponentType } from "react";
import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findAlbumList } from "./findAlbumList";
import { components, OptionProps } from "react-select";
import { GRAY_5 } from "constants/baseColor";
import styled from "styled-components";
import { PRIMARY_COLOR_LIGHT } from "constants/color";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  | "style"
  | "className"
  | "classNamePrefix"
  | "isDisabled"
  | "defaultValue"
  | "defaultOptions"
  | "onChange"
  | "onBlur"
  | "autoFocus"
  | "filterOption"
  | "placeholder"
>;

export const AlbumTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  onChange,
  onBlur,
  autoFocus,
  filterOption,
  placeholder = "검색어 : (앨범명 + 가수명) or $ID"
}: Props) => {
  return (
    <TextAutoComplete
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      filterOption={filterOption}
      onChange={onChange}
      onBlur={onBlur}
      autoFocus={autoFocus}
      loadInfoList={findAlbumList}
      components={{ Option }}
    />
  );
};

const Option: ComponentType<OptionProps<any>> = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionWrapper>
        <span className="id">{props.data.id}</span>
        <span>{children}</span>
        <span className="extra">{props.data.extra.artist}</span>
      </OptionWrapper>
    </components.Option>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  .id {
    font-size: 9px;
    color: ${GRAY_5};
  }
  .extra {
    font-size: 11px;
    color: ${PRIMARY_COLOR_LIGHT};
  }
`;
