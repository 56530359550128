import React, { useState } from "react";
import styled from "styled-components";
import { useAppStore } from "App/Store";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { TableTemplate } from "App/Templates/TableTemplate";
import { LanguageItem } from "./LanguageItem";
import { useLanguageStore } from "../Store";
import { HEADER_HEIGHT_PX } from "constants/size";
import { Pagination } from "App/Molecules/Pagination";
import { LANGUAGE } from "constants/route";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { Language } from "GraphQL/Queries/Language/GetLanguage";
import { SearchForm } from "App/Organisms/SearchForm";
import { useHistory } from "react-router";
import { useQueryParams } from "lib/use-query-params";
import { LoadingAction } from "App/Store/Loading";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { AppStore } from "App/Store-v3";

enum SearchType {
  code = "code",
  name = "name"
}

const SUB_HEAD_LIST = ["코드", "이름", "유효성", "Locale", "#"];
const SEARCH_OPTION_LIST = [
  { id: SearchType.code, name: "코드" },
  { id: SearchType.name, name: "이름" }
];

export const RightLanguageTable = () => {
  const [{ loading }, dispatchApp] = useAppStore(store => store.Loading);
  const [{ languages, edge }] = useLanguageStore(store => store.Language);
  const router = useHistory();
  const queryParams = useQueryParams();
  const page = queryParams.get("page", { default: 1, cast: v => +v });
  const searchName = queryParams.get(SearchType.name, { default: undefined }) as SearchType | undefined;
  const searchCode = queryParams.get(SearchType.code, { default: undefined }) as SearchType | undefined;
  const defaultSeachType = !searchName && !searchCode ? SearchType.name : searchName ? SearchType.name : SearchType.code;
  const [searchType, setSearchType] = useState<SearchType>(defaultSeachType ?? SearchType.name);
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());

  const goToPage = (index: number) =>
    `${LANGUAGE}?page=${index}${!searchName ? "" : `&name=${searchName}`}${!searchCode ? "" : `&code=${searchCode}`}`;

  const onSearch = (e: React.FormEvent<HTMLFormElement>, text: string) => {
    e.preventDefault();
    dispatchApp(LoadingAction.setLoading(LOADING.UNLOAD));
    if (!text.length) {
      router.push(`${LANGUAGE}?page=1`);
      return;
    }
    router.push(`${LANGUAGE}?page=${page}&${searchType}=${text}`);
  };

  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <SearchForm
          route={LANGUAGE}
          optionColumnList={SEARCH_OPTION_LIST}
          onChangeSearchTypeInfo={info => setSearchType(info!.id as SearchType)}
          onSearch={onSearch}
        />
      </TopbarTemplate>
      <TableBox>
        <TableTemplate
          headList={[{ name: "언어팩", colSpan: SUB_HEAD_LIST.length }]}
          subHeadList={SUB_HEAD_LIST}
          keyBy={(data: Language) => data.languageCode}
          dataList={loading !== LOADING.UNLOAD ? languages : []}
          Column={LanguageItem}
        />
        <LoadingTemplate loading={loading} />
        {!languages.length ? null : <Pagination edge={edge} goTo={goToPage} />}
      </TableBox>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
`;

const TableBox = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
`;
