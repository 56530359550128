import { HEADER_HEIGHT_PX } from "constants/size";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { Form } from "./Form";

export const ProductForm = (): ReactElement => {
  return (
    <Layout>
      <Form />
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  width: 100%;
  max-width: 570px;
  padding: 60px 26px 140px;
  margin-right: auto;
  margin-left: auto;
`;
