import {
  TRACK_FILTER,
  TRACK_VC_FILTER,
  TRACK_GENRE_FILTER,
  TRACK_ARTIST_FILTER,
  TRACK_ROLE_FILTER,
  TRACK_CHARACTER_FILTER,
  TRACK_LANGUAGE_FILTER,
  TRACK_COMPANY_FILTER,
  TRACK_COUNT
} from "constants/storageKey";
import { Head, sanitizeHeads, Subhead } from "./sanitizeHeads";
import { ValidCheck, sanitizeVCs } from "./sanitizeVCs";
import { Language, sanitizeLanguages } from "./sanitizeLanguages";
import { initializeKeyValue } from "lib/initialize-key-value";
import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

type Genre = Readonly<{
  id: string;
  typeKind?: string;
  name: string;
}>;

type Artist = Readonly<{
  id: string;
  name: string;
}>;

type Role = Readonly<{
  id: string;
  name: string;
}>;

type Character = Readonly<{
  id: string;
  name: string;
}>;

type Company = Readonly<{
  id: string;
  name: string;
}>;

type License = {
  typeTrack: string;
  countryCode: string;
};

const createInitialState = () => {
  return {
    ...loadHeads(),
    ...loadValidCheck(),
    ...loadGenreCheck(),
    ...loadArtistCheck(),
    ...loadRoleCheck(),
    ...loadCharacterCheck(),
    ...loadLanguageCheck(),
    ...loadCompanyCheck(),
    count: loadTrackCount()
  };
};

const afterChange = (state: State) => {
  const { head, subhead, validCheck, genreCheck, artistCheck, roleCheck, characterCheck, languageCheck, companyCheck, count } = state;
  window.localStorage[TRACK_FILTER] = JSON.stringify({
    Head: head,
    Subhead: subhead
  });
  window.sessionStorage[TRACK_VC_FILTER] = JSON.stringify({
    ValidCheck: validCheck
  });
  window.sessionStorage[TRACK_GENRE_FILTER] = JSON.stringify({
    GenreCheck: genreCheck
  });
  window.sessionStorage[TRACK_ARTIST_FILTER] = JSON.stringify({
    ArtistCheck: artistCheck
  });
  window.sessionStorage[TRACK_ROLE_FILTER] = JSON.stringify({
    RoleCheck: roleCheck
  });
  window.sessionStorage[TRACK_CHARACTER_FILTER] = JSON.stringify({
    CharacterCheck: characterCheck
  });
  window.sessionStorage[TRACK_LANGUAGE_FILTER] = JSON.stringify({
    LanguageCheck: languageCheck
  });
  window.sessionStorage[TRACK_COMPANY_FILTER] = JSON.stringify({
    CompanyCheck: companyCheck
  });
  window.localStorage[TRACK_COUNT] = JSON.stringify({
    count: count
  });
};

export const WorkTrackTableInfoActions = createDuck({
  namespace: "WorkTrackTableInfo",
  createInitialState,
  reducers: {
    setHead(state, head: Head) {
      state.head = head;
    },
    setSubhead(state, subhead: Subhead) {
      state.subhead = subhead;
    },
    setValidCheck(state, validCheck: ValidCheck) {
      state.validCheck = validCheck;
    },
    setGenreCheck(state, genreCheck: Genre[]) {
      state.genreCheck = genreCheck;
    },
    setArtistCheck(state, artistCheck: Artist[]) {
      state.artistCheck = artistCheck;
    },
    setRoleCheck(state, roleCheck: Role[]) {
      state.roleCheck = roleCheck;
    },
    setCharacterCheck(state, characterCheck: Character[]) {
      state.characterCheck = characterCheck;
    },
    setLanguageCheck(state, languageCheck: Language) {
      state.languageCheck = languageCheck;
    },
    setCompanyCheck(state, companyCheck: Company[]) {
      state.companyCheck = companyCheck;
    },
    setTrackCount(state, count: number) {
      state.count = count;
    }
  },
  afterChange
});

const loadHeads = () => {
  try {
    const trackFilter = window.localStorage.getItem(TRACK_FILTER);
    return sanitizeHeads(trackFilter ? JSON.parse(trackFilter) : {});
  } catch (error) {
    return sanitizeHeads({});
  }
};

const loadValidCheck = () => {
  try {
    const validCheck = window.sessionStorage.getItem(TRACK_VC_FILTER);
    return sanitizeVCs(validCheck ? JSON.parse(validCheck) : {});
  } catch (error) {
    return sanitizeVCs({});
  }
};

const loadGenreCheck = () => {
  try {
    const genreCheck = window.sessionStorage.getItem(TRACK_GENRE_FILTER);
    return sanitizeGenres(genreCheck ? JSON.parse(genreCheck) : ([] as Genre[]));
  } catch (err) {
    return sanitizeGenres({});
  }
};

const loadArtistCheck = () => {
  try {
    const artistCheck = window.sessionStorage.getItem(TRACK_ARTIST_FILTER);
    return sanitizeArtists(artistCheck ? JSON.parse(artistCheck) : ([] as Artist[]));
  } catch (err) {
    return sanitizeArtists({});
  }
};

const loadRoleCheck = () => {
  try {
    const roleCheck = window.sessionStorage.getItem(TRACK_ROLE_FILTER);
    return sanitizeRoles(roleCheck ? JSON.parse(roleCheck) : ([] as Role[]));
  } catch (err) {
    console.log(err);
    return sanitizeRoles({});
  }
};

const loadCharacterCheck = () => {
  try {
    const characterCheck = window.sessionStorage.getItem(TRACK_CHARACTER_FILTER);
    return sanitizeCharacters(characterCheck ? JSON.parse(characterCheck) : ([] as Character[]));
  } catch (err) {
    console.log(err);
    return sanitizeCharacters({});
  }
};

const loadLanguageCheck = () => {
  try {
    const languageCheck = window.sessionStorage.getItem(TRACK_LANGUAGE_FILTER);
    return sanitizeLanguages(languageCheck ? JSON.parse(languageCheck) : {});
  } catch (e) {
    console.log(e);
    return sanitizeLanguages({});
  }
};

const loadCompanyCheck = () => {
  try {
    const companyCheck = window.sessionStorage.getItem(TRACK_COMPANY_FILTER);
    return sanitizeCompany(companyCheck ? JSON.parse(companyCheck) : ([] as Company[]));
  } catch (e) {
    console.log(e);
    return sanitizeCompany({});
  }
};

const loadTrackCount = () => {
  try {
    const trackCount = window.localStorage.getItem(TRACK_COUNT);
    return trackCount ? parseInt(JSON.parse(trackCount).count) : 10;
  } catch (e) {
    console.log(e);
    return 10;
  }
};

const sanitizeGenres = (option: Record<string, boolean>) => {
  const { GenreCheck: genreCheck } = initializeKeyValue(option, {
    GenreCheck: [{ id: "", name: "" }] as Genre[]
  });
  return { genreCheck };
};

const sanitizeArtists = (option: Record<string, boolean>) => {
  const { ArtistCheck: artistCheck } = initializeKeyValue(option, {
    ArtistCheck: [{ id: "", name: "" }] as Artist[]
  });
  return { artistCheck };
};

const sanitizeRoles = (option: Record<string, boolean>) => {
  const { RoleCheck: roleCheck } = initializeKeyValue(option, {
    RoleCheck: [{ id: "", name: "" }] as Role[]
  });
  return { roleCheck };
};

const sanitizeCharacters = (option: Record<string, boolean>) => {
  const { CharacterCheck: characterCheck } = initializeKeyValue(option, {
    CharacterCheck: [{ id: "", name: "" }] as Character[]
  });
  return { characterCheck };
};

const sanitizeCompany = (option: Record<string, boolean>) => {
  const { CompanyCheck: companyCheck } = initializeKeyValue(option, {
    CompanyCheck: [{ id: "", name: "" }] as Company[]
  });
  return { companyCheck };
};
