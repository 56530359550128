import React from "react";
import { Table } from "App/Atomics/Table";
import { ClipBoard } from "App/Molecules/ClipBoard";

type ArtistProps = Readonly<{
  index: number;
  artists: any[];
}>;
type Artist = {
  id: string;
  name: string;
};
type ArtistInput = {
  id: string;
  artist: Artist[];
  role: Artist[];
};

export const MainPerformer = ({ index, artists }: ArtistProps) => {
  if (!artists?.length) {
    return <Table.Data key={index}>{}</Table.Data>;
  }

  const mainPerformer = artists.filter((artist: ArtistInput) => artist.role[0]?.id === "343");

  return (
    <Table.Data key={index}>
      <span>{!mainPerformer || !mainPerformer.length ? "" : <ClipBoard text={mainPerformer[0]?.artist[0]?.name ?? ""} />}</span>
    </Table.Data>
  );
};

export const Composer = ({ index, artists }: ArtistProps) => {
  const composer = !artists.length ? undefined : artists.filter((artist: ArtistInput) => artist.role[0]?.id === "175");
  if (!composer || !composer.length) {
    return <Table.Data key={index}>{}</Table.Data>;
  }

  return (
    <Table.Data key={index}>
      <span>{composer[0].artist[0].name ?? ""}</span>
    </Table.Data>
  );
};

export const OriginalArtist = ({ index, artists }: ArtistProps) => {
  if (!artists.length) {
    return <Table.Data key={index}>{}</Table.Data>;
  }

  const originalArtist = artists.filter((artist: ArtistInput) => artist.role[0]?.id === "3570");
  return (
    <Table.Data key={index}>
      <span>{!originalArtist || !originalArtist.length ? "" : <ClipBoard text={originalArtist[0]?.artist[0]?.name ?? ""} />}</span>
    </Table.Data>
  );
};
