import React from "react";
import { FilterGroup, Filters } from "../..";
import { Input } from "App/Atomics/Input";
import { Info } from "App/Atomics/Input/Select/TextSelect";

type Props = {
  count?: string;
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

const CountOption = [
  { id: "10", name: "10개 보기" },
  { id: "20", name: "20개 보기" },
  { id: "40", name: "40개 보기" },
  { id: "60", name: "60개 보기" },
  { id: "80", name: "80개 보기" },
  { id: "100", name: "100개 보기" }
] as Info[];

export const CountFilter = ({ count, setFilters }: Props) => {
  return (
    <FilterGroup>
      <h4>개수 조절</h4>
      <span className="info">기본 값은 20개 입니다.</span>
      <Input.TextSelect
        defaultValue={CountOption.find(({ id }) => id === count) ?? CountOption[1]}
        optionList={CountOption}
        onChange={info => {
          if (info) {
            setFilters(filter => ({ ...filter, count: info.id }));
          }
        }}
      />
    </FilterGroup>
  );
};
