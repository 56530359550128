import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { ArtistActions as Artist } from "./Artist";

const reducers = { Artist };

const reducer = combineReducers(reducers, createStoreLogger("Artist"));

export const {
  StoreProvider: ArtistStoreProvider,
  useStore: useArtistStore,
  useSelector: useArtistSelector,
  useDispatch: useArtistDispatch
} = createStore(reducer);
