import React, { ReactElement, useRef } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { ProductDetailStore } from "App/Routes/ProductDetail/Store";
import { ProductDetailActions } from "App/Routes/ProductDetail/Store/ProductDetail";

type Props = {
  readonly quality: string;
};

export const ProductQuality = ({ quality }: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Style.Row>
      <Style.Caption>퀄리티</Style.Caption>
      <Style.SubCaption>상품의 상태에 따른 등급을 나타냅니다.</Style.SubCaption>
      <Style.InputText
        ref={inputRef}
        placeholder={"NEW, S+, S, A+, A, B+, B"}
        defaultValue={quality}
        onBlur={value => ProductDetailStore.dispatch(ProductDetailActions.setQuality(value))}
      />
      <Style.AlertCaption data-visible={!quality?.length}>퀄리티를 입력해주세요.</Style.AlertCaption>
    </Style.Row>
  );
};
