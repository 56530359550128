import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { ArtistEditActions as Artist } from "./Artist";
import { ArtistEditInfoActions as ArtistInfo } from "./ArtistInfo";

const reducers = { Artist, ArtistInfo };

const reducer = combineReducers(reducers, createStoreLogger("ArtistEdit"));

export const {
  StoreProvider: ArtistsStoreProvider,
  useStore: useArtistsStore,
  useSelector: useArtistsSelector,
  useDispatch: useArtistsDispatch
} = createStore(reducer);
