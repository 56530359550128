/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field } from "graphql-jsx";
import { clients } from "utils/clients";
import { GoodsType } from "models/GoodsModel";

export const LoadGoodsType = async () => {
  const { query, variables } = render(
    <Query operationName="LOAD_GOODS_TYPE">
      <SelectionSet
        name="goods_type"
        alias="goodsType"
        argument={{
          first: 99
        }}
      >
        <Field name="id" />
        <Field name="type" />
        <Field name="category" />
        <Field name="display_type" alias="displayType" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    goodsType: GoodsType[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
