import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { SearchActions as Search } from "./Search";

const reducers = { Search };

const reducer = combineReducers(reducers, createStoreLogger("Search"));

export const {
  StoreProvider: SearchStoreProvider,
  useStore: useSearchStore,
  useSelector: useSearchSelector,
  useDispatch: useSearchDispatch
} = createStore(reducer);
