import { LocationState } from "history";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

type Props = { isDisabled?: boolean } & NavLinkProps<LocationState>;

export const Internal = ({ to, style, isDisabled, className, activeStyle, activeClassName, onClick, children }: Props) => (
  <NavLink
    aria-disabled={isDisabled}
    exact
    to={to}
    style={style}
    activeStyle={activeStyle}
    className={className}
    activeClassName={activeClassName}
    onClick={onClick}
  >
    {children}
  </NavLink>
);
