import React, { ReactElement } from "react";
import styled from "styled-components";
import { Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { Time } from "lib/time";
import { PRIMARY_COLOR } from "constants/color";
import { GRAY_7 } from "constants/baseColor";
type Props = {
  readonly createdAt: string;
};

export const GoodsDate = ({ createdAt }: Props): ReactElement => {
  return (
    <Style.Row>
      <Style.Caption>발매 일자 *</Style.Caption>
      <Style.SubCaption>날짜를 모두 입력하면 반영됩니다.</Style.SubCaption>
      <DateContainer>
        <DateSelect
          id="date-label"
          type="date"
          defaultValue={createdAt}
          required
          min="1900-01-01"
          max="2099-12-31"
          onChange={event => GoodsCreateStore.dispatch(GoodsCreateActions.setCreatedAt(event.target.value))}
        />
        <DateLabel htmlFor="date-label">{Time(createdAt).format("YYYY년 MM월 DD일")}</DateLabel>
      </DateContainer>
      <Style.AlertCaption>날짜를 선택해주세요.</Style.AlertCaption>
    </Style.Row>
  );
};

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
const DateLabel = styled.label`
  margin-right: 4px;
  box-shadow: inset 0 0 0 1px #cdcdcd;
  border-radius: 8px;
  padding: 10px 16px;
  color: ${PRIMARY_COLOR};
`;
const DateSelect = styled.input`
  padding-bottom: 2px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: ${GRAY_7};
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    margin: 0;
    cursor: pointer;
  }
`;
