/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, ListScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { PlaylistOrderByInputScalar, MetadataPlaylistRelationOrderScalar } from "GraphQL/Scalars";
import { PlaylistOrderByInput } from "constants/PlaylistOrderByInput";
import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";

type Option = Readonly<{
  id?: string;
  email?: string;
  trackOrder?: MetadataPlaylistRelationOrderByInput;
  trackCount?: number;
  trackSkip?: number;
}>;

export type PlaylistInfo = {
  id: string;
  name: string;
  kind: string;
  count: number;
  metadataPlaylistRelation: {
    uuid: string;
    metadata: {
      metadataId: string;
      title: string;
      artistRelation: {
        artist: {
          name: string;
        }[];
      }[];
    }[];
    order: number;
  }[];
};

export const GetPlaylistInfo = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const $email = <Variable name="email" scalar={<IdScalar />} value={option.email} />;
  const $trackOrder = (
    <Variable
      name="track_order"
      scalar={<ListScalar scalar={<MetadataPlaylistRelationOrderScalar />} isNullable />}
      defaultValue={MetadataPlaylistRelationOrderByInput.exposure_order_DESC}
      value={option.trackOrder}
    />
  );
  const $trackCount = <Variable name="trackCount" scalar={<IntScalar isNullable />} defaultValue={9999} value={option.trackCount} />;
  const $trackSkip = <Variable name="trackSkip" scalar={<IntScalar isNullable />} value={option.trackSkip} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_INFO">
      <SelectionSet
        name="playlist"
        argument={{
          group: true,
          first: 999,
          where: {
            playlist_id: $playlistId,
            playlist_users_relation__some: {
              users__some: {
                email: $email
              }
            }
          },
          orderBy: <PlaylistOrderByInputScalar value={PlaylistOrderByInput.playlist_id_DESC} />
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="name" />
        <Field name="kind" />
        <SelectionSet
          name="metadata_playlist_relation"
          alias="metadataPlaylistRelation"
          argument={{
            first: $trackCount,
            skip: $trackSkip,
            orderBy: $trackOrder
          }}
        >
          <Field name="id" alias="uuid" />
          <SelectionSet name="metadata">
            <Field name="metadata_id" alias="metadataId" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    playlist: PlaylistInfo[];
  }>;
  const { errors, data } = await clients.playlist.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }

  return data!.playlist;
};
