import ms from "ms.macro";

const urls = {
  access: process.env.REACT_APP_URL_ACCESS!,
  files: process.env.REACT_APP_URL_FILES!,
  artist: process.env.REACT_APP_URL_ARTIST!,
  company: process.env.REACT_APP_URL_COMPANY!,
  genre: process.env.REACT_APP_URL_GENRE!,
  language: process.env.REACT_APP_URL_LANGUAGE!,
  metadata: process.env.REACT_APP_URL_METADATA!,
  role: process.env.REACT_APP_URL_ROLE!,
  playlist: process.env.REACT_APP_URL_PLAYLIST!,
  audio: process.env.REACT_APP_URL_AUDIO!,
  image: process.env.REACT_APP_URL_IMAGE!,
  goods: process.env.REACT_APP_URL_GOODS!,
  goodsWeb: process.env.REACT_APP_URL_GOODS_WEB!
};

const account = {
  email: process.env.REACT_APP_EMAIL_LIST!
};

const masterAccessKey = process.env.NODE_ENV === "production" ? "" : process.env.REACT_APP_MASTER_ACCESS_KEY;

export const configs = {
  urls,
  masterAccessKey,
  userTokenUpdateTerm: ms("5m"),
  account
};
