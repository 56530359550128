import { createAfterDispatch } from "lib/store/createAfterDispatch";

export const logger = createAfterDispatch((previousStore, nextStore, action, isChanged, next) => {
  if (process.env.NODE_ENV !== "production") {
    console.groupCollapsed(
      `Store::Dispatch::[${typeof action.type === "symbol" ? action.type.toString() : action.type}]::${isChanged ? "Changed" : "Unchanged"}`
    );
    if (isChanged) {
      console.log("before");
      console.log(previousStore);
      console.log("after");
      console.log(nextStore);
    }
    console.groupEnd();
  }
  next();
});
