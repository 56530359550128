/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  email: string;
  password: string;
}>;

export const LogIn = async (option: Option) => {
  const email = <Variable name="email" scalar={<IdScalar isNullable={false} />} value={option.email} />;
  const password = <Variable name="password" scalar={<StringScalar isNullable={false} />} value={option.password} />;
  const { query, variables } = render(
    <Query operationName="ACCESS_SIGN_IN">
      <SelectionSet name="logIn" alias="logIn" argument={{ where: { email, password } }}>
        <SelectionSet name="jsonWebToken" alias="tokens">
          <Field name="refresh_user_token" alias="refreshToken" />
          <Field name="access_token" alias="accessToken" />
          <Field name="user_token" alias="userToken" />
          <Field name="payload_token" alias="payloadToken" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    logIn: Readonly<{
      tokens: Readonly<{
        refreshToken: string;
        accessToken: string;
        userToken: string;
        payloadToken: string;
      }>;
    }>;
  }>;
  return await clients.access.request<Data>(query, variables);
};
