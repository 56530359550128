/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  name: string;
  type: string;
}>;

export const CheckDuplicatedName = async (option: Option) => {
  const $name = <Variable name="name" scalar={<StringScalar />} value={option.name} />;
  const $type = <Variable name="type_kind" scalar={<StringScalar />} value={option.type} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_NAME">
      <SelectionSet
        name="genre"
        argument={{
          where: {
            name: $name,
            type_kind: $type
          }
        }}
      >
        <Field name="name" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    genre: Readonly<{
      name: string;
    }>[];
  }>;
  return await clients.genre.request<Data>(query, variables);
};
