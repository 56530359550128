import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Metadata, Artist, Company, Url, SubData, ElementRelation, Title } from "GraphQL/Queries/Metadata/GetMetadata";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import { RoleTextAutoComplete, CharacterTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import uuidv4 from "uuid/v4";
import { useWorkDetailStore } from "App/Routes/WorkDetail/Store";
import { WorkDetailActions } from "App/Routes/WorkDetail/Store/WorkDetail";
import { GRAY_4, GRAY_7 } from "constants/baseColor";
import { Tag } from "App/Atomics/Tag";
import { useAsyncEffect } from "lib/use-async-effect";
import { WORK_GENRE_LIST, WORK_MOOD_LIST } from "constants/storageKey";
import { GetGenreList, GetMoodList } from "GraphQL/Queries/Genre";
import { GetMoodParentList } from "GraphQL/Queries/Genre/GetMoodParentList";
import { Genre } from "../../../Store/WorkDetail/index";
import { TrackTextAutoComplete } from "App/Molecules/AutoCompletes/Track";
import { UserRole } from "constants/UserRole";
import { useAppSelector } from "App/Store";
import { UrlOptionList } from "constants/UrlOptionList";
import { TypeOptionInfo } from "constants/TypeOptionInfo";
import { LanguageTextSelect } from "App/Molecules/Selects/Language";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { ClipBoard } from "App/Molecules/ClipBoard";

type SectionProps = {
  index: number;
  data: Metadata;
  pageIndex: Page;
};

export const ColumnSection = ({ index, data, pageIndex }: SectionProps) => {
  const [{ genreList, moodList }, dispatch] = useWorkDetailStore(store => store.WorkDetail);
  const { genreRelation, moodRelation } = data;
  const userRole = useAppSelector(store => store.UserToken.role);
  // 모든 장르/무드를 담을 리스트
  const [filteredGenreList, setFilteredGenreList] = useState<Genre[]>(() => {
    const localGenre = window.localStorage.getItem(WORK_GENRE_LIST);
    if (!localGenre) {
      return [];
    }
    const parsedLocalGenre = JSON.parse(localGenre);
    return parsedLocalGenre;
  });
  const [filteredMoodList, setFilteredMoodList] = useState<Genre[]>(() => {
    const localMood = window.localStorage.getItem(WORK_MOOD_LIST);
    if (!localMood) {
      return [];
    }
    const parsedLocalMood = JSON.parse(localMood);
    return parsedLocalMood;
  });

  useAsyncEffect(
    async isMounted => {
      dispatch(WorkDetailActions.setEditLoading(true));
      // 로컬스토리지에 데이터가 없으면 쿼리 실행
      if (isMounted()) {
        if (!filteredGenreList.length) {
          const { data: genreData } = await GetGenreList();
          if (genreData) {
            window.localStorage.setItem(WORK_GENRE_LIST, JSON.stringify(genreData.genre));
            setFilteredGenreList(genreData.genre);
          }
          if (!filteredMoodList.length) {
            const { data: childData } = await GetMoodList();
            const { data: parentData } = await GetMoodParentList();
            if (childData && parentData) {
              const noChildParentData = parentData.genre.map(item => ({
                id: item.id,
                name: item.name,
                typeKind: item.typeKind,
                genre_self_relation_child: item.genre_self_relation_child as any[]
              }));
              const moodArr = childData.genre.concat(noChildParentData);
              window.localStorage.setItem(WORK_MOOD_LIST, JSON.stringify(moodArr));
              setFilteredMoodList(moodArr);
            }
          }
        }
      }
      dispatch(WorkDetailActions.setEditLoading(false));
      const initialGenre: Genre[] = !genreRelation.length
        ? []
        : filteredGenreList.filter(({ id }) => {
            for (const { genre } of genreRelation) {
              if (genre[0].id === id) {
                return true;
              }
            }
            return false;
          });
      const initialMood: Genre[] = !moodRelation.length
        ? []
        : filteredMoodList.filter(({ id }) => {
            for (const { mood } of moodRelation) {
              if (mood[0].id === id) {
                return true;
              }
            }
            return false;
          });
      dispatch([WorkDetailActions.setWorkGenre(initialGenre), WorkDetailActions.setWorkMood(initialMood)]);
    },
    [filteredGenreList, filteredMoodList, genreRelation, moodRelation]
  );

  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          if (value === null) return null;
          // 메인 정보
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "metadataId":
              case "title":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "metadataId" ? "ID" : "제목"}</span>
                    <InputText
                      isDisabled={key === "metadataId"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(WorkDetailActions.updateWorkTitle({ index, title: val }))}
                    />
                  </LabelGroup>
                );
              case "subClass":
                return (
                  <LabelGroup key={key}>
                    <span>{"분류"}</span>
                    <InputText isDisabled defaultValue={value as string} />
                  </LabelGroup>
                );
              case "artistRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>{"아티스트"}</span>
                      <RowGroup className={!value.length ? "" : "border"}>
                        {!(value as Artist[]).length
                          ? null
                          : (value as Artist[]).map(({ artist, role, character }, artistIndex) => {
                              const key = `${artist[0]?.id ?? ""}-${role[0]?.id ?? ""}-${character[0]?.id ?? ""}-${artistIndex}`;
                              return (
                                <ExtraInfo key={key} column={4}>
                                  <RoundButton
                                    color="danger"
                                    key={`${key}-remove`}
                                    onClick={() => {
                                      dispatch(WorkDetailActions.deleteWorkArtist({ index, artistIndex }));
                                    }}
                                  >
                                    -
                                  </RoundButton>
                                  <ArtistTextAutoComplete
                                    className="select"
                                    key={`${key}-artist`}
                                    defaultValue={
                                      !artist.length || !artist[0].id ? undefined : { id: artist[0].id!, name: artist[0].name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const artistInfo = { id: info.id, name: info.name };
                                        dispatch(WorkDetailActions.updateWorkArtist({ index, artistIndex, info: artistInfo }));
                                      }
                                    }}
                                  />
                                  <RoleTextAutoComplete
                                    className="select"
                                    key={`${key}-role`}
                                    defaultValue={!role.length || !role[0].id ? undefined : { id: role[0].id!, name: role[0].name! }}
                                    onChange={info => {
                                      if (info) {
                                        const roleInfo = { id: info.id, name: info.name };
                                        dispatch(WorkDetailActions.updateWorkRole({ index, roleIndex: artistIndex, info: roleInfo }));
                                      }
                                    }}
                                  />
                                  <CharacterTextAutoComplete
                                    className="select character"
                                    key={`${key}-character`}
                                    defaultValue={
                                      !character.length || !character[0].id ? undefined : { id: character[0].id!, name: character[0].name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const characterInfo = { id: info.id, name: info.name };
                                        dispatch(
                                          WorkDetailActions.updateWorkCharacter({ index, characterIndex: artistIndex, info: characterInfo })
                                        );
                                      }
                                    }}
                                  />
                                </ExtraInfo>
                              );
                            })}
                        <RoundButton
                          color="success"
                          onClick={() => {
                            dispatch(WorkDetailActions.createWorkArtist(index));
                          }}
                        >
                          +
                        </RoundButton>
                      </RowGroup>
                    </LabelGroup>
                  </Fragment>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(WorkDetailActions.updateWorkVC({ index, vc }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "rightsCompany":
                const rightsCompany = (value as Company[])[0]?.company ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"권리사"}</span>
                    <CompanyTextAutoComplete
                      isDisabled={userRole !== UserRole.Master || !rightsCompany}
                      defaultValue={
                        !rightsCompany
                          ? { id: "none", name: "권리사 정보가 없습니다" }
                          : { id: rightsCompany[0].id, name: rightsCompany[0].name }
                      }
                      onBlur={info => {
                        if (info) {
                          dispatch(WorkDetailActions.setWorkRightsCompany({ index, id: info.id, name: info.name }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "productions":
                const productions = (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"제작사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={productions}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "productions",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(WorkDetailActions.setWorkProductions({ index, productions: list }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "publications":
                const publications =
                  (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"배급사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={publications}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "publications",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(WorkDetailActions.setWorkPublications({ index, publications: list }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "class":
              case "subclass":
              default:
                return null;
            }
          } else if (pageIndex === Page.SECOND) {
            switch (key) {
              case "genreRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>장르</span>
                      <RowGroup>
                        <InputTagSelect
                          className="autocomplete"
                          autoFocus
                          optionList={filteredGenreList
                            .filter(({ genre_self_relation_child }) => genre_self_relation_child && genre_self_relation_child.length)
                            .map(({ id, name, typeKind, genre_self_relation_child }) => {
                              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                                const parentName = genre_self_relation_child[0].parent[0].name;
                                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                                return { id, name: mixedName, typeKind };
                              }
                              return { id, name, typeKind };
                            })}
                          value={genreList}
                          onChange={info => {
                            if (info) {
                              dispatch(
                                WorkDetailActions.changeWorkGenre({
                                  index,
                                  genre: info.map(({ id, name }) => ({ id, name, typeKind: "genre" }))
                                })
                              );
                            }
                          }}
                        />
                        <TagContainer type="genre">
                          {filteredGenreList
                            .filter(({ id, genre_self_relation_child }) => {
                              if (!genre_self_relation_child || !genre_self_relation_child.length) {
                                return false;
                              }
                              return !genreList.find(item => item.id === id);
                            })
                            .map((genre, i) => {
                              const { name, genre_self_relation_child } = genre;
                              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                                const parentName = genre_self_relation_child[0].parent[0].name;
                                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                                const mixedGenre = {
                                  ...genre,
                                  name: mixedName
                                };
                                return (
                                  <Tag
                                    key={genre.id}
                                    style={{ width: "95%", textAlign: "center" }}
                                    color="primary"
                                    filled={false}
                                    onClick={() =>
                                      dispatch(WorkDetailActions.changeWorkGenre({ index, genre: [...genreList, mixedGenre] }))
                                    }
                                  >
                                    {mixedName}
                                  </Tag>
                                );
                              }
                              return (
                                <Tag
                                  key={genre.id}
                                  style={{ width: "95%", textAlign: "center" }}
                                  color="primary"
                                  filled={false}
                                  onClick={() => dispatch(WorkDetailActions.changeWorkGenre({ index, genre: [...genreList, genre] }))}
                                >
                                  {genre.name}
                                </Tag>
                              );
                            })}
                        </TagContainer>
                      </RowGroup>
                    </LabelGroup>
                    <div style={{ height: "1px", backgroundColor: "#ddd" }} />
                  </Fragment>
                );
              case "moodRelation":
                return (
                  <LabelGroup key={key}>
                    <span>무드</span>
                    <RowGroup>
                      <InputTagSelect
                        className="autocomplete"
                        autoFocus
                        optionList={filteredMoodList.map(({ id, name, genre_self_relation_child }) => {
                          let isDuplicateMood = moodList.some(list =>
                            list.genre_self_relation_child?.find(({ parent, child }) => {
                              if (parent && parent.length && genre_self_relation_child) {
                                const check1 = genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                                const check2 = parent.find(item => item.id === id);
                                return check1 || check2;
                              }
                              if (child && child.length) {
                                return child.find(item => item.id === id);
                              }
                            })
                          );
                          return { id, name, isDisabled: isDuplicateMood };
                        })}
                        value={moodList}
                        onChange={info => {
                          if (info) {
                            const selectedMood = filteredMoodList.filter(mood => info.find(item => item.id === mood.id));
                            dispatch(WorkDetailActions.changeWorkMood({ index, mood: selectedMood }));
                          } else {
                            // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                          }
                        }}
                      />
                      <TagContainer type="mood">
                        {filteredMoodList
                          .filter(mood => !moodList.find(item => item.id === mood.id))
                          .map((mood, i) => (
                            <Tag
                              key={mood.id}
                              disabled={moodList.some(list =>
                                list.genre_self_relation_child?.find(({ parent, child }) => {
                                  if (parent && parent.length && mood.genre_self_relation_child) {
                                    const check1 = mood.genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                                    const check2 = parent.find(item => item.id === mood.id);
                                    return check1 || check2;
                                  }
                                  if (child && child.length) {
                                    return child.find(item => item.id === mood.id);
                                  }
                                })
                              )}
                              style={{ width: "90%", textAlign: "center" }}
                              filled={false}
                              color="primary"
                              onClick={() => dispatch(WorkDetailActions.changeWorkMood({ index, mood: [...moodList, mood] }))}
                            >
                              {mood.name}
                            </Tag>
                          ))}
                      </TagContainer>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          } else if (pageIndex === Page.THIRD) {
            switch (key) {
              case "elementRelation":
                const elements = value as ElementRelation[];
                return (
                  <LabelGroup key={key}>
                    <span>{"연관 트랙"}</span>
                    <RowGroup className={!elements.length ? "" : "border"}>
                      {!elements?.length
                        ? null
                        : elements.map(({ uuid, listRelation }, i) => {
                            const trackDefaultValue =
                              !listRelation[0].id || !listRelation[0].title || !listRelation[0].artistRelation
                                ? undefined
                                : {
                                    id: listRelation[0].id!,
                                    name: `[${listRelation[0].id}] ${listRelation[0].title!} (🎤 ${
                                      listRelation[0].artistRelation[0].artist[0].name
                                    })`
                                  };

                            return (
                              <ExtraInfo key={uuid} column={2}>
                                <RoundButton
                                  color="danger"
                                  onClick={() => {
                                    dispatch(WorkDetailActions.deleteWorkElementRelation({ index, subIndex: i }));
                                  }}
                                >
                                  -
                                </RoundButton>
                                <TextSelectWrapper>
                                  <TrackTextAutoComplete
                                    className="select"
                                    defaultValue={trackDefaultValue}
                                    onBlur={info => {
                                      if (info) {
                                        dispatch(
                                          WorkDetailActions.updateWorkElementRelationTrack({
                                            index,
                                            subIndex: i,
                                            id: info.id,
                                            title: info.name
                                          })
                                        );
                                      }
                                    }}
                                  />
                                  <ClipBoard text={listRelation[0]?.title ?? ""}>
                                    <span>COPY</span>
                                  </ClipBoard>
                                </TextSelectWrapper>
                              </ExtraInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          dispatch(WorkDetailActions.createWorkElementRelation(index));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                break;
            }
          } else {
            switch (key) {
              case "titles":
                return (
                  <LabelGroup key={key}>
                    <span>언어팩 & 가사</span>
                    <RowGroup className={!value.length ? "" : "border"}>
                      {!(value as Title[]).length
                        ? null
                        : (value as Title[]).map(({ language, typeTitle, title }, subIndex) => {
                            const key = `${!language ? "" : language[0].languageCode}-${typeTitle ?? ""}-${title ?? ""}-${subIndex}`;
                            let titleValue = undefined;
                            if (title) {
                              try {
                                titleValue = window.decodeURIComponent(title);
                              } catch (err) {
                                titleValue = title;
                              }
                            }
                            return (
                              <ExtraInfo key={key} column={4} className="subdata">
                                <RoundButton
                                  color="danger"
                                  key={`${key}-remove`}
                                  onClick={() => {
                                    dispatch(WorkDetailActions.deleteWorkTitleRelation({ index, subIndex }));
                                  }}
                                >
                                  -
                                </RoundButton>
                                <Input.TextSelect
                                  className="select"
                                  key={`${key}-typeTitle`}
                                  optionList={TypeOptionInfo}
                                  defaultValue={!typeTitle ? undefined : { id: typeTitle, name: typeTitle }}
                                  placeholder="타입"
                                  onBlur={info => {
                                    if (info) {
                                      dispatch(WorkDetailActions.updateWorkTitleRelationTypeTitle({ index, subIndex, typeTitle: info.id }));
                                    }
                                  }}
                                />
                                <LanguageTextSelect
                                  className="select"
                                  key={`${key}-langauge`}
                                  defaultValue={!language ? undefined : { id: language[0].languageCode, name: language[0].name }}
                                  placeholder="언어"
                                  onBlur={info => {
                                    if (info) {
                                      dispatch(
                                        WorkDetailActions.updateWorkTitleRelationLanguage({
                                          index,
                                          subIndex,
                                          languageCode: info.id,
                                          languageName: info.name
                                        })
                                      );
                                    }
                                  }}
                                />
                                <InputText
                                  defaultValue={titleValue}
                                  placeholder="내용"
                                  onBlur={text => {
                                    if (text) {
                                      dispatch(WorkDetailActions.updateWorkTitleRelationValue({ index, subIndex, value: text }));
                                    }
                                  }}
                                />
                              </ExtraInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          dispatch(WorkDetailActions.createWorkTitleRelation(index));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              case "metadataUrl":
                const urls = value as Url[];
                return (
                  <LabelGroup key={key}>
                    <span>{"URL"}</span>
                    <RowGroup className={!urls.length ? "" : "border"}>
                      {!urls?.length
                        ? null
                        : urls.map((url, i) => (
                            <ExtraInfo key={i} column={3}>
                              <RoundButton
                                color="danger"
                                onClick={() => {
                                  dispatch(WorkDetailActions.deleteWorkUrl({ index, urlIndex: i }));
                                }}
                              >
                                -
                              </RoundButton>
                              <CreatableTextSelect
                                name="select"
                                className="select"
                                optionList={UrlOptionList}
                                placeholder="타입"
                                defaultValue={!url.typeUrl ? undefined : { id: url.typeUrl, name: url.typeUrl }}
                                onBlur={info => {
                                  if (info) {
                                    dispatch(WorkDetailActions.updateWorkTypeUrl({ index, urlIndex: i, typeUrl: info.id }));
                                  }
                                }}
                              />
                              <InputText
                                placeholder="값"
                                defaultValue={!url.url ? undefined : url.url}
                                onBlur={text => {
                                  dispatch(WorkDetailActions.updateWorkUrl({ index, urlIndex: i, url: text }));
                                }}
                              />
                            </ExtraInfo>
                          ))}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const tempUrl = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeUrl: undefined,
                            url: undefined
                          } as Url;
                          dispatch(WorkDetailActions.createWorkUrl({ index, url: tempUrl }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              case "subdataUnique":
                return (
                  <LabelGroup key={key}>
                    <span>서브데이터</span>
                    <RowGroup className={!value.length ? "" : "border"}>
                      {!(value as SubData[]).length
                        ? null
                        : (value as SubData[]).map(({ category, field, value }, subIndex) => {
                            const uuid = uuidv4();
                            return (
                              <ExtraInfo key={uuid} column={4} className="subdata">
                                <RoundButton
                                  color="danger"
                                  key={`${uuid}-remove`}
                                  onClick={() => {
                                    dispatch(WorkDetailActions.deleteWorkSubdata({ index, subIndex }));
                                  }}
                                >
                                  -
                                </RoundButton>
                                <CreatableTextSelect
                                  name="category"
                                  className="select"
                                  key={`${uuid}-category`}
                                  optionList={[{ id: "etc", name: "etc" }]}
                                  defaultValue={!category ? undefined : { id: category, name: category }}
                                  onBlur={info => {
                                    if (info?.id.length) {
                                      dispatch(WorkDetailActions.updateWorkSubdataCategory({ index, subIndex, category: info.name }));
                                    }
                                  }}
                                />
                                <CreatableTextSelect
                                  name="field"
                                  className="select"
                                  key={`${uuid}-field`}
                                  optionList={[
                                    { id: "first_publication", name: "first_publication" },
                                    { id: "imslp_url", name: "imslp_url" },
                                    { id: "insts_main", name: "insts_main" },
                                    { id: "language", name: "language" },
                                    { id: "note", name: "note" },
                                    { id: "work_style", name: "work_style" }
                                  ]}
                                  defaultValue={!field ? undefined : { id: field, name: field }}
                                  onBlur={info => {
                                    if (info?.id.length) {
                                      dispatch(WorkDetailActions.updateWorkSubdataField({ index, subIndex, field: info.name }));
                                    }
                                  }}
                                />
                                <InputText
                                  defaultValue={value}
                                  onBlur={text => dispatch(WorkDetailActions.updateWorkSubdataValue({ index, subIndex, value: text }))}
                                />
                              </ExtraInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          dispatch(WorkDetailActions.createWorkSubdata(index));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );

              default:
                return null;
            }
          }
          return null;
        })}
    </>
  );
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 700px;
  padding: 0.5rem;
  overflow: hidden;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 2 ? `2rem auto` : props.column === 3 ? `2rem 150px auto` : `2rem 34% 28% auto`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;

  &.subdata {
    grid-template-columns: 2rem 20% 25% auto;
  }
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

const InputTagSelect = styled(Input.TagSelect)`
  margin-bottom: 1rem;
  border-color: ${GRAY_4};
`;

const TagContainer = styled.div<{ type: string }>`
  width: 100%;
  height: 16rem;
  overflow: scroll;
  display: grid;
  grid-template-columns: ${props => (props.type === "genre" ? "repeat(4, 24.5%)" : "repeat(7, 14%)")};
  grid-gap: 5px;
  box-shadow: 0 0 4px ${GRAY_4};
  padding: 1rem;
`;

const TextSelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    color: ${GRAY_4};
    &:hover {
      color: ${GRAY_7};
    }
  }
`;
