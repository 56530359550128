import { useEffect, DependencyList } from "react";

export const useAsyncEffect = (callback: (isMounted: () => boolean) => void | Promise<void>, dependencies?: DependencyList) => {
  const runEffect = () => {
    let isMounted = true;
    const checkToBeMounted = () => isMounted;
    callback(checkToBeMounted);
    return () => {
      isMounted = false;
    };
  };
  useEffect(runEffect, dependencies);
};
