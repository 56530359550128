/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, StringScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar, MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";
type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  character_id?: string;
  character_name?: string;
  order?: number;
};
type Work = {
  id: string;
  title: string;
  order?: number;
};
type Title = {
  type_metadata_title: string;
  value: string;
};
type Genre = {
  id: string;
  name: string;
  typeKind: string;
};

type Production = {
  company_id: string;
  name: string;
};

type Publication = {
  company_id: string;
  name: string;
};

type Option = Readonly<{
  no: string;
  title: string;
  class: string;
  subClass: string;
  trackArtist: Artist[];
  companyId: string;
  productions?: Production[];
  publications?: Publication[];
  genre?: Genre[];
  work?: Work[];
  mp3Url?: string;
  aacUrl?: string;
  flacUrl?: string;
  wavUrl?: string;
  parentId: number;
  notice?: string;
  countryCode?: string;
  typeTrack?: string;
  trackTitle?: Title;
  publishDate?: string;
  recordYear?: number;
  publishYear?: number;
  firstEdition?: number;
}>;

export const CreateCsvTrack = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $title_no_space = (
    <Variable name="title_no_space" scalar={<StringScalar isNullable={false} />} value={option.title.replace(/\s/gi, "")} />
  );
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.companyId} />;
  const $mp3Url = <Variable name="mp3url" scalar={<StringScalar isNullable={false} />} value={option.mp3Url} />;
  const $aacUrl = <Variable name="aacurl" scalar={<StringScalar isNullable={false} />} value={option.aacUrl} />;
  const $flacUrl = <Variable name="flacurl" scalar={<StringScalar isNullable={false} />} value={option.flacUrl} />;
  const $wavUrl = <Variable name="wavurl" scalar={<StringScalar isNullable={false} />} value={option.wavUrl} />;
  const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable={false} />} value={option.parentId} />;
  const $notice = <Variable name="notice" scalar={<StringScalar isNullable={true} />} value={option.notice} />;
  const $countryCode = <Variable name="countryCode" scalar={<StringScalar isNullable={false} />} value={option.countryCode} />;
  const $typeTrack = <Variable name="typeTrack" scalar={<StringScalar isNullable={false} />} value={option.typeTrack} />;
  const $publishDate = <Variable name="publishDate" scalar={<DateTimeScalar />} value={JSON.stringify(`${option.publishDate} 05:00:00`)} />;
  const $recordYear = <Variable name="recordYear" scalar={<IntScalar />} value={option.recordYear} />;
  const $publishYear = <Variable name="publishYear" scalar={<IntScalar />} value={option.publishYear} />;
  const $firstEdition = <Variable name="firstEdition" scalar={<IntScalar />} value={option.firstEdition} />;
  const $rightCompany = <MetadataRightScalar isNullable={true} value={MetadataRightType.rightCompany} />;
  const $production = <MetadataRightScalar isNullable={true} value={MetadataRightType.production} />;
  const $publication = <MetadataRightScalar isNullable={true} value={MetadataRightType.publication} />;

  const { query, variables } = render(
    <Mutation operationName="CREATE_TRACK_LOG">
      <SelectionSet
        name="createMetadata"
        alias="createTrack"
        argument={{
          data: {
            no: $no,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title_no_space,
                  exposure_order: 1
                },
                !option.trackTitle
                  ? undefined
                  : {
                      language: {
                        connect: {
                          language_code: "XX"
                        }
                      },
                      type_metadata_title: option.trackTitle.type_metadata_title,
                      value: option.trackTitle.value,
                      exposure_order: 0
                    }
              ]
            },
            metadata_self_relations_list_metadata: {
              create: [
                ...(option.work?.map(({ id }, order) => ({
                  type_function: "works",
                  metadata_self_relations_element_metadata: {
                    connect: {
                      metadata_id: id
                    }
                  },
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_artist_relation: {
              create: [
                ...option.trackArtist.map(({ artist_id, role_id, character_id, order }) => ({
                  artist: {
                    connect: {
                      artist_id: artist_id
                    }
                  },
                  role: {
                    connect: {
                      role_id: role_id
                    }
                  },
                  character: {
                    connect: {
                      role_id: character_id
                    }
                  },
                  exposure_order: order
                }))
              ]
            },
            metadata_genre_relation: {
              create:
                !option.genre || !option.genre.length
                  ? []
                  : option.genre
                      .filter(({ typeKind }) => typeKind === "genre")
                      .map(({ id }, order) => ({
                        genre: {
                          connect: {
                            genre_id: id
                          }
                        },
                        exposure_order: order
                      }))
                      .concat(
                        option.genre
                          .filter(({ typeKind }) => typeKind === "mood")
                          .map(({ id }, order) => ({
                            genre: {
                              connect: {
                                genre_id: id
                              }
                            },
                            exposure_order: order
                          }))
                      )
            },
            track_license: {
              create: [
                !(option.notice && option.countryCode && option.typeTrack && option.publishDate)
                  ? {}
                  : {
                      copyright: true,
                      neighboring: true,
                      notice: $notice,
                      country_code: $countryCode,
                      type_track: $typeTrack,
                      publish_date: $publishDate
                    }
              ]
            },
            track_license_extra: {
              create: [
                {
                  record_year: $recordYear,
                  publish_year: $publishYear,
                  first_edition: $firstEdition
                }
              ]
            },
            metadata_url: {
              create: [
                option.mp3Url && {
                  type_url: "mp3high",
                  url: $mp3Url
                },
                option.aacUrl && {
                  type_url: "aac",
                  url: $aacUrl
                },
                option.flacUrl && {
                  type_url: "flac",
                  url: $flacUrl
                },
                option.wavUrl && {
                  type_url: "wav",
                  url: $wavUrl
                }
              ].filter(Boolean)
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $rightCompany,
                  exposure_order: 0
                },
                ...(option.productions?.map(({ company_id }, order) => ({
                  company: {
                    connect: {
                      company_id: company_id
                    }
                  },
                  type_kind: $production,
                  exposure_order: order
                })) ?? []),
                ...(option.publications?.map(({ company_id }, order) => ({
                  company: {
                    connect: {
                      company_id: company_id
                    }
                  },
                  type_kind: $publication,
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_structure: {
              create: { parent_id: $parentId }
            }
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata_url">
          <Field name="id" />
          <Field name="type_url" />
          <Field name="url" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createTrack: Readonly<{
      metadata_id: string;
      metadata_url: {
        id: string;
        type_url: string;
        url: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
