import { createStore, combineReducers } from "lib/store";
import { afterChanges, afterDispatches } from "utils/store";
import { reducer as UserInfo } from "./UserInfo";

const reducers = { UserInfo };

const reducer = combineReducers(reducers, afterDispatches, afterChanges);

export const {
  StoreProvider: ChangePasswordStoreProvider,
  useStore: useChangePasswordStore,
  useSelector: useChangePasswordSelector,
  useDispatch: useChangePasswordDispatch
} = createStore(reducer);
