import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { VideoCreateStyle } from "App/Routes/VideoCreate/Style";
import React from "react";

type Props = {
  readonly company: Info;
};

export const CompanyForm = ({ company }: Props) => (
  <VideoCreateStyle.Row>
    <VideoCreateStyle.Caption>권리사 *</VideoCreateStyle.Caption>
    <VideoCreateStyle.SubCaption>* 변경은 관리자에게 문의해주세요.</VideoCreateStyle.SubCaption>
    <CompanyTextAutoComplete isDisabled defaultValue={company} />
  </VideoCreateStyle.Row>
);
