/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, EnumScalar, ListScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

export const findGoodsList = async ({ keyword, type, typeNot }: { keyword: string; type?: string; typeNot?: string }) => {
  let $id = undefined;
  if (keyword.startsWith("$")) {
    const searchId = keyword.match(/\$([0-9]+)/)?.[1];
    if (searchId) {
      $id = <Variable name="id" scalar={<IdScalar />} value={searchId} />;
    }
  }
  let $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  const $type = <Variable name="type" scalar={<StringScalar isNullable />} value={type} />;
  const $typeNot = <Variable name="type__not" scalar={<StringScalar isNullable />} value={typeNot} />;
  const { query, variables } = render(
    <Query operationName="GOODS_SEARCHED_LIST">
      <SelectionSet
        name="goods_title"
        alias="list"
        argument={{
          first: 40,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Goods_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            ...($id
              ? { goods__some: { goods_id: $id } }
              : {
                  goods__some: { goods_type__some: { type: $type, type__not: $typeNot } },
                  type_title__starts_with: "search",
                  tsvec_words__search: $keyword
                })
          }
        }}
      >
        <Field name="goods_id" alias="id" />
        <SelectionSet name="goods">
          <Field name="goods_id" alias="id" />
          <Field name="name" />
          <Field name="created_at" alias="createdAt" />
          <SelectionSet
            name="goods_artist_relation"
            alias="goodsArtist"
            argument={{
              where: {
                artist__some: {
                  type_artist_class: "organization"
                }
              }
            }}
          >
            <SelectionSet name="artist">
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
          <Field name="is_sale" alias="isSale" />
          <SelectionSet name="goods_type" alias="goodsType">
            <Field name="id" />
            <Field name="display_type" alias="displayName" />
          </SelectionSet>
          <SelectionSet
            name="goods_url"
            alias="goodsUrl"
            argument={{
              where: {
                type_url: "official"
              }
            }}
          >
            <Field name="id" />
            <Field name="url" />
          </SelectionSet>
          <SelectionSet name="goods_structure" alias="goodsStructure">
            <Field name="id" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: {
      id: string;
      goods: {
        id: string;
        name: string;
        createdAt: string;
        type: string;
        isSale: boolean;
        goodsArtist: {
          artist: {
            name: string;
          }[];
        }[];
        goodsType: {
          id: number;
          displayName: string;
        };
        goodsStructure: {
          id: string;
        }[];
        goodsUrl: {
          id: string;
          url: string;
        }[];
      }[];
    }[];
  }>;
  const response = await clients.goods.request<Data>(query, variables);
  if (response.data) {
    try {
      let dataList = response.data.list.map(({ goods }) => {
        const { id, name, goodsType, goodsStructure, goodsArtist, goodsUrl, isSale, createdAt } = goods[0];
        const artist = goodsArtist[0]?.artist[0].name ?? "-";
        const officialUrl = goodsUrl[0]?.url ?? null;
        return {
          id,
          name,
          extra: {
            goodsType,
            artist,
            isSale,
            structureId: goodsStructure[0].id,
            officialUrl,
            createdAt
          }
        };
      });
      return dataList;
    } catch (error) {
      console.error(error);
    }
    return [];
  } else {
    return [];
  }
};
