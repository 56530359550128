import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  MARGING_LARGE,
  PADDING_LARGE_PX,
  PADDING_X_LARGE_PX,
  BORDER_RADIUS_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { GRAY_0 } from "constants/baseColor";
import { DANGER_COLOR, DEFAULT_BORDER_COLOR } from "constants/color";
import { Progress } from "App/Atomics/Progress";
import axios from "axios";
import { CreateInvalidation, DeleteAccessRecord, UpdateFile, UploadFile } from "GraphQL/Queries";
import { MediaType } from "GraphQL/Scalars/MediaType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { createFormData } from "lib/createFormData";
import { CreateMetadataUrl, DeleteMetadataUrl, GetCompanyId, GetTrackNoWithAlbumSerial, UpdateMetadataUrl } from "GraphQL/Queries/Metadata";
import { usePlaylistDetailDispatch } from "App/Routes/PlaylistDetail/Store";
import { PlaylistTrackActions } from "App/Routes/PlaylistDetail/Store/PlaylistTrack";

type Props = Readonly<{
  id: string;
  typeUrl: string;
  url?: string;
  urlIndex: number;
  metadataId: string;
  toClose: () => void;
}>;

type FileInfo = Readonly<{
  ext: string;
  accept: string;
}>;

type TrackData = Readonly<{
  name: string;
  file: File;
  fileType: string;
}>;

type LoadedData = {
  loaded: number | undefined;
  total: number | undefined;
};

const getFileInfo = (typeUrl: string) => {
  switch (typeUrl) {
    case "mp3high":
      return { ext: "mp3", accept: "audio/mpeg" } as FileInfo;
    case "aac":
      return { ext: "m4a", accept: "audio/x-m4a" } as FileInfo;
    case "flac":
      return { ext: "flac", accept: "audio/flac" } as FileInfo;
    case "wav":
      return { ext: "wav", accept: "audio/wav" } as FileInfo;
    case "zip":
      return { ext: "zip", accept: ".zip" } as FileInfo;
    case "txt":
      return { ext: "txt", accept: "text/plain" } as FileInfo;
    default:
      return { ext: "", accept: "" };
  }
};

export const UploadFileModal = ({ id, typeUrl, url, metadataId, urlIndex, toClose }: Props) => {
  const dispatch = usePlaylistDetailDispatch();
  const [trackData, setTrackData] = useState({} as TrackData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeStamp, setTimeStamp] = useState(0);
  const [loadedData, setLoadedData] = useState({
    loaded: undefined,
    total: undefined
  } as LoadedData);
  const [failed, setFailed] = useState(false);
  const fileInfo: FileInfo = getFileInfo(typeUrl);

  const setTrackFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = () => {
      setTrackData({
        name: file.files![0].name,
        file: file.files![0],
        fileType
      } as TrackData);
    };
  };

  const onUploadFile = async () => {
    const accessId = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
    try {
      const companyId = await GetCompanyId({ id: metadataId });
      if (!companyId) {
        throw new Error("권리사 ID를 찾을 수 없습니다.");
      }
      const { albumNo, trackNo } = await GetTrackNoWithAlbumSerial({ metadataId });
      const keyUrl = !url ? undefined : url?.split("/");
      const filename = `${albumNo}/${typeUrl}/${trackNo}.${trackData.fileType}`;
      if (!url) {
        // TODO: UPLOAD
        const { data: urlData, errors: urlErr } = await CreateMetadataUrl({ metadataId, typeUrl, url: filename });
        if (urlErr || !urlData) {
          throw urlErr;
        }
        const uuid = urlData.createMetadataUrl.metadata_url[0].uuid;
        const { data, errors: uploadErr } = await UploadFile({ companyId, filename });
        if (uploadErr || !data) {
          await DeleteMetadataUrl({ uuid });
          throw uploadErr;
        }
        const uploadData = data.uploadFile;
        if (uploadData) {
          await axios({
            method: "post",
            url: uploadData.url,
            data: createFormData(uploadData, trackData.file),
            onUploadProgress: progressEvent => {
              loadedData.loaded = progressEvent.loaded;
              loadedData.total = progressEvent.total;
              setTimeStamp(progressEvent.timeStamp);
              setLoadedData(loadedData);
            },
            headers: {
              "Content-Type": fileInfo.accept
            }
          });
          await UpdateMetadataUrl({ uuid, url: uploadData.key });
          dispatch([
            PlaylistTrackActions.updateTrackUrlUuid({ prevUuid: id, uuid }),
            PlaylistTrackActions.updateTrackUrl({ urlIndex, url: uploadData.key })
          ]);
        }
      } else {
        // TODO: UPDATE
        const updateFileName = keyUrl!
          .splice(2)
          .join("/")
          .split("?")[0];
        const { data, errors: updateErr } = await UpdateFile({ companyId, filename: updateFileName });
        if (updateErr || !data) {
          throw updateErr;
        }
        const uploadData = data.updateFile;
        if (uploadData) {
          await axios({
            method: "post",
            url: uploadData.url,
            data: createFormData(uploadData, trackData.file),
            onUploadProgress: progressEvent => {
              loadedData.loaded = progressEvent.loaded;
              loadedData.total = progressEvent.total;
              setTimeStamp(progressEvent.timeStamp);
              setLoadedData(loadedData);
            },
            headers: {
              "Content-Type": fileInfo.accept
            }
          });
          await CreateInvalidation({
            token: uploadData.invalidateToken,
            mediaType: MediaType.track
          });
          if (uploadData.key !== url) {
            await UpdateMetadataUrl({ uuid: id, url: uploadData.key });
            dispatch(PlaylistTrackActions.updateTrackUrl({ urlIndex, url: uploadData.key }));
          }
        }
      }
      await DeleteAccessRecord({ id: accessId! });
      Toast.primary("성공적으로 업로드되었습니다.", undefined, "top-center");
      toClose();
    } catch (e) {
      console.log(e);
      await DeleteAccessRecord({ id: accessId! });
      setFailed(true);
      Toast.error("업로드에 실패하였습니다.", undefined, "top-center");
    }
  };

  return (
    <Layout>
      <RowGroup>
        <h2>파일 업로드</h2>
        <span className="alert-span">파일 업로드는 관리를 위해 변경 후 즉시 저장됩니다. 주의해주세요.</span>
      </RowGroup>
      <RowGroup>
        <input type="file" accept={fileInfo.accept} onChange={event => setTrackFile(event, fileInfo.ext)} />
      </RowGroup>
      <RowGroup>
        <div style={{ textAlign: "left", whiteSpace: "normal" }}>
          <b>현재 파일: </b> {trackData && trackData.name ? trackData.name : url}
        </div>
      </RowGroup>
      <RowGroup>
        <Progress
          style={{ width: "100%" }}
          percent={loadedData.loaded && loadedData.total ? (loadedData.loaded / loadedData.total) * 100 : 0}
        />
      </RowGroup>
      <ButtonGroup>
        <Input.Button
          color="danger"
          className="cancelButton"
          disabled={!failed && loadedData.loaded !== undefined && loadedData.total !== undefined && loadedData.loaded === loadedData.total}
          onClick={toClose}
        >
          닫기
        </Input.Button>
        <Input.Button color="primary" disabled={!(trackData && trackData.name) || loadedData.loaded !== undefined} onClick={onUploadFile}>
          업로드
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: ${pixelize(UNIT * 35)};
  height: ${pixelize(UNIT * 20)};
  padding: ${PADDING_XX_LARGE_PX};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${GRAY_0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RowGroup = styled.div`
  width: 100%;
  padding: ${PADDING_LARGE_PX} 0px;

  & > h2 {
    font-size: 20px;
    margin: ${pixelize(MARGING_LARGE * 1.5)} 0;
  }

  & > input {
    width: 100%;
    background-color: transparent;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }

  .alert-span {
    color: ${DANGER_COLOR};
    font-size: 12px;
  }
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: ${MARGING_SMALL_PX} ${MARGING_LARGE_PX};
  font-weight: bold;

  .cancelButton {
    margin-left: ${MARGING_LARGE_PX};
  }
`;
