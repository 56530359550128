/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { PlaylistOrderByInput } from "../../constants/PlaylistOrderByInput";

type Props = Readonly<{
  value?: PlaylistOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  PlaylistOrderByInput.playlist_id_ASC,
  PlaylistOrderByInput.playlist_id_DESC,
  PlaylistOrderByInput.name_ASC,
  PlaylistOrderByInput.name_DESC,
  PlaylistOrderByInput.kind_ASC,
  PlaylistOrderByInput.kind_DESC,
  PlaylistOrderByInput.valid_check_ASC,
  PlaylistOrderByInput.valid_check_DESC,
  PlaylistOrderByInput.service_ASC,
  PlaylistOrderByInput.service_DESC
];

export const PlaylistOrderByInputScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="PlaylistOrderByInput" value={value} isNullable={isNullable} items={items} />
);
