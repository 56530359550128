/** @jsx jsx */

import { GoodsTitleOrderByInput } from "constants/GoodsTitleOrderByInput";
import { jsx, EnumScalar } from "graphql-jsx";

type Props = Readonly<{
  value?: GoodsTitleOrderByInput;
  isNullable?: boolean;
}>;
const items = Object.values(GoodsTitleOrderByInput);

export const GoodsTitleOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="Goods_titleOrderByInput" value={value} isNullable={isNullable} items={items} />
);
