/** @jsx jsx */

import { Field, IdScalar, jsx, ListScalar, Query, render, Variable, SelectionSet } from "graphql-jsx";

import { MetadataSelectionSet } from "GraphQL/SelectionSet";
import { clients } from "utils/clients";

type TrackList = Array<{
  id: string;
  title: string;
  audioList: [{ type: string; url: string }];
}>;

export const getTrackList = async (idList: string[]) => {
  const $metadataIdList = (
    <Variable name="metadataIdList" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={idList} />
  );
  const { query, variables } = render(
    <Query operationName="GET_TRACK_LIST">
      <MetadataSelectionSet
        alias="trackList"
        argument={{
          group: true,
          first: 300,
          where: {
            AND: [
              { metadata_id__in: $metadataIdList },
              { type_metadata_class__in: ["record", "theater"] },
              { type_metadata_subclass__in: ["track", "effect"] }
            ]
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />

        <SelectionSet
          name="metadata_url"
          alias="audioList"
          argument={{ first: 1, where: { type_url__in: ["mp3high", "aac", "flac", "wav"] } }}
        >
          <Field name="type_url" alias="type" />
          <Field name="url" />
        </SelectionSet>
      </MetadataSelectionSet>
    </Query>
  );

  type Data = {
    trackList: Array<{
      id: string;
      title: string;
      audioList: [{ type: string; url: string }];
    }>;
  };
  const response = await clients.metadata.request<Data>(query, variables);
  if (response.errors) {
    window.alert("데이터를 불러오는 중 에러가 발생했습니다");
    return;
  }
  const sortedTrackList: TrackList = [];
  const trackMap = new Map(response.data!.trackList.map(track => [track.id, track]));
  idList.forEach(id => {
    trackMap.forEach(track => {
      if (track.id === id) {
        sortedTrackList.push(track);
      }
    });
  });
  return sortedTrackList.map(({ id, title, audioList: [audio] }) => ({ id, title, audio }));
};
