/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
}>;

export const DeleteComment = async (option: Option) => {
  const $uuid = <Variable name="id" scalar={<UuidScalar />} value={option.uuid} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_COMMENT">
      <SelectionSet
        name="deleteComment"
        argument={{
          where: { id: $uuid }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    deleteComment: Readonly<{
      id: string[];
    }>;
  }>;
  return await clients.access.request<Data>(query, variables);
};
