import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { YELLO_4 } from "constants/baseColor";
import { WARNING_COLOR, DANGER_COLOR } from "constants/color";

const BoxForm = styled.div`
  position: relative;
  padding-bottom: 44px;
  .album_caption {
    font-size: 1.25rem;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  .album_notice {
    margin-top: 8px;
    margin-bottom: 2px;
    color: #bababa;
  }
  .regen-uuid {
    margin-left: 4px;
    color: ${YELLO_4};
    text-decoration: underline;
    &:hover {
      color: ${WARNING_COLOR};
    }
  }
  .alert-message {
    display: none;
    color: ${DANGER_COLOR};
    margin-top: 4px;
    font-size: 0.9rem;
  }
  .alert-message.show {
    display: block;
  }
`;

const InputText = styled(Input.Text)<{ width: string }>`
  width: ${props => `${props.width}px`};
  height: 44px;
  margin-top: 4px;
  background-color: #fff;
  position: relative;
  border: 1px solid #cdcdcd;
  line-height: 22px;
  padding: 1rem;
  transition: border 0.15s;
  &:hover {
    border-color: #adadad;
  }
  &:focus {
    border-color: #ffdc00;
  }
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchButton = styled(Input.Button)`
  margin-left: 4px;
  margin-top: 3px;
  height: 42px;
  background-color: #ffdc00 !important;
  color: #000 !important;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: inline-block;
    width: 190px;
    height: 60px;
    margin: 0 3px;
    border: 1px solid #d4d4d4;
    line-height: 61px;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const InputFileForm = styled.form`
  & + & {
    margin-left: 4px;
  }
  input[type="file"] {
    display: none;
  }
  label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    height: 40px;
    background-color: #ffdc00;
    padding: 0 6px;
    font-size: 0.9rem;
  }
  .file-text {
    padding: 1rem;
    border: 1px solid #cdcdcd;
    display: inline-block;
    margin: 4px 0;
  }
`;

export const EffectStyle = { BoxForm, InputText, SearchButton, RowContainer, ButtonGroup, InputFileForm };
