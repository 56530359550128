import shajs from "sha.js";

export const createShaHash = (value: any) => new shajs.sha256().update(value).digest("hex");

export type Hash = string;

const hash = (index: number): string => (index < 26 ? String.fromCharCode(97 + index) : hash(index / 26 - 1) + hash(index % 26));

export const createHash = (() => {
  let index = 0;
  return () => hash(index++);
})();
