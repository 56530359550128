import React, { ReactNode } from "react";
import styled from "styled-components";
import { GRAY_2, GRAY_3, GRAY_4, GRAY_6 } from "constants/baseColor";
import { SUCCESS_COLOR } from "constants/color";

export type SelectMenuProps = Styleable & {
  selectCount?: number;
  selectInfoString?: string;
  isOpen?: boolean;
  isBottom?: boolean;
  children?: ReactNode;
};

export const SelectMenu = ({
  style,
  className,
  selectCount = 0,
  selectInfoString = "곡을",
  isOpen = false,
  isBottom,
  children
}: SelectMenuProps) => {
  const count = React.Children.toArray(children).length;
  return (
    <Layout style={style} count={count} className={!isOpen ? className : `select-menu-open ${className}`} isBottom={isBottom}>
      <div className="select-wrapper">
        <div className="select-left">
          선택된 <span className="select-count">{selectCount}</span>
          <span>{selectInfoString}</span>
        </div>
        <div className="select-right">{children}</div>
      </div>
    </Layout>
  );
};

SelectMenu.IconButton = styled.div<{ isWide?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => (!props.isWide ? "30%" : "23%")};
  height: 100%;
  font-size: ${props => (!props.isWide ? "0.9rem" : "0.7rem")};
  cursor: pointer;
  svg {
    width: ${props => (!props.isWide ? "1.1rem" : "1.0rem")};
    height: ${props => (!props.isWide ? "1.1rem" : "1.0rem")};
    fill: #000;
    margin-bottom: 8px;
  }
  &:hover {
    color: ${GRAY_6};
    svg {
      fill: ${GRAY_6};
    }
  }
`;

const Layout = styled.div<{ isBottom?: boolean; count?: number }>`
  /* 위치 */
  background-color: #fff;
  position: fixed;
  left: 50%;
  bottom: -20%;
  transform: translateX(-50%);
  transition: bottom 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  &.select-menu-open {
    bottom: 3%;
    ${props => (props.isBottom ? `bottom: 12%` : "")}
  }

  /* 모양  */
  width: ${props => (props.count === 3 ? "400px" : "460px")};
  height: 75px;
  border: 1px solid ${GRAY_4};
  border-radius: 12px;
  box-shadow: 0 2px 2px ${GRAY_3};

  .select-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .select-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${GRAY_2};

    .select-count {
      margin: 0 2px 2px 4px;
      font-size: 20px;
      color: ${SUCCESS_COLOR};
    }
  }
  .select-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
