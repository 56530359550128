import styled from "styled-components";
import React from "react";
import { Input } from "App/Atomics/Input";
import { MARGING_X_LARGE_PX, PADDING_LARGE_PX, PADDING_X_LARGE_PX } from "constants/size";
import { DEFAULT_BORDER_COLOR, MODAL_BACKGROUND, PRIMARY_COLOR } from "constants/color";
import { usePlaylistAutoCreateSelector } from "../../Store";
import { createPlaylist } from "./createPlaylist";
import { ServiceType } from "constants/ServiceType";
import { Toast } from "lib/toast";
import { CheckDuplicatedTypeTrackInPlaylist } from "GraphQL/Queries/Playlist/CheckDuplicatedTypeTrackInPlaylist";

const Layout = styled.div``;
const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  & > label {
    padding: ${PADDING_LARGE_PX} 0px;
    text-align: left;
    font-weight: bold;

    & > svg {
      position: relative;
      top: 4px;
    }
  }

  & > input {
    border: 1px solid ${DEFAULT_BORDER_COLOR};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${PADDING_X_LARGE_PX};
  font-size: 0.9rem;

  button {
    &:hover {
      background-color: ${MODAL_BACKGROUND};
    }
  }

  .targetService__control {
    &--is-focused {
      box-shadow: 0px 0px 1px 1px #4c52bc;
    }
  }

  .targetService__menu {
    .targetService__option {
      &--is-selected {
        background-color: ${PRIMARY_COLOR};
      }
    }
  }
`;

export const CreateTrackForm = () => {
  const { csvData } = usePlaylistAutoCreateSelector(store => store.CsvUpload);

  const loadAllPlayListInTrackIds = async (first: number, defaultPage: number) => {
    let page = defaultPage;
    let allPlayList = [];

    let endPage = defaultPage;
    while (page <= endPage) {
      //test
      // if (page > 2) {
      //   break;
      // }
      // const isEdge = endPage !== defaultPage;
      const result = await CheckDuplicatedTypeTrackInPlaylist({
        first,
        page,
        kind: "앨범 발매",
        serviceType: ServiceType.CoverLala
      });

      if (!result?.data?.metadataPlaylistRelation.length) {
        break;
      }
      const { data } = result;

      const alereadyTrackList = data.metadataPlaylistRelation.map(playlistRelation => playlistRelation.metadata[0].id);
      allPlayList.push(...alereadyTrackList);

      if (!!data.edge?.pageCount) {
        endPage = data.edge.pageCount;
      }
      page += 1;
    }

    return allPlayList;
  };

  const createAutoPlaylist = async () => {
    if (!window.confirm("정말 생성하시겠습니까?")) {
      return;
    }
    if (!csvData.length) {
      console.log("csvdata 없음");
      return;
    }

    const allPlaylistTrack = await loadAllPlayListInTrackIds(1000, 1);

    for (const data of csvData) {
      const isNotInclude = data.track_id.every(track_id => !allPlaylistTrack.includes(track_id));
      if (!isNotInclude) {
        console.log("==생략됨==");
        console.log(data);
        console.log("==생략됨==");
        continue;
      }
      await createPlaylist({
        kind: "앨범 발매",
        title: data.name,
        metadataIdList: data.track_id,
        serviceType: ServiceType.CoverLala
      });
    }
    Toast.primary("완료되었습니다.", undefined, "bottom-right");
  };
  return (
    <Layout>
      <Container>
        <LabelGroup>
          <label>플레이리스트 생성하기</label>
        </LabelGroup>
        <Input.Button color="primary" onClick={createAutoPlaylist}>
          생성하기!
        </Input.Button>
      </Container>
    </Layout>
  );
};
