/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, StringScalar, IdScalar, IntScalar } from "graphql-jsx";
import { ServiceTypeScalar, UuidScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
import { ServiceType } from "constants/ServiceType";

type Option = Readonly<{
  id: string;
  name?: string;
  kind?: string;
  service?: ServiceType;
  price?: number;
  remark?: string;
  typeUrl?: string;
  url?: string;
  order?: number;
  validCheck?: ValidType;
  titleUuid?: string;
}>;

export const UpdatePlaylist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const $name = <Variable name="name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $kind = <Variable name="kind" scalar={<StringScalar isNullable />} value={option.kind} />;
  const $service = <Variable name="service" scalar={<ServiceTypeScalar isNullable />} value={option.service} />;
  const $price = <Variable name="price" scalar={<IntScalar isNullable />} value={option.price} />;
  const $remark = <Variable name="remark" scalar={<StringScalar isNullable />} value={option.remark} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable={false} />} value={option.typeUrl} />;
  const $url = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.url} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const $validCheck = <Variable name="validCheck" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const $titleUuid = <Variable name="title_uuid" scalar={<UuidScalar isNullable />} value={option.titleUuid} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_PLAYLIST">
      <SelectionSet
        name="updatePlaylist"
        argument={{
          where: { playlist_id: $id },
          data: {
            name: $name,
            kind: $kind,
            service: $service,
            price: $price,
            remark: $remark,
            valid_check: $validCheck,
            ...(option.typeUrl &&
              option.url && { _onlySearch: true, playlist_url: { create: [{ type_url: $typeUrl, url: $url, exposure_order: $order }] } }),
            ...(option.titleUuid && {
              playlist_title: {
                update: {
                  where: { id: $titleUuid },
                  data: {
                    value: $name
                  }
                }
              }
            })
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="name" />
        <Field name="price" />
        <Field name="remark" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet name="playlist_url">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = {
    updatePlaylist: {
      id: string;
      name: string;
      price: number;
      remark: string;
      playlist_url: {
        uuid: string;
      }[];
    };
  };
  return await clients.playlist.request<Data>(query, variables);
};
