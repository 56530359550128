const WHITE_SPACE = /\s+/g;

export class GQL {
  constructor(readonly query: string) {}
}

export const gql = ([head, ...tail]: TemplateStringsArray, ...rest: readonly (null | boolean | string | number)[]) => {
  const query = tail
    .reduce((result, current, index) => result + rest[index] + current.replace(WHITE_SPACE, " "), head.replace(WHITE_SPACE, " "))
    .trim();
  if (process.env.NODE_ENV !== "production") {
    const isValid = query.startsWith("query") || query.startsWith("mutation");
    if (!isValid) {
      throw new Error("GraphQL Query는 query 혹은 mutation으로 시작해야 합니다");
    }
  }
  return new GQL(query);
};
