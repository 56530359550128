import React, { useCallback, useState } from "react";
import styled, { keyframes } from "styled-components";
import { pixelize, UNIT, MARGING_SMALL_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import axios from "axios";
import JSZip from "jszip";
import fileSaver from "file-saver";
import { UserRole } from "constants/UserRole";
import { ValidType } from "constants/ValidType";
import { useAppSelector } from "App/Store";
import { useAsyncEffect } from "lib/use-async-effect";
import { MetadataPlaylistRelation, PlaylistDetail } from "GraphQL/Queries/Playlist/MetadataPlaylistRelation";
import { usePlaylistDetailSelector } from "App/Routes/PlaylistDetail/Store";
import { shift } from "lib/shift";
import { GRAY_0, GRAY_2 } from "constants/baseColor";
import { Toast } from "lib/toast";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as CrossIcon } from "assets/icons/circle-cancel.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/circle-refresh.svg";
import { AnimatedCheckIcon } from "App/Atomics/AnimatedCheckIcon";
import { configs } from "configs";

type File = {
  uuid: string;
  id: string;
  trackId: string;
  no: string;
  name: string;
  mainName?: string;
  file: Blob;
  ext: string;
  order: number;
};

type Props = Readonly<{
  selectedMode: boolean;
  playlistId: string;
  titleType: string;
  typeUrl?: string;
  toClose: () => void;
}>;

enum TrackState {
  IDLE = "IDLE",
  DOWNLOADING = "DOWNLOADING",
  DOWNLOADED = "DOWNLOADED",
  DONE = "DONE",
  FAIL = "FAIL"
}

export const MusicDownloadModal = ({ selectedMode, playlistId, titleType, typeUrl, toClose }: Props) => {
  const { role: userRole } = useAppSelector(store => store.UserToken);
  const { order, checkList } = usePlaylistDetailSelector(store => store.PlaylistDetail);
  const [playlistData, setPlaylistData] = useState<PlaylistDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [trackState, setTrackState] = useState(new Map<string, TrackState>());
  const fileType =
    typeUrl === "flac"
      ? "audio/flac"
      : typeUrl === "aac"
      ? "audio/aac"
      : typeUrl === "mp3high"
      ? "audio/mpeg"
      : typeUrl === "wav"
      ? "audio/wav"
      : typeUrl === "txt"
      ? "text/plain"
      : "application/zip";
  const validCheckNot = userRole !== UserRole.Master && userRole !== UserRole.Arbeit ? ValidType.REMOVE : undefined;

  const fetchMetadataPlaylistRelation = useCallback(
    async (id: string, count: number, skip: number, list: PlaylistDetail[], metadataIdIn?: string[]): Promise<PlaylistDetail[]> => {
      try {
        const { data: playlistData, errors } = await MetadataPlaylistRelation({
          playlistId,
          metadataIdIn,
          skip,
          count,
          typeUrl,
          order,
          validCheckNot: validCheckNot,
          isService: userRole === UserRole.Partner ? 0 : undefined
        });
        if (errors || !playlistData) {
          throw errors;
        }
        if (!list.length) {
          list = playlistData.metadataPlaylistRelation;
        } else {
          list = [...list, ...playlistData.metadataPlaylistRelation];
        }
        if (playlistData.metadataPlaylistRelation.length === 100) {
          return fetchMetadataPlaylistRelation(id, count, skip + count, list, metadataIdIn);
        }
        return list;
      } catch (err) {
        console.log(err);
        Toast.error("음원 정보를 가져올 수 없습니다,");
        return list;
      }
    },
    [order, playlistId, typeUrl, userRole, validCheckNot]
  );

  useAsyncEffect(async () => {
    if (!playlistData.length) {
      setLoading(true);
      try {
        const metadataIdIn = !checkList.size
          ? undefined
          : Array.from(checkList)
              .filter(item => item[1])
              .map(item => item[0]);
        const playlistData = await fetchMetadataPlaylistRelation(playlistId, 100, 0, [], metadataIdIn);
        if (playlistData) {
          setPlaylistData(playlistData);
        }
      } catch (err) {
        console.log(err);
        Toast.error("음원 정보를 가져올 수 없습니다.", undefined, "top-center");
        return;
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const handleDonwload = async () => {
    try {
      if (!playlistData.length) {
        Toast.error("다운로드할 음원이 없습니다.", undefined, "top-center");
        return;
      }
      // TODO: 20개씩 음원 분할
      for (const data of shift(playlistData, 20)) {
        let files: File[] = [];
        const promiseData = data.map(async ({ id, metadata, order }) => {
          const path = metadata[0].metadataUrl.filter(data => data.typeUrl === typeUrl)[0];
          const contentType =
            typeUrl === "mp3high"
              ? "audio/mpeg"
              : typeUrl === "aac"
              ? "audio/aac"
              : typeUrl === "flac"
              ? "audio/flac"
              : typeUrl === "wav"
              ? "audio/wav"
              : typeUrl === "txt"
              ? "text/plain"
              : "application/zip";
          setTrackState(trackState => new Map(trackState.set(id, TrackState.DOWNLOADING)));
          try {
            await axios({
              method: "get",
              url: `${configs.urls.audio}/${path.url}`,
              responseType: "blob",
              headers: {
                "Content-Type": contentType
              }
            }).then(res => {
              let mainName: string | undefined = undefined;
              if (metadata[0].structures[0]?.parent[0]?.parentMetadata[0].typeSubclass !== "album") {
                mainName = metadata[0].structures[0]?.parent[0]?.parentMetadata[0].title;
              }
              const file = {
                uuid: id,
                id: path.id,
                trackId: metadata[0].metadataId,
                no: metadata[0].no,
                order: order,
                name: `${order.toString()}.${metadata[0].title.replace(/[\n/]+/g, "-")}`,
                mainName: mainName ? `${order.toString()}.${mainName.replace(/[\n/]+/g, "-")}` : undefined,
                file: new Blob([res.data], { type: fileType }),
                ext: typeUrl === "mp3high" ? "mp3" : typeUrl
              } as File;
              files.push(file);
              setTrackState(trackState => new Map(trackState.set(id, TrackState.DOWNLOADED)));
            });
          } catch (err) {
            console.log(err);
            setTrackState(trackState => new Map(trackState.set(id, TrackState.FAIL)));
          }
        });
        try {
          await Promise.all(promiseData).then(async () => {
            if (files.length) {
              const zip = new JSZip();
              for (const { uuid, trackId, name, mainName, file, ext, order } of files) {
                const mixName = mainName ? `${mainName} ${name}` : name;
                setTrackState(trackState => {
                  return new Map(trackState.set(uuid, TrackState.DONE));
                });
                console.log(titleType);
                zip.file(
                  `${
                    titleType === "id"
                      ? uuid
                      : titleType === "id_mixed"
                      ? `${order}.${trackId}`
                      : titleType === "track_id"
                      ? trackId
                      : titleType === "title"
                      ? name
                      : mixName
                  }.${ext}`,
                  file
                );
              }
              const content = await zip.generateAsync({ type: "blob" });
              const { kind, name } = playlistData[0].playlist[0];
              fileSaver.saveAs(content, `playlist${playlistId}_${kind}_${name}.zip`);
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
      setIsDone(true);
    } catch (e) {
      console.log(e);
      window.alert("데이터를 가져올 수 없습니다.");
      setIsDone(true);
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h3>음원 다운로드</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <ToolTipBox>
          <ul className="tip-box">
            <li>한 압축 파일의 최대 곡 수는 20개입니다.</li>
            <li>곡 수에 따라 많은 시간이 소요될 수 있습니다.</li>
            <li>승인되지 않은 음원은 다운로드에 포함되지 않습니다.</li>
          </ul>
        </ToolTipBox>
        {loading ? (
          <BounceLoading>
            <div className="wrap">
              <div className="loading">
                <div className="bounceball" />
                <div className="text">LOADING...</div>
              </div>
            </div>
          </BounceLoading>
        ) : (
          <ListForm>
            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "80px" }}>번호</th>
                    <th>제목</th>
                    <th style={{ width: "200px" }}>아티스트</th>
                    <th style={{ width: "80px" }}>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {playlistData.map(({ id, metadata }, index) => {
                    const { title, artists } = metadata[0];
                    const state = trackState.get(id) as TrackState;
                    return (
                      <TrackRow key={id} state={trackState.get(id) as TrackState}>
                        <td>{index + 1}</td>
                        <td className="elipse">{title}</td>
                        <td className="elipse">{artists[0]?.artist[0]?.name ?? "-"}</td>
                        <td>
                          {!state || state === TrackState.FAIL ? (
                            <CrossIcon />
                          ) : state === TrackState.DONE ? (
                            <AnimatedCheckIcon size={"1.3rem"} />
                          ) : (
                            <RefreshIcon className="spin" />
                          )}
                        </td>
                      </TrackRow>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </ListForm>
        )}
      </Section>
      <Divider>
        <div />
      </Divider>

      <ButtonGroup>
        {!isDone ? (
          <Input.Button
            className="btn"
            isFill={false}
            disabled={trackState.size !== 0}
            color={trackState.size === 0 ? "primary" : "success"}
            onClick={handleDonwload}
          >
            {trackState.size === 0
              ? "DOWNLOAD"
              : `${
                  Array.from(trackState)
                    .map(item => item[1])
                    .filter(item => item === TrackState.DONE).length
                } / ${playlistData.length}`}
          </Input.Button>
        ) : (
          <Input.Button className="btn" color="danger" isFill={false} onClick={toClose}>
            종료
          </Input.Button>
        )}
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  font-size: 0.9rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: ${pixelize(50 * UNIT)};
  height: ${pixelize(44 * UNIT)};
  align-items: center;
  padding: 1.5rem 1rem 0;
  h2 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const Header = styled.header`
  width: 100%;
  height: 4rem;
  background-color: #6a5fdd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

  .cancelIcon {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    margin-right: 4px;
    cursor: pointer;
    transition: all 0.1s;
    &:hover {
      fill: ${GRAY_2};
    }
  }
`;

const ToolTipBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
  .tip-box {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1rem;
    background-color: ${GRAY_0};
    li {
      list-style: inside;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1rem;
  .btn {
    width: 12rem;
    height: 3rem;
    border-radius: 20px;
  }
`;

const ListForm = styled.div`
  width: 100%;
  position: relative;
  .table-wrap {
    position: absolute;
    height: 550px;
    top: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      z-index: 10;
      tr {
        th {
          height: 48px;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          color: #606060;
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    tbody {
      overflow-y: scroll;
      tr {
        td {
          padding: 0 6px;
          height: 56px;
          border-bottom: 1px solid #f2f2f2;
          text-align: center;
        }
        .elipse {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
  }
`;

const bounce = keyframes`
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2);
  }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0;
  }
`;

const BounceLoading = styled.div`
  position: relative;
  width: 100%;
  height: ${pixelize(40 * UNIT)};
  .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .text {
    color: #6a5fdd;
    display: inline-block;
    margin-left: 8px;
  }

  .bounceball {
    position: relative;
    display: inline-block;
    height: 37px;
    width: 15px;
    &:before {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #6a5fdd;
      transform-origin: 50%;
      animation: ${bounce} 500ms alternate infinite ease;
    }
  }
`;

const Divider = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div {
    width: 100%;
    margin: 0 1rem;
    height: 1px;
    background-color: #ddd;
  }
`;

const spin = keyframes`
  0%  {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const TrackRow = styled.tr<{ state?: TrackState }>`
  transition: background-color 0.15s;
  background-color: ${props =>
    !props.state
      ? "#fff"
      : props.state === TrackState.DOWNLOADING
      ? "#fff9db"
      : props.state === TrackState.DOWNLOADED
      ? "#ebfbee"
      : "#e3fafc"};
  &:hover {
    ${props => (!props.state ? `background-color: ${GRAY_0}` : "")}
  }

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 6px;
  }

  .spin {
    animation: ${spin} 2s infinite linear;
  }
`;
