import React, { useState } from "react";
import styled from "styled-components";
import { BLUE_5, GRAY_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { DatePicker } from "App/Molecules/DatePicker";
import { MARGING_LARGE_PX, PADDING_X_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useAlbumSelector } from "../../Store";
import { Toast } from "lib/toast";
import dayjs from "dayjs";
import { GetMetadataLicenseInfo, GetTracksInAlbumById, UpdateTrackLicense } from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";

type Props = {
  index: number;
  onClose: () => void;
};

export const PublishDateModal = ({ index, onClose }: Props) => {
  const metadataId = useAlbumSelector(store => store.Album.albums[index].metadataId);
  const [date, setDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const onSave = async () => {
    if (!window.confirm("발매일을 수정하시겠습니까?")) return;
    try {
      const publishDate = dayjs(date).toString();
      const { data, errors } = await GetTracksInAlbumById({
        metadataId,
        first: 100,
        typeClass: "record",
        typeSubClassIn: ["track", "effect"]
      });
      if (errors || !data) {
        throw errors;
      }
      setLoading(true);
      for (const { metadataId } of data.metadata) {
        const uuidList = await GetMetadataLicenseInfo({ id: metadataId, typeClass: "record", typeSubClassIn: ["track", "effect"] });
        if (uuidList.length) {
          const id = await requestAccessRecord({ targetId: metadataId, targetTable: TargetTableInput.Metadata });
          if (id) {
            try {
              for (const uuid of uuidList) {
                await UpdateTrackLicense({ uuid, publishDate });
              }
            } catch (err) {
              throw err;
            } finally {
              await DeleteAccessRecord({ id });
            }
          }
        }
      }
      Toast.success("일괄 수정되었습니다.");
      onClose();
    } catch (err) {
      console.log(err);
      Toast.error("업데이트에 실패하였습니다.");
      return;
    } finally {
      setLoading(false);
    }
  };

  const onClearAndClose = () => {
    onClose();
  };
  return (
    <Layout>
      <Header>
        <h3>발매일 일괄 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Container>
        <div className="date-wrapper">
          <span className="date">앨범 발매일</span>
          <Picker
            maxDate={dayjs("2099-12-31").toDate()}
            defaultValue={date}
            showTimeSelect
            format="yyyy년 MM월 dd일 hh:mm:ss"
            onChange={val => {
              if (!Array.isArray(val)) {
                setDate(val);
              }
            }}
          />
        </div>
      </Container>
      <ButtonGroup>
        <Input.Button color="danger" isWide onClick={onSave}>
          해당 앨범의 전체 발매일자를 수정합니다.
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 400px;
  height: 230px;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.9rem;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cd {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
    margin-top: 3px;
    fill: ${BLUE_5};
  }
  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Container = styled.div`
  padding: ${PADDING_X_LARGE_PX};
  .date-wrapper {
    display: flex;
    flex-direction: column;
    .date {
      margin-bottom: ${MARGING_LARGE_PX};
    }
  }
`;

const ButtonGroup = styled.div`
  padding: ${PADDING_X_LARGE_PX};
`;

const Picker = styled(DatePicker)`
  input {
    width: 300px;
  }
`;
