import React, { MouseEvent } from "react";
import styled from "styled-components";
import { MARGING_LARGE_PX, MARGING_X_LARGE_PX, MARGING_SMALL_PX, PADDING_XXX_LARGE_PX } from "constants/size";
import { useAppStore } from "App/Store";
import { TableTemplate, ColumnProps } from "App/Templates/TableTemplate";
import { Table } from "App/Atomics/Table";
import { Divider } from "App/Atomics/Divider";
import { GRAY_6 } from "constants/baseColor";
import { PRIMARY_COLOR } from "constants/color";
import { LoadingTemplate, LOADING } from "App/Templates/LoadingTemplate";
import { useSettleStore } from "../../Store";
import { convertNumberToMoney } from "lib/convertNumberToMoney";
import { Input } from "App/Atomics/Input";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { TrackListModal } from "./TrackListModal";
import { GuideTooltip } from "App/Molecules/AnimatedTooltips";
import { useHistory } from "react-router";
import { SETTLEMENT_MONTHLY } from "constants/route";

type DataInfo = {
  key: string;
  value: string;
};

type Condition = {
  calculation: string;
  name: string;
  totalamount: number;
  type: number;
};

const Layout = styled.div`
  margin: 2rem;
  min-height: 84vh;
  padding: ${PADDING_XXX_LARGE_PX};
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  h1 {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
  h2 {
    margin-top: ${MARGING_LARGE_PX};
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CustomRow = styled(Table.Row)<{ name?: string }>`
  .content {
    width: 70%;
  }
  .content,
  .value {
    color: ${props => (props.name === "최종 금액" ? "#7f7fdf" : "#000")};
    font-weight: ${props => (props.name === "최종 금액" ? "bold" : "normal")};
  }

  .detail-button {
    color: ${PRIMARY_COLOR};
    font-size: 0.95rem;
    margin-left: ${MARGING_SMALL_PX};
    cursor: pointer;
  }

  .detail-text {
    color: ${GRAY_6};
    margin-left: ${MARGING_SMALL_PX};
    font-size: 0.95rem;
  }
`;

const RowGroup = styled.div`
  margin: ${MARGING_X_LARGE_PX} 0;
  .title {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const ToolTipTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};
`;

export const SettleDetail = () => {
  const [{ billInfo }] = useSettleStore(store => ({
    billInfo: store.Settle.billInfo
  }));
  const [{ loading }] = useAppStore(store => ({
    loading: store.Loading.loading
  }));
  const router = useHistory();

  const settleTracksModal = useToggle();

  const { conditionPricePerPlayingTracks, conditionPricePerMembership, conditionPricePerSales, currency } = billInfo;

  // 1. 계약 조건
  const contractDataList = [
    {
      key: "요율",
      value: billInfo.contractRate === undefined ? "-" : `${(billInfo.contractRate * 100).toFixed(1)} %`
    },
    billInfo.playingRate === 0 || billInfo.playingRate
      ? {
          key: "재생 수 당 단가",
          value: `${convertNumberToMoney(billInfo.playingRate)} 원`
        }
      : null,
    billInfo.subscriberRate === 0 || billInfo.subscriberRate
      ? {
          key: "가입자 당 단가",
          value: `${convertNumberToMoney(billInfo.subscriberRate)} 원`
        }
      : null,
    billInfo.price === 0 || billInfo.price
      ? {
          key: "요금제 단가",
          value: `${convertNumberToMoney(billInfo.price)} ${currency === "KRW" ? "원" : "$"}`
        }
      : null
  ];

  // 2. 월별 REPORT
  const monthDataList = [
    {
      key: "요금제 월 매출",
      value: !billInfo.totalSalesAmount ? "0 원" : `${convertNumberToMoney(billInfo.totalSalesAmount)} 원`
    },
    {
      key: "공급자 음원 사용 수",
      value: !billInfo.specificplayedtrackscountpermonth
        ? "0 회"
        : `${convertNumberToMoney(+billInfo.specificplayedtrackscountpermonth)} 회`
    },
    {
      key: "전체 음원 사용 수",
      value: !billInfo.totalplayedtrackscountpermonth ? "0 회" : `${convertNumberToMoney(+billInfo.totalplayedtrackscountpermonth)} 회`
    },
    {
      key: "요금제 음원 가입자 수",
      value: !billInfo.membershipCount ? "0 명" : `${convertNumberToMoney(billInfo.membershipCount)} 명`
    }
  ];

  // 3. 정산 REPORT
  const settleDataList = [
    conditionPricePerSales
      ? {
          key: "요율 기준",
          value: `${convertNumberToMoney(conditionPricePerSales.totalamount)} 원`
        }
      : null,
    conditionPricePerPlayingTracks
      ? {
          key: "재생 수당 단가 기준",
          value: `${convertNumberToMoney(conditionPricePerPlayingTracks.totalamount)} 원`
        }
      : null,
    conditionPricePerMembership
      ? {
          key: "가입 수당 단가 기준",
          value: `${convertNumberToMoney(conditionPricePerMembership.totalamount)} 원`
        }
      : null,

    {
      key: "최종 금액",
      value: `${convertNumberToMoney(billInfo.resultAmount)} 원`
    }
  ];

  const openSettleTracksModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    settleTracksModal.on();
  };

  const calculationName = [
    ...[conditionPricePerMembership ?? ({} as Condition)],
    ...[conditionPricePerPlayingTracks ?? ({} as Condition)],
    ...[conditionPricePerSales ?? ({} as Condition)]
  ].find(item => item?.totalamount === billInfo.resultAmount)?.name;

  const dataHeadList = [{ name: "항목" }, { name: "값" }];
  const dataTemplate = ({ index, data }: ColumnProps<DataInfo>) => {
    return (
      <CustomRow name={data && data.key}>
        {!data ? null : (
          <>
            <Table.Data className="content">
              {data.key === "공급자 음원 사용 수" ? (
                <>
                  <span>{data.key}</span>
                  <button className="detail-button" onClick={openSettleTracksModal}>
                    {"(음원상세 보기)"}
                  </button>
                </>
              ) : data.key === "요금제 월 매출" ? (
                <>
                  <span>{data.key}</span>
                  <span className="detail-text">{"(VAT 별도)"}</span>
                </>
              ) : data.key === "요금제 단가" ? (
                <>
                  <span>{data.key}</span>
                  <span className="detail-text">{"(월, VAT 별도)"}</span>
                </>
              ) : (
                <span>{data.key}</span>
              )}
            </Table.Data>
            <AlignCenterData className="value">{data.value}</AlignCenterData>
          </>
        )}
      </CustomRow>
    );
  };
  return (
    <Layout>
      <Header>
        <h1>정산 상세 내역</h1>
        <Input.Button color="primary" onClick={() => router.push(SETTLEMENT_MONTHLY)}>
          이전 페이지
        </Input.Button>
      </Header>
      <Divider />
      {loading === LOADING.UNLOAD ? (
        <LoadingTemplate loading={loading} />
      ) : (
        <>
          <RowGroup>
            <h3 className="title">1. 계약 조건</h3>
            <TableTemplate headList={dataHeadList} keyBy={(data, index) => index} dataList={contractDataList} Column={dataTemplate} />
          </RowGroup>
          <RowGroup>
            <h3 className="title">2. 월별 레포트</h3>
            <TableTemplate headList={dataHeadList} keyBy={(data, index) => index} dataList={monthDataList} Column={dataTemplate} />
          </RowGroup>
          <RowGroup>
            <ToolTipTitle className="title">
              <h3>3. 정산 레포트</h3>
              {!calculationName ? null : <GuideTooltip text={calculationName} direction="right" />}
            </ToolTipTitle>
            <TableTemplate headList={dataHeadList} keyBy={(data, index) => index} dataList={settleDataList} Column={dataTemplate} />
          </RowGroup>
        </>
      )}
      <Modal isOpen={settleTracksModal.isToggled}>
        <TrackListModal toClose={settleTracksModal.off} />
      </Modal>
    </Layout>
  );
};
