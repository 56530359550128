import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateActions, TypeInfo } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { GoodsType as TypeGoodsType } from "models/GoodsModel";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";

type Props = {
  readonly goodsTypes: TypeGoodsType[];
  readonly typeInfo: TypeInfo;
};

export const GoodsType = ({ goodsTypes, typeInfo }: Props): ReactElement => {
  const typeOptionList = goodsTypes.map(item => ({ id: item.id.toString(), name: item.displayType, extra: item.category }));
  return (
    <Style.Row>
      <Style.Caption>타입 *</Style.Caption>
      <Style.SubCaption>괄호( ) 안은 카테고리입니다.</Style.SubCaption>
      <Style.Select
        isDisabled={false}
        style={SelectStyle}
        key={`type-${typeInfo?.id}`}
        defaultValue={(typeOptionList as any[]).find(({ id }) => id === typeInfo?.id)}
        optionList={typeOptionList}
        placeholder="굿즈 타입"
        onChange={info => info && GoodsCreateStore.dispatch(GoodsCreateActions.setTypeInfo(info))}
      />
    </Style.Row>
  );
};
