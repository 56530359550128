import { createReducer, createHandler, createActions } from "lib/store";

type State = ReturnType<typeof createInitialState>;

type Artist = any;

const SET_ARTIST_SEARCH_COLUMN = Symbol("Artist::SearchColumn::Set");

export const AppArtistActions = createActions({
  setArtistSearchColumn(column: string) {
    return { type: SET_ARTIST_SEARCH_COLUMN, column };
  }
});

const handler = createHandler<State>({
  [SET_ARTIST_SEARCH_COLUMN](state, payload: { column: string }) {
    state.column = payload.column;
  }
});

const createInitialState = () => ({
  column: "이름" as string
});

export const reducer = createReducer(handler, createInitialState);
