import { Reducer } from "react";

type Reducers<S> = Readonly<{ [Key in keyof S]: Reducer<S[Key], any> }>;

export type Action = Readonly<{ type: symbol }>;
export type ActionList = readonly [Action, ...readonly Action[]];

export type AfterDispatch<S> = (
  previousStore: S,
  nextStore: S,
  action: Readonly<{ type: string | symbol }>,
  isChanged: boolean
) => void | Promise<void>;
export type AfterChange<S> = (previousStore: S, nextStore: S) => void | Promise<void>;

/**
 * 여러 reducer를 하나의 reducer인 것처럼 작동하게 해주는 함수
 *
 * @param reducers key-value 형태의 reducer 오브젝트
 * @param afterChange dispatch 후 store의 변화가 있으면 호출하는 callback 함수
 */
export const combineReducers = <S extends Record<string, any>, A extends Action | ActionList>(
  reducers: Reducers<S>,
  afterDispatch?: AfterDispatch<any>,
  afterChange?: AfterChange<any>
) => {
  const reducer: Reducer<S, A> = (previousStore = {} as S, action) => {
    const actions = Array.isArray(action) ? (action as ActionList) : [action as Action];
    const nextStore = {} as S;

    let isChanged = false;
    for (const action of actions) {
      let isChangedLocal = false;
      for (const key in reducers) {
        const reducer = reducers[key];
        const previousState = nextStore[key] || previousStore[key];
        const nextState = reducer(previousState, action);
        nextStore[key] = nextState;
        if (!isChangedLocal) {
          isChangedLocal = previousState !== nextState;
        }
      }
      if (!isChanged) {
        isChanged = isChangedLocal;
      }
      if (process.env.NODE_ENV !== "production" && afterDispatch) {
        afterDispatch(previousStore, nextStore, action, isChangedLocal);
      }
    }
    if (isChanged && afterChange) {
      afterChange(previousStore, nextStore);
    }

    return isChanged ? nextStore : previousStore;
  };

  return reducer;
};
