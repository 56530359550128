/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IntScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  url: string;
  order: number;
}>;

export const UpdateGoodsUrl = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar isNullable={false} />} value={option.uuid} />;
  const $url = <Variable name="artist_id" scalar={<StringScalar isNullable />} value={option.url} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS_URL">
      <SelectionSet
        name="updateGoods_url"
        alias="updateGoodsUrl"
        argument={{
          where: { id: $id },
          data: {
            url: $url,
            exposure_order: $order
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoodsUrl: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
