import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSettleSelector } from "../../../Store";
import { useAsyncEffect } from "lib/use-async-effect";
import { TrackPlayCount } from "GraphQL/Queries";
import { ServiceType } from "constants/ServiceType";
import { TableTemplate, ColumnProps } from "App/Templates/TableTemplate";
import { Table } from "App/Atomics/Table";
import { GRAY_0, GRAY_4 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { TrackInfo } from "GraphQL/Queries/Access/GetAccount";
import { KeyInfo } from "lib/key-info";
import { Button } from "App/Atomics/Input/Button";
import { handleDownloadCsv } from "./handleDownloadCsv";

type Props = {
  toClose: () => void;
};

export const TrackListModal = ({ toClose }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trackPlayCount, setTrackPlayCount] = useState<TrackPlayCount[]>([]);
  const { dateInfo, companyInfo, billInfo } = useSettleSelector(store => store.Settle);
  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      const { data, errors } = await TrackPlayCount({
        service: billInfo.service as ServiceType,
        productId: billInfo.product_id.toString(),
        priceId: billInfo.price_id.toString(),
        startDate: dateInfo.fromDate,
        endDate: dateInfo.toDate,
        companyId: companyInfo.company?.[0]?.id
      });
      if (errors) {
        console.log(errors);
        return;
      }
      if (data) {
        setTrackPlayCount(data.trackPlayCount);
      }
    }
  }, []);

  const trackDataTemplate = ({ data, index }: ColumnProps<TrackInfo>) => {
    const { id_track, metadata_id, title, count } = data;
    return (
      <Table.Row>
        <>
          <AlignCenterData>{metadata_id}</AlignCenterData>
          <AlignCenterData>{id_track ? id_track : "-"}</AlignCenterData>
          <AlignCenterData>{`${count}회`}</AlignCenterData>
          <CustomData>
            <span>{title}</span>
          </CustomData>
        </>
      </Table.Row>
    );
  };

  const HEAD_LIST = [{ name: "#" }, { name: "아이디" }, { name: "재생 횟수" }, { name: "타이틀" }];

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (KeyInfo.from(e).isEscape) {
        toClose();
      }
    },
    [toClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyPress);
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [onKeyPress]);

  return (
    <Layout>
      <Header>
        <h3>공급자 사용 음원 정보</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>

      <ToolTipBox>
        <ul className="tip-box">
          <li>{`총 재생된 곡은 ${billInfo.specificPlayedTracksEachCountPerMonth.length}곡입니다.`}</li>
          <li>{`총 재생된 횟수는 ${billInfo.specificplayedtrackscountpermonth} 회입니다.`}</li>
        </ul>
      </ToolTipBox>
      <CsvButton color="success" isFill={false} onClick={() => handleDownloadCsv(billInfo, dateInfo)}>
        CSV 다운로드
      </CsvButton>
      <TableTemplate
        headList={HEAD_LIST}
        keyBy={(_, index) => index}
        dataList={billInfo.specificPlayedTracksEachCountPerMonth}
        Column={trackDataTemplate}
      />
    </Layout>
  );
};

const Layout = styled.div`
  overflow-y: scroll;
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 0.8rem;
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: #6a5fdd;
  color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 2;
  font-size: 1rem;

  .cancelIcon {
    position: absolute;
    right: 2%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: #fff;
    cursor: pointer;
    transition: all 0.15s;
    &:hover {
      transform: scale(1.1);
      fill: ${GRAY_4};
    }
  }
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;

const CustomData = styled(Table.Data)`
  span {
    width: 75vw;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ToolTipBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0.5rem;
  width: 100%;
  .tip-box {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1rem;
    background-color: ${GRAY_0};
    li {
      list-style: inside;
      font-size: 0.95rem;
    }
  }
`;

const CsvButton = styled(Button)`
  margin-left: 8px;
  margin-bottom: 8px;
`;
