/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { ServiceTypeScalar } from "GraphQL/Scalars";
import { ServiceType } from "constants/ServiceType";

type Option = Readonly<{
  metadataIdIn: string[];
  serviceType?: ServiceType;
  kind: string;
}>;

export const CheckDuplicatedTrackInPlaylist = async (option: Option) => {
  const $serviceType = <Variable name="serviceType" scalar={<ServiceTypeScalar value={option.serviceType} />} />;
  const $kind = <Variable name="kind" scalar={<StringScalar />} value={option.kind} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_TRACK">
      <SelectionSet
        name="metadata_playlist_relation"
        alias="metadataPlaylistRelation"
        argument={{
          group: true,
          first: option.metadataIdIn.length,
          where: {
            metadata__some: {
              metadata_id__in: option.metadataIdIn ?? []
            },
            playlist__some: {
              service: $serviceType,
              kind: $kind
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataPlaylistRelation: {
      metadata: {
        id: string;
      }[];
    }[];
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
