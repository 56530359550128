/** @jsx jsx */

import { jsx, render, Field, Variable, StringScalar, Mutation, SelectionSet, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { TargetTableInput } from "constants/TargetTableInput";
import { TargetTableInputScalar, UuidScalar } from "GraphQL/Scalars";
import { Comment } from "./GetComment";

type Option = Readonly<{
  targetId: string;
  targetTable: TargetTableInput;
  value: string;
  parentId?: string;
}>;

export const CreateComment = async (option: Option) => {
  const $targetId = <Variable name="target_id" scalar={<IdScalar isNullable={false} />} value={option.targetId} />;
  const $targetTable = <Variable name="target_table" scalar={<TargetTableInputScalar isNullable={false} />} value={option.targetTable} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $parentId = <Variable name="parent_id" scalar={<UuidScalar isNullable />} value={option.parentId} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_COMMENT">
      <SelectionSet
        name="createComment"
        argument={{
          data: { target_id: $targetId, target_table: $targetTable, value: $value, parent_id: $parentId }
        }}
      >
        <Field name="id" alias="uuid" />
        <Field name="target_id" alias="targetId" />
        <Field name="target_table" alias="targetTable" />
        <Field name="value" />
        <Field name="timestamp" />
        <Field name="root_id" alias="rootId" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createComment: Comment;
  }>;
  return await clients.access.request<Data>(query, variables);
};
