import { Permission } from "models/Permission";
import { AppStore } from "App/Store";

export const allowMetadataCreate = (store: AppStore) => {
  const { allowTables } = store.UserToken;
  for (const allowTable of allowTables) {
    const permission = new Permission(allowTable.allow.split(""));
    switch (allowTable.table) {
      case "metadata":
        if (
          !permission.isUserReadable() ||
          !permission.isUserEditable() ||
          !permission.isGroupReadable() ||
          !permission.isGroupEditable() ||
          !permission.isOtherWritable()
        ) {
          return false;
        }
        break;

      case "metadata_title":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "metadata_structure":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "metadata_artist_relation":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "metadata_genre_relation":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "metadata_company_relation":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "metadata_url":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      case "users":
        if (!permission.isUserReadable()) {
          return false;
        }
        break;
      default:
        break;
    }
  }
  return true;
};
