import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { VideoCreateStyle } from "App/Routes/VideoCreate/Style";
import React from "react";

type Props = {
  readonly artist: Info;
};

export const ArtistForm = ({ artist }: Props) => (
  <VideoCreateStyle.Row>
    <VideoCreateStyle.Caption>아티스트 *</VideoCreateStyle.Caption>
    <VideoCreateStyle.SubCaption>* 변경은 관리자에게 문의해주세요.</VideoCreateStyle.SubCaption>
    <ArtistTextAutoComplete isDisabled defaultValue={artist} />
  </VideoCreateStyle.Row>
);
