import { TargetTableInput } from "constants/TargetTableInput";
import { CreateMetadataUrl, DeleteAccessRecord, UploadFile } from "GraphQL/Queries";
import { CreateVideo } from "GraphQL/Queries/Metadata/CreateVideo";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { Toast } from "lib/toast";
import { useToggle } from "lib/use-toggle";
import { useVideoCreateSelector } from "../Store";
import axios from "axios";
import { createFormData } from "lib/createFormData";

export const useCreateVideo = () => {
  const loading = useToggle(false);
  const { name, artist, role, company, url } = useVideoCreateSelector(store => store.VideoCreate);

  const createImage = async (id: string) => {
    const companyId = company.id;
    const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
    if (!accessId) {
      Toast.error("영상 등록에 실패하였습니다.");
      return;
    }
    const { data } = await UploadFile({ filename: `metadata/${id}/video/video.mp4`, companyId });
    if (!data?.uploadFile) {
      await DeleteAccessRecord({ id: accessId });
      Toast.error("영상 업로드에 실패하였습니다.");
    }
    const bucket = data!.uploadFile;
    await axios({
      method: "post",
      url: bucket.url,
      data: createFormData(bucket, url!.file),
      headers: {
        "Content-Type": "video/mp4"
      }
    });
    await CreateMetadataUrl({ metadataId: id, typeUrl: "video", url: data!.uploadFile.key, order: 0 });
    await DeleteAccessRecord({ id: accessId });
  };

  const createVideo = async () => {
    if (!name?.length || !artist?.id || !role.id || !company?.id || !url?.file) {
      Toast.warning("모든 값을 입력해주세요.");
      return;
    }
    try {
      loading.on();
      const { data, error } = await CreateVideo({
        title: name,
        class: "record",
        subClass: "video",
        artist: artist.id,
        role: role.id,
        company: company.id
      });
      if (!data || error) {
        throw new Error(error?.message);
      }
      const { metadata_id: id } = data.createMetadata;
      await createImage(id);
      Toast.success("등록되었습니다.");

      if (window.confirm("초기화하시겠습니까?")) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    } finally {
      loading.off();
    }
  };

  return { createVideo, loading };
};
