import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Input } from "App/Atomics/Input";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { WorkTextAutoComplete } from "App/Molecules/AutoCompletes/Work";
import { useAlbumStore } from "App/Routes/AlbumRenewal/Store";
import { SingleTrackActions } from "App/Routes/AlbumRenewal/Store/SingleTrack";
import { VIOLET_4, VIOLET_2, VIOLET_3, BLUE_6 } from "constants/baseColor";
import { PADDING_SMALL_PX } from "constants/size";
import { CreateSingleTrack } from "GraphQL/Queries/Track/CreateSingleTrack";
import { Toast } from "lib/toast";
import { s3SingleUpload } from "App/Routes/SingleAlbumCreate/Query/s3SingleUpload";
import { createFormData } from "lib/createFormData";
import { Loading } from "App/Atomics/Loading";
import { CheckDuplicatedNo, UpdateMetadataUrl } from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import dayjs from "dayjs";

type Props = {
  index: number;
  toClose: () => void;
};

type FileInfo = Readonly<{
  ext: string;
  accept: string;
}>;

const SubClassOptionList = [
  { id: "track", name: "트랙" },
  { id: "effect", name: "효과음" }
];

export const SingleTrackForm = ({ index, toClose }: Props) => {
  const [{ album, singleTrack }, dispatch] = useAlbumStore(store => ({
    album: store.Album.albums[index],
    singleTrack: store.SingleTrack.singleTrack
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [isValidNo, setIsValidNo] = useState<boolean>(false);
  const currentYear = dayjs(new Date()).format("YYYY");

  const getFileInfo = useCallback((typeUrl: string) => {
    switch (typeUrl) {
      case "mp3high":
        return { ext: "mp3", accept: "audio/mpeg" } as FileInfo;
      case "aac":
        return { ext: "m4a", accept: "audio/x-m4a" } as FileInfo;
      case "flac":
        return { ext: "flac", accept: "audio/flac" } as FileInfo;
      case "wav":
        return { ext: "wav", accept: "audio/wav" } as FileInfo;
      default:
        return { ext: "", accept: "" };
    }
  }, []);

  const checkDuplicatedNo = async (no?: string) => {
    if (!no) {
      Toast.warning("값을 입력해주세요.", undefined, "top-center");
      return;
    }
    if (!no.match(/^[0-9]*$/g)) {
      Toast.error("숫자만 입력해주세요", undefined, "top-center");
      return;
    }
    try {
      setLoading(true);
      const parentId = parseInt(album.structures[0].structureId, 10);
      const { data, errors } = await CheckDuplicatedNo({ parentId, no });
      if (errors) {
        throw errors[0].message;
      }
      if (data && data.metadata.length) {
        Toast.error("중복된 no가 있습니다. 다른 no를 입력해주세요!", undefined, "top-center");
      } else {
        Toast.primary("사용가능한 no입니다.", undefined, "top-center");
        setIsValidNo(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      Toast.error("중복 검사에 실패하였습니다.");
      return;
    }
  };

  const isDisableButton = () => {
    const { subClass, no, title, artist, rights, record, trackUrl } = singleTrack;
    return !no || !title || (subClass === "track" && !artist) || !rights || !record || !trackUrl;
  };

  const onCreateTrack = useCallback(async () => {
    const parentId = parseInt(album.structures[0].structureId, 10);
    const { subClass, no, title, artist, rights, record, publish, edition, trackUrl, work } = singleTrack;
    setLoading(true);

    try {
      const { data: trackData, errors } = await CreateSingleTrack({
        no: no!,
        title: title!,
        class: "record",
        subClass: subClass,
        parentId,
        artistId: subClass === "track" ? artist!.id : undefined,
        workIds: subClass === "track" && work?.id ? [work.id] : undefined,
        companyId: rights!.id,
        recordYear: +record!,
        publishYear: +publish!,
        firstEdition: +edition!,
        mp3Url: trackUrl.trackMp3.url,
        aacUrl: trackUrl.trackAac.url,
        flacUrl: trackUrl.trackFlac.url,
        wavUrl: trackUrl.trackWav.url
      });
      if (errors) {
        throw new Error(errors[0].message);
      }
      if (trackData) {
        const accessId = await requestAccessRecord({ targetId: trackData.createTrack.id, targetTable: TargetTableInput.Metadata });
        if (accessId) {
          for (const [key, track] of Object.entries(trackUrl)) {
            if (track && track.url) {
              const { data, errors } = await s3SingleUpload({
                filename: track.url,
                companyId: album.rightsCompany[0].company[0].id!,
                action: "INSERT"
              });
              if (errors) {
                throw errors[0].message;
              }
              if (data) {
                try {
                  await axios({
                    method: "post",
                    url: data.singleUpload.url,
                    data: createFormData(data.singleUpload, track.file),
                    headers: {
                      "Content-Type": getFileInfo(track.typeUrl).accept
                    }
                  });
                  const typeUrl = key === "trackMp3" ? "mp3high" : key === "trackAac" ? "aac" : key === "trackFlac" ? "flac" : "wav";
                  const uuid = trackData.createTrack.metadata_url.find(item => item.typeUrl === typeUrl)!.uuid;
                  await UpdateMetadataUrl({ uuid: uuid, url: data.singleUpload.key });
                } catch (err) {
                  console.log(err);
                  Toast.error("음원 업로드에 실패하였습니다.");
                  setLoading(false);
                  return;
                }
              }
            }
          }
          await DeleteAccessRecord({ id: accessId });
        }
        dispatch(SingleTrackActions.setSingleTrackClear());
        Toast.primary("음원이 추가되었습니다.");
        setLoading(false);
        toClose();
      }
    } catch (err) {
      console.log(err);
      Toast.error("트랙 생성에 실패하였습니다.");
      setLoading(false);
      return;
    }
  }, [album.rightsCompany, album.structures, dispatch, getFileInfo, singleTrack, toClose]);

  useEffect(() => {
    if (!singleTrack.record && !singleTrack.publish && !singleTrack.edition) {
      dispatch([
        SingleTrackActions.setSingleTrackRecord(currentYear),
        SingleTrackActions.setSingleTrackPublish(currentYear),
        SingleTrackActions.setSingleTrackEdition(currentYear)
      ]);
    }
  }, [currentYear, dispatch, singleTrack.edition, singleTrack.publish, singleTrack.record]);

  return (
    <Layout>
      <LabelSelect>
        <span>타입</span>
        <Input.TextSelect
          key={singleTrack.subClass}
          className="select"
          style={selectStyle}
          placeholder="트랙의 제목을 입력해주세요."
          optionList={SubClassOptionList}
          defaultValue={SubClassOptionList.find(({ id }) => id === singleTrack.subClass)}
          onChange={info => {
            if (info) {
              dispatch(SingleTrackActions.setSingleTrackSubClass(info.id));
            }
          }}
        />
      </LabelSelect>
      <InputText isValid={isValidNo}>
        <span>No</span>
        <div className="no">
          <Input.Text
            key={singleTrack.no}
            className="input-text"
            placeholder="트랙의 번호를 입력해주세요."
            defaultValue={singleTrack.no}
            onChange={() => setIsValidNo(false)}
            onBlur={val => {
              dispatch(SingleTrackActions.setSingleTrackNo(val));
              Object.entries(singleTrack.trackUrl).forEach(([key, url]) => {
                if (url.file) {
                  const typeUrl = key === "trackMp3" ? "mp3high" : key === "trackAac" ? "aac" : key === "trackFlac" ? "flac" : "wav";
                  const newUrl = `${album.no}/${typeUrl}/${val}.${url.ext}`;
                  dispatch(SingleTrackActions.updateSingleTrackUrl({ key, url: newUrl }));
                }
              });
            }}
          />
          <button type="button" className="checkbtn" onClick={() => checkDuplicatedNo(singleTrack.no)}>
            중복 검사
          </button>
        </div>
      </InputText>

      <InputText>
        <span>제목</span>
        <Input.Text
          className="input-text"
          placeholder="트랙의 제목을 입력해주세요."
          defaultValue={singleTrack.title}
          onBlur={val => dispatch(SingleTrackActions.setSingleTrackTitle(val))}
        />
      </InputText>
      {singleTrack.subClass === "track" && (
        <InputText>
          <span>메인 아티스트</span>
          <ArtistTextAutoComplete
            style={selectStyle}
            defaultValue={singleTrack.artist}
            onChange={info => {
              if (info) {
                dispatch(SingleTrackActions.setSingleTrackArtist(info));
              }
            }}
          />
        </InputText>
      )}
      {singleTrack.subClass === "track" && (
        <InputText>
          <span>작품</span>
          <WorkTextAutoComplete
            style={selectStyle}
            defaultValue={singleTrack.work}
            onChange={info => {
              if (info) {
                dispatch(SingleTrackActions.setSingleTrackWork(info));
              }
            }}
          />
        </InputText>
      )}
      <InputText>
        <span>권리사</span>
        <CompanyTextAutoComplete
          style={selectStyle}
          defaultValue={singleTrack.rights}
          onChange={info => {
            if (info) {
              dispatch(SingleTrackActions.setSingleTrackRights(info));
            }
          }}
        />
      </InputText>
      <InputText>
        <span>녹음 / 발매 / 초판 연도</span>
        <div className="year-wrapper">
          <Input.Text
            key={singleTrack.record}
            className="input-text"
            placeholder="녹음연도를 입력하세요."
            defaultValue={singleTrack.record}
            onBlur={year => dispatch(SingleTrackActions.setSingleTrackRecord(year))}
          />
          <Input.Text
            key={singleTrack.publish}
            className="input-text"
            placeholder="발매연도를 입력하세요."
            defaultValue={singleTrack.publish}
            onBlur={year => dispatch(SingleTrackActions.setSingleTrackPublish(year))}
          />
          <Input.Text
            key={singleTrack.edition}
            className="input-text"
            placeholder="초판연도를 입력하세요."
            defaultValue={singleTrack.edition}
            onBlur={year => dispatch(SingleTrackActions.setSingleTrackEdition(year))}
          />
        </div>
      </InputText>
      <ButtonGroup>
        <Input.Button disabled={isDisableButton() || !isValidNo} color="default" isWide onClick={onCreateTrack}>
          트랙 등록
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  padding: 1rem 2rem;
  position: relative;
  height: 90%;
`;

const LabelSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin: 1rem 0;
  span {
    color: #999;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const InputText = styled.div<{ isValid?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  span {
    color: #999;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .no {
    width: 100%;
    position: relative;
    .checkbtn {
      color: ${props => (!props.isValid ? DANGER_COLOR : SUCCESS_COLOR)};
      font-size: 0.9rem;
      position: absolute;
      height: 2.5rem;
      right: 2.3rem;
      top: 5px;
      background: #fff;
      border-radius: 8px;
      padding: 0.5rem;
      transition: all 0.2s;
      &:hover {
        box-shadow: 0 0 4px ${VIOLET_2};
      }
      &:active {
        box-shadow: 0 0 4px ${VIOLET_3};
        transform: translateY(1.5px);
      }
    }
    &::after {
      content: "";
      display: inline-block;
      ${props =>
        props.isValid === false
          ? `background: rgba(0, 0, 0, 0)
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29ucyAvIFNldHRpbmdzIC8gSW52YWxpZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy0vLVNldHRpbmdzLS8tSW52YWxpZCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IngtY2lyY2xlLWYiIGZpbGw9IiNEQjQyNDEiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNy4wNzA1NTU2LDE3LjA3IEMxMy4xODE2NjY3LDIwLjk1ODg4ODkgNi44MTgzMzMzMywyMC45NTg4ODg5IDIuOTI5NDQ0NDQsMTcuMDcgQy0wLjk1ODg4ODg4OSwxMy4xODE2NjY3IC0wLjk1ODg4ODg4OSw2LjgxODMzMzMzIDIuOTI5NDQ0NDQsMi45Mjk0NDQ0NCBDNi44MTgzMzMzMywtMC45NTg4ODg4ODkgMTMuMTgxNjY2NywtMC45NTg4ODg4ODkgMTcuMDcsMi45Mjk0NDQ0NCBDMjAuOTU4ODg4OSw2LjgxODMzMzMzIDIwLjk1ODg4ODksMTMuMTgxNjY2NyAxNy4wNzA1NTU2LDE3LjA3IEwxNy4wNzA1NTU2LDE3LjA3IFogTTEzLjg5Mzg4ODksNy42NjM4ODg4OSBMMTIuMzM2MTExMSw2LjEwNjExMTExIEwxMCw4LjQ0Mjc3Nzc4IEw3LjY2Mzg4ODg5LDYuMTA2MTExMTEgTDYuMTA2NjY2NjcsNy42NjM4ODg4OSBMOC40NDI3Nzc3OCwxMCBMNi4xMDY2NjY2NywxMi4zMzYxMTExIEw3LjY2Mzg4ODg5LDEzLjg5Mzg4ODkgTDEwLDExLjU1NzIyMjIgTDEyLjMzNjExMTEsMTMuODkzODg4OSBMMTMuODkzODg4OSwxMi4zMzYxMTExIEwxMS41NTcyMjIyLDEwIEwxMy44OTM4ODg5LDcuNjYzODg4ODkgTDEzLjg5Mzg4ODksNy42NjM4ODg4OSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
      no-repeat scroll 0% 0%`
          : props.isValid === true
          ? `background: rgba(0, 0, 0, 0)
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5JY29ucyAvIFNldHRpbmdzIC8gVmFsaWQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iSWNvbnMtLy1TZXR0aW5ncy0vLVZhbGlkIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iY2hlY2stY2lyY2xlLWYiIGZpbGw9IiMzQ0FBRkYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMCwxOS45OTg4ODg5IEM0LjUwMDU1NTU2LDE5Ljk5ODg4ODkgMC4wMDExMTExMTExMSwxNS40OTg4ODg5IDAuMDAxMTExMTExMTEsOS45OTk0NDQ0NCBDMC4wMDExMTExMTExMSw0LjUwMTExMTExIDQuNTAwNTU1NTYsMC4wMDExMTExMTExMSAxMCwwLjAwMTExMTExMTExIEMxNS40OTk0NDQ0LDAuMDAxMTExMTExMTEgMTkuOTk4ODg4OSw0LjUwMTExMTExIDE5Ljk5ODg4ODksOS45OTk0NDQ0NCBDMTkuOTk4ODg4OSwxNS40OTg4ODg5IDE1LjQ5OTQ0NDQsMTkuOTk4ODg4OSAxMCwxOS45OTg4ODg5IEwxMCwxOS45OTg4ODg5IFogTTEzLjM5Nzc3NzgsNi4xMTA1NTU1NiBMOC4xMTk0NDQ0NCwxMS4yOTYxMTExIEw2LjA0NjY2NjY3LDkuMjYgTDQuNDQzODg4ODksMTAuODM0NDQ0NCBMOC4xMTk0NDQ0NCwxNC40NDUgTDkuNzIyMjIyMjIsMTIuODcwNTU1NiBMMTUuMDAwNTU1Niw3LjY4NTU1NTU2IEwxMy4zOTc3Nzc4LDYuMTEwNTU1NTYgTDEzLjM5Nzc3NzgsNi4xMTA1NTU1NiBaIE04LjExOTQ0NDQ0LDExLjI5NjExMTEgTDguMTE5NDQ0NDQsMTEuMjk2MTExMSBMOS43MjIyMjIyMiwxMi44NzA1NTU2IEw4LjExOTQ0NDQ0LDExLjI5NjExMTEgTDguMTE5NDQ0NDQsMTEuMjk2MTExMSBaIiBpZD0iU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==")
      no-repeat scroll 0% 0%`
          : ""};
      position: absolute;
      top: 50%;
      right: 12px;
      bottom: auto;
      width: 20px;
      height: 20px;
      margin-top: -10px;
    }
  }
  .input-text {
    width: 100%;
    height: 3rem;
    padding: 0.6rem;
    border: 1px solid hsl(210 6% 83%);
    border-radius: 8px;
    transition: all 0.15s;
    &:hover {
      border-color: hsl(210 6% 67%);
    }
    &:focus {
      border-color: ${BLUE_6};
    }
  }
  .duplicate-box {
    width: 60%;
    position: relative;
    input {
      width: 100%;
      padding-right: 75px;
    }
    .checkbtn {
      color: ${VIOLET_4};
      font-size: 0.9rem;
      position: absolute;
      height: 2.5rem;
      right: 2%;
      top: 5px;
      background: #fff;
      border-radius: 8px;
      padding: ${PADDING_SMALL_PX};
      transition: all 0.2s;
      &:hover {
        box-shadow: 0 0 4px ${VIOLET_2};
      }
      &:active {
        box-shadow: 0 0 4px ${VIOLET_3};
        transform: translateY(1.5px);
      }
    }
  }
  .year-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 32.8%);
    grid-column-gap: 0.5rem;
    justify-content: center;
  }
`;
const selectStyle = {
  control: (base: any) => ({
    ...base,
    border: "1px solid hsl(210 6% 83%)",
    padding: ".3rem",
    borderRadius: "8px"
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: ".5rem"
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "lightgray",
    fontWeight: 450
  })
};

const ButtonGroup = styled.div`
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 10rem;

  button {
    height: 2.5rem;
  }
`;
