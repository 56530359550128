import { createActions, createHandler, createReducer } from "lib/store";

type State = ReturnType<typeof createInitialState>;

type UserInfo = {
  currentPassword: string;
  newPassword: string;
  checkPassword: string;
};

const SET_CURRENT_PASSWORD = Symbol("Profile::CurrentPassword::Set");
const SET_NEW_PASSWORD = Symbol("Profile::NewPassword::Set");
const SET_CHECK_PASSWORD = Symbol("Profile::CheckPassword::Set");

export const ChangePasswordActions = createActions({
  setCurrentPassword(password: string) {
    return { type: SET_CURRENT_PASSWORD, password };
  },
  setNewPassword(password: string) {
    return { type: SET_NEW_PASSWORD, password };
  },
  setCheckPassword(password: string) {
    return { type: SET_CHECK_PASSWORD, password };
  }
});

const handler = createHandler<State>({
  [SET_CURRENT_PASSWORD](state, payload: { password: string }) {
    state.userInfo.currentPassword = payload.password;
  },
  [SET_NEW_PASSWORD](state, payload: { password: string }) {
    state.userInfo.newPassword = payload.password;
  },
  [SET_CHECK_PASSWORD](state, payload: { password: string }) {
    state.userInfo.checkPassword = payload.password;
  }
});

const createInitialState = () => ({
  userInfo: {
    currentPassword: "",
    newPassword: "",
    checkPassword: ""
  } as UserInfo
});

export const reducer = createReducer(handler, createInitialState);
