import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Metadata, Artist, Company, Url, ListRelation, TrackInformation } from "GraphQL/Queries/Metadata/GetMetadata";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { CompanyTagAutoComplete, CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import { RoleTextAutoComplete, CharacterTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import uuidv4 from "uuid/v4";
import { GRAY_4 } from "constants/baseColor";
import { Tag } from "App/Atomics/Tag";
import { useAsyncEffect } from "lib/use-async-effect";
import { WORK_GENRE_LIST, WORK_MOOD_LIST } from "constants/storageKey";
import { GetGenreList, GetMoodList } from "GraphQL/Queries/Genre";
import { GetMoodParentList } from "GraphQL/Queries/Genre/GetMoodParentList";
import { usePlaylistDetailDispatch, usePlaylistDetailStore } from "App/Routes/PlaylistDetail/Store";
import { Genre, PlaylistTrackActions } from "App/Routes/PlaylistDetail/Store/PlaylistTrack";
import { TonalityOptionList } from "constants/TrackInfoOpionList";
import { GenderType } from "constants/GenderType";
import { TypeUrlOptionList } from "constants/TypeUrlOptionList";
import { UploadFileModal } from "../UploadFileModal";
import { Modal } from "lib/modal";
import { useToggle } from "lib/use-toggle";
import { Toast } from "lib/toast";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { WorkTextAutoComplete } from "App/Molecules/AutoCompletes/Work";

type SectionProps = {
  data: Metadata;
  pageIndex: Page;
};

const TimeSignatureOptionList = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "4", name: "4" },
  { id: "8", name: "8" },
  { id: "16", name: "16" },
  { id: "32", name: "32" },
  { id: "64", name: "64" }
];

const VoiceGenderOptionList = [
  { id: "Male", name: "Male" },
  { id: "Female", name: "Female" }
];

export const ColumnSection = ({ data, pageIndex }: SectionProps) => {
  const [{ genreList, moodList }, dispatch] = usePlaylistDetailStore(store => store.PlaylistTrack);
  const genreRelation = data?.genreRelation ?? [];
  const moodRelation = data?.moodRelation ?? [];

  // 모든 장르/무드를 담을 리스트
  const [filteredGenreList, setFilteredGenreList] = useState<Genre[]>(() => {
    const localGenre = window.localStorage.getItem(WORK_GENRE_LIST);
    if (!localGenre) {
      return [];
    }
    const parsedLocalGenre = JSON.parse(localGenre);
    return parsedLocalGenre;
  });
  const [filteredMoodList, setFilteredMoodList] = useState<Genre[]>(() => {
    const localMood = window.localStorage.getItem(WORK_MOOD_LIST);
    if (!localMood) {
      return [];
    }
    const parsedLocalMood = JSON.parse(localMood);
    return parsedLocalMood;
  });
  const uploadMp3Modal = useToggle();
  const uploadAacModal = useToggle();
  const uploadFlacModal = useToggle();
  const uploadWavModal = useToggle();
  const uploadTxtModal = useToggle();
  const uploadZipModal = useToggle();

  const openUploadModal = (typeUrl?: string) => {
    if (!typeUrl) return;
    console.log(typeUrl);
    switch (typeUrl) {
      case "mp3high":
        uploadMp3Modal.on();
        break;
      case "aac":
        uploadAacModal.on();
        break;
      case "flac":
        uploadFlacModal.on();
        break;
      case "wav":
        uploadWavModal.on();
        break;
      case "txt":
        uploadTxtModal.on();
        break;
      case "zip":
        uploadZipModal.on();
        break;
      default:
        Toast.warning("업로드 파일 형식이 아닙니다.");
        break;
    }
  };

  useAsyncEffect(
    async isMounted => {
      dispatch(PlaylistTrackActions.setEditLoading(true));
      // 로컬스토리지에 데이터가 없으면 쿼리 실행
      if (isMounted()) {
        if (!filteredGenreList.length) {
          const { data: genreData } = await GetGenreList();
          if (genreData) {
            window.localStorage.setItem(WORK_GENRE_LIST, JSON.stringify(genreData.genre));
            setFilteredGenreList(genreData.genre);
          }
          if (!filteredMoodList.length) {
            const { data: childData } = await GetMoodList();
            const { data: parentData } = await GetMoodParentList();
            if (childData && parentData) {
              const noChildParentData = parentData.genre.map(item => ({
                id: item.id,
                name: item.name,
                typeKind: item.typeKind,
                genre_self_relation_child: item.genre_self_relation_child as any[]
              }));
              const moodArr = childData.genre.concat(noChildParentData);
              window.localStorage.setItem(WORK_MOOD_LIST, JSON.stringify(moodArr));
              setFilteredMoodList(moodArr);
            }
          }
        }
      }
      dispatch(PlaylistTrackActions.setEditLoading(false));
      const initialGenre: Genre[] = !genreRelation?.length
        ? []
        : filteredGenreList.filter(({ id }) => {
            for (const { genre } of genreRelation) {
              if (genre[0].id === id) {
                return true;
              }
            }
            return false;
          });
      const initialMood: Genre[] = !moodRelation?.length
        ? []
        : filteredMoodList.filter(({ id }) => {
            for (const { mood } of moodRelation) {
              if (mood[0].id === id) {
                return true;
              }
            }
            return false;
          });
      dispatch([PlaylistTrackActions.setTrackGenre(initialGenre), PlaylistTrackActions.setTrackMood(initialMood)]);
    },
    [filteredGenreList, filteredMoodList, genreRelation, moodRelation]
  );
  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          if (value === null) return null;
          // 메인 정보
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "metadataId":
              case "title":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "metadataId" ? "ID" : "제목"}</span>
                    <InputText
                      isDisabled={key === "metadataId"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(PlaylistTrackActions.updateTrackTitle(val))}
                    />
                  </LabelGroup>
                );
              case "subClass":
                return (
                  <LabelGroup key={key}>
                    <span>{"분류"}</span>
                    <InputText isDisabled defaultValue={value as string} />
                  </LabelGroup>
                );
              case "artistRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>{"아티스트"}</span>
                      <RowGroup className={!value.length ? "" : "border"}>
                        {!(value as Artist[]).length
                          ? null
                          : (value as Artist[]).map(({ artist, role, character }, artistIndex) => {
                              const key = `${!artist[0]?.id ? "" : artist[0]?.id}-${!role[0]?.id ? "" : role[0]?.id}${
                                !character[0]?.id ? "" : character[0]?.id
                              }`;
                              return (
                                <ExtraInfo key={key} column={4}>
                                  <RoundButton
                                    color="danger"
                                    key={`${key}-remove`}
                                    onClick={() => {
                                      dispatch(PlaylistTrackActions.deleteTrackArtist(artistIndex));
                                    }}
                                  >
                                    -
                                  </RoundButton>
                                  <ArtistTextAutoComplete
                                    className="select"
                                    key={`${key}-artist`}
                                    defaultValue={
                                      !artist.length || !artist[0]?.id ? undefined : { id: artist[0]?.id!, name: artist[0]?.name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const artistInfo = { id: info.id, name: info.name };
                                        dispatch(PlaylistTrackActions.updateTrackArtist({ artistIndex, info: artistInfo }));
                                      }
                                    }}
                                  />
                                  <RoleTextAutoComplete
                                    className="select"
                                    key={`${key}-role`}
                                    defaultValue={!role.length || !role[0]?.id ? undefined : { id: role[0]?.id!, name: role[0]?.name! }}
                                    onChange={info => {
                                      if (info) {
                                        const roleInfo = { id: info.id, name: info.name };
                                        dispatch(PlaylistTrackActions.updateTrackRole({ roleIndex: artistIndex, info: roleInfo }));
                                      }
                                    }}
                                  />
                                  <CharacterTextAutoComplete
                                    className="select character"
                                    key={`${key}-character`}
                                    defaultValue={
                                      !character.length || !character[0]?.id
                                        ? undefined
                                        : { id: character[0]?.id!, name: character[0]?.name! }
                                    }
                                    onChange={info => {
                                      if (info) {
                                        const characterInfo = { id: info.id, name: info.name };
                                        dispatch(
                                          PlaylistTrackActions.updateTrackCharacter({
                                            characterIndex: artistIndex,
                                            info: characterInfo
                                          })
                                        );
                                      }
                                    }}
                                  />
                                </ExtraInfo>
                              );
                            })}
                        <RoundButton
                          color="success"
                          onClick={() => {
                            dispatch(PlaylistTrackActions.createTrackArtist());
                          }}
                        >
                          +
                        </RoundButton>
                      </RowGroup>
                    </LabelGroup>
                  </Fragment>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(PlaylistTrackActions.updateTrackVC(vc));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "rightsCompany":
                const rightsCompany = (value as Company[])[0]?.company ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"권리사"}</span>
                    <CompanyTextAutoComplete
                      isDisabled
                      defaultValue={
                        !rightsCompany
                          ? { id: "none", name: "권리사 정보가 없습니다" }
                          : { id: rightsCompany[0].id, name: rightsCompany[0].name }
                      }
                    />
                  </LabelGroup>
                );
              case "productions":
                const productions = (value as Company[])?.map(({ company }) => ({ id: company[0].id, name: company[0].name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"제작사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={productions}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "productions",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(PlaylistTrackActions.setTrackProductions(list));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "publications":
                const publications =
                  (value as Company[])?.map(({ company }) => ({ id: company[0]?.id, name: company[0]?.name })) ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"배급사"}</span>
                    <CompanyTagAutoComplete
                      defaultValue={publications}
                      onBlur={info => {
                        if (info) {
                          const list = info.map(({ id, name }, order) => {
                            const uuid = uuidv4();
                            return {
                              uuid,
                              validCheck: ValidType.V3,
                              typeKind: "publications",
                              order,
                              company: [
                                {
                                  id,
                                  name
                                }
                              ]
                            };
                          }) as Company[];
                          dispatch(PlaylistTrackActions.setTrackPublications(list));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "class":
              case "subclass":
              default:
                return null;
            }
            // 부가 정보
          } else if (pageIndex === Page.SECOND) {
            switch (key) {
              case "genreRelation":
                return (
                  <Fragment key={key}>
                    <LabelGroup>
                      <span>장르</span>
                      <RowGroup>
                        <InputTagSelect
                          className="autocomplete"
                          optionList={filteredGenreList
                            .filter(({ genre_self_relation_child }) => genre_self_relation_child && genre_self_relation_child.length)
                            .map(({ id, name, typeKind, genre_self_relation_child }) => {
                              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                                const parentName = genre_self_relation_child[0].parent[0].name;
                                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                                return { id, name: mixedName, typeKind };
                              }
                              return { id, name, typeKind };
                            })}
                          value={genreList}
                          onChange={info => {
                            if (info) {
                              dispatch(
                                PlaylistTrackActions.changeTrackGenre(info.map(({ id, name }) => ({ id, name, typeKind: "genre" })))
                              );
                            }
                          }}
                        />
                        <TagContainer type="genre">
                          {filteredGenreList
                            .filter(({ id, genre_self_relation_child }) => {
                              if (!genre_self_relation_child || !genre_self_relation_child.length) {
                                return false;
                              }
                              return !genreList.find(item => item.id === id);
                            })
                            .map((genre, i) => {
                              const { name, genre_self_relation_child } = genre;
                              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                                const parentName = genre_self_relation_child[0].parent[0].name;
                                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                                const mixedGenre = {
                                  ...genre,
                                  name: mixedName
                                };
                                return (
                                  <Tag
                                    key={genre.id}
                                    style={{ width: "95%", textAlign: "center" }}
                                    color="success"
                                    filled={false}
                                    onClick={() => dispatch(PlaylistTrackActions.changeTrackGenre([...genreList, mixedGenre]))}
                                  >
                                    {mixedName}
                                  </Tag>
                                );
                              }
                              return (
                                <Tag
                                  key={genre.id}
                                  style={{ width: "95%", textAlign: "center" }}
                                  color="success"
                                  filled={false}
                                  onClick={() => dispatch(PlaylistTrackActions.changeTrackGenre([...genreList, genre]))}
                                >
                                  {genre.name}
                                </Tag>
                              );
                            })}
                        </TagContainer>
                      </RowGroup>
                    </LabelGroup>
                    <div style={{ height: "1px", backgroundColor: "#ddd" }} />
                  </Fragment>
                );
              case "moodRelation":
                return (
                  <LabelGroup key={key}>
                    <span>무드</span>
                    <RowGroup>
                      <InputTagSelect
                        className="autocomplete"
                        optionList={filteredMoodList.map(({ id, name, genre_self_relation_child }) => {
                          let isDuplicateMood = moodList.some(list =>
                            list.genre_self_relation_child?.find(({ parent, child }) => {
                              if (parent && parent.length && genre_self_relation_child) {
                                const check1 = genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                                const check2 = parent.find(item => item.id === id);
                                return check1 || check2;
                              }
                              if (child && child.length) {
                                return child.find(item => item.id === id);
                              }
                            })
                          );
                          return { id, name, isDisabled: isDuplicateMood };
                        })}
                        value={moodList}
                        onChange={info => {
                          if (info) {
                            const selectedMood = filteredMoodList.filter(mood => info.find(item => item.id === mood.id));
                            dispatch(PlaylistTrackActions.changeTrackMood(selectedMood));
                          } else {
                            // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
                          }
                        }}
                      />
                      <TagContainer type="mood">
                        {filteredMoodList
                          .filter(mood => !moodList.find(item => item.id === mood.id))
                          .map((mood, i) => (
                            <Tag
                              key={mood.id}
                              disabled={moodList.some(list =>
                                list.genre_self_relation_child?.find(({ parent, child }) => {
                                  if (parent && parent.length && mood.genre_self_relation_child) {
                                    const check1 = mood.genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                                    const check2 = parent.find(item => item.id === mood.id);
                                    return check1 || check2;
                                  }
                                  if (child && child.length) {
                                    return child.find(item => item.id === mood.id);
                                  }
                                })
                              )}
                              style={{ width: "90%", textAlign: "center" }}
                              filled={false}
                              color="success"
                              onClick={() => dispatch(PlaylistTrackActions.changeTrackMood([...moodList, mood]))}
                            >
                              {mood.name}
                            </Tag>
                          ))}
                      </TagContainer>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          } else if (pageIndex === Page.THIRD) {
            switch (key) {
              case "listRelation":
                const lists = value as ListRelation[];
                return (
                  <LabelGroup key={key}>
                    <span>{"연관 작품"}</span>
                    <RowGroup className={!lists.length ? "" : "border"}>
                      {!lists?.length
                        ? null
                        : lists.map(({ uuid, elementRelation }, i) => (
                            <ExtraInfo key={uuid} column={2}>
                              <RoundButton
                                color="danger"
                                onClick={() => {
                                  dispatch(PlaylistTrackActions.deleteTrackElementRelation(i));
                                }}
                              >
                                -
                              </RoundButton>
                              <WorkTextAutoComplete
                                className="select"
                                defaultValue={
                                  !elementRelation[0].id && !elementRelation[0].title && !elementRelation[0].artistRelation
                                    ? undefined
                                    : !elementRelation[0].artistRelation
                                    ? { id: elementRelation[0].id!, name: elementRelation[0].title! }
                                    : {
                                        id: elementRelation[0].id!,
                                        name: `[${elementRelation[0].id}] ${elementRelation[0].title!} (🎤 ${
                                          elementRelation[0].artistRelation[0].artist[0].name
                                        })`
                                      }
                                }
                                onBlur={info => {
                                  if (info) {
                                    dispatch(
                                      PlaylistTrackActions.updateTrackElementRelationTrack({
                                        subIndex: i,
                                        id: info.id,
                                        title: info.name
                                      })
                                    );
                                  }
                                }}
                              />
                            </ExtraInfo>
                          ))}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          dispatch(PlaylistTrackActions.createTrackElementRelation());
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                break;
            }
          } else {
            switch (key) {
              case "metadataUrl":
                const urls = value as Url[];
                return (
                  <LabelGroup key={key}>
                    <span>{"URL"}</span>
                    <RowGroup className={!urls.length ? "" : "border"}>
                      {!urls?.length
                        ? null
                        : urls.map((url, i) => {
                            const key = `${url.typeUrl ?? ""}-${url.url}-${i}`;
                            return (
                              <UrlInfo key={key}>
                                <RoundButton
                                  color="danger"
                                  onClick={() => {
                                    if (window.confirm("음원 삭제의 경우 복구가 어려울 수 있습니다. 삭제하시겠습니까?")) {
                                      dispatch(PlaylistTrackActions.deleteTrackUrl(i));
                                    }
                                  }}
                                >
                                  -
                                </RoundButton>
                                <Input.Button
                                  className="upload-btn"
                                  disabled={!url.typeUrl}
                                  color="danger"
                                  onClick={() => openUploadModal(url.typeUrl)}
                                >
                                  업로드
                                </Input.Button>
                                <CreatableTextSelect
                                  name="select"
                                  className="select"
                                  optionList={TypeUrlOptionList}
                                  placeholder="타입"
                                  defaultValue={!url.typeUrl ? undefined : { id: url.typeUrl, name: url.typeUrl }}
                                  onChange={info => {
                                    if (info) {
                                      dispatch(PlaylistTrackActions.updateTrackTypeUrl({ urlIndex: i, typeUrl: info.id }));
                                    }
                                  }}
                                />
                                <InputText
                                  placeholder="URL"
                                  defaultValue={!url.url ? undefined : url.url}
                                  onBlur={text => {
                                    dispatch(PlaylistTrackActions.updateTrackUrl({ urlIndex: i, url: text }));
                                  }}
                                />
                                {url.typeUrl === "mp3high" && (
                                  <Modal isOpen={uploadMp3Modal.isToggled} onClose={uploadMp3Modal.off}>
                                    <UploadFileModal
                                      id={url.uuid}
                                      metadataId={data.metadataId}
                                      typeUrl={"mp3high"}
                                      url={url.url}
                                      urlIndex={i}
                                      toClose={uploadMp3Modal.off}
                                    />
                                  </Modal>
                                )}
                                {url.typeUrl === "aac" && (
                                  <Modal isOpen={uploadAacModal.isToggled} onClose={uploadAacModal.off}>
                                    <UploadFileModal
                                      id={url.uuid}
                                      metadataId={data.metadataId}
                                      typeUrl={"aac"}
                                      url={url.url}
                                      urlIndex={i}
                                      toClose={uploadAacModal.off}
                                    />
                                  </Modal>
                                )}
                                {url.typeUrl === "flac" && (
                                  <Modal isOpen={uploadFlacModal.isToggled} onClose={uploadFlacModal.off}>
                                    <UploadFileModal
                                      id={url.uuid}
                                      metadataId={data.metadataId}
                                      typeUrl={"flac"}
                                      url={url.url}
                                      urlIndex={i}
                                      toClose={uploadFlacModal.off}
                                    />
                                  </Modal>
                                )}
                                {url.typeUrl === "wav" && (
                                  <Modal isOpen={uploadWavModal.isToggled} onClose={uploadWavModal.off}>
                                    <UploadFileModal
                                      id={url.uuid}
                                      metadataId={data.metadataId}
                                      typeUrl={"wav"}
                                      url={url.url}
                                      urlIndex={i}
                                      toClose={uploadWavModal.off}
                                    />
                                  </Modal>
                                )}
                                {url.typeUrl === "txt" && (
                                  <Modal isOpen={uploadTxtModal.isToggled} onClose={uploadTxtModal.off}>
                                    <UploadFileModal
                                      id={url.uuid}
                                      metadataId={data.metadataId}
                                      typeUrl={"txt"}
                                      url={url.url}
                                      urlIndex={i}
                                      toClose={uploadTxtModal.off}
                                    />
                                  </Modal>
                                )}
                                {url.typeUrl === "zip" && (
                                  <Modal isOpen={uploadZipModal.isToggled} onClose={uploadZipModal.off}>
                                    <UploadFileModal
                                      id={url.uuid}
                                      metadataId={data.metadataId}
                                      typeUrl={"zip"}
                                      url={url.url}
                                      urlIndex={i}
                                      toClose={uploadZipModal.off}
                                    />
                                  </Modal>
                                )}
                              </UrlInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const tempUrl = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeUrl: undefined,
                            url: undefined
                          } as Url;
                          dispatch(PlaylistTrackActions.createTrackUrl(tempUrl));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              case "trackInformation":
                const trackInfo = !value?.length ? undefined : (value[0] as TrackInformation);
                return (
                  <LabelGroup key={key}>
                    <span>트랙 정보</span>
                    <RowGroup className={"border"}>
                      <TrackInfoWrapper>
                        <div className="info info-0">
                          <div className="info-wrap">
                            <span>채널</span>
                            <InputNumber
                              step={1}
                              min={0}
                              max={9999}
                              placeholder="채널"
                              defaultValue={trackInfo?.channel}
                              onBlur={value => dispatch(PlaylistTrackActions.setTrackChannel(value))}
                            />
                          </div>
                          <div className="info-wrap">
                            <span>재생시간</span>
                            <InputNumber
                              step={1}
                              min={0}
                              max={9999}
                              placeholder="재생시간"
                              defaultValue={trackInfo?.duration}
                              onBlur={value => dispatch(PlaylistTrackActions.setTrackDuration(value))}
                            />
                          </div>
                          <div className="info-wrap">
                            <span>비트</span>
                            <InputNumber
                              step={1}
                              min={0}
                              max={99999}
                              placeholder="비트"
                              defaultValue={trackInfo?.bitrate}
                              onBlur={value => dispatch(PlaylistTrackActions.setTrackBirate(value))}
                            />
                          </div>
                          <div className="info-wrap">
                            <span>템포</span>
                            <InputNumber
                              step={0.1}
                              min={0}
                              max={99999}
                              placeholder="템포"
                              defaultValue={trackInfo?.tempo}
                              onBlur={value => dispatch(PlaylistTrackActions.setTrackTempo(value))}
                            />
                          </div>
                        </div>
                        <div className="info info-1">
                          <div className="info-wrap">
                            <span>조성</span>
                            <InputTextSelect
                              placeholder="조성"
                              defaultValue={!trackInfo?.tonality ? undefined : { id: trackInfo.tonality, name: trackInfo.tonality }}
                              optionList={TonalityOptionList}
                              onBlur={info => {
                                if (info) {
                                  dispatch(PlaylistTrackActions.setTrackTonality(info.id));
                                }
                              }}
                            />
                          </div>
                          <div className="info-wrap">
                            <span>목소리</span>
                            <InputTextSelect
                              placeholder="목소리"
                              optionList={VoiceGenderOptionList}
                              defaultValue={
                                !trackInfo?.voiceGender ? undefined : { id: trackInfo?.voiceGender, name: trackInfo?.voiceGender }
                              }
                              onBlur={info => {
                                if (info) {
                                  dispatch(PlaylistTrackActions.setTrackVoiceGender(info.id as GenderType));
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="info info-2">
                          <div className="info-wrap">
                            <span style={{ marginRight: "12px" }}>박자</span>
                            <TimeSignature timeSignature={trackInfo?.timeSignature} />
                          </div>
                        </div>
                        <div className="info info-3">
                          <div className="info-extra">
                            <span>기타 설명</span>
                            <Multiline
                              defaultValue={trackInfo?.extraData}
                              onBlur={e => dispatch(PlaylistTrackActions.setTrackTimeExtraData(e.target.value))}
                            />
                          </div>
                        </div>
                      </TrackInfoWrapper>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          }
          return null;
        })}
    </>
  );
};

const TimeSignature = ({ timeSignature }: { timeSignature?: string }) => {
  // 3/4 박자 => signTop: 3, signBottom: 4
  let signTop: string | undefined;
  let signBottom: string | undefined;
  if (timeSignature) {
    signTop = timeSignature.split("/")[0];
    signBottom = timeSignature.split("/")[1];
  }
  const dispatch = usePlaylistDetailDispatch();
  return (
    <TimeSignatureWrapper>
      <div className="time-box">
        <InputNumber
          step={1}
          min={1}
          max={64}
          className="select-top"
          defaultValue={!signTop ? undefined : parseInt(signTop)}
          onChange={top => {
            const infoValue = `${top}/${!signBottom ? 1 : signBottom}`;
            dispatch(PlaylistTrackActions.setTrackTimeSignature(infoValue));
          }}
        />
        <div className="bar"></div>
        <InputTextSelect
          className="select-bottom"
          placeholder=""
          optionList={TimeSignatureOptionList}
          defaultValue={!signBottom ? undefined : TimeSignatureOptionList.find(({ id }) => id === signBottom)}
          onChange={info => {
            if (info) {
              const infoValue = `${!signTop ? 1 : signTop}/${info.id}`;
              dispatch(PlaylistTrackActions.setTrackTimeSignature(infoValue));
            }
          }}
        />
      </div>
    </TimeSignatureWrapper>
  );
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 700px;
  padding: 0.5rem;
  overflow: hidden;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;
const InputNumber = styled(Input.Number)`
  width: 8rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  background-color: #fff;
  margin: 0 0.25rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const InputTextSelect = styled(Input.TextSelect)`
  width: 12rem;
  padding: 0.5rem;
  margin: 0 0.25rem;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 2 ? `2rem auto` : props.column === 3 ? `2rem 150px auto` : `2rem 34% 28% auto`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;

  &.subdata {
    grid-template-columns: 2rem 20% 25% auto;
  }
`;

const UrlInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2rem 65px 150px auto;
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;

  .upload-btn {
    padding: 0.6rem 1.2rem;
    font-weight: 600;
  }
`;

const TrackInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 0.5rem;

  .info {
    margin: 1rem 0;
  }
  .info.info-0 {
    display: grid;
    grid-template-columns: repeat(4, 9rem);

    span {
      margin-bottom: 4px;
    }
  }
  .info.info-1 {
    display: grid;
    grid-template-columns: 12rem 12rem auto;
  }
  .info.info-2 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .info.info-3 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .info-extra {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
    width: 60%;
    span {
      margin-bottom: 4px;
      text-align: center;
    }
  }
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

const InputTagSelect = styled(Input.TagSelect)`
  margin-bottom: 1rem;
  border-color: ${GRAY_4};
`;

const TagContainer = styled.div<{ type: string }>`
  width: 100%;
  height: 16rem;
  overflow: scroll;
  display: grid;
  grid-template-columns: ${props => (props.type === "genre" ? "repeat(4, 24.5%)" : "repeat(7, 14%)")};
  grid-gap: 5px;
  box-shadow: 0 0 4px ${GRAY_4};
  padding: 1rem;
`;

const TimeSignatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .time-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
  }

  .bar {
    width: 30px;
    height: 1px;
    background-color: #191919;
    transform: rotate(-70deg);
    margin: 0 -12px 0 -4px;
  }

  .select-top {
    width: 5rem;
    height: 2.5rem;
  }

  .select-bottom {
    width: 6rem;
  }
`;

const Multiline = styled.textarea`
  height: 12rem;
  font-size: 13px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  line-height: 1.6em;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;
