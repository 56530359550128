/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
}>;

export const DeleteRights = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Mutation operationName="DELETE_RIGHTS">
      <SelectionSet
        name="deleteCompany"
        argument={{
          where: {
            company_id: $id
          }
        }}
      >
        <Field name="company_id" alias="id" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    deleteRights: {
      id: string;
    }[];
  }>;

  return await clients.company.request<Data>(query, variables);
};
