/** @jsx jsx */

import { TypeAudio } from "App/Store/AudioPlayer";
import { jsx, render, Query, SelectionSet, Field, Variable, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import uuidv4 from "uuid/v4";

type Option = Readonly<{
  rootId: number;
}>;

export const GetMetadataAudioDataByAlbum = async (option: Option) => {
  const $rootId = <Variable name="id" scalar={<IntScalar />} value={option.rootId} />;
  const { query, variables } = render(
    <Query operationName="GET_METADATA_AUDIO_DATA_BY_ALBUM">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 999,
          where: {
            type_metadata_class__in: ["record", "theater"],
            type_metadata_subclass__in: ["track", "effect"],
            metadata_structure__some: {
              structure_root_id: $rootId
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            first: 1,
            where: {
              role__some: {
                role_id: "343"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_url"
          alias="metadataUrl"
          argument={{
            where: {
              type_url__in: ["wav", "mp3high", "aac", "flac"]
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
        </SelectionSet>
        <SelectionSet name="metadata_structure" alias="structures">
          <SelectionSet name="list">
            <SelectionSet name="metadata">
              <SelectionSet
                name="metadata_url"
                alias="metadataUrl"
                argument={{
                  where: {
                    type_url: "cover"
                  }
                }}
              >
                <Field name="id" />
                <Field name="type_url" alias="typeUrl" />
                <Field name="url" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      title: string;
      artistRelation: {
        uuid: string;
        artist: {
          id: string;
          name: string;
        }[];
      }[];
      metadataUrl: {
        uuid: string;
        typeUrl: string;
        url: string;
      }[];
      structures: {
        list: {
          metadata: {
            metadataUrl: {
              id: string;
              typeUrl: string;
              url: string;
            }[];
          }[];
        }[];
      }[];
    }[];
  }>;
  const { errors, data: trackData } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  const data = trackData!.metadata.map(item => {
    const uuid = uuidv4();
    const title = item.title;
    const artist = item.artistRelation[0]?.artist[0]?.name;
    const coverUrl = item.structures[0]?.list[0]?.metadata[0]?.metadataUrl[0]?.url;
    const url = item.metadataUrl[0]?.url;
    return { uuid, title, artist, coverUrl, url } as TypeAudio;
  });
  return data;
};
