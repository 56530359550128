import React from "react";
import styled from "styled-components";
import { GRAY_6 } from "constants/baseColor";
import { ReactComponent as CDIcon } from "assets/icons/cd.svg";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { EffectForm } from "./EffectForm";

type Props = {
  onClose: () => void;
};

export const CreateTrackModal = ({ onClose }: Props) => {
  const onClearAndClose = () => {
    onClose();
  };
  return (
    <Layout>
      <Header>
        <CDIcon className="cd" />
        <h3>음원 추가</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <EffectForm onClose={onClearAndClose} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 800px;
  height: 800px;
  background-color: #fff;
  font-size: 0.9rem;
  overflow-y: scroll;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #ffdc00;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  h3 {
    font-size: 1.2rem;
  }

  .cd {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.4rem;
    margin-top: 3px;
  }
  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;
