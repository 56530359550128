import Axios, { AxiosInstance } from "axios";

import { GQL } from "lib/gql-tag";
import { configs } from "configs";

type Variables = Record<string, any>;
type Headers = Record<string, any>;

export type GraphQLClientOption = Readonly<{
  baseUrl: string;
  getHeader: () => Headers;
  isFailover: boolean;
}>;

const instanceMap = new Map<string, GraphQLClient>();

/**
 * # Query를 보낼 때
 *
 * ```typescript
 * const QUERY = gql`
 *   query EXAMPLE_GET_USER {
 *     user {
 *       name
 *     }
 *   }
 * `
 * const client = new GraphQLClient('https://example.com/');
 * client.query(QUERY).then(console.log)
 * ```
 *
 * # Mutation을 보낼 때
 *
 * ```typescript
 * const QUERY = gql`
 *   mutation EXAMPLE_CREATE_USER ($name: String!) {
 *     createUser(name: $name) {
 *       name
 *     }
 *   }
 * `
 * const client = new GraphQLClient('https://example.com/', { name: 'Danuel' });
 * client.mutation(QUERY).then(console.log)
 * ```
 */
export class GraphQLClient {
  static failover = () => Promise.resolve();

  static setFailover(failover: () => Promise<void>) {
    GraphQLClient.failover = failover;
  }

  static getInstance(option: GraphQLClientOption) {
    const { baseUrl } = option;
    if (!instanceMap.has(baseUrl)) {
      instanceMap.set(baseUrl, new GraphQLClient(option));
    }
    return instanceMap.get(baseUrl)!;
  }

  private readonly client: AxiosInstance;

  private constructor(private readonly option: GraphQLClientOption) {
    this.client = Axios.create({ baseURL: option.baseUrl });
  }

  private combineHeaders(headers: Headers) {
    return (process.env.NODE_ENV as string) === "development"
      ? Object.assign({ access_token: configs.masterAccessKey }, headers, this.option.getHeader())
      : Object.assign({}, headers, this.option.getHeader());
  }

  request<T = any, E extends any = any>(query: string, variables: Variables = {}, headers: Headers = {}) {
    type R = {
      data?: T;
      error?: { message: string };
      errors?: readonly E[];
    };
    const source = Axios.CancelToken.source();
    const promise = new Promise(async (resolve: (value: R) => void, reject: (value?: any) => void) => {
      const body = { query, variables, operationName: null };
      try {
        const response = await this.client.post<R>("", body, { headers: this.combineHeaders(headers), cancelToken: source.token });
        if (!response.data.errors) {
          resolve(response.data);
          return;
        }
        if (!this.option.isFailover) {
          resolve(response.data);
          return;
        }
        if (response) {
          resolve(response.data);
          return;
        }
        await GraphQLClient.failover();
        const recoveredResponse = await this.client.post<R>("", body, { headers: this.combineHeaders(headers), cancelToken: source.token });
        resolve(recoveredResponse.data);
      } catch (error) {
        if (error === "ABORTED") {
          resolve({ error: { message: "ABORTED" } } as any);
        } else {
          reject();
        }
      }
    });
    Object.assign(promise, {
      cancel() {
        source.cancel("ABORTED");
      }
    });
    return promise as Promise<R> & {
      cancel(): void;
    };
  }

  /**
   * @param query lib/gql-tag의 함수로 생성한 변수만 가능
   * @param variables GraphQL Query를 보내면서 변수를 보내야 할 때 사용
   * @param headers 이 Client 객체가 가진 default 값에 추가적으로 보내야 할 것이 있을 떄에 사용
   */
  async query<T = any, E = any>(query: GQL, variables: Variables = {}, headers: Headers = {}) {
    return this.request<T, E>(query.query, variables, headers) as Promise<T> & {
      cancel(): void;
    };
  }

  /**
   * @param query lib/gql-tag의 함수로 생성한 변수만 가능
   * @param variables GraphQL Query를 보내면서 변수를 보내야 할 때 사용
   * @param headers 이 Client 객체가 가진 default 값에 추가적으로 보내야 할 것이 있을 떄에 사용
   */
  async mutation<T = any, E = any>(query: GQL, variables: Variables = {}, headers: Headers = {}) {
    return this.request<T, E>(query.query, variables, headers) as Promise<T> & {
      cancel(): void;
    };
  }
}
