import { ReactNode } from "react";
import { TypeOptions, ToastContent, ToastOptions, toast, ToastId, PositionOptions } from "react-toastify";

type openProps = {
  children: ToastContent;
  type?: TypeOptions;
  option?: commonOption;
  position?: PositionOptions;
};

type commonOption = {
  timeout?: number | false;
  position?: PositionOptions;
};

type infoOption = commonOption;
type warningOption = commonOption;
type successOption = commonOption;
type errorOption = commonOption;

const defaultOption: commonOption = {
  timeout: 1500
};

const open = ({ children, type, option = defaultOption, position }: openProps) => {
  const { timeout } = option;
  const toastOption: ToastOptions = {
    type,
    autoClose: timeout,
    position
  };
  return toast(children, toastOption);
};

const close = (toastId: ToastId) => toast.dismiss(toastId);
const closeAll = () => toast.dismiss();

const primary = (children: ReactNode, option?: errorOption, position?: PositionOptions) => open({ children, option, position });
const info = (children: ReactNode, option?: infoOption, position?: PositionOptions) => open({ children, type: "info", option, position });
const warning = (children: ReactNode, option?: warningOption, position?: PositionOptions) =>
  open({ children, type: "warning", option, position });
const success = (children: ReactNode, option?: successOption, position?: PositionOptions) =>
  open({ children, type: "success", option, position });
const error = (children: ReactNode, option?: errorOption, position?: PositionOptions) =>
  open({ children, type: "error", option, position });

export const Toast = { primary, info, warning, success, error, close, closeAll };
