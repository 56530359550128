/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, EnumScalar, ListScalar } from "graphql-jsx";

import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
import { createHash } from "utils/createHash";

let currentHash: null | string = null;

export const findWorkAlbumList = async (keyword: string, typeClass: string, searchType?: string) => {
  const hash = createHash();
  currentHash = hash;

  const $typeClass = <Variable name="typeClass" scalar={<StringScalar />} value={typeClass} />;
  const $titleKeyword =
    !searchType || searchType === "title" ? (
      <Variable name="titleKeyword" scalar={<StringScalar isNullable />} value={keyword} />
    ) : (
      undefined
    );
  const $artistKeyword =
    searchType === "artist" ? <Variable name="artistKeyword" scalar={<StringScalar isNullable />} value={keyword} /> : undefined;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  const { query, variables } = render(
    <Query operationName="FIND_METADATA_SEARCH_LIST">
      <SelectionSet
        name="metadata_title"
        alias="list"
        argument={{
          first: 20,
          group: true,
          orderBy: (
            <ListScalar
              scalar={<EnumScalar type="Metadata_titleOrderByInput" items={["value__SIMILARITY"]} />}
              value={["value__SIMILARITY"]}
            />
          ),
          where: {
            metadata__some: {
              type_metadata_class: $typeClass,
              valid_check__not: $remove,
              metadata_artist_relation__some: {
                artist__some: {
                  name__contains: $artistKeyword
                }
              }
            },
            type_metadata_title__starts_with: "name",
            tsvec_words__search: $titleKeyword
          }
        }}
      >
        <Field name="metadata_id" />
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
          <Field name="title" alias="name" />
          <Field name="type_metadata_subclass" alias="subClass" />
          <SelectionSet name="metadata_artist_relation" alias="artistRelation" argument={{ where: { role__some: { role_id: 343 } } }}>
            <SelectionSet name="artist">
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
          <SelectionSet name="metadata_structure" alias="structures">
            <Field name="structure_id" alias="id" />
            <SelectionSet
              name="list"
              argument={{
                where: {
                  metadata__some: {
                    type_metadata_subclass: "album"
                  }
                }
              }}
            >
              <SelectionSet name="metadata">
                <SelectionSet name="metadata_url" alias="metadataUrl" argument={{ where: { type_url: "cover" } }}>
                  <Field name="url" />
                </SelectionSet>
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: {
      metadata_id: string;
      metadata: {
        id: string;
        name: string;
        subClass: string;
        artistRelation: ReadonlyArray<{
          artist: ReadonlyArray<{
            name: string;
          }>;
        }>;
        structures: ReadonlyArray<{
          id: string;
          list: {
            metadata: {
              metadataUrl: {
                url: string;
              }[];
            }[];
          }[];
        }>;
      }[];
    }[];
  }>;
  const response = await clients.metadata.request<Data>(query, variables);
  if (currentHash !== hash) {
    return null;
  }

  if (response.data) {
    let dataList = response.data.list.map(({ metadata }) => {
      const { id, name, subClass, artistRelation, structures } = metadata[0];
      const artist = !artistRelation.length ? "missing artist" : artistRelation[0].artist[0].name;
      const url = !structures.length ? undefined : structures[0]?.list[0]?.metadata[0]?.metadataUrl[0]?.url;
      const extra = { artist, url };
      return { id, name: `[${subClass === "track" ? "트랙" : "앨범"}] ${name}`, extra };
    });
    return dataList;
  } else {
    return [];
  }
};
