import { StringID } from "utils/id";
import { immerable } from "immer";
import Time, { Dayjs } from "dayjs";
import { GoodsID } from "./GoodsModel";

export class ProductID extends StringID {
  constructor(id: string) {
    super("product", id);
  }
}

export type Url = {
  readonly id: string;
  readonly typeUrl: string;
  readonly url: string;
  readonly order: number;
};

export type ProductPayload = {
  readonly id: string;
  readonly goodsId: string;
  readonly displayName: string;
  readonly quality: string;
  readonly barcode: string;
  readonly price: number;
  readonly createdAt: string;
  readonly urls: Url[];
};

export class ProductModel {
  static from({ id, goodsId, displayName, quality, barcode, price, createdAt, urls }: ProductPayload): ProductModel {
    return new ProductModel(new ProductID(id), new GoodsID(goodsId), displayName, quality, barcode, price, Time(createdAt), urls);
  }

  readonly [immerable] = true;

  constructor(
    readonly id: ProductID,
    readonly goodsId: GoodsID,
    readonly displayName: string,
    readonly quality: string,
    readonly barcode: string,
    readonly price: number,
    readonly createdAt: Dayjs,
    readonly urls: Url[]
  ) {}
}
