import React, { useRef } from "react";
import styled from "styled-components";
import { ColumnProps } from "App/Templates/TableTemplate";
import { Table } from "App/Atomics/Table";
import { GRAY_3, YELLO_6 } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { useAppStore } from "App/Store";
import { usePlaylistDetailStore } from "../../Store";
import { PlaylistDetailActions, PlaylistRelation } from "../../Store/PlaylistDetail";
import { UserRole } from "constants/UserRole";
import { useToggle } from "lib/use-toggle";
import { LicenseModal } from "../Modal/LicenseModal";
import { Modal } from "lib/modal";
import { AnimatedCheckbox } from "App/Molecules/AnimatedCheckbox";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { LyricsModal } from "../Modal/LyricsModal";
import { ValidType } from "constants/ValidType";
import { Url } from "./Url";
import { MainPerformer, Composer, OriginalArtist } from "./Artist";
import { Genre, Mood } from "./Genre";
import { Column } from "constants/Column";
import { Info } from "./Info";
import { EditInfoModal } from "../Modal/EditInfoModal";
import { allowPlaylistCreate } from "App/Routes/AdminRoutes/allowTables";

export const DataTemplate = ({ index, data }: ColumnProps<PlaylistRelation>) => {
  const { id, validCheck, metadata, order, playlist } = data;
  const { metadataId, no, title, genres, artists, metadataUrl, license, structures, track_information } = metadata[0];
  const [{ checkList }, playlistDetailDispatch] = usePlaylistDetailStore(store => ({
    checkList: store.PlaylistDetail.checkList
  }));
  const [{ userRole, store }] = useAppStore(store => ({
    userRole: store.UserToken.role!,
    store: store
  }));
  const mainPerformer = artists.filter(({ role }) => role[0]?.id === "343")[0]?.artist[0]?.name;
  const visibleField = playlist[0].userRelation.filter(({ owner }) => !owner)[0]?.visibleField ?? [];
  const licenseModal = useToggle();
  const lyricsModal = useToggle();
  const editInfoModal = useToggle();
  const licenseRef = useRef<any>(null);
  const allowEditPlaylist = userRole === UserRole.Master || (userRole === UserRole.Arbeit && allowPlaylistCreate(store));
  // TODO: Input Check Event
  const onToggleCheck = (checked: boolean, id: string, uuid: string) => {
    playlistDetailDispatch([
      PlaylistDetailActions.setPlyalistDetailCheckList({ id, checked }),
      PlaylistDetailActions.setPlaylistDetailDeleteList({ id: uuid, checked })
    ]);
  };

  const openEditModal = () => {
    if (!allowEditPlaylist) return;
    editInfoModal.on();
  };

  const openLyricsModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    lyricsModal.on();
  };
  const openLicenseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    licenseModal.on();
  };

  const allowColumnByUserRole = (userRole: UserRole): Column[] => {
    switch (userRole) {
      case UserRole.Master:
      case UserRole.Arbeit:
        return [
          Column.checkbox,
          Column.order,
          Column.id,
          Column.validCheck,
          Column.no,
          Column.title,
          Column.url,
          Column.mainPerformer,
          Column.originalArtist,
          Column.genre,
          Column.mood,
          Column.lyrics,
          Column.license,
          Column.info
        ];
      case UserRole.Partner:
        const result = Object.entries(Column)
          .filter(([_, value]) => visibleField?.includes(value))
          .map(([_, value]) => value) as Column[];
        return result;
      default:
        return [Column.title, Column.license];
    }
  };

  return (
    <>
      <PlaylistTable onClick={openEditModal}>
        {allowColumnByUserRole(userRole).map(column => {
          switch (column) {
            case Column.checkbox:
              return (
                <Table.Data style={{ width: "5%" }}>
                  <div onClick={e => e.stopPropagation()}>
                    <AnimatedCheckbox
                      id={metadataId}
                      isDisabled={validCheck === "REMOVE"}
                      isChecked={checkList.get(metadataId)}
                      onToggle={checked => onToggleCheck(checked, metadataId, id)}
                    />
                  </div>
                </Table.Data>
              );
            case Column.order:
              return (
                <Table.Data style={{ width: "5%" }}>
                  <FullDuv>{order}</FullDuv>
                </Table.Data>
              );
            case Column.id:
              return (
                <Table.Data>
                  <ClipBoard text={metadataId} />
                </Table.Data>
              );
            case Column.validCheck:
              return <Table.Data>{validCheck}</Table.Data>;
            case Column.no:
              return <Table.Data>{no}</Table.Data>;
            case Column.title:
              return (
                <AlignCenterData style={{ minWidth: "30%" }}>
                  <TitleData>
                    <div className="title">
                      <div className="title-text">
                        <ClipBoard text={title} />
                        {validCheck === ValidType.V3 && <span className="new">{"NEW"}</span>}
                      </div>
                    </div>
                  </TitleData>
                </AlignCenterData>
              );
            case Column.url:
              return (
                <Url
                  index={index}
                  userRole={userRole}
                  validCheck={validCheck}
                  metadataUrl={metadataUrl}
                  structures={structures}
                  title={title}
                  isService={license[0]?.isService}
                  mainPerformer={mainPerformer}
                  visibleField={visibleField}
                />
              );
            case Column.originalArtist:
              return <OriginalArtist index={index} artists={artists} />;
            case Column.mainPerformer:
              return <MainPerformer index={index} artists={artists} />;
            case Column.composer:
              return <Composer index={index} artists={artists} />;
            case Column.genre:
              return <Genre index={index} genres={genres} />;
            case Column.mood:
              return <Mood index={index} genres={genres} />;
            case Column.lyrics:
              return (
                <AlignCenterData style={{ width: "8%" }}>
                  <Input.Button color="primary" isFill={false} isWide onClick={openLyricsModal}>
                    가사 보기
                  </Input.Button>
                </AlignCenterData>
              );
            case Column.license:
              return (
                <Table.Data ref={licenseRef} style={{ width: "8%" }}>
                  <Input.Button color="primary" isFill={false} isWide onClick={openLicenseModal}>
                    창 열기
                  </Input.Button>
                </Table.Data>
              );
            case Column.info:
              return <Info index={index} info={track_information} />;
            default:
              return null;
          }
        })}
      </PlaylistTable>
      <Modal isOpen={licenseModal.isToggled}>
        <LicenseModal metadataId={metadataId} toClose={licenseModal.off} />
      </Modal>
      <Modal isOpen={lyricsModal.isToggled}>
        <LyricsModal metadataId={metadataId} toClose={lyricsModal.off} />
      </Modal>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        <EditInfoModal id={metadataId} toClose={editInfoModal.off} />
      </Modal>
    </>
  );
};

const PlaylistTable = styled(Table.Row)<{ isAllow?: boolean }>`
  transition: background-color 0.15s;
  & td {
    text-align: center;
  }

  audio {
    border: 1px solid ${GRAY_3};
    border-radius: 25px;

    &:focus {
      outline: none;
    }
  }
`;

const TitleData = styled.div`
  width: 100%;
  display: flex;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 4px;
  }
  .title {
    white-space: pre-wrap;
    text-align: left;

    .title-text {
      line-height: 1.8;
      .new {
        user-select: none;
        border: 1.5px solid ${YELLO_6};
        color: ${YELLO_6};
        border-radius: 8px;
        font-size: 11px;
        margin-left: 4px;
        padding: 1.5px 4px;
      }
    }
  }
`;

const FullDuv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlignCenterData = styled(Table.Data)`
  text-align: center;
`;
