import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_X_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_X_LARGE,
  MARGING_SMALL_PX
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as CircleRIcon } from "assets/icons/circle-r.svg";
import { ReactComponent as CircleCIcon } from "assets/icons/circle-c.svg";
import { ReactComponent as YtvIcon } from "assets/icons/ytv.svg";
import { WHITE, GRAY_4, GRAY_7, BLACK } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { PRIMARY_COLOR, DANGER_ALERT_COLOR, DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetPlaylistLicense } from "GraphQL/Queries/Playlist";
import { License } from "App/Routes/PlaylistDetail/Store/PlaylistDetail";
import { CountryCodeInfo } from "constants/CountryCodeInfo";
import { ValidType } from "constants/ValidType";

type Props = Readonly<{
  metadataId: string;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 960px;
  height: 80vh;
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  min-height: ${pixelize(UNIT * 12.5)};
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.25rem;
  }
  .title {
    padding: ${pixelize(PADDING_X_LARGE * 1.4)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35%;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const MenuContainer = styled.div`
  width: 100%;
  padding: 0 ${pixelize(PADDING_X_LARGE * 1.4)};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  justify-content: center;
  align-items: center;
  align-self: center;

  & > div > span {
    font-size: 14px;
    font-weight: bold;
  }

  .select {
    width: 100%;
    font-size: 12px;
    margin-top: ${MARGING_LARGE_PX};
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  padding: ${PADDING_X_LARGE_PX} ${pixelize(PADDING_X_LARGE * 1.4)};
`;

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 3px;
    transform: translateY(-1px);
  }
  span {
    font-size: 14px;
    font-weight: bold;
    color: #4c52bc;
  }
`;

const DescCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  margin: ${pixelize(MARGING_X_LARGE * 1.5)};
  padding: ${PADDING_X_LARGE_PX};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${pixelize(UNIT * 2)};
    margin-bottom: ${MARGING_SMALL_PX};
    border-bottom: 1px solid #c9c9c9;
    border-radius: 0;

    .titleBox {
      font-size: 12px;
      font-weight: 600;
      color: #757575;
    }
  }
`;

const RightBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
    margin-left: ${MARGING_SMALL_PX};
  }

  .date {
    color: ${GRAY_7};
    font-size: 0.8rem;
  }
`;

const EplipsisMultiline = styled(Input.Multiline)`
  width: 100%;
  margin: ${MARGING_LARGE_PX} 0;
  height: ${pixelize(UNIT * 10)};
  border: none;
  padding: 0;
  color: #212121;
  font-size: 14px;
  line-height: 1.43;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
`;

const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const NoData = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-weight: 400;

    .primary-underline {
      color: ${PRIMARY_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
    .danger-underline {
      color: ${DANGER_ALERT_COLOR};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const TypeInfo = [
  { id: "PD", name: "PD" },
  { id: "ccl", name: "ccl" },
  { id: "NoCopyright", name: "NoCopyright" },
  { id: "VL", name: "VL" },
  { id: "Cover", name: "Cover" }
];

export const LicenseModal = ({ metadataId, toClose }: Props) => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [typeTrack, setTypeTrack] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const CountryCodeInfoList = useMemo(() => CountryCodeInfo.filter(({ id }) => id === "XX" || id === "ZZ" || id === "KR"), []);
  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        try {
          const { data } = await GetPlaylistLicense({ id: metadataId, typeTrack, countryCode });
          if (data && data.metadata.length) {
            setLicenses(data.metadata[0].licenses);
          }
          setLoading(false);
        } catch (e) {
          console.log(e);
          window.alert("Internal Server Error");
        }
      }
    },
    [typeTrack, countryCode]
  );

  const setFilterReset = () => {
    setTypeTrack(null);
    setCountryCode(null);
  };

  return loading ? null : (
    <Layout>
      <Header>
        <div className="title">
          <h3>라이센스 정보</h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
        <MenuContainer>
          <div>
            <span>타입 필터</span>
            <Input.TextSelect
              className="select"
              optionList={TypeInfo}
              value={!typeTrack ? null : { id: typeTrack, name: typeTrack }}
              onChange={info => {
                if (info) {
                  setTypeTrack(info.id);
                }
              }}
            />
          </div>
          <div>
            <span>국가 필터</span>
            <Input.TextSelect
              key={!countryCode ? "" : countryCode}
              className="select"
              optionList={CountryCodeInfoList}
              value={CountryCodeInfo.find(({ id }) => id === countryCode)}
              onChange={info => {
                if (info) {
                  setCountryCode(info.id);
                }
              }}
            />
          </div>
        </MenuContainer>
        <FilterContainer>
          <FilterBox onClick={setFilterReset}>
            <RefreshIcon />
            <span>필터 초기화</span>
          </FilterBox>
        </FilterContainer>
      </Header>

      {!licenses.length ? (
        <NoData>
          <span>데이터가 없습니다.</span>
        </NoData>
      ) : (
        licenses.map((license, i) => {
          const { country_code, type_track, notice, copyright, neighboring, license_date, publish_date, valid_check } = license;
          const countryName = CountryCodeInfo.find(({ id }) => id === country_code)?.name ?? "-";
          return (
            <DescCard key={i}>
              <div className="titleContainer">
                <div className="titleBox">
                  <span>
                    {type_track} | {countryName}
                  </span>
                </div>
                <RightBox>
                  <span className="date">{!publish_date ? "" : publish_date}</span>
                  <CircleCIcon fill={copyright === false ? DANGER_COLOR : copyright === true ? SUCCESS_COLOR : GRAY_4} />
                  <CircleRIcon fill={neighboring === false ? DANGER_COLOR : neighboring === true ? SUCCESS_COLOR : GRAY_4} />
                  <YtvIcon
                    fill={
                      valid_check === ValidType.MODIFY ? DANGER_COLOR : (valid_check === ValidType.DONE) === true ? SUCCESS_COLOR : GRAY_4
                    }
                  />
                </RightBox>
              </div>
              <EplipsisMultiline
                isDisabled
                value={notice ? `${decodeURIComponent(JSON.parse(notice).text)}\n(updated: ${license_date ?? "0000-00-00"})` : ""}
              />
            </DescCard>
          );
        })
      )}
      <MarginDiv>{"0"}</MarginDiv>
    </Layout>
  );
};
