import { Info } from "App/Atomics/Input/Select/TextSelect";
import { createDuck } from "lib/store/v2";

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

type Track = {
  subClass: string;
  title?: string;
  no?: string;
  artist?: Info;
  work?: Info;
  rights?: Info;
  license?: Info;
  record?: string;
  publish?: string;
  edition?: string;
  trackUrl: {
    trackMp3: Url;
    trackAac: Url;
    trackFlac: Url;
    trackWav: Url;
  };
  sourceUrl: string;
};

export enum Page {
  FIRST,
  SECOND
}

const createInitialState = () => ({
  singleTrack: {
    subClass: "track",
    trackUrl: {
      trackMp3: {} as Url,
      trackAac: {} as Url,
      trackFlac: {} as Url,
      trackWav: {} as Url
    }
  } as Track,
  page: Page.FIRST as Page
});

export const SingleTrackActions = createDuck({
  namespace: "SingleTrack",
  createInitialState,
  reducers: {
    setPage(state, page: Page) {
      state.page = page;
    },
    setSingleTrackSubClass(state, subClass: string) {
      state.singleTrack.subClass = subClass;
    },
    setSingleTrackNo(state, no: string) {
      state.singleTrack.no = no;
    },
    setSingleTrackTitle(state, title: string) {
      state.singleTrack.title = title;
    },
    setSingleTrackArtist(state, artistInfo: Info) {
      state.singleTrack.artist = artistInfo;
    },
    setSingleTrackWork(state, workInfo: Info) {
      state.singleTrack.work = workInfo;
    },
    setSingleTrackRights(state, rightsInfo: Info) {
      state.singleTrack.rights = rightsInfo;
    },
    setSingleTrackLicense(state, licenseInfo: Info) {
      state.singleTrack.license = licenseInfo;
    },
    setSingleTrackRecord(state, record: string) {
      state.singleTrack.record = record;
      state.singleTrack.publish = record;
      state.singleTrack.edition = record;
    },
    setSingleTrackPublish(state, publish: string) {
      state.singleTrack.publish = publish;
    },
    setSingleTrackEdition(state, edition: string) {
      state.singleTrack.edition = edition;
    },
    setSingleTrackUrl(state, { fileType, url }: { fileType: string; url: string }) {
      switch (fileType) {
        case "mp3high":
          state.singleTrack.trackUrl.trackMp3 = {
            file: state.singleTrack.trackUrl.trackMp3.file,
            ext: "mp3",
            typeUrl: "mp3high",
            data: state.singleTrack.trackUrl.trackMp3.data,
            url: url
          };
          break;
        case "aac":
          state.singleTrack.trackUrl.trackAac = {
            file: state.singleTrack.trackUrl.trackAac.file,
            ext: "m4a",
            typeUrl: "aac",
            data: state.singleTrack.trackUrl.trackAac.data,
            url: url
          };
          break;
        case "flac":
          state.singleTrack.trackUrl.trackFlac = {
            file: state.singleTrack.trackUrl.trackFlac.file,
            ext: "flac",
            typeUrl: "flac",
            data: state.singleTrack.trackUrl.trackFlac.data,
            url: url
          };
          break;
        case "wav":
          state.singleTrack.trackUrl.trackWav = {
            file: state.singleTrack.trackUrl.trackWav.file,
            ext: "wav",
            typeUrl: "wav",
            data: state.singleTrack.trackUrl.trackWav.data,
            url: url
          };
          break;
        default:
          break;
      }
    },
    setSingleTrackData(state, { file, fileType, name }: { file: File; fileType: string; name: string }) {
      switch (fileType) {
        case "mp3high":
          state.singleTrack.trackUrl.trackMp3 = {
            file: file,
            ext: "mp3",
            typeUrl: "mp3high",
            url: name
          } as Url;
          break;
        case "aac":
          state.singleTrack.trackUrl.trackAac = {
            file: file,
            ext: "m4a",
            typeUrl: "aac",
            url: name
          } as Url;
          break;
        case "flac":
          state.singleTrack.trackUrl.trackFlac = {
            file: file,
            ext: "flac",
            typeUrl: "flac",
            url: name
          } as Url;
          break;
        case "wav":
          state.singleTrack.trackUrl.trackWav = {
            file: file,
            ext: "wav",
            typeUrl: "wav",
            url: name
          } as Url;
          break;
        default:
          break;
      }
    },
    updateSingleTrackUrl(state, { key, url }: { key: string; url: string }) {
      switch (key) {
        case "trackMp3":
          state.singleTrack.trackUrl.trackMp3.url = url;
          break;
        case "trackAac":
          state.singleTrack.trackUrl.trackAac.url = url;
          break;
        case "trackFlac":
          state.singleTrack.trackUrl.trackFlac.url = url;
          break;
        case "trackWav":
          state.singleTrack.trackUrl.trackWav.url = url;
          break;
        default:
          break;
      }
    },
    setSingleTrackClear(state) {
      state.singleTrack = {
        subClass: "track",
        trackUrl: {
          trackMp3: {} as Url,
          trackAac: {} as Url,
          trackFlac: {} as Url,
          trackWav: {} as Url
        }
      } as Track;
      state.page = Page.FIRST;
    }
  }
});
