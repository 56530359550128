import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { Artist, Url, Structure, Company } from "GraphQL/Queries/Metadata/GetMetadata";
import { configs } from "configs";
import { useToggle } from "lib/use-toggle";
import { ReactComponent as TitleIcon } from "assets/icons/title.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as PhotoIcon } from "assets/icons/photo.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { ReactComponent as PlayIcon } from "assets/icons/circle-play.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import defaultImage from "assets/images/cover.png";
import { mediaQuery } from "constants/media";
import { Modal } from "lib/modal";
import { ImageViewModal, EditInfoModal, TitleModal } from "../../../Modals";
import { ARTISTS } from "constants/route";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, DANGER_COLOR_LIGHT, PRIMARY_COLOR } from "constants/color";
import uuidv4 from "uuid/v4";
import { ClipBoard } from "App/Molecules/ClipBoard";
import { Confirm } from "App/Molecules/Confirm";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { DeleteMetadata } from "GraphQL/Queries/Track";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";
import { DeleteMetadataUrl } from "GraphQL/Queries/Metadata";
import { allowMetadataUpdate } from "App/Routes/AdminRoutes/allowTables";
import { UserRole } from "constants/UserRole";
import { useAppStore } from "App/Store";
import { useLiveReplayDispatch } from "App/Routes/LiveReplay/Store";
import { AudioPlayerActions, TypeAudio } from "App/Store/AudioPlayer";
import { GRAY_2 } from "constants/baseColor";
import { LiveReplayActions } from "App/Routes/LiveReplay/Store/LiveReplay";
import axios from "axios";
import JSZip from "jszip";
import fileSaver from "file-saver";
import { Loading } from "App/Atomics/Loading";

type Props = {
  index: number;
  metadataId: string;
  title: string;
  metadataUrl: Url[];
  artistRelation: Artist[];
  rightsCompany: Company[];
  validCheck: ValidType;
  structures: Structure[];
};

type File = {
  trackId: string;
  name: string;
  file: Blob;
};

const Layout = styled.li`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding-left: 0.6em;
  padding-right: 0.6em;
  box-sizing: border-box;

  ${mediaQuery(1024)} {
    width: 25%;
  }

  ${mediaQuery(768)} {
    width: 50%;
    padding-left: 0.3em;
    padding-right: 0.3em;
  }
`;

const ImageContainter = styled.div`
  position: relative;
  padding-top: 100%;
  cursor: pointer;
  border-radius: 0.2em;
  overflow: hidden;
  color: transparent;
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: #eee;
    }
  }

  a.link::after {
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6em;
    content: "";
    z-index: 1;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
    transition: opacity 0.2s ease-in;
  }
  .play-btn,
  .play,
  .download {
    opacity: 0;
  }
  &:hover {
    a.link::after {
      opacity: 1;
    }

    .wrapper,
    .delete,
    .photo,
    .document,
    .title,
    .shuffle,
    .play-btn,
    .play,
    .download {
      opacity: 1;
    }
  }
  .svg-wrapper {
    position: absolute;
    width: 100%;
    height: 3rem;
    bottom: 0;
    right: 0;
    z-index: 2;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }
  }

  .delete,
  .photo,
  .document,
  .title,
  .shuffle,
  .download {
    position: absolute;
    bottom: 1.2em;
    z-index: 3;
    opacity: 0;
    fill: #eee;
    width: 1.2em;
    height: 1.2em;
    transition: all 0.15s;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transition: opacity 0.2s ease-in;
    }

    &:hover {
      fill: #fff;
      transform: scale(1.3, 1.3);
    }
  }

  .delete {
    left: 1.2em;
    &:hover {
      fill: #f00;
    }
  }

  .photo {
    right: 1.2em;
  }
  .document {
    right: 3.5em;
  }
  .title {
    right: 5.8em;
  }
  .shuffle {
    right: 8.1em;
  }
  .download {
    right: 8.2em;
    bottom: 1.1em;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  height: 9em;
  max-height: 8em;

  span {
    white-space: pre-wrap;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .id {
    font-size: 0.8em;
    color: #bbb;
  }
  .title {
    font-weight: bold;
    flex-wrap: wrap;
  }
  .artist {
    color: #666;
    cursor: pointer;
    font-size: 0.9em;
    margin-right: 0.2rem;
  }
`;

const Badge = styled.div<{ vc: ValidType }>`
  position: absolute;
  z-index: 1;
  top: 2%;
  right: 3%;
  color: ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR : "#eee")};
  text-shadow: 0 0 2px ${props => (props.vc === ValidType.REMOVE ? DANGER_COLOR_LIGHT : PRIMARY_COLOR)};
`;

export const CardItem = ({ index, metadataId, title, metadataUrl, artistRelation, rightsCompany, validCheck }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [{ store, userRole }, dispatchApp] = useAppStore(store => ({
    store: store,
    userRole: store.UserToken.role
  }));
  const dispatch = useLiveReplayDispatch();
  const router = useHistory();
  const imageViewModal = useToggle();
  const editInfoModal = useToggle();
  const confirmModal = useToggle();
  const titleModal = useToggle();
  const imageUrls = metadataUrl?.filter(({ typeUrl }) => typeUrl === "head" || typeUrl === "thumbnail" || typeUrl === "cover") ?? [];
  const trackUrls = metadataUrl?.filter(({ typeUrl }) => typeUrl === "mp3high" || typeUrl === "junk") ?? [];

  const url =
    imageUrls && imageUrls.length
      ? `${configs.urls.image}/${imageUrls[0].url}${imageUrls[0].url!.includes("?") ? `&` : `?`}mode=m`
      : defaultImage;
  const onPlayLiveReplay = async () => {
    try {
      const url = trackUrls?.find(({ typeUrl }) => typeUrl === "junk")?.url ?? trackUrls?.find(({ typeUrl }) => typeUrl === "mp3high")?.url;
      const uuid = uuidv4();
      const audioData = {
        uuid,
        title,
        artist: artistRelation[0]?.artist[0]?.name ?? "-",
        coverUrl: imageUrls[0]?.url,
        url
      } as TypeAudio;
      if (!audioData?.url) {
        Toast.warning("재생할 음원이 없습니다.");
        return;
      }
      dispatchApp([
        AudioPlayerActions.toggleVisible(true),
        AudioPlayerActions.addAudioInPlaylist(audioData),
        AudioPlayerActions.setAudioData(audioData),
        AudioPlayerActions.setAudioPlay("PLAY")
      ]);
    } catch (err) {
      console.log(err);
      Toast.error("재생목록을 불러올 수 없습니다.");
      return;
    }
  };

  const onDelete = async (id: string) => {
    try {
      dispatch(LiveReplayActions.setEditLoading(true));
      const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Metadata });
      if (accessId) {
        for (const file of [...imageUrls, ...trackUrls]) {
          await DeleteFile({
            filename: file.url,
            companyId: rightsCompany[0].company[0].id,
            fileType: FileType.FILE,
            book: BookType.immediate
          });
          await DeleteMetadataUrl({ uuid: file.uuid });
        }
        const { errors } = await DeleteMetadata({ id });
        if (errors) {
          await DeleteAccessRecord({ id: accessId });
          throw errors[0].message;
        }
        await DeleteAccessRecord({ id: accessId });
        dispatch(LiveReplayActions.setEditLoading(false));
        Toast.primary("삭제되었습니다", undefined, "top-center");
        dispatch(LiveReplayActions.deletePodCast(index));
      }
    } catch (err) {
      console.log(err);
      dispatch(LiveReplayActions.setEditLoading(false));
      Toast.error("삭제에 실패하였습니다.", undefined, "top-center");
      return;
    }
  };

  const onDownloadFile = async () => {
    setLoading(true);
    const junkUrl = metadataUrl.filter(item => item?.typeUrl === "junk")[0]?.url;
    const mp3Url = metadataUrl.filter(item => item?.typeUrl === "mp3high")[0]?.url;
    if (!junkUrl && !mp3Url) {
      Toast.warning("다운로드할 음원이 없습니다.");
      setLoading(false);
      return;
    }
    try {
      const res = await axios({
        method: "get",
        url: `${configs.urls.audio}/${junkUrl ?? mp3Url}`,
        responseType: "blob",
        headers: {
          "Content-Type": "audio/aac"
        }
      });
      const file = {
        trackId: metadataId,
        name: `${title.replace(/[\n/]+/g, "-")}`,
        file: new Blob([res.data], { type: "audio/aac" })
      } as File;
      const zip = new JSZip();
      zip.file(`${file.name}.m4a`, file.file);
      const content = await zip.generateAsync({ type: "blob" });
      fileSaver.saveAs(content, `${file.name}.zip`);
      setLoading(false);
    } catch (err) {
      console.log(err);
      Toast.error("잠시 후 다시 시도해주세요.");
      setLoading(false);
      return;
    }
  };

  return (
    <Layout key={metadataId}>
      <ImageContainter>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="link">
          <img src={url} alt={`${title}-cover`} />
        </a>
        <MusicPlayButton className="play-btn" onClick={e => e.stopPropagation()}>
          <PlayIcon className="play" onClick={onPlayLiveReplay} />
        </MusicPlayButton>

        <Badge vc={validCheck}>{validCheck}</Badge>
        {(userRole === UserRole.Master || allowMetadataUpdate(store)) && (
          <div className="svg-wrapper" onClick={e => e.stopPropagation()}>
            {userRole === UserRole.Master && <DeleteIcon className="delete" onClick={confirmModal.on} />}
            <TitleIcon className="title" onClick={titleModal.on} />
            <DocumentIcon className="document" onClick={editInfoModal.on} />
            <PhotoIcon className="photo" onClick={imageViewModal.on} />
            <DownloadIcon className="download" onClick={onDownloadFile} />
          </div>
        )}
      </ImageContainter>
      <Info>
        <ClipBoard text={metadataId}>
          <span className="id">{metadataId}</span>
        </ClipBoard>
        <ClipBoard text={title}>
          <span className="title">{title}</span>
        </ClipBoard>
        <div>
          {!artistRelation.length ? (
            <span className="artist">{"-"}</span>
          ) : (
            artistRelation.map(({ artist }, index) => {
              const uuid = uuidv4();
              return (
                <span className="artist" key={uuid} onClick={() => router.push(`${ARTISTS}?page=1&q=${artist[0].id}&type=아이디`)}>
                  {`${artist[0].name}${index !== artistRelation.length - 1 ? "," : ""}`}
                </span>
              );
            })
          )}
        </div>
      </Info>
      <Modal isOpen={imageViewModal.isToggled} onClose={imageViewModal.off}>
        <ImageViewModal index={index} id={metadataId} metadataUrl={imageUrls} toClose={imageViewModal.off} />
      </Modal>
      <Modal isOpen={editInfoModal.isToggled} onClose={editInfoModal.off}>
        <EditInfoModal index={index} toClose={editInfoModal.off} />
      </Modal>
      <Modal isOpen={confirmModal.isToggled} onClose={confirmModal.off}>
        <Confirm title={"경고"} context={"정말로 삭제하시겠습니까?"} toSave={() => onDelete(metadataId)} toClose={confirmModal.off} />
      </Modal>
      <Modal isOpen={titleModal.isToggled} onClose={titleModal.off}>
        <TitleModal id={metadataId} toClose={titleModal.off} />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};

const MusicPlayButton = styled.div`
  position: absolute;
  z-index: 1;
  width: 2rem;
  height: 2rem;
  top: 3%;
  left: 3%;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  transition: opacity 0.1s ease-in;
  .play {
    width: 2rem;
    height: 2rem;
    fill: #fff;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_2};
    }
  }
`;
