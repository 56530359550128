import React, { useState, Fragment } from "react";
import styled from "styled-components";
import immer from "immer";
import { Head, Subhead } from "../../Store/ArtistInfo/sanitizeHeads";
import {
  MARGING_SMALL_PX,
  MARGING_LARGE_PX,
  pixelize,
  UNIT,
  PADDING_XX_LARGE_PX,
  BORDER_RADIUS_PX,
  MARGING_XX_LARGE_PX
} from "constants/size";
import { ARTIST_FILTER } from "constants/storageKey";
import { UserRole } from "constants/UserRole";
import { Input } from "App/Atomics/Input";
import { WHITE } from "constants/baseColor";

type Props = Readonly<{
  head: Head;
  subhead: Subhead;
  userRole: UserRole | null;
  onClick: (head: Head, subhead: Subhead) => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: overlay;

  width: ${pixelize(UNIT * 40)};
  padding: ${PADDING_XX_LARGE_PX};

  h3 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  & > button {
    width: ${pixelize(UNIT * 5)};
    margin-top: auto;
    margin-left: auto;
  }
`;

const FilterGroup = styled.section`
  div {
    margin-bottom: ${MARGING_LARGE_PX};
  }

  label {
    font-weight: bold;
  }
`;

const ButtonGroup = styled(Input.Group)`
  width: min-content;
  margin-bottom: ${MARGING_LARGE_PX};

  & > button {
    border-radius: 0px;

    &:first-child {
      border-top-left-radius: ${BORDER_RADIUS_PX};
      border-bottom-left-radius: ${BORDER_RADIUS_PX};
    }

    &:last-child {
      border-top-right-radius: ${BORDER_RADIUS_PX};
      border-bottom-right-radius: ${BORDER_RADIUS_PX};
    }

    & + button {
      border-left: 1px solid ${WHITE};
    }
  }
`;

export const TableFiltermodal = ({ head: initialHead, subhead: initialSubhead, userRole, onClick }: Props) => {
  const [head] = useState(initialHead);
  const [subhead, setSubhead] = useState(initialSubhead);
  const toSave = () => {
    window.localStorage.setItem(
      ARTIST_FILTER,
      JSON.stringify({
        Head: head,
        Subhead: {
          Artist: subhead.Artist,
          ArtistUrl: subhead.ArtistUrl,
          History: subhead.History,
          Role: subhead.Role
        }
      })
    );
    onClick(head, subhead);
  };

  const toggleSubhead = (key: keyof typeof subhead, subKey: string) => {
    setSubhead(
      subhead =>
        immer(subhead as Record<string, Record<string, boolean>>, draft => {
          draft[key][subKey] = !(subhead as Record<string, Record<string, boolean>>)[key][subKey];
        }) as typeof subhead
    );
  };

  return (
    <Layout>
      <h3>테이블 필터</h3>
      <FilterGroup>
        {Object.entries(head)
          .filter(([key, value]) => {
            return key;
          })
          .map(([key], index) => (
            <Fragment key={index}>
              <label>{key}</label>
              <ButtonGroup>
                {Object.entries(subhead[key as keyof typeof subhead]).map(
                  ([subKey, subValue], index) =>
                    subKey !== "artistId" && (
                      <Input.Toggle
                        color="primary"
                        toggleValue={subKey}
                        isActive={subValue}
                        onClick={() => toggleSubhead(key as keyof typeof subhead, subKey)}
                        key={index}
                      />
                    )
                )}
              </ButtonGroup>
            </Fragment>
          ))}
      </FilterGroup>
      <Input.Button isWide onClick={toSave}>
        저장
      </Input.Button>
    </Layout>
  );
};
