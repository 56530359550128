import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_6, GREEN_5, GREEN_4, GREEN_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { SUCCESS_COLOR_DARK, SUCCESS_COLOR } from "constants/color";
import { mediaQuery } from "constants/media";
import { Input } from "App/Atomics/Input";
import { ColumnSection } from "./ColumnSection";
import { Artist, Company, Genre, Metadata, Mood, TrackInformation, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import _ from "lodash";
import { Toast } from "lib/toast";
import { ValidType } from "constants/ValidType";
import {
  UpdateMetadataTitle,
  UpdateMetadataVC,
  CreateMetadataArtist,
  DeleteMetadataArtist,
  UpdateMetadataArtist,
  DeleteMetadataCompany,
  DeleteMetadataUrl,
  DeleteMetadataGenre,
  CreateMetadataGenre,
  CreateTrackInformation,
  UpdateTrackInformation,
  GetMetadataTrack,
  CreateMetadataListRelation,
  DeleteMetadataListRelation,
  UpdateMetadataListRelation,
  UpdateMetadataTitleRelation,
  GetMetadataTitleRelation,
  CreateMetadataUrl,
  UpdateMetadataUrl
} from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteFile } from "GraphQL/Queries";
import { CreateMetadataCompany } from "GraphQL/Queries/Metadata";
import { Loading } from "App/Atomics/Loading";
import { usePlaylistDetailStore } from "App/Routes/PlaylistDetail/Store";
import { PlaylistTrackActions } from "App/Routes/PlaylistDetail/Store/PlaylistTrack";
import { useAsyncEffect } from "lib/use-async-effect";
import { ListRelation } from "GraphQL/Queries/Metadata/GetMetadataTrack";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";

type Props = {
  id: string;
  toClose: () => void;
};

type Keys = keyof Metadata;
type Values = Metadata[Keys];

export enum Page {
  FIRST,
  SECOND,
  THIRD,
  FOURTH
}

export const EditInfoModal = ({ id, toClose }: Props) => {
  const [{ editLoading, origin, track }, dispatch] = usePlaylistDetailStore(store => ({
    editLoading: store.PlaylistTrack.editLoading,
    origin: store.PlaylistTrack.origin,
    track: store.PlaylistTrack.track
  }));
  const [pageIndex, setPageIndex] = useState<Page>(Page.FIRST);

  const onClearAndClose = () => {
    dispatch(PlaylistTrackActions.setTrackClear());
    toClose();
  };

  const onSave = async (origin: Metadata, track: Metadata) => {
    if (_.isEqual(origin, track)) {
      Toast.warning("변경사항이 없습니다.", undefined, "bottom-right");
      return;
    }
    dispatch(PlaylistTrackActions.setEditLoading(true));
    const changeMap = new Map<Keys, { from: Values; to: Values }>();
    const WorkEntries = Object.entries(track);
    const OriginValue = Object.values<Values>(origin);
    const rightsCompanyId = track.rightsCompany[0]?.company[0]?.id;
    WorkEntries.forEach(([key, value], i) => {
      switch (key) {
        case "title":
        case "validCheck":
          if (OriginValue[i] !== value) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        case "artistRelation":
        case "productions":
        case "publications":
        case "metadataUrl":
        case "subdataUnique":
        case "genreRelation":
        case "moodRelation":
        case "listRelation":
        case "trackInformation":
          if (!_.isEqual(OriginValue[i], value)) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        default:
          break;
      }
    });
    const accessId = await requestAccessRecord({ targetId: track.metadataId, targetTable: TargetTableInput.Metadata });
    try {
      for (const [key, { from, to }] of changeMap) {
        switch (key) {
          case "title":
            const { errors: titleErr, data: titleData } = await GetMetadataTitleRelation({ id: track.metadataId, typeTitle: "name" });
            if (titleErr || !titleData) {
              throw titleErr;
            }
            const titleRelationId = titleData.metadata[0]?.titleRelation[0]?.uuid;
            await UpdateMetadataTitle({ metadataId: track.metadataId, title: to as string });
            if (titleRelationId) {
              await UpdateMetadataTitleRelation({ id: titleRelationId, typeTitle: "name", value: to as string });
            }
            break;
          case "validCheck":
            await UpdateMetadataVC({ metadataId: track.metadataId, validCheck: to as ValidType });
            break;
          case "artistRelation":
            const deleteArtistList = (from as Artist[])
              .filter(({ uuid }) => !(to as Artist[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createArtistList = (to as Artist[]).filter(
              ({ uuid, artist, role }) => !(from as Artist[]).map(({ uuid }) => uuid).includes(uuid) && !!artist[0].id && !!role[0].id
            );
            const updateArtistList = (to as Artist[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as Artist[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteArtistList) {
              await DeleteMetadataArtist({ uuid });
            }
            for (const { uuid, artist, role, character, order, validCheck } of createArtistList) {
              const artistId = artist[0]?.id ?? undefined;
              const roleId = role[0]?.id ?? undefined;
              const characterId = character[0]?.id ?? undefined;
              const { data } = await CreateMetadataArtist({
                metadataId: track.metadataId,
                artistId,
                roleId,
                characterId,
                order,
                validCheck
              });
              if (data) {
                dispatch(
                  PlaylistTrackActions.updateTrackArtistUuid({
                    prevUuid: uuid,
                    uuid: data.createMetadataArtist.metadata_artist_relation[0].uuid
                  })
                );
              }
            }

            for (const { uuid, artist, role, character, order, validCheck } of updateArtistList) {
              const artistId = artist[0]?.id ?? undefined;
              const roleId = role[0]?.id ?? undefined;
              const characterId = character[0]?.id ?? undefined;
              await UpdateMetadataArtist({ uuid, artistId, roleId, characterId, order, validCheck });
            }
            break;
          case "productions":
          case "publications":
            const deleteCompanyList = (from as Company[])
              .filter(({ uuid }) => !(to as Company[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createCompanyList = (to as Company[]).filter(({ uuid }) => !(from as Company[]).map(({ uuid }) => uuid).includes(uuid));
            for (const uuid of deleteCompanyList) {
              await DeleteMetadataCompany({ uuid });
            }
            for (const { uuid, company, typeKind, order, validCheck } of createCompanyList) {
              const companyId = company[0]?.id ?? undefined;
              const { data, errors } = await CreateMetadataCompany({
                metadataId: track.metadataId,
                companyId,
                typeKind,
                order,
                validCheck
              });
              if (errors) {
                throw new Error("중복된 데이터가 존재합니다.");
              }
              if (data) {
                dispatch(
                  PlaylistTrackActions.updateTrackCompanyUuid({
                    typeKind,
                    prevUuid: uuid,
                    uuid: data.createMetadataCompany.metadata_company_relation[0].uuid
                  })
                );
              }
            }
            break;
          case "metadataUrl":
            const deleteUrlList = (from as Url[]).filter(({ uuid }) => !(to as Url[]).map(({ uuid }) => uuid).includes(uuid));
            const createUrlList = (to as Url[]).filter(
              ({ uuid, typeUrl }) =>
                !(from as Url[]).map(({ uuid }) => uuid).includes(uuid) &&
                typeUrl !== "mp3high" &&
                typeUrl !== "aac" &&
                typeUrl !== "flac" &&
                typeUrl !== "wav" &&
                typeUrl !== "txt" &&
                typeUrl !== "zip"
            );
            const updateUrlList = (to as Url[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as Url[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            if (rightsCompanyId) {
              for (const { uuid, typeUrl, url } of deleteUrlList) {
                if (
                  typeUrl === "mp3high" ||
                  typeUrl === "aac" ||
                  typeUrl === "flac" ||
                  typeUrl === "wav" ||
                  typeUrl === "txt" ||
                  typeUrl === "zip"
                ) {
                  await DeleteFile({
                    filename: url,
                    companyId: rightsCompanyId,
                    fileType: FileType.FILE,
                    book: BookType.immediate
                  });
                }
                await DeleteMetadataUrl({ uuid });
              }
            } else {
              Toast.warning("트랙을 삭제할 수 없습니다.");
            }
            for (const { typeUrl, url, order } of createUrlList) {
              await CreateMetadataUrl({ metadataId: track.metadataId, typeUrl: typeUrl!, url: url!, order });
            }
            for (const { uuid, typeUrl, url } of updateUrlList) {
              await UpdateMetadataUrl({ uuid: uuid, typeUrl: typeUrl!, url: url! });
            }
            break;
          case "listRelation":
            const deleteListRelation = (from as ListRelation[])
              .filter(({ uuid }) => !(to as ListRelation[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createListRelation = (to as ListRelation[]).filter(
              ({ uuid }) => !(from as ListRelation[]).map(({ uuid }) => uuid).includes(uuid)
            );
            const updateListRelation = (to as ListRelation[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as ListRelation[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteListRelation) {
              await DeleteMetadataListRelation({ uuid });
            }
            for (const { uuid, order, elementRelation } of createListRelation) {
              const { metadata_self_relations_list_metadata } = await CreateMetadataListRelation({
                metadataId: track.metadataId,
                typeFunction: "work",
                order,
                elementId: elementRelation[0].id!
              });
              dispatch(
                PlaylistTrackActions.updateTrackElementRelationUuid({
                  prevUuid: uuid,
                  uuid: metadata_self_relations_list_metadata[0].uuid
                })
              );
            }

            for (const { uuid, elementRelation } of updateListRelation) {
              await UpdateMetadataListRelation({ uuid, elementId: elementRelation[0].id });
            }
            break;
          case "genreRelation":
            const deleteGenreList = (from as Genre[])
              .filter(({ uuid }) => !(to as Genre[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createGenreList = (to as Genre[]).filter(({ uuid }) => !(from as Genre[]).map(({ uuid }) => uuid).includes(uuid));
            for (const uuid of deleteGenreList) {
              await DeleteMetadataGenre({ uuid });
            }
            for (const { uuid, genre, validCheck } of createGenreList) {
              const { data } = await CreateMetadataGenre({
                metadataId: track.metadataId,
                genreId: genre[0].id,
                validCheck
              });
              if (data) {
                dispatch(
                  PlaylistTrackActions.updateTrackGenreUuid({
                    prevUuid: uuid,
                    uuid: data.createMetadataGenre.metadata_genre_relation[0].uuid
                  })
                );
              }
            }
            break;
          case "moodRelation":
            const deleteMoodList = (from as Mood[])
              .filter(({ uuid }) => !(to as Mood[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createMoodList = (to as Mood[]).filter(({ uuid }) => !(from as Mood[]).map(({ uuid }) => uuid).includes(uuid));
            for (const uuid of deleteMoodList) {
              await DeleteMetadataGenre({ uuid });
            }
            for (const { uuid, mood, validCheck } of createMoodList) {
              const { data } = await CreateMetadataGenre({
                metadataId: track.metadataId,
                genreId: mood[0].id,
                validCheck
              });
              if (data) {
                dispatch(
                  PlaylistTrackActions.updateTrackMoodUuid({
                    prevUuid: uuid,
                    uuid: data.createMetadataGenre.metadata_genre_relation[0].uuid
                  })
                );
              }
            }
            break;
          case "trackInformation":
            const {
              uuid: infoUuid,
              channel,
              bitrate,
              tempo,
              tonality,
              timeSignature,
              voiceGender,
              duration,
              extraData
            } = to![0] as TrackInformation;
            if (infoUuid === "fake_uuid") {
              // create
              await CreateTrackInformation({
                metadataId: track.metadataId,
                channel,
                bitrate,
                tempo,
                tonality,
                duration,
                timeSignature,
                voiceGender,
                extraData
              });
            } else {
              // update
              await UpdateTrackInformation({
                uuid: infoUuid!,
                channel,
                bitrate,
                tempo,
                tonality,
                duration,
                timeSignature,
                voiceGender,
                extraData
              });
            }
            break;
          default:
            break;
        }
      }
    } catch (err) {
      console.log(err);
      Toast.error("저장을 실패하였습니다.", undefined, "top-center");
      dispatch([PlaylistTrackActions.setEditLoading(false), PlaylistTrackActions.setTrackClear()]);
      await DeleteAccessRecord({ id: accessId! });
      return;
    }
    await DeleteAccessRecord({ id: accessId! });
    dispatch(PlaylistTrackActions.setEditLoading(false));
    window.alert("성공적으로 저장되었습니다. 확인버튼을 누르면 새로고침 됩니다.");
    window.location.reload();

    toClose();
  };

  useAsyncEffect(async isMounted => {
    dispatch(PlaylistTrackActions.setEditLoading(true));
    if (isMounted()) {
      try {
        const { errors, data } = await GetMetadataTrack({
          first: 1,
          metadataId: id,
          typeClass: "record",
          typeSubClassIn: ["track", "effect"]
        });
        if (errors) {
          throw errors;
        }
        if (data) {
          dispatch(PlaylistTrackActions.setTrack(data.metadata[0]));
        }
      } catch (err) {
        console.log(err);
        Toast.error("데이터를 가져올 수 없습니다.");
      } finally {
        dispatch(PlaylistTrackActions.setEditLoading(false));
      }
    }
  }, []);

  return (
    <Layout>
      <Header>
        <DocumentIcon className="document" />
        <h3>트랙 정보 및 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <ul className="left-container">
          <li className={pageIndex === Page.FIRST ? "active" : ""} onClick={() => setPageIndex(Page.FIRST)}>
            메인 정보
          </li>
          <li className={pageIndex === Page.SECOND ? "active" : ""} onClick={() => setPageIndex(Page.SECOND)}>
            장르 / 무드
          </li>
          <li className={pageIndex === Page.THIRD ? "active" : ""} onClick={() => setPageIndex(Page.THIRD)}>
            연관 작품
          </li>
          <li className={pageIndex === Page.FOURTH ? "active" : ""} onClick={() => setPageIndex(Page.FOURTH)}>
            부가 정보
          </li>
        </ul>
        <div className="right">
          {track?.metadataId && <ColumnSection data={track} pageIndex={pageIndex} />}
          <ButtonGroup>
            <Input.Button className="btn cancel" disabled={editLoading} isFill={false} color="success" onClick={onClearAndClose}>
              취소
            </Input.Button>
            <Input.Button
              className="btn save"
              disabled={editLoading}
              isFill
              color="success"
              onClick={async () => await onSave(origin, track)}
            >
              저장
            </Input.Button>
          </ButtonGroup>
        </div>
      </Section>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  right: 0%;
  top: 0%;
  width: 1200px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.8rem;
  ${mediaQuery(1200)} {
    width: 100%;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .document {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: 0.4rem;
    fill: ${SUCCESS_COLOR_DARK};
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  display: flex;
  height: 100%;
  .left-container {
    width: 7rem;
    border-right: 1px solid #eee;
    li {
      display: flex;
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      color: #fff;
      background-color: ${GREEN_5};

      &:hover {
        background-color: ${GREEN_4};
      }
      &:focus {
        background-color: ${GREEN_6};
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 5rem;
    overflow-y: scroll;
  }

  ${mediaQuery(965)} {
    flex-direction: column;
    .left-container {
      width: 100%;
      display: flex;
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
  .btn {
    width: 6rem;
    margin-right: 0.5rem;
  }
  .cancel {
    &:hover {
      background-color: #eee;
    }
  }
  /* Input.Button 기본 css를 수정해야함. */
  .save {
    border: 1px solid ${SUCCESS_COLOR};
    &:hover {
      border: 1px solid ${SUCCESS_COLOR};
    }
  }
`;
