/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_X_LARGE,
  PADDING_X_LARGE_PX,
  MARGING_SMALL_PX
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as AddToPlaylistIcon } from "assets/icons/add-to-playlist.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as NoDataIcon } from "assets/icons/no-data.svg";
import { BLUE_6, GRAY_1, GRAY_2, GRAY_4, GRAY_6, WHITE } from "constants/baseColor";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAppStore } from "App/Store";
import { AddTracksToPlaylist, GetPlaylistInfo } from "GraphQL/Queries/Playlist";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { Loading } from "App/Atomics/Loading";
import { usePlaylistCreateSelector } from "../../Store";
import { Toast } from "lib/toast";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { CommentModal } from "../CommentModal";
import { GetPlaylistInfoByEmail } from "GraphQL/Queries/Playlist/GetPlaylistInfoByEmail";
import { GetPlaylistMetadataRelationCount } from "GraphQL/Queries/Playlist/GetPlaylistMetadataRelationCount";

type Props = Readonly<{
  toClose: () => void;
}>;

const colorAnim = keyframes`
  0% {
    background-color: ${WHITE};
  };
  50% {
    background-color: ${GRAY_1};
  };
  100% {
    background-color: ${GRAY_2};
  };
`;

export type AddedTrackInfo = {
  playlistId?: string;
  trackList: string[];
};

export const PlaylistModal = ({ toClose }: Props) => {
  const [{ email }] = useAppStore(store => ({
    email: store.UserToken.email
  }));
  const { selectedTrackList: trackList } = usePlaylistCreateSelector(store => store.TrackList);
  const [playlist, setPlaylist] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [addedTrackInfo, setAddedTrackInfo] = useState<AddedTrackInfo>({} as AddedTrackInfo);
  const confirmModal = useToggle();
  const commentModal = useToggle();

  const onAddTrackToPlaylist = useCallback(
    async (id: string) => {
      if (!trackList.length) {
        Toast.error("추가할 트랙이 없습니다.", undefined, "top-center");
        return;
      }
      try {
        setLoading(true);
        const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
        if (accessId) {
          try {
            const playlistInfo = await GetPlaylistInfo({ id });
            const ids = playlistInfo[0].metadataPlaylistRelation.map(({ metadata }) => metadata[0].metadataId);
            const trackInfo = trackList.map(({ id }) => id);
            const filteredTrackInfo = trackInfo.filter(trackId => !ids.includes(trackId)); // 플레이리스트의 트랙과 체크한 트랙의 id를 비교
            if (filteredTrackInfo.length === 0) {
              Toast.warning("모든 음원이 중복됩니다.", undefined, "top-center");
              return;
            } else if (trackInfo.length !== filteredTrackInfo.length) {
              Toast.primary(`중복을 제외한 ${filteredTrackInfo.length}개의 음원이 추가되었습니다.`, undefined, "top-center");
            }
            const { errors } = await AddTracksToPlaylist({ id, trackInfo: filteredTrackInfo });
            if (errors) {
              throw errors;
            }
            setAddedTrackInfo({ playlistId: id, trackList: filteredTrackInfo });
            confirmModal.on();
          } catch (err) {
            console.log(err);
            Toast.error("권한 요청에 실패하였습니다", undefined, "top-center");
            return;
          } finally {
            await DeleteAccessRecord({ id: accessId });
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        Toast.error("권한 요청에 실패하였습니다", undefined, "top-center");
        setLoading(false);
        return;
      }
    },
    [confirmModal, trackList]
  );

  useAsyncEffect(async isMounted => {
    setLoading(true);
    if (isMounted()) {
      try {
        const playlistData = await GetPlaylistInfoByEmail({ email });
        for (const list of playlistData) {
          const count = await GetPlaylistMetadataRelationCount({ id: list.id });
          list.count = count;
        }
        setPlaylist(playlistData);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        return;
      }
    }
  }, []);

  return (
    <Layout>
      <Header>
        <div className="title">
          <h3>
            <AddToPlaylistIcon />
            기존 플레이리스트에 등록
          </h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
      </Header>
      {!playlist?.length ? (
        <EmptyMessage>
          <NoDataIcon />
          <span>해당 계정으로 생성한 플레이리스트가 없습니다.</span>
        </EmptyMessage>
      ) : (
        playlist.map(({ id, name, kind, count }: any) => {
          return (
            <ListBox key={id}>
              <div className="left">{id}</div>
              <div className="right">
                <div className="right-top">
                  <span className="kind">[{kind}]</span>
                  <span className="name">{name}</span>
                </div>
                <span className="right-bottom">{count}곡</span>
              </div>
              <ButtonGroup>
                <IconButton onClick={() => onAddTrackToPlaylist(id)}>
                  <PlusIcon />
                  <button>담기</button>
                </IconButton>
              </ButtonGroup>
            </ListBox>
          );
        })
      )}
      <Loading loading={loading} />
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm
          title="알림"
          context={"플레이리스트에 곡이 추가되었습니다!\n추가적으로 댓글을 작성하시겠습니까?"}
          toSave={commentModal.on}
          toClose={confirmModal.off}
        />
      </Modal>
      <Modal isOpen={commentModal.isToggled} onClose={commentModal.off}>
        <CommentModal
          addedTrackInfo={addedTrackInfo}
          toClose={() => {
            setAddedTrackInfo({} as AddedTrackInfo);
            commentModal.off();
          }}
        />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 42)};
  height: ${pixelize(UNIT * 36)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  font-size: 0.9rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: #6a5fdd;
  color: #fff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  width: 100%;
  display: flex;
  h3 {
    font-size: 1.1rem;
    svg {
      fill: #fff;
      width: ${pixelize(UNIT * 1.1)};
      height: ${pixelize(UNIT * 1.1)};
      margin-right: ${MARGING_LARGE_PX};
    }
  }
  .title {
    width: 100%;
    padding: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: #fff;
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const ListBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
  grid-gap: ${MARGING_SMALL_PX};
  padding: ${PADDING_X_LARGE_PX};
  background-color: ${WHITE};
  animation: all 0.2s;
  border-bottom: 1px solid #ddd;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${MARGING_LARGE_PX};
  }
  .right {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .right-top {
      .name {
        margin-left: ${MARGING_SMALL_PX};
      }
      .kind {
        color: ${BLUE_6};
      }
    }
    .right-bottom {
      color: ${GRAY_6};
    }
  }

  &:hover {
    animation: ${colorAnim} 0.1s ease-in-out;
    background-color: ${GRAY_2};
  }
`;

const EmptyMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  svg {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1rem;
  }
  span {
    font-size: 0.8rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: ${MARGING_SMALL_PX};
`;

const IconButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
  font-size: 0.8rem;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
    fill: #000;
    margin-bottom: 8px;
  }
  &:hover {
    color: ${GRAY_6};
    svg {
      fill: ${GRAY_6};
    }
  }
`;
