import React, { ReactNode } from "react";
import styled from "styled-components";

import { HEADER_HEIGHT_PX, PADDING_X_LARGE_PX } from "constants/size";

type Props = Styleable & Readonly<{ children: ReactNode }>;

const Layout = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  position: fixed;
  height: ${HEADER_HEIGHT_PX};

  padding-right: ${PADDING_X_LARGE_PX};
  z-index: 8;
  overflow: hidden;
`;

export const Topbar = ({ className, children, style }: Props) => (
  <Layout style={style} className={className}>
    {children}
  </Layout>
);
