/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  id: string;
  typeTitle: string | null;
  value: string;
  languageCode: string;
  order: number;
  validCheck?: ValidType;
}>;

export const CreateMetadataTitleRelation = async (option: Option) => {
  const $id = <Variable name="metadata_Id" scalar={<IdScalar />} value={option.id} />;
  const $typeTitle = <Variable name="type_title" scalar={<StringScalar isNullable={false} />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar />} value={option.order} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar isNullable />} value={option.validCheck} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_TITLE_RELATION">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataTitleRelation"
        argument={{
          where: {
            metadata_id: $id
          },
          data: {
            _onlySearch: true,
            metadata_title: {
              create: [
                {
                  type_metadata_title: $typeTitle,
                  exposure_order: $order,
                  language: { connect: { language_code: $languageCode } },
                  value: $value,
                  valid_check: $validCheck
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata_title" alias="titleRelation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createMetadataTitleRelation: {
      id: string;
      titleRelation: {
        uuid: string;
      }[];
    };
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
