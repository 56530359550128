import React, { useState } from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { MARGING_LARGE_PX } from "constants/size";
import { AlbumForm, WorkForm, SearchForm, ArtistForm } from "./Form";
import { SearchStoreProvider, useSearchStore } from "./Store";
import { VIOLET_4 } from "constants/baseColor";
import { Helmet } from "App/Atomics/Helmet";
import { useQueryParams } from "lib/use-query-params";
import { useAsyncEffect } from "lib/use-async-effect";
import { GetMetadata } from "GraphQL/Queries/Metadata";
import { Loading } from "App/Atomics/Loading";
import { GetArtist } from "GraphQL/Queries";
import { SearchActions } from "./Store/Search";
import { mediaQuery } from "constants/media";

const Layout = styled.div`
  padding: 2rem;

  .loading {
    transition: left 0.5s;
    left: 58%;
    ${mediaQuery(1200)} {
      left: 50%;
    }
  }
`;

export const MovePageButton = styled.div`
  color: #4c52bc;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: ${MARGING_LARGE_PX};
  &:hover {
    color: ${VIOLET_4};
  }
`;

type SearchLoading = {
  album: boolean;
  work: boolean;
  artist: boolean;
};

const SearchLayout = () => {
  const [{ album, work, artist }, dispatch] = useSearchStore(store => store.Search);
  const [loading, setLoading] = useState<SearchLoading>({ album: false, work: false, artist: false });
  const queryParams = useQueryParams();
  const searchQuery = queryParams.get("query", { default: undefined });
  useAsyncEffect(
    async isMounted => {
      setLoading({ album: true, work: true, artist: true });
      if (isMounted()) {
        if (searchQuery) {
          const { data: album } = await GetMetadata({
            first: 5,
            typeClassIn: ["record", "theater"],
            typeSubClass: "album",
            title: searchQuery
          });
          dispatch(SearchActions.setSearchAlbum(album?.metadata ?? []));
          setLoading(prev => ({ ...prev, album: false }));
          const { data: work } = await GetMetadata({ first: 5, typeClassIn: ["work"], typeSubClass: "album", title: searchQuery });
          dispatch(SearchActions.setSearchWork(work?.metadata ?? []));
          setLoading(prev => ({ ...prev, work: false }));
          const { data: artist } = await GetArtist({ first: 5, name: searchQuery });
          dispatch(SearchActions.setSearchArtist(artist?.artist ?? []));
          setLoading(prev => ({ ...prev, artist: false }));
        }
      }
    },
    [searchQuery]
  );

  return (
    <Layout>
      <SearchForm />
      {searchQuery && (
        <div>
          {!loading.album && <AlbumForm album={album} searchQuery={searchQuery} />}
          {!loading.work && <WorkForm work={work} searchQuery={searchQuery} />}
          {!loading.artist && <ArtistForm artist={artist} searchQuery={searchQuery} />}
        </div>
      )}
      {searchQuery && <Loading loading={loading.album || loading.work || loading.artist} />}
    </Layout>
  );
};

export const Search = () => (
  <SearchStoreProvider>
    <Helmet title="전체 검색" />
    <ResponsiveToSideNavigatorTemplate>
      <SearchLayout />
    </ResponsiveToSideNavigatorTemplate>
  </SearchStoreProvider>
);
