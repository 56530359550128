import React from "react";
import styled from "styled-components";
import { Helmet } from "App/Atomics/Helmet";
import { LeftSideBar } from "App/Molecules/LeftSidebar";
import { Progress } from "utils/progress";
import { ProductTable } from "./ProductTable";
import { useLoadProduct } from "./Hooks/useLoadProduct";

export const Product = () => {
  const { loading } = useLoadProduct();

  return (
    <Layout>
      <Helmet title="굿즈 관리" />
      <Progress.Bottom />
      <LeftSideBar />
      <ProductTable loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.9rem;
`;
