import React from "react";
import { FilterGroup, Filters } from "../..";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { GenreTagAutoComplete } from "App/Molecules/AutoCompletes/Genre";

type Props = {
  genre?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const GenreFilter = ({ genre, setFilters }: Props) => (
  <FilterGroup>
    <h4>장르</h4>
    <span className="info">여러 개의 장르을 선택할 수 있습니다.</span>
    <GenreTagAutoComplete
      menuPlacement="top"
      defaultValue={genre?.length ? genre.map(({ id, name }) => ({ id, name: window.decodeURIComponent(name) })) : undefined}
      onChange={info => {
        if (info) {
          const list = info.map(({ id, name }) => ({ id, name: window.encodeURIComponent(name) }));
          setFilters(filter => ({ ...filter, genre: list }));
        }
      }}
    />
  </FilterGroup>
);
