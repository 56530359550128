/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";

type Props = Readonly<{
  value?: ValidType;
  isNullable?: boolean;
}>;

const items = [ValidType.V1, ValidType.V2, ValidType.V3, ValidType.VC, ValidType.MODIFY, ValidType.REMOVE, ValidType.DONE, ValidType.ETC];

export const ValidTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="ValidType" value={value} isNullable={isNullable} items={items} />
);
