import { HEADER_HEIGHT_PX } from "constants/size";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { useGoodsCreateSelector } from "../Store";
import { Section } from "../Store/GoodsCreate";
import { NavBar } from "./NavBar";
import { First, Second, Third } from "./Section";

export const GoodsForm = (): ReactElement => {
  const { section } = useGoodsCreateSelector(store => store.GoodsCreate);

  return (
    <Layout>
      <NavBar section={section} />
      {section === Section.First && <First />}
      {section === Section.Second && <Second />}
      {section === Section.Third && <Third />}
    </Layout>
  );
};

const Layout = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  width: 100%;
  max-width: 570px;
  padding: 60px 26px 140px;
  margin-right: auto;
  margin-left: auto;
`;
