import { useState, useMemo } from "react";

export const useToggle = (isToggled = false) => {
  const [state, setState] = useState(isToggled);
  const createMemo = () => ({
    isToggled: state,
    on() {
      setState(true);
    },
    off() {
      setState(false);
    },
    toggle(isToggled?: boolean) {
      if (isToggled === undefined) {
        setState(isToggled => !isToggled);
      } else {
        setState(isToggled);
      }
    }
  });
  return useMemo(createMemo, [state]);
};
