/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, IntScalar, StringScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { PlaylistOrderByInputScalar, MetadataPlaylistRelationOrderScalar, ServiceTypeScalar } from "GraphQL/Scalars";
import { PlaylistOrderByInput } from "constants/PlaylistOrderByInput";
import { MetadataPlaylistRelationOrderByInput } from "constants/MetadataPlaylistRelationOrderByInput";
import { ServiceType } from "constants/ServiceType";

type Option = Readonly<{
  id?: string;
  idNot?: string;
  idList?: string[];
  first?: number;
  skip?: number;
  kind?: string;
  serviceType?: ServiceType;
}>;

export type Playlist = {
  id: string;
  name: string;
  kind: string;
  metadataPlaylistRelation: {
    uuid: string;
    order: number;
    metadata: {
      id: string;
      title: string;
      artistRelation: {
        artist: {
          name: string;
        }[];
      }[];
      metadataUrl: {
        uuid: string;
        typeUrl: string;
        url: string;
      }[];
    }[];
  }[];
};

export const GetPlaylistWithTrack = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable />} value={option.id} />;
  const $idNot = <Variable name="id_not" scalar={<IdScalar isNullable />} value={option.idNot} />;
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $skip = <Variable name="skip" scalar={<IntScalar isNullable />} value={option.skip} />;
  const $kind = <Variable name="kind" scalar={<StringScalar isNullable />} value={option.kind} />;
  const $serviceType = <Variable name="service_type" scalar={<ServiceTypeScalar isNullable />} value={option.serviceType} />;
  const $metadataIdIn = (
    <Variable name="metadata_id__in" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={option.idList} />
  );
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_WITH_TRACK">
      <SelectionSet
        name="playlist"
        argument={{
          first: 999,
          orderBy: <PlaylistOrderByInputScalar value={PlaylistOrderByInput.playlist_id_DESC} />,
          where: {
            playlist_id: $id,
            playlist_id__not: $idNot,
            kind: $kind,
            service: $serviceType
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="kind" />
        <Field name="name" />
        <Field name="service" />
        <SelectionSet
          name="metadata_playlist_relation"
          alias="metadataPlaylistRelation"
          argument={{
            first: $first,
            skip: $skip,
            group: true,
            orderBy: <MetadataPlaylistRelationOrderScalar value={MetadataPlaylistRelationOrderByInput.exposure_order_ASC} />,
            where: {
              metadata__some: {
                metadata_id__in: $metadataIdIn
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata">
            <Field name="metadata_id" alias="id" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
            <SelectionSet name="metadata_url" alias="metadataUrl">
              <Field name="id" alias="uuid" />
              <Field name="type_url" alias="typeUrl" />
              <Field name="url" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    playlist: Playlist[];
  }>;
  return await clients.playlist.request<Data>(query, variables);
};
