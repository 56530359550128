import React from "react";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { Helmet } from "App/Atomics/Helmet";
import { SettleStoreProvider } from "./Store";
import { SettleMain, SettleMonthly, SettleDetail } from "./SettlePage";
import { useLocation } from "react-router";
import { SETTLEMENT, SETTLEMENT_DETAIL, SETTLEMENT_MONTHLY } from "constants/route";

export enum SettlePage {
  FIRST,
  SECOND,
  THIRD
}

export const Settlement = () => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <SettleStoreProvider>
      <Helmet title="정산 내역" />
      <ResponsiveToSideNavigatorTemplate>
        {path === SETTLEMENT && <SettleMain />}
        {path === SETTLEMENT_MONTHLY && <SettleMonthly />}
        {path === SETTLEMENT_DETAIL && <SettleDetail />}
      </ResponsiveToSideNavigatorTemplate>
    </SettleStoreProvider>
  );
};
