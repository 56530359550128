/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar } from "graphql-jsx";
import { Toast } from "lib/toast";

import { clients } from "utils/clients";

export const findPlaylist = async (keyword: string) => {
  const $keyword = <Variable name="keyword" scalar={<StringScalar isNullable />} value={keyword} />;

  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_WITH_TRACK">
      <SelectionSet
        name="playlist"
        argument={{
          first: 40,
          where: {
            OR: [
              {
                name__contains: $keyword
              },
              {
                kind__contains: $keyword
              }
            ]
          }
        }}
      >
        <Field name="playlist_id" alias="id" />
        <Field name="kind" />
        <Field name="name" />
        <SelectionSet
          name="metadata_playlist_relation"
          alias="metadataPlaylistRelation"
          argument={{
            first: 999
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata">
            <Field name="metadata_id" alias="id" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
            <SelectionSet name="metadata_url" alias="metadataUrl">
              <Field name="id" alias="uuid" />
              <Field name="type_url" alias="typeUrl" />
              <Field name="id" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    playlist: {
      id: string;
      kind: string;
      name: string;
      metadataPlaylistRelation: ReadonlyArray<{
        uuid: string;
        metadata: ReadonlyArray<{
          id: string;
          title: string;
        }>;
      }>;
    }[];
  }>;
  const { data, errors } = await clients.playlist.request<Data>(query, variables);
  if (errors) {
    console.log(errors[0].message);
    Toast.error("플레이리스트 정보를 가져올 수 없습니다", undefined, "top-center");
    return [];
  }
  if (data) {
    let dataList = data.playlist.map(item => {
      const res = { id: item.id, name: `(${item.kind}) ${item.name}`, extra: item.metadataPlaylistRelation };
      return res;
    });
    return dataList;
  } else {
    return [];
  }
};
