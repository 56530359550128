import React, { ReactElement, useRef } from "react";
import styled from "styled-components";
import { Style } from "App/Routes/GoodsDetail/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";
import { GoodsUrl } from "GraphQL/Queries/Goods/LoadGoodsDetail";
import { GRAY_1, GRAY_2, WHITE } from "constants/baseColor";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { configs } from "configs";
import { Input } from "App/Atomics/Input";

type Props = {
  readonly type: "cover" | "detail" | "video";
  readonly urls: GoodsUrl[];
};

export const GoodsUrls = ({ type, urls }: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const addCoverUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    const id = uuidv4();
    const order = !urls?.length ? 0 : (_.last(urls)?.order ?? 0) + 1;
    try {
      fileReader.readAsDataURL(file.files![0]);
      fileReader.onloadend = e => {
        const data = {
          id,
          file: file.files![0],
          data: e.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          url: file.files![0].name,
          typeUrl: file.files![0].type,
          order
        };
        if (type === "cover") {
          GoodsDetailStore.dispatch(GoodsDetailActions.addCoverUrl(data));
          inputRef.current!.value = "";
        } else if (type === "detail") {
          GoodsDetailStore.dispatch(GoodsDetailActions.addDetailUrl(data));
          inputRef.current!.value = "";
        } else {
          GoodsDetailStore.dispatch(GoodsDetailActions.addVideoUrl(data));
          inputRef.current!.value = "";
        }
      };
    } catch (err) {
      return;
    }
  };

  const onChangeOrder = (type: "cover" | "detail" | "video", index: number, order: number) =>
    GoodsDetailStore.dispatch(
      type === "cover"
        ? GoodsDetailActions.updateCoverUrlOrder({ index, order })
        : type === "detail"
        ? GoodsDetailActions.updateDetailUrlOrder({ index, order })
        : GoodsDetailActions.updateVideoUrlOrder({ index, order })
    );

  const deleteUrl = (index: number) =>
    GoodsDetailStore.dispatch(
      type === "cover"
        ? GoodsDetailActions.removeCoverUrl(index)
        : type === "detail"
        ? GoodsDetailActions.removeDetailUrl(index)
        : GoodsDetailActions.removeVideoUrl(index)
    );

  return (
    <Style.Row>
      <Style.Caption>{`${type === "cover" ? "커버 이미지" : type === "detail" ? "상세 이미지" : "동영상"}`}</Style.Caption>
      <Style.SubCaption>이미지를 누르면 삭제됩니다.</Style.SubCaption>
      <ImageContainer>
        <UploadButton>
          <AddLabel htmlFor={`input-file-${type}`}>+</AddLabel>
          <HiddenInput
            ref={inputRef}
            type="file"
            id={`input-file-${type}`}
            accept={type === "video" ? "video/mp4" : "image/jpeg,image/png"}
            onChange={addCoverUrl}
          />
        </UploadButton>
        {urls?.map((cover, index) => (
          <ImageWrap key={cover.id}>
            {type === "video" ? (
              <Video
                src={!cover?.file ? `${configs.urls.image}/${cover.url}` : URL.createObjectURL(cover.file)}
                autoPlay={false}
                onClick={() => deleteUrl(index)}
              />
            ) : (
              <Image
                src={!cover?.file ? `${configs.urls.image}/${cover.url}` : URL.createObjectURL(cover.file)}
                alt="cover"
                onClick={() => deleteUrl(index)}
              />
            )}
            <OrderInput min={0} max={99} step={1} defaultValue={cover.order} onBlur={order => onChangeOrder(type, index, order)} />
          </ImageWrap>
        ))}
      </ImageContainer>
    </Style.Row>
  );
};

const UploadButton = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  transition: background 0.15s;
  &:hover {
    background: ${GRAY_1};
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ImageContainer = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  align-items: center;

  @media (min-width: 720px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
const ImageWrap = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;

const AddLabel = styled.label`
  font-size: 36px;
  color: ${GRAY_2};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const OrderInput = styled(Input.Number)`
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
  border-radius: 2px;
  color: ${WHITE};
  font-size: 14px;
  font-weight: bold;
  padding: 2px 4px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
