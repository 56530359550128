import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import React from "react";
import { FilterItem, OptionButton } from "../../formStyle";

export const LicenseOptionFilter = () => {
  const [{ mode, searchList }, dispatch] = useAuthGrantStore(store => ({
    mode: store.IdList.mode,
    searchList: store.IdList.searchList
  }));
  const licenses = searchList.Track.licenses;
  const onToggleLicense = (toggleValue: string) => {
    dispatch(IdActions.setLicense({ idType: mode, license: toggleValue }));
  };
  return (
    <FilterItem>
      <div className="item-label">
        <span>라이센스</span>
      </div>
      <div className="item-option-wrapper">
        <div className="item-option">
          <OptionButton isActive={licenses.has("PD")} onClick={() => onToggleLicense("PD")}>
            {"PD"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={licenses.has("CCL")} onClick={() => onToggleLicense("CCL")}>
            {"CCL"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={licenses.has("NO COPYRIGHT")} onClick={() => onToggleLicense("NO COPYRIGHT")}>
            {"NO COPYRIGHT"}
          </OptionButton>
        </div>
        <div className="item-option">
          <OptionButton isActive={licenses.has("VL")} onClick={() => onToggleLicense("VL")}>
            {"VL"}
          </OptionButton>
        </div>
      </div>
    </FilterItem>
  );
};
