import { Reducer } from "react";
import immer from "immer";

import { Handlers } from "./createHandler";
import { identity } from "utils/identity";

type Actions = {
  readonly [key: string]: (
    ...args: any[]
  ) => {
    readonly type: string;
  };
};

type createReducer = <S, A extends Actions>(
  handler: Handlers<S>,
  createInitialState: () => S,
  afterChange?: (state: S) => void
) => Reducer<S, ReturnType<A[keyof A]>>;

/**
 * reducer를 생성하는 함수
 *
 * * before
 *
 * const createInitialState = (): State => { ... }
 *
 * const reducer = (state: State = createInitialState(), action: Action) => {
 *   switch (action.type) {
 *     case 'User::Name::Update': {
 *       return { ...state, name: action.name }
 *     }
 *   }
 * }
 *
 * * after
 *
 * const handler = createHandler({
 *   'User::Name::Update' (state, payload) {
 *     state.name = payload.name
 *   }
 * })
 *
 * const createInitialState = (): State => { ... }
 *
 * const reducer = createReducer(handler, createInitialState)
 */
export const createReducer: createReducer = (handler, createInitialState, afterChange = identity) => (
  state = createInitialState(),
  { type, ...payload }
) => {
  if (!handler.hasOwnProperty(type)) {
    return state;
  }
  const nextState = immer(state, draft => void handler[type](draft, payload));
  if (nextState !== state) {
    afterChange(nextState);
  }
  return nextState;
};
