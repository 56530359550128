import React, { useState } from "react";
import { Input } from "App/Atomics/Input";
import { TitleTypeOptionInfo } from "constants/TypeOptionInfo";
import { Toast } from "lib/toast";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { LocaleModalStyle } from "../style";
import { UpdateLanguageLocale } from "GraphQL/Queries/Language";
import { LanguageCodeInfo } from "constants/LanguageCodeInfo";
import { useLanguageDispatch } from "App/Routes/LanguageManage/Store";
import { LanguageActions } from "App/Routes/LanguageManage/Store/Language";

type Props = {
  index: number;
  uuid: string;
  languageCode: string;
  typeTitle: string;
  displayCode: string;
  value: string;
  onClose: () => void;
};

export const UpdateLocaleModal = ({
  index,
  uuid,
  languageCode,
  typeTitle: initialTypeTitle,
  displayCode: initialDisplayCode,
  value: initialValue,
  onClose
}: Props) => {
  const dispatch = useLanguageDispatch();
  const [typeTitle, setTypeTitle] = useState<string>(initialTypeTitle);
  const [displayCode, setDisplayCode] = useState<string>(initialDisplayCode);
  const [value, setValue] = useState<string>(initialValue);

  const onSave = async (uuid: string, typeTitle: string, displayCode: string, value: string) => {
    const accessId = await requestAccessRecord({ targetId: languageCode, targetTable: TargetTableInput.Language });
    if (accessId) {
      try {
        await UpdateLanguageLocale({ uuid, typeTitle, displayCode, value });
        await DeleteAccessRecord({ id: accessId });
        dispatch(LanguageActions.updateLanguageLocale({ index, typeTitle, displayCode, value }));
        Toast.success("수정되었습니다.");

        onClose();
      } catch (err) {
        console.log(err);
        await DeleteAccessRecord({ id: accessId });
        Toast.error("수정에 실패하였습니다.");
        return;
      }
    }
  };

  return (
    <LocaleModalStyle.Layout>
      <LocaleModalStyle.RowGroup>
        <h2>언어팩 수정</h2>
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.RowGroup>
        <h4>타입 선택 (Type Title)</h4>
        <Input.TextSelect
          placeholder="타입을 선택해주세요"
          className="typeSelect"
          optionList={TitleTypeOptionInfo}
          defaultValue={{ id: typeTitle, name: typeTitle }}
          onChange={info => {
            if (info) {
              setTypeTitle(info.id);
            }
          }}
        />
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.RowGroup>
        <h4>표기 언어 선택 (Display Code)</h4>
        <LocaleModalStyle.LanguageSelect
          defaultValue={LanguageCodeInfo.find(({ id }) => languageCode === id)}
          onChange={code => {
            if (code) {
              setDisplayCode(code.id);
            }
          }}
        />
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.RowGroup>
        <h4>내용</h4>
        <LocaleModalStyle.InputTextArea placeholder="내용을 입력하세요" defaultValue={value} onChange={e => setValue(e.target.value)} />
      </LocaleModalStyle.RowGroup>
      <LocaleModalStyle.ButtonGroup>
        <Input.Button className="cancelButton" onClick={onClose}>
          취소
        </Input.Button>
        <Input.Button
          color="primary"
          disabled={!typeTitle.length || !displayCode.length || !value.length}
          onClick={() => onSave(uuid, typeTitle, displayCode, value)}
        >
          수정
        </Input.Button>
      </LocaleModalStyle.ButtonGroup>
    </LocaleModalStyle.Layout>
  );
};
