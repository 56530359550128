/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FormEvent, useCallback } from "react";
import styled from "styled-components";
import { TopbarTemplate } from "App/Templates/TopbarTemplate";
import { GOODS } from "constants/route";
import { useHistory } from "react-router";
import { useQueryParams } from "lib/use-query-params";
import { SearchForm } from "App/Organisms/SearchForm";
import { Toast } from "lib/toast";
import { AppStore } from "App/Store-v3";
import { SidebarActions } from "App/Store-v3/Sidebar";
import { CardList } from "./CardList";
import { Pagination } from "App/Molecules/Pagination";
import { useGoodsSelector } from "../Store";
import { HEADER_HEIGHT_PX } from "constants/size";
import { mediaQuery } from "constants/media";
import ContentLoader from "react-content-loader";
import { usePlaceholder } from "lib/use-place-holder";
import { Filter } from "./Filter";

type Props = {
  readonly loading: boolean;
};

const SearchColumnList = [
  { id: "id", name: "아이디" },
  { id: "title", name: "이름" }
];

export const GoodsTable = ({ loading }: Props) => {
  const { edge, filter } = useGoodsSelector(store => store.Goods);
  const [searchType, setSearchType] = useState<string>(SearchColumnList[1].id);
  const { placeholderWidth } = usePlaceholder();
  const router = useHistory();
  const queryParams = useQueryParams();
  const id = queryParams.get("id", { default: undefined });
  const title = queryParams.get("title", { default: undefined });
  const parentId = queryParams.get("parentId", { default: undefined });
  const openSidebar = () => AppStore.dispatch(SidebarActions.open());
  const getSearchString = useCallback((type: string) => queryParams.get(type, { default: undefined }), [queryParams]);
  const onSearch = useCallback(
    (e: FormEvent<HTMLFormElement>, searchStr: string) => {
      e.preventDefault();
      if (searchStr === getSearchString(searchType)) {
        return;
      }
      const url = new window.URLSearchParams();
      Object.entries(filter).forEach(([key, value]) => {
        const params = [...value].join(" ");
        !value.size ? url.delete(key) : url.append(key, params);
      });
      if (!searchStr) {
        router.push(`${GOODS}?page=1${!url.toString().length ? "" : `&${url}`}`);
        return;
      }
      if (searchType === SearchColumnList[0].id && !searchStr.match(/^[0-9]+$/)) {
        Toast.error("아이디는 숫자만 입력가능합니다.", undefined, "top-center");
        return;
      }
      router.push(`${GOODS}?page=1&${searchType}=${searchStr}${!url.toString().length ? "" : `&${url}`}`);
    },
    [getSearchString, router, searchType]
  );
  const goTo = (index: number) => {
    const url = new window.URLSearchParams();
    Object.entries(filter).forEach(([key, value]) => {
      const params = [...value].join(" ");
      !value.size ? url.delete(key) : url.append(key, params);
    });
    return `${GOODS}?page=${index}${!url.toString().length ? "" : `&${url}`}${!id ? "" : `&id=${id}`}${!title ? "" : `&title=${title}`}${
      !parentId ? "" : `&parentId=${parentId}`
    }`;
  };
  return (
    <Layout>
      <TopbarTemplate openSidebar={openSidebar}>
        <SearchForm route={GOODS} searchType={searchType} onChangeSearchTypeInfo={info => setSearchType(info!.id)} onSearch={onSearch} />
      </TopbarTemplate>
      <Filter />
      <Container>
        {loading && (
          <LoadingPlaceholder>
            {[...Array(20)].map((_, key) => (
              <Placeholder key={key} width={placeholderWidth} />
            ))}
          </LoadingPlaceholder>
        )}
        {!loading && <CardList />}
        <Pagination edge={edge} goTo={goTo} />
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  display: inherit;
  width: 100%;
  overflow: auto;
`;

const Container = styled.div`
  margin-top: ${HEADER_HEIGHT_PX};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LoadingPlaceholder = styled.div`
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  padding: 2em 0;
  svg {
    margin: 0 0.6em;
  }
  ${mediaQuery(768)} {
    svg {
      margin: 0 0.3em;
    }
  }
`;

const Placeholder = ({ width }: { width: number }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={width * 1.3}
    viewBox={`0 0 ${width} ${width * 1.3}`}
    backgroundColor="#f2f2f2"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="2" ry="2" width={width} height={width} />
    <rect x="0" y={width * 1.03} rx="2" ry="2" width={width * 0.2} height={width * 0.03} />
    <rect x="0" y={width * 1.07} rx="2" ry="2" width={width * 0.9} height={width * 0.03} />
    <rect x="0" y={width * 1.11} rx="2" ry="2" width={width * 0.3} height={width * 0.03} />
  </ContentLoader>
);
