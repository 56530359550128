/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, render, Variable, Query, Field, SelectionSet, IdScalar } from "graphql-jsx";

type Option = Readonly<{
  id: string;
}>;

export const CheckMetadataCompanyExist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="CHECK_METADATA_COMPANY_EXIST">
      <SelectionSet
        name="company"
        argument={{
          where: {
            company_id: $id,
            OR: [
              { metadata_company_relation__some: { id__exist: true } },
              {
                company_users_relation__some: {
                  id__exist: true
                }
              }
            ]
          }
        }}
      >
        <Field name="company_id" alias="id" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    company: ReadonlyArray<{
      id: string;
    }>;
  }>;
  return await clients.company.request<Data>(query, variables);
};
