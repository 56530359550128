import { requestAccessRecord } from "lib/requestAccessRecord";
import { useProductDetailSelector } from "../Store";
import { isEqual } from "lodash";
import { Toast } from "lib/toast";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord, DeleteCommodityUrl, DeleteFile, LoadGoodsCompanyId, UpdateCommodity } from "GraphQL/Queries";
import { useCreateImage } from "./useCreateImage";
import { FileType } from "GraphQL/Scalars/FileType";
import { BookType } from "GraphQL/Scalars/BookType";
import { ProductDetail as TypeProductDetail, ProductGoodsSubdataUniqueRelation, ProductUrl } from "../Store/ProductDetail";

type Keys = keyof TypeProductDetail;
type Values = TypeProductDetail[Keys];

export const useUpdateProduct = () => {
  const { product, origin } = useProductDetailSelector(store => store.ProductDetail);
  const { createImage } = useCreateImage();
  const updateProduct = async () => {
    if (isEqual(product, origin)) {
      Toast.warning("변경사항이 없습니다.");
      return;
    }
    if (!window.confirm("저장하시겠습니까?")) return;
    const changeMap = formatDataToChangeMap(product, origin);
    const targetId = product.id;

    const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Goods });
    try {
      const barcode = changeMap.get("barcode")?.to as string;
      const quality = changeMap.get("quality")?.to as string;
      const price = changeMap.get("price")?.to as number;
      const createdAt = changeMap.get("createdAt")?.to as string;
      if (barcode || quality || price) {
        await UpdateCommodity({ id: targetId, barcode, quality, price, createdAt });
      }
      const companyId = await LoadGoodsCompanyId({ id: product.goods.id });
      for (const [key, { from, to }] of changeMap) {
        switch (key) {
          case "urls":
            const deleteUrlList = (from as ProductUrl[])
              .filter(({ id }) => !(to as ProductUrl[]).map(({ id }) => id).includes(id))
              .map(({ id, url }) => ({ id: id as string, filename: url }));

            const createUrlList = (to as ProductUrl[]).filter(
              ({ id, typeUrl, url }) => !(from as ProductUrl[]).map(({ id }) => id).includes(id) && !!typeUrl && !!url
            );
            if (deleteUrlList.length) {
              const uuidIn = deleteUrlList.map(({ id }) => id);
              for (const { filename } of deleteUrlList) {
                await DeleteFile({
                  filename,
                  companyId,
                  fileType: FileType.FILE,
                  book: BookType.immediate
                });
              }
              await DeleteCommodityUrl({ uuidIn });
            }
            if (createUrlList.length) {
              await createImage({ id: targetId, companyId, urls: createUrlList });
            }
            break;
          case "optionList":
            const deleteOptionList = (from as ProductGoodsSubdataUniqueRelation[])
              .filter(({ id }) => !(to as ProductGoodsSubdataUniqueRelation[]).map(({ id }) => id).includes(id))
              .map(({ id }) => ({ id: id as string }));

            const createOptionList = (to as ProductGoodsSubdataUniqueRelation[]).filter(
              ({ id }) => !(from as ProductGoodsSubdataUniqueRelation[]).map(({ id }) => id).includes(id)
            );
            if (deleteOptionList.length) {
              const uuidIn = deleteOptionList.map(({ id }) => id);
              console.log({ deleteOptionList, uuidIn });
              // await DeleteOptionList({ uuidIn });
            }
            if (createOptionList.length) {
              console.log({ createOptionList });
              // await createOptionList({ id: targetId, companyId, urls: createUrlList });
            }
            break;
        }
      }
      await DeleteAccessRecord({ id: accessId! });
      Toast.primary("저장되었습니다.");
    } catch (err) {
      console.log(err);
      return;
    }
  };
  return { updateProduct: updateProduct };
};

const formatDataToChangeMap = (product: TypeProductDetail, origin: TypeProductDetail) => {
  const changeMap = new Map<Keys, { from: Values; to: Values }>();
  const ProductEntries = Object.entries(product);
  const OriginValue = Object.values<Values>(origin);
  ProductEntries.forEach(([key, value], index) => {
    switch (key) {
      case "barcode":
      case "quality":
      case "price":
      case "optionList":
      case "createdAt":
        if (OriginValue[index] !== value) {
          changeMap.set(key, { from: OriginValue[index], to: value });
        }
        break;
      case "urls":
        if (!isEqual(OriginValue[index], value)) {
          changeMap.set(key, { from: OriginValue[index], to: value });
        }
        break;
      default:
        break;
    }
  });
  return changeMap;
};
