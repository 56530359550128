import { Edge } from "models/Edge";
import { createDuck } from "lib/store/v2";

type State = ReturnType<typeof createInitialState>;

export type License = {
  id: string;
  notice: string;
  copyright?: boolean;
  neighboring?: boolean;
  country_code: string;
  license_date: string | null;
  publish_date: string | null;
  type_track: string;
  is_service?: number;
};

export type Playlist = {
  id: string;
  name: string;
  kind: string;
  metadataPlaylistRelation: {
    metadata: {
      metadataId: string;
    }[];
    order?: number;
  }[];
};

const createInitialState = () => ({
  edge: null as null | Edge,
  tracks: [] as any[],
  license: [] as License[],
  modalId: "" as string,
  checkList: new Map<string, boolean>(),
  playlist: [] as Playlist[]
});

export const WorkTrackActions = createDuck({
  namespace: "WorkTrack",
  createInitialState,
  reducers: {
    setEdge(state, edge: Edge) {
      state.edge = edge;
    },
    setTrack(state, tracks: any[]) {
      state.tracks = tracks;
    },
    setLicense(state, license: License[]) {
      state.license = license;
    },
    createLicense(state, license: License) {
      state.license.unshift(license);
    },
    updateLicense(
      state,
      {
        id,
        typeTrack,
        countryCode,
        notice,
        copyright,
        neighboring,
        publishDate,
        isService
      }: {
        id: string;
        typeTrack: string;
        countryCode: string;
        notice: string;
        copyright: boolean | undefined;
        neighboring: boolean | undefined;
        publishDate: string | null;
        isService: number | undefined;
      }
    ) {
      const idx = state.license.findIndex(item => item.id === id);
      state.license[idx].type_track = typeTrack;
      state.license[idx].country_code = countryCode;
      state.license[idx].notice = notice;
      state.license[idx].copyright = copyright;
      state.license[idx].neighboring = neighboring;
      state.license[idx].publish_date = publishDate;
      state.license[idx].is_service = isService;
    },
    deleteLicense(state, id: string) {
      state.license = state.license.filter(item => item.id !== id);
    },
    setTrackCheckListInit(state, ids: string[]) {
      ids.forEach(id => {
        state.checkList.get(id) === undefined && state.checkList.set(id, false);
      });
    },
    setTrackCheckList(state, { id, checked }: { id: string; checked: boolean }) {
      state.checkList.set(id, checked);
    },
    clearTrackCheckList(state) {
      state.checkList.clear();
    },
    setLicenseModalId(state, id: string) {
      state.modalId = id;
    },
    setTrackPlaylist(state, playlists: Playlist[]) {
      state.playlist = playlists;
    },
    createTrackPlaylist(state, playlist: Playlist) {
      state.playlist.unshift(playlist);
    },
    addTrackToPlaylist(state, { id, trackInfo }: { id: string; trackInfo: string[] }) {
      const index = state.playlist.findIndex(item => item.id === id);
      const newMetadataRelation = trackInfo.map(trackId => ({
        metadata: [
          {
            metadataId: trackId
          }
        ]
      }));
      state.playlist[index].metadataPlaylistRelation.push(...newMetadataRelation);
    }
  }
});
