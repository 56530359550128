import { GetTracksInAlbumById } from "GraphQL/Queries/Metadata";
export const useLoadTracksInAlbum = () => {
  const loadTracks = async (id: string) => {
    const { data, errors } = await GetTracksInAlbumById({
      first: 99,
      metadataId: id,
      typeClass: "record",
      typeSubClassIn: ["text", "track"]
    });
    if (errors) {
      throw new Error(errors[0].message);
    }
    const trackList = data!.metadata.map(data => data.structures[0].structureId);
    return trackList;
  };
  return { loadTracks };
};
