import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  pixelize,
  UNIT,
  MARGING_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_X_LARGE,
  PADDING_X_LARGE_PX,
  MARGING_SMALL_PX
} from "constants/size";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as AddToPlaylistIcon } from "assets/icons/add-to-playlist.svg";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";
import { WHITE, GRAY_4, BLACK, BLUE_6, GRAY_2, GRAY_1, GRAY_6 } from "constants/baseColor";
import { useAsyncEffect } from "lib/use-async-effect";
import { useAppStore } from "App/Store";
import { GetPlaylistInfo, AddTracksToPlaylist } from "GraphQL/Queries/Playlist";
import { useTracksStore } from "../../../Store";
import { WorkTrackActions } from "../../../Store/Track";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { CreatePlaylistModal } from "../";
import { Input } from "App/Atomics/Input";
import { useHistory } from "react-router";
import { PLAYLIST_DETAIL } from "constants/route";

type Props = Readonly<{
  toClose: () => void;
}>;

const colorAnim = keyframes`
  0% {
    background-color: ${WHITE};
  };
  50% {
    background-color: ${GRAY_1};
  };
  100% {
    background-color: ${GRAY_2};
  };
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 42)};
  height: ${pixelize(UNIT * 36)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header = styled.div`
  position: -webkit-sticky;
  position: sticky;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  top: 0;
  min-height: ${pixelize(UNIT * 4.5)};
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGING_LARGE_PX};

  h3 {
    font-size: 1.25rem;
    svg {
      width: ${pixelize(UNIT * 1.25)};
      height: ${pixelize(UNIT * 1.25)};
      margin-right: ${MARGING_LARGE_PX};
    }
  }
  .title {
    padding: ${pixelize(PADDING_X_LARGE * 1.4)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cancelIcon {
      fill: ${BLACK};
      width: ${pixelize(UNIT)};
      height: ${pixelize(UNIT)};
      margin-right: ${MARGING_LARGE_PX};
      transition: all 0.1s;
      cursor: pointer;
      &:hover {
        fill: ${GRAY_4};
      }
    }
  }
`;

const MarginDiv = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 2)};
  color: transparent;
`;

const ListBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
  grid-gap: ${MARGING_SMALL_PX};
  margin: ${MARGING_LARGE_PX} ${pixelize(MARGING_X_LARGE * 1.5)};
  padding: ${PADDING_X_LARGE_PX};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};
  animation: all 0.2s;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${MARGING_LARGE_PX};
  }
  .right {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .right-top {
      .name {
        margin-left: ${MARGING_SMALL_PX};
      }
      .kind {
        color: ${BLUE_6};
      }
    }
    .right-bottom {
      color: ${GRAY_6};
    }
  }

  &:hover {
    animation: ${colorAnim} 0.1s ease-in-out;
    background-color: ${GRAY_2};
  }
`;

const NewPlaylistBox = styled.div`
  height: ${pixelize(UNIT * 3.5)};
  margin: ${MARGING_LARGE_PX} ${pixelize(MARGING_X_LARGE * 1.5)};
  padding: ${PADDING_X_LARGE_PX};
  border-radius: 4px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.25);
  background-color: ${WHITE};
  cursor: pointer;
  &:hover {
    animation: ${colorAnim} 0.2s ease-in-out;
    background-color: ${GRAY_2};
  }
`;

const spin = keyframes`
  0%  {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg {
    animation: ${spin} 1.5s linear infinite;
    position: relative;
    width: ${pixelize(UNIT * 1.5)};
    height: ${pixelize(UNIT * 1.5)};
    top: 50%;
    left: 50%;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  margin-left: ${MARGING_SMALL_PX};
`;

export const PlaylistModal = ({ toClose }: Props) => {
  const [{ email }] = useAppStore(store => ({
    email: store.UserToken.email
  }));
  const [{ playlist, checkList }, dispatch] = useTracksStore(store => ({
    playlist: store.WorkTrack.playlist,
    checkList: Array.from(store.WorkTrack.checkList)
      .filter(list => list[1])
      .map(list => list[0])
  }));
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const router = useHistory();
  const confirmModal = useToggle();
  const createPlaylistModal = useToggle();

  const moveToPlaylist = (id: string) => {
    router.push(`${PLAYLIST_DETAIL}?id=${id}`);
  };

  useAsyncEffect(async isMounted => {
    if (isMounted()) {
      try {
        const playlistData = await GetPlaylistInfo({ email });
        dispatch(WorkTrackActions.setTrackPlaylist(playlistData));
        setLoading(false);
      } catch (err) {
        console.log(err);
        return;
      }
    }
  }, []);

  const handleClick = async (id: string, trackInfo: string[]) => {
    try {
      setSaveLoading(true);
      const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
      if (accessData) {
        const playlistInfo = await GetPlaylistInfo({ id });
        const ids = playlistInfo[0].metadataPlaylistRelation.map(({ metadata }) => metadata[0].metadataId);
        const filteredTrackInfo = trackInfo.filter(trackId => !ids.includes(trackId)); // 플레이리스트의 트랙과 체크한 트랙의 id를 비교
        if (trackInfo.length === filteredTrackInfo.length) {
          setAlertMsg(`${filteredTrackInfo.length}개의 음원이 추가되었습니다.`);
          await AddTracksToPlaylist({ id, trackInfo: filteredTrackInfo });
          dispatch(WorkTrackActions.addTrackToPlaylist({ id, trackInfo: filteredTrackInfo }));
        } else if (filteredTrackInfo.length === 0) {
          setAlertMsg(`모든 음원이 중복됩니다.`);
        } else {
          setAlertMsg(`중복을 제외한 ${filteredTrackInfo.length}개의 음원이 추가되었습니다.`);
          await AddTracksToPlaylist({ id, trackInfo: filteredTrackInfo });
          dispatch(WorkTrackActions.addTrackToPlaylist({ id, trackInfo: filteredTrackInfo }));
        }
        await DeleteAccessRecord({ id: accessData.createAccess.id });
        setSaveLoading(false);
        confirmModal.on();
      }
    } catch (err) {
      setSaveLoading(false);
      setAlertMsg("음원 추가에 실패하였습니다.");
      console.log(err);
      return;
    }
  };

  return loading ? null : (
    <Layout>
      <Header>
        <div className="title">
          <h3>
            <AddToPlaylistIcon />내 플레이리스트 추가
          </h3>
          <CancelIcon className="cancelIcon" onClick={toClose} />
        </div>
      </Header>
      <NewPlaylistBox onClick={createPlaylistModal.on}>+ 새 플레이리스트 추가</NewPlaylistBox>
      {!playlist.length
        ? null
        : playlist.map(({ id, name, kind, metadataPlaylistRelation }) => {
            const trackInfo = checkList.map(id => id);
            return (
              <ListBox key={id}>
                <div className="left">{id}</div>
                <div className="right">
                  <div className="right-top">
                    <span className="kind">[{kind}]</span>
                    <span className="name">{name}</span>
                  </div>
                  <span className="right-bottom">{metadataPlaylistRelation.length}곡</span>
                </div>
                <ButtonGroup>
                  <Input.Button color="primary" onClick={() => handleClick(id, trackInfo)}>
                    담기
                  </Input.Button>
                  <Input.Button color="success" onClick={() => moveToPlaylist(id)}>
                    이동
                  </Input.Button>
                </ButtonGroup>
              </ListBox>
            );
          })}

      <MarginDiv>{"0"}</MarginDiv>
      {!saveLoading ? null : (
        <Loading>
          <LoadingIcon />
        </Loading>
      )}
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm title="알림" context={alertMsg} toClose={confirmModal.off} />
      </Modal>
      <Modal isOpen={createPlaylistModal.isToggled}>
        <CreatePlaylistModal toClose={createPlaylistModal.off} />
      </Modal>
    </Layout>
  );
};
