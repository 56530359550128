/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, EnumScalar, IdScalar } from "graphql-jsx";

import { clients } from "utils/clients";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";

export const findArtistListExceptNaxos = async (keyword: string, searchType?: string) => {
  let $keyword = (
    <Variable name="keyword" scalar={searchType === "name" ? <StringScalar isNullable={false} /> : <IdScalar />} value={keyword} />
  );
  let $id = undefined;
  const $v1 = <ValidTypeScalar value={ValidType.V1} />;
  const $remove = <ValidTypeScalar value={ValidType.REMOVE} />;
  if (keyword.includes("$")) {
    const searchArr = keyword.split("$");
    $keyword = <Variable name="keyword" scalar={<StringScalar isNullable />} value={!searchArr[0].length ? undefined : searchArr[0]} />;
    $id = <Variable name="id" scalar={<IdScalar />} value={searchArr[1]} />;
  }
  const { query: queryA, variables: variablesA } =
    searchType === "name"
      ? render(
          <Query operationName="FIND_ARTIST_LIST_EXCEPT_NAXOS">
            <SelectionSet
              name="artist_title"
              alias="list"
              argument={{
                group: true,
                first: 50,
                orderBy: <EnumScalar type="Artist_titleOrderByInput" value="value__SIMILARITY" items={["value__SIMILARITY"]} />,
                where: {
                  tsvec_words__search: $keyword,
                  type_artist_title__starts_with: "name",
                  artist__some: {
                    artist_id: $id,
                    weight: 0,
                    AND: [{ valid_check__not: $remove }, { valid_check__not: $v1 }]
                  }
                }
              }}
            >
              <Field name="artist_id" />
              <SelectionSet name="artist">
                <Field name="artist_id" alias="id" />
                <Field name="name" />
                <Field name="type_artist_class" alias="type" />
              </SelectionSet>
            </SelectionSet>
          </Query>
        )
      : render(
          <Query operationName="FIND_ARTIST_LIST_EXCEPT_NAXOS">
            <SelectionSet
              name="artist_title"
              alias="list"
              argument={{
                group: true,
                first: 40,
                orderBy: <EnumScalar type="Artist_titleOrderByInput" value="value__SIMILARITY" items={["value__SIMILARITY"]} />,
                where: {
                  artist__some: {
                    artist_id: $keyword,
                    weight: 0
                  },
                  AND: [{ valid_check__not: $remove }, { valid_check__not: $v1 }]
                }
              }}
            >
              <Field name="artist_id" />
              <SelectionSet name="artist">
                <Field name="artist_id" alias="id" />
                <Field name="name" />
                <Field name="type_artist_class" alias="type" />
              </SelectionSet>
            </SelectionSet>
          </Query>
        );

  type Data = Readonly<{
    list: ReadonlyArray<{
      artist_id: string;
      artist: {
        id: string;
        name: string;
        type: string;
      }[];
    }>;
  }>;
  const responseA = await clients.artist.request<Data>(queryA, variablesA);

  if (responseA.errors) {
    return [];
  }
  return responseA.data!.list.map(({ artist }) => ({
    id: artist[0].id,
    name: `${artist[0].name}`,
    type: artist[0].type
  }));
};
