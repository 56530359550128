/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";

type Option = Readonly<{
  no: string;
  title: string;
  class: string;
  subClass: string;
  artistId: string;
  companyId: string | null;
}>;

export const CreateEmptyAlbum = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $title_no_space =
    option.title === option.title.replace(/\s/gi, "") ? (
      undefined
    ) : (
      <Variable name="title_no_space" scalar={<StringScalar isNullable={false} />} value={option.title.replace(/\s/gi, "")} />
    );
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.companyId} />;
  const $artistId = <Variable name="artistId" scalar={<IdScalar />} value={option.artistId} />;
  const $rightCompany = <MetadataRightScalar isNullable={true} value={MetadataRightType.rightCompany} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_EMPTY_ALUBM">
      <SelectionSet
        name="createMetadata"
        alias="createAlbum"
        argument={{
          data: {
            no: $no,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                !$title_no_space
                  ? {}
                  : {
                      language: {
                        connect: {
                          language_code: "OO"
                        }
                      },
                      type_metadata_title: "name",
                      value: $title_no_space,
                      exposure_order: 1
                    }
              ]
            },
            metadata_artist_relation: {
              create: {
                artist: {
                  connect: {
                    artist_id: $artistId
                  }
                },
                role: {
                  connect: {
                    role_id: "343"
                  }
                },
                exposure_order: 0
              }
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $rightCompany,
                  exposure_order: 0
                }
              ]
            },
            metadata_structure: {
              create: [{ parent_id: 0 }]
            }
          }
        }}
      >
        <Field name="metadata_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createAlbum: Readonly<{
      metadata_id: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
