/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const GetMetadataUrl = async (option: Option) => {
  const $id = <Variable name="id" scalar={<IdScalar isNullable={false} />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_METADATA_IMAGE">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 99,
          where: {
            metadata_id: $id,
            type_metadata_class: "record",
            type_metadata_subclass: "album"
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <SelectionSet name="metadata_url" alias="metadataUrl">
          <Field name="id" />
          <Field name="url" />
        </SelectionSet>
        <SelectionSet name="metadata_company_relation" alias="companyRelation">
          <Field name="company_id" alias="companyId" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    metadata: {
      id: string;
      metadataUrl: {
        id: string;
        url: string;
      }[];
      companyRelation: {
        companyId: string;
      }[];
    }[];
  }>;

  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  const { id, metadataUrl, companyRelation } = data.metadata[0];
  return { id, urls: metadataUrl, companyId: companyRelation[0].companyId };
};
