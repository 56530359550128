/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  name: string;
  type: string;
}>;

export const CheckDuplicatedArtist = async (option: Option) => {
  const $name = <Variable name="name" scalar={<StringScalar />} value={option.name} />;
  const $type = <Variable name="type_artist_class" scalar={<StringScalar />} value={option.type} />;
  const { query, variables } = render(
    <Query operationName="CHECK_DUPLICATED_ARTIST">
      <SelectionSet
        name="artist"
        argument={{
          where: {
            name: $name,
            type_artist_class: $type
          }
        }}
      >
        <Field name="name" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    artist: Readonly<{
      name: string;
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
