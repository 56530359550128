import { Info, TagSelect } from "App/Atomics/Input/Select/TagSelect";
import { ValidType } from "constants/ValidType";
import { createDuck } from "lib/store/v2";
import { GrantKey } from "../Grant";

type Id = string;
type Genre = Info;
type Mood = Info;
type Company = Info;
type CountryCode = Info;
type OptionList = Parameters<typeof TagSelect>[0]["optionList"];

export type IdType = { id: Id };

const createInitialState = () => ({
  mode: GrantKey.TRACK as GrantKey,
  idList: {
    Goods: [] as readonly IdType[],
    Track: [] as readonly IdType[],
    Artist: [] as readonly IdType[],
    MetadataArtistRelation: [] as readonly IdType[],
    Role: [] as readonly IdType[],
    Genre: [] as readonly IdType[],
    Playlist: [] as readonly IdType[],
    Company: [] as readonly IdType[],
    Language: [] as readonly IdType[]
  },
  searchList: {
    Track: {
      count: 300 as number,
      skip: 0 as number,
      idLte: undefined as number | undefined,
      idGte: undefined as number | undefined,
      idIncludeList: new Set<string>(),
      idExcludeList: new Set<string>(),
      includeGenreList: [] as Genre[],
      excludeGenreList: [] as Genre[],
      includeMoodList: [] as Mood[],
      excludeMoodList: [] as Mood[],
      validTypeList: [] as ValidType[],
      licenses: new Set<string>(),
      includeCompanyList: [] as Company[]
    },
    Artist: {
      count: 300 as number,
      skip: 0 as number,
      idLte: undefined as number | undefined,
      idGte: undefined as number | undefined,
      idIncludeList: new Set<string>(),
      idExcludeList: new Set<string>(),
      validTypeList: [] as ValidType[]
    },
    MetadataArtistRelation: {
      count: 300 as number,
      skip: 0 as number,
      idLte: undefined as number | undefined,
      idGte: undefined as number | undefined,
      idIncludeList: new Set<string>(),
      idExcludeList: new Set<string>(),
      validTypeList: [] as ValidType[]
    },
    Genre: {
      count: 300 as number,
      skip: 0 as number,
      idLte: undefined as number | undefined,
      idGte: undefined as number | undefined,
      idIncludeList: new Set<string>(),
      idExcludeList: new Set<string>(),
      validTypeList: [] as ValidType[]
    },
    Playlist: {
      count: 300 as number,
      skip: 0 as number,
      idLte: undefined as number | undefined,
      idGte: undefined as number | undefined,
      idIncludeList: new Set<string>(),
      idExcludeList: new Set<string>(),
      validTypeList: [] as ValidType[]
    },
    Company: {
      count: 300 as number,
      skip: 0 as number,
      idLte: undefined as number | undefined,
      idGte: undefined as number | undefined,
      idIncludeList: new Set<string>(),
      idExcludeList: new Set<string>(),
      validTypeList: [] as ValidType[]
    }
  },
  selectedMap: {
    selectedTrackMap: new Map<Id, IdType>(),
    selectedArtistMap: new Map<Id, IdType>(),
    selectedMetadataArtistRelationMap: new Map<Id, IdType>(),
    selectedRoleMap: new Map<Id, IdType>(),
    selectedGenreMap: new Map<Id, IdType>(),
    selectedPlaylistMap: new Map<Id, IdType>(),
    selectedCompanyMap: new Map<Id, IdType>(),
    selectedLangaugeMap: new Map<Id, IdType>()
  }
});

export const IdActions = createDuck({
  namespace: "IdList",
  createInitialState,
  reducers: {
    set(state, { idType, idList }: { idType: string; idList: IdType[] }) {
      switch (idType) {
        case GrantKey.GOODS:
          state.idList.Goods = idList;
          break;
        case GrantKey.TRACK:
          state.idList.Track = idList;
          break;
        case GrantKey.ARTIST:
          state.idList.Artist = idList;
          state.idList.MetadataArtistRelation = idList;
          break;
        case GrantKey.ROLE:
          state.idList.Role = idList;
          break;
        case GrantKey.GENRE:
          state.idList.Genre = idList;
          break;
        case GrantKey.PLAYLIST:
          state.idList.Playlist = idList;
          break;
        case GrantKey.COMPANY:
          state.idList.Company = idList;
          break;
        case GrantKey.LANGUAGE:
          state.idList.Language = idList;
          break;
        default:
          break;
      }
    },
    clear(state) {
      Object.values(state.selectedMap).forEach(value => value.clear());
    },
    setMode(state, mode: GrantKey) {
      state.mode = mode;
    },
    select(state, { idType, idList }: { idType: string; idList: IdType[] }) {
      switch (idType) {
        case GrantKey.GOODS:
          idList.forEach(track => {
            if (!state.selectedMap.selectedTrackMap.has(track.id)) {
              state.selectedMap.selectedTrackMap.set(track.id, track);
            }
          });
          break;
        case GrantKey.TRACK:
          idList.forEach(track => {
            if (!state.selectedMap.selectedTrackMap.has(track.id)) {
              state.selectedMap.selectedTrackMap.set(track.id, track);
            }
          });
          break;
        case GrantKey.ARTIST:
          idList.forEach(artist => {
            if (!state.selectedMap.selectedArtistMap.has(artist.id)) {
              state.selectedMap.selectedArtistMap.set(artist.id, artist);
            }
          });
          break;
        case GrantKey.ROLE:
          idList.forEach(role => {
            if (!state.selectedMap.selectedRoleMap.has(role.id)) {
              state.selectedMap.selectedRoleMap.set(role.id, role);
            }
          });
          break;
        case GrantKey.GENRE:
          idList.forEach(genre => {
            if (!state.selectedMap.selectedGenreMap.has(genre.id)) {
              state.selectedMap.selectedGenreMap.set(genre.id, genre);
            }
          });
          break;
        case GrantKey.PLAYLIST:
          idList.forEach(playlist => {
            if (!state.selectedMap.selectedPlaylistMap.has(playlist.id)) {
              state.selectedMap.selectedPlaylistMap.set(playlist.id, playlist);
            }
          });
          break;
        case GrantKey.COMPANY:
          idList.forEach(company => {
            if (!state.selectedMap.selectedCompanyMap.has(company.id)) {
              state.selectedMap.selectedCompanyMap.set(company.id, company);
            }
          });
          break;
        case GrantKey.LANGUAGE:
          idList.forEach(language => {
            if (!state.selectedMap.selectedLangaugeMap.has(language.id)) {
              state.selectedMap.selectedLangaugeMap.set(language.id, language);
            }
          });
          break;
        case GrantKey.METADATA_ARTIST_RELATION:
          idList.forEach(metadataArtist => {
            if (!state.selectedMap.selectedMetadataArtistRelationMap.has(metadataArtist.id)) {
              state.selectedMap.selectedMetadataArtistRelationMap.set(metadataArtist.id, metadataArtist);
            }
          });
          break;
        default:
          break;
      }
    },
    unselect(state, { idType, idList }: { idType: string; idList: IdType[] }) {
      switch (idType) {
        case GrantKey.GOODS:
          idList.forEach(track => {
            if (state.selectedMap.selectedTrackMap.has(track.id)) {
              state.selectedMap.selectedTrackMap.delete(track.id);
            }
          });
          break;
        case GrantKey.TRACK:
          idList.forEach(track => {
            if (state.selectedMap.selectedTrackMap.has(track.id)) {
              state.selectedMap.selectedTrackMap.delete(track.id);
            }
          });
          break;
        case GrantKey.ARTIST:
          idList.forEach(artist => {
            if (!state.selectedMap.selectedArtistMap.has(artist.id)) {
              state.selectedMap.selectedArtistMap.delete(artist.id);
            }
          });
          break;
        case GrantKey.ROLE:
          idList.forEach(role => {
            if (!state.selectedMap.selectedRoleMap.has(role.id)) {
              state.selectedMap.selectedRoleMap.delete(role.id);
            }
          });
          break;
        case GrantKey.GENRE:
          idList.forEach(genre => {
            if (!state.selectedMap.selectedGenreMap.has(genre.id)) {
              state.selectedMap.selectedGenreMap.delete(genre.id);
            }
          });
          break;
        case GrantKey.PLAYLIST:
          idList.forEach(playlist => {
            if (!state.selectedMap.selectedPlaylistMap.has(playlist.id)) {
              state.selectedMap.selectedPlaylistMap.delete(playlist.id);
            }
          });
          break;
        case GrantKey.COMPANY:
          idList.forEach(company => {
            if (!state.selectedMap.selectedCompanyMap.has(company.id)) {
              state.selectedMap.selectedCompanyMap.delete(company.id);
            }
          });
          break;
        case GrantKey.LANGUAGE:
          idList.forEach(language => {
            if (!state.selectedMap.selectedLangaugeMap.has(language.id)) {
              state.selectedMap.selectedLangaugeMap.delete(language.id);
            }
          });
          break;
        case GrantKey.METADATA_ARTIST_RELATION:
          idList.forEach(metadataArtist => {
            if (!state.selectedMap.selectedMetadataArtistRelationMap.has(metadataArtist.id)) {
              state.selectedMap.selectedMetadataArtistRelationMap.delete(metadataArtist.id);
            }
          });
          break;
        default:
          break;
      }
    },
    removeId(state, { idType, id }: { idType: string; id: string }) {
      switch (idType) {
        case GrantKey.GOODS:
          state.idList.Goods = state.idList.Goods.filter(item => item.id !== id);
          break;
        case GrantKey.TRACK:
          state.idList.Track = state.idList.Track.filter(item => item.id !== id);
          break;
        case GrantKey.ARTIST:
          state.idList.Artist = state.idList.Artist.filter(item => item.id !== id);
          break;
        case GrantKey.ROLE:
          state.idList.Role = state.idList.Role.filter(item => item.id !== id);
          break;
        case GrantKey.GENRE:
          state.idList.Genre = state.idList.Genre.filter(item => item.id !== id);
          break;
        case GrantKey.PLAYLIST:
          state.idList.Playlist = state.idList.Playlist.filter(item => item.id !== id);
          break;
        case GrantKey.COMPANY:
          state.idList.Company = state.idList.Company.filter(item => item.id !== id);
          break;
        case GrantKey.LANGUAGE:
          state.idList.Language = state.idList.Language.filter(item => item.id !== id);
          break;
        case GrantKey.METADATA_ARTIST_RELATION:
          state.idList.MetadataArtistRelation = state.idList.MetadataArtistRelation.filter(item => item.id !== id);
          break;
        default:
          break;
      }
    },
    // SEARCH_ACTION
    setCount(state, { idType, count }: { idType: GrantKey; count: number }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.count = count;
          break;
        case GrantKey.ARTIST:
          state.searchList.Artist.count = count;
          break;
        case GrantKey.GENRE:
          state.searchList.Genre.count = count;
          break;
        case GrantKey.PLAYLIST:
          state.searchList.Playlist.count = count;
          break;
        case GrantKey.COMPANY:
          state.searchList.Company.count = count;
          break;
        default:
          break;
      }
    },
    setSkip(state, { idType, skip }: { idType: GrantKey; skip: number }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.skip = skip;
          break;
        case GrantKey.ARTIST:
          state.searchList.Artist.skip = skip;
          break;
        case GrantKey.GENRE:
          state.searchList.Genre.skip = skip;
          break;
        case GrantKey.PLAYLIST:
          state.searchList.Playlist.skip = skip;
          break;
        case GrantKey.COMPANY:
          state.searchList.Company.skip = skip;
          break;
        default:
          break;
      }
    },
    setIdGte(state, { idType, gte }: { idType: GrantKey; gte?: number }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.idGte = gte;
          break;
        case GrantKey.ARTIST:
          state.searchList.Artist.idGte = gte;
          break;
        case GrantKey.GENRE:
          state.searchList.Genre.idGte = gte;
          break;
        case GrantKey.PLAYLIST:
          state.searchList.Playlist.idGte = gte;
          break;
        case GrantKey.COMPANY:
          state.searchList.Company.idGte = gte;
          break;
        default:
          break;
      }
    },
    setIdLte(state, { idType, lte }: { idType: GrantKey; lte?: number }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.idLte = lte;
          break;
        case GrantKey.ARTIST:
          state.searchList.Artist.idLte = lte;
          break;
        case GrantKey.GENRE:
          state.searchList.Genre.idLte = lte;
          break;
        case GrantKey.PLAYLIST:
          state.searchList.Playlist.idLte = lte;
          break;
        case GrantKey.COMPANY:
          state.searchList.Company.idLte = lte;
          break;
        default:
          break;
      }
    },
    setIdIncludeList(state, { idType, idList }: { idType: string; idList: Set<string> }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.idIncludeList = idList;
          break;
        case GrantKey.ARTIST:
          state.searchList.Artist.idIncludeList = idList;
          state.searchList.MetadataArtistRelation.idIncludeList = idList;
          break;
        case GrantKey.GENRE:
          state.searchList.Genre.idIncludeList = idList;
          break;
        case GrantKey.PLAYLIST:
          state.searchList.Playlist.idIncludeList = idList;
          break;
        case GrantKey.COMPANY:
          state.searchList.Company.idIncludeList = idList;
          break;
        default:
          break;
      }
    },
    setIdExcludeList(state, { idType, idList }: { idType: string; idList: Set<string> }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.idExcludeList = idList;
          break;
        case GrantKey.ARTIST:
          state.searchList.Artist.idExcludeList = idList;
          state.searchList.MetadataArtistRelation.idExcludeList = idList;
          break;
        case GrantKey.GENRE:
          state.searchList.Genre.idExcludeList = idList;
          break;
        case GrantKey.PLAYLIST:
          state.searchList.Playlist.idExcludeList = idList;
          break;
        case GrantKey.COMPANY:
          state.searchList.Company.idExcludeList = idList;
          break;
        default:
          break;
      }
    },
    setIncludeGenreList(state, { idType, genreList }: { idType: GrantKey; genreList: Genre[] }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.includeGenreList = genreList;
          break;
        default:
          break;
      }
    },
    setExcludeGenreList(state, { idType, genreList }: { idType: GrantKey; genreList: Genre[] }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.excludeGenreList = genreList;
          break;
        default:
          break;
      }
    },
    setIncludeMoodList(state, { idType, moodList }: { idType: GrantKey; moodList: Mood[] }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.includeMoodList = moodList;
          break;
        default:
          break;
      }
    },
    setExcludeMoodList(state, { idType, moodList }: { idType: GrantKey; moodList: Mood[] }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.excludeMoodList = moodList;
          break;
        default:
          break;
      }
    },
    setValidCheck(state, { idType, validTypeList }: { idType: GrantKey; validTypeList: ValidType[] }) {
      switch (idType) {
        case GrantKey.TRACK:
          state.searchList.Track.validTypeList = validTypeList;
          break;
        default:
          break;
      }
    },
    setLicense(state, { idType, license }: { idType: GrantKey; license: string }) {
      switch (idType) {
        case GrantKey.TRACK:
          if (state.searchList.Track.licenses.has(license)) {
            state.searchList.Track.licenses.delete(license);
          } else {
            state.searchList.Track.licenses.add(license);
          }
          break;
        default:
          break;
      }
    }
  }
});
