import React, { useState } from "react";
import styled from "styled-components";
import { ResponsiveToSideNavigatorTemplate } from "App/Templates/ResponsiveToSideNavigatorTemplate";
import { Input } from "App/Atomics/Input";
import { UserRole } from "constants/UserRole";
import { MARGING_LARGE_PX, PADDING_XXX_LARGE_PX, pixelize, UNIT, MARGING_X_LARGE_PX, MARGING_SMALL_PX } from "constants/size";
import { GRAY_5 } from "constants/baseColor";
import { Divider } from "App/Atomics/Divider";
import { GrantFormDev } from "./GrantFormDev";
import { AuthGrantStoreProvider, useAuthGrantStore } from "./Store";
import { GuideTooltip } from "App/Molecules/AnimatedTooltips";
import { clients } from "utils/clients";
import { gql } from "lib/gql-tag";
import { IdActions, IdType } from "./Store/IdList";
import itiriri from "itiriri";
import { AuthGrantActions } from "./Store/Grant";
import { Helmet } from "App/Atomics/Helmet";
import { SetAccess } from "GraphQL/Queries/Access/SetAccess";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { ColumnModal } from "./Modals";
import { Column } from "constants/Column";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { GetPlaylistVisibleField, UpdatePlaylistUsersRelation } from "GraphQL/Queries/Playlist";
import { Loading } from "App/Atomics/Loading";
import { defaultManager } from "./AuthDefault/Manager";
import { SearchForm } from "./SearchForm/index";
import { defaultArtist } from "./AuthDefault/Artist";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${PADDING_XXX_LARGE_PX};

  .group {
    width: min-content;
    margin-top: ${MARGING_SMALL_PX};
  }
`;

const ChildLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: ${MARGING_X_LARGE_PX};

  label {
    margin-bottom: ${MARGING_LARGE_PX};
    font-weight: bold;
    font-size: 1.1rem;
  }
`;

const RowGroup = styled.div<{ tier?: UserRole }>`
  display: grid;
  grid-template-columns: ${props => (props.tier === UserRole.Partner ? "35% auto auto" : "35% auto")};
  width: ${pixelize(UNIT * 30)};

  & > input {
    border-bottom: 1px solid ${GRAY_5};
    border-radius: 0;
  }

  & + & {
    margin-top: ${MARGING_LARGE_PX};
  }

  .column-button {
    margin: 0 0.5rem;
  }
`;

enum State {
  None,
  Waiting
}

const AccessType = [
  {
    id: UserRole.Arbeit,
    name: "데이터 입력직"
  },
  {
    id: UserRole.Manager,
    name: "매니저"
  },
  {
    id: UserRole.Artist,
    name: "아티스트"
  },
  {
    id: UserRole.Partner,
    name: "제휴사"
  },
  {
    id: UserRole.Master,
    name: "관리자"
  }
];

enum Mode {
  RWE = "RWE",
  SEARCH = "SEARCH"
}
const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};

  & > * {
    height: ${pixelize(UNIT * 1.5)};
  }
`;

const SearchUser = () => {
  const [email, setEmail] = useState("");
  const [state, setState] = useState<State>(State.None);
  const [tier, setTier] = useState(AccessType[0].id);
  const [{ idList, allows, defaultArbeits, defaultPartners, field }, dispatch] = useAuthGrantStore(store => ({
    idList: store.IdList.idList,
    allows: store.Grant.allows,
    defaultArbeits: store.Grant.arbeits,
    defaultPartners: store.Grant.partners,
    field: store.Grant.field
  }));
  const columnModal = useToggle();
  const SET_ACCESS_ARBEIT = (allowTables: string) => {
    return gql`
    mutation ACCESS_SET_ACCESS($email: ID!, $tier: TierType!) {
      set_access(
        where: {
          email: $email
          tier: $tier
          allowTable: [
            ${allowTables}
          ]
        }
      )
    }
  `;
  };

  const SET_ACCESS_PARTNER = (allowTables: string) => {
    return gql`
      mutation ACCESS_SET_ACCESS($email: ID!, $tier: TierType!) {
        set_access(
          where: {
            email: $email
            tier: $tier
            allowTable: [
            ${allowTables}
          ]
          }
        )
      }
    `;
  };

  const UPDATE_ACCESS_ARBEIT = (allowTables: string) => {
    return gql`
    mutation ACCESS_UPDATE_ACCESS($email: ID!, $tier: TierType!) {
      modify_access(
        where: {
          email: $email
          tier: $tier
          allowTable: [
            ${allowTables}
          ]
        }
      )
    }
  `;
  };

  const setGrantTableMap = () => {
    const grantTableMap = new Map();
    itiriri(Object.values(allows)).forEach(value => {
      value.tables.forEach((table: any) => {
        if (!grantTableMap.has(table.key) || value.key === table.key) {
          if (value.key === table.key && (idList as { [key: string]: readonly IdType[] })[value.name].length) {
            grantTableMap.set(table.key, {
              table: table.key,
              allow: table.allow,
              id: (idList as { [key: string]: readonly IdType[] })[value.name].map(({ id }) => +id)
            });
            if (table.extra) {
              grantTableMap.set(table.key, {
                table: table.key,
                allow: table.allow,
                id: (idList as { [key: string]: readonly IdType[] })[value.name].map(({ id }) => +id),
                key: table.extra
              });
            }
          } else {
            grantTableMap.set(table.key, { table: table.key, allow: table.allow });
          }
        }

        if (table.relation) {
          table.relation.forEach((relation: any) => {
            if (!grantTableMap.has(relation.key)) {
              grantTableMap.set(relation.key, { table: relation.key, allow: relation.allow });
            }
          });
        }
      });
    });

    return grantTableMap;
  };

  const setAccess = async () => {
    if (!email) {
      window.alert("권한을 부여할 이메일이 없습니다.");
      return;
    } else if (state !== State.None) {
      return;
    }

    setState(State.Waiting);
    const grantTableMap = setGrantTableMap();
    const allowTables = itiriri(grantTableMap.values())
      .toArray()
      .flatMap(value => {
        return `{
            ${Object.entries(value)
              .map(([key, value]: [string, any]) => `${key}: ${Array.isArray(value) ? `[${value.map(value => +value)}]` : `"${value}"`}`)
              .join(",")}
          }`;
      })
      .join("");

    try {
      switch (tier) {
        case UserRole.Arbeit:
        case UserRole.Manager:
        case UserRole.Artist:
          await clients.access.mutation(SET_ACCESS_ARBEIT(allowTables), { email, tier: "S" });
          break;
        case UserRole.Partner:
          await clients.access.mutation(SET_ACCESS_PARTNER(allowTables), { email, tier });
          break;
        case UserRole.Master:
          await SetAccess({ email, tier });
          break;

        default:
          break;
      }
    } catch (err) {
      console.log(err);
      window.alert("권한 덮어쓰기에 실패하였습니다.");
      return;
    }
    const playlistIdList = idList.Playlist.map(({ id }) => id);
    await onSetVisibleField(playlistIdList, field);
    setState(State.None);
    dispatch(IdActions.clear());
    window.alert("권한을 덮어씌웠습니다.");
    return;
  };

  const updateAccess = async () => {
    if (!email) {
      window.alert("권한을 부여할 이메일이 없습니다.");
      return;
    } else if (state !== State.None) {
      return;
    }

    setState(State.Waiting);
    const grantTableMap = setGrantTableMap();
    const allowTables = itiriri(grantTableMap.values())
      .toArray()
      .flatMap(
        value => `{
            ${Object.entries(value)
              .map(([key, value]: [string, any]) => `${key}: ${Array.isArray(value) ? `[${value.map(value => +value)}]` : `"${value}"`}`)
              .join(",")}
          }`
      )
      .join("");
    switch (tier) {
      case UserRole.Arbeit:
        await clients.access.mutation(UPDATE_ACCESS_ARBEIT(allowTables), { email, tier });
        break;
      case UserRole.Partner:
        await clients.access.mutation(UPDATE_ACCESS_ARBEIT(allowTables), { email, tier });
        break;
      default:
        break;
    }
    const playlistIdList = idList.Playlist.map(({ id }) => id);
    await onSetVisibleField(playlistIdList, field);
    setState(State.None);
    dispatch(IdActions.clear());
    window.alert("권한을 추가하였습니다.");
  };

  const getUsersAllowTable = async () => {
    switch (tier) {
      case UserRole.Arbeit:
        dispatch([AuthGrantActions.setGrantKey(UserRole.Arbeit), AuthGrantActions.setUsersAllowTables(defaultArbeits)]);
        break;
      case UserRole.Manager:
        dispatch([AuthGrantActions.setGrantKey(UserRole.Arbeit), AuthGrantActions.setUsersAllowTables(defaultManager)]);
        break;
      case UserRole.Artist:
        dispatch([AuthGrantActions.setGrantKey(UserRole.Arbeit), AuthGrantActions.setUsersAllowTables(defaultArtist)]);
        break;
      case UserRole.Partner:
        dispatch([AuthGrantActions.setGrantKey(UserRole.Partner), AuthGrantActions.setUsersAllowTables(defaultPartners)]);
        break;
      default:
        break;
    }
  };

  const onSetVisibleField = async (idList: string[], fields: Column[]) => {
    if (!idList.length || !fields.length) return;
    try {
      for (const id of idList) {
        const userRelation = await GetPlaylistVisibleField({ id });
        if (userRelation.length) {
          const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
          if (accessId) {
            const { errors } = await UpdatePlaylistUsersRelation({ uuid: userRelation[0].uuid, visibleField: fields });
            if (errors) throw errors;
            await DeleteAccessRecord({ id: accessId });
          }
        }
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <ChildLayout>
      <LabelContainer>
        <label>권한 줄 대상</label>
        <GuideTooltip text="권한을 선택하고, 이메일을 입력해주세요." direction="right" />
      </LabelContainer>
      <RowGroup tier={tier}>
        <Input.TextSelect
          optionList={AccessType}
          defaultValue={AccessType[0]}
          onChange={info => setTier(info?.id ? (info?.id as UserRole) : (AccessType[0].id as UserRole))}
        />
        <Input.Text placeholder="ex) arbeit@artistcard.com" onBlur={setEmail} />
        {tier === UserRole.Partner && (
          <Input.Button className="column-button" color="primary" onClick={columnModal.on}>
            컬럼 선택
          </Input.Button>
        )}
      </RowGroup>
      <Input.Group className="group">
        <Input.Button
          disabled={tier === UserRole.Master}
          style={{ marginRight: "5px" }}
          isFill={false}
          color="success"
          onClick={getUsersAllowTable}
        >
          권한 불러오기
        </Input.Button>
        <Input.Button style={{ marginRight: "5px" }} isFill={false} color="danger" onClick={setAccess}>
          권한 덮어쓰기
        </Input.Button>
        <Input.Button disabled={tier === UserRole.Master} isFill={false} color="primary" onClick={updateAccess}>
          권한 추가하기
        </Input.Button>
      </Input.Group>
      <Modal isOpen={columnModal.isToggled} onClose={columnModal.off}>
        <ColumnModal toClose={columnModal.off} />
      </Modal>
      <Loading loading={state === State.Waiting} />
    </ChildLayout>
  );
};

export const AuthGrant = () => {
  const [mode, setMode] = useState(Mode.RWE);
  return (
    <AuthGrantStoreProvider>
      <Helmet title="권한 부여" />
      <ResponsiveToSideNavigatorTemplate>
        <Layout>
          <SearchUser />
          <Divider />
          <div>
            <Input.Group className="group">
              <Input.Toggle color="primary" toggleValue="RWE 설정" isActive={mode === Mode.RWE} onClick={() => setMode(Mode.RWE)} />
              <Input.Toggle
                color="success"
                toggleValue="권한 부여 아이디 검색"
                isActive={mode === Mode.SEARCH}
                onClick={() => setMode(Mode.SEARCH)}
              />
            </Input.Group>
          </div>
          {mode === Mode.RWE && <GrantFormDev />}
          {/* {mode === Mode.SEARCH && <GrantSearch />} */}
          {mode === Mode.SEARCH && <SearchForm />}
        </Layout>
      </ResponsiveToSideNavigatorTemplate>
    </AuthGrantStoreProvider>
  );
};
