import React from "react";
import styled from "styled-components";
import { Page } from "../index";
import { Input } from "App/Atomics/Input";
import itiriri from "itiriri";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";
import { ValidType } from "constants/ValidType";
import { DANGER_COLOR, SUCCESS_COLOR } from "constants/color";
import { CreatableTextSelect } from "App/Atomics/Input/Select";
import uuidv4 from "uuid/v4";
import _ from "lodash";
import { useArtistDispatch } from "App/Routes/ArtistRenewal/Store";
import { ArtistActions } from "App/Routes/ArtistRenewal/Store/Artist";
import { Artist, CompanyRelation, GenreRelation, History, RoleRelation, Url } from "GraphQL/Queries/Artist/GetArtistRenewal";
import { ArtistAutoCompleteByNameStartExceptNaxos } from "App/Molecules/AutoCompletes/Artist";
import { GenreTextAutoComplete } from "App/Molecules/AutoCompletes/Genre";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";

type SectionProps = {
  index: number;
  data: Artist;
  pageIndex: Page;
};

const LabelGroup = styled.div`
  width: 100%;
  padding: 1.2rem 2rem;
  display: grid;
  grid-template-columns: 5.5rem auto;
  align-items: center;

  span {
    font-weight: 600;
  }

  .select {
    width: 100% !important;
    border: none;
  }
`;

const RowGroup = styled.div`
  min-width: 700px;
  padding: 0.5rem;
  &.border {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    box-shadow: 0 0 2px #eee;
  }
`;

const InputText = styled(Input.Text)`
  border: 1px solid #ccc;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `2rem 150px auto` : `2rem 34% 28% 28%`)};
  grid-gap: 0.8rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  background-color: #fff;
  border: 1.5px solid ${props => (props.color === "danger" ? DANGER_COLOR : SUCCESS_COLOR)};
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

export const ColumnSection = ({ index, data, pageIndex }: SectionProps) => {
  const dispatch = useArtistDispatch();
  return (
    <>
      {itiriri(Object.entries(data))
        .toArray(([key, value]) => ({ key, value }))
        .map(({ key, value }) => {
          if (pageIndex === Page.FIRST) {
            switch (key) {
              case "id":
              case "name":
                return (
                  <LabelGroup key={key}>
                    <span>{key === "id" ? "ID" : "이름"}</span>
                    <InputText
                      isDisabled={key === "id"}
                      defaultValue={value as string}
                      onBlur={val => dispatch(ArtistActions.updateArtistName({ index, name: val }))}
                    />
                  </LabelGroup>
                );
              case "memo":
                const memo = !value ? undefined : JSON.parse(value as string);
                return (
                  <LabelGroup key={key}>
                    <span>{"메모"}</span>
                    <ArtistAutoCompleteByNameStartExceptNaxos
                      key={memo}
                      searchType="name"
                      defaultValue={memo}
                      onChange={info => {
                        if (info) {
                          const newMemo = { id: info.id, name: info.name };
                          dispatch(ArtistActions.updateArtistMemo({ index, memo: JSON.stringify(newMemo) }));
                        }
                      }}
                    />
                  </LabelGroup>
                );
              case "weight":
                return (
                  <LabelGroup key={key}>
                    <span>중요도</span>
                    <InputText
                      defaultValue={value as string}
                      onBlur={val => dispatch(ArtistActions.updateWeight({ index, weight: val }))}
                    />
                  </LabelGroup>
                );
              case "validCheck":
                return (
                  <LabelGroup key={key}>
                    <span>{"유효성"}</span>
                    <ValidTypeTextSelect
                      className="select"
                      defaultValue={value as ValidType}
                      onChange={vc => {
                        if (vc) {
                          dispatch(ArtistActions.updateArtistVC({ index, vc }));
                        }
                      }}
                    />
                  </LabelGroup>
                );

              case "companyRelation":
                const rightsCompany = (value as CompanyRelation[])[0]?.company ?? undefined;
                return (
                  <LabelGroup key={key}>
                    <span>{"권리사"}</span>
                    <CompanyTextAutoComplete
                      isDisabled
                      defaultValue={
                        !rightsCompany
                          ? { id: "none", name: "권리사 정보가 없습니다" }
                          : { id: rightsCompany[0].id, name: rightsCompany[0].name }
                      }
                    />
                  </LabelGroup>
                );
              case "artistHistory":
                const artistHistory = value as History[];
                return (
                  <LabelGroup key={key}>
                    <span>History</span>
                    <ArtistHistory>
                      <div>
                        <span>Birth Date</span>
                        <InputText
                          className="history-place"
                          defaultValue={artistHistory[0]?.birthDate}
                          placeholder="ex. 2020"
                          onBlur={text => dispatch(ArtistActions.setArtistBirthDate({ index, birthDate: text }))}
                        />
                      </div>
                      <div>
                        <span>Death Date</span>
                        <InputText
                          className="history-place"
                          defaultValue={artistHistory[0]?.deathDate}
                          placeholder="ex. 2020"
                          onBlur={text => dispatch(ArtistActions.setArtistDeathDate({ index, deathDate: text }))}
                        />
                      </div>
                      <div>
                        <span>Birth Place</span>
                        <InputText
                          className="history-place"
                          defaultValue={artistHistory[0]?.birthPlace}
                          placeholder="ex. 장소"
                          onBlur={text => dispatch(ArtistActions.setArtistBirthPlace({ index, birthPlace: text }))}
                        />
                      </div>
                      <div>
                        <span>Death Place</span>
                        <InputText
                          className="history-place"
                          defaultValue={artistHistory[0]?.deathPlace}
                          placeholder="ex. 장소"
                          onBlur={text => dispatch(ArtistActions.setArtistDeathPlace({ index, deathPlace: text }))}
                        />
                      </div>
                    </ArtistHistory>
                  </LabelGroup>
                );
              default:
                return null;
            }
          } else if (pageIndex === Page.SECOND) {
            switch (key) {
              case "roleRelation":
                const roleRelation = value as RoleRelation[];
                return (
                  <LabelGroup key={key}>
                    <span>{"역할"}</span>
                    <RowGroup className={!roleRelation.length ? "" : "border"}>
                      {!roleRelation?.length
                        ? null
                        : roleRelation.map(({ role }, i) => {
                            const key = `${role[0]?.id ?? ""}-${role[0]?.name ?? ""}-${role[0]?.typeRoleClass ?? ""}-${i}`;
                            return (
                              <ExtraInfo key={key} column={3}>
                                <RoundButton
                                  color="danger"
                                  onClick={() => dispatch(ArtistActions.deleteArtistRole({ index, roleIndex: i }))}
                                >
                                  -
                                </RoundButton>
                                <InputText isDisabled defaultValue={!role?.length ? undefined : role[0].typeRoleClass ?? ""} />
                                <RoleTextAutoComplete
                                  defaultValue={!role?.length ? undefined : { id: role[0].id, name: role[0].name }}
                                  onChange={info => {
                                    if (info) {
                                      const roleInfo = { id: info.id, name: info.name, typeRoleClass: info.type };
                                      dispatch(ArtistActions.updateArtistRole({ index, roleIndex: i, role: roleInfo }));
                                    }
                                  }}
                                />
                              </ExtraInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const order = !roleRelation?.length ? 0 : (_.last(roleRelation)?.order ?? 0) + 1;
                          const tempRole = {
                            uuid,
                            order,
                            role: []
                          } as RoleRelation;
                          dispatch(ArtistActions.createArtistRole({ index, role: tempRole }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              case "genreRelation":
                const genreRelation = value as GenreRelation[];
                return (
                  <LabelGroup key={key}>
                    <span>{"장르"}</span>
                    <RowGroup className={!genreRelation.length ? "" : "border"}>
                      {!genreRelation?.length
                        ? null
                        : genreRelation.map(({ uuid, genre }, i) => {
                            return (
                              <ExtraInfo key={uuid} column={3}>
                                <RoundButton
                                  color="danger"
                                  onClick={() => dispatch(ArtistActions.deleteArtistGenre({ index, genreIndex: i }))}
                                >
                                  -
                                </RoundButton>
                                <InputText isDisabled defaultValue={"genre"} />
                                <GenreTextAutoComplete
                                  defaultValue={!genre?.length ? undefined : { id: genre[0].id, name: genre[0].name }}
                                  onBlur={info => {
                                    if (info) {
                                      const genreInfo = { ...info, typeKind: "genre" };
                                      dispatch(ArtistActions.updateArtistGenre({ index, genreIndex: i, genre: genreInfo }));
                                    }
                                  }}
                                />
                              </ExtraInfo>
                            );
                          })}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const order = !genreRelation?.length ? 0 : (_.last(genreRelation)?.order ?? 0) + 1;
                          const tempGenre = {
                            uuid,
                            order,
                            genre: []
                          } as GenreRelation;
                          dispatch(ArtistActions.createArtistGenre({ index, genre: tempGenre }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );
              default:
                return null;
            }
          } else {
            switch (key) {
              case "artistUrl":
                const urls = value as Url[];
                return (
                  <LabelGroup key={key}>
                    <span>{"URL"}</span>
                    <RowGroup className={!urls.length ? "" : "border"}>
                      {!urls?.length
                        ? null
                        : urls.map((url, i) => (
                            <ExtraInfo key={i} column={3}>
                              <RoundButton color="danger" onClick={() => dispatch(ArtistActions.deleteArtistUrl({ index, urlIndex: i }))}>
                                -
                              </RoundButton>
                              <CreatableTextSelect
                                name="select"
                                className="select"
                                optionList={[
                                  { id: "source", name: "source" },
                                  { id: "score", name: "score" },
                                  { id: "imslp", name: "imslp" }
                                ]}
                                placeholder="타입"
                                defaultValue={!url.typeUrl ? undefined : { id: url.typeUrl, name: url.typeUrl }}
                                onBlur={info => {
                                  if (info) {
                                    dispatch(ArtistActions.updateArtistTypeUrl({ index, urlIndex: i, typeUrl: info.id }));
                                  }
                                }}
                              />
                              <InputText
                                placeholder="값"
                                defaultValue={!url.url ? undefined : url.url}
                                onBlur={text => {
                                  dispatch(ArtistActions.updateArtistUrl({ index, urlIndex: i, url: text }));
                                }}
                              />
                            </ExtraInfo>
                          ))}
                      <RoundButton
                        color="success"
                        onClick={() => {
                          const uuid = uuidv4();
                          const tempUrl = {
                            uuid,
                            validCheck: ValidType.V3,
                            typeUrl: undefined,
                            url: undefined
                          } as Url;
                          dispatch(ArtistActions.createArtistUrl({ index, url: tempUrl }));
                        }}
                      >
                        +
                      </RoundButton>
                    </RowGroup>
                  </LabelGroup>
                );

              default:
                return null;
            }
          }
        })}
    </>
  );
};

const ArtistHistory = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid#ddd;
  border-radius: 8px;
  padding: 1.5em;
  width: min-content;
  & > div {
    margin-bottom: 4px;
    display: grid;
    grid-template-columns: 5.5rem auto;
    align-items: center;
  }
  span {
    font-weight: 400;
  }
  .history-place {
    width: 12rem;
    text-align: center;
  }
`;
