/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, IntScalar, EnumScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";
import { MetadataRightType } from "constants/MetadataRightType";
import { MetadataRightScalar, MetadataSelfRelationOrderScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
import { MetadataSelfRelationOrderByInput } from "constants/MetadataSelfRelationOrderByInput";
import { GenderType } from "constants/GenderType";
import { MetadataUrlOrderByInput } from "constants/MetadataUrlOrderByInput";

type Option = Readonly<{
  page?: number;
  first?: number;
  metadataId?: string;
  title?: string;
  artistName?: string;
  no?: string;
  typeClass: string;
  typeSubClassIn: string[];
  detail?: boolean;
  validCheck?: string[];
  gte?: number;
  lte?: number;
  artistIdIn?: string[];
  rightsIdIn?: string[];
  url?: boolean;
  order?: string;
}>;

export type Artist = {
  uuid: string;
  validCheck: ValidType;
  order: number;
  artist: {
    id?: string;
    name?: string;
  }[];
  role: {
    id?: string;
    name?: string;
  }[];
  character: {
    id?: string;
    name?: string;
  }[];
};

export type Company = {
  uuid: string;
  validCheck: ValidType;
  typeKind: string;
  order: number;
  company: {
    id: string;
    name: string;
    type?: string;
    path?: string;
    license?: string;
  }[];
};

export type Genre = {
  uuid: string;
  validCheck: ValidType;
  genre: {
    id: string;
    name: string;
  }[];
};

export type Mood = {
  uuid: string;
  validCheck: ValidType;
  mood: {
    id: string;
    name: string;
  }[];
};

export type Structure = {
  rootId: number;
  structureId: string;
  child: {
    innerOrder: number;
    metadata: Metadata[];
  }[];
};

export type Title = {
  uuid: string;
  validCheck: ValidType;
  typeTitle: string;
  title: string;
  language: {
    name: string;
    languageCode: string;
    validCheck: string;
  }[];
};

export type Url = {
  uuid: string;
  validCheck: ValidType;
  typeUrl?: string;
  url?: string;
  order?: number;
};

export type SubData = {
  uuid: string;
  field?: string;
  category?: string;
  value?: string;
  validCheck: ValidType;
};

export type ElementRelation = {
  uuid: string;
  order: number;
  listRelation: {
    id?: string;
    title?: string;
    artistRelation?: {
      artist: {
        name: string;
      }[];
    }[];
  }[];
};

export type ListRelation = {
  uuid: string;
  order: number;
  elementRelation: {
    id?: string;
    title?: string;
    artistRelation?: {
      artist: {
        name: string;
      }[];
    }[];
  }[];
};

export type TrackInformation = {
  uuid?: string;
  channel?: number;
  duration?: number;
  bitrate?: number;
  tempo?: number;
  tonality?: string;
  timeSignature?: string;
  voiceGender?: GenderType;
  extraData?: string;
};

export type Metadata = {
  metadataId: string;
  v1: string | null;
  no: string | null;
  class: string;
  subClass: string;
  title: string;
  validCheck: ValidType;
  artistRelation: Artist[];
  genreRelation: Genre[];
  moodRelation: Mood[];
  rightsCompany: Company[];
  productions: Company[];
  publications: Company[];
  titles: Title[];
  metadataUrl: Url[];
  structures: Structure[];
  subdataUnique: SubData[];
  elementRelation: ElementRelation[];
  listRelation: ListRelation[];
  trackInformation: TrackInformation[];
};

export const GetMetadataTrack = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable />} value={option.page} />;
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar isNullable />} value={option.metadataId} />;
  const $typeClass = <Variable name="class" scalar={<StringScalar />} value={option.typeClass} />;
  const $typeSubClassIn = (
    <Variable name="type_subclass" scalar={<ListScalar scalar={<StringScalar isNullable={false} />} />} value={option.typeSubClassIn} />
  );
  const $rightCompany = <MetadataRightScalar isNullable value={MetadataRightType.rightCompany} />;
  const $production = <MetadataRightScalar isNullable value={MetadataRightType.production} />;
  const $publication = <MetadataRightScalar isNullable value={MetadataRightType.publication} />;
  const $order =
    option.no || option.metadataId || option.title || option.artistName ? (
      undefined
    ) : (
      <EnumScalar type="MetadataOrderByInput" value={option.order ?? "metadata_id__DESC"} items={[option.order ?? "metadata_id__DESC"]} />
    );
  const { query, variables } = render(
    <Query operationName="GET_METADATA_TRACK">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: $first,
          page: $page,
          orderBy: $order,
          where: {
            metadata_id: $metadataId,
            type_metadata_class: $typeClass,
            type_metadata_subclass__in: $typeSubClassIn
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <Field name="compatibility_v1_id" alias="v1" />
        <Field name="no" />
        <Field name="title" />
        <Field name="type_metadata_class" alias="class" />
        <Field name="type_metadata_subclass" alias="subClass" />
        <Field name="valid_check" alias="validCheck" />
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            first: 99,
            orderBy: (
              <EnumScalar type="Metadata_artist_relationOrderByInput" value={"exposure_order__ASC"} items={["exposure_order__ASC"]} />
            )
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
          <SelectionSet name="role">
            <Field name="role_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
          <SelectionSet name="character">
            <Field name="role_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_genre_relation"
          alias="genreRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "genre"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_genre_relation"
          alias="moodRelation"
          argument={{
            first: 99,
            where: {
              genre__some: {
                type_kind: "mood"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="genre" alias="mood">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="rightsCompany"
          argument={{
            where: {
              type_kind: $rightCompany
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="type" />
            <Field name="group_path" alias="path" />
            <Field name="license" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="productions"
          argument={{
            where: {
              type_kind: $production
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="type" />
            <Field name="group_path" alias="path" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_company_relation"
          alias="publications"
          argument={{
            where: {
              type_kind: $publication
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_kind" alias="typeKind" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="type" />
            <Field name="group_path" alias="path" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_title"
          alias="titles"
          argument={{
            where: {
              type_metadata_title__not_starts_with: "search"
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_metadata_title" alias="typeTitle" />
          <Field name="value" alias="title" />
          <SelectionSet name="language">
            <Field name="name" />
            <Field name="language_code" alias="languageCode" />
            <Field name="valid_check" alias="validCheck" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_url"
          alias="metadataUrl"
          argument={{
            orderBy: (
              <ListScalar
                scalar={<EnumScalar type="Metadata_urlOrderByInput" items={[MetadataUrlOrderByInput.type_url_DESC]} />}
                value={[MetadataUrlOrderByInput.type_url_DESC]}
              />
            )
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet name="metadata_subdata_unique" alias="subdataUnique">
          <Field name="id" alias="uuid" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="type_subdata_field" alias="field" />
          <Field name="type_subdata_category" alias="category" />
          <Field name="value" />
        </SelectionSet>
        <SelectionSet
          name="metadata_self_relations_element_metadata"
          alias="elementRelation"
          argument={{
            orderBy: <MetadataSelfRelationOrderScalar value={MetadataSelfRelationOrderByInput.exposure_order_ASC} />,
            where: {
              metadata_self_relations_list_metadata__some: {
                type_metadata_class: "record",
                type_metadata_subclass: "track"
              }
            }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata_self_relations_list_metadata" alias="listRelation">
            <Field name="metadata_id" alias="id" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
        <SelectionSet
          name="metadata_self_relations_list_metadata"
          alias="listRelation"
          argument={{
            orderBy: <MetadataSelfRelationOrderScalar value={MetadataSelfRelationOrderByInput.exposure_order_ASC} />
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata_self_relations_element_metadata" alias="elementRelation">
            <Field name="metadata_id" alias="id" />
            <Field name="title" />
            <SelectionSet name="metadata_artist_relation" alias="artistRelation">
              <SelectionSet name="artist">
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="track_information" alias="trackInformation" argument={{ first: 1 }}>
          <Field name="id" alias="uuid" />
          <Field name="channel" />
          <Field name="duration" />
          <Field name="sample_bitrate" alias="bitrate" />
          <Field name="tempo" />
          <Field name="tonality" />
          <Field name="time_signature" alias="timeSignature" />
          <Field name="voice_gender" alias="voiceGender" />
          <Field name="extra_data" alias="extraData" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: Metadata[];
    edge: Edge;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
