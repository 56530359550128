import React, { MouseEvent } from "react";
import styled from "styled-components";
import classNames from "classnames";

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  INFO_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
  DANGER_COLOR,
  SECONDARY_TOGGLE_OFF,
  SECONDARY_TOGGLE_ON,
  INFO_TOGGLE_OFF,
  INFO_TOGGLE_ON,
  SUCCESS_TOGGLE_OFF,
  SUCCESS_TOGGLE_ON,
  WARNING_TOGGLE_OFF,
  WARNING_TOGGLE_ON,
  DANGER_TOGGLE_ON,
  DANGER_TOGGLE_OFF,
  FONT_BASIC,
  PRIMARY_COLOR_LIGHT,
  PRIMARY_TOGGLE_OFF
} from "constants/color";
import { WHITE, VIOLET_3, GRAY_3, GREEN_3, YELLO_3, RED_3, BLACK } from "constants/baseColor";
import { PADDING_LARGE_PX, PADDING_X_LARGE_PX, BORDER_RADIUS_PX } from "constants/size";
import { identity } from "utils/identity";

type Color = "primary" | "secondary" | "info" | "success" | "warning" | "danger" | "dark";

type Props = Styleable &
  Readonly<{
    color?: Color;
    toggleValue?: string | [string, string];
    isWide?: boolean;
    isActive?: boolean;
    onClick?: () => void;
  }>;

const ToggleLayout = styled.button<{ isWide: boolean; isActive: boolean }>`
  display: block;
  width: ${props => (props.isWide ? "100%" : "min-content")};
  padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
  border-radius: ${BORDER_RADIUS_PX};
  background-color: transparent;
  color: ${FONT_BASIC};
  white-space: nowrap;

  transition: all 0.2s;

  &.primary {
    color: ${WHITE};
    background-color: ${PRIMARY_TOGGLE_OFF};

    &.active {
      background-color: ${PRIMARY_COLOR};

      &:hover {
        background-color: ${PRIMARY_COLOR_LIGHT};
      }
    }

    &:hover {
      background-color: ${PRIMARY_COLOR_LIGHT};
    }
  }

  &.secondary {
    color: ${WHITE};
    background-color: ${SECONDARY_TOGGLE_OFF};

    &.active {
      background-color: ${SECONDARY_TOGGLE_ON};

      &:hover {
        background-color: ${SECONDARY_COLOR};
      }
    }

    &:hover {
      background-color: ${VIOLET_3};
    }
  }

  &.info {
    color: ${WHITE};
    background-color: ${INFO_TOGGLE_OFF};

    &.active {
      background-color: ${INFO_TOGGLE_ON};

      &:hover {
        background-color: ${INFO_COLOR};
      }
    }

    &:hover {
      background-color: ${GRAY_3};
    }
  }

  &.success {
    color: ${WHITE};
    background-color: ${SUCCESS_TOGGLE_OFF};

    &.active {
      background-color: ${SUCCESS_TOGGLE_ON};

      &:hover {
        background-color: ${SUCCESS_COLOR};
      }
    }

    &:hover {
      background-color: ${GREEN_3};
    }
  }

  &.warning {
    color: ${WHITE};
    background-color: ${WARNING_TOGGLE_OFF};

    &.active {
      background-color: ${WARNING_TOGGLE_ON};

      &:hover {
        background-color: ${WARNING_COLOR};
      }
    }

    &:hover {
      background-color: ${YELLO_3};
    }
  }

  &.danger {
    color: ${WHITE};
    background-color: ${DANGER_TOGGLE_OFF};

    &.active {
      background-color: ${DANGER_TOGGLE_ON};

      &:hover {
        background-color: ${DANGER_COLOR};
      }
    }

    &:hover {
      background-color: ${RED_3};
    }
  }

  &.dark {
    color: ${WHITE};
    background-color: #343a40;

    &.active {
      background-color: ${BLACK};

      &:hover {
        background-color: #495057;
      }
    }

    &:hover {
      background-color: #868e96;
    }
  }
`;

export const Toggle = ({ className, style, toggleValue, color, isWide = false, isActive = false, onClick = identity }: Props) => {
  if (!toggleValue) {
    toggleValue = typeof toggleValue === "string" ? "Button" : ["ON", "OFF"];
  }

  const toggle = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.currentTarget;
    isActive = !isActive;

    if (typeof toggleValue === "object") {
      target.innerText = isActive ? toggleValue[0] : toggleValue[1];
    }

    target.classList.toggle("active", isActive);
    onClick();
  };

  return (
    <ToggleLayout
      style={style}
      className={classNames(className, { active: isActive }, color)}
      type="button"
      isWide={isWide}
      isActive={isActive}
      onClick={toggle}
    >
      {typeof toggleValue === "object" ? (isActive ? toggleValue[0] : toggleValue[1]) : toggleValue}
    </ToggleLayout>
  );
};
