import React, { useState } from "react";
import styled from "styled-components";
import {
  pixelize,
  UNIT,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_LARGE_PX,
  MARGING_X_LARGE_PX,
  MARGING_XX_LARGE_PX
} from "constants/size";
import { WHITE, GRAY_2, BLACK, GRAY_4, GRAY_5, BLUE_7 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as SearchIcon } from "assets/icons/custom-search.svg";
import { Input } from "App/Atomics/Input";
import { GetArtistRelationsInTracks } from "GraphQL/Queries/Track/GetArtistRelationsInTracks";
import { DANGER_COLOR } from "constants/color";
import { CreateAccessRecord, DeleteAccessRecord } from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { shift } from "lib/shift";
import { timeout } from "utils/timeout";
import { UpdateTrackArtistRelation } from "GraphQL/Queries/Track/UpdateTrackArtistRelation";
import { AnimatedProgress } from "App/Atomics/AnimatedProgress";
import { DeleteTrackArtistRelation } from "GraphQL/Queries/Track";
import { ArtistTextAutoComplete } from "App/Molecules/AutoCompletes/Artist";
type Props = {
  toClose: () => void;
};
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 42)};
  height: ${pixelize(UNIT * 32)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;
`;

const Header = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  padding: ${pixelize(PADDING_X_LARGE * 1.4)};
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
    font-weight: bold;
  }
  .cancelIcon {
    fill: ${BLACK};
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    transition: all 0.1s;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_4};
    }
  }
`;

const InputButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: ${MARGING_X_LARGE_PX};
  svg {
    transform: scale(1.4);

    stroke: ${WHITE};
  }
`;

const CustomDivider = styled.div`
  display: block;
  width: 90%;
  height: 2px;
  margin: 0 ${MARGING_XX_LARGE_PX};
  border: 1.2px solid ${GRAY_5};
  border-bottom-color: ${WHITE};
`;

const SpanContainer = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 8)};
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    span {
      color: ${BLUE_7};
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
`;

const AutoComplete = styled(ArtistTextAutoComplete)`
  width: ${pixelize(UNIT * 18)};
  margin: 0 ${MARGING_LARGE_PX};
`;

const ButtonContainer = styled.div`
  width: 90%;
  margin: ${MARGING_LARGE_PX} ${MARGING_XX_LARGE_PX};
  margin-bottom: ${MARGING_XX_LARGE_PX};
`;

export const BatchEditModal = ({ toClose }: Props) => {
  const [prevId, setPrevId] = useState(""); // 바꾸기 이전의 artist_id
  const [nextId, setNextId] = useState(""); // 바꿀 artist_id
  const [relationIds, setRelationIds] = useState<string[] | undefined>(undefined);
  const [percent, setPercent] = useState<number>(-1);
  const onSearch = async () => {
    const { data, errors } = await GetArtistRelationsInTracks({ id: prevId });
    if (errors) {
      window.alert("데이터를 가져올 수 없습니다.");
      return;
    }
    if (data) {
      let tempArr: string[] = [];
      data.metadata.forEach(({ artistRelation }) => {
        for (const { id } of artistRelation) {
          tempArr.push(id);
        }
      });
      setRelationIds(tempArr);
    }
  };

  // TODO
  const onSave = async () => {
    if (window.confirm(`이 작업은 복구가 어렵습니다. 정말로 변경하시겠습니까?`)) {
      try {
        setPercent(0);
        const { data } = await CreateAccessRecord({ targetId: prevId, targetTable: TargetTableInput.Artist });
        if (data) {
          const accessId = data.createAccess.id;
          let count = 0;
          for (const uuid of shift(relationIds!, 1)) {
            count += 1;
            setPercent((count / relationIds!.length) * 100);
            try {
              await timeout(1000).then(async () => {
                const { errors } = await UpdateTrackArtistRelation({ uuid: uuid[0], artistId: nextId });
                if (errors) {
                  await DeleteTrackArtistRelation({ uuid: uuid[0] });
                }
              });
            } catch (e) {
              window.alert(`${count}번째 변경에 실패하였습니다.`);
              await DeleteAccessRecord({ id: accessId });
              console.log(e);
              return;
            }
          }
          try {
            await DeleteAccessRecord({ id: accessId });
            setPercent(100);
            window.alert("변경이 완료되었습니다.");
            window.location.reload();
          } catch (e) {
            window.alert("권한 삭제 요청을 실패하였습니다.");
            console.log(e);
            return;
          }
        }
      } catch (e) {
        window.alert("권한 요청을 실패하였습니다.");
        console.log(e);
        return;
      }
    } else {
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h2>일괄 편집</h2>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <SpanContainer>
        전체 트랙 중{" "}
        <AutoComplete
          onChange={info => {
            if (info) {
              setPrevId(info.id);
              setRelationIds(undefined);
            }
          }}
        />{" "}
        를 검색합니다.
        <InputButton color="default" onClick={onSearch}>
          <SearchIcon />
        </InputButton>
      </SpanContainer>
      <CustomDivider />
      <SpanContainer>
        {relationIds === undefined ? (
          <span style={{ color: DANGER_COLOR }}>아티스트를 먼저 검색해주세요.</span>
        ) : relationIds.length === 0 ? (
          <span style={{ color: BLACK }}>해당 아티스트의 트랙이 존재하지 않습니다.</span>
        ) : (
          <p>
            전체 트랙 중 <span>{relationIds.length}개</span>의 해당 아티스트의 트랙이 존재합니다.
          </p>
        )}
      </SpanContainer>
      <CustomDivider />
      <SpanContainer>
        해당 트랙의 아티스트를
        <AutoComplete
          onChange={info => {
            if (info) {
              setNextId(info.id);
            }
          }}
        />
        로 변경합니다.
      </SpanContainer>
      <ButtonContainer>
        {percent < 0 ? (
          <Input.Button disabled={!prevId || !nextId || !relationIds || !relationIds.length} color="danger" isWide onClick={onSave}>
            작업량에 따라 약간의 시간이 소요될 수 있습니다. 확인 후 버튼을 눌러주세요.
          </Input.Button>
        ) : (
          <AnimatedProgress percent={percent} />
        )}
      </ButtonContainer>
    </Layout>
  );
};
