/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  artistId?: string;
}>;

export const GetArtistRelationByMemo = async (option: Option) => {
  const $artistId = <Variable name="artist_id" scalar={<IdScalar isNullable={true} />} value={option.artistId} />;
  const { query, variables } = render(
    <Query operationName="GET_ARTIST_RELATION_BY_MEMO">
      <SelectionSet
        name="artist"
        argument={{
          group: true,
          first: 9999,
          where: {
            artist_id: $artistId,
            memo__exist: true,
            metadata_artist_relation__some: {
              artist__some: {
                memo__exist: true
              }
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <Field name="name" />
        <Field name="memo" />
        <SelectionSet name="metadata_artist_relation" argument={{ first: 9999 }}>
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    artist: Readonly<{
      id: string;
      name: string;
      memo: string;
      metadata_artist_relation: {
        uuid: string;
      }[];
    }>[];
  }>;
  return await clients.artist.request<Data>(query, variables);
};
