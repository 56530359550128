/** @jsx jsx */

import { Field, jsx, Query, render, SelectionSet } from "graphql-jsx";

import { configs } from "configs";
import { GraphQLClient } from "lib/gql-client";
import { GraphQLClientHeader } from "./GraphQLClientHeader";

export const updateTokens = async () => {
  const { query, variables } = render(
    <Query operationName="ACCESS_UPDATE_TOKENS">
      <SelectionSet name="refreshToken" alias="updateTokens">
        <SelectionSet name="jsonWebToken" alias="tokens">
          <Field name="refresh_user_token" alias="refreshToken" />
          <Field name="access_token" alias="accessToken" />
          <Field name="user_token" alias="userToken" />
          <Field name="payload_token" alias="payloadToken" />
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  const client = GraphQLClient.getInstance({
    getHeader: GraphQLClientHeader.get,
    baseUrl: configs.urls.access,
    isFailover: false
  });
  return await client.request(query, variables);
};
