/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, StringScalar, IntScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";
import { GoodsTitleOrderByInput } from "constants/GoodsTitleOrderByInput";
import { GoodsTitleOrderScalar } from "GraphQL/Scalars";
import { GoodsUrlOrderScalar } from "GraphQL/Scalars/GoodsUrlOrderScalar";
import { GoodsUrlOrderByInput } from "constants/GoodsUrlOrderByInput";

type Option = Readonly<{
  page?: number;
  first?: number;
  id?: string;
  name?: string;
  parentId?: number;
  categoryIn?: string[];
  typeIn?: string[];
  typeNotIn?: string[];
}>;

type Url = Readonly<{
  id: string;
  typeUrl: string;
  url: string;
  order: number;
}>;

type Company = Readonly<{
  company: {
    id: string;
    name: string;
  };
}>;

type GoodsType = Readonly<{
  id: number;
  type: string;
  category: string;
  displayType: string;
}>;

type Goods = Readonly<{
  id: string;
  name: string;
  weight: number;
  barcode: string;
  askPrice: number;
  officialPrice: number;
  quality: string;
  isCollection: boolean;
  createdAt: string;
  goodsStructure: {
    id: string;
    parent: {
      goods: {
        id: string;
        name: string;
      }[];
    }[];
  }[];
  goodsTitle: {
    id: string;
    value: string;
  }[];
  goodsType: GoodsType;
  goodsUrl: Url[];
  goodsCompanyRelation: Company[];
}>;

export const LoadGoodsBySearch = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable />} value={option.page} />;
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable />} value={option.id} />;
  const $keyword = <Variable name="keyword" scalar={<StringScalar isNullable />} value={option.name} />;
  const $parentId = <Variable name="parent_id" scalar={<IntScalar isNullable />} value={option.parentId} />;
  const $categoryIn = (
    <Variable
      name="category__in"
      scalar={<ListScalar isNullable scalar={<StringScalar isNullable={false} />} />}
      value={option.categoryIn}
    />
  );
  const $typeIn = (
    <Variable name="type__in" scalar={<ListScalar isNullable scalar={<StringScalar isNullable={false} />} />} value={option.typeIn} />
  );
  const $typeNotIn = (
    <Variable
      name="type__not_in"
      scalar={<ListScalar isNullable scalar={<StringScalar isNullable={false} />} value={option.typeNotIn} />}
      value={option.typeNotIn}
    />
  );
  const $order = (
    <Variable
      name="orderBy"
      scalar={<ListScalar scalar={<GoodsTitleOrderScalar isNullable={false} />} />}
      value={option.name ? GoodsTitleOrderByInput.value__SIMILARITY : GoodsTitleOrderByInput.goods_id__DESC}
    />
  );
  const $urlOrder = <GoodsUrlOrderScalar value={GoodsUrlOrderByInput.exposure_order__ASC} />;
  const { query, variables } = render(
    <Query operationName="LOAD_GOODS_BY_SEARCH">
      <SelectionSet
        name="goods_title"
        alias="goodsTitle"
        argument={{
          group: true,
          first: $first,
          page: $page,
          orderBy: $order,
          where: {
            type_title__starts_with: "search",
            tsvec_words__search: $keyword,
            goods__some: {
              goods_id: $id,
              goods_type__some: {
                type__in: $typeIn,
                type__not_in: $typeNotIn,
                category__in: $categoryIn
              },
              goods_structure__some: {
                parent_id: $parentId
              }
            }
          }
        }}
      >
        <Field name="goods_id" />
        <SelectionSet name="goods">
          <Field name="goods_id" alias="id" />
          <Field name="name" />
          <Field name="type_id" />
          <Field name="weight" />
          <Field name="ask_price" alias="askPrice" />
          <Field name="official_price" alias="officialPrice" />
          <Field name="is_collection" alias="isCollection" />
          <Field name="created_at" alias="createdAt" />
          <SelectionSet name="goods_url" alias="goodsUrl" argument={{ first: 1, where: { type_url: "cover" }, orderBy: $urlOrder }}>
            <Field name="goods_id" alias="id" />
            <Field name="type_url" alias="typeUrl" />
            <Field name="url" />
            <Field name="exposure_order" alias="order" />
          </SelectionSet>
          <SelectionSet name="goods_company_relation" alias="goodsCompanyRelation">
            <SelectionSet name="company">
              <Field name="company_id" alias="id" />
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
          <SelectionSet name="goods_structure" alias="goodsStructure">
            <Field name="id" />
            <SelectionSet name="parent">
              <SelectionSet name="goods">
                <Field name="goods_id" alias="id" />
                <Field name="name" />
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
          <SelectionSet name="goods_title" alias="goodsTitle" argument={{ where: { type_title: "subtitle" } }}>
            <Field name="id" />
            <Field name="value" />
          </SelectionSet>
          <SelectionSet name="goods_type" alias="goodsType">
            <Field name="id" />
            <Field name="type" />
            <Field name="category" />
            <Field name="display_type" alias="displayType" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "goods",
          first: $first,
          page: $page,
          where: {
            goods: {
              goods_title__some: {
                OR: [{ type_title__starts_with: "name" }, { type_title__starts_with: "tag" }],
                tsvec_words__search: $keyword,
                goods__some: {
                  goods_id: $id,
                  goods_type__some: {
                    type__in: $typeIn,
                    type__not_in: $typeNotIn,
                    category__in: $categoryIn
                  },
                  goods_structure__some: {
                    parent_id: $parentId
                  }
                }
              }
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    goodsTitle: {
      goods_id: string;
      goods: Goods[];
    }[];
    edge: Edge;
  }>;
  return await clients.goods.request<Data>(query, variables);
};
