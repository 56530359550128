/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{}>;

export type CompanyUserRelation = {
  uuid: string;
  company: {
    id: string;
    name: string;
  }[];
  users: {
    id: number;
    email: string;
  }[];
  isOwner: number;
};

export const GetCompanyUserInfo = async (option: Option) => {
  const { query, variables } = render(
    <Query operationName="GET_COMPANY_USER_INFO">
      <SelectionSet
        name="company_users_relation"
        alias="companyUserRelation"
        argument={{
          first: 999,
          where: {
            is_owner: 1,
            company__some: {
              type__not: "Cover"
            }
          }
        }}
      >
        <Field name="id" alias="uuid" />
        <SelectionSet name="company">
          <Field name="company_id" alias="id" />
          <Field name="name" />
        </SelectionSet>
        <SelectionSet name="users">
          <Field name="users_id" alias="id" />
          <Field name="email" />
        </SelectionSet>
        <Field name="is_owner" alias="isOwner" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    companyUserRelation: CompanyUserRelation[];
  }>;
  return await clients.company.request<Data>(query, variables);
};
