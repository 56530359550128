import { useState, useMemo, useLayoutEffect } from "react";

export const usePlaceholder = () => {
  const [screenX, setScreenX] = useState<number>(window.innerWidth);
  const placeholderWidth = useMemo(() => {
    if (screenX > 1024) {
      return screenX * 0.18;
    } else if (screenX > 768) {
      return screenX * 0.225;
    } else {
      return screenX * 0.45;
    }
  }, [screenX]);
  useLayoutEffect(() => {
    const handler = () => {
      setScreenX(document.body.clientWidth);
    };
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return { placeholderWidth };
};
