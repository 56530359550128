/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, ListScalar, IdScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  idList: string[];
  playlistKind: string;
}>;

export const GetMetadataInPlaylist = async (option: Option) => {
  const $idList = <Variable name="track_id_list" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={option.idList} />;
  const $kind = <Variable name="kind" scalar={<StringScalar isNullable />} value={option.playlistKind} />;
  const { query, variables } = render(
    <Query operationName="GET_NO_DUPLICATED_METADATA">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 9999,
          where: {
            metadata_id__in: $idList,
            type_metadata_class__in: ["record"],
            type_metadata_subclass__in: ["track", "effect"],
            metadata_playlist_relation__some: {
              playlist__some: {
                kind: $kind
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      title: string;
    }[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  const result = data!.metadata.map(({ id, title }) => ({ id, title }));
  return result;
};
