/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { TrackPlayCountOrderByInput } from "../../constants/TrackPlayCountOrderByInput";

type Props = Readonly<{
  value?: TrackPlayCountOrderByInput;
  isNullable?: boolean;
}>;

const items = [
  TrackPlayCountOrderByInput.id_ASC,
  TrackPlayCountOrderByInput.id_DESC,
  TrackPlayCountOrderByInput.timestamp_ASC,
  TrackPlayCountOrderByInput.timestamp_DESC,
  TrackPlayCountOrderByInput.servie_ASC,
  TrackPlayCountOrderByInput.servie_DESC,
  TrackPlayCountOrderByInput.metadata_id_ASC,
  TrackPlayCountOrderByInput.metadata_id_DESC
];

export const TrackPlayCountOrderScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="TrackPlayCountOrderScalar" value={value} isNullable={isNullable} items={items} />
);
