import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { CompanyTextAutoComplete } from "App/Molecules/AutoCompletes/Company";

type Info = {
  id: string;
  name: string;
};

type Props = {
  readonly company: Info;
};

export const GoodsCompany = ({ company }: Props): ReactElement => {
  const onChange = (info: Info | null) => info && GoodsCreateStore.dispatch(GoodsCreateActions.setCompany(info));
  return (
    <Style.Row>
      <Style.Caption>제조사 *</Style.Caption>
      <CompanyTextAutoComplete style={SelectStyle} defaultValue={company} placeholder="제조사를 입력해주세요" onBlur={onChange} />
      <Style.AlertCaption>제조사를 선택해주세요.</Style.AlertCaption>
    </Style.Row>
  );
};
