import React, { ReactElement } from "react";
import { SelectStyle, Style } from "App/Routes/GoodsCreate/Style";
import { GoodsCreateStore } from "App/Routes/GoodsCreate/Store";
import { GoodsCreateActions } from "App/Routes/GoodsCreate/Store/GoodsCreate";
import { ArtistTagAutoComplete } from "App/Molecules/AutoCompletes/Artist";

type Info = {
  id: string;
  name: string;
};

type Props = {
  readonly artists: Info[];
};

export const GoodsArtist = ({ artists }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>{`아티스트 *`}</Style.Caption>
    <Style.SubCaption>소속그룹(organization)과 최소 1명의 아티스트(person)를 추가해주세요 *</Style.SubCaption>
    <ArtistTagAutoComplete
      style={SelectStyle}
      defaultValue={artists}
      onBlur={info => {
        const ids = info.map(({ id, name }) => ({ id, name }));
        GoodsCreateStore.dispatch(GoodsCreateActions.setArtists(ids));
      }}
    />
    <Style.AlertCaption>아티스트를 선택해주세요.</Style.AlertCaption>
  </Style.Row>
);
