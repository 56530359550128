import React, { useState, SetStateAction } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { DEFAULT_BORDER_COLOR, DANGER_COLOR_LIGHT, SECONDARY_COLOR, DANGER_COLOR, PRIMARY_COLOR, INFO_COLOR_LIGHT } from "constants/color";
import {
  PADDING_X_LARGE_PX,
  pixelize,
  UNIT,
  BORDER_RADIUS_PX,
  MARGING_X_LARGE_PX,
  PADDING_LARGE_PX,
  PADDING_XX_LARGE_PX,
  MARGING_LARGE_PX,
  MARGING_XX_LARGE_PX,
  MARGING_SMALL_PX
} from "constants/size";
import { WHITE, GRAY_4, GRAY_6 } from "constants/baseColor";
import { ReactComponent as RoleIcon } from "assets/icons/role.svg";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { Confirm } from "App/Molecules/Confirm";
import { useRoleStore } from "../Store";
import { RoleCreateActions } from "../Store/Role";
import { RoleTextAutoComplete } from "App/Molecules/AutoCompletes/Role";
import { Loading } from "App/Atomics/Loading";
import { Toast } from "lib/toast";
import { CreateRole } from "GraphQL/Queries/Role";
import uuidv4 from "uuid/v4";
import { RoleTypeFunctionInfo } from "constants/RoleTypeFunctionInfo";

const Layout = styled.div`
  display: inherit;
  width: 765px;
  flex-direction: column;
  overflow: auto;
  background: ${WHITE};
  padding: ${PADDING_XX_LARGE_PX};
  box-shadow: 0px 0px 8px 0px ${GRAY_4};
  margin-top: 1rem;
  margin-bottom: 1rem;
  h2 {
    text-align: left;
  }

  h4 {
    margin-bottom: ${MARGING_LARGE_PX};
  }

  hr {
    margin-bottom: ${MARGING_XX_LARGE_PX};
  }

  .autocomplete {
    width: 100%;
  }

  input {
    width: 100%;
    padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
    border: 1px solid ${DEFAULT_BORDER_COLOR};
    border-radius: ${BORDER_RADIUS_PX};
    text-align: left;
    transition: border 0.5s;

    &:focus {
      border: 1px solid #4c52bc;
    }
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: ${MARGING_LARGE_PX};

  svg {
    width: ${pixelize(UNIT * 1.5)};
    height: ${pixelize(UNIT * 1.5)};
  }

  span {
    color: ${INFO_COLOR_LIGHT};
  }
`;

const CustomDivider = styled.div`
  color: ${GRAY_4};
  background-color: ${GRAY_4};
  width: 100%;
  height: 1px;
  margin: ${MARGING_X_LARGE_PX} 0;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${PADDING_LARGE_PX};

  h4 {
    display: flex;
    align-items: center;
    span {
      font-weight: 400;
      font-size: 0.85rem;
      color: ${DANGER_COLOR_LIGHT};
      margin-left: 0.4rem;
    }
    .info {
      color: ${INFO_COLOR_LIGHT};
    }
  }

  input[type="text"],
  input[type="file"],
  input[type="number"] {
    background-color: transparent;
    margin: ${MARGING_SMALL_PX} 0;
    padding: ${pixelize(UNIT * 0.6)};
    width: 100%;
    border-bottom: 1px solid ${DEFAULT_BORDER_COLOR};
    text-align: left;
    transition: border 0.5s;
    border: 1px solid ${GRAY_4};
    border-radius: 4px;
    &:hover {
      border-color: ${GRAY_6};
    }
    &:focus {
      border-color: ${SECONDARY_COLOR};
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  .btn {
    width: 8rem;
  }
`;

const ExtraInfo = styled.div<{ column: number }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.column === 3 ? `40px 350px auto` : `40px 200px 200px auto`)};
  grid-gap: ${MARGING_LARGE_PX};
  align-items: center;
  margin-bottom: ${MARGING_SMALL_PX};
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  color: #fff;
  background-color: ${props => (props.color === "danger" ? DANGER_COLOR : PRIMARY_COLOR)};
`;

const TypeClassInfo = [
  { id: "character", name: "배역 (character)" },
  { id: "job", name: "직업 (job)" },
  { id: "instrument", name: "악기 (instrument)" }
];

export const RoleForm = () => {
  const [{ role }, dispatch] = useRoleStore(store => ({
    role: store.Role.role
  }));
  const [loading, setLoading] = useState<boolean>(false);

  const confirmModal = useToggle();

  const onError = (err: string, alertMsg: string, loadingAction: SetStateAction<any>) => {
    console.log(err);
    Toast.error(alertMsg, undefined, "top-center");
    loadingAction(false);
    return;
  };

  const onCreate = async () => {
    const { name, typeClass, validCheck, count, expose, selfRelation } = role;
    setLoading(true);
    try {
      const { data, errors } = await CreateRole({ name, typeClass, validCheck, count, expose, roleSelfRelation: selfRelation });
      if (errors && errors.length) {
        onError(errors[0].message, "역할 등록을 실패하였습니다.", setLoading);
      }
      if (data) {
        setLoading(false);
        Toast.primary("역할이 생성되었습니다.", undefined, "top-center");
        dispatch(RoleCreateActions.clearRole());
      }
    } catch (err) {
      onError(err, "역할 등록을 실패하였습니다.", setLoading);
    }
  };

  return (
    <Layout>
      <Title>
        <RoleIcon />
        <h2>역할 등록</h2>
        <span>*은 필수값입니다. 입력하지않으면 등록할 수 없습니다.</span>
      </Title>
      <CustomDivider />
      <RowGroup>
        <h4>역할 이름*</h4>
        <Input.Text
          key={role.name}
          isRequired
          placeholder="이름을 입력하세요."
          defaultValue={role.name}
          onBlur={text => dispatch(RoleCreateActions.setRoleName(text))}
        />
      </RowGroup>
      <RowGroup>
        <h4>클래스*</h4>
        <Input.TextSelect
          key={role.typeClass}
          className="autocomplete"
          optionList={TypeClassInfo}
          defaultValue={!role.typeClass ? undefined : TypeClassInfo.find(({ id }) => id === role.typeClass)}
          onBlur={info => {
            if (info) {
              dispatch(RoleCreateActions.setRoleTypeClass(info.id));
            }
          }}
        />
      </RowGroup>
      <RowGroup>
        <h4>역할 개수*</h4>
        <Input.Number
          key={role.count}
          isRequired
          min={0}
          max={20}
          step={1}
          defaultValue={0}
          value={role.count}
          onChange={num => dispatch(RoleCreateActions.setRoleCount(num))}
        />
      </RowGroup>
      <RowGroup>
        <h4>
          역할 관계<span className="info">생성하려는 역할에 포함될 역할을 추가해주세요.</span>
        </h4>
        {!role.selfRelation?.length
          ? null
          : role.selfRelation.map((item, index) => {
              const uuid = uuidv4();
              return (
                <ExtraInfo key={uuid} column={3}>
                  <RoundButton color="danger" onClick={() => dispatch(RoleCreateActions.deleteRoleSelfRelation(index))}>
                    -
                  </RoundButton>
                  <Input.TextSelect
                    key={item.typeFunctionId}
                    className="autocomplete"
                    optionList={RoleTypeFunctionInfo}
                    defaultValue={
                      !item.typeFunctionId || !item.typeFunctionName ? undefined : { id: item.typeFunctionId, name: item.typeFunctionName }
                    }
                    onBlur={info => {
                      if (info) {
                        dispatch(RoleCreateActions.updateRoleSelfRelationTypeFuncion(index, info.id, info.name));
                      }
                    }}
                  />
                  <RoleTextAutoComplete
                    key={item.id}
                    defaultValue={!item.id || !item.name ? undefined : { id: item.id, name: item.name }}
                    onChange={info => {
                      if (info) {
                        dispatch(RoleCreateActions.updateRoleSelfRelationRole(index, info.id, info.name));
                      }
                    }}
                  />
                </ExtraInfo>
              );
            })}
        <RoundButton color="primary" onClick={() => dispatch(RoleCreateActions.createRoleSelfRelation())}>
          +
        </RoundButton>
      </RowGroup>
      <ButtonGroup>
        <Input.Button className="btn" color="primary" disabled={!role.name || !role.typeClass} onClick={confirmModal.on}>
          등록
        </Input.Button>
      </ButtonGroup>
      <Modal isOpen={confirmModal.isToggled}>
        <Confirm title="알림" context="역할을 생성하시겠습니까?" toSave={onCreate} toClose={confirmModal.off} />
      </Modal>
      <Loading loading={loading} />
    </Layout>
  );
};
