/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, Mutation, StringScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";

type Option = {
  title: string;
  class: string;
  subClass: string;
  artist: string;
  role: string;
  company: string;
};

export const CreateVideo = async (option: Option) => {
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $artistId = <Variable name="artistId" scalar={<IdScalar />} value={option.artist} />;
  const $roleId = <Variable name="roleId" scalar={<IdScalar />} value={option.role} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.company} />;
  const $rightsCompany = <MetadataRightScalar isNullable value={MetadataRightType.rightCompany} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA">
      <SelectionSet
        name="createMetadata"
        argument={{
          data: {
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                }
              ]
            },
            metadata_artist_relation: {
              create: [
                {
                  artist: {
                    connect: {
                      artist_id: $artistId
                    }
                  },
                  role: {
                    connect: {
                      role_id: $roleId
                    }
                  },
                  exposure_order: 0
                }
              ]
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $rightsCompany,
                  exposure_order: 0
                }
              ]
            }
          }
        }}
      >
        <Field name="metadata_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createMetadata: Readonly<{
      metadata_id: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
