import React from "react";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { GRAY_2, GRAY_6, GRAY_1 } from "constants/baseColor";
import { Input } from "App/Atomics/Input";

type Props = {
  onEdit: () => void;
  onDelete: () => void;
  toClose: () => void;
};

const Layout = styled.div`
  width: 20rem;
  height: 10rem;
`;

const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 10%;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  svg {
    position: absolute;
    right: 3%;
    width: 35px;
    height: 35px;
    padding: 0.5rem;
    fill: black;
    border-radius: 50%;
    background-color: ${GRAY_2};
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
      background-color: ${GRAY_1};
    }
  }
`;

const ButtonGroup = styled.div`
  height: 6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .btn {
    width: 5rem;
  }
`;

export const SelectEditTypeModal = ({ onEdit, onDelete, toClose }: Props) => (
  <Layout>
    <Header>
      <h3>장르 이미지 변경</h3>
      <CancelIcon onClick={toClose} />
    </Header>
    <ButtonGroup>
      <Input.Button className="btn" color="success" onClick={onEdit}>
        편집
      </Input.Button>
      <Input.Button className="btn" color="danger" onClick={onDelete}>
        삭제
      </Input.Button>
    </ButtonGroup>
  </Layout>
);
