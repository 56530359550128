import { combineReducers, createStore } from "lib/store/v3";
import { useAsyncEffect } from "lib/use-async-effect";
import ms from "ms.macro";
import React, { Fragment, useRef } from "react";
import styled from "styled-components";
import { createHash, Hash } from "utils/createHash";
import { createProgressActions } from "./createProgressActions";
import { PRIMARY_COLOR } from "constants/color";

export type Progress = {
  readonly add: (percent: number) => void;
  readonly moveTo: (percent: number) => void;
  readonly setGuard: (hasGuard: boolean) => void;
  readonly clear: () => void;
};

export type Callback<T> = (progress: Progress) => Promise<T>;

export const createProgress = () => {
  const ProgressActions = createProgressActions();

  const { getState, dispatch, useSelector } = createStore(combineReducers({ Progress: ProgressActions }));
  const clear = (hash: Hash): void => dispatch(ProgressActions.clear(hash));
  const current = (hash: Hash): void => dispatch(ProgressActions.current(hash));
  const setGuard = (hash: Hash, setGuard: boolean): void => dispatch(ProgressActions.setGuard(hash, setGuard));
  const on = (hash: Hash): void => dispatch(ProgressActions.on(hash));
  const add = (hash: Hash, percent: number): void => dispatch(ProgressActions.add(hash, percent));
  const moveTo = (hash: Hash, percent: number): void => dispatch(ProgressActions.moveTo(hash, percent));

  const isVisible = (): boolean => getState().Progress.isVisible;

  async function start<T = void>(percent: number, callback: Callback<T>): Promise<T> {
    return startTransaction<T>(percent, false, callback);
  }

  async function transaction<T = void>(percent: number, callback: Callback<T>): Promise<T> {
    return startTransaction<T>(percent, true, callback);
  }

  async function startTransaction<T>(percent: number, hasGuard: boolean, callback: (progress: Progress) => T | Promise<T>): Promise<T> {
    const hash = createHash();
    current(hash);
    moveTo(hash, percent);
    setGuard(hash, hasGuard);

    return new Promise<T>(async (resolve, reject) => {
      const timer = window.setTimeout(() => on(hash), ms("500ms"));

      const end = () => clear(hash);

      try {
        const ret = await callback({
          add: (percent: number): void => add(hash, percent),
          moveTo: (percent: number): void => moveTo(hash, percent),
          setGuard: (hasGuard: boolean): void => setGuard(hash, hasGuard),
          clear: end
        });
        resolve(ret);
        window.clearTimeout(timer);
      } catch (error) {
        reject(error);
      }

      moveTo(hash, 99);

      window.setTimeout(end, ms("1s"));
    });
  }

  const useProgress = () => useSelector(store => store.Progress);

  const Top = () => <ProgressBar position="top" />;
  const Bottom = () => <ProgressBar position="bottom" />;

  type ProgressBarProps = {
    readonly position: "top" | "bottom";
  };

  const ProgressBar = ({ position }: ProgressBarProps) => {
    const { hash, isVisible, hasGuard } = useSelector(({ Progress: { hash, isVisible, hasGuard } }) => ({ hash, isVisible, hasGuard }));
    if (!isVisible) {
      return null;
    }

    return (
      <Fragment key={hash ?? undefined}>
        <Layout data-position={position}>
          <Progress />
        </Layout>
        {hasGuard && (
          <TouchGuardLayout>
            <TouchGuard />
          </TouchGuardLayout>
        )}
      </Fragment>
    );
  };

  const Progress = () => {
    const currentPercent = useSelector(store => store.Progress.percent);
    const ref = useRef<HTMLDivElement>(null);

    const updateProgressBarPercent = async (isMounted: () => boolean): Promise<void> => {
      let percent = currentPercent;

      const updatePercent = (): void => {
        if (isMounted() && ref.current) {
          percent = Math.min(percent * 1.001, 100);
          ref.current.style.width = `${percent}%`;
          if (percent < 100) {
            window.setTimeout(updatePercent, ms("0.05s"));
          }
        }
      };
      window.setTimeout(updatePercent);
    };
    useAsyncEffect(updateProgressBarPercent, [currentPercent]);

    return <Bar ref={ref} />;
  };

  return { isVisible, start, transaction, Top, Bottom, useProgress };
};

const Layout = styled.div`
  z-index: 1000;
  position: absolute;
  right: 0;
  left: 0;

  &[data-position="top"] {
    top: 0;
  }

  &[data-position="bottom"] {
    top: 100%;
  }
`;

const Bar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  width: 0;

  border-bottom: 2px solid ${PRIMARY_COLOR};

  transition-property: width;
`;

const TouchGuardLayout = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

const TouchGuard = styled.div`
  cursor: not-allowed;
  pointer-events: none;

  width: 100%;
  height: 100%;

  background-color: hsl(0 0% 0% / 10%);
`;
