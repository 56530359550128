import React, { ReactElement, useRef } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { GoodsDetailStore } from "App/Routes/GoodsDetail/Store";
import { GoodsDetailActions } from "App/Routes/GoodsDetail/Store/GoodsDetail";

type Props = {
  readonly barcode?: string;
};

export const GoodsOfficialBarcode = ({ barcode }: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Style.Row>
      <Style.Caption>공식 바코드</Style.Caption>
      <Style.SubCaption>- KMCA 로그 연동 규격서 참조</Style.SubCaption>
      <Style.InputText
        ref={inputRef}
        placeholder={"바코드"}
        defaultValue={barcode}
        onBlur={value => GoodsDetailStore.dispatch(GoodsDetailActions.setOfficialBarcode(value))}
      />
    </Style.Row>
  );
};
