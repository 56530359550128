/** @jsx jsx */

import { ValidType } from "constants/ValidType";
import { jsx, render, Query, SelectionSet, Field, Variable, StringScalar, ListScalar, EnumScalar, IntScalar, IdScalar } from "graphql-jsx";
import { ValidTypeScalar } from "GraphQL/Scalars";

import { clients } from "utils/clients";

export const findCompanyList = async (keyword: string, weight?: number) => {
  let $keyword = <Variable name="keyword" scalar={<StringScalar isNullable={false} />} value={keyword} />;
  let $id = undefined;
  if (keyword.startsWith("$")) {
    const searchId = keyword.match(/\$([0-9]+)/)?.[1];
    if (searchId) {
      $id = <Variable name="id" scalar={<IdScalar />} value={searchId} />;
    }
  }
  const $weight = <Variable name="weight" scalar={<IntScalar isNullable />} value={weight} />;
  const $v2 = <ValidTypeScalar value={ValidType.V2} />;
  const { query, variables } = render(
    <Query operationName="COMPANY_SEARCHED_LIST">
      <SelectionSet
        name="company"
        alias="list"
        argument={{
          group: true,
          first: 40,
          orderBy: <ListScalar scalar={<EnumScalar type="CompanyOrderByInput" items={["name__ASC"]} />} value={["name__ASC"]} />,
          where: {
            valid_check__not: $v2,
            weight: $weight,
            ...($id
              ? {
                  company_id: $id
                }
              : {
                  name__contains: $keyword
                })
          }
        }}
      >
        <Field name="company_id" alias="id" />
        <Field name="name" />
        <Field name="type" />
        <Field name="license" />
        <Field name="group_path" alias="path" />
      </SelectionSet>
    </Query>
  );

  type Data = Readonly<{
    list: ReadonlyArray<{
      id: string;
      name: string;
      type: string;
      license: string;
      path: string;
    }>;
  }>;
  const { data, errors } = await clients.company.request<Data>(query, variables);
  if (!data || errors) {
    return [];
  }
  const result = data.list;
  return result;
};
