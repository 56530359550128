import React from "react";
import styled from "styled-components";
import { pixelize, UNIT, PADDING_XX_LARGE_PX, MARGING_X_LARGE_PX, UNIT_PX, MARGING_LARGE_PX } from "constants/size";
import { Input } from "App/Atomics/Input";
import { useRightsSelector } from "../../Store";
import { Divider } from "App/Atomics/Divider";

type Props = Readonly<{
  toCreate: () => void;
  toCancel: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: ${pixelize(UNIT * 38)};
  height: ${pixelize(UNIT * 28)};
  padding: ${PADDING_XX_LARGE_PX};

  section {
    margin-bottom: ${MARGING_X_LARGE_PX};
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

const RowGroup = styled.div`
  display: grid;
  min-height: ${pixelize(UNIT * 2.25)};
  max-height: ${pixelize(UNIT * 5)};
  grid-template-columns: 15% 85%;
  grid-gap: ${UNIT_PX};
  align-items: center;
  margin-bottom: ${MARGING_LARGE_PX};
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;
  gap: ${MARGING_LARGE_PX};
  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

export const SubmitModal = ({ toCreate, toCancel }: Props) => {
  const { rights } = useRightsSelector(store => store.Rights);
  const { rightsType, name, license, displayName } = rights;
  return (
    <Layout>
      <h2>권리사 입력 정보</h2>
      <Divider />
      <RowGroup>
        <h4>이름</h4>
        <span>{name}</span>
      </RowGroup>
      <RowGroup>
        <h4>표기명</h4>
        <span>{displayName}</span>
      </RowGroup>
      <RowGroup>
        <h4>타입</h4>
        <span>{rightsType === "vl" ? "유통사" : rightsType}</span>
      </RowGroup>
      <RowGroup>
        <h4>라이센스</h4>
        <span>{license}</span>
      </RowGroup>
      <ButtonGroup>
        <Input.Button color="primary" onClick={toCreate}>
          생성
        </Input.Button>
        <Input.Button color="danger" onClick={toCancel}>
          취소
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
