import styled, { keyframes } from "styled-components";

const slide = keyframes`
  0% {
    opacity: 0
    transform: translate3d(-200px, 0, 0) rotate(-20deg);
  }
  50% {
    transform: translate3d(-100px, 0, 0) rotate(0deg);
  }
  100% {
    opacity: 1
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
`;

export const Layout = styled.div`
  padding: 1rem;
  margin: 0.5rem 0 1.5rem;
  background: #eee; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffffff, #eee); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffffff, #eee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: 5px 5px 15px #eee;
  border-radius: 8px;
  animation: ${slide} 0.5s ease-in;
  .no-data {
    padding: 1rem 0.5rem 0.5rem;
  }
`;

export const RowTitle = styled.h3`
  margin-left: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const RowGroup = styled.div`
  display: table;
  width: 100%;
  height: 4rem;
  padding: 0.5rem;
  table-layout: fixed;
  transition: background 0.1s;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  .thumb {
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 4.5rem;
    height: 4rem;
    img {
      width: 3.8rem;
      height: 3.8rem;
      border-radius: 0.2rem;
    }
  }
  .info_area {
    display: table-cell;
    vertical-align: middle;
    .title {
      overflow: hidden;
      --webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      word-break: break-all;
      display: --webkit-box;
      font-size: 1.05rem;
    }
    .artist,
    .company {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      margin-top: 3px;
      font-size: 0.9rem;
      color: #999;
      transition: all 0.2s;
    }
  }
`;
