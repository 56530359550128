/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";

type Option = Readonly<{
  genreId: string;
  typeTitle: string;
  value: string;
  languageCode: string;
  order: number;
}>;

export const CreateGenreTitleRelation = async (option: Option) => {
  const $genreId = <Variable name="genre_id" scalar={<IdScalar />} value={option.genreId} />;
  const $typeTitle = <Variable name="type_title" scalar={<StringScalar isNullable={false} />} value={option.typeTitle} />;
  const $value = <Variable name="value" scalar={<StringScalar isNullable={false} />} value={option.value} />;
  const $languageCode = <Variable name="language_code" scalar={<IdScalar />} value={option.languageCode} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_GENRE_TITLE_RELATION">
      <SelectionSet
        name="updateGenre"
        alias="createGenreTitleRelation"
        argument={{
          where: {
            genre_id: $genreId
          },
          data: {
            _onlySearch: true,
            genre_title: {
              create: [
                {
                  type_genre_title: $typeTitle,
                  language: { connect: { language_code: $languageCode } },
                  value: $value,
                  exposure_order: $order
                }
              ]
            }
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <SelectionSet name="genre_title">
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createGenreTitleRelation: {
      id: string;
      genre_title: {
        uuid: string;
        order: number;
      }[];
    };
  }>;

  return await clients.genre.request<Data>(query, variables);
};
