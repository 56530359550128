/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, EnumScalar, ListScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
type Option = Readonly<{
  trackIdList: string[];
}>;

export const GetTrackInfo = async (option: Option) => {
  const $trackIdList = (
    <Variable name="track_id_list" scalar={<ListScalar scalar={<IdScalar isNullable={false} />} />} value={option.trackIdList} />
  );
  const { query, variables } = render(
    <Query operationName="GET_TRACK_INFO">
      <SelectionSet
        name="metadata"
        argument={{
          group: true,
          first: 9999,
          where: {
            metadata_id__in: $trackIdList,
            type_metadata_class__in: ["record", "theater"],
            type_metadata_subclass: "track"
          }
        }}
      >
        <Field name="metadata_id" alias="id" />
        <Field name="title" />
        <SelectionSet
          name="metadata_artist_relation"
          alias="artistRelation"
          argument={{
            first: 1,
            orderBy: (
              <EnumScalar type="Metadata_artist_relationOrderByInput" value={"exposure_order__ASC"} items={["exposure_order__ASC"]} />
            )
          }}
        >
          <Field name="id" alias="uuid" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadata: {
      id: string;
      title: string;
      artistRelation: {
        uuid: string;
        artist: {
          id: string;
          name: string;
        }[];
      }[];
    }[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors) {
    throw errors;
  }
  const trackInfo = data!.metadata.map(({ id, title, artistRelation }) => ({ id, title, artist: artistRelation[0].artist[0].name }));
  return trackInfo;
};
