import React, { ReactNode } from "react";
import styled from "styled-components";

import { GRAY_5 } from "constants/baseColor";

type Props = Styleable & Readonly<{ children: ReactNode }>;

const Layout = styled.tfoot`
  border-top: 2px solid ${GRAY_5};

  tr {
    border-bottom: 1px solid ${GRAY_5};
  }
`;

export const Footer = ({ children, style, className }: Props) => (
  <Layout style={style} className={className}>
    {children}
  </Layout>
);
