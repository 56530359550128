import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { ArtistActions as Artist } from "./Artist";
import { ArtistInfoActions as ArtistInfo } from "./ArtistInfo";

const reducers = { Artist, ArtistInfo };
const reducer = combineReducers(reducers, createStoreLogger("Artist"));

export const {
  StoreProvider: ArtistsStoreProvider,
  useStore: useArtistsStore,
  useSelector: useArtistsSelector,
  useDispatch: useArtistsDispatch
} = createStore(reducer);
