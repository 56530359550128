import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { DANGER_COLOR } from "constants/color";
import { useEffectCreateStore } from "App/Routes/EffectCreate/Store";
import { Effect, EffectCreateActions } from "../../../Store/Effect";
import { Modal } from "lib/modal";
import { TagModal } from "App/Organisms/TagModal";
import { useToggle } from "lib/use-toggle";
import itiriri from "itiriri";
import { Toast } from "lib/toast";
import { EffectStyle } from "App/Routes/EffectCreate/style";

type Props = {
  index: number;
  onClose: () => void;
};

export const EffectForm = ({ index, onClose }: Props) => {
  const [{ initialEffect }, dispatch] = useEffectCreateStore(store => ({
    initialEffect: store.EffectCreate.tracks[index]
  }));
  const tagModal = useToggle();
  const [effect, setEffect] = useState<Effect>(initialEffect);

  const setTrackFile = (event: ChangeEvent<HTMLInputElement>, fileType: string) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = e => {
      const trackFile = {
        file: file.files![0],
        ext: fileType === "mp3high" ? "mp3" : "wav",
        typeUrl: fileType,
        url: file.files![0].name,
        data: e.target?.result as string
      };
      switch (fileType) {
        case "mp3high":
          setEffect(prev => ({ ...prev, trackUrl: { trackMp3: trackFile, trackWav: prev.trackUrl.trackWav } }));
          break;
        case "wav":
          setEffect(prev => ({ ...prev, trackUrl: { trackMp3: prev.trackUrl.trackMp3, trackWav: trackFile } }));
          break;
        default:
          break;
      }
    };
  };

  const updateGenre: Parameters<typeof TagModal>[0]["onSave"] = (genreList, moodList) => {
    if (genreList) {
      const genre = genreList
        .map(genre => Object.assign(genre, { typeKind: "genre" }))
        .concat(moodList.map(mood => Object.assign(mood, { typeKind: "mood" })));
      setEffect(prev => ({ ...prev, genre }));
    }
    tagModal.off();
  };

  const onAddEffect = () => {
    if (!effect.title?.length) {
      Toast.error("효과음 제목을 입력해주세요.");
      return;
    } else if (!effect.trackUrl.trackMp3?.url && !effect.trackUrl.trackWav?.url) {
      Toast.error("최소 한 종류의 파일을 등록하셔야 합니다.");
      return;
    } else if (!effect.record) {
      Toast.error("녹음 연도를 입력해주세요.");
      return;
    }

    dispatch(EffectCreateActions.updateAlbumEffect({ index, effect }));
    onClose();
  };

  return (
    <Layout>
      <EffectStyle.BoxForm>
        <span className="album_caption">
          효과음 제목
          <RequireStar />
        </span>
        <p className="album_notice">앨범 안에 들어갈 효과음의 제목입니다.</p>
        <EffectStyle.InputText
          width={"600"}
          placeholder="제목"
          defaultValue={effect.title}
          onChange={val => setEffect(prev => ({ ...prev, title: val }))}
        />
      </EffectStyle.BoxForm>
      <EffectStyle.BoxForm>
        <span className="album_caption">장르 / 무드</span>
        <p className="album_notice">우측 버튼으로 검색 후, 추가 시 자동으로 입력됩니다.</p>
        <EffectStyle.RowContainer>
          <EffectStyle.InputText
            width={"600"}
            isDisabled
            placeholder="장르를 선택해주세요"
            value={
              effect.genre
                ? itiriri(effect.genre)
                    .toArray(({ name }) => name)
                    .join(", ")
                : ""
            }
          />
          <EffectStyle.SearchButton color="warning" onClick={tagModal.on}>
            검색
          </EffectStyle.SearchButton>
        </EffectStyle.RowContainer>
      </EffectStyle.BoxForm>
      <EffectStyle.BoxForm>
        <span className="album_caption">
          녹음 <RequireStar /> / 발매 / 초판 연도
        </span>
        <EffectStyle.RowContainer>
          <EffectStyle.InputText
            width={"100"}
            className="input-text"
            placeholder="녹음연도를 입력하세요."
            defaultValue={effect.record}
            onChange={year => setEffect(prev => ({ ...prev, record: year }))}
          />
          <EffectStyle.InputText
            width={"100"}
            className="input-text"
            placeholder="발매연도를 입력하세요."
            defaultValue={effect.publish}
            onChange={year => setEffect(prev => ({ ...prev, publish: year }))}
          />
          <EffectStyle.InputText
            width={"100"}
            className="input-text"
            placeholder="초판연도를 입력하세요."
            defaultValue={effect.edition}
            onChange={year => setEffect(prev => ({ ...prev, edition: year }))}
          />
        </EffectStyle.RowContainer>
      </EffectStyle.BoxForm>
      <EffectStyle.BoxForm>
        <span className="album_caption">
          MP3 파일
          <RequireStar />
        </span>
        <p className="album_notice">MP3, WAV 둘 중 하나의 음원은 반드시 추가해야합니다.</p>
        <EffectStyle.InputFileForm>
          <input id="upload-mp3" type="file" accept="audio/mpeg" onChange={e => setTrackFile(e, "mp3high")} />
          {effect.trackUrl.trackMp3?.url && <span className="file-text">{effect.trackUrl.trackMp3.url}</span>}
          <label htmlFor="upload-mp3">{!effect.trackUrl.trackMp3?.url ? "등록" : "수정"}</label>
        </EffectStyle.InputFileForm>
      </EffectStyle.BoxForm>

      <EffectStyle.BoxForm>
        <span className="album_caption">
          WAV 파일
          <RequireStar />
        </span>
        <p className="album_notice">MP3, WAV 둘 중 하나의 음원은 반드시 추가해야합니다.</p>
        <EffectStyle.InputFileForm>
          <input id="upload-wav" type="file" accept="audio/wav" onChange={e => setTrackFile(e, "wav")} />
          {effect.trackUrl.trackWav?.url && <span className="file-text">{effect.trackUrl.trackWav.url}</span>}
          <label htmlFor="upload-wav">{!effect.trackUrl.trackWav?.url ? "등록" : "수정"}</label>
        </EffectStyle.InputFileForm>
      </EffectStyle.BoxForm>
      <EffectStyle.ButtonGroup>
        <button onClick={onAddEffect}>효과음 수정</button>
      </EffectStyle.ButtonGroup>
      <Modal isOpen={tagModal.isToggled} onClose={tagModal.off}>
        <TagModal
          genreList={effect.genre?.filter(({ typeKind }) => typeKind === "genre").map(({ id, name }) => ({ id, name })) ?? []}
          moodList={
            effect.genre
              ?.filter(({ typeKind }) => typeKind === "mood")
              .map(({ id, name, relationList }) => ({ id, name, relationList: relationList! })) ?? []
          }
          onSave={updateGenre}
          onCancel={tagModal.off}
        />
      </Modal>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 2rem;
  position: relative;

  .txt_star {
    color: ${DANGER_COLOR};
    font-style: normal;
  }

  .input-text {
    margin-right: 4px;
  }
`;

const RequireStar = () => <em className="txt_star">*</em>;
