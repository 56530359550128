import React, { useState } from "react";

import { TextAutoComplete } from "App/Atomics/AutoComplete";
import { findMoodList } from "./findMoodList";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";

type Props = Pick<
  Parameters<typeof TextAutoComplete>[0],
  "style" | "className" | "classNamePrefix" | "isDisabled" | "isClearable" | "defaultValue" | "defaultOptions" | "onChange" | "onBlur"
> &
  Readonly<{ isParent?: boolean }>;

export const MoodTextAutoComplete = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  defaultValue,
  defaultOptions,
  onChange,
  onBlur,
  isParent,
  isClearable
}: Props) => {
  const [initialOptions, setInitialOptions] = useState<Info[] | undefined>(defaultOptions);

  const onFocus = async () => {
    const info = await findMoodList({ keyword: "", isParent });
    setInitialOptions(info);
  };

  return (
    <TextAutoComplete
      placeholder="무드를 입력해주세요."
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      defaultOptions={initialOptions}
      delay={200}
      style={style}
      className={className}
      classNamePrefix={classNamePrefix}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findMoodList({ keyword, isParent })}
      isClearable={isClearable}
    />
  );
};
