import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { BLUE_6, GRAY_6 } from "constants/baseColor";
import { ValidTypeTextSelect } from "App/Molecules/Selects/ValidType";
import { ValidType } from "constants/ValidType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { Toast } from "lib/toast";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { Loading } from "App/Atomics/Loading";
import { usePlaylistsStore } from "App/Routes/Playlists/Store";
import { ServiceType } from "constants/ServiceType";
import { UpdatePlaylist } from "GraphQL/Queries/Playlist";
import { ServiceTypeTextSelect } from "App/Molecules/Selects/ServiceType";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";

type Props = {
  index: number;
  toClose: () => void;
};

type PlaylistInfo = {
  name: string;
  kind: string;
  service: ServiceType;
  validCheck: ValidType;
};

export const EditModal = ({ index, toClose }: Props) => {
  const [playlist, dispatch] = usePlaylistsStore(store => store.Playlist.playlists[index]);
  const [loading, setLoading] = useState<boolean>(false);
  const [playlistInfo, setPlaylistInfo] = useState<PlaylistInfo>({
    name: playlist.name,
    kind: playlist.kind,
    service: playlist.service as ServiceType,
    validCheck: playlist.validCheck
  });
  const titleUuid = playlist.playlistTitle[0]?.uuid;

  const onSave = useCallback(
    async (targetId: string, playlistInfo: PlaylistInfo) => {
      setLoading(true);
      const { name, kind, service, validCheck } = playlistInfo;
      try {
        const accessId = await requestAccessRecord({ targetId, targetTable: TargetTableInput.Playlist });
        if (accessId) {
          try {
            const { data, errors } = await UpdatePlaylist({ id: targetId, name, kind, service, validCheck, titleUuid });
            if (errors) {
              throw new Error(errors[0].message);
            }
            if (data) {
              dispatch([
                PlaylistsActions.editPlaylistNameByIndex({ index, name }),
                PlaylistsActions.editPlaylistKindByIndex({ index, kind }),
                PlaylistsActions.editPlaylistServiceByIndex({ index, service }),
                PlaylistsActions.updatePlaylistValidCheck({ index, validCheck })
              ]);
              Toast.primary("업데이트되었습니다.", undefined, "top-center");
              toClose();
            }
          } catch (err) {
            Toast.error("업데이트에 실패하였습니다.", undefined, "top-center");
            console.log(err);
            return;
          } finally {
            await DeleteAccessRecord({ id: accessId });
            setLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        Toast.error("권한 신청에 실패하였습니다.", undefined, "top-center");
        setLoading(false);
        return;
      }
    },
    [dispatch, index, titleUuid, toClose]
  );

  return (
    <Layout>
      <Header>
        <h3>플레이리스트 편집</h3>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <Section>
        <span>종류</span>
        <Input.Text
          className="input-text"
          placeholder="플레이리스트 종류를 입력해주세요."
          defaultValue={playlistInfo.kind}
          onBlur={text => setPlaylistInfo(prev => ({ ...prev, kind: text }))}
        />
        <span>이름</span>
        <Input.Text
          className="input-text"
          placeholder="플레이리스트 이름을 입력해주세요."
          defaultValue={playlistInfo.name}
          onBlur={text => setPlaylistInfo(prev => ({ ...prev, name: text }))}
        />
        <span>유효성</span>
        <ValidTypeTextSelect
          defaultValue={playlistInfo.validCheck}
          style={selectStyle}
          onBlur={vc => {
            if (vc) {
              setPlaylistInfo(prev => ({ ...prev, validCheck: vc }));
            }
          }}
        />
        <span>서비스 타입</span>
        <ServiceTypeTextSelect
          placeholder="서비스를 선택해주세요"
          defaultValue={playlistInfo.service}
          style={selectStyle}
          onBlur={value => {
            if (value) {
              setPlaylistInfo(prev => ({ ...prev, service: value }));
            }
          }}
        />
      </Section>
      <ButtonGroup>
        <Input.Button onClick={toClose}>취소</Input.Button>
        <Input.Button
          color="primary"
          disabled={!playlistInfo.name || !playlistInfo.kind || !playlistInfo.validCheck}
          onClick={() => onSave(playlist.id, playlistInfo)}
        >
          저장
        </Input.Button>
      </ButtonGroup>
      <Loading loading={loading} />
    </Layout>
  );
};

const Layout = styled.div`
  width: 765px;
  height: 520px;
  font-size: 0.8rem;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    color: #191919;
    margin: 1rem 0.2rem 0.5rem;
  }

  .input-text {
    border: 1px solid hsl(210 6% 83%);
    padding: 0.6rem;
    transition: border-color 0.15s;
    &:hover {
      border-color: hsl(210 6% 70%);
    }
    &:focus {
      border-color: ${BLUE_6};
      box-shadow: 0px 0px 0px 1.2px ${BLUE_6};
    }
  }
`;

const ButtonGroup = styled.div`
  height: 5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 1rem 0;
`;

const selectStyle = {
  control: (base: any) => ({
    ...base,
    border: "1px solid hsl(210 6% 83%)",
    padding: "0.1rem"
  })
};
