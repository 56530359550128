import styled, { css } from "styled-components";
import { Input } from "App/Atomics/Input";
import { AC_BLACK, DANGER_COLOR, PRIMARY_COLOR_LIGHT } from "constants/color";
import { BLUE_7, BLUE_8, CYAN_8, WHITE } from "constants/baseColor";

const Title = styled.div`
  display: block;
  padding-bottom: 30px;
  font-size: 26px;
  font-weight: bold;
  color: #333d4b;
  word-break: keep-all;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const Caption = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;
  font-size: 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
  color: ${({ color }) => color ?? AC_BLACK};
`;

const SubCaption = styled.span`
  font-size: 0.85rem;
  margin-bottom: 2px;
  color: ${CYAN_8};
`;

const AlertCaption = styled.span`
  display: none;
  overflow: hidden;
  font-size: 0.85rem;
  color: ${DANGER_COLOR};
  margin-top: 4px;
  transition: all 0.15s;
  &[data-visible="true"] {
    display: block;
  }
`;

const Category = styled.div``;
const InputStyle = css`
  position: relative;
  width: 100%;
  height: 44px;
  margin-top: 4px;
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #cdcdcd;
  border: none;
  border-radius: 8px;
  line-height: 22px;
  padding: 1rem;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: inset 0 0 0 2px #adadad;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px ${PRIMARY_COLOR_LIGHT};
  }
`;
const InputText = styled(Input.Text)`
  ${InputStyle};
`;

const InputTextArea = styled.textarea`
  ${InputStyle};
  height: auto;
  min-height: 120px;
  &::placeholder {
    color: #cfcfcf;
  }
`;

const Inputs = styled.input`
  ${InputStyle};
`;
const InputNumber = styled.input`
  ${InputStyle};
  width: 120px;
  text-align: right;
`;

const Select = styled(Input.TextSelect)`
  margin-top: 2px;
`;

const CreatableSelect = styled(Input.CreatableTextSelect)`
  margin-top: 2px;
`;

const ColContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchButton = styled(Input.Button)`
  margin-left: 4px;
  margin-top: 3px;
  height: 42px;
  background-color: #ffdc00 !important;
  color: #000 !important;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconButton = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${BLUE_8};
  color: ${WHITE};
  transition: background 0.15s;
  cursor: pointer;
  &:hover {
    background: ${BLUE_7};
  }
  & + & {
    margin-left: 8px;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${WHITE};
  }
`;

const InputFileForm = styled.form`
  & + & {
    margin-left: 4px;
  }
  input[type="file"] {
    display: none;
  }
  label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    height: 40px;
    background-color: #ffdc00;
    padding: 0 6px;
    font-size: 0.9rem;
  }
  .file-text {
    padding: 1rem;
    border: 1px solid #cdcdcd;
    display: inline-block;
    margin: 4px 0;
  }
`;

const RoundButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  padding-top: 2px;
  color: ${props => props.color};
  background-color: #fff;
  border: 1.5px solid ${props => props.color}};
  margin-right: 8px;
  &:hover {
    background-color: hsl(255, 0%, 97%);
  }
`;

export const SelectStyle = {
  container: (base: any) => ({
    ...base
  }),
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 44,
    boxShadow: `inset 0 0 0 1px #cdcdcd`,
    border: "none",
    borderRadius: "8px",
    ":hover": {
      boxShadow: `inset 0 0 0 2px  ${state.isFocused ? PRIMARY_COLOR_LIGHT : "#adadad"}`
    }
  }),
  placeholder: (base: any) => ({
    ...base,
    color: "#cfcfcf"
  })
};

export const Style = {
  Title,
  Row,
  Caption,
  SubCaption,
  AlertCaption,
  InputText,
  InputTextArea,
  Inputs,
  InputNumber,
  Select,
  CreatableSelect,
  Category,
  SearchButton,
  RowContainer,
  ButtonGroup,
  IconButton,
  InputFileForm,
  ColContainer,
  RoundButton
};
