import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { GRAY_4, WHITE, VIOLET_4, VIOLET_2, VIOLET_3 } from "constants/baseColor";
import { BORDER_RADIUS_PX, pixelize, UNIT, PADDING_SMALL_PX } from "constants/size";
import { PRIMARY_COLOR, SECONDARY_COLOR_LIGHT, PRIMARY_COLOR_LIGHT } from "constants/color";
import { mediaQuery } from "constants/media";
import { Helmet } from "App/Atomics/Helmet";
import { useHistory } from "react-router";
import { IdIsExist, CreateUser } from "GraphQL/Queries";
import { SIGN } from "constants/route";

const Layout = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  min-width: ${pixelize(UNIT * 20)};
  align-items: center;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7vh;
  max-height: ${pixelize(UNIT * 3.5)};
  width: 100%;
  background-image: linear-gradient(187deg, rgb(106, 95, 221) 38%, rgb(36, 29, 140) 100%);
  color: #fff;
  font-family: Rubik;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;
`;

const SignUpForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1rem;
  width: 80vw;
  min-width: ${pixelize(UNIT * 21)};
  max-width: ${pixelize(UNIT * 24)};
  grid-gap: 1rem;

  input {
    width: 100%;
    height: 3em;
    font-size: 0.9rem;
    border: 1px solid ${GRAY_4};
    border-radius: ${BORDER_RADIUS_PX};
    transition: all 0.1s;
    &:focus {
      border-color: ${PRIMARY_COLOR};
    }
    background-color: ${WHITE};
  }

  .title {
    text-align: center;
    margin-top: 1rem;
    height: 7vh;
    font-weight: 400;
    font-size: 2.5rem;
    color: ${PRIMARY_COLOR_LIGHT};
    text-shadow: 0 1.5px 1.5px ${PRIMARY_COLOR_LIGHT};
    ${mediaQuery(768)} {
      font-size: 2rem;
    }
    ${mediaQuery(375)} {
      font-size: 1.5rem;
    }
  }

  .bar {
    width: 100%;
    height: 3px;
    background-color: ${PRIMARY_COLOR_LIGHT};
    color: ${PRIMARY_COLOR_LIGHT};
    border: none;
  }

  .alert-password {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 0.7em;
    color: ${SECONDARY_COLOR_LIGHT};
    text-align: left;
  }

  .alert-signup {
    width: 100%;
    text-align: right;
    font-size: 0.9rem;
    button {
      color: ${PRIMARY_COLOR};
      font-weight: bold;
      &:hover {
        color: ${PRIMARY_COLOR_LIGHT};
      }
    }
  }
`;

const LabelGroup = styled.div`
  width: 100%;
  display: grid;
  font-size: 0.9rem;
  grid-gap: 0.5rem;
`;

const CheckInputContainer = styled.div`
  width: 100%;
  position: relative;
  .checkbtn {
    color: ${VIOLET_4};
    font-size: 0.8rem;
    position: absolute;
    right: 2%;
    bottom: 19%;
    z-index: 1;
    background: ${WHITE};
    border-radius: 8px;
    padding: ${PADDING_SMALL_PX};
    border: 1px solid ${VIOLET_2};
    transition: all 0.2s;
    &:hover {
      box-shadow: 0 0 4px ${VIOLET_2};
    }
    &:active {
      box-shadow: 0 0 4px ${VIOLET_3};
      transform: translateY(1.5px);
    }
  }
`;

const SignUpButton = styled(Input.Button)`
  width: 100%;
  height: 3.5em;
  font-size: 1.1.em;
`;

const SingUpLayout = () => {
  const [allowEmail, setAllowEmail] = useState<boolean>(false);
  const [allowChannel, setAllowChannel] = useState<boolean>(false);
  const router = useHistory();

  const useHtmlInputElementRef = () => useRef<HTMLInputElement>(null);
  const nameRef = useHtmlInputElementRef();
  const nicknameRef = useHtmlInputElementRef();
  const channelRef = useHtmlInputElementRef();
  const emailRef = useHtmlInputElementRef();
  const passwordRef = useHtmlInputElementRef();
  const checkPasswordRef = useHtmlInputElementRef();

  const onEmailCheck = async () => {
    const email = emailRef.current!.value;
    if (!email) {
      window.alert("이메일을 입력해주세요!");
      emailRef.current!.focus();
      setAllowEmail(false);
      return;
    }
    if (!email.includes("@")) {
      window.alert("잘못된 이메일 형식입니다.");
      emailRef.current!.focus();
      setAllowEmail(false);
      return;
    }
    const response = await IdIsExist({ email });
    if (response.errors) {
      window.alert("서버 에러.");
      setAllowEmail(false);
      return;
    }
    if (response.data && !response.data.idIsExist) {
      window.alert("중복된 이메일이 있습니다. 다른 이메일을 입력해주세요!");
      emailRef.current!.focus();
      setAllowEmail(false);
      return;
    } else {
      window.alert("중복된 이메일이 없습니다.");
      setAllowEmail(true);
    }
  };

  const onChannelCheck = async () => {
    const name = channelRef.current!.value;
    if (!name) {
      window.alert("채널 이름을 입력해주세요.");
      channelRef.current!.focus();
      setAllowChannel(false);
      return;
    }
    const response = await IdIsExist({ channel: name });
    if (response.errors) {
      window.alert("서버 에러");
      setAllowChannel(false);
      return;
    }
    if (response.data && !response.data.idIsExist) {
      window.alert("중복된 채널명이 있습니다. 다른 이름을 입력해주세요.");
      channelRef.current!.focus();
      setAllowChannel(false);
    } else {
      window.alert("중복된 채널명이 없습니다.");
      setAllowChannel(true);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const name = nameRef.current!.value;
    const nickname = nicknameRef.current!.value;
    const email = emailRef.current!.value;
    const password = passwordRef.current!.value;
    const confirmPassword = checkPasswordRef.current!.value;
    const channel = channelRef.current!.value;
    if (!allowEmail) {
      window.alert("이메일 중복검사를 해주세요.");
      return;
    } else if (!allowChannel) {
      window.alert("채널명 중복검사를 해주세요.");
      return;
    }
    if (password !== confirmPassword) {
      window.alert("password must be equal with confirm password!");
      return;
    }
    const response = await CreateUser({ name, nickname, email, password, channel });
    if (response.errors) {
      window.alert("SignUp Failed.");
      return;
    }
    if (response.data!.createUser) {
      window.alert("회원가입 되었습니다.");
      router.push(SIGN);
      return;
    }
  };

  return (
    <Layout>
      <Header>DMS</Header>
      <SignUpForm onSubmit={onSubmit}>
        <label className="title">회원가입</label>
        <hr className="bar" />
        <LabelGroup>
          <label>이메일</label>
          <CheckInputContainer>
            <Input.Text ref={emailRef} autoComplete="email" placeholder="이메일을 입력하세요." maxLength={32} />
            <button type="button" className="checkbtn" onClick={onEmailCheck}>
              중복 검사
            </button>
          </CheckInputContainer>
        </LabelGroup>
        <LabelGroup>
          <label>비밀번호</label>
          <Input.Password ref={passwordRef} placeholder="비밀번호를 입력하세요." />
          <Input.Password ref={checkPasswordRef} placeholder="비밀번호를 확인해주세요." />
        </LabelGroup>
        <LabelGroup>
          <label>추가 정보</label>
          <Input.Text ref={nameRef} placeholder="이름을 입력하세요." />
          <Input.Text ref={nicknameRef} placeholder="닉네임을 입력하세요." />
          <CheckInputContainer>
            <Input.Text ref={channelRef} placeholder="채널명을 입력하세요." maxLength={32} />
            <button type="button" className="checkbtn" onClick={onChannelCheck}>
              중복 검사
            </button>
          </CheckInputContainer>
        </LabelGroup>
        <SignUpButton type="submit" color="default">
          가입하기
        </SignUpButton>
      </SignUpForm>
    </Layout>
  );
};

export const SignUp = () => (
  <>
    <Helmet title="회원가입" />
    <SingUpLayout />
  </>
);
