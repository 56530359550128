/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, EnumScalar } from "graphql-jsx";
import { clients } from "utils/clients";

export const GetMoodParentList = async () => {
  const { query, variables } = render(
    <Query operationName="GET_MOOD_PARENT_INFO">
      <SelectionSet
        name="genre"
        argument={{
          first: 999,
          group: true,
          orderBy: <EnumScalar type="GenreOrderByInput" value="name__ASC" items={["name__ASC"]} />,
          where: {
            weight: 1,
            type_kind: "mood"
          }
        }}
      >
        <Field name="genre_id" alias="id" />
        <Field name="name" />
        <Field name="type_kind" alias="typeKind" />
        {/* genre_self_relation_child alias에러로 인해 child로 alias를 맞추게 됨 */}
        <SelectionSet name="genre_self_relation" alias="genre_self_relation_child">
          <SelectionSet name="genre_self_relation_child" alias="child">
            <Field name="genre_id" alias="id" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = {
    genre: {
      id: string;
      name: string;
      typeKind: string;
      genre_self_relation_child?: {
        child?: {
          id: string;
        }[];
      }[];
    }[];
  };
  return await clients.genre.request<Data>(query, variables);
};
