export const GoodsSubdataOptionList = [
  { id: "size", name: "SIZE" },
  { id: "design", name: "DESIGN" },
  { id: "model", name: "MODEL" },
  { id: "type", name: "TYPE" },
  { id: "celeb", name: "CELEB" },
  { id: "price", name: "PRICE" },
  { id: "extra", name: "EXTRA" }
];

export const GoodsSubdataCategoryList = [
  { id: "option", name: "옵션" },
  { id: "weight", name: "무게 (g)" },
  { id: "quantity", name: "수량" },
  { id: "auto-add", name: "상품 자동 추가" },
  { id: "gift", name: "GIFT" },
  { id: "etc", name: "기타" }
];

export const GoodsIPhoneModels = [
  "iPhone 6/6s",
  "iPhone 6+",
  "iPhone 7/8",
  "iPhone 7+/8+",
  "iPhone X/XS",
  "iPhone XR",
  "iPhone XS MAX",
  "iPhone 11",
  "iPhone 11 Pro",
  "iPhone 11 Pro Max",
  "iPhone 12 Mini",
  "iPhone 12",
  "iPhone 12 Pro",
  "iPhone 12 Pro Max",
  "iPhone 13 Mini",
  "iPhone 13",
  "iPhone 13 Pro",
  "iPhone 13 Pro Max"
];

export const GoodsGalaxyModels = [
  "Galaxy Note 8",
  "Galaxy Note 9",
  "Galaxy Note 10",
  "Galaxy Note 10+",
  "Galaxy Note 20",
  "Galaxy Note 20 Ultra",
  "Galaxy S8",
  "Galaxy S8+",
  "Galaxy S9",
  "Galaxy S9+",
  "Galaxy S10",
  "Galaxy S10+",
  "Galaxy S10e",
  "Galaxy S20",
  "Galaxy S20+",
  "Galaxy S20 FE",
  "Galaxy S20 Ultra",
  "Galaxy S21",
  "Galaxy S21+",
  "Galaxy S21 FE",
  "Galaxy S21 Ultra",
  "Galaxy S22",
  "Galaxy S22+",
  "Galaxy S22 Ultra",
  "Galaxy ZFlip 3"
];
