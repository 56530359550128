import { useAuthGrantStore } from "App/Routes/AuthGrant/Store";
import { IdActions } from "App/Routes/AuthGrant/Store/IdList";
import React, { useMemo } from "react";
import { FilterItem, InputNumber } from "../../formStyle";
import { GrantKey } from "App/Routes/AuthGrant/Store/Grant";

export const SkipFilter = () => {
  const [{ mode, searchList }, dispatch] = useAuthGrantStore(store => store.IdList);

  const skip = useMemo(() => {
    switch (mode) {
      case GrantKey.TRACK:
        return searchList.Track.skip;
      case GrantKey.ARTIST:
        return searchList.Artist.skip;
      case GrantKey.GENRE:
        return searchList.Genre.skip;
      case GrantKey.PLAYLIST:
        return searchList.Playlist.skip;
      case GrantKey.COMPANY:
        return searchList.Company.skip;
      default:
        return 0;
    }
  }, [mode, searchList.Artist.skip, searchList.Company.skip, searchList.Genre.skip, searchList.Playlist.skip, searchList.Track.skip]);

  const onChangeNumber = (num: number) => {
    dispatch(IdActions.setSkip({ idType: mode, skip: num }));
  };

  return (
    <FilterItem>
      <div className="item-label">
        <span>스킵 개수</span>
      </div>
      <div className="item-option-wrapper">
        <div className="item-option">
          <InputNumber key={mode} min={0} max={99999} step={1} placeholder="최소" defaultValue={skip} onBlur={onChangeNumber} />
        </div>
        <span style={{ display: "flex", alignItems: "center" }}>개</span>
      </div>
    </FilterItem>
  );
};
