/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, render, Mutation, SelectionSet, Field, IdScalar } from "graphql-jsx";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  elementId?: string;
}>;

export const UpdateMetadataListRelation = async (option: Option) => {
  const $uuid = <Variable name="uuid" scalar={<UuidScalar />} value={option.uuid} />;
  const $elementId = <Variable name="list_id" scalar={<IdScalar />} value={option.elementId} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_METADATA_LIST_RELATION">
      <SelectionSet
        name="updateMetadata_self_relations_list_metadata"
        alias="updateMetadataSelfRelationsListMetadata"
        argument={{
          where: {
            id: $uuid
          },
          data: {
            metadata_self_relations_element_metadata: {
              connect: {
                metadata_id: $elementId
              }
            }
          }
        }}
      >
        <Field name="id" alias="uuid" />
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    updateMetadataSelfRelationsListMetadata: {
      uuid: string;
    }[];
  }>;

  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    throw errors;
  }
  return data.updateMetadataSelfRelationsListMetadata;
};
