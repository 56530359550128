import React from "react";
import styled from "styled-components";
import { LeftTemplate } from "./LeftTemplate";
import { RightTemplate } from "./RightTemplate";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const BottomTemplate = () => {
  return (
    <Layout>
      <LeftTemplate />
      <RightTemplate />
    </Layout>
  );
};
