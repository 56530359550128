/** @jsx jsx */

import { jsx, render, SelectionSet, Field, Variable, IdScalar, Mutation, StringScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { DateTimeScalar, MetadataRightScalar } from "GraphQL/Scalars";
import { MetadataRightType } from "constants/MetadataRightType";
type Artist = {
  artist_id: string;
  artist_name: string;
  role_id: string;
  role_name: string;
  character_id?: string;
  character_name?: string;
  order?: number;
};
type Option = Readonly<{
  no: string;
  title: string;
  class: string;
  subClass: string;
  trackArtist: Artist[];
  companyId: string;
  genreIds: string[];
  moodIds: string[];
  workIds?: string[];
  youtubeUrl: string;
  mp3Url: string;
  parentId: number;
  notice: string;
  countryCode: string;
  typeTrack: string;
  titleType?: string;
  titleValue?: string;
  publishDate: string;
  recordYear: number;
  publishYear: number;
  firstEdition: number;
  typeKind?: MetadataRightType;
  isEffect?: boolean;
}>;

export const CreateTrack = async (option: Option) => {
  const $no = <Variable name="no" scalar={<StringScalar />} value={option.no} />;
  const $title = <Variable name="title" scalar={<StringScalar isNullable={false} />} value={option.title} />;
  const $title_no_space = (
    <Variable name="title_no_space" scalar={<StringScalar isNullable={false} />} value={option.title.replace(/\s/gi, "")} />
  );
  const $class = <Variable name="class" scalar={<StringScalar isNullable={false} />} value={option.class} />;
  const $subClass = <Variable name="subclass" scalar={<StringScalar />} value={option.subClass} />;
  const $titleType = <Variable name="titleType" scalar={<StringScalar isNullable={false} />} value={option.titleType} />;
  const $titleValue = <Variable name="titleValue" scalar={<StringScalar isNullable={false} />} value={option.titleValue} />;
  const $companyId = <Variable name="companyId" scalar={<IdScalar />} value={option.companyId} />;
  const $youtubeUrl = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.youtubeUrl} />;
  const $mp3Url = <Variable name="mp3url" scalar={<StringScalar isNullable={false} />} value={option.mp3Url} />;
  const $parentId = <Variable name="parentId" scalar={<IntScalar isNullable={false} />} value={option.parentId} />;
  const $notice = <Variable name="notice" scalar={<StringScalar />} value={option.notice} />;
  const $countryCode = <Variable name="countryCode" scalar={<StringScalar isNullable={false} />} value={option.countryCode} />;
  const $typeTrack = <Variable name="typeTrack" scalar={<StringScalar isNullable={false} />} value={option.typeTrack} />;
  const $publishDate = <Variable name="publishDate" scalar={<DateTimeScalar />} value={JSON.stringify(`${option.publishDate} 05:00:00`)} />;
  const $recordYear = <Variable name="recordYear" scalar={<IntScalar />} value={option.recordYear} />;
  const $publishYear = <Variable name="publishYear" scalar={<IntScalar />} value={option.publishYear} />;
  const $firstEdition = <Variable name="firstEdition" scalar={<IntScalar />} value={option.firstEdition} />;
  const $typeKind = <MetadataRightScalar isNullable={true} value={option.typeKind} />;
  const genreIds = option.genreIds.map((id, order) => ({ id, order }));
  const moodIds = option.moodIds.map((id, order) => ({ id, order }));
  const totalGenre = genreIds.concat(moodIds);
  const { query, variables } = render(
    <Mutation operationName="CREATE_TRACK_LOG">
      <SelectionSet
        name="createMetadata"
        alias="createTrack"
        argument={{
          data: {
            no: $no,
            title: $title,
            type_metadata_class: $class,
            type_metadata_subclass: $subClass,
            metadata_title: {
              create: [
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title,
                  exposure_order: 0
                },
                {
                  language: {
                    connect: {
                      language_code: "OO"
                    }
                  },
                  type_metadata_title: "name",
                  value: $title_no_space,
                  exposure_order: 1
                },
                !option.titleType || !option.titleValue
                  ? undefined
                  : {
                      language: {
                        connect: {
                          language_code: "XX"
                        }
                      },
                      type_metadata_title: $titleType,
                      value: $titleValue,
                      exposure_order: 0
                    }
              ]
            },
            metadata_self_relations_list_metadata: {
              create: [
                ...(option.workIds?.map((id, order) => ({
                  type_function: "works",
                  metadata_self_relations_element_metadata: {
                    connect: {
                      metadata_id: id
                    }
                  },
                  exposure_order: order
                })) ?? [])
              ]
            },
            metadata_artist_relation: {
              create: [
                ...option.trackArtist.map(({ artist_id, role_id, character_id, order }) => ({
                  artist: {
                    connect: {
                      artist_id: artist_id
                    }
                  },
                  role: {
                    connect: {
                      role_id: role_id
                    }
                  },
                  character: {
                    connect: {
                      role_id: character_id
                    }
                  },
                  exposure_order: order
                }))
              ]
            },
            metadata_genre_relation: {
              create: !totalGenre.length
                ? []
                : totalGenre.map(({ id, order }) => ({
                    genre: {
                      connect: {
                        genre_id: id
                      }
                    },
                    exposure_order: order
                  }))
            },
            track_license: {
              create: [
                option.isEffect
                  ? {}
                  : {
                      copyright: true,
                      neighboring: true,
                      notice: $notice,
                      country_code: $countryCode,
                      type_track: $typeTrack,
                      publish_date: $publishDate
                    }
              ]
            },
            track_license_extra: {
              create: [
                option.isEffect
                  ? {}
                  : {
                      record_year: $recordYear,
                      publish_year: $publishYear,
                      first_edition: $firstEdition
                    }
              ]
            },
            metadata_url: {
              create: [
                {
                  type_url: "youtube",
                  url: $youtubeUrl
                },
                {
                  type_url: "mp3high",
                  url: $mp3Url
                }
              ]
            },
            metadata_company_relation: {
              create: [
                {
                  company: {
                    connect: {
                      company_id: $companyId
                    }
                  },
                  type_kind: $typeKind,
                  exposure_order: 0
                }
              ]
            },
            metadata_structure: {
              create: { parent_id: $parentId }
            }
          }
        }}
      >
        <Field name="metadata_id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createTrack: Readonly<{
      metadata_id: string;
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
