export class Result<T, E> {
  constructor(readonly result: Ok<T> | Err<E>) {}

  get isOk() {
    return this.result.isOk;
  }

  get isErr() {
    return !this.isOk;
  }

  get item() {
    const result = this.result as Ok<T>;
    return result.item;
  }

  get error() {
    const result = this.result as Err<E>;
    return result.error;
  }

  ok_map<U>(fn: (item: T) => U): Result<U, E> {
    if (this.result.isOk) {
      return ok(fn(this.result.item));
    }
    return new Result(this.result);
  }

  err_map<E2>(fn: (error: E) => E2): Result<T, E2> {
    if (!this.result.isOk) {
      return err(fn(this.result.error));
    }
    return new Result(this.result);
  }

  ok_then<U>(fn: (item: T) => Result<U, E>): Result<U, E> {
    if (this.result.isOk) {
      return fn(this.result.item);
    }
    return err(this.result.error);
  }

  err_then<E2>(fn: (error: E) => Result<T, E2>): Result<T, E2> {
    if (!this.result.isOk) {
      return fn(this.result.error);
    }
    return ok(this.result.item);
  }

  unwrap(): T {
    if (this.result.isOk) {
      return this.result.item;
    }
    throw new Error("Err can not be unwrapped!");
  }

  unwrapOr(item: T): T {
    if (this.result.isOk) {
      return this.result.item;
    }
    return item;
  }

  unwrapOrNull(): null | T {
    if (this.result.isOk) {
      return this.result.item;
    }
    return null;
  }

  unwrapOrElse(fn: () => T): T {
    if (this.result.isOk) {
      return this.result.item;
    }
    return fn();
  }
}

export class Ok<T> {
  constructor(readonly item: T) {}

  readonly isOk = true;
}

export class Err<E> {
  constructor(readonly error: E) {}

  readonly isOk = false;
}

export const ok = <T>(item: T): Result<T, any> => new Result(new Ok(item));

export const err = <E>(error: E): Result<any, E> => new Result(new Err(error));
