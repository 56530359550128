import React from "react";
import styled from "styled-components";

import { GrantForm } from "./GrantForm";
import { TrackSearchForm } from "./TrackSearchForm";
import { PADDING_XX_LARGE_PX, pixelize, UNIT } from "constants/size";

const Layout = styled.div`
  width: ${pixelize(UNIT * 37.5)};
  height: 100vh;
  padding: ${PADDING_XX_LARGE_PX};
  overflow-y: scroll;
`;

export const Left = () => (
  <Layout>
    <GrantForm />
    <TrackSearchForm />
  </Layout>
);
