import classNames from "classnames";
import React, { FC, useState } from "react";
import ReactSelect from "react-select";

import { identity } from "utils/identity";

import makeAnimated from "react-select/animated";
import { SelectComponents } from "react-select/src/components";

type Value = string;
type Label = string;

export type Info = Readonly<{
  id: Value;
  name: Label;
  eu?: number;
  asia?: number;
  parent?: {
    id: string;
    name: string;
  };
  isDisabled?: boolean;
}>;

type SelectComponentType =
  | Partial<
      SelectComponents<
        Readonly<{
          id: string;
          name: string;
          isDisabled?: boolean;
          type?: string | undefined;
        }>
      >
    >
  | undefined;

type Props = Readonly<{
  style?: import("react-select/src/styles").Styles;
  className?: string;
  classNamePrefix?: string;
  isDisabled?: boolean;
  isMenuDisable?: boolean;
  inputValue?: string;
  value?: readonly Info[];
  defaultValue?: readonly Info[];
  placeholder?: string;
  optionList?: readonly Info[];
  indicator?: null | FC;
  autoFocus?: boolean;
  onInputChange?: (value: string) => void;
  onChange?: (option: readonly Info[]) => void;
  onBlur?: (option: readonly Info[]) => void;
}>;

export const TagSelect = ({
  style,
  className,
  classNamePrefix,
  isDisabled,
  isMenuDisable,
  inputValue,
  value,
  defaultValue = [],
  placeholder,
  optionList,
  indicator,
  autoFocus,
  onInputChange = identity,
  onChange = identity,
  onBlur = identity
}: Props) => {
  const [info, setInfo] = useState(defaultValue);
  const updateInput: ReactSelect<Info>["props"]["onInputChange"] = (nextInfo, meta) => {
    switch (meta.action) {
      case "input-change":
      case "set-value": {
        onInputChange(nextInfo);
      }
    }
  };
  const change: ReactSelect<Info>["props"]["onChange"] = nextInfo => {
    setInfo((nextInfo || []) as readonly Info[]);
    onChange((nextInfo || []) as readonly Info[]);
  };
  const blur = () => onBlur(info);
  const animatedComponents: SelectComponentType = makeAnimated();
  return (
    <ReactSelect
      styles={style}
      className={classNames(className)}
      classNamePrefix={classNamePrefix}
      isDisabled={isDisabled}
      menuPosition={"fixed"}
      menuIsOpen={isMenuDisable === undefined || !isMenuDisable ? undefined : !isMenuDisable}
      components={{
        ...animatedComponents,
        ...(indicator ? { DropdownIndicator: indicator } : isMenuDisable && { DropdownIndicator: null })
      }}
      inputValue={inputValue}
      value={value}
      defaultValue={defaultValue}
      isMulti={true}
      getOptionValue={info => info.id}
      getOptionLabel={info => info.name}
      placeholder={placeholder}
      options={optionList}
      autoFocus={autoFocus}
      onInputChange={updateInput}
      onChange={change}
      onBlur={blur}
    />
  );
};
