import React, { ReactElement, useRef } from "react";
import styled from "styled-components";
import { Style } from "App/Routes/GoodsDetail/Style";
import { GRAY_1, GRAY_2 } from "constants/baseColor";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { configs } from "configs";
import { ProductDetailStore } from "App/Routes/ProductDetail/Store";
import { ProductDetailActions, ProductUrl } from "App/Routes/ProductDetail/Store/ProductDetail";

type Props = {
  readonly urls: ProductUrl[];
};

export const ProductUrls = ({ urls }: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const addCoverUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    const id = uuidv4();

    const order = !urls?.length ? 0 : (_.last(urls)?.order ?? 0) + 1;
    try {
      fileReader.readAsDataURL(file.files![0]);
      fileReader.onloadend = e => {
        const data = {
          id,
          file: file.files![0],
          data: e.target?.result as string,
          ext: file.files![0].type.split("/")[1],
          url: file.files![0].name,
          typeUrl: file.files![0].type,
          order
        };
        ProductDetailStore.dispatch(ProductDetailActions.addCoverUrl(data));
        inputRef.current!.value = "";
      };
    } catch (err) {
      return;
    }
  };

  const deleteUrl = (index: number) => ProductDetailStore.dispatch(ProductDetailActions.removeCoverUrl(index));
  return (
    <Style.Row>
      <Style.Caption>{`상품 이미지`}</Style.Caption>
      <Style.SubCaption>이미지를 누르면 삭제됩니다.</Style.SubCaption>
      <ImageContainer>
        <UploadButton>
          <AddLabel htmlFor={`input-file`}>+</AddLabel>
          <HiddenInput ref={inputRef} type="file" id={`input-file`} accept="image/jpeg,image/png" onChange={addCoverUrl} />
        </UploadButton>
        {urls?.map((cover, index) => (
          <Image
            key={cover.id}
            src={!cover?.file ? `${configs.urls.image}/${cover.url}` : URL.createObjectURL(cover.file)}
            alt="cover"
            onClick={() => deleteUrl(index)}
          />
        ))}
      </ImageContainer>
    </Style.Row>
  );
};

const UploadButton = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
  transition: background 0.15s;
  &:hover {
    background: ${GRAY_1};
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ImageContainer = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  align-items: center;

  @media (min-width: 720px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 8px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  cursor: pointer;
`;

const AddLabel = styled.label`
  font-size: 36px;
  color: ${GRAY_2};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
