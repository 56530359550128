/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  id: string;
}>;

export const GetPlaylistTrackInfo = async (option: Option) => {
  const $id = <Variable name="playlist_id" scalar={<IdScalar />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="GET_PLAYLIST_TRACK_INFO">
      <SelectionSet
        name="metadata_playlist_relation"
        alias="metadataPlaylistRelation"
        argument={{
          group: true,
          first: 999,
          where: {
            playlist__some: { playlist_id: $id }
          }
        }}
      >
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="id" />
          <Field name="title" />
          <SelectionSet
            name="metadata_url"
            alias="metadataUrl"
            argument={{
              where: {
                type_url__in: ["mp3high", "aac", "wav"]
              }
            }}
          >
            <Field name="id" />
            <Field name="type_url" alias="typeUrl" />
            <Field name="url" />
          </SelectionSet>
          <SelectionSet name="track_information" alias="trackInformation">
            <Field name="id" />
            <Field name="duration" />
            <Field name="channel" />
            <Field name="sample_bitrate" alias="bitrate" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataPlaylistRelation: {
      metadata: {
        id: string;
        title: string;
        metadataUrl: {
          id: string;
          typeUrl: string;
          url: string;
        }[];
        trackInformation: {
          id: string;
          duration: string;
          channel: string;
          bitrate: string;
        }[];
      }[];
    }[];
  }>;
  const { data, error } = await clients.metadata.request<Data>(query, variables);
  if (error || !data) {
    throw error;
  }
  return data;
};
