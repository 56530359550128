import { createStore, combineReducers } from "lib/store/v2";
import { createStoreLogger } from "utils/createStoreLogger";
import { GenreCreateActions as Genre } from "./Genre";

const reducers = { Genre };

const reducer = combineReducers(reducers, createStoreLogger("GenreCreate"));

export const {
  StoreProvider: GenreCreateStoreProvider,
  useStore: useGenreCreateStore,
  useSelector: useGenreCreateSelector,
  useDispatch: useGenreCreateDispatch
} = createStore(reducer);
