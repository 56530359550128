import React, { ChangeEvent, useRef } from "react";
import styled from "styled-components";
import { VideoCreateStyle } from "App/Routes/VideoCreate/Style";
import { useVideoCreateDispatch } from "App/Routes/VideoCreate/Store";
import { VideoCreateActions } from "App/Routes/VideoCreate/Store/VideoCreate";

type Props = {
  file?: File;
};

export const UrlForm = ({ file }: Props) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const dispatch = useVideoCreateDispatch();
  const setVideoUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file.files![0]);
    fileReader.onloadend = event => {
      dispatch(
        VideoCreateActions.setVideo({
          file: file.files![0],
          url: file.files![0].name
        })
      );
    };
  };
  return (
    <VideoCreateStyle.Row>
      <VideoCreateStyle.Caption>비디오 *</VideoCreateStyle.Caption>
      <VideoCreateStyle.SubCaption>* 너무 용량이 클 경우 업로드가 어려울 수 있습니다.</VideoCreateStyle.SubCaption>
      <InputFile ref={fileRef} disabled={!!file} type="file" accept=".mp4" onChange={setVideoUrl} />
    </VideoCreateStyle.Row>
  );
};

const InputFile = styled.input`
  margin: 8px 0;
`;
