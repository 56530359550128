/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar, IntScalar } from "graphql-jsx";
import { MetadataRightScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";
import { MetadataRightType } from "constants/MetadataRightType";

type Option = Readonly<{
  metadataId: string;
  companyId?: string;
  typeKind?: string;
  order: number;
  validCheck: ValidType;
}>;

export const CreateMetadataCompany = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar />} value={option.metadataId} />;
  const $companyId = <Variable name="company_id" scalar={<IdScalar />} value={option.companyId} />;
  const $typeKind = (
    <MetadataRightScalar
      value={
        option.typeKind === "rightsCompany"
          ? MetadataRightType.rightCompany
          : option.typeKind === "productions"
          ? MetadataRightType.production
          : MetadataRightType.publication
      }
    />
  );
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_COMPANY">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataCompany"
        argument={{
          where: { metadata_id: $metadataId },
          data: {
            _onlySearch: true,
            metadata_company_relation: {
              create: {
                company: {
                  connect: {
                    company_id: $companyId
                  }
                },
                type_kind: $typeKind,
                valid_check: $validCheck,
                exposure_order: $order
              }
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <SelectionSet name="metadata_company_relation">
          <Field name="id" alias="uuid" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    createMetadataCompany: Readonly<{
      metadataId: string;
      metadata_company_relation: {
        uuid: string;
      }[];
    }>;
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
