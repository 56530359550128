/** @jsx jsx */

import { clients } from "utils/clients";
import { jsx, Variable, StringScalar, render, Mutation, SelectionSet, Field, IdScalar, IntScalar } from "graphql-jsx";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  metadataId: string;
  typeUrl: string;
  url: string;
  order?: number;
  validCheck?: ValidType;
}>;

export const CreateMetadataUrl = async (option: Option) => {
  const $metadataId = <Variable name="metadataId" scalar={<IdScalar />} value={option.metadataId} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable={false} />} value={option.typeUrl} />;
  const $url = <Variable name="url" scalar={<StringScalar isNullable={false} />} value={option.url} />;
  const $order = <Variable name="order" scalar={<IntScalar />} value={option.order} />;
  const $validCheck = <Variable name="valid_check" scalar={<ValidTypeScalar value={option.validCheck} isNullable />} />;
  const { query, variables } = render(
    <Mutation operationName="CREATE_METADATA_URL">
      <SelectionSet
        name="updateMetadata"
        alias="createMetadataUrl"
        argument={{
          where: {
            metadata_id: $metadataId
          },
          data: {
            _onlySearch: true,
            metadata_url: {
              create: [{ type_url: $typeUrl, url: $url, exposure_order: $order, valid_check: $validCheck }]
            }
          }
        }}
      >
        <Field name="metadata_id" alias="metadataId" />
        <SelectionSet name="metadata_url">
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
        </SelectionSet>
      </SelectionSet>
    </Mutation>
  );

  type Data = Readonly<{
    createMetadataUrl: {
      metadataId: string;
      metadata_url: {
        uuid: string;
        typeUrl: string;
        url: string;
      }[];
    };
  }>;

  return await clients.metadata.request<Data>(query, variables);
};
