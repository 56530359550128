/** @jsx jsx */

import { jsx, Scalar } from "graphql-jsx";

type Props = Readonly<{
  value?: string[];
  isNullable?: boolean;
}>;

export const ArrayScalar = ({ value, isNullable }: Props) => <Scalar type="Array" value={value} isNullable={isNullable} />;
