import { createReducer, createHandler, createActions } from "lib/store";

type State = ReturnType<typeof createInitialState>;

const TOGGLE_DEVMODE = Symbol("App::DevMode::ToggleDevMode");

export const DevModeActions = createActions({
  toggleDevMode(isVisible?: boolean) {
    return { type: TOGGLE_DEVMODE, isVisible };
  }
});

const handler = createHandler<State>({
  [TOGGLE_DEVMODE](state, payload: { isVisible?: boolean }) {
    state.isVisible = payload.isVisible === undefined ? !state.isVisible : payload.isVisible!;
  }
});

const createInitialState = () => ({
  isVisible: false
});

export const reducer = createReducer(handler, createInitialState);
