/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, ListScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { GoodsOrderByInput } from "constants/GoodsOrderByInput";
import { CategoryOfGoods } from "GraphQL/Scalars/CategoryOfGoodsScalar";
import { TypeOfGoods } from "GraphQL/Scalars/TypeOfGoodsScalar";
import { GoodsTitleOrderScalar } from "GraphQL/Scalars";
import { GoodsTitleOrderByInput } from "constants/GoodsTitleOrderByInput";
import { GoodsType } from "models/GoodsModel";
import { GoodsUrlOrderScalar } from "GraphQL/Scalars/GoodsUrlOrderScalar";
import { GoodsUrlOrderByInput } from "constants/GoodsUrlOrderByInput";

type Option = Readonly<{
  page?: number;
  first?: number;
  id?: string;
  name?: string;
  order?: GoodsOrderByInput;
}>;

export type GoodsUrl = {
  readonly id: string;
  readonly typeUrl: string;
  url: string;
  order: number;

  file?: File;
  ext?: string;
  data?: string;
};

export type GoodsArtist = Readonly<{
  uuid: string;
  order: number;
  artist: {
    id: string;
    name: string;
  }[];
}>;

export type GoodsCompany = Readonly<{
  uuid: string;
  company: {
    id: string;
    name: string;
  }[];
}>;

export type GoodsWork = Readonly<{
  uuid: string;
  order: number;
  metadata: {
    id: string;
    name: string;
  }[];
}>;

export type GoodsTitle = {
  id: string;
  type: string;
  languageCode: string;
  value: string;
  order: number;
};

export type GoodsSubdata = {
  id: string;
  field: string;
  value: string;
  category: string;
};

export type GoodsStructure = {
  id: string;
  order: number;
  parent: {
    goods: {
      id: string;
      name: string;
    }[];
  }[];
};

type GoodsDetail = Readonly<{
  id: string;
  name: string;
  category: CategoryOfGoods;
  type: TypeOfGoods;
  weight: number;
  officialPrice: number;
  officialBarcode?: string;
  askPrice: number;
  askQuantity: number;
  createdAt: string;
  goodsUrl: GoodsUrl[];
  goodsTitle: GoodsTitle[];
  goodsArtist: GoodsArtist[];
  goodsCompanyRelation: GoodsCompany[];
  metadata_goods_relation: GoodsWork[];
  goodsSubdata: GoodsSubdata[];
  goodsType: GoodsType;
  goodsStructure: GoodsStructure[];
  isCollection: boolean;
}>;

export const LoadGoodsDetail = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable />} value={option.id} />;
  const $titleOrder = <GoodsTitleOrderScalar value={GoodsTitleOrderByInput.type_title__DESC} />;
  const $orderASC = (
    <Variable
      name="Goods_urlOrderByInput"
      scalar={<ListScalar scalar={<GoodsUrlOrderScalar isNullable={false} />} />}
      value={GoodsUrlOrderByInput.exposure_order__ASC}
    />
  );
  const { query, variables } = render(
    <Query operationName="LOAD_GOODS_DETAIL">
      <SelectionSet
        name="goods"
        argument={{
          group: true,
          first: 1,
          page: 1,
          where: {
            goods_id: $id
          }
        }}
      >
        <Field name="goods_id" alias="id" />
        <Field name="name" />
        <Field name="type_id" />
        <Field name="weight" />
        <Field name="ask_price" alias="askPrice" />
        <Field name="official_price" alias="officialPrice" />
        <Field name="official_barcode" alias="officialBarcode" />
        <Field name="ask_quantity" alias="askQuantity" />
        <Field name="is_collection" alias="isCollection" />
        <Field name="created_at" alias="createdAt" />
        <SelectionSet name="goods_artist_relation" alias="goodsArtist" argument={{ first: 99 }}>
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="artist">
            <Field name="artist_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="goods_title" alias="goodsTitle" argument={{ orderBy: $titleOrder }}>
          <Field name="id" />
          <Field name="type_title" alias="type" />
          <Field name="language_code" alias="languageCode" />
          <Field name="value" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet
          name="goods_url"
          alias="goodsUrl"
          argument={{ orderBy: $orderASC, first: 99, where: { type_url__in: ["cover", "detail", "video", "official", "youtube"] } }}
        >
          <Field name="id" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        <SelectionSet name="goods_company_relation" alias="goodsCompanyRelation">
          <Field name="id" alias="uuid" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="metadata_goods_relation">
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="metadata">
            <Field name="metadata_id" alias="id" />
            <Field name="title" alias="name" />
          </SelectionSet>
        </SelectionSet>
        <SelectionSet name="goods_subdata_unique" alias="goodsSubdata" argument={{ first: 99 }}>
          <Field name="id" />
          <Field name="type_subdata_category" alias="category" />
          <Field name="type_subdata_field" alias="field" />
          <Field name="value" />
        </SelectionSet>
        <SelectionSet name="goods_type" alias="goodsType">
          <Field name="id" />
          <Field name="type" />
          <Field name="category" />
          <Field name="display_type" alias="displayType" />
        </SelectionSet>
        <SelectionSet name="goods_structure" alias="goodsStructure" argument={{ first: 99 }}>
          <Field name="id" />
          <Field name="inner_order" alias="order" />
          <SelectionSet name="parent">
            <SelectionSet name="goods">
              <Field name="goods_id" alias="id" />
              <Field name="name" />
            </SelectionSet>
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    goods: GoodsDetail[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
