import { createDuck } from "lib/store/v2";
import { Artist, Company, Metadata, SubData, Title, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import { LOADING } from "App/Templates/LoadingTemplate";
import { ValidType } from "constants/ValidType";
import { Info } from "App/Atomics/AutoComplete/TextAutoComplete";
import _ from "lodash";
import uuidv4 from "uuid/v4";

export type Genre = Readonly<{
  id: string;
  typeKind: string;
  name: string;
  genre_self_relation_child?: {
    parent?: {
      id: string;
      name?: string;
    }[];
    child?: {
      id: string;
      name?: string;
    }[];
  }[];
}>;

const createInitialState = () => ({
  loading: LOADING.ONLOAD as LOADING,
  editLoading: false as boolean,
  album: {} as Metadata,
  tracks: [] as Metadata[],
  origin: [] as Metadata[],
  genreList: [] as Genre[],
  moodList: [] as Genre[]
});

export const WorkDetailActions = createDuck({
  namespace: "WorkDetail",
  createInitialState,
  reducers: {
    setLoading(state, loading: LOADING) {
      state.loading = loading;
    },
    setEditLoading(state, loading: boolean) {
      state.editLoading = loading;
    },
    setWorkAlbum(state, album: Metadata) {
      const { structures } = album;
      state.album = album;
      if (structures[0]?.child.length) {
        state.tracks = structures[0].child.map(({ metadata }) => metadata[0]);
      }
      state.origin = state.tracks;
    },
    setWorkClear(state) {
      state.tracks = state.origin;
      state.genreList = [];
      state.moodList = [];
    },
    setWorkSave(state) {
      state.origin = state.tracks;
    },
    updateWorkTitle(state, { index, title }: { index: number; title: string }) {
      state.tracks[index].title = title;
    },
    updateWorkVC(state, { index, vc }: { index: number; vc: ValidType }) {
      state.tracks[index].validCheck = vc;
    },
    createWorkArtist(state, index: number) {
      const { artistRelation } = state.tracks[index];
      const uuid = uuidv4();
      const order = !artistRelation.length ? 0 : _.last(artistRelation)!.order + 1;
      const tempArtist = {
        uuid,
        artist: [{ id: undefined, name: undefined }],
        role: [{ id: undefined, name: undefined }],
        character: [{ id: undefined, name: undefined }],
        validCheck: ValidType.V3,
        order
      } as Artist;
      if (!artistRelation.length) {
        state.tracks[index].artistRelation = [tempArtist];
      } else {
        state.tracks[index].artistRelation.push(tempArtist);
      }
    },
    updateWorkArtist(state, { index, artistIndex, info }: { index: number; artistIndex: number; info: Info }) {
      const { artistRelation } = state.tracks[index];
      artistRelation[artistIndex].artist[0].id = info.id;
      artistRelation[artistIndex].artist[0].name = info.name;
    },
    updateWorkArtistUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { artistRelation } = state.tracks[index];
      const artistIndex = artistRelation.findIndex(({ uuid }) => uuid === prevUuid);
      artistRelation[artistIndex].uuid = uuid;
    },
    updateWorkRole(state, { index, roleIndex, info }: { index: number; roleIndex: number; info: Info }) {
      const { artistRelation } = state.tracks[index];
      artistRelation[roleIndex].role[0].id = info.id;
      artistRelation[roleIndex].role[0].name = info.name;
    },
    updateWorkCharacter(state, { index, characterIndex, info }: { index: number; characterIndex: number; info: Info }) {
      const { artistRelation } = state.tracks[index];
      if (!artistRelation[characterIndex].character.length) {
        artistRelation[characterIndex].character = [{ id: info.id, name: info.name }];
      } else {
        artistRelation[characterIndex].character[0].id = info.id;
        artistRelation[characterIndex].character[0].name = info.name;
      }
    },
    deleteWorkArtist(state, { index, artistIndex }: { index: number; artistIndex: number }) {
      state.tracks[index].artistRelation.splice(artistIndex, 1);
    },
    setWorkRightsCompany(state, { index, id, name }: { index: number; id: string; name: string }) {
      state.tracks[index].rightsCompany[0].company[0].id = id;
      state.tracks[index].rightsCompany[0].company[0].name = name;
    },
    setWorkProductions(state, { index, productions }: { index: number; productions: Company[] }) {
      state.tracks[index].productions = productions;
    },
    setWorkPublications(state, { index, publications }: { index: number; publications: Company[] }) {
      state.tracks[index].publications = publications;
    },
    updateWorkCompanyUuid(state, { index, typeKind, prevUuid, uuid }: { index: number; typeKind: string; prevUuid: string; uuid: string }) {
      if (typeKind === "productions") {
        const companyIndex = state.tracks[index].productions.findIndex(({ uuid }) => uuid === prevUuid);
        state.tracks[index].productions[companyIndex].uuid = uuid;
      } else {
        const companyIndex = state.tracks[index].publications.findIndex(({ uuid }) => uuid === prevUuid);
        state.tracks[index].publications[companyIndex].uuid = uuid;
      }
    },
    createWorkUrl(state, { index, url }: { index: number; url: Url }) {
      if (!state.tracks[index].metadataUrl.length) {
        state.tracks[index].metadataUrl = [url];
      } else {
        state.tracks[index].metadataUrl.push(url);
      }
    },
    updateWorkTypeUrl(state, { index, urlIndex, typeUrl }: { index: number; urlIndex: number; typeUrl: string }) {
      const order = (_.last(state.tracks[index].metadataUrl.filter(item => item.typeUrl === typeUrl))?.order ?? -1) + 1;
      state.tracks[index].metadataUrl[urlIndex].typeUrl = typeUrl;
      state.tracks[index].metadataUrl[urlIndex].order = order;
    },
    updateWorkUrl(state, { index, urlIndex, url }: { index: number; urlIndex: number; url: string }) {
      state.tracks[index].metadataUrl[urlIndex].url = url;
    },
    updateWorkUrlUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { metadataUrl } = state.tracks[index];
      const urlIndex = metadataUrl.findIndex(({ uuid }) => uuid === prevUuid);
      metadataUrl[urlIndex].uuid = uuid;
    },
    deleteWorkUrl(state, { index, urlIndex }: { index: number; urlIndex: number }) {
      state.tracks[index].metadataUrl.splice(urlIndex, 1);
    },
    createWorkSubdata(state, index: number) {
      const { subdataUnique } = state.tracks[index];
      const uuid = uuidv4();
      const temp = {
        uuid,
        category: undefined,
        field: undefined,
        value: undefined,
        validCheck: ValidType.V3
      } as SubData;
      if (!subdataUnique.length) {
        state.tracks[index].subdataUnique = [temp];
      } else {
        state.tracks[index].subdataUnique.push(temp);
      }
    },
    updateWorkSubdataUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { subdataUnique } = state.tracks[index];
      const subIndex = subdataUnique.findIndex(({ uuid }) => uuid === prevUuid);
      subdataUnique[subIndex].uuid = uuid;
    },
    updateWorkSubdataCategory(state, { index, subIndex, category }: { index: number; subIndex: number; category: string }) {
      const { subdataUnique } = state.tracks[index];
      subdataUnique[subIndex].category = category;
    },
    updateWorkSubdataField(state, { index, subIndex, field }: { index: number; subIndex: number; field: string }) {
      const { subdataUnique } = state.tracks[index];
      subdataUnique[subIndex].field = field;
    },
    updateWorkSubdataValue(state, { index, subIndex, value }: { index: number; subIndex: number; value: string }) {
      const { subdataUnique } = state.tracks[index];
      subdataUnique[subIndex].value = value;
    },
    deleteWorkSubdata(state, { index, subIndex }: { index: number; subIndex: number }) {
      state.tracks[index].subdataUnique.splice(subIndex, 1);
    },
    createWorkTitleRelation(state, index: number) {
      const { titles } = state.tracks[index];
      const uuid = uuidv4();
      const temp = {
        uuid,
        language: undefined,
        typeTitle: undefined,
        title: undefined,
        validCheck: ValidType.V3
      } as Title;
      if (!titles.length) {
        state.tracks[index].titles = [temp];
      } else {
        state.tracks[index].titles.push(temp);
      }
    },
    updateWorkTitleRelationUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { titles } = state.tracks[index];
      const subIndex = titles.findIndex(({ uuid }) => uuid === prevUuid);
      titles[subIndex].uuid = uuid;
    },
    updateWorkTitleRelationTypeTitle(state, { index, subIndex, typeTitle }: { index: number; subIndex: number; typeTitle: string }) {
      const { titles } = state.tracks[index];
      titles[subIndex].typeTitle = typeTitle;
    },
    updateWorkTitleRelationLanguage(
      state,
      { index, subIndex, languageCode, languageName }: { index: number; subIndex: number; languageCode: string; languageName: string }
    ) {
      const { titles } = state.tracks[index];
      titles[subIndex].language = [
        {
          languageCode,
          name: languageName,
          validCheck: ValidType.V3
        }
      ];
    },
    updateWorkTitleRelationValue(state, { index, subIndex, value }: { index: number; subIndex: number; value: string }) {
      const { titles } = state.tracks[index];
      titles[subIndex].title = value;
    },
    deleteWorkTitleRelation(state, { index, subIndex }: { index: number; subIndex: number }) {
      state.tracks[index].titles.splice(subIndex, 1);
    },
    createWorkElementRelation(state, index: number) {
      const { elementRelation } = state.tracks[index];
      const uuid = uuidv4();
      const order = !elementRelation.length ? 0 : _.last(elementRelation)!.order + 1;
      const temp = {
        uuid,
        order,
        listRelation: [
          {
            id: undefined,
            title: undefined
          }
        ]
      };
      if (!elementRelation.length) {
        state.tracks[index].elementRelation = [temp];
      } else {
        state.tracks[index].elementRelation.push(temp);
      }
    },
    updateWorkElementRelationUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { elementRelation } = state.tracks[index];
      const subIndex = elementRelation.findIndex(({ uuid }) => uuid === prevUuid);
      elementRelation[subIndex].uuid = uuid;
    },
    updateWorkElementRelationTrack(state, { index, subIndex, id, title }: { index: number; subIndex: number; id: string; title: string }) {
      const { elementRelation } = state.tracks[index];
      elementRelation[subIndex].listRelation[0].id = id;
      elementRelation[subIndex].listRelation[0].title = title;
    },
    deleteWorkElementRelation(state, { index, subIndex }: { index: number; subIndex: number }) {
      state.tracks[index].elementRelation.splice(subIndex, 1);
    },
    setWorkGenre(state, genre: Genre[]) {
      state.genreList = genre;
    },
    setWorkMood(state, mood: Genre[]) {
      state.moodList = mood;
    },
    changeWorkGenre(state, { index, genre }: { index: number; genre: Genre[] }) {
      state.genreList = genre;
      const trackGenre = state.tracks[index].genreRelation;
      const payloadGenre =
        genre?.map(({ id, name }) => ({
          uuid: uuidv4(),
          validCheck: ValidType.V3,
          genre: [
            {
              id,
              name
            }
          ]
        })) ?? [];
      if (trackGenre.length < payloadGenre.length) {
        const addGenreList = payloadGenre?.filter(({ genre }) => trackGenre.every(item => item.genre[0].id !== genre[0].id)) ?? [];
        state.tracks[index].genreRelation.push(...addGenreList);
      } else if (trackGenre.length > payloadGenre.length) {
        if (!payloadGenre.length) {
          state.tracks[index].genreRelation = [];
        } else {
          const removeIndex = trackGenre.findIndex(({ genre }) => !payloadGenre.some(item => item.genre[0].id === genre[0].id));
          state.tracks[index].genreRelation.splice(removeIndex, 1);
        }
      } else {
        return;
      }
    },
    changeWorkMood(state, { index, mood }: { index: number; mood: Genre[] }) {
      state.moodList = mood;
      const trackMood = state.tracks[index].moodRelation;
      const payloadMood =
        mood?.map(({ id, name }) => ({
          uuid: uuidv4(),
          validCheck: ValidType.V3,
          mood: [
            {
              id,
              name
            }
          ]
        })) ?? [];
      if (trackMood.length < payloadMood.length) {
        const addMoodList = payloadMood?.filter(({ mood }) => trackMood.every(item => item.mood[0].id !== mood[0].id)) ?? [];
        state.tracks[index].moodRelation.push(...addMoodList);
      } else if (trackMood.length > payloadMood.length) {
        if (!payloadMood.length) {
          state.tracks[index].moodRelation = [];
        } else {
          const removeIndex = trackMood.findIndex(({ mood }) => !payloadMood.some(item => item.mood[0].id === mood[0].id));
          state.tracks[index].moodRelation.splice(removeIndex, 1);
        }
      } else {
        return;
      }
    },
    updateWorkGenreUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { genreRelation } = state.tracks[index];
      const genreIndex = genreRelation.findIndex(({ uuid }) => uuid === prevUuid);
      genreRelation[genreIndex].uuid = uuid;
    },
    updateWorkMoodUuid(state, { index, prevUuid, uuid }: { index: number; prevUuid: string; uuid: string }) {
      const { moodRelation } = state.tracks[index];
      const moodIndex = moodRelation.findIndex(({ uuid }) => uuid === prevUuid);
      moodRelation[moodIndex].uuid = uuid;
    },
    removeWorkByIndex(state, index: number) {
      state.tracks.splice(index, 1);
    }
  }
});
