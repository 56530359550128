import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsDetail/Style";
import { ProductDetailStore } from "App/Routes/ProductDetail/Store";
import { ProductDetailActions } from "App/Routes/ProductDetail/Store/ProductDetail";

type Props = {
  readonly barcode: string;
};

export const ProductBarcode = ({ barcode }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>바코드</Style.Caption>
    <Style.SubCaption>ex. 12389912</Style.SubCaption>
    <Style.InputText
      placeholder={"바코드"}
      defaultValue={barcode}
      isDisabled
      onBlur={value => ProductDetailStore.dispatch(ProductDetailActions.setBarcode(value))}
    />
    <Style.AlertCaption data-visible={!barcode?.length}>바코드을 입력해주세요.</Style.AlertCaption>
  </Style.Row>
);
