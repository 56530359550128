/** @jsx jsx */

import { jsx, EnumScalar } from "graphql-jsx";
import { GenderType } from "constants/GenderType";

type Props = Readonly<{
  value?: GenderType;
  isNullable?: boolean;
}>;

const items = [GenderType.Male, GenderType.Female];

export const GenderTypeScalar = ({ value, isNullable }: Props) => (
  <EnumScalar type="genderType" value={value} isNullable={isNullable} items={items} />
);
