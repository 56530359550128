import React, { useState } from "react";
import styled from "styled-components";
import { GRAY_6, GREEN_5, GREEN_4, GREEN_6 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document.svg";
import { SUCCESS_COLOR_DARK, SUCCESS_COLOR } from "constants/color";
import { mediaQuery } from "constants/media";
import { Input } from "App/Atomics/Input";
import { useLiveReplayStore } from "../../Store";
import { ColumnSection } from "./ColumnSection";
import { Artist, Company, Metadata, Url } from "GraphQL/Queries/Metadata/GetMetadata";
import _ from "lodash";
import { Toast } from "lib/toast";
import { ValidType } from "constants/ValidType";
import {
  UpdateMetadataTitle,
  UpdateMetadataVC,
  CreateMetadataArtist,
  DeleteMetadataArtist,
  UpdateMetadataArtist,
  DeleteMetadataCompany,
  CreateMetadataUrl,
  DeleteMetadataUrl,
  UpdateMetadataUrl,
  UpdateMetadataCompany,
  GetMetadataTitleRelation,
  UpdateMetadataTitleRelation
} from "GraphQL/Queries/Metadata";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { CreateMetadataCompany } from "GraphQL/Queries/Metadata";
import { Loading } from "App/Atomics/Loading";
import { LiveReplayActions } from "../../Store/LiveReplay";

type Props = {
  index: number;
  toClose: () => void;
};

type Keys = keyof Metadata;
type Values = Metadata[Keys];

export enum Page {
  FIRST,
  SECOND
}

export const EditInfoModal = ({ index, toClose }: Props) => {
  const [{ liveReplay, origin }, dispatch] = useLiveReplayStore(store => ({
    liveReplay: store.PodCast.liveReplay[index],
    origin: store.PodCast.origin[index]
  }));
  const [pageIndex, setPageIndex] = useState<Page>(Page.FIRST);
  const [editLoading, setEditLoading] = useState<boolean>(false);

  const onClearAndClose = () => {
    dispatch(LiveReplayActions.setPodCastClear());
    toClose();
  };

  const onSave = async (origin: Metadata, work: Metadata) => {
    if (_.isEqual(origin, work)) {
      Toast.warning("변경사항이 없습니다.", undefined, "bottom-right");
      return;
    }
    setEditLoading(true);
    const changeMap = new Map<Keys, { from: Values; to: Values }>();
    const WorkEntries = Object.entries(work);
    const OriginValue = Object.values<Values>(origin);
    WorkEntries.forEach(([key, value], i) => {
      switch (key) {
        case "title":
        case "validCheck":
          if (OriginValue[i] !== value) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        case "artistRelation":
        case "rightsCompany":
        case "productions":
        case "publications":
        case "metadataUrl":
          if (!_.isEqual(OriginValue[i], value)) {
            changeMap.set(key, { from: OriginValue[i], to: value });
          }
          break;
        default:
          break;
      }
    });
    const accessId = await requestAccessRecord({ targetId: work.metadataId, targetTable: TargetTableInput.Metadata });
    try {
      for (const [key, { from, to }] of changeMap) {
        switch (key) {
          case "title":
            const { errors: titleErr, data: titleData } = await GetMetadataTitleRelation({ id: work.metadataId, typeTitle: "name" });
            if (titleErr || !titleData) {
              throw titleErr;
            }
            const titleRelationId = titleData.metadata[0]?.titleRelation[0]?.uuid;
            await UpdateMetadataTitle({ metadataId: work.metadataId, title: to as string });
            if (titleRelationId) {
              await UpdateMetadataTitleRelation({ id: titleRelationId, typeTitle: "name", value: to as string });
            }
            break;
          case "validCheck":
            await UpdateMetadataVC({ metadataId: work.metadataId, validCheck: to as ValidType });
            break;
          case "artistRelation":
            const deleteArtistList = (from as Artist[])
              .filter(({ uuid }) => !(to as Artist[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createArtistList = (to as Artist[]).filter(
              ({ uuid, artist, role }) => !(from as Artist[]).map(({ uuid }) => uuid).includes(uuid) && !!artist[0].id && !!role[0].id
            );
            const updateArtistList = (to as Artist[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as Artist[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteArtistList) {
              await DeleteMetadataArtist({ uuid });
            }
            for (const { uuid, artist, role, character, order, validCheck } of createArtistList) {
              const artistId = artist[0]?.id ?? undefined;
              const roleId = role[0]?.id ?? undefined;
              const characterId = character[0]?.id ?? undefined;
              const { data } = await CreateMetadataArtist({
                metadataId: work.metadataId,
                artistId,
                roleId,
                characterId,
                order,
                validCheck
              });
              if (data) {
                dispatch(
                  LiveReplayActions.updatePodCastArtistUuid({
                    index,
                    prevUuid: uuid,
                    uuid: data.createMetadataArtist.metadata_artist_relation[0].uuid
                  })
                );
              }
            }

            for (const { uuid, artist, role, character, order, validCheck } of updateArtistList) {
              const artistId = artist[0]?.id ?? undefined;
              const roleId = role[0]?.id ?? undefined;
              const characterId = character[0]?.id ?? undefined;
              await UpdateMetadataArtist({ uuid, artistId, roleId, characterId, order, validCheck });
            }
            break;
          case "rightsCompany":
            const { uuid, company } = (to as Company[])[0];
            const { errors } = await UpdateMetadataCompany({ uuid, companyId: company[0].id });
            if (errors) {
              console.log(errors[0].message);
              throw new Error("권리사 변경에 실패하였습니다.");
            }
            break;
          case "productions":
          case "publications":
            const deleteCompanyList = (from as Company[])
              .filter(({ uuid }) => !(to as Company[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createCompanyList = (to as Company[]).filter(({ uuid }) => !(from as Company[]).map(({ uuid }) => uuid).includes(uuid));
            for (const uuid of deleteCompanyList) {
              await DeleteMetadataCompany({ uuid });
            }
            for (const { uuid, company, typeKind, order, validCheck } of createCompanyList) {
              const companyId = company[0]?.id ?? undefined;
              const { data, errors } = await CreateMetadataCompany({
                metadataId: work.metadataId,
                companyId,
                typeKind,
                order,
                validCheck
              });
              if (errors) {
                throw new Error("중복된 데이터가 존재합니다.");
              }
              if (data) {
                dispatch(
                  LiveReplayActions.updatePodCastCompanyUuid({
                    index,
                    typeKind,
                    prevUuid: uuid,
                    uuid: data.createMetadataCompany.metadata_company_relation[0].uuid
                  })
                );
              }
            }
            break;
          case "metadataUrl":
            const deleteUrlList = (from as Url[])
              .filter(({ uuid }) => !(to as Url[]).map(({ uuid }) => uuid).includes(uuid))
              .map(({ uuid }) => uuid);
            const createUrlList = (to as Url[]).filter(
              ({ uuid, typeUrl, url }) => !(from as Url[]).map(({ uuid }) => uuid).includes(uuid) && !!typeUrl && !!url
            );
            const updateUrlList = (to as Url[]).filter(toItem => {
              let isUpdated = false;
              for (const fromItem of from as Url[]) {
                if (fromItem.uuid === toItem.uuid && !_.isEqual(fromItem, toItem)) {
                  isUpdated = true;
                }
              }
              return isUpdated;
            });
            for (const uuid of deleteUrlList) {
              await DeleteMetadataUrl({ uuid });
            }
            for (const { uuid, typeUrl, url, order, validCheck } of createUrlList) {
              const { data } = await CreateMetadataUrl({
                metadataId: work.metadataId,
                typeUrl: typeUrl!,
                url: url!,
                order: order,
                validCheck
              });
              if (data) {
                dispatch(
                  LiveReplayActions.updatePodCastUrlUuid({ index, prevUuid: uuid, uuid: data.createMetadataUrl.metadata_url[0].uuid })
                );
              }
            }

            for (const { uuid, typeUrl, url, order, validCheck } of updateUrlList) {
              await UpdateMetadataUrl({ uuid, typeUrl, url, order, validCheck });
            }
            break;
          default:
            break;
        }
      }
      Toast.primary("저장되었습니다.", undefined, "top-center");
    } catch (err) {
      console.log(err);
      Toast.error("저장을 실패하였습니다.", undefined, "top-center");
      setEditLoading(false);
      await DeleteAccessRecord({ id: accessId! });
      return;
    }

    await DeleteAccessRecord({ id: accessId! });
    setEditLoading(false);
    dispatch(LiveReplayActions.setPodCastSave());
    toClose();
  };

  return (
    <Layout>
      <Header>
        <DocumentIcon className="document" />
        <h3>작품 정보 및 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <ul className="left-container">
          <li className={pageIndex === Page.FIRST ? "active" : ""} onClick={() => setPageIndex(Page.FIRST)}>
            메인 정보
          </li>
          <li className={pageIndex === Page.SECOND ? "active" : ""} onClick={() => setPageIndex(Page.SECOND)}>
            부가 정보
          </li>
        </ul>
        <div className="right">
          <ColumnSection index={index} data={liveReplay} pageIndex={pageIndex} />
          <ButtonGroup>
            <Input.Button className="btn cancel" disabled={editLoading} isFill={false} color="success" onClick={onClearAndClose}>
              취소
            </Input.Button>
            <Input.Button
              className="btn save"
              disabled={editLoading}
              isFill
              color="success"
              onClick={async () => await onSave(origin, liveReplay)}
            >
              저장
            </Input.Button>
          </ButtonGroup>
        </div>
      </Section>
      <Loading loading={editLoading} />
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  right: 0%;
  top: 0%;
  width: 1000px;
  height: 100vh;
  overflow: hidden;
  background-color: #fff;
  font-size: 0.8rem;
  ${mediaQuery(1000)} {
    width: 100%;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .document {
    width: 1.15rem;
    height: 1.15rem;
    margin-right: 0.4rem;
    fill: ${SUCCESS_COLOR_DARK};
  }

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  display: flex;
  height: 100%;
  .left-container {
    width: 7rem;
    border-right: 1px solid #eee;
    li {
      display: flex;
      width: 100%;
      height: 4rem;
      padding: 0.5rem;
      font-weight: 600;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.15s;
      &:hover {
        background-color: #eee;
      }
    }
    li.active {
      color: #fff;
      background-color: ${GREEN_5};

      &:hover {
        background-color: ${GREEN_4};
      }
      &:focus {
        background-color: ${GREEN_6};
      }
    }
  }
  .right {
    width: 100%;
    margin-bottom: 5rem;
    overflow-y: scroll;
  }

  ${mediaQuery(965)} {
    flex-direction: column;
    .left-container {
      width: 100%;
      display: flex;
      border-right: none;
      border-bottom: 1px solid #eee;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem;
  .btn {
    width: 6rem;
    margin-right: 0.5rem;
  }
  .cancel {
    &:hover {
      background-color: #eee;
    }
  }
  /* Input.Button 기본 css를 수정해야함. */
  .save {
    border: 1px solid ${SUCCESS_COLOR};
    &:hover {
      border: 1px solid ${SUCCESS_COLOR};
    }
  }
`;
