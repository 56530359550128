/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { GoodsSubdata } from "App/Routes/GoodsCreate/Store/GoodsCreate";

type Option = {
  readonly id: string;
};

export const LoadGoodsSubdatas = async (option: Option) => {
  const $id = <Variable name="goods_id" scalar={<IdScalar isNullable />} value={option.id} />;
  const { query, variables } = render(
    <Query operationName="LOAD_GOODS_SUBDATAS">
      <SelectionSet
        name="goods_subdata_unique"
        alias="goodsSubdatas"
        argument={{
          first: 99,
          where: {
            goods__some: {
              goods_id: $id
            }
          }
        }}
      >
        <Field name="id" />
        <Field name="type_subdata_category" alias="category" />
        <Field name="type_subdata_field" alias="field" />
        <Field name="value" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    goodsSubdatas: GoodsSubdata[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
