import React from "react";
import { FilterGroup, Filters } from "../..";
import { ArtistTagAutoComplete } from "App/Molecules/AutoCompletes/Artist";
import { Info } from "App/Atomics/AutoComplete/TagAutoComplete";
import { Input } from "App/Atomics/Input";
import { ArtistTypeOptionList } from "constants/ArtistTypeOptionList";

type Props = {
  artist?: Info[];
  artistType?: Info[];
  setFilters: (value: React.SetStateAction<Filters>) => void;
};

export const ArtistFilter = ({ artist, artistType, setFilters }: Props) => (
  <>
    <FilterGroup>
      <h4>아티스트</h4>
      <span className="info">여러 명의 아티스트를 선택할 수 있습니다.</span>
      <ArtistTagAutoComplete
        defaultValue={artist?.length ? artist.map(({ id, name }) => ({ id, name: window.decodeURIComponent(name) })) : undefined}
        onChange={info => {
          if (info) {
            const list = info.map(({ id, name }) => ({ id, name: window.encodeURIComponent(name) }));
            setFilters(filter => ({ ...filter, artist: list }));
          }
        }}
      />
    </FilterGroup>
    <FilterGroup>
      <h4>아티스트 타입</h4>
      <span className="info">여러 개의 타입을 선택할 수 있습니다.</span>
      <Input.TagSelect
        optionList={ArtistTypeOptionList}
        placeholder="타입을 선택해주세요."
        defaultValue={artistType}
        onChange={info => {
          if (info) {
            const list = info.map(({ id, name }) => ({ id, name }));
            setFilters(filter => ({ ...filter, artistType: list }));
          }
        }}
      />
    </FilterGroup>
  </>
);
