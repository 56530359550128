import React, { ChangeEvent, useRef, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";

import { PADDING_SMALL_PX, PADDING_X_LARGE_PX } from "constants/size";
import { identity } from "utils/identity";

type ImperativeHandle = Readonly<{ value: string }>;

type Props = Readonly<{
  value?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
  autoComplete?: string;
  a11y?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onFocus?: (value: string) => void;
  onBlur?: (value: string) => void;
}>;

const Layout = styled.input`
  padding: ${PADDING_SMALL_PX} ${PADDING_X_LARGE_PX};
  background-color: #fff;
`;

export const Password = forwardRef<ImperativeHandle, Props>(
  (
    {
      value,
      isDisabled,
      isRequired,
      minLength = 0,
      maxLength,
      autoComplete,
      a11y,
      placeholder,
      onChange = identity,
      onFocus = identity,
      onBlur = identity
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const getValue = () => inputRef.current!.value || "";

    useImperativeHandle(ref, () => ({
      get value() {
        return getValue();
      }
    }));

    const change = (event: ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.currentTarget.value;
      onChange(nextValue);
    };

    const focus = () => {
      onFocus(getValue());
    };

    const blur = () => {
      onBlur(getValue());
    };

    return (
      <Layout
        type="password"
        ref={inputRef}
        disabled={isDisabled}
        required={isRequired}
        minLength={minLength}
        maxLength={maxLength}
        autoComplete={autoComplete}
        spellCheck={false}
        alt={a11y}
        placeholder={placeholder}
        value={value}
        onChange={change}
        onFocus={focus}
        onBlur={blur}
      />
    );
  }
);
