import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  pixelize,
  UNIT,
  PADDING_LARGE_PX,
  PADDING_X_LARGE,
  MARGING_LARGE_PX,
  MARGING_X_LARGE_PX,
  MARGING_XX_LARGE_PX,
  MARGING_SMALL_PX,
  PADDING_X_LARGE_PX
} from "constants/size";
import { WHITE, GRAY_2, BLACK, GRAY_4, GRAY_5, BLUE_7 } from "constants/baseColor";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { ReactComponent as SearchIcon } from "assets/icons/custom-search.svg";
import { Input } from "App/Atomics/Input";
import { DANGER_COLOR, DANGER_COLOR_LIGHT } from "constants/color";
import {
  CreateAccessRecord,
  DeleteAccessRecord,
  GetArtistRelationByMemo,
  UpdateMetadataArtistRelation,
  UpdateArtistRelation,
  DeleteArtistRelation,
  CreateDescArtist
} from "GraphQL/Queries";
import { TargetTableInput } from "constants/TargetTableInput";
import { shift } from "lib/shift";
import { timeout } from "utils/timeout";
import { AnimatedProgress } from "App/Atomics/AnimatedProgress";
import { ReactComponent as Loading } from "assets/icons/loading.svg";
import itiriri from "itiriri";
import { requestAccessRecord } from "lib/requestAccessRecord";

type Props = {
  toClose: () => void;
};

const spin = keyframes`
  0%  {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: ${pixelize(UNIT * 42)};
  height: ${pixelize(UNIT * 32)};
  padding-bottom: ${PADDING_LARGE_PX};
  background-color: #f7f7f7;

  .loading {
    width: ${pixelize(UNIT * 1.2)};
    height: ${pixelize(UNIT * 1.2)};
    animation: ${spin} 1.5s linear infinite;
  }
`;

const Header = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 5)};
  display: flex;
  background-color: ${WHITE};
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  padding: ${pixelize(PADDING_X_LARGE * 1.4)};
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.25rem;
    text-shadow: 0 0 1px ${GRAY_2};
    font-weight: bold;
  }
  .cancelIcon {
    fill: ${BLACK};
    width: ${pixelize(UNIT)};
    height: ${pixelize(UNIT)};
    margin-right: ${MARGING_LARGE_PX};
    transition: all 0.1s;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_4};
    }
  }
`;

const InputButton = styled(Input.Button)`
  width: ${pixelize(UNIT * 3)};
  height: ${pixelize(UNIT * 3)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: ${MARGING_X_LARGE_PX};
  svg {
    transform: scale(1.4);

    stroke: ${WHITE};
  }
`;

const CustomDivider = styled.div`
  display: block;
  width: 90%;
  height: 2px;
  margin: 0 ${MARGING_XX_LARGE_PX};
  border: 1.2px solid ${GRAY_5};
  border-bottom-color: ${WHITE};
`;

const SpanContainer = styled.div`
  width: 100%;
  height: ${pixelize(UNIT * 8)};
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    span {
      margin-left: ${MARGING_SMALL_PX};
      color: ${BLUE_7};
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 90%;
  margin: ${MARGING_LARGE_PX} ${MARGING_XX_LARGE_PX};
  margin-bottom: ${MARGING_XX_LARGE_PX};
`;

const ScreenLoading = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${pixelize(UNIT * 32)};
  height: ${pixelize(UNIT * 21)};
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  .loading-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${pixelize(UNIT * 4)};
    display: flex;
    align-items: center;
    padding-left: ${PADDING_X_LARGE_PX};
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 600;
    font-size: 1.1em;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.25);
  }
  h3 {
    margin-top: 40px;
  }
  span {
    color: ${DANGER_COLOR_LIGHT};
    font-size: 0.9em;
    margin-bottom: ${MARGING_X_LARGE_PX};
  }
`;

/**
 * 1. 메모가 있는 모든 아티스트를 찾음.
 *    artist {
 *       id: string;
 *       memo: string;
 *       metadata_artist_relation: {
 *         uuid: string;
 *       }[];
 *     }[];
 * 2. 검색된 각 아티스트의 id는 access를 요청할 id.
 * 3. 검색된 각 metadata_artist_relation의 uuid는 where 조건문의 uuid
 * 4. JSON.parse(memo).id를 가져와 data 조건문의 artist_id: memo_id 로 넣어줌.
 */

type BatchData = {
  id: string;
  name: string;
  memo: string;
};

export const BatchEditModal = ({ toClose }: Props) => {
  const [didSearch, setDidSearch] = useState<boolean>(false);
  const [batchData, setBatchData] = useState<BatchData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [extraLoading, setExtraLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [percent, setPercent] = useState<number>(-1);
  const [extraPercent, setExtraPercent] = useState<number>(0);

  const onSearch = async () => {
    setDidSearch(true);
    setLoading(true);
    const { data, errors } = await GetArtistRelationByMemo({});
    if (errors) {
      window.alert("데이터를 가져올 수 없습니다.");
      setLoading(false);
      return;
    }
    if (data) {
      const tempArr: BatchData[] = [];
      data.artist.forEach(({ id, name, memo }) => {
        const tempData: BatchData = {
          id,
          name,
          memo: JSON.parse(memo).id
        };
        tempArr.push(tempData);
      });
      setBatchData(tempArr);
      setLoading(false);
    }
  };

  // TODO
  const onSave = async () => {
    if (window.confirm(`이 작업은 복구가 어렵습니다. 정말로 변경하시겠습니까?`)) {
      const failedList = new Map<string, string>();
      try {
        setPercent(0);
        for (const data of shift(batchData, 1)) {
          const { id, name, memo } = data[0];
          const { data: accessData } = await CreateAccessRecord({ targetId: id, targetTable: TargetTableInput.Artist });
          if (accessData) {
            const accessId = accessData.createAccess.id;
            try {
              await timeout(5000).then(async () => {
                const { errors: BatchError } = await UpdateMetadataArtistRelation({ prevId: id, nextId: memo });
                if (BatchError) {
                  // TODO: 실패 시, 실패한 케이스를 [prevId, nextId]로 저장함.
                  failedList.set(id, memo);
                } else {
                  const memoAccessId = await requestAccessRecord({ targetId: memo, targetTable: TargetTableInput.Artist });
                  await CreateDescArtist({ artistId: memo, type: "name_other", value: name, languageCode: "OO", order: 0 });
                  await DeleteAccessRecord({ id: memoAccessId! });
                }
              });
              await DeleteAccessRecord({ id: accessId });
              setCount(c => c + 1);
              setPercent((count / batchData.length) * 100);
            } catch (err) {
              console.log(err);
              window.alert(`${count + 1}번째 변경에 실패하였습니다.`);
              await DeleteAccessRecord({ id: accessId });
              return;
            }
          }
        }
      } catch (e) {
        window.alert("권한 요청을 실패하였습니다.");
        console.log(e);
        return;
      }
      setPercent(100);
      if (!failedList.size) {
        window.alert("변경이 완료되었습니다.");
        window.location.reload();
      } else {
        if (window.confirm(`${failedList.size}개의 중복된 데이터가 있습니다. 삭제하시겠습니까?`)) {
          setExtraLoading(true);
          let extraCount = 0;
          itiriri(failedList).toMap(async ([prevId, nextId]) => {
            try {
              const { data: accessData } = await CreateAccessRecord({ targetId: prevId, targetTable: TargetTableInput.Artist });
              if (accessData) {
                const { data: prevData } = await GetArtistRelationByMemo({ artistId: prevId });
                if (prevData) {
                  const uuidList = !prevData.artist[0].metadata_artist_relation.length
                    ? []
                    : prevData.artist[0].metadata_artist_relation.map(({ uuid }) => uuid);
                  for (const uuid of uuidList) {
                    try {
                      const { errors } = await UpdateArtistRelation({ uuid, artistId: nextId });
                      if (errors) {
                        await DeleteArtistRelation({ uuid });
                        extraCount += 1;
                        setExtraPercent((extraCount / failedList.size) * 100);
                      }
                    } catch (err) {
                      console.error(err);
                      return;
                    }
                  }
                }
                await DeleteAccessRecord({ id: accessData.createAccess.id });
              }
            } catch (err) {
              console.error(err);
              return;
            }
            setExtraPercent(100);
            setExtraLoading(false);
            window.location.reload();
          });
        } else {
          return;
        }
      }
    } else {
      return;
    }
  };

  return (
    <Layout>
      <Header>
        <h2>일괄 편집</h2>
        <CancelIcon className="cancelIcon" onClick={toClose} />
      </Header>
      <SpanContainer>
        전체 아티스트 중 편집이 필요한 아티스트를 검색합니다.
        <InputButton color="default" onClick={onSearch}>
          <SearchIcon />
        </InputButton>
      </SpanContainer>
      <CustomDivider />
      <SpanContainer>
        {loading ? (
          <Loading className="loading" />
        ) : !didSearch ? (
          <span style={{ color: DANGER_COLOR }}>아티스트를 먼저 검색해주세요.</span>
        ) : !batchData.length ? (
          <span style={{ color: BLACK }}>편집이 필요한 아티스트가 존재하지 않습니다.</span>
        ) : (
          <p>
            편집이 필요한<span>{batchData.length}개</span>의 아티스트가 존재합니다.
          </p>
        )}
      </SpanContainer>
      <CustomDivider />
      <SpanContainer>
        {!batchData.length ? (
          ""
        ) : (
          <p>
            해당 아티스트의 데이터를 메모에 남겨진 아티스트로 변경합니다. 총 작업
            <span style={{ color: DANGER_COLOR }}>{batchData.length - count < 0 ? 0 : batchData.length - count}개</span>
          </p>
        )}
      </SpanContainer>
      <ButtonContainer>
        {percent < 0 ? (
          <Input.Button disabled={!didSearch || !batchData.length} color="danger" isWide onClick={onSave}>
            작업량에 따라 약간의 시간이 소요될 수 있습니다. 확인 후 버튼을 눌러주세요.
          </Input.Button>
        ) : (
          <AnimatedProgress percent={(count / batchData.length) * 100} count={count} total={batchData.length} />
        )}
      </ButtonContainer>
      {!extraLoading ? null : (
        <ScreenLoading>
          <div className="loading-header">추가 작업</div>
          <h3>추가 작업을 진행중입니다.</h3>
          <span>작업이 완료되면 자동으로 새로고침됩니다.</span>
          <AnimatedProgress percent={extraPercent} />
        </ScreenLoading>
      )}
    </Layout>
  );
};
