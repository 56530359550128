import Time from "dayjs";
import { createDuck } from "lib/store/v2";

type Title = {
  type_metadata_title: string;
  value: string;
};

type Genre = {
  id: string;
  name: string;
  typeKind: string;
};

type Url = {
  file: File;
  ext: string;
  typeUrl: string;
  url: string;
  data: string;
};

export type License = {
  notice: string;
  country_code: string;
  type_track: string;
  publish_date: string;
};

export type Extra = {
  record_year: string;
  publish_year: string;
  first_edition: string;
};

export type SingleAlbum = {
  type_metadata_class: string;
  type_metadata_subclass: string;
  title: string;
  no: string;
  releaseDate: string;
  albumUrl: Url;
  artist: {
    artistId: string;
    artistName: string;
    roleId: string;
    roleName: string;
  };
  company: {
    companyId: string;
    name: string;
    license: string;
  };
};

export type Track = {
  type_metadata_class: string;
  type_metadata_subclass: string;
  title: string;
  no: string;

  trackTitle: Title;
  trackUrl: {
    trackMp3: Url;
    trackAac: Url;
    trackFlac: Url;
  };
  trackGenre: Genre[];
  trackMood: Genre[];
  trackLicense: License;
  trackLicenseExtra: Extra;
  sourceUrl: string;

  work: {
    id: string;
    title: string;
  };
};

export enum Page {
  FIRST,
  SECOND
}

const createInitialState = () => ({
  singleAlbum: {} as SingleAlbum,
  trackList: {
    no: "01",
    type_metadata_class: "record",
    type_metadata_subclass: "track",
    title: "",
    trackUrl: {
      trackMp3: {} as Url,
      trackAac: {} as Url,
      trackFlac: {} as Url
    },
    trackGenre: [] as Genre[],
    trackMood: [] as Genre[],
    trackLicense: {
      country_code: "ZZ",
      notice: '{"text": "License : Artists Card"}',
      type_track: "VL",
      publish_date: Time().format("YYYY-MM-DD")
    } as License,
    trackLicenseExtra: {
      record_year: "0",
      publish_year: "0",
      first_edition: "0"
    } as Extra
  } as Track,
  page: Page.FIRST as Page
});

export const SingleAlbumActions = createDuck({
  namespace: "SingleAlbumCreate",
  createInitialState,
  reducers: {
    setSingleAlbum(state, singleAlbum: SingleAlbum) {
      state.singleAlbum = singleAlbum;
    },
    setSingleAlbumNo(state, no: string) {
      state.singleAlbum.no = no;
    },
    setSingleAlbumDescription(state, description: string) {
      state.singleAlbum.title = description;
    },
    setSingleAlbumClass(state) {
      state.singleAlbum.type_metadata_class = "record";
      state.singleAlbum.type_metadata_subclass = "album";
    },
    setSingleAlbumUrl(
      state,
      { file, value, ext, name, valueType }: { file: File; value: string; ext: string; name: string; valueType: string }
    ) {
      state.singleAlbum.albumUrl = {
        file: file,
        ext: ext,
        typeUrl: valueType,
        data: value,
        url: name
      };
    },
    setSingleAlbumRelease(state, releaseDate: string) {
      state.singleAlbum.releaseDate = releaseDate;
    },
    setSingleAlbumCompany(state, { companyId, name, license }: { companyId: string; name: string; license: string }) {
      state.singleAlbum.company = {
        companyId: companyId,
        name: name,
        license: license
      };
    },
    setSingleAlbumArtist(
      state,
      { artistId, artistName, roleId, roleName }: { artistId: string; artistName: string; roleId: string; roleName: string }
    ) {
      state.singleAlbum.artist = {
        artistId,
        artistName,
        roleId,
        roleName
      };
    },
    setSingleTrackGenre(state, genre: Genre[]) {
      state.trackList.trackGenre = genre;
    },
    setSingleTrackMood(state, mood: Genre[]) {
      state.trackList.trackMood = mood;
    },
    setSingleTrackUrl(state, { fileType, url }: { fileType: string; url: string }) {
      switch (fileType) {
        case "mp3high":
          state.trackList.trackUrl.trackMp3 = {
            file: state.trackList.trackUrl.trackMp3.file,
            ext: "mp3",
            typeUrl: "mp3high",
            data: state.trackList.trackUrl.trackMp3.data,
            url: url
          };
          break;
        case "aac":
          state.trackList.trackUrl.trackAac = {
            file: state.trackList.trackUrl.trackAac.file,
            ext: "m4a",
            typeUrl: "aac",
            data: state.trackList.trackUrl.trackAac.data,
            url: url
          };
          break;
        case "flac":
          state.trackList.trackUrl.trackFlac = {
            file: state.trackList.trackUrl.trackFlac.file,
            ext: "flac",
            typeUrl: "flac",
            data: state.trackList.trackUrl.trackFlac.data,
            url: url
          };
          break;
        default:
          break;
      }
    },
    setSingleTrackData(state, { file, fileType, name }: { file: File; fileType: string; name: string }) {
      switch (fileType) {
        case "mp3high":
          state.trackList.trackUrl.trackMp3 = {
            file: file,
            ext: "mp3",
            typeUrl: "mp3high",
            url: name
          } as Url;
          break;
        case "aac":
          state.trackList.trackUrl.trackAac = {
            file: file,
            ext: "m4a",
            typeUrl: "aac",
            url: name
          } as Url;
          break;
        case "flac":
          state.trackList.trackUrl.trackFlac = {
            file: file,
            ext: "flac",
            typeUrl: "flac",
            url: name
          } as Url;
          break;
        default:
          break;
      }
    },
    setSingleTrackTitle(state, title: string) {
      state.trackList.title = title;
    },
    setSingleTrackDescription(state, description: string) {
      state.trackList.trackTitle = {
        type_metadata_title: "description",
        value: description ? decodeURIComponent(description) : ""
      } as Title;
    },
    clearSingleTrack(state) {
      state.trackList = {} as Track;
    },
    setForm(state, page: Page) {
      state.page = page;
    }
  }
});
