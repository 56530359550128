import React from "react";

import { TagAutoComplete } from "App/Atomics/AutoComplete";
import { findAllGenreList } from "./findAllGenreList";

type Props = Pick<
  Parameters<typeof TagAutoComplete>[0],
  "style" | "className" | "isDisabled" | "defaultValue" | "defaultOptions" | "onChange" | "onBlur"
>;

export const AllGenreTagAutoComplete = ({ style, className, isDisabled, defaultValue, defaultOptions, onChange, onBlur }: Props) => {
  return (
    <TagAutoComplete
      placeholder="값을 입력해주세요."
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      delay={200}
      style={style}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      loadInfoList={keyword => findAllGenreList({ keyword })}
    />
  );
};
