import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router";
import { AuthRoute } from "App/Atomics/AuthRoute";
import { AppStore } from "App/Store";
import {
  ALBUMS,
  ALBUM_DETAIL,
  ALBUM_CREATE,
  ARTISTS_EDIT,
  ERROR,
  GENRES,
  PLAYLIST,
  PLAYLIST_CREATE,
  PLAYLIST_DETAIL,
  ROLES,
  ROLES_CREATE,
  SETTLEMENT,
  TRACKS,
  TRACKS_EDIT,
  WORKS,
  ARTISTS_CREATE,
  AUTH_GRANT,
  SEARCH,
  WORKS_CREATE,
  RIGHTS,
  RIGHTS_CREATE,
  SIGN,
  ADMIN_SIGUNUP,
  CSV_UPLOAD,
  GENRES_CREATE,
  WORKS_DETAIL,
  WORKS_TRACK,
  WORKS_TRACK_EDIT,
  LIVE_REPLAY,
  GOODS,
  ARTISTS_RENEWAL,
  ARTISTS,
  LANGUAGE,
  EFFECTS_CREATE,
  SETTLEMENT_MONTHLY,
  SETTLEMENT_DETAIL,
  GOODS_CREATE,
  GOODS_DETAIL,
  PRODUCT_CREATE,
  PRODUCT,
  PRODUCT_DETAIL,
  VIDEO_CREATE,
  ADMIN_RESET_PASSWORD,
  CREATE_AUTO_PLAYLIST
} from "constants/route";
import { UserRole } from "constants/UserRole";
import { AlbumCreate } from "../AlbumCreate";
import { AlbumDetail } from "../AlbumDetail";
import { ArtistEdit } from "../ArtistEdit";
import { Genres } from "../GenreRenewal";
import { Rights } from "../Rights";
import { PlaylistCreate } from "../PlaylistCreate";
import { PlaylistDetail } from "../PlaylistDetail";
import { Playlist } from "../Playlists";
import { Roles } from "../Roles";
import { Settlement } from "../Settlement";
import { TrackEdit } from "../TrackEdit";
import { Tracks } from "../Tracks";
import { ArtistCreate } from "../ArtistCreate";
import { AuthGrant } from "../AuthGrant";
import { Search } from "../Search";
import { WorkCreate } from "../WorkCreate";
import { RightsCreate } from "../RightsCreate";
import {
  allowArtistCreate,
  allowCompanyCreate,
  allowCompanyRead,
  allowGenreCreate,
  allowMetadataCreate,
  allowMetadataUpdate,
  allowPlaylistCreate,
  allowLanguageRead
} from "./allowTables";
import { AdminSignUp } from "../AdminSignUp";
import { CsvUpload } from "../CsvUpload";
import { GenreCreate } from "../GenreCreate";
import { WorkRenewal } from "../WorkRenewal";
import { WorkDetail } from "../WorkDetail";
import { WorkTrack } from "../WorkTrack";
import { WorkTrackEdit } from "../WorkTrackEdit";
import { RoleCreate } from "../RoleCreate";
import { AlbumRenewal } from "../AlbumRenewal";
import { allowRoleCreate } from "./allowTables/allowRoleCreate";
import { LiveReplay } from "../LiveReplay";
import { ArtistRenewal } from "../ArtistRenewal";
import { Artists } from "../Artists";
import { LanguageManage } from "../LanguageManage";
import { EffectCreate } from "../EffectCreate";
import { GoodsCreate } from "../GoodsCreate";
import { Goods } from "../Goods";
import { GoodsDetail } from "../GoodsDetail";
import { ProductCreate } from "../ProductCreate";
import { Product } from "../Product";
import { ProductDetail } from "../ProductDetail";
import { VideoCreate } from "../VideoCreate";
import { ResetPassword } from "../ResetPassword";
import { PlaylistAutoCreate } from "../PlaylistAutoCreate";

const RedirectToSignIn = () => {
  const { pathname, search } = window.location;
  const from = window.encodeURIComponent(`${pathname}${search}`);
  return <Redirect to={`${SIGN}?from=${from}`} />;
};

const isSignedIn = (store: AppStore) => !!store.UserToken.email;
const isAdmin = (store: AppStore) => {
  switch (store.UserToken.role) {
    case UserRole.Master:
    case UserRole.Owner: {
      return true;
    }
  }
  return false;
};

const isArbeit = (store: AppStore) => {
  switch (store.UserToken.role) {
    case UserRole.Master:
    case UserRole.Owner:
    case UserRole.Arbeit: {
      return true;
    }
  }
  return false;
};

const isClient = (store: AppStore) => {
  switch (store.UserToken.role) {
    case UserRole.Client: {
      return true;
    }
  }
  return false;
};

export const AdminRoutes = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        {/* 컨텐츠 등록 관련 페이지 */}
        <AuthRoute path={ARTISTS} component={Artists} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute path={ARTISTS_RENEWAL} component={ArtistRenewal} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={ARTISTS_CREATE}
          component={ArtistCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowArtistCreate(store))}
        />
        <AuthRoute
          path={ALBUM_CREATE}
          component={AlbumCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataCreate(store))}
        />
        <AuthRoute
          path={GOODS_CREATE}
          component={GoodsCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataCreate(store))}
        />
        <AuthRoute
          path={PRODUCT_CREATE}
          component={ProductCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataCreate(store))}
        />
        <AuthRoute
          path={EFFECTS_CREATE}
          component={EffectCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataCreate(store))}
        />
        <AuthRoute
          path={VIDEO_CREATE}
          component={VideoCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataCreate(store))}
        />
        <AuthRoute
          path={GENRES_CREATE}
          component={GenreCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowGenreCreate(store))}
        />
        <AuthRoute
          path={CSV_UPLOAD}
          component={CsvUpload}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataCreate(store))}
        />
        <AuthRoute path={TRACKS} component={Tracks} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={PLAYLIST_CREATE}
          component={PlaylistCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowPlaylistCreate(store))}
        />
        <AuthRoute
          path={CREATE_AUTO_PLAYLIST}
          component={PlaylistAutoCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowPlaylistCreate(store))}
        />

        <AuthRoute path={ROLES} component={Roles} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={ROLES_CREATE}
          component={RoleCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowRoleCreate(store))}
        />
        <AuthRoute path={GENRES} component={Genres} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute path={WORKS} component={WorkRenewal} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={WORKS_DETAIL}
          component={WorkDetail}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataUpdate(store))}
        />
        <AuthRoute path={WORKS_TRACK} component={WorkTrack} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={WORKS_TRACK_EDIT}
          component={WorkTrackEdit}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowMetadataUpdate(store))}
        />
        <AuthRoute
          path={WORKS_CREATE}
          component={WorkCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || allowMetadataCreate(store)}
        />
        <AuthRoute
          path={RIGHTS_CREATE}
          component={RightsCreate}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || allowCompanyCreate(store)}
        />
        <AuthRoute path={AUTH_GRANT} component={AuthGrant} fallback={RedirectToSignIn} exact={true} isAuthorized={isAdmin} />
        {/* 컨텐츠 관리 관련 페이지 */}
        <AuthRoute
          path={ARTISTS_EDIT}
          component={ArtistEdit}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || allowArtistCreate(store)}
        />
        <AuthRoute path={ALBUMS} component={AlbumRenewal} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={ALBUM_DETAIL}
          component={AlbumDetail}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || allowMetadataUpdate(store)}
        />
        <AuthRoute
          path={TRACKS_EDIT}
          component={TrackEdit}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || allowMetadataUpdate(store)}
        />
        <AuthRoute
          path={RIGHTS}
          component={Rights}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || (isArbeit(store) && allowCompanyRead(store))}
        />
        <AuthRoute path={PLAYLIST} component={Playlist} fallback={RedirectToSignIn} exact={true} isAuthorized={isSignedIn} />
        <AuthRoute path={PLAYLIST_DETAIL} component={PlaylistDetail} fallback={RedirectToSignIn} exact={true} isAuthorized={isSignedIn} />
        <AuthRoute path={SEARCH} component={Search} fallback={RedirectToSignIn} exact={true} isAuthorized={isAdmin} />
        <AuthRoute path={LIVE_REPLAY} component={LiveReplay} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute path={GOODS} component={Goods} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute path={GOODS_DETAIL} component={GoodsDetail} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute path={PRODUCT} component={Product} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute path={PRODUCT_DETAIL} component={ProductDetail} fallback={RedirectToSignIn} exact={true} isAuthorized={isArbeit} />
        <AuthRoute
          path={LANGUAGE}
          component={LanguageManage}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || allowLanguageRead(store)}
        />
        {/* 권한 관리 관련 페이지 */}
        <AuthRoute path={ADMIN_SIGUNUP} component={AdminSignUp} fallback={RedirectToSignIn} exact={true} isAuthorized={isAdmin} />
        <AuthRoute path={ADMIN_RESET_PASSWORD} component={ResetPassword} fallback={RedirectToSignIn} exact={true} isAuthorized={isAdmin} />
        {/* 정산 관련 페이지 */}
        <AuthRoute
          path={SETTLEMENT}
          component={Settlement}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || isClient(store)}
        />
        <AuthRoute
          path={SETTLEMENT_MONTHLY}
          component={Settlement}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || isClient(store)}
        />
        <AuthRoute
          path={SETTLEMENT_DETAIL}
          component={Settlement}
          fallback={RedirectToSignIn}
          exact={true}
          isAuthorized={store => isAdmin(store) || isClient(store)}
        />
        <Redirect to={ERROR} />
      </Switch>
    </Suspense>
  );
};
