/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, SelectionSet, IdScalar, IntScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { UuidScalar } from "GraphQL/Scalars";

type Option = Readonly<{
  uuid: string;
  artistId: string;
  order: number;
}>;

export const UpdateGoodsArtist = async (option: Option) => {
  const $id = <Variable name="id" scalar={<UuidScalar isNullable={false} />} value={option.uuid} />;
  const $artistId = <Variable name="artist_id" scalar={<IdScalar isNullable={false} />} value={option.artistId} />;
  const $order = <Variable name="exposure_order" scalar={<IntScalar isNullable />} value={option.order} />;
  const { query, variables } = render(
    <Mutation operationName="UPDATE_GOODS_ARTIST_RELATION">
      <SelectionSet
        name="updateGoods_artist_relation"
        alias="updateGoodsArtist"
        argument={{
          where: { id: $id },
          data: {
            artist: {
              connect: {
                artist_id: $artistId
              }
            },
            exposure_order: $order
          }
        }}
      >
        <Field name="id" />
      </SelectionSet>
    </Mutation>
  );
  type Data = Readonly<{
    updateGoodsArtist: Readonly<{
      id: string;
    }>[];
  }>;
  return await clients.goods.request<Data>(query, variables);
};
