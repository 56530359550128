/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar, ListScalar, IntScalar, StringScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { MetadataPlaylistRelationOrderScalar, MetadataUrlOrderScalar, ValidTypeScalar } from "GraphQL/Scalars";
import { ValidType } from "constants/ValidType";
import { MetadataUrlOrderByInput } from "constants/MetadataUrlOrderByInput";
import { ServiceType } from "constants/ServiceType";
import { GenderType } from "constants/GenderType";

type Option = Readonly<{
  playlistId?: string;
  metadataIdIn?: string[];
  skip?: number;
  count?: number;
  page?: number;
  typeUrl?: string;
  validCheck?: string[];
  artistIdIn?: string[];
  order?: string;
  genreIdIn?: string[];
  validCheckNot?: ValidType;
  isService?: number;
  csvType?: string;
}>;

export type PlaylistDetail = {
  id: string;
  validCheck: ValidType;
  metadata: {
    metadataId: string;
    v1: string;
    no: string;
    title: string;
    validCheck: ValidType;
    subdataUnique: {
      value: string;
    }[];
    titleRelation: {
      name: string;
    }[];
    metadataUrl: {
      id: string;
      url: string;
      typeUrl: string;
    }[];
    genres: {
      id: string;
      genre: {
        genreId: string;
        name: string;
        typeKind: string;
      }[];
    }[];
    artists: {
      id: string;
      artist: {
        name: string;
        artist_history: {
          birth: string;
          death: string;
        }[];
        artist_title: {
          value: string;
        }[];
      }[];
      role: {
        id: string;
        name: string;
        typeRole: string;
      }[];
    }[];
    structures: {
      parent: {
        parentMetadata: {
          title: string;
          typeSubclass: string;
        }[];
      }[];
      list: {
        metadata: {
          title: string;
          typeSubclass: string;
          metadataUrl: {
            id: string;
            typeUrl: string;
            url: string;
          }[];
        }[];
      }[];
    }[];
    license: {
      isService: number;
      notice: string;
      licenseDate: string | null;
      validCheck: ValidType;
    }[];
    licenseExtra: {
      recordYear: number;
      publishYear: number;
      firstEdition: number;
      place: string;
    }[];
    metadata_self_relations_list_metadata: {
      metadata_self_relations_element_metadata: {
        title: string;
      }[];
    }[];
    track_information: {
      tempo: number;
      tonality: string;
      timeSignature: string;
      voiceGender: GenderType;
      extraData: string;
      bitrate: number;
      channel: number;
      duration: number;
    }[];
  }[];
  playlist: {
    playlistId: string;
    name: string;
    kind: string;
    service: ServiceType;
    validCheck: ValidType;
    userRelation: {
      owner: boolean;
      visibleField: string[];
    }[];
  }[];
  order: number;
};

export const MetadataPlaylistRelation = async (option: Option) => {
  const $playlistId = <Variable name="playlist_id" scalar={<IdScalar />} value={option.playlistId} />;
  const $metadataIdIn = (
    <Variable
      name="metadata_id__in"
      scalar={<ListScalar scalar={<IdScalar isNullable={false} />} isNullable />}
      value={option.metadataIdIn}
    />
  );
  const $count = <Variable name="count" scalar={<IntScalar />} value={option.count} />;
  const $skip = <Variable name="skip" scalar={<IntScalar />} value={option.skip} />;
  const $page = <Variable name="page" scalar={<IntScalar />} value={option.page} />;
  const $typeUrl = <Variable name="type_url" scalar={<StringScalar isNullable />} value={option.typeUrl} />;
  const $order = (
    <Variable
      name="order"
      scalar={<ListScalar scalar={<MetadataPlaylistRelationOrderScalar />} />}
      value={!option.order ? "exposure_order__ASC" : option.order}
    />
  );
  const $urlOrder = (
    <Variable name="url_order" scalar={<ListScalar scalar={<MetadataUrlOrderScalar />} />} value={MetadataUrlOrderByInput.type_url_ASC} />
  );
  const $artistIdIn =
    !option.artistIdIn || !option.artistIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.artistIdIn} />;
  const $genreIdIn =
    !option.genreIdIn || !option.genreIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.genreIdIn} />;
  const $validCheck = !option.validCheck ? undefined : <ListScalar scalar={<ValidTypeScalar />} isNullable value={option.validCheck} />;
  const $validCheckNot = <Variable name="validCheckNot" scalar={<ValidTypeScalar />} value={option.validCheckNot} />;
  const $V3 = <Variable name="V3" scalar={<ValidTypeScalar isNullable={false} />} value={ValidType.V3} />;
  const $V3_AND_REMOVE = <ListScalar scalar={<ValidTypeScalar />} isNullable value={[ValidType.V3, ValidType.REMOVE]} />;
  // const $isService = <Variable name="is_service" scalar={<IntScalar isNullable />} value={option.isService} />;
  const { query, variables } = render(
    <Query operationName="METADATA_PLAYLIST_RELATION">
      <SelectionSet
        name="metadata_playlist_relation"
        alias="metadataPlaylistRelation"
        argument={{
          where: {
            playlist__some: {
              playlist_id: $playlistId,
              valid_check__not: $validCheckNot
            },
            metadata__some: {
              metadata_id__in: $metadataIdIn,
              valid_check__in: $validCheck,
              metadata_url__some: {
                type_url: $typeUrl,
                valid_check__in: $V3_AND_REMOVE
              },
              metadata_artist_relation__some: {
                artist__some: {
                  artist_id__in: $artistIdIn
                }
              },
              metadata_genre_relation__some: {
                genre__some: {
                  genre_id__in: $genreIdIn
                }
              },
              // track_license__some: {
              //   is_service: $isService
              // },
              ...(option.csvType === "youtube" && {
                track_license__some: {
                  type_track__not: "PD"
                }
              })
            }
          },
          first: $count,
          skip: $skip,
          page: $page,
          orderBy: $order,
          group: true
        }}
      >
        <Field name="id" />
        <Field name="valid_check" alias="validCheck" />
        {/* METADATA */}
        <SelectionSet name="metadata">
          <Field name="metadata_id" alias="metadataId" />
          <Field name="compatibility_v1_id" alias="v1" />
          <Field name="no" />
          <Field name="title" />
          <Field name="valid_check" alias="validCheck" />
          {/* TITLE */}
          <SelectionSet
            name="metadata_title"
            alias="titleRelation"
            argument={{
              where: {
                type_metadata_title: "name",
                language__some: {
                  language_code: "KO"
                },
                type_metadata_title__not_starts_with: "search"
              }
            }}
          >
            <Field name="value" alias="name" />
          </SelectionSet>
          {/* SUBDATA */}
          <SelectionSet name="metadata_subdata_unique" alias="subdataUnique" argument={{ where: { type_subdata_field: "source_data" } }}>
            <Field name="value" />
          </SelectionSet>
          {/* URL */}
          <SelectionSet
            name="metadata_url"
            alias="metadataUrl"
            argument={{ where: { valid_check: $V3, type_url: $typeUrl }, orderBy: $urlOrder }}
          >
            <Field name="id" />
            <Field name="url" />
            <Field name="type_url" alias="typeUrl" />
            <Field name="valid_check" />
          </SelectionSet>
          {/* GENRE */}
          <SelectionSet
            name="metadata_genre_relation"
            alias="genres"
            argument={{ where: { valid_check__not: $validCheckNot }, first: 1000 }}
          >
            <Field name="id" />
            <SelectionSet name="genre">
              <Field name="genre_id" alias="genreId" />
              <Field name="name" />
              <Field name="type_kind" alias="typeKind" />
            </SelectionSet>
          </SelectionSet>
          {/* ARTIST */}
          <SelectionSet name="metadata_artist_relation" alias="artists" argument={{ where: { valid_check__not: $validCheckNot } }}>
            <Field name="id" />
            <SelectionSet name="artist">
              <Field name="name" />
              <SelectionSet name="artist_history">
                <Field name="birth_date" alias="birth" />
                <Field name="death_date" alias="death" />
              </SelectionSet>
              <SelectionSet
                name="artist_title"
                argument={{
                  where: {
                    type_artist_title: "name",
                    type_artist_title__not_starts_with: "search",
                    language__some: { language_code: "KO" }
                  }
                }}
              >
                <Field name="value" />
              </SelectionSet>
            </SelectionSet>
            <SelectionSet name="role">
              <Field name="role_id" alias="id" />
              <Field name="name" />
              <Field name="type_role_class" alias="typeRole" />
            </SelectionSet>
          </SelectionSet>
          {/* STRUCTURE */}
          <SelectionSet name="metadata_structure" alias="structures">
            <SelectionSet name="parent">
              <SelectionSet name="metadata" alias="parentMetadata">
                <Field name="title" />
                <Field name="type_metadata_subclass" alias="typeSubclass" />
              </SelectionSet>
            </SelectionSet>
            <SelectionSet name="list">
              <SelectionSet name="metadata">
                <Field name="title" />
                <Field name="type_metadata_subclass" alias="typeSubclass" />
                <SelectionSet
                  name="metadata_url"
                  alias="metadataUrl"
                  argument={{
                    where: {
                      type_url: "cover"
                    }
                  }}
                >
                  <Field name="id" />
                  <Field name="type_url" alias="typeUrl" />
                  <Field name="url" />
                </SelectionSet>
              </SelectionSet>
            </SelectionSet>
          </SelectionSet>
          {/* LICENSE */}
          <SelectionSet
            name="track_license"
            alias="license"
            argument={{
              where: {
                country_code__in: ["KR", "ZZ", "CN"]
              }
            }}
          >
            <Field name="is_service" alias="isService" />
            <Field name="notice" />
            <Field name="license_date" alias="licenseDate" />
            <Field name="valid_check" alias="validCheck" />
            <Field name="country_code" alias="countryCode" />
          </SelectionSet>
          {/* EXTRA */}
          <SelectionSet name="track_license_extra" alias="licenseExtra">
            <Field name="record_year" alias="recordYear" />
            <Field name="publish_year" alias="publishYear" />
            <Field name="first_edition" alias="firstEdition" />
            <Field name="place" />
          </SelectionSet>
          {/* WORK */}
          <SelectionSet name="metadata_self_relations_list_metadata">
            <SelectionSet name="metadata_self_relations_element_metadata">
              <Field name="title" />
            </SelectionSet>
          </SelectionSet>
          {/* TRACK_INFORMATION */}
          <SelectionSet name="track_information">
            <Field name="tempo" />
            <Field name="tonality" />
            <Field name="time_signature" alias="timeSignature" />
            <Field name="voice_gender" alias="voiceGender" />
            <Field name="extra_data" alias="extraData" />
            <Field name="sample_bitrate" alias="bitrate" />
            <Field name="duration" />
            <Field name="channel" />
          </SelectionSet>
        </SelectionSet>
        {/* PLAYLIST */}
        <SelectionSet name="playlist">
          <Field name="playlist_id" alias="playlistId" />
          <Field name="name" />
          <Field name="kind" />
          <Field name="service" />
          <Field name="valid_check" alias="validCheck" />
          <SelectionSet name="playlist_users_relation" alias="userRelation">
            <Field name="is_onwer" alias="owner" />
            <Field name="visible_field" alias="visibleField" />
          </SelectionSet>
        </SelectionSet>
        <Field name="exposure_order" alias="order" />
      </SelectionSet>
      {/* EDGE */}
      <SelectionSet
        name="edge"
        argument={{
          node: "metadata_playlist_relation",
          page: $page,
          first: $count,
          where: {
            metadata_playlist_relation: {
              playlist__some: {
                playlist_id: $playlistId,
                valid_check__not: $validCheckNot
              },
              metadata__some: {
                valid_check__in: $validCheck,
                metadata_artist_relation__some: {
                  artist__some: {
                    artist_id__in: $artistIdIn
                  }
                },
                metadata_genre_relation__some: {
                  genre__some: {
                    genre_id__in: $genreIdIn
                  }
                }
              }
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataPlaylistRelation: PlaylistDetail[];
    edge: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string;
      endCursor: string;
      currentPage: number;
      pageCount: number;
    };
  }>;
  return await clients.metadata.request<Data>(query, variables);
};
