import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "App/Atomics/Input";
import { ReactComponent as CancelIcon } from "assets/icons/cancel-button.svg";
import { BLUE_6, GRAY_6 } from "constants/baseColor";
import { usePlaylistsDispatch } from "App/Routes/Playlists/Store";
import { PlaylistsActions } from "App/Routes/Playlists/Store/Playlist";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteAccessRecord } from "GraphQL/Queries";
import { UpdatePlaylist } from "GraphQL/Queries/Playlist";
import { Toast } from "lib/toast";

type Props = {
  index: number;
  id: string;
  price?: number;
  remark?: string;
  toClose: () => void;
};

export const EditModal = ({ index, id, price: initialPrice, remark: initialRemark, toClose }: Props) => {
  const dispatch = usePlaylistsDispatch();
  const [price, setPrice] = useState<number | undefined>(initialPrice);
  const [remark, setRemark] = useState<string | undefined>(initialRemark);
  const onSave = async () => {
    const accessId = await requestAccessRecord({ targetId: id, targetTable: TargetTableInput.Playlist });
    if (accessId) {
      try {
        const { errors } = await UpdatePlaylist({ id, price, remark: !remark ? "" : remark });
        if (errors) {
          throw new Error(errors[0].message);
        }
        Toast.primary("수정되었습니다.");
        dispatch([
          PlaylistsActions.setSave(),
          PlaylistsActions.editPlaylistPriceByIndex({ index, price: price ?? 0 }),
          PlaylistsActions.editPlaylistRemarkByIndex({ index, remark: remark ?? "" })
        ]);
        toClose();
      } catch (err) {
        console.log(err);
        Toast.error("오류가 발생하였습니다.");
        return;
      } finally {
        await DeleteAccessRecord({ id: accessId });
      }
    }
  };

  const onClearAndClose = () => {
    dispatch(PlaylistsActions.setClear());
    toClose();
  };

  return (
    <Layout>
      <Header>
        <h3>가격 및 설명 수정</h3>
        <CancelIcon className="cancelIcon" onClick={onClearAndClose} />
      </Header>
      <Section>
        <span>가격 (원)</span>
        <Input.Text
          className="input-price"
          pattern={"^[0-9]*$"}
          placeholder={"가격을 입력해주세요"}
          defaultValue={!price ? undefined : price.toString()}
          onBlur={val => setPrice(parseInt(val, 10) ?? 0)}
        />
        <span>설명 (remark)</span>
        <textarea
          className="input-remark"
          placeholder={"설명을 입력해주세요"}
          defaultValue={!remark ? undefined : window.decodeURIComponent(remark)}
          onBlur={e => setRemark(e.target.value)}
        />
      </Section>
      <ButtonGroup>
        <Input.Button onClick={onClearAndClose}>취소</Input.Button>
        <Input.Button color="primary" onClick={onSave}>
          저장
        </Input.Button>
      </ButtonGroup>
    </Layout>
  );
};

const Layout = styled.div`
  width: 512px;
  height: 410px;
  font-size: 0.8rem;
`;

const Header = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .cancelIcon {
    position: absolute;
    right: 3%;
    width: 3em;
    height: 3em;
    padding: 1em;
    fill: black;
    cursor: pointer;
    &:hover {
      fill: ${GRAY_6};
    }
  }
`;

const Section = styled.section`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    color: #191919;
    margin-left: 0.25rem;
    margin-bottom: 0.3rem;
  }

  .input-price {
    border: 1px solid hsl(210 6% 83%);
    padding: 0.6rem;
    transition: border-color 0.15s;
    margin-bottom: 0.5rem;
    &:hover {
      border-color: hsl(210 6% 70%);
    }
    &:focus {
      border-color: ${BLUE_6};
      box-shadow: 0px 0px 0px 1.2px ${BLUE_6};
    }
  }

  .input-remark {
    border: 1px solid hsl(210 6% 83%);
    padding: 0.6rem;
    border-radius: 5px;
    height: 9rem;
    transition: border-color 0.15s;
    &:hover {
      border-color: hsl(210 6% 70%);
    }
    &:focus {
      border-color: ${BLUE_6};
      box-shadow: 0px 0px 0px 1.2px ${BLUE_6};
    }
  }
`;

const ButtonGroup = styled.div`
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem 1rem 0;
`;
