import React from "react";
import styled from "styled-components";
import { Table } from "App/Atomics/Table";
import { formatDuration } from "utils/format/index";

type InfoProps = Readonly<{
  index: number;
  info: {
    duration?: number;
  }[];
}>;

export const Info = ({ index, info }: InfoProps) => {
  if (!info?.length) {
    return <Table.Data>{}</Table.Data>;
  }
  const { duration } = info[0];
  return (
    <Table.Data key={index}>
      <InfoWrapper>{formatDuration(duration ?? 0)}</InfoWrapper>
    </Table.Data>
  );
};

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
