import React from "react";
import styled from "styled-components";
import { useAppSelector } from "App/Store";
import { TextDivider, IconInputText } from "../..";
import { ReactComponent as WriteIcon } from "assets/icons/write.svg";
import { pixelize, PADDING_XX_LARGE_PX, MARGING_LARGE_PX, MARGING_SMALL_PX, UNIT } from "constants/size";
import { GRAY_8 } from "constants/baseColor";
import { Input } from "App/Atomics/Input";
import { useToggle } from "lib/use-toggle";
import { Modal } from "lib/modal";
import { NameChangeModal } from "../../Modals";

const Layout = styled.div`
  width: 30%;
  height: 100%;
  min-width: ${pixelize(UNIT * 16)};
  padding: 0 ${PADDING_XX_LARGE_PX};
  text-align: center;

  h4 {
    margin: ${MARGING_LARGE_PX} 0 ${MARGING_SMALL_PX} 0;
    color: ${GRAY_8};
  }
`;

const UserInfoBox = styled.form`
  text-align: start;
`;

export const LeftTemplate = () => {
  const { email, name } = useAppSelector(store => store.UserToken);
  const nameChangeModal = useToggle();
  return (
    <Layout>
      <TextDivider>
        <span>USER</span>
        <div />
      </TextDivider>
      <UserInfoBox>
        <h4>이름</h4>
        <IconInputText>
          <TextBox key={!name ? undefined : name} disabled defaultValue={!name ? undefined : name} placeholder="정보 없음" />
          <WriteIcon onClick={nameChangeModal.on} />
        </IconInputText>
        <h4>이메일</h4>
        <TextBox disabled defaultValue={email} placeholder="정보 없음" />
        <h4>연락처</h4>
        <IconInputText>
          <Input.Text isDisabled autoComplete="off" defaultValue="" placeholder="기능 준비 중..." />
          <WriteIcon />
        </IconInputText>
        <TextDivider>
          <span>BANK</span>
          <div />
        </TextDivider>
        <h4>은행</h4>
        <Input.Text isDisabled placeholder="기능 준비 중..." />
        <h4>계좌번호</h4>
        <Input.Text isDisabled placeholder="기능 준비 중..." />
      </UserInfoBox>
      <Modal isOpen={nameChangeModal.isToggled} onClose={nameChangeModal.off}>
        <NameChangeModal name={name} toClose={nameChangeModal.off} />
      </Modal>
    </Layout>
  );
};

const TextBox = styled.input`
  width: 100%;
  margin: 4px 0;
  padding: 9.6px;
  border: 1px solid hsl(210 6% 83%);
  border-radius: 8px;
`;
