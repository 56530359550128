import React, { useState } from "react";
import styled from "styled-components";

import {
  MARGING_LARGE_PX,
  MARGING_SMALL_PX,
  PADDING_XX_LARGE_PX,
  pixelize,
  UNIT,
  MARGING_XX_LARGE_PX,
  MARGING_X_LARGE_PX,
  PADDING_LARGE_PX
} from "constants/size";
import { Input } from "App/Atomics/Input";
import { useAsyncEffect } from "lib/use-async-effect";
import { GENRE_LIST, MOOD_LIST } from "constants/storageKey";
import { GetGenreList, GetMoodList } from "GraphQL/Queries/Genre";
import { Tag } from "App/Atomics/Tag";
import { GRAY_4 } from "constants/baseColor";
import { LOADING, LoadingTemplate } from "App/Templates/LoadingTemplate";
import { GetMoodParentList } from "GraphQL/Queries/Genre/GetMoodParentList";
import { useCsvUploadDispatch } from "../../Store";
import { CsvUploadActions } from "../../Store/CsvUpload";

type Genre = Readonly<{
  id: string;
  typeKind: string;
  name: string;
  genre_self_relation_child?: {
    parent?: {
      id: string;
      name?: string;
    }[];
    child?: {
      id: string;
      name?: string;
    }[];
  }[];
}>;

type Props = Readonly<{
  genreList: Genre[];
  index: number;
  trackIndex: number;
  toClose: () => void;
}>;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow: overlay;

  width: ${pixelize(UNIT * 58)};
  height: ${pixelize(UNIT * 50)};
  padding: ${PADDING_XX_LARGE_PX};

  h3 {
    margin-top: ${MARGING_SMALL_PX};
    margin-bottom: ${MARGING_LARGE_PX};
  }

  .suggestSpan {
    display: flex;
    flex-direction: row;
    margin-bottom: ${MARGING_XX_LARGE_PX};

    & > .autocomplete {
      width: 100%;
      margin-right: ${MARGING_LARGE_PX};
    }

    button {
      height: fit-content;
    }
  }
`;

const FilterGroup = styled.section`
  margin-bottom: ${MARGING_LARGE_PX};

  hr {
    margin-bottom: ${MARGING_LARGE_PX};
  }
`;

const ButtonGroup = styled(Input.Group)`
  display: flex;
  margin-top: auto;
  margin-left: auto;

  & > button {
    width: ${pixelize(UNIT * 5)};
  }
`;

const InputTagSelect = styled(Input.TagSelect)`
  margin: ${MARGING_LARGE_PX} 0;
  border-color: ${GRAY_4};
`;

const TagContainer = styled.div<{ type: string }>`
  width: 100%;
  height: 40%;
  overflow: scroll;
  display: grid;
  grid-template-columns: ${props => (props.type === "genre" ? "repeat(4, 24.5%)" : "repeat(7, 14%)")};
  grid-gap: 5px;
  box-shadow: 0 0 2px ${GRAY_4};
  margin-bottom: ${MARGING_LARGE_PX};
  padding: ${PADDING_LARGE_PX};
`;

const CustomDivider = styled.div`
  border: 1px solid ${GRAY_4};
  margin: ${MARGING_X_LARGE_PX} 0;
  width: 100%;
`;

export const GenreModal = ({ genreList: initialGenre, index, trackIndex, toClose }: Props) => {
  // 기본(최종) 장르/무드 리스트
  const dispatch = useCsvUploadDispatch();
  const [genreList, setGenreList] = useState(
    initialGenre.filter(genre => genre.typeKind === "genre").length
      ? initialGenre.filter(genre => genre.typeKind === "genre")
      : ([] as Genre[])
  );
  const [moodList, setMoodList] = useState(
    initialGenre.filter(genre => genre.typeKind === "mood").length
      ? initialGenre.filter(genre => genre.typeKind === "mood")
      : ([] as Genre[])
  );
  const [loading, setLoading] = useState<LOADING>(LOADING.UNLOAD);

  // 모든 장르/무드를 담을 리스트
  const [filteredGenreList, setFilteredGenreList] = useState<Genre[]>(() => {
    const localGenre = window.localStorage.getItem(GENRE_LIST);
    if (!localGenre) {
      return [];
    }
    const parsedLocalGenre = JSON.parse(localGenre);
    return parsedLocalGenre;
  });
  const [filteredMoodList, setFilteredMoodList] = useState<Genre[]>(() => {
    const localMood = window.localStorage.getItem(MOOD_LIST);
    if (!localMood) {
      return [];
    }
    const parsedLocalMood = JSON.parse(localMood);
    return parsedLocalMood;
  });

  const toSave = () => {
    dispatch(CsvUploadActions.setCsvTrackGenre(index, trackIndex, genreList.concat(moodList)));
    toClose();
  };

  useAsyncEffect(
    async isMounted => {
      if (isMounted()) {
        // 로컬스토리지에 데이터가 없으면 쿼리 실행
        if (!filteredGenreList.length) {
          const { data } = await GetGenreList();
          if (data) {
            window.localStorage.setItem(GENRE_LIST, JSON.stringify(data.genre.filter(item => item.typeKind === "genre")));
            setFilteredGenreList(data.genre.filter(item => item.typeKind === "genre"));
          }
        }
        if (!filteredMoodList.length) {
          const { data: childData } = await GetMoodList();
          const { data: parentData } = await GetMoodParentList();
          if (childData && parentData) {
            const noChildParentData = parentData.genre.map(item => ({
              id: item.id,
              name: item.name,
              typeKind: item.typeKind,
              genre_self_relation_child: item.genre_self_relation_child as any[]
            }));
            const moodArr = childData.genre.concat(noChildParentData);
            window.localStorage.setItem(MOOD_LIST, JSON.stringify(moodArr));
            setFilteredMoodList(moodArr);
          }
        }
      }
      setLoading(LOADING.ONLOAD);
    },
    [filteredGenreList, filteredMoodList]
  );
  return (
    <Layout>
      <h3>장르 검색</h3>
      <FilterGroup>
        <InputTagSelect
          className="autocomplete"
          optionList={filteredGenreList
            .filter(({ genre_self_relation_child }) => genre_self_relation_child && genre_self_relation_child.length)
            .map(({ id, name, typeKind, genre_self_relation_child }) => {
              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                const parentName = genre_self_relation_child[0].parent[0].name;
                const mixedName = name === parentName ? name : `${name} (${parentName})`;
                return { id, name: mixedName, typeKind };
              }
              return { id, name, typeKind };
            })}
          autoFocus
          value={genreList}
          onChange={info => {
            if (info) {
              setGenreList(info.map(({ id, name }) => ({ id, name, typeKind: "genre" })));
            } else {
              // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
            }
          }}
        />
      </FilterGroup>
      {loading === LOADING.UNLOAD ? (
        <LoadingTemplate loading={loading} />
      ) : (
        <TagContainer type="genre">
          {filteredGenreList
            .filter(({ id, genre_self_relation_child }) => {
              if (!genre_self_relation_child || !genre_self_relation_child.length) {
                return false;
              }
              return !genreList.find(item => item.id === id);
            })
            .map((genre, i) => {
              const { name, genre_self_relation_child } = genre;
              if (genre_self_relation_child?.length && genre_self_relation_child[0].parent?.length) {
                const mixedName = `${name} (${genre_self_relation_child[0].parent[0].name})`;
                const mixedGenre = {
                  ...genre,
                  name: mixedName
                };
                return (
                  <Tag
                    key={i}
                    style={{ width: "95%", textAlign: "center" }}
                    color="primary"
                    filled={false}
                    onClick={() => setGenreList([...genreList, mixedGenre])}
                  >
                    {mixedName}
                  </Tag>
                );
              }
              return (
                <Tag
                  key={i}
                  style={{ width: "95%", textAlign: "center" }}
                  color="primary"
                  filled={false}
                  onClick={() => setGenreList([...genreList, genre])}
                >
                  {genre.name}
                </Tag>
              );
            })}
        </TagContainer>
      )}
      <CustomDivider />
      <h3>무드 검색</h3>
      <FilterGroup>
        <InputTagSelect
          className="autocomplete"
          optionList={filteredMoodList.map(({ id, name, genre_self_relation_child }) => {
            let isDuplicateMood = moodList.some(list =>
              list.genre_self_relation_child?.find(({ parent, child }) => {
                if (parent && parent.length && genre_self_relation_child) {
                  const check1 = genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                  const check2 = parent.find(item => item.id === id);
                  return check1 || check2;
                }
                if (child && child.length) {
                  return child.find(item => item.id === id);
                }
              })
            );
            return { id, name, isDisabled: isDuplicateMood };
          })}
          value={moodList}
          onChange={info => {
            if (info) {
              const selectedMood = filteredMoodList.filter(mood => info.find(item => item.id === mood.id));
              setMoodList(selectedMood);
            } else {
              // TODO: 이미 입력을 해놓은 상태에서 지운 경우?
            }
          }}
        />
      </FilterGroup>
      {loading === LOADING.UNLOAD ? (
        <LoadingTemplate loading={loading} />
      ) : (
        <TagContainer type="mood">
          {filteredMoodList
            .filter(mood => !moodList.find(item => item.id === mood.id))
            .map((mood, i) => (
              <Tag
                key={i}
                disabled={moodList.some(list =>
                  list.genre_self_relation_child?.find(({ parent, child }) => {
                    if (parent && parent.length && mood.genre_self_relation_child) {
                      const check1 = mood.genre_self_relation_child.find(item => item.parent?.[0].id === parent[0].id);
                      const check2 = parent.find(item => item.id === mood.id);
                      return check1 || check2;
                    }
                    if (child && child.length) {
                      return child.find(item => item.id === mood.id);
                    }
                  })
                )}
                style={{ width: "90%", textAlign: "center" }}
                filled={false}
                color="primary"
                onClick={() => setMoodList([...moodList, mood])}
              >
                {mood.name}
              </Tag>
            ))}
        </TagContainer>
      )}
      <ButtonGroup>
        <Input.Button onClick={toSave}>입력</Input.Button>
        <Input.Button onClick={toClose}>취소</Input.Button>
      </ButtonGroup>
    </Layout>
  );
};
