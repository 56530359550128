/** @jsx jsx */

import {
  jsx,
  render,
  Query,
  SelectionSet,
  Field,
  Variable,
  IdScalar,
  StringScalar,
  IntScalar,
  ListScalar,
  EnumScalar,
  BooleanScalar
} from "graphql-jsx";
import { clients } from "utils/clients";
import { ValidType } from "constants/ValidType";
import { ValidTypeScalar } from "GraphQL/Scalars";
import { Edge } from "models/Edge";

type Option = Readonly<{
  page?: number;
  first?: number;
  artistId?: string;
  artistIdIn?: string;
  roleIdIn?: string[];
  genreIdIn?: string[];
  rightsIdIn?: string[];
  name?: string;
  order?: string;
  validCheckIn?: string[];
  urlExist?: boolean;
}>;

export type RoleRelation = {
  uuid: string;
  order: number;
  role: {
    id: string;
    name: string;
    typeRoleClass?: string;
  }[];
};

export type GenreRelation = {
  uuid: string;
  order: number;
  genre: {
    id: string;
    name: string;
    typeKind: string;
  }[];
};

export type CompanyRelation = {
  uuid: string;
  company: {
    id: string;
    name: string;
    displayName: string;
  }[];
};

export type Url = {
  uuid: string;
  typeUrl?: string;
  url?: string;
  validCheck: ValidType;
  order?: number;
};

export type History = {
  uuid: string;
  birthDate?: string;
  birthPlace?: string;
  deathDate?: string;
  deathPlace?: string;
};

export type Artist = {
  id: string;
  name: string;
  typeArtistClass: string;
  memo: string;
  validCheck: ValidType;
  roleRelation: RoleRelation[];
  genreRelation: GenreRelation[];
  companyRelation: CompanyRelation[];
  weight: string;
  artistUrl: Url[];
  artistHistory: History[];
};

export const GetArtistRenewal = async (option: Option) => {
  const $first = <Variable name="first" scalar={<IntScalar isNullable />} value={option.first} />;
  const $page = <Variable name="page" scalar={<IntScalar isNullable />} value={option.page} />;
  const $artistId = <Variable name="artistId" scalar={<IdScalar isNullable />} value={option.artistId} />;
  const $artistIdIn =
    !option.artistIdIn || !option.artistIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.artistIdIn} />;
  const $name = <Variable name="artist_name" scalar={<StringScalar isNullable />} value={option.name} />;
  const $order = (
    <EnumScalar type="ArtistOrderByInput" value={option.order ?? "artist_id__DESC"} items={[option.order ?? "artist_id__DESC"]} />
  );
  const $exposureOrder = <EnumScalar type="ExposureOrder" value={"exposure_order__DESC"} items={["exposure_order__DESC"]} />;
  const $urlExist = <Variable name="url_exist" scalar={<BooleanScalar isNullable />} value={option.urlExist} />;
  const $roleIdIn =
    !option.roleIdIn || !option.roleIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.roleIdIn} />;
  const $genreIdIn =
    !option.genreIdIn || !option.genreIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.genreIdIn} />;
  const $rightsIdIn =
    !option.rightsIdIn || !option.rightsIdIn.length ? undefined : <ListScalar scalar={<IdScalar />} isNullable value={option.rightsIdIn} />;
  const $validCheckIn = !option.validCheckIn ? (
    undefined
  ) : (
    <ListScalar scalar={<ValidTypeScalar />} isNullable value={option.validCheckIn} />
  );

  const { query, variables } = render(
    <Query operationName="GET_ARTIST">
      <SelectionSet
        name="artist"
        argument={{
          group: true,
          first: $first,
          page: $page,
          orderBy: $order,
          where: {
            artist_id: $artistId,
            artist_id__in: $artistIdIn,
            artist_title__some: {
              type_artist_title__starts_with: !option.name ? undefined : "name",
              tsvec_words__search: $name
            },
            valid_check__in: $validCheckIn,
            artist_url__some: {
              id__exist: $urlExist
            },
            artist_role_relation__some: {
              role__some: {
                role_id__in: $roleIdIn
              }
            },
            artist_genre_relation__some: {
              genre__some: {
                genre_id__in: $genreIdIn
              }
            },
            artist_company_relation__some: {
              company__some: {
                company_id__in: $rightsIdIn
              }
            }
          }
        }}
      >
        <Field name="artist_id" alias="id" />
        <Field name="name" />
        <Field name="type_artist_class" alias="typeArtistClass" />
        <Field name="memo" />
        <Field name="weight" />
        <Field name="valid_check" alias="validCheck" />
        {/* ROLE */}
        <SelectionSet name="artist_role_relation" alias="roleRelation">
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="role">
            <Field name="role_id" alias="id" />
            <Field name="name" />
            <Field name="type_role_class" alias="typeRoleClass" />
          </SelectionSet>
        </SelectionSet>
        {/* GENRE */}
        <SelectionSet
          name="artist_genre_relation"
          alias="genreRelation"
          argument={{
            orderBy: $exposureOrder,
            where: { genre__some: { type_kind: "genre" } }
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="exposure_order" alias="order" />
          <SelectionSet name="genre">
            <Field name="genre_id" alias="id" />
            <Field name="name" />
            <Field name="type_kind" alias="typeKind" />
          </SelectionSet>
        </SelectionSet>
        {/* COMPANY */}
        <SelectionSet name="artist_company_relation" alias="companyRelation">
          <Field name="id" alias="uuid" />
          <SelectionSet name="company">
            <Field name="company_id" alias="id" />
            <Field name="name" />
            <Field name="display_name" alias="displayName" />
          </SelectionSet>
        </SelectionSet>
        {/* URL */}
        <SelectionSet
          name="artist_url"
          alias="artistUrl"
          argument={{
            orderBy: $exposureOrder
          }}
        >
          <Field name="id" alias="uuid" />
          <Field name="type_url" alias="typeUrl" />
          <Field name="url" />
          <Field name="valid_check" alias="validCheck" />
          <Field name="exposure_order" alias="order" />
        </SelectionSet>
        {/* HISTORY */}
        <SelectionSet name="artist_history" alias="artistHistory">
          <Field name="id" alias="uuid" />
          <Field name="birth_date" alias="birthDate" />
          <Field name="birth_place" alias="birthPlace" />
          <Field name="death_date" alias="deathDate" />
          <Field name="death_place" alias="deathPlace" />
        </SelectionSet>
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          node: "artist",
          group: true,
          first: $first,
          page: $page,
          where: {
            artist: {
              artist_id: $artistId,
              artist_id__in: $artistIdIn,
              artist_title__some: {
                type_artist_title__starts_with: !option.name ? undefined : "name",
                tsvec_words__search: $name
              },
              valid_check__in: $validCheckIn,
              artist_url__some: {
                id__exist: $urlExist
              },
              artist_role_relation__some: {
                role__some: {
                  role_id__in: $roleIdIn
                }
              },
              artist_genre_relation__some: {
                genre__some: {
                  genre_id__in: $genreIdIn
                }
              },
              artist_company_relation__some: {
                company__some: {
                  company_id__in: $rightsIdIn
                }
              }
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = {
    artist: Artist[];
    edge: Edge;
  };
  return await clients.artist.request<Data>(query, variables);
};
