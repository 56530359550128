import React, { ReactNode, MouseEvent } from "react";
import styled from "styled-components";
import classNames from "classnames";

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  INFO_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
  DANGER_COLOR,
  PRIMARY_COLOR_LIGHT,
  SECONDARY_COLOR_LIGHT,
  INFO_COLOR_LIGHT,
  SUCCESS_COLOR_LIGHT,
  WARNING_COLOR_LIGHT,
  DANGER_COLOR_LIGHT,
  PRIMARY_COLOR_DISABLED,
  SECONDARY_COLOR_DISABLED,
  INFO_COLOR_DISABLED,
  WARNING_COLOR_DISABLED,
  DANGER_COLOR_DISABLED,
  PRIMARY_COLOR_DARK,
  SECONDARY_COLOR_DARK,
  INFO_COLOR_DARK,
  SUCCESS_COLOR_DARK,
  WARNING_COLOR_DARK,
  DANGER_COLOR_DARK,
  transparentize,
  FONT_BASIC
} from "constants/color";
import { WHITE } from "constants/baseColor";
import { PADDING_LARGE_PX, PADDING_X_LARGE_PX, BORDER_RADIUS_PX } from "constants/size";

export type Color = "default" | "primary" | "secondary" | "info" | "success" | "warning" | "danger";

export type ButtonProps = Styleable &
  Readonly<{
    type?: "button" | "submit" | "reset";
    title?: string;
    color?: Color;
    isWide?: boolean;
    isFill?: boolean;
    children: ReactNode;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
  }>;

const Layout = styled.button<{ isWide: boolean; isFill: boolean; disabled: boolean }>`
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  display: block;
  width: ${props => (props.isWide ? "100%" : "min-content")};
  padding: ${PADDING_LARGE_PX} ${PADDING_X_LARGE_PX};
  border-radius: ${BORDER_RADIUS_PX};
  background-color: transparent;
  color: ${FONT_BASIC};
  white-space: nowrap;

  transition: all 0.2s;

  &:hover {
    color: ${transparentize(FONT_BASIC, 50)};
  }

  &.default {
    background-image: ${props =>
      props.disabled ? `linear-gradient(to bottom, #8c7fee, #797ebe)` : `linear-gradient(to bottom, #6a5fdd, #241d8c)`};
    color: ${WHITE};

    &:hover {
      background-color: ${transparentize(WHITE, 30)};
      background-blend-mode: soft-light;
    }
  }

  &.primary {
    color: ${props => (props.isFill ? WHITE : PRIMARY_COLOR)};
    background-color: ${props => (props.disabled ? PRIMARY_COLOR_DISABLED : props.isFill ? PRIMARY_COLOR : WHITE)};
    border: ${props => (props.isFill ? "none" : `1.5px solid ${PRIMARY_COLOR}`)};

    ${props =>
      !props.disabled
        ? `
        &:hover {
          background-color: ${props.isFill ? PRIMARY_COLOR_LIGHT : WHITE};
          border: ${props.isFill ? "none" : `1.5px solid ${PRIMARY_COLOR_LIGHT}`};
          color: ${props.isFill ? WHITE : PRIMARY_COLOR_LIGHT};
        }
        &:active {
          color: ${WHITE};
          background-color: ${PRIMARY_COLOR_DARK};
        }`
        : ""}
  }

  &.danger {
    color: ${props => (props.isFill ? WHITE : DANGER_COLOR)};
    background-color: ${props => (props.disabled ? DANGER_COLOR_DISABLED : props.isFill ? DANGER_COLOR : WHITE)};
    border: ${props => (props.isFill ? "none" : `1.5px solid ${DANGER_COLOR}`)};

    ${props =>
      !props.disabled
        ? `
        &:hover {
          background-color: ${props.isFill ? DANGER_COLOR_LIGHT : WHITE};
          border: ${props.isFill ? "none" : `1.5px solid ${DANGER_COLOR_LIGHT}`};
          color: ${props.isFill ? WHITE : DANGER_COLOR_LIGHT};
        }`
        : ""}

    &:active {
      color: ${WHITE};
      background-color: ${DANGER_COLOR_DARK};
    }
  }

  &.secondary {
    color: ${WHITE};
    background-color: ${props => (props.disabled ? SECONDARY_COLOR_DISABLED : SECONDARY_COLOR)};

    ${props =>
      !props.disabled
        ? `
        &:hover {
          background-color: ${SECONDARY_COLOR_LIGHT};
        }`
        : ""}

    &:active {
      color: ${WHITE};
      background-color: ${SECONDARY_COLOR_DARK};
    }
  }

  &.info {
    color: ${WHITE};
    background-color: ${props => (props.disabled ? INFO_COLOR_DISABLED : INFO_COLOR)};

    ${props =>
      !props.disabled
        ? `
        &:hover {
          background-color: ${INFO_COLOR_LIGHT};
        }`
        : ""}

    &:active {
      color: ${WHITE};
      background-color: ${INFO_COLOR_DARK};
    }
  }

  &.success {
    color: ${props => (props.isFill ? WHITE : SUCCESS_COLOR)};
    background-color: ${props => (props.disabled ? WHITE : props.isFill ? SUCCESS_COLOR : WHITE)};
    border: ${props => (props.isFill ? "none" : `1.5px solid ${SUCCESS_COLOR}`)};

    ${props =>
      !props.disabled
        ? `
        &:hover {
          background-color: ${props.isFill ? SUCCESS_COLOR_LIGHT : WHITE};
          border: ${props.isFill ? "none" : `1.5px solid ${SUCCESS_COLOR_LIGHT}`};
          color: ${props.isFill ? WHITE : SUCCESS_COLOR_LIGHT};
        }`
        : ""}

    &:active {
      color: ${WHITE};
      background-color: ${SUCCESS_COLOR_DARK};
    }
  }

  &.warning {
    color: ${WHITE};
    background-color: ${props => (props.disabled ? WARNING_COLOR_DISABLED : WARNING_COLOR)};

    ${props =>
      !props.disabled
        ? `
        &:hover {
          background-color: ${WARNING_COLOR_LIGHT};
        }`
        : ""}

    &:active {
      background-color: ${WARNING_COLOR_DARK};
    }
  }
`;

/**
 * Atomic 버튼 입니다.
 *
 * - `children`으로 값을 받을 시 텍스트로 적용됩니다.
 * - `isFill`과 `isWide`, `disabled` props는 기본값으로 `false`가 적용됩니다.
 */
export const Button = ({
  className,
  style,
  children,
  type = "button",
  color,
  title,
  isWide = false,
  isFill = true,
  onClick,
  disabled = false
}: ButtonProps) => (
  <Layout
    title={title}
    style={style}
    className={classNames(className, color)}
    type={type}
    isWide={isWide}
    isFill={isFill}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Layout>
);
