/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IntScalar, EnumScalar, StringScalar, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";
import { Edge } from "models/Edge";

type Option = Readonly<{
  page?: number;
  searchId?: string;
  searchName?: string;
  count: number;
}>;

export const GetRights = async (option: Option) => {
  const $page = <Variable name="page" scalar={<IntScalar />} value={option.page} />;
  const $searchId = <Variable name="searchId" scalar={<IdScalar isNullable />} value={option.searchId} />;
  const $searchName = <Variable name="searchName" scalar={<StringScalar isNullable />} value={option.searchName} />;
  const $count = <Variable name="count" scalar={<IntScalar />} value={option.count} />;
  const { query, variables } = render(
    <Query operationName="GET_RIGHT">
      <SelectionSet
        name="company"
        alias="rights"
        argument={{
          first: $count,
          page: $page,
          where: {
            company_id: $searchId,
            name__contains: $searchName
          },
          orderBy: <EnumScalar type="CompanyOrderByInput" value="company_id__DESC" items={["company_id__DESC"]} />
        }}
      >
        <Field name="company_id" alias="id" />
        <Field name="name" />
        <Field name="display_name" alias="displayName" />
        <Field name="valid_check" alias="validCheck" />
        <Field name="weight" />
        <Field name="license" />
        <Field name="group_path" alias="path" />
        <Field name="type" />
      </SelectionSet>
      <SelectionSet
        name="edge"
        argument={{
          first: $count,
          page: $page,
          node: "company",
          where: {
            company: {
              company_id: $searchId,
              name__contains: $searchName
            }
          }
        }}
      >
        <Field name="hasNextPage" />
        <Field name="hasPreviousPage" />
        <Field name="startCursor" />
        <Field name="endCursor" />
        <Field name="currentPage" />
        <Field name="pageCount" />
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    rights: {
      id: string;
      name: string;
      displayName: string;
      validCheck: string;
      weight: number;
      license: string;
      path: string;
      type: string;
    }[];
    edge: Edge;
  }>;
  return await clients.company.request<Data>(query, variables);
};
