import { createReducer, createHandler, createActions } from "lib/store";

type State = ReturnType<typeof createInitialState>;

export type TypeAudio = {
  uuid: string;
  title: string;
  url: string;
  type: string;
  artist?: string;
  coverUrl?: string;
};

export type PLAY_STATE = "PLAY" | "PAUSE" | "STOP";

const TOGGLE_VISIBLE = Symbol("App::AudioPlayer::ToggleVisible");
const SET_AUDIO_DATA = Symbol("App::AudioPlayer::SetAudioData");
const SET_AUDIO_PLAY = Symbol("App::AudioPlayer::SetAudioPlay");
const ADD_AUDIO_IN_PLAYLIST = Symbol("App::AudioPlayer::AddAudioInPlaylist");
const ADD_MULTI_AUDIO_IN_PLAYLIST = Symbol("App::AudioPlayer::AddMultiAudioInPlaylist");
const REMOVE_AUDIO_IN_PLAYLIST = Symbol("App::AudioPlayer::RemoveAudioInPlaylist");
const CLEAR_PLAYLIST = Symbol("App::AudioPlayer::ClearPlaylist");

export const AudioPlayerActions = createActions({
  toggleVisible(isVisible?: boolean) {
    return { type: TOGGLE_VISIBLE, isVisible };
  },
  setAudioData(audio: TypeAudio) {
    return { type: SET_AUDIO_DATA, audio };
  },
  setAudioPlay(isPlay: PLAY_STATE) {
    return { type: SET_AUDIO_PLAY, isPlay };
  },
  addAudioInPlaylist(audio: TypeAudio) {
    return { type: ADD_AUDIO_IN_PLAYLIST, audio };
  },
  addMultiAudioInPlaylist(playlist: TypeAudio[]) {
    return { type: ADD_MULTI_AUDIO_IN_PLAYLIST, playlist };
  },
  removeAudioInPlaylist(index: number) {
    return { type: REMOVE_AUDIO_IN_PLAYLIST, index };
  },
  clearAudioPlaylist() {
    return { type: CLEAR_PLAYLIST };
  }
});

const handler = createHandler<State>({
  [TOGGLE_VISIBLE](state, payload: { isVisible?: boolean }) {
    state.isVisible = payload.isVisible === undefined ? !state.isVisible : payload.isVisible!;
  },
  [SET_AUDIO_DATA](state, payload: { audio: TypeAudio }) {
    state.audio = payload.audio;
  },
  [SET_AUDIO_PLAY](state, payload: { isPlay: PLAY_STATE }) {
    state.isPlay = payload.isPlay;
  },
  [ADD_AUDIO_IN_PLAYLIST](state, payload: { audio: TypeAudio }) {
    state.playlist.push(payload.audio);
  },
  [ADD_MULTI_AUDIO_IN_PLAYLIST](state, payload: { playlist: TypeAudio[] }) {
    state.playlist = [...state.playlist, ...payload.playlist];
  },
  [REMOVE_AUDIO_IN_PLAYLIST](state, payload: { index: number }) {
    state.playlist.splice(payload.index, 1);
  },
  [CLEAR_PLAYLIST](state) {
    state.playlist = [];
  }
});

const createInitialState = () => ({
  /**
   * 오디오 플레이어를 보여줄지 말지 판정하는 플래그
   */
  isVisible: false,
  /**
   * 재생할 오디오의 정보
   */
  audio: {} as TypeAudio,
  playlist: [] as TypeAudio[],
  isPlay: "STOP" as PLAY_STATE
});

export const reducer = createReducer(handler, createInitialState);
