import React, { ReactElement } from "react";
import { Style } from "App/Routes/GoodsCreate/Style";
import { ProductCreateStore } from "App/Routes/ProductCreate/Store";
import { ProductCreateActions } from "App/Routes/ProductCreate/Store/ProductCreate";

type Props = {
  readonly quality: string;
};

const QualityOption = [
  {
    id: "NEW",
    name: "NEW"
  },
  {
    id: "A",
    name: "A"
  }
];

export const ProductQuality = ({ quality }: Props): ReactElement => (
  <Style.Row>
    <Style.Caption>퀄리티 *</Style.Caption>
    <Style.SubCaption>상품의 상태에 따른 등급을 나타냅니다.</Style.SubCaption>
    <Style.Select
      placeholder={"퀄리티를 선택해주세요."}
      defaultValue={QualityOption.find(item => item.id === quality)}
      optionList={QualityOption}
      onBlur={info => info && ProductCreateStore.dispatch(ProductCreateActions.setQuality(info.id))}
    />
  </Style.Row>
);
