import { Info } from "App/Atomics/Input/Select/TextSelect";
import { ValidType } from "./ValidType";

export const TypeOptionInfo = [
  { id: "name", name: "name" },
  { id: "name_youtube", name: "name_youtube" },
  { id: "name_other", name: "name_other" },
  { id: "description", name: "description" },
  { id: "lyrics", name: "lyrics" },
  { id: "name_past", name: "name_past" },
  { id: "name_current", name: "name_current" }
];

export const TitleTypeOptionInfo = [
  { id: "name", name: "name" },
  { id: "description", name: "description" },
  { id: "subtitle", name: "subtitle" },
  { id: "tag", name: "tag" },
  { id: "name_youtube", name: "name_youtube" },
  { id: "name_other", name: "name_other" },
  { id: "lyrics", name: "lyrics" },
  { id: "description_performer", name: "description_performer" },
  { id: "description_composer", name: "description_composer" },
  { id: "name_sur", name: "name_sur" },
  { id: "name_nick", name: "name_nick" },
  { id: "name_first_middle", name: "name_first_middle" },
  { id: "name_peerage", name: "name_peerage" },
  { id: "name_distinguish", name: "name_distinguish" },
  { id: "name_past", name: "name_past" },
  { id: "name_particule", name: "name_particule" },
  { id: "name_current", name: "name_current" }
];

export const YtvLicenseValidCheckInfo = [
  { id: ValidType.V3, name: "미확인" },
  { id: ValidType.MODIFY, name: "만료" },
  { id: ValidType.DONE, name: "사용 가능" }
] as Info[];

export const RoleTypeClassOptionInfo = [
  { id: "character", name: "배역 (character)" },
  { id: "job", name: "직업 (job)" },
  { id: "instrument", name: "악기 (instrument)" }
];

export const LicenseTypeOptionInfo = [
  { id: "PD", name: "PublicDomain" },
  { id: "ccl", name: "CCL" },
  { id: "NoCopyright", name: "NoCopyright" },
  { id: "VL", name: "VL" },
  { id: "Cover", name: "Cover" }
];
