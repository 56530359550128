/** @jsx jsx */

import { jsx, render, Field, Variable, Mutation, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  idList: string[];
}>;

export const DeleteMultiAccessRecord = async (option: Option) => {
  const { query, variables } = render(
    <Mutation operationName="DELETE_MULTI_ACCESS_RECORD">
      {option.idList.map((id, i) => {
        const $id = <Variable name={`deleteId__${i}`} scalar={<IdScalar />} value={id} />;
        return (
          <Field
            name="deleteAccess_record"
            alias={`deleteAccess_${i}`}
            argument={{
              where: { id: $id }
            }}
          />
        );
      })}
    </Mutation>
  );
  type Data = Readonly<{
    [key: string]: boolean;
  }>;
  return await clients.access.request<Data>(query, variables);
};
