import { CreateEmptyAlbum } from "./CreateEmptyAlbum";
import { v4 as uuidv4 } from "uuid";

type Props = Readonly<{
  title: string;
  artistId: string;
  companyId: string;
}>;

export const useCreateEmptyAlbum = () => {
  const createAlbum = async (props: Props) => {
    const no = uuidv4();
    await CreateEmptyAlbum({
      no,
      class: "record",
      subClass: "album",
      ...props
    });
  };
  return { createAlbum };
};
