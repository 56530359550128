import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

type Props = {
  title: string;
};

export const Helmet = ({ title }: Props) => (
  <ReactHelmet>
    <title>{`DMS - ${title}`}</title>
  </ReactHelmet>
);
