/** @jsx jsx */

import { jsx, render, Query, SelectionSet, Field, Variable, IdScalar } from "graphql-jsx";
import { clients } from "utils/clients";

type Option = Readonly<{
  metadataId?: string;
}>;

export type Metadata = Readonly<{
  metadataId: string;
  class: string;
  subClass: string;
  title: string;
  validCheck: string;
  structures: {
    rootId: string;
    structureId: string;
  }[];
}>;

export const GetTrackNoWithAlbumSerial = async (option: Option) => {
  const $metadataId = <Variable name="metadata_id" scalar={<IdScalar isNullable={true} />} value={option.metadataId} />;
  const { query, variables } = render(
    <Query operationName="GET_TRACK_NO_WITH_ALBUM_SERIAL">
      <SelectionSet
        name="metadata_structure"
        alias="metadataStructure"
        argument={{
          group: true,
          first: 1,
          where: {
            list__some: {
              metadata__some: {
                metadata_id: $metadataId
              }
            }
          }
        }}
      >
        <SelectionSet
          name="list"
          argument={{
            where: {
              OR: [{ metadata__some: { type_metadata_subclass: "album" } }, { metadata__some: { metadata_id: $metadataId } }]
            }
          }}
        >
          <SelectionSet name="metadata">
            <Field name="no" />
          </SelectionSet>
        </SelectionSet>
      </SelectionSet>
    </Query>
  );
  type Data = Readonly<{
    metadataStructure: {
      list: {
        metadata: {
          no: string;
        }[];
      }[];
    }[];
  }>;
  const { errors, data } = await clients.metadata.request<Data>(query, variables);
  if (errors || !data) {
    console.log(errors);
    throw errors;
  }
  const result = { albumNo: data.metadataStructure[0].list[0].metadata[0].no, trackNo: data.metadataStructure[0].list[1].metadata[0].no };
  return result;
};
