import { DeleteAccessRecord } from "GraphQL/Queries/Access/DeleteAccessRecord";
import { TargetTableInput } from "constants/TargetTableInput";
import { DeleteFile } from "GraphQL/Queries";
import { DeleteGoods } from "GraphQL/Queries/Goods";
import { BookType } from "GraphQL/Scalars/BookType";
import { FileType } from "GraphQL/Scalars/FileType";
import { requestAccessRecord } from "lib/requestAccessRecord";
import { GoodsStore, useGoodsSelector } from "../Store";
import { GoodsActions } from "../Store/Goods";

export const useDeleteGoods = () => {
  const goods = useGoodsSelector(store => store.Goods.goods);
  const deleteGoods = async (key: string) => {
    const { id, urls, company } = goods.find(item => item.id.key === key)!;
    const accessId = await requestAccessRecord({ targetId: id.raw, targetTable: TargetTableInput.Goods });
    for (const item of urls) {
      if (item.url) {
        await DeleteFile({ filename: item.url, companyId: company.id, fileType: FileType.FILE, book: BookType.immediate });
      }
    }
    await DeleteGoods({ id: id.raw });
    await DeleteAccessRecord({ id: accessId! });
    GoodsStore.dispatch(GoodsActions.removeGoods(key));
  };
  return { deleteGoods };
};
