import React from "react";

import { INFO_LIST } from "./infoList";
import { Input } from "App/Atomics/Input";
import { ValidType } from "constants/ValidType";
import { identity } from "utils/identity";

type Props = Omit<Parameters<typeof Input.TagSelect>[0], "defaultValue" | "optionList" | "onChange" | "onBlur"> &
  Readonly<{
    placeholder?: string;
    defaultValue?: readonly ValidType[];
    onChange?: (validType: ValidType[]) => void;
    onBlur?: (validType: ValidType[]) => void;
  }>;

export const ValidTypeTagSelect = ({
  style,
  className,
  isDisabled,
  defaultValue,
  onChange = identity,
  onBlur = identity,
  placeholder = "유효성"
}: Props) => {
  const change = (optionList: ReadonlyArray<{ id: string }>) => onChange(optionList.map(({ id }) => id as ValidType));
  const blur = (optionList: ReadonlyArray<{ id: string }>) => onBlur(optionList.map(({ id }) => id as ValidType));
  return (
    <Input.TagSelect
      placeholder={placeholder}
      isDisabled={isDisabled}
      defaultValue={defaultValue?.map(name => ({ id: name, name }))}
      optionList={INFO_LIST}
      style={style}
      className={className}
      onChange={change}
      onBlur={blur}
    />
  );
};
